import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Container from '../../components/styleguide/Container'
import { FONT_COLOR_PRIMARY } from '../../styles/colors'

import EventLogBody from './EventLogBody'
import { getOrganizationEvents } from '../../repositories/events_repository'
import { mapEventsToState } from '../../mappers/events_mapper'

const StyledContainer = styled(Container)`
  padding: 0;
  position: relative;
`

const EventLog = ({
  eventActionTypes,
  getEvents,
  exportEvents,
  classes,
  cardTitle,
  targetID,
}) => {
  const loadEvents = (
    setEvents,
    setError,
    setLoading,
    setCurrentPage,
    setPageCount,
    events = [],
    currentPage = 1,
    newFilter = {}
  ) => {
    let params = {
      page: currentPage,
      filter: newFilter,
    }
    if (targetID) {
      params.target_id = targetID
    }

    getEvents(params)
      // .then(() => {
      //   setLoading(true)
      // })
      .then((res) => {
        if (res.events.length === 0) {
          setEvents([])
        } else {
          setEvents(mapEventsToState(res.events))
        }
        setLoading(false)

        setPageCount(res.page_count)
        setCurrentPage(currentPage)
      })
      .catch((err) => {
        const error = err.response ? err.response.status : 502
        setError(error)
        setLoading(false)
        console.log('event log err', err.response.statusText)
      })
  }

  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState(null)

  useEffect(
    loadEvents.bind(
      null,
      setEvents,
      setError,
      setLoading,
      setCurrentPage,
      setPageCount,
      [],
      currentPage
    ),
    []
  )

  const onChange = ({ newFilter, newPage }) => {
    setEvents([]);
    setLoading(true);

    loadEvents(
      setEvents,
      setError,
      setLoading,
      setCurrentPage,
      setPageCount,
      events,
      newPage,
      newFilter
    ).then((res) => {
      setLoading(false);
    })
  }

  const onDownload = ({ filter, page }) => {
    exportEvents({ filter: filter, page: page }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data.exported_events_csv]));
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', response.data.filename);
      document.body.appendChild(link)
      link.click()
    })
  }

  return (
    <StyledContainer>
      <EventLogBody
        events={events}
        onChange={onChange}
        onDownload={onDownload}
        actionTypes={eventActionTypes}
        pageCount={pageCount}
        currentPage={currentPage}
        showFilter={true}
        showPageNumber={true}
        cardTitle={cardTitle}
        loading={loading}
      />
    </StyledContainer>
  )
}

export default EventLog
