import React from 'react'
import PropTypes from 'prop-types';


const YAxisLine = ({classes, xstart, ystart, yend, style}) => {




    return (
        <line 
            x1={xstart} 
            x2={xstart}
            y1={ystart} 
            y2={yend}
            className={classes}
            style={style}
        />
    )
}

YAxisLine.propTypes = {
    classes: PropTypes.string,
    ystart : PropTypes.number,
    yend : PropTypes.number,
    xstart : PropTypes.number

}


export default YAxisLine;

