import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Pagination } from 'react-bootstrap'

import { getSasbMetricNotes } from '../../../../../repositories/sasb_metric_tracking_repository'
import { NoteContent } from './NoteContent'
import { StyledNotesList } from './NotesList.styles'

dayjs().format()

export const NotesList = ({ metric }) => {
  const [notes, setNotes] = useState([])
  const [pageData, setPageData] = useState({
    current: metric.note_page,
    limit: metric.note_limit,
    max: metric.note_page_count,
  })
  useEffect(() => {
    setNotes(metric.notes)
    setPageData({
      current: metric.note_page,
      limit: metric.note_limit,
      max: metric.note_page_count,
    })
  }, [metric])

  const paginationRequest = async page => {
    const res = await getSasbMetricNotes(metric.id, page, 10)
    setPageData({
      current: res.note_page,
      limit: res.note_limit,
      max: res.note_page_count,
    })
    setNotes(res.notes)
  }

  return (
    <StyledNotesList>
      <h3>Internal Notes:</h3>
      <ul className='notes-list'>
        {notes != null && notes.length ? (
          notes.map(el => (
            <li key={el.id}>
              <div className='user-section'>
                <div className='media'>
                  {el.user_avatar_url != null ? (
                    <img
                      src={el.user_avatar_url}
                      alt='User avatar'
                      className='user-avatar'
                      loading='lazy'
                      width='25'
                      height='25'
                    />
                  ) : (
                    <div className='user-avatar-placeholder'>
                      <img
                        src='/icons/ico_user_alt.svg'
                        loading='lazy'
                        width='9'
                        height='9'
                      />
                    </div>
                  )}
                  <div className='media-body'>
                    <h6>{el.user_full_name}</h6>
                    <span className='date'>
                      {dayjs(el.created_at).format('MMMM D, YYYY h:mma')}
                    </span>
                  </div>
                </div>
              </div>
              <div className='note-content'>
                <NoteContent note={el} subMetrics={metric.sub_metrics} />
              </div>
            </li>
          ))
        ) : (
          <div className='empty-alert'>
            <h5>There are no notes</h5>
          </div>
        )}
      </ul>
      {metric && pageData.max > 0 ? (
        <Pagination
          max={pageData.max}
          selected={pageData.current}
          onChange={paginationRequest}
        />
      ) : null}
    </StyledNotesList>
  )
}

NotesList.propTypes = {
  metric: PropTypes.object.isRequired,
}
