export const portfolioStatuses = {
    pending: 'pending',
    processing: 'processing',
    failure: 'failure',
    not_modelable: 'not_modelable',
    successfully_processed: 'successfully_processed'
}

export const assetClassOptions = [
    { label: "Equity", value: "Equity" },
    { label: "Fixed Income", value: "Fixed Income" },
    { label: "Money Market", value: "Money Market" },
    { label: "Corporate Bond", value: "Corporate Bond" },
    { label: "Sovereign Bond", value: "Sovereign Bond" },
    { label: "Mutual Fund", value: "Mutual Fund" },
    { label: "ETF", value: "ETF" },
];
  
export const defaultAssetClass = assetClassOptions[0].value;

export const countryCodeOptions = [
    { label: "United States", value: "US" },
    { label: "United Kingdom", value: "UK" },
    { label: "Canada", value:"CA" },
];

export const defaultCountryCode = countryCodeOptions[0].value;
