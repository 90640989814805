import React, { useState } from 'react'
import { SketchPicker } from 'react-color'
import styled from 'styled-components'

import { ESG_SCALE_COLOR_BAD } from '../../../styles/colors'

const Trigger = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${props => props.color};
  cursor: pointer;
`

const Frame = styled.div`
  position: fixed;
  inset: 0px;
`

const StyledPicker = styled(SketchPicker)`
  position: absolute;
`

const ColorPicker = ({
  defaultColor = ESG_SCALE_COLOR_BAD,
  color,
  onChange,
}) => {
  const [pickedColor, setPickedColor] = useState(defaultColor)
  const [open, setOpen] = useState(false)

  const handleColorChange = hexColor => {
    setPickedColor(hexColor)
    onChange && onChange(hexColor)
  }

  const displayColor = color || pickedColor

  return (
    <>
      <Trigger color={displayColor} onClick={() => setOpen(true)} />
      {open && (
        <>
          <Frame onClick={() => setOpen(false)} />
          <StyledPicker
            color={displayColor}
            presetColors={[]}
            onChangeComplete={color => handleColorChange(color.hex)}
          />
        </>
      )}
    </>
  )
}

export default ColorPicker
