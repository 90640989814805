import { useContext, useEffect } from 'react'

import { AppActions } from '../../yb_stores/app_store/actions/AppActions'
import { AppContext } from '../../yb_stores/app_store/AppStore'

const useCurrentTabRef = ref => {
  const [state, dispatch] = useContext(AppContext)

  useEffect(() => {
    if (ref) {
      dispatch({
        type: AppActions.SET_CURRENT_TAB_REF,
        payload: ref,
      })
    }
  }, [ref])

  return [state.currentTabRef]
}

export default useCurrentTabRef
