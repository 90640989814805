import { useEffect } from 'react'

export default function useInputValidations<T>(
    value: T,
    validations: YB.TInputValidation<T>[],
    feedbackModel: YB.TModel<string>
) {
    const [, setFeedback] = feedbackModel

    useEffect(() => {
        let updatedFeedback = ''

        for (let i = 0; i < validations.length; i = i + 1) {
            const feedbackMessage = validations[i](value)

            if (feedbackMessage) {
                updatedFeedback = feedbackMessage
                break
            }
        }

        setFeedback(updatedFeedback)
    }, [value])
}
