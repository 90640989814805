import React, { useState } from 'react'

import SwitchButton from '../../../components/styleguide/SwitchButton'
import Tabs from '../../../components/styleguide/Tabs'
import { tabNames } from '../../../constants'
import { timestamp } from '../../../helpers/shared'
import { downloadTemplate } from '../../../pdf/index'
import {
  ExplainerLink,
  LinkIcon,
  LinkText,
} from '../../../yb_components/styleguide/links/Link.styles'
import { PEERS_MODULE_EXPLAINER_URL } from '../../../yb_constants/urls/explainers'
import {
  dataDisplayOptions,
  dataProviderDisplayOptions,
} from './peers/constants'
import DownloadLandscape from './peers/DownloadLandscape'
import PeersESGScoreCard from './peers/PeersESGScoreCard'
import PeersMetricCard from './peers/PeersMetricCard'
import PeersSDGAlignment from './peers/PeersSDGAlignment'
import PeersTemplatePDF from './peers/PeersTemplatePDF'
import ReplacePeerModal from './peers/ReplacePeerModal'
import { PeerFooter, StyledCard, StyledPeers } from './peers/styles'

/* global react_app_organization */
/* eslint no-undef: "error" */

const Peers = ({
  instrument,
  peers,
  currentPeersTab,
  setCurrentPeersTab,
  setPeersCallback,
}) => {
  const [isGeneratingReport, setIsGeneratingReport] = useState(false)
  const [isReplaceModalOpen, setIsReplaceModalOpen] = useState(false)
  const [dataDisplayType, setDataDisplayType] = useState('zscore')
  const [dataProvider, setDataProvider] = useState('ideal-ratings')

  const exportLandscape = () => {
    setIsGeneratingReport(true)
    downloadTemplate(
      <PeersTemplatePDF
        instrument={instrument}
        peers={peers}
        dataDisplayType={dataDisplayType}
        dataProvider={dataProvider}
      />,
      'full',
      `yb-${instrument.name.replace(' ', '-')}-peers-${timestamp}`,
      instrument.id,
      'EntityPeers',
      instrument.name,
      () => setIsGeneratingReport(false)
    )
  }

  const openReplacePeerModal = () => {
    setIsReplaceModalOpen(true)
  }

  const dataDisplaySwitchClick = clickedDataDisplayType => {
    setDataDisplayType(clickedDataDisplayType)
  }

  const dataProviderSwitchClick = clickedDataProviderType => {
    if (
      !react_app_organization.organization.can_access_raw_refinitiv_data &&
      dataDisplayType === 'raw' &&
      clickedDataProviderType === 'refinitiv'
    ) {
      setDataDisplayType('normalized')
    }
    setDataProvider(clickedDataProviderType)
  }

  return (
    <StyledPeers>
      <StyledCard>
        <Tabs
          style={{ marginTop: '0' }}
          title='Peer Analysis'
          headerComponent={[
            <SwitchButton
              onClick={dataProviderSwitchClick}
              active={dataProvider}
              options={dataProviderDisplayOptions}
              customStyle={'position: relative; bottom: 3px;'}
              key={0}
            />,
            <SwitchButton
              onClick={dataDisplaySwitchClick}
              active={dataDisplayType}
              options={dataDisplayOptions}
              disabledOptions={
                !react_app_organization.organization
                  .can_access_raw_refinitiv_data &&
                dataProvider === 'refinitiv' && ['raw']
              }
              customStyle={'position: relative; bottom: 3px;'}
              key={1}
            />,
          ]}
          headerComponentCondition={[
            'ENVIRONMENTAL',
            'SOCIAL',
            'GOVERNANCE',
          ].includes(currentPeersTab)}
          tabs={{
            [tabNames.ESG_SCORES]: (
              <PeersESGScoreCard
                peers={peers}
                dataDisplayType={dataDisplayType}
                openReplacePeerModal={openReplacePeerModal}
              />
            ),
            [tabNames.SDG_ALIGNMENT]: (
              <PeersSDGAlignment
                peers={peers}
                dataDisplayType={dataDisplayType}
                openReplacePeerModal={openReplacePeerModal}
              />
            ),
            [tabNames.ENVIRONMENTAL]: (
              <PeersMetricCard
                peers={peers}
                metricType='e'
                dataDisplayType={dataDisplayType}
                dataProvider={dataProvider}
                openReplacePeerModal={openReplacePeerModal}
              />
            ),
            [tabNames.SOCIAL]: (
              <PeersMetricCard
                peers={peers}
                metricType='s'
                dataDisplayType={dataDisplayType}
                dataProvider={dataProvider}
                openReplacePeerModal={openReplacePeerModal}
              />
            ),
            [tabNames.GOVERNANCE]: (
              <PeersMetricCard
                peers={peers}
                metricType='g'
                dataDisplayType={dataDisplayType}
                dataProvider={dataProvider}
                openReplacePeerModal={openReplacePeerModal}
              />
            ),
          }}
          activeTab={currentPeersTab}
          showContentInCard={false}
          onChange={setCurrentPeersTab}
        />

        <PeerFooter>
          <ExplainerLink href={PEERS_MODULE_EXPLAINER_URL} target='_blank'>
            <LinkIcon className='far fa-question-circle' />
            <LinkText>What do these scores mean?</LinkText>
          </ExplainerLink>
          <DownloadLandscape
            isBusy={isGeneratingReport}
            onClick={exportLandscape}
          />
        </PeerFooter>
      </StyledCard>

      <ReplacePeerModal
        show={isReplaceModalOpen}
        peers={peers}
        setGlobalPeers={setPeersCallback}
        onClose={() => {
          setIsReplaceModalOpen(false)
        }}
      />
    </StyledPeers>
  )
}

export default Peers
