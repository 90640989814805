import React from 'react';
import styled from 'styled-components';
import { BG_COLOR_SECONDARY } from '../../styles/colors';
import TextView from '../styleguide/TextView';
import Link from '../styleguide/Link';

const StyledCard = styled.div`
  background-color: ${BG_COLOR_SECONDARY};
  border-radius: 5px;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  img {
    height: 32px;
    width: 32px;
    margin-bottom: 10px;
  }
`;

const ErrorCard = () => {
  return (
    <StyledCard>
      <img src="/ico_exclamation_error.svg"/>
      <TextView center bold size={18} text="Oops! Something went wrong"/>
      <TextView center secondary text="Something went wrong loading the content."/>
      <Link onClick={() => window.location.reload()}>
        Reload
      </Link>
    </StyledCard>
  );
}

export default ErrorCard;