import React from 'react'
import styled from 'styled-components'

import Table from '../../../components/styleguide/Table'
import {
  BORDER_COLOR_LIGHT,
  BORDER_COLOR_PRIMARY,
  FONT_COLOR_PRIMARY,
  FONT_COLOR_SECONDARY,
} from '../../../styles/colors'
import LoadingSpinner from '../../styleguide/spinners/LoadingSpinner'

const StyledContainer = styled.div`
  min-height: var(--min-height, 0px);
  margin-bottom: 20px;
  overflow: auto;

  tbody tr:nth-child(even) {
    border: 1px solid ${BORDER_COLOR_LIGHT};
    border-left: 0;
    border-right: 0;
  }

  .header-row {
    border-bottom: 2px solid ${BORDER_COLOR_PRIMARY};

    th {
      > span {
        overflow: hidden;
        white-space: nowrap;
        padding: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-content: center;
        justify-content: flex-start;

        color: ${FONT_COLOR_PRIMARY};
      }

      :nth-child(n + 3) {
        > span {
          display: flex;
          align-content: center;
          justify-content: center;
        }
      }
    }
  }
`

const StyledCenteredDiv = styled.div`
  min-height: calc(var(--min-height, 0px) - 75px);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SyledNoDataFound = styled.div`
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: 16px;
  color: ${FONT_COLOR_SECONDARY};
  text-align: center;
`

const FlatTable = ({ className, isLoading, noDataText, ...props }) => {
  const renderCustomBody = () => {
    if (isLoading)
      return (
        <StyledCenteredDiv>
          <LoadingSpinner size={'3rem'} />
        </StyledCenteredDiv>
      )
    if (!props?.body?.length || props?.holdingsTotal == 0)
      return (
        <StyledCenteredDiv>
          <SyledNoDataFound>{noDataText}</SyledNoDataFound>
        </StyledCenteredDiv>
      )
    return null
  }

  return (
    <StyledContainer className={className}>
      <Table customBody={renderCustomBody()} {...props} />
    </StyledContainer>
  )
}

export default FlatTable
