import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { BaseFormGroup } from '../../../../../yb_components/styleguide/form/base/form_group/BaseFormGroup'
import { BaseErrorText } from '../../../../../yb_components/styleguide/form/base/help_text/BaseErrorText'
import { BaseCombobox } from '../../../../../yb_components/styleguide/form/base/inputs/BaseCombobox'
import { BaseLabel } from '../../../../../yb_components/styleguide/form/base/label/BaseLabel'
import { getPeerCompanies } from '../../repositories/custom_companies_repository'
import { StyledCard } from './FormCard'

export const SectorSection = ({ industryGroups, initialFormData }) => {
  const {
    clearErrors,
    control,
    formState: { errors },
    setValue,
  } = useFormContext()

  const SELECTED_PEERS_INITIAL = {
    a: { id: null, name: '' },
    b: { id: null, name: '' },
    c: { id: null, name: '' },
    d: { id: null, name: '' },
  }

  const [selectedIndustryGroup, setSelectedIndustryGroup] = useState({})
  const [selectedIndustry, setSelectedIndustry] = useState({})
  const [selectedActivity, setSelectedActivity] = useState({})
  const [peerOptions, setPeerOptions] = useState([])
  const [selectedPeers, setSelectedPeers] = useState(SELECTED_PEERS_INITIAL)

  const getIndustryGroupByActivityId = async activityId => {
    if (activityId == null) return
    const industryGroup = industryGroups.find(({ trbc_industries }) =>
      trbc_industries.some(({ trbc_activities }) =>
        trbc_activities.some(({ id }) => id === activityId)
      )
    )
    return industryGroup
  }
  const getIndustry = async (indGroup, activityId) => {
    const industryGroup = industryGroups.find(el => el.id === indGroup.id)
    const industry = industryGroup.trbc_industries.find(({ trbc_activities }) =>
      trbc_activities.some(({ id }) => id === activityId)
    )
    return industry
  }
  const getActivity = async (indGroup, ind, activityId) => {
    const industryGroup = industryGroups.find(el => el.id === indGroup.id)
    const industry = industryGroup.trbc_industries.find(el => el.id === ind.id)
    const { name, id } = industry.trbc_activities.find(
      el => el.id === activityId
    )
    return { name, id }
  }

  const initIndustryComboboxes = async activityId => {
    if (activityId == null) return
    const industryGroup = await getIndustryGroupByActivityId(activityId)
    const industry = await getIndustry(industryGroup, activityId)
    const activity = await getActivity(industryGroup, industry, activityId)
    setSelectedIndustryGroup(industryGroup)
    setSelectedIndustry(industry)
    setSelectedActivity(activity)
    const peerCompanies = await loadPeerCompanies(activityId)
    // filter existing peers out of peer options
    const peerIdsArr = Object.values(selectedPeers).flatMap(peer =>
      peer.id !== null ? [peer.id] : []
    )
    setPeerOptions(peerCompanies.filter(el => !peerIdsArr.includes(el.id)))
  }

  useEffect(() => {
    initIndustryComboboxes(initialFormData.trbc_activity_id)
  }, [initialFormData])

  const initPeerCompanies = async activityId => {
    if (peerOptions != null && peerOptions.length) return
    else {
      const peerCompanies = await loadPeerCompanies(activityId)
      setPeerOptions(peerCompanies)
    }
  }
  useEffect(() => {
    initPeerCompanies(initialFormData.trbc_activity_id)
  }, [initialFormData])

  const handleSelectIndustryGroup = data => {
    clearErrors('industry_group')
    setSelectedIndustryGroup(data)
    setSelectedIndustry({})
    setSelectedActivity({})
    setSelectedPeers(SELECTED_PEERS_INITIAL)
    setPeerOptions([])
  }
  const handleSelectIndustry = data => {
    clearErrors('industry')
    setSelectedIndustry(data)
    setSelectedActivity({})
    setSelectedPeers(SELECTED_PEERS_INITIAL)
    setPeerOptions([])
  }
  const handleSelectActivity = async data => {
    setPeerOptions([])
    setSelectedActivity(data)
    const peerCos = await loadPeerCompanies(data.id)
    setPeerOptions(peerCos)
    setSelectedPeers(SELECTED_PEERS_INITIAL)
  }

  useEffect(() => {
    setValue('industry_group', selectedIndustryGroup.id, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }, [selectedIndustryGroup])
  useEffect(() => {
    setValue('industry', selectedIndustry.id, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }, [selectedIndustry])
  useEffect(() => {
    setValue('trbc_activity_id', selectedActivity.id, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }, [selectedActivity])

  const loadPeerCompanies = async id => {
    try {
      const res = await getPeerCompanies(id)
      const sortedRes = res.sort(function (a, b) {
        var nameA = a.name.toUpperCase() // ignore upper and lowercase
        var nameB = b.name.toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
      // setPeerOptions(sortedRes)
      return sortedRes
    } catch (e) {
      console.error(e)
    }
  }

  const handleSelectPeer = (peerId, data) => {
    // add old peer to options array if it has data
    const oldPeer = selectedPeers[peerId]
    if (oldPeer != null && oldPeer.id != null) {
      peerOptions.push(oldPeer)
      peerOptions.sort(function (a, b) {
        var nameA = a.name.toUpperCase() // ignore upper and lowercase
        var nameB = b.name.toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
      setPeerOptions(peerOptions)
    }
    // set new selected peer
    selectedPeers[peerId] = data
    setSelectedPeers(selectedPeers)
    const peerIdsArr = Object.values(selectedPeers).flatMap(peer =>
      peer.id !== null ? [peer.id] : []
    )
    // filter out selected peers from peer options
    setPeerOptions(peerOptions.filter(el => !peerIdsArr.includes(el.id)))
  }

  const cvt = function (n) {
    return String.fromCharCode(n + 'a'.charCodeAt(0) - 1)
  }

  const sanitizeInitialPeers = peers => {
    if (peers == null || !peers.length) return
    peers.forEach((el, i) => setInitialPeer(cvt(i + 1), el))
  }
  const setInitialPeer = (peerId, data) => {
    // set new selected peer
    selectedPeers[peerId] = data
    setSelectedPeers(selectedPeers)
    const peerIdsArr = Object.values(selectedPeers).flatMap(peer =>
      peer.id !== null ? [peer.id] : []
    )
    // filter out selected peers from peer options
    setPeerOptions(peerOptions.filter(el => !peerIdsArr.includes(el.id)))
  }

  useEffect(() => {
    sanitizeInitialPeers(initialFormData.peer_companies)
  }, [initialFormData])

  useEffect(() => {
    const peerIdsArr = Object.values(selectedPeers).flatMap(peer =>
      peer.id !== null ? [peer.id] : []
    )
    setValue('peer_company_ids', peerIdsArr, {
      shouldValidate: false,
      shouldDirty: true,
    })
  }, [selectedPeers.a, selectedPeers.b, selectedPeers.c, selectedPeers.d])

  return (
    <StyledCard title='Sector & Peer Information' className='sector-card'>
      <section>
        {/* industry group */}
        <BaseFormGroup controlId='industry_group_select'>
          <BaseLabel>
            Industry Group <small>(Required)</small>
          </BaseLabel>
          <Controller
            control={control}
            name='industry_group'
            render={({ field: { name, ref } }) => (
              <BaseCombobox
                placeholder='Select Industry Group'
                inputRef={ref}
                value={selectedIndustryGroup}
                getOptionValue={option => `${option.id}`}
                getOptionLabel={option =>
                  option.name != null ? `${option.name}` : null
                }
                options={industryGroups}
                control={control}
                name={name}
                rules={{ required: true }}
                onChange={data => handleSelectIndustryGroup(data)}
                className={errors.industry_group && 'has-error'}
                isInvalid={!!errors.industry_group}
              />
            )}
          />
          {errors.industry_group?.message && (
            <BaseErrorText>{errors.industry_group.message}</BaseErrorText>
          )}
        </BaseFormGroup>
        {/* industry  */}
        <BaseFormGroup controlId='industry_select'>
          <BaseLabel>
            Industry <small>(Required)</small>
          </BaseLabel>
          <Controller
            control={control}
            name='industry'
            render={({ field: { name, ref } }) => (
              <BaseCombobox
                placeholder='Select Industry'
                inputRef={ref}
                value={selectedIndustry}
                getOptionValue={option => `${option.id}`}
                getOptionLabel={option =>
                  option.name != null ? `${option.name}` : null
                }
                options={selectedIndustryGroup.trbc_industries}
                control={control}
                name={name}
                rules={{ required: true }}
                onChange={data => handleSelectIndustry(data)}
                className={errors.industry && 'has-error'}
                isInvalid={!!errors.industry}
              />
            )}
          />
          {errors.industry?.message && (
            <BaseErrorText>{errors.industry.message}</BaseErrorText>
          )}
        </BaseFormGroup>
        {/* business activity */}
        <BaseFormGroup controlId='business_activity_select'>
          <BaseLabel>
            Business Activity <small>(Required)</small>
          </BaseLabel>

          <Controller
            control={control}
            name='trbc_activity_id'
            render={({ field: { name, ref } }) => (
              <BaseCombobox
                placeholder='Select Business Activity'
                inputRef={ref}
                value={selectedActivity}
                getOptionValue={option => `${option.id}`}
                getOptionLabel={option =>
                  option.name != null ? `${option.name}` : null
                }
                options={selectedIndustry?.trbc_activities}
                control={control}
                name={name}
                rules={{ required: true }}
                onChange={data => handleSelectActivity(data)}
                className={errors.trbc_activity_id && 'has-error'}
                isInvalid={!!errors.trbc_activity_id}
              />
            )}
          />
          {errors.trbc_activity_id?.message && (
            <BaseErrorText>{errors.trbc_activity_id.message}</BaseErrorText>
          )}
        </BaseFormGroup>
      </section>
      <section>
        {/* peer company a */}
        <BaseFormGroup controlId='peer_company_a_select'>
          <BaseLabel>Peer Company A</BaseLabel>
          <BaseCombobox
            getOptionValue={option => `${option.id}`}
            getOptionLabel={option =>
              option.name != null ? `${option.name}` : null
            }
            options={peerOptions}
            value={selectedPeers['a']}
            onChange={data => handleSelectPeer('a', data)}
          />
        </BaseFormGroup>
        {/* peer company b */}
        <BaseFormGroup controlId='peer_company_b_select'>
          <BaseLabel>Peer Company B</BaseLabel>
          <BaseCombobox
            getOptionValue={option => `${option.id}`}
            getOptionLabel={option =>
              option.name != null ? `${option.name}` : null
            }
            options={peerOptions}
            value={selectedPeers['b']}
            onChange={data => handleSelectPeer('b', data)}
          />
        </BaseFormGroup>
        {/* peer company c */}
        <BaseFormGroup controlId='peer_company_c_select'>
          <BaseLabel>Peer Company C</BaseLabel>
          <BaseCombobox
            getOptionValue={option => `${option.id}`}
            getOptionLabel={option =>
              option.name != null ? `${option.name}` : null
            }
            options={peerOptions}
            value={selectedPeers['c']}
            onChange={data => handleSelectPeer('c', data)}
          />
        </BaseFormGroup>
        {/* peer company d */}
        <BaseFormGroup controlId='peer_company_d_select'>
          <BaseLabel>Peer Company D</BaseLabel>
          <BaseCombobox
            getOptionValue={option => `${option.id}`}
            getOptionLabel={option =>
              option.name != null ? `${option.name}` : null
            }
            options={peerOptions}
            value={selectedPeers['d']}
            onChange={data => handleSelectPeer('d', data)}
          />
        </BaseFormGroup>
      </section>
    </StyledCard>
  )
}
