import React, { useState, useEffect } from 'react';

const useResize = (id) => {
    const [height, setHeight] = useState(500);    
    const [width, setWidth] = useState(1100);

    const onResize = (chartId) => {
        const chartEl = document.getElementById(chartId);
    
        if(!chartEl) {
          console.error(`Chart with id '${chartId}' is null`);
          return;
        }
        
        setWidth(chartEl.clientWidth);
        setHeight(chartEl.clientHeight);
    }

    useEffect(() => {
        onResize(id);
    })

    useEffect(() => {
        window.addEventListener("resize", () => onResize(id));
        return () => window.removeEventListener('resize', onResize(id));
    });

    return {height, width};
}

export default useResize;