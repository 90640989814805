import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { getManagerDBFunds } from '../../../repositories/managers_repository'
import { NavbarLinks } from './NavbarLinks'

const StyledNav = styled.nav`
  margin: 0;
  padding: 0 1.25rem;
  width: 100vw;
  height: 40px;
  position: fixed;
  top: 60px;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  border-bottom: 1px solid #aaaaaa;
  background-color: white;
`

export const Navbar = ({ impactBenchmarks, organization, portfolios }) => {
  const [activeLink, setActiveLink] = useState('')
  const determineActivePage = () => {
    const url = window.location.href
    if (/app\/?$/.test(url)) setActiveLink('dashboard')
    if (/app\/portfolios/.test(url)) setActiveLink('portfolios')
    if (/app\/research_center/.test(url)) setActiveLink('research')
    if (/app\/sdgs/.test(url)) setActiveLink('research')
    if (/app\/sectors/.test(url)) setActiveLink('sectors')
    if (/app\/instruments/.test(url)) testPageForActiveLink('instruments')
    if (/app\/funds/.test(url)) setActiveLink('funds')
    if (/app\/managers/.test(url)) setActiveLink('managerDb')
  }
  const testPageForActiveLink = type => {
    let instrumentId
    switch (type) {
      case 'instruments':
        instrumentId = Number(window.location.href.match(/\d+(?=\D*$)/)[0])
        if (impactBenchmarks.some(el => el.targetId === instrumentId))
          setActiveLink('benchmarks')
        break
      default:
        setActiveLink('')
    }
  }

  const [managerDbData, setManagerDbData] = useState(null)
  const loadManagerDbData = async () => {
    try {
      const res = await getManagerDBFunds()
      if (res.request.status === 200)
        setManagerDbData(linkGenerator('managerDb', res.data.slice(0, 10)))
    } catch (err) {
      console.error(err)
    }
  }
  useEffect(() => {
    determineActivePage()
    if (organization.can_access_manager_db && managerDbData == null)
      loadManagerDbData()
  }, [])

  const linkGenerator = (type, arr) => {
    let linkBase
    let target = 'id'
    switch (type) {
      case 'benchmarks':
        linkBase = `/app/instruments/`
        target = 'targetId'
        break
      case 'funds':
        linkBase = `/app/funds/`
        break
      case 'managerDb':
        linkBase = '/app/managers/'
        break
      case 'portfolios':
        linkBase = `/app/portfolios/`
        break
      default:
        linkBase = '/app/'
    }
    arr.map(el => (el.href = linkBase + el[target]))
    return arr
  }
  // base navbar links & dropdown triggers
  const links = [
    {
      title: 'Dashboard',
      href: '/app',
      active: activeLink === 'dashboard',
      primaryLinks: null,
      secondaryLinks: null,
    },
    {
      title: 'Portfolios',
      href: null,
      active: activeLink === 'portfolios',
      primaryLinks: linkGenerator('portfolios', portfolios.slice(0, 10)),
      secondaryLinks: [
        {
          name: 'Upload new portfolio',
          href: '/app/portfolios/snapshot_uploads/new',
        },
        { name: 'View all portfolios', href: '/app/portfolios' },
      ],
    },
    {
      title: 'Research Center',
      href: '/app/research_center',
      active: activeLink === 'research',
      secondaryLinks: null,
    },
    {
      title: 'Benchmarks',
      href: null,
      active: activeLink === 'benchmarks',
      primaryLinks: linkGenerator('benchmarks', impactBenchmarks),
    },
  ]
  const sectorsLinkObj = {
    title: 'Sectors',
    href: '/app/sectors',
    active: activeLink === 'sectors',
    primaryLinks: null,
    secondaryLinks: null,
  }
  const managerDbLinkObj = {
    title: 'Managers',
    href: null,
    active: activeLink === 'managerDb',
    primaryLinks: managerDbData,
    secondaryLinks: [{ name: 'Managers Dashboard', href: '/app/managers' }],
  }
  organization.can_access_sector_pages && links.splice(2, 0, sectorsLinkObj)
  organization.can_access_manager_db && links.push(managerDbLinkObj)

  return (
    <StyledNav data-cy='navbar'>
      <NavbarLinks links={links} />
    </StyledNav>
  )
}
