import React from 'react';
import { LegendItemType } from './LegendItems.constants';
import { BigLabel, Dot, DotLabel, DottedLabelContainer, MapDot } from './LegendItem.styles';

const LegendItem = ({
  label,
  labelFontSize,
  labelFontWeight,
  labelColor,
  dotSize,
  dotColor,
  type = LegendItemType.DEFAULT,
  displayDot = true }) => {

  switch (type) {

    /* Big Label */
    case LegendItemType.BIG_LABEL: {
      return (
        <BigLabel
          data-cy={'legend_item_big_label'}
          color={labelColor}
          fontSize={labelFontSize}
          fontWeight={labelFontWeight}
        >
          {label}
        </BigLabel>
      );
    }

    /* Map Label */
    case LegendItemType.MAP_LABEL: {
      return (
          <DottedLabelContainer data-cy={'legend_item_map_label'}>
          {displayDot && (
            <MapDot data-cy={'legend_item_map_dot'} color={dotColor} size={dotSize} />
          )}
          <DotLabel
            data-cy={'dot_label'}
            color={labelColor}
            fontSize={labelFontSize}
            fontWeight={labelFontWeight}
          >
            {label}
          </DotLabel>
        </DottedLabelContainer>
      );
    }

    default:
    /* Dotted Label */
    case LegendItemType.DOTTED_LABEL: {
      return (
        <DottedLabelContainer data-cy={'legend_item_dotted_label'}>
          {displayDot && (
            <Dot color={dotColor} size={dotSize} />
          )}
          <DotLabel
            data-cy={'dot_label'}
            color={labelColor}
            fontSize={labelFontSize}
            fontWeight={labelFontWeight}
          >
            {label}
          </DotLabel>
        </DottedLabelContainer>
      );
    }
  }
}

export default LegendItem;