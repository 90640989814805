/**
 * Methos used to check if a string is a valid `http` OR `https` URL
 *
 * @param {string} string The string that might contain an valid `http` OR `https` URL
 * @returns true If the string is a valid `http` OR `https` URL
 */
export const isValidHttpUrl = string => {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}
