import React from 'react'
import styled from 'styled-components'

import Link from '../../../../../components/styleguide/Link'
import {
  APP_COLOR_PRIMARY,
  BORDER_COLOR_PRIMARY,
  FONT_COLOR_PRIMARY,
} from '../../../../../styles/colors'

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @keyframes pulse-back {
    0% {
      transform: scale(1);
    }

    70% {
      transform: scale(1.4);
    }

    100% {
      transform: scale(1.2);
    }
  }
`

const StyledIcon = styled.i`
  margin-right: 20px;
  font-size: 30px;
  color: ${props =>
    props.isFavorite ? APP_COLOR_PRIMARY : BORDER_COLOR_PRIMARY};
  cursor: pointer;
  transform: scale(1);
  transition: color 1s linear;

  :hover {
    animation: pulse-back 0.2s forwards;
  }
`

const FavoriteTableCell = ({ portfolio, isFavorite, onFavoriteClick }) => {
  if (!portfolio) return

  return (
    <div>
      <StyledContainer key={portfolio.id}>
        <StyledIcon
          isFavorite={isFavorite}
          onClick={() => onFavoriteClick?.(portfolio.id, !isFavorite)}
          className={`${isFavorite ? 'fas' : 'far'} fa-star`}
        />
        <Link
          styles={{ color: FONT_COLOR_PRIMARY, verticalAlign: 'center' }}
          href={`/app/portfolios/${portfolio.id}/snapshots/${portfolio.snapshot_id}`}
        >
          {portfolio.name}
        </Link>
      </StyledContainer>
    </div>
  )
}

export default FavoriteTableCell
