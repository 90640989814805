import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledMoreButton = styled.button`
  position: ${props => (props.isOpen ? 'relative' : 'absolute')};
  width: 100%;
  top: ${props => (props.isOpen ? 'auto' : '170px')};
  right: 0;
  left: 0;
  height: 50px;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  outline: none;
  color: #666666;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #f1f1f1;
  transition: color 0.15s ease-in-out;

  background: linear-gradient(
    180deg,
    rgba(241, 241, 241, 0) 0%,
    rgba(241, 241, 241, 0.7) 39.06%,
    #f1f1f1 100%
  );
  backdrop-filter: blur(2px);

  svg {
    margin: ${props => (props.isOpen ? '-16px 20px 0' : '10px 20px 0')};
    transform: ${props => (props.isOpen ? 'none' : 'scaleY(-1)')};
  }

  &:hover {
    color: #515151;
  }
`

export const MoreButton = ({ isOpen, onClick }) => {
  return (
    <StyledMoreButton isOpen={isOpen} onClick={e => onClick(e)}>
      <div className='blur'></div>
      <svg
        width='12'
        height='15'
        viewBox='0 0 12 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M1.19527 4.02539L4.89092 0.275391C5.06993 0.0908203 5.30668 0 5.56942 0C5.83216 0 6.04235 0.0915527 6.2228 0.274658L9.89247 4.02466C10.2534 4.39087 10.2534 4.98413 9.89247 5.35034C9.53156 5.71655 8.9469 5.71655 8.586 5.35034L6.46735 3.19922L6.46735 12.6562C6.46735 12.9144 6.67523 13.125 6.92931 13.125H10.163C10.6732 13.125 11.0869 13.5442 11.0869 14.0625C11.0869 14.5808 10.6732 15 10.163 15H6.92931C5.65604 15 4.61952 13.9488 4.61952 12.6562L4.61952 3.19922L2.50116 5.34902C2.14026 5.71523 1.5556 5.71523 1.19469 5.34902C0.833789 4.98281 0.834366 4.3916 1.19527 4.02539Z'
          fill='currentColor'
        />
      </svg>
      {isOpen ? 'Click to Collapse' : 'Click to Expand'}
      <svg
        width='11'
        height='15'
        viewBox='0 0 11 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9.81406 4.02539L6.1474 0.275391C5.96979 0.0908203 5.7349 0 5.47422 0C5.21354 0 5.005 0.0915527 4.82596 0.274658L1.18508 4.02466C0.827005 4.39087 0.827005 4.98413 1.18508 5.35034C1.54315 5.71655 2.12323 5.71655 2.4813 5.35034L4.58333 3.19922L4.58333 12.6562C4.58333 12.9144 4.37708 13.125 4.125 13.125H0.916667C0.410495 13.125 0 13.5442 0 14.0625C0 14.5808 0.410495 15 0.916667 15H4.125C5.38828 15 6.41667 13.9488 6.41667 12.6562L6.41667 3.19922L8.51841 5.34902C8.87648 5.71523 9.45656 5.71523 9.81464 5.34902C10.1727 4.98281 10.1721 4.3916 9.81406 4.02539Z'
          fill='currentColor'
        />
      </svg>
    </StyledMoreButton>
  )
}

MoreButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}
