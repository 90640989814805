export const holdingManageButtons = [
    "Basic Information",
    "Key People",
    // "Sharing & Securty",
];

export const monitoringAndEngagementHeaders = [
    " ",
    "Target Value:",
    "Current Value:",
    "Historical Data:"
]

export const customFormHeadersTitelized = {
    "custom": "Company Metrics",
    "default": "Default Fund Metrics",
    "un_sdg": "UN SDG Metrics"
}

export const successfulMetricTargetAddMessage = "You have successfully added a new metric target";

export const problemAddingMetricTarget =  "There was a problem adding a new metric target";

export const successfulMetricValueAddMessage = "You have successfully added a new metric value";

export const problemAddingMetricValue =  "There was a problem adding a new metric value";

export const successfulMetricAddMessage = "You have successfully added a new metric";

export const problemAddingMetric =  "There was a problem adding a new metric";

export const successfulNoteAddMessage = "You have successfully added a new note";

export const problemAddingNote = "There was a problem adding a new note";

export const eventsPerPage = 10;
