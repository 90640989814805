import { TDisclosureFormsState } from '../DisclosureFormsStore'
import { DisclosureFormsActions } from '../actions/DisclosureFormsActions'

const DisclosureFormsReducer = (state: TDisclosureFormsState, action) => {
  switch (action.type) {
    case DisclosureFormsActions.SET_TRIGGERS:
      return { ...state, triggers: action.payload }

    case DisclosureFormsActions.SET_ANSWERS:
      return { ...state, answers: action.payload }

    case DisclosureFormsActions.ADD_LOCAL_ANSWER:
      return {
        ...state,
        localAnswers: { ...state.localAnswers, ...action.payload },
      }

    case DisclosureFormsActions.SET_LOCAL_ANSWER:
      return {
        ...state,
        localAnswers: action.payload,
      }

    default:
      return state
  }
}

export default DisclosureFormsReducer
