import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React from 'react'

import { UnitHelper } from './UnitHelper'

dayjs().format()

export const LastReportedValueCell = ({ subMetrics }) => {
  // show single dash when no estimated_last_reported_value(s) present
  const arr = subMetrics
    .map(el => el.estimated_last_reported_value)
    .filter(val => val != null)
  const showDash = arr.length === 0

  return subMetrics.length ? (
    <span>
      {showDash ? (
        <span>—</span>
      ) : (
        subMetrics
          .map(({ estimated_last_reported_value, id, target_value, unit }, i) =>
            estimated_last_reported_value != null ? (
              <span key={id} className='cell-list-span'>
                ({i + 1}) <UnitHelper measure={target_value} unit={unit} />
              </span>
            ) : (
              <span key={id} className='cell-list-span'>
                <span>—</span>
              </span>
            )
          )
          .filter(val => val != null)
      )}
    </span>
  ) : (
    <span>—</span>
  )
}

LastReportedValueCell.propTypes = {
  subMetrics: PropTypes.array.isRequired,
}
