import PropTypes from 'prop-types'
import qs from 'query-string'
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Nav } from 'react-bootstrap'
import styled from 'styled-components'

import { getEntityById } from '../../../repositories/entities_repository'
import {
  getSasbReportByEntityId,
  updateEnableMetricStatus,
} from '../../../repositories/sasb_metric_tracking_repository'
import { StyledTabNav } from '../../../yb_components/styleguide/navs/tab_nav/TabNav'
import { SasbDataGrid } from './components/data_grid/SasbDataGrid'
import { StyledSasbCard } from './components/sasb_card/SasbCard'

const StyledHeader = styled.header`
  margin: 1.25rem 0 0;

  .breadcrumb {
    margin: 0 0 1.25rem 0;
    padding-left: 1.25rem;
  }
  a {
    color: #002fa7;
  }
  h1 {
    margin: 0 0 1rem 0;
    font-weight: 700;
    font-size: 30px;
    line-height: 1.1;
  }
`

const EntitySasbPage = ({ entityId }) => {
  const [entity, setEntity] = useState(null)
  // request entity info
  useEffect(() => {
    const loadEntityById = async () => {
      const res = await getEntityById(entityId)
      setEntity(res)
    }
    loadEntityById()
  }, [])

  const portfolioName = qs.parse(window.location.search).portfolioName
  const portfolioId = qs.parse(window.location.search).portfolioId

  const [gridsData, setGridsData] = useState([])
  // request entity sasb info
  useEffect(() => {
    const loadReportData = async () => {
      // get grid(s) data
      const res = await getSasbReportByEntityId(entityId)
      const grids = res.dimensions
      // create structured grid(s) data
      grids.map(el => {
        el.grid_rows = el.general_issue_categories.flatMap(cat => cat)
      })
      setGridsData(grids)
    }
    loadReportData()
  }, [])

  // update metric enabled status
  const updateEnableMetric = async (metricId, initialValue) => {
    const metricRes = await updateEnableMetricStatus(metricId, initialValue)
    setGridsData(
      gridsData.map(el => ({
        ...el,
        grid_rows: el.grid_rows.map(obj =>
          obj.id === metricRes.id ? { ...obj, enabled: metricRes.enabled } : obj
        ),
      }))
    )
  }

  return (
    <div className='page__container'>
      <StyledHeader>
        <Breadcrumb>
          <Breadcrumb.Item href='/app'>Home</Breadcrumb.Item>
          {portfolioId && portfolioName && (
            <>
              <Breadcrumb.Item href='/app/portfolios'>
                Portfolios
              </Breadcrumb.Item>
              <Breadcrumb.Item href={'/app/portfolios/' + portfolioId}>
                {portfolioName}
              </Breadcrumb.Item>
            </>
          )}
          {entity && <Breadcrumb.Item active>{entity.name}</Breadcrumb.Item>}
          <Breadcrumb.Item active>Risk Assessments</Breadcrumb.Item>
        </Breadcrumb>
        {entity ? (
          <h1>{entity.name}</h1>
        ) : (
          <div style={{ margin: '0 0 1rem', height: '33px' }}></div>
        )}
      </StyledHeader>
      {/* tab nav */}
      <StyledTabNav style={{ marginBottom: '20px' }}>
        <Nav defaultActiveKey='sasb_materiality' as='ul'>
          <Nav.Item>
            <Nav.Link
              eventKey='esg_factors'
              href={entity ? `/app/entities/${entity.id}/esg` : '#'}
              disabled={!entity}
            >
              ESG Factors
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='sasb_materiality'>SASB Materiality</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='pai' disabled>
              PAI
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='board_vote_database' disabled>
              Board Vote Database
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </StyledTabNav>
      {/* card */}
      <StyledSasbCard className='sasb-grid-card'>
        <header>
          <h3 className='card-heading'>SASB Materiality</h3>
          <p className='card-subheading'>
            Sustainability accounting reflects the management of a corporation’s
            environmental and social impacts arising from production of goods
            and services, as well as its management of the environmental and
            social capitals necessary to create long-term value. It also
            includes the impacts that sustainability challenges have on
            innovation, business models, and corporate governance and vice
            versa.
          </p>
          <img
            className='sasb-logo-right'
            src='/img/logos/sasb_logo.svg'
            alt='SASB logo'
          />
        </header>
        <main>
          {gridsData &&
            gridsData.map(el => (
              <SasbDataGrid
                gridData={el}
                updateEnableMetric={updateEnableMetric}
                key={el.dimension}
              />
            ))}
        </main>
      </StyledSasbCard>
    </div>
  )
}

EntitySasbPage.propTypes = {
  entityId: PropTypes.number.isRequired,
}

export default EntitySasbPage
