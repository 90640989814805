import React from 'react'
import styled from 'styled-components'

import { staticDefaultWeights } from '../CustomESGScoring.constants'
import Pillar from './Pillar'

const parsePillars = value => ({
  e: parseInt(value?.e),
  s: parseInt(value?.s),
  g: parseInt(value?.g),
})
const undefinedOrNull = value => value == null || value == undefined

const BasicView = ({
  dispatch,
  loadedESGPillars,
  action,
  propsDefaultWeights,
}) => {
  const defaultWeights = { ...propsDefaultWeights }

  if (undefinedOrNull(loadedESGPillars?.e))
    loadedESGPillars = parsePillars(defaultWeights)
  else loadedESGPillars = parsePillars(loadedESGPillars)

  const StyledView = styled.div`
    .pillar:first-child {
      margin-top: 20px;
    }
    .pillar {
      margin-top: 50px;
    }

    .weights-info {
      margin-top: 20px;
      color: #979797;
      float: right;
    }
  `

  const handlePillarChange = (value, pillar) => {
    const pillarWeights = { ...loadedESGPillars, [pillar]: Number(value) }

    if (dispatch)
      dispatch({
        type: `${action.type}-basic`,
        payload: {
          ...action?.payload,
          ...defaultWeights,
          ...pillarWeights,
        },
      })
  }

  return (
    <StyledView>
      <Pillar
        label={'Environmental'}
        defaultValue={loadedESGPillars.e}
        onChange={value => handlePillarChange(value, 'e')}
      />
      <Pillar
        label={'Social'}
        defaultValue={loadedESGPillars.s}
        onChange={value => handlePillarChange(value, 's')}
      />
      <Pillar
        label={'Governance'}
        defaultValue={loadedESGPillars.g}
        onChange={value => handlePillarChange(value, 'g')}
      />
      <div className='weights-info'>
        Total E, S, and G weights must equal 100%.
      </div>
    </StyledView>
  )
}

export default BasicView
