import React, { useMemo } from 'react'

import { renderCompanyCarbonReportWidget } from '../../modules/instrument/helpers/carbon_report'
import { TcfdPortfolioScoreCard } from '../../modules/portfolio/tcfd/components/tcfd_portfolio_score/TcfdPortfolioCard'
import { useCarbonAuditTable } from '../../yb_components/cards/carbon_audit_card/hooks/useCarbonAuditTable'
import { TargetType } from '../../yb_constants/enums/global_enums'
import { splitArrayInGroups } from '../../yb_helpers/arrays'
import BadgesPDF from '../components/BadgesPDF'
import SDGExposureCardPDF from '../components/charts/SDGExposureCardPDF'
import Co2ProjectionsCardPdf from '../components/Co2ProjectionsCardPDF'
import ESGScore from '../components/ESGScore'
import Footer from '../components/Footer'
import Header from '../components/Header'
import PeersPDFContainer from '../components/PeersPDFContainer'
import AssessmentMetrics from '../components/sections/AssessmentMetrics'
import AssetAllocation from '../components/sections/AssetAllocation'
import ImpactAssessment from '../components/sections/ImpactAssessment'
import InstrumentDisclosure from '../components/sections/InstrumentDisclosure'
import InstrumentHeading from '../components/sections/InstrumentHeading'
import RevenueSegmentsSectionPDF from '../components/sections/RevenueSegmentsSectionPDF'
import SubsidiesViolationsSectionPDF from '../components/sections/SubsidiesViolationsSectionPDF'
import TVLTargetCardPDF from '../components/sections/TVLTargetCardPDF'
import { PDF } from '../pdf'

/* global react_app_organization */
/*eslint no-undef: "error"*/

const InstrumentTemplate = ({
  instrument: i,
  impactBenchmark,
  badges,
  assets,
  co2Projections,
  version,
  peersTab,
  peers,
  carbonAuditData,
  tcfdScores,
  carbonReportData,
}) => {
  const positive_badges_chunks = useMemo(
    () => splitArrayInGroups(badges?.positive, 6),
    [badges]
  )
  const negative_badges_chunks = useMemo(
    () => splitArrayInGroups(badges?.negative, 6),
    [badges]
  )

  const cachedColors = JSON.parse(
    localStorage.getItem(`carbon_audit_colors_${carbonAuditData?.groupBy}`)
  )

  const { tableHeaders, tableBody, tableFooter } = useCarbonAuditTable({
    selectedChart: carbonAuditData?.groupBy,
    colors: cachedColors,
    data: carbonAuditData,
    targetName: 'Portfolio',
    includeBenchmark: carbonAuditData?.includeBenchmark,
  })

  const canRenderCarbonAudit =
    carbonAuditData?.table?.data?.length && i?.has_holdings

  return (
    <div className='document'>
      <Header />
      <Footer />

      <div className='pdf-content'>
        <InstrumentHeading instrument={i} />

        <div
          style={{ maxHeight: '400px', display: 'flex' }}
          className='portfolio-asset-wrapper'
        >
          <div style={{ width: '50%', marginRight: '15px' }}>
            <p>{i?.description}</p>
            {i?.trbc_business_sector && (
              <p>
                <strong>Sector:</strong> {i?.trbc_business_sector}
              </p>
            )}
            {i?.trbc_industry_group && (
              <p>
                <strong>Industry Group:</strong> {i?.trbc_industry_group}
              </p>
            )}
            {i?.trbc_industry && (
              <p>
                <strong>Industry:</strong> {i?.trbc_industry}
              </p>
            )}
          </div>
          <div style={{ width: '50%' }}>
            {i?.esg_score && (
              <ESGScore
                scores={i?.esg_score}
                customESGLabels={i?.custom_esg_labels}
              />
            )}
          </div>
        </div>
        {react_app_organization.organization.can_access_carbon_report && (
          <div style={{ pageBreakBefore: 'always' }}>
            <TcfdPortfolioScoreCard
              scores={tcfdScores}
              instrument={true}
              pdfStyle={true}
            />
            {react_app_organization.organization.can_access_carbon_report &&
              renderCompanyCarbonReportWidget(carbonReportData, true)}
          </div>
        )}

        <div>
          {!!(assets && assets?.length && assets?.length > 0) && (
            <AssetAllocation showValue={false} assets={assets} />
          )}
        </div>

        {peers?.length > 1 && (
          <>
            <div style={{ pageBreakBefore: 'always' }}></div>
            <PeersPDFContainer tabName={peersTab} peers={peers} />
          </>
        )}

        {i?.tvl_insight_scores && i?.tvl_insight_scores.length > 0 && (
          <>
            <div style={{ pageBreakBefore: 'always' }}></div>
            <TVLTargetCardPDF
              target={i}
              data={i?.tvl_insight_scores}
              benchmarkName={impactBenchmark?.name}
              benchmarkData={impactBenchmark?.tvl_insight_scores}
              description={
                'These ESG-like scores utilize Truvalue Labs long-term momentum scores to \
                measure ESG performance over time. These momentum scores are based on \
                daily tracking scores measuring sentiment analysis for daily news \
                controversies and reports on a company level.'
              }
            />
          </>
        )}

        {/* Positive Impact Badges */}
        {positive_badges_chunks.map((positive_badges, index) => (
          <>
            <div style={{ pageBreakBefore: 'always' }}></div>
            <BadgesPDF
              key={index}
              title={'POSITIVE IMPACT BADGES'}
              badges={positive_badges}
              holdingsTotal={i?.holdings_total}
            />
          </>
        ))}

        {/* Negative Impact Badges */}
        {negative_badges_chunks.map((negative_badges, index) => (
          <>
            <div style={{ pageBreakBefore: 'always' }}></div>
            <BadgesPDF
              key={index}
              title={'NEGATIVE IMPACT BADGES'}
              badges={negative_badges}
              holdingsTotal={i?.holdings_total}
            />
          </>
        ))}

        <div>
          {canRenderCarbonAudit && (
            <>
              <div style={{ pageBreakBefore: 'always' }}></div>
              <h2>Carbon Audit</h2>
              <PDF.CarbonAudit
                targetName={'Instrument'}
                colors={cachedColors}
                chartData={carbonAuditData?.chartFormat}
                tableData={{
                  headers: tableHeaders,
                  body: tableBody,
                  footer: tableFooter,
                }}
                switchSelections={{
                  chart: carbonAuditData?.groupBy,
                  degree: carbonAuditData?.degree,
                  budget: carbonAuditData?.budget,
                  calculationMethod: carbonAuditData?.calculationMethod,
                }}
              />
            </>
          )}

          {version == 'full' && (
            <>
              <SubsidiesViolationsSectionPDF
                subsidies={i?.subsidies}
                violations={i?.violations}
                targetType='Instrument'
              />
              <RevenueSegmentsSectionPDF segments={i?.revenue_segments} />
            </>
          )}
        </div>

        {version == 'full' && (
          <>
            {i?.sdg_exposures && (
              <SDGExposureCardPDF
                data={i?.sdg_exposures}
                width={60}
                height={200}
                title='SDG EXPOSURES'
              />
            )}
            {i?.impacts && (
              <ImpactAssessment
                targetType={TargetType.INSTRUMENT}
                impacts={i?.impacts}
                name={i?.name}
              />
            )}
            {i?.assessment_metrics && (
              <AssessmentMetrics metrics={i?.assessment_metrics} />
            )}
            {/* {
                hasESGYearlyMetrics(ESGYearlyMetrics) && (
                    <>
                        <ESGMetricsTable isPDF={true} ESGYearlyMetrics={ESGYearlyMetrics} metricType={"e"} />

                        <ESGMetricsTable isPDF={true} ESGYearlyMetrics={ESGYearlyMetrics} metricType={"s"} />

                        <ESGMetricsTable isPDF={true} ESGYearlyMetrics={ESGYearlyMetrics} metricType={"g"} />
                    </>
                )
            } */}

            {co2Projections &&
              co2Projections?.projections &&
              co2Projections?.projections.length > 1 && (
                <>
                  <div style={{ pageBreakBefore: 'always' }}></div>
                  <Co2ProjectionsCardPdf projections={co2Projections} />
                </>
              )}
          </>
        )}

        <div style={{ pageBreakBefore: 'always' }}></div>
        <InstrumentDisclosure />
      </div>
    </div>
  )
}

export default InstrumentTemplate
