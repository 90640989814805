import React from 'react'

import {
  comparisonsTableColumnCount,
  emptyScorePlaceholder,
} from '../../../constants'
import { computeESGColor } from '../../../helpers'
import { StyledCell } from './ScoreTableRow.styles'

const ScoreTableRow = ({
  portfolios,
  scoreName,
  scoreDescription,
  retrieveScore = () => {},
  computeColor = computeESGColor,
  displayEmptyColumns,
}) => {
  const tableColumnCount = displayEmptyColumns
    ? comparisonsTableColumnCount
    : portfolios.length
  return (
    <tr>
      <td className='name-column'>
        {scoreName}
        {scoreDescription && (
          <div className='name-column-description'>{scoreDescription}</div>
        )}
      </td>
      {[...Array(tableColumnCount).keys()].map(i => {
        const score = retrieveScore(portfolios[i])

        return (
          <StyledCell key={`table-cell-${i}`} color={computeColor(score)}>
            {score}
          </StyledCell>
        )
      })}
    </tr>
  )
}

export default ScoreTableRow
