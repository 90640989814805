// LIBRARIES
import React, { useState } from 'react'
// BOOSTRAP COMPONENTS
import { Modal } from 'react-bootstrap'
import Select from 'react-select-3'

import Button from '../../../../../components/styleguide/Button'
// STYLEGUIDE COMPONENTS
import InputLabel from '../../../../../components/styleguide/InputLabel'
import SectionWrapper from '../../../../../components/styleguide/SectionWrapper'
import TextInput from '../../../../../components/styleguide/TextInput'
// CONSTANTS
import {
  defaultPipelineState,
  pipelineStateOptions,
} from '../../../../../constants/funds'
//REPOSITORY CALLS
import { updateFundHoldingPipeline } from '../../../../../repositories/funds_repository'
// Models
import { Holding } from '../../../models/Holding'
// STYLING
import { AddPipelineDialog } from '../../../styles'

const HoldingPipelineEditModal = ({
  fundId,
  show,
  onHide,
  onSubmit,
  holding,
}) => {
  const [pipelineState, setPipelineState] = useState(holding.pipeline_state)
  const oldPipelineState = holding.pipeline_state
  const oldHolding = Object.assign({}, holding)

  const handleSubmit = () => {
    const newHolding = oldHolding
    newHolding.pipeline_state = pipelineState
    const holdingId = oldHolding.id
    const new_pipeline_state = pipelineState
    updateFundHoldingPipeline({ fundId, holdingId, new_pipeline_state })
      // eslint-disable-next-line no-unused-vars
      .then(response => {
        onSubmit(new Holding(newHolding), 'edit')
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getPipeline = state => {
    return pipelineStateOptions.find(el => el.value === state)
  }

  return (
    <AddPipelineDialog show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Move Holding In Pipeline</Modal.Title>
      </Modal.Header>
      <SectionWrapper align='center' margin='5px 0px 5px 10px' width='100%'>
        <SectionWrapper margin='14.25px 0px 0px 20px' width='90%'>
          <TextInput
            label='Holding Name'
            width='100%'
            id='holding-name'
            value={holding.name}
            disabled={true}
            // onChange={(id, value, e) => { setName(value) }}
          />
        </SectionWrapper>
        <SectionWrapper
          align='center'
          margin='14.25px 0px 0px 20px'
          width='90%'
        >
          <InputLabel label='Current State' />
          <Select
            styles={{
              container: provided => ({
                ...provided,
                width: '100%',
                marginBottom: '16.5px',
              }),
              input: provided => ({
                ...provided,
                paddingTop: '8px',
                paddingBottom: '4.75px',
              }),
            }}
            isDisabled={true}
            value={getPipeline(oldPipelineState)}
            defaultValue={getPipeline(oldPipelineState)}
          />
        </SectionWrapper>
        <SectionWrapper
          align='center'
          margin='14.25px 0px 0px 20px'
          width='90%'
        >
          <InputLabel label='New State' />
          <Select
            styles={{
              container: provided => ({
                ...provided,
                width: '100%',
                marginBottom: '16.5px',
              }),
              input: provided => ({
                ...provided,
                paddingTop: '8px',
                paddingBottom: '4.75px',
              }),
            }}
            onChange={input => {
              setPipelineState(input.value)
            }}
            options={pipelineStateOptions}
            value={getPipeline(pipelineState)}
            defaultValue={defaultPipelineState}
          />
        </SectionWrapper>
      </SectionWrapper>
      <SectionWrapper justify='space-between' margin='25px 30px 0px 30px'>
        <Button width='20%' label='Cancel' onClick={onHide} />
        <Button
          width='30%'
          label='Save Prospect'
          onClick={holding => handleSubmit(holding)}
        />
      </SectionWrapper>
    </AddPipelineDialog>
  )
}

export default HoldingPipelineEditModal
