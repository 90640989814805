import React, { memo, useState } from 'react'
import { Col } from 'react-bootstrap'
import Select from 'react-select-3'
import styled from 'styled-components'

import Button from '../../../components/styleguide/Button'
import { defaultStyles } from './Select.styles'

const StyledContainer = styled.div`
  height: auto;
  background-color: #f7f7f7;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${props => props.marginBottom || '0px'};
`

const StyledLabel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #7a7a7a;
  font-size: 12px;
  font-weight: bold;
`

const StyledInlineDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ClearFilterButtonLabel = styled.div`
  margin-left: 5px;
`

const StyledSelectCol = styled(Col)`
  position: static;
`

const FiltersBar = ({
  label = 'FILTER:',
  hideResetButton,
  columns,
  onChange,
  onClearAll,
  marginBottom,
  loading,
  'data-cy': dataCy,
}) => {
  /* Hooks */
  const [filter, setFilter] = useState({})

  if (!columns) return null

  /* Events */
  const onChangeOption = (value, column) => {
    filter[column.accessor] = value

    setFilter(filter)
    if (onChange)
      onChange({
        accessor: column.accessor,
        properties: value,
        isMulti: column.filter.isMulti,
      })
  }

  const onClearFilters = () => {
    setFilter({})
    if (onClearAll) onClearAll()
  }

  /* Render */
  return (
    <StyledContainer marginBottom={marginBottom} data-cy={dataCy}>
      <Col md={'auto'}>
        <StyledLabel>{label}</StyledLabel>
      </Col>
      <StyledSelectCol>
        <StyledInlineDiv data-cy={'all_filter_select_container'}>
          {columns.map(column => {
            if (!column?.filter?.selectOptions) return null

            let valueIsPresent = filter[column.accessor]
            if (column.filter.isMulti)
              valueIsPresent = filter[column.accessor]?.length > 0

            return (
              <Select
                data-cy={`filter_select_${column.accessor}`}
                key={column.accessor}
                placeholder={column.filter.placeholder}
                isMulti={column.filter.isMulti}
                menuShouldScrollIntoView={false}
                styles={defaultStyles(valueIsPresent ? true : false)}
                options={column.filter.selectOptions}
                isSearchable={false}
                value={valueIsPresent ? filter[column.accessor] : null}
                onChange={value => onChangeOption(value, column)}
              />
            )
          })}
        </StyledInlineDiv>
      </StyledSelectCol>

      {!hideResetButton && (
        <Col md={2}>
          <Button
            disabled={loading}
            data-cy='filter_bar_reset_button'
            secondary
            onClick={onClearFilters}
            label={
              <StyledInlineDiv>
                <i className='fas fa-times'></i>
                <ClearFilterButtonLabel>Clear filters</ClearFilterButtonLabel>
              </StyledInlineDiv>
            }
            outline
          />
        </Col>
      )}
    </StyledContainer>
  )
}

export default memo(FiltersBar)
