import React from 'react';
import Card from '../Card';
import HorizontalProgress from '../HorizontalProgress';

const ESG_SCALE_COLORS = [
  { color: '#E74C4C', label: 'Bad', min: 0, max: 42 },
  { color: '#E7924C', label: 'Poor', min: 42, max: 46 },
  { color: '#E7BB4B', label: 'Below Average', min: 46, max: 50 },
  { color: '#EAD836', label: 'Above Average', min: 50, max: 54 },
  { color: '#BED600', label: 'Good', min: 54, max: 58 },
  { color: '#7BD500', label: 'Superior', min: 58, max: 62 },
  { color: '#45D56E', label: 'Excellent', min: 62, max: 100 },
];

const AssessmentMetrics = ({ metrics }) => {
  if(!metrics) return null;

  return (
    <section>
      <h2>Assessment Metrics</h2>

      <table>
        <thead>
          <tr>
            <th>WEIGHT</th>
            <th>METRIC</th>
            <th>MEASURE</th>
            <th>INSTRUMENT VALUE</th>
            <th>SECTOR MEDIAN</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className="table-section-title" colSpan="5">ENVIRONMENTAL</td>
          </tr>
          {metrics.env.map(m => (
            <tr key={m.metric}>
              <td>{m.weight}%</td>
              <td>{m.metric}</td>
              <td><HorizontalProgress height={8} width={120} progress={m.measure}/></td>
              <td>{m.instrumentValue}</td>
              <td>{m.sectorMedian}</td>
            </tr>
          ))}
          <tr>
            <td className="table-section-title" colSpan="5">SOCIAL</td>
          </tr>
          {metrics.soc.map(m => (
            <tr key={m.metric}>
              <td>{m.weight}%</td>
              <td>{m.metric}</td>
              <td><HorizontalProgress height={8} width={120} progress={m.measure}/></td>
              <td>{m.instrumentValue}</td>
              <td>{m.sectorMedian}</td>
            </tr>
          ))}
          <tr>
            <td className="table-section-title" colSpan="5">GOVERNANCE</td>
          </tr>
          {metrics.gov.map(m => (
            <tr key={m.metric}>
              <td>{m.weight}%</td>
              <td>{m.metric}</td>
              <td><HorizontalProgress height={8} width={120} progress={m.measure}/></td>
              <td>{m.instrumentValue}</td>
              <td>{m.sectorMedian}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

export default AssessmentMetrics;