import dayjs from 'dayjs'
import React from 'react'

const Header = ({ date }) => {
  let localDate = date
  if (localDate === undefined) {
    localDate = dayjs()
  }

  return (
    <div className='header-container'>
      <div className='header'>
        <img className='page-header-logo' src='/ico_logo_white.svg' />
        <span className='page-header-date'>
          {localDate.format('MMM D, YYYY')}
        </span>
      </div>
    </div>
  )
}

export default Header
