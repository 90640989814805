import React, { useMemo, memo } from 'react';
import { FONT_COLOR_PRIMARY } from '../../../styles/colors';
import Card from '../../../components/styleguide/Card';
import CardTitle from '../../../components/styleguide/CardTitle';
import TextSection from '../../../components/styleguide/TextSection';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';

import RecordsTable from '../../tables/records_table/RecordsTable';

const StyledContainer = styled.div`
  max-width: 1300px;
  margin-bottom: ${props => props.marginBottom || '0px'};
`;

const StyledHeaderContainer = styled(Row)`
  margin-bottom: 30px;
`;

const RecordsCard = ({
  title,
  description,
  data,
  columns,
  marginBottom,

  defaultColumn,
  loading,
  maxPage,
  fetchData,
  "data-cy": dataCy
}) => {

  return (
    <StyledContainer data-cy={dataCy} marginBottom={marginBottom}>
      <Card>

        {/* Title */}
        <CardTitle title={title} fontSize="14px" textTransform='uppercase' data-cy='records_card_title' />

        <StyledHeaderContainer data-cy='records_card_description'>
          {/* Description */}
          <Col md={8}>
            <TextSection fontSize={'14px'} color={FONT_COLOR_PRIMARY}>{description}</TextSection>
          </Col>
        </StyledHeaderContainer>

        <RecordsTable
          data-cy="records_table"
          data={data}
          columns={columns}

          defaultColumn={defaultColumn}
          loading={loading}
          maxPage={maxPage}
          fetchData={fetchData}
        />

      </Card>
    </StyledContainer>
  );
}

export default memo(RecordsCard);
