import React, { useRef } from 'react'
import { Row } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import styled from 'styled-components'

import { BG_COLOR_WHITE } from '../../styles/colors'
import ScoresStackedGraph from '../../yb_components/charts/scores_stacked_graph/ScoresStackedGraph'
import NewsEventsList from '../../yb_components/lists/news-events-list/NewsEventsList'
import TrendList from '../../yb_components/lists/trend-list/TrendList'
import { MAX_WEEKS_IN_A_YEAR } from '../../yb_constants/time'
import useContainerRefSize from '../../yb_hooks/useContainerRefSize'
import { ESGChartLabels } from '../charts/constants/ChartLabels'
import CircleChartKeyPDF from '../sections/CircleChartKey'
import CardTitle from '../styleguide/CardTitle'
import {
  KeyCircle,
  KeyMarkerWrapperSolo,
} from '../styleguide/ESGChartKey/styles'
import SectionWrapper from '../styleguide/SectionWrapper'
import TextView from '../styleguide/TextView'
import WrapperColumn from '../styleguide/WrapperColumn'
import WrapperRow from '../styleguide/WrapperRow'

const StyledTrendColumn = styled(Col)`
  margin-right: 0px;
`

const StyledTitleRow = styled(Row)`
  margin-bottom: 10px;
`

const StyledInfoCol = styled(Col)`
  justify-content: flex-end;
  display: flex;
`

const StyledInfoContainer = styled.a`
  text-decoration: none !important;
  width: 16px;
  height: 16px;
  background-color: #d8d8d8;
  border-radius: 100px;
  border: none;
  cursor: pointer;

  align-items: center;
  justify-content: center;
  display: flex;
`

const StyledInfoIcon = styled.i`
  color: white;
  font-size: 10px;
`

const TVLTargetCard = ({
  target,
  data = [],
  benchmarkName = '',
  benchmarkData = [],
  description,
}) => {
  const containerRef = useRef(null)
  const [containerWidth] = useContainerRefSize(containerRef)

  const wrapperRowDefaultStyle = {
    align: 'flex-start',
    justify: 'flex-start',
    width: '100%',
    margin: '0 0 20px 0',
  }

  return (
    <Col xs={12} ref={containerRef}>
      <WrapperColumn
        styles={{
          bgColor: BG_COLOR_WHITE,
          margin: '0px 0px 0px 0px',
          borderRadius: '6px',
          border: '1px solid rgba(0,0,0,0.05)',
          boxShadow: '0 2px 9px 0 rgba(0,0,0,0.05)',
          padding: '20px',
        }}
      >
        <WrapperColumn>
          <StyledTitleRow>
            <Col>
              <CardTitle
                title='NEWS SENTIMENT-BASED REPUTATION RISK'
                className='rep-risk-portfolio-title'
              />
            </Col>
            <StyledInfoCol>
              <StyledInfoContainer
                href={
                  'http://help.yves.blue/en/articles/6051026-news-controversies-from-truevalue-labs-tvl'
                }
                target='_blank'
              >
                <StyledInfoIcon className='fa fa-question' />
              </StyledInfoContainer>
            </StyledInfoCol>
          </StyledTitleRow>
          <TextView size={15.5} secondary text={description} />
        </WrapperColumn>

        <SectionWrapper justify='space-between' margin='10px 0 0 0'>
          <CircleChartKeyPDF
            labels={ESGChartLabels}
            classes={{
              wrapper: 'ESG-chart-key-wrapper-rep-risk',
              markerWrapper: 'ESG-chart-key-pdf-marker-wrapper-momentum',
            }}
            useKey='RepRiskBarGraphPortfolioCard '
          />

          <KeyMarkerWrapperSolo>
            <KeyCircle color='#E7BB4C'></KeyCircle>
            <div>Benchmark ({benchmarkName})</div>
          </KeyMarkerWrapperSolo>
        </SectionWrapper>

        <WrapperRow
          styles={{
            ...wrapperRowDefaultStyle,
            height: '250px',
          }}
        >
          <ScoresStackedGraph
            data={data.slice(0, MAX_WEEKS_IN_A_YEAR)}
            benchmarkData={benchmarkData.slice(0, MAX_WEEKS_IN_A_YEAR)}
            width={containerWidth - 100}
            height={250}
          />
        </WrapperRow>
        <WrapperRow styles={wrapperRowDefaultStyle}>
          <StyledTrendColumn>
            <TrendList
              title='BEST PERFORMING SASB CATEGORIES (WITH TREND)'
              data={target?.best_sasb_categories}
            />
            <TrendList
              title='WORST PERFORMING SASB CATEGORIES (WITH TREND)'
              data={target?.worst_sasb_categories}
              marginTop='10px'
            />
          </StyledTrendColumn>
          <Col sm={7}>
            <NewsEventsList
              title={'MOST RECENT NEWS EVENTS FROM MATERIAL CATEGORIES'}
              data={target?.tvl_sasb_spotlight_events}
              maxCategoriesNr={5}
            />
          </Col>
        </WrapperRow>
      </WrapperColumn>
    </Col>
  )
}

export default TVLTargetCard
