import React from 'react'
import styled from 'styled-components'

import InputLabel from '../../../../components/styleguide/InputLabel'

const StyledFileInput = styled.div`
  margin-top: 10px;

  input[type='file'] {
    display: block;
  }
`

const FileInput = ({ label, onChange }) => (
  <StyledFileInput>
    <InputLabel label={label} />
    <input type='file' onChange={onChange} />
  </StyledFileInput>
)

export default FileInput
