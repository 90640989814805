import { Bar, ResponsiveBar } from '@nivo/bar'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import ErrorPage from '../../../../components/styleguide/ErrorPage'
import { getSbti } from '../../../../repositories/portfolio_repository'
import BarComponent from '../../../../yb_components/charts/components/bar_component/BarComponent'
import {
  ExplainerLink,
  LinkIcon,
  LinkText,
} from '../../../../yb_components/styleguide/links/Link.styles'
import Pagination from '../../../../yb_components/styleguide/pagination/Pagination'
import SpinnerContainer from '../../../../yb_components/styleguide/spinners/SpinnerContainer'
import BarTooltip from './components/BarTooltip'
import CarbonReportCard from './components/CarbonReportCard'
import CarbonReportLink from './components/CarbonReportLink'
import {
  CARBON_REPORT_TEXTS,
  DEFAULT_SORT,
  sbtiTableHeader,
  SCIENCE_BASED_CATEGORIES,
} from './constants'
import { formatSort, getPdfSortFieldText } from './helpers'
import {
  CarbonReportTable,
  COMMOM_BAR_PROPS,
  ModuleDescription,
  renderTh,
  StyledExplainerLink,
} from './styles'

const StyledCarbonReportCard = styled(CarbonReportCard)`
  grid-column-start: 1;
  grid-column: span 6;
  .explainer-link {
    justify-content: flex-end;
  }
  .table-container {
    width: 100%;
  }
  .content {
    display: flex;
    table {
      margin-bottom: 10px;
      th:last-child {
        text-align: right;
      }
    }
    .bar-container {
      width: 300px;
      margin-right: 20px;
    }
  }
`

const colors = {
  '50%+ Progress': '#00BE62',
  '25 to 50% Progress': '#2FC178',
  '10 to 25% Progress': '#5BB480',
  '0 to 10% Progress': '#85977A',
  'Behind Schedule': '#CD5461',
  'No Committment': '#D02F44',
}

export const loadSbti = ({
  snapshotId,
  portfolioId,
  setData,
  setBarData,
  setError,
  setIsLoading,
  isLoading,
  setTotalPages,
  page,
  sort,
  dispatch,
  category,
}) => {
  if (isLoading) return

  setIsLoading?.(true)
  getSbti({ snapshotId, portfolioId, page, ...sort, category })
    .then(res => {
      setData(res?.data?.data)
      setBarData(res?.data?.total_percentages)
      dispatch({
        type: 'sbti-loaded',
        payload: {
          data: res?.data?.data,
          barData: res?.data?.total_percentages,
          sort: sort,
        },
      })
      setTotalPages(res?.data?.total_pages)
    })
    .catch(err => {
      const error = err?.response ? err?.response?.status : 502
      setError(error)
    })
    .finally(() => {
      setIsLoading?.(false)
    })
}

const CATEGORY_KEYS = [
  '50%+ Progress',
  '25 to 50% Progress',
  '10 to 25% Progress',
  '0 to 10% Progress',
  'Behind Schedule',
  'No Committment',
]

const ScienceBasedTargetsInitiative = ({
  pdf,
  portfolioId,
  snapshotId,
  dispatch,
  defaultData,
  defaultBarData,
  defaultSort,

  title = CARBON_REPORT_TEXTS?.sbti.title,
  description = CARBON_REPORT_TEXTS?.sbti.description,
}) => {
  const [isLoading, setIsLoading] = useState(!pdf)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [error, setError] = useState()
  const [data, setData] = useState(defaultData || [])
  const [barData, setBarData] = useState(defaultBarData || [])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [sort, setSort] = useState(defaultSort || DEFAULT_SORT)
  const [category, setCategory] = useState('all')
  const [activeBarItem, setActiveBarItem] = useState(0)

  useEffect(() => {
    if (defaultData) setData(defaultData)
  }, [defaultData])

  useEffect(() => {
    if (defaultBarData) setBarData(defaultBarData)
  }, [defaultBarData])

  useEffect(
    () =>
      !pdf
        ? loadSbti({
            portfolioId,
            snapshotId,
            page,
            sort,
            dispatch,
            setData,
            setBarData,
            setTotalPages,
            setError,
            setIsLoading,
            category,
          })
        : () => {},
    [page, sort]
  )

  const selectedCategory = useMemo(() => {
    return SCIENCE_BASED_CATEGORIES.find(cat => category === cat.id) || 'all'
  }, [category])

  const tableHeader = sbtiTableHeader(pdf)
  if (category !== 'all') {
    tableHeader[0].text = `Companies with ${selectedCategory.label}`
  } else {
    tableHeader[0].text = 'COMPANY'
  }

  const handleCategoryChange = e => {
    const defaultPage = 1
    setPage(defaultPage)

    const localSelectedCategory =
      SCIENCE_BASED_CATEGORIES.find(cat => cat.label === e.id) || 'all'

    if (e === 'all' || localSelectedCategory?.id === category) {
      handleActiveBarItem('all')
      setCategory('all')
      loadSbti({
        portfolioId,
        snapshotId,
        defaultPage,
        sort,
        dispatch,
        setData,
        setBarData,
        setTotalPages,
        setError,
        setIsLoading: setIsTableLoading,
        isLoading: isTableLoading,
        category: 'all',
      })
    } else {
      handleActiveBarItem(e.id)
      setCategory(localSelectedCategory.id)
      loadSbti({
        portfolioId,
        snapshotId,
        defaultPage,
        sort,
        dispatch,
        setData,
        setBarData,
        setTotalPages,
        setError,
        setIsLoading: setIsTableLoading,
        isLoading: isTableLoading,
        category: localSelectedCategory.id,
      })
    }
  }

  const handleActiveBarItem = selectedCategory => {
    const arrayBarData = Object.keys(barData)
    setActiveBarItem(
      selectedCategory === 'all'
        ? 0
        : arrayBarData.indexOf(selectedCategory) + 1
    )
  }

  let barProps = {
    ...COMMOM_BAR_PROPS,
    data: [barData],
    keys: CATEGORY_KEYS,
    colors: ({ id }) => String(colors[id]),
    onClick: handleCategoryChange,
  }

  const PDF_DIMENSIONS = { height: 450, width: 300 }

  if (pdf) {
    barProps = { ...barProps, ...PDF_DIMENSIONS }
  }

  const formattedPdfSortField = useMemo(
    () => getPdfSortFieldText(sbtiTableHeader(pdf), defaultSort, pdf),
    [defaultSort, pdf]
  )

  return (
    <StyledCarbonReportCard
      activeBarItem={activeBarItem}
      pdf={pdf}
      title={title}
      width={'760px'}
      trHeight={'40px'}
      pdfSubTitle={{
        label: `(Top 10 Companies By ${formattedPdfSortField})`,
        inline: true,
      }}
    >
      {!pdf && (
        <StyledExplainerLink
          pdf={pdf}
          href='http://help.yves.blue/en/articles/6698762-carbon-report-portfolio-summary-on-yvesblue#h_3399b2012e'
          target='_blank'
        >
          <LinkIcon className='fa fa-question' />
        </StyledExplainerLink>
      )}

      <ModuleDescription pdf={pdf} marginBottom={'10px'}>
        {description}
      </ModuleDescription>
      {error ? (
        <ErrorPage code={error} message={error == 404 ? 'NOT FOUND' : ''} />
      ) : (
        <>
          {isLoading && <br />}
          <SpinnerContainer isLoading={isLoading}>
            {!pdf && (
              <ExplainerLink
                fontSize='14px'
                padding='8px 20px'
                margin='0 10px 0 0'
                {...(category === 'all' && { visibility: 'hidden' })}
                onClick={() => handleCategoryChange('all')}
                className='explainer-link'
              >
                <LinkText>(Clear Filter)</LinkText>
              </ExplainerLink>
            )}
            <div className='content'>
              <div className='bar-container'>
                {pdf ? (
                  <Bar {...barProps} />
                ) : (
                  <ResponsiveBar
                    {...barProps}
                    barComponent={props => (
                      <BarComponent
                        {...props}
                        onClick={handleCategoryChange}
                        activeId={selectedCategory?.label}
                        tooltipComponent={data => <BarTooltip data={data} />}
                      />
                    )}
                  />
                )}
              </div>
              <div className='table-container'>
                <SpinnerContainer isLoading={isTableLoading}>
                  <CarbonReportTable pdf={pdf}>
                    <thead>
                      <tr>
                        {tableHeader?.map(elem =>
                          renderTh(
                            elem,
                            sort,
                            (sortField, sortDir) =>
                              setSort(formatSort(sortField, sortDir)),
                            pdf
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map(elem => (
                        <tr key={elem?.id}>
                          <td>
                            <CarbonReportLink
                              pdf={pdf}
                              name={elem?.name}
                              href={`/app/instruments/${elem?.id}#report-summary-card`}
                            />
                          </td>
                          {pdf && <td className='number'>{elem?.weight}%</td>}
                          <td className='number'>{elem?.committment || '—'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </CarbonReportTable>

                  {!pdf && (
                    <Pagination
                      max={totalPages}
                      selected={page}
                      onChange={e => setPage(e)}
                    />
                  )}
                </SpinnerContainer>
              </div>
            </div>
          </SpinnerContainer>
        </>
      )}
      {/* </StyledCard> */}
    </StyledCarbonReportCard>
  )
}

export default ScienceBasedTargetsInitiative
