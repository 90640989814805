import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { FONT_COLOR_SECONDARY } from '../../../../../../../styles/colors'
import { getPaiColor } from '../../../utils/pai_colors'

const StyledDiv = styled.div`
  width: 100px;
  height: 100%;
  min-height: 50px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: ${props => props.bgColor || FONT_COLOR_SECONDARY};
`

export const ColorBlock = ({ number, style }) => {
  return (
    <StyledDiv style={style} bgColor={getPaiColor(number)}>
      {Math.round(number * 100)}%
    </StyledDiv>
  )
}

ColorBlock.propTypes = {
  number: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}
