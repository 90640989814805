import DOMPurify from 'dompurify'
import React from 'react'

const sanitize = (stringTemplate, config) => ({
  __html: DOMPurify.sanitize(stringTemplate, config),
})

const SanitizedHTML = ({ stringTemplate = '', config = {} }) => (
  <div dangerouslySetInnerHTML={sanitize(stringTemplate, config)} />
)

export default SanitizedHTML
