import PropTypes from 'prop-types'
import React from 'react'

import { MainTable } from '../../../../components/tables/styles'
import { esgScoreFullNames } from '../../../../constants'
import { displayValueOrPlaceholder } from '../../../../helpers/shared'
import { peersCustomTableStyle } from './constants'
import PeersHeaderRow from './PeersHeaderRow'
import ScoreTableRow from './ScoreTableRow'

const PeersESGScoreCard = ({ peers, openReplacePeerModal }) => {
  const retrieveScore = metricType => entity => {
    return displayValueOrPlaceholder(
      entity.esg_score[`${metricType}_score`],
      true
    )
  }

  return (
    <MainTable customStyle={peersCustomTableStyle} look={{}}>
      <PeersHeaderRow
        peers={peers}
        openReplacePeerModal={openReplacePeerModal}
      />
      <tbody>
        {['e', 's', 'g', 'total'].map(metricType => (
          <ScoreTableRow
            showTooltip
            key={`table-row-${metricType}`}
            scoreName={esgScoreFullNames[metricType].toUpperCase()}
            retrieveScore={retrieveScore(metricType)}
            peers={peers}
          />
        ))}
      </tbody>
    </MainTable>
  )
}

export default PeersESGScoreCard

PeersESGScoreCard.propTypes = {
  peers: PropTypes.array,
  openReplacePeerModal: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}
