import React from 'react'
import styled from 'styled-components'

import Container from '../../components/styleguide/Container'
import {
  getOrganizationEvents,
  exportOrganizationEvents,
} from '../../repositories/events_repository'
import { mapEventsToState } from '../../mappers/events_mapper'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { FONT_COLOR_PRIMARY } from '../../styles/colors'
import { OrganizationEventActionTypes } from './constants'

import EventLog from './EventLog'
const StyledContainer = styled(Container)`
  padding: 1.25rem;
  position: relative;
  /* & .activities-title {
    font-size: 36px;
    font-weight: bold;
    line-height: 1.3;
    color: ${FONT_COLOR_PRIMARY};
  } */
  & .event-card-header {
    margin: 0 0 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  & .event-card-title {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
    color: ${FONT_COLOR_PRIMARY};
  }
  & .event-download-button {
    margin: 0;
  }
`

const OrganizationEventLog = ({ classes }) => {
  return (
    <StyledContainer>
      <Breadcrumb>
        <Breadcrumb.Item href='/app'>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Organization Event Log</Breadcrumb.Item>
      </Breadcrumb>

      <EventLog
        getEvents={getOrganizationEvents}
        exportEvents={exportOrganizationEvents}
        eventActionTypes={OrganizationEventActionTypes}
        cardTitle={'Organization Event Log'}
      />
    </StyledContainer>
  )
}

export default OrganizationEventLog
