import React from 'react';
import styled from 'styled-components';
import LegendItem from '../../../styleguide/legend_items/LegendItem';
import { LegendItemType } from '../../../styleguide/legend_items/LegendItems.constants';

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const StyledContent = styled.div`
  max-width: 110px;
`;

const HeatMapLegend = ({ data, dataFormatter, 'data-cy': dataCy }) => {
  if (!data) return null;

  return (
    <StyledContainer>
      <StyledContent data-cy={dataCy}>
        {data.map((elem) => {
          let label = elem.label ? elem.label : null;
          if (!label) label = dataFormatter ? dataFormatter(elem.value) : elem.value;

          return (
            <LegendItem
              key={elem.label + elem.dotColor}
              label={label}
              labelFontSize={'11px'}
              dotColor={elem.dotColor}
              type={LegendItemType.MAP_LABEL}
            />
          );
        })}
      </StyledContent>
    </StyledContainer>
  )
}

export default HeatMapLegend;
