import _isEmpty from 'lodash.isempty'
import propTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Form, FormControl, InputGroup } from 'react-bootstrap'
import styled from 'styled-components'

import { StyledForm } from '../../../components/forms/EntityForm.styles'
import { createFocusArea } from '../../repository/esg_respository'
import BaseDialog from './BaseDialog'
import DialogControls from './DialogControls'

const DialogContent = styled.div`
  .modal-form {
    margin: 0;
    padding: 6px 10px 10px;
    border-radius: 5px;
    background-color: #f6f8fd;
  }
  .text-inputs-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    .form-group {
      flex-grow: 1;
    }
  }
`

const uploadInitiative = (entityId, data) => {
  if (data.initiative_id === 'custom') {
    data.custom_initiative = {
      name: data.initiative_name,
      unit: data.unit,
      category: data.category,
      description: data.description,
    }
  }

  return createFocusArea(entityId, data)
}

const getInitialValues = (options, category) => {
  const values = {
    target_value: '',
    current_value: '',
    description: '',
    initiative_name: '',
    publish: false,
    category,
  }

  if (options.length === 1) {
    values.initiative = 'custom'
    values.unit = null
  } else {
    const opt = options[0]
    values.initiative_id = opt.id
    values.initiative_label = opt.label
    values.target_value =
      opt.target ||
      (opt.target_value ? `${opt.target_value} ${opt.target_unit}` : '')
    values.unit = opt.unit
  }

  return values
}

const AddFocusAreaDialog = ({
  open,
  options,
  entityId,
  category,
  onClose,
  onSuccess,
}) => {
  const [isCustom, setIsCustom] = useState(options.length === 1)
  const [values, setValues] = useState(getInitialValues(options, category))

  const [errors, setErrors] = useState({
    target_value: '',
    current_value: '',
    unit: '',
    description: '',
  })

  useEffect(() => {
    setIsCustom(options.length === 1)
    setValues(getInitialValues(options, category))
  }, [options])

  const onInputChange = (id, value) => {
    const newValues = { ...values }
    let newErrors = { ...errors }

    if (id === 'target-unit') {
      newValues.unit = value
      setValues(newValues)
    } else if (id === 'initiative_id') {
      setIsCustom(value === 'custom')

      if (value === 'custom') {
        newValues.unit = 'kg'
        setValues(newValues)
        null
      } else {
        let newInitiative = null
        options.forEach(init => {
          if (init.id == value) {
            newInitiative = init
          }
        })

        if (newInitiative) {
          newValues.unit = newInitiative.unit
        } else {
          newValues.unit = null
        }
      }
    }
    newValues[id] = value
    newErrors[id] = ''
    setValues(newValues)
    setErrors(newErrors)
  }

  const onFormSubmit = e => {
    e.preventDefault()
    const validation = {}

    if (isCustom && !values.target_value)
      validation.target_value = 'Target Value is required'
    if (isCustom && !values.unit) validation.unit = 'Unit is required'
    if (isCustom && !values.initiative_name)
      validation.initiative_name = 'Name is required'
    if (isCustom && !values.description)
      validation.description = 'Description is required'
    if (!values.current_value)
      validation.current_value = 'Current Value is required'

    if (_isEmpty(validation)) {
      uploadInitiative(entityId, {
        ...values,
        custom: isCustom,
        holding_id: entityId,
        category,
      }).then(res => {
        onSuccess(res.data)
        onClose()
      })
    } else {
      setErrors(validation)
    }
  }

  const onCloseDialog = () => {
    setErrors({})
    setIsCustom(false)
    setValues(getInitialValues(options, category))
    onClose()
  }

  const customOptions = [
    { id: 'kg', label: 'kg' },
    { id: '%', label: '%' },
  ]

  return (
    <BaseDialog
      padding={25}
      title='Add New Initiative'
      open={open}
      onClose={onClose}
      dataCy='add_focus_area_dialog'
      render={() => (
        <DialogContent>
          <StyledForm onSubmit={onFormSubmit} className='modal-form'>
            <div className='form-group' data-cy='initiative_select_form_group'>
              <label htmlFor={`initiative_id`}>Select Initiative</label>
              <Form.Control
                as='select'
                id='initiative_id'
                onChange={e => onInputChange(e.target.id, e.target.value, e)}
                custom
              >
                {options &&
                  options.map(opt => (
                    <option key={opt.id} id={opt.id} value={opt.id}>
                      {opt.label}
                    </option>
                  ))}
              </Form.Control>
            </div>

            {isCustom && (
              <div
                className='form-group'
                style={{ marginBottom: '.5rem' }}
                data-cy='initiative_name_form_group'
              >
                <label htmlFor={`initiative_name`}>Initiative Name</label>
                <FormControl
                  id={`initiative_name`}
                  aria-describedby={`initiative_name`}
                  type='text'
                  onChange={e =>
                    onInputChange('initiative_name', e.target.value)
                  }
                  isInvalid={errors.initiative_name}
                />
                <Form.Control.Feedback type='invalid' as='small'>
                  {errors.initiative_name}
                </Form.Control.Feedback>
              </div>
            )}

            <div className='text-inputs-container'>
              {isCustom && (
                <div className='form-group' data-cy='target_unit_form_group'>
                  <label htmlFor={`target-unit`}>Unit</label>
                  <Form.Control
                    as='select'
                    id='target-unit'
                    onChange={e =>
                      onInputChange('target-unit', e.target.value, e)
                    }
                    custom
                    style={{ minWidth: '80px' }}
                  >
                    {customOptions.map(opt => (
                      <option key={opt.id} id={opt.id} value={opt.id}>
                        {opt.label}
                      </option>
                    ))}
                  </Form.Control>
                </div>
              )}
              <div className='form-group' data-cy='target_value_form_group'>
                <label htmlFor={`target_value_input`}>Target Value</label>
                <InputGroup>
                  <FormControl
                    id={`target_value_input`}
                    aria-describedby={`target_value_input`}
                    type='number'
                    onChange={e =>
                      onInputChange('target_value', e.target.value)
                    }
                    isInvalid={errors.target_value}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>{values.unit}</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <Form.Control.Feedback type='invalid' as='small'>
                  {errors.target_value}
                </Form.Control.Feedback>
              </div>
              <div className='form-group' data-cy='current_value_form_group'>
                <label htmlFor={`current_value_input`}>Current Value</label>
                <InputGroup>
                  <FormControl
                    id={`current_value_input`}
                    aria-describedby={`current_value_input`}
                    type='number'
                    onChange={e =>
                      onInputChange('current_value', e.target.value)
                    }
                    isInvalid={errors.current_value}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>{values.unit}</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <Form.Control.Feedback type='invalid' as='small'>
                  {errors.current_value}
                </Form.Control.Feedback>
              </div>
            </div>

            {isCustom && (
              <div className='form-group' data-cy='description_form_group'>
                <label htmlFor={`description`}>
                  Describe Your Initiative in a Few Words
                </label>
                <FormControl
                  id={`description`}
                  aria-describedby={`description`}
                  type='text'
                  onChange={e => onInputChange('description', e.target.value)}
                  isInvalid={errors.description}
                />
                <Form.Control.Feedback type='invalid' as='small'>
                  {errors.description}
                </Form.Control.Feedback>
              </div>
            )}
            <DialogControls onClose={onCloseDialog} />
          </StyledForm>
        </DialogContent>
      )}
    />
  )
}
export default AddFocusAreaDialog

AddFocusAreaDialog.propTypes = {
  open: propTypes.bool.isRequired,
  options: propTypes.array.isRequired,
  entityId: propTypes.number.isRequired,
  category: propTypes.string.isRequired,
  onClose: propTypes.func.isRequired,
  onSuccess: propTypes.func.isRequired,
}
