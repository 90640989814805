import _ from 'lodash'

export const objectToURLParams = query => {
  const amendedQuery = {}

  for (const searchCriteria in query) {
    const searchValue = query[searchCriteria]

    if (searchValue && searchValue !== 'all') {
      amendedQuery[_.snakeCase(searchCriteria)] = searchValue
    }
  }

  return new URLSearchParams(amendedQuery).toString()
}

export const objectToFormData = data => {
  const payload = new FormData()

  Object.keys(data).forEach(field => {
    payload.append(_.snakeCase(field), data[field])
  })

  return payload
}

export const formatObjectCasing = obj => {
  const formatted = {}

  for (const field in obj) {
    formatted[_.snakeCase(field)] = obj[field]
  }

  return formatted
}
