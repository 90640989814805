import { sanitize } from 'dompurify'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { Button, FormControl } from 'react-bootstrap'
import styled from 'styled-components'

import {
  getPolicyStatements,
  savePolicyStatements,
} from '../../../../../repositories/portfolio_repository'
import {
  BG_COLOR_SECONDARY,
  FONT_COLOR_SECONDARY,
} from '../../../../../styles/colors'
import SanitizedHTML from '../../../../../yb_components/security/SanitizedHTML'
import SpinnerContainer from '../../../../../yb_components/styleguide/spinners/SpinnerContainer'
import {
  getNow,
  getRemainingLoadingTime,
} from '../../../../../yb_helpers/loader'
import { useUnsavedChangesGuard } from '../../../../../yb_hooks/security'
import { StyledBar } from '../../../../../yb_modules/custom_esg_scoring/CustomESGScoring.styles.js'
import { StyledHTML } from './PaiPolicyStatementsTab.style'

const StyledLink = styled.a`
  display: inline-flex;
  align-items: center;

  i {
    margin-left: 3px;
    font-size: 0.75em;
  }
`

const StyledList = styled.ul`
  margin: 0 0 1.25rem;
  padding: 0;
  list-style-type: none;

  li {
    padding: 10px;
    display: flex;

    &:nth-child(odd) {
      background-color: #f8f8f8;
    }
  }
  .inner-left {
    padding-right: 4rem;
    max-width: 500px;

    label {
      font-size: 12px;
      line-height: 14px;
      font-weight: 700;
      color: ${FONT_COLOR_SECONDARY};
    }
    p {
      font-size: 12px;
      line-height: 14px;
      color: ${FONT_COLOR_SECONDARY};
    }
  }

  textarea {
    flex-grow: 1;
    background: #ffffff;
    border: 1px solid ${BG_COLOR_SECONDARY};
    border-radius: 6px;
  }
`

export const PaiPolicyStatementsTab = ({ portfolioId, snapshotId }) => {
  const [hasUnsavedChanges, setHasUsavedChanges] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [policyStatmentsSnapshot, setPolicityStatementsSnapshot] = useState([])
  const [policyStatements, setPolicityStatements] = useState([])
  const formattedStatements = useMemo(
    () =>
      policyStatements.reduce(
        (result, policyStatement) => ({
          ...result,
          [policyStatement.key]: sanitize(policyStatement.statement) || '',
        }),
        {}
      ),
    [policyStatements]
  )

  const handleCancel = () => setPolicityStatements(policyStatmentsSnapshot)

  const handleStatementChange = (policyKey, statement) => {
    setPolicityStatements(
      policyStatements.map(policyStatement =>
        policyStatement.key === policyKey
          ? {
              ...policyStatement,
              statement,
            }
          : policyStatement
      )
    )
  }

  const updateStatements = statements => {
    setPolicityStatementsSnapshot(statements)
    setPolicityStatements(statements)
  }

  const fetchPolicyStatements = () => {
    const now = getNow()

    setIsLoading(true)
    getPolicyStatements(portfolioId, snapshotId)
      .then(({ data }) => updateStatements(data.policy_statements))
      .finally(() => {
        const remainingLoadingTime = getRemainingLoadingTime(now)

        setTimeout(() => setIsLoading(false), remainingLoadingTime)
      })
  }

  const handleSave = () => {
    const now = getNow()

    setIsSaving(true)
    savePolicyStatements(portfolioId, snapshotId, formattedStatements)
      .then(({ data }) => updateStatements(data.policy_statements))
      .finally(() => {
        const remainingLoadingTime = getRemainingLoadingTime(now)

        setTimeout(() => setIsSaving(false), remainingLoadingTime)
      })
  }

  useEffect(() => {
    fetchPolicyStatements()
  }, [])

  useEffect(() => {
    for (let i = 0; policyStatements.length > i; i = i + 1) {
      if (
        policyStatements[i].statement !== policyStatmentsSnapshot[i].statement
      ) {
        return setHasUsavedChanges(true)
      }
    }

    setHasUsavedChanges(false)
  }, [policyStatements])

  useUnsavedChangesGuard(hasUnsavedChanges)

  return (
    <main>
      <p style={{ margin: '0 0 15px', color: FONT_COLOR_SECONDARY }}>
        Financial market participants are required to make a statement of how
        they incorporate Principal Adverse Impact (PAI) in their investment
        decision process. This is a narrative disclosure in the form of a
        statement and examples include{' '}
        <StyledLink
          target='blank'
          href='https://www.robeco.com/docm/docu-robeco-principal-adverse-impact-statement.pdf'
        >
          Robeco’s statement
          <i className='fal fa-external-link' />
        </StyledLink>{' '}
        and{' '}
        <StyledLink
          target='blank'
          href='https://www.nordea.lu/documents/principal-adverse-impact-statement/PAIS_eng_INT.pdf/'
        >
          Nordea’s statement
          <i className='fal fa-external-link' />
        </StyledLink>
        . This policy disclosure is required for financial products starting at
        the end of 2022.
      </p>
      <p style={{ margin: '0 0 30px', color: FONT_COLOR_SECONDARY }}>
        The format below is the narrative portion of the PAI that requires the
        user to describe in specific terms using the data associated with the
        product to answer the questions. Fill this out and it will be
        incorporated into your final PAI portfolio report. Click{' '}
        <StyledLink
          target='blank'
          href='https://www.esma.europa.eu/sites/default/files/library/jc_2021_03_joint_esas_final_report_on_rts_under_sfdr.pdf'
        >
          here
          <i className='fal fa-external-link' />
        </StyledLink>{' '}
        to view the EU’s Report on Regulatory Technical Standards.
      </p>
      <form>
        <SpinnerContainer isLoading={isLoading}>
          <StyledList>
            {policyStatements.map(el => (
              <li key={el.key}>
                <div className='inner-left'>
                  <label htmlFor={el.key}>{el.name}</label>
                  <StyledHTML>
                    <SanitizedHTML
                      stringTemplate={el.description}
                      config={{ ADD_ATTR: ['target'] }}
                    />
                  </StyledHTML>
                </div>
                <FormControl
                  as='textarea'
                  disabled={isSaving}
                  name={el.name}
                  id={el.key}
                  value={el.statement ? el.statement : ''}
                  onChange={event =>
                    handleStatementChange(el.key, event.target.value)
                  }
                  rows='12'
                ></FormControl>
              </li>
            ))}
          </StyledList>
        </SpinnerContainer>

        <StyledBar>
          <Button
            variant='link'
            disabled={isLoading || isSaving || !hasUnsavedChanges}
            onClick={e => handleCancel(e)}
          >
            Cancel
          </Button>
          <Button
            variant='primary'
            disabled={isLoading || isSaving || !hasUnsavedChanges}
            onClick={handleSave}
          >
            Save Changes
          </Button>
        </StyledBar>
      </form>
    </main>
  )
}

PaiPolicyStatementsTab.propTypes = {
  portfolioId: PropTypes.string,
  snapshotId: PropTypes.string,
}
