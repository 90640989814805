import React, { useState } from "react";
import Table from "../styleguide/Table";
import Paginator from "../styleguide/Paginator";
import Card from "../styleguide/Card";
import { Event } from "../../modules/events/Event";

const EventsTable = ({events, page, pageCount, onPaginatorChange}) => {
    const headers = [{ label: "EVENT" }];
    let eventComponents = [];

    events.forEach((event) => {
        const eventComponent = new Event(event);
        eventComponents.push(eventComponent);
    });


    const tableRows = () => {
        return eventComponents.map((eventComponent) => {
            return [<span>{ eventComponent.render() }</span>]
        })
    };

    const handlePaginatorChange = (newPage) => {
        onPaginatorChange(newPage);
    };

    return(
        <>
            <Table body={tableRows()} headers={headers} />
            <Paginator selected={page} max={pageCount} onChange={handlePaginatorChange} />
        </>
    )
};

export default EventsTable;
