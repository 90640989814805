import { defaultConfiguration, defaultInvestmentStrategy, defaultTermsOfAgreement, defaultGeography } from "../../../constants/funds";

export class Fund {
    constructor(params) {
        this.id = params.id;
        this.name = params.name || "";
        this.status = params.status || "";
        this.stage = params.stage || "";
        this.configuration = params.configuration || defaultConfiguration;
        this.investment_strategy = params.investment_strategy || defaultInvestmentStrategy;
        this.geography = params.geography || defaultGeography;
        this.terms_of_agreement = params.terms_of_agreement || defaultTermsOfAgreement;
        this.has_ifc_principle = params.has_ifc_principle || false;
        this.current_app_user_can_update = params.current_app_user_can_update || false;
    };

    // INSTANCE METHODS
    toJSON(){
        return {
            id: this.id,
            name: this.name,
            status: this.status,
            stage: this.stage,
            configuration: this.configuration,
            investment_strategy: this.investment_strategy,
            geography: this.geography,
            terms_of_agreement: this.terms_of_agreement
        };
    };

    createDetailsHashMap(){
        return [
            {key:"Name", value:this.name},
            {key:"Manager", value:this.configuration.administrator},
            {key:"Management Fee", value: isNaN(this.terms_of_agreement.management_fee) ? "-" : this.terms_of_agreement.management_fee},
            {key:"Incentive Fee", value: isNaN(this.terms_of_agreement.performance_fee) ? "-" : this.terms_of_agreement.performance_fee},
            {key:"Term", value:isNaN(this.investment_strategy.investment_years) ? "-" : this.investment_strategy.investment_years},
            {key:"Fundraising Target", value:isNaN(this.investment_strategy.target_aum) ? "-" : this.investment_strategy.target_aum},
            {key:"Target Return", value:isNaN(this.investment_strategy.target_return) ? "-" : this.investment_strategy.target_return},
        ]
    }

    // SETTERS
    setStatus(value){
        this.status = value;
    };

    setStage(value){
        this.stage = value;
    };

    setConfigurationParam(key, value){
        this.configuration[key] = value;
    };

    setInvestmentStrategyParam(key, value){
        this.investment_strategy[key] = value;
    };

    setGeographyParam(key, value){
        this.geography[key] = value;
    };

    setTermsOfAgreementParam(key, value){
        this.terms_of_agreement[key] = value;
    };

    setId(value){
        this.id = value;
    };

    setName(value){
        this.name = value;
    };
};
