import _sortBy from 'lodash.sortby'

export const mapTargetToState = target => {
  return {
    id: target.id,
    name: target.name,
    tvl_insight_scores: target.tvl_insight_scores,
    best_sasb_categories: target.best_sasb_categories,
    worst_sasb_categories: target.worst_sasb_categories,
    tvl_sasb_spotlight_events: target.tvl_sasb_spotlight_events,
    headlines: _mapHeadlines(target),
    impacts: _mapImpacts(target),
    sdgs: _mapSDGs(target),
    subsidies: _mapSubsidies(target),
    violations: _mapViolations(target),
    rep_risk_data: _mapRepRiskData(target),
    assessment_metrics: _mapAssessmentMetrics(target),
    revenue_segments: _mapRevenueSegments(target),
    sdg_exposures: _mapSDGExposures(target),
    custom_esg_labels:
      target.custom_esg_labels == null ? undefined : target.custom_esg_labels,
  }
}

const _mapHeadlines = target => {
  if (!target.headlines || !target.headlines.length) return null

  return target.headlines.map(h => ({
    title: h.title,
    source: h.source,
    category: h.categories,
    date: h.date,
    url: h.url,
  }))
}

const _mapImpacts = target => {
  const impactsIsEmpty = target => {
    return (
      !target.impacts ||
      (!target.impacts.trees_killed &&
        !target.impacts.pay_gap &&
        !target.impacts.waste &&
        !target.impacts.water_usage &&
        !target.impacts.hazardous_waste)
    )
  }

  if (impactsIsEmpty(target)) return null

  return {
    trees_killed: {
      description: target.impacts.trees_killed.description,
      total: parseFloat(target.impacts.trees_killed.total).toFixed(2),
      holdings_ownership_pct: parseFloat(
        target.impacts.trees_killed.holdings_ownership_pct
      ),
    },
    pay_gap: {
      description: target.impacts.pay_gap.description,
      total: parseFloat(target.impacts.pay_gap.total).toFixed(2),
      holdings_ownership_pct: parseFloat(
        target.impacts.pay_gap.holdings_ownership_pct
      ),
    },
    waste: {
      description: target.impacts.waste.description,
      total: parseFloat(target.impacts.waste.total).toFixed(2),
      holdings_ownership_pct: parseFloat(
        target.impacts.waste.holdings_ownership_pct
      ),
    },
    water_usage: {
      description: target.impacts.water_usage.description,
      total: parseFloat(target.impacts.water_usage.total).toFixed(2),
      holdings_ownership_pct: parseFloat(
        target.impacts.water_usage.holdings_ownership_pct
      ),
    },
    hazardous_waste: {
      description: target.impacts.hazardous_waste.description,
      total: parseFloat(target.impacts.hazardous_waste.total).toFixed(2),
      holdings_ownership_pct: parseFloat(
        target.impacts.hazardous_waste.holdings_ownership_pct
      ),
    },
  }
}

const _mapSDGs = target => {
  if (!target.sdgs) return null

  return {
    positive: target.sdgs.positive || [],
    negative: target.sdgs.negative || [],
  }
}

const _mapSubsidies = target => {
  if (
    !target.subsidies ||
    !target.subsidies.timeline ||
    !target.subsidies.timeline.length
  )
    return null

  return {
    total: target.subsidies.total,
    timeline: _sortBy(target.subsidies.timeline, 'year'),
  }
}

const _mapViolations = target => {
  if (
    !target.violations ||
    !target.violations.timeline ||
    !target.violations.timeline.length
  )
    return null

  return {
    total: target.violations.total,
    timeline: _sortBy(target.violations.timeline, 'year'),
  }
}

const _mapRepRiskData = target => {
  if (
    !target.rep_risk_data ||
    (target.rep_risk_data && target.rep_risk_data.length == 0)
  )
    return null

  return target.rep_risk_data
}

const _mapAssessmentMetrics = target => {
  if (!target.assessment_metrics) return null

  const mapMetric = metric => {
    return Object.keys(metric).map(k => ({
      weight: (metric[k].weight * 100).toFixed(0),
      metric: metric[k].display_name,
      value: metric[k].value
        ? `${parseFloat(metric[k].value).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })} ${metric[k].unit}`
        : '—',
      benchmark: metric[k].benchmark
        ? `${parseFloat(metric[k].benchmark).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })} ${metric[k].unit}`
        : '_',
    }))
  }

  const assessment_metrics = {}

  assessment_metrics.env = mapMetric(target.assessment_metrics.environmental)
  assessment_metrics.soc = mapMetric(target.assessment_metrics.social)
  assessment_metrics.gov = mapMetric(target.assessment_metrics.governance)

  return assessment_metrics
}

const _mapRevenueSegments = target => {
  if (!target.revenue_segments) return null

  const rsList = []

  target.revenue_segments.forEach(r => {
    const percent = Math.round(parseFloat(r.revenue_percent) * 100)

    if (percent > 0) {
      rsList.push({
        percent,
        title: r.name,
        value: r.score || 0,
        tooltip: r.revenue || 0,
      })
    }
  })

  return rsList.length ? rsList : null
}

const _mapSDGExposures = target => {
  if (
    !target.sdg_exposures ||
    (target.sdg_exposures && target.sdg_exposures.length == 0)
  )
    return null

  const sdgs = {}
  for (const key in target.sdg_exposures) {
    let exposure = parseFloat(target.sdg_exposures[key]) || 0

    sdgs[key] = exposure
  }

  return sdgs
}
