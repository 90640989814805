import { tcfdRiskHardcodeItems } from '../../../../../modules/portfolio/tcfd/constants/tcfd_holding_details_data'
import { default as PEERS_TEXT } from '../../../../../modules/portfolio/tcfd/constants/tcfd_peer_data.json'

export const PeersCellType = {
  DEFAULT: 'DEFAULT',
  NUMBER: 'NUMBER',
  COLORED: 'COLORED',
  TEXT: 'TEXT',
}

const {
  market_risk_1,
  market_risk_2,
  market_risk_3,
  physical_risk_1,
  policy_and_legislation,
  reputation_1,
  reputation_2,
  technology_and_adaptation,
} = PEERS_TEXT

export const RISK_ITEMS = [
  {
    id: 1,
    key: policy_and_legislation.id,
    name: policy_and_legislation.policy_and_legislation,
    description:
      'Climate Change Laws of the World and Climate Change Litigation of the World https://climate-laws.org/ build on more than a decade of data collection by the Grantham Research Institute at LSE and the Sabin Center at Columbia Law School. Climate Change Laws of the World covers national-level climate change legislation and policies globally. The database covers climate and climate-related laws, as well as laws and policies promoting low carbon transitions, which reflects the relevance of climate policy in areas including energy, transport, land use, and climate resilience. YvesBlue combines the litigation and regulation scores by country on a 0-4 basis.',
    attrKeys: [
      {
        key: 'laws_and_policies',
        name: policy_and_legislation.laws_and_policies,
      },
      {
        key: 'litigation_cases',
        name: policy_and_legislation.litigation_cases,
      },
      {
        key: 'sum',
        name: policy_and_legislation.sum,
      },
      {
        key: 'score',
        name: policy_and_legislation.score,
        type: PeersCellType.COLORED,
      },
      {
        key: 'sector_score',
        name: policy_and_legislation.sector_score,
        type: PeersCellType.COLORED,
      },
    ],
    scoreText: tcfdRiskHardcodeItems?.[0]?.score_text,
    newPage: true,
  },
  {
    id: 2,
    key: technology_and_adaptation.id,
    name: technology_and_adaptation.technology_and_adaptation,
    description:
      'The risk score of 0-4 for 800+ industrial activities indicates high risk for difficult to decarbonize sectors and sectors with vulnerable assets, scores of 2 or 1 are for industrial activities that will have to buy more credits, increase capex to reduce emissions or which have generally more moderate exposure to technology risk and a score of 0 indicates not relevant or not applicable. Hovering over each holding, a pop-up provides information about industrial optimization, software solutions, new practices, new technologies and adoption of new frameworks that certain industries must consider in order to make timely emissions reductions in the face of rising carbon credit prices, enhance productivity, reduce operating costs and protect assets against increasingly extreme weather events. These lists are non-exhaustive but could be used when asking Investor Relations about what changes are being made. These will also be provided in the engagement platform on YvesBlue.',
    attrKeys: [
      {
        key: 'score',
        name: technology_and_adaptation.score,
        type: PeersCellType.COLORED,
      },
      {
        key: 'sector_score',
        name: technology_and_adaptation.sector_score,
        type: PeersCellType.COLORED,
      },
    ],
    scoreText: tcfdRiskHardcodeItems?.[1]?.score_text,
    newPage: true,
  },
  {
    id: 3,
    key: market_risk_1.id,
    name: market_risk_1.market_risk_1,
    description:
      'Counter to Oil & Gas company claims, the future is not in plastics. Coal and natural gas are no longer price competitive in all places. Renewable energy as a percent of utility fuel mix is rising. Barocaloric heating and cooling is winning prizes. Sustainabile agriculture, olivine beaches, carbon mineralization, kelp farming, DAC are collectively thought to be a trillion dollar carbon dioxide removal industry. New industries full of opportunity arise out of the need to address climate change. Information about these new industries: https://circularcarbon.org/, https://openaircollective.cc/, https://foundationforclimaterestoration.org/ and many others',
    attrKeys: [
      {
        key: 'score',
        name: market_risk_1.score,
        type: PeersCellType.COLORED,
      },
      {
        key: 'sector_score',
        name: market_risk_1.sector_score,
        type: PeersCellType.COLORED,
      },
    ],
    scoreText: tcfdRiskHardcodeItems?.[2]?.score_text,
  },
  {
    id: 4,
    key: market_risk_2.id,
    name: market_risk_2.market_risk_2,
    description:
      'The risk to certain industries lies in the failure to recognize or slowness to act on climate driven consumer choices. Demand for electric vehicles is rising, Gen X prefers second hand clothing, people are chosing organic at the grocery store, they are composting more, they are using public transportation, and are looking for eco-frendly travel options.',
    attrKeys: [
      {
        key: 'score',
        name: market_risk_2.score,
        type: PeersCellType.COLORED,
      },
      {
        key: 'sector_score',
        name: market_risk_2.sector_score,
        type: PeersCellType.COLORED,
      },
    ],
    scoreText: tcfdRiskHardcodeItems?.[3]?.score_text,
    newPage: true,
  },
  {
    id: 5,
    key: market_risk_3.id,
    name: market_risk_3.market_risk_3,
    description:
      'A carbon intensive company operating in a location with a trading system or a tax may see the costs of this rise. The price of the EU ETS went from $20 a few years ago to almost $80 today.',
    attrKeys: [
      { key: 'country', name: market_risk_3.country, type: PeersCellType.TEXT },
      { key: 'annual_emissions', name: market_risk_3.annual_emissions },
      { key: 'annual_revenue', name: market_risk_3.annual_revenue },
      { key: 'price_of_carbon', name: market_risk_3.price_of_carbon },
      {
        key: 'percentage_cost_of_carbon_vs_revenue',
        name: market_risk_3.percentage_cost_of_carbon_vs_revenue,
      },
      {
        key: 'score',
        name: market_risk_3.score,
        type: PeersCellType.COLORED,
      },
      {
        key: 'sector_score',
        name: market_risk_3.sector_score,
        type: PeersCellType.COLORED,
      },
    ],
    scoreText: tcfdRiskHardcodeItems?.[4]?.score_text,
    newPage: true,
  },
  {
    id: 6,
    key: reputation_1.id,
    name: reputation_1.reputation_1,
    description:
      "The Transition Pathway Initiative (TPI) is a global, asset-owner led initiative which assesses companies' preparedness for the transition to a low carbon economy. Rapidly becoming the go-to corporate climate action benchmark. Companies are scored 0-4, 4 being best. A good TPI Score is reputationally good.",
    attrKeys: [
      {
        key: 'score',
        name: reputation_1.score,
        type: PeersCellType.COLORED,
      },
      {
        key: 'sector_score',
        name: reputation_1.sector_score,
        type: PeersCellType.COLORED,
      },
    ],
    scoreText: tcfdRiskHardcodeItems?.[5]?.score_text,
    newPage: true,
  },
  {
    id: 7,
    key: reputation_2.id,
    name: reputation_2.reputation_2,
    description:
      'Industries are scored 0-4 on the notion that reputation risk could stem from the impacts that such industries are causing to the environment.',
    attrKeys: [
      {
        key: 'industry_group',
        name: reputation_2.industry_group,
        type: PeersCellType.TEXT,
      },
      {
        key: 'carbon_budget',
        name: reputation_2.carbon_budget,
        type: PeersCellType.COLORED,
      },
      {
        key: 'ocean_plastic',
        name: reputation_2.ocean_plastic,
        type: PeersCellType.COLORED,
      },
      {
        key: 'water_pollution',
        name: reputation_2.water_pollution,
        type: PeersCellType.COLORED,
      },
      {
        key: 'land_use',
        name: reputation_2.land_use,
        type: PeersCellType.COLORED,
      },
      {
        key: 'deforestation',
        name: reputation_2.deforestation,
        type: PeersCellType.COLORED,
      },
      {
        key: 'biodiversity',
        name: reputation_2.biodiversity,
        type: PeersCellType.COLORED,
      },
      {
        key: 'toxic_release',
        name: reputation_2.toxic_release,
        type: PeersCellType.COLORED,
      },
      {
        key: 'e_waste',
        name: reputation_2.e_waste,
        type: PeersCellType.COLORED,
      },
      {
        key: 'overfishing',
        name: reputation_2.overfishing,
        type: PeersCellType.COLORED,
      },
      {
        key: 'chemicals_in_product',
        name: reputation_2.chemicals_in_product,
        type: PeersCellType.COLORED,
      },
      {
        key: 'heavy_water_use',
        name: reputation_2.heavy_water_use,
        type: PeersCellType.COLORED,
      },
      {
        key: 'severe_operational_risk',
        name: reputation_2.severe_operational_risk,
        type: PeersCellType.COLORED,
      },
      {
        key: 'score',
        name: reputation_2.score,
        type: PeersCellType.COLORED,
      },
      {
        key: 'sector_score',
        name: reputation_2.sector_score,
        type: PeersCellType.COLORED,
      },
    ],
    scoreText: tcfdRiskHardcodeItems?.[6]?.score_text,
  },
  {
    id: 8,
    key: physical_risk_1.id,
    name: physical_risk_1.physical_risk_1,
    description:
      'Operational, supply and risk to property, plant and equipment in the event of sudden and severe climate event or gradual effects of climate change over time. In a given industry there will be wide variation in impacts depending on location and circumstance.',
    attrKeys: [
      {
        key: 'industry_group',
        name: physical_risk_1.industry_group,
        type: PeersCellType.TEXT,
      },
      {
        key: 'sea_level_rise',
        name: physical_risk_1.sea_level_rise,
        type: PeersCellType.COLORED,
      },
      {
        key: 'extreme_weather',
        name: physical_risk_1.extreme_weather,
        type: PeersCellType.COLORED,
      },
      {
        key: 'forest_fire',
        name: physical_risk_1.forest_fire,
        type: PeersCellType.COLORED,
      },
      {
        key: 'drought',
        name: physical_risk_1.drought,
        type: PeersCellType.COLORED,
      },
      {
        key: 'flooding',
        name: physical_risk_1.flooding,
        type: PeersCellType.COLORED,
      },
      {
        key: 'acute',
        name: physical_risk_1.acute,
        type: PeersCellType.COLORED,
      },
      {
        key: 'chronic',
        name: physical_risk_1.chronic,
        type: PeersCellType.COLORED,
      },
      {
        key: 'score',
        name: physical_risk_1.score,
        type: PeersCellType.COLORED,
      },
      {
        key: 'sector_score',
        name: physical_risk_1.sector_score,
        type: PeersCellType.COLORED,
      },
    ],
    scoreText: tcfdRiskHardcodeItems?.[7]?.score_text,
    hasLegend: true,
  },
]
