import React from 'react';
import styled from 'styled-components';
import InputLabel from './InputLabel';
import { BORDER_COLOR_PRIMARY, APP_COLOR_ERROR  } from '../../styles/colors';
import DatePicker from "react-datepicker";

const InputGroup = styled.div`
  margin-top: 10px;
  margin-right:${props => props.marginRight};
  position: relative;
  display:flex;
  flex-direction:column;
  width:${props => props.width};
  padding-bottom: 18px;
  input {
    display: block;
    width: 100%;
    height:${props => props.height};
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${props => props.error ? APP_COLOR_ERROR : BORDER_COLOR_PRIMARY};
    -webkit-appearance: none;
    :focus {
      outline: none;
    }
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  input[type=number] {
    -moz-appearance:textfield;
  }
  .text-input-error {
    position: absolute;
    font-size: 12px;
    white-space: nowrap;
    left: 0;
    bottom: -6;
    color: ${APP_COLOR_ERROR};
  }
`;

const YBDatePicker = ({ label, value, placeholder, onChange, id, error, marginRight, type = "text", width, height, date, showYearDropdown, showMonthDropdown, minDate, disabled }) => {
  const localDate = date ? new Date(date) : date;
  date = localDate ? new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000) : localDate;

  return (
    <InputGroup error={error} marginRight={marginRight} width={width} height={height}>
      <InputLabel label={label} />
      <DatePicker
        onChange={(date) => onChange(date, id)}
        selected={date}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        minDate={minDate}
        readOnly={disabled}
      />
      <div>
        <div className="text-input-error">{error}</div>
      </div>
    </InputGroup>
  );
}

export default YBDatePicker;
