import dayjs from 'dayjs'
import qs from 'query-string'
import React from 'react'
import Form from 'react-bootstrap/Form'
import styled from 'styled-components'

import { CurrentValueCell } from './CurrentValueCell'
import { LastReportedValueCell } from './LastReportedValueCell'
import { TargetedValueCell } from './TargetedValueCell'

dayjs().format()

const StyledGrid = styled.div`
  margin: 0 0 2.5rem 0;

  &:last-child {
    margin-bottom: 0;
  }

  // blue title box
  .grid-title-container {
    margin: 0;
    padding: 12px;
    width: 100%;
    min-height: 39px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #ccd5ed;

    h4 {
      margin: 0;
      font-size: 18px;
      line-height: 0;
      color: black;
      font-weight: 700;
    }
  }
  // grid column headers
  .grid-head-row {
    height: auto;
    min-height: 20px;
    grid-column: 1 / 7;
    display: grid;
    grid-template-columns: 82px 1fr 1fr 1fr 1fr 1fr;
    background-color: #edeef3;
  }
  .grid-head-cell {
    padding: 0.24rem 0.5rem;
    display: flex;
    align-items: center;
  }
  .grid-head-cell span {
    display: inline-block;
    font-size: 11px;
    line-height: 12px;
    text-transform: uppercase;
    font-weight: 700;
    color: #6c757d;
  }
  // grid data
  .grid-data-row {
    grid-column: 1 / 7;
    display: grid;
    grid-template-columns: 82px 1fr 1fr 1fr 1fr 1fr;
    background-color: white;
    color: black;

    &:nth-of-type(even) {
      background-color: #f6f8fd;
    }
    &.inactive {
      color: #6c757d;
    }
  }
  .grid-data-cell {
    padding: 0.5rem;
    min-height: 80px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.center-content {
      justify-content: center;
    }

    h5 {
      margin: 0 0 2px;
      font-size: 0.5rem;
      line-height: 1.2;
      font-weight: 400;
      text-transform: uppercase;
      color: #6c757d;
    }
    span {
      height: auto;
      display: inline-block;
      font-size: 11px;
      line-height: 1.1;
    }
    .cell-list-span {
      display: block;
      /* &:not(:last-child) {
        &:after {
          content: ',';
        }
      } */
    }
    .cell-date-span {
      display: block;
      color: #6c757d;
      font-size: 9px;
      line-height: 12px;
      white-space: nowrap;
      a {
        text-decoration: underline;
        color: #002fa7;
        font-size: 9px;
        line-height: 12px;
      }
    }
    a {
      height: auto;
      display: inline-block;
      font-size: 11px;
      line-height: 1.1;
      color: #002fa7;
    }
    form {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  // custom switch
  --switch-color: #198754;

  .custom-control-input:focus ~ .custom-control-label::before {
    border-color: var(--switch-color) !important;
    box-shadow: 0 0 0 0.2rem rgb(72 180 97 / 50%); // success button focus ring
  }
  /*sets the background color of switch when it is checked*/
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--switch-color) !important;
    background-color: var(--switch-color) !important;
  }
  /*sets the background color of when it is active*/
  .custom-control-input:active ~ .custom-control-label::before {
    background-color: var(--switch-color) !important;
    border-color: var(--switch-color) !important;
  }
  /*sets the border color of switch when it is not checked*/
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: var(--switch-color) !important;
  }
`

export const SasbDataGrid = ({ gridData, updateEnableMetric }) => {
  const portfolioName = qs.parse(window.location.search).portfolioName
  const portfolioId = qs.parse(window.location.search).portfolioId

  const handleToggleEnableMetric = (metricId, initialValue) => {
    updateEnableMetric(metricId, initialValue)
  }
  const metricDetailsLink = metricId => {
    const currentPath = window.location.pathname.replace(/\/$/, '')
    return portfolioId && portfolioName
      ? `${currentPath}/metrics/${metricId}?portfolioId=${portfolioId}&portfolioName=${portfolioName}`
      : `${currentPath}/metrics/${metricId}`
  }

  return (
    <StyledGrid key={gridData.dimension} role='table'>
      <div className='grid-title-container'>
        <h4>{gridData.dimension}</h4>
      </div>
      <div className='grid-head-row' role='row'>
        <div className='grid-head-cell' role='columnheader'>
          <span></span>
        </div>
        <div className='grid-head-cell' role='columnheader'>
          <span>Metric Description</span>
        </div>
        <div className='grid-head-cell' role='columnheader'>
          <span>Est. / Last Reported Value</span>
        </div>
        <div className='grid-head-cell' role='columnheader'>
          <span>Targeted Value</span>
        </div>
        <div className='grid-head-cell' role='columnheader'>
          <span>Current Value</span>
        </div>
        <div className='grid-head-cell' role='columnheader'>
          <span></span>
        </div>
      </div>
      {gridData.grid_rows.map(row => (
        <div
          className={`grid-data-row ${!row.enabled ? 'inactive' : ''}`}
          key={row.id}
          role='row'
        >
          <div className='grid-data-cell center-content' role='cell'>
            <Form.Check
              type='switch'
              id={`switch_${row.id}`}
              label={``}
              checked={row.enabled}
              onChange={() => handleToggleEnableMetric(row.id, row.enabled)}
            />
          </div>
          <div className='grid-data-cell' role='cell'>
            <span>
              <h5>{row.general_issue_category_name}</h5>
              {row.sub_metrics.length > 1 ? (
                row.sub_metrics.map((el, i) => (
                  <span className='cell-list-span' key={el.id}>
                    ({i + 1}) {el.prompt}
                  </span>
                ))
              ) : (
                <span>{row.sub_metrics[0].prompt}</span>
              )}
            </span>
          </div>
          <div className='grid-data-cell' role='cell'>
            <LastReportedValueCell subMetrics={row.sub_metrics} />
          </div>
          <div className='grid-data-cell' role='cell'>
            <TargetedValueCell
              enabled={row.enabled}
              subMetrics={row.sub_metrics}
              metricDetailsLink={metricDetailsLink(row.id)}
            />
          </div>
          <div className='grid-data-cell' role='cell'>
            <CurrentValueCell
              enabled={row.enabled}
              subMetrics={row.sub_metrics}
              metricDetailsLink={metricDetailsLink(row.id)}
            />
          </div>
          <div className='grid-data-cell' role='cell'>
            <a href={metricDetailsLink(row.id)}>View / Add Notes</a>
          </div>
        </div>
      ))}
    </StyledGrid>
  )
}
