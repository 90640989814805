import React from 'react'
import styled, { css } from 'styled-components'

import ChartLabel from '../../../../components/charts/ChartLabel'
import { abbreviateNumber } from '../../../../helpers/number'
import {
  FONT_COLOR_PRIMARY,
  STACKED_CHART_SET_TABLEAU,
} from '../../../../styles/colors'
import { CarbonAuditTabs } from '../CarbonAuditCard.constants'

const StyledChartLabel = styled(ChartLabel)`
  ${props => (props.pdf ? `display: inline-block;` : '')}
  ${props =>
    props.pdf === 'report'
      ? css`
          margin-left: 30px;
        `
      : ''}
`

const CarbonAuditLegend = ({ selectedChart, data, targetName, pdf }) => {
  const parseValue = value => {
    return parseFloat(value) || 0
  }

  const totalBudgets =
    parseValue(data?.totals?.budget?.longs) +
    parseValue(data?.totals?.budget?.shorts)
  const totalEmissions =
    parseValue(data?.totals?.emissions?.longs) +
    parseValue(data?.totals?.emissions?.shorts)
  const benchmarkTotalEmissions =
    parseValue(data?.totals?.benchmark?.longs) +
    parseValue(data?.totals?.benchmark?.shorts)

  const renderlegend = () => {
    if (selectedChart === CarbonAuditTabs.HOLDING || !data?.data[2]) {
      return (
        <>
          <StyledChartLabel
            pdf={pdf}
            value={abbreviateNumber(totalBudgets, 2)}
            label={targetName + ' Budget'}
            textColor={FONT_COLOR_PRIMARY}
            unit='tonnes*'
            color={STACKED_CHART_SET_TABLEAU[1]}
            showLegend={false}
          />
          <StyledChartLabel
            pdf={pdf}
            value={abbreviateNumber(totalEmissions, 2)}
            label={targetName + ' Emissions'}
            textColor={FONT_COLOR_PRIMARY}
            unit='tonnes*'
            color={STACKED_CHART_SET_TABLEAU[1]}
            showLegend={false}
          />
        </>
      )
    } else {
      return (
        <>
          <StyledChartLabel
            pdf={pdf}
            value={abbreviateNumber(totalBudgets, 2)}
            label={targetName + ' Budget'}
            textColor={FONT_COLOR_PRIMARY}
            unit='tonnes*'
            color={STACKED_CHART_SET_TABLEAU[1]}
            showLegend={false}
          />
          <StyledChartLabel
            pdf={pdf}
            value={abbreviateNumber(totalEmissions, 2)}
            label={targetName + ' Emissions'}
            textColor={FONT_COLOR_PRIMARY}
            unit='tonnes*'
            color={STACKED_CHART_SET_TABLEAU[1]}
            showLegend={false}
          />
          <StyledChartLabel
            pdf={pdf}
            value={abbreviateNumber(benchmarkTotalEmissions, 2)}
            label={'Benchmark Emissions'}
            textColor={FONT_COLOR_PRIMARY}
            unit='tonnes*'
            color={STACKED_CHART_SET_TABLEAU[1]}
            showLegend={false}
          />
        </>
      )
    }
  }

  return <div className='carbon-budget-legend'>{renderlegend()}</div>
}

export default CarbonAuditLegend
