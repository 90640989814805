export const invalidOtpSecretAttemptMessage = "Invalid attempt";

export const stepTitles = [
    "Configure Google Authenticator",
    "Verify Google Authenticator",
    "Backup Codes",
    "Two Factor Authentication Successfully Enabled."
];

export const TwoFactorAuthDisabledMessage = "Two Factor Authentication(2FA) Successfully Disabled";
