import React from 'react';
import styled from 'styled-components';
import InputLabel from './InputLabel';
import { BORDER_COLOR_PRIMARY, APP_COLOR_DANGER, APP_COLOR_ERROR  } from '../../styles/colors';

const InputGroup = styled.div`
  margin-top: 10px;
  position: relative;
  padding-bottom: 18px;
  width:${props => props.width};
  textarea {
    display: block;
    width: 100%;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${props => props.error ? APP_COLOR_ERROR : BORDER_COLOR_PRIMARY};
    -webkit-appearance: none;
    :focus {
      outline: none;
    }
  }
  .text-input-error {
    position: absolute;
    font-size: 12px;
    white-space: nowrap;
    left: 0;
    bottom: 0;
    color: ${APP_COLOR_ERROR};
  }
`;

const TextArea = ({ label, value, placeholder, onChange, id, error, type = "text", rows=10, width}) => {
  return (
    <InputGroup error={error} width={width}>
      <InputLabel label={label} />
      <textarea id={id}
                rows={rows}
                value={value}
                onChange={e => onChange(e.target.id, e.target.value, e)}
                placeholder={placeholder}>{value}</textarea>
      <div className="text-input-error">{error}</div>
    </InputGroup>
  );
}

export default TextArea;
