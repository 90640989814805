import { exportHoldings } from "../repositories/funds_repository";
import { timestamp } from "./shared";

const urlStrings = {
    csv: 'data:text/csv;charset=utf-8,'
}

export const exportFundHoldings = ({ fundID, params, type, fundName }) => {
    exportHoldings({ fundID, params }).then((response) => {
        const encodedLink = encodeURI(response.data);
        const url = urlStrings[type] + encodedLink;
        const download = document.createElement('a');
        download.href = url;
        download.setAttribute('download', `yb-${fundName}-holdings-${timestamp}.${type}`);
        document.body.appendChild(download);
        download.click();
        download.remove();
    })
}
