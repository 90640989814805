import React from 'react';
import * as d3 from 'd3';
import calculateSize from 'calculate-size';

const RevenueSegmentsChartPDF = ({ data: _data, colors }) => {
  const data = {
    name: "Root",
    children: _data
  };
  console.log('data', data)

  const height = 250,
        width = 328,
        tilePadding = 5;

  // create treemap layout
  const treemap = d3.treemap()
      .size([width, height])
      .tile((node, x0, y0, x1, y1) => {
        return d3.treemapSquarify.ratio(1)(node, x0, y0, x1, y1);
      })
      .padding(5);


  // create hierarchy data
  const root = d3.hierarchy(data)
      .sum(d => d.percent)
      .sort(d => -d.percent);

  // bind data to layout
  const nodes = treemap(root);



  const colorScale = d3.scaleQuantize()
      .domain([0, 100])
      .range(colors);    
      

  const renderLabels = () => {
    return nodes.children.map(n => {
      const { x0, y0, x1, y1 } = n;
      const percent = Math.round(100 * n.value / n.parent.value) + '%';
      const tileWidth = Math.abs(x1 - x0) - 2 * tilePadding;
      const tileHeight = Math.abs(y1 - y0) - 2 * tilePadding;
      const percentFontSize = Math.min(20, tileWidth , tileHeight);
      const percentSize = calculateSize(percent, { font: 'Lato', fontSize: percentFontSize, fontWeight: 'bold' });
      const percentTextX = x0 + tilePadding;
      const percentTextY = y0 + percentFontSize + tilePadding;

      function getEllipsis(label, width) {
        var ellipsis = '';

        for(let i = 0; i < label.length; i++) {
          const text = ellipsis + label[i];
          const size = calculateSize(text, {
            font: 'Lato',
            fontSize: 12,
            fontWeight: 'bold'
          });

          if(size.width < width) {
            ellipsis = text;
          }
        }

        if(ellipsis.length < label.length) {
          ellipsis = ellipsis.trim() + '…';
        }

        return ellipsis;
      }

      return (
        <React.Fragment key={x0}>
          <text
            style={{ fontSize: percentFontSize }} 
            className="revenue-segment-chart-label"
            x={percentTextX}
            y={percentTextY} >
            {tileWidth > percentSize.width && tileHeight > percentSize.height  && percent}
          </text>
          {}
          <text x={percentTextX} y={percentTextY + percentFontSize * 0.7} className="revenue-segment-chart-label">
            {tileWidth > percentSize.width && tileHeight > percentSize.height + 12 && getEllipsis(n.data.title, tileWidth)}
          </text>
        </React.Fragment>
      );
    } 
    );
  }

  const renderData = () => {
    return nodes.children.map(({ x0, y0, x1, y1, data }) => {
      return <rect key={x0} x={x0} y={y0} width={Math.abs(x1 - x0)} height={Math.abs(y1 - y0)} rx="5" ry="5" 
      fill={colorScale(data.value)}/>
    });
  }

  return (
    <div className="revenue-segment-chart">
      <svg height={height} width={width}>
        {renderData()}
        {renderLabels()}
      </svg>
    </div>
  );
}

export default RevenueSegmentsChartPDF;