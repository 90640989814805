import axios from 'axios';

export const postFundCustomReports = (fundId) => {
  const url = `/app/api/funds/${fundId}/custom_reports`;
  return axios.post(url);
};

export const deleteFundHolding = (fundId, holdingId) => {
  const url = `/app/api/funds/${fundId}/holdings/${holdingId}`;
  return axios.delete(url);
};

export const putFund = ({ fund }) => {
  const url = `/app/api/funds/${fund.id}`;
  const params = { fund: fund };
  return axios.put(url, params);
};

export const getHedgeFundHolding = (fundId, snapshotId, holdingId) => {
  return axios.get(`/app/api/portfolios/${fundId}/snapshots/${snapshotId}/holdings/${holdingId}`).then(res => res.data);
};

export const getFunds = () => {
  const url = `/app/api/funds`;
  return axios.get(url);
};

export const getFund = ({ id }) => {
  const url = `/app/api/funds/${id}`;
  return axios.get(url);
};

export const postFund = ({ fund }) => {
  const url = `/app/api/funds`;
  const params = { fund: fund };
  return axios.post(url, params);
};

export const queryHoldings = ( params ) => {
  const url = `/app/api/funds/${params.fund_id}/query_holdings`;
  return axios.post(url, params);
};

export const exportHoldings = ( { fundID, params }) => {
  console.log(fundID, params);
  const url = `/app/api/funds/${fundID}/export_holdings`;
  return axios.post(url, params);
}

export const createFundHolding = ({ fundId, holding, initialFormSubmission }) => {
  const url = `/app/api/funds/${fundId}/holdings`;
  const params = {
    fund_id:fundId,
    holding:holding.toJSON(),
    initial_form_submission: initialFormSubmission
  };

  return axios.post( url, params );
};

export const editFundHolding = ({ fundId, holding, holdingId }) => {
  const url = `/app/api/funds/${fundId}/holdings/${holdingId}`;
  const params = {
    holding:holding.toJSON()
  }

  return axios.put(url, params);
}

export const updateFundHoldingPipeline = ({ fundId, holdingId, new_pipeline_state  }) => {
  const url = `/app/api/funds/${fundId}/holdings/${holdingId}/update_state?new_state=${new_pipeline_state}`;
  return axios.put(url);
}

export const postQueryFundHoldingEvents = ({holding, page, perPage}) => {
  const url = `/app/api/funds/${holding.fund_id}/holdings/${holding.id}/query_events`;
  return axios.post(url, { page: page, per_page: perPage });
};

export const getHolding = ({ fundId, holdingId }) => {
  const url = `/app/api/funds/${fundId}/holdings/${holdingId}`;
  return axios.get(url);
};
export const onboardFundMember = (fundId, fundMember) => {
  const url = `/app/api/funds/${fundId}/onboard_member`;
  return axios.post(url, fundMember);
};

export const postFundMembers = (fundId, fundMembers) => {
  const url = `/app/api/funds/${fundId}/members`;
  return axios.post(url, fundMembers)
};

export const getFundMembers = (fundId) => {
  const url  = `/app/api/funds/${fundId}/members`;
  return axios.get(url);
};

export const putFundMember = ({ fundId, memberId, newRole }) => {
  const url = `/app/api/funds/${fundId}/members/${memberId}?new_role=${newRole}`;
  return axios.put(url);
};

export const setAllMembersToReaders = ({ fundId }) => {
  const url =  `/app/api/funds/${fundId}/set_all_members_as_reader`;
  return axios.put(url);
};

export const getFundForm = ({ fundID, formKey }) => {
  const url = `/app/api/funds/${fundID}/forms?key=${formKey}`;
  return axios.get(url);
};

export const postFundQueryEvents =({fundID, page, perPage}) => {
  const url = `/app/api/funds/${fundID}/query_events?page=${page}&per_page=${perPage}`;
  return axios.post(url);
};

export const postCustomReportFormSubmissions = ({ formID, submission }) => {
  const url = `/app/api/custom_report_forms/${formID}/submissions`;
  return axios.post(url, submission);
};

export const getHoldingForm = ({ fundID, holdingID, formKey }) => {
  const url = `/app/api/funds/${fundID}/holdings/${holdingID}/forms?key=${formKey}`;
  return axios.get(url);
};

export const getThematicSnapshots = ({ fundID, formKey }) => {
  const url = `/app/api/funds/${fundID}/thematic_snapshots?key=${formKey}`;
  return axios.get(url);
};

export const postReportingFramework = (fundId, payload) => {
  const url = `/app/api/funds/${fundId}/reporting_frameworks`;
  return axios.post(url, { reporting_framework: payload } );
};

export const getFundHoldingFormTemplates = (fundId) => {
  const url = `/app/api/funds/${fundId}/form_templates?target_type=Holding`;
  return axios.get(url);
};


export const getCustomReports = ({ fundID, holdingID }) => {
  const url = `/app/api/funds/${fundID}/holdings/${holdingID}/custom_reports`;
  return axios.get(url);
};

export const getFormItemTargets = ({ fundID, holdingID, formItemID }) => {
  const url = `/app/api/funds/${fundID}/holdings/${holdingID}/form_items/${formItemID}/targets`;
  return axios.get(url);
}

export const postFormItemTargets = ({ fundID, holdingID, formItemID, params }) => {
  const url = `/app/api/funds/${fundID}/holdings/${holdingID}/form_items/${formItemID}/targets`;
  return axios.post(url, params)
};

export const postFormItems = ({ fundID, holdingID, params }) => {
  const url = `/app/api/funds/${fundID}/holdings/${holdingID}/form_items`;
  return axios.post(url, params)
};

export const postFormItemValues = ({ fundID, holdingID, formItemID, params }) => {
  const url = `/app/api/funds/${fundID}/holdings/${holdingID}/form_items/${formItemID}/values`
  return axios.post(url, params);
};

export const postFormItemNotes = ({ fundID, holdingID, formItemID, params }) => {
  const url = `/app/api/funds/${fundID}/holdings/${holdingID}/form_items/${formItemID}/notes`;
  return axios.post(url, params);
};

export const getFormItemNotes = ({ fundID, holdingID, formItemID }) => {
  const url = `/app/api/funds/${fundID}/holdings/${holdingID}/form_items/${formItemID}/notes`;
  return axios.get(url);
};
