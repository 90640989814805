import { IModuleTab } from '@ui/components/interactive/navigation/ModuleTabs'
import { useMemo } from 'react'

class SFDRController {
    useTabNav(portfolioId: string, snapshotId: string): IModuleTab[] {
        return useMemo<IModuleTab[]>(
            () =>
                [
                    {
                        id: SFDR_MODULES.OVERVIEW,
                        label: 'Overview',
                    },
                    {
                        id: SFDR_MODULES.FUND_CONFIGURATION,
                        label: 'Fund Configuration',
                    },
                    {
                        id: SFDR_MODULES.HOLDING_DATA,
                        label: 'Holding Data',
                    },
                    {
                        id: SFDR_MODULES.DISCLOSURES,
                        label: 'Disclosures',
                    },
                ].map(tab => ({
                    ...tab,
                    route: `/app/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/sfdr/${tab.id}`,
                })),
            [portfolioId, snapshotId]
        )
    }
}

export const SFDR_MODULES = {
    OVERVIEW: 'overview',
    FUND_CONFIGURATION: 'fund_configuration',
    HOLDING_DATA: 'holding_data',
    HOLDING_DATA_DETAIL: 'holding_data_detail',
    DISCLOSURES: 'disclosures',
    DISCLOSURES_DETAIL: 'disclosures_detail',
} as const

export default new SFDRController()
