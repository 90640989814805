import withInputValidations from '@ui/components/compositions/withInputValidations'

import { INumberInputProps, NumberInput } from '../NumberInput'
import Controller from './PercentageInput.controller'

export const PercentageInput = withInputValidations<INumberInputProps, string>(
    NumberInput,
    [Controller.betweenRange],
    { min: 0, max: 100 }
)
