import React from 'react';
import injectSheet from 'react-jss';
import propTypes from 'prop-types';

import Card from '../styleguide/Card';
import CardTitle from '../styleguide/CardTitle';


const InstrumentSummaryCard = ({ instrument, classes }) => {
  return (
    <Card padding={20}>
        <CardTitle title="INSTRUMENT SUMMARY" />
        <div className={classes.info}>
        <p>{instrument.description}</p>
            { instrument.trbc_business_sector && (<p><strong>Sector:</strong> {instrument.trbc_business_sector}</p>) }
            { instrument.trbc_industry_group && (<p><strong>Industry Group:</strong> {instrument.trbc_industry_group}</p>) }
            { instrument.trbc_industry && (<p><strong>Industry:</strong> {instrument.trbc_industry}</p>) }
        </div>
    </Card>
  );
}


const style =  {
    info: {
        '& > :not(:last-child)': {
          marginBottom: '0.88rem'
        },
        fontSize : '15.5px',
        lineHeight: '20px'
    },
}  


export default injectSheet(style)(InstrumentSummaryCard);





