import axios from 'axios'
import qs from 'query-string'

export const loadList = (
  { setList, setLastPage, setBadge, setPortfolioData },
  query
) => {
  if (!query) return
  const badgeId = qs.parse(window.location.search).badgeId

  axios
    .get(`/app/api/badges/${badgeId}/instruments?${query}`)
    .then(res => res.data)
    .then(data => {
      setPortfolioData({ name: data.portfolio_name, id: data.portfolio_id })
      setList(data.list)
      setLastPage(data.total_pages)
      setBadge(data.badge)
    })
}
