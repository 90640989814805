import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  margin: 0;
  padding: 1.25rem;
  border-radius: 6px;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.03);
  background-color: white;

  // standard uppercase card title styles for when including title prop
  .card-title-base {
    margin: 0 0 1.25rem;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1;
    font-weight: 700;
  }
`

export const Card = ({ children, className, title }) => {
  return (
    <StyledDiv className={className}>
      {title && <h4 className='card-title-base'>{title}</h4>}
      {children}
    </StyledDiv>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
}
