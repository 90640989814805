import React, { useEffect, useMemo, useState } from 'react'
import ReactGA from 'react-ga'

import FilterTableButton from '../../../../../../yb_components/styleguide/buttons/filter_table_button/FilterTableButton'
import { countFilters } from '../../helpers'
import FilterTooltipWrapper from '../FilterTooltipWrapper'
import MetricsTooltip from './MetricsTooltip'

const MetricsFilter = ({ defaultData, options, onUpdate }) => {
  /* State */
  const [isOpen, setIsOpen] = useState(false)
  const [curData, setCurData] = useState({})

  useEffect(() => {
    setCurData({ ...defaultData })
  }, [defaultData])

  const filterCount = useMemo(() => {
    return countFilters(curData)
  }, [curData])

  const filterLabel = useMemo(() => {
    if (filterCount) {
      return filterCount > 1
        ? `${filterCount} Metrics`
        : `${filterCount} Metric`
    } else return 'Metrics'
  }, [filterCount])

  /* Events */
  const onChange = (filterType, value) => {
    setCurData({ ...curData, [filterType]: value })
  }

  const onCancel = () => {
    ReactGA.event({
      category: 'Portfolio Holdings Table',
      action: 'Close Modal',
      label: `Metrics`,
    })
    setCurData(defaultData)
    setIsOpen(false)
  }

  const onUpdateLocal = () => {
    onUpdate?.(curData, 'metrics')
    setIsOpen(false)
  }

  /* Render */
  const Tooltip = (
    <MetricsTooltip
      data={curData || {}}
      options={options}
      onChange={onChange}
      onCancel={onCancel}
      onUpdate={onUpdateLocal}
    />
  )

  return (
    <FilterTooltipWrapper
      onClickOutside={onCancel}
      displayTooltip={isOpen}
      tooltip={Tooltip}
    >
      <FilterTableButton
        label={filterLabel}
        active={filterCount && !isOpen}
        onClick={() => setIsOpen(prev => !prev)}
      />
    </FilterTooltipWrapper>
  )
}

export default MetricsFilter
