import React, { useState } from 'react';
import SectionWrapper from "../../../../../components/styleguide/SectionWrapper";
import { holdingManageButtons } from "../../../holding/constants";

import HoldingConfigurationSection from "../components/HoldingConfigurationSection";
import SqrButtonCase from "../../../components/SqrButtonCase";
import HoldingManageKeyPersonModule from "../../../holding/modules/manage/HoldingManageKeyPersonModule";

const ManageHoldingModule = ({ holding, updateHolding, errors = {}}) => {
    const [currTab, setCurrTab] = useState("Basic Information")

    const currModule = () => {
        switch(currTab){
            case "Basic Information":
                return <HoldingConfigurationSection holding={holding} updateHolding={updateHolding} errors={errors}/>
            case "Key People":
                return <HoldingManageKeyPersonModule holding={holding} config={{fundTableMargin:'0px', buttonWrapperWidth:"100%"}}/>
        }
    };

    return(
        <SectionWrapper data-cy="manage-fund-wrapper">
            <SectionWrapper data-cy="manage-fund-side-button-wrapper" width="20%" height="25%" margin="45px 0px 0px 0px">
                <SqrButtonCase
                    onChange={(tab) => setCurrTab(tab)}
                    buttons={holdingManageButtons}
                    dataLabel="manage-holding-side-button::manage-buttons"
                    activeButton={currTab}
                    styles={{width:'100%'}}
                />
            </SectionWrapper>
            <SectionWrapper style={{minHeight:'180px'}} width="80%" data-cy="manage-fund-module-wrapper" >
                {currModule()}
            </SectionWrapper>
        </SectionWrapper>
    )
}

export default ManageHoldingModule;
