import { SelectControl } from '@ui/components/interactive/Select'
import React from 'react'

// import theme from './HoldingAlignmentDetailFilters.module.scss'
import './HoldingAlignmentDetailFilters.module.scss'

export const HoldingAlignmentDetailFilters: React.FC = () => {
  return (
    <div className='HoldingAlignmentDetailFilters'>
      <SelectControl
        name='dataCategory'
        label='Data categories'
        options={[
          new Option('All', 'all'),
          new Option('Category 1', '0'),
          new Option('Category 2', '1'),
          new Option('Category 3', '2'),
        ]}
      />
    </div>
  )
}
