import { useEffect, useMemo, useState } from 'react'
import { SFDR_MODULES } from '../../../../SFDR.controller'
import React from 'react'
import { getSFDRDisclosures } from '../../../../../../../../../react/repositories/portfolio_repository'

const mockCompanyData = [
  { key: <a href=''>Company 1</a>, value: '10%' },
  { key: <a href=''>Company 2</a>, value: '10%' },
  { key: <a href=''>Company 3</a>, value: '10%' },
  { key: <a href=''>Company 4</a>, value: '10%' },
  { key: <a href=''>Company 5</a>, value: '10%' },
  { key: <a href=''>Company 6</a>, value: '10%' },
  { key: <a href=''>Company 7</a>, value: '10%' },
  { key: <a href=''>Company 8</a>, value: '10%' },
]

class SFDRDashboardController {
  useSFDRRoutes = (portfolioId: string, snapshotId: string) =>
    useMemo(() => {
      const base = `/app/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/sfdr`

      return [
        `${base}/${SFDR_MODULES.FUND_CONFIGURATION}`,
        `${base}/${SFDR_MODULES.HOLDING_DATA}`,
        `${base}/${SFDR_MODULES.DISCLOSURES}`,
      ]
    }, [portfolioId, snapshotId])

  useAlignmentData = () => [
    { key: 'Aligned', value: '44%' },
    { key: 'Unaligned', value: '32%' },
    { key: 'No Alignment Data', value: '24%' },
  ]

  useCompanyAlignmentData = () => [mockCompanyData, mockCompanyData]

  useDisclosureTimelineData = (portfolioId, snapshotId) => {
    const [data, setData] = useState(null)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      const loadAsyncStuff = async () => {
        try {
          setLoading(true)

          const response = await getSFDRDisclosures(portfolioId, snapshotId, {
            sort_field: 'publish_date',
            sort_dir: 'desc',
          })

          const fData = response?.data?.disclosures?.map(e => ({
            key: (
              <a href={`disclosures/${e?.id}`}>
                {e?.type_name}
                {e?.description ? ` – ${e?.description}` : ''}
              </a>
            ),
            value: e?.publish_date || '–',
          }))

          setData(fData)
        } catch (error) {
          setError(error)
        } finally {
          setLoading(false)
        }
      }

      loadAsyncStuff()
    }, [])

    return { data, error, loading }
  }
}

export default new SFDRDashboardController()
