import React from 'react'
import styled from 'styled-components'

import { getProgressColor } from '../../helpers/esg_scale'

const StyledTable = styled.table`
  text-align: center;

  thead {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;

    tr {
      height: 40px;
    }
  }

  tbody {
    tr {
      height: 60px;
    }

    td {
      border: none !important;
      font-weight: bold;
      font-size: 22px;
      line-height: 26px;
    }

    td.score-type {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: #6c757d;
    }
  }
`

const ESGShortsLongsTable = ({ shorts, longs, customESGLabels }) => {
  return (
    <StyledTable className='shorts-longs-table'>
      <thead>
        <tr>
          <th></th>
          <th>ENVIRONMENTAL</th>
          <th>SOCIAL</th>
          <th>GOVERNANCE</th>
          <th>OVERALL</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='score-type'>SHORTS</td>
          <td
            style={{ color: getProgressColor(shorts.e_score, customESGLabels) }}
          >
            {shorts.e_score}
          </td>
          <td
            style={{ color: getProgressColor(shorts.s_score, customESGLabels) }}
          >
            {shorts.s_score}
          </td>
          <td
            style={{ color: getProgressColor(shorts.g_score, customESGLabels) }}
          >
            {shorts.g_score}
          </td>
          <td
            style={{
              color: getProgressColor(shorts.total_score, customESGLabels),
            }}
          >
            {shorts.total_score}
          </td>
        </tr>
        <tr>
          <td className='score-type'>LONGS</td>
          <td
            style={{ color: getProgressColor(shorts.e_score, customESGLabels) }}
          >
            {longs.e_score}
          </td>
          <td
            style={{ color: getProgressColor(shorts.s_score, customESGLabels) }}
          >
            {longs.s_score}
          </td>
          <td
            style={{ color: getProgressColor(shorts.g_score, customESGLabels) }}
          >
            {longs.g_score}
          </td>
          <td
            style={{
              color: getProgressColor(shorts.total_score, customESGLabels),
            }}
          >
            {longs.total_score}
          </td>
        </tr>
      </tbody>
    </StyledTable>
  )
}

export default ESGShortsLongsTable
