import React from 'react'
import styled from 'styled-components'

import {
  computeColor,
  formatRangeScore,
} from '../../modules/instrument/components/peers/helpers'
import {
  StyledSecondaryCellText,
  StyleMeasureType,
} from '../../modules/instrument/components/peers/styles'

export const StyledSecondaryCellTextPdf = styled(StyledSecondaryCellText)`
  font-size: 10px;
`

export const StyleMeasureTypePdf = styled(StyleMeasureType)`
  font-size: 8px;
`

const PeersScoreTableRowPDF = ({
  peers,
  scoreName,
  retrieveScore,
  retrieveScoreRange,
  normUnits,
  normUnitsShort,
  neutralIsZero = false,
  scoreFormatter,
  extraClassNames = [],
  dataDisplayType = 'zscore',
}) => {
  return (
    <tr>
      <td className={`name-column ${extraClassNames.join(' ')}`}>
        {scoreName}

        <StyleMeasureTypePdf>
          {normUnits ? `(${normUnits})` : ''}
        </StyleMeasureTypePdf>
      </td>
      {peers.map(entity => {
        const score = retrieveScore(entity)
        const scoreRange = retrieveScoreRange?.(entity)
        const fMin = formatRangeScore(scoreRange?.min)
        const fMax = formatRangeScore(scoreRange?.max)

        return (
          <td
            key={`table-cell-${entity.id}`}
            className='score-cell'
            style={{
              color:
                dataDisplayType == 'zscore'
                  ? computeColor(score, neutralIsZero)
                  : '#979CA6',
            }}
          >
            {scoreFormatter ? scoreFormatter?.(score) : score}
            <StyledSecondaryCellTextPdf>
              {dataDisplayType == 'zscore' &&
                fMin &&
                fMax &&
                `(${fMin} to ${fMax}${
                  normUnitsShort ? ` ${normUnitsShort}` : ''
                })`}
            </StyledSecondaryCellTextPdf>
          </td>
        )
      })}
    </tr>
  )
}

export default PeersScoreTableRowPDF
