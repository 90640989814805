import React from 'react'

// import theme from './Link.module.scss'
import './Link.module.scss'

export interface IProps extends React.HTMLAttributes<HTMLAnchorElement> {
  href: string
  testId?: string
  target?: '_blank' | '_self' | '_parent' | '_top' | 'framename'
  download?: boolean
}

export const Link: React.FC<IProps> = ({
  testId,
  href,
  children,
  className = '',
  ...htmlProps
}) => {
  return (
    <a
      data-cy={testId}
      href={href}
      className={`Link ${className}`}
      {...htmlProps}
    >
      {children}
    </a>
  )
}
