import React from 'react'

import Card from '../../../../../../components/styleguide/Card'
import SectionTitle from '../../../../../../components/styleguide/SectionTitle'
import SwitchButton from '../../../../../../components/styleguide/SwitchButton'
import Tooltip from '../../../../../../components/styleguide/Tooltip'
import FilledButton from '../../../../../../yb_components/styleguide/buttons/filled_button/FilledButton'
import {
  HOLDINGS_FILTERS_COPY,
  MODELED_SWITCH_OPTIONS,
} from '../../PortfolioHoldingsCard.constants'

const CoverageTooltip = ({ data, onChange, onCancel, onUpdate }) => {
  return (
    <Tooltip hideArrow noTransform inheritVisibility x='-130'>
      <Card width='450px' height='fit-content'>
        <SectionTitle
          title={HOLDINGS_FILTERS_COPY.coverage.title}
          className='filter-title'
        />
        <p className='filter-description'>
          {HOLDINGS_FILTERS_COPY.coverage.description}
        </p>
        <SwitchButton
          onClick={value => onChange?.(value)}
          active={data}
          options={MODELED_SWITCH_OPTIONS}
        />
        <div className='form-buttons-wrapper'>
          <div className='unstyled-button cancel-button'>
            <button onClick={() => onCancel?.('coverage')}>Cancel</button>
          </div>
          <FilledButton
            onClick={() => onUpdate?.('coverage')}
            label='UPDATE'
            borderRadio={'25px'}
            width='150px'
          />
        </div>
      </Card>
    </Tooltip>
  )
}

export default CoverageTooltip
