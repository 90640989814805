import React from 'react'
import Select from 'react-select-3'

import Button from '../../../components/styleguide/Button'
import Card from '../../../components/styleguide/Card'
import CardTitle from '../../../components/styleguide/CardTitle'
import SortButton from '../../../components/styleguide/SortButton'
import WrapperColumn from '../../../components/styleguide/WrapperColumn'
import WrapperRow from '../../../components/styleguide/WrapperRow'
import { MainTable } from '../../../components/tables/styles'
import {
  defaultFactor,
  factorOptions,
  factorOptionsPretty,
  instrumentsLimit,
  metricsComparator,
  sectorsLimit,
} from '../constants'
import SectorService from '../SectorService'
import { factorStyles } from '../styles'

export default class ESGMetricsTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      yearlyMetrics: [],
      sortDirection: null,
      sortColumn: null,
      selectedFactor: defaultFactor,
      selectedButton: null,
    }
    this.onFactorSelect = this.onFactorSelect.bind(this)
    this.onTableHeaderClick = this.onTableHeaderClick.bind(this)

    this.onSectorComparisonBttnClick =
      this.onSectorComparisonBttnClick.bind(this)
    this.onBestCompaniesBttnClick = this.onBestCompaniesBttnClick.bind(this)
    this.onWorstCompaniesBttnClick = this.onWorstCompaniesBttnClick.bind(this)
    this.onTopMarketCapCompaniesBttnClick =
      this.onTopMarketCapCompaniesBttnClick.bind(this)
  }

  componentDidMount() {
    SectorService.getMetrics().then(response => {
      this.setState({
        yearlyMetrics: response.data,
        sortDirection: defaultFactor.sortDirection,
        sortColumn: defaultFactor.sortColumn,
      })
    })
  }

  onFactorSelect = factorOpt => {
    const factor = factorOptions[factorOpt.value]

    SectorService.getMetrics({
      sortDirection: factor.sortDirection,
      sortColumn: factor.sortColumn,
      visibleFields: factor.visibleFields,
    }).then(response => {
      this.setState({
        selectedFactor: factor,
        sortColumn: factor.sortColumn,
        sortDirection: factor.sortDirection,
        yearlyMetrics: response.data,
      })
    })
  }

  onSectorComparisonBttnClick = () => {
    SectorService.getMetrics({
      targetType: 'TRBCIndustryGroup',
      sortColumn: this.state.selectedFactor.sortColumn,
      sortDirection: this.state.selectedFactor.sortDirection,
      visibleFields: this.state.selectedFactor.visibleFields,
      limit: sectorsLimit,
    }).then(response => {
      this.setState({
        yearlyMetrics: response.data,
        sortDirection: this.state.selectedFactor.sortDirection,
        sortColumn: this.state.selectedFactor.sortColumn,
        selectedButton: 'SectorComparison',
      })
    })
  }

  onBestCompaniesBttnClick = () => {
    SectorService.getMetrics({
      targetType: 'Instrument',
      sortColumn: this.state.selectedFactor.sortColumn,
      sortDirection: this.state.selectedFactor.sortDirection,
      visibleFields: this.state.selectedFactor.visibleFields,
      limit: instrumentsLimit,
    }).then(response => {
      this.setState({
        yearlyMetrics: response.data,
        sortDirection: this.state.selectedFactor.sortDirection,
        sortColumn: this.state.selectedFactor.sortColumn,
        selectedButton: 'BestCompanies',
      })
    })
  }

  onWorstCompaniesBttnClick = () => {
    const newDirection =
      this.state.selectedFactor.sortDirection === 'asc' ? 'desc' : 'asc'
    SectorService.getMetrics({
      targetType: 'Instrument',
      sortColumn: this.state.selectedFactor.sortColumn,
      sortDirection: newDirection,
      visibleFields: this.state.selectedFactor.visibleFields,
      limit: instrumentsLimit,
    }).then(response => {
      this.setState({
        yearlyMetrics: response.data,
        sortDirection: newDirection,
        sortColumn: this.state.selectedFactor.sortColumn,
        selectedButton: 'WorstCompanies',
      })
    })
  }

  onTopMarketCapCompaniesBttnClick = () => {
    const newVisibleFields =
      this.state.selectedFactor.visibleFields.concat('market_cap')
    SectorService.getMetrics({
      targetType: 'Instrument',
      industryGroupId: this.props.sectorId,
      sortColumn: 'market_cap',
      sortDirection: 'desc',
      visibleFields: newVisibleFields,
      limit: instrumentsLimit,
    }).then(response => {
      this.setState({
        yearlyMetrics: response.data,
        sortDirection: 'desc',
        sortColumn: 'market_cap',
        selectedButton: 'topMarketCap',
      })
    })
  }

  onTableHeaderClick = metric => {
    let newDirection

    if (this.state.sortColumn === metric.label) {
      newDirection = this.state.sortDirection === 'asc' ? 'desc' : 'asc'
    } else {
      newDirection = 'asc'
    }

    const sortedMetrics = this.state.yearlyMetrics.sort(
      metricsComparator(metric.label, newDirection)
    )
    this.setState({
      yearlyMetrics: sortedMetrics,
      sortColumn: metric.label,
      sortDirection: newDirection,
    })
  }

  render() {
    return (
      this.state.yearlyMetrics &&
      this.state.yearlyMetrics.length > 0 && (
        <Card>
          <WrapperRow styles={{ justify: 'space-between', align: 'center' }}>
            <CardTitle title='SASB CRITERIA'></CardTitle>
            <Select
              placeholder={factorOptionsPretty[0]}
              defaultValue={factorOptionsPretty[0]}
              menuShouldScrollIntoView={false}
              styles={factorStyles}
              isSearchable={false}
              options={factorOptionsPretty}
              onChange={this.onFactorSelect}
            />
          </WrapperRow>
          <WrapperRow styles={{ margin: '20px 0px 0px 0px' }}>
            <WrapperColumn styles={{ margin: '72px 0px 0px 0px' }}>
              <Button
                disabled={this.state.selectedButton === 'SectorComparison'}
                margin={'5px 0px 0px 0px'}
                borderRadius={'0px'}
                height={'25px'}
                width={'150px'}
                secondary
                onClick={this.onSectorComparisonBttnClick}
                label={'Sector Comparison'}
              />
              <Button
                disabled={this.state.selectedButton === 'BestCompanies'}
                margin={'5px 0px 0px 0px'}
                borderRadius={'0px'}
                height={'25px'}
                width={'150px'}
                secondary
                onClick={this.onBestCompaniesBttnClick}
                label={'Best Companies'}
              />
              <Button
                disabled={this.state.selectedButton === 'WorstCompanies'}
                margin={'5px 0px 0px 0px'}
                borderRadius={'0px'}
                height={'25px'}
                width={'150px'}
                secondary
                onClick={this.onWorstCompaniesBttnClick}
                label={'Worst Companies'}
              />
              <Button
                disabled={this.state.selectedButton === 'topMarketCap'}
                margin='5px 0px 0px 0px'
                borderRadius={'0px'}
                height={'25px'}
                width={'150px'}
                secondary
                onClick={this.onTopMarketCapCompaniesBttnClick}
                label={'Top 10 Market Cap'}
              />
            </WrapperColumn>
            <WrapperRow styles={{ width: '100%' }}>
              <MainTable
                look={{
                  textAlign: 'left',
                  tdPadding: '0px 0px 0px 10px',
                  width: '100%',
                  tableMargin: '-20px 0px 0px 3px',
                }}
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    {this.state.yearlyMetrics[0].map((metric, i) => {
                      return (
                        <th key={`${metric.name}-${i}`}>
                          <span onClick={() => this.onTableHeaderClick(metric)}>
                            {metric.name}
                            <SortButton
                              active={
                                metric.name === this.state.sortField
                                  ? this.state.sortDirection
                                  : false
                              }
                            />
                          </span>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {this.state.yearlyMetrics.map((metrics, i) => {
                    return (
                      <tr key={`${metrics[0].target_name}-${i}`}>
                        <td>{metrics[0].target_name}</td>
                        {metrics.map((metric, i) => {
                          const metricValue = metric.value
                            ? parseFloat(metric.value).toFixed(2)
                            : '-'
                          return (
                            <td key={`${metric.name}-${i}`}>{metricValue}</td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </MainTable>
            </WrapperRow>
          </WrapperRow>
        </Card>
      )
    )
  }
}
