import React, { useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import styled from 'styled-components'

import Button from '../../../components/styleguide/Button'
import SpinnerContainer from '../../../yb_components/styleguide/spinners/SpinnerContainer'
import { loadPasswordResetRequest } from '../loaders/account_loaders'

const PasswordResetContainer = styled.form`
  transform: translate(
    ${props => (props.display ? '0%' : '-80%')},
    0%
  ) !important;
  position: relative;
  bottom: 30%;
`

const PasswordReset = ({ handlePasswordReset, activeMainSection }) => {
  const [display, setDisplay] = useState(false)

  const [response, setResponse] = useState(false)

  const [loading, setLoading] = useState(false)

  let emailVerified = false

  useEffect(() => {
    if (response?.status === 204) {
      emailVerified = true
      handlePasswordReset(emailVerified)
    }
  }, [response])

  useEffect(() => {
    activeMainSection === 'password-reset'
      ? setDisplay(true)
      : setDisplay(false)
  }, [activeMainSection])

  const [email, setEmail] = useState('')

  const handleEmailChange = e => {
    setEmail(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    loadPasswordResetRequest(email, setResponse, setLoading)
  }

  return (
    <PasswordResetContainer onSubmit={handleSubmit} display={display}>
      <h1 className='forgot-your-password-title'>Forgot your password?</h1>
      <Form.Group className='mb-3' controlId='passwordResetRequestEmail'>
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type='email'
          className='shadow-none'
          placeholder='Enter email'
          onChange={handleEmailChange}
          value={email}
        />
      </Form.Group>
      <div>
        <Button type='submit'>SEND RESET INSTRUCTIONS</Button>
        <span
          className='anchor-style small-grey-text'
          onClick={() => handlePasswordReset(emailVerified)}
        >
          Log in
        </span>
        <div className='response-container'>
          <SpinnerContainer
            className='password-reset-spinner'
            isLoading={loading}
          >
            {response && response.status !== 204 && (
              <Alert variant='danger'>{response.data}</Alert>
            )}
          </SpinnerContainer>
        </div>
      </div>
    </PasswordResetContainer>
  )
}

export default PasswordReset
