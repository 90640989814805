import React  from 'react';
import styled from 'styled-components';

const PortfolioIndexTable = styled.table`
    color:#212529;
    height:320px;
    table-layout: fixed;
    td{
        padding:0.5rem; 
        font-weight:700;
        font-size: 16px;
        width:100%;
        borderTop:1px solid #dee2e6;
        overflow: hidden;
        white-space:nowrap;
        text-overflow: ellipsis; 
    }
    td:nth-child(2) { 
        text-align:right;
    }
    tbody{
        tr {
            line-height:22px;
        }
    }
`

export default PortfolioIndexTable;
