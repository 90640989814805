import React, { useState, useEffect } from 'react';
import SectionWrapper from '../../../../../components/styleguide/SectionWrapper';
import { MultiPartDisplayHeader } from '../../../../../components/styleguide/MultiPartDisplay/MultiPartDisplayHeader';
import { MultiPartDisplayRowHeader } from '../../../../../components/styleguide/MultiPartDisplay/MultipartDisplayRowHeader';
import { MultiPartDisplay } from '../../../../../components/styleguide/MultiPartDisplay/MultiPartDisplay';
import MultiPartDisplayCustomFormRow from './MultiPartDisplayCustomFormRow';
import { SqrButton } from '../../../styles'
import AddMetricsModal from './AddMetricsModal';
import { getCustomReports, postFormItemTargets, postFormItems, postFormItemNotes, getFormItemNotes, postFormItemValues } from '../../../../../repositories/funds_repository';
import { monitoringAndEngagementHeaders, customFormHeadersTitelized, successfulMetricTargetAddMessage, problemAddingMetricTarget, successfulMetricValueAddMessage, successfulMetricAddMessage, problemAddingMetric, successfulNoteAddMessage } from '../../../../../constants/funds'
import { MultiPartDisplayRowWrapper } from '../../../../../components/styleguide/MultiPartDisplay/MultiPartDisplayRowWrapper';
import { MultiPartDisplayBody } from '../../../../../components/styleguide/MultiPartDisplay/MultiPartDisplayBody';
import Alert from '../../../../../components/styleguide/Alert'

const CustomFormsDisplay = ({ fundID, holdingID, dataLabel, scrollToTop, current_user, config }) => {

    const [currentForm, setCurrentForm] = useState({});
    const [forms, setForms] = useState([]);
    const [showAddMetriclModal, setShowAddMetriclModal] = useState(false);
    const [openForms, setOpenForms] = useState([]);
    const [alert, setAlert] = useState(null);

    useEffect(() => {
        getCustomReports({ fundID, holdingID }).then((response) => {
            let formWithItemsFound = false;
            for( let i = 0; i < response.data.forms.length && !formWithItemsFound && openForms.length < 1; i++ ){
                if(response.data.forms[i].items.length > 0){
                    formWithItemsFound = true;
                    response.data.forms[i].show = true;
                };
            };
            response.data.forms.map((form) => {
                openForms.map((openForm) => {
                    if(form.id === openForm.id){
                        form.show = true;
                    }
                })
            });
            setForms(response.data.forms.filter((form) => form.key !== "ifc_principle"));
            response.data.forms.map((form) => {
                form.items.map((item) => {
                    const formItemID = item.id;
                    getFormItemNotes(({ fundID, holdingID, formItemID }))
                    .then((response) => {
                        item.notes = response.data;
                    })
                })
            });
        })
        .catch((errors) => {
            console.log(errors);
        });
    },[fundID, holdingID, currentForm])

    const createNewMetricTarget = ( target, formItemID ) => {
        const params = {
            target: {
                value: target.target_value,
                as_of: new Date(),
                due_date: target.target_date,
            }
        }
        setAlert(null);
        assignOpenForms();
        postFormItemTargets({ fundID, holdingID, formItemID, params })
        .then(() => {
            setCurrentForm({});
            setAlert(successfulMetricTargetAddMessage);
            scrollToTop();
        })
        .catch((errors) => {
            setAlert(problemAddingMetricTarget);
        })
    }

    const createNewMetricValue = ( target, formItemID ) => {
        const params = {
            note: target.value_note,
            value: target.current_value,
        }
        assignOpenForms();
        setAlert(null);
        postFormItemValues({ fundID, holdingID, formItemID, params })
        .then(() => {
            setCurrentForm({});
            setAlert(successfulMetricValueAddMessage);
            scrollToTop();
        })
        .catch((error) => {
            setAlert(problemAddingMetricValue);
        })
    }

    const createNewMetric = ( form_item ) => {
        const params = {
            form_item : {
                form_key: currentForm.key,
                item_key: form_item.metric_name,

                metric: {
                    unit: form_item.unit,
                    description: form_item.description,
                    input_type:form_item.metric_name,
                },

                target: {
                    due_date: new Date(form_item.target_date),
                    note: "",
                    value: form_item.target_value,
                },

                note: form_item.value_note,

                initial_value: {
                    value: form_item.current_value,
                    as_of: new Date(),
                }
            }
        }
        assignOpenForms();
        setAlert(null);
        postFormItems({ fundID, holdingID, params })
        .then((response) => {
            setCurrentForm({});
            setAlert(successfulMetricAddMessage);
            scrollToTop();
        })
        .catch((errors) => {
            setAlert(problemAddingMetric);
        })
    }

    const createNewNote = ( body, formItemID ) => {
        const params = {
            note : {
                body,
            }
        }
        assignOpenForms();
        setAlert(null);
        postFormItemNotes({ fundID, holdingID, formItemID, params })
        .then((response) => {
            setCurrentForm({});
            setAlert(successfulNoteAddMessage);
            scrollToTop();
        })
        .catch((error) => {
            setAlert(problemAddingNote);
        })
    }

    const assignOpenForms = () => {
        setOpenForms(forms.filter((form) => form.show));
    }

    const getFormRows = ( form, wrapperIndex ) => {
        return <MultiPartDisplayRowWrapper key={`${dataLabel}-multipart-display-wrapper-${wrapperIndex}`} data-cy={`${dataLabel}-multipart-display-wrapper`}>
            {
                form.items.map((item, index) => {
                    return (
                        <MultiPartDisplayCustomFormRow
                            parity={(index % 2) === 0 ? "even" : "odd"}
                            item={item}
                            config={config}
                            current_user={current_user}
                            dataLabel={dataLabel}
                            key={`${dataLabel}-multipart-display-custom-form-row-${index}`}
                            updateTarget={createNewMetricTarget}
                            updateValue={createNewMetricValue}
                            createNewNote={createNewNote}
                        />
                    );
                })
            }
        </MultiPartDisplayRowWrapper>
    }

    const showForm = ( id ) => {
        const form = forms.find((form) => form.id === id);
        const newForms = forms.filter((form) => form.id !== id);
        const index = forms.findIndex((form) => form.id === id);
        form.show = !form.show;
        newForms.splice(index, 0, form);
        setForms(newForms);
    }


    return (
        <>
            <SectionWrapper data-cy={`${dataLabel}-custom-form-wrapper`}>
                {
                    alert && alert.length > 0 && <Alert type="success">{alert}</Alert>
                }
                <MultiPartDisplayHeader config={{dataLabel:`custom-form-display-${dataLabel}`}}>
                    {
                        monitoringAndEngagementHeaders.map((header, i) => {
                            return <SectionWrapper key={`${dataLabel}-header-wrapper-${i}`} flex={i === 0 ? "2" : "1" } data-cy={`${dataLabel}-header-wrapper`}>
                                {header}
                            </SectionWrapper>
                        })
                    }
                </MultiPartDisplayHeader>
                <MultiPartDisplay data-cy={`${dataLabel}-multi-part-display`}>
                    {
                        forms.map((form, formIndex) => {
                            if(form.items.length === 0){
                                return <div key={`${dataLabel}-multi-part-display-row-header-${formIndex}`}></div>;
                            } else {
                                return (
                                    <React.Fragment key={`${dataLabel}-multi-part-display-row-header-${formIndex}`}>
                                        <MultiPartDisplayRowHeader data-cy={`${dataLabel}-multi-part-display-row-header`}>
                                            <SectionWrapper flex='1' justify="space-between" data-cy={`${dataLabel}-multi-part-display`}>
                                                <SectionWrapper flex='1' data-cy={`${dataLabel}-multi-part-display::show-hide-header`}>
                                                    <span style={{cursor:'pointer'}} onClick={() => showForm(form.id)} data-cy={`${dataLabel}-multi-part-display::icon-wrapper}`}>{form.show ?
                                                      <i className="far fa-minus-circle" data-cy={`${dataLabel}-multi-part-display::icon-minus`}></i> :
                                                      <i className="far fa-plus-circle" data-cy={`${dataLabel}-multi-part-display::icon-plus`}></i>
                                                     }</span>{customFormHeadersTitelized[form.key]}
                                                </SectionWrapper>
                                                {/* <SectionWrapper flex='1' data-cy={`${dataLabel}-multi-part-display::metric-button-add`}>
                                                    {
                                                        form.key === "custom" && (
                                                            <SectionWrapper flex="1" justify="flex-end" data-cy={`${dataLabel}-multi-part-display::metric-button-add`}>
                                                                <SqrButton
                                                                    data-cy={`${dataLabel}-multi-part-display::metric-button`}
                                                                    onClick={() => {
                                                                        setCurrentForm(form);
                                                                        setShowAddMetriclModal(true);
                                                                    }}
                                                                    padding="0"
                                                                    width="30%"
                                                                >
                                                                    + Add Metric
                                                                </SqrButton>
                                                            </SectionWrapper>
                                                        )

                                                    }
                                                </SectionWrapper> */}
                                            </SectionWrapper>
                                        </MultiPartDisplayRowHeader>
                                        <MultiPartDisplayBody data-cy={`${dataLabel}-multi-part-display-body`}>
                                            {
                                                form.show && getFormRows(form, formIndex)
                                            }
                                        </MultiPartDisplayBody>
                                    </React.Fragment>
                                )
                            }
                        })
                    }
                </MultiPartDisplay>
            </SectionWrapper>
            {/* <AddMetricsModal
                dataLabel={dataLabel}
                show={showAddMetriclModal}
                form={currentForm}
                setShowAddMetriclModal={setShowAddMetriclModal}
                postMetric={createNewMetric}
            /> */}
        </>
    );
}

export default CustomFormsDisplay;
