import React from 'react'
import styled from 'styled-components'

import { SmallText } from '../../../../../../yb_styles/typography'

const StyledTableHead = styled.thead`
  th {
    ${SmallText}
    font-weight: bold;
    padding: 0 2px 10px;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    vertical-align: bottom;

    & small {
      font-weight: normal;
      text-transform: initial;
      line-height: 1.2 !important;
    }
  }
`

const StyledCategory = styled.th`
  padding: 0;
  vertical-align: bottom;
`

export const TcfdTableHead = () => {
  return (
    <StyledTableHead>
      <tr>
        <StyledCategory colSpan='5' />
        <StyledCategory colSpan='3'>Market Risk</StyledCategory>
        <StyledCategory colSpan='2'>Reputation Risk</StyledCategory>
        <StyledCategory colSpan='2'>Physical Risk</StyledCategory>
      </tr>
      <tr>
        <th>Name</th>
        <th>Weight</th>
        <th>Overall</th>
        <th>
          Policy & <br />
          Litigation
        </th>
        <th>
          Tech. & <br />
          Adaptation
        </th>
        <th>
          <small>Old/New Economy</small>
        </th>
        <th>
          <small>Ebbing Market Demand</small>
        </th>
        <th>
          <small>Price of Carbon</small>
        </th>
        <th>
          <small>T.P.I.</small>
        </th>
        <th>
          <small>Industry Enviro. Impacts</small>
        </th>
        <th>
          <small>Climate Risk for Industries</small>
        </th>
        <th>
          <small>Moody’s Facilities Risk</small>
        </th>
      </tr>
    </StyledTableHead>
  )
}
