import propTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { Dropdown } from 'react-bootstrap'
import styled from 'styled-components'

import { UserAvatar } from '../../styleguide/user_avatars/UserAvatar'

const StyledButton = styled.button`
  --button-color-active: #d6eeff;

  margin: 0 -8px 0 0;
  padding: 12px 8px;
  height: 40px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  line-height: 1.1;
  color: white;
  background-color: transparent;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    color: var(--button-color-active);
  }
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    color: var(--button-color-active);
  }
  > div,
  img {
    margin-right: 10px;
  }
  svg {
    margin: 2px 0 0 10px;
    transition: fill 0.15s ease-in-out;
  }
`

const DropdownMenu = styled(Dropdown.Menu)`
  --dropdown-border-color: #dddddd;
  --link-color: #333333;
  --link-color-highlight: #d6eeff;

  padding: 6px 0;
  min-width: 180px;
  background: #ffffff;
  border: 1px solid var(--dropdown-border-color);
  box-shadow: 0px 4px 12px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  a {
    padding: 4px 10px;
    font-size: 13px;
    line-height: 16px;
    color: var(--link-color);

    &:hover,
    &:focus,
    &:active {
      color: var(--link-color);
      background-color: var(--link-color-highlight);
    }
    &:focus-visible {
      outline: none;
    }
  }
  .dropdown-divider {
    margin: 6px 0;
    border-top-color: var(--dropdown-border-color);
  }
`

export const UserDropdown = ({ user }) => {
  const getUserUrls = () => {
    const userUrls = [
      { id: 1, name: 'Account settings', href: '/app/account/edit' },
      user.role === 'admin' || user.role === 'owner'
        ? {
            id: 2,
            name: 'Organization settings',
            href: '/app/organization/settings',
          }
        : null,
      // eslint-disable-next-line no-undef
      react_app_organization.organization.can_customize_esg_scores
        ? {
            id: 3,
            name: 'Organization ESG settings',
            href: '/app/organization/custom_esg_scoring',
          }
        : null,
      // eslint-disable-next-line no-undef
      react_app_organization.organization.can_access_addepar_integration
        ? {
            id: 4,
            name: 'Integrations',
            href: '/app/organization/integrations',
          }
        : null,
      user.role === 'admin' || user.role === 'owner'
        ? {
            id: 5,
            name: 'Manage users',
            href: '/app/organization/users',
          }
        : null,
      { id: 6, name: 'Knowledgebase', href: 'https://help.yves.blue/en' },
    ]
    const arr = userUrls.filter(el => el != null)
    return arr
  }

  // eslint-disable-next-line react/display-name
  const DropdownButton = forwardRef(({ children, onClick }, ref) => (
    <StyledButton
      className='user-dropdown-button btn'
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
      <svg width='8' height='5' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4 4.666a.664.664 0 0 1-.472-.195L.195 1.138a.666.666 0 1 1 .943-.943L4 3.058 6.862.196a.666.666 0 1 1 .943.942L4.472 4.472A.665.665 0 0 1 4 4.666Z'
          fill='currentColor'
        />
      </svg>
    </StyledButton>
  ))

  const onSignOut = () => {
    localStorage.removeItem('portfolio_holdings_table_filters')
  }

  return (
    <Dropdown style={{ zIndex: '200000' }} data-cy='user_dropdown'>
      <Dropdown.Toggle as={DropdownButton} id='user_dropdown_toggle'>
        <UserAvatar
          user={user}
          initials={`${user.first_name.charAt(0)}${user.last_name.charAt(0)}`}
          width={30}
          backgroundColor={'#00127C'}
        />
        {user.first_name} {user.last_name}
      </Dropdown.Toggle>
      <DropdownMenu data-cy='user_dropdown_menu'>
        {getUserUrls().map(({ name, href }, index) => (
          <Dropdown.Item key={index} href={href}>
            {name}
          </Dropdown.Item>
        ))}
        <Dropdown.Divider />
        <Dropdown.Item href='/app/sign_out' onClick={onSignOut}>
          Sign out
        </Dropdown.Item>
      </DropdownMenu>
    </Dropdown>
  )
}

UserDropdown.propTypes = {
  user: propTypes.object.isRequired,
}
