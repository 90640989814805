import React  from 'react';
import styled from 'styled-components';
import { APP_COLOR_ERROR, BORDER_COLOR_PRIMARY } from '../../styles/colors';

const TextAreaPlain = styled.textarea`
    display: block;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${props => props.error ? APP_COLOR_ERROR : BORDER_COLOR_PRIMARY};
    -webkit-appearance: none;
    :focus {
        outline: none;
    }
    width:${props => props.width};
    height:${props => props.height}
`

export default TextAreaPlain;
