import PropTypes from 'prop-types'
import React from 'react'

export const TcfdCompanyCell = ({ holdingDetailHref, row }) => {
  return (
    row && (
      <td
        style={{
          padding: '0 10px',
          width: '240px',
          borderRight: 'none',
          textAlign: 'left',
          lineHeight: 1.2,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            width: '220px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {row.target_id != null ? (
            <a
              style={{
                fontSize: '15px',
                fontWeight: '700',
                textOverflow: 'ellipsis',
              }}
              href={holdingDetailHref}
            >
              {row.name || '—'}
            </a>
          ) : (
            <span>{row.name || '—'}</span>
          )}
        </div>
      </td>
    )
  )
}

TcfdCompanyCell.propTypes = {
  holdingDetailHref: PropTypes.string,
  row: PropTypes.object.isRequired,
}
