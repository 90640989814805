import React from 'react'
import styled from 'styled-components'

import { APP_COLOR_PRIMARY } from '../../../styles/colors'

const StyledButton = styled.button`
  color: white;
  border-radius: 25px;
  text-transform: uppercase;
  font-size: 14px;
  padding: 10px 30px 10px 30px;
  background-color: ${APP_COLOR_PRIMARY};
  border: none;

  :disabled {
    background-color: #607abd;
  }
`
const Button = ({ text, onClick, ...props }) => {
  return <StyledButton {...props} onClick={onClick}>{text}</StyledButton>
}

export default Button
