import React, { useState, useEffect } from 'react';
import {ModuleWrapper, ModuleCardTitle, FundTableWrapper} from "../../../styles";
import SectionWrapper from "../../../../../components/styleguide/SectionWrapper";
import { getHoldingKeyPeople } from "../../../../../repositories/holdings_repository";
import Table from "../../../../../components/styleguide/Table";
import Button from "../../../../../components/styleguide/Button";
import AddHoldingKeyPersonModal from "../../components/AddHoldingKeyPersonModal";
import { resendHoldingKeyPersonConfirmationEmail } from "../../../../../repositories/holdings_repository";
import Alert from "../../../../../components/styleguide/Alert";
import WrapperColumn from "../../../../../components/styleguide/WrapperColumn";

const HoldingManageKeyPersonModule = ({holding, dataLabel}) => {
    const [keyPeople, setKeyPeople] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showConfirmationEmailSentAlert, setShowConfirmationEmailSentAlert] = useState(false);

    useEffect(() => {
        getHoldingKeyPeople(holding.id).then((response) => {
            setKeyPeople(response.data);
        });
    }, []);

    const handleModalShow = () => {
        setShowModal(true);
    };

    const handleModalHide = () => {
        setShowModal(false);
    };

    const handleModalSubmit = (newKeyPerson) => {
        const newKeyPeople = Array.from(keyPeople);
        newKeyPeople.push(newKeyPerson);
        setShowModal(false);
        setKeyPeople(newKeyPeople);
    };

    const tableRows = () => {
        return keyPeople.map((keyPerson) => {
            return [
                <span>{keyPerson.full_name }</span>,
                <span>{keyPerson.title}</span>,
                <span>{keyPerson.email}</span>,
                <span>{getConfirmedAtColumn(keyPerson)}</span>
            ]
        })
    };

    const getConfirmedAtColumn = (keyPerson) => {
        if (keyPerson.confirmed_at) {
            return (<div>{ keyPerson.confirmed_at }</div>)
        } else if (new Date() > new Date(keyPerson.confirmation_link_expires_at)) {
            return <div
                onClick={() => onResendConfirmationEmail(keyPerson)}
                style={{color: "blue", "text-decoration": "underline", cursor: "pointer", alignSelf:"center"}}
                data-cy={`${dataLabel}-ifc-principle-show:new-report-link`}
            >
                Confirmation Link Expired (Resend)
            </div>

        } else {
            return <div
                onClick={() => onResendConfirmationEmail(keyPerson)}
                id={keyPerson.id}
                style={{color: "blue", "text-decoration": "underline", cursor: "pointer", alignSelf:"center"}}
                data-cy={`${dataLabel}-ifc-principle-show:new-report-link`}
            >
                Resend Confirmation Email
            </div>
        }
    }

    const onResendConfirmationEmail = (keyPerson) => {
        resendHoldingKeyPersonConfirmationEmail({ holdingID: holding.id, keyPersonID: keyPerson.id }).then((response) => {
            const newConfirmationLinkExpiresAt = response.data.confirmation_link_expires_at;
            let newKeyPeople = keyPeople.slice();
            let newKeyPerson = newKeyPeople.find((newKeyPerson) => newKeyPerson.id === keyPerson.id);
            newKeyPerson.confirmation_link_expires_at = newConfirmationLinkExpiresAt;

            setShowConfirmationEmailSentAlert(true);
            setKeyPeople(newKeyPeople);
        });
    }

    const headers = [
        { label: "NAME", sort: false, id: "full_name" },
        { label: "TITLE" , sort: false, id: "title" },
        { label: "EMAIL" , sort: false, id: "email" },
        { label: "CONFIRMED AT", sort: false, id: "confirmed_at" }
    ];

    return (
        <ModuleWrapper width="100%" data-cy="holding-configuration-container">
            <WrapperColumn styles={{height:'100%', justify:"space-around"}}>
                <ModuleCardTitle title="Key People" data-cy="holding-configuration-module-card-title-one"/>
                { showConfirmationEmailSentAlert && <Alert type="success">Confirmation Email Successfully Sent.</Alert> }
                <SectionWrapper margin="5px 0px 5px 0px" width="100%" data-cy="holding-configuration-section-name-wrapper">
                    {
                        keyPeople.length > 0 &&
                        <FundTableWrapper style={{width:"100%"}}>
                            <Table width="100%"  body={tableRows()} headers={headers}/>
                        </FundTableWrapper>
                    }
                    {
                        keyPeople.length === 0 && <SectionWrapper margin="0px 0px 0px 10px"> You have no key people, yet. </SectionWrapper>
                    }
                </SectionWrapper>

                {
                    holding.current_app_user_can_update &&
                    <SectionWrapper margin="0px 0px 10px 4px" className="button-wrapper" width="15%">
                        <Button label="Add Key Person" onClick={handleModalShow}/>
                    </SectionWrapper>
                }
                <AddHoldingKeyPersonModal holding={holding} show={showModal} onModalHide={handleModalHide} onModalSubmit={handleModalSubmit} />
            </WrapperColumn>
        </ModuleWrapper>
    )
};

export default HoldingManageKeyPersonModule;
