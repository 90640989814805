import axios from 'axios'
import qs from 'qs'

export const getCarbonAuditData = query => {
  const fQuery = qs.stringify({ limit: 10, ...query }, { addQueryPrefix: true })
  return axios.get(`/app/api/carbon_audit${fQuery}`)
}

export const getCarbonAuditPerformers = query => {
  const fQuery = qs.stringify(query, { addQueryPrefix: true })
  return axios.get(`/app/api/carbon_audit/performers${fQuery}`)
}

export const downloadCSV = query => {
  const fQuery = qs.stringify({ limit: 20, ...query }, { addQueryPrefix: true })
  window.location.assign(`/app/api/carbon_audit/csv${fQuery}`)
}
