export const USER_ROLE_OPTIONS = [
  {
    value: '',
    text: 'All User Types',
  },
  {
    value: 'admin',
    text: 'Admin',
  },
  {
    value: 'owner',
    text: 'Owner',
  },
  {
    value: 'writer',
    text: 'Writer',
  },
  {
    value: 'reader',
    text: 'Read-Only',
  },
]
