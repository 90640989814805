import Popover from 'react-bootstrap/Popover'
import styled from 'styled-components'

import Card from '../Card'

const StyledBadges = styled(Card)`
  height: 100%;

  /* Slider */
  .slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: hand;
  }

  .badge-card {
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
    border-radius: 25px;
    :hover {
      background-color: #edeef3;
    }
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;

    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }

  img {
    margin-top: 10px;
    cursor: pointer !important;
  }

  .badges-container {
    margin-top: 30px;
  }

  .slider-container {
    padding: 20px;
  }

  .font-size-25 {
    font-size: 25px;
  }

  .arrow {
    margin-right: 10px;
    cursor: pointer;
    color: #989da8;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  li {
    display: -webkit-box;
  }

  .display-grid {
    width: 100%;
    margin-top: 18px;
    margin-left: 10px;
    font-size: 15.5px;
  }

  .progress-container {
    margin-top: 5px;
  }
  .holdings-counter {
    font-size: 0.9rem;
    color: #979ca6;
  }
  .description {
    padding-left: 0;
    font-size: 15.5px;
  }
  .right-subheader {
    display: flex;
    align-items: baseline;
    gap: 40px;
    & a {
      margin-bottom: 0px;
    }
  }
`

const StyledTitle = styled(Popover.Title)`
  padding: 10px;
`

const StyledContent = styled(Popover.Content)`
  padding: 10px;
`
const StyledContainer = styled(Popover)`
  margin-left: 30px;
  margin-top: -20px;
  border: none;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
`

export { StyledBadges, StyledContainer, StyledContent, StyledTitle }
