import React, { useCallback, useEffect, useMemo, useState } from 'react'

import ErrorPage from '../../../components/styleguide/ErrorPage'
import { completeMissingYears } from '../../../helpers/objects_and_arrays'
import { getColorFromLegendData } from '../../../helpers/styling'
import {
  getViolations,
  getViolationsStats,
} from '../../../repositories/violations_repository'
import { SUBSIDIES_VIOLATIONS_COLOR_SCHEME } from '../../../styles/colors'
import HeatMapCard from '../../../yb_components/cards/heat_map_card/HeatMapCard'
import OverTimeCard from '../../../yb_components/cards/over_time_card/OverTimeCard'
import PieCard from '../../../yb_components/cards/pie_card/PieCard'
import TargetBreadcrumbs from '../../../yb_components/styleguide/breadcrumbs/TargetBreadcrumbs'
import PageTextHeader from '../../../yb_components/styleguide/page_headers/PageTextHeader'
import { TargetType } from '../../../yb_constants/enums/global_enums'
import { USA_TOPO_JSON } from '../../../yb_constants/topoJSONs/usa'
import { convertToPieChartData } from '../../../yb_helpers/data_converters'
import { percentageFormatter, titleCase } from '../../../yb_helpers/strings'
import { loadTarget } from '../../../yb_services/api/loaders/loaders'
import ViolationsRecordsCard from './components/ViolationsRecordsCard'
import {
  HEADER_DESCRIPTION,
  HEADER_TITLE,
  OVER_TIME_CARD_DESCRIPTION,
  OVER_TIME_CARD_TITLE,
  RECORDS_TABLE_DESCRIPTION,
  RECORDS_TABLE_TITLE,
  VIOLATIONS_BUCKET_DATA,
  VIOLATIONS_STATS_QUERY,
} from './Violations.constants'
import {
  SecondCardRowsContainer,
  StyledOverTimeCard,
  ViolationsContainer,
} from './Violations.styles'

const Violations = ({
  id = null,
  targetData = null,
  targetType = null,
  statsData = null,
  recordsData = null,
}) => {
  /* State */
  const [target, setTarget] = useState(targetData)
  const [stats, setStats] = useState(statsData)
  const [records, setRecords] = useState(recordsData)
  const [loadingRecords, setLoadingRecords] = useState(false)
  const [error, setError] = useState(null)

  const targetPath = useMemo(() => {
    if (targetType === TargetType.PORTFOLIO_SNAPSHOT)
      return `portfolios/${id?.portfolioId}/snapshots`
    return targetType
  }, [id, targetType])

  const localId = useMemo(() => {
    if (targetType === TargetType.PORTFOLIO_SNAPSHOT) return id?.snapshotId
    return id
  }, [id, targetType])

  /* Requests */
  const loadViolationsStats = useCallback(() => {
    getViolationsStats(localId, {
      query: VIOLATIONS_STATS_QUERY,
      targetPath,
    })
      .then(response => {
        setStats(response.data.violations_stats)
      })
      .catch(err => {
        const error = err.response ? err.response.status : 502
        setError(error)
      })
  }, [localId, targetPath])

  const loadViolationsData = useCallback(
    (recordsQuery = '') => {
      setLoadingRecords(true)
      getViolations(localId, { query: recordsQuery, targetPath })
        .then(response => {
          setRecords(response.data)
          setLoadingRecords(false)
        })
        .catch(err => {
          const error = err.response ? err.response.status : 502
          setError(error)
        })
    },
    [localId, targetPath]
  )

  /* Use Effect */
  useEffect(() => {
    loadTarget({ id, targetType, setTarget, setError })
    loadViolationsStats()
    loadViolationsData()
  }, [id, loadViolationsData, loadViolationsStats, targetType])

  /* Useful functions */
  const initRowHeadersData = () => {
    if (!stats?.filter_values?.offense_group) return null

    /* Create the row headers used by LongitudinalTable and by OverTimeChart  */
    let result = []
    const offense_groups = stats.filter_values.offense_group
    for (const offense_group of offense_groups) {
      result.push({
        label: titleCase(offense_group),
        key: offense_group,
        omitFromChart: offense_group === 'total',
      })
    }

    /* Add total as well */
    result.push({
      label: 'Total',
      key: 'total',
      omitFromChart: true,
    })
    return result
  }

  /* Data & Memoized data */
  const overTimeTableRowHeaders = useMemo(initRowHeadersData, [stats])
  const showOverTimeCard = stats?.violations_over_time?.values
    ? stats.violations_over_time.values.length > 0
    : false

  const memoizedData = useMemo(
    () => completeMissingYears(stats?.violations_over_time?.values),
    [stats]
  )
  const memoizedPenaltyTypeData = useMemo(
    () => convertToPieChartData(stats?.penalty_type, 'offense_group'),
    [stats]
  )
  const memoizedPenaltySizeData = useMemo(
    () =>
      convertToPieChartData(stats?.penalty_size, 'label', 'percentage', v => v),
    [stats]
  )
  const memoizedPenaltiesStatesData = stats?.total_state_penalities
    ? stats.total_state_penalities
    : null
  const memoizedViolationsData = useMemo(() => records?.violations, [records])

  if (!target || !stats || error)
    return <ErrorPage code={error} message={error == 404 ? 'NOT FOUND' : ''} />
  return (
    <div className='page__container'>
      <ViolationsContainer>
        {/* Breadcrumb */}
        <TargetBreadcrumbs
          target={target}
          targetType={targetType}
          additionalBreadcrumbs={[
            { name: 'Corporate Violations', active: true },
          ]}
        />

        {/* Header */}
        <PageTextHeader
          data-cy={'violations_page_text_header'}
          title={HEADER_TITLE}
          description={HEADER_DESCRIPTION}
          marginBottom={'20px'}
        />

        {/* Violations Over Time */}
        <StyledOverTimeCard>
          <OverTimeCard
            data-cy={'violations_over_time_card'}
            show={showOverTimeCard}
            title={OVER_TIME_CARD_TITLE}
            description={OVER_TIME_CARD_DESCRIPTION}
            rowHeaders={overTimeTableRowHeaders}
            data={memoizedData}
            colorScheme={SUBSIDIES_VIOLATIONS_COLOR_SCHEME}
            marginBottom={'20px'}
          />
        </StyledOverTimeCard>

        <SecondCardRowsContainer>
          {/* Penalty Type */}
          <PieCard
            data-cy={'penalty_type_pie_card'}
            title={'Penalty Type'}
            data={memoizedPenaltyTypeData}
            tooltipValueFormatter={percentageFormatter}
            marginRight={'20px'}
          />

          {/* Penalty Size */}
          <PieCard
            data-cy={'penalty_size_pie_card'}
            title={'Penalty Size'}
            data={memoizedPenaltySizeData}
            tooltipValueFormatter={percentageFormatter}
            marginRight={'20px'}
          />

          {/* Total State Penalties */}
          <HeatMapCard
            data-cy={'total_state_penalties_card'}
            title={'Total State Penalties'}
            data={memoizedPenaltiesStatesData}
            geoUrl={USA_TOPO_JSON}
            legendData={VIOLATIONS_BUCKET_DATA}
            fillColor={geo =>
              getColorFromLegendData(
                geo,
                memoizedPenaltiesStatesData,
                VIOLATIONS_BUCKET_DATA,
                1000
              )
            }
          />
        </SecondCardRowsContainer>

        <ViolationsRecordsCard
          data-cy='penalties_records_card'
          stats={stats}
          title={RECORDS_TABLE_TITLE}
          description={RECORDS_TABLE_DESCRIPTION}
          data={memoizedViolationsData}
          loading={loadingRecords}
          maxPage={records ? records.max_page : 0}
          fetchData={loadViolationsData}
        />
      </ViolationsContainer>
    </div>
  )
}

export default Violations
