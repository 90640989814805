import { Button } from '@ui/elements/Button'
import React, { useCallback, useContext, useMemo, useState } from 'react'

import { saveSFDRAnswer } from '../../../../../../../react/repositories/portfolio_repository'
import { ArticleQuestionnaire } from './components/ArticleQuestionnaire'
import {
  questionnaires,
  TFormAnswer,
  TFormTrigger,
  TQuestionnaire,
} from './data'
import DisclosureDetailController from './DisclosureDetail.controller'
// import theme from './DisclosureDetail.module.scss'
import './DisclosureDetail.module.scss'
import SFDRDashbaordController from '../SFDROverview/components/SFDRDashboard/SFDRDashbaord.controller'
import { DisclosureFormsContext } from '../../../../../../../react/yb_stores/sfdr/disclosure_forms/DisclosureFormsStore'
import { DisclosureFormsActions } from '../../../../../../../react/yb_stores/sfdr/disclosure_forms/actions/DisclosureFormsActions'
import styled, { css } from 'styled-components'
import uuid from '@utils/uuid'
import { useLocation } from 'react-router-dom'

const S = {
  Container: styled.div`
    ${props =>
      !props.pdf &&
      css`
        /* --base-input-min-height: 10px;
        --base-input-width: 100%; */
      `}
  `,
}
interface IProps {
  pdf?: boolean

  portfolioId: string
  snapshotId: string
  disclosureId?: string

  defaultQuestionnaires?: TQuestionnaire[]
  defaultTab?: string
}

const DisclosureDetail: React.FC<IProps> = ({
  pdf,

  portfolioId,
  snapshotId,
  disclosureId,

  defaultQuestionnaires,
  defaultTab,
}) => {
  /* State */
  const [, dispatchformstate] = useContext(DisclosureFormsContext)

  const [reportType, setReportType] = useState<string>()
  const [questionnaires, setQuestionnaires] = useState<TQuestionnaire[]>(
    defaultQuestionnaires || []
  )

  const search = useLocation().search
  const searchParams = new URLSearchParams(search)

  const [selectedTab, setSelectedTab] = useState<string>(
    defaultTab || searchParams?.get('tab') || '1'
  )
  const questionnaireId = useMemo(() => Number(selectedTab) - 1, [selectedTab])

  DisclosureDetailController.useGetQuestionnaires(
    portfolioId,
    snapshotId,
    disclosureId,
    useCallback((reportType, newQuestionnaires, newTriggers, newAnswers) => {
      setReportType(reportType)
      setQuestionnaires(newQuestionnaires)
      dispatchformstate({
        type: DisclosureFormsActions.SET_TRIGGERS,
        payload: newTriggers,
      })
      dispatchformstate({
        type: DisclosureFormsActions.SET_ANSWERS,
        payload: newAnswers,
      })
    }, [])
  )

  const [, , disclosuresRoute] = SFDRDashbaordController.useSFDRRoutes(
    portfolioId,
    snapshotId
  )

  const reportTypeLabel = useMemo(
    () =>
      reportType === 'pre_contractual'
        ? 'Pre-Contractual Disclosure'
        : 'Periodic Disclosure',
    [reportType]
  )

  /* Events */
  const selectTab = (id: string) => {
    setSelectedTab(id)
  }

  const handleSubmit = (formData: unknown, validity: boolean) => {
    if (validity) {
      saveSFDRAnswer(
        portfolioId,
        snapshotId,
        disclosureId,
        formData,
        questionnaires?.[questionnaireId]?.db_id
      ).then(response => {
        setReportType(response?.data?.report_type)
        setQuestionnaires(response?.data?.questionnaires)

        dispatchformstate({
          type: DisclosureFormsActions.SET_TRIGGERS,
          payload: response?.data?.triggers,
        })

        dispatchformstate({
          type: DisclosureFormsActions.SET_ANSWERS,
          payload: response.data?.answers,
        })

        dispatchformstate({
          type: DisclosureFormsActions.SET_LOCAL_ANSWER,
          payload: response.data?.answers,
        })

        if (questionnaires?.length > Number(selectedTab)) {
          const nextTab = ((Number(selectedTab) || 0) + 1).toString()
          // Changed from `setSelectedTab(nextTab)`
          setSelectedTab(nextTab)
          // window.location.assign(
          //   `${disclosuresRoute}/${disclosureId}?tab=${nextTab}`
          // )
        } else {
          window.location.assign(disclosuresRoute)
        }
      })
    }
  }

  /* Render */
  return (
    <S.Container pdf={pdf}>
      <div className='DisclosureDetail'>
        {pdf ? (
          <p>
            <strong>{questionnaires?.[parseInt(selectedTab)]?.title}</strong>
          </p>
        ) : reportType ? (
          <p>
            <strong>{reportTypeLabel}</strong> ({reportTypeLabel} prior to
            convertible.)
          </p>
        ) : null}
        <div className='questionnaire'>
          <ul className='questionnaireNav'>
            {!pdf &&
              questionnaires?.map(questionnaire => (
                <li key={questionnaire.id}>
                  <Button
                    variant='link'
                    disabled={questionnaire.id === selectedTab}
                    onClick={selectTab.bind(null, questionnaire.id)}
                  >
                    {questionnaire.title}
                  </Button>
                </li>
              ))}
          </ul>
          <div className='content'>
            {questionnaires && (
              <ArticleQuestionnaire
                onSubmit={handleSubmit}
                key={questionnaireId}
                questionnaire={questionnaires[questionnaireId]}
              />
            )}
          </div>
        </div>
      </div>
    </S.Container>
  )
}

export default DisclosureDetail
