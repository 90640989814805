import React, { useEffect, useState } from 'react'
import { FormControl } from 'react-bootstrap'

import SwitchButton from '../../../../../../../../components/styleguide/SwitchButton'
import {
  PAI_COMPLETION_OPTIONS,
  PAI_REQUIRED_OPTIONS,
} from '../../filters/PaiFilters.constants'
import { StyledFiltersContainer } from '../../filters/PaiFilters.styles'

export const PaiIndicatorsFilters = ({ categories = [], onChange }) => {
  const [category, setCategory] = useState('all')
  const [displayRequirement, setDisplayRequirement] = useState('all')
  const [completionRate, setCompletionRate] = useState('all')

  useEffect(() => {
    onChange({ category, displayRequirement, completionRate })
  }, [category, displayRequirement, completionRate])

  useEffect(() => {
    if (!category) {
      setCategory(categories[0])
    }
  }, [categories])

  return (
    <StyledFiltersContainer>
      <div style={{ width: '300px' }}>
        <label htmlFor='categories_select'>Indicator Categories</label>
        <FormControl
          as='select'
          size='sm'
          name='categories_select'
          value={category}
          id='categories_select'
          onChange={evt => setCategory(evt.target.value)}
        >
          <option value='all'>All</option>
          {categories.map(category => (
            <option key={category} value={category}>
              {category.charAt(0).toUpperCase()}
              {category.slice(1).toLowerCase()}
            </option>
          ))}
        </FormControl>
      </div>
      <div>
        <label>PAI Tables</label>
        <SwitchButton
          onClick={setDisplayRequirement}
          active={displayRequirement}
          options={PAI_REQUIRED_OPTIONS}
        />
      </div>
      <div>
        <label>Completion Amount</label>
        <SwitchButton
          onClick={setCompletionRate}
          active={completionRate}
          options={PAI_COMPLETION_OPTIONS}
        />
      </div>
    </StyledFiltersContainer>
  )
}
