import React from 'react'

import { DisclosureDetail as DisclosureDetailComp } from '../../../../../../../ui/screens/Portfolios/RegulatoryFrameworks/SFDR/components/DisclosureDetail'
import DisclosureFormsStore from '../../../../../../yb_stores/sfdr/disclosure_forms/DisclosureFormsStore'
import ReportPage from '../../../../../yb_layout/report_page/ReportPage'

const DisclosureDetail = ({
  targetName,
  benchmarkName,
  disclosureId = undefined || '',
  portfolioId,
  snapshotId,
  triggers,
  answers,
  questionnaires,
}) => {
  return (
    <ReportPage
      title={'Disclosure Detail'}
      targetName={targetName}
      benchmarkName={benchmarkName}
    >
      <DisclosureFormsStore
        initialState={{
          triggers,
          answers,
          localAnswers: answers,
          isPdf: true,
        }}
      >
        {questionnaires?.map((questionnaire, index) => (
          <>
            <DisclosureDetailComp
              pdf={true}
              key={index}
              portfolioId={portfolioId}
              snapshotId={snapshotId}
              disclosureId={disclosureId}
              defaultTab={(index + 1).toString()}
              defaultQuestionnaires={questionnaires}
            />
            <div style={{ pageBreakBefore: 'always' }} />
          </>
        ))}
      </DisclosureFormsStore>
    </ReportPage>
  )
}

export default DisclosureDetail
