import { Bar } from '@nivo/bar'
import _ from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'

import { generateSplittedArrayByMaxValue } from '../../../helpers/number'
import {
  APP_COLOR_PRIMARY,
  BORDER_COLOR_SECONDARY,
  CHART_COLOR_E_SCORE,
  CHART_COLOR_G_SCORE,
  CHART_COLOR_S_SCORE,
  FONT_COLOR_PRIMARY,
  FONT_COLOR_SECONDARY,
} from '../../../styles/colors'
import GraphArea from './components/GraphArea.jsx'
import GraphBarComponent from './components/GraphBarComponent'
import GraphLine from './components/GraphLine.jsx'
import { DEFAULT_KEYS, DEFAULT_MAX_VALUE } from './ScoresStackedGraph.constants'

const ScoresStackedGraph = ({
  isPdfTemplate,
  data,
  benchmarkData,
  keys = DEFAULT_KEYS,
  width,
  height,
  maxValue = DEFAULT_MAX_VALUE,
  style,
}) => {
  /* Format Style data */
  let fStyle = null
  if (isPdfTemplate) {
    fStyle = {
      padding: 0.9,
      margin: {
        right: 20,
        left: 40,
      },
      axisLeft: {
        legendOffset: -35,
      },
      legend: {
        fontSize: 11,
      },
      tick: {
        fontSize: 10,
      },
    }
  }
  if (style) fStyle = { ...fStyle, ...style }

  /* Methods */
  const formatData = () => {
    if (!data?.length > 0) return data

    const eqWeekData = _.isEqual(
      benchmarkData?.map(elem => elem.week),
      data?.map(elem => elem.week)
    )

    return data
      .map((elem, index) => {
        const {
          e_insight_score,
          s_insight_score,
          g_insight_score,
          total_insight_score,
        } = elem
        const total =
          parseFloat(e_insight_score) +
          parseFloat(s_insight_score) +
          parseFloat(g_insight_score)

        const ePercentage = e_insight_score / total
        const sPercentage = s_insight_score / total
        const gPercentage = g_insight_score / total

        const eScore = ePercentage * total_insight_score
        const sScore = sPercentage * total_insight_score
        const gScore = gPercentage * total_insight_score

        let benchmarkScore = null
        if (eqWeekData) {
          benchmarkScore = parseFloat(benchmarkData[index].total_insight_score)
        }

        return {
          ...elem,
          index: index.toString(),
          eScore,
          sScore,
          gScore,
          benchmarkScore,
        }
      })
      .sort((a, b) => new Date(a.week) - new Date(b.week))
  }

  const formatAxisBottom = () => {
    let axisArr = generateSplittedArrayByMaxValue(
      data?.length,
      data?.length / 3
    )
    axisArr = axisArr.map(index => Math.round(index))
    axisArr.push(data?.length - 1)

    return axisArr
  }

  /* Data & Memoized data */
  const leftAxisValues = generateSplittedArrayByMaxValue(101, 25)
  const memoizedData = useMemo(() => formatData(), [data, benchmarkData])
  const memoizedAxisBottom = useMemo(() => formatAxisBottom(), [data])
  if (!data?.length) return null

  /* Render */
  const renderTick = tick => {
    let strokeColor = BORDER_COLOR_SECONDARY
    let text = ''
    if (memoizedAxisBottom?.includes(parseInt(tick.value))) {
      strokeColor = APP_COLOR_PRIMARY
      text = moment.utc(data[parseInt(tick.value)].week).format('MMMM DD YYYY')
    }
    return (
      <g transform={`translate(${tick.x},${tick.y + 19})`}>
        <line stroke={strokeColor} strokeWidth={2} y1={-22} y2={-15} />
        <text
          textAnchor='middle'
          dominantBaseline='middle'
          style={{
            fill: FONT_COLOR_SECONDARY,
            fontSize: fStyle?.tick?.fontSize || 12,
          }}
        >
          {text}
        </text>
      </g>
    )
  }

  return (
    <div>
      <Bar
        width={width}
        height={height}
        data={memoizedData}
        keys={keys}
        maxValue={maxValue}
        padding={fStyle?.padding || 0.8}
        margin={{
          top: 10,
          bottom: 36,
          right: fStyle?.margin?.right || 30,
          left: fStyle?.margin?.left || 55,
        }}
        indexBy='index'
        enableLabel={false}
        colors={[CHART_COLOR_E_SCORE, CHART_COLOR_S_SCORE, CHART_COLOR_G_SCORE]}
        axisLeft={{
          tickSize: 0,
          tickPadding: 10,
          tickValues: leftAxisValues,
          legend: 'Risk Score',
          legendPosition: 'middle',
          legendOffset: fStyle?.axisLeft?.legendOffset || -45,
        }}
        axisBottom={{
          renderTick: renderTick,
        }}
        gridYValues={leftAxisValues}
        barComponent={props => (
          <GraphBarComponent
            key={props.data.data.week}
            keys={keys}
            {...props}
          ></GraphBarComponent>
        )}
        layers={[
          'grid',
          'bars',
          'axes',
          GraphArea,
          GraphLine,
          GraphArea,
          'markers',
          'legends',
        ]}
        theme={{
          grid: {
            line: {
              stroke: BORDER_COLOR_SECONDARY,
              strokeWidth: 1,
            },
          },
          axis: {
            ticks: {
              text: {
                fill: FONT_COLOR_SECONDARY,
                fontSize: fStyle?.tick?.fontSize || 12,
              },
            },
            legend: {
              text: {
                fill: FONT_COLOR_PRIMARY,
                fontSize: fStyle?.legend?.fontSize || 13,
                fontWeight: 'bold',
              },
            },
          },
        }}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          precision: 'day',
        }}
      />
    </div>
  )
}
export default ScoresStackedGraph
