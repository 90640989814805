import { Box, IBoxProps } from '@ui/elements/grouping/Box'
import React from 'react'

// import theme from './ModuleCardFallback.module.scss'
import './ModuleCardFallback.module.scss'

export type IProps = IBoxProps

export const ModuleCardFallback: React.FC<IProps> = ({
  children,
  isPresent,
}) => (
  <Box isPresent={isPresent}>
    <div className='ModuleCardFallback'>{children}</div>
  </Box>
)
