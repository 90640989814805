import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { queryStringParse } from '../../../helpers/objects_and_arrays'
import { getAssetClasses } from '../../../repositories/managers_repository'

const StyledA = styled.a`
  cursor: pointer;
`

const SearchByAssetClass = ({ onAssetClassChange = () => {} }) => {
  const [assetClasses, setAssetClasses] = useState([])
  const [selectedAssetClassId, setSelectedAssetClassId] = useState(
    parseInt(queryStringParse().assetClassId)
  )

  useEffect(() => {
    getAssetClasses().then(response => {
      setAssetClasses(response.data)
    })
  }, [])

  const onClick = assetClassId => () => {
    let newValue = assetClassId
    if (assetClassId === selectedAssetClassId) {
      newValue = null
    }

    setSelectedAssetClassId(newValue)
    onAssetClassChange(newValue)
  }

  return (
    <div className='card mb-4'>
      <h6 className='card-header'>Search By Asset Class</h6>
      <div className='card-body'>
        {assetClasses.map(assetClass => (
          <StyledA
            key={`asset-class-${assetClass.id}`}
            className={`mr-1 badge badge-${
              assetClass.id === selectedAssetClassId ? 'primary' : 'secondary'
            }`}
            onClick={onClick(assetClass.id)}
          >
            {assetClass.name}
          </StyledA>
        ))}
      </div>
    </div>
  )
}

export default SearchByAssetClass
