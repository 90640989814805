import PropTypes from 'prop-types'
import React from 'react'

import { TcfdCompanyCell } from './tcfd_table_cells/TcfdCompanyCell'
import { TcfdTableColorCell } from './tcfd_table_cells/TcfdTableColorCell'
import { TcfdWeightCell } from './tcfd_table_cells/TcfdWeightCell'

export const TcfdTableRow = ({ portfolioId, row, snapshotId }) => {
  const holdingDetailHref = `/app/portfolios/${portfolioId}/snapshots/${snapshotId}/carbon/tcfd/holdings/${row.holding_id}`

  return (
    <tr>
      <TcfdCompanyCell holdingDetailHref={holdingDetailHref} row={row} />
      <TcfdWeightCell row={row} />
      <TcfdTableColorCell
        number={row.overall_score}
        href={`${holdingDetailHref}`}
      />
      <TcfdTableColorCell
        number={row.policy_and_legislation_score}
        href={`${holdingDetailHref}?active_risk=policy_and_legislation`}
      />
      <TcfdTableColorCell
        number={row.technology_and_adaptation_score}
        href={`${holdingDetailHref}?active_risk=technology_and_adaptation`}
      />
      <TcfdTableColorCell
        number={row.market_risk_1_score}
        href={`${holdingDetailHref}?active_risk=market_risk_1`}
      />
      <TcfdTableColorCell
        number={row.market_risk_2_score}
        href={`${holdingDetailHref}?active_risk=market_risk_2`}
      />
      <TcfdTableColorCell
        number={row.market_risk_3_score}
        href={`${holdingDetailHref}?active_risk=market_risk_3`}
      />
      <TcfdTableColorCell
        number={row.reputation_1_score}
        href={`${holdingDetailHref}?active_risk=reputation_1`}
      />
      <TcfdTableColorCell
        number={row.reputation_2_score}
        href={`${holdingDetailHref}?active_risk=reputation_2`}
      />
      <TcfdTableColorCell
        number={row.physical_risk_1_score}
        href={`${holdingDetailHref}?active_risk=physical_risk_1`}
      />
      <TcfdTableColorCell
        number={row.physical_risk_2_score}
        href={`${holdingDetailHref}?active_risk=physical_risk_2`}
      />
    </tr>
  )
}

TcfdTableRow.propTypes = {
  isSkeleton: PropTypes.bool,
  row: PropTypes.object.isRequired,
  portfolioId: PropTypes.string.isRequired,
  snapshotId: PropTypes.string.isRequired,
}
