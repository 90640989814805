import { abbreviateNumber } from '../helpers/number'

/**
 * Generates a title cased string
 *
 * @param {*} str A string object
 *
 * @returns A title cased string
 */
export const titleCase = (str, separators = [' ', '/', '-']) => {
  if (!str?.length) return null
  let result = str?.toLowerCase()

  for (const separator of separators) {
    result = result
      .split(separator)
      .map(function (word) {
        if (!word) return ''
        return word.replace(word[0], word[0].toUpperCase())
      })
      .join(separator)
  }

  return result
}

/**
 * Format number with a money label in front and corresponding abbdreviation at the end
 *
 * @param {number} value Value to be formatted
 *
 * @returns The formatted string
 */
export const moneyLabelFormatter = value => {
  return `$${abbreviateNumber(value)}`
}

/**
 * Format number with a money label in front and corresponding abbdreviation at the end
 *
 * @param {number} value The value to be formatted
 *
 * @returns The formatted string
 */
export const percentageFormatter = value => {
  const intValue = parseInt(value)
  if (intValue <= 0) return '< 1%'
  return `${intValue}%`
}
