import React from 'react';
import styled from 'styled-components';
import InputLabel from './InputLabel';
import { BORDER_COLOR_PRIMARY, APP_COLOR_DANGER, APP_COLOR_ERROR, BG_COLOR_SECONDARY  } from '../../styles/colors';
import { Modal, InputGroup, FormControl } from "react-bootstrap";
import SectionWrapper from './SectionWrapper';


const TextInputGroupWrapper = styled.div`
  margin: ${props => props.margin || '10px 0 6px 0'};
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom}`};
  ${props => props.marginRight && `margin-right: ${props.marginRight}`};
  position: relative;
  width: ${props => props.width};
  padding-bottom: ${props => props.paddingBottom || '18px'};
  padding: ${props => props.padding};

  input.prepend {
    border-left: none;
  }

  input.append {
    border-right: none;
  }

  input {
    display: block;
    width: 100%;
    height:${props => props.height};
    background-color:${props => props.disabled ? 'hsl(0,0%,95%)' : 'white'};
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${props => props.error ? APP_COLOR_ERROR : BORDER_COLOR_PRIMARY};
    -webkit-appearance: none;
    :focus {
      outline: none;
    }
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  input[type=number] {
    -moz-appearance:textfield;
  }
  .text-input-error {
    position: absolute;
    font-size: 12px;
    white-space: nowrap;
    left: 0;
    bottom: -6;
    color: ${APP_COLOR_ERROR};
    padding-top: 2px;
  }

  .input-group-append .input-group-text, .input-group-prepend .input-group-text {
    color: white;
    background-color: ${BG_COLOR_SECONDARY};
    border-color: ${BORDER_COLOR_PRIMARY};
  }
  
  .input-group-append .input-group-text {
    border-left: none;
  }
  
  .input-group-preppend .input-group-text {
    border-right: none;
  }
`;

const TextInput = ({ inputRef, className, label, value, placeholder, onChange, id, error, type = "text", width, height, defaultValue, disabled, prepend, append, onKeyPress, paddingBottom, margin, padding, marginRight, marginBottom, ...props }) => {
  
  const keyPressLocal = (e) => {
    if(e.key === 'Enter' || e.keyCode === 13){
      onKeyPress && onKeyPress(e);
    }
  }

  return (
    <TextInputGroupWrapper
      disabled={disabled}
      error={error}
      margin={margin}
      marginRight={marginRight}
      marginBottom={marginBottom}
      paddingBottom={paddingBottom}
      padding={padding}
      width={width}
      height={height}
    >
      {label && (<InputLabel label={label} />)}
      <InputGroup>
        {
          prepend &&
          <InputGroup.Prepend>
            <InputGroup.Text>{prepend}</InputGroup.Text>
          </InputGroup.Prepend>
        }
        <FormControl
          ref={inputRef}
          disabled={disabled}
          defaultValue={defaultValue}
          type={type}
          value={value}
          id={id}
          onKeyPress={(e) => keyPressLocal(e)}
          className={`${className} ${prepend ? "prepend" : ""} ${append ? "append" : ""}`}
          onChange={e => onChange(e.target.id, e.target.value, e)}
          placeholder={placeholder}
          data-cy={props['data-cy']}
        />
        {
          append &&
          <InputGroup.Append>
            <InputGroup.Text>{append}</InputGroup.Text>
          </InputGroup.Append>
        }
      </InputGroup>
      <div className="text-input-error">{error}</div>
    </TextInputGroupWrapper>
  );
}

export default TextInput;
