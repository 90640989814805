import { ResponsivePie } from '@nivo/pie'
import React from 'react'
import styled from 'styled-components'

import TextView from '../../../components/styleguide/TextView'
import Tooltip from '../../../components/styleguide/Tooltip'
import { PIE_COLOR_SCHEME } from '../../../styles/colors'
import { ColoringMethod } from '../../../styles/enums'
import useChartColoringMethods from '../../../yb_hooks/useChartColoringMethods'
import WordSplittedArcLinkLabel from './components/WordSplittedArcLinkLabel'

const StyledContainer = styled.div`
  height: ${props => props.height || '270px'};
  ${props => props.width && `width: ${props.width};`}
`

const PieChart = ({
  className,
  data,
  height,
  width,
  tooltipValueFormatter,
  tooltipLabelFormatter,
  margin = { top: 0, right: 55, bottom: 0, left: 55 },
  tooltipStyle = { width: '100px' },
  arcLinkLabelsDiagonalLength = 5,
  arcLinkLabelsStraightLength = 7,
  arcLinkLabelsThickness = 2,
  arcLinkLabelsTextOffset = 3,
  arcLinkLabelsTextColor = {
    from: 'color',
    modifiers: [['darker', 1.2]],
  },
  abbreviateLabels = true,
  arcLinkLabel = e => (
    <WordSplittedArcLinkLabel
      key={e.label}
      data={e}
      abbreviateLabels={abbreviateLabels}
    />
  ),
  theme = {
    labels: {
      text: {
        fill: 'white',
        fontWeight: 'bold',
        fontSize: 9,
      },
    },
    tooltip: {
      container: {
        background: '#333',
      },
    },
  },
  colorScheme = PIE_COLOR_SCHEME,
  coloringMethod = ColoringMethod.BLEND_EACH_DIFFERENT,
  'data-cy': dataCy,
  onClick = null,
}) => {
  /* Hooks */
  const { colors } = useChartColoringMethods(
    colorScheme,
    coloringMethod,
    data.length
  )

  if (!data) return null

  /* Render */
  const renderCustomToolTip = ({ value, label }) => {
    return (
      <Tooltip padding={10} width={tooltipStyle?.width} show={true}>
        <TextView
          bold
          center
          text={tooltipValueFormatter ? tooltipValueFormatter(value) : value}
        />
        <TextView
          center
          text={tooltipLabelFormatter ? tooltipLabelFormatter(label) : label}
        />
      </Tooltip>
    )
  }

  return (
    <StyledContainer
      className={className}
      data-cy={dataCy}
      width={width}
      height={height}
    >
      <ResponsivePie
        data={data}
        colors={colors}
        margin={margin}
        animate={true}
        arcLinkLabelsDiagonalLength={arcLinkLabelsDiagonalLength}
        arcLinkLabelsStraightLength={arcLinkLabelsStraightLength}
        activeOuterRadiusOffset={5}
        arcLinkLabelsSkipAngle={15}
        arcLinkLabelsThickness={arcLinkLabelsThickness}
        arcLinkLabel={arcLinkLabel}
        arcLabelsSkipAngle={10}
        arcLinkLabelsTextOffset={arcLinkLabelsTextOffset}
        enableArcLabels={false}
        arcLinkLabelsColor={{
          from: 'color',
        }}
        arcLinkLabelsTextColor={arcLinkLabelsTextColor}
        tooltip={({ datum }) => renderCustomToolTip(datum)}
        theme={theme}
        layers={['arcs', 'arcLinkLabels', 'arcLabels', 'legends']}
        onClick={onClick}
      />
    </StyledContainer>
  )
}

export default PieChart
