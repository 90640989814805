import React, { useState } from 'react'
import ReactGA from 'react-ga'

import Link from '../../../../../../components/styleguide/Link'
import TooltipWrapper from '../../../../../styleguide/tooltips/tooltip_wrapper/TooltipWrapper'
import LabelTooltip from './LabelTooltip'

const Label = ({ id, label, performers, sectorId }) => {
  /* State */
  const [isOpen, setIsOpen] = useState(false)

  /* Events */
  const onCancel = () => {
    setIsOpen(false)
  }

  /* Render */
  return (
    <TooltipWrapper
      zIndex={1}
      positioning='BOTTOM'
      clickFunctionality={true}
      onClickOutside={onCancel}
      displayTooltip={isOpen}
      tooltip={
        <LabelTooltip
          id={id}
          label={label}
          performers={performers}
          onClose={onCancel}
          sectorId={sectorId}
        />
      }
    >
      <Link
        onClick={() => {
          setIsOpen(prev => !prev)
          ReactGA.event({
            category: 'Carbon Audit',
            action: 'Open Sector Tooltip',
            label: `${!isOpen}`,
          })
        }}
      >
        {label}
      </Link>
    </TooltipWrapper>
  )
}

export default Label
