import styled from 'styled-components'

import Container from '../../components/styleguide/Container'
import SectionWrapper from '../../components/styleguide/SectionWrapper'
import {
  APP_COLOR_PRIMARY,
  BG_COLOR_SECONDARY,
  FONT_COLOR_PRIMARY,
  FONT_COLOR_SECONDARY,
} from '../../styles/colors'

const SectionWrapperSector = styled(SectionWrapper)`
  margin-top: 1.25rem;
  margin-bottom: 2.5rem;
`

const SubSectorWrapper = styled.div`
  font-size: 15.5px;
  line-height: 1.3;
  margin-bottom: 5px;

  .sub-sectors {
    margin-right: 10px;
    line-height: 1.3;
    margin-bottom: 5px;
  }

  > &:last-child {
    margin-top: 0.88rem;
  }
`

const CardTitleSector = styled.div`
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  margin-left: 8px;
`

const factorStyles = {
  container: () => ({ width: '25%' }),
  input: () => ({ height: 40 }),
  menu: style => ({
    ...style,
    top: null,
    marginTop: 0,
    maxWidth: '20%',
    marginLeft: '15px',
  }),
  placeholder: () => ({ color: FONT_COLOR_SECONDARY, padding: '7px' }),
  indicatorSeparator: () => ({ display: 'none' }),
  selectContainer: () => ({}),
  multiValueLabel: style => ({ ...style, color: APP_COLOR_PRIMARY }),
  control: styles => ({
    ...styles,
    backgroundColor: 'white',
    marginLeft: '10px',
    borderRadius: '9.5px',
    marginRight: '20px',
    borderColor: BG_COLOR_SECONDARY,
  }),
}

const SectorTitle = styled.div`
  padding-right: 20px;
  padding-top: 10px;
  font-size: 36px;
  font-weight: bold;
  color: ${FONT_COLOR_PRIMARY};
  margin-top: 0;
  margin-bottom: 1rem;
`

const StyledContainer = styled(Container)`
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  max-width: 1440px;
  position: relative;
`

export {
  CardTitleSector,
  factorStyles,
  SectionWrapperSector,
  SectorTitle,
  StyledContainer,
  SubSectorWrapper,
}
