import axios from 'axios';

export const getViolationsStats = (id, { query, targetPath }) => {
    const fTargetPath = targetPath ? targetPath : 'instruments';
    return axios.get(`/app/api/${fTargetPath}/${id}/violations/stats${query}`);
};

export const getViolations = (id, { query, targetPath }) => {
    const fTargetPath = targetPath ? targetPath : 'instruments';
    return axios.get(`/app/api/${fTargetPath}/${id}/violations${query}`);
};
