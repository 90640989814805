export const mapInitiativesToState = initiatives => {
  const mapped = {
    environmental: [],
    social: [],
    governance: [],
  }

  initiatives.forEach(i => {
    if (mapped[i.category]) {
      mapped[i.category].push({
        id: i.id,
        name: i.name,
        label: i.name,
        unit: i.unit,
        target_value: i.target_value,
      })
    } else {
      console.warn(
        `Unkown initiative category '${i.category}' in initiatives_mapper`
      )
    }
  })
  return mapped
}
