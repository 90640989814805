import React, { useEffect, useMemo, useState } from 'react'
import ReactGA from 'react-ga'

import FilterTableButton from '../../../../../../yb_components/styleguide/buttons/filter_table_button/FilterTableButton'
import { titleCase } from '../../../../../../yb_helpers/strings'
import { MODELED_SWITCH_OPTIONS } from '../../PortfolioHoldingsCard.constants'
import FilterTooltipWrapper from '../FilterTooltipWrapper'
import CoverageTooltip from './CoverageTooltip'

const CoverageFilter = ({ defaultData, onUpdate }) => {
  /* State */
  const [isOpen, setIsOpen] = useState(false)
  const [curData, setCurData] = useState(null)

  useEffect(() => {
    setCurData(defaultData)
  }, [defaultData])

  const filterLabel = useMemo(() => {
    if (!curData || (curData === 'all' && !isOpen)) return 'Coverage'
    if (curData) return titleCase(curData)
    return '—'
  }, [curData, isOpen])

  /* Events */
  const onChange = value => {
    setCurData(value)
  }

  const onCancel = () => {
    ReactGA.event({
      category: 'Portfolio Holdings Table',
      action: 'Close Modal',
      label: `Coverage`,
    })
    setCurData(defaultData)
    setIsOpen(false)
  }

  const onUpdateLocal = () => {
    onUpdate?.({ coverage: curData }, 'coverage')
    setIsOpen(false)
  }

  /* Render */
  const Tooltip = (
    <CoverageTooltip
      data={curData || MODELED_SWITCH_OPTIONS?.[0]}
      onChange={onChange}
      onCancel={onCancel}
      onUpdate={onUpdateLocal}
    />
  )

  return (
    <FilterTooltipWrapper
      onClickOutside={onCancel}
      displayTooltip={isOpen}
      tooltip={Tooltip}
    >
      <FilterTableButton
        label={filterLabel}
        active={!isOpen && curData != 'all'}
        onClick={() => setIsOpen(prev => !prev)}
      />
    </FilterTooltipWrapper>
  )
}
export default CoverageFilter
