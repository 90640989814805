import styled, { css, keyframes } from 'styled-components'

import {
  BG_COLOR_PRIMARY,
  BG_COLOR_WHITE,
  FONT_COLOR_SECONDARY,
} from '../../styles/colors'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

export const MainTable = styled.table`
  width: ${props => props.look.tableWidth || '100%'};
  margin: ${props => props.look.tableMargin};
  background-color: ${BG_COLOR_WHITE};
  font-size: 15.5px;

  border-spacing: 0;
  visibility: ${props => (props.look.out ? 'hidden' : 'visible')};
  animation: ${props => (props.look.out ? fadeOut : fadeIn)} 0.5s linear;

  th {
    ${props =>
      props.look?.th?.sticky
        ? css`
            position: sticky;
            top: 0;
          `
        : ''}
    ${props =>
      props.look?.th?.padding ? `padding: ${props.look?.th?.padding};` : ''}

    ${props =>
      props.look?.th?.transform
        ? `transform: ${props.look?.th?.transform};`
        : ''}

    ${props =>
      props.look?.th?.backgroundColor
        ? `background-color: ${props.look?.th?.backgroundColor};`
        : ''}

    > span {
      color: ${FONT_COLOR_SECONDARY};
      font-size: ${props => props.look?.thFontSize || '15.5px'};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      padding: ${props => props.look?.thSpanPadding || '0.94rem 0'};
      width: fit-content;
      margin: auto;
      &.sortable {
        cursor: pointer;
      }
    }

    &:first-child {
      padding-left: 0.94rem;

      > span {
        justify-content: flex-start;
      }
    }

    &:not(:first-child) {
      text-align: ${props => props.look.thTextAlign || 'left'};
    }

    &:nth-child(1) {
      width: ${props => props.look.thFirstChildWidth || 'auto'};
    }
    &:nth-child(2) {
      width: ${props => props.look.thSecondChildWidth || 'auto'};
    }
    &:nth-child(3) {
      width: ${props => props.look.thThirdChildWidth || 'auto'};
    }
    &:nth-child(4) {
      width: ${props => props.look.thFourthChildWidth || 'auto'};
    }
    &:nth-child(5) {
      width: ${props => props.look.thFifthChildWidth || 'auto'};
    }
    &:nth-child(6) {
      width: ${props => props.look.thSixthChildWidth || 'auto'};
    }

    &:last-child {
      padding-right: 0.94rem;
    }
  }

  tbody {
    visibility: ${props => (props.look.out ? 'hidden' : 'visible')};
  }

  tbody
    tr:nth-child(${props =>
        props.look.rowAlternation ? props.look.rowAlternation : 'even'}) {
    background-color: ${props =>
      props.look.trEvenBackgroundColor
        ? props.look.trEvenBackgroundColor
        : BG_COLOR_PRIMARY};
  }

  .skeleton-td {
    padding: 0.86rem 0;
    padding-right: 10px;
  }

  ${props =>
    props.look.rowAlternation &&
    `
    tbody tr:nth-child(even) {
      background-color: #ffffff; 
    }
  `}

  td {
    ${props =>
      props.look.tdCustomStyle &&
      `
      &:nth-child(${props.look.tdCustomStyle.nthChild})  {
        text-align: ${props.look.tdCustomStyle.textAlign};
        width: ${props.look.tdCustomStyle.width};
      }
    `}
    ${props =>
      props.look.verticalAlign &&
      `vertical-align: ${props.look.verticalAlign};`}
    ${props =>
      props.look?.tdFontSize ? `font-size: ${props.look?.tdFontSize};` : ''}
    padding: ${props => props.look.tdPadding || '0.94rem 0'};
    padding-left: ${props => props.look.tdPaddingLeft};

    > svg {
      padding: ${props => props.look.svgPadding || '5px'};
    }

    &:not(:first-child) {
      text-align: ${props => props.look.textAlign || 'center'};
      padding: ${props => props.look.tdPadding};
    }
    &:first-child {
      padding-left: ${props => props.look?.sideCellsPadding || '0.94rem'};
    }
    &:last-child {
      text-align: ${props =>
        props.look?.lastRow?.textAlign ? props.look?.lastRow?.textAlign : ''};
      padding-right: ${props => props.look?.sideCellsPadding || '0.94rem'};
      width: ${props =>
        props.look?.lastRow?.width ? props.look?.lastRow?.width : 'auto'};
    }
  }

  tfoot td {
    font-weight: bold;

    ${props =>
      props.look?.tfoot?.sticky
        ? css`
            position: sticky;
            bottom: 0;
          `
        : ''}

    ${props =>
      props.look?.tfoot?.backgroundColor
        ? `background-color: ${props.look?.tfoot?.backgroundColor};`
        : ''}

    ${props =>
      props.look?.tfoot?.margin ? `margin: ${props.look?.tfoot?.margin};` : ''}

    ${props =>
      props.look?.tfoot?.transform
        ? `transform: ${props.look?.tfoot?.transform};`
        : ''}
  }
  ${props =>
    props?.look?.tfoot?.customCol &&
    `tfoot td:nth-child(${props.look.tfoot.customCol.nthChild}){
    padding: ${props.look.tfoot.customCol.padding};
  }`}
  ${props => props.customStyle && props.customStyle}
`
