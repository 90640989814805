import React from 'react';

import { FundTitleWrapper, FundHeaderWrapper, ExportButtonHeaderWrapper, FundTitle } from '../styles';
import ExportButton from '../../../components/styleguide/ExportButton';

const FundHeader = ({ fund, onExport }) => {

  return (
    <FundHeaderWrapper>
      <FundTitleWrapper>
        <FundTitle>{fund.name}</FundTitle>
      </FundTitleWrapper>
      <ExportButtonHeaderWrapper>
        <ExportButton onClick={onExport} fileType="PDF" className="export-btn" />
      </ExportButtonHeaderWrapper>    
    </FundHeaderWrapper>  
    );
}


export default FundHeader;
