import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { FONT_COLOR_PRIMARY } from '../../../styles/colors'
import { BodyText } from '../../yb_styles/typography'
import Header from './components/ReportHeader'

const StyledContainer = styled.div`
  ${BodyText}
  color: ${FONT_COLOR_PRIMARY};
`

const ReportPage = ({
  title,
  asOf,
  targetName,
  benchmarkName,
  holdingName,
  disclaimerText,
  children,
}) => {
  const localAsOf = useMemo(() => dayjs(), [])

  return (
    <StyledContainer>
      <Header
        title={title}
        asOf={asOf || localAsOf}
        targetName={targetName}
        holdingName={holdingName}
        benchmarkName={benchmarkName}
        disclaimerText={disclaimerText}
      />
      {children}
    </StyledContainer>
  )
}

ReportPage.propTypes = {
  title: PropTypes.string,
  asOf: PropTypes.instanceOf(dayjs.Dayjs),
  targetName: PropTypes.string,
  holdingName: PropTypes.string,
  benchmarkName: PropTypes.string,
  disclaimerText: PropTypes.string,
  children: PropTypes.elementType,
}

export default ReportPage
