import propTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import cns from '../../helpers/classnames'
import { APP_COLOR_PRIMARY } from '../../styles/colors'
import Button from './Button'

const ExportButton = ({
  classes,
  fileType,
  className,
  onClick,
  label,
  icon,
  fontSize,
  height,
  padding,
}) => {
  return (
    <Button
      secondary
      onClick={onClick}
      className={cns(classes.exportBtn, className)}
      {...(fontSize ? { fontSize: fontSize } : {})}
      {...(height ? { height: height } : {})}
      {...(padding ? { padding: padding } : {})}
      label={[
        icon ? icon : <img key={0} src='/ico_export.svg' />,
        label ? label : `EXPORT TO ${fileType}`,
      ]}
      outline
    />
  )
}

ExportButton.propTypes = {
  fileType: propTypes.string.isRequired,
  className: propTypes.string,
  onClick: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
}

const style = {
  exportBtn: {
    height: 43,
    fontWeight: 'bold',
    padding: '0 20px',
    width: `fit-content`,
    transition: '0.5s',
    transitionTimingFunction: 'ease-in-out',
    '& img': {
      height: 20,
      width: 20,
      marginRight: '5px',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: APP_COLOR_PRIMARY,
    },
  },
}

export default injectSheet(style)(ExportButton)
