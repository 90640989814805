import React, { useEffect, useState } from 'react'

import { getOrganizationUserById } from '../../../repositories/organizations_repository'
import { BreadcrumbNav } from '../../../yb_components/styleguide/breadcrumbs/BreadcrumbNav'
import PageCard from '../../../yb_components/styleguide/cards/PageCard'
import { CreateUpdateUserForm } from './components/forms/CreateUpdateUserForm'

export const EditUserPage = ({ userId, organization }) => {
  const [user, setUser] = useState(null)
  useEffect(() => {
    // get user call
    const loadUserById = async () => {
      try {
        const res = await getOrganizationUserById(userId)
        if (res != null) setUser(res)
      } catch (e) {
        console.error('get user error', e)
      }
    }
    loadUserById()
  }, [])

  const breadcrumbLinks = [
    {
      url: '/app',
      text: 'Home',
      active: false,
    },
    {
      url: '/app/organization',
      text: `${organization?.name ? organization.name : 'Organization'}`,
      active: true,
    },
    {
      url: '/app/organization/users',
      text: 'User Management',
      active: false,
    },
    {
      url: `/app/organization/users/${userId}`,
      text: user?.full_name ? user.full_name : 'Edit User',
      active: true,
    },
  ]

  return user ? (
    <div className='page__container'>
      <header style={{ padding: '1.25rem 0' }}>
        <BreadcrumbNav links={breadcrumbLinks} />
      </header>
      <PageCard title={'User Management'}>
        <CreateUpdateUserForm user={user} isEditing={true} />
      </PageCard>
    </div>
  ) : (
    <div style={{ paddingTop: '20px' }} className='page__container'>
      <BreadcrumbNav
        links={[
          {
            url: '/app',
            text: 'Home',
            active: false,
          },
        ]}
      />
      <PageCard title={'User Management'}>
        <span></span>
      </PageCard>
    </div>
  )
}
