import React from 'react';
import injectSheet from 'react-jss';
import propTypes from 'prop-types';

import { BG_COLOR_SECONDARY } from '../../styles/colors';

const ErrorPage = ({ classes, code, message }) => {
  return (
    <div className={classes.container}>
      <span className="error-code">{code}</span>
      <span className="error-message">{message}</span>
    </div>
  );
}

ErrorPage.defaultProps = {
  code: 502,
  message: "An error occurred. Please try again later."
};

ErrorPage.propTypes = {
  code: propTypes.number.isRequired,
  message: propTypes.string,
  classes: propTypes.object.isRequired
}

const style = { 
  container: {
    display: 'flex',
    margin:'auto',
    height: 'calc(100vh - 124px)',
    flexDirection: 'column',
    alignItems: 'center',
    color: BG_COLOR_SECONDARY,
    fontWeight: 'bold',
    
    '& .error-code': {
      marginTop: 50,
      fontSize: 190
    },
    '& .error-message': {
      fontSize: 60
    }
  }
};

export default injectSheet(style)(ErrorPage);