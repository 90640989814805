import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)

const SnapshotCreatedEvent = ({ event }) => {
  const formattedAsOf = dayjs(event.data.snapshot_as_of).utc().format('MMM D, YYYY')
  
  return (
    <>
      <span className="event-user-name">
        {event.context.full_name}
      </span> created new portfolio snapshot {event.targetExists ?
          <a href={`/app/portfolios/${event.data.portfolio_id}/snapshots/${event.data.snapshot_id}`}>
              {event.data.portfolio_name}
          </a> :
          <span className="event-user-name">{event.data.portfolio_name}</span>
        } as of {formattedAsOf}.
      <span className="event-date">{event.formattedTimestamp}</span>
    </>
  );
};

export default SnapshotCreatedEvent;
