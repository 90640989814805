import dayjs from 'dayjs'
import propTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import Switch from 'react-switch'

import { APP_COLOR_PRIMARY } from '../../../../../styles/colors'
import { NotesList } from '../../../components/notes/NotesList'
import { AddNoteForm } from '../forms/AddNoteForm'

const displayValueWithUnit = (value, unit) => {
  let out = ''
  if (value != null) {
    out += value.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
    if (unit != null) {
      out += ` ${unit}`
    }
  } else {
    out = '—'
  }
  return out
}

export const RiskAssessmentRow = ({
  row,
  i,
  classNames,
  onSwitchChange,
  onEditClick,
  entityId,
  onSuccess,
  showNotesParent,
}) => {
  // notes
  const [showNotes, setShowNotes] = useState(showNotesParent)
  const onToggleNotesSectionClick = (id, e) => {
    e.preventDefault()
    setShowNotes(!showNotes)
  }
  useEffect(() => {
    setShowNotes(showNotesParent)
  }, [showNotesParent])
  // enable/disable switch
  const handleSwitchChange = (newStatus, id) => {
    onSwitchChange(newStatus, id)
  }
  // launch modals to create/update vals
  const handleEditClick = (i, val) => {
    onEditClick(i, val)
  }

  return (
    <React.Fragment>
      <tr className={classNames}>
        <td rowSpan='2'>
          <div className='status-switch'>
            <Switch
              onChange={() => handleSwitchChange(!row.status, row.id)}
              checked={!!row.status}
              uncheckedIcon={false}
              checkedIcon={false}
              height={18}
              width={36}
            />
          </div>
        </td>
        <td rowSpan='2'>
          <h5>{row.name}</h5>
          <p>{row.description}</p>
        </td>

        <td className='reported'>
          {row.previous_value != null
            ? displayValueWithUnit(row.previous_value, row.unit)
            : '—'}
        </td>

        <td className='target'>
          {row.target_value != null ? (
            <>
              <a
                className='target-value'
                onClick={() => handleEditClick(i, 'target_value')}
                title='Edit Target Value'
                disabled={!row.status ? 'disabled' : ''}
                tabIndex={row.status ? '0' : '-1'}
              >
                {displayValueWithUnit(row.target_value, row.unit)}
              </a>
              <span className='cell-date-span'>
                (by {dayjs(row.target_date).format('MMMM D, YYYY')})
              </span>
              {row.target_user ? (
                <span className='last-updated'>
                  <em>Last Updated by </em>
                  <span className='no-wrap'>{row.target_user}</span>
                </span>
              ) : null}
            </>
          ) : (
            <a
              className='current-value'
              onClick={() => handleEditClick(i, 'target_value')}
              title='Add Target Value'
              disabled={!row.status ? 'disabled' : ''}
              tabIndex={row.status ? '0' : '-1'}
            >
              Add Target
            </a>
          )}
        </td>

        <td className='current'>
          {row.current_value != null ? (
            <>
              <a
                className='current-value'
                onClick={() => handleEditClick(i, 'current_value')}
                title='Edit Current Value'
                disabled={!row.status ? 'disabled' : ''}
                tabIndex={row.status ? '0' : '-1'}
              >
                {displayValueWithUnit(row.current_value, row.unit)}
              </a>
              <span className='cell-date-span'>
                (as of {dayjs(row.current_as_of).format('MMMM D, YYYY')})
              </span>
              {row.current_user ? (
                <span className='last-updated'>
                  <em>Last Updated by </em>
                  <span className='no-wrap'>{row.current_user}</span>
                </span>
              ) : null}
            </>
          ) : (
            <a
              className='current-value'
              onClick={handleEditClick(i, 'current_value')}
              title='Add Current Value'
              disabled={!row.status ? 'disabled' : ''}
              tabIndex={row.status ? '0' : '-1'}
            >
              Add Current Value
            </a>
          )}
        </td>
        <td>
          <button
            className='btn notes-toggle-button'
            onClick={e => onToggleNotesSectionClick(row.id, e)}
          >
            <span className='notes-toggle-button-span'>
              Notes ({row.notes.length})
            </span>
            <svg
              className={showNotes ? 'open' : 'closed'}
              width='18'
              height='10'
              viewBox='0 0 18 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1 1L9 8L17 1'
                stroke={APP_COLOR_PRIMARY}
                strokeWidth='2'
              />
            </svg>
          </button>
        </td>
      </tr>
      <Collapse in={showNotes}>
        <tr className='notes-row' style={{ margin: '0', padding: '0' }}>
          <td></td>
          <td className='notes-td'>
            <NotesList notes={row.notes} />
            <AddNoteForm
              entityId={entityId}
              focusAreaId={row.id}
              onSuccess={onSuccess}
              disabled={row.status < 1}
            />
          </td>
        </tr>
      </Collapse>
    </React.Fragment>
  )
}

RiskAssessmentRow.propTypes = {
  row: propTypes.shape({
    id: propTypes.number.isRequired,
    name: propTypes.string.isRequired,
    unit: propTypes.string.isRequired,
    description: propTypes.string.isRequired,
    notes: propTypes.array.isRequired,
    current_value: propTypes.number.isRequired,
    current_as_of: propTypes.string,
    current_user: propTypes.string,
    target_date: propTypes.string,
    date: propTypes.string,
    target: propTypes.string,
    target_value: propTypes.number,
    previous_value: propTypes.number,
    status: propTypes.number.isRequired,
    target_user: propTypes.string,
  }).isRequired,
  entityId: propTypes.number.isRequired,
  showNotesParent: propTypes.bool.isRequired,
  i: propTypes.number,
  classNames: propTypes.string.isRequired,
  onSwitchChange: propTypes.func.isRequired,
  onEditClick: propTypes.func.isRequired,
  onSuccess: propTypes.func.isRequired,
}
