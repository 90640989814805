import styled from 'styled-components';


const YBSpan = styled.span`
    margin: ${props => props.margin};
    width: ${props => props.width};
    width: ${props => props.height};
    color: ${props => props.color};
    font-weight: ${props => props.fontWeight};
    font-size: ${props => props.fontSize};
`

export default YBSpan;
