import { mapTargetToState } from './target_mapper'

export const mapInstrumentToState = instrument => {
  return {
    description: instrument.description,
    holdings_total: instrument.holdings_total,
    has_holdings: instrument.has_holdings,
    trbc_business_sector: instrument.trbc_business_sector,
    trbc_industry_group: instrument.trbc_industry_group,
    trbc_industry: instrument.trbc_industry,
    entity_id: instrument.entity_id,
    benchmark_id: instrument.benchmark_id,
    benchmark_name: instrument.benchmark_name,
    proxied_instrument_id: instrument.proxied_instrument_id,
    esg_score: _mapESGScore(instrument),
    esg_scores: _mapESGScores(instrument),
    holdings: _mapHoldings(instrument),

    ...mapTargetToState(instrument),
  }
}

const _mapESGScore = instrument => {
  return {
    total_score: parseFloat(instrument.total_score).toFixed(0),
    e_score: parseFloat(instrument.e_score).toFixed(0),
    s_score: parseFloat(instrument.s_score).toFixed(0),
    g_score: parseFloat(instrument.g_score).toFixed(0),
  }
}

const _mapESGScores = instrument => {
  if (
    !instrument.esg_scores ||
    (instrument.esg_scores && instrument.esg_scores.length == 0)
  )
    return null

  return {
    environment: instrument.esg_scores.map(score => {
      return { date: score.year, score: +score.e_score }
    }),
    social: instrument.esg_scores.map(score => {
      return { date: score.year, score: +score.s_score }
    }),
    governance: instrument.esg_scores.map(score => {
      return { date: score.year, score: +score.g_score }
    }),
  }
}

const _mapHoldings = instrument => {
  if (!instrument.holdings || !instrument.holdings.length) return null

  return instrument.holdings.map(h => ({
    id: h.instrument_id,
    asset_class: h.asset_class || '-',
    symbol: h.ticker || '-',
    name: h.name,
    description: h.description,
    weight: (parseFloat(h.weight) * 100).toFixed(2),

    esg_score: {
      total_score: parseFloat(h.scores.total).toFixed(2),
      e_score: parseFloat(h.scores.e).toFixed(2),
      s_score: parseFloat(h.scores.s).toFixed(2),
      g_score: parseFloat(h.scores.g).toFixed(2),
    },
  }))
}
