import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { generateDrinkStats } from '@nivo/generators';
import Legend from './Legend';
import { FONT_COLOR_SECONDARY } from '../../../../../../styles/colors';
import styled from 'styled-components';


const leftAxisFormatter = (value) => {
  return `${value}%`
}

const bottomAxisFormatter = (value) => {
  const splitText = value.split(" ")
  return (
    <>
      <tspan x="0" dy="1.2em" fontWeight="700">{`${splitText[0]} ${splitText[1]}`}</tspan>
      <tspan x="0" dy="1.2em" fontWeight="700">{splitText[2]}</tspan>
    </>
  )
}
const GraphContainer = styled.div`
  height: 300px;
  margin-bottom: 60px;
`

const LongitudinalESGLineGraph = ({data}) => {
  const graphProps = {
    margin: { top: 50, right: 50, bottom: 60, left: 100 },
    data,
    animate: true,
    enableSlices: 'x',
    enableGridX: false,
    colors: { datum: 'color' },
    gridYValues: [0, 20, 40, 60, 80, 100],
    yScale: { type: 'linear', min: 0, max: 100},
    axisLeft: {
      format: leftAxisFormatter,
      tickValues: [0, 20, 40, 60, 80, 100],
      tickSize: 0,
      tickPadding: 30,
      legend: 'Percentile Rankings',
      legendOffset: -80,
      legendPosition: 'middle',
    },
    axisBottom: {
      format: bottomAxisFormatter,
      tickSize: 0,
      tickPadding: 10,
    },
    theme: {
      axis: {
        ticks: {
          text: {
            fill: FONT_COLOR_SECONDARY,
            fontSize: '12px',
          }
        },
        legend: {
          text: {
            fill: '#7B7B7B',
            fontSize: '12px',
            fontWeight: 'bold',
          }
        },
      },
    }
  }

  return (
    <>
      <Legend/>
      <GraphContainer>
        <ResponsiveLine
          {...graphProps}
        />
      </GraphContainer>
    </>
  )
}

export default LongitudinalESGLineGraph
