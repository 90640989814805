import React from 'react'

import Button from '../../../../components/styleguide/Button'

const UNACTIVE_STYLE = {
  tertiary: true,
  border: '1px solid #E2E3E7',
}

const DEFAULT_STYLE = {
  width: '200px',
  borderRadius: '4px',
  fontWeight: 'normal',
}

const FilterTableButton = ({ active, label, onClick, onBlur }) => {
  return (
    <Button
      {...DEFAULT_STYLE}
      {...(!active && UNACTIVE_STYLE)}
      label={label}
      onClick={onClick}
      onBlur={onBlur}
    />
  )
}

export default FilterTableButton
