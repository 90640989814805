import styled from 'styled-components';
import Card from '../../../../components/styleguide/Card';
import Container from '../../../../components/styleguide/Container';

export const StyledHoldingsEditTableCard = styled(Card)`
  margin-top: 30px;

  .text-right {
    text-align: right;
  }

  hr {
    margin: 0px;
  }

  thead {
    cursor: pointer;
  }
  
  tbody {
    tr {
      height: 68px;
    }
    
    td {
      padding: 0px 10px 0px 10px !important;
    }
    td.holding-name-column {
      width: 20vw;
    }

    td.holding-value-column {
      width: 15vw;
    }

    td.holding-action-column {
      text-align: center;
      width: 5vw;
    }
    .instrument-select {
      margin-top: 3px;
    }
  }

  .portfolio-total-value {
    margin-right: 5vw;

    .label {
      margin-right: 10px;
      font-size: 15px;
      line-height: 18px;
      text-align: right;
      color: #979CA6;
    }
    .input {
      display: inline-block;
      width: 15vw;
    }
  }
`

export const Footer = styled.div`
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  text-align: right;
  height: 80px;
`

export const Icon = styled.i`
  padding-left: 3px;
`

export const PortfolioEditContainer = styled(Container)`
  padding: 40px;
  input {
    height: 50px;
  }
`
