export const peersTableColumnCount = 6

export const emptyScorePlaceholder = '—'

export const dataDisplayOptions = [
  { id: 'raw', label: 'Raw' },
  { id: 'normalized', label: 'Normalized' },
  { id: 'zscore', label: 'Z-Scores' },
]

export const dataProviderDisplayOptions = [
  { id: 'ideal-ratings', label: 'Ideal Ratings' },
  { id: 'refinitiv', label: 'ESG Data' },
]

export const dataMapping = dataProvider => {
  if (dataProvider === 'ideal-ratings') {
    return {
      zscore: 'ideal_ratings_esg_metric_scores',
      raw: 'ideal_ratings_raw_esg_metrics',
      normalized: 'ideal_ratings_normalized_esg_metrics',
    }
  } else {
    return {
      zscore: 'esg_metric_scores',
      raw: 'raw_esg_metrics',
      normalized: 'normalized_esg_metrics',
    }
  }
}

export const peersCustomTableStyle = `
table-layout: fixed ; 
td:last-child {
  padding-right: 0
  width: auto;
}`
