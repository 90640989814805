import propTypes from 'prop-types'
import React, { useState } from 'react'
import ReactGA from 'react-ga'
import injectSheet from 'react-jss'

import cns from '../../helpers/classnames'
import { BG_COLOR_PRIMARY, FONT_COLOR_SECONDARY } from '../../styles/colors'

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_KEY)

const SearchBar = ({
  defaultSearch = '',
  value,
  classes,
  className,
  onSubmit,
  onChange,
  placeholder,
}) => {
  const [search, setSearch] = useState(defaultSearch)

  const onFormSubmit = e => {
    ReactGA.event({
      category: 'Main App',
      action: 'Search',
      label: `searched for:${search}`,
    })
    e.preventDefault()

    if (onSubmit) {
      onSubmit(search)
    }
  }

  const onInputChange = e => {
    setSearch(e.target.value)

    if (onChange) {
      onChange(e.target.value)
    }
  }

  return (
    <form onSubmit={onFormSubmit} className={cns(classes.search, className)}>
      <input
        value={value || search}
        onChange={onInputChange}
        placeholder={placeholder}
      />
      <img onClick={onFormSubmit} src='/search.svg' />
    </form>
  )
}

SearchBar.propTypes = {
  className: propTypes.string,
  onSubmit: propTypes.func,
  onChange: propTypes.func,
  placeholder: propTypes.string,
  bgColor: propTypes.string,
  classes: propTypes.object.isRequired,
}

SearchBar.defaultProps = {
  placeholder: 'Search content',
  bgColor: BG_COLOR_PRIMARY,
}

const style = {
  search: {
    backgroundColor: props => props.bgColor,
    padding: 10,
    paddingRight: 15,
    borderRadius: 7,
    verticalAlign: 'middle',

    '& input': {
      display: 'inline-block',
      color: FONT_COLOR_SECONDARY,
      fontSize: 18,
      width: 'calc(100% - 23px)',
      border: 'none',
      outline: 'none',
      backgroundColor: 'transparent',
      padding: '0 10px',
    },

    '& img': {
      verticalAlign: 'middle',
      display: 'inline-block',
      height: 23,
      width: 23,
      cursor: 'pointer',
    },
  },
}

export default injectSheet(style)(SearchBar)
