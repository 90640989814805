import _isEmpty from 'lodash.isempty'
import propTypes from 'prop-types'
import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'

import { StyledForm } from '../../../components/forms/EntityForm.styles'
import { createFocusAreaNote } from '../../repository/esg_respository'

export const AddNoteForm = ({ entityId, focusAreaId, onSuccess, disabled }) => {
  const [values, setValues] = useState({})
  const [errors, setErrors] = useState({ note: '' })

  const onInputChange = value => {
    const newValues = { ...values }
    let newErrors = { ...errors }
    newValues.note = value
    newErrors.note = ''
    setValues(newValues)
    setErrors(newErrors)
  }

  const onFormSubmit = e => {
    e.preventDefault()
    const validation = {}
    if (!values.note) validation.note = 'Note is required'
    if (_isEmpty(validation)) {
      createFocusAreaNote(entityId, focusAreaId, values.note).then(res => {
        onSuccess(res.data)
        setValues({ note: '' })
      })
    } else {
      setErrors(validation)
    }
  }

  return (
    <StyledForm
      className='notes-grid-form'
      style={{ width: '100%' }}
      onSubmit={onFormSubmit}
    >
      <h5 className='notes-heading'>Add a New Note</h5>
      <Form.Group>
        <Form.Label htmlFor={`textarea_${focusAreaId}`} srOnly>
          Note
        </Form.Label>
        <Form.Control
          style={{ marginBottom: '10px' }}
          as='textarea'
          rows={6}
          onChange={e => onInputChange(e.target.value)}
          value={values.note}
          error={errors.note}
          name={`textarea_${focusAreaId}`}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.note}
        </Form.Control.Feedback>
      </Form.Group>
      <Button className='submit-button' type='submit' disabled={disabled}>
        Add Note
      </Button>
    </StyledForm>
  )
}

AddNoteForm.propTypes = {
  entityId: propTypes.number.isRequired,
  focusAreaId: propTypes.number.isRequired,
  onSuccess: propTypes.func.isRequired,
  disabled: propTypes.bool.isRequired,
}
