import React from 'react';
import ErrorBoundary from '@honeybadger-io/react';
import ErrorCard from '../components/cards/ErrorCard';

import honeybadger from '../config/honeybadger';

const withErrorBoundary = Component => props => (
  <ErrorBoundary honeybadger={honeybadger} ErrorComponent={ErrorCard}>
    <Component {...props} />
  </ErrorBoundary>
);

export default withErrorBoundary;