import React, { ReactNode } from 'react'

export interface IProps {
    isPresent: boolean
    children?: ReactNode
}

export const Box: React.FC<IProps> = ({ isPresent, children }) => {
    if (!isPresent) return null
    return <>{children}</>
}
