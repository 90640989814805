import React from 'react';
import { MainTable } from '../../../components/tables/styles';

const DetailsBox = ({ details, look, dataLabel }) => {
    return (
        <MainTable data-cy={dataLabel} look={look}>
            <tbody>
            {
                details.map((detail, i) => (
                    <tr data-cy={`${dataLabel}:::table-row-${i}`} key={`${i}-fund-details-box`}>
                        <td data-cy={`${dataLabel}:::table-cell-left-${i}`} key={`${dataLabel}:::table-cell-top-${i}`}>
                            {detail.key || "—"}
                        </td>
                        <td key={`${dataLabel}:::table-cell-right-${i}`}data-cy={`${dataLabel}:::table-cell-right-${i}`}>
                            {detail.value || "—"}
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </MainTable>
    );
}

export default DetailsBox;
