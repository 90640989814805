import React from 'react'

export const CarbonAuditTabs = {
  SECTOR: 'sector',
  SCOPE: 'scope',
  HOLDING: 'holding',
}

export const SWITCH_OPTIONS = [
  { id: CarbonAuditTabs.SECTOR, label: 'Sectors' },
  { id: CarbonAuditTabs.HOLDING, label: 'Holdings' },
  { id: CarbonAuditTabs.SCOPE, label: 'Scope' },
]

export const DEGREE_SWITCH_OPTIONS = [
  { id: 'a', label: '1.5ºC Budget' },
  { id: 'b', label: '<1.5ºC Budget' },
]

export const CALCULATION_METHOD_SWITCH_OPTIONS = [
  { id: 'enterprise_value', label: 'Enterprise Value' },
  { id: 'revenue', label: 'Revenue' },
]

export const BUDGET_SWITCH_OPTIONS = [
  { id: 'all', label: 'All' },
  { id: 'under_budget', label: 'Under Budget' },
  { id: 'over_budget', label: 'Over Budget' },
]

export const DESCRIPTION = (
  <>
    The Carbon Audit represents the owned emissions of this portfolio as it
    relates to the company and industry emissions limits that are required for
    the planet to remain under 1.5ºC global warming by 2050. Due to
    visualization challenges, smaller holdings may not populate to this visual
    analytic.
  </>
)

export const DISCLAIMER = (
  <>
    (1) Emissions represent metric tons of CO<sub>2</sub>-equivalent emissions
    (tCO<sub>2</sub>e). Emissions are modeled where not otherwise available.{' '}
    <br />
    (2) Displayed values are calculated based on any applied filters (sectors
    hidden, inclusion of short positions, etc.)
  </>
)

export const CarbonAuditTarget = {
  INSTRUMENT: 'Instrument',
  PORTFOLIO: 'Portfolio',
}

const labelWithSymbol = (label, symbol = null) => (
  <div>
    {label} <br></br>
    {symbol || (
      <>
        (tCO<sub>2</sub>e)
      </>
    )}
  </div>
)

export const TableHeaders = includeBenchmark => {
  /* Scope - Portfolio */
  let SCOPE_PORTFOLIO = [
    { id: 'name', label: 'Scope', sort: true, span: 2 },
    {
      id: 'target_total_co2_budget',
      label: labelWithSymbol('BUDGET'),
      sort: true,
    },
    {
      id: 'target_total_co2_emitted',
      label: labelWithSymbol('PORTFOLIO'),
      sort: true,
    },
    {
      id: 'target_vs_budget',
      label: labelWithSymbol('VS BUDGET', '(%)'),
      sort: true,
      span: 2,
    },
  ]
  if (includeBenchmark)
    SCOPE_PORTFOLIO = [
      ...SCOPE_PORTFOLIO,
      {
        id: 'benchmark_total_co2_budget',
        label: labelWithSymbol('BENCHMARK'),
        sort: true,
        span: 1,
      },
    ]

  /* Scope - Instrument */
  let SCOPE_INSTRUMENT = [
    { id: 'name', label: 'Scope', sort: true, span: 2 },
    {
      id: 'target_total_co2_budget',
      label: labelWithSymbol('BUDGET'),
      sort: true,
    },
    {
      id: 'target_total_co2_emitted',
      label: labelWithSymbol('INSTRUMENT'),
      sort: true,
    },
    {
      id: 'target_vs_budget',
      label: labelWithSymbol('VS BUDGET', '(%)'),
      sort: true,
      span: 2,
    },
  ]
  if (includeBenchmark)
    SCOPE_INSTRUMENT = [
      ...SCOPE_INSTRUMENT,
      {
        id: 'benchmark_total_co2_budget',
        label: labelWithSymbol('BENCHMARK'),
        sort: true,
        span: 1,
      },
    ]

  /* Holdings */
  let HOLDINGS = [
    { id: 'name', label: 'Company', sort: true, span: 2 },
    {
      id: 'target_total_co2_budget',
      label: labelWithSymbol('BUDGET'),
      sort: true,
    },
    {
      id: 'target_total_co2_emitted',
      label: labelWithSymbol('PORTFOLIO'),
      sort: true,
    },
    {
      id: 'target_vs_budget',
      label: labelWithSymbol('VS BUDGET', '(%)'),
      sort: true,
      span: 2,
    },
  ]

  /*  */
  let SECTOR = [
    { id: 'name', label: 'Sector', sort: true, span: 2 },
    {
      id: 'target_total_co2_budget',
      label: labelWithSymbol('BUDGET'),
      sort: true,
    },
    {
      id: 'target_total_co2_emitted',
      label: labelWithSymbol('PORTFOLIO'),
      sort: true,
    },
    {
      id: 'target_vs_budget',
      label: labelWithSymbol('VS BUDGET', '(%)'),
      sort: true,
      span: 2,
    },
  ]
  if (includeBenchmark)
    SECTOR = [
      ...SECTOR,
      {
        id: 'benchmark_total_co2_budget',
        label: labelWithSymbol('BENCHMARK'),
        sort: true,
        span: 1,
      },
    ]

  return {
    SCOPE_PORTFOLIO,
    SCOPE_INSTRUMENT,
    HOLDINGS,
    SECTOR,
  }
}

export const TooltipHeaders = [
  {
    label: 'COMPANY',
  },
  {
    label: 'BUDGETED',
  },
  {
    label: 'EMISSIONS',
  },
  {
    label: 'DIFFERENCE',
  },
]

export const OVER_BUDGET_COLOR = '#E74C4C'
export const UNDER_BUDGET_COLOR = '#00d66e'
