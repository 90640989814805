import React from 'react';
import Spinner from 'react-bootstrap/Spinner'
import { StyledDownloadLandscape } from './styles';

const DownloadLandscape = ({ isBusy, onClick }) => {

  const icon = isBusy ? <Spinner key={'icon-0'} animation="border"/> : <img key={'icon-1'} src="/ico_adobe_reader.svg"/>

  return (
    <StyledDownloadLandscape secondary outline
      label={ [icon, 'Download Landscape'] }
      disabled={ isBusy }
      onClick={ onClick }
    />
  )
}

export default DownloadLandscape
