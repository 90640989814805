import { line } from 'd3-shape'
import React from 'react'

import { CHART_COLOR_BENCHMARK } from '../../../../styles/colors'

const GraphLine = ({ bars, xScale, yScale }) => {
  const lineGenerator = line()
    .x(bar => xScale(parseFloat(bar?.data?.indexValue)) + bar?.width / 2)
    .y(bar => yScale(bar?.data?.data?.benchmarkScore))
  const lineBars = bars.slice(0, bars?.length / 3)

  return (
    <>
      <path
        d={lineGenerator(lineBars)}
        fill='none'
        stroke={CHART_COLOR_BENCHMARK}
        style={{ pointerEvents: 'none' }}
        strokeDasharray='6,6'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </>
  )
}

export default GraphLine
