import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { BreadcrumbNav } from '../../../styleguide/breadcrumbs/BreadcrumbNav'

export const PageHeaderBase = ({ breadcrumbLinks, title }) => {
  const StyledHeader = styled.header`
    padding: 1.25rem 0;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    h1 {
      margin: 0;
      font-weight: 700;
      font-size: 30px;
      line-height: 1.1;
    }
  `
  return (
    <StyledHeader>
      {breadcrumbLinks && <BreadcrumbNav links={breadcrumbLinks} />}
      {title && <h1>{title}</h1>}
    </StyledHeader>
  )
}

PageHeaderBase.propTypes = {
  breadcrumbLinks: PropTypes.array,
  title: PropTypes.string,
}
