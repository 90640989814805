export const RESEARCH_CENTER_DATA_CHOICES = {
  SUMMARY: 'SUMMARY',
  ESG: 'ESG',
  SDG: 'SDG',
  CARBON: 'CARBON',
}

export const RESEARCH_CENTER_COMPANY_TYPES = {
  PUBLIC: 'public',
  PRIVATE: 'private',
}

export const FILTER_TYPES = {
  MARKET_CAP: 'MARKET_CAP',
  BUSINESS_SECTOR: 'BUSINESS_SECTOR',
  INDUSTRY_GROUP: 'INDUSTRY_GROUP',
  SDG: 'SDG',
  POSITIVE_BADGE: 'POSITIVE_BADGE',
  NEGATIVE_BADGE: 'NEGATIVE_BADGE',
  INSTRUMENT_TYPE: 'INSTRUMENT_TYPE',
  FUND_TYPE: 'FUND_TYPE',
  FUND_ASSET_CLASS: 'FUND_ASSET_CLASS',
  FUND_SECTOR: 'FUND_SECTOR',
  COMPANY_TYPE: 'COMPANY_TYPE',
  REVENUE: 'REVENUE',
  COUNTRY_OF_HQ: 'COUNTRY_OF_HQ',
  SEARCH_QUERY: 'SEARCH_QUERY',
  E_SCORE: 'E_SCORE',
  S_SCORE: 'S_SCORE',
  G_SCORE: 'G_SCORE',
  TOTAL_SCORE: 'TOTAL_SCORE',
}
