import React from 'react'
import styled from 'styled-components'

import { sdgs } from './consts/sdgs'
import { SdgButton } from './SdgButton'

const StyledGrid = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: repeat(4, minmax(48px, 1fr));
  gap: 10px;

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(8, minmax(48px, 1fr));
  }

  &.has-error {
    outline: 2px solid var(--danger);
  }
`

export const SdgGrid = ({ isInvalid, onToggleSdg, selectedSdgs }) => {
  const isSelected = num => {
    return selectedSdgs.find(el => el === num)
  }

  return (
    <StyledGrid className={isInvalid ? 'has-error' : ''}>
      {sdgs.map(({ num, color }, index) => (
        <SdgButton
          key={index}
          onToggleSdg={onToggleSdg}
          sdg={num}
          color={color}
          selected={isSelected(num)}
        />
      ))}
    </StyledGrid>
  )
}
