import dayjs from 'dayjs'
import React, { Fragment, useEffect, useState } from 'react'

import { getManagerDBFund } from '../../repositories/managers_repository'

const ManagersFundShow = ({ id }) => {
  const [fund, setFund] = useState(null)

  useEffect(() => {
    getManagerDBFund(id).then(response => {
      setFund(response.data)
    })
  }, [id])

  const renderOverviewCard = fund => (
    <div className='card mb-3'>
      <h6 className='card-header'>Overview</h6>
      <div
        className='card-body'
        dangerouslySetInnerHTML={{ __html: fund.strategy_description }}
      />
    </div>
  )

  const renderKeyTermsCard = fund => (
    <div className='card mb-3'>
      <h6 className='card-header'>Key Terms</h6>
      <table className='card-body table table-striped table-sm'>
        {fund.asset_class?.id === 1
          ? renderEquityKeyTermsTableBody(fund)
          : renderKeyTermsTableBody(fund)}
      </table>
    </div>
  )

  const renderSustainabilityCard = fund => (
    <div className='card mb-3'>
      <h6 className='card-header'>Sustainability</h6>
      <div
        className='card-body'
        dangerouslySetInnerHTML={{ __html: fund.impact_description }}
      ></div>
    </div>
  )

  const renderFundClassificationCard = fund => (
    <div className='card mb-3'>
      <h6 className='card-header'>Fund Classification</h6>
      <table className='table table-sm table-striped'>
        <tbody>
          {renderTableBodyRows([
            {
              header: 'Fund Type',
              data: fund.subasset_class_name,
            },
            {
              header: 'Sector',
              data: fund.secondary_impact_theme,
            },
            {
              header: 'Geography',
              data: fund.geographic_focus,
            },
          ])}
        </tbody>
      </table>
    </div>
  )

  const renderAttachedFilesCard = fund => (
    <div className='card mb-3'>
      <h6 className='card-header'>Attached Files</h6>
      <table className='table table-sm table-striped'>
        <thead>
          <tr>
            <th>File Type</th>
            <th>File Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {fund.files.map(file => (
            <tr key={file.id}>
              <td>{file.type}</td>
              <td>{file.name}</td>
              <td>
                <a href={file.attachment_url} download>
                  Download
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )

  const renderEquityKeyTermsTableBody = fund => (
    <tbody>
      {renderTableBodyRows([
        {
          header: 'Investment Manager Name',
          data: fund.firm?.name || '',
        },
        {
          header: 'Investment Name',
          data: fund.name,
        },
        {
          header: 'Asset Class',
          data: fund.asset_class?.name,
        },
        {
          header: 'US Ticker',
          data: fund.ticker,
        },
        {
          header: 'CUSIP',
          data: fund.cusip,
        },
        {
          header: 'ISIN',
          data: fund.isin,
        },
        {
          header: 'Fund AUM',
          data: fund.strategy_aum_dollars,
        },
        {
          header: 'Inception Date',
          data: dayjs(fund.strategy_inception_date).format('YYYY-MM-D'),
        },
        {
          header: 'Expense Ratio',
          data: fund.expense_ratio,
        },
        {
          header: 'Number of Holdings',
          data: fund.number_of_holdings,
        },
        {
          header: 'Current Step',
          data: fund?.investment_stage,
        },
        {
          header: 'SDG Exposure',
          data: fund.sdgs
            .filter(sdg => sdg !== 'N/A')
            .map((sdg, i) => (
              <Fragment key={i}>
                {sdg.name}
                <br />
              </Fragment>
            )),
        },
        {
          header: 'Impact Themes',
          data: [fund.primary_impact_theme, fund.secondary_impact_theme]
            .filter(theme => theme)
            .join(' – '),
        },
      ])}
    </tbody>
  )

  const renderKeyTermsTableBody = fund => (
    <tbody>
      {renderTableBodyRows([
        {
          header: 'Fund',
          data: fund.strategy_name,
        },
        {
          header: 'Manager',
          data: fund.firm?.name,
        },
        {
          header: 'Management Fee',
          data: [`${fund.mgmt_fee_pct}%`, fund.footnote_mgmt_fee]
            .filter(el => el)
            .join('; '),
        },
        {
          header: 'Incentive Fee',
          data: `${fund.incentive_fee_pct}%`,
        },
        {
          header: 'Minimum Investment',
          data: fund.investment_minimum_dollars,
        },
        {
          header: 'Term',
          data: `${fund.lock_up_liquidity} ${fund.footnote_lock_up_liquidity}`,
        },
        {
          header: 'Fundraising Target',
          data: fund.fundraise_close_target,
        },
        {
          header: 'Target Return',
          data: fund.target_return,
        },
      ])}
    </tbody>
  )

  const renderTableBodyRows = rows =>
    rows.map((row, i) => (
      <tr key={`table-body-${i}`}>
        <th>{row?.header}:</th>
        <td>{row?.data}</td>
      </tr>
    ))

  return (
    fund && (
      <div className='page__container'>
        <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-2 mb-3 border-bottom'>
          <h1 className='h2'>{fund.name}</h1>
          <div>
            {fund.sdgs
              .filter(sdg => sdg.index && sdg.index !== 17)
              .map(sdg => (
                <img
                  key={sdg.index}
                  className='d-inline-block m-1'
                  height='50'
                  width='50'
                  src={`/sdg_icons/${sdg.index}-125.png`}
                ></img>
              ))}
          </div>
        </div>
        <div className='text-align-center pt-3 pb-3 mb-0'>
          <h1 className='h4'>General Information</h1>
        </div>
        <div className='row'>
          <div className='col-md-8'>
            {renderOverviewCard(fund)}
            {renderKeyTermsCard(fund)}
            {renderSustainabilityCard(fund)}
          </div>
          <div className='col-4'>
            {renderFundClassificationCard(fund)}
            {renderAttachedFilesCard(fund)}
          </div>
        </div>
      </div>
    )
  )
}

export default ManagersFundShow
