import React, { useEffect, useMemo, useState } from 'react'

import ErrorPage from '../../../../components/styleguide/ErrorPage'
import { abbreviateNumber } from '../../../../helpers/number'
import { getCarbonTaxRates } from '../../../../repositories/portfolio_repository'
import { LinkIcon } from '../../../../yb_components/styleguide/links/Link.styles'
import Pagination from '../../../../yb_components/styleguide/pagination/Pagination'
import SpinnerContainer from '../../../../yb_components/styleguide/spinners/SpinnerContainer'
import CarbonReportCard from './components/CarbonReportCard'
import CarbonReportLink from './components/CarbonReportLink'
import {
  CARBON_REPORT_TEXTS,
  carbonTaxExposureTableHeader,
  DEFAULT_SORT,
} from './constants'
import { formatSort, getPdfSortFieldText } from './helpers'
import {
  CarbonReportTable,
  ModuleDescription,
  renderTh,
  StyledExplainerLink,
} from './styles'

const loadCarbonTaxRates = ({
  snapshotId,
  portfolioId,
  setCarbonTaxRates,
  setError,
  setIsLoading,
  setTotalPages,
  page,
  sort,
  dispatch,
}) => {
  getCarbonTaxRates({ snapshotId, portfolioId, page, ...sort })
    .then(res => {
      setIsLoading(false)
      setCarbonTaxRates(res.data.carbon_tax_rates)
      setTotalPages(res.data.total_pages)
      dispatch({
        type: 'carbon-tax-rates-loaded',
        payload: {
          data: res.data.carbon_tax_rates,
          sort: sort,
        },
      })
    })
    .catch(err => {
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

const CarbonTaxExposure = ({
  portfolioId,
  snapshotId,
  dispatch,
  pdf,
  defaultData,
  defaultSort,

  title = CARBON_REPORT_TEXTS?.carbonTaxRates.title,
  description = CARBON_REPORT_TEXTS?.carbonTaxRates.description,
}) => {
  const [isLoading, setIsLoading] = useState(!pdf)
  const [error, setError] = useState()
  const [carbonTaxRates, setCarbonTaxRates] = useState(defaultData || [])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [sort, setSort] = useState(defaultSort || DEFAULT_SORT)

  useEffect(() => {
    if (defaultData) setCarbonTaxRates(defaultData)
  }, [defaultData])

  useEffect(
    () =>
      !pdf
        ? loadCarbonTaxRates({
            portfolioId,
            snapshotId,
            page,
            sort,
            dispatch,
            setCarbonTaxRates,
            setTotalPages,
            setError,
            setIsLoading,
          })
        : () => {},
    [page, sort]
  )

  const formatValue = (value, formatter = null, percentage = false) => {
    let fValue = parseFloat(value)
    if (isNaN(fValue)) return '—'

    if (percentage) fValue = fValue?.toFixed(2)
    else fValue = abbreviateNumber(fValue)

    return formatter ? formatter?.(fValue) : fValue
  }

  const formattedPdfSortField = useMemo(
    () =>
      getPdfSortFieldText(carbonTaxExposureTableHeader(pdf), defaultSort, pdf),
    [defaultSort, pdf]
  )

  return (
    <CarbonReportCard
      pdf={pdf}
      title={title}
      pdfSubTitle={{
        label: `(Top 20 Holdings By ${formattedPdfSortField})`,
      }}
    >
      {!pdf && (
        <StyledExplainerLink
          pdf={pdf}
          href='http://help.yves.blue/en/articles/6698762-carbon-report-portfolio-summary-on-yvesblue#h_08e642c8c1'
          target='_blank'
        >
          <LinkIcon className='fa fa-question' />
        </StyledExplainerLink>
      )}

      <ModuleDescription pdf={pdf}>{description}</ModuleDescription>
      {error ? (
        <ErrorPage code={error} message={error == 404 ? 'NOT FOUND' : ''} />
      ) : (
        <>
          {isLoading && <br />}
          <SpinnerContainer isLoading={isLoading}>
            {carbonTaxRates?.length ? (
              <>
                <CarbonReportTable pdf={pdf}>
                  <thead>
                    <tr>
                      {carbonTaxExposureTableHeader(pdf).map(elem =>
                        renderTh(
                          elem,
                          sort,
                          (sortField, sortDir) =>
                            setSort(formatSort(sortField, sortDir)),
                          pdf
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {carbonTaxRates.map((taxRate, index) => (
                      <tr key={`carbon-tax-rate-${index}`}>
                        <td>
                          <CarbonReportLink
                            pdf={pdf}
                            name={taxRate?.name}
                            href={`/app/instruments/${taxRate?.id}#report-summary-card`}
                          />
                        </td>
                        {pdf && (
                          <td className='number'>
                            {formatValue(taxRate?.weight, e => `${e}%`, true)}
                          </td>
                        )}
                        <td>{taxRate.country || '—'}</td>
                        <td>{taxRate.type || '—'}</td>
                        <td className='number'>
                          {formatValue(taxRate?.amount, e => `$${e}`)}
                        </td>
                        <td className='number'>
                          {formatValue(
                            taxRate?.total_price_x_emissions,
                            e => `$${e}`
                          )}
                        </td>
                        <td className='number'>
                          {formatValue(
                            taxRate?.percentage_of_enterprise_value,
                            e => `${e <= 0.0 ? '≈0.00' : e}%`,
                            true
                          )}
                        </td>

                        <td className='number'>
                          {formatValue(
                            taxRate?.percentage_of_revenue,
                            e => `${e <= 0.0 ? '≈0.00' : e}%`,
                            true
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </CarbonReportTable>
                {!pdf && (
                  <Pagination
                    max={totalPages}
                    selected={page}
                    onChange={e => setPage(e)}
                  />
                )}
              </>
            ) : (
              // TODO: Replace this div with NoDataText component after merge in https://github.com/ExponentialTech/expo-base/pull/2300
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                There are no companies in your portfolio that <br />
                have a carbon tax or price in place.
              </div>
            )}
          </SpinnerContainer>
        </>
      )}
    </CarbonReportCard>
  )
}

export default CarbonTaxExposure
