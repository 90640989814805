import React, { useEffect, useRef } from 'react'

import { TooltipPositioning } from './TooltipWrapper.constants'
import { useClientSize } from './TooltipWrapper.helpers'
import { StyledContainer, TooltipContent } from './TooltipWrapper.styles'

const TooltipWrapper = ({
  tooltip,
  positioning = TooltipPositioning.TOP,
  children,
  margin,
  clickFunctionality,
  displayTooltip = false,
  zIndex,
  onClickOutside,
  customStyle,
  ...props
}) => {
  const [wrapperRef, wrapper] = useClientSize()
  const [contentRef, content] = useClientSize()

  const childRef = useRef()

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        contentRef?.current &&
        !contentRef?.current?.contains(event?.target) &&
        childRef?.current &&
        !childRef?.current?.contains(event?.target)
      ) {
        onClickOutside?.()
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [onClickOutside, contentRef])

  return (
    <StyledContainer
      ref={wrapperRef}
      clickFunctionality={clickFunctionality}
      displayTooltip={displayTooltip}
      zIndex={zIndex}
      {...props}
    >
      <div ref={childRef}>{children}</div>
      <TooltipContent
        ref={contentRef}
        wrapper={wrapper}
        content={content}
        margin={margin}
        positioning={positioning}
        customStyle={customStyle}
      >
        {tooltip}
      </TooltipContent>
    </StyledContainer>
  )
}

export default TooltipWrapper
