import React from 'react';
import SectionWrapper from '../../../components/styleguide/SectionWrapper';
import { LinkToggle, SqrButton } from '../styles';

const SqrButtonCase = ({ onChange, buttons, dataLabel, activeButton, styles = {} }) => {
    return(
        <SectionWrapper data-cy={dataLabel} >
            {
                buttons.map((button,i) => {
                    return(
                        <SqrButton
                            width={styles.width}
                            active={button === activeButton} 
                            onClick={() => {onChange(button)}}
                            data-cy={`${dataLabel}:link-button-${i}`}
                            key={`${dataLabel}:link-button-${i}`}
                        >
                            {button}
                        </SqrButton>
                    )
                })
            }
        </SectionWrapper>
    )
}

export default SqrButtonCase;
