import styled from 'styled-components'

import { Card } from '../../../../../yb_components/styleguide/cards/Card'

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  .card-title-base {
    margin: 0;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
  }
  &.market-card,
  &.sector-card {
    section {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  &.financial-card {
    section {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      align-items: center;
    }
    .left-label {
      display: inline-block;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      color: #979ca6;
    }
  }
  &.submit-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1.25rem;
  }
  .flex-direction-column {
    flex-direction: column;
  }
`
