import React from 'react'

import SDGIcon from '../../../components/styleguide/SDGIcon'

const TopHoldingsSDGExposuresTable = ({ holdings }) => {
  holdings = holdings && holdings.slice(0, 10)
  holdings = holdings.sort((h1, h2) => +h2.weight - +h1.weight)
  return (
    <section className='holdings-table top-sdg-exposures'>
      <h2>SDG EXPOSURES: Top 10 Modeled Holdings</h2>

      <table>
        <thead>
          <tr>
            <th>Weight</th>
            <th>Name</th>
            <th>Positive Aligned SDGs</th>
            <th>Negatively Aligned SDGs</th>
          </tr>
        </thead>

        <tbody>
          {holdings &&
            holdings.map((h, i) => (
              <tr key={`sdg-exposures-holding-${i}`}>
                <td className='weight' key={`holding-weight-${i}`}>
                  {h.weight}%
                </td>
                <td className='name' key={`holding-name-${i}`}>
                  {h.name === null ? h.description : h.name}
                </td>
                <td>
                  {h &&
                    h.sdgs &&
                    h.sdgs.positive &&
                    h.sdgs.positive.map(sdg => {
                      return (
                        <span
                          className='sdg-icon'
                          key={`${sdg}-span-positive-holding`}
                        >
                          <SDGIcon
                            key={`${sdg}-positive-holding`}
                            height={25}
                            width={25}
                            sdg={sdg}
                          />
                        </span>
                      )
                    })}
                </td>
                <td>
                  {h &&
                    h.sdgs &&
                    h.sdgs.negative &&
                    h.sdgs.negative.map(sdg => {
                      return (
                        <span
                          className='sdg-icon'
                          key={`${sdg}-span-negative-holding`}
                        >
                          <SDGIcon
                            key={`${sdg}-negative-holding`}
                            height={25}
                            width={25}
                            sdg={sdg}
                          />
                        </span>
                      )
                    })}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </section>
  )
}

export default TopHoldingsSDGExposuresTable
