import React from 'react'

import SDGIcon from '../../components/styleguide/SDGIcon'
import { MainTable } from '../../components/tables/styles'
import { SDGDescriptions } from '../../constants'
import { emptyScorePlaceholder } from '../../modules/instrument/components/peers/constants'
import PeersHeaderRow from '../../modules/instrument/components/peers/PeersHeaderRow'
import PeersScoreTableRowPDF from './PeersScoreTableRowPDF'

const sdgScore = (entity, sdgIndex) => {
  const sdgValue = entity.sdg_exposure[`sdg_${sdgIndex}`]
  return sdgValue && !isNaN(sdgValue)
    ? Math.round(sdgValue * 100)
    : emptyScorePlaceholder
}

const PeersSDGAlignment = ({ peers }) => {
  const sdgIcon = sdg => (
    <>
      <div>
        <SDGIcon sdg={sdg} height={40} width={40} />
      </div>
      <div className='sdg-name'>{SDGDescriptions[sdg].shortName}</div>
    </>
  )

  const retrieveScore = sdg => entity => {
    return sdgScore(entity, sdg)
  }

  return (
    <div>
      <h2>Peer Analysis - SDG Alignment</h2>
      <MainTable look={{ tdPadding: '10px' }}>
        <PeersHeaderRow isPdf peers={peers} />
        <tbody>
          {Object.keys(SDGDescriptions).map(sdg => (
            <PeersScoreTableRowPDF
              key={`table-row-${sdg}`}
              extraClassNames={['sdg-column']}
              neutralIsZero={true}
              scoreName={sdgIcon(sdg)}
              retrieveScore={retrieveScore(sdg)}
              peers={peers}
            />
          ))}
        </tbody>
      </MainTable>
    </div>
  )
}

export default PeersSDGAlignment
