import React, { useEffect, useState } from 'react'

import { queryStringParse } from '../../helpers/objects_and_arrays'
import { searchFunds } from '../../repositories/managers_repository'
import SpinnerContainer from '../../yb_components/styleguide/spinners/SpinnerContainer'
import KaminariPagination from './components/KaminariPagination'
import SearchByAssetClass from './components/SearchByAssetClass'
import SearchByImpactTheme from './components/SearchByImpactTheme'
import SearchByName from './components/SearchByName'
import SearchBySDG from './components/SearchBySDG'
import SearchResultsTable from './components/SearchResultsTable'

const ManagersFundSearch = () => {
  const [searchQuery, setSearchQuery] = useState(queryStringParse())
  const [sorting, setSorting] = useState({ order: 'asc', field: 'id' })
  const [fundResults, setFundResults] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    searchFunds({ ...searchQuery, sorting }).then(result => {
      setFundResults(result.data)
      setIsLoading(false)
    })
  }, [searchQuery, sorting])

  const fetchPage = page => {
    setSearchQuery({ ...searchQuery, page })
  }
  return (
    <div className='page__container'>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom'>
        <h1 className='h2'>Flat World Partners Manager Research</h1>
      </div>

      <div className='row'>
        <div className='col-md-9'>
          <SpinnerContainer isLoading={isLoading}>
            {!fundResults?.funds?.length ? (
              'There are no funds for the selected criteria.'
            ) : (
              <>
                <SearchResultsTable
                  funds={fundResults?.funds}
                  sorting={sorting}
                  setSorting={setSorting}
                />
                {fundResults?.total_pages > 1 && (
                  <KaminariPagination
                    currentPage={searchQuery.page || 1}
                    totalPages={fundResults?.total_pages}
                    fetchPage={fetchPage}
                  />
                )}
              </>
            )}
          </SpinnerContainer>
        </div>
        <div className='col-md-3'>
          <SearchByName
            onNameChange={name => {
              setIsLoading(true)
              setSearchQuery({ ...searchQuery, query: name, page: 1 })
            }}
          />
          <SearchByAssetClass
            onAssetClassChange={assetClassId => {
              setIsLoading(true)
              setSearchQuery({ ...searchQuery, assetClassId, page: 1 })
            }}
          />
          <SearchBySDG
            onSDGChange={sdg => {
              setIsLoading(true)
              setSearchQuery({ ...searchQuery, sdg, page: 1 })
            }}
          />
          <SearchByImpactTheme
            onImpactThemeChange={(primaryThemeId, secondaryThemeId) => {
              setIsLoading(true)
              setSearchQuery({
                ...searchQuery,
                primaryThemeId,
                secondaryThemeId,
                page: 1,
              })
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default ManagersFundSearch
