import styled from 'styled-components'

const Section = styled.div`
  & table {
    table-layout: fixed;
    & thead {
      & tr {
        & th {
          width: 100px;
          &:nth-child(-n + 2) {
            width: 185px;
          }
          &:nth-child(3) {
            & span {
              justify-content: flex-start !important;
            }
            width: 300px;
          }
          &:nth-child(4) {
            & span {
              justify-content: flex-start !important;
            }
            width: 100px;
          }
          &:nth-child(5) {
            & span {
              justify-content: flex-end !important;
            }
            width: 100px;
          }
          &:nth-child(6) {
            width: 100px;
          }
        }
      }
    }
    & tbody {
      & td {
        white-space: nowrap;

        overflow: hidden;
        text-align: center;
        :nth-child(-n + 4) {
          text-align: left;
          padding-right: 10px;
          padding-left: 10px;
        }
      }
    }
  }
  & div.switch-wrapper {
    margin-top: 20px;
    &.hidden {
      visibility: hidden;
    }
  }
  & > .holdings-card {
    margin-top: 20;
    overflow-x: auto;
    & .table-title {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      & > * {
        display: flex;
      }
    }
    & .header-container {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;

      & .right-subheader {
        display: flex;
        align-items: flex-start;
        gap: 40px;
        & a {
          margin-bottom: 0px;
        }
      }
    }
    & .right-header-container {
      display: flex;
      gap: 30px;
      & .csv-icon {
        margin-right: 6px;
      }
    }
  }
  & table {
    & td {
      & .symbol,
      .weight {
        color: FONT_COLOR_SECONDARY;
      }
      & .offset {
        margin-left: 12px;
      }
      & .center {
        text-align: center;
      }
      & .title {
        max-width: 180px;
      }
      & .name a,
      .total {
        color: APP_COLOR_PRIMARY;
        font-weight: bold;
      }
      &:nth-child(3) {
        max-width: 150px;
      }
      &:nth-child(4) {
        max-width: 300px;
      }
      &:nth-child(5) {
        max-width: 200px;
      }
      & .weight,
      .total {
        text-align: center;
      }
    }
    & span.left {
      display: inline-block;
      text-align: left;
    }
    & span.ellipsis {
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  & .paginator-container {
    flex: 1;
    display: flex;
    justify-content: ${props =>
      props.groupBySwitcher ? 'flex-end' : 'space-around'};
    align-items: center;
    padding-top: 20px;
    & .switch-wrapper {
      margin-top: 0px;
      & button {
        width: 141px;
        font-size: 0.9rem;
      }
    }
    & .position-entity-sector_wrapper {
      display: ${props => (props.groupBySwitcher ? 'flex' : 'none')};
      & div {
        width: fit-content;
        margin-left: auto;
      }
    }
    & .paginator-switch-wrapper {
      margin-top: 4px;
    }
  }

  .filter-buttons-wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 10px;
    margin-bottom: 25px;
  }
  .form-buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .filter-title {
    margin-bottom: 10px;
  }
  .filter-description {
    font-size: 14px;
    color: #979ca6;
  }
  .margin-right {
    margin-right: 20px;
  }
  .margin-left {
    margin-left: 10px;
  }
  .unstyled-button {
    button,
    input[type='submit'],
    input[type='reset'] {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: flex !important;
      align-items: center;
      color: #979ca6;
    }
  }
  .cancel-button {
    padding: 10px;
    margin-right: 20px;
  }
`

export default Section
