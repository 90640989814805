import React from 'react'

import { MainTable } from '../../components/tables/styles'
import { esgScoreFullNames } from '../../constants'
import { emptyScorePlaceholder } from '../../modules/instrument/components/peers/constants'
import PeersHeaderRow from '../../modules/instrument/components/peers/PeersHeaderRow'
import PeersScoreTableRowPDF from './PeersScoreTableRowPDF'

const PeersESGScore = ({ peers }) => {
  const retrieveScore = metricType => entity => {
    return (
      Math.round(entity.esg_score[`${metricType}_score`]) ||
      emptyScorePlaceholder
    )
  }

  return (
    <div>
      <h2>Peer Analysis - ESG Scores</h2>
      <MainTable look={{ tdPadding: '10px' }}>
        <PeersHeaderRow isPdf peers={peers} />
        <tbody>
          {['e', 's', 'g', 'total'].map(metricType => (
            <PeersScoreTableRowPDF
              key={`table-row-${metricType}`}
              scoreName={esgScoreFullNames[metricType].toUpperCase()}
              retrieveScore={retrieveScore(metricType)}
              peers={peers}
            />
          ))}
        </tbody>
      </MainTable>
    </div>
  )
}

export default PeersESGScore
