import React from 'react';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { LegendItemType } from './LegendItems.constants';
import LegendItem from './LegendItem';

const OverTimeLegendItem = ({ label, bigLabel, color }) => {
  return (
    <Col>
      <Row>
        <LegendItem
          label={bigLabel}
          labelColor={color}
          type={LegendItemType.BIG_LABEL}
        />
      </Row>
      <Row>
        <LegendItem
          label={label}
          dotColor={color}
          type={LegendItemType.DOTTED_LABEL}
        />
      </Row>
    </Col>
  );
}

export default OverTimeLegendItem;
