import React from 'react'

import { abbreviateNumber } from '../../helpers/number'
import { HAZARDOUS_WASTE_EXPLAINER_URL } from '../../yb_constants/urls/explainers'
import ImpactCard from './ImpactCard'

const HazardousWasteImpactCard = ({
  targetType,
  data,
  minHeight,
  benchmarkName,
  benchmarkData,
}) => {
  const benchmarkComparison = (
    ((data.total - benchmarkData?.total) / data.total) *
    100
  ).toFixed(2)
  const benchmarkComparisonAbs = Math.abs(benchmarkComparison).toFixed(2)

  return (
    <ImpactCard
      targetType={targetType}
      value={abbreviateNumber(data.total * 1.0, 0)}
      title='HAZARDOUS WASTE'
      unit='$'
      holdingsPct={data.holdings_ownership_pct}
      description='required to cleanup the average <br/> amount of hazardous waste <br/> emitted by this portfolio in a year.'
      benchmarkDescription={
        benchmarkName && {
          text: `${benchmarkComparisonAbs}% ${
            benchmarkComparison > 0 ? 'more' : 'less'
          } than the ${benchmarkName}.`,
          color: '#D02F44',
        }
      }
      minHeight={minHeight}
      icons={
        <div className='icons'>
          <div>
            <img src={`/impact_icons/ico_haz_waste.svg`} />
          </div>
        </div>
      }
      explainerHref={HAZARDOUS_WASTE_EXPLAINER_URL}
    />
  )
}

export default HazardousWasteImpactCard
