import React from 'react';
import BrandingPrimaryLogo from '../../layout/branding_primary_logo/BrandingPrimaryLogo';
import SDGAlignment from '../SDGAlignment';

const PortfolioHeading = ({ name, benchmark, sdgs }) => {
  return (
    <section className="portfolio-heading">
      <div className="portfolio-heading__information">
        <h1>
          {name}
          <br/>
          <span>Benchmark: </span><span className="benchmark">{benchmark}</span>
        </h1>

        <SDGAlignment sdgs={sdgs}/>
      </div>

      <BrandingPrimaryLogo />
    </section>
  );
}

export default PortfolioHeading;
