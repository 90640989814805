import React from 'react';
import SDGIcon from '../../../../../../../components/styleguide/SDGIcon';
import { SDGDescriptions } from '../../../../../../../constants';
import { emptyScorePlaceholder } from '../../../constants';
import { computeSDGColor } from '../../../helpers';
import { StyledMainTable } from '../../comparisons/Comparisons.styles';
import HeaderRow from '../../table_rows/header_row/HeaderRow';
import ScoreTableRow from '../../table_rows/score_table_row/ScoreTableRow';
import { IconContainer, SDGContainer, SDGName } from './SDGAlignmentCard.styles';

const SDGAlignmentCard = ({ portfolios, openReplaceModal=()=>{}, isPdf=false }) => {
  const sdgIcon = sdg => (
    <SDGContainer>
      <IconContainer>
        <SDGIcon sdg={sdg} height={50} width={50} />
      </IconContainer>
      <SDGName>{ SDGDescriptions[sdg].shortName }</SDGName>
    </SDGContainer>
  )

  const retrieveScore = sdgIndex => portfolio => {
    const sdgValue = portfolio?.sdg_exposure?.[`sdg_${sdgIndex}`]
    return sdgValue ? Math.round(sdgValue * 100) : emptyScorePlaceholder
  }

  return (
    <StyledMainTable look={{ sideCellsPadding: '0' }} isPdf={ isPdf }>
      <HeaderRow portfolios={ portfolios } openReplaceModal={ openReplaceModal } isPdf={ isPdf } />
      <tbody>
        {Object.keys(SDGDescriptions).map( sdg => (
          <ScoreTableRow
            key={ `table-row-${sdg}` }
            computeColor={ computeSDGColor}
            scoreName={ sdgIcon(sdg) }
            retrieveScore={ retrieveScore(sdg) }
            portfolios={ portfolios }
          />
        ))}
      </tbody>
    </StyledMainTable>
  )
}

export default SDGAlignmentCard
