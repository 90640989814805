import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { APP_COLOR_PRIMARY, FONT_COLOR_WHITE } from '../../../../styles/colors'
import { AlignCenter, FullSize } from '../../../yb_styles/layout'
import { H1, H2, H4 } from '../../../yb_styles/typography'

const StyledContainer = styled.div`
  ${FullSize}
`

const StyledHeaderContainer = styled.div`
  border-top: 72px solid white;
  width: 100%;
  padding: 18px;
  display: flex;
  justify-content: space-between;
  background-color: ${APP_COLOR_PRIMARY};
  font-size: 14px;
`

const StyledText = styled.div`
  ${AlignCenter}
  color: ${FONT_COLOR_WHITE};
`

const StyledTitle = styled.div`
  ${H1}
  padding-top: 10px;
`

const StyledName = styled.div`
  ${H2}
`

const StyledSubtitle = styled.div`
  ${H4}
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  display: ${props => (props.inline ? 'inline' : 'block')};
`

const ReportHeader = ({
  title,
  asOf,
  targetName,
  benchmarkName,
  disclaimerText,
  holdingName,
}) => {
  return (
    <StyledContainer className='header-container'>
      <StyledHeaderContainer>
        <img src='/ico_logo_white.svg' size={20} />

        <StyledText abs>{disclaimerText}</StyledText>

        <StyledText>
          <div>{dayjs()?.format('MMM D, YYYY')}</div>
        </StyledText>
      </StyledHeaderContainer>
      <div>
        {title && <StyledTitle>{title}</StyledTitle>}
        {targetName && <StyledName>{targetName}</StyledName>}
        {asOf && (
          <StyledSubtitle bold>
            As Of:{' '}
            <StyledSubtitle inline>
              {asOf?.format('MMM D, YYYY')}
            </StyledSubtitle>
          </StyledSubtitle>
        )}
        {holdingName && (
          <StyledSubtitle bold>
            Holding: <StyledSubtitle inline>{holdingName}</StyledSubtitle>
          </StyledSubtitle>
        )}
        {benchmarkName && (
          <StyledSubtitle bold>
            Benchmark: <StyledSubtitle inline>{benchmarkName}</StyledSubtitle>
          </StyledSubtitle>
        )}
      </div>
    </StyledContainer>
  )
}

ReportHeader.propTypes = {}

export default ReportHeader
