import PropTypes from 'prop-types'
import React, { memo, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'

import { FONT_COLOR_SECONDARY } from '../../../styles/colors'

const StyledPagination = styled.div`
  &.pagination-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* gap: 1rem; */
    gap: 2px;
    span {
      margin: 0 1rem 0 0;
      display: inline-block;
      font-size: 14px;
      line-height: 1.2;
    }
  }
  & .pagination-button {
    margin: 0;
    padding: 0.5rem;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      height: 14px;
    }
  }
  & .pagination-input {
    margin: 0 0.5ch 0 1rem;
    padding: 6px 10px;
    min-width: calc(1ch + 22px);
    display: inline-block;
    border: 1px solid #e6e7ea;
    border-radius: 3px;
    font-size: 14px;
    line-height: 1.2;
    color: ${FONT_COLOR_SECONDARY};
    &:focus,
    &:active {
      outline: none;
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }
  }
`

const Pagination = ({
  max,
  selected,
  onChange,
  'data-cy': dataCy = '',
  style = {},
  gaCat = null,
}) => {
  const [inputValue, setInputValue] = useState(selected)
  const [maxVal, setMaxVal] = useState(max)

  useEffect(() => {
    setInputValue(selected)
    setMaxVal(max)
  }, [selected, max])

  const onChangeInputValue = (e, key) => {
    e.preventDefault()
    // handle empty input & reset to previous value
    if (!e?.target?.value?.length) return setInputValue(selected)
    // handle non-numbers & reset to previous value
    if (isNaN(inputValue)) return setInputValue(selected)
    // set new page number
    const page = Math.max(1, Math.min(max, parseInt(inputValue)))
    if (page != inputValue || key == 'Enter') setInputValue(page)
    // handle page change
    onChange(page)
  }

  const gaEventCall = control => {
    gaCat &&
      ReactGA.event({
        category: gaCat,
        action: Pagination,
        label: `${control}`,
      })
  }

  const onControlClick = control => {
    // handle empty input & reset to previous value
    if (isNaN(inputValue)) setInputValue(selected)
    // define page number
    let page
    gaEventCall(control)
    switch (control) {
      case 'first':
        page = 1
        break
      case 'previous':
        page = Math.max(1, parseInt(selected) - 1)
        break
      case 'next':
        page = Math.min(max, parseInt(selected) + 1)
        break
      case 'last':
        page = max
        break
    }
    // handle page change & update input
    onChange(page, control)
    setInputValue(page)
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      onChangeInputValue(e, e.key)
    }
  }

  const renderPages = () => {
    return (
      <form onSubmit={onChangeInputValue} data-cy='pagination_form'>
        <input
          data-cy='pagination_input'
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          onBlur={onChangeInputValue}
          onKeyDown={handleKeyDown}
          className='pagination-input'
          style={{
            width: `calc(${inputValue.toString().length}ch + 22px)`,
          }}
        />
        <span>of {maxVal}</span>
      </form>
    )
  }

  return (
    <StyledPagination
      data-cy={dataCy}
      className='pagination-container'
      style={style}
    >
      {/* first page btn */}
      <button
        data-cy='pagination_first_page_btn'
        aria-label='go to first page'
        href='#'
        onClick={onControlClick.bind(null, 'first')}
        className='pagination-button btn'
        disabled={inputValue === 1}
      >
        <img
          src='/icons/ico_arrow_left_to_solid.svg'
          alt='previous page icon'
          aria-hidden='true'
        />
      </button>
      {/* previous page btn */}
      <button
        data-cy='pagination_prev_page_btn'
        aria-label='go to previous page'
        href='#'
        onClick={onControlClick.bind(null, 'previous')}
        className='pagination-button btn'
        disabled={inputValue === 1}
      >
        <img
          src='/icons/ico_arrow_left.svg'
          alt='previous page icon'
          aria-hidden='true'
        />
      </button>
      {/* input & span */}
      {renderPages()}
      {/* next page btn */}
      <button
        data-cy='pagination_next_page_btn'
        aria-label='go to next page'
        href='#'
        onClick={onControlClick.bind(null, 'next')}
        disabled={inputValue === maxVal}
        className='pagination-button btn'
      >
        <img
          src='/icons/ico_arrow_right.svg'
          alt='next page icon'
          aria-hidden='true'
        />
      </button>
      {/* last page btn */}
      <button
        data-cy='pagination_last_page_btn'
        aria-label='go to last page'
        href='#'
        onClick={onControlClick.bind(null, 'last')}
        disabled={inputValue === maxVal}
        className='pagination-button btn'
      >
        <img
          src='/icons/ico_arrow_right_to_solid.svg'
          alt='last page icon'
          aria-hidden='true'
        />
      </button>
    </StyledPagination>
  )
}

Pagination.defaultProps = {
  onChange: () => {},
  max: 0,
  selected: 0,
}

Pagination.propTypes = {
  max: PropTypes.number.isRequired,
  selected: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default memo(Pagination)
