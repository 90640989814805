import FormService from '@services/Form'
import { FormActions } from '@ui/components/interactive/FormActions'
import uuid from '@utils/uuid'
import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { StyledBar } from '../../../../../../../../../react/yb_modules/custom_esg_scoring/CustomESGScoring.styles'
import { IForm, TFormAnswer, TFormTrigger, TQuestionnaire } from '../../data'
import Controller, { TModels } from './ArticleQuestionnaire.controller'
import { DisclosureFormsContext } from '../../../../../../../../../react/yb_stores/sfdr/disclosure_forms/DisclosureFormsStore'
import _ from 'lodash'
interface IProps {
  questionnaire: TQuestionnaire
  onSubmit: (formData: unknown, validity: boolean) => void
}

const Form = new FormService()

export const ArticleQuestionnaire: React.FC<IProps> = ({
  questionnaire,
  onSubmit,
}) => {
  /* State */
  const [state] = useContext(DisclosureFormsContext)
  const { answers, triggers, localAnswers, isPdf } = state

  if (
    !questionnaire ||
    !answers ||
    (answers && !Object.entries(answers).length)
  )
    return <></>
  const { rows: questions } = questionnaire

  const [key, setKey] = useState<string>(uuid())

  /* Controller */
  const [inputs, medatada] = Controller.useDynamicModel(
    Form,
    questions,
    answers
  )

  const [rows, models] = Controller.useDynamicQuestionnaire(
    inputs,
    medatada,
    triggers
  )

  /* Forms */
  const formData = Form.useFormData(models.values)
  const validity = Form.useFormValidity(models.feedbacks)
  const [snapshot] = Form.useFormDataSnapshot(formData)

  /* Events */
  const handleCancel = () => {
    Form.setFormModel(models.values, snapshot)
    setKey(uuid())
  }

  const isDisabled = key => {
    let localDisabled = false

    if (triggers[key]) {
      const { key: triggerKey, value: triggerValue } = triggers[key]

      let currentValue = localAnswers?.[triggerKey]
      currentValue = Array.isArray(currentValue)
        ? _.first(currentValue)
        : currentValue

      if (Array.isArray(currentValue))
        localDisabled = !currentValue.includes(triggerValue)
      else localDisabled = currentValue !== triggerValue
    }

    return localDisabled
  }

  const formatFormData = () => {
    const formattedData = {}

    Object.entries(formData || {})?.forEach(([key, value]) => {
      if (typeof value === 'object') {
        let localDisabled = true
        Object.entries(value || {}).forEach(([innerKey]) => {
          if (!isDisabled(innerKey)) {
            localDisabled = false
          }
        })
        if (!localDisabled) {
          formattedData[key] = value
        }
      } else {
        if (!isDisabled(key)) {
          formattedData[key] = value
        }
      }
    })
    return formattedData
  }

  /* Render */
  return (
    <>
      <div key={key}>{rows}</div>
      {!isPdf && (
        <StyledBar>
          <FormActions
            canSubmit={validity}
            onCancel={handleCancel}
            onSubmit={() => onSubmit(formatFormData(), validity)}
            submitLabel='Save and continue'
            triggers={triggers}
          />
        </StyledBar>
      )}
    </>
  )
}
