import React from 'react'
import Select from 'react-select-3'
import styled from 'styled-components'

import { BORDER_COLOR_PRIMARY, FONT_COLOR_SECONDARY } from '../../styles/colors'
import InputLabel from './InputLabel'

const SelectWrapper = styled.div`
  margin: ${props => props.margin || '10px 0 6px 0'};
  width: ${props => props.width || '100%'};
  height: ${props => props.height};
`
export const selectStyles = props => {
  return {
    container: style => ({ ...style, position: 'relative' }),
    valueContainer: style => ({
      ...style,
      padding: '10px',
      boxSizing: 'content-box',
      lineHeight: '1',
      height: '1rem',
    }),
    placeholder: style => ({ ...style, color: FONT_COLOR_SECONDARY }),
    control: style => ({
      ...style,
      borderColor: BORDER_COLOR_PRIMARY,
      fontSize: '1rem',
      height: props.height,
    }),
    indicatorSeparator: style => ({ ...style, display: 'none' }),
    singleValue: style => ({ ...style, padding: '5px 0' }),
    menu: style => ({ ...style, top: null, marginTop: 0 }),
  }
}

const YBSelect = ({ margin, width, height, label, ...props }) => {
  return (
    <SelectWrapper
      margin={margin}
      width={width}
      height={height}
      data-cy={props['data-cy']}
    >
      {label && <InputLabel label={label} />}
      <Select styles={selectStyles({ height })} {...props} />
    </SelectWrapper>
  )
}

export default YBSelect
