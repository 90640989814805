import React from 'react'

import HorizontalScore from '../../../components/styleguide/HorizontalScore'
import PortfolioIndexTable from '../../../components/styleguide/PortfolioIndexTable'
import SectionWrapper from '../../../components/styleguide/SectionWrapper'
import SkeletonBox from '../../../components/styleguide/skeleton/SkeletonBox'
import SkeletonChart from '../../../components/styleguide/skeleton/SkeletonChart'
import TextSection from '../../../components/styleguide/TextSection'
import WrapperColumn from '../../../components/styleguide/WrapperColumn'
import { getProgressColor } from '../../../helpers/esg_scale'
import { APP_COLOR_PRIMARY_DARK } from '../../../styles/colors'
import ESGScoreBreakdownBar from './ESGScoreBreakdownBar'

const PortfolioInfo = ({ portfolio, skeleton }) => {
  const skeletonHoldings = []

  for (let i = 0; i <= 4; i++) {
    skeletonHoldings.push(
      <tr key={i}>
        <td>
          <SkeletonBox width='122px' height='15px' margin='5px 0 5px 0' />
        </td>
        <td>
          <SkeletonBox width='45px' height='15px' margin='5px 0 5px auto' />
        </td>
      </tr>
    )
  }

  return (
    <SectionWrapper justify='space-between' width='100%' wrap='nowrap'>
      <WrapperColumn styles={{ margin: '0px 20px 0px 0px', width: '50%' }}>
        {(skeleton ||
          (portfolio?.e_score && portfolio?.s_score && portfolio?.g_score)) && (
          <>
            <SectionWrapper wrap='nowrap'>
              <TextSection fontSize='36px' color='#2E384D' width='auto'>
                {skeleton ? (
                  <SkeletonBox margin='16px 0 0 6px' height='44px' width='59px' />
                ) : (
                  `${(+portfolio.total_score).toFixed(0)}%`
                )}
              </TextSection>
              <SectionWrapper
                width='100%'
                align='stretch'
                margin='6px 0px 0px 10px'
              >
                {skeleton ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      marginTop: '10px',
                    }}
                  >
                    <SkeletonBox
                      width='94px'
                      height='14px'
                      margin='15px 0px 0px 0'
                    />
                    <SkeletonBox width='271px' height='10px' margin='0px' />
                  </div>
                ) : (
                  <HorizontalScore
                    label='OVERALL ESG SCORE'
                    score={portfolio.total_score}
                    color={getProgressColor(
                      portfolio.total_score,
                      portfolio.custom_esg_labels
                    )}
                  />
                )}
              </SectionWrapper>
            </SectionWrapper>
            <SectionWrapper height='250px'>
              {skeleton ? (
                <SkeletonChart />
              ) : (
                <ESGScoreBreakdownBar
                  e_score={portfolio.e_score}
                  s_score={portfolio.s_score}
                  g_score={portfolio.g_score}
                  index={portfolio.id}
                  customESGLabels={portfolio.custom_esg_labels}
                />
              )}
            </SectionWrapper>
          </>
        )}
      </WrapperColumn>
      <SectionWrapper height='320px' width='40%' margin='-10px 0px 0px 0px'>
        <PortfolioIndexTable>
          <thead>
            <tr>
              {skeleton ? (
                <SkeletonBox
                  height='17px'
                  width='100px'
                  margin='15px 15px 20px 12px'
                />
              ) : (
                <th colSpan='2'>Top Holdings</th>
              )}
            </tr>
          </thead>
          <tbody>
            {skeleton
              ? skeletonHoldings
              : portfolio.holdings.map(holding => {
                  return (
                    <tr key={holding.id}>
                      <td>{holding.description}</td>
                      <td style={{ color: `${APP_COLOR_PRIMARY_DARK}` }}>{`${(
                        +holding.weight * 100
                      ).toFixed(2)}%`}</td>
                    </tr>
                  )
                })}
          </tbody>
        </PortfolioIndexTable>
      </SectionWrapper>
    </SectionWrapper>
  )
}

export default PortfolioInfo
