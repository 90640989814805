import dayjs from 'dayjs'
import React, { useMemo } from 'react'

import { REPORT_SUMMARY_CARD_COPY } from '../../../../modules/instrument/constants'
import BudgetProjections from '../../../../modules/portfolio/components/carbon_report/BudgetProjections'
import CarbonOwnership from '../../../../modules/portfolio/components/carbon_report/CarbonOwnership'
import CarbonTaxExposure from '../../../../modules/portfolio/components/carbon_report/CarbonTaxExposure'
import LeadersLaggards from '../../../../modules/portfolio/components/carbon_report/LeadersLaggards'
import PowerCompanyTransition from '../../../../modules/portfolio/components/carbon_report/PowerCompanyTransition'
import RenewableEnergyPurchasing from '../../../../modules/portfolio/components/carbon_report/RenewableEnergyPurchasing'
import ReportSummaryCard from '../../../../modules/portfolio/components/carbon_report/ReportSummaryCard'
import ScienceBasedTargetsInitiative from '../../../../modules/portfolio/components/carbon_report/ScienceBasedTargetsInitiative'
import SectorAllocation from '../../../../modules/portfolio/components/carbon_report/SectorAllocation'
import SolutionCompanyHoldings from '../../../../modules/portfolio/components/carbon_report/SolutionCompanyHoldings'
import ReportPage from '../../../yb_layout/report_page/ReportPage'
import { CARBON_REPORT_PDF_TEXTS } from './CarbonReport.constants'

const CarbonReport = ({
  targetName,
  benchmarkName,
  summaryData,
  holdingOwnerships,
  sectorAllocations,
  carbonTaxRates,
  renewableEnergy,
  powerCompanyTransitions,
  solutionCompanies,
  sbti,
  budgetProjections,
  leadersLaggards,
  impactBenchmark,
}) => {
  const localDate = useMemo(() => dayjs(), [])

  return (
    <ReportPage
      title={'Carbon Report'}
      targetName={targetName}
      benchmarkName={benchmarkName}
      asOf={localDate}
    >
      {/* Page 1 */}
      <ReportSummaryCard
        landscapePdf
        dataProp={summaryData}
        title={REPORT_SUMMARY_CARD_COPY.title}
        moduleDescription={REPORT_SUMMARY_CARD_COPY.moduleDescription}
      />
      <CarbonOwnership
        pdf
        defaultData={holdingOwnerships?.data}
        defaultSort={holdingOwnerships?.sort}
        title={CARBON_REPORT_PDF_TEXTS?.carbonOwnership?.title}
        description={CARBON_REPORT_PDF_TEXTS?.carbonOwnership?.description}
      />
      <div style={{ pageBreakBefore: 'always' }} />

      {/* Page 2 */}
      <SectorAllocation
        pdf
        defaultData={sectorAllocations?.data}
        defaultTotals={sectorAllocations?.totals}
        defaultSort={sectorAllocations?.sort}
        title={CARBON_REPORT_PDF_TEXTS?.sectorAllocation?.title}
        description={CARBON_REPORT_PDF_TEXTS?.sectorAllocation?.description}
      />
      <div style={{ pageBreakBefore: 'always' }} />

      {/* Page 3 */}
      <LeadersLaggards
        pdf
        defaultData={leadersLaggards?.targetData}
        defaultBenchmarkData={leadersLaggards?.benchmarkData}
        defaultSort={leadersLaggards?.sort}
        defaultImpactBenchmark={impactBenchmark}
        title={CARBON_REPORT_PDF_TEXTS?.leadersLaggards?.title}
        description={CARBON_REPORT_PDF_TEXTS?.leadersLaggards?.description}
      />
      <div style={{ pageBreakBefore: 'always' }} />

      {/* Page 4 */}
      <ScienceBasedTargetsInitiative
        pdf
        defaultData={sbti?.data}
        defaultBarData={sbti?.barData}
        defaultSort={sbti?.sort}
        title={CARBON_REPORT_PDF_TEXTS?.sbti?.title}
        description={CARBON_REPORT_PDF_TEXTS?.sbti?.description}
      />
      <BudgetProjections
        pdf
        defaultData={budgetProjections?.projections}
        defaultBarData={budgetProjections?.totals}
        defaultSort={budgetProjections?.sort}
        title={CARBON_REPORT_PDF_TEXTS?.budgetProjections?.title}
        description={CARBON_REPORT_PDF_TEXTS?.budgetProjections?.description}
      />
      <div style={{ pageBreakBefore: 'always' }} />

      {/* Page 5 */}
      <CarbonTaxExposure
        pdf
        defaultData={carbonTaxRates?.data}
        defaultSort={carbonTaxRates?.sort}
        title={CARBON_REPORT_PDF_TEXTS?.carbonTaxRates?.title}
        description={CARBON_REPORT_PDF_TEXTS?.carbonTaxRates?.description}
      />
      <div style={{ pageBreakBefore: 'always' }} />

      {/* Page 6 */}
      <RenewableEnergyPurchasing
        pdf
        defaultData={renewableEnergy?.data}
        defaultSort={renewableEnergy?.sort}
        title={CARBON_REPORT_PDF_TEXTS?.renewableEnergy?.title}
        description={CARBON_REPORT_PDF_TEXTS?.renewableEnergy?.description}
      />
      <div style={{ pageBreakBefore: 'always' }} />

      {/* Page 7 */}
      <PowerCompanyTransition
        pdf
        defaultData={powerCompanyTransitions?.data}
        defaultSort={powerCompanyTransitions?.sort}
        title={CARBON_REPORT_PDF_TEXTS?.powerCompanyTransitions?.title}
        description={
          CARBON_REPORT_PDF_TEXTS?.powerCompanyTransitions?.description
        }
      />
      <SolutionCompanyHoldings
        pdf
        defaultData={solutionCompanies?.data}
        defaultSort={solutionCompanies?.sort}
        title={CARBON_REPORT_PDF_TEXTS?.solutionCompanies?.title}
        description={CARBON_REPORT_PDF_TEXTS?.solutionCompanies?.description}
      />
    </ReportPage>
  )
}

export default CarbonReport
