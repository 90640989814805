import { RadioButton } from '@ui/components/interactive/RadioButton'
import { KeyValueList } from '@ui/components/presentation/lists/KeyValueList'
import classnames from 'classnames'
import React, { useState } from 'react'

// import theme from './FundAlignmentRadio.module.scss'
import './FundAlignmentRadio.module.scss'

interface IProps {
  isComplient: boolean
  model: YB.TModel<string>
  article: 6 | 8 | 9
  description: JSX.Element | string
}

const Yes = () => <span className='yes'>yes</span>
const No = () => <span className='no'>no</span>

export const FundAlignmentRadio: React.FC<IProps> = ({
  isComplient,
  model = useState<string>(''),
  article: articleId,
  description,
}) => {
  // const items = [
  //     { key: 'Fund alignment', value: <Yes /> },
  //     { key: 'PAI', value: <No /> },
  //     { key: 'EU Taxonomy Data', value: <Yes /> },
  //     { key: 'EU Taxonomy Aligned', value: <No /> },
  // ]

  return (
    <div className='FundAlignmentRadio'>
      <RadioButton
        label={`Article ${articleId}`}
        name={'article'}
        value={articleId.toString()}
        model={model}
        required={false}
      />
      <p>{description}</p>
      {/* <p
        className={classnames('compliance', {
          compliant: isComplient,
        })}
      >
        {isComplient
          ? `The fund does currently meet requirements for Article ${articleId}.`
          : `The fund does not currently meet requirements for Article ${articleId}.`}
      </p> */}
      {/* <KeyValueList
                keyTitle='Criteria'
                valueTitle='Fulfilled'
                items={items}
            /> */}
    </div>
  )
}
