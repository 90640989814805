import axios from 'axios'

export const getEsgByEntityId = id => {
  return axios.get(`/app/api/entities/${id}/esg`).then(res => {
    const environmental = []
    const social = []
    const governance = []
    res.data.focus_areas.map(el => {
      if (el.category === 'environmental') environmental.push(el)
      if (el.category === 'social') social.push(el)
      if (el.category === 'governance') governance.push(el)
    })
    const focusAreas = {
      environmental,
      social,
      governance,
    }
    return focusAreas
  })
}

export const createFocusAreaNote = (entityId, focusAreaId, note) => {
  return axios.post(
    `/app/api/entities/${entityId}/focus_areas/${focusAreaId}/notes`,
    { note }
  )
}

export const createFocusArea = (entityId, data) => {
  if (data.initiative_id === 'custom') {
    data.initiative_id = null
  }
  return axios.post(`/app/api/entities/${entityId}/focus_areas`, {
    focus_area: data,
  })
}

export const updateFocusArea = (
  entityId,
  focusAreaId,
  editingType,
  value,
  date,
  note
) => {
  let payload = {}
  if (editingType == 'current_value') {
    payload = {
      current_value: value,
      current_as_of: new Date(),
      note: note,
    }
  } else {
    payload = {
      target_value: value,
      target_date: date,
      note: note,
    }
  }

  return axios
    .post(
      `/app/api/entities/${entityId}/focus_areas/${focusAreaId}/statuses`,
      payload
    )
    .then(res => res.data)
}

export const enableFocusArea = (entityId, focusAreaId) => {
  return axios.put(
    `/app/api/entities/${entityId}/focus_areas/${focusAreaId}/enable`
  )
}

export const disableFocusArea = (entityId, focusAreaId) => {
  return axios.put(
    `/app/api/entities/${entityId}/focus_areas/${focusAreaId}/disable`
  )
}

export const getInitiatives = ({ entityId }) => {
  return axios
    .get(`/app/api/entities/${entityId}/initiatives`)
    .then(res => res.data)
}
