import propTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { APP_COLOR_PRIMARY, FONT_COLOR_SECONDARY } from '../../styles/colors'

const StyledButton = styled.button`
  border: ${props => (props.border ? props.border : '1px solid transparent')};
  height: ${props => props.height};
  font-size: ${props => props.fontSize};
  border-radius: ${props => props.borderRadius};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  outline: none;
  -webkit-appearance: none;
  font-weight: ${props => props.fontWeight};

  color: ${props => props.color};
  background-color: ${props => props.bgColor};
  opacity: ${props => (props.disabled ? 0.5 : 'unset')};
  transition: opacity 0.3s;
  width: ${props => props.width};
  border-color: ${props => props.borderColor};

  &:focus {
    outline: none;
  }

  img {
    margin-right: 10px;
    margin-bottom: 5px;
  }
`

const Button = ({
  type,
  onClick,
  label,
  className,
  secondary,
  tertiary,
  width,
  disabled,
  borderRadius,
  height,
  margin,
  padding,
  children,
  color,
  fontSize,
  border,
  fontWeight,
  onBlur,
  ...props
}) => {
  const buttonColor = color || APP_COLOR_PRIMARY
  const btnWidth = isNaN(width) ? width : width + 'px'
  const bgColor = secondary || tertiary ? 'transparent' : buttonColor
  const fontColor = secondary
    ? buttonColor
    : tertiary
    ? FONT_COLOR_SECONDARY
    : 'white'
  const borderColor = border
    ? 'default'
    : secondary
    ? buttonColor
    : 'transparent'
  const fontWeightStyle = fontWeight ? fontWeight : tertiary ? 'normal' : 'bold'
  borderRadius = borderRadius ? borderRadius : '20px'
  height = height ? height : '40px'
  margin = margin ? margin : '0px'
  padding = padding ? padding : '0 30px'
  fontSize = fontSize ? fontSize : 'inherit'

  return (
    <StyledButton
      className={className}
      type={type}
      onClick={onClick}
      width={btnWidth}
      color={fontColor}
      bgColor={bgColor}
      borderColor={borderColor}
      disabled={disabled}
      height={height}
      borderRadius={borderRadius}
      margin={margin}
      padding={padding}
      fontSize={fontSize}
      fontWeight={fontWeightStyle}
      data-cy={props['data-cy']}
      border={border}
      onBlur={onBlur}
    >
      {label || children}
    </StyledButton>
  )
}

Button.defaultProps = {
  type: 'button',
  width: '100%',
}

Button.propTypes = {
  type: propTypes.string,
  onClick: propTypes.func,
  label: propTypes.node,
  color: propTypes.string,
  secondary: propTypes.bool,
  disabled: propTypes.bool,
  border: propTypes.string,
  fontWeight: propTypes.string,
}

export default Button
