import React from 'react'
import withFormControl from '@ui/components/compositions/withFormControl'
import {
  BaseInput,
  IBaseInputProps,
} from '@ui/components/interactive/BaseInput'

interface IProps extends IBaseInputProps {
  type?: 'date' | 'month' | 'datetime-local' | 'week' | 'time' | 'year'
}

const DatePicker: React.FC<IProps> = ({ type = 'date', ...baseInputProps }) => {
  return <BaseInput {...baseInputProps} type={type} />
}

export default DatePicker

export const DatePickerControl = withFormControl<IProps>(DatePicker)
