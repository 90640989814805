import propTypes from 'prop-types'
import React from 'react'

import { GENDER_GAP_EXPLAINER_URL } from '../../yb_constants/urls/explainers'
import ImpactCard from './ImpactCard'

const GenderImpactCard = ({
  targetType,
  data,
  minHeight,
  benchmarkData,
  benchmarkName,
}) => {
  const benchmarkComparison = data.total - benchmarkData?.total
  const benchmarkComparisonAbs = Math.abs(benchmarkComparison).toFixed(2)

  return (
    <ImpactCard
      targetType={targetType}
      title='GENDER PROMOTION GAP'
      value={Math.round(data.total * 10) / 10}
      unit='×'
      holdingsPct={data.holdings_ownership_pct}
      description='More likely for a male representing person <br/> to be a manager than a female representing person.'
      benchmarkDescription={
        benchmarkName && {
          text: `${benchmarkComparisonAbs}x ${
            benchmarkComparison > 0 ? 'more' : 'less'
          } likely than the ${benchmarkName}.`,
          color: benchmarkComparison > 0 ? '#D02F44' : '#00D679',
        }
      }
      minHeight={minHeight}
      icons={<img src='/impact_icons/ico_gender.svg' />}
      explainerHref={GENDER_GAP_EXPLAINER_URL}
    />
  )
}

GenderImpactCard.defaultProps = {
  data: {
    total: 0,
  },
}

GenderImpactCard.propTypes = {
  data: propTypes.shape({
    total: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
  }).isRequired,
}

export default GenderImpactCard
