import Color from 'color'
import propTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { BG_COLOR_SECONDARY } from '../../../styles/colors'

const IconBackground = styled.div`
  width: ${props => props.widthRem};
  height: ${props => props.widthRem};
  background-color: ${props =>
    props.backgroundColor || props.iconColor.fade(0.8).string()};
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    max-width: 60%;
    max-height: 60%;
    fill: ${props => props.iconColor.hex()};

    & path {
      fill: ${props => props.iconColor.hex()};
    }
  }
`

const RoundBackgroundIcon = ({
  iconColor,
  backgroundColor,
  icon,
  widthRem,
  ...props
}) => {
  const cleanColor = Color(iconColor)

  return (
    <IconBackground
      iconColor={cleanColor}
      backgroundColor={backgroundColor}
      widthRem={widthRem}
      data-cy={props['data-cy']}
    >
      {icon}
    </IconBackground>
  )
}

RoundBackgroundIcon.propTypes = {
  iconColor: propTypes.string.isRequired,
  backgroundColor: propTypes.string,
  icon: propTypes.node.isRequired,
  widthRem: propTypes.string,
  'data-cy': propTypes.string,
}

RoundBackgroundIcon.defaultProps = {
  widthRem: '2rem',
  iconColor: BG_COLOR_SECONDARY,
}

export default RoundBackgroundIcon
