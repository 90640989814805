import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {
  getTcfdAccentColor,
  getTcfdColor,
  getTcfdHoverColor,
  sanitizedTcfdNumber,
} from '../../../utils/tcdf_colors'

const StyledTableData = styled.td`
  position: relative;
  text-align: center;
  color: ${props => props.color};
  background-color: ${props => props.bgColor};
  border: 1px solid white;
  height: 100%;
  display: table-cell;

  &:nth-child(3) {
    border-left: 10px solid white;
    border-right: 10px solid white;
  }
  &:nth-child(4) {
    border-right: 10px solid white;
  }
  &:nth-child(5) {
    border-right: 10px solid white;
  }
  &:nth-child(8) {
    border-right: 10px solid white;
  }
  &:nth-child(10) {
    border-right: 10px solid white;
  }

  a {
    min-width: 88px;
    width: 100%;
    min-height: 50px;
    height: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: ${props => props.color};
    background-color: ${props => props.bgColor};
    transition: 0.15s background-color ease-in-out;

    &:hover {
      background-color: ${props => props.hoverBgColor};
    }
  }
`

export const TcfdTableColorCell = ({ href, number, style }) => {
  return (
    <StyledTableData
      color={getTcfdAccentColor(number)}
      bgColor={getTcfdColor(number)}
      hoverBgColor={getTcfdHoverColor(number)}
      style={style}
    >
      <a href={href}>{number != null ? sanitizedTcfdNumber(number) : '—'}</a>
    </StyledTableData>
  )
}

TcfdTableColorCell.propTypes = {
  href: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}
