import React, { useState } from 'react'
import styled from 'styled-components'

import Slider from '../../../components/styleguide/Slider'
import {
  BG_COLOR_SECONDARY,
  FONT_COLOR_SECONDARY,
  FONT_COLOR_TERTIARY,
} from '../../../styles/colors'

const StyledPillar = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(12, 1fr);

  .label {
    grid-column: span 2;

    font-weight: bold;
    font-size: 12px;
    color: ${FONT_COLOR_TERTIARY};
    text-transform: uppercase;
  }
  .slider {
    grid-column: span 9;
  }

  input[type='number'] {
    grid-column: span 1;

    text-align: center;
    color: ${FONT_COLOR_SECONDARY};
    font-family: 'Lato';

    border: 1px solid ${BG_COLOR_SECONDARY};
    border-radius: 6px;
    width: 60px;
  }

  .percent {
    position: relative;
  }
  .percent::after {
    position: absolute;
    top: 2px;
    left: 39px;
    color: ${FONT_COLOR_SECONDARY};
    content: '%';
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }

  input::-webkit-textfield-decoration-container {
    padding-right: 2px;
  }
`
const Pillar = ({ label, defaultValue, onChange }) => {
  const [weight, setWeight] = useState(defaultValue)

  const handleWeightInput = inputValue => {
    if (inputValue <= 100 && inputValue >= 0) {
      onChange(inputValue)
      setWeight(inputValue)
    }
  }

  const handleInputSync = inputValue => {
    if (inputValue <= 100 && inputValue >= 0) {
      setWeight(inputValue)
    }
  }

  return (
    <StyledPillar className='pillar'>
      <div className='label'>{label}</div>
      <Slider
        defaultValue={weight}
        onChange={sliderValue => handleWeightInput(sliderValue)}
        handleInputSync={sliderValue => handleInputSync(sliderValue)}
      />
      <div className='percent'>
        <input
          type='number'
          value={parseInt(weight)}
          onChange={e => handleWeightInput(e.target.value)}
        />
      </div>
    </StyledPillar>
  )
}

export default Pillar
