import { ERROR, WARNING } from '../../../yb_components/styleguide/image_file_input/ImageFileInput.constants';

export const PRIMARY_SMALL_IMAGE_ERROR = 'Primary Logo must be at least 200x200 pixels'
export const SECONDARY_SMALL_IMAGE_ERROR = 'Secondary Logo must be at least 400x200 pixels'
export const PRIMARY_IMAGE_RATIO_WARNING = 'For best results, Primary Logo should be square'
export const IMAGE_TOO_LARGE_ERROR = 'Maximum image size allowed is 4MB'

export const MAXIMUM_FILE_SIZE = 4 * 1024 * 1024 // 4MB
export const ALERT_DISPLAY_TIME = 3000 // ms

export const primaryLogoValidations = {
  min: {
    width: 200,
    height: 200,
    message: PRIMARY_SMALL_IMAGE_ERROR,
    messageType: ERROR,
  },
  widthPerHeight: {
    value: 1,
    message: PRIMARY_IMAGE_RATIO_WARNING,
    messageType: WARNING,
  },
  maxFileSize: {
    value: MAXIMUM_FILE_SIZE,
    message: IMAGE_TOO_LARGE_ERROR,
    messageType: ERROR,
  },
}

export const secondaryLogoValidations = {
  min: {
    width: 400,
    height: 200,
    message: SECONDARY_SMALL_IMAGE_ERROR,
    messageType: ERROR,
  },
  maxFileSize: {
    value: MAXIMUM_FILE_SIZE,
    message: IMAGE_TOO_LARGE_ERROR,
    messageType: ERROR,
  },
}

export const allowedFileTypes = ['image/png', 'image/gif', 'image/jpeg']
