import { titleCase } from './strings';

/**
 * Converts from response to supported Pie Chart format
 * 
 * @param {*} values Values returted by request
 * @param {*} labelProperty Label property name
 * @param {*} valueProperty Value property name
 * 
 * @returns The array in the Pie Chart supported format
 */
export const convertToPieChartData = (values, labelProperty = 'label', valueProperty = 'percentage', labelFormatter = titleCase) => {
    if (!values) return null;

    let formattedData = [];
    values.forEach((elem, index) => {
        formattedData.push({
            index: index,
            id: elem[labelProperty],
            label: labelFormatter ? labelFormatter(elem[labelProperty]) : elem[labelProperty],
            value: elem[valueProperty],
        });
    });

    return formattedData;
}

/**
 * Adds filter select options to Columns object (from react-table) to be used in custom filter components
 * 
 * @param {Array} columns The columns previousely defined (as in react-table library)
 * @param {Array} filterSelectOptions An array that contains the filter select options (typically sent by the server)
 * @param {function} defaultFilterLabelFormatter An optional formatter for the label
 * 
 * @returns New columns array with property `selectOptions` nested under `filter` object
 * 
 */
export const addFilterOptionsToColumns = (columns, filterSelectOptions, defaultFilterLabelFormatter = null) => {
    if (!columns || !filterSelectOptions) return null;
    let aColumns = columns;

    for (const column of aColumns) {
        if (column?.filter?.selectOptions) continue;

        /* Check if the specific filter exist */
        const values = filterSelectOptions[column.accessor];
        if (!values) continue;

        /* Create options array */
        let options = [];
        for (const value of values) {
            let fLabel = value;

            if (column?.filter?.labelFormatter) fLabel = column.filter.labelFormatter(value);
            else if (defaultFilterLabelFormatter) fLabel = defaultFilterLabelFormatter(value);

            options.push({
                value: value,
                label: fLabel
            });
        }
        if (!column.filter) column.filter = {};
        column.filter.selectOptions = options;
    }
    return aColumns;
}

/**
 * Convert bucket data (used in the map legend for example) to filter select options
 * 
 * @param {{label: string, dotColor: string, lowerBound: number, upperBound: number}[]} bucketData The bucket data array
 * @param {number} scaleNr The scale with which we multiply the `upperBound` and `lowerBound`
 * 
 * @returns A filter select options array
 */
export const bucketToFilterSelectOptions = (bucketData, scaleNr = 1000000) => {
    let filterSelectOptions = [];
    for (const bucket of bucketData) {
        const upperBound = (bucket.upperBound != null) ? (bucket.upperBound * scaleNr) : '';
        const lowerBound = (bucket.lowerBound != null) ? (bucket.lowerBound * scaleNr) : '';

        filterSelectOptions.push({
            value: [lowerBound, upperBound],
            label: bucket.label
        })
    }
    return filterSelectOptions;
}