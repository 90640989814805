import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { BaseLabelButton } from '../../../../../yb_components/styleguide/form/base/buttons/BaseLabelButton'
import { BaseFormGroup } from '../../../../../yb_components/styleguide/form/base/form_group/BaseFormGroup'
import { BaseErrorText } from '../../../../../yb_components/styleguide/form/base/help_text/BaseErrorText'
import { BaseCombobox } from '../../../../../yb_components/styleguide/form/base/inputs/BaseCombobox'
import { BaseInput } from '../../../../../yb_components/styleguide/form/base/inputs/BaseInput'
import { BaseLabel } from '../../../../../yb_components/styleguide/form/base/label/BaseLabel'
import { postUploadLogo } from '../../repositories/custom_companies_repository'
import { BaseFileDropZone } from './BaseFileDropZone'
import { StyledCard } from './FormCard'

export const BaseInfoSection = ({ countriesList, imageUrl, isEditing }) => {
  const {
    control,
    formState: { errors },
    resetField,
    setValue,
  } = useFormContext()

  const [sectionData, setSectionData] = useState({})
  const [localBlobIdError, setLocalBlobIdError] = useState({
    status: false,
    message: '',
  })

  useEffect(() => {
    isEditing && setSectionData({ logo_url: imageUrl })
  }, [imageUrl])

  const handleUploadFile = async uploadedFile => {
    // validate file type
    const fileType = uploadedFile[0].type
    if (
      fileType !== 'image/png' &&
      fileType !== 'image/jpg' &&
      fileType !== 'image/jpeg' &&
      fileType !== 'image/gif'
    ) {
      setLocalBlobIdError({
        status: true,
        message: 'Image file type must be PNG, JPG, or GIF.',
      })
      return
    } else {
      setLocalBlobIdError({
        status: false,
        message: '',
      })
      resetField('company_logo_blob_id')
    }

    var formData = new FormData()
    formData.append('file', uploadedFile[0])
    try {
      const res = await postUploadLogo(formData)
      setSectionData({ logo_url: res.url })
      setValue('company_logo_blob_id', res.id, {
        shouldValidate: true,
        shouldDirty: true,
      })
    } catch (e) {
      setLocalBlobIdError({
        status: true,
        message: 'Image upload failed. Please try again.',
      })
      console.error(e)
    }
  }

  const handleRemoveImage = () => {
    setValue('company_logo_blob_id', null, {
      shouldValidate: true,
      shouldDirty: true,
    })
    setSectionData({ logo_url: null })
  }

  const currentYear = new Date().getFullYear()

  return (
    <StyledCard title='Base Information' className='base-info-card'>
      <section>
        {/* company name */}
        <BaseFormGroup controlId='name' style={{ flexGrow: '1' }}>
          <BaseLabel>
            Company Name <small>(Required)</small>
          </BaseLabel>
          <BaseInput
            control={control}
            name='name'
            rules={{ required: true }}
            isInvalid={!!errors.name}
          />
          {errors?.name && (
            <BaseErrorText>Company Name is required.</BaseErrorText>
          )}
        </BaseFormGroup>
        {/* website */}
        <BaseFormGroup
          controlId='website_input'
          style={{ width: 'calc(50% - 10px - 20px - 180px)' }}
        >
          <BaseLabel>Website</BaseLabel>
          <BaseInput
            type='text'
            control={control}
            name='website_url'
            rules={{ maxLength: 200 }}
          />
        </BaseFormGroup>
        {/* year founded */}
        <BaseFormGroup
          controlId='year_founded_input'
          style={{ minWidth: '180px' }}
        >
          <BaseLabel>Year Founded</BaseLabel>
          <BaseInput
            type='number'
            control={control}
            name='year_founded'
            rules={{
              min: {
                value: 1800,
                message: `Year Founded must be between 1800 and ${currentYear}.`,
              },
              max: {
                value: currentYear,
                message: `Year Founded must be between 1800 and ${currentYear}.`,
              },
            }}
            isInvalid={!!errors.year_founded}
          />
          {errors?.year_founded && (
            <BaseErrorText>{errors.year_founded.message}</BaseErrorText>
          )}
        </BaseFormGroup>
      </section>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: '20px',
        }}
      >
        {/* headquarters address */}
        <BaseFormGroup controlId='headquarters_address_input'>
          <BaseLabel>Headquarters Address</BaseLabel>
          <BaseInput control={control} name='headquarters_address' />
        </BaseFormGroup>
        {/* country of domicile */}
        <BaseFormGroup controlId='country_of_incorporation_select'>
          <BaseLabel>
            Country of Domicile <small>(Required)</small>
          </BaseLabel>
          <Controller
            control={control}
            name='country_of_incorporation'
            render={({ field: { onChange, value, name, ref } }) => (
              <BaseCombobox
                inputRef={ref}
                value={countriesList.find(c => c.alpha3_code === value)}
                getOptionValue={option => `${option.alpha3_code}`}
                getOptionLabel={option => `${option.name}`}
                options={countriesList}
                control={control}
                name={name}
                rules={{ required: true }}
                onChange={opt => {
                  onChange(opt.alpha3_code)
                }}
              />
            )}
          />
        </BaseFormGroup>
        {/* country of risk */}
        <BaseFormGroup controlId='country_of_hq_select'>
          <BaseLabel>
            Country of Risk <small>(Required)</small>
          </BaseLabel>
          <Controller
            control={control}
            name='country_of_hq'
            render={({ field: { onChange, value, name, ref } }) => (
              <BaseCombobox
                inputRef={ref}
                value={countriesList.find(c => c.alpha3_code === value)}
                getOptionValue={option => `${option.alpha3_code}`}
                getOptionLabel={option => `${option.name}`}
                options={countriesList}
                control={control}
                name={name}
                rules={{ required: true }}
                onChange={opt => {
                  onChange(opt.alpha3_code)
                }}
              />
            )}
          />
        </BaseFormGroup>
      </div>
      <section>
        {/* description */}
        <BaseFormGroup
          controlId='description_textarea'
          style={{ flexGrow: '1' }}
        >
          <BaseLabel>Description</BaseLabel>
          <BaseInput
            as='textarea'
            style={{ minHeight: '180px' }}
            placeholder='Enter a description of the company for printing on reports.'
            control={control}
            name='description'
          />
        </BaseFormGroup>
        {/* company logo */}
        <BaseFormGroup
          controlId='company_logo_input'
          style={{ width: 'calc(50% - 10px)' }}
        >
          <BaseLabel>
            Company Logo
            {sectionData.logo_url && (
              <BaseLabelButton
                style={{ position: 'absolute', top: '-2px', right: '0' }}
                variant='outline-danger'
                onClick={() => handleRemoveImage()}
              >
                Remove Image
              </BaseLabelButton>
            )}
          </BaseLabel>
          {sectionData.logo_url ? (
            <div
              style={{
                width: '100%',
                height: '180px',
                border: '1px solid #e6e7ea',
                borderRadius: '6px',
                overflow: 'hidden',
                textAlign: 'center',
              }}
            >
              <img
                style={{
                  maxHeight: '100%',
                  maxWidth: '100%',
                }}
                src={sectionData.logo_url}
                alt='Company Logo'
              />
            </div>
          ) : (
            <BaseFileDropZone
              handleDropFile={handleUploadFile}
              errors={localBlobIdError}
              name='company_logo_blob_id'
              id='company_logo_blob_id'
            />
          )}
          {localBlobIdError?.status && (
            <BaseErrorText>{localBlobIdError.message}</BaseErrorText>
          )}
        </BaseFormGroup>
      </section>
    </StyledCard>
  )
}
