import React, { isValidElement, ReactNode } from 'react'

// import theme from './TreeDiagram.module.scss'
import './TreeDiagram.module.scss'

interface IProps {
  branches: TBranch[]
}

export type TBranch = ReactNode | ITrunk

export type ITrunk = {
  node: ReactNode
  branches: TBranch[]
}

export const TreeDiagram: React.FC<IProps> = ({ branches }) => {
  if (!branches?.length) return <></>

  const renderNode = (branch: TBranch) => {
    if (isValidElement(branch)) {
      return (
        <div className='node'>
          <div className='content'>{branch}</div>
        </div>
      )
    }

    const { node, branches } = branch as ITrunk

    return (
      <div className='node'>
        <div className='content'>{node}</div>
        {renderBranches(branches)}
      </div>
    )
  }

  const renderBranches = (branches: TBranch[]) => (
    <div className='branch'>{branches.map(renderNode)}</div>
  )

  return <div className='TreeDiagram'>{renderBranches(branches)}</div>
}
