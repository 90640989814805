import {
  APP_COLOR_PRIMARY,
  BG_COLOR_SECONDARY,
  ESG_SCALE_COLOR_EXCELLENT,
  FONT_COLOR_SECONDARY,
} from '../../../styles/colors'

const marketCapStyles = {
  container: () => ({ width: '100%' }),
  input: () => ({ height: 40 }),
  menu: style => ({
    ...style,
    top: null,
    marginTop: 0,
    width: '33%',
    marginLeft: '15px',
  }),
  placeholder: () => ({ color: FONT_COLOR_SECONDARY, padding: '7px' }),
  indicatorSeparator: () => ({ display: 'none' }),
  selectContainer: () => ({}),
  multiValueLabel: style => ({ ...style, color: APP_COLOR_PRIMARY }),
  control: styles => ({
    ...styles,
    backgroundColor: 'white',
    marginLeft: '10px',
    borderRadius: '9.5px',
    marginRight: '10px',
    borderColor: BG_COLOR_SECONDARY,
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: '#E9EFFF',
    color: APP_COLOR_PRIMARY,
    borderRadius: '20.5px',
    fontWeight: 'bold',
    padding: '3.5px',
  }),
  multiValueRemove: provided => ({
    ...provided,
    ':hover': {
      backgroundColor: APP_COLOR_PRIMARY,
      color: FONT_COLOR_SECONDARY,
      borderRadius: '12.5px',
      fontWeight: 'bold',
    },
  }),
}

const geoStyles = {
  container: () => ({ width: '100%' }),
  input: () => ({ height: 40 }),
  menu: style => ({
    ...style,
    top: null,
    marginTop: 0,
    width: '33%',
    marginLeft: '15px',
  }),
  placeholder: () => ({ color: FONT_COLOR_SECONDARY, padding: '7px' }),
  indicatorSeparator: () => ({ display: 'none' }),
  multiValueLabel: style => ({ ...style, color: '#2631AC' }),
  control: styles => ({
    ...styles,
    backgroundColor: 'white',
    marginLeft: '10px',
    borderRadius: '9.5px',
    marginRight: '10px',
    borderColor: BG_COLOR_SECONDARY,
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: '#E6E8FE',
    color: '#2631AC',
    borderRadius: '20.5px',
    fontWeight: 'bold',
    padding: '3.5px',
  }),
  multiValueRemove: provided => ({
    ...provided,
    ':hover': {
      backgroundColor: APP_COLOR_PRIMARY,
      color: '#2631AC',
      borderRadius: '12.5px',
      fontWeight: 'bold',
    },
  }),
}

const sectorStyles = {
  container: () => ({ width: '100%' }),
  input: () => ({ height: 40 }),
  menu: style => ({
    ...style,
    top: null,
    marginTop: 0,
    width: '33%',
    marginLeft: '15px',
  }),
  placeholder: () => ({ color: FONT_COLOR_SECONDARY, padding: '7px' }),
  indicatorSeparator: () => ({ display: 'none' }),
  control: styles => ({
    ...styles,
    backgroundColor: 'white',
    marginLeft: '10px',
    borderRadius: '9.5px',
    marginRight: '10px',
    borderColor: BG_COLOR_SECONDARY,
  }),
  multiValueLabel: style => ({ ...style, color: ESG_SCALE_COLOR_EXCELLENT }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: '#B0BBFF',
    color: ESG_SCALE_COLOR_EXCELLENT,
    borderRadius: '20.5px',
    fontWeight: 'bold',
    padding: '3.5px',
  }),
  multiValueRemove: provided => ({
    ...provided,
    ':hover': {
      backgroundColor: APP_COLOR_PRIMARY,
      color: ESG_SCALE_COLOR_EXCELLENT,
      borderRadius: '12.5px',
      fontWeight: 'bold',
    },
  }),
}

const industryStyles = {
  container: () => ({ width: '100%' }),
  input: () => ({ height: 40 }),
  menu: style => ({
    ...style,
    top: null,
    marginTop: 0,
    width: '33%',
    marginLeft: '15px',
  }),
  placeholder: (styles, { isDisabled }) => ({
    color: FONT_COLOR_SECONDARY,
    fontStyle: isDisabled ? 'italic' : null,
    fontSize: isDisabled ? '13px' : null,
    padding: '7px',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? '#FAFAFA' : 'white',
    marginLeft: '10px',
    borderRadius: '9.5px',
    marginRight: '10px',
    borderColor: BG_COLOR_SECONDARY,
  }),
  multiValueLabel: style => ({ ...style, color: ESG_SCALE_COLOR_EXCELLENT }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: '#B0BBFF',
    color: ESG_SCALE_COLOR_EXCELLENT,
    borderRadius: '20.5px',
    fontWeight: 'bold',
    padding: '3.5px',
  }),
  multiValueRemove: provided => ({
    ...provided,
    ':hover': {
      backgroundColor: APP_COLOR_PRIMARY,
      color: ESG_SCALE_COLOR_EXCELLENT,
      borderRadius: '12.5px',
      fontWeight: 'bold',
    },
  }),
}

const customHoldingStyles = {
  label: () => ({ margin: '0' }),
}

export {
  customHoldingStyles,
  geoStyles,
  industryStyles,
  marketCapStyles,
  sectorStyles,
}
