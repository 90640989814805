import React from 'react';
import withErrorBoundary from '../../../hocs/withErrorBoundary';
import Card from '../../../components/styleguide/Card';
import CardTitle from '../../../components/styleguide/CardTitle';
import SectionWrapper from '../../../components/styleguide/SectionWrapper';
import TextSection from '../../../components/styleguide/TextSection'

const IndustryGroupFacts = ({ facts }) => {
  return(
    <Card style={{width:'49%', minHeight:"200px"}}>
      <CardTitle title={"Sector Fast Facts"}/>
      <SectionWrapper flex="1" margin="5px 0px 20px 14px">
        <TextSection>
          <ol style={{padding:'0px'}}>
            {
              facts && facts.map((fact) => {
                return (
                  <li>
                    {fact.body}
                    {fact.url && (
                      <span className="ml-1">
                        (<a href={fact.url} target="_blank">source</a>)
                      </span>
                    )}
                  </li>
                )
              })
            }
          </ol>
        </TextSection>
      </SectionWrapper>
    </Card>
  )
};

export default withErrorBoundary(IndustryGroupFacts);
