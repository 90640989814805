import React, { useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'

import Button from '../../../components/styleguide/Button'
import SpinnerContainer from '../../../yb_components/styleguide/spinners/SpinnerContainer'
import useNavigation from '../../../yb_hooks/useNavigation'
import AccountHeader from '../components/AccountHeader'
import PasswordReset from '../components/PasswordReset'
import { loadSignInRequest } from '../loaders/account_loaders'
import { SignInContainer } from './SignIn.styles'

const SignIn = () => {
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')
  const [code, setCode] = useState('')
  const [backUpCode, setBackUpCode] = useState(false)
  const [passwordResetRequest, setPasswordResetRequest] = useState(false)

  /*
  Handle CSS translation 
  Main section: sign-in & password-reset
  Sign In section: default & 2fa-code
  */

  const [activeMainSection, setActiveMainSection] = useState('sign-in')
  const [activeSignInSection, setActiveSignInSection] = useState('default')

  const [response, setResponse] = useState(null)

  const { navigateTo } = useNavigation()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (response?.status === 201) {
      return navigateTo('/app')
    }
    switch (response?.data?.error_type) {
      case 'otp_required':
        return handleCodeTranslation()
      case 'invalid_credentials':
        return setPassword('')
      case 'invalid_otp':
        return setCode('')
    }
  }, [response])

  const handleUserChange = e => {
    setPasswordResetRequest(false)
    setUser(e.target.value)
  }

  const handlePasswordChange = e => {
    setPasswordResetRequest(false)
    setPassword(e.target.value)
  }

  const handleCodeChange = e => {
    setPasswordResetRequest(false)
    setCode(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    loadSignInRequest(user, password, setResponse, setLoading, code, backUpCode)
  }

  const handlePasswordReset = requestSuccess => {
    if (requestSuccess) {
      setPasswordResetRequest(true)
    }
    // Handle translation
    if (activeMainSection === 'sign-in') {
      setActiveMainSection('password-reset')
    } else {
      setResponse(null)
      setPassword('')
      setActiveMainSection('sign-in')
      setActiveSignInSection('default')
    }
  }

  const handleCodeTranslation = () => {
    setActiveSignInSection('2fa-code')
  }

  const onLogIn = () => {
    localStorage.removeItem('portfolio_holdings_table_filters')
  }

  return (
    <SignInContainer
      activeMainSection={activeMainSection}
      activeSignInSection={activeSignInSection}
    >
      <AccountHeader />
      <div className='background-container'>
        <img data-cy='right_design' src='/sign_in_right_design.svg' />
      </div>
      <div className='body-container'>
        <div className='left-content'>
          <Form onSubmit={handleSubmit} data-cy='sign_in_form_group'>
            <h1>Log in</h1>
            <div className='basic-inputs'>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  data-cy='email_input'
                  type='email'
                  className='shadow-none'
                  placeholder='Enter email'
                  onChange={handleUserChange}
                  value={user}
                  required
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='formBasicPassword'>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  data-cy='password_input'
                  type='password'
                  className='shadow-none'
                  placeholder='Password'
                  onChange={handlePasswordChange}
                  value={password}
                  required
                />
              </Form.Group>
            </div>
            <div className='code-inputs'>
              <Form.Group className='mb-3' controlId='OTPcode'>
                <Form.Label>Enter your OTP code</Form.Label>
                <Form.Control
                  data-cy='otp_input'
                  autoComplete='one-time-code'
                  type='text'
                  className='shadow-none code-input'
                  placeholder='Code'
                  onChange={handleCodeChange}
                  value={code}
                />
              </Form.Group>
              <div key={`otp_backup_check_input`} className='mb-3'>
                <Form.Check
                  data-cy='otp_backup_check_input'
                  type={'checkbox'}
                  id={`otp_backup_check_input`}
                  label={`Backup code attempt`}
                  onChange={() =>
                    backUpCode ? setBackUpCode(false) : setBackUpCode(true)
                  }
                />
              </div>
            </div>
            <div className='log-in-button-container'>
              <Button type='submit' data-cy='login_button' onClick={onLogIn}>
                LOG IN
              </Button>
              <span
                className='anchor-style small-grey-text'
                onClick={() => handlePasswordReset(passwordResetRequest)}
              >
                Forgot password
              </span>
              <div className='response-container'>
                <SpinnerContainer isLoading={loading}>
                  {response ? (
                    response.status == 201 ? (
                      <Alert className='alert' variant='success'>
                        Login Successful. Redirecting to your Dashboard.
                      </Alert>
                    ) : response?.data?.error_type === 'otp_required' ? (
                      <Alert className='alert' variant='primary'>
                        {response.data.error_message}
                      </Alert>
                    ) : (
                      <Alert className='alert' variant='danger'>
                        {response.data.error_message}
                      </Alert>
                    )
                  ) : (
                    passwordResetRequest && (
                      <Alert className='alert' variant='primary'>
                        You will receive an email with instructions on how to
                        reset your password in a few minutes.
                      </Alert>
                    )
                  )}
                </SpinnerContainer>
              </div>
            </div>
          </Form>
          <PasswordReset
            activeMainSection={activeMainSection}
            handlePasswordReset={handlePasswordReset}
          />
        </div>
        <div className='right-content'>
          <p>
            <span>Yves</span>Blue distills the sustainability narrative across
            your entire portfolio
          </p>
        </div>
      </div>
      <div className='footer-container'>
        <span>
          <a data-cy='product_link' href='https://yves.blue/our-product'>
            Product
          </a>
        </span>
        <span>
          <a data-cy='solutions_link' href='https://yves.blue/solutions'>
            Solutions
          </a>
        </span>
        <span>
          <a data-cy='clients_link' href='https://yves.blue/clients'>
            Clients
          </a>
        </span>
        <span>
          <a data-cy='company_link' href='https://yves.blue/company'>
            Company
          </a>
        </span>
        <span>
          <a data-cy='resources_link' href='https://yves.blue/resources'>
            Resources
          </a>
        </span>
        <span>
          <a data-cy='knowledgbase_link' href='https://help.yves.blue/'>
            Knowledgebase
          </a>
        </span>
      </div>
    </SignInContainer>
  )
}

export default SignIn
