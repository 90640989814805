import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { SortArrows } from './SortArrows'

const StyledButton = styled.button`
  width: 100%;
  height: auto;
  padding: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  border-radius: 0;
  background: transparent;
  color: #979ca6;
  font-size: ${props => (props.isSmall ? '80%' : '.75rem')};
  line-height: 1.2;
  font-weight: ${props => (props.isSmall ? '400' : '700')};
  text-transform: ${props => (props.isSmall ? 'none' : 'uppercase')};

  ${props => {
    if (props.align === 'left')
      return css`
        justify-content: flex-start;
        text-align: left;
      `
    else if (props.align === 'right')
      return css`
        justify-content: flex-end;
        text-align: right;
      `
  }}

  span {
    display: inline-block;
    line-height: 1.2;
  }
`

export const SortDir = {
  ASC: 'asc',
  DESC: 'desc',
  NONE: 'none',
}

export const SortButton = ({
  text,
  field,
  sorting,
  updateSort,

  className,
  style,
  align,

  isSmall,
  pdf,
}) => {
  const isActive = sorting.sort_field === field ? true : false
  const direction = isActive ? sorting.sort_dir : SortDir.NONE

  const handleSort = e => {
    e.preventDefault()

    let newDir
    switch (direction) {
      case SortDir.NONE:
        newDir = SortDir.ASC
        break
      case SortDir.ASC:
        newDir = SortDir.DESC
        break
      case SortDir.DESC:
        newDir = SortDir.NONE
        break
      default:
        newDir = SortDir.NONE
    }

    updateSort(field, newDir)
  }

  return (
    <StyledButton
      isSmall={isSmall}
      onClick={e => handleSort(e)}
      style={style}
      className={className}
      align={align}
    >
      <span>{text}</span>
      {!pdf && <SortArrows direction={direction} />}
    </StyledButton>
  )
}

SortButton.propTypes = {
  field: PropTypes.string.isRequired,
  isSmall: PropTypes.bool,
  sorting: PropTypes.object.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  text: PropTypes.string.isRequired,
  updateSort: PropTypes.func.isRequired,
  className: PropTypes.object,
  align: PropTypes.string,
  pdf: PropTypes.bool,
}
