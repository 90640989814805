import PropTypes from 'prop-types'
import React from 'react'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'

import { TcfdTableHead } from './tcfd_table_head/TcfdTableHead'
import { TcfdTableRow } from './TcfdTableRow'

const StyledTable = styled.table`
  border-collapse: collapse;
  min-height: 50px;

  tbody tr:nth-child(odd) {
    background-color: #f1f1f1;
  }
  tbody tr:nth-child(even) {
    background-color: #fff;
  }

  td {
    padding: 0;
    height: 50px;
    width: 80px;
  }
`

export const TcfdTable = ({
  holdings,
  isLoading,
  portfolioId,
  snapshotId,
  sorting,
  updateSort,
}) => {
  return isLoading ? (
    <div
      style={{
        height: '1054px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spinner animation='border' variant='secondary' />
    </div>
  ) : (
    <StyledTable>
      <TcfdTableHead sorting={sorting} updateSort={updateSort} />
      <tbody>
        {holdings.map((row, index) => (
          <TcfdTableRow
            row={row}
            portfolioId={portfolioId}
            snapshotId={snapshotId}
            key={index}
          />
        ))}
      </tbody>
    </StyledTable>
  )
}

TcfdTable.propTypes = {
  holdings: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  portfolioId: PropTypes.string.isRequired,
  snapshotId: PropTypes.string.isRequired,
  sorting: PropTypes.object.isRequired,
  updateSort: PropTypes.func.isRequired,
}
