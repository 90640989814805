import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

import { APP_COLOR_PRIMARY, BORDER_COLOR_PRIMARY } from '../../../styles/colors';

import TextView from '../../../components/styleguide/TextView';
import Button from '../../../components/styleguide/Button';

const Div = styled.div`
  display: flex;
  border-bottom: 1px solid ${BORDER_COLOR_PRIMARY};
  padding-bottom: 30px;
  img {
    height: 120px;
    width: 120px;
  }
  .hedge-fund-header-text {
    padding-left: 20px;
    width: 100%;
    .hedge-fund-header-title {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const HoldingHeader = ({ fundId, holding, showRiskButton = true}) => {
  return (
    <Div>
      <div className="hedge-fund-header-text">
        <div className="hedge-fund-header-title">
          <TextView bold color={APP_COLOR_PRIMARY} size={30} text={holding.name} />

          {(showRiskButton &&
            <Button onClick={() => window.location.assign('/app/snapshots/' + fundId + '/holdings/' + holding.id + '/risk_assessment/esg_analysis')} width={200} label="RISK ASSESSMENT"/>
          )}
        </div>

        <TextView marginTop={20} secondary size={16} text={holding.instrument.description} />
      </div>
    </Div>
  );
}

HoldingHeader.propTypes = {
  holding: propTypes.shape({
    avatar_url: propTypes.string,
    name: propTypes.string.isRequired,
    description: propTypes.string
  }).isRequired
}

export default HoldingHeader;
