import { css } from 'styled-components'

export const H1 = css`
  font-size: 48px;
  font-weight: 600;
`

export const H2 = css`
  font-size: 36px;
  font-weight: 600;
`

export const H3 = css`
  font-size: 24px;
  font-weight: 500;
`

export const H4 = css`
  font-size: 18px;
  font-weight: 500;
`

export const BodyText = css`
  font-size: 15px;
  font-weight: 400;
`

export const SmallText = css`
  font-size: 12px;
  font-weight: 400;
`
