import ReactGA from 'react-ga'

import { FILTER_TYPES } from '../constants/enums'

const changeFilters = (val, type, filters) => {
  const values = val.map(v => (v.value ? v.value : v)).join(', ')
  ReactGA.event({
    category: 'Research Center',
    action: 'Filter change',
    label: `filter:${type}, values:${values}`,
  })
  if (type !== 'clear-all') {
    filters[type] = val
  }
  return filters
}

const formatFilter = (value, type) => {
  switch (type) {
    case FILTER_TYPES.MARKET_CAP:
      return value.map(f => f.value.toLowerCase().split(' ')[0]).join(',')

    case FILTER_TYPES.REVENUE:
      return value
        .map(
          f =>
            `${f.value?.lowerBound}${
              f.value?.upperBound ? `-${f.value?.upperBound}` : ''
            }`
        )
        .join(',')

    case FILTER_TYPES.SDG:
      return value.join(',')
    case FILTER_TYPES.G_SCORE:
    case FILTER_TYPES.S_SCORE:
    case FILTER_TYPES.E_SCORE:
    case FILTER_TYPES.TOTAL_SCORE:
      return value.join('_')
    default:
      return value.map(f => f.id).join(',')
  }
}

export const makeFilterQueryObject = filters => {
  filters = Object.keys(filters).reduce((acc, el) => {
    // Remove filters that have none existant parameters
    if (filters[el].length > 0) {
      // market_cap needs to be just the first word
      // transform the array of filter objects into a single string and assign it to the filter e.g
      // INDUSTRY_GROUP:[{value:"Utilities", label:"Utilities"}] -> industry_group: "utilities"
      acc[el.toLowerCase()] = formatFilter(filters[el], el)
    }
    return acc
  }, {})
  return filters
}

export const makeSingleValue = (val, type, filters) => {
  if (filters[type].length > 0 && val.length > 0) {
    val = [val[1]]
  }
  return val
}

export { changeFilters }

export const sortByLabel = arr => {
  return arr.sort(function (a, b) {
    const nameA = a.label.toUpperCase() // ignore upper and lowercase
    const nameB = b.label.toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    // names must be equal
    return 0
  })
}

export const filterArrByLabel = (arr, arrToFilter) => {
  return arr.filter(country => {
    let bool = true
    arrToFilter.forEach(headCountry => {
      if (country.label === headCountry.label) bool = false
    })
    return bool
  })
}
