import React from 'react';
import styled from 'styled-components';

import { CHART_COLOR_GRID_LINE } from '../../styles/colors';

const Line = styled.line`
  stroke: ${CHART_COLOR_GRID_LINE};
`;

const ChartGridLine = props => {
  return (
    <Line {...props} />
  );
}

export default ChartGridLine;