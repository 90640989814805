import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Link from '../../../components/styleguide/Link'
import { getReleaseNotesAndUpdates } from '../../../repositories/release_notes_repository'
import EmptySectionCard from '../../../yb_components/styleguide/cards/EmptySectionCard'
import H2 from '../../../yb_components/styleguide/headings/H2'
import SpinnerContainer from '../../../yb_components/styleguide/spinners/SpinnerContainer'
import ListItem from '../components/ListItem'
import { RELEASE_NOTES_COLLECTION_LINK } from './ReleaseNotesAndUpdates.constants'

export const StyledContainer = styled.div`
  margin-bottom: 30px;
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.375rem;

  a {
    flex: 0 0 auto;
    margin-left: 0.625rem;
  }

  h2 {
    margin-bottom: 0;
  }
`

const ReleaseNotesAndUpdates = () => {
  const [releaseNotesAndUpdates, setReleaseNotesAndUpdates] = useState(null)

  useEffect(() => {
    getReleaseNotesAndUpdates().then(response => {
      setReleaseNotesAndUpdates(response.data)
    })
  }, [])

  const renderCardsList = () => (
    <SpinnerContainer isLoading={releaseNotesAndUpdates == null}>
      {releaseNotesAndUpdates?.length === 0 ? (
        <EmptySectionCard data-cy='empty-card' />
      ) : (
        releaseNotesAndUpdates?.map(item => (
          <ListItem key={item.id} item={item} data-cy='article' />
        ))
      )}
    </SpinnerContainer>
  )

  return (
    <StyledContainer data-cy='release-notes-and-updates'>
      <HeaderContainer data-cy='title'>
        <H2>YvesBlue Updates & Release Notes</H2>
        <Link
          href={RELEASE_NOTES_COLLECTION_LINK}
          target='_blank'
          styles={{ fontWeight: 'normal' }}
        >
          View More »
        </Link>
      </HeaderContainer>
      {renderCardsList()}
    </StyledContainer>
  )
}

export default ReleaseNotesAndUpdates
