import { getAddeparLink, postAddeparCode } from "../../../repositories/addepar_repository"


export const loadAddeparLink = (setLink, setLoading, setError) => {

    setLoading(true)

    getAddeparLink().then(res => {
        setLink(res.data)
        setLoading(false)
      })
      .catch(err => {
        setError(err.response.data)
        setLoading(false)
      })
}

export const validateAddeparRequest = (code, setLoading, setError) => {

    setLoading(true)

    postAddeparCode(code).then(() => {
        setLoading(false)
      })
      .catch(err => {
        setError(err.response.data)
        setLoading(false)
      })
}