import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import styled from 'styled-components'

import FilledButton from '../../../../yb_components/styleguide/buttons/filled_button/FilledButton'
import { titleCase } from '../../../../yb_helpers/strings'
import useCurrentTabRef from '../../../../yb_hooks/target/useCurrentTabRef'
import { loadUtilities } from '../../../research_center/helpers/requests'
import {
  loadPortfolioCorporateEntities,
  loadPortfolioHoldings,
  loadPortfolioIndustryGroups,
} from '../../loaders/portfolio_loaders'
import PortfolioHoldingsCard from '../portfolio_holdings_card/PortfolioHoldingsCard'

const CustomButton = styled(FilledButton)`
  margin-bottom: 10px;
`

const PortfolioHoldings = forwardRef(
  (
    {
      snapshotId,
      portfolioId,
      setError,
      holdingsFilters,
      setHoldingsFilters,
      limit = 10,
    },
    ref
  ) => {
    const [holdings, setHoldings] = useState(null)
    const [searchQuery, setSearchQuery] = useState('')
    const [loading, setLoading] = useState(false)
    const [totalCount, setTotalCount] = useState(0)
    const [filtersOptions, setFiltersOptions] = useState({
      industries: [],
      marketCap: [],
      revenues: [],
      sectors: [],
      companyTypes: [],
      positiveBadges: [],
      negativeBadges: [],
      assetClasses: [],
      trbcIndustryGroup: [],
      trbcBusinessSector: [],
      countries: [],
      sdgs: [],
      fundAssetClasses: [],
      fundSectors: [],
    })
    useCurrentTabRef(ref)

    useEffect(() => {
      loadUtilities({
        setFiltersOptions,
        snapshotId,
        query: [
          'market_caps',
          'industries',
          'sectors',
          'positive_badges',
          'negative_badges',
          'asset_classes',
          'trbc_business_sector',
          'trbc_industry_group',
          'sdgs',
          'country_of_hq',
          'funds_asset_classes',
          'funds_sectors',
          'revenues',
          'scores',
        ],
      })
    }, [])

    const onUpdateHoldings = useCallback((type, query) => {
      const params = {
        setData: setHoldings,
        setTotal: setTotalCount,
        setLoading,
        setError,
        query: { ...query, limit },
      }

      switch (type) {
        case 'entity':
          loadPortfolioCorporateEntities(params)
          break
        case 'sector':
          loadPortfolioIndustryGroups(params)
          break
        default:
          loadPortfolioHoldings(params)
          break
      }
    }, [])

    const onRemoveFilter = key => {
      const newHoldingsFilters = structuredClone(holdingsFilters)
      delete newHoldingsFilters?.[key]
      setHoldingsFilters(newHoldingsFilters)
    }

    return (
      <div>
        {holdingsFilters?.carbon_category ? (
          <CustomButton
            width={'auto'}
            onClick={() => onRemoveFilter('carbon_category')}
          >
            {titleCase(holdingsFilters?.carbon_category)}
            <i className='fas fa-times ml-1'></i>
          </CustomButton>
        ) : null}

        <PortfolioHoldingsCard
          /* Portfolio */
          snapshotId={snapshotId}
          portfolioId={portfolioId}
          /* Holdings */
          holdings={holdings}
          onUpdateHoldings={onUpdateHoldings}
          loading={loading}
          limit={limit}
          totalCount={totalCount}
          /* Search */
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          /* Filters */
          filtersOptions={filtersOptions}
        />
      </div>
    )
  }
)

PortfolioHoldings.displayName = 'PortfolioHoldings'

export default PortfolioHoldings
