import React from 'react'
import styled from 'styled-components'

import withErrorBoundary from '../../hocs/withErrorBoundary'
import {
  ExplainerLink,
  LinkIcon,
} from '../../yb_components/styleguide/links/Link.styles'
import Card from '../styleguide/Card'
import CardTitle from '../styleguide/CardTitle'
import SDGIcon from '../styleguide/SDGIcon'
import Table from '../styleguide/Table'

const StyledCard = styled(Card)`
  .top-holdings-sdg-exposure-body{


    table {

      th{
        > span{
          font-size:15.5px;
        }
      }
    }
`

const StyledExplainerLink = styled(ExplainerLink)`
  float: right;
  margin-top: 5px;
`

const TopHoldingsSDGExposureCard = ({ holdings }) => {
  const table_headers = [
    { label: 'Weight' },
    { label: 'Name' },
    { label: 'Positively Aligned SDGs' },
    { label: 'Negatively Aligned SDGs' },
  ]

  let table_body = []

  if (holdings && holdings.length > 0) {
    holdings.forEach(holding => {
      table_body.push([
        <>{holding.weight}%</>,
        holding.name === null ? holding.description : holding.name,
        holding.sdgs.positive.map(sdg => {
          return <SDGIcon key={`${sdg}-positive-holding`} sdg={sdg} />
        }),
        holding.sdgs.negative.map(sdg => {
          return <SDGIcon key={`${sdg}-negative-holding`} sdg={sdg} />
        }),
      ])
    })
  }

  table_body = table_body && table_body.slice(0, 10)

  return (
    <StyledCard padding={20}>
      <CardTitle title='TOP 10 MODELED HOLDINGS' />
      <div className='float-right'>
        <StyledExplainerLink
          href='https://help.yves.blue/en/articles/5013759-the-yvesblue-public-equity-sdg-model'
          target='_blank'
        >
          <LinkIcon className='far fa-question-circle' />
        </StyledExplainerLink>
        <br />
      </div>
      <div className='top-holdings-sdg-exposure-body'>
        <Table
          showSort={false}
          headers={table_headers}
          body={table_body}
          look={{
            textAlign: 'left',
            thTextAlign: 'center',
            tdPadding: '10px',
            thSecondChildWidth: '35%',
          }}
        />
      </div>
    </StyledCard>
  )
}

export default withErrorBoundary(TopHoldingsSDGExposureCard)
