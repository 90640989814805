import qs from 'qs'

import { generateLastXYearsArray } from './number'

/**
 * Generates an Object from an Array that contains the default Properties name
 *
 * @param {String[]} arr The String Array that contains Properties that we want to generate in the new Object
 * @param {*} defaultPropValue The default value for each Object Property
 *
 * @returns The Object generated with all the Properties mentioned in 'arr'
 *
 * @example
 * generateObjectFromArray(['name', 'email', 'phoneNr'], 0);
 * // Output: { name: 0, email: 0, phobeNr: 0 }
 *
 */
export const generateObjectFromArray = (arr, defaultPropValue = null) => {
  let obj = {}
  for (const propertyName of arr) {
    obj[propertyName] = defaultPropValue
  }
  return obj
}

/**
 * Adds missing years from a Column based Array
 *
 * @param {*[]} data The current data
 * @param {number} x Last x years to be checked and completed
 *
 * @returns The data object with added years properties
 *
 */
export const completeMissingYears = (data, x = 10) => {
  if (!data) return null

  let yearsArray = generateLastXYearsArray(x, -1)
  let aValues = JSON.parse(JSON.stringify(data))

  for (const year of yearsArray) {
    let founded = data.find(elem => elem.year == year)
    if (!founded) aValues.push({ year: parseInt(year) })
  }
  aValues.sort((a, b) => a.year < b.year)

  /* Delete data that is outside this range */
  while (aValues.length > yearsArray.length) {
    aValues.pop()
  }

  return aValues
}

/*
 * Generate an Array of numbers within a given range, with an optional 'step' parameter
 */
export const range = ({ start, end, step = 1 }) =>
  Array.from({ length: (end - start) / step + 1 }, (_, i) => start + i * step)

export const queryStringParse = () =>
  qs.parse(window.location.search.substring(1))

export const generateArrayOfNumbers = total => {
  return new Array(total).fill(0).map((_number, index) => index + 1)
}

export const isArrayEqual = (array1, array2) => {
  if (array1.length === array2.length) {
    return array1.every(element => {
      if (array2.includes(element)) {
        return true
      }

      return false
    })
  }
  return false
}

export const isObjEqual = (objA, objB) => {
  const objAKeys = Object.keys(objA)
  let isEqual = true

  objAKeys.forEach(key => {
    switch (typeof objA[key]) {
      case 'object':
        if (Array.isArray(objA[key])) {
          if (isArrayEqual(objA[key], objB[key]) === false) {
            isEqual = false
          }
        } else {
          if (isObjEqual(objA, objB) === false) {
            isEqual = false
          }
        }
        break
      default:
        if (objA[key] !== objB[key]) {
          isEqual = false
        }
        break
    }
  })

  return isEqual
}
