import React from 'react'
import styled from 'styled-components'

import Link from '../../../../../components/styleguide/Link'
import { BodyText } from '../../../../../pdf/yb_styles/typography'

const StyledName = styled.div`
  ${BodyText}
  font-weight: ${props => props.fontWeight || '700'};
`

const CarbonReportLink = ({ name, href, target, pdf, fontWeight, onClick }) => {
  return pdf ? (
    <StyledName fontWeight={fontWeight}>{name || '—'}</StyledName>
  ) : (
    <Link href={href} target={target} onClick={onClick}>
      {name || '—'}
    </Link>
  )
}

export default CarbonReportLink
