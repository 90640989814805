import React from 'react'

import { esgScoreFullNames } from '../../../../../../../constants'
import { emptyScorePlaceholder } from '../../../constants'
import { StyledMainTable } from '../../comparisons/Comparisons.styles'
import HeaderRow from '../../table_rows/header_row/HeaderRow'
import ScoreTableRow from '../../table_rows/score_table_row/ScoreTableRow'

const YearlyMetricCard = ({
  portfolios,
  metricType,
  openReplaceModal = () => {},
  isPdf = false,
}) => {
  const metrics = portfolios[0]?.esg_metric_scores?.[metricType]

  const retrieveTopRowScore = portfolio => {
    return (
      portfolio?.esg_score?.[`${metricType}_score`] || emptyScorePlaceholder
    )
  }

  const retrieveScore = index => portfolio => {
    let metricScore = parseFloat(
      parseFloat(
        portfolio?.esg_metric_scores?.[metricType]?.[index]?.value
      ).toFixed(2)
    )
    if (isNaN(metricScore)) metricScore = emptyScorePlaceholder

    return metricScore
  }

  return (
    <StyledMainTable look={{ sideCellsPadding: '0' }} isPdf={isPdf}>
      <HeaderRow
        portfolios={portfolios}
        openReplaceModal={openReplaceModal}
        isPdf={isPdf}
      />
      <tbody>
        <ScoreTableRow
          scoreName={`${esgScoreFullNames[metricType]} Score`}
          retrieveScore={retrieveTopRowScore}
          portfolios={portfolios}
        />
        {metrics?.map((metric, index) => (
          <ScoreTableRow
            key={`table-row-metric-score-${index}`}
            scoreName={metric.name}
            retrieveScore={retrieveScore(index)}
            portfolios={portfolios}
          />
        ))}
      </tbody>
    </StyledMainTable>
  )
}

export default YearlyMetricCard
