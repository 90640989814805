import React, { useEffect, useState } from 'react'
import AsyncCreatable from 'react-select-3/async-creatable'

import { selectStyles } from '../../../../components/styleguide/YBSelect'
import { getInstrumentQuery } from '../../../../repositories/instruments_repository'

const InstrumentSelect = ({
  defaultValue,
  onChange,
  onCreateInstrument,
  className,
}) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const loadOptions = (inputValue, callback) => {
    getInstrumentQuery(encodeURIComponent(inputValue)).then(response => {
      callback(response.data)
    })
  }

  const handleChange = newInstrument => {
    setValue(newInstrument)
    return onChange(newInstrument.value)
  }

  const handleCreateOption = newInstrumentName => {
    onCreateInstrument(newInstrumentName)
  }

  return (
    <AsyncCreatable
      styles={selectStyles({ height: '50px' })}
      className={className || ''}
      value={value}
      defaultOptions={[value]}
      loadOptions={loadOptions}
      onChange={handleChange}
      onCreateOption={handleCreateOption}
    />
  )
}

export default InstrumentSelect
