import axios from 'axios'

// gets all sasb materiality info for entity
// `/entities/{{entity_id}}/sasb`
export const getSasbReportByEntityId = entityId => {
  return axios.get(`/app/api/entities/${entityId}/sasb`).then(res => res.data)
}

export const updateEnableMetricStatus = async (metricId, initialValue) => {
  let process
  switch (initialValue) {
    case true:
      process = 'disable'
      break
    case false:
      process = 'enable'
      break
    default:
      process = 'enable'
  }
  try {
    const res = await axios.put(`/app/api/sasb/metrics/${metricId}/${process}`)
    return res.data
  } catch {
    throw new Error('Update metric enabled status failure')
  }
}

export const getMetricById = metricId => {
  return axios.get(`/app/api/sasb/metrics/${metricId}`).then(res => res.data)
}

export const updateSasbMetric = (metricId, metricData) => {
  return axios
    .post(`/app/api/sasb/metrics/${metricId}/status_updates`, metricData)
    .then(res => res.data)
}

export const getSasbMetricNotes = (metricId, pageNum, limit) => {
  return axios
    .get(
      `/app/api/sasb/metrics/${metricId}/notes?page=${pageNum}&limit=${limit}`
    )
    .then(res => res.data)
}
