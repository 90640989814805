import _ from 'lodash'
import ReactGA from 'react-ga'

import { FilterTypes } from './PortfolioHoldingsCard.constants'

export const triggerFilterGaEvent = filters => {
  const fFilters = []
  _.each(FilterTypes, type => {
    const value = filters[type]
    if ((value && value?.length) || (value && Object.keys(value)?.length)) {
      let fValue
      if (Array.isArray(value)) {
        fValue = value?.map(v => (v?.value ? v?.value : v)).join(', ')
      } else if (typeof value === 'object') {
        fValue = Object.keys(value)
      } else {
        fValue = value
      }
      fFilters.push({ type, value: fValue })
    }
  })

  if (filters?.query) fFilters?.push({ type: 'query', value: filters?.query })

  fFilters?.forEach(elem => {
    ReactGA.event({
      category: 'Portfolio - Holdings Table',
      action: 'Filter change',
      label: `filter:${elem?.type}; values:${elem?.value}`,
    })
  })
}

export const countFilters = (filters = {}) => {
  let total = 0
  _.each(filters, value => {
    if (Array.isArray(value)) {
      total += value?.length
    } else if (value && value !== 'all') {
      total += 1
    }
  })
  return total
}

export const findFirstFilter = (filters = {}) => {
  if (!filters) return false

  return Object.values(filters).find(value => {
    if (!value) return false
    else if (Array.isArray(value) && value?.length) return true
    else if (typeof value === 'string' && value !== 'all') return true
    else if (Object.keys(value).length > 0 && value !== 'all') return true
    return false
  })
}

export const getCsvEndpoint = groupBy => {
  switch (groupBy) {
    case 'sector':
      return 'industry_group_holdings'

    case 'entity':
      return 'corporate_entity_holdings'

    default:
      return 'holdings'
  }
}

export const formatFilters = newFilters => {
  const formattedFilters = {
    [FilterTypes.ASSET_TYPE]: [],
  }

  /* Checkbox Filters */
  const asset_type = newFilters?.[FilterTypes.ASSET_TYPE]
  if (asset_type)
    Object.keys(asset_type)?.forEach(key => {
      if (asset_type?.[key]) formattedFilters[FilterTypes.ASSET_TYPE].push(key)
    })

  /* Dropdown Filters */
  const DROPDOWN_FILTERS = [
    FilterTypes.REVENUE,
    FilterTypes.INDUSTRY_GROUP,
    FilterTypes.BUSINESS_SECTOR,
    FilterTypes.COUNTRY_OF_HQ,
    FilterTypes.POSITIVE_BADGE,
    FilterTypes.NEGATIVE_BADGE,
    FilterTypes.E_SCORE,
    FilterTypes.S_SCORE,
    FilterTypes.G_SCORE,
    FilterTypes.TOTAL_SCORE,
    FilterTypes.MARKET_CAP,
  ]
  DROPDOWN_FILTERS?.forEach(key => {
    if (!formattedFilters[key]) formattedFilters[key] = []
    formattedFilters[key] = newFilters?.[key]?.map(elem => elem?.value)
  })

  /* Single Value Filters */
  return {
    ...formattedFilters,
    [FilterTypes.COVERAGE]: newFilters?.[FilterTypes.COVERAGE],
    [FilterTypes.POSITION_TYPE]: newFilters?.[FilterTypes.POSITION_TYPE],
  }
}
