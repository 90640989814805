import React, { useEffect, useState } from 'react'

import ErrorPage from '../../components/styleguide/ErrorPage'
import { mapHedgeFundHoldingToState } from '../../mappers/funds_mapper'
import { getHedgeFundHolding } from '../../repositories/funds_repository'
import Instrument from '../instrument/Instrument'

const loadHolding = ({
  fundId,
  holdingId,
  snapshotId,
  setHolding,
  setError,
}) => {
  getHedgeFundHolding(fundId, snapshotId, holdingId)
    .then(response => {
      setHolding(mapHedgeFundHoldingToState(response))
    })
    .catch(err => {
      console.error(err)
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

const Holding = ({ fundId, holdingId, snapshotId }) => {
  const [holding, setHolding] = useState(null)
  const [error, setError] = useState(null)

  useEffect(
    loadHolding.bind(null, {
      fundId,
      holdingId,
      snapshotId,
      setHolding,
      setError,
    }),
    []
  )

  if (error)
    return <ErrorPage code={error} message={error == 404 ? 'NOT FOUND' : ''} />

  if (!holding) return null

  let riskAssessmentLink =
    holding.entity_id != null &&
    holding.portfolio_name != null &&
    fundId != null
      ? `/app/entities/${holding.entity_id}/esg?portfolioId=${fundId}&portfolioName=${holding.portfolio_name}`
      : null

  let breadcrumbs = [
    { link: '/app', name: 'Home', active: false },
    {
      link: `/app/portfolios/${fundId}/snapshots/${snapshotId}`,
      name: holding.portfolio_name,
      active: false,
    },
    { link: null, name: holding.name, active: true },
  ]

  return (
    <Instrument
      instrumentId={holding.instrument_id}
      breadcrumbs={breadcrumbs}
      riskAssessmentLink={riskAssessmentLink}
    />
  )
}

export default Holding
