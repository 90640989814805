import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CustomMetricDialog } from './styles';
import WrapperColumn from '../../../../../components/styleguide/WrapperColumn';
import TextArea from '../../../../../components/styleguide/TextArea';
import Button from '../../../../../components/styleguide/Button';
import SectionWrapper from '../../../../../components/styleguide/SectionWrapper';


const UpdateTargetModal = ({ show, setShowAddNoteModal, addNewNote, dataLabel }) => {
    
    const [noteBody, setNoteBody] = useState({});

    return (
        show && <CustomMetricDialog show={show} onHide={() => setShowUpdateTargetModal(false)} data-cy={`${dataLabel}-update-target-modal`}>
            <Modal.Header closeButton data-cy={`${dataLabel}-update-target-modal-header`}>
                <Modal.Title data-cy={`${dataLabel}-update-target-modal-title`}>Add Note</Modal.Title>
            </Modal.Header>
            <WrapperColumn data-cy={`${dataLabel}-update-target-modal-input-wrapper`}>
                <SectionWrapper margin="-12px 10px 10px 10px" flex="1" data-cy={`${dataLabel}-update-target-modal-input-wrapper`}>
                    <TextArea
                        label="Note"
                        width="98%"
                        id="target_note"
                        data-cy={`${dataLabel}-update-target-modal-text-input`}
                        rows={5}
                        onChange={(id, value) => { setNoteBody(value) }}
                    />
                </SectionWrapper>
                <SectionWrapper width="98%" justify="flex-end" margin="0px 0px 12px 0px" data-cy={`${dataLabel}-update-target-modal-button-wrapper`}>
                    <Button data-cy={`${dataLabel}-update-target-modal-cancel-button`} margin="0px 15px 0px 0px" width="30%" label="Cancel" onClick={() => setShowAddNoteModal(false)}/>
                    <Button data-cy={`${dataLabel}-update-target-modal-submit-button`} width="30%" label="Save Note" onClick={() => addNewNote(noteBody)}/>
                </SectionWrapper>
            </WrapperColumn>
        </CustomMetricDialog>
    )
}

export default UpdateTargetModal;
