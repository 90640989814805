import { TabNav } from '@exponentialtech/yb-component-library'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import Container from '../../components/styleguide/Container'
import ErrorPage from '../../components/styleguide/ErrorPage'
import Loading from '../../components/styleguide/Loading'
import { AppActions } from '../../yb_stores/app_store/actions/AppActions'
import { AppContext } from '../../yb_stores/app_store/AppStore'
import PortfolioHeader from './components/PortfolioHeader'
import { loadSnapshot } from './loaders/portfolio_loaders'
import { portfolioTabNavProps } from './portfolio_tabs/constants'

const StyledContainer = styled(Container)`
  position: 'relative';
  padding: 20px;
  margin: 48px auto;
  width: 100%;
  max-width: 1440px;
  padding: 20px;
`
/* global react_app_organization */

const Portfolio = ({
  navOptions,
  activeValue = 'summary',
  portfolioId,
  snapshotId,
  holdingId,
  organization,
}) => {
  const [snapshot, setSnapshot] = useState(null)
  const [impactBenchmark, setImpactBenchmark] = useState(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState({ show: false })
  const [holdingsFilters, setHoldingsFilters] = useState({})
  const [summaryHoldingsFilter, setSummaryHoldingsFilter] = useState({})
  const [tcfdHoldingId, setTcfdHoldingId] = useState(holdingId)
  const [activeTab, setActiveTab] = useState(activeValue)
  const [displayIcons, setDisplayIcons] = useState({
    options:
      activeTab === 'summary' || activeTab === 'carbon_report_metrics'
        ? true
        : false,
    sdgs: activeTab === 'summary' ? true : false,
  })

  /* Tabs Refs */
  const summaryRef = useRef()
  const holdingsRef = useRef()
  const carbonReportMetricsRef = useRef()
  const tcfdHoldingDetailRef = useRef()
  const tcfdHoldingsRef = useRef()

  let history = useHistory()
  const [, appDispatch] = useContext(AppContext)

  useEffect(
    loadSnapshot.bind(
      null,
      setSnapshot,
      setImpactBenchmark,
      setError,
      snapshotId,
      portfolioId
    ),
    []
  )

  useEffect(() => {
    appDispatch({ type: AppActions.SET_TARGET, payload: snapshot })
  }, [snapshot])

  useEffect(() => {
    appDispatch({
      type: AppActions.SET_BENCHMARK,
      payload: impactBenchmark,
    })
  }, [impactBenchmark])

  useEffect(() => {
    document.title = document.title.replace(/(&amp;)/g, '&')
  }, [])

  if (!snapshot) {
    if (!error) return null
    return <ErrorPage code={error} message={error == 404 ? 'NOT FOUND' : ''} />
  }

  const onCarbonFilterChange = key => {
    setSummaryHoldingsFilter(prevFilters => Object.assign({}, prevFilters, key))
    setHoldingsFilters(prevFilters => Object.assign({}, prevFilters, key))
    setActiveTab('holdings')
    setDisplayIcons(prev => ({ ...prev, options: false }))
    history.push(
      `/app/portfolios/${portfolioId}/snapshots/${snapshotId}/holdings`
    )
  }

  const handleUrlOnTabChange = e => {
    // Setting the activeTab to null allows it to trigger a re-render in the TabNav component when its set again as a string.

    if (activeTab) {
      setActiveTab(null)
    }

    switch (e.key) {
      case 'summary':
        setDisplayIcons({ options: true, sdgs: true })
        history.push(`/app/portfolios/${portfolioId}/snapshots/${snapshotId}`)
        break
      case 'holdings':
        setDisplayIcons({ options: false, sdgs: false })
        history.push(
          `/app/portfolios/${portfolioId}/snapshots/${snapshotId}/holdings`
        )
        break
      case 'carbon_report_metrics':
        setDisplayIcons({ options: true, sdgs: false })
        history.push(
          `/app/portfolios/${portfolioId}/snapshots/${snapshotId}/carbon`
        )
        break
      case 'carbon_report_tcfd':
        setDisplayIcons({ options: false, sdgs: false })
        setTcfdHoldingId(null)
        history.push(
          `/app/portfolios/${portfolioId}/snapshots/${snapshotId}/carbon/tcfd`
        )
        break
    }
  }

  return (
    <StyledContainer>
      <PortfolioHeader
        snapshot={snapshot}
        portfolioId={portfolioId}
        impactBenchmark={impactBenchmark}
        sdgs={snapshot.sdgs}
        displayIcons={displayIcons}
      />
      <TabNav
        {...portfolioTabNavProps(
          snapshotId,
          portfolioId,
          snapshot,
          impactBenchmark,
          setLoading,
          setError,
          summaryHoldingsFilter,
          setSummaryHoldingsFilter,
          holdingsFilters,
          setHoldingsFilters,
          onCarbonFilterChange,
          activeTab,
          tcfdHoldingId,
          react_app_organization?.organization?.can_access_carbon_report,
          react_app_organization &&
            react_app_organization.organization &&
            react_app_organization.organization.can_access_active_mgmt,
          handleUrlOnTabChange,
          navOptions,
          {
            summaryRef,
            holdingsRef,
            carbonReportMetricsRef,
            tcfdHoldingDetailRef,
            tcfdHoldingsRef,
          },
          organization
        )}
      />

      <Loading show={loading.show} message={loading.message} full={true} />
    </StyledContainer>
  )
}

export default Portfolio
