import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  padding: 10px;
  border-radius: 10px;
  background-color: #fafafa;
  overflow: hidden;

  .well-title {
    margin: 0 0 10px;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    font-weight: 700;
  }
`

export const Well = ({ children, style, title }) => {
  return (
    <StyledDiv style={style}>
      {title && <h4 className='well-title'>{title}</h4>}
      {children}
    </StyledDiv>
  )
}

Well.propTypes = {
  children: PropTypes.node,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.string,
}
