import { APP_COLOR_PRIMARY, BG_COLOR_LIGHT_BLUE } from '../styles/colors'
import { ColoringMethod } from '../styles/enums'
import { hexToRGBA, RGB_Linear_Blend } from '../yb_helpers/colors_util'

const useChartColoringMethods = (
  colorScheme,
  coloringMethod,
  elemCount = null,
  lightenScale = 5
) => {
  const getColor = (datum, total = 100) => {
    const getFirstAndSecondColors = () => {
      const color1 = colorScheme?.color1
        ? hexToRGBA(colorScheme.color1)
        : hexToRGBA(BG_COLOR_LIGHT_BLUE)
      const color2 = colorScheme?.color2
        ? hexToRGBA(colorScheme.color2)
        : hexToRGBA(APP_COLOR_PRIMARY)

      return { color1, color2 }
    }

    switch (coloringMethod) {
      case ColoringMethod.FROM_DATA: {
        if (datum?.color) return datum.color
        return APP_COLOR_PRIMARY
      }

      case ColoringMethod.BLEND_EACH_DIFFERENT: {
        const totalValue = datum?.data?.total ? datum.data.total : total
        const stepSize = totalValue / elemCount

        const { color1, color2 } = getFirstAndSecondColors()
        return RGB_Linear_Blend(
          (stepSize * datum.data.index + datum.value / lightenScale) /
            totalValue,
          color1,
          color2
        )
      }

      default:
      case ColoringMethod.BLEND: {
        const { color1, color2 } = getFirstAndSecondColors()
        const totalValue = datum?.data?.total ? datum.data.total : total
        return RGB_Linear_Blend(datum.value / totalValue, color1, color2)
      }
    }
  }

  const useColorScheme =
    coloringMethod === ColoringMethod.FROM_SCHEME && Array.isArray(colorScheme)
  const colors = useColorScheme ? colorScheme : getColor

  return { colors }
}

export default useChartColoringMethods
