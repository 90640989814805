import axios from 'axios'
import React, { useState } from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'

import BaseCheckbox from '../../../../yb_components/styleguide/form/base/checkbox/BaseCheckbox'
import Modal from '../../../../yb_components/styleguide/modal/Modal'

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_KEY)

const CheckboxContainer = styled.div`
  margin-top: 35px;
`
const handleDestroy = ({
  portfolioId,
  snapshotId,
  handleClose,
  onDelete,
  redirectTo,
}) => {
  ReactGA.event({
    category: 'Portfolio::Snapshot',
    action: 'delete',
    label: `${snapshotId}`,
  })

  axios
    .delete(`/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}`)
    .then(response => {
      if (response.status == 200) {
        onDelete()
        handleClose()
        redirectTo && window.location.assign(redirectTo)
      }
    })
}

const DeleteSnapshotDialog = ({
  show,
  setShow,
  snapshotId,
  portfolioId,
  asOf,
  onDelete,
  redirectTo,
}) => {
  const [submitDisabled, setSubmitDisabled] = useState(true)

  const handleClose = () => {
    setShow(false)
    setSubmitDisabled(true)
  }

  return (
    <Modal
      show={show}
      setShow={setShow}
      onHide={handleClose}
      title={`Delete Snapshot as of ${asOf}`}
      submitText='delete'
      submitDisabled={submitDisabled}
      onSubmit={() =>
        handleDestroy({
          snapshotId,
          portfolioId,
          handleClose,
          onDelete,
          redirectTo,
        })
      }
    >
      Are you sure you want to delete this portfolio snapshot? Once deleted, you
      will no longer have access to it and it will not be able to be recovered.
      <CheckboxContainer>
        <BaseCheckbox
          label={'Yes, permanently delete the portfolio snapshot.'}
          onChange={e => setSubmitDisabled(!e.target.checked)}
        />
      </CheckboxContainer>
    </Modal>
  )
}

export default DeleteSnapshotDialog
