import React from 'react';
import LegendItem from '../../../../../../yb_components/styleguide/legend_items/LegendItem';
import { FONT_COLOR_SECONDARY } from '../../../../../../styles/colors';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import { GRAPH_COLORS } from '../../constants';

const LegendList = styled.ul`
  display: inline-block;
  list-style: none;
  padding-left: 5px;
  margin-top: 40px;

  li {
    display: inline-block;
  }

  li:not(:first-child) {
    margin-left: 42.5px;
  }
`
const Legend = () => {
  return (
    <LegendList>
      <li>
        <LegendItem
          label={"Environment"}
          labelFontSize={'14px'}
          labelFontWeight={'400'}
          labelColor={FONT_COLOR_SECONDARY}
          dotSize={'0.50rem'}
          dotColor={GRAPH_COLORS.e}
          dotMargin={'0.6rem'}
          displayDot={true}
        />
      </li>
      <li>
        <LegendItem
          label={"Social"}
          labelFontSize={'14px'}
          labelFontWeight={'400'}
          labelColor={FONT_COLOR_SECONDARY}
          
          dotMargin={'0.6rem'}
          dotSize={'0.50rem'}
          dotColor={GRAPH_COLORS.s}
          displayDot={true}
        />
      </li>
      <li>
        <LegendItem
          label={"Governance"}
          labelFontSize={'14px'}
          labelFontWeight={'400'}
          labelColor={FONT_COLOR_SECONDARY}
          
          dotMargin={'0.6rem'}
          dotSize={'0.50rem'}
          dotColor={GRAPH_COLORS.g}
          displayDot={true}
        />
      </li>
    </LegendList>
  )
}

export default Legend
