export const countryCodes = [
    { value: "us", label: "US" },
    { value: "uk", label: "UK" },
    { value: "ca", label: "CA" }
];
export const defaultCountryCode = countryCodes[0];

export const verticals = [
    { value: "3D printing", label: "3D printing" },
    { value: "Adtech", label: "Adtech" },
    { value: "Advanced manufacturing", label: "Advanced manufacturing" },
    { value: "Agtech", label: "Agtech" },
    { value: "Artificial intelligence and machine learning", label: "Artificial intelligence and machine learning" },
    { value: "Audiotech", label: "Audiotech" },
    { value: "Augmented reality", label: "Augmented reality" },
    { value: "Autonomous cars", label: "Autonomous cars" },
    { value: "B2B payments", label: "B2B payments" },
    { value: "Beauty", label: "Beauty" },
    { value: "Big data", label: "Big data" },
    { value: "Cannabis", label: "Cannabis" },
    { value: "Circular Carbon", label: "Circular Carbon" },
    { value: "Carsharing", label: "Carsharing" },
    { value: "Cleantech", label: "Cleantech" },
    { value: "Construction technology", label: "Construction technology" },
    { value: "Cryptocurrency/Blockchain", label: "Cryptocurrency/Blockchain" },
    { value: "Cybersecurity", label: "Cybersecurity" },
    { value: "Digital health", label: "Digital health" },
    { value: "Ecommerce", label: "Ecommerce" },
    { value: "Edtech", label: "Edtech" },
    { value: "Ephemeral content", label: "Ephemeral content" },
    { value: "eSports", label: "eSports" },
    { value: "Fintech", label: "Fintech" },
    { value: "Femtech", label: "Femtech" },
    { value: "Foodtech", label: "Foodtech" },
    { value: "Gaming", label: "Gaming" },
    { value: "Healthtech", label: "Healthtech" },
    { value: "HRtech", label: "HRtech" },
    { value: "Impact investing", label: "Impact investing" },
    { value: "Industrials", label: "Industrials" },
    { value: "Infrastructure", label: "Infrastructure" },
    { value: "Insurtech", label: "Insurtech" },
    { value: "Internet of Things (IoT)", label: "Internet of Things (IoT)" },
    { value: "Life sciences", label: "Life sciences" },
    { value: "LOHAS and wellness", label: "LOHAS and wellness" },
    { value: "Manufacturing", label: "Manufacturing" },
    { value: "Marketing tech", label: "Marketing tech" },
    { value: "Micro-mobility", label: "Micro-mobility" },
    { value: "Mobile", label: "Mobile" },
    { value: "Mobile commerce", label: "Mobile commerce" },
    { value: "Mortgage tech", label: "Mortgage tech" },
    { value: "Nanotechnology", label: "Nanotechnology" },
    { value: "Oil and gas", label: "Oil and gas" },
    { value: "Oncology", label: "Oncology" },
    { value: "Pet technology", label: "Pet technology" },
    { value: "Real estate technology", label: "Real estate technology" },
    { value: "Restaurant technology", label: "Restaurant technology" },
    { value: "Ridesharing", label: "Ridesharing" },
    { value: "Robotics and drones", label: "Robotics and drones" },
    { value: "SaaS (software as a service)", label: "SaaS (software as a service)" },
    { value: "Space technology", label: "Space technology" },
    { value: "TMT (technology, media and telecommunications)", label: "TMT (technology, media and telecommunications)" },
    { value: "Virtual reality", label: "Virtual reality" },
    { value: "Wearables and quantified self", label: "Wearables and quantified self" },
    { value: "Other", label: "Other" }
];
export const defaultVertical = verticals[0];

export const industries = [
    { value: "Telecommunications Services", label: "Telecommunications Services" },
    { value: "Specialty Retailers", label: "Specialty Retailers" },
    { value: "Software & IT Services", label: "Software & IT Services" },
    { value: "Banking Services", label: "Banking Services" },
    { value: "Computers, Phones & Household Electronics", label: "Computers, Phones & Household Electronics" },
    { value: "Investment Banking & Investment Services", label: "Investment Banking & Investment Services" },
    { value: "Collective Investments", label: "Collective Investments" },
    { value: "Industrial Conglomerates", label: "Industrial Conglomerates" },
    { value: "Personal & Household Products & Services", label: "Personal & Household Products & Services" },
    { value: "Pharmaceuticals", label: "Pharmaceuticals" },
    { value: "Professional & Commercial Services", label: "Professional & Commercial Services" },
    { value: "Hotels & Entertainment Services", label: "Hotels & Entertainment Services" },
    { value: "Communications & Networking", label: "Communications & Networking" },
    { value: "Freight & Logistics Services", label: "Freight & Logistics Services" },
    { value: "Real Estate Operations", label: "Real Estate Operations" },
    { value: "Machinery, Tools, Heavy Vehicles, Trains & Ships", label: "Machinery, Tools, Heavy Vehicles, Trains & Ships" },
    { value: "Electric Utilities & IPPs", label: "Electric Utilities & IPPs" },
    { value: "Passenger Transportation Services", label: "Passenger Transportation Services" },
    { value: "Electronic Equipment & Parts", label: "Electronic Equipment & Parts" },
    { value: "Aerospace & Defense", label: "Aerospace & Defense" },
    { value: "Residential & Commercial REITs", label: "Residential & Commercial REITs" },
    { value: "Healthcare Equipment & Supplies", label: "Healthcare Equipment & Supplies" },
    { value: "Oil & Gas Related Equipment and Services", label: "Oil & Gas Related Equipment and Services" },
    { value: "Construction & Engineering", label: "Construction & Engineering" },
    { value: "Biotechnology & Medical Research", label: "Biotechnology & Medical Research" },
    { value: "Transport Infrastructure", label: "Transport Infrastructure" },
    { value: "Oil & Gas", label: "Oil & Gas" },
    { value: "Metals & Mining", label: "Metals & Mining" },
    { value: "Healthcare Providers & Services", label: "Healthcare Providers & Services" },
    { value: "Construction Materials", label: "Construction Materials" },
    { value: "Multiline Utilities", label: "Multiline Utilities" },
    { value: "Homebuilding & Construction Supplies", label: "Homebuilding & Construction Supplies" },
    { value: "Leisure Products", label: "Leisure Products" },
    { value: "Diversified Industrial Goods Wholesalers", label: "Diversified Industrial Goods Wholesalers" },
    { value: "Coal", label: "Coal" },
    { value: "Textiles & Apparel", label: "Textiles & Apparel" },
    { value: "Automobiles & Auto Parts", label: "Automobiles & Auto Parts" },
    { value: "Insurance", label: "Insurance" },
    { value: "Semiconductors & Semiconductor Equipment", label: "Semiconductors & Semiconductor Equipment" },
    { value: "Food & Tobacco", label: "Food & Tobacco" },
    { value: "Chemicals", label: "Chemicals" },
    { value: "Media & Publishing", label: "Media & Publishing" },
    { value: "Food & Drug Retailing", label: "Food & Drug Retailing" },
    { value: "Household Goods", label: "Household Goods" },
    { value: "Natural Gas Utilities", label: "Natural Gas Utilities" },
    { value: "Water & Related Utilities", label: "Water & Related Utilities" },
    { value: "Diversified Retail", label: "Diversified Retail" },
    { value: "Beverages", label: "Beverages" },
    { value: "Containers & Packaging", label: "Containers & Packaging" },
    { value: "Renewable Energy", label: "Renewable Energy" },
    { value: "Investment Holding Companies", label: "Investment Holding Companies" },
    { value: "Uranium", label: "Uranium" },
    { value: "Paper & Forest Products", label: "Paper & Forest Products" },
    { value: "Office Equipment", label: "Office Equipment" }];
export const defaultIndustry = industries[0];


export const currencies = [
    { value: "usd", label: "usd" },
];

export const defaultCurrency = currencies[0];

export const investmentTypes = [
    { value: "venture_capital", label: "Venture Capital" },
    { value: "growth_capital", label: "Growth Capital" },
    { value: "buyout_capital", label: "Buyout Capital" },
    { value: "mezzanine_capital", label: "Mezzanine Capital" },
    { value: "real_estate", label: "Real Estate" },
    { value: "infrastructure", label: "Infrastructure" },
    { value: "distressed", label: "Distressed" }
];
export const defaultInvestmentType = investmentTypes[0];

export const impactThemes = [
    { value: "smart_cities", label: "Smart Cities" },
    { value: "renewable_energy", label: "Renewable Energy" },
    { value: "health_and_wellness", label: "Health & Wellness" },
    { value: "cleantech", label: "Cleantech" },
    { value: "infrastructure", label: "Infrastructure" },
    { value: "mobility", label: "Mobility" },
    { value: "affordable_housing", label: "Affordable Housing" },
    { value: "water", label: "Water" },
    { value: "income_inequality", label: "Income Inequality" },
    { value: "economic_development", label: "Economic Development" },
    { value: "gender_lens", label: "Gender Lens" },
    { value: "arts_and_culture", label: "Arts & Culture" },
    { value: "human_rights", label: "Human Rights" },
    { value: "social_justice", label: "Social Justice" },
    { value: "biodiversity_and_conservation", label: "Biodiversity & Conservation" },
    { value: "food_and_agriculture", label: "Food & Agriculture" },
];
export const defaultImpactTheme = impactThemes[0];

export const developmentFocuses = [
    { value: "developed", label: "Developed" },
    { value: "developing", label: "Developing" },
    { value: "frontier", label: "Frontier" },
    { value: "all", label: "All" }
];
export const defaultDevelopmentFocus = developmentFocuses[0];

export const investmentStatuses = [
    {value: "fundraising", label: "Fundraising"},
    {value: "open", label: "Open"},
    {value: "closed", label: "Closed"},
];
export const defaultInvestmentStatus = investmentStatuses[0];

export const investmentStages = [
    { value: "seed", label: "Seed" },
    { value: "early", label: "Early Stage" },
    { value: "late", label: "Late Stage" },
    { value: "pre_ipo", label: "Pre-IPO" },
    { value: "post_ipo", label: "Public" },
];
export const defaultInvestmentStage = investmentStages[0];


export const titles = [
    {value:"Institutional Fixed Income Sales Trader", label: "Institutional Fixed Income Sales Trader"},
    {value:"Equity Derivatives Market Specialist", label: "Equity Derivatives Market Specialist"},
    {value:"Fixed Income Sales", label: "Fixed Income Sales"},
    {value:"Senior Vice President", label: "Senior Vice President"},
    {value:"Equity Sales Trader", label: "Equity Sales Trader"},
    {value:"Junior Equity Sales Trader", label: "Junior Equity Sales Trader"},
    {value:"Sales Assistant (Institutional Sales and Trading)", label: "Sales Assistant (Institutional Sales and Trading)"},
    {value:"Production Equity Sales Trader", label: "Production Equity Sales Trader"},
    {value:"Equity Sales and Trading Compliance Officer", label: "Equity Sales and Trading Compliance Officer"},
    {value:"Equity Sales Trader", label: "Equity Sales Trader"},
    {value:"Investment Specialist", label: "Investment Specialist"},
    {value:"Institutional Global Equities Sales Trader", label: "Institutional Global Equities Sales Trader"},
    {value:"Trader", label: "Trader"},
    {value:"Equity Trader", label: "Equity Trader"},
    {value:"Fixed Income Trader", label: "Fixed Income Trader"},
    {value:"Head of Trading", label: "Head of Trading"},
    {value:"Equity Analyst", label: "Equity Analyst"},
    {value:"Research Associate", label: "Research Associate"},
    {value:"Portfolio Manager", label: "Portfolio Manager"},
    {value:"Private Equity Associate (Internship)", label: "Private Equity Associate (Internship)"},
    {value:"Acquisitions Associate", label: "Acquisitions Associate"},
    {value:"Analyst, Global Equity Products", label: "Analyst, Global Equity Products"},
    {value:"Senior Analyst – Investment Analytics", label: "Senior Analyst – Investment Analytics"},
    {value:"Private Equity Analyst – Paid Internship", label: "Private Equity Analyst – Paid Internship"},
    {value:"Research Associate", label: "Research Associate"},
    {value:"Research Associate Gold & Precious Minerals", label: "Research Associate Gold & Precious Minerals"},
    {value:"Research Analyst", label: "Research Analyst"},
    {value:"Research Coordinator", label: "Research Coordinator"},
    {value:"Analyst", label: "Analyst"},
    {value:"Director", label: "Director"},
    {value:"Managing Director", label: "Managing Director"}
];
export const defaultTitle = titles[0];

export const departments = [
    {value:"Investment Banking", label: "Investment Banking"},
    {value:"Financial Advisory", label: "Financial Advisory"},
    {value:"Underwriting", label: "Underwriting"},
    {value:"Equity Underwriting", label: "Equity Underwriting"},
    {value:"Debt Underwriting", label: "Debt Underwriting"},
    {value:"Institutional Client Services", label: "Institutional Client Services"},
    {value:"Fixed Income Client Execution", label: "Fixed Income Client Execution"},
    {value:"Currency Client Execution", label: "Currency Client Execution"},
    {value:"Commodoities Client Execution", label: "Commodoities Client Execution"},
    {value:"Equities Client Execution", label: "Equities Client Execution"},
    {value:"Investing & Lending", label: "Investing & Lending"},
    {value:"Equity Securities", label: "Equity Securities"},
    {value:"Debt Securities & Loans", label: "Debt Securities & Loans"},
    {value:"Investment Management", label: "Investment Management"},
    {value:"Business Banking", label: "Business Banking"},
    {value:"Debt Securities & Loans", label: "Debt Securities & Loans"},
    {value:"Commercial Lending", label: "Commercial Lending"},
    {value:"Mortgage Lending", label: "Mortgage Lending"},
    {value:"Consumer Lending", label: "Consumer Lending"},
];
export const defaultDepartment = departments[0];


export const steps = [
    "Fund Information",
    "Reporting Frameworks",
    "Holdings",
    "Access & Sharing"
];

export const initialFundState = {
    id: null,
    name: null,
    manager: null,
    address:null,
    holdings: []
};

export const defaultConfiguration = {
    firm: "",
    inception_date: "",
    currency: defaultCurrency.value,
    administrator: "",
    address: ""
};

export const defaultInvestmentStrategy = {
    investment_benchmark: "",
    impact_benchmark: "",
    investment_type: defaultInvestmentType.value,
    impact_theme: "",
    development_focus: "",
    target_return: "",
    investment_years: "",
    aum: "",
    target_aum: ""
};

export const defaultGeography = {
    domicile: defaultCountryCode.value,
    domicile_type: "Country",
    focus: defaultCountryCode.value,
    focus_type: "Country"
};

export const defaultTermsOfAgreement = {
    minimum_investment: "",
    performance_fee: "",
    management_fee: ""
};

export const fundShowTabNames = [
//   "Reporting",
  "Current Investments",
  "Pipeline",
  "Team Activity",
  "Manage"
];

export const currentInvestmentsToggles = [
    "Basic",
    "Impact",
    "Financial"
];

export const reportingFrameworkFundShowHeaders = {
   "Impact" : "Impact Report",
   "IFC Principles" : "IFC Principles",
   "SASB" : "SASB"
};

export const fundPipelineButtons = [
    "Opportunity",
    "In Contact",
    "Research",
    "Diligence",
    "Approved",
    "Funded",
    "Exited",
    "Rejected",
];

export const defaultStartFundShow = {
    currTab : "Pipeline",
    activeToggle : "Basic",
    activeReportingToggle : "Impact",
    pipelinebutton : "Opportunity"
};


export const fundManageButtons = [
    "Fund Information",
    "Sharing & Securty",
    // "Fund Status Bar",
    // "Default Metrics",
    // "Reporting Frameworks",
    // "Pipeline"
];

export const reportingSDGDefaults = {
    sdg_1: false,
    sdg_2: false,
};

export const ifcPrinciplesDefaults = {
    principle_1: false,
    principle_2: false
};

export const holdingShowTabs =  [
    // "Reporting",
    "Monitoring & Engagement",
    "Reports",
    "Activity",
    "Manage"
];

export const defaultStartHoldingShow = {
    currTab : "Reports",
    pipelinebutton : "Due Dilligence"
};

export const holdingPipelineButtons = [
    // "Due Dilligence",
    // "Custom Forms",
    // "Reports"
];

export const reportingFrameworksDefault = {
    "UN Sustainability Goals": {
        yb_identifier: "un_sdg",
        description: "The Sustainable Development Goals are a universal call to action to end poverty, protect the planet and improve the lives and prospects of everyone, everywhere. The Goals were adopted by all UN Member States in 2015, as part of the 2030 Agenda for Sustainable Development which set out a 15-year plan to achieve the Goals. This framework is included here to allow funds and companies to indicate their alignment with the objectives of the Goals, to specify how they see the business of the company and the policies of the fund facilitating those goals, and to track and communicate positive outcomes at the fund and company level."
    },
    "IFC Operating Principles for Impact Management" : {
         yb_identifier: "ifc_principle",
         description: "The Principles are intended to be a framework for investors for the design and implementation of their impact management systems, ensuring that impact considerations are integrated throughout the investment lifecycle. They may be implemented through different types of systems, each of which can be designed to fit the needs of an individual institution. They do not prescribe specific tools and approaches, or specific impact measurement frameworks. The expectation is that industry participants will continue to learn from each other as they implement the Principles."
    },
};

export const defaultHoldingConfiguration = {
    legal_name: "",
    business_description: "",
    sustainability_challenge: "",
    impact_thesis: "",
    url:"",
    headquarters_address: ""
};

export const defaultHoldingGeography = {
    primary_market: defaultCountryCode.value,
    secondary_market: defaultCountryCode.value,
};

export const holdingTableHeaders = {
    basic: [
            { label: "NAME", sort: true, id: "name" },
            { label: "VERTICAL", sort: true, id: "vertical" },
            { label: "INDUSTRY", sort: true, id: "industry" },
            { label: "PRIMARY MARKET", sort: true, id: "primary_market" },
            { label: "STATE", sort: true, id: "pipeline_state" },
    ],
    basicshow: [
        { label: "NAME", sort: true, id: "name" },
        { label: "VERTICAL", sort: true, id: "vertical" },
        { label: "INDUSTRY", sort: true, id: "industry" },
        { label: "PRIMARY MARKET", sort: true, id: "primary_market" },
        { label: "STATE", sort: true, id: "pipeline_state" },
        { label: "", sort: false, id: "" },
        { label: "", sort: false, id: "" },
    ],
    impact: [
        { label: "NAME", sort: true, id: "name" },
        { label: "KEY METRIC 1", sort: true, id: "key_metric_1" },
        { label: "", sort: false, id: "key_metric_1_graph" },
        { label: "KEY METRIC 2", sort: true, id: "key_metric_2" },
        { label: "", sort: false, id: "key_metric_2_graph" },
        { label: "LAST UPDATED", sort: true, id: "last_updated" },
        { label: "", sort: false, id: "" },
        { label: "", sort: false, id: "" },
    ],
    financial: [
        { label: "NAME", sort: true, id: "name" },
        { label: "INVESTMENT AMOUNT", sort: true, id: "investment_a" },
        { label: "MULTIPLIER", sort: true, id: "multiple" },
        { label: "REVENUE", sort: true, id: "revenue" },
        { label: "TARGET GROWTH", sort: true, id: "target_growth" },
        { label: "YoY GROWTH", sort: true, id: "yoy_growth" },
        { label: "", sort: false, id: "" },
        { label: "", sort: false, id: "" },
    ],
}

export const memberTableHeaders = [
    { label: "NAME", sort: false, id: "name" },
    { label: "TITLE", sort: false, id: "title" },
    { label: "DEPARTMENT", sort: false, id: "department" },
    { label: "EMAIL", sort: false, id: "email" },
    { label: "ACCESS", sort: false, id: "access" },
];


export const defaultClassification = {
    industry: "",
    vertical: ""
};

export const pipelineStatesTitleized = {
    opportunity: "Opportunity",
    in_contact: "In Contact",
    research: "Research",
    diligence: "Diligence",
    approved: "Approved",
    funded: "Funded",
    exited: "Exited",
    rejected: "Rejected"
};

export const pipelineStateOptions = [
    {value:"opportunity", label:"Opportunity"},
    {value:"in_contact", label:"In Contact"},
    {value:"diligence", label:"Diligence"},
    {value:"research", label:"Research"},
    {value:"approved", label:"Approved"},
    {value:"funded", label:"Funded"},
    {value:"exited", label:"Exited"},
    {value:"rejected", label:"Rejected"},
];

export const defaultPipelineState = {value:"opportunity", label:"Opportunity"};

export const roleTypes = {
    reader: 'Reader',
    writer: 'Writer',
    admin: 'Admin',
    owner: 'Owner',
    no_access: 'No Access'
};


export const roleTypeLabels = [
    {value:"reader", label:"Reader"},
    {value:"writer", label:"Writer"},
    {value:"admin", label:"Admin"},
    {value:"no_access", label:"No Access"},
];


export const defaultRole = roleTypes.no_access;

export const blankMessages = {
    ifc_principles : "There are no notes for this IFC principle"
};

export const reportingFrameworkToggles = (fund) => {
    if(fund.has_ifc_principle){
        return [
            "Impact",
            "IFC Principles",
            "SASB"
        ];
    } else {
        return [
            "Impact",
            "SASB"
        ];
    }
};


export const monitoringAndEngagementHeaders = [
    " ",
    "Target Value:",
    "Current Value:",
    "Historical Data:"
];

export const customFormHeadersTitelized = {
    "custom": "Company Metrics",
    "default": "Default Fund Metrics",
    "un_sdg": "UN SDG Metrics"
};

export const holdingManageButtons = [
    "Basic Information",
    "Team",
    "Sharing & Securty",
];

export const successfulMetricTargetAddMessage = "You have successfully added a new metric target";

export const problemAddingMetricTarget =  "There was a problem adding a new metric target";

export const successfulMetricValueAddMessage = "You have successfully added a new metric value";

export const problemAddingMetricValue =  "There was a problem adding a new metric value";

export const successfulMetricAddMessage = "You have successfully added a new metric";

export const problemAddingMetric =  "There was a problem adding a new metric";

export const successfulNoteAddMessage = "You have successfully added a new note";

export const problemAddingNote = "There was a problem adding a new note";
