import propTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Form, FormControl, InputGroup } from 'react-bootstrap'
import styled from 'styled-components'

import { StyledForm } from '../../../components/forms/EntityForm.styles'
import { updateFocusArea } from '../../repository/esg_respository'
import BaseDialog from './BaseDialog'
import DialogControls from './DialogControls'

const DialogContent = styled.div`
  .modal-form {
    margin: 0;
    padding: 6px 10px 10px;
    border-radius: 5px;
    background-color: #f6f8fd;
  }
  .text-inputs-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    .form-group {
      flex-grow: 1;
    }
  }
`

const saveChanges = (entityId, focusAreaId, dataType, value, date, note) => {
  return updateFocusArea(entityId, focusAreaId, dataType, value, date, note)
}

const EditFocusAreaValueDialog = ({
  entityId,
  data,
  onClose,
  onSuccess,
  editingType,
  open,
}) => {
  const [value, setValue] = useState('')
  const [note, setNote] = useState('')
  const [date, setDate] = useState('')
  const [error, setError] = useState(null)
  const [dateError, setDateError] = useState(null)

  useEffect(() => {
    resetForm()
  }, [])

  const resetForm = () => {
    setValue('')
    setNote('')
    setDate('')
    setError(null)
    setDateError(null)
  }

  const onInputChange = (id, value) => {
    id == 'value' ? setValue(value) : setDate(value)
    if (value != '') setError(null)
    if (date != '') setDateError(null)
  }

  const onNoteChange = value => {
    setNote(value)
  }

  const onModalClose = () => {
    resetForm()
    onClose()
  }

  const typeLabel =
    editingType === 'current_value' ? 'Current Value' : 'Target Value'

  const onFormSubmit = e => {
    e.preventDefault()

    value === '' ? setError(`${typeLabel} is required`) : setError(null)
    date === '' && editingType === 'target_value'
      ? setDateError('Date is required')
      : setDateError(null)

    if (!error && !dateError) {
      saveChanges(entityId, data.id, editingType, value, date, note).then(
        res => {
          onModalClose()
          onSuccess(res)
        }
      )
    }
  }

  return (
    <BaseDialog
      title={`Edit ${typeLabel}`}
      open={open}
      onClose={onModalClose}
      dataCy='edit_focus_area_dialog'
      render={() => (
        <DialogContent>
          <StyledForm onSubmit={onFormSubmit} className='modal-form'>
            <div className='text-inputs-container'>
              <div className='form-group'>
                <label htmlFor={`value`}>New Value</label>
                <InputGroup hasValidation>
                  <FormControl
                    id='value'
                    aria-describedby={`value`}
                    type='number'
                    onChange={e => onInputChange('value', e.target.value)}
                    required
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>{data.unit}</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <Form.Control.Feedback type='invalid' as='small'>
                  {error}
                </Form.Control.Feedback>
              </div>
              {editingType === 'target_value' && (
                <div className='form-group'>
                  <label htmlFor={`date`}>Target Date</label>
                  <InputGroup hasValidation>
                    <FormControl
                      id={`date`}
                      aria-describedby={`date`}
                      type='date'
                      onChange={e => onInputChange('date', e.target.value)}
                      required
                    />
                  </InputGroup>
                  <Form.Control.Feedback type='invalid' as='small'>
                    {dateError}
                  </Form.Control.Feedback>
                </div>
              )}
            </div>
            <div className='form-group'>
              <label htmlFor={`note_textarea`}>Note</label>
              <FormControl
                id={`note_textarea`}
                aria-describedby={`note_textarea`}
                as='textarea'
                rows={4}
                onChange={e => onNoteChange(e.target.value)}
              />
            </div>
            <DialogControls onClose={onModalClose} />
          </StyledForm>
        </DialogContent>
      )}
    />
  )
}

EditFocusAreaValueDialog.defaultProps = {
  data: {
    previous_values: [],
  },
}
EditFocusAreaValueDialog.propTypes = {
  open: propTypes.bool,
  entityId: propTypes.number.isRequired,
  onClose: propTypes.func.isRequired,
  onSuccess: propTypes.func.isRequired,
  data: propTypes.object.isRequired,
  editingType: propTypes.string,
}

export default EditFocusAreaValueDialog
