import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { StyledButton } from './DownloadLandscape.styles';

const DownloadLandscape = ({ isBusy, onClick, ...props }) => {
  const icon = isBusy ?
    <Spinner key={'icon-spinner'} animation="border" /> :
    <img key={'icon-pdf'} src="/ico_adobe_reader.svg" />

  return (
    <StyledButton secondary
      label={[icon, "Download Landscape"]}
      disabled={ isBusy }
      onClick={ onClick }
      data-cy={props['data-cy']}
    />
  )
}

export default DownloadLandscape
