export const hasESGYearlyMetrics = (ESGYearlyMetrics) => {
    return !!ESGYearlyMetrics && Object.values(ESGYearlyMetrics).some(year => year !== null);
};

export const loadESGYearlyMetrics = ({targetId, parentTargetId=null, setESGYearlyMetrics, getESGYearlyMetrics, setError}) => {
    const args = [targetId]
    if (parentTargetId) args.push(parentTargetId)

    getESGYearlyMetrics(...args)
        .then(res => {
          if (hasESGYearlyMetrics(res.data)) {
            setESGYearlyMetrics(res.data);
          } else {
            setESGYearlyMetrics(null);
          }
        })
        .catch(err => {
          const error = err.response ? err.response.status : 502;
          setError(error);
        });
  };

export const hasESGs = (esg_score) => {
  return Object.values(esg_score).filter((s) => s && s !== "NaN").length > 0
}
