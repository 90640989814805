import React from 'react'

import LoadingSpinner from './LoadingSpinner'

const SpinnerContainer = ({ isLoading, size, children = <></>, className }) =>
  isLoading ? <LoadingSpinner size={size} className={className} /> : children

SpinnerContainer.defaultProps = {
  size: '4rem',
}

export default SpinnerContainer
