import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';





const LineChartBody = ({data, xscale, yscale, radius, svgstyles}) => {
    const dots = () => {
        return data.map((d, i) => {
            return <circle
            key={i}
            cx={xscale(d.x)}
            cy={yscale(d.y)}
            r={radius}
            thing={d.score}
            // pathLength={pathlength}
            stroke={svgstyles.dotStroke}
            fill={svgstyles.dotFill}
            strokeWidth={svgstyles.circleWidth}
            >
            </circle>
        })
    }

    const line = () => {
        const useData = data.map((d) =>  [xscale(d.x), yscale(d.y)])
        const line = d3.line()(useData);
        return <path
        d={line}
        fill={svgstyles.lineFill}
        stroke={svgstyles.lineStroke}
        strokeWidth={svgstyles.lineWidth}
        strokeDasharray={svgstyles.strokeDasharray}
        >
        </path>
    }


    return (
        <>
            {line()}
            {dots()}
        </>
    )
}
// data, xscale, yscale, radius, svgstyles
LineChartBody.propTypes = {
    data : PropTypes.array,
    xscale : PropTypes.func,
    yscale : PropTypes.func,
    radius : PropTypes.number,
    svgstyles : PropTypes.object

}


export default LineChartBody;