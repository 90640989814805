// import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { getPaiOverview } from '../../../../../repositories/portfolio_repository'
import { FONT_COLOR_SECONDARY } from '../../../../../styles/colors'
import SpinnerContainer from '../../../../../yb_components/styleguide/spinners/SpinnerContainer'
import {
  getNow,
  getRemainingLoadingTime,
} from '../../../../../yb_helpers/loader'
import { ProgressIndicator } from '../components/reporting_progress/progress_indicator/ProgressIndicator'
import { SpeedDialTable } from '../components/tables/speed_dial/SpeedDialTable'
import { Well } from '../components/well/Well'

export const PaiOverviewTab = ({ portfolioId, snapshotId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [incompleteHoldings, setIncompleteHoldings] = useState([])
  // const [outOfDateHoldings, setOutOfDateHoldings] = useState([])
  // const [sectorOutliers, setSectorOutliers] = useState([])

  const formatHoldingCompletionRate = holding => {
    return {
      ...holding,
      value: `${Math.round(Number(holding.completion_rate) * 100)}%`,
    }
  }

  // const formatHoldingDate = holding => {
  //   return {
  //     ...holding,
  //     value: moment(holding.last_updated).format('MM/DD/YYYY'),
  //   }
  // }

  useEffect(() => {
    const now = getNow()
    setIsLoading(true)
    getPaiOverview(portfolioId, snapshotId)
      .then(
        ({
          data: {
            progress,
            incomplete_holdings,
            // out_of_date_holdings,
            // sector_outliers,
          },
        }) => {
          setProgress(progress || 0)
          setIncompleteHoldings(
            incomplete_holdings.map(formatHoldingCompletionRate)
          )
          // setOutOfDateHoldings(out_of_date_holdings.map(formatHoldingDate))
          // setSectorOutliers(sector_outliers.map(formatHoldingCompletionRate))
        }
      )
      .finally(() => {
        const remainingLoadingTime = getRemainingLoadingTime(now)

        setTimeout(() => setIsLoading(false), remainingLoadingTime)
      })
  }, [])

  return (
    <main>
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          gap: '20px',
        }}
      >
        <p style={{ color: `${FONT_COLOR_SECONDARY}`, flexGrow: 1 }}>
          Below, please find a summary of the data gaps and issues that were
          identified when your portfolio was populated with the data YvesBlue
          has for the Principal Adverse Impacts, a component of the SFDR
          submission rules. In the following work spaces, you will have the
          opportunity to address these gaps directly into the data tables in
          order to ready the data for the questionnaires. You will then be able
          to see the data it uses to fill out the questionnaires.
        </p>
        <ProgressIndicator progress={progress} isLoading={isLoading} />
      </div>
      <div>
        <SpinnerContainer isLoading={isLoading}>
          <div style={{ maxWidth: 400 }}>
            <Well title={'Entities with Missing Indicator Data'}>
              <SpeedDialTable
                portfolioId={portfolioId}
                snapshotId={snapshotId}
                tableData={incompleteHoldings}
                tableHeadItems={['Company Name', 'Completion Rate']}
              />
            </Well>
          </div>
          {/* Commented out because we need specifications for it's contents */}
          {/* <Well title={'Entities with Out of Date Data'}>
            <SpeedDialTable
              tableData={outOfDateHoldings}
              tableHeadItems={['Company Name', 'Last Updated']}
            />
          </Well>
          <Well title={'Entities with Sector Outliers'}>
            <SpeedDialTable
              tableData={sectorOutliers}
              tableHeadItems={['Company Name', 'Completion rate']}
            />
          </Well> */}
        </SpinnerContainer>
      </div>
    </main>
  )
}

PaiOverviewTab.propTypes = {
  portfolioId: PropTypes.string.isRequired,
  snapshotId: PropTypes.string.isRequired,
}
