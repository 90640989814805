import React from 'react'

export const DEFAULT_SORT = {
  sort_field: 'name',
  sort_dir: 'asc',
}

export const CARBON_OWNERSHIP_TABLE_HEADER = [
  {
    field: 'name',
    text: 'NAME',
    rawText: 'Name',
    align: 'left',
  },
  {
    field: 'weight',
    text: (
      <>
        PORTFOLIO <br /> WEIGHT (%)
      </>
    ),
    rawText: 'Portfolio Weight',
    align: 'right',
  },
  {
    field: 'owned_emissions',
    text: (
      <>
        CARBON OWNERSHIP <br /> (tCO<sub>2</sub>e)
      </>
    ),
    rawText: 'Carbon Ownership',
    align: 'right',
  },
  {
    field: 'portion_of_portfolio_emissions',
    text: (
      <>
        PORTION OF PORTFOLIO <br /> TOTAL EMISSIONS (%)
      </>
    ),
    rawText: 'Portion of Portolio Total Emissions',
    align: 'right',
  },
  {
    field: 'owned_intensity',
    text: (
      <>
        OWNED CARBON INTENSITY <br /> (tCO<sub>2</sub>e / $M Revenue)
      </>
    ),
    rawText: 'Owned Carbon Intensity',
    align: 'right',
  },
  {
    field: 'intensity',
    text: (
      <>
        CARBON INTESITY <br /> (tCO<sub>2</sub>e / $M Revenue)
      </>
    ),
    rawText: 'Carbon Intensity',
    align: 'right',
  },
  {
    field: 'intensity_vs_industry',
    text: (
      <>
        CARBON INTENSITY <br /> VS. INDUSTRY (%)
      </>
    ),
    rawText: 'Carbon VS. Industry',
    align: 'right',
  },
]

export const SECTOR_ALLOCATIONS_TABLE_HEADER = (
  benchmarkName = 'BENCHMARK'
) => [
  {
    field: 'name',
    text: 'SECTOR',
    rawText: 'Sector',
    align: 'left',
  },
  {
    field: 'target_weight',
    text: 'PORTFOLIO',
    rawText: 'Portfolio Weight',
    align: 'right',
  },
  {
    field: 'benchmark_weight',
    text: benchmarkName,
    rawText: 'Benchmark Weight',
    align: 'right',
  },
  { empty: true },
  {
    field: 'target_carbon_to_revenue',
    text: 'PORTFOLIO',
    rawText: 'Portfolio Carbon To Revenue',
    align: 'right',
  },
  {
    field: 'benchmark_carbon_to_revenue',
    text: benchmarkName,
    rawText: 'Benchmark Carbon To Revenue',
    align: 'right',
  },
  { empty: true },
  {
    field: 'target_sector_allocation',
    text: (
      <>
        SECTOR <br />
        ALLOCATION
      </>
    ),
    rawText: 'Sector Allocation',
    align: 'right',
  },
  {
    field: 'target_company_selection',
    text: (
      <>
        COMPANY <br />
        SELECTION
      </>
    ),
    rawText: 'Company Selection',
    align: 'right',
  },
  {
    field: 'combined_effect',
    text: (
      <>
        COMBINED <br />
        EFFECT
      </>
    ),
    rawText: 'Combined Effect',
    align: 'right',
  },
  {
    field: 'target_total_effect',
    text: (
      <>
        TOTAL <br />
        EFFECT
      </>
    ),
    rawText: 'Total Effect',
    align: 'right',
  },
]

export const powerCompanyTransitionTableHeader = pdf => {
  let weightCol = []
  if (pdf)
    weightCol = [
      {
        field: 'weight',
        text: 'WEIGHT',
        rawText: 'Weight',
        align: 'right',
      },
    ]

  return [
    {
      field: 'name',
      text: 'NAME',
      rawText: 'Name',
      align: 'left',
    },
    ...weightCol,
    {
      field: 'trbc_industry_group_name',
      text: 'INDUSTRY',
      rawText: 'Industry',
      align: 'left',
    },
    {
      field: 'percent_renewable',
      text: <div style={{ whiteSpace: 'nowrap' }}>% RENEWABLE</div>,
      rawText: '% Renewable',
      align: 'right',
    },
    {
      field: 'source',
      text: 'SOURCE',
      rawText: 'Source',
      align: 'left',
    },
  ]
}

export const sbtiTableHeader = pdf => {
  let weightCol = []
  if (pdf)
    weightCol = [
      {
        field: 'weight',
        text: 'WEIGHT (%)',
        rawText: 'Weight',
        align: 'right',
      },
    ]

  return [
    {
      field: 'name',
      text: 'COMPANY',
      rawText: 'Company',
      align: 'left',
    },
    ...weightCol,
    {
      field: 'target_status',
      text: 'COMMITTMENT',
      rawText: 'Committment',
      align: 'right',
    },
  ]
}

export const leadersLaggardsTableHeader = pdf => {
  let weightCol = []
  if (pdf)
    weightCol = [
      {
        field: 'weight',
        text: 'WEIGHT',
        rawText: 'Weight',
        align: 'right',
      },
    ]

  return [
    {
      field: 'name',
      text: 'COMPANY',
      rawText: 'Company',
      align: 'left',
    },
    ...weightCol,
    {
      field: 'category',
      text: 'CATEGORIES',
      rawText: 'Categories',
      align: 'left',
    },
  ]
}

export const budgetProjectionsTableHeader = pdf => {
  let weightCol = []
  if (pdf)
    weightCol = [
      {
        field: 'weight',
        text: 'WEIGHT (%)',
        rawText: 'Weight',
        align: 'right',
      },
    ]

  return [
    {
      field: 'name',
      text: 'COMPANY',
      rawText: 'Company',
      align: 'left',
    },
    ...weightCol,
    {
      field: 'projection',
      text: 'PROJECTIONS',
      rawText: 'Projections',
      align: 'right',
    },
  ]
}

export const carbonTaxExposureTableHeader = pdf => {
  let weightCol = []
  if (pdf)
    weightCol = [
      {
        field: 'weight',
        text: 'WEIGHT',
        rawText: 'Weight',
        align: 'right',
      },
    ]

  return [
    {
      field: 'name',
      text: 'COMPANY',
      rawText: 'Company',
      align: 'left',
    },
    ...weightCol,
    {
      field: 'country',
      text: 'COUNTRY',
      rawText: 'Country',
      align: 'left',
    },
    {
      field: 'rate_type',
      text: 'TYPE',
      rawText: 'Type',
      align: 'left',
    },
    {
      field: 'amount',
      text: (
        <>
          AMOUNT <br /> (USD)
        </>
      ),
      rawText: 'Amount',
      align: 'right',
    },
    {
      field: 'total_price_x_emissions',
      text: 'TOTAL PRICE × OWNED EMISSIONS',
      rawText: 'Total Price × Owned Emissions',
      align: 'right',
    },
    {
      field: 'percentage_of_enterprise_value',
      text: '% OF OWNED ENTERPRISE VALUE',
      rawText: '% of Owned Enterprise Value',
      align: 'right',
    },
    {
      field: 'percentage_of_revenue',
      text: '% OF OWNED REVENUE',
      rawText: '% of Owned Revenue',
      align: 'right',
    },
  ]
}

export const renewableEnergyTableHeader = pdf => {
  let weightCol = []
  if (pdf)
    weightCol = [
      {
        field: 'weight',
        text: 'WEIGHT',
        rawText: 'Weight',
        align: 'right',
      },
    ]

  return [
    {
      field: 'name',
      text: 'COMPANY',
      rawText: 'Company',
      align: 'left',
    },
    ...weightCol,
    {
      field: 'renewables_purchased',
      text: (
        <>
          TOTAL RENEWABLE ENERGY <br /> PURCHASED (MWh)
        </>
      ),
      rawText: 'Total Renewable Energy Purchased',
      align: 'right',
    },
    {
      field: 'renewables_produced',
      text: (
        <>
          TOTAL RENEWABLE ENERGY <br /> PRODUCED (MWh)
        </>
      ),
      rawText: 'Total Renewable Energy Produced',
      align: 'right',
    },
    {
      field: 'total_energy_usage',
      text: (
        <>
          TOTAL ENERGY <br /> USAGE (MWh)
        </>
      ),
      rawText: 'Total Energy Usage',
      align: 'right',
    },
    {
      field: 'renewables_of_total_energy_usage',
      text: (
        <>
          RENEWABLE % <br /> OF TOTAL ENERGY USAGE (MWh)
        </>
      ),
      rawText: 'Renewable % of Total Energy Usage',
      align: 'right',
    },
  ]
}

export const SCIENCE_BASED_CATEGORIES = [
  {
    label: '50%+ Progress',
    id: '50_',
  },
  {
    label: '25 to 50% Progress',
    id: '25_50',
  },
  {
    label: '10 to 25% Progress',
    id: '10_25',
  },
  {
    label: '0 to 10% Progress',
    id: '0_10',
  },
  {
    label: 'Behind Schedule',
    id: 'behind_schedule',
  },
  {
    label: 'No Committment',
    id: 'no_committment',
  },
]

export const BUDGET_PROJECTION_TYPES = [
  {
    label: 'Unmodeled',
    id: 'unmodeled',
  },
  {
    label: 'Zero Emissions',
    id: 'zero',
  },
  {
    label: 'Well Below Budget By 2030',
    id: 'under budget',
  },
  {
    label: 'Meet Budget by 2030',
    id: 'meet budget',
  },
  {
    label: 'Over Budget by 2030',
    id: 'over budget',
  },
]

export const CARBON_REPORT_TEXTS = {
  carbonOwnership: {
    title: 'CARBON EMISSIONS OWNERSHIP & INTENSITY',
    description:
      'The portfolio summary provides a basic overview of the carbon intensity of the portfolio holdings and of the portfolio itself. Intensity is the measure of units of carbon emissions per unit of revenue and the carbon footprint is the overall amount of carbon that the portfolio is responsible for based on the shares that are held in each investment. For further explanation of the calculations used for this please see the YvesBlue Explainer.',
  },
  sectorAllocation: {
    title: 'SECTOR ALLOCATION & ATTRIBUTION',
    description:
      'Portfolio Managers use attribution to understand how their sector weight allocations and company selection in those sectors contributed to better returns than the benchmark. This module does the same thing but uses sector carbon intensity (carbon/revenues) to express the same analysis. Please see the explainer for formulas and deeper explanation.',
  },
  carbonTaxRates: {
    title: 'CARBON TAX EXPOSURE',
    description: (
      <>
        Many countries have{' '}
        <a
          target='_blank'
          href='https://carbonpricingdashboard.worldbank.org/'
          rel='noreferrer'
        >
          a carbon tax or price in place already
        </a>
        . The product of a company&apos;s annual emissions and the price is
        shown as a percent of the company&apos;s enterprise value.
      </>
    ),
  },
  renewableEnergy: {
    title: 'RENEWABLE ENERGY PURCHASING',
    description:
      'Companies are making commitments to source renewable energy through their power company or to install onsite renewables. Investing in more companies that do this will help reduce the carbon footprint of your portfolio as those companies will be reporting lower emissions over time. Please note that many companies are not purchasing or producing renewable energy or they do not report this information. We show the raw and imputed energy use data such that a percent of total energy use could be provided for those companies that report. This data comes from ESG Data.',
  },
  powerCompanyTransitions: {
    title: 'POWER COMPANY TRANSITION',
    description:
      'If your portfolio contains any publicly listed power utilities, it is important to keep track of their transition status. Some power companies are stepping up their transition plans while others are staying with plans that were set several years ago. This information will be updated annually from company public documentation. If the uploaded portfolio does not include any publicly listed power companies, this module will have no data.',
  },
  solutionCompanies: {
    title: 'SOLUTION COMPANY HOLDINGS',
    description:
      'Carbon Solutions companies are those that have more than 50% of their revenue in a market segment that will be important to the carbon transition economy. If your portfolio includes any company that falls within this parameter, they will appear in your report.',
  },
  sbti: {
    title: 'SCIENCE BASED TARGETS INITIATIVE',
    description:
      'This provides a count of the entities in your portfolio that have set a carbon emissions reduction target in cooperation with the Science Based Targets Initiative. Science-based targets show organizations how much and how quickly they need to reduce their greenhouse gas (GHG) emissions to prevent the worst effects of climate change. The organization provides technical assistance to companies in setting these targets.',
  },
  budgetProjections: {
    title: '2030 BUDGET PROJECTIONS',
    description: (
      <>
        Each of your holding companies has its own carbon budget extending to
        2050. You can learn more about that{' '}
        <a
          target='_blank'
          href='http://help.yves.blue/en/articles/6698762-carbon-report-portfolio-summary-on-yvesblue#h_7771887a2b'
          rel='noreferrer'
        >
          here
        </a>
        . This metric counts in your portfolio how many holdings will be over
        budget by 2030 and how many will still be over budget by 2050. You see
        which ones Intersect with their budgeted emissions by 2030 and 2050,
        which of your holdings is projected to be Well Below budget or even at
        zero by 2050.
      </>
    ),
  },
  leadersLaggards: {
    title: 'LEADERS AND LAGGARDS',
    description:
      "Leaders and Laggards displays a color-scaled pie chart informed by companies' environmental performance, reporting, and actions. Companies are scored on their climate change strategy and practice across six indicators. The resulting score is classified into the four categories shown in the pie chart below and displayed based on the weight of the holdings in the portfolio.",
  },
}
