import axios from 'axios'
import PropTypes from 'prop-types'
import React, { forwardRef, useContext, useEffect, useState } from 'react'

import Loading from '../../../components/styleguide/Loading'
import { timestamp } from '../../../helpers/shared'
import { downloadTemplate } from '../../../pdf'
import { PDF } from '../../../pdf/pdf'
import useCurrentTabRef from '../../../yb_hooks/target/useCurrentTabRef'
import { AppContext } from '../../../yb_stores/app_store/AppStore'
import ReplacePeerModal from '../../instrument/components/peers/ReplacePeerModal'
import { TcfdHoldingDetailCard } from './components/holding_detail_card/TcfdHoldingDetailCard'
import { TcfdHoldingDetailListHeader } from './components/holding_detail_list/list_header/TcfdHoldingDetailListHeader'
import { TcfdHoldingDetailList } from './components/holding_detail_list/TcfdHoldingDetailList'
import { TcfdLegend } from './components/legend/TcfdLegend'
import { TcfdPortfolioScoreCard } from './components/tcfd_portfolio_score/TcfdPortfolioCard'
import { tcfdRiskHardcodeItems } from './constants/tcfd_holding_details_data'
import { loadHoldingDetails } from './loaders/tcfd_loaders'
import { postTcfdNote } from './repositories/tcfd_repository'

const getSelectedItemFromUrl = () => {
  const params = new URLSearchParams(window.location.search)
  for (const param of params) {
    if (params.has('active_risk')) return param[1]
  }
}

export const TcfdHoldingDetailTab = forwardRef(
  ({ holdingId, portfolioId, snapshotId }, ref) => {
    const [entity, setEntity] = useState(null)
    const [listItems, setListItems] = useState([])
    const [links, setLinks] = useState({ prev: null, next: null, home: null })
    const [portfolioScores, setPortfolioScores] = useState({})
    const [peersData, setPeersData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const [isReplacePeerModalOpen, setIsReplacePeerModalOpen] = useState(false)
    const [modalPeers, setModalPeers] = useState([])
    const [pdfLoading, setPdfLoading] = useState(false)
    const [appState] = useContext(AppContext)
    useCurrentTabRef(ref)

    const handleOpenReplacePeerModal = peers => {
      setIsReplacePeerModalOpen(true)
      setModalPeers(peers)
    }
    const onCloseReplacePeerModal = () => {
      setIsReplacePeerModalOpen(false)
      loadHoldingDetails(
        holdingId,
        portfolioId,
        snapshotId,
        setEntity,
        setIsLoading,
        setLinks,
        setListItems,
        setPortfolioScores
      )
    }

    useEffect(() => {
      loadHoldingDetails(
        holdingId,
        portfolioId,
        snapshotId,
        setEntity,
        setIsLoading,
        setLinks,
        setListItems,
        setPortfolioScores,
        setPeersData
      )
    }, [])

    const onExportPDF = () => {
      setPdfLoading(true)
      downloadTemplate(
        <PDF.TcfdHoldingDetail
          targetName={appState?.target?.name}
          holdingName={entity?.name}
          scores={portfolioScores}
          peerData={peersData}
        />,
        1,
        `yb-${entity?.name?.split(' ')?.join('-')}-tcfd-${timestamp}`,
        holdingId,
        'TCFD',
        `HoldingId-${holdingId}`,
        () => {
          setPdfLoading(false)
        }
      )
    }

    const onExportCSV = () => {
      axios
        .get(
          `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/holdings/${holdingId}/tcfd/csv`,
          { responseType: 'blob' }
        )
        .then(response => {
          const href = URL.createObjectURL(response.data)

          // create "a" HTML element with href to file & click
          const link = document.createElement('a')
          link.href = href
          const filename =
            response.headers['content-disposition'].match(
              /filename="(.+.csv)"/
            )[1]

          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link)
          URL.revokeObjectURL(href)
        })
    }

    const submitNote = (
      value,
      setLoading,
      tcfdSector,
      setNoteList,
      setError,
      setValue
    ) => {
      postTcfdNote(
        value,
        setLoading,
        tcfdSector,
        holdingId,
        portfolioId,
        snapshotId,
        setNoteList,
        setError,
        setValue
      )
    }

    return (
      <div style={{ marginTop: '20px' }}>
        <header style={{ marginBottom: '20px' }}>
          <TcfdPortfolioScoreCard scores={portfolioScores} />
        </header>
        <main style={{ marginBottom: '20px' }}>
          <TcfdHoldingDetailCard>
            <header>
              <h3>Holdings Transition & Physical Risk Scoring Model</h3>
              <p>
                All holdings in the portfolio have a score calculated for each
                pillar. The Market Risk, Reputation, and Physical Risk scores
                are computed from multiple data inputs as subscores, aggregating
                to their overall score. All pillar scores are customizable based
                on investment and risk priorities. Each holding’s pillar scores
                and subscores are compared against companies in the same sector
                as the holding.
              </p>
            </header>
            <main>
              <TcfdHoldingDetailListHeader
                entityCountry={entity?.country ?? ''}
                entityName={entity?.name ?? ''}
                entitySector={entity?.sector ?? ''}
                entityTrbcActivity={entity?.activity ?? ''}
                links={links}
                isLoading={isLoading}
                onExportPDF={onExportPDF}
                onExportCSV={onExportCSV}
              />
              <TcfdHoldingDetailList
                selectedItem={getSelectedItemFromUrl()}
                riskApi={listItems}
                riskHardcode={tcfdRiskHardcodeItems}
                isLoading={isLoading}
                openReplacePeerModal={handleOpenReplacePeerModal}
                saveNote={submitNote}
              />
              <div
                style={{
                  margin: '20px 0 0 0',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <TcfdLegend />
              </div>
            </main>
          </TcfdHoldingDetailCard>
        </main>
        <ReplacePeerModal
          show={isReplacePeerModalOpen}
          peers={modalPeers}
          onClose={onCloseReplacePeerModal}
        />
        <Loading show={pdfLoading} message={'Generating report...'} />
      </div>
    )
  }
)

TcfdHoldingDetailTab.displayName = 'TcfdHoldingDetailTab'

TcfdHoldingDetailTab.propTypes = {
  holdingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  portfolioId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  snapshotId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
