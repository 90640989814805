// LIBRARIES
import React, { useState } from 'react'
// COMPONENTS
import { Modal } from 'react-bootstrap'
import Select from 'react-select-3'

import Button from '../../../../../components/styleguide/Button'
import InputLabel from '../../../../../components/styleguide/InputLabel'
import SectionWrapper from '../../../../../components/styleguide/SectionWrapper'
import TextInput from '../../../../../components/styleguide/TextInput'
import WrapperColumn from '../../../../../components/styleguide/WrapperColumn'
// LOOKUPS
import {
  defaultDepartment,
  defaultTitle,
  departments,
  titles,
} from '../../../../../constants/funds'
// REPOSITORY CALLS
import { postFundMembers } from '../../../../../repositories/funds_repository'
import { PEErrorService } from '../../../errorService'
import { MemberFactory } from '../../../factories/MemberFactory'
import { AddPipelineDialog } from '../../../styles'

const CreateMemberModal = ({ show, onHide, onSubmit, fundId }) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [title, setTitle] = useState(defaultTitle.value)
  const [department, setDepartment] = useState(defaultDepartment.value)
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState([])

  const handleSubmit = () => {
    setErrors([])

    const memberParams = {
      first_name: firstName,
      last_name: lastName,
      title: title,
      department: department,
      email: email,
    }

    const newMember = MemberFactory.build(memberParams)
    const errors = newMember.errors

    if (Object.values(errors).length > 0) {
      setErrors(errors)
    } else {
      postFundMembers(fundId, memberParams)
        .then(response => {
          const newMember = MemberFactory.build(
            JSON.parse(response.config.data)
          )
          newMember.setRoleType('no_access')
          onSubmit(newMember)
        })
        .catch(error => {
          error &&
            error.response &&
            PEErrorService.setErrors(error.response.data, setErrors)
        })
    }
  }

  return (
    <AddPipelineDialog minHeight='300px' show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create New User for Fund</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SectionWrapper justify='space-around'>
          <TextInput
            error={errors.first_name}
            label='First Name *'
            width='45%'
            id='member-first-name'
            onChange={(id, value) => {
              setFirstName(value)
            }}
          />
          <TextInput
            error={errors.last_name}
            label='Last Name *'
            width='45%'
            id='member-last-name'
            onChange={(id, value) => {
              setLastName(value)
            }}
          />
        </SectionWrapper>
        <SectionWrapper justify='space-around'>
          <WrapperColumn styles={{ width: '45%' }}>
            <InputLabel label='Job Title' />
            <Select
              styles={{
                container: provided => ({
                  ...provided,
                  width: '100%',
                  marginBottom: '16.5px',
                }),
                input: provided => ({
                  ...provided,
                  paddingTop: '8px',
                  paddingBottom: '4.75px',
                }),
              }}
              onChange={input => {
                setTitle(input.value)
              }}
              options={titles}
              defaultValue={defaultTitle}
            />
          </WrapperColumn>
          <WrapperColumn styles={{ margin: '0px 0px 0px 0px', width: '45%' }}>
            <InputLabel label='Department' />
            <Select
              styles={{
                container: provided => ({
                  ...provided,
                  width: '100%',
                  marginBottom: '16.5px',
                }),
                input: provided => ({
                  ...provided,
                  paddingTop: '8px',
                  paddingBottom: '4.75px',
                }),
              }}
              onChange={input => {
                setDepartment(input.value)
              }}
              options={departments}
              defaultValue={defaultDepartment}
            />
          </WrapperColumn>
        </SectionWrapper>
        <SectionWrapper margin='-10px 0px 0px 0px' justify='center'>
          <TextInput
            error={errors.email}
            label='Email *'
            width='95%'
            id='member-email'
            onChange={(id, value) => {
              setEmail(value)
            }}
          />
        </SectionWrapper>
        <SectionWrapper justify='space-between' margin='10px 10px 0px 12px'>
          <Button width='20%' label='Cancel' onClick={onHide} />
          <Button width='30%' label='Add Member' onClick={handleSubmit} />
        </SectionWrapper>
      </Modal.Body>
    </AddPipelineDialog>
  )
}

export default CreateMemberModal
