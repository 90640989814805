import React, { useState } from 'react'
import styled from 'styled-components'

import {
  APP_COLOR_PRIMARY,
  BG_COLOR_SECONDARY,
  FONT_COLOR_SECONDARY,
} from '../../styles/colors'

const StyledToggle = styled.div`
  float: right;

  display: inline-flex;

  button {
    font-size: 12px;
    width: 80px;
    height: 30px;
    border: 1px solid ${BG_COLOR_SECONDARY};
    color: ${FONT_COLOR_SECONDARY};
    background-color: white;

    :first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    :last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  button[active='true'] {
    background-color: ${APP_COLOR_PRIMARY};
    color: white;
    border: none;
  }
`

const Toggle = ({ values, active, onChange }) => {
  const [current, setCurrent] = useState(values[0].key)

  const handleChange = key => {
    setCurrent(key)
    onChange(key)
  }
  return (
    <StyledToggle>
      {values.map((value, index) => (
        <button
          key={`toggle-item-${index}`}
          active={`${(active || current) == value.key}`}
          onClick={() => handleChange(value.key)}
        >
          {value.label}
        </button>
      ))}
    </StyledToggle>
  )
}

export default Toggle
