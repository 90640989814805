import React, { useEffect, useState } from 'react'

import { getFunnelStates } from '../../../repositories/managers_repository'
import SpinnerContainer from '../../../yb_components/styleguide/spinners/SpinnerContainer'

const Funnel = () => {
  const [funnelStates, setFunnelStates] = useState([])

  useEffect(() => {
    getFunnelStates().then(result => {
      setFunnelStates(result.data)
    })
  }, [])

  return (
    <>
      <h3>Funnel</h3>
      <SpinnerContainer isLoading={funnelStates?.length === 0}>
        <table className='table table-sm table-striped'>
          <thead>
            <tr>
              <th>State</th>
              <th>Count</th>
            </tr>
          </thead>

          <tbody>
            {Object.entries(funnelStates).map(([state_key, state]) => (
              <tr key={state_key}>
                <td>{state.name}</td>
                <td>{state.count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </SpinnerContainer>
    </>
  )
}

export default Funnel
