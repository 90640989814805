import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {
  getTcfdAccentColor,
  getTcfdColor,
  getTcfdHoverColor,
  sanitizedTcfdNumber,
} from '../../../../../../../modules/portfolio/tcfd/utils/tcdf_colors'
import { AlignCenter, FullSize } from '../../../../../../yb_styles/layout'

const StyledTableData = styled.td`
  width: 84px;
  height: 30px;
  text-align: center;
  border: 1px solid white;
  color: ${props => props.color};
  background-color: ${props => props.bgColor};

  a {
    ${FullSize}
    ${AlignCenter}
    text-decoration: none;

    flex-grow: 1;
    color: inherit;
    background-color: inherit;
  }
`

export const TcfdTableColorCell = ({ href, number, style }) => {
  return (
    <StyledTableData
      color={getTcfdAccentColor(number)}
      bgColor={getTcfdColor(number)}
      hoverBgColor={getTcfdHoverColor(number)}
      style={style}
    >
      <a href={href}>{number != null ? sanitizedTcfdNumber(number) : '—'}</a>
    </StyledTableData>
  )
}

TcfdTableColorCell.propTypes = {
  href: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}
