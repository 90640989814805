import FormService from '@services/Form'
import { MultipleOptionSwitch } from '@ui/components/interactive/MultipleOptionSwitch'
import { SelectControl } from '@ui/components/interactive/Select'
import getTestingProps from '@utils/test/getTestingProps'
import React, { useEffect, useMemo, useState } from 'react'

import Controller from './DisclosureFilters.controller'
// import theme from './DisclosuresFilters.module.scss'
import './DisclosuresFilters.module.scss'

export interface IDisclosuresFiltersData {
  disclosureBase: string
  reportType: string
  asOfDate: string
  reportDescription: string
}

const Form = new FormService<IDisclosuresFiltersData>()
interface IProps {
  onChange: (formData: IDisclosuresFiltersData) => void
}

const DisclosuresFiltersComp: React.FC<IProps> = ({ onChange }) => {
  const yearOptions = Controller.useCompileYearOptions()

  const publishingYearModel = useState<string>('all')
  const [publishingYear] = publishingYearModel

  const [disclosureType] = Form.useInputModel<string>('all')
  const [state] = Form.useInputModel<string>('all')

  const formModel = useMemo(
    () => ({
      disclosure_type: disclosureType,
      state,
      publish_year: publishingYearModel,
    }),
    [disclosureType, state, publishingYearModel]
  )

  const formData = useMemo(() => Form.useFormData(formModel), [formModel])

  useEffect(() => {
    onChange?.(formData)
  }, [formData, onChange])

  return (
    <div
      className='DisclosuresFilters'
      {...getTestingProps('disclosureFilters')}
    >
      <div className='formControl'>
        <label htmlFor='disclosureType'>Disclosure Type</label>
        <MultipleOptionSwitch
          name='disclosureType'
          options={[
            { label: 'All', value: 'all' },
            { label: 'Pre-Contractual', value: 'pre_contractual' },
            {
              label: 'Periodic',
              value: 'periodic',
            },
          ]}
          model={disclosureType}
        />
      </div>
      <div className='formControl'>
        <label htmlFor='revenue'>State</label>
        <MultipleOptionSwitch
          name='revenue'
          options={[
            { label: 'All', value: 'all' },
            { label: 'Draft', value: 'draft' },
            { label: 'Published', value: 'published' },
          ]}
          model={state}
        />
      </div>
      <SelectControl
        label='Publishing year'
        name='publishingYearModel'
        className='publishingYearSelect'
        model={publishingYearModel}
        options={yearOptions}
      />
    </div>
  )
}

const DisclosuresFilters = React.memo(DisclosuresFiltersComp)
export { DisclosuresFilters }
