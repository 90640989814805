import _ from 'lodash'
import React from 'react'

import TextView from '../../../../components/styleguide/TextView'
import Tooltip from '../../../../components/styleguide/Tooltip'
import { DEFAULT_KEYS } from '../ScoresStackedGraph.constants'

export const GraphBarComponent = ({
  x,
  y,
  width,
  height,
  color,
  data,
  showTooltip,
  hideTooltip,
  keys,
  ellipseSize = 4,
}) => {
  const isLastRow = _.last(keys) === data.id

  /* Render the top svg element with top rounded borders */
  let renderSvg = ''
  if (isLastRow) {
    renderSvg = `
      M${-((ellipseSize + width) / 2)}, ${ellipseSize}
      q 0 -${ellipseSize} ${ellipseSize} -${ellipseSize}
      h ${width}
      q ${ellipseSize} 0 ${ellipseSize} ${ellipseSize}
      v ${height - ellipseSize}
    `
  } else {
    renderSvg = `
      M${-((ellipseSize + width) / 2)}, 0
      h ${width + ellipseSize * 2}
      v ${height}
    `
  }

  /* Get the label for each ESG Score */
  const [eScore, sScore, gScore] = DEFAULT_KEYS
  let label = ''
  switch (data.id) {
    case eScore:
      label = 'Environmental'
      break

    case sScore:
      label = 'Social'
      break

    case gScore:
      label = 'Governmental'
      break

    default:
      break
  }

  return (
    <g
      transform={`translate(${x}, ${y})`}
      onMouseMove={event => {
        showTooltip(
          <>
            <Tooltip padding={10} show={true}>
              <TextView bold center text={label} />
              <TextView center text={`${Math.round(data.value * 10) / 10}%`} />
            </Tooltip>
          </>,
          event
        )
      }}
      onMouseLeave={() => {
        hideTooltip()
      }}
    >
      <path
        d={`
            ${renderSvg}
            h -${width + ellipseSize * 2}
            z
          `}
        fill={color}
      />
    </g>
  )
}

export default GraphBarComponent
