import propTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  margin: 0;
  padding: 1.25rem;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.03);
  background-color: white;

  h1 {
    margin: 0 0 1.25rem;
    display: inline-block;
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 700;
  }
`

const PageCard = ({ children, className, title }) => {
  return (
    <StyledDiv className={className}>
      {title && <h1>{title}</h1>}
      {children}
    </StyledDiv>
  )
}

PageCard.propTypes = {
  children: propTypes.node.isRequired,
  className: propTypes.string,
  title: propTypes.string, // optional; can't use if header is more complicated than just an h1
}

export default PageCard
