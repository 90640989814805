import React from 'react'
import Select from 'react-select'

// https://react-select.com/styles
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    minHeight: '48px',
    border: state.isFocused ? '1px solid #002174' : '1px solid #e6e7ea',
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgb(38 78 180 / 50%)' : 0,
    '&:hover': {
      border: state.isFocused ? '1px solid #002174' : '1px solid #e6e7ea',
      cursor: 'pointer',
    },
    '.has-error &': {
      color: 'var(--danger)',
      borderColor: 'var(--danger)',
      boxShadow: state.isFocused ? '0 0 0 0.2rem rgb(220 53 69 / 25%);' : 0,
    },
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '2px 8px',
    color: '#495057',
    fontSize: '14px',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#979797;',
  }),
  input: provided => ({
    ...provided,
    color: '#495057',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  // menu
  menu: provided => ({
    ...provided,
    padding: '2px 0',
    color: '#495057',
    fontSize: '14px',
  }),
  menuList: provided => ({
    ...provided,
  }),
  menuPortal: provided => ({
    ...provided,
  }),
  option: provided => ({
    ...provided,
    padding: '6px 10px',
    fontSize: '14px',
    lineHeight: '1.2',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  noOptionsMessage: provided => ({
    ...provided,
  }),
}

// primary
// primary75
// primary50
// primary25
// danger
// dangerLight
// neutral0
// neutral5
// neutral10
// neutral20
// neutral30
// neutral40
// neutral50
// neutral60
// neutral70
// neutral80
// neutral90

export const BaseCombobox = ({ ...props }) => {
  return (
    <Select
      {...props}
      styles={customStyles}
      theme={theme => ({
        ...theme,
        borderRadius: 6,
        colors: {
          ...theme.colors,
          primary: '#002fa7',
          primary25: '#d6eeff',
          danger: '#dc3545',
        },
      })}
    />
  )
}
