import useFeedbackMessage from '@env/hooks/forms/useFeedbackMessage'
import useInputValidations from '@env/hooks/forms/useInputValidations'
import useRequiredValidation from '@env/hooks/forms/useRequiredValidation'
import useShouldDisplayInputFeedback from '@env/hooks/forms/useShouldDisplayInputFeedback'
import withFormControl from '@ui/components/compositions/withFormControl'
import withFormRow from '@ui/components/compositions/withFormRow/withFormRow'
import { InputFeedback } from '@ui/components/presentation/copy/InputFeedback'
import classnames from 'classnames'
import React, { ChangeEvent, useState } from 'react'

// import theme from './TextArea.module.scss'
import './TextArea.module.scss'

export interface IProps
  extends YB.IFormControl<string>,
    React.DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    > {}

const TextArea: React.FC<IProps> = ({
  model = useState<string>(''),
  feedbackModel = useState<string>(''),
  formFeedbackModel = useState<string>(''),
  validations = [],
  className = '',
  required = true,
  disabled,
  ...inputProps
}) => {
  const [value, setValue] = model
  const [isPristine, setIsPristine] = useState(true)
  const feedbackMessage = useFeedbackMessage(feedbackModel, formFeedbackModel)
  const isInvalid = useShouldDisplayInputFeedback(
    feedbackMessage,
    isPristine,
    disabled
  )

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value)
  }

  useInputValidations(
    value,
    useRequiredValidation(validations, required),
    feedbackModel
  )

  return (
    <>
      <textarea
        {...inputProps}
        placeholder={inputProps?.placeholder?.label}
        required={required}
        aria-required={required}
        disabled={disabled}
        value={value}
        className={classnames('TextArea', {
          invalid: isInvalid,
          [className]: !!className,
        })}
        onChange={handleChange}
        onBlur={() => setIsPristine(false)}
      />
      <InputFeedback isPresent={isInvalid} feedbackMessage={feedbackMessage} />
    </>
  )
}

export default TextArea

export const TextAreaControl = withFormControl<IProps>(TextArea)

export const TextAreaRow = withFormRow<IProps>(TextArea)
