import React from 'react'

import TextView from '../../../../../components/styleguide/TextView'
import Tooltip from '../../../../../components/styleguide/Tooltip'
import { abbreviateNumber } from '../../../../../helpers/number'

const BarTooltip = ({ data }) => {
  return (
    <Tooltip padding={10} show={true} width='120px'>
      <TextView bold center text={`${abbreviateNumber(data?.value)}%`} />
      <TextView center text={`${data?.id ? data?.id : '—'}`} />
    </Tooltip>
  )
}

export default BarTooltip
