import React, { useEffect, useState } from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Nav from 'react-bootstrap/Nav'
import styled from 'styled-components'

import Container from '../../components/styleguide/Container'
import { hedgeFundUnits } from '../../helpers/unit'
import { mapHedgeFundToState } from '../../mappers/funds_mapper'
import { mapInitiativesToState } from '../../mappers/initiatives_mapper'
import { getHedgeFundHolding } from '../../repositories/funds_repository'
import { getInitiatives } from '../../repositories/initiatives_repository'
import HoldingHeader from './components/HoldingHeader'
import RiskAssessmentCard from './components/RiskAssessmentCard'

const StyledContainer = styled(Container)`
  padding: 30px;
`

const Div = styled.div`
  margin-top: 20px;
  > :not(:first-child) {
    margin-top: 20px;
  }
`

const CUSTOM_OPTION = { id: 'custom', label: 'Custom Initiative' }

const loadInitiatives = ({ setInitiatives, holdingId }) => {
  if (holdingId) {
    getInitiatives({ holdingId: holdingId }).then(res => {
      setInitiatives(mapInitiativesToState(res.initiatives))
    })
  }
}

const loadFund = ({ fundId, snapshotId, holdingId, setFund }) => {
  getHedgeFundHolding(fundId, snapshotId, holdingId).then(res => {
    setFund(mapHedgeFundToState(res))
  })
}

const RiskAssessment = ({ fundId, holdingId, snapshotId }) => {
  const [fund, setFund] = useState(null)
  const [initiatives, setInitiatives] = useState(null)
  const [options, setOptions] = useState({
    environmental: [CUSTOM_OPTION],
    social: [CUSTOM_OPTION],
    governance: [CUSTOM_OPTION],
    units: hedgeFundUnits,
  })

  useEffect(loadInitiatives.bind(null, { setInitiatives, holdingId }), [])
  useEffect(loadFund.bind(null, { fundId, snapshotId, holdingId, setFund }), [])

  useEffect(() => {
    if (initiatives) {
      setOptions({
        environmental: [...initiatives.environmental, CUSTOM_OPTION],
        social: [...initiatives.social, CUSTOM_OPTION],
        governance: [...initiatives.governance, CUSTOM_OPTION],
        units: hedgeFundUnits,
      })
    }
  }, [initiatives])

  const onCreateDialogSuccess = () => {
    loadInitiatives({ setInitiatives, holdingId })
  }

  if (!fund) return null

  return (
    <StyledContainer>
      <Breadcrumb>
        <Breadcrumb.Item href='/app'>Home</Breadcrumb.Item>
        <Breadcrumb.Item
          href={`/app/portfolios/${fundId}/snapshots/${snapshotId}`}
        >
          {fund.portfolio_name}
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href={`/app/portfolios/${fundId}/snapshots/${snapshotId}/holdings/${holdingId}`}
        >
          {fund.name}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Risk Assessments</Breadcrumb.Item>
      </Breadcrumb>
      <HoldingHeader holding={fund} showRiskButton={false} />
      <StyledContainer>
        <Nav variant='pills' defaultActiveKey='esg_analysis'>
          <Nav.Item>
            <Nav.Link
              eventKey='esg_analysis'
              href={`/app/portfolios/${fundId}/snapshots/${snapshotId}/holdings/${holdingId}/risk_assessment/esg_analysis`}
            >
              ESG Analysis
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey='sasb_materiality'
              href={`/app/entities/${fund.entity_id}/sasb?portfolioId=${fundId}&portfolioName=${fund.portfolio_name}`}
            >
              SASB Materiality
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='custom-checklist' disabled>
              Custom Checklist
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='proxy-voting' disabled>
              Proxy Voting
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Div>
          <RiskAssessmentCard
            onCreateDialogSuccess={onCreateDialogSuccess}
            options={options.environmental}
            units={options.units}
            riskAssessment={fund.initiatives.environmental}
            category='environmental'
            title='ENVIRONMENTAL ISSUES'
            fundId={fundId}
            holdingId={holdingId}
          />

          <RiskAssessmentCard
            onCreateDialogSuccess={onCreateDialogSuccess}
            options={options.social}
            units={options.units}
            riskAssessment={fund.initiatives.social}
            category='social'
            title='SOCIAL ISSUES'
            fundId={fundId}
            holdingId={holdingId}
          />

          <RiskAssessmentCard
            onCreateDialogSuccess={onCreateDialogSuccess}
            options={options.governance}
            units={options.units}
            riskAssessment={fund.initiatives.governance}
            category='governance'
            title='GOVERNANCE ISSUES'
            fundId={fundId}
            holdingId={holdingId}
          />
        </Div>
      </StyledContainer>
    </StyledContainer>
  )
}

export default RiskAssessment
