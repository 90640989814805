import React, { useState } from 'react'
import styled from 'styled-components'

import Card from '../../../components/styleguide/Card'
import CardTitle from '../../../components/styleguide/CardTitle'
import MainRadioButton from '../../../components/styleguide/MainRadioButton'

const CardContainer = styled.div`
  grid-column: span 4;
`

const StyledRadio = styled.div`
  margin-top: 15px;
  label {
    color: #979797;
    margin-bottom: 0px;
  }

  .description {
    margin-top: -12px;
    margin-left: 35px;
    font-size: 12px;
    color: #979797;
  }
`

const ScoreRankingSettings = ({ setting, dispatch }) => {
  if (setting == null) setting = 'sector'

  const handleRadioChange = value => {
    dispatch({ type: 'ranking-setting-changed', payload: value })
  }

  return (
    <CardContainer>
      <Card>
        <CardTitle title='SCORE CONTEXT' />
        <StyledRadio>
          <MainRadioButton
            label={'Rank Companies by Universe'}
            setRadioButton={handleRadioChange}
            value={'universe'}
            checked={setting == 'universe'}
          />
          <div className='description'>
            The scores are contextualized relative to the firms in the entire YB
            universe irrespective of their industry group, size or jurisdiction.
          </div>
        </StyledRadio>
        <StyledRadio>
          <MainRadioButton
            label={'Rank Companies by Sector'}
            setRadioButton={handleRadioChange}
            value={'sector'}
            checked={setting == 'sector'}
          />
          <div className='description'>
            The scores are contextualized relative to the firms in the same
            industry group irrespective of their size or jurisdiction. This
            context assesses the firm relative to firms who produce similar
            products, have similar supply/value chains and face the similar
            scrutiny, transition, regulatory, and social risks.
          </div>
        </StyledRadio>
        <StyledRadio>
          <MainRadioButton
            label={'Rank Companies by Region'}
            setRadioButton={handleRadioChange}
            value={'jurisdiction'}
            checked={setting == 'jurisdiction'}
          />
          <div className='description'>
            The scores are contextualized relative to the firms in the same
            region irrespective of their industry group or size. This context
            assesses the firm relative to firms who are located in the same
            region and face similar geo-political and regulatory regimes.
          </div>
        </StyledRadio>
        <StyledRadio>
          <MainRadioButton
            label={'Rank Companies by Market Cap'}
            setRadioButton={handleRadioChange}
            value={'market_size'}
            checked={setting == 'market_size'}
          />
          <div className='description'>
            The scores are contextualized relative to the firms who have similar
            size market cap (mega cap, large cap, etc.) irrespective of their
            industry group or region they belong to. The regulatory bodies,
            politicians and public have similar expectations about their
            product, policies and processes.
          </div>
        </StyledRadio>
      </Card>
    </CardContainer>
  )
}

export default ScoreRankingSettings
