import propTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {
  APP_COLOR_PRIMARY,
  FONT_COLOR_PRIMARY,
  FONT_COLOR_SECONDARY,
} from '../../styles/colors'

const StyledLabel = styled.div`
  .chart-value {
    font-size: 1.63rem;
    font-weight: bold;
    color: ${props => props.textColor || props.color || FONT_COLOR_PRIMARY};

    .chart-unit {
      font-size: 1rem;
    }
  }
  .chart-legend {
    display: inline-block;
    margin-right: 0.63rem;
    height: 0.63rem;
    width: 0.63rem;
    border-radius: 50%;
    background-color: ${props => props.color || APP_COLOR_PRIMARY};
  }
  .chart-label {
    color: ${FONT_COLOR_SECONDARY};
  }
`

const ChartLabel = ({
  value,
  label,
  color,
  unit,
  textColor,
  showLegend = true,
  className,
}) => {
  return (
    <StyledLabel color={color} textColor={textColor} className={className}>
      <div>
        <span className='chart-value'>
          {value} <span className='chart-unit'>{unit}</span>
        </span>
        <br />
        {showLegend && (
          <>
            <div className='chart-legend' />
          </>
        )}
        <span className='chart-label'>{label}</span>
      </div>
    </StyledLabel>
  )
}

ChartLabel.propTypes = {
  value: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
  label: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
  textColor: propTypes.string,
  color: propTypes.string,
  unit: propTypes.string,
  showLegend: propTypes.bool,
  className: propTypes.string,
}

export default ChartLabel
