import React, { useState, useEffect } from "react";
import Link from "../../../../components/styleguide/Link";
import Table from "../../../../components/styleguide/Table";
import SectionWrapper from "../../../../components/styleguide/SectionWrapper";

import { getOtpBackupCodes, postOptBackupCodes, postOtpBackupCodesExport } from "../../../../repositories/otp_backup_codes_repository";

const OtpBackupCodesIndex = ({showBackToAccountSettingsLink}) => {
    const [loading, setLoading] = useState(true);
    const [backupCodes, setBackupCodes] = useState([]);
    const [lastGeneratedAt, setLastGeneratedAt] = useState(null);
    const [activeCount, setActiveCount] = useState(null);
    const [twoFactorNotEnabled, setTwoFactorNotEnabled] = useState(false);
    const [disableGenerateNewCodes, setDisableGenerateNewCodes] = useState(false);

    useEffect(() => {
        getOtpBackupCodes().then((response) => {
            setLoading(false);
            setBackupCodes(response.data.otp_backup_codes);
            setLastGeneratedAt(response.data.last_otp_backup_codes_generated_at);
            setActiveCount(response.data.active_otp_backup_code_count)
        }).catch((error) => {
            setLoading(false);

            const responseData = error.response.data;
            if (responseData.otp_secret && responseData.otp_secret.includes("cant_be_blank")) {
                setTwoFactorNotEnabled(true);
            }
        });
    }, []);

    const onPostOptBackupCodes = () => {
        setDisableGenerateNewCodes(true);

        postOptBackupCodes().then((response) => {
            setDisableGenerateNewCodes(false);
            setBackupCodes(response.data.otp_backup_codes);
            setLastGeneratedAt(response.data.last_otp_backup_codes_generated_at);
            setActiveCount(response.data.active_otp_backup_code_count)
        })
    };

    const onDownloadOptBackupCodes = () => {
        postOtpBackupCodesExport().then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'yb_otp_backup_codes.csv');
            document.body.appendChild(link);
            link.click();
        })
    };

    const onBackToAccountEdit = () => {
        window.location.href="/app/account/edit";
    };

    const getStyle = (backupCode) => {
        return backupCode.used_at ? { textDecorationLine: 'line-through' } : {};
    };

    const tableRows = () => {
        let rows = [];

        const half = backupCodes.length / 2;
        for (let i = 0; i < half; i++) {
            const ithBackupCode = backupCodes[i];
            const jthBackupCode = backupCodes[i + half];

            const row = [
                <SectionWrapper justify="space-between">
                    <SectionWrapper style={getStyle(ithBackupCode)}>
                        { ithBackupCode.decrypted_code }
                    </SectionWrapper>

                    <SectionWrapper style={getStyle(jthBackupCode)}>
                        { jthBackupCode.decrypted_code }
                    </SectionWrapper>
                </SectionWrapper>
            ]
            rows.push(row)
        }

        return rows;
    }

    const headers = [{label: "CODE"}];

    if (loading) {
        return <SectionWrapper>Loading...</SectionWrapper>
    } else if (twoFactorNotEnabled) {
        return <SectionWrapper>Two Factor Authentication (2FA) must be enabled to create OTP Backup Codes.  To enable 2FA, go to Account Settings. </SectionWrapper>
    } else {
        return(<>
            <>
                {
                    !loading &&
                    <>
                        {
                            showBackToAccountSettingsLink && <h3>2FA Sucessfully Turned On.</h3>
                        }
                        <p>Backup codes are one-time use codes that you should keep with you physically.  You can use
                            each code one time while logging in, if you are unable to access Google Authenticator.  If
                            you don't have access to Google Authenticator or a backup code, you will need to contact YvesBlue
                            support for recovering your account.</p>

                        <p>We recommend that you print these and store them in a safe place.</p>
                        <SectionWrapper align="center" justify="space-evenly">
                            <SectionWrapper width="50%">
                                <Table body={tableRows()} headers={headers} />
                            </SectionWrapper>

                            <div>
                                <p>These codes were generated on { lastGeneratedAt }.  </p>
                                <p>{ activeCount } out of 10 codes available.</p>

                                {
                                    disableGenerateNewCodes && <p>Loading...</p>
                                }

                                {
                                    !disableGenerateNewCodes && <p><Link onClick={onPostOptBackupCodes}>Generate new codes</Link></p>
                                }

                                <p><Link onClick={onDownloadOptBackupCodes}>Download codes</Link></p>
                            </div>

                            <SectionWrapper justify="flex-end" margin="80px 0 0 0" width="100%">
                                <Link onClick={onBackToAccountEdit}>Back to Account Settings</Link>
                            </SectionWrapper>
                        </SectionWrapper>

                    </>
                }
            </>
        </>)
    }
};

export default OtpBackupCodesIndex;
