import axios from 'axios';
import qs from 'query-string';

export const getHoldings = query => {
  return axios.get(`/app/api/instruments?${qs.stringify({ query })}`).then(res => res.data);
};

export const confirmHoldingKeyPerson = digest => {
  const url = `/key_people/${digest}/confirm_email`;
  return axios.post(url);
};

export const getHoldingKeyPeople = holdingID => {
  const url = `/app/api/holdings/${holdingID}/key_people`;
  return axios.get(url);
};

export const postHoldingKeyPeople = ({holdingID, keyPerson}) => {
  const url = `/app/api/holdings/${holdingID}/key_people`;
  return axios.post(url, { key_person: keyPerson } )
};

export const resendHoldingKeyPersonConfirmationEmail = ({ holdingID, keyPersonID }) => {
  const url = `/app/api/holdings/${holdingID}/key_people/${keyPersonID}/resend_confirmation_email`;
  return axios.post(url)
}

export const getHoldingFormPublications = holdingID => {
  const url = `/app/api/holdings/${holdingID}/form_publications`;
  return axios.get(url)
};

export const postHoldingFormPublications = (publication) => {
  const url = `/app/api/publications`;

  const params = {
    name: publication.name,
    holding_key_person_id: publication.holding_key_person_id,
    custom_report_form_id: publication.custom_report_form_id,
    expires_at: publication.expires_at,
  };

  return axios.post(url, params);
};
