export interface ITestingProps {
    'data-testid': string
    'data-cy': string
}

export default function getTestingProps(id: string): ITestingProps {
    return {
        ['data-testid']: id,
        ['data-cy']: id,
    }
}
