import React from 'react'
import { KeyValueCard } from '../../../../../../../../components/presentation/cards/KeyValueCard'
import { ArticleAlignment } from './components/ArticleAlignment'
import Controller from './SFDRDashbaord.controller'

interface IProps extends YB.IWithPortfolioIds {}

export const SFDRDashboard: React.FC<IProps> = ({
  portfolioId,
  snapshotId,
}) => {
  const [, , disclosuresRoute] = Controller.useSFDRRoutes(
    portfolioId,
    snapshotId
  )
  const alignment = Controller.useAlignmentData()
  const [mostAlignedCompanies, leastAlignedCompanies] =
    Controller.useCompanyAlignmentData()
  const { data: disclosureTimeline } = Controller.useDisclosureTimelineData(
    portfolioId,
    snapshotId
  )

  return (
    <div
      style={{
        display: 'grid',
        gap: '10px',
        gridTemplateColumns: '1fr 1fr',
      }}
    >
      {/* ICristi: Temporarily removed */}
      {/* <ArticleAlignment title='Article 8 Alignment' alignment={alignment}>
        <p>
          This section will populate once you have configured what article your
          fund is working to be in alignment with.
        </p>
        <p>
          Visit the <a href={fundRoute}>Fund Configuration</a> page to begin
          that process.
        </p>
      </ArticleAlignment>
      <KeyValueCard title='Most Aligned Companies' items={[]}>
        <p>
          This section will populate once you have begun updating your holdings'
          alignment.
        </p>
        <p>
          Visit the <a href={holdingRoute}>Holding Alignment</a> page to begin
          that process.
        </p>
      </KeyValueCard>
      <KeyValueCard
        title='Least Aligned Companies'
        items={mostAlignedCompanies}
      >
        <p>
          This section will populate once you have begun updating your holdings'
          alignment.
        </p>
        <p>
          Visit the <a href={holdingRoute}>Holding Alignment</a> page to begin
          that process.
        </p>
      </KeyValueCard> */}
      <KeyValueCard
        title='Disclosure Publishing Timeline'
        items={disclosureTimeline}
      >
        <p>
          This section will populate once you have created your first
          disclosure.
        </p>
        <p>
          Visit the <a href={disclosuresRoute}>Disclosures</a> page to begin
          that process.
        </p>
      </KeyValueCard>
    </div>
  )
}
