import React, { useState } from 'react';
import styled from 'styled-components';
import PasswordVerificationForm from "../../components/forms/PasswordVerificationForm";
import OrganizationDetailsCard from "./components/OrganizationDetailsCard";
import OrganizationBrandingLogosCard from './components/OrganizationBrandingLogosCard';
import OrganizationAPIKeysCard from "./components/OrganizationAPIKeysCard";
import Card from "../../components/styleguide/Card";
import StyledContainer from "./components/StyledContainer";
import { StyledCard } from '../../components/styleguide/Card.styles';

const OrganizationContainer = styled(StyledContainer)`
  ${StyledCard}:not(:last-child) {
    margin-bottom: 20px;
  }
`

const OrganizationIndex = ({organization}) => {
  const [authenticated, setAuthenticated] = useState(false);

  return(
    <OrganizationContainer>
      <h1>Organization Settings</h1>
      { !authenticated ? (
        <Card>
          <PasswordVerificationForm onVerify={() => setAuthenticated(true)}/>
        </Card>
      ) : (
        <>
          <OrganizationDetailsCard organization={organization}/>
          { organization.can_access_cobranded_reports && <OrganizationBrandingLogosCard/> }
          <OrganizationAPIKeysCard/>
        </>
      )}
    </OrganizationContainer>
  )
};

export default OrganizationIndex;
