import React from 'react'
import PropTypes from 'prop-types';


const XAxisLabels = ({values, classes, linedist, offset, ystart, xscale, rotate = 0  }) => {

    const xAxisLabels = () => {

        return (
            <>
            {   
                values.map((v, i) => 
                    <text
                    key={i}
                    className={classes} 
                    transform={`translate(${xscale(i) + (i*linedist) + offset}, ${ystart}) rotate(${rotate})`}
                    >
                        {v}
                    </text>
                )
            }
            </>
        )
    }



    return (
        <>  
            {xAxisLabels()}
        </>
    )
}

XAxisLabels.propTypes = {
    values: PropTypes.array,
    classes: PropTypes.string,
    linedist : PropTypes.number,
    offset : PropTypes.number,
    ystart : PropTypes.number,
    xscale : PropTypes.func,

}



export default XAxisLabels;

