import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import SDGIcon from '../../../../../components/styleguide/SDGIcon'

const StyledDataList = styled.dl`
  margin: 0 20px 20px 0;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 50px 50px 50px;
  grid-column-gap: 10px;

  dt {
    margin: 0 0 6px;
    grid-column: 1 / 4;
  }
`

export const SdgList = ({ sdgs }) => {
  return (
    <StyledDataList>
      <dt>SDG Tags:</dt>
      {sdgs.map(el => (
        <dd className='sdg-box' key={el}>
          <SDGIcon className='sdg-box' sdg={el} width={50} height={50} />
        </dd>
      ))}
    </StyledDataList>
  )
}

SdgList.propTypes = {
  sdgs: PropTypes.array.isRequired,
}
