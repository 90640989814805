import React, { useRef } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'

import {
  ExplainerLink,
  LinkIcon,
} from '../../../yb_components/styleguide/links/Link.styles'
import CardTitle from '../CardTitle'
import BadgeComponent from './BadgeComponent'
import { StyledBadges } from './badges-style'

const StyledExplainerLink = styled(ExplainerLink)`
  float: right;
  margin-bottom: 10px;
`

Object.defineProperty(Array.prototype, 'chunk', {
  value: function (chunkSize) {
    var R = []
    for (var i = 0; i < this.length; i += chunkSize)
      R.push(this.slice(i, i + chunkSize))
    return R
  },
})

// const popover = (props, badge) => {

//   return (
//     <StyledContainer id="popover-basic" {...props}>
//       <StyledTitle bsPrefix="badge-details-header" as="h6">{title[badge]}</StyledTitle>
//       <StyledContent >
//         {description[badge]}
//       </StyledContent>
//     </StyledContainer>
//   )
// };

const Badges = ({ badges, title, holdingsTotal, snapshotId }) => {
  let sliderRef = useRef()

  if (badges.length == 0) {
    return <div></div>
  }

  let settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
  }

  return (
    <StyledBadges padding={20}>
      <CardTitle title={title} />
      <div className='float-right right-subheader'>
        <div>
          {badges.length > 3 && (
            <>
              <i
                className='fas fa-angle-left font-size-25 arrow'
                onClick={() => sliderRef.slickPrev()}
              ></i>
              <i
                className='fas fa-angle-right font-size-25 arrow'
                onClick={() => sliderRef.slickNext()}
              ></i>
            </>
          )}
        </div>
        <div>
          <StyledExplainerLink
            href={
              title.includes('POSITIVE')
                ? 'https://help.yves.blue/en/articles/6173018-positive-impact-badges'
                : 'https://help.yves.blue/en/articles/6202789-negative-impact-badges'
            }
            target='_blank'
          >
            <LinkIcon className='far fa-question-circle' />
          </StyledExplainerLink>
        </div>
      </div>
      <div className={'badges-container'}>
        <div className='slider-container'>
          <Slider ref={slider => (sliderRef = slider)} {...settings}>
            {badges.chunk(3).map((badgeSet, index) => (
              <div key={`badges-${badgeSet}-${index}`}>
                <ul>
                  {badgeSet.map((badge, index) => (
                    <BadgeComponent
                      key={`badges-${index}`}
                      badge={badge}
                      holdingsTotal={holdingsTotal}
                      snapshotId={snapshotId}
                    />
                  ))}
                </ul>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </StyledBadges>
  )
}

export default Badges
