import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { TcfdLegend } from '../../../../../../modules/portfolio/tcfd/components/legend/TcfdLegend'
import { Tile } from '../../../../../../modules/portfolio/tcfd/components/tiles/Tile'
import { getScoreText } from '../../../../../../modules/portfolio/tcfd/utils/scores_display'
import {
  getTcfdAccentColor,
  getTcfdColor,
} from '../../../../../../modules/portfolio/tcfd/utils/tcdf_colors'
import { FONT_COLOR_SECONDARY } from '../../../../../../styles/colors'
import {
  AlignCenter,
  AlignRight,
  FullSize,
  HLine,
} from '../../../../../yb_styles/layout'
import TcfdPeerTable from './TcfdPeerTable'

const StyledScoreDisplay = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: inherit;

  .value-container {
    width: 96px;
    .value {
      width: 96px;
      display: inline-block;
      text-align: center;
      font-weight: 700;
      font-size: 36px;
      line-height: 43px;
    }
  }
  p {
    margin: 0;
    font-size: 15px;
    line-height: 18px;
  }
`

const StyledDescContainer = styled.div`
  display: block;
  margin-bottom: 18px;
`

const StyledDescText = styled.div`
  color: ${FONT_COLOR_SECONDARY};
  font-weight: ${props => (props.title ? '700' : 'normal')};
  margin: ${props => props.margin || '0'};
`

const StyledTileContainer = styled.div`
  ${FullSize}
  ${AlignCenter}
  margin-bottom: 19px;
`

const StyledTile = styled(Tile)`
  display: block;
  width: 475px;
  margin: ${props => props.margin || '0'};
`

const StyledPeersContainer = styled.div`
  padding: 0 55px 0 55px;
  margin-bottom: 28px;
`

const StyledTcfdLegendContainer = styled.div`
  ${AlignRight}
`

const TcfdHoldingDetailListItem = ({ item, peerData }) => {
  const currentCompany = _.first(peerData)?.[item?.key]

  const score = isNaN(parseInt(currentCompany?.score))
    ? currentCompany?.score
    : parseInt(currentCompany?.score)

  const sectorScore = isNaN(parseInt(currentCompany?.sector_score))
    ? currentCompany?.sector_score
    : parseInt(currentCompany?.sector_score)

  const renderLegend = () => (
    <StyledTcfdLegendContainer>
      <TcfdLegend />
    </StyledTcfdLegendContainer>
  )

  return (
    <>
      <div>
        <HLine />
        <StyledDescContainer>
          <StyledDescText title margin={'0 0 3px 0'}>
            {item?.name}
          </StyledDescText>
          <StyledDescText>{item?.description}</StyledDescText>
        </StyledDescContainer>
        <StyledTileContainer>
          <StyledTile
            bgColor={getTcfdColor(score)}
            color={getTcfdAccentColor(score)}
          >
            <h5>Company Score</h5>
            <StyledScoreDisplay>
              <div className='value-container'>
                <span className='value'>{score ?? '—'}</span>
              </div>
              <p>{item.scoreText[parseInt(score)]}</p>
            </StyledScoreDisplay>
          </StyledTile>
          <StyledTile
            bgColor={getTcfdColor(sectorScore)}
            color={getTcfdAccentColor(sectorScore)}
            margin={'0 0 0 10px'}
          >
            <h5>Sector Score</h5>
            <StyledScoreDisplay>
              <div className='value-container'>
                <span className='value'>{sectorScore ?? '—'}</span>
              </div>
              <p>{item.scoreText[parseInt(sectorScore)]}</p>
            </StyledScoreDisplay>
          </StyledTile>
        </StyledTileContainer>

        <StyledPeersContainer>
          <TcfdPeerTable item={item} data={peerData} />
        </StyledPeersContainer>
      </div>
      {item?.newPage && (
        <>
          {renderLegend()}

          <div style={{ pageBreakBefore: 'always' }} />
        </>
      )}
      {item?.hasLegend && renderLegend()}
    </>
  )
}

TcfdHoldingDetailListItem.propTypes = {
  item: PropTypes.object.isRequired,
  peerData: PropTypes.array.isRequired,
}

export default TcfdHoldingDetailListItem
