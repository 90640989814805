// https://docs.google.com/spreadsheets/d/17s0WLFsJJlz_9u5N69Zu_kdlKruYd3nHvhIrDgbkcSM/edit#gid=0

const eventActionTypesByResource = {
    user: [
        {
            action_type: 'login',
            target_type: 'User',
            id: 'login-user',
            label: 'User Logins',
        },

        {
            action_type: 'create',
            target_type: 'User',
            id: 'create-user',
            label: 'User Created',
        },

        {
            action_type: 'destroy',
            target_type: 'user',
            id: 'destroy-user',
            label: 'User Deleted',
        }
    ],

    focus_area: [
        {
            action_type: 'create',
            target_type: 'FocusArea',
            id: 'create-focus_area',
            label: 'Focus Area Created'
        },

        {
            action_type: 'update',
            target_type: 'FocusArea',
            id: 'update-focus_area',
            label: 'Focus Area Updated'
        },

        {
            action_type: 'destroy',
            target_type: 'FocusArea',
            id: 'destroy-focus_area',
            label: 'Focus Area Deleted'
        },

        {
            action_type: 'enable',
            target_type: 'FocusArea',
            id: 'enable-focus_area',
            label: 'Focus Area Enabled',
        },

        {
            action_type: 'disable',
            target_type: 'FocusArea',
            id: 'disable-focus_area',
            label: 'Focus Area Disabled',
        },

        {
            action_type: 'create_note',
            target_type: 'FocusArea',
            id: 'create-note-focus_area',
            label: 'Focus Area Note Created',
        },
    ],

    holding: [
        {
            action_type: 'update',
            target_type: 'Holding',
            id: 'update-holding',
            label: 'Holding Updated'
        },

        {
            action_type: 'destroy',
            target_type: 'Holding',
            id: 'destroy-holding',
            label: 'Holding Deleted'
        },
    ],

    portfolio: [
        {
            action_type: 'create',
            target_type: 'Portfolio',
            id: 'create-portfolio',
            label: 'Portfolio Created',
        },
        {
            action_type: 'destroy',
            target_type: 'Portfolio',
            id: 'destroy-portfolio',
            label: 'Portfolio Deleted',
        },
        {
            action_type: 'download_pdf',
            target_type: 'Portfolio::Snapshot',
            id: 'download_pdf-portfolio-snapshot',
            label: 'Portfolio PDF Downloaded',
        },
    ]
};

export const OrganizationEventActionTypes = [
    {
        id: 'all',
        label: 'All Events',
    },

    eventActionTypesByResource.user,
    eventActionTypesByResource.focus_area,
    eventActionTypesByResource.holding,
    eventActionTypesByResource.portfolio
].flat();

export const PortfolioEventActionTypes = [
    {
        id: 'all',
        label: 'All Events',
    },

    eventActionTypesByResource.focus_area,
    eventActionTypesByResource.holding,
    eventActionTypesByResource.portfolio
].flat();
