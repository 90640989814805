import { useEffect, useState } from 'react'

/**
 * Hook used to debounce an input for api call
 *
 * @param {String} defaultQuery The default search query value
 * @param {function} onSearch The callback on search change
 * @param {number} timeout The timeout for the debouncing
 * @returns Getter / Setter for the debounced query
 */
const useTimerDebouncing = (
  defaultQuery = '',
  onSearch = null,
  timeout = 500
) => {
  const [query, setQuery] = useState(defaultQuery)
  const [debouncedQuery, setDebouncedQuery] = useState(query)

  useEffect(() => {
    const timer = setTimeout(() => setQuery(debouncedQuery), timeout)
    return () => clearTimeout(timer)
  }, [debouncedQuery])

  useEffect(() => {
    onSearch?.(query)
  }, [query])

  return [debouncedQuery, setDebouncedQuery]
}

export default useTimerDebouncing
