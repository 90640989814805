import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { FONT_COLOR_SECONDARY } from '../../../../../../../styles/colors'
import Box from '../../../../../../../yb_components/styleguide/containers/Box'
import { StyledTable } from '../styles/PaiIndicatorsTable.styles'
import { DataRow } from './DataRow'

const StyledCenteredDiv = styled.div`
  margin-bottom: 30px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SyledNoDataFound = styled.div`
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: 16px;
  color: ${FONT_COLOR_SECONDARY};
  text-align: center;
`

export const PaiIndicatorsTable = ({
  launchExplanationModal,
  setIndicatorChanges,
  impactYears,
  noDataText,
  data = [],
  explanationData = {},
}) => {
  const hasData = useMemo(() => !!data.length, [data])

  return (
    <>
      <StyledTable>
        <thead>
          <tr>
            <th>PAI Portfolio-level Report</th>
            <th>PAI Table</th>
            <th>Unit</th>
            <th>{impactYears.current} Impact</th>
            <th>{impactYears.previous} Impact</th>
            <th>Completion</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map(group => (
            <React.Fragment key={group.category_group_name}>
              <tr>
                <td colSpan='7' className='group'>
                  {group.category_group_name}
                </td>
              </tr>
              {group.category_group_data.map(cat => (
                <React.Fragment key={cat.category_name}>
                  <tr>
                    <td colSpan='7' className='category'>
                      {cat.category_name}
                    </td>
                  </tr>
                  {cat.category_data.map((indicator, index) => (
                    <DataRow
                      key={indicator.indicator_id}
                      index={index + 1}
                      row={indicator}
                      explanationData={explanationData}
                      launchExplanationModal={launchExplanationModal}
                      setIndicatorChanges={setIndicatorChanges}
                    />
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </StyledTable>
      <Box isPresent={!hasData}>
        <StyledCenteredDiv>
          <SyledNoDataFound>
            {noDataText || (
              <>
                <p>No items were found for your search query.</p>
                <p>Please try again.</p>
              </>
            )}
          </SyledNoDataFound>
        </StyledCenteredDiv>
      </Box>
    </>
  )
}

PaiIndicatorsTable.propTypes = {
  launchExplanationModal: PropTypes.func.isRequired,
  setIndicatorChanges: PropTypes.func.isRequired,
  impactYears: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  explanationData: PropTypes.object.isRequired,
  noDataText: PropTypes.string,
}
