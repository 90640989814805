import { useMemo } from 'react'

const useShouldDisplayInputFeedback = (
    feedbackMessage: string,
    isPristine = false,
    disabled = false
) =>
    useMemo(
        () => (!isPristine || disabled) && !!feedbackMessage,
        [isPristine, feedbackMessage, disabled]
    )

export default useShouldDisplayInputFeedback
