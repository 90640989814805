import FormService from '@services/Form'
import { DatePickerControl } from '@ui/components/interactive/DatePicker'
import { FormActions } from '@ui/components/interactive/FormActions'
import { TextAreaControl } from '@ui/components/interactive/TextArea'
import { URLInputControl } from '@ui/components/interactive/URLInput'
import { AlertCard } from '@ui/components/presentation/cards/AlertCard'
import { Modal } from '@ui/components/presentation/modals/Modal'
import { Box } from '@ui/elements/grouping/Box'
import moment from 'moment'
import React, { useMemo } from 'react'

import { IDisclosure } from '../../Disclosures.controller'
// import theme from './PublishDisclosureModal.module.scss'
import './PublishDisclosureModal.module.scss'

interface IProps {
  showModel: YB.TModel<boolean>
  onSubmit: (data: IPublishDisclosureData) => void
  disclosure: IDisclosure | null
}

export interface IPublishDisclosureData {
  publish_date: string
  report_url: string
  additional_info: string
}

const Form = new FormService<IPublishDisclosureData>()

export const PublishDisclosureModal: React.FC<IProps> = ({
  disclosure,
  showModel,
  onSubmit,
}) => {
  const today = useMemo(
    () => moment(new Date().toString()).format('YYYY-MM-D'),
    []
  )

  const [isPresent, setIsPresent] = showModel
  const [asOfDate, asOfDateFeedback] = Form.useInputModel<string>(today || '')
  const [reportUrl, reportUrlFeedback] = Form.useInputModel<string>(
    disclosure?.report_url || ''
  )
  const [additionalInformation, additionalInformationFeedback] =
    Form.useInputModel<string>(disclosure?.additional_info || '')
  const [showSubmitCard, setShowSubmitCard] = React.useState<boolean>(false)

  const formModel = {
    publish_date: asOfDate,
    report_url: reportUrl,
    additional_info: additionalInformation,
  }

  const formData = Form.useFormData(formModel)

  const isFormValid = Form.useFormValidity([
    asOfDateFeedback,
    reportUrlFeedback,
    additionalInformationFeedback,
  ])

  const [formDataSnapshot] = Form.useFormDataSnapshot(formData)

  /* Events */
  const handleHide = () => {
    setIsPresent(false)
    setShowSubmitCard(false)
    Form.setFormModel(formModel, formDataSnapshot)
  }

  const handleSubmit = () => onSubmit?.(formData)
  const showAlertCard = () => setShowSubmitCard(true)

  return (
    <Modal
      show={isPresent}
      title='Publish disclosure'
      onHide={handleHide}
      className='PublishDisclosureModal'
    >
      <p className='explanation'>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum in
        suscipit sem. Mauris molestie pulvinar faucibus. Phasellus rutrum
        lobortis est et pretium.
      </p>
      <div className='disclosureInfoWrapper'>
        <div className='formControl'>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>Disclosure name</label>
          <span>{disclosure?.description || '–'}</span>
        </div>
        <DatePickerControl
          label='As of date'
          name='asOfDate'
          model={asOfDate}
          feedbackModel={asOfDateFeedback}
          className='formControl'
        />
      </div>
      <TextAreaControl
        label='Additional information'
        name='additionalInformation'
        model={additionalInformation}
        feedbackModel={additionalInformationFeedback}
        rows={5}
      />
      <URLInputControl
        label='URL where Report Will be available'
        name='reportUrl'
        model={reportUrl}
        feedbackModel={reportUrlFeedback}
      />

      <Box isPresent={showSubmitCard}>
        <AlertCard
          title='Are you sure you want to publish your disclosure?'
          type='warning'
          layout='horizontal'
          className='publishCard'
        >
          <p>
            The disclosure you’re attempting to publish still has missing gaps.
            Before you publish the disclosure you should all missing data and
            gaps are filled.
          </p>
          <FormActions
            submitLabel='Publish disclosure'
            onCancel={handleHide}
            onSubmit={handleSubmit}
            canSubmit={isFormValid}
          />
        </AlertCard>
      </Box>
      <Box isPresent={!showSubmitCard}>
        <FormActions
          submitLabel='Publish disclosure'
          onCancel={handleHide}
          onSubmit={showAlertCard}
          canSubmit={isFormValid}
        />
      </Box>
    </Modal>
  )
}
