export const FILTERS = [
  {
    label: 'environmentally_sustainable',
    values: [
      'environmental_objective',
      'dnsh_evaluation',
      'good_governance',
      'environmentally_sustainable',
    ],
  },
  {
    label: 'good_governance',
    values: [
      'women_board',
      'board_independence',
      'average_salary',
      'average_training',
      'salary_gap',
      'good_governance',
    ],
  },
  {
    label: 'oecd_aligned',
    values: [
      'no_human_rights_legal_proceedings',
      'no_employee_safety_legal_proceedings',
      'no_supply_chain_legal_proceedings',
      'has_programs_reduce_safety_incidents',
      'performs_safety_training',
      'has_supplier_code_of_conduct',
      'oecd_aligned',
    ],
  },
  {
    label: 'socially_sustainable',
    values: [
      'social_objective',
      'dnsh_evaluation',
      'good_governance',
      'socially_sustainable',
    ],
  },
  {
    label: 'sustainable_investment',
    values: [
      'environmental_objective',
      'social_objective',
      'dnsh_evaluation',
      'good_governance',
      'sustainable_investment',
    ],
  },
  {
    label: 'taxonomy_aligned',
    values: [
      'level_of_alignment',
      'tsc_evaluation',
      'dnsh_evaluation',
      'min_safeguards_evaluation',
      'taxonomy_aligned',
    ],
  },
  {
    label: 'taxonomy_aligned_fossil_gas',
    values: [
      'fossil_gas',
      'level_of_alignment',
      'tsc_evaluation',
      'dnsh_evaluation',
      'min_safeguards_evaluation',
      'taxonomy_aligned_fossil_gas',
    ],
  },
  {
    label: 'taxonomy_aligned_nuclear',
    values: [
      'nuclear',
      'level_of_alignment',
      'tsc_evaluation',
      'dnsh_evaluation',
      'min_safeguards_evaluation',
      'taxonomy_aligned_nuclear',
    ],
  },
]
