import React, { memo } from 'react';
import { StyledTable, StyledTr, StyledTrHeader, StyledTh, StyledTd, StyledCell, StyledDataCell, StyledHeaderCell, StyledRowHeaderCell } from './LongitudinalTable.styles';
import { generateKeyFromProperties } from '../../../helpers/list_keys';

/* COMPONENT */
const LongitudinalTable = ({
  columnHeaders,
  rowHeaders,
  data,
  indexBy,
  displayColumnHeader = true,
  displayRowHeader = true,
  dataFormatter = null,

  /* Custom components */
  rowHeaderComponent = null,
  'data-cy': dataCy
}) => {

  if (!columnHeaders || !rowHeaders || !data || !indexBy) return null;
  return (
    <StyledTable data-cy={dataCy}>

      {/* Table Header */}
      {displayColumnHeader && (
        <thead>
          <StyledTrHeader data-cy={`longitudinal_table_header_tr`}>

            {/* First empty column element */}
            <StyledTh width={'100px'} />

            {/* Top Header */}
            {columnHeaders.map((columnHeader, columnHeaderIndex) => (
              <StyledTh key={columnHeader}>
                <StyledHeaderCell data-cy={`longitudinal_table_header_${columnHeaderIndex}`}>{columnHeader}</StyledHeaderCell>
              </StyledTh>)
            )}
          </StyledTrHeader>
        </thead>
      )}

      <tbody>
        {/* Left Header */}
        {displayRowHeader && (
          <StyledTr data-cy={`longitudinal_table_row_header_tr`} width={'100px'}>
            {rowHeaders.map((rowHeader, rowHeaderIndex) => {
              return (
                <StyledTd key={rowHeader.key}>
                  {/* Render custom component if specified */}
                  {rowHeaderComponent ? (
                    <StyledCell data-cy={`longitudinal_table_row_header_${rowHeaderIndex}`} justifyContent={'start'}>
                      {rowHeaderComponent(rowHeader)}
                    </StyledCell>
                  ) : (
                    <StyledRowHeaderCell data-cy={`longitudinal_table_row_header_${rowHeaderIndex}`}>{rowHeader.label}</StyledRowHeaderCell>
                  )}
                </StyledTd>)
            })}
          </StyledTr>
        )}

        {/* Iterate through each Column element */}
        {data.map((elem, columnIndex) => {
          const key = generateKeyFromProperties(elem);

          return (
            <StyledTr data-cy={`longitudinal_table_tr_${columnIndex}`} key={key}>
              {rowHeaders.map((rowHeader, rowIndex) => {
                let label = '—';
                if (elem[rowHeader.key])
                  label = dataFormatter ? dataFormatter(elem[rowHeader.key]) : elem[rowHeader.key].label;

                return (
                  <StyledTd key={rowHeader.key}>
                    <StyledDataCell data-cy={`longitudinal_table_cell_${columnIndex}_${rowIndex}`}>{label}</StyledDataCell>
                  </StyledTd>
                )
              })}
            </StyledTr>
          )
        })}
      </tbody>

    </StyledTable>
  );
}

export default memo(LongitudinalTable);
