import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _find from 'lodash.find';
import _isEmpty from 'lodash.isempty';

import BaseDialog from './BaseDialog';
import TextView from '../styleguide/TextView';
import SelectInput from '../styleguide/SelectInput';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextArea from '../styleguide/TextArea';
import InputLabel from '../styleguide/InputLabel';
import SwitchButton from '../styleguide/SwitchButton';
import Button from '../styleguide/Button';
import Span from '../styleguide/Span';
import { BG_COLOR_SECONDARY, APP_COLOR_PRIMARY, FONT_COLOR_PRIMARY } from '../../styles/colors';
import { createFocusAreaNote } from '../../repositories/risk_assessments_repository';
import { mapStateToRiskAssessment } from '../../mappers/risk_assessment_mapper';
import DialogControls from './DialogControls';

const DialogContent = styled.div`
  .create-initiative-initiative-select {
    color: ${APP_COLOR_PRIMARY};
    font-weight: bold;
    option {
      color: ${FONT_COLOR_PRIMARY};
    }
  }
  .create-initiative-publish-form-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .create-initiative-publish-label {
      margin-bottom: 0;
    }
  }
  .create-initiative-current-unit-input-group {
    display: flex;
    align-items: flex-end;
    > * {
      width: 100%;
    }
  }
  .create-initiative-form-controls {
    margin-top:
  }
`;

const AddFocusAreaNoteDialog = ({ open, holdingId, focusAreaId, onClose, onSuccess }) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({
    note: "",
  });

  useEffect(() => {
    setValues({});
  }, [open]);

  const onInputChange = (id, value) => {
    const newValues = { ...values };
    let newErrors = { ...errors };

    newValues[id] = value;
    newErrors[id] = "";

    setValues(newValues);
    setErrors(newErrors);
  }

  const onFormSubmit = e => {
    e.preventDefault();

    const validation = {};

    if(!values.note) validation.note = "Note is required";

    if(_isEmpty(validation)) {
      createFocusAreaNote(holdingId, focusAreaId, values.note)
        .then(res => {
          onSuccess(res.data);
          onClose();
        });

    } else {
      setErrors(validation);
    }
  }

  const onCloseDialog = () => {
    setErrors({});
    onClose();
  }

  return (
    <BaseDialog padding={25} title="Add Note" open={open} onClose={onCloseDialog} render={() => (
      <DialogContent>
        <form onSubmit={onFormSubmit}>
          <Row>
            <Col xs={12}>
              <TextArea
                onChange={onInputChange}
                value={values.note}
                error={errors.note}
                id="note"
                label="Note" placeholder="Enter your note content here"
              />
            </Col>
          </Row>

          <DialogControls onClose={onClose} />
        </form>
      </DialogContent>
    )} />
  );
}
export default AddFocusAreaNoteDialog;
