import {defaultCountryCode} from "../../../constants/funds";

export class Holding {
    constructor(params) {
        this.id = params.id || "";
        this.fund_id = params.fund_id || "";
        this.name = params.name || "";
        this.pipeline_state = params.pipeline_state || "";
        this.vertical = params.vertical || "";
        this.industry = params.industry || "";
        this.primary_market = params.primary_market || defaultCountryCode.label;
        this.value = params.value || 0;
        this.weight = params.weight;
        this.initial_form_submissions = params.initial_form_submissions || {forms:[]}
        this.reports = params.reports || [{}];
        this.key_people = params.key_people || [{}];
        this.forms = params.forms || [{}];
        this.current_app_user_can_update = params.current_app_user_can_update || false;
        this.description = params.description || "";
        this.headquarters_address = params.headquarters_address || "";
    }

    // INSTANCE METHODS

    confirmedKeyPeople() {
        return this.key_people.filter((keyPerson) => {
            return keyPerson.confirmed_at;
        });
    }

    formsWithItems() {
        return this.forms.filter((form) => {
            return form.items.length > 0;
        })
    }

    hasIFCForm() {
        return this.forms.map((form) => form.key ).includes("ifc_principle")
    }

    toJSON(){
        return {
            name: this.name,
            pipeline_state: this.pipeline_state,
            value: this.value,
            weight: this.weight,
            description: this.description,
            geography: {
                primary_market: this.primary_market
            },
            configuration: {
                headquarters_address: this.headquarters_address
            },
            classification: {
                vertical: this.vertical,
                industry: this.industry
            },
        }
    };

    // SETTERS
    setId(newValue) {
        this.id = newValue;
    }

    setHeadquartersAddress(newValue) {
        this.headquarters_address = newValue;
    }

    setValue(newValue) {
        this.value = newValue;
    }

    setFundId(newValue) {
        this.fund_id = newValue;
    }

    setName(newValue) {
        this.name = newValue;
    }

    setPipelineState(newValue) {
        this.pipeline_state = newValue;
    }

    setPrimaryMarket(newValue) {
        this.primary_market = newValue;
    }

    setIndustry(newValue) {
        this.industry = newValue;
    }

    setVertical(newValue) {
        this.vertical = newValue;
    }

    setInvestmentAmount(newValue){
        this.investment_amount = newValue;
    }

    setDescription(newValue){
        this.description = newValue;
    }

    setWeight(newValue){
        this.weight = newValue;
    }

    setInitialFormSubmission(key, newValue){
        this.initial_form_submissions[key] = newValue;
    }

    setLastUpdated(updateHash){
       this.last_updated = updateHash
    }

    setKeyPeople(newValue) {
        this.key_people = newValue;
    }

    setForms(newValue) {
        this.forms = newValue;
    }

    convertFromJSON(holdingJSON){
        this.setIndustry(holdingJSON.classification.industry);
        this.setVertical(holdingJSON.classification.vertical);
        this.setPrimaryMarket(holdingJSON.geography.primary_market)
    }

    createDetailsHashMap(){
        return [
            {key:"Name", value:this.name},
            {key:"Impact Theme", value:this.impact_theme},
            {key:"Sector", value: this.industry},
            {key:"Investment Size", value: isNaN(this.value) ? "-" : this.value},
            {key: "UN SDG Alignment", value: this.un_sdg_alignment},
        ]
    }
}
