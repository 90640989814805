import React from 'react'
import injectSheet from 'react-jss'

import cns from '../../helpers/classnames'
import { getProgressColor } from '../../helpers/esg_scale'
import { FONT_COLOR_SECONDARY } from '../../styles/colors'
import ESGCircularScore from './ESGCircularScore'
import HorizontalScore from './HorizontalScore'

const ESGOverallScore = ({
  scores = {},
  benchmarkScores = {},
  benchmarkName,
  classes,
  className,
  customESGLabels,
}) => {
  const benchmarkDifference = scores.total_score - benchmarkScores.total_score

  return (
    <div className={cns(classes.scores, className)}>
      <div className={classes.overall}>
        <span className={classes.overallValue}>{scores.total_score}</span>
        <div className={classes.overallScore}>
          <HorizontalScore
            score={scores.total_score}
            color={getProgressColor(scores.total_score, customESGLabels)}
          />
          {benchmarkScores.total_score && (
            <div
              style={{ color: benchmarkDifference > 0 ? '#00D679' : '#D02F44' }}
              className='text-left'
            >
              {`vs. ${benchmarkScores.total_score} for ${benchmarkName}`}
            </div>
          )}
        </div>
      </div>

      <div className={classes.circular}>
        <ESGCircularScore
          label='ENVIRONMENTAL'
          progress={scores.e_score}
          benchmarkName={benchmarkName}
          benchmarkScore={benchmarkScores.e_score}
          customESGLabels={customESGLabels}
        />
        <ESGCircularScore
          label='SOCIAL'
          progress={scores.s_score}
          benchmarkName={benchmarkName}
          benchmarkScore={benchmarkScores.s_score}
          customESGLabels={customESGLabels}
        />
        <ESGCircularScore
          label='GOVERNANCE'
          progress={scores.g_score}
          benchmarkName={benchmarkName}
          benchmarkScore={benchmarkScores.g_score}
          customESGLabels={customESGLabels}
        />
      </div>
    </div>
  )
}

const style = {
  scores: {
    textAlign: 'center',
    width: props => (props.full ? '100%' : ''),
  },
  circular: {
    marginTop: '2rem',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-around',
    '& > :nth-child(2)': {
      margin: '0 1.25rem',
    },
  },
  overall: {
    display: 'flex',
    flex: 1,
  },
  overallValue: {
    verticalAlign: 'top',
    display: 'inline-block',
    fontSize: '2.25rem',
    letterSpacing: -0.5,
    paddingRight: '0.94rem',
  },
  overallScore: {
    display: 'inline-block',
    flexGrow: 1,
  },
  delta: {
    color: FONT_COLOR_SECONDARY,
    fontWeight: 'bold',
    fontSize: '0.88rem',
  },
}

export default injectSheet(style)(ESGOverallScore)
