import axios from 'axios'

export const getEntityById = id => {
  return axios.get(`/app/api/entities/${id}`).then(res => res.data)
}

export const getEntities = query => {
  const encodedQuery = encodeURI(query)
  return axios.get(`/app/api/entities?${encodedQuery}`).then(res => res.data)
}
