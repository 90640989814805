import React, { useMemo } from 'react'
import styled from 'styled-components'

import Link from '../../../../components/styleguide/Link'
import { addThousandSeparator } from '../../../../helpers/number'
import { FONT_COLOR_TERTIARY } from '../../../../styles/colors'
import {
  CarbonAuditTabs,
  CarbonAuditTarget,
  TableHeaders,
} from '../CarbonAuditCard.constants'
import { formatCellValue, renderBudgetIcon } from '../CarbonAuditCard.helpers'
import Label from '../components/carbon_audit_table/label/Label'

const StyledSectorSubText = styled.p`
  margin: 0px;
  font-size: 12px;
  color: ${FONT_COLOR_TERTIARY};
  font-weight: 700;
  font-style: italic;
`

export const useCarbonAuditTable = ({
  selectedChart,
  performers,
  colors,
  data,
  targetName,
  includeBenchmark,
}) => {
  /* Table Header */
  const getTableHeaders = targetName => {
    if (selectedChart === CarbonAuditTabs.SCOPE) {
      if (targetName === CarbonAuditTarget.INSTRUMENT)
        return TableHeaders(includeBenchmark).SCOPE_INSTRUMENT
      if (targetName === CarbonAuditTarget.PORTFOLIO)
        return TableHeaders(includeBenchmark).SCOPE_PORTFOLIO
      return 'Target'
    } else if (selectedChart === CarbonAuditTabs.HOLDING) {
      return TableHeaders(includeBenchmark).HOLDINGS
    } else {
      return TableHeaders(includeBenchmark).SECTOR
    }
  }

  /* Table Body */
  const getTableBody = () => {
    let table_body = []
    const firstCellMargin = '10px'

    const getRow = ({ circleColor, tooltipData, label, values, id }) => {
      const formattedLabel =
        label?.length || typeof label === 'object' ? label : '-'
      return [
        <i
          key={label}
          className='fas fa-circle color-indicator'
          style={{ color: circleColor, marginRight: firstCellMargin }}
        />,
        tooltipData ? (
          <Label
            id={formattedLabel}
            label={formattedLabel}
            performers={tooltipData}
            sectorId={id}
          />
        ) : (
          <div
            key={label}
            style={{
              marginRight: firstCellMargin,
            }}
          >
            {formattedLabel}
          </div>
        ),
      ].concat(values)
    }

    const formatScope = () => {
      data?.table?.data?.forEach(elem => {
        const { label, target, benchmark } = elem
        if (includeBenchmark && benchmark) {
          table_body.push(
            getRow({
              circleColor: colors?.[label],
              label: label,
              values: [
                formatCellValue(target?.budget, 2),
                formatCellValue(target?.emitted, 2),
                `${formatCellValue(
                  addThousandSeparator(Math.round(target?.vs_budget))
                )}%`,
                renderBudgetIcon(target?.emitted, target?.budget),
                formatCellValue(benchmark?.emitted, 2),
              ],
            })
          )
        } else {
          table_body.push(
            getRow({
              circleColor: colors?.[label],
              label: label,
              values: [
                formatCellValue(target?.budget, 2),
                formatCellValue(target?.emitted, 2),
                `${formatCellValue(
                  addThousandSeparator(Math.round(target?.vs_budget))
                )}%`,
                renderBudgetIcon(target?.emitted, target?.budget),
              ],
            })
          )
        }
      })
    }

    const formatHoldings = () => {
      data?.table?.data?.forEach(elem => {
        const { id, label, target } = elem

        table_body.push(
          getRow({
            circleColor: colors?.[label],
            label: label?.length ? (
              <>
                <Link href={`/app/instruments/${id}`} target='_blank'>
                  {label}
                </Link>

                {target?.sector_name && (
                  <StyledSectorSubText>
                    ({target?.sector_name})
                  </StyledSectorSubText>
                )}
              </>
            ) : (
              'Uncategorized Company'
            ),
            values: [
              formatCellValue(target?.budget, 2),
              formatCellValue(target?.emitted, 2),
              `${formatCellValue(
                addThousandSeparator(Math.round(target?.vs_budget))
              )}%`,
              renderBudgetIcon(target?.emitted, target?.budget),
            ],
          })
        )
      })
    }

    const formatSector = () => {
      data?.table?.data?.forEach(elem => {
        const { label, target, benchmark, id } = elem

        const formattedLabel = label?.length ? label : 'Uncategorized Companies'

        if (includeBenchmark && benchmark) {
          table_body.push(
            getRow({
              circleColor: colors?.[label],
              label: formattedLabel,
              tooltipData: performers?.[label],
              values: [
                formatCellValue(target?.budget, 2),
                formatCellValue(target?.emitted, 2),
                `${formatCellValue(
                  addThousandSeparator(Math.round(target?.vs_budget))
                )}%`,
                renderBudgetIcon(target?.emitted, target?.budget),
                formatCellValue(benchmark?.emitted, 2),
              ],
              id: id,
            })
          )
        } else {
          table_body.push(
            getRow({
              circleColor: colors?.[label],
              label: formattedLabel,
              tooltipData: performers?.[label],
              values: [
                formatCellValue(target?.budget, 2),
                formatCellValue(target?.emitted, 2),
                `${formatCellValue(
                  addThousandSeparator(Math.round(target?.vs_budget))
                )}%`,
                renderBudgetIcon(target?.emitted, target?.budget),
              ],
              id: id,
            })
          )
        }
      })
    }

    switch (selectedChart) {
      case CarbonAuditTabs.SCOPE:
        formatScope()
        break
      case CarbonAuditTabs.HOLDING:
        formatHoldings()
        break
      default:
        formatSector()
        break
    }

    return table_body
  }

  /* Table Footer */
  const getTableFooter = () => {
    const { budget, emissions, benchmark, vs_budget } =
      data?.chartFormat?.totals

    let table_footer

    const calculateTotals = (longs, shorts, percentage) => {
      if (shorts || shorts === 0) {
        return percentage
          ? `${formatCellValue(
              addThousandSeparator(Math.round(Number(longs) + Number(shorts)))
            )}%`
          : formatCellValue(Number(longs) + Number(shorts), 2)
      } else {
        return percentage
          ? `${formatCellValue(
              addThousandSeparator(Math.round(Number(longs)))
            )}%`
          : formatCellValue(Number(longs), 2)
      }
    }

    const formatScope = () => {
      if (includeBenchmark && benchmark) {
        table_footer = [
          [
            '',
            'Total',
            calculateTotals(budget?.longs, budget?.shorts),
            calculateTotals(emissions?.longs, emissions?.shorts),
            calculateTotals(vs_budget?.longs, vs_budget?.shorts, true),
            renderBudgetIcon(
              Number(emissions?.longs) + emissions?.shorts,
              Number(budget?.longs) + budget?.shorts
            ),
            calculateTotals(benchmark?.longs, benchmark?.shorts),
          ],
        ]
      } else {
        table_footer = [
          [
            '',
            'Total',
            calculateTotals(budget?.longs, budget?.shorts),
            calculateTotals(emissions?.longs, emissions?.shorts),
            calculateTotals(vs_budget?.longs, vs_budget?.shorts, true),
            renderBudgetIcon(
              Number(emissions?.longs) + emissions?.shorts,
              Number(budget?.longs) + budget?.shorts
            ),
          ],
        ]
      }
    }

    const formatHoldings = () => {
      table_footer = [
        [
          '',
          'Total',
          calculateTotals(budget?.longs, budget?.shorts),
          calculateTotals(emissions?.longs, emissions?.shorts),
          calculateTotals(vs_budget?.longs, vs_budget?.shorts, true),
          renderBudgetIcon(
            Number(emissions?.longs) + emissions?.shorts,
            Number(budget?.longs) + budget?.shorts
          ),
        ],
      ]
    }
    const formatSector = () => {
      if (includeBenchmark && benchmark) {
        table_footer = [
          [
            '',
            'Total',
            calculateTotals(budget?.longs, budget?.shorts),
            calculateTotals(emissions?.longs, emissions?.shorts),
            calculateTotals(vs_budget?.longs, vs_budget?.shorts, true),
            renderBudgetIcon(
              Number(emissions?.longs) + emissions?.shorts,
              Number(budget?.longs) + budget?.shorts
            ),
            calculateTotals(benchmark?.longs, benchmark?.shorts),
          ],
        ]
      } else {
        table_footer = [
          [
            '',
            'Total',
            calculateTotals(budget?.longs, budget?.shorts),
            calculateTotals(emissions?.longs, emissions?.shorts),
            calculateTotals(vs_budget?.longs, vs_budget?.shorts, true),
            renderBudgetIcon(
              Number(emissions?.longs) + emissions?.shorts,
              Number(budget?.longs) + budget?.shorts
            ),
          ],
        ]
      }
    }

    switch (selectedChart) {
      case CarbonAuditTabs.SCOPE:
        formatScope()
        break
      case CarbonAuditTabs.HOLDING:
        formatHoldings()
        break
      default:
        formatSector()
        break
    }

    return table_footer
  }

  /* Memoized data */
  let tableHeaders = useMemo(
    () => getTableHeaders(targetName),
    [selectedChart, includeBenchmark]
  )
  let tableBody = useMemo(
    () => getTableBody(),
    [selectedChart, colors, data, performers, includeBenchmark]
  )
  let tableFooter = useMemo(
    () => getTableFooter(),
    [selectedChart, data, includeBenchmark]
  )

  return {
    tableHeaders,
    tableBody,
    tableFooter,
    getTableHeaders,
    getTableBody,
    getTableFooter,
  }
}
