import React, { useState } from 'react'
import styled from 'styled-components'

import { APP_COLOR_PRIMARY } from '../../styles/colors'

const StyledSlider = styled.div`
  position: relative;
  input[type='range'] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    // background: transparent; /* Otherwise white in Chrome */
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    z-index: 999;
  }

  input[type='range']:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  /* Special styling for WebKit/Blink */
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #cbcbcb;
    height: 30px;
    width: 20px;
    border-radius: 3px;
    background: #f0f0f0;
    cursor: pointer;
    margin-top: -5px;
    z-index: 999;
  }

  /* All the same stuff for Firefox */
  input[type='range']::-moz-range-thumb {
    border: 1px solid #cbcbcb;
    height: 30px;
    width: 20px;
    border-radius: 3px;
    background: #f0f0f0;
    cursor: pointer;
    z-index: 999;
  }

  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 20px;
    cursor: pointer;
    background: #efefef;
    border-radius: 5px;
  }

  input[type='range']:focus::-webkit-slider-runnable-track {
    background: #efefef;
  }

  input[type='range']::-moz-range-track {
    width: 100%;
    height: 20px;
    cursor: pointer;
    background: #efefef;
    border-radius: 5px;
  }

  input[type='range']::-ms-track {
    width: 100%;
    height: 20px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type='range']::-ms-fill-lower {
    background: #efefef;
    border-radius: 2.6px;
  }
  input[type='range']:focus::-ms-fill-lower {
    background: ##efefef;
  }
  input[type='range']::-ms-fill-upper {
    background: #efefef;
    border-radius: 2.6px;
  }
  input[type='range']:focus::-ms-fill-upper {
    background: #efefef;
  }
`

const Progress = styled.div`
  height: 20px;
  background-color: ${APP_COLOR_PRIMARY};
  width: calc(
    ${props => props.value}% - calc(20px * ${props => props.value / 100})
  );
  position: absolute;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  pointer-events: none;
`

const Slider = ({
  min = 0,
  max = 100,
  step = 1,
  defaultValue,
  value,
  onChange,
  handleInputSync,
}) => {
  const [sliderValue, setSliderValue] = useState(defaultValue)

  const handleLocalChange = inputValue => {
    setSliderValue(inputValue)
    handleInputSync(inputValue)
  }

  return (
    <StyledSlider value={value} className='slider'>
      <Progress value={sliderValue} />
      <input
        id='typeinp'
        type='range'
        min={min}
        max={max}
        step={step}
        value={sliderValue}
        defaultValue={defaultValue}
        onChange={e => handleLocalChange(e.target.value)}
        onMouseUp={e => onChange(e.target.value)}
      />
    </StyledSlider>
  )
}

export default Slider
