import qs from 'query-string'
import React, { useEffect, useState } from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Button from 'react-bootstrap/Button'

import Card from '../../components/styleguide/Card'
import Heading1 from '../../components/styleguide/Heading1'
import Link from '../../components/styleguide/Link'
import Paginator from '../../components/styleguide/Paginator'
import Table from '../../components/styleguide/Table'
import TextView from '../../components/styleguide/TextView'
import { loadList } from './Badges.loaders'
import { BadgeContainer } from './Badges.styles'

const Badges = () => {
  const [list, setList] = useState([])
  const [query, setQuery] = useState(null)
  const [lastPage, setLastPage] = useState(0)
  const [badge, setBadge] = useState({})
  const [portfolioData, setPortfolioData] = useState({ name: '', id: null })

  const onPaginatorChange = page => {
    setQuery({ ...query, page })
  }

  const onClearFilterClick = () => {
    setQuery({ ...query, snapshot_id: null })
  }

  const sortFieldsList = fields => {
    let keys = Object.keys(fields).sort(f =>
      ['Rank Worst Best'].includes(f) ? -1 : 0
    )
    keys = keys.sort(f =>
      ['Name', 'Company Name', 'Bank', 'Rank Worst Best'].includes(f) ? -1 : 0
    )

    return [keys, keys.map(k => fields[k])]
  }
  // LOADS LIST
  useEffect(
    loadList.bind(
      null,
      { setList, setLastPage, setBadge, setPortfolioData },
      qs.stringify(query)
    ),
    [query]
  )

  // UPDATES QUERY OBJECT ON FIRST RENDER BASED ON URL
  useEffect(() => {
    const query = {
      sort_field: 'rank',
      sort_dir: 'asc',
      page: 1,
      snapshot_id: null,
      badge: '',
    }

    const search = qs.parse(window.location.search)

    if (search.page) {
      query.page = search.page
    }

    if (search.sort_dir) {
      query.sort_dir = search.sort_dir
    }

    if (search.sort_field) {
      query.sort_field = search.sort_field
    }

    if (search.snapshot_id) {
      query.snapshot_id = search.snapshot_id
    }

    if (search.badge) {
      query.badge = search.badge
    }

    setQuery(query)
  }, [])

  // UPDATES URL BASED ON QUERY OBJECT
  useEffect(() => {
    const oldQuery = qs.parse(window.location.search)

    if (qs.stringify(oldQuery) !== qs.stringify(query)) {
      const newQuery = { ...oldQuery, ...query }

      if (!newQuery.snapshot_id) {
        delete newQuery.snapshot_id
      }

      window.history.replaceState(
        {},
        '',
        '/app/badges?' + qs.stringify(newQuery)
      )
    }
  }, [query])

  /// MAP HEADER AND BODY

  let headers = []
  if (list[0]) {
    headers = sortFieldsList(list[0].fields)[0].map(field => ({
      label: field.replaceAll('_', ' ').toUpperCase(),
      id: field,
      sort: true,
    }))
  }
  let body = []

  body = list.map(c =>
    sortFieldsList(c.fields)[1].map(field => (
      <TextView
        key={field}
        bold
        className='badges-table-rank'
        text={field}
        truncateLength={40}
      />
    ))
  )

  if (!query) return null

  if (!badge) return null

  return (
    <BadgeContainer>
      {query.snapshot_id && portfolioData.name && (
        <Breadcrumb>
          <Breadcrumb.Item href='/app'>Home</Breadcrumb.Item>
          <Breadcrumb.Item
            href={`/app/portfolios/${portfolioData.id}/snapshots/${query.snapshot_id}`}
          >
            {portfolioData.name}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Badges</Breadcrumb.Item>
          <Breadcrumb.Item active>{badge.name}</Breadcrumb.Item>
        </Breadcrumb>
      )}

      <Heading1 text={badge.name} />
      <TextView className='badges-description' text={badge.description} />

      {badge?.source && (
        <div className='badges-source'>
          Source:{' '}
          {badge.source.split(' ').map(source => (
            <div key={source}>
              <Link target='_blank' href={source}>
                {source}
              </Link>
            </div>
          ))}
        </div>
      )}
      {query.snapshot_id && portfolioData.name && (
        <Button
          className='remove-filter'
          onClick={() => onClearFilterClick()}
        >
          {`Badge filtered to companies from the ${portfolioData.name} portfolio`}
          <i className='fas fa-times ml-1'></i>
        </Button>
      )}
      <Card className='badges-card'>
        <Table
          headers={headers}
          body={body}
          sort={{
            sortField: query.sort_field,
            sortDirection: query.sort_dir,
          }}
          onSort={(sort_field, sort_dir) =>
            setQuery({ ...query, sort_field, sort_dir })
          }
          className='badges-table'
        />
        {lastPage > 1 && (
          <Paginator
            className={'paginator'}
            max={lastPage}
            selected={query.page}
            onChange={onPaginatorChange}
          />
        )}
      </Card>

    </BadgeContainer>
  )
}

export default Badges
