import React from 'react'
import styled from 'styled-components'

import SearchBar from '../../../components/styleguide/SearchBar'
import { BG_COLOR_WHITE, BORDER_COLOR_TERTIARY } from '../../../styles/colors'

const StyledContainer = styled.div`
  ${props => props.padding && `padding: ${props.padding};`}
  ${props => props.margin && `margin: ${props.margin};`}
  .search-bar {
    width: 100%;
    height: 50px;

    background-color: ${BG_COLOR_WHITE};
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    border: 5px solid ${BORDER_COLOR_TERTIARY};

    > input {
      font-size: 14px;
    }

    > img {
      width: 20px;
      height: 20px;
    }
  }
`

const FlatSearchBar = props => {
  return (
    <StyledContainer margin={props?.margin} padding={props?.padding}>
      <SearchBar
        className={'search-bar'}
        placeholder='Search for...'
        {...props}
      />
    </StyledContainer>
  )
}

export default FlatSearchBar
