import React, { forwardRef } from 'react'

// eslint-disable-next-line react/display-name
export const EditIcon = forwardRef(() => (
  <svg
    width='11'
    height='12'
    viewBox='0 0 11 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.37095 0.90583C8.91213 0.364723 9.79101 0.364723 10.3322 0.90583L10.5941 1.16799C11.1353 1.70921 11.1353 2.58728 10.5941 3.12849L5.82308 7.89767C5.64125 8.08169 5.40962 8.18993 5.15635 8.26787L3.19512 8.72032C3.07823 8.7463 2.957 8.71166 2.87258 8.62723C2.78815 8.5428 2.75352 8.42157 2.77949 8.30467L3.23192 6.34331C3.29037 6.09002 3.41809 5.85838 3.60209 5.65705L8.37095 0.90583ZM9.8408 1.39574C9.57021 1.12513 9.13293 1.12513 8.86234 1.39574L8.2822 1.97462L9.52475 3.21725L10.1027 2.63707C10.3733 2.36711 10.3733 1.92851 10.1027 1.6579L9.8408 1.39574ZM3.90731 6.49918L3.57827 7.92149L4.98101 7.59243C5.12605 7.56429 5.24294 7.49934 5.33386 7.38893L9.03552 3.70651L7.79297 2.46453L4.09131 6.16579C4.00039 6.25671 3.93545 6.37361 3.90731 6.49918ZM4.50261 1.80143C4.6931 1.80143 4.84896 1.95644 4.84896 2.14781C4.84896 2.33918 4.6931 2.49419 4.50261 2.49419H1.73177C1.15791 2.49419 0.692709 2.95963 0.692709 3.53332V9.76811C0.692709 10.3418 1.15791 10.8072 1.73177 10.8072H7.96615C8.5398 10.8072 9.00522 10.3418 9.00522 9.76811V6.99709C9.00522 6.80659 9.16107 6.65072 9.35157 6.65072C9.54206 6.65072 9.69792 6.80659 9.69792 6.99709V9.76811C9.69792 10.725 8.92296 11.5 7.96615 11.5H1.73177C0.775401 11.5 0 10.725 0 9.76811V3.53332C0 2.57688 0.775401 1.80143 1.73177 1.80143H4.50261Z'
      fill='currentColor'
    />
  </svg>
))
