import React, { useState } from 'react'
import styled from 'styled-components'

import SearchIcon from '../../../../../../public/icons/ico_search.svg'

const SearchBarContainer = styled.div`
  display: flex;
  border: solid 3px;
  padding: 0.5rem;
  border-color: rgb(0 0 0 / 15%);
  border-radius: 10px;
  width: 300px;
  align-items: center;
  height: ${props => props.height ? props.height : 'auto'};
  & input {
    border: none;
    outline: none;
    width: 100%;
    &:focus {
      border: none;
      outline: none;
    }
  }
  & svg {
    transition: 0.3s;
    transition-timing-function: ease-in-out;
    padding-right: 0.5rem;
    opacity: ${props => props.opacity};
    width: 27px;
  }
`

const HoldingsSearchBar = ({onSearch, height}) => {
  const [value, setValue] = useState()
  const [opacity, setOpacity] = useState('0.3;')
  const handleChange = e => {
    setValue(e.target.value)
    if (e.target.value === '') {
      onSearch('')
    }
  }
  const handleSubmit = e => {
    e.preventDefault()
    onSearch(value)
  }
  const handleFocus = () => {
    setOpacity('1;')
  }
  const handleBlur = () => {
    setOpacity('0.3;')
  }
  return (
    <form onSubmit={handleSubmit}>
      <SearchBarContainer opacity={opacity} height={height}>
        <SearchIcon />
        <input
          type='search'
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          value={value}
          placeholder='Search Holdings'
        ></input>
      </SearchBarContainer>
    </form>
  )
}

export default HoldingsSearchBar
