import { mapInstrumentToState } from '../../../mappers/instruments_mapper'
import { mapSnapshotToState } from '../../../mappers/portfolio_mapper'
import { getInstrument } from '../../../repositories/instruments_repository'
import { getSnapshot } from '../../../repositories/portfolio_repository'
import { getIndustryGroup } from '../../../repositories/sectors_repository'
import { TargetType } from '../../../yb_constants/enums/global_enums'

export const loadTarget = ({ id, targetType, setTarget, setError }) => {
  let getTarget = null

  switch (targetType) {
    case TargetType.TRBC_INDUSTRY_GROUP:
      getTarget = getIndustryGroup(id).then(response => {
        setTarget(response.data)
      })
      break

    case TargetType.PORTFOLIO_SNAPSHOT:
      getTarget = getSnapshot(id?.snapshotId, id?.portfolioId).then(
        response => {
          setTarget(mapSnapshotToState(response?.data?.snapshot))
        }
      )
      break

    case TargetType.INSTRUMENT:
    default:
      getTarget = getInstrument(id).then(response => {
        setTarget(mapInstrumentToState(response.data))
      })
      break
  }

  getTarget.catch(err => {
    const error = err.response ? err.response.status : 502
    setError(error)
  })
}
