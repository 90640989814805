import styled from 'styled-components'

export const ScoreDisplay = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: inherit;

  .value-container {
    margin: 0 0 10px;
    width: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .value {
    text-align: center;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
  }
  p {
    margin: 0 0 10px;
    width: calc(480px - 20px - 96px);
    font-size: 15px;
    line-height: 18px;
  }
`
