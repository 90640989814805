import useFeedbackMessage from '@env/hooks/forms/useFeedbackMessage'
import useInputValidations from '@env/hooks/forms/useInputValidations'
import useRequiredValidation from '@env/hooks/forms/useRequiredValidation'
import useShouldDisplayInputFeedback from '@env/hooks/forms/useShouldDisplayInputFeedback'
import withFormControl from '@ui/components/compositions/withFormControl/withFormControl'
import { InputFeedback } from '@ui/components/presentation/copy/InputFeedback'
import getTestingProps from '@utils/test/getTestingProps'
import classnames from 'classnames'
import React, { useState } from 'react'

import { Button, EButtonVariants } from '../../../elements/Button'
// import theme from './MultipleOptionSwitch.module.scss'
import './MultipleOptionSwitch.module.scss'
import styled from 'styled-components'
import { APP_COLOR_PRIMARY } from '../../../../react/styles/colors'

export interface IProps extends YB.IFormControl<string> {
  options: YB.IOption[]
  className?: string
  name: string
  disabled?: boolean
  required?: boolean
  style?: StyleSheet
}

const S = {
  Container: styled.div`
    .MultipleOptionSwitch {
      display: flex;
      flex-direction: row;
      list-style: none;
      margin: 0;
      padding: 0;

      .customButton {
        background: white;
        border: 1px solid #e2e3e7;
        white-space: nowrap;

        :disabled {
          border-color: #e6e7ea;
          font-weight: bold;
          color: #979ca6;
          padding: 5px 15px;
          transition: none;
        }

        :disabled {
          .selected {
            color: black;
          }
        }

        :hover,
        :active {
          border-color: #e6e7ea !important; // Bootstrap override.
        }

        .selected {
          :active {
            color: ${APP_COLOR_PRIMARY};
            background-color: #f8f9fa;
            border-color: #e6e7ea;
          }
        }
      }
    }
  `,

  Li: styled.li`
    border: 1px solid #e2e3e7;
    border-radius: 50px;

    :first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }

    :not(:first-child):not(:last-child) {
      border-radius: 0%;
      border-right: 0;
    }

    :last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  `,
}

export const MultipleOptionSwitch: React.FC<IProps> = ({
  options,
  name,
  className,
  disabled = false,
  required = true,
  model = useState<string>(options[0]?.value),
  feedbackModel = useState<string>(''),
  formFeedbackModel = useState<string>(''),
  validations = [],
  style = {},
}) => {
  const [selected, setSelected] = model
  const feedbackMessage = useFeedbackMessage(feedbackModel, formFeedbackModel)
  const isInvalid = useShouldDisplayInputFeedback(feedbackMessage, !disabled)

  if (!options.length) return null

  useInputValidations(
    selected,
    useRequiredValidation(validations, required),
    feedbackModel
  )

  return (
    <S.Container
      {...getTestingProps(name)}
      id={name}
      aria-labelledby={`${name}-label`}
      style={style}
    >
      <ul className={classnames('MultipleOptionSwitch', className)}>
        {options.map((option, index) => (
          <S.Li className='customLi' key={option.label}>
            <Button
              testId={`multipleOptionSwitch-${name}-${index}`}
              variant={EButtonVariants.text}
              disabled={disabled}
              className={classnames(
                {
                  selected: option.value === selected,
                },
                'customButton'
              )}
              onClick={() => setSelected(option.value)}
            >
              {option.label}
            </Button>
          </S.Li>
        ))}
      </ul>
      <InputFeedback isPresent={isInvalid} feedbackMessage={feedbackMessage} />
    </S.Container>
  )
}

export const MultipleOptionSwitchControl = withFormControl(MultipleOptionSwitch)
