import React, { useState, useEffect } from 'react';
import { ModuleWrapper } from '../styles';

const PEShowModuleWrapper = ({ children }) => {
    return (
        <ModuleWrapper margin="0px" padding="15px 15px 30px 15px">
            {children}
        </ModuleWrapper>
    )
}

export default PEShowModuleWrapper;
