import React, { useState, useEffect, useMemo } from 'react';
import { getIndustryGroup } from '../../repositories/sectors_repository';
import withErrorBoundary from '../../hocs/withErrorBoundary';
import IndustryGroupDetails from "./components/IndustryGroupDetails";
import ESGMetricsTable from "./components/ESGMetricsTable";
import SectionWrapper from '../../components/styleguide/SectionWrapper';
import { SectorTitle, StyledContainer } from './styles';
import IndustryGroupFacts from './components/IndustryGroupFacts';
import SDGAlignment from '../../components/styleguide/SDGAlignment';
import XAxis from '../../components/charts/components/xAxisLine';
import SDGEXposureCardHorizontal from '../../components/cards/SDGEXposureCardHorizontal';
import ErrorPage from '../../components/styleguide/ErrorPage';
import OverTimeCard from '../../yb_components/cards/over_time_card/OverTimeCard';
import { completeMissingYears } from '../../helpers/objects_and_arrays';
import { SUBSIDIES_OVER_TIME_CARD_DESCRIPTION, SUBSIDIES_OVER_TIME_CARD_TITLE, OVER_TIME_TABLE_ROW_HEADERS, SUBSIDIES_STATS_QUERY, VIOLATIONS_OVER_TIME_CARD_DESCRIPTION, VIOLATIONS_OVER_TIME_CARD_TITLE, VIOLATIONS_STATS_QUERY, SECTOR_SHOW_BREADCRUMBS } from './constants';
import OverTimeLegend from './components/OverTimeLegend';
import { APP_COLOR_PRIMARY } from '../../styles/colors';
import { getSubsidiesStats } from '../../repositories/subsidies_repository';
import { getViolationsStats } from '../../repositories/violations_repository';
import YBBreadcrumb from '../../components/styleguide/YBBreadcrumb';


const SectorShow = ({ id }) => {

    /* State */
    const [industryGroup, setIndustryGroup] = useState(null);
    const [positiveSDGs, setPositiveSDGS] = useState(null);
    const [negativeSDGs, setNegativeSDGS] = useState(null);
    const [sdgExposures, setSdgExposures] = useState(null);
    const [sdgExposuresOverall, setSdgExposuresOverall] = useState(null);
    const [sdgExposuresByCluster, setSdgExposuresByCluster] = useState(null);
    const [sdgType, setSdgType] = useState(null)
    const [subsidiesStats, setSubsidiesStats] = useState(null);
    const [violationsStats, setViolationsStats] = useState(null);
    const [error, setError] = useState(null);

    /* Requests */
    const loadIndustryGroup = () => {
        getIndustryGroup(id).then((response) => {
            setIndustryGroup(response.data);
            const positiveSDGs = response.data.sdgs.positive.map((sdg) => sdg.split("_")[1]);
            const negativeSDGs = response.data.sdgs.negative.map((sdg) => sdg.split("_")[1])
            setPositiveSDGS(positiveSDGs);
            setNegativeSDGS(negativeSDGs);
            setSdgExposures(response.data.sdg_exposures_overall);
            setSdgExposuresOverall(response.data.sdg_exposures_overall);
            setSdgExposuresByCluster(response.data.sdg_exposures_by_cluster);
        }).catch((error) => {
            console.log(error)
        })
    };

    const loadSubsidiesStats = () => {
        getSubsidiesStats(id, { targetPath: 'sectors', query: SUBSIDIES_STATS_QUERY })
            .then((response) => {
                setSubsidiesStats(response.data.subsidies_stats);
            })
            .catch((err) => {
                const error = err.response ? err.response.status : 502;
                setError(error);
            });
    }

    const loadViolationsStats = () => {
        getViolationsStats(id, { targetPath: 'sectors', query: VIOLATIONS_STATS_QUERY })
            .then((response) => {
                setViolationsStats(response.data.violations_stats);
            })
            .catch((err) => {
                const error = err.response ? err.response.status : 502;
                setError(error);
            });
    }

    /* Use Effect */
    useEffect(() => {
        loadIndustryGroup();
        loadSubsidiesStats();
        loadViolationsStats();
    }, []);

    const changeType = (type) => {
        switch (type) {
            case "Overall":
                setSdgExposures(sdgExposuresOverall);
                setSdgType(false);
                break;
            case "By Cluster":
                setSdgExposures(sdgExposuresByCluster);
                setSdgType("cluster");
                break;
        }
    }

    /* Data & Memoized data */
    const subsidiesTotals = subsidiesStats?.subsidies_over_time?.totals;
    const showSubsidiesOverTimeCard = subsidiesStats?.subsidies_over_time?.values ? subsidiesStats.subsidies_over_time.values.length > 0 : false;
    const memoizedSubsidiesStats = useMemo(() => completeMissingYears(subsidiesStats?.subsidies_over_time?.values), [subsidiesStats]);

    const violationsTotals = violationsStats?.violations_over_time?.totals;
    const showViolationsOverTimeCard = violationsStats?.violations_over_time?.values ? violationsStats.violations_over_time.values.length > 0 : false;
    const memoizedViolationsStats = useMemo(() => completeMissingYears(violationsStats?.violations_over_time?.values), [violationsStats]);

    /* Render */
    if (error) return <ErrorPage code={error} message={error == 404 ? 'NOT FOUND' : ''} />;
    return (
        <StyledContainer>
            <YBBreadcrumb breadcrumbs={[...SECTOR_SHOW_BREADCRUMBS, { name: industryGroup?.name, active: true }]} />

            {industryGroup &&
                (
                    <SectionWrapper justify="space-between" wrap="nowrap">
                        <SectorTitle>{industryGroup.name}</SectorTitle>
                        {(positiveSDGs || negativeSDGs) && <SDGAlignment className="sector-sdgs" sdgs={{ positive: positiveSDGs, negative: negativeSDGs }} />}
                    </SectionWrapper>
                )
            }
            <SectionWrapper width="100%" justify="space-between" margin="20px 0px 20px 0px">
                {(industryGroup?.summary || industryGroup?.subsectors) && <IndustryGroupDetails summary={industryGroup.summary} subsectors={industryGroup.subsectors} />}
                {industryGroup?.facts?.length > 0 && <IndustryGroupFacts facts={industryGroup.facts} />}
            </SectionWrapper>


            <SectionWrapper height="5%" margin={'0px 0px 20px 0px'}>
                {sdgExposures && (
                    <SDGEXposureCardHorizontal
                        sdgAlignments={sdgExposures}
                        showToggle={true}
                        changeType={changeType}
                        type={sdgType}
                    />
                )}
            </SectionWrapper>

            {/* Subsidies & Loans Over Time */}
            <OverTimeCard
                data-cy={'sector_subsidies_over_time_card'}
                show={showSubsidiesOverTimeCard}
                title={SUBSIDIES_OVER_TIME_CARD_TITLE}
                additionalTitleLink={{label: 'More Info »', href: `/app/sectors/${id}/subsidies`}}
                description={SUBSIDIES_OVER_TIME_CARD_DESCRIPTION}
                rowHeaders={OVER_TIME_TABLE_ROW_HEADERS}
                data={memoizedSubsidiesStats}
                colorScheme={[APP_COLOR_PRIMARY]}

                legendComponent={<OverTimeLegend totals={subsidiesTotals} propertyName='subsidy_level' />}
                marginBottom={'20px'}
            />

            {/* Violations Over Time */}
            <OverTimeCard
                data-cy={'sector_violations_over_time_card'}
                show={showViolationsOverTimeCard}
                title={VIOLATIONS_OVER_TIME_CARD_TITLE}
                additionalTitleLink={{label: 'More Info »', href: `/app/sectors/${id}/violations`}}
                description={VIOLATIONS_OVER_TIME_CARD_DESCRIPTION}
                rowHeaders={OVER_TIME_TABLE_ROW_HEADERS}
                data={memoizedViolationsStats}
                colorScheme={[APP_COLOR_PRIMARY]}

                legendComponent={<OverTimeLegend totals={violationsTotals} propertyName='offense_group' />}
                marginBottom={'20px'}
            />
        </StyledContainer>
    )
};
export default withErrorBoundary(SectorShow);
