import React, { useState } from 'react'
import styled from 'styled-components'

import { APP_COLOR_PRIMARY, FONT_COLOR_SECONDARY } from '../../../styles/colors'

const StyledTable = styled.table`
  margin-top: 20px;
  thead {
    th:first-child {
      border: none;
    }

    th.standard-weight-header {
      color: ${APP_COLOR_PRIMARY};
    }
    .number {
      text-align: center;
    }
  }
  tbody {
    th {
      background-color: white;
      vertical-align: top;
      text-transform: capitalize;
    }
    tr {
      td.metric {
        text-transform: capitalize;
      }
    }
    .number {
      text-align: right;
    }

    tr.total-row {
      background-color: white;
      td :last-child {
        text-align: right;
        padding-right: 17px;
      }

      td :not(:last-child) {
        border: none;
      }

      .regular-text {
        font-weight: normal;
        margin-right: 40px;
      }
    }
  }

  th,
  td {
    padding: 8px;
    border: 1px solid #eeeeee;
    font-size: 12px;
    font-weight: bold;
    color: #979ca6;
  }

  td.input-container {
    padding: 0px;
  }

  td {
    .percent {
      position: relative;
    }
    .percent::after {
      position: relative;
      color: ${FONT_COLOR_SECONDARY};
      content: '%';
      padding-right: 8px;
    }
    input {
      font-size: 12px;
      font-weight: bold;
      color: #979ca6;

      text-align: right;
      border: none;
      width: calc(100% - 20px);
      height: 35px;
      background-color: inherit;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      display: none;
    }

    input::-webkit-textfield-decoration-container {
      padding-right: 2px;
    }
  }
`

const AdvancedView = ({
  esgMetricsMap,
  dispatch,
  action,
  loadedPillars,
  propsDefaultWeights,
  currentSectorId,
}) => {
  const loadedMetricWeights = loadedPillars?.advanced
  const [originalWeights] = useState(loadedMetricWeights)

  const defaultWeights = {}

  if (currentSectorId) esgMetricsMap = esgMetricsMap[currentSectorId]

  Object.keys(esgMetricsMap).map(pillar =>
    esgMetricsMap[pillar].map(
      metric =>
        (defaultWeights[metric.metric] = (
          metric?.weight || 100.0 / esgMetricsMap[pillar].length
        ).toFixed(1))
    )
  )

  const weights = {
    ...defaultWeights,
    ...propsDefaultWeights?.advanced,
    ...loadedMetricWeights,
  }

  const handleMetricChange = (metric, value) => {
    value = Number(value)
    const weightsToAdjust = { ...weights }

    weightsToAdjust[metric] = value

    if (value > 100) weightsToAdjust[metric] = 100
    if (value < 0) weightsToAdjust[metric] = 0

    if (dispatch)
      dispatch({
        type: `${action.type}-advanced`,
        payload: {
          ...action?.payload,
          ...loadedPillars,
          advanced: { ...weightsToAdjust },
        },
      })
  }

  return (
    <StyledTable>
      <thead>
        <tr>
          <th></th>
          <th>Metric</th>
          <th className='number standard-weight-header'>YB Standard Weight</th>
          <th className='number'>Original Weight</th>
          <th className='number'>Custom Weight</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(esgMetricsMap).map(pillar => {
          return (
            <>
              {esgMetricsMap[pillar].map((metric, index) => (
                <tr key={metric.metric}>
                  {index == 0 && (
                    <th rowSpan={esgMetricsMap[pillar].length}>{pillar}</th>
                  )}
                  <td className='metric'>{metric.display_name}</td>
                  <td className='number'>{metric.weight?.toFixed(1)}%</td>
                  <td className='number'>
                    {originalWeights?.[metric.metric] ||
                      metric.weight?.toFixed(1)}
                    %
                  </td>
                  <td className='input-container'>
                    <div className='percent'>
                      <input
                        type='number'
                        step='.5'
                        value={weights[metric.metric]}
                        onChange={e =>
                          handleMetricChange(metric.metric, e.target.value)
                        }
                      />
                    </div>
                  </td>
                </tr>
              ))}
              <tr className='total-row'>
                <td></td>
                <td></td>
                <td colSpan={2}>
                  <span className='float-right'>Total weight:</span>
                  <span className='regular-text float-right'>
                    Total weights for {pillar} must equal 100%
                  </span>
                </td>
                <td>
                  {Math.round(
                    esgMetricsMap[pillar].reduce(
                      (sum, metric) => sum + Number(weights[metric.metric]),
                      0
                    )
                  ) + '%'}
                </td>
              </tr>
            </>
          )
        })}
      </tbody>
    </StyledTable>
  )
}

export default AdvancedView
