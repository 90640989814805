import React from 'react'

import { BreadcrumbNav } from '../../../yb_components/styleguide/breadcrumbs/BreadcrumbNav'
import PageCard from '../../../yb_components/styleguide/cards/PageCard'
import { CreateUpdateUserForm } from './components/forms/CreateUpdateUserForm'

export const NewUserPage = ({ organization }) => {
  const breadcrumbLinks = [
    {
      url: '/app',
      text: 'Home',
      active: false,
    },
    {
      url: '/app/organization',
      text: `${organization?.name ? organization.name : 'Organization'}`,
      active: true,
    },
    {
      url: '/app/organization/users',
      text: 'User Management',
      active: false,
    },
    {
      url: '/app/organization/users/new',
      text: 'New User',
      active: true,
    },
  ]
  return (
    <div className='page__container'>
      <header style={{ padding: '1.25rem 0' }}>
        <BreadcrumbNav links={breadcrumbLinks} />
      </header>
      <PageCard title={'New User'}>
        <CreateUpdateUserForm />
      </PageCard>
    </div>
  )
}
