import { ModuleCard } from '@ui/components/presentation/cards/ModuleCard'
import { ModuleCardFallback } from '@ui/components/presentation/cards/ModuleCard/components/ModuleCardFallback'
import { KeyValueList } from '@ui/components/presentation/lists/KeyValueList'
import { Box } from '@ui/elements/grouping/Box'
import React, { useMemo } from 'react'

// import theme from './KeyValueCard.module.scss'
import * as S from './KeyValueCard.styles'

export interface IProps {
  items: YB.IKeyValueRecord<React.ReactNode>[]
  title: string
  children: React.ReactNode
}

export const KeyValueCard: React.FC<IProps> = ({
  title,
  items = [],
  children,
}) => {
  const hasItems = useMemo(() => !!items?.length, [items])

  return (
    <S.Container title={title}>
      <Box isPresent={hasItems}>
        <KeyValueList
          keyTitle='Disclosure'
          valueTitle='Publish Date'
          items={items}
        />
      </Box>
      <ModuleCardFallback isPresent={!hasItems}>{children}</ModuleCardFallback>
    </S.Container>
  )
}
