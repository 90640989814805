import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'

import {
  BG_COLOR_GRAY_ALT,
  BG_COLOR_PRIMARY,
  BORDER_COLOR_GRAY_ALT,
  BORDER_COLOR_LIGHT,
  FONT_COLOR_PRIMARY,
  FONT_COLOR_SECONDARY,
} from '../../../styles/colors'

export const StyledContainer = styled.div`
  width: 100%;
  display: inline-block;
  flex-direction: row;
`

export const StyledRow = styled(Row)`
  width: 100%;
  min-height: '80px';
  ${props => props.isPdfTemplate && 'max-height: 70px;'}
  border-bottom: 1px solid ${BORDER_COLOR_LIGHT};
  padding: 10px;
  margin-left: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  :nth-child(odd) {
    background-color: ${BG_COLOR_PRIMARY};
  }
`

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: flex-start;
  font-weight: bold;
  font-size: ${props => (props.isPdfTemplate ? '11px' : '14px')};
`

export const StyledLabel = styled.a`
  color: ${props =>
    props.color ? props.color + ' !importat' : FONT_COLOR_PRIMARY};
  margin-left: ${props => (props.isPdfTemplate ? '30px' : '0px')};
  font-size: ${props =>
    props.isPdfTemplate ? '11px' : props.size ? props.size : '14px'};
  font-weight: bold;
`

export const StyledDateContainer = styled(Col)`
  color: ${FONT_COLOR_SECONDARY};
  margin-right: ${props => (props.isPdfTemplate ? '30px' : '0px')};
  font-size: ${props => (props.isPdfTemplate ? '7px' : '10px')};
  display: flex;
  justify-content: flex-end;
`

export const StyledCellsContainer = styled(Row)`
  width: ${props => (props.isPdfTemplate ? '700px' : '100%')};
  margin-left: ${props => (props.isPdfTemplate ? '20px' : '0px')};
  margin-right: ${props => (props.isPdfTemplate ? '20px' : '0px')};
  display: inline-block;
  margin-top: 5px;
  text-transform: uppercase;
`

export const StyledCell = styled.span`
  background-color: ${BG_COLOR_GRAY_ALT};
  color: ${FONT_COLOR_SECONDARY};
  border: 1px solid ${BORDER_COLOR_GRAY_ALT};
  border-radius: 13px;
  padding: 5px 10px 5px 10px;
  margin: 0px 5px 5px 5px;
  display: inline-block;
  font-size: ${props => (props.isPdfTemplate ? '7px' : '10px')};
`
