import styled from 'styled-components'

export const StyledSasbCard = styled.div`
  margin: 0;
  padding: 1.25rem;
  border-radius: 6px;
  border: none;
  background-color: white;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.03);

  header {
    margin: 0 0 2.5rem 0;
    position: relative;
    color: #2e384d;
  }

  &.sasb-grid-card {
    .card-heading {
      margin: 0 0 10px 0;
      width: calc(100% - 60px - 20px);
      max-width: 740px;
      font-size: 14px;
      line-height: 16.8px;
      font-weight: 900;
      text-transform: uppercase;
    }
    .card-subheading {
      margin: 0;
      width: calc(100% - 60px - 20px);
      max-width: 740px;
      font-size: 14px;
      line-height: 16.8px;
    }
    .sasb-logo-right {
      position: absolute;
      top: -3px;
      right: -3px;
    }
  }

  &.sasb-details-card {
    .sasb-logo-left {
      width: 70px;
      height: 70px;
      position: absolute;
      top: -3px;
      left: -3px;
    }
    .card-heading {
      margin: 0 0 0.75rem 84px; // 70px img width - 3px*2 + 20px padding
      font-size: 18px;
      line-height: 21.6px;
      font-weight: 900;
      text-transform: uppercase;
    }
    .header-content {
      padding: 0 0 0 84px; // 70px img width - 3px*2 + 20px padding
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media only screen and (min-width: 992px) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
    .prompt-list-wrapper {
      margin: 0 20px 20px 0;
    }
    .prompt-list-span {
      margin: 0 0 4px 0;
      display: block;
      font-size: 14px;
      line-height: 16.8px;
    }
    dt {
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
      white-space: nowrap;
    }
    .info-wrapper {
      dl {
        margin: 0 0 12px 0;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
`
