import React from 'react';
import styled from 'styled-components';
import Container from '../../../../../../components/styleguide/Container';
import { MainTable } from '../../../../../../components/tables/styles';
import { APP_COLOR_PRIMARY } from '../../../../../../styles/colors';

export const StyledContainer = styled(Container)`
  position: relative;
`

export const StyledMainTable = styled(({isPdf, ...rest}) => <MainTable {...rest}/>)`
  thead tr:first-child th {
    ${props => !props.isPdf && 'padding-bottom: 0;'}
  }

  thead tr:nth-child(2) th {
    padding-top: 0;
    line-height: 15px;
  }

  th {
    text-align: center !important;
    font-weight: bold;
    font-size: ${props => props.isPdf ? '18px' : '20px'};
    line-height: ${props => props.isPdf ? '22px' : '26px'};

    &:first-child {
      width: 20%;
    }
  }
  td {
    width: auto;
  }

  th {
    width: auto;
  }

  td.name-column {
    text-align: right;
    color: #6C757D;
    font-size: ${props => props.isPdf ? '12px' : '14px'};
    line-height: ${props => props.isPdf ? '15px' : '17px'};
    font-weight: bold;
    border: none !important;
    text-transform: uppercase;
    padding: 0.94rem;
    width: 20%;

    div.name-column-description {
      font-size: ${props => props.isPdf ? '8px' : '10px'};
      line-height: ${props => props.isPdf ? '10px' : '12px'};
      font-weight: normal;
    }
  }
`

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;

  .spinner-border {
    height: 6rem;
    width: 6rem;
    color: ${APP_COLOR_PRIMARY};
  }
`
