import propTypes from 'prop-types'
import React from 'react'

import { StyledCard } from './Card.styles'

const Card = ({
  children,
  className,
  margin,
  padding,
  height,
  width,
  style = {},
  ...props
}) => {
  const cardPadding = isNaN(padding) ? padding : padding + 'px'
  const cardHeight = isNaN(height) ? height : height + 'px'
  const cardWidth = isNaN(width) ? width : width + 'px'

  return (
    <StyledCard
      style={style}
      className={className}
      padding={cardPadding}
      height={cardHeight}
      width={cardWidth}
      margin={margin}
      {...props}
    >
      {children}
    </StyledCard>
  )
}

Card.propTypes = {
  children: propTypes.node.isRequired,
  margin: propTypes.oneOfType([propTypes.number, propTypes.string]),
  padding: propTypes.oneOfType([propTypes.number, propTypes.string]),
  height: propTypes.oneOfType([propTypes.number, propTypes.string]),
  width: propTypes.oneOfType([propTypes.number, propTypes.string]),
  style: propTypes.object,
  className: propTypes.string,
}

Card.defaultProps = {
  padding: 20,
  height: 'auto',
}

export default Card
