import withFormControl from '@ui/components/compositions/withFormControl'
import classnames from 'classnames'
import React from 'react'

import {
  IMultipleOptionSwitchProps,
  MultipleOptionSwitch,
} from '../MultipleOptionSwitch'
// import theme from './ClosedQuestionButtons.module.scss'
import './ClosedQuestionButtons.module.scss'

export interface IProps extends IMultipleOptionSwitchProps {
  options: [YB.IOption, YB.IOption]
  name: string
}

const ClosedQuestionButtons: React.FC<IProps> = ({
  options,
  className,
  name,
  ...props
}) => {
  return (
    <MultipleOptionSwitch
      {...props}
      name={name}
      className={classnames(className, 'ClosedQuestionButtons')}
      options={options}
    />
  )
}

export default ClosedQuestionButtons

export const ClosedQuestionButtonsControl = withFormControl(
  ClosedQuestionButtons
)
