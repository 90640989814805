import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { SortButton } from './SortButton'

const StyledTableHead = styled.thead`
  th {
    padding: 0 0 8px;
    text-align: center;
    vertical-align: bottom;
    font-size: 0.75rem;
    line-height: 1.2;
    font-weight: 700;
    text-transform: uppercase;

    &:first-child {
      padding-left: 10px;
      width: 240px;
    }
    &:nth-child(3) {
      border-left: 10px solid white;
      border-right: 10px solid white;
    }
    &:nth-child(4) {
      border-right: 10px solid white;
    }
    &:nth-child(5) {
      border-right: 10px solid white;
    }
    &:nth-child(8) {
      border-right: 10px solid white;
    }
    &:nth-child(10) {
      border-right: 10px solid white;
    }

    & .small {
      display: inline-block;
      line-height: 1.2 !important;
      text-transform: initial;
    }
  }
`

export const TcfdTableHead = ({ sorting, updateSort }) => {
  return (
    <StyledTableHead>
      <tr>
        <th colSpan='5' style={{ padding: 0, verticalAlign: 'bottom' }}></th>
        <th colSpan='3' style={{ padding: 0, verticalAlign: 'bottom' }}>
          Market Risk
        </th>
        <th colSpan='2' style={{ padding: 0, verticalAlign: 'bottom' }}>
          Reputation Risk
        </th>
        <th colSpan='2' style={{ padding: 0, verticalAlign: 'bottom' }}>
          Physical Risk
        </th>
      </tr>
      <tr>
        <th>
          <SortButton
            field={'name'}
            sorting={sorting}
            text={'Name'}
            updateSort={updateSort}
            style={{ justifyContent: 'flex-start' }}
          />
        </th>
        <th>
          <SortButton
            field={'weight'}
            sorting={sorting}
            text={'Weight'}
            updateSort={updateSort}
          />
        </th>
        <th>
          <SortButton
            field={'overall_score'}
            sorting={sorting}
            text={'Overall'}
            updateSort={updateSort}
          />
        </th>
        <th>
          <SortButton
            field={'policy_and_legislation_score'}
            sorting={sorting}
            text={'Policy & Litigation'}
            updateSort={updateSort}
          />
        </th>
        <th>
          <SortButton
            field={'technology_and_adaptation_score'}
            sorting={sorting}
            text={'Tech. & Adaptation'}
            updateSort={updateSort}
          />
        </th>
        <th>
          <SortButton
            field={'market_risk_1_score'}
            sorting={sorting}
            text={'Old/New Economy'}
            updateSort={updateSort}
            isSmall={true}
          />
        </th>
        <th>
          <SortButton
            field={'market_risk_2_score'}
            sorting={sorting}
            text={'Ebbing Market Demand'}
            updateSort={updateSort}
            isSmall={true}
          />
        </th>
        <th>
          <SortButton
            field={'market_risk_3_score'}
            sorting={sorting}
            text={'Price of Carbon'}
            updateSort={updateSort}
            isSmall={true}
          />
        </th>
        <th>
          <span className='small'>Transition Pathway Initiative</span>
        </th>
        <th>
          <SortButton
            field={'reputation_2_score'}
            sorting={sorting}
            text={'Industry Enviro. Impacts'}
            updateSort={updateSort}
            isSmall={true}
          />
        </th>
        <th>
          <SortButton
            field={'physical_risk_1_score'}
            sorting={sorting}
            text={'Climate Risk for Industries'}
            updateSort={updateSort}
            isSmall={true}
          />
        </th>
        <th>
          <span className='small'>Moody’s Facilities Risk</span>
        </th>
      </tr>
    </StyledTableHead>
  )
}

TcfdTableHead.propTypes = {
  sorting: PropTypes.object.isRequired,
  updateSort: PropTypes.func.isRequired,
}
