import React from 'react';
import injectSheet from 'react-jss';
import propTypes from 'prop-types'
import cns from '../../helpers/classnames';

const SectionTitle = ({ title, classes, className }) => {
  return (
    <div className={cns(classes.title, className)}>{title}</div>
  );
}

SectionTitle.propTypes = {
  title: propTypes.string.isRequired,
}

const style = {
  title: {
    fontSize: '26px',
    display: 'inline-block'
  }
};

export default injectSheet(style)(SectionTitle);