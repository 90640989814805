import React from 'react'
import Select from 'react-select-3'

import Card from '../../../../../../components/styleguide/Card'
import SectionTitle from '../../../../../../components/styleguide/SectionTitle'
import Tooltip from '../../../../../../components/styleguide/Tooltip'
import FilledButton from '../../../../../../yb_components/styleguide/buttons/filled_button/FilledButton'
import { marketCapStyles } from '../../../../../research_center/styles/select-styles'
import {
  SelectLabel,
  SelectLabelWrapper,
  SelectWrapper,
  SelectWrapperInner,
} from '../../../../../research_center/styles/styles'
import {
  FilterTypes,
  HOLDINGS_FILTERS_COPY,
} from '../../PortfolioHoldingsCard.constants'

const MetricsTooltip = ({
  data,
  options,

  onChange,
  onCancel,
  onUpdate,
}) => {
  return (
    <Tooltip hideArrow noTransform inheritVisibility x='-300'>
      <Card width='720px' height='fit-content'>
        <SectionTitle
          title={HOLDINGS_FILTERS_COPY.metrics.title}
          className='margin-left margin-right'
        />
        <p className='margin-left margin-right filter-description'>
          {HOLDINGS_FILTERS_COPY.metrics.description}
        </p>
        <SelectWrapper>
          <SelectWrapperInner>
            <SelectLabelWrapper>
              <SelectLabel>POSITIVE BADGES</SelectLabel>
              <Select
                placeholder={''}
                isMulti
                value={data?.positive_badge}
                menuShouldScrollIntoView={false}
                styles={marketCapStyles}
                options={options?.positiveBadges}
                isSearchable={false}
                onChange={val => onChange(FilterTypes.POSITIVE_BADGE, val)}
              />
            </SelectLabelWrapper>
            <SelectLabelWrapper>
              <SelectLabel>NEGATIVE BADGES</SelectLabel>
              <Select
                placeholder={''}
                isMulti
                value={data?.negative_badge}
                menuShouldScrollIntoView={false}
                styles={marketCapStyles}
                options={options?.negativeBadges}
                isSearchable={false}
                onChange={val => onChange(FilterTypes.NEGATIVE_BADGE, val)}
              />
            </SelectLabelWrapper>
          </SelectWrapperInner>

          <SelectWrapperInner>
            <SelectLabelWrapper>
              <SelectLabel>E SCORE</SelectLabel>
              <Select
                placeholder={''}
                isMulti
                value={data?.e_score}
                menuShouldScrollIntoView={false}
                styles={marketCapStyles}
                options={options?.scores}
                isSearchable={false}
                onChange={val => onChange(FilterTypes.E_SCORE, val)}
              />
            </SelectLabelWrapper>
            <SelectLabelWrapper>
              <SelectLabel>S SCORE</SelectLabel>
              <Select
                placeholder={''}
                isMulti
                value={data?.s_score}
                menuShouldScrollIntoView={false}
                styles={marketCapStyles}
                options={options?.scores}
                isSearchable={false}
                onChange={val => onChange(FilterTypes.S_SCORE, val)}
              />
            </SelectLabelWrapper>
          </SelectWrapperInner>

          <SelectWrapperInner>
            <SelectLabelWrapper>
              <SelectLabel>G SCORE</SelectLabel>
              <Select
                placeholder={''}
                isMulti
                value={data?.g_score}
                menuShouldScrollIntoView={false}
                styles={marketCapStyles}
                options={options?.scores}
                isSearchable={false}
                onChange={val => onChange(FilterTypes.G_SCORE, val)}
              />
            </SelectLabelWrapper>
            <SelectLabelWrapper>
              <SelectLabel>TOTAL SCORE</SelectLabel>
              <Select
                placeholder={''}
                isMulti
                value={data?.total_score}
                menuShouldScrollIntoView={false}
                styles={marketCapStyles}
                options={options?.scores}
                isSearchable={false}
                onChange={val => onChange(FilterTypes.TOTAL_SCORE, val)}
              />
            </SelectLabelWrapper>
          </SelectWrapperInner>
        </SelectWrapper>
        <div className='form-buttons-wrapper margin-right'>
          <div className='unstyled-button cancel-button'>
            <button onClick={() => onCancel('metrics')}>Cancel</button>
          </div>
          <FilledButton
            onClick={() => {
              onUpdate('metrics')
            }}
            label='UPDATE'
            borderRadio={'25px'}
            width='150px'
          />
        </div>
      </Card>
    </Tooltip>
  )
}

export default MetricsTooltip
