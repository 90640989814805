import React from 'react'
import styled from 'styled-components'

import Table from '../../../../../components/styleguide/Table'
import { CarbonAuditTabs } from '../../../../../yb_components/cards/carbon_audit_card/CarbonAuditCard.constants'
import { splitArrayInGroups } from '../../../../../yb_helpers/arrays'

export const StyledContainer = styled.div`
  max-height: 800px;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`

const CarbonAuditTable = ({ report, selectedChart, headers, body, footer }) => {
  if (!headers?.length || !body?.length || !footer?.length) return <></>

  const renderTable = curBody => (
    <Table
      leftAlignHeader={[0]}
      rightAlignHeader={[1, 2, 3, 4]}
      headers={headers}
      body={curBody}
      footer={footer}
      loadingColumns={5}
      alignNumbers={true}
      look={{
        textAlign: 'left',
        thTextAlign: 'center',
        thFontSize: report ? '15px' : '10px',
        tdFontSize: report ? '15px' : '10px',
        thSpanPadding: '0px',
        th: {
          sticky: true,
          backgroundColor: 'white',
          transform: 'translate(0, -10px)',
          padding: '10px 0px',
        },
        trEvenBackgroundColor: '#F1F1F1',
        tfoot: {
          sticky: true,
          margin: '10 0 0 0',
          backgroundColor: 'white',
          transform: 'translate(0, 10px)',
        },
        lastRow: {
          textAlign: 'right',
        },
      }}
    />
  )

  const renderSplittedTable = (chunks = 10) => {
    const bodyData = splitArrayInGroups(body, chunks)
    return bodyData?.map((curBody, index) => (
      <>
        <StyledContainer>{renderTable(curBody)}</StyledContainer>
        {index < bodyData?.length - 1 && (
          <div style={{ pageBreakBefore: 'always' }} />
        )}
      </>
    ))
  }

  return (
    <>
      {selectedChart === CarbonAuditTabs.HOLDING
        ? renderSplittedTable(10)
        : renderTable(body)}
    </>
  )
}

export default CarbonAuditTable
