import React from 'react';
import injectSheet from 'react-jss';
import { FONT_COLOR_SECONDARY, FONT_COLOR_PRIMARY } from '../../../styles/colors';

const AccountHeader = ({ classes, title }) => {
  return (
    <div >
      <div className="name-container">
        <div className={classes.container}>
          <div className="account-title">
            {title}<br/>
          </div>
        </div>
      </div>
    </div>
  );
}

const style = {
  container: {
    '& .account-title': {
      fontSize: 36,
      fontWeight: 'bold',
      lineHeight: '1.3',
      color: FONT_COLOR_PRIMARY
    }
  }
};

export default injectSheet(style)(AccountHeader);
