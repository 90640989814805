import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { UnitHelper } from '../data_grid/UnitHelper'

dayjs().format()

export const NoteContent = ({ note, subMetrics }) => {
  // determine whether to show/hide indicator number based on number of submetrics
  const [showIndicatorNumber, setShowIndicatorNumber] = useState(false)
  useEffect(() => {
    if (subMetrics.length === 1) setShowIndicatorNumber(false)
    else return setShowIndicatorNumber(true)
  }, [subMetrics])
  // get indicator number by matching submetric index
  const getSubMetricNumber = subMetricId => {
    const index = subMetrics.findIndex(el => el.id === subMetricId)
    return index + 1
  }

  return (
    <>
      {note.body != null && note.body.length > 0 ? <p>{note.body}</p> : null}
      {note.metadata.updated_statuses != null &&
      note.metadata.updated_statuses.length
        ? note.metadata.updated_statuses.map(el =>
            el.updated_values?.map(val => (
              <React.Fragment key={val.name}>
                {val.name === 'current_value' ? (
                  <p>
                    {showIndicatorNumber ? (
                      <em>
                        Indicator &quot;({getSubMetricNumber(el.sub_metric?.id)}
                        ) {el.prompt}
                        &quot; was updated to{' '}
                        <UnitHelper
                          measure={val.updated_value}
                          unit={el.sub_metric?.unit}
                        />
                        .
                      </em>
                    ) : (
                      <em>
                        Indicator &quot;{el.prompt}
                        &quot; was updated to{' '}
                        <UnitHelper
                          measure={val.updated_value}
                          unit={el.sub_metric?.unit}
                        />
                        .
                      </em>
                    )}
                  </p>
                ) : null}
                {val.name === 'current_value_as_of' ? (
                  <p>
                    {showIndicatorNumber ? (
                      <em>
                        Indicator &quot;({getSubMetricNumber(el.sub_metric?.id)}
                        ) {el.prompt}&quot; was updated as of{' '}
                        {dayjs(val.updated_value).format('MMMM D, YYYY')}.
                      </em>
                    ) : (
                      <em>
                        Indicator &quot;{el.prompt}&quot; was updated as of{' '}
                        {dayjs(val.updated_value).format('MMMM D, YYYY')}.
                      </em>
                    )}
                  </p>
                ) : null}
                {val.name === 'target_value' ? (
                  <p>
                    {showIndicatorNumber ? (
                      <em>
                        Indicator &quot;({getSubMetricNumber(el.sub_metric?.id)}
                        ) {el.prompt}&quot; was updated with a target value of{' '}
                        <UnitHelper
                          measure={val.updated_value}
                          unit={el.sub_metric?.unit}
                        />
                        .
                      </em>
                    ) : (
                      <em>
                        Indicator &quot;{el.prompt}&quot; was updated with a
                        target value of{' '}
                        <UnitHelper
                          measure={val.updated_value}
                          unit={el.sub_metric?.unit}
                        />
                        .
                      </em>
                    )}
                  </p>
                ) : null}
                {val.name === 'target_due_date' ? (
                  <p>
                    {showIndicatorNumber ? (
                      <em>
                        Indicator &quot;({getSubMetricNumber(el.sub_metric?.id)}
                        ) {el.prompt}&quot; was updated with a target date of{' '}
                        {dayjs(val.updated_value).format('MMMM D, YYYY')}.
                      </em>
                    ) : (
                      <em>
                        Indicator &quot;{el.prompt}&quot; was updated with a
                        target date of{' '}
                        {dayjs(val.updated_value).format('MMMM D, YYYY')}.
                      </em>
                    )}
                  </p>
                ) : null}
              </React.Fragment>
            ))
          )
        : null}
    </>
  )
}

NoteContent.propTypes = {
  note: PropTypes.object.isRequired,
  subMetrics: PropTypes.array.isRequired,
}
