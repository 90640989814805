import styled from 'styled-components'

import { FONT_COLOR_SECONDARY } from '../../../../../../styles/colors'

export const StyledPeersTable = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;

  .heading-column {
    width: calc(50% - 5px);
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    text-align: right;
  }

  .heading-row {
    padding-right: 1.5rem;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;

    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: ${FONT_COLOR_SECONDARY};
    }
  }

  .content-column {
    width: calc(50% - 5px);
    flex: 1 1 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content-row {
    height: auto;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    span {
      text-align: center;
      font-weight: 700;
      font-size: 22px;
      line-height: 1.1;
    }
  }
`
