import { useEffect } from 'react'

import { getSFDRDisclosureForms } from '../../../../../../../react/repositories/portfolio_repository'
import { TFormAnswer, TFormTrigger, TQuestionnaire } from './data'

class DisclosureDetailController {
  useGetQuestionnaires = (
    portfolioId: string,
    snapshotId: string,
    disclosureId?: string,
    onSuccess?: (
      reportType: string,
      questionnaires: TQuestionnaire[],
      triggers: TFormTrigger,
      answers: TFormAnswer
    ) => void
  ) =>
    useEffect(() => {
      if (disclosureId) {
        getSFDRDisclosureForms(portfolioId, snapshotId, disclosureId).then(
          response => {
            onSuccess?.(
              response?.data?.report_type as string,
              response?.data?.questionnaires as TQuestionnaire[],
              response?.data?.triggers as TFormTrigger,
              response?.data?.answers as TFormAnswer
            )
          }
        )
      }
    }, [portfolioId, snapshotId, disclosureId, onSuccess])
}

export default new DisclosureDetailController()
