import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import styled from 'styled-components'

import { PillButton } from '../../../../../yb_components/styleguide/buttons/pill_button/PillButton'
import {
  getEntityById,
  postEntity,
  updateEntity,
} from '../../repositories/custom_companies_repository'
import { BaseInfoSection } from './BaseInfoSection'
import { FinancialSection } from './FinancialSection'
import { StyledCard } from './FormCard'
import { ImpactSection } from './ImpactSection'
import { MarketSection } from './MarketSection'
import { SectorSection } from './SectorSection'

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`
const defaultValues = {
  name: '',
  website_url: '',
  year_founded: '',
  headquarters_address: '',
  country_of_incorporation: 'USA',
  country_of_hq: 'USA',
  description: '',
  company_logo_blob_id: '',
  asset_type_id: 1,
  ticker: '',
  isin: '',
  cusip: '',
  figi: '',
  ric: '',
  sedol: '',
  loanx: '',
  revenue_2017: '',
  revenue_2018: '',
  revenue_2019: '',
  revenue_2020: '',
  revenue_2021: '',
  valuation_2017: '',
  valuation_2018: '',
  valuation_2019: '',
  valuation_2020: '',
  valuation_2021: '',
  industry: null,
  industry_group: null,
  trbc_activity_id: null,
  peer_company_ids: [],
  impact_theme_ids: [],
  sdg_alignment_ids: [],
}

export const CreateEditEntityForm = ({
  countriesList,
  entityId = null,
  impactThemes,
  industryGroups,
  isEditing = false,
}) => {
  // form handling
  const methods = useForm({ defaultValues })

  // wrapperFormData for passing as prop when editing
  const [wrapperFormData, setWrapperFormData] = useState({})

  const sanitizeSdgs = sdgs => {
    const arr = []
    for (const [key, value] of Object.entries(sdgs)) {
      if (value != null) arr.push(key)
    }
    const sdgsArr = arr.map(el => Number(el.replace(/sdg_/, '')))
    return sdgsArr
  }

  const loadEntityData = async entityId => {
    if (isEditing) {
      try {
        const res = await getEntityById(entityId)
        methods.setValue('name', res.name)
        methods.setValue('website_url', res.website_url)
        methods.setValue(
          'year_founded',
          res.year_founded == null ? '' : res.year_founded
        )
        methods.setValue('headquarters_address', res.headquarters_address)
        methods.setValue(
          'country_of_incorporation',
          res.country_of_incorporation
        )
        methods.setValue('country_of_hq', res.country_of_hq)
        methods.setValue('description', res.description)
        methods.setValue('company_logo_blob_id', res.company_logo_blob_id)
        methods.setValue('asset_type_id', res.asset_type_id)
        methods.setValue('ticker', res.ticker)
        methods.setValue('isin', res.isin)
        methods.setValue('cusip', res.cusip)
        methods.setValue('figi', res.figi)
        methods.setValue('ric', res.ric)
        methods.setValue('sedol', res.sedol)
        methods.setValue('loanx', res.loanx)
        methods.setValue(
          'revenue_2017',
          res.revenue_2017 == null ? '' : res.revenue_2017
        )
        methods.setValue(
          'revenue_2018',
          res.revenue_2018 == null ? '' : res.revenue_2018
        )
        methods.setValue(
          'revenue_2019',
          res.revenue_2019 == null ? '' : res.revenue_2019
        )
        methods.setValue(
          'revenue_2020',
          res.revenue_2020 == null ? '' : res.revenue_2020
        )
        methods.setValue(
          'revenue_2021',
          res.revenue_2021 == null ? '' : res.revenue_2021
        )
        methods.setValue(
          'valuation_2017',
          res.valuation_2017 == null ? '' : res.revenue_2017
        )
        methods.setValue(
          'valuation_2018',
          res.valuation_2018 == null ? '' : res.revenue_2018
        )
        methods.setValue(
          'valuation_2019',
          res.valuation_2019 == null ? '' : res.revenue_2019
        )
        methods.setValue(
          'valuation_2020',
          res.valuation_2020 == null ? '' : res.revenue_2020
        )
        methods.setValue(
          'valuation_2021',
          res.valuation_2021 == null ? '' : res.revenue_2021
        )
        methods.setValue('trbc_activity_id', res.trbc_activity_id)
        methods.setValue('peer_companies', res.peer_companies)
        methods.setValue(
          'peer_company_ids',
          res.peer_companies.map(el => el.id)
        )
        methods.setValue(
          'impact_theme_ids',
          res.impact_themes.map(el => el.id)
        )
        methods.setValue('impact_themes', res.impact_themes)
        methods.setValue('sdg_alignment_ids', sanitizeSdgs(res.sdg_exposure))

        setWrapperFormData(methods.getValues())
      } catch (e) {
        console.error(e)
      }
    } else {
      setWrapperFormData(defaultValues)
    }
  }

  useEffect(() => {
    loadEntityData(entityId)
  }, [entityId])

  const [submitLoading, setSubmitLoading] = useState(false)

  const onSubmit = async data => {
    setSubmitLoading(true)

    let hasError = false

    if (data.industry == null) {
      methods.setError('industry', {
        type: 'focus',
        message: 'Industry is required.',
      })
      hasError = true
    }
    if (data.industry_group == null) {
      methods.setError('industry_group', {
        type: 'focus',
        message: 'Industry Group is required.',
      })
      hasError = true
    }
    if (data.trbc_activity_id == null) {
      methods.setError('trbc_activity_id', {
        type: 'focus',
        message: 'Business Activity is required.',
      })
      hasError = true
    }
    if (
      data.sdg_alignment_ids == null ||
      data.sdg_alignment_ids.length < 1 ||
      data.sdg_alignment_ids.length > 5
    ) {
      methods.setError('sdg_alignment_ids', {
        type: 'focus',
        message: 'Please select 1 to 5 SDGs.',
      })
      hasError = true
    }
    if (
      data.impact_theme_ids == null ||
      data.impact_theme_ids.length < 1 ||
      data.impact_theme_ids.length > 3
    ) {
      methods.setError('impact_theme_ids', {
        type: 'focus',
      })
      hasError = true
    }

    delete data.industry_group
    delete data.industry
    delete data.impact_themes
    delete data.peer_companies

    if (hasError) {
      setSubmitLoading(false)
      return
    }

    try {
      let res
      isEditing
        ? (res = await updateEntity(entityId, data))
        : (res = await postEntity(data))

      const successMsg = isEditing
        ? `${res.name} updated`
        : `${res.name} created`
      toast.success(successMsg)
      setSubmitLoading(false)
      window.location.href = `/app/instruments/${res.instrument_id}`
    } catch (e) {
      const successMsg = isEditing
        ? `Update company failed`
        : `Create company failed`
      toast.error(successMsg)
      setSubmitLoading(false)
      console.error(e)
    }
  }

  const handleCancelClick = () => {
    window.location.href = `/app`
  }

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={methods.handleSubmit(onSubmit)}>
        <BaseInfoSection
          countriesList={countriesList}
          isEditing={isEditing}
          imageUrl={wrapperFormData.company_logo_url}
        />
        <MarketSection initialFormData={wrapperFormData} />
        <FinancialSection />
        <SectorSection
          industryGroups={industryGroups}
          initialFormData={wrapperFormData}
          isEditing={isEditing}
        />
        <ImpactSection
          impactThemes={impactThemes}
          selectedSdgs={[]}
          isEditing={isEditing}
          initialFormData={wrapperFormData}
        />
        {/* submit card */}
        <StyledCard className='submit-card'>
          <PillButton
            variant='outline-secondary'
            onClick={() => handleCancelClick()}
          >
            Cancel
          </PillButton>
          <PillButton variant='primary' type='submit' disabled={submitLoading}>
            Save Changes
          </PillButton>
        </StyledCard>
      </StyledForm>
    </FormProvider>
  )
}

CreateEditEntityForm.propTypes = {
  countriesList: PropTypes.array,
  entityId: PropTypes.number,
  impactThemes: PropTypes.array,
  industryGroups: PropTypes.array,
  isEditing: PropTypes.bool,
}
