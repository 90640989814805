import _ from 'lodash'
import numeral from 'numeral'

export const addThousandSeparator = (value, decimals) => {
  const commaStrippedValue = value.toString().replace(/,/g, '')
  if (isNaN(commaStrippedValue) || commaStrippedValue?.length === 0)
    return value

  let intValue = parseInt(commaStrippedValue)
  const decPart = commaStrippedValue - intValue

  let valuePrefix = ''
  if (intValue < 0) {
    valuePrefix = '-'
    intValue = -intValue
  }

  const valueArray = intValue.toString().split('')

  let returnValue =
    valuePrefix +
    valueArray
      .reverse()
      .map((s, i) => {
        return s + (i && i % 3 == 0 && valueArray.length - i >= 1 ? ',' : '')
      })
      .reverse()
      .join('')

  const decValue = (decPart.toFixed(decimals ? decimals : 2) + '').split('.')

  if (decPart.toFixed(decimals ? decimals : 2) != 0) {
    returnValue = returnValue + '.' + decValue[1]
  }
  if (value[value.length - 1] === '.') {
    returnValue = returnValue + '.'
  }
  return returnValue
}

export const getChartMax = value => {
  const order = getOrderOfMagnitude(value)
  return (
    (Math.ceil(value / order) - (value % order < order / 2 ? 0.5 : 0)) * order
  )
}

export const getOrderOfMagnitude = n =>
  Math.pow(10, Math.floor(Math.log(n) / Math.LN10 + 0.000000001))

export const addOrderOfMagnitudeMask = n => {
  if (Math.abs(n) > 1e6) return (n / 1e6).toFixed(0) + 'M'
  else if (Math.abs(n) > 1e3) return (n / 1e3).toFixed(0) + 'K'

  return n
}

export const abbreviateNumber = (x, decimals = 1) => {
  if (isNaN(x) || x === null) return null

  if (x == 0) return 0

  if (x < 0) {
    if (x > -999) {
      return x.toFixed(decimals)
    }

    if (x > -1000000) {
      return (x / 1000).toFixed(decimals) + 'K'
    }
    if (x > -10000000) {
      return (x / 1000000).toFixed(decimals) + 'M'
    }

    if (x > -1000000000) {
      return (x / 10000000).toFixed(decimals) + 'M'
    }

    if (x > -1000000000000) {
      return (x / 1000000000).toFixed(decimals) + 'B'
    }

    if (x > -10000000000000 || x < -10000000000000) {
      return addThousandSeparator((x / 1000000000000).toFixed(decimals)) + 'T'
    }
  }

  if (x < 999) {
    if (
      !isNaN(x / 1) &&
      !Object.prototype.toString.call(x / 1) === '[object Object]' &&
      x / 1
    ) {
      return (x / 1).toFixed(decimals)
    } else if (parseFloat(x)) {
      return (x / 1).toFixed(decimals)
    } else {
      return null
    }
  }

  if (x < 1000000) {
    return (x / 1000).toFixed(decimals) + 'K'
  }
  if (x < 10000000) {
    return (x / 1000000).toFixed(decimals) + 'M'
  }

  if (x < 1000000000) {
    return (x / 1000000).toFixed(decimals) + 'M'
  }

  if (x < 1000000000000) {
    return (x / 1000000000).toFixed(decimals) + 'B'
  }

  if (x < 10000000000000 || x > 10000000000000) {
    return addThousandSeparator((x / 1000000000000).toFixed(decimals)) + 'T'
  }
}

export const formatShort = number => {
  if (!number) return '0'

  return numeral(number).format('a0[.]0').toUpperCase()
}

/**
 * Generates last X years in an Array format
 *
 * @param {number} x Number of years to generate
 * @param {number} currentYearOffset The year(s) offset applied to the current year (can be positive(+) or negative(-) number)
 *
 * @return {Array} The Array with each year as number objects
 *
 * @example
 * generateLastXYearsArray(10);
 * // If the current year is 2021:
 * // Output: [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021]
 *
 * generateLastXYearsArray(5, -1);
 * // If the current year is 2021:
 * // Output: [2016, 2017, 2018, 2019, 2020]
 *
 */
export const generateLastXYearsArray = (x = 10, currentYearOffset = 0) => {
  let aX = x - 1
  let aCurrentYearOffset = 0

  if (
    (currentYearOffset < 0 && aX > currentYearOffset) ||
    currentYearOffset > 0
  )
    aCurrentYearOffset = currentYearOffset

  let max = new Date().getFullYear() + aCurrentYearOffset
  let min = max - aX
  let years = []

  for (let i = min; i <= max; i++) {
    years.push(i.toString())
  }
  return years
}

/**
 * Generates splitted numbers Array based on Max Value
 *
 * @param {number} maxValue The max value of the array (and the last one)
 * @param {number} splitValue The value of which the max value will be splitted with
 * @param {boolean} includeZero Specify if we include the 0 value as the first element in the result Array
 * @param {boolean} includeNotDivMaxValue Specify if we include the max value in the array, it matters just if 'maxValue' is not precisely divided by 'splitValue'
 *
 * @return {Array} The Array with each splitted value as number obects
 *
 * @example
 * generateSplittedArrayByMaxValue(250, 50);
 * // Output: [0, 50, 100, 150, 200, 250]
 *
 * generateSplittedArrayByMaxValue(251, 50, false, true);
 * // Output: [50, 100, 150, 200, 250, 251]
 *
 */
export const generateSplittedArrayByMaxValue = (
  maxValue,
  splitValue,
  includeZero = true,
  includeNotDivMaxValue = false
) => {
  let currentValue = 0
  let result = []

  if (includeZero) result.push(currentValue)
  while (currentValue + splitValue < maxValue) {
    currentValue += splitValue
    result.push(currentValue)
  }

  if (includeNotDivMaxValue && _.last(result) != maxValue) {
    result.push(maxValue)
  }

  return result
}

export const baseLog = (x, y) => {
  return Math.log(y) / Math.log(x)
}

export const roundUpNearest10 = num => {
  return Math.ceil(num / 10) * 10
}
