import { isNumeral } from 'numeral'
import PropTypes from 'prop-types'
import React from 'react'

import { MainTable } from '../../../../components/tables/styles'
import { esgScoreFullNames } from '../../../../constants'
import { abbreviateNumber } from '../../../../helpers/number'
import { displayValueOrPlaceholder } from '../../../../helpers/shared'
import {
  dataMapping,
  emptyScorePlaceholder,
  peersCustomTableStyle,
} from './constants'
import PeersHeaderRow from './PeersHeaderRow'
import ScoreTableRow from './ScoreTableRow'

const metricRank = (
  entity,
  dataProvider,
  dataDisplayType,
  metricType,
  index
) => {
  return parseFloat(
    parseFloat(
      entity[dataTypeMap(dataProvider, dataDisplayType)]?.[metricType]?.[index]
        ?.value
    ).toFixed(2)
  )
}

const dataTypeMap = (dataProvider, dataDisplayType) => {
  return dataMapping(dataProvider)[dataDisplayType]
}

const PeersMetricCard = ({
  peers,
  metricType,
  dataDisplayType,
  openReplacePeerModal,
  dataProvider,
}) => {
  const metrics =
    peers[0]?.[dataTypeMap(dataProvider, dataDisplayType)]?.[metricType]

  const retrieveTopRowScore = entity => {
    const parameters = {
      score: entity.esg_score[`${metricType}_score`],
      decimals: true,
    }
    return displayValueOrPlaceholder(parameters.score, parameters.decimals)
  }

  const retrieveScore = index => entity => {
    let metricScore = metricRank(
      entity,
      dataProvider,
      dataDisplayType,
      metricType,
      index
    )
    return displayValueOrPlaceholder(metricScore)
  }

  const retrieveScoreRange = index => entity => {
    let metricScoreRange =
      entity?.[dataTypeMap(dataProvider, dataDisplayType)]?.[metricType]?.[
        index
      ]?.value_range

    return metricScoreRange
  }

  const scoreFormatter = value => {
    if (value == emptyScorePlaceholder) return value
    return isNumeral(value) ? Math.round(value) : abbreviateNumber(value)
  }

  return (
    <MainTable customStyle={peersCustomTableStyle} look={{}}>
      <PeersHeaderRow
        peers={peers}
        openReplacePeerModal={openReplacePeerModal}
      />
      <tbody>
        <ScoreTableRow
          dataDisplayType={dataDisplayType}
          showTooltip={dataDisplayType == 'zscore'}
          scoreName={`${esgScoreFullNames[metricType]} Score`}
          retrieveScore={retrieveTopRowScore}
          retrieveScoreRange={retrieveScoreRange}
          peers={peers}
        />
        {metrics?.map((metric, index) => (
          <ScoreTableRow
            dataDisplayType={dataDisplayType}
            showTooltip={dataDisplayType == 'zscore'}
            showScorePercentage={false}
            scoreFormatter={scoreFormatter}
            key={`table-row-metric-score-${index}`}
            scoreName={metric.name}
            normUnits={metric.units}
            normUnitsShort={metric.units_short}
            retrieveScore={retrieveScore(index)}
            retrieveScoreRange={retrieveScoreRange(index)}
            peers={peers}
          />
        ))}
      </tbody>
    </MainTable>
  )
}

PeersMetricCard.propTypes = {
  peers: PropTypes.array.isRequired,
  metricType: PropTypes.string.isRequired,
  dataDisplayType: PropTypes.string.isRequired,
  dataProvider: PropTypes.string.isRequired,
  openReplacePeerModal: PropTypes.func.isRequired,
}

export default PeersMetricCard
