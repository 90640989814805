import React, { useEffect, useRef, useState } from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'

import Card from '../../../../../components/styleguide/Card'
import CardTitle from '../../../../../components/styleguide/CardTitle'
import ErrorPage from '../../../../../components/styleguide/ErrorPage'
import ExportButton from '../../../../../components/styleguide/ExportButton'
import Loading from '../../../../../components/styleguide/Loading'
import { timestamp } from '../../../../../helpers/shared'
import { downloadTemplateFromRef } from '../../../../../pdf'
import YBOverviewCard from '../../comparisons/components/cards/yb_overview_card/YBOverviewCard'
import { mapReportDataToESGLineGraph } from '../longitudinal_report_mapper'
import { loadSnapshotData } from '../longitudinal_report_repository'
import LongitudinalESGLineGraph from './graph/LongitudinalESGLineGraph'
import PortfolioLongitudinalReportTemplate from './pdf/PortfolioLongitudinalReportTemplate'
import YBOverviewCustomHeader from './table/YBOverviewCustomHeader'
import TemplateRenderContainer from './TemplateRenderContainer'

const loadReportData = ({
  portfolioId,
  setError,
  setPortfolio,
  setReportData,
}) => {
  loadSnapshotData({ portfolioId, setError, setPortfolio })
    .then(res => {
      setPortfolio({
        name: res.data.portfolio_name,
        snapshot_id: res.data.snapshot_id,
      })
      setReportData(res.data.longitudinal_report)
    })
    .catch(err => {
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

const PortfolioLongitudinalReport = ({ portfolioId }) => {
  const [error, setError] = useState(false)
  const [portfolio, setPortfolio] = useState()
  const [reportData, setReportData] = useState([])
  const [loading, setLoading] = useState({})

  useEffect(
    () =>
      loadReportData({ portfolioId, setError, setPortfolio, setReportData }),
    []
  )

  if (error) {
    return <ErrorPage code={error} message={error == 404 ? 'NOT FOUND' : ''} />
  }

  const esgGraphData = mapReportDataToESGLineGraph(reportData)
  const asOfDates = reportData.map(snapshot => snapshot.as_of)
  const snapshotIds = reportData.map(snapshot => snapshot.id)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const templateRef = useRef()
  const generatePDF = () => {
    setLoading({ show: true, message: 'Generating Report...' })
    downloadTemplateFromRef(
      templateRef,
      'full',
      `yb-${portfolio.name.split(' ').join('-')}-${timestamp}`,
      portfolioId,
      'Portfolio',
      portfolio.name,
      () => {
        setLoading({ show: false })
      }
    )
  }

  return (
    <div className='page__container' style={{ paddingTop: '1.25rem' }}>
      {portfolio && (
        <>
          <Breadcrumb style={{ marginBottom: '1.25rem' }}>
            <Breadcrumb.Item href='/app'>Home</Breadcrumb.Item>
            <Breadcrumb.Item
              href={`/app/portfolios/${portfolioId}/snapshots/${
                snapshotIds?.[snapshotIds.length - 1]
              }`}
            >
              {portfolio.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Longitudinal Report</Breadcrumb.Item>
          </Breadcrumb>

          <Card>
            <CardTitle title={'LONGITUDINAL PORTFOLIO SCORES'} />
            <ExportButton
              onClick={() => generatePDF()}
              fileType='PDF'
              className='export-btn float-right'
            />
            <br />
            {esgGraphData?.length > 0 && (
              <LongitudinalESGLineGraph data={esgGraphData} />
            )}

            <YBOverviewCard
              portfolios={reportData}
              displayEmptyColumns={false}
              customHeader={
                <YBOverviewCustomHeader
                  asOfDates={asOfDates}
                  snapshotIds={snapshotIds}
                  portfolioId={portfolioId}
                  reportData={reportData}
                  setReportData={setReportData}
                />
              }
            />
          </Card>
          <Loading show={loading.show} message={loading.message} full={true} />

          <TemplateRenderContainer landscape={true} ref={templateRef}>
            <PortfolioLongitudinalReportTemplate
              reportData={reportData}
              esgGraphData={esgGraphData}
              asOfDates={asOfDates}
              snapshotIds={snapshotIds}
              portfolioId={portfolioId}
              portfolio={portfolio}
            />
          </TemplateRenderContainer>
        </>
      )}
    </div>
  )
}

export default PortfolioLongitudinalReport
