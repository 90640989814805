import PropTypes from 'prop-types'
import React from 'react'

export const UnitHelper = ({ measure, unit }) => {
  const FormattedSpan = () => {
    switch (unit) {
      case 'Percentage (%)':
        return `${Number(measure).toLocaleString()}%`
      case '$':
        return `$${Number(measure).toLocaleString()}`
      case 'dollars':
        return `$${Number(measure).toLocaleString()}`
      case 'Number':
        return `${Number(measure).toLocaleString()}`
      case 'Reporting currency':
        return `${Number(measure).toLocaleString()} ${unit}`
      case undefined:
        return `${measure}`
      case null:
        return `${measure}`
      default:
        return `${measure} ${unit}`
    }
  }
  return <FormattedSpan />
}

UnitHelper.propTypes = {
  measure: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.string,
}
