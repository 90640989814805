import React from 'react'
import { Col, Row } from 'react-bootstrap'

import { APP_COLOR_PRIMARY } from '../../../styles/colors'
import {
  StyledCell,
  StyledCellsContainer,
  StyledContainer,
  StyledDateContainer,
  StyledLabel,
  StyledRow,
  StyledTitle,
} from './NewsEventsList.styles'

const NewsEventsList = ({
  title,
  data,
  maxCategoriesNr = null,
  isPdfTemplate = false,
  'data-cy': dataCy,
}) => {
  if (!data?.length) return null

  const renderCells = (categories, isPdfTemplate) => {
    if (!categories?.length) return
    const categoriesNr =
      maxCategoriesNr != null ? maxCategoriesNr : categories?.length

    return categories.slice(0, categoriesNr).map(category => (
      <StyledCell isPdfTemplate={isPdfTemplate} key={category}>
        {category.name}
      </StyledCell>
    ))
  }

  return (
    <StyledContainer data-cy={dataCy}>
      <StyledTitle isPdfTemplate={isPdfTemplate}>{title}</StyledTitle>

      {data.map(elem => {
        return (
          <StyledRow isPdfTemplate={isPdfTemplate} key={elem.key}>
            <Col>
              <Row>
                {elem?.instrument_id && elem?.instrument_name && (
                  <StyledLabel
                    isPdfTemplate={isPdfTemplate}
                    href={`/app/instruments/${elem?.instrument_id}`}
                    target='_blank'
                    rel='noreferrer'
                    color={APP_COLOR_PRIMARY}
                    size={'12px'}
                  >
                    {elem?.instrument_name}
                  </StyledLabel>
                )}
              </Row>
              <Row>
                <StyledLabel
                  isPdfTemplate={isPdfTemplate}
                  href={elem.url}
                  target='_blank'
                  rel='noreferrer'
                >
                  {elem.headline}
                </StyledLabel>
                <StyledDateContainer isPdfTemplate={isPdfTemplate}>
                  {elem.date}
                </StyledDateContainer>
              </Row>
              <StyledCellsContainer isPdfTemplate={isPdfTemplate}>
                {renderCells(elem.categories, isPdfTemplate)}
              </StyledCellsContainer>
            </Col>
          </StyledRow>
        )
      })}
    </StyledContainer>
  )
}

export default NewsEventsList
