import { Alert } from '@ui/components/interactive/Alert'
import { IconButton } from '@ui/components/interactive/buttons/IconButton'
import {
  SortedTableHead,
  Table,
  TableController,
  TableHead,
} from '@ui/components/presentation/Table'
import { ITableHeadSort } from '@ui/components/presentation/Table/components/SortedTableHead'
import { Button, EButtonVariants } from '@ui/elements/Button'
import { Link } from '@ui/elements/Link'
import getTestingProps from '@utils/test/getTestingProps'
import uuid from '@utils/uuid'
import moment from 'moment'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

import { SFDR_MODULES } from '../../SFDR.controller'
import { CreateDisclosureModal } from './components/CreateDisclosureModal'
import { ICreateDisclosureData } from './components/CreateDisclosureModal/CreateDisclosureModal'
import { DisclosuresFilters } from './components/DisclosuresFilters'
import { IDisclosuresFiltersData } from './components/DisclosuresFilters/DisclosuresFilters'
import { PublishDisclosureModal } from './components/PublishDisclosureModal'
import { IPublishDisclosureData } from './components/PublishDisclosureModal/PublishDisclosureModal'
import DisclosuresController, { IDisclosure } from './Disclosures.controller'
// import theme from './Disclosures.module.scss'
import './Disclosures.module.scss'
import SFDRDashbaordController from '../SFDROverview/components/SFDRDashboard/SFDRDashbaord.controller'
import { downloadTemplateFromRef } from '../../../../../../../react/pdf'
import Loading from '../../../../../../../react/components/styleguide/Loading'
import { timestamp } from '../../../../../../../react/helpers/shared'
import { getSFDRDisclosureForms } from '../../../../../../../react/repositories/portfolio_repository'
import {
  TFormAnswer,
  TFormTrigger,
  TQuestionnaire,
} from '../DisclosureDetail/data'
import { PDF } from '../../../../../../../react/pdf/pdf'
import TemplateRenderContainer from '../../../../../../../react/yb_modules/portfolio/modules/longitudinal_report/components/TemplateRenderContainer'
import Pagination from '../../../../../../../react/yb_components/styleguide/pagination/Pagination'
import { AppContext } from '../../../../../../../react/yb_stores/app_store/AppStore'

/* Types */
const TableRenderer = new TableController()

/* Styles */
const S = {
  PlaceholderPar: styled.p`
    text-align: center;
    font-weight: bold;
    grid-row: none;
  `,

  Container: styled.div`
    /* --base-input-min-height: 35px; */
    /* --base-input-width: 100%; */
  `,

  Footer: styled.footer``,

  PaginationContainer: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
}

/* Constants */
const header = [
  new TableHead('Disclosure Type', 'report_type'),
  new TableHead('Description', 'description'),
  new TableHead('State', 'state'),
  new TableHead('Publish Date', 'publish_date'),
  new TableHead('Last edited date', 'updated_at'),
  // 'Preview',
  'Manage',
  'Downloads',
]

/* Component */
export const Disclosures: React.FC<YB.IWithPortfolioIds> = ({
  portfolioId,
  snapshotId,
}) => {
  /* State */
  const [loading, setLoading] = useState<
    { show?: boolean; message?: string } | undefined
  >({ show: false })
  const [disclosures, setDisclosures] = useState<IDisclosure[] | null>(null)
  const [isConfigured, setIsConfigured] = useState<boolean>(false)
  const [fundAlignment, setFundAlignment] = useState<String | undefined>()
  const [periodicDisclosure, setPeriodicDisclosure] = useState<
    | {
        previous: string
        next: string
      }
    | undefined
  >()
  const templateRef = useRef()

  const displayCreateModel = useState<boolean>(false)
  const displayPublishModel = useState<boolean>(false)
  const displayAlertModel = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number | undefined>(1)
  const [totalPages, setTotalPages] = useState<number | undefined>(1)
  const [, setIsCreateModalOpen] = displayCreateModel
  const [, setIsPublishModalOpen] = displayPublishModel
  const [publishDisclosure, setPublishDisclosure] =
    useState<IDisclosure | null>(null)
  const [isAlertOpen, setIsAlertOpen] = displayAlertModel

  const [filters, setFilters] = useState<IDisclosuresFiltersData>()
  const sortingModel = useState<ITableHeadSort>({
    value: 'publish_date',
    order: 'desc',
  })
  const [sorting] = sortingModel
  const [state] = useContext(AppContext)
  const { target, benchmark } = state

  DisclosuresController.useGetDisclosures(
    portfolioId,
    snapshotId,
    filters,
    sorting,
    currentPage,
    useCallback(
      (
        data,
        isConfigured,
        pDisclosure,
        totalPages,
        currentPage,
        newFundAlignment
      ) => {
        setDisclosures(data)
        setIsConfigured(isConfigured as boolean)
        setPeriodicDisclosure(pDisclosure)
        setFundAlignment(newFundAlignment)
        setCurrentPage(currentPage)
        setTotalPages(totalPages)
      },
      []
    )
  )

  const customFormat = (date: string | undefined) =>
    date ? moment(date).format('MMMM DD, YYYY') : '–'

  /* Data */
  const periodicDisclosureRange = useMemo(() => {
    return {
      previous: customFormat(periodicDisclosure?.previous),
      next: customFormat(periodicDisclosure?.next),
    }
  }, [periodicDisclosure])

  const [fundConfigurationRoute] = SFDRDashbaordController.useSFDRRoutes(
    portfolioId,
    snapshotId
  )

  const disclosuresTableData = useMemo(() => {
    return (
      disclosures?.map(elem => [
        <Link key={uuid()} href={`${window.location.pathname}/${elem?.id}`}>
          {elem?.type_name}
        </Link>,
        elem?.description,
        elem?.state,
        customFormat(elem?.publish_date),
        customFormat(elem?.last_edited_date),
        // <div key={uuid()}>
        //     <IconButton variant='filePreview' onClick={() => null} />
        // </div>,
        <div className='rowActions' key={uuid()}>
          {/* <IconButton variant='edit' disabled /> */}
          <IconButton
            style={{
              color: elem?.publish_date ? '#7BD600' : '',
              cursor: elem?.publish_date ? 'initial' : 'pointer',
            }}
            variant='cloudUpload'
            onClick={() => {
              if (!elem?.publish_date) {
                setPublishDisclosure(elem)
                setIsPublishModalOpen(true)
              }
            }}
          />
        </div>,
        // TODO: ICristi add PDF support
        <div className='rowActions' key={uuid()}>
          <IconButton
            key={uuid()}
            variant='pdf'
            onClick={() => exportReport({ disclosureId: elem?.id?.toString() })}
          />
          {/* <IconButton variant='docx' />
                <IconButton variant='html' /> */}
        </div>,
      ]) || []
    )
  }, [disclosures, setPublishDisclosure, setIsPublishModalOpen])

  /* Events */
  const openCreateDisclosureModal = () => setIsCreateModalOpen(true)

  const onFilterChange = useCallback((formData: IDisclosuresFiltersData) => {
    setFilters(formData)
  }, [])

  const onCreateDisclosure = useCallback(
    (disclosure: ICreateDisclosureData) => {
      DisclosuresController.createSFDRDisclosure(
        portfolioId,
        snapshotId,
        disclosure
      ).then(response => {
        setIsCreateModalOpen(false)
        if (response?.data) setDisclosures(response?.data?.disclosures)
      })
    },
    [portfolioId, snapshotId, setIsCreateModalOpen]
  )

  const onPublishDisclosure = useCallback(
    (disclosure: IPublishDisclosureData) => {
      DisclosuresController.publishSFDRDisclosure(portfolioId, snapshotId, {
        ...publishDisclosure,
        ...disclosure,
      } as IDisclosure)
        .then(response => {
          setIsPublishModalOpen(false)
          if (response?.data?.disclosures)
            setDisclosures(response?.data?.disclosures)
        })
        .catch(err => console.log(err))
    },
    [portfolioId, snapshotId, publishDisclosure, setIsPublishModalOpen]
  )

  TableRenderer.onRenderHeader = (tableHead: TableHead | string) => {
    if (typeof tableHead === 'string') {
      return <th key={tableHead}>{tableHead}</th>
    }

    return (
      <SortedTableHead
        data={tableHead}
        sortingModel={sortingModel}
        key={tableHead.label}
      />
    )
  }

  function exportReport({
    disclosureId,
    reportVersion,
    timestamp: localTimestamp,
  }: {
    disclosureId: string | undefined
    reportVersion?: string | undefined
    timestamp?: any
  }) {
    setLoading({ show: true, message: 'Generating Report...' })

    getSFDRDisclosureForms(portfolioId, snapshotId, disclosureId).then(
      async response => {
        const triggers = response?.data?.triggers as TFormTrigger
        const answers = response?.data?.answers as TFormAnswer
        const questionnaires = response?.data
          ?.questionnaires as TQuestionnaire[]

        setPdfData({ triggers, answers, questionnaires, disclosureId })
      }
    )
  }

  const [pdfData, setPdfData] = useState<{
    disclosureId?: string
    triggers?: TFormTrigger
    answers?: TFormAnswer
    questionnaires?: TQuestionnaire[]
  }>({})

  useEffect(() => {
    if (pdfData?.triggers && pdfData?.answers && pdfData?.questionnaires) {
      setTimeout(() => {
        downloadTemplateFromRef(
          templateRef,
          'full',
          `yb-${target?.name?.split(' ')?.join('-')}-Disclosure-Details-${
            pdfData?.disclosureId
          }-${timestamp}`, // `yb-${snapshot.name.split(' ').join('-')}-${timestamp}`,
          snapshotId,
          'Report',
          snapshotId, // snapshot.name,
          () => {
            setLoading({ show: false })
          }
        )
      }, 2000)
    }
  }, [pdfData])

  /* Render */
  const renderTable = () => {
    if (!fundAlignment || fundAlignment === 'article_6') {
      return (
        <>
          <S.PlaceholderPar>
            The creation and publication of disclosures is only available for
            Articles 8 and 9.
          </S.PlaceholderPar>
          <S.PlaceholderPar>
            Visit the <a href={fundConfigurationRoute}>Fund Configuration</a>{' '}
            page to change those options.
          </S.PlaceholderPar>
        </>
      )
    }
    return (
      <>
        <DisclosuresFilters onChange={onFilterChange} />

        <Table
          name='disclosuresTable'
          data={disclosuresTableData as unknown[]}
          header={header}
          renderer={TableRenderer}
        />

        <S.Footer className='footer'>
          <S.PaginationContainer>
            <Pagination
              max={totalPages}
              selected={currentPage}
              onChange={newPage => setCurrentPage(newPage)}
            />
          </S.PaginationContainer>
          <div className='createNewDisclosureBtnContainer'>
            <Button
              style={{ width: '215px', height: '40px' }}
              variant={EButtonVariants.secondary}
              onClick={openCreateDisclosureModal}
              disabled={!isConfigured}
            >
              <i className='fal fa-file-plus' />{' '}
              <span>Create new disclosure</span>
            </Button>
            {!isConfigured && (
              <div className='configurationMissingText'>
                Please complete{' '}
                <a
                  href={`/app/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/sfdr/${SFDR_MODULES.FUND_CONFIGURATION}`}
                >
                  Fund Configuration
                </a>{' '}
                to create a disclosure!
              </div>
            )}
          </div>
        </S.Footer>
      </>
    )
  }

  return (
    <div>
      <div className='disclosureDescription'>
        <p
          className='tabDetail'
          {...getTestingProps('disclosureTabDescription')}
        >
          This is the Periodic Disclosures Workspace. The purpose of periodic
          disclosure is so that potential investors can see how the investment
          product performs over time given its marketed sustainability
          attributes. For example, if your investment product uses a specialized
          carbon benchmark, you will show investors how this product has fallen
          below the benchmark’s reported carbon over the last few quarters. The
          specified benchmark’s data is compared to the carbon data that you
          have for a year of your live product. This information will be used to
          answer various questions in the questionnaire as well as changes over
          time in the PAI results and the EU Taxonomy results.
        </p>
        <div
          className='periodicDisclosureCard'
          {...getTestingProps('disclosureCadenceCard')}
        >
          <h6>Periodic Disclosure</h6>
          <p>
            Your last periodic disclosure was published on{' '}
            {periodicDisclosureRange?.previous}. Periodic disclosures are
            required to be published every year.
          </p>
          <p className='periodicDisclosureDisclaimer'>
            Your next period disclosure should be published by{' '}
            <strong>{periodicDisclosureRange?.next}</strong>.
          </p>
        </div>
      </div>

      {renderTable()}

      <CreateDisclosureModal
        showModel={displayCreateModel}
        disclosures={disclosures}
        onSubmit={onCreateDisclosure}
      />
      <PublishDisclosureModal
        key={publishDisclosure?.id}
        disclosure={publishDisclosure}
        showModel={displayPublishModel}
        onSubmit={onPublishDisclosure}
      />
      <Alert
        title='Are you sure you want to change the article alignment for your fund?'
        type='warning'
        show={isAlertOpen}
        onHide={() => setIsAlertOpen(false)}
        onSubmit={() => console.log('Hey')}
      >
        <p>
          You&apos;ve chosen to change the article alignment for your fund. By
          doing this, you might need to fill out additional details and publish
          additional disclosures.
        </p>
      </Alert>
      <Loading show={loading?.show} message={loading?.message} full={true} />

      <TemplateRenderContainer ref={templateRef} key={pdfData?.disclosureId}>
        <S.Container>
          <PDF.DisclosureDetail
            targetName={target?.name}
            benchmarkName={benchmark?.name}
            disclosureId={pdfData?.disclosureId}
            portfolioId={portfolioId}
            snapshotId={snapshotId}
            triggers={pdfData?.triggers}
            answers={pdfData?.answers}
            questionnaires={pdfData?.questionnaires}
          />
        </S.Container>
      </TemplateRenderContainer>
    </div>
  )
}
