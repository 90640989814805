import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import CardTitle from '../../../../components/styleguide/CardTitle'
import ErrorPage from '../../../../components/styleguide/ErrorPage'
import {
  abbreviateNumber,
  addThousandSeparator,
} from '../../../../helpers/number'
import { formatTextCell } from '../../../../helpers/shared'
import withErrorBoundary from '../../../../hocs/withErrorBoundary'
import {
  FONT_COLOR_PRIMARY,
  FONT_COLOR_SECONDARY,
} from '../../../../styles/colors'
import {
  FilledExplainerLink,
  LinkIcon,
} from '../../../../yb_components/styleguide/links/Link.styles'
import SpinnerContainer from '../../../../yb_components/styleguide/spinners/SpinnerContainer'
import CarbonReportCard from './components/CarbonReportCard'
import { ModuleDescription, StyledCellSubValue } from './styles'

/* Styled Components */

const StyledContent = styled.div`
  flex-direction: ${props => (props.pdf ? 'column' : 'row')};
  display: flex;
  ${props => (props.pdf ? 'height: 200px;' : '')};
  ${StyledCellSubValue} {
    ${props => (props.pdf ? 'font-size: 14px !important;' : '')}
  }
`

const CustomExplainerLink = styled(FilledExplainerLink)`
  float: right;
`

const StyledCell = styled.div`
  width: 466px;
  height: 200px;
  background-color: #fafafa;
  border-radius: 20px;
`

const StyledCellTitle = styled.div`
  margin: 10px 0 20px 20px;
  font-size: ${props => (props.pdf ? '14px' : '16px')};
  font-weight: bold;
`

const StyledImageCellContent = styled.div`
  text-align: center;
  padding: ${props => props.padding || '0px'};
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => (props.pdf ? 'margin-bottom: 10px;' : '')}
`

const StyledCellContent = styled.div`
  text-align: inherit;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  ${props => props.pdf && 'height: 150px;'}
`

const StyledCellValue = styled.div`
  display: inline;
  margin: ${props => (props.margin ? props.margin : '0px')};
  color: ${FONT_COLOR_PRIMARY};
  font-size: ${props => (props.small ? '24px' : '40px')};
`

const StyledImage = styled.img`
  margin-right: 30px;
  ${props =>
    props.pdf &&
    css`
      height: 70px;
      width: 70px;
    `}
`

const StyledText = styled.div`
  color: ${FONT_COLOR_PRIMARY};
  font-size: ${props => (props.pdf ? '20px' : '36px')};
  font-weight: bold;
  display: block;
  height: 90px;
  line-height: 35px;
  span:last-child {
    position: relative;
    bottom: 13px;
  }
`

const StyledSubText = styled.div`
  color: ${FONT_COLOR_SECONDARY};
  font-size: ${props => (props.pdf ? '10px' : '14px')};
  font-weight: 700;
  display: flex;
  height: 60px;
  align-items: center;
  width: 160px;
  justify-content: center;
  margin-bottom: 10px;
`

/* Component */
const ReportSummaryCard = ({
  pdf,
  landscapePdf,
  dataProp = {
    footPrint: {
      value: '',
      benchmarkValue: '',
      symbol: '',
      customComparativeStrings: {
        green: '',
        red: '',
      },
    },
    dataBreakdown: [
      {
        text: '',
        upperText: '',
        value: 0,
        benchmarkValue: 0,
        customComparativeFunction: () => {},
        customComparativeStrings: {
          green: '',
          red: '',
        },
      },
    ],
  },
  moduleDescription = 'This is the module description.',
  title = 'This is the title',
  explainerLink = '',
}) => {
  /* Hooks */
  const [isLoading, setIsLoading] = useState(!pdf)
  const [data, setData] = useState(dataProp)

  useEffect(() => {
    if (dataProp.dataBreakdown.length === 4 || dataProp?.err?.response) {
      setIsLoading(false)
      setData(dataProp)
    }
  }, [dataProp])

  const formatValue = (value, decimals = 2) => {
    if (value === 0 || value !== null) {
      return value
    } else {
      const parsedValue = value ? parseFloat(value) : null
      return parsedValue || !isNaN(parsedValue)
        ? parsedValue?.toFixed(decimals)
        : '—'
    }
  }

  const defaultDifferenceRender = (value, benchmarkValue, customResponse) => {
    if (benchmarkValue !== 0 && !benchmarkValue) return

    const fValue = isNaN(parseFloat(value)) ? null : parseFloat(value)
    const fBenchmarkValue = isNaN(parseFloat(benchmarkValue))
      ? null
      : parseFloat(benchmarkValue)

    let positive = false
    let difference = 0
    if (fValue && fBenchmarkValue) {
      difference = ((fValue - fBenchmarkValue) / fBenchmarkValue) * 100
      positive = difference < 0
      difference = Math.abs(difference)
    }
    return (
      <StyledCellSubValue positive={positive}>
        {positive
          ? customResponse?.green
            ? `${formatValue(addThousandSeparator(Math.round(difference)))}% ${
                customResponse.green
              }`
            : `${formatValue(
                addThousandSeparator(Math.round(difference))
              )}% less than the benchmark.`
          : customResponse?.red
          ? `${formatValue(addThousandSeparator(Math.round(difference)))}% ${
              customResponse.red
            }`
          : `${formatValue(
              addThousandSeparator(Math.round(difference))
            )}% more than benchmark.`}
      </StyledCellSubValue>
    )
  }

  /* Render */
  const renderImageCell = ({
    value,
    symbol,
    benchmarkValue,
    customComparativeStrings,
    pdf,
  }) => {
    const fValue = isNaN(parseFloat(value)) ? null : parseFloat(value)
    return (
      <StyledCell>
        <StyledCellTitle pdf={pdf}>CARBON FOOTPRINT</StyledCellTitle>
        <StyledImageCellContent pdf={pdf}>
          <StyledImage pdf={pdf} src={`/impact_icons/ico_carbon.svg`} />
          <div>
            <StyledCellValue small={pdf}>
              {abbreviateNumber(fValue)}
              <StyledCellValue small margin={'0 0 0 15px'}>
                {symbol}
              </StyledCellValue>
            </StyledCellValue>
            {defaultDifferenceRender(
              value,
              benchmarkValue,
              customComparativeStrings
            )}
          </div>
        </StyledImageCellContent>
      </StyledCell>
    )
  }

  const renderTextCell = (text, upperText, index, pdf) => {
    return (
      <StyledCellContent pdf={pdf} key={index}>
        <StyledSubText pdf={pdf}>
          <span>{upperText}</span>
        </StyledSubText>
        <StyledText pdf={pdf}>{text}</StyledText>
      </StyledCellContent>
    )
  }

  const renderResult = () => {
    let content = data?.dataBreakdown.map((obj, index) => {
      let width = '250px'
      if (pdf) width = '120px'
      if (landscapePdf) width = '250px'

      return (
        <div
          key={index}
          style={{
            padding: '0px 15px 0px 15px',
            width: width,
            marginTop: pdf ? '20px' : '0',
          }}
        >
          {renderTextCell(formatTextCell(obj.text), obj.upperText, index, pdf)}
          <div>
            {obj.customComparativeFunction
              ? obj.customComparativeFunction(obj.value, obj.benchmarkValue)
              : defaultDifferenceRender(
                  obj.value,
                  obj.benchmarkValue,
                  obj.customComparativeStrings
                )}
          </div>
        </div>
      )
    })

    if (pdf)
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {content}
        </div>
      )
    else return content
  }

  return (
    <CarbonReportCard pdf={pdf || landscapePdf}>
      {!pdf && !landscapePdf && (
        <>
          <CardTitle title={title} />

          <CustomExplainerLink pdf={pdf} href={explainerLink} target='_blank'>
            <LinkIcon className='fa fa-question' />
          </CustomExplainerLink>

          <ModuleDescription marginBottom={'10px'}>
            {moduleDescription}
          </ModuleDescription>
        </>
      )}

      <SpinnerContainer isLoading={isLoading}>
        {dataProp?.err?.response ? (
          [
            <br key={0} />,
            <ErrorPage
              key={1}
              code={dataProp?.err?.response}
              message={dataProp?.err?.response == 404 ? 'NOT FOUND' : ''}
            />,
          ]
        ) : (
          <StyledContent pdf={pdf}>
            {renderImageCell({
              value: formatValue(data?.footPrint?.value),
              benchmarkValue: data?.footPrint?.benchmarkValue,
              symbol: data?.footPrint?.symbol,
              customComparativeStrings:
                data?.footPrint?.customComparativeStrings,
              pdf,
            })}
            {renderResult()}
          </StyledContent>
        )}
      </SpinnerContainer>
    </CarbonReportCard>
  )
}

export default withErrorBoundary(ReportSummaryCard)
