import React from 'react'
import { renderToString } from 'react-dom/server'
import ReactGA from 'react-ga'
import request from 'request'

import { createDownloadPdfEvent } from '../repositories/events_repository'

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_KEY)

const templateBaseUrl = () => {
  switch (process.env.PIPELINE_ENV) {
    case 'review':
      return `http://${process.env.HEROKU_APP_NAME}.herokuapp.com`
    case 'staging':
      return process.env.SITE_URL
    case 'production':
      return process.env.SITE_URL
    default:
      // You can hard code this URL to a specific Ngrok proxy or if you're running the `yarn dev:proxy` command
      // you can use process.env.NGROK_PROXY_URL
      // return 'http://85c0c8bf.ngrok.io';

      return process.env.NGROK_PROXY_URL
  }
}

/* Get the styled-components styles from the document */
function getComponentsStyle() {
  const componentsStyle = [...document.querySelectorAll('style[data-styled]')]
    .map(styleTag => {
      return Object.values(styleTag.sheet.cssRules)
        .map(rule => rule.cssText)
        .join(' ')
    })
    .join(' ')

  return componentsStyle.replace(
    /(body \{[^\}]+)background-color: #F4F6F9;\n\}/gi,
    '$1}'
  )
}

function getSassStyle() {
  const componentsStyle = [
    ...document.querySelectorAll('style[data-sass-component]'),
  ]
    .map(styleTag => {
      return Object.values(styleTag.sheet.cssRules)
        .map(rule => rule.cssText)
        .join(' ')
    })
    .join(' ')

  return componentsStyle
}

/* Convert React component ref to plain HTML (required for components that are not SSR / some libraries) */
function refToInnerHtml(ref) {
  if (!ref) return null
  if (!ref.current) return null

  return ref.current.innerHTML.replace(/\/\//g, '')
}

/* Filter imported stylesheets to only those which are needed in the current context */
const buildHeadStylesheets = targetType => {
  const baseStylesheets = [
    '/pdf/badges.css',
    '/pdf/components.css',
    '/pdf/sections.css',
    '/pdf/charts.css',
    '/pdf/LineChart.css',
  ]

  let specificStylesheets = []

  if (['Instrument', 'Portfolio::Snapshot'].includes(targetType)) {
    specificStylesheets = [
      ...specificStylesheets,
      '/pdf/ESGMetrics.css',
      '/pdf/ESGMomentumLineChart.css',
      '/pdf/BubbleChartNews.css',
      '/pdf/SDGExposure.css',
      '/pdf/CO2ProjectionChart-pdf.css',
    ]
  }
  if (['Instrument', 'EntityPeers'].includes(targetType)) {
    specificStylesheets = [...specificStylesheets, '/pdf/Peers.css']
  }

  if (['TCFD', 'CarbonReport', 'Report'].includes(targetType)) {
    specificStylesheets = [
      ...specificStylesheets,
      '/pdf/page_layout/Report.css',
    ]
  } else {
    specificStylesheets = [
      ...specificStylesheets,
      '/pdf/page_layout/Default.css',
    ]
  }

  const stylesheets = [...baseStylesheets, ...specificStylesheets]
    .map(stylesheet => `<link href="${stylesheet}" rel="stylesheet">`)
    .join('\n')

  return stylesheets
}

const downloadTemplateBase = (
  html,
  version,
  fileName = 'test',
  target_id,
  target_type,
  target_name,
  callback
) => {
  const content = `
    <html>
      <head>
        <style>
          ${getSassStyle()}
          ${getComponentsStyle()}
        </style>
        ${buildHeadStylesheets(target_type)}
        <link href="https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i&display=swap" rel="stylesheet">
        <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.7.0/css/all.css" integrity="sha384-6jHF7Z3XI3fF4XZixAuSu0gGKrXwoX/w3uFPxC56OtjChio7wtTGJWRW53Nhx6Ev" crossorigin="anonymous">

      </head>
      <body>
        ${html}
      </body>
    </html>
  `

  const test_mode = process.env.PIPELINE_ENV != 'production'

  const config = {
    url: 'https://docraptor.com/docs',
    encoding: null, //IMPORTANT! This produces a binary body response instead of text
    headers: {
      'Content-Type': 'application/json',
    },
    json: {
      user_credentials: process.env.DOCRAPTOR_API_KEY,
      doc: {
        document_content: content,
        type: 'pdf',
        test: test_mode,
        javascript: true,
        prince_options: {
          media: 'screen', // use screen styles instead of print styles
          baseurl: templateBaseUrl(), // URL to use for generating absolute URLs for assets from relative URLs
        },
      },
    },
  }

  request.post(config, function (err, response, body) {
    var type = response.headers['content-type']
    var blob = new Blob([body], { type: type })
    var filename = `${fileName}.pdf`

    var URL = window.URL || window.webkitURL
    var downloadUrl = URL.createObjectURL(blob)

    if (filename) {
      // use HTML5 a[download] attribute to specify filename
      var a = document.createElement('a')
      // safari doesn't support this yet
      if (typeof a.download === 'undefined') {
        window.location = downloadUrl
      } else {
        a.href = downloadUrl
        a.download = filename
        document.body.appendChild(a)
        a.click()
      }
    } else {
      window.location = downloadUrl
    }

    if (callback && typeof callback == 'function') {
      callback()
    }

    ReactGA.event({
      category: target_type,
      action: `${version}-report-download`,
      label: filename,
      value: target_id,
    })

    createDownloadPdfEvent({
      event: {
        target_id,
        target_type,
        target_name,
      },
    })
  })
}

export const downloadTemplate = (
  template,
  version,
  fileName = 'test',
  target_id,
  target_type,
  target_name,
  callback
) => {
  downloadTemplateBase(
    renderToString(template),
    version,
    fileName,
    target_id,
    target_type,
    target_name,
    callback
  )
}

export const downloadTemplateFromRef = (
  ref,
  version = 'full',
  fileName = 'test',
  target_id,
  target_type,
  target_name,
  callback
) => {
  downloadTemplateBase(
    refToInnerHtml(ref),
    version,
    fileName,
    target_id,
    target_type,
    target_name,
    callback
  )
}
