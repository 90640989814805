import axios from 'axios';

export const getAllIndustryGroups = () => {
    return axios.get(`/app/api/industry_groups`);
};

export const getIndustryGroup = (id) => {
    return axios.get(`/app/api/industry_groups/${id}`);
};

export const searchESGYearlyMetrics = (params) => {
    const baseUrl = `/app/api/esg_yearly_metrics/search`;
    return axios.get(baseUrl, { params: params });
};