import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const YBBreadcrumb = ({ breadcrumbs, ...props }) => {
  return (
    !_.isEmpty(breadcrumbs) && (
      <Breadcrumb data-cy={props['data-cy']}>
        {
          breadcrumbs.map((breadcrumb, idx) => {
            return <Breadcrumb.Item key={"breadcrumb-" + idx}
              href={breadcrumb.link} active={breadcrumb.active}>{breadcrumb.name}</Breadcrumb.Item>
          })
        }
      </Breadcrumb>
    )
  )
}

export default YBBreadcrumb
