import React, { useState, useMemo, useRef, useEffect } from 'react';
import InstrumentSelect from './InstrumentSelect';
import TextInput from '../../../../components/styleguide/TextInput';
import { addThousandSeparator } from '../../../../helpers/number';
import { usePrevious } from '../../../../helpers/custom_hooks'

const TableRow = ({ index, holding, handleInstrumentChange, handleCreateNewInstrument, handleHoldingChange, handleOnHoldingDelete, holdings}) => {
  const holdingValueRef = useRef(null)
  const [cursorPosition, setCursorPosition] = useState(null)
  
  useEffect(() => {
    if (cursorPosition != null && holdingValueRef.current) {
      holdingValueRef.current.setSelectionRange(cursorPosition, cursorPosition)
    }
  })

  const holdingValuePlain = holding.value.toString().replace(/,/g, '')
  const holdingValueThousands = addThousandSeparator(holding.value)
  const prevHoldingValue = usePrevious(holdingValuePlain)

  const repositionCursorOnSeparatorChanges = () => {
    const leadingDigitOffset = holdingValuePlain.split('.')[0].length % 3
    const isFillingInDecimals = holdingValueThousands.slice(0, cursorPosition).indexOf('.') > -1

    if (prevHoldingValue?.length < holdingValuePlain.length && leadingDigitOffset === 1 && !isFillingInDecimals) {
      setCursorPosition(cursorPosition + 1)
    } else if (prevHoldingValue?.length > holdingValuePlain.length && leadingDigitOffset === 0 && !isFillingInDecimals) {
      setCursorPosition(Math.max(cursorPosition - 1, 0))
    }
  }

  useEffect(repositionCursorOnSeparatorChanges, [holding.value])

  return useMemo(() => (
    <tr key={`holding-${holding.instrument_id}-${index}`}>
      <td className="holding-name-column">
        <InstrumentSelect
          className="instrument-select"
          defaultValue={{
                          value: { name: holding.instrument_name, id: holding.instrument_id },
                          label: holding.instrument_name
                        }}
          onChange={ (instrument) => handleInstrumentChange(holding, instrument) }
          onCreateInstrument={(newInstrumentName) => handleCreateNewInstrument(newInstrumentName)}
        />
      </td>
      <td className="holding-description-column">
        <TextInput
          width="100%"
          height="50px"
          paddingBottom="0px"
          marginBottom="7px"
          marginTop="7px"
          id={`description-${holding.instrument_id}-${index}`}
          value={ holding.description }
          onChange={ (id, value, e) => handleHoldingChange(holding, 'description', e) }
        />
      </td>
      <td className="holding-value-column">
        <TextInput
          inputRef={holdingValueRef}
          width="100%"
          height="50px"
          paddingBottom="0px"
          marginBottom="7px"
          className="text-right"
          marginTop="7px"
          id={`value-${holding.instrument_id}-${index}`}
          value={ holdingValueThousands }
          prepend="$"
          onChange={ (id, value, e) => {
            handleHoldingChange(holding, 'value', e)
            setCursorPosition(e.target.selectionStart)
          }}
        />
      </td>
      <td className="holding-action-column" onClick={ () => handleOnHoldingDelete(holding.key) }>
        <img src="/trash-icon.svg"/>
      </td>
    </tr>
  ), [holding.description, holding.value, holding.instrument_name, holding.instrument_id, holdings.length, holdingValueRef])
}

const TableBody = ({setHoldings, holdings, setInstrumentModalOpen, setCreatedInstrumentName, paginatedHoldings}) => {
  const handleInstrumentChange = (holding, newInstrument) => {
    let newHoldings = [...holdings]
    let newHolding = newHoldings.find((newHolding) => { return newHolding.key === holding.key })

    newHolding.instrument_id = newInstrument.id
    newHolding.instrument_name = newInstrument.name
    newHolding.isin = newInstrument.isin

    setHoldings(newHoldings)
  }

  const handleHoldingChange = (holding, column, event) => {
    const newHoldings = holdings.slice()

    let newHolding = newHoldings.find((newHolding) => {
      return holding.key === newHolding.key
    })
    
    if (column === 'value') {
      newHolding[column] = event.target.value.replace(/,/g, '')
    } else {
      newHolding[column] = event.target.value
    }
    
    setHoldings(newHoldings)
  }

  const handleOnHoldingDelete = (key) => {
    const newHoldings = _.filter(holdings, (holding) => {
      return holding.key !== key
    })

    setHoldings(newHoldings)
  }

  const handleCreateNewInstrument = (newInstrumentName) => {
    setInstrumentModalOpen(true)
    setCreatedInstrumentName(newInstrumentName)
  }

  const rows = paginatedHoldings.map((holding, index) => (
    <TableRow
      key={`table-row-${holding.instrument_id}-${index}`}
      index={index}
      holding={holding}
      holdings={holdings}
      handleInstrumentChange={handleInstrumentChange}
      handleHoldingChange={handleHoldingChange}
      handleCreateNewInstrument={handleCreateNewInstrument}
      handleOnHoldingDelete={handleOnHoldingDelete}
    />
  ))

  return rows
}

export default TableBody
