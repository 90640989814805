import React, { useState, useEffect } from 'react';
import YBBreadcrumb from '../../../../../components/styleguide/YBBreadcrumb';
import { getUploadLog } from '../upload_log_repository';
import { BG_COLOR_SECONDARY, FONT_COLOR_SECONDARY } from '../../../../../styles/colors';
import styled from 'styled-components';

const Logger = styled.div`
  height: 78vh;
  overflow: auto;
  border: 1px solid #D8D8D8;
  border-radius: 5px;
  background: ${BG_COLOR_SECONDARY};
  padding: 20px;
`

const LogItem = styled.div`
  font-family: monospace;
  font-size: 12px;
`

const loadUploadLog = ({portfolioId, snapshotId, setUploadLog, setPortfolioName}) => {
  getUploadLog({ portfolioId, snapshotId }).then(res => {
    setUploadLog(res.data.upload_logs)
    setPortfolioName(res.data.portfolio_name)
  })
}

const SnapshotUploadLog = ({ portfolioId, snapshotId}) => {
  const [ uploadLog, setUploadLog ] = useState([])
  const [ portfolioName, setPortfolioName ] = useState()

  const breadcrumbs = [
    { link: "/app", name: "Home", active: false },
    { link: `/app/portfolios/${portfolioId}/snapshots/${snapshotId}`, name: portfolioName, active: false },
    { link: null, name: "Upload Log", active: true },
  ]

  useEffect(() => loadUploadLog({portfolioId, snapshotId, setUploadLog, setPortfolioName}), [])
  
  return (
    <>
      {portfolioName && (
        <>
          <YBBreadcrumb breadcrumbs={ breadcrumbs } />

          <Logger>
            {uploadLog.map(item => (
              <LogItem>{item.text}</LogItem>
            ))}
          </Logger>
        </>
      )}
    </>
  )
}

export default SnapshotUploadLog