import { BaseInput } from '@ui/components/interactive/BaseInput'
import {
  Table,
  TableCategory,
  TableController,
  TableHead,
} from '@ui/components/presentation/Table'

import { HoldingAlignmentDetailFilters } from './components/HoldingAlignmentFilters'
// import theme from './HoldingAlignmentDetail.module.scss'
import './HoldingAlignmentDetail.module.scss'
import React from 'react'

const TableRenderer = new TableController()

export const HoldingAlignmentDetail = () => {
  const header = [
    new TableHead('Data point', 'dataPoint'),
    new TableHead('Unit', 'unit'),
    new TableHead('ESG Data', 'refinitiv'),
    new TableHead('Ideal Ratings', 'idealRatings'),
    new TableHead('Custom Data', 'customData'),
  ]

  const row = ['1(a). Scope 1 GHG emissions', 'tCO2e', '32,543.00', '32,543.00']

  const category = new TableCategory(
    'Greenhouse gas emissions',
    new Array(3).fill(row)
  )

  const data = new TableCategory(
    'SUSTAINABLE CHARACTERISTICS',
    new Array(3).fill(category)
  )

  TableRenderer.onRenderRow = ([
    dataPoint,
    unit,
    refinitiv,
    idealRatings,
  ]: typeof row) => {
    return (
      <tr className='tableRow'>
        <td>{dataPoint}</td>
        <td>{unit}</td>
        <td>{refinitiv}</td>
        <td>{idealRatings}</td>
        <td>
          <BaseInput name='' />
        </td>
      </tr>
    )
  }

  return (
    <>
      <p className='tabDetail'>
        Mauris a diam at magna sagittis maximus eget sed purus. Nullam dapibus
        est sapien, non blandit arcu viverra vitae. Aenean sit amet ex quis
        lacus blandit auctor. Maecenas cursus ut nibh vitae accumsan. Aliquam
        ullamcorper leo magna, non rhoncus eros sodales eu. Duis at turpis arcu.
        Fusce in porta felis. Mauris sed sollicitudin diam. Quisque eu interdum
        ligula. Mauris a diam at magna sagittis maximus eget sed purus. Nullam
        dapibus est sapien, non blandit arcu viverra vitae. Aenean sit amet ex
        quis lacus blandit. Find out more information in our.
      </p>
      <div>
        <div className='filtersContainer'>
          <h3>Fox Factory Holding Corp</h3>
          <HoldingAlignmentDetailFilters />
        </div>
        <Table
          name='holdingAlignmentTable'
          data={new Array(3).fill(data)}
          header={header}
          renderer={TableRenderer}
        />
      </div>
    </>
  )
}
