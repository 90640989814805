import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';


const TabHeader = ({ tabs, activeTab, onChange, dataLabel }) => {

    return (
        <Tabs
            data-cy={dataLabel}
            activeKey={activeTab}
            onSelect={(k) => onChange(k)}
        >
            {
                tabs.map((t, i) => {
                    return (
                        <Tab 
                            data-cy={`${dataLabel}:tab-${i}`}
                            key={`${dataLabel}:tab-${i}`}
                            eventKey={t} 
                            title={t} 
                        />
                    )
                })
            }
        </Tabs>
    );
}



export default TabHeader;
