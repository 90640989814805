import React from 'react'
import { CancelButton } from '../buttons/CancelButton'
import { SaveButton } from '../buttons/SaveButton'
// import theme from './FormActions.module.scss'
import './FormActions.module.scss'

export interface IProps {
  onCancel: () => void
  onSubmit: () => void
  canSubmit: boolean
  submitLabel?: string
}

export const FormActions: React.FC<IProps> = ({
  onCancel,
  onSubmit,
  canSubmit,
  submitLabel,
}) => (
  <div className='FormActions'>
    {onCancel && <CancelButton onClick={onCancel} />}
    <SaveButton disabled={!canSubmit} onClick={onSubmit}>
      {submitLabel}
    </SaveButton>
  </div>
)
