import React from 'react';
import styled from 'styled-components';

const H1 = styled.h1`
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 0;
  ${props => props.color ? `color: ${props.color};` : ''}
`;

const Heading1 = ({ text, color }) => {
  return (
    <H1 color={color}>
      {text}
    </H1>
  );
}

export default Heading1;