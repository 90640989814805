import React from 'react'

const CircleChartKeyPDF = ({
  isPdfTemplate = false,
  classes = {},
  labels,
  useKey,
}) => {
  return (
    <div className={classes.wrapper || 'ESG-chart-key-pdf-wrapper'}>
      {labels.map((label, i) => {
        return (
          <div key={`${useKey}-${i}`} className={classes.markerWrapper}>
            <div className={label.className}></div>
            <div style={isPdfTemplate ? { fontSize: '10px' } : {}}>
              {label.name}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default CircleChartKeyPDF
