import React from 'react';
import styled from 'styled-components';

import { FONT_COLOR_SECONDARY } from '../../styles/colors';

const TSpan = styled.tspan`
  fill: ${FONT_COLOR_SECONDARY};
`;

const ChartLabel = ({ label, ...props }) => {
  return (
    <TSpan {...props}>
      {label}
    </TSpan>
  );
}

export default ChartLabel;