import styled from 'styled-components'

export const StyledRisks = styled.div`
  padding: 1.25rem 0;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: justify;
  gap: 10px;
  margin: ${props => (props.pdfStyle ? '0 0 25px 0' : 'auto')};

  .risk-item {
    margin: 0;
    padding: 0;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
  }
  span {
    display: block;
    text-transform: uppercase;
    font-weight: 700;
  }
  .risk-text {
    color: #979ca6;
    font-size: 14px;
    line-height: 1.2;
  }
  .risk-value {
    color: #2e384d;
    font-size: 36px;
    line-height: 1.1;
  }
`
