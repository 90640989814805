import React from 'react'
import { Box } from '@ui/elements/grouping/Box'

// import theme from './InputFeedback.module.scss'
import './InputFeedback.module.scss'

interface IProps {
  isPresent: boolean
  feedbackMessage: string
}

export const InputFeedback: React.FC<IProps> = ({
  isPresent,
  feedbackMessage,
}) => (
  <Box isPresent={isPresent}>
    <p className='InputFeedback'>{feedbackMessage}</p>
  </Box>
)
