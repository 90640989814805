import React from 'react'
import { Box } from '@ui/elements/grouping/Box'
import classNames from 'classnames'

// import theme from './FormControl.module.scss'
import './FormControl.module.scss'
import SanitizedHTML from '../../../../react/yb_components/security/SanitizedHTML'

interface IFormControlProps {
  name: string
  label: string
  className?: string
  error?: string
  required?: boolean
  showOptionalBadge?: boolean
}

export default function withFormControl<T>(InputComponent: React.FC<T>) {
  // eslint-disable-next-line react/display-name
  return (props: IFormControlProps & T) => {
    const {
      label,
      error,
      className,
      name,
      required = true,
      showOptionalBadge = true,
      ...rest
    } = props

    return (
      <div
        className={classNames('FormControl', className, {
          invalid: !!error,
        })}
      >
        <Box isPresent={!!label}>
          <label htmlFor={name} id={`${name}-label`}>
            <SanitizedHTML stringTemplate={`${label} `} />
            <Box isPresent={!required && showOptionalBadge}>
              <small>(optional)</small>
            </Box>
          </label>
        </Box>
        <InputComponent {...props} {...rest} required={required} id={name} />
      </div>
    )
  }
}
