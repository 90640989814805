import React from 'react'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter } from 'react-router-dom'

import withErrorBoundary from './react/hocs/withErrorBoundary'
import { mapImpactBenchmarksToState } from './react/mappers/impact_benchmarks_mapper'
import Account from './react/modules/account/Account'
import Badges from './react/modules/badges/Badges'
import Dashboard from './react/modules/dashboard/Dashboard'
import OrganizationEventLog from './react/modules/events/OrganizationEventLog'
import PortfolioEventLog from './react/modules/events/PortfolioEventLog'
import FundIndex from './react/modules/fund/FundIndex'
import FundNew from './react/modules/fund/FundNew'
import FundShow from './react/modules/fund/FundShow'
import HoldingShowPage from './react/modules/fund/modules/holding/HoldingShowPage'
import Holding from './react/modules/hedge_fund/Holding'
import RiskAssessment from './react/modules/hedge_fund/RiskAssessment'
import Instrument from './react/modules/instrument/Instrument'
import ManagersFundSearch from './react/modules/managers/ManagersFundSearch'
import ManagersFundShow from './react/modules/managers/ManagersFundShow'
import ManagersIndex from './react/modules/managers/ManagersIndex'
import OrganizationIndex from './react/modules/organization/OrganizationIndex'
import PortfolioEdit from './react/modules/portfolio/edit/PortfolioEdit'
import Portfolio from './react/modules/portfolio/Portfolio'
import PortfolioNew from './react/modules/portfolio/PortfolioNew'
import { TcfdDataSheetPage } from './react/modules/portfolio/tcfd/components/data_sheet/tcfdDataSheetPage'
import ResearchCenter from './react/modules/research_center/ResearchCenter'
import SectorIndex from './react/modules/sector/SectorIndex'
import SectorShow from './react/modules/sector/SectorShow'
import TwoFactorIndex from './react/modules/settings/two_factor/TwoFactorIndex'
import TwoFactorOtpBackupCodesIndex from './react/modules/settings/two_factor/TwoFactorOtpBackupCodesIndex'
import { HeaderBar } from './react/yb_components/layout/header_bar/HeaderBar'
import { Navbar } from './react/yb_components/layout/navbar/Navbar'
import { YBToastContainer } from './react/yb_components/styleguide/toasts/YBToastContainer'
import { TargetType } from './react/yb_constants/enums/global_enums'
import SignIn from './react/yb_modules/account/sign_in/SignIn'
import ESGCustomizationNotice from './react/yb_modules/custom_esg_scoring/components/ESGCustomizationNotice'
import CustomESGScoring from './react/yb_modules/custom_esg_scoring/CustomESGScoring'
import { CreateEntityPage } from './react/yb_modules/entities/custom_companies/CreateEntityPage'
import { EditEntityPage } from './react/yb_modules/entities/custom_companies/EditEntityPage'
import { EntityEsgPage } from './react/yb_modules/entities/esg/EntityEsgPage'
import EntitySasbPage from './react/yb_modules/entities/sasb_metric_tracking/EntitySasbPage'
import SasbDetailsPage from './react/yb_modules/entities/sasb_metric_tracking/SasbDetailsPage'
import Subsidies from './react/yb_modules/instrument/subsidies/Subsidies'
import Violations from './react/yb_modules/instrument/violations/Violations'
import AddeparPage from './react/yb_modules/organization/AddeparPage'
import { EditUserPage } from './react/yb_modules/organization/users/EditUserPage'
import { NewUserPage } from './react/yb_modules/organization/users/NewUserPage'
import OrganizationUsersPage from './react/yb_modules/organization/users/OrganizationUsersPage'
import PortfolioComparisonsIndex from './react/yb_modules/portfolio/modules/comparisons/components/portfolio_comparisons_index/PortfolioComparisonsIndex'
import PortfolioIndex from './react/yb_modules/portfolio/modules/index/PortfolioIndex'
import PortfolioLongitudinalReport from './react/yb_modules/portfolio/modules/longitudinal_report/components/PortfolioLongitudinalReport'
import SnapshotUploadLog from './react/yb_modules/portfolio/snapshot/upload_log/components/SnapshotUploadLog'
import UserAgreement from './react/yb_modules/user_agreement/UserAgreement'
import AppStore from './react/yb_stores/app_store/AppStore'
import DisclosureFormsStore from './react/yb_stores/sfdr/disclosure_forms/DisclosureFormsStore'
/* global expo_base, react_app_user, react_app_organization */
/* eslint no-undef: "error" */

const App = () => {
  const getPageContent = path => {
    if (path.includes('/app/search')) {
      return <></>
    }

    if (path.includes('app/instruments')) {
      // The first match is always the whole match, so we don't save that.
      let [, instrumentId] = path.match(/app\/instruments\/(\d+)\/?.*$/)
      let isSubsidiesScreen = path.match(/app\/instruments\/(\d+)\/?subsidies$/)
      let isViolationsScreen = path.match(
        /app\/instruments\/(\d+)\/?violations$/
      )

      if (isSubsidiesScreen)
        return (
          <Subsidies id={instrumentId} targetType={TargetType.INSTRUMENT} />
        )
      if (isViolationsScreen)
        return (
          <Violations id={instrumentId} targetType={TargetType.INSTRUMENT} />
        )

      return <Instrument instrumentId={instrumentId} />
    }

    // Portfolio Snapshots - Subsidies
    if (path.match(/app\/portfolios\/(\d+)\/snapshots\/(\d+)\/subsidies\/?/)) {
      let [, portfolioId, snapshotId] = path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/subsidies\/?/
      )

      return (
        <Subsidies
          id={{ portfolioId, snapshotId }}
          targetType={TargetType.PORTFOLIO_SNAPSHOT}
        />
      )
    }

    // Portfolio Snapshots - Violations
    if (path.match(/app\/portfolios\/(\d+)\/snapshots\/(\d+)\/violations\/?/)) {
      let [, portfolioId, snapshotId] = path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/violations\/?/
      )

      return (
        <Violations
          id={{ portfolioId, snapshotId }}
          targetType={TargetType.PORTFOLIO_SNAPSHOT}
        />
      )
    }

    // Portfolio Longitudinal Report
    if (path.match(/app\/portfolios\/(\d+)\/longitudinal_report\/?/)) {
      let [, portfolioId] = path.match(
        /app\/portfolios\/(\d+)\/longitudinal_report\/?/
      )
      return <PortfolioLongitudinalReport portfolioId={portfolioId} />
    }
    // Individual Portfolio Events
    // app/portfolios/:portfolio_id/snapshots/:id/event_log
    if (path.match(/app\/portfolios\/(\d+)\/snapshots\/(\d+)\/upload_log\/?/)) {
      let [, portfolioId, snapshotId] = path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/upload_log\/?/
      )
      return (
        <SnapshotUploadLog snapshotId={snapshotId} portfolioId={portfolioId} />
      )
    }
    // app/portfolios/:portfolio_id/snapshots/:id/event_log
    if (path.match(/app\/portfolios\/(\d+)\/snapshots\/(\d+)\/event_log\/?/)) {
      let [, portfolioId, snapshotId] = path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/event_log\/?/
      )
      return (
        <PortfolioEventLog snapshotId={snapshotId} portfolioId={portfolioId} />
      )
    }

    if (path.match(/app\/portfolios\/(\d+)\/snapshots\/(\d+)\/edit\/?/)) {
      // eslint-disable-next-line no-undef
      return <PortfolioEdit snapshot={expo_base.snapshot} />
    }

    // app/portfolios/:portfolio_id/snapshots/:id/holdings/:id/risk_assessment
    if (
      path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/holdings\/(\d+)\/risk_assessment\/?.*$/
      )
    ) {
      let [, fundId, snapshotId, holdingId] = path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/holdings\/(\d+)\/risk_assessment\/?.*$/
      )
      return (
        <RiskAssessment
          holdingId={holdingId}
          fundId={fundId}
          snapshotId={snapshotId}
        />
      )
    }

    // app/fund/:id/holdings/:id
    if (path.match(/app\/funds\/(\d+)\/holdings\/(\d+)/)) {
      let [, fundId, holdingId] = path.match(
        /app\/funds\/(\d+)\/holdings\/(\d+)/
      )
      return (
        <HoldingShowPage
          fundId={fundId}
          holdingId={holdingId}
          // eslint-disable-next-line no-undef
          current_user={react_app_user?.user}
        ></HoldingShowPage>
      )
    }

    // app/portfolios/:portfolio_id/snapshots/:id/holdings/:id
    if (
      path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/holdings\/(\d+)\/?$/
      )
    ) {
      let [, fundId, snapshotId, holdingId] = path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/holdings\/(\d+)\/?$/
      )

      return (
        <Holding
          holdingId={holdingId}
          fundId={fundId}
          snapshotId={snapshotId}
        />
      )
    }

    // app/portfolios/:portfolio_id/snapshots/:id/engagement
    if (
      path.match(/app\/portfolios\/(\d+)\/snapshots\/(\d+)\/engagement\/?$/)
    ) {
      let [, portfolioId, snapshotId] = path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/engagement$/
      )

      return (
        <PortfolioEventLog snapshotId={snapshotId} portfolioId={portfolioId} />
      )
    }

    // app/portfolios/:portfolio_id/snapshots/:id/holdings
    if (path.match(/app\/portfolios\/(\d+)\/snapshots\/(\d+)\/holdings\/?$/)) {
      let [, portfolioId, snapshotId] = path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/holdings\/?$/
      )
      return (
        <Portfolio
          organization={react_app_organization.organization}
          activeValue='holdings'
          portfolioId={portfolioId}
          snapshotId={snapshotId}
        />
      )
    }

    // app/portfolios/:portfolio_id/snapshots/:id
    if (path.match(/app\/portfolios\/(\d+)\/snapshots\/(\d+)\/?$/)) {
      let [, portfolioId, snapshotId] = path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/?$/
      )
      return (
        <Portfolio
          organization={react_app_organization.organization}
          portfolioId={portfolioId}
          snapshotId={snapshotId}
        />
      )
    }

    // app/portfolios/:portfolio_id/snapshots/:snapshot_id/carbon
    if (path.match(/app\/portfolios\/(\d+)\/snapshots\/(\d+)\/carbon\/?$/)) {
      let [, portfolioId, snapshotId] = path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/carbon\/?$/
      )
      return (
        <Portfolio
          organization={react_app_organization.organization}
          activeValue='carbon_report_metrics'
          portfolioId={portfolioId}
          snapshotId={snapshotId}
        />
      )
    }

    // app/portfolios/:portfolio_id/snapshots/:snapshot_id/carbon/tcfd
    if (
      path.match(/app\/portfolios\/(\d+)\/snapshots\/(\d+)\/carbon\/tcfd\/?$/)
    ) {
      let [, portfolioId, snapshotId] = path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/carbon\/tcfd\/?$/
      )
      return (
        <Portfolio
          organization={react_app_organization.organization}
          activeValue='carbon_report_tcfd'
          portfolioId={portfolioId}
          snapshotId={snapshotId}
        />
      )
    }
    // /app/portfolios/:portfolio_id/snapshots/:snapshot_id/carbon/tcfd/holdings/:holding_id
    if (
      path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/carbon\/tcfd\/holdings\/(\d+)\/?/
      )
    ) {
      let [, portfolioId, snapshotId, holdingId] = path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/carbon\/tcfd\/holdings\/(\d+)\/?$/
      )
      return (
        <Portfolio
          organization={react_app_organization.organization}
          activeValue='carbon_report_tcfd'
          portfolioId={portfolioId}
          snapshotId={snapshotId}
          holdingId={holdingId}
        />
      )
    }

    // /app/portfolios/:portfolio_id/snapshots/:snapshot_id/regulatory
    if (
      path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/regulatory\/pai\/overview\/?$/
      )
    ) {
      let [, portfolioId, snapshotId] = path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/regulatory\/pai\/overview\/?$/
      )
      return (
        <Portfolio
          organization={react_app_organization.organization}
          portfolioId={portfolioId}
          snapshotId={snapshotId}
          activeValue='regulatory_pai'
          navOptions='dash'
        />
      )
    }
    // /app/portfolios/:portfolio_id/snapshots/:snapshot_id/regulatory/sfdr/:tab
    // tabs: overview, fund_configuration, holding_data, disclosures
    const sfdrRouteMatch = path.match(
      /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/regulatory\/sfdr\/(overview|fund_configuration|holding_data|disclosures)(?:\/?)((\d+))?$/
    )

    if (sfdrRouteMatch) {
      let [, portfolioId, snapshotId, tab, detailId] = sfdrRouteMatch
      const getTab = (tab, detailId) => (detailId ? `${tab}_detail` : tab)

      return (
        // <SFDR
        //     tab={getTab(tab, detailId)}
        //     portfolioId={portfolioId}
        //     snapshotId={snapshotId}
        //     detailId={detailId}
        // />
        <Portfolio
          organization={react_app_organization.organization}
          activeValue='regulatory_sfdr'
          portfolioId={portfolioId}
          snapshotId={snapshotId}
          navOptions={{
            subTab: getTab(tab, detailId),
            detailId,
          }}
        />
      )
    }
    // /app/portfolios/:portfolio_id/snapshots/:snapshot_id/regulatory/pai/policy_statements
    const test = path.match(
      /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/regulatory\/pai\/policy_statements\/?$/
    )
    if (
      path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/regulatory\/pai\/policy_statements\/?$/
      )
    ) {
      let [, portfolioId, snapshotId] = path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/regulatory\/pai\/policy_statements\/?$/
      )
      return (
        <Portfolio
          organization={react_app_organization.organization}
          activeValue='regulatory_pai'
          portfolioId={portfolioId}
          snapshotId={snapshotId}
          navOptions={'pai_policy_statements'}
        />
      )
    }
    // /app/portfolios/:portfolio_id/snapshots/:snapshot_id/regulatory/pai/indicators
    if (
      path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/regulatory\/pai\/indicators\/?$/
      )
    ) {
      let [, portfolioId, snapshotId] = path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/regulatory\/pai\/indicators\/?$/
      )
      return (
        <Portfolio
          organization={react_app_organization.organization}
          activeValue='regulatory_pai'
          portfolioId={portfolioId}
          snapshotId={snapshotId}
          navOptions={'pai_indicators'}
        />
      )
    }
    // /app/portfolios/:portfolio_id/snapshots/:snapshot_id/regulatory/pai/entities
    if (
      path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/regulatory\/pai\/entities\/?$/
      )
    ) {
      let [, portfolioId, snapshotId] = path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/regulatory\/pai\/entities\/?$/
      )
      return (
        <Portfolio
          organization={react_app_organization.organization}
          activeValue='regulatory_pai'
          portfolioId={portfolioId}
          snapshotId={snapshotId}
          navOptions={'pai_entities'}
        />
      )
    }
    // /app/portfolios/:portfolio_id/snapshots/:snapshot_id/regulatory/pai/entities/:holding_id
    if (
      path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/regulatory\/pai\/entities\/(\d+)\/?$/
      )
    ) {
      let [, portfolioId, snapshotId] = path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/regulatory\/pai\/entities\/(\d+)\/?$/
      )
      return (
        <Portfolio
          organization={react_app_organization.organization}
          activeValue='regulatory_pai'
          portfolioId={portfolioId}
          snapshotId={snapshotId}
          navOptions={'pai_entity_detail'}
        />
      )
    }
    // /app/portfolios/:portfolio_id/snapshots/:snapshot_id/regulatory/pai/downloads
    if (
      path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/regulatory\/pai\/downloads\/?$/
      )
    ) {
      let [, portfolioId, snapshotId] = path.match(
        /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/regulatory\/pai\/downloads\/?$/
      )
      return (
        <Portfolio
          organization={react_app_organization.organization}
          activeValue='regulatory_pai'
          portfolioId={portfolioId}
          snapshotId={snapshotId}
          navOptions={'pai_downloads'}
        />
      )
    }

    // app/entities/new
    if (path.match(/app\/entities\/new\/?$/)) {
      return <CreateEntityPage />
    }
    // app/entities/:entity_id/edit
    if (path.match(/app\/entities\/(\d+)\/edit\/?$/)) {
      let [, entityId] = path.match(/app\/entities\/(\d+)\/edit\/?$/)
      return <EditEntityPage entityId={Number(entityId)} />
    }

    // app/entities/:entity_id/sasb
    if (path.match(/app\/entities\/(\d+)\/sasb\/?$/)) {
      let [, entityId] = path.match(/app\/entities\/(\d+)\/sasb\/?$/)
      return <EntitySasbPage entityId={Number(entityId)} />
    }
    // app/entities/:entity_id/sasb/metrics/:metric_id
    if (path.match(/app\/entities\/(\d+)\/sasb\/metrics\/(\d+)\/?$/)) {
      let [, entityId] = path.match(
        /app\/entities\/(\d+)\/sasb\/metrics\/(\d+)\/?$/
      )
      let [, metricId] = path.match(/\/metrics\/(\d+)\/?$/)
      return (
        <SasbDetailsPage
          entityId={Number(entityId)}
          metricId={Number(metricId)}
        />
      )
    }
    // app/entities/:entity_id/esg
    if (path.match(/app\/entities\/(\d+)\/esg\/?$/)) {
      let [, entityId] = path.match(/app\/entities\/(\d+)\/esg\/?$/)
      return <EntityEsgPage entityId={Number(entityId)} />
    }

    // app/sectors
    if (path.match(/app\/sectors$/)) {
      return <SectorIndex />
    }

    // app/sectors/:id
    if (path.includes('app/sectors')) {
      let [, sectorId] = path.match(/app\/sectors\/(\d+)\/?.*$/)

      if (path.match(/app\/sectors\/[0-9]+$/))
        return <SectorShow id={sectorId} />

      let isSubsidiesScreen = path.match(/app\/sectors\/(\d+)\/?subsidies$/)
      let isViolationsScreen = path.match(/app\/sectors\/(\d+)\/?violations$/)

      if (isSubsidiesScreen)
        return (
          <Subsidies
            id={sectorId}
            targetType={TargetType.TRBC_INDUSTRY_GROUP}
          />
        )
      if (isViolationsScreen)
        return (
          <Violations
            id={sectorId}
            targetType={TargetType.TRBC_INDUSTRY_GROUP}
          />
        )
    }

    // app/funds
    if (path.match(/app\/funds$/)) {
      return <FundIndex />
    }

    // app/funds/new
    if (path.match(/app\/funds\/new$/)) {
      return <FundNew />
    }

    // app/funds/:id
    if (path.match(/app\/funds\/[0-9]+$/)) {
      var id = path.substring(path.lastIndexOf('/') + 1)
      return <FundShow id={parseInt(id)} />
    }

    // app/managers/:fund_id
    if (path.match(/app\/managers\/(\d+)$/)) {
      const id = path.substring(path.lastIndexOf('/') + 1)
      return <ManagersFundShow id={parseInt(id)} />
    }

    // app/settings/two_factor
    if (path.match(/app\/settings\/two_factor+$/)) {
      return <TwoFactorIndex />
    }

    // app/settings/two_factor/otp_backup_codes
    if (path.match(/app\/settings\/two_factor\/otp_backup_codes+$/)) {
      return <TwoFactorOtpBackupCodesIndex />
    }

    // Event log for Organization
    // /app/organization/event_log
    if (path.match(/app\/organization\/event_log\/?$/)) {
      return <OrganizationEventLog />
    }
    // /app/organization/users
    if (path.match(/app\/organization\/users\/?$/)) {
      return (
        <OrganizationUsersPage
          // eslint-disable-next-line no-undef
          organization={react_app_organization.organization}
        />
      )
    }
    // /app/organization/users/new
    if (path.match(/app\/organization\/users\/new\/?$/)) {
      // eslint-disable-next-line no-undef
      return <NewUserPage organization={react_app_organization.organization} />
    }
    // /app/organization/users/:id
    if (path.match(/app\/organization\/users\/(\d+)\/?$/)) {
      let [, userId] = path.match(/app\/organization\/users\/(\d+)\/?$/)
      return (
        <EditUserPage
          userId={userId}
          // eslint-disable-next-line no-undef
          organization={react_app_organization.organization}
        />
      )
    }

    // /app/organization/integrations
    if (path.match(/app\/organization\/integrations\/?$/)) {
      return <AddeparPage />
    }

    // /app/portfolios/:id/custom_esg_settings
    if (path.match(/app\/portfolios\/(\d+)\/custom_esg_scoring/)) {
      let [, portfolioId] = path.match(
        /app\/portfolios\/(\d+)\/custom_esg_scoring/
      )
      if (react_app_organization.organization.can_customize_esg_scores)
        return (
          <CustomESGScoring targetType='Portfolio' targetId={portfolioId} />
        )
      else return 'Not Found'
    }

    // /app/research_center/data/:data_name

    if (
      path.match(
        /app\/research_center\/data\/reputation_1|reputation_2|market_risk_1|market_risk_2|market_risk_3|physical_risk_1|policy_and_legislation|technology_and_adaptation/
      )
    ) {
      const dataName = path.substring(path.lastIndexOf('/') + 1)
      return <TcfdDataSheetPage dataName={dataName} />
    }

    switch (path) {
      case '/app/managers':
      case '/app/managers/':
        return <ManagersIndex />
      case '/app/managers/search':
      case '/app/managers/search/':
        return <ManagersFundSearch />
      case '/app/portfolios':
      case '/app/portfolios/':
        return <PortfolioIndex />
      case '/app/portfolios/snapshot_uploads/new':
      case '/app/portfolios/snapshot_uploads/new/':
        return <PortfolioNew />
      case '/app/portfolios/comparisons':
      case '/app/portfolios/comparisons/':
        return <PortfolioComparisonsIndex />
      case '/app/badges':
      case '/app/badges/':
        return <Badges />
      case '/app/research_center':
      case '/app/research_center/':
        return <ResearchCenter />
      case '/app/account/edit/':
      case '/app/account/edit':
        return <Account />
      case '/app/organization/custom_esg_scoring':
        if (react_app_organization.organization.can_customize_esg_scores)
          return (
            <CustomESGScoring
              targetType='Organization'
              targetId={react_app_organization?.organization?.id}
            />
          )
        else return 'Not Found'
      case '/app/organization/settings':
      case '/app/organization/settings/':
        return (
          <OrganizationIndex
            // eslint-disable-next-line no-undef
            organization={react_app_organization.organization}
          />
        )
      case '/app':
      case '/app/':
        return <Dashboard />
      default:
        return 'Not Found'
    }
  }

  const buildPage = () => {
    const path = window.location.pathname

    /* Entire page modules */
    switch (path) {
      case '/app/sign_in':
        return <SignIn />
      case '/app/user_agreement':
      case '/app/user_agreement/':
        return <UserAgreement />
      default:
    }

    /* Modules that conform to standard layout */
    const content = getPageContent(path)
    return (
      <>
        <HeaderBar user={react_app_user?.user} />
        <Navbar
          user={react_app_user?.user}
          funds={react_app_user?.user.funds}
          organization={react_app_organization.organization}
          portfolios={react_app_user?.user?.favorite_portfolios || []}
          impactBenchmarks={mapImpactBenchmarksToState(
            react_app_organization.impact_benchmarks
          )}
          inProduction={react_app_user?.user.in_production}
        />
        {react_app_organization?.organization
          ?.custom_esg_processing_main_batch && (
          <ESGCustomizationNotice mainBatch={true} />
        )}
        {react_app_organization?.organization?.custom_esg_processing_all &&
          !react_app_organization?.organization
            ?.custom_esg_processing_main_batch && <ESGCustomizationNotice />}

        {content === 'Not Found' ? (window.location.pathname = 404) : content}
        <YBToastContainer />
        <Toaster />
      </>
      /* eslint-enable no-undef */
    )
  }

  return (
    <BrowserRouter>
      <AppStore>{buildPage()}</AppStore>
    </BrowserRouter>
  )
}

export default withErrorBoundary(App)
