import propTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

const SDGAlignment = ({ sdgs, classes, className }) => {
  const renderSDG = (type, list) => {
    return (
      <div className={classes.sdg}>
        <span>{`${type} SDG Exposure`}</span>
        {list.map((item, i) => (
          <img
            key={i}
            src={`/sdg_icons/${item}-125.png`}
            data-cy={`{${type.toLowerCase()}-sdg-icon-${i}`}
          />
        ))}
      </div>
    )
  }

  return (
    <div className={className}>
      {sdgs.positive &&
        sdgs.positive &&
        sdgs.positive.length != 0 &&
        renderSDG('Positive', sdgs.positive)}
      {sdgs.negative &&
        sdgs.negative &&
        sdgs.negative.length != 0 &&
        renderSDG('Negative', sdgs.negative)}
    </div>
  )
}

SDGAlignment.propTypes = {
  sdgs: propTypes.shape({
    positive: propTypes.arrayOf(
      propTypes.oneOfType([propTypes.number, propTypes.string])
    ).isRequired,
    negative: propTypes.arrayOf(
      propTypes.oneOfType([propTypes.number, propTypes.string])
    ).isRequired,
  }).isRequired,
  classes: propTypes.object.isRequired,
  className: propTypes.string,
}

const style = {
  sdg: {
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: 'bold',
    '&:first-child': {
      marginRight: 30,
    },
    '& span': {
      display: 'block',
      marginBottom: 6,
    },
    '& img': {
      height: 50,
      width: 50,
      '& + img': {
        marginLeft: '0.19rem',
      },
    },
  },
}

export default injectSheet(style)(SDGAlignment)
