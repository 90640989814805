import React from 'react'

const UserDisabledEvent = ({ event }) => {
  return (
    <>
      <span className='event-user-name'>{event.context.full_name}</span>{' '}
      disabled user{' '}
      <span className='event-user-name'>
        {event.data.first_name} {event.data.last_name}
      </span>
      <span className='event-date'>{event.formattedTimestamp}</span>.
    </>
  )
}

export default UserDisabledEvent
