import React, { useState } from 'react'
import styled from 'styled-components'

import { queryStringParse, range } from '../../../helpers/objects_and_arrays'

const StyledA = styled.a`
  cursor: pointer;
`

const SearchBySDG = ({ onSDGChange = () => {} }) => {
  const [selectedSDG, setSelectedSDG] = useState(
    parseInt(queryStringParse().sdg)
  )

  const onClick = sdgIndex => () => {
    let newValue = sdgIndex
    if (selectedSDG === sdgIndex) {
      newValue = null
    }

    setSelectedSDG(newValue)
    onSDGChange(newValue)
  }

  const selectionClass = sdgIndex => {
    if (selectedSDG === sdgIndex) return 'selected'
    if (selectedSDG) return ''
    return 'none-selected'
  }

  return (
    <div className='card mb-4'>
      <h6 className='card-header'>Search By UN SDG</h6>
      <div className='card-body'>
        {range({ start: 1, end: 17 }).map(sdgIndex => (
          <StyledA
            key={`sdg-${sdgIndex}`}
            title={`SDG #${sdgIndex}`}
            className={`sdg ${selectionClass(sdgIndex)}`}
            onClick={onClick(sdgIndex)}
          >
            <img
              src={`/sdg_icons/${sdgIndex}-125.png`}
              className='d-inline-block m-1'
              height='50'
              width='50'
            />
          </StyledA>
        ))}
      </div>
    </div>
  )
}

export default SearchBySDG
