import { Bar, ResponsiveBar } from '@nivo/bar'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import ErrorPage from '../../../../components/styleguide/ErrorPage'
import withErrorBoundary from '../../../../hocs/withErrorBoundary'
import { getBudgetProjections } from '../../../../repositories/portfolio_repository'
import { StyledExplainerLink } from '../../../../yb_components/cards/carbon_audit_card/CarbonAuditCard.styles'
import BarComponent from '../../../../yb_components/charts/components/bar_component/BarComponent'
import {
  ExplainerLink,
  LinkIcon,
  LinkText,
} from '../../../../yb_components/styleguide/links/Link.styles'
import Pagination from '../../../../yb_components/styleguide/pagination/Pagination'
import SpinnerContainer from '../../../../yb_components/styleguide/spinners/SpinnerContainer'
import BarTooltip from './components/BarTooltip'
import CarbonReportCard from './components/CarbonReportCard'
import CarbonReportLink from './components/CarbonReportLink'
import {
  BUDGET_PROJECTION_TYPES,
  budgetProjectionsTableHeader,
  CARBON_REPORT_TEXTS,
  DEFAULT_SORT,
} from './constants'
import { formatSort, getPdfSortFieldText } from './helpers'
import {
  CarbonReportTable,
  COMMOM_BAR_PROPS,
  ModuleDescription,
  renderTh,
} from './styles'

const StyledCarbonReportCard = styled(CarbonReportCard)`
  grid-column-start: 1;
  grid-column: span 6;
  .explainer-link {
    justify-content: flex-end;
  }
  .content {
    display: flex;
    table {
      margin-bottom: 10px;
      .number {
        width: ${props => (props.pdf ? '180px' : '200px')};
      }
    }
    .bar-container {
      margin-right: 20px;
      width: ${props => (props.pdf ? '180px' : '300px')};
    }
    .table-container {
      width: 100%;
    }
  }
`

const colors = {
  Unmodeled: '#979797',
  'Zero Emissions': '#00BE62',
  'Well Below Budget By 2030': '#2FC178',
  'Meet Budget by 2030': '#A3786D',
  'Over Budget by 2030': '#D02F44',
}

const formatChartLabel = (d, barData) => {
  const value = barData?.[d.id]
  if (value != 0) {
    return d.id == 'Zero Emissions' ? (
      `${d.id} (${value}%)`
    ) : (
      <>
        <tspan x='50%' dy='-0.5em'>
          {d.id}
        </tspan>
        <tspan x='50%' dy='1.2em'>
          ({value}%)
        </tspan>
      </>
    )
  }
}
export const loadBudgetProjections = ({
  snapshotId,
  portfolioId,
  setBudgetProjections,
  setBarData,
  setError,
  setIsLoading,
  setTotalPages,
  page,
  sort,
  dispatch,
  projection,
}) => {
  setIsLoading(true)
  getBudgetProjections({ snapshotId, portfolioId, page, ...sort, projection })
    .then(res => {
      setIsLoading(false)
      setBudgetProjections(res.data.budget_projections)
      setBarData(res.data.total_percentages)
      dispatch({
        type: 'budget-projections-loaded',
        payload: {
          projections: res.data.budget_projections,
          totals: res.data.total_percentages,
          sort: sort,
        },
      })
      setTotalPages(res.data.total_pages)
    })
    .catch(err => {
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

const PROJECTION_KEYS = [
  'Unmodeled',
  'Zero Emissions',
  'Well Below Budget By 2030',
  'Meet Budget by 2030',
  'Over Budget by 2030',
]

const BudgetProjections = ({
  portfolioId,
  snapshotId,
  dispatch,
  defaultData,
  defaultBarData,
  defaultSort,
  pdf,
  title = CARBON_REPORT_TEXTS?.budgetProjections.title,
  description = CARBON_REPORT_TEXTS?.budgetProjections.description,
}) => {
  const [isLoading, setIsLoading] = useState(!pdf)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [error, setError] = useState()
  const [budgetProjections, setBudgetProjections] = useState(defaultData || [])
  const [barData, setBarData] = useState(defaultBarData || {})
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [sort, setSort] = useState(defaultSort || DEFAULT_SORT)
  const [projection, setProjection] = useState('all')
  const [activeBarItem, setActiveBarItem] = useState(0)

  useEffect(() => {
    if (defaultData) setBudgetProjections(defaultData)
  }, [defaultData])

  useEffect(() => {
    if (defaultBarData) setBarData(defaultBarData)
  }, [defaultBarData])

  useEffect(
    () =>
      !pdf
        ? loadBudgetProjections({
            portfolioId,
            snapshotId,
            page,
            sort,
            dispatch,
            setBudgetProjections,
            setBarData,
            setTotalPages,
            setError,
            setIsLoading,
            projection,
          })
        : () => {},
    [page, sort]
  )

  const formattedBarData = () => {
    let temp = {}
    Object.keys(barData).map(
      id =>
        barData[id] !== 0 &&
        (temp[id] = barData[id] < 13 && barData[id] != 0 ? 13 : barData[id])
    )
    return [temp]
  }

  const handleProjectionChange = e => {
    const defaultPage = 1
    setPage(defaultPage)

    const selectedProjection =
      _.first(BUDGET_PROJECTION_TYPES.filter(proj => proj?.label === e.id)) ||
      'all'

    if (e === 'all' || selectedProjection?.id === projection) {
      handleActiveBarItem('all')
      setProjection('all')
      handleActiveBarItem('all')
      loadBudgetProjections({
        portfolioId,
        snapshotId,
        page: defaultPage,
        sort,
        dispatch,
        setBarData,
        setTotalPages,
        setError,
        setIsLoading: setIsTableLoading,
        isLoading: isTableLoading,
        setBudgetProjections,
        projection: 'all',
      })
    } else {
      handleActiveBarItem(e.id)
      setProjection(selectedProjection?.id)
      loadBudgetProjections({
        portfolioId,
        snapshotId,
        page: defaultPage,
        sort,
        dispatch,
        setBarData,
        setTotalPages,
        setError,
        setIsLoading: setIsTableLoading,
        isLoading: isTableLoading,
        setBudgetProjections,
        projection: selectedProjection?.id,
      })
    }
  }

  const handleActiveBarItem = selectedProjection => {
    const arrayBarData = Object.keys(barData)
    setActiveBarItem(
      selectedProjection === 'all'
        ? 0
        : arrayBarData.indexOf(selectedProjection) + 1
    )
  }

  const selectedProjection = useMemo(() => {
    return BUDGET_PROJECTION_TYPES.find(proj => projection === proj.id) || 'all'
  }, [projection])

  if (projection !== 'all') {
    budgetProjectionsTableHeader(
      pdf
    )[0].text = `${selectedProjection?.label} Companies in Portfolio`
  } else {
    budgetProjectionsTableHeader(pdf)[0].text = 'COMPANY'
  }

  let barProps = {
    ...COMMOM_BAR_PROPS,
    data: formattedBarData(),
    keys: PROJECTION_KEYS,
    label: d => formatChartLabel(d, barData), //check if this is needed
    colors: ({ id }) => String(colors[id]),
    onClick: handleProjectionChange,
  }

  const PDF_DIMENSIONS = { height: 450, width: 300 }

  if (pdf) {
    barProps = { ...barProps, ...PDF_DIMENSIONS }
  }

  const formattedPdfSortField = useMemo(
    () =>
      getPdfSortFieldText(budgetProjectionsTableHeader(pdf), defaultSort, pdf),
    [defaultSort, pdf]
  )

  return (
    <StyledCarbonReportCard
      pdf={pdf}
      activeBarItem={activeBarItem}
      title={title}
      width={'760px'}
      trHeight={'40px'}
      pdfSubTitle={{
        label: `(Top 10 Holdings By ${formattedPdfSortField})`,
        inline: true,
      }}
    >
      {!pdf && (
        <StyledExplainerLink
          pdf={pdf}
          href='http://help.yves.blue/en/articles/6698762-carbon-report-portfolio-summary-on-yvesblue#h_7771887a2b'
          target='_blank'
        >
          <LinkIcon
            style={{ color: '#D8D8D8', fontSize: '16px' }}
            className='fa fa-question-circle'
          />
        </StyledExplainerLink>
      )}

      <ModuleDescription marginBottom={'10px'} pdf={pdf}>
        {description}
      </ModuleDescription>
      {error ? (
        <ErrorPage code={error} message={error == 404 ? 'NOT FOUND' : ''} />
      ) : (
        <>
          {isLoading && <br />}
          <SpinnerContainer isLoading={isLoading}>
            {!pdf && (
              <ExplainerLink
                fontSize='14px'
                padding='8px 20px'
                margin='0 10px 0 0'
                style={{ position: 'absolute' }}
                {...(projection === 'all' && { visibility: 'hidden' })}
                onClick={() => handleProjectionChange('all')}
                className='explainer-link'
              >
                <LinkText>(Clear Filter)</LinkText>
              </ExplainerLink>
            )}
            <div className='content'>
              <div className='bar-container'>
                {pdf ? (
                  <Bar {...barProps} />
                ) : (
                  <ResponsiveBar
                    {...barProps}
                    barComponent={props => (
                      <BarComponent
                        {...props}
                        onClick={handleProjectionChange}
                        keys={PROJECTION_KEYS}
                        activeId={selectedProjection?.label}
                        tooltipComponent={data => <BarTooltip data={data} />}
                      />
                    )}
                  />
                )}
              </div>
              <div className='table-container'>
                <SpinnerContainer isLoading={isTableLoading}>
                  <CarbonReportTable pdf={pdf}>
                    <thead>
                      <tr>
                        {projection === 'all'
                          ? budgetProjectionsTableHeader(pdf).map(elem =>
                              renderTh(
                                elem,
                                sort,
                                (sortField, sortDir) =>
                                  setSort(formatSort(sortField, sortDir)),
                                pdf
                              )
                            )
                          : budgetProjectionsTableHeader(pdf)
                              .slice(0, -1)
                              .map(elem =>
                                renderTh(
                                  elem,
                                  sort,
                                  (sortField, sortDir) =>
                                    setSort(formatSort(sortField, sortDir)),
                                  pdf
                                )
                              )}
                      </tr>
                    </thead>
                    <tbody>
                      {budgetProjections.map((budgetProjection, index) => (
                        <tr key={index}>
                          <td>
                            <CarbonReportLink
                              pdf={pdf}
                              name={budgetProjection.name}
                              href={`/app/instruments/${budgetProjection.instrument_id}#report-summary-card`}
                            />
                          </td>
                          {pdf && (
                            <td className='number'>
                              {budgetProjection?.weight}%
                            </td>
                          )}
                          {projection === 'all' && (
                            <td className='number'>
                              {budgetProjection.projection || 'Unmodeled'}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </CarbonReportTable>
                  {!pdf && (
                    <Pagination
                      max={totalPages}
                      selected={page}
                      onChange={e => setPage(e)}
                    />
                  )}
                </SpinnerContainer>
              </div>
            </div>
          </SpinnerContainer>
        </>
      )}
    </StyledCarbonReportCard>
  )
}

export default withErrorBoundary(BudgetProjections)
