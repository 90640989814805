import { FONT_COLOR_SECONDARY } from '../../../../../styles/colors'

export const PAI_COLOR_RED = '#E74C4C'
export const PAI_COLOR_YELLOW = '#EAD836'
export const PAI_COLOR_GREEN = '#7BD600'

export const getPaiColor = number => {
  if (number <= 0.33) return PAI_COLOR_RED
  if (number > 0.33 && number <= 0.66) return PAI_COLOR_YELLOW
  if (number > 0.66) return PAI_COLOR_GREEN
  else return FONT_COLOR_SECONDARY
}
