import React from 'react'
// import theme from './MessageCard.module.scss'
import './MessageCard.module.scss'

export interface IProps {
  children: React.ReactNode
}

export const MessageCard: React.FC<IProps> = ({ children }) => {
  return <div className='MessageCard'>{children}</div>
}
