import { useEffect, useRef, useState } from 'react'

import { TooltipPositioning } from './TooltipWrapper.constants'

/* Methods */
export const getPositioningTransform = (
  positioning,
  wrapper,
  content,
  margin
) => {
  let transformX = 0
  let transformY = 0

  switch (positioning) {
    case TooltipPositioning.LEFT:
      transformX = -content.width - margin
      transformY = -(content.width / 2 - wrapper.width / 2)
      break

    case TooltipPositioning.RIGHT:
      transformX = wrapper.width + margin
      transformY = -(content.width / 2 - wrapper.width / 2)
      break

    case TooltipPositioning.BOTTOM:
      transformX = -(content.width / 2 - wrapper.width / 2)
      transformY = margin
      break

    default:
    case TooltipPositioning.TOP:
      transformX = -(content.width / 2 - wrapper.width / 2)
      transformY = -(content.height + wrapper.height + margin)
      break
  }
  return `transform: translate(${transformX}px, ${transformY}px)`
}

/* Hooks */
export const useClientSize = () => {
  const myRef = useRef(null)
  const [clientSize, setClientSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    setClientSize({
      width: myRef.current?.clientWidth,
      height: myRef.current?.clientHeight,
    })
  }, [myRef])

  return [myRef, clientSize]
}
