import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import styled from 'styled-components'

import { APP_COLOR_PRIMARY } from '../../../styles/colors'

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${APP_COLOR_PRIMARY};

  .spinner-border {
    ${props => props.size && `height: ${props.size};`}
    ${props => props.size && `width: ${props.size};`}
  }
`

const LoadingSpinner = ({ className, size }) => {
  return (
    <SpinnerContainer className={className} size={size}>
      <Spinner animation='border' />
    </SpinnerContainer>
  )
}

export default LoadingSpinner
