import React, { useState } from 'react'
import styled from 'styled-components'

import Card from '../../../../components/styleguide/Card'
import CardTitle from '../../../../components/styleguide/CardTitle'
import Toggle from '../../../../components/styleguide/Toggle'
import ESGPillarsInput from '../ESGPillarsInput'

const StyledCard = styled(Card)`
  grid-column: span 8;

  .scale-container {
    margin-bottom: 30px;
  }

  .description {
    color: #979797;
  }
`

const ESGScorePillars = ({
  dispatch,
  loadedESGPillars,
  esgMetricsMap,
  defaultPillarWeights,
}) => {
  const [view, setView] = useState('basic')

  const handleViewChange = toggleKey => {
    setView(toggleKey)
  }

  return (
    <StyledCard>
      <CardTitle title='CUSTOMIZE ESG SCORE PILLARS' />
      <Toggle
        values={[
          { label: 'Pillar', key: 'basic' },
          { label: 'Metric', key: 'advanced' },
        ]}
        active={view}
        onChange={handleViewChange}
      />
      <div className='description'>
        These settings allows a user to set default weights for E, S and G
        pillars to compute the composite ESG score. These weights would be
        applied to all sectors unless they are overridden by the sector specific
        weights. A manager can use this to tailor the ESG score and include that
        in their forecast model.
      </div>

      <ESGPillarsInput
        view={view}
        action={{
          type: 'esg-pillar-changed',
        }}
        dispatch={dispatch}
        loadedESGPillars={loadedESGPillars}
        esgMetricsMap={esgMetricsMap}
        propsDefaultWeights={defaultPillarWeights?.overall}
      />
    </StyledCard>
  )
}

export default ESGScorePillars
