import React from 'react';
import injectSheet from 'react-jss';
import propTypes from 'prop-types'

import cns from '../../helpers/classnames';
import { APP_COLOR_PRIMARY, BG_COLOR_SECONDARY, FONT_COLOR_PRIMARY } from '../../styles/colors';

import Chip from './Chip';

const InstrumentTitle = ({ classes, name, location, type, isin, className }) => {
  return (
    <div className={cns(classes.container, className)}>
      <h1 className={classes.name}>{name}</h1>
      {/* <Chip fontColor={APP_COLOR_PRIMARY} borderColor={BG_COLOR_SECONDARY} label="Equity" />
      <div className={classes.info}>{`${location}, ${type}, ISIN ${isin}`}</div> */}
    </div>
  );
}


const style = {
  container: {
    verticalAlign: 'top',
    display: 'inline-block',
    '& > *': {
      verticalAlign: 'top'
    }
  },
  name: {
    fontSize: '36px',
    fontWeight: 'bold',
    color: FONT_COLOR_PRIMARY,
    marginTop: 0,
    marginBottom: '1rem'
  },
  info: {
    display: 'inline-block',
    fontWeight: 'bold',
    color: '#979ca6',
    fontSize: '0.88rem',
    paddingLeft: 10,
    verticalAlign: 'middle',
  }
};

InstrumentTitle.propTypes = {
  classes: propTypes.object.isRequired, 
  name: propTypes.string.isRequired, 
  location: propTypes.string.isRequired, 
  type: propTypes.string.isRequired, 
  // isin: propTypes.string.isRequired, 
  className: propTypes.string
}

InstrumentTitle.defaultProps = {
  location: 'USA', // TODO - remove
  type: 'Cyclical Consumer Services' // TODO - remove
}

export default injectSheet(style)(InstrumentTitle);