import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const StyledArrowsContainer = styled.button`
  margin-left: 4px;
  width: 9px;
  display: flex;
  flex-direction: column;
  border: none;
  background: none;

  & img {
    height: 9px;

    &:first-child {
      transform: rotateZ(180deg);
      margin-bottom: -2px;
    }
  }
`

export const SortArrows = ({ sortModel, name }) => {
  const [sort, setSort] = sortModel
  const direction = useMemo(
    () => (sort.name === name ? sort.direction : null),
    [sort, name]
  )

  const handleChange = () => {
    if (sort.name === name) {
      setSort({
        ...sort,
        direction: sort.direction === 'asc' ? 'desc' : 'asc',
      })
    } else {
      setSort({
        ...sort,
        name,
      })
    }
  }

  return (
    <StyledArrowsContainer onClick={handleChange}>
      <img
        className='asc'
        src={`/ico_sort_${direction == 'asc' ? 'enabled' : 'disabled'}.svg`}
      />
      <img
        className='desc'
        src={`/ico_sort_${direction == 'desc' ? 'enabled' : 'disabled'}.svg`}
      />
    </StyledArrowsContainer>
  )
}

SortArrows.propTypes = {
  sortModel: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
}

export default SortArrows
