import React, { useEffect, useState } from 'react'

import { PageHeaderBase } from '../../../yb_components/layout/page/header/PageHeaderBase'
import { CreateEditEntityForm } from './components/forms/CreateEditEntityForm'
import { StyledCard } from './components/forms/FormCard'
import {
  getCountriesList,
  getImpactThemes,
  getIndustyGroups,
} from './repositories/custom_companies_repository'

export const EditEntityPage = ({ entityId }) => {
  const breadcrumbLinks = [
    { text: 'Home', url: '/app', active: false },
    { text: 'Edit Custom Company', url: null, active: true },
  ]

  const [countriesList, setCountriesList] = useState([])
  const [industryGroups, setIndustryGroups] = useState([])
  const [impactThemes, setImpactThemes] = useState([])

  const loadCountries = async () => {
    try {
      const res = await getCountriesList()
      setCountriesList(res)
    } catch (e) {
      console.error(e)
    }
  }
  const loadIndustryGroups = async () => {
    try {
      const res = await getIndustyGroups()
      setIndustryGroups(res)
    } catch (e) {
      console.error(e)
    }
  }
  const loadImpactThemes = async () => {
    try {
      const res = await getImpactThemes()
      setImpactThemes(res)
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    loadCountries()
    loadIndustryGroups()
    loadImpactThemes()
  }, [])

  return (
    <div className='page__container'>
      <PageHeaderBase
        breadcrumbLinks={breadcrumbLinks}
        title={`Edit Custom Company`}
      />
      <main style={{ marginBottom: '1.25rem' }}>
        {impactThemes != null && impactThemes.length ? (
          <CreateEditEntityForm
            isEditing={true}
            entityId={entityId}
            countriesList={countriesList}
            industryGroups={industryGroups}
            impactThemes={impactThemes}
          />
        ) : (
          <StyledCard>Loading...</StyledCard>
        )}
      </main>
    </div>
  )
}
