import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap'

import { StyledForm } from './TargetsForm.styles'

dayjs().format()

export const TargetsForm = ({ metric, updateMetric }) => {
  const [showIndicatorNumber, setShowIndicatorNumber] = useState(false)
  useEffect(() => {
    if (metric.sub_metrics.length === 1) setShowIndicatorNumber(false)
    else setShowIndicatorNumber(true)
  }, [metric])

  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [formData, setFormData] = useState({})
  const updateFormData = subMetrics => {
    const data = {
      sub_metrics: subMetrics,
      note: '',
    }
    setFormData(data)
    setSubmitDisabled(false)
  }
  const updateNoteContent = noteContent => {
    const data = {
      sub_metrics: subMetrics,
      note: noteContent,
    }
    setFormData(data)
    setSubmitDisabled(false)
  }

  const [subMetrics, setSubMetrics] = useState([])
  useEffect(() => {
    setSubMetrics(metric.sub_metrics)
  }, [metric.sub_metrics])

  const handleCurrentValueInputChange = (e, subMetricId) => {
    e.persist()
    const currentValue = e.target.value.length ? e.target.value : null
    const updatedSubMetrics = subMetrics.map(el => {
      return el.id === subMetricId ? { ...el, current_value: currentValue } : el
    })
    setSubMetrics(updatedSubMetrics)
    updateFormData(updatedSubMetrics)
  }
  const handleCurrentValueAsOfInputChange = (e, subMetricId) => {
    e.persist()
    const formattedDate = dayjs(e.target.value).toISOString()
    const updatedSubMetrics = subMetrics.map(el => {
      return el.id === subMetricId
        ? { ...el, current_value_as_of: formattedDate }
        : el
    })
    setSubMetrics(updatedSubMetrics)
    updateFormData(updatedSubMetrics)
  }
  const handleTargetValueInputChange = (e, subMetricId) => {
    e.persist()
    const targetValue = e.target.value.length ? e.target.value : null
    const updatedSubMetrics = subMetrics.map(el => {
      return el.id === subMetricId ? { ...el, target_value: targetValue } : el
    })
    setSubMetrics(updatedSubMetrics)
    updateFormData(updatedSubMetrics)
  }
  const handleTargetValueDueDateInputChange = (e, subMetricId) => {
    e.persist()
    const formattedDate = dayjs(e.target.value).toISOString()
    const updatedSubMetrics = subMetrics.map(el => {
      return el.id === subMetricId
        ? { ...el, target_due_date: formattedDate }
        : el
    })
    setSubMetrics(updatedSubMetrics)
    updateFormData(updatedSubMetrics)
  }
  const handleNoteInputChange = e => {
    e.persist()
    updateNoteContent(e.target.value)
  }

  const handleSubmitForm = e => {
    e.preventDefault()

    updateMetric(metric.id, formData)
    setSubMetrics([{}])
    updateNoteContent('')
    Array.from(document.querySelectorAll('input')).forEach(
      input => (input.value = '')
    )
    document.getElementById('target_notes').value = ''
    setSubmitDisabled(true)
  }

  return (
    <StyledForm>
      <h3>Update Target & Current Values:</h3>
      <Form onSubmit={e => handleSubmitForm(e)}>
        {metric.sub_metrics != null && metric.sub_metrics.length
          ? metric.sub_metrics.map((el, i) => (
              <section className='form-section' key={el.id}>
                {showIndicatorNumber ? (
                  <h5>
                    ({i + 1}) {el.prompt}
                  </h5>
                ) : (
                  <h5>{el.prompt}</h5>
                )}
                <div className='form-grid'>
                  {/* current value input */}
                  <div className='form-group'>
                    <label htmlFor={`current_value_${el.id}`}>
                      Current Value:
                    </label>
                    <InputGroup>
                      <FormControl
                        id={`current_value_${el.id}`}
                        aria-describedby={`current_value_${el.id}`}
                        onChange={e => handleCurrentValueInputChange(e, el.id)}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>
                          {el.unit ? el.unit : `Answer`}
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                  {/* current value as of input */}
                  <div className='form-group'>
                    <label htmlFor={`current_value_as_of_${el.id}`}>
                      As of:
                    </label>
                    <FormControl
                      id={`current_value_as_of_${el.id}`}
                      aria-describedby={`current_value_as_of_${el.id}`}
                      type='date'
                      onChange={e =>
                        handleCurrentValueAsOfInputChange(e, el.id)
                      }
                    />
                  </div>
                  {/* target value input */}
                  <div className='form-group'>
                    <label htmlFor={`target_value_${el.id}`}>
                      Target Value:
                    </label>
                    <InputGroup>
                      <FormControl
                        id={`target_value_${el.id}`}
                        aria-describedby={`target_value_${el.id}`}
                        onChange={e => handleTargetValueInputChange(e, el.id)}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>
                          {el.unit ? el.unit : `Answer`}
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </div>
                  {/* target date */}
                  <div className='form-group'>
                    <label htmlFor={`target_date_${el.id}`}>Target Date:</label>
                    <InputGroup>
                      <FormControl
                        id={`target_date_${el.id}`}
                        aria-describedby={`target_date_${el.id}`}
                        type='date'
                        onChange={e =>
                          handleTargetValueDueDateInputChange(e, el.id)
                        }
                      />
                    </InputGroup>
                  </div>
                </div>
              </section>
            ))
          : null}
        <section>
          <div className='form-group' style={{ margin: '8px 0 12px 0' }}>
            <label htmlFor='target_notes'>Note:</label>
            <Form.Control
              id='target_notes'
              as='textarea'
              rows={8}
              onChange={e => handleNoteInputChange(e)}
            />
          </div>
          <Button
            variant='primary'
            type='submit'
            className='submit-button'
            disabled={submitDisabled}
          >
            Save
          </Button>
        </section>
      </Form>
    </StyledForm>
  )
}

TargetsForm.propTypes = {
  metric: PropTypes.object.isRequired,
  updateMetric: PropTypes.func.isRequired,
}
