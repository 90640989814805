import React from 'react';
import injectSheet from 'react-jss';
import Card from '../../components/styleguide/Card';
import CardTitle from '../../components/styleguide/CardTitle';
import Container from '../../components/styleguide/Container';
import AccountHeader from './components/AccountHeader';
import AccountForm from './components/AccountForm';
import update from 'immutability-helper';
import { getAccount, updateAccount } from '../../repositories/account_repository';
import { mapAccountToState, mapStateToAccount, mapStateToFormData } from '../../mappers/account_mapper';
import { validate } from '@babel/types';
import { validateInput } from '../../helpers/validators';
import { errorMessages } from '../../constants';
import TwoFactorForm from "./components/TwoFactorForm";

class Account extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      notifications: [],
      success: false,
      showConfirmModal:false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleTwoFactorDisable = this.handleTwoFactorDisable.bind(this);
    this.handleAvatarChange = this.handleAvatarChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    getAccount()
        .then((res) => {
          this.setState({
            account: mapAccountToState(res.data.account)
          })
        });
  }

  handleChange(e) {
    const { id, value } = e.target;
    this.setState({
      errors:[]
    })
    this.setState({ account: update(this.state.account, { [id]: { $set: value } })});
  }

  handleAvatarChange(e) {
    const file = e.target.files[0];
    const newAvatarUrl = URL.createObjectURL(file);
    this.setState({
      account: update(this.state.account,{ avatar: { $set: file }, avatar_url: { $set: newAvatarUrl } })
    })
  }

  handleSubmit() {
    window.scrollTo(0,0);
    let errors = [];
    if(this.state.account.email !== this.state.account.new_email){
      errors.push(errorMessages.confirmEmail);
    }

    const newErrors = validateInput("email", this.state.account.email, errors);

    if(newErrors.length > 0){
      this.setState({
        errors: newErrors
      });
      return;
    }


    this.setState({ success: false });
    this.setState({ loading: true });


    const formData = new FormData(document.querySelector("#account-form"));
    const account = mapStateToFormData(this.state.account, formData);

    updateAccount(account)
      .then((res) => {
          const notifications = res.data.notifications ? res.data.notifications : [];
          this.setState({ success: true, notifications: notifications, errors:[] })
        })
        .catch((error) => {
          const errors = error.response.data.errors ? error.response.data.errors : [];
          this.setState({ errors: errors });
        });
  }

  handleTwoFactorDisable() {
    this.setState({ success: true });
  }

  renderSuccess() {
    return this.state.success ? (
        <div className="alert alert-success">Account saved!</div>
    ) : null;
  }

  renderNotifications() {
    return this.state.notifications ?  this.state.notifications.map((e, i) => (
        <div key={i} className="alert alert-primary">{e}</div>
    )) : null;
  }

  renderErrors() {
    return this.state.errors.map((e, i) => (
        <div key={i} className="alert alert-danger">{e}</div>
    ));
  }

  render() {
    const { classes } = this.props;
    const { account } = this.state;

    if (!account) {
      return null;
    }

    return (
      <Container className={classes.container}>
        <AccountHeader title="Settings" />
        {this.renderSuccess()}
        {this.renderNotifications()}
        {this.renderErrors()}
        <div className={classes.body}>
          <div className="row">
            <div className="col-md-12">
              <Card padding={35}>
                <CardTitle title="Personal Data" />
                <AccountForm errors={this.state.errors} account={account} handleSubmit={this.handleSubmit} handleChange={this.handleChange} />
              </Card>

              <Card margin="20px 0 0 0" padding={35}>
                <CardTitle title="Two Factor Authentication (2FA)" />
                <TwoFactorForm user={account} onTwoFactorDisable={this.handleTwoFactorDisable}/>
              </Card>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const style = {
  container: {
    position: 'relative',
    padding: 40
  },
  body: {
    "& > .row": {
      marginBottom: 20,

      '& > *': {
        marginTop: 20
      }
    },
    "& > .section": {
      marginTop: 50
    },
    '& > :last-child': {
      marginBottom: 100
    }
  }
};

export default injectSheet(style)(Account);
