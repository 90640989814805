/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import ErrorPage from '../../../components/styleguide/ErrorPage'
import { completeMissingYears } from '../../../helpers/objects_and_arrays'
import { getColorFromLegendData } from '../../../helpers/styling'
import {
  getSubsidies,
  getSubsidiesStats,
} from '../../../repositories/subsidies_repository'
import HeatMapCard from '../../../yb_components/cards/heat_map_card/HeatMapCard'
import OverTimeCard from '../../../yb_components/cards/over_time_card/OverTimeCard'
import PieCard from '../../../yb_components/cards/pie_card/PieCard'
import TargetBreadcrumbs from '../../../yb_components/styleguide/breadcrumbs/TargetBreadcrumbs'
import PageTextHeader from '../../../yb_components/styleguide/page_headers/PageTextHeader'
import { TargetType } from '../../../yb_constants/enums/global_enums'
import { USA_TOPO_JSON } from '../../../yb_constants/topoJSONs/usa'
import { convertToPieChartData } from '../../../yb_helpers/data_converters'
import { percentageFormatter } from '../../../yb_helpers/strings'
import { loadTarget } from '../../../yb_services/api/loaders/loaders'
import OverTimeLegend from './components/OverTimeLegend'
import SubsidiesRecordsCard from './components/SubsidiesRecordsCard'
import {
  HEADER_DESCRIPTION,
  HEADER_TITLE,
  OVER_TIME_CARD_DESCRIPTION,
  OVER_TIME_CARD_TITLE,
  OVER_TIME_GRAPH_COLORS,
  OVER_TIME_LEGEND_ORDER,
  OVER_TIME_TABLE_ROW_HEADERS,
  RECORDS_TABLE_DESCRIPTION,
  RECORDS_TABLE_TITLE,
  SUBSIDIES_BUCKET_DATA,
  SUBSIDIES_STATS_QUERY,
} from './Subsidies.constants'
import { SecondCardRowsContainer, SubsidiesContainer } from './Subsidies.styles'

const Subsidies = ({
  id = null,
  targetType = null,
  targetData = null,
  statsData = null,
  recordsData = null,
}) => {
  if (id === null || targetType === null) return null

  /* State */
  const [target, setTarget] = useState(targetData)
  const [stats, setStats] = useState(statsData)
  const [records, setRecords] = useState(recordsData)
  const [loadingRecords, setLoadingRecords] = useState(false)
  const [error, setError] = useState(null)

  const targetPath = useMemo(() => {
    if (targetType === TargetType.PORTFOLIO_SNAPSHOT)
      return `portfolios/${id?.portfolioId}/snapshots`
    return targetType
  }, [id, targetType])

  const localId = useMemo(() => {
    if (targetType === TargetType.PORTFOLIO_SNAPSHOT) return id?.snapshotId
    return id
  }, [id, targetType])

  /* Requests */
  const loadSubsidiesStats = useCallback(() => {
    getSubsidiesStats(localId, {
      query: SUBSIDIES_STATS_QUERY,
      targetPath,
    })
      .then(response => {
        setStats(response.data.subsidies_stats)
      })
      .catch(err => {
        const error = err.response ? err.response.status : 502
        setError(error)
      })
  }, [localId, targetPath])

  const loadSubsidiesData = useCallback(
    (recordsQuery = '') => {
      setLoadingRecords(true)
      getSubsidies(localId, { query: recordsQuery, targetPath })
        .then(response => {
          setRecords(response.data)
          setLoadingRecords(false)
        })
        .catch(err => {
          const error = err.response ? err.response.status : 502
          setError(error)
        })
    },
    [localId, targetPath]
  )

  /* Use Effect */
  useEffect(() => {
    loadTarget({ id, targetType, setTarget, setError })
    loadSubsidiesStats()
    loadSubsidiesData()
  }, [id, loadSubsidiesData, loadSubsidiesStats, targetType])

  /* Data & Memoized data */
  const totals = stats?.subsidies_over_time?.totals
  const showOverTimeCard = stats?.subsidies_over_time?.values
    ? stats.subsidies_over_time.values.length > 0
    : false

  const memoizedData = useMemo(
    () => completeMissingYears(stats?.subsidies_over_time?.values),
    [stats]
  )
  const memoizedSubsidyTypeData = useMemo(
    () => convertToPieChartData(stats?.subsidy_type, 'subsidy_type'),
    [stats]
  )
  const memoizedSubsidySizeData = useMemo(
    () =>
      convertToPieChartData(stats?.subsidy_size, 'label', 'percentage', v => v),
    [stats]
  )
  const memoizedSubsidyStatesData = stats?.total_state_and_local_subsidies
    ? stats.total_state_and_local_subsidies
    : null
  const memoizedSubsidiesData = useMemo(() => records?.subsidies, [records])

  /* Render */
  if (!target || !stats || error)
    return <ErrorPage code={error} message={error == 404 ? 'NOT FOUND' : ''} />
  return (
    <div className='page__container'>
      <SubsidiesContainer>
        {/* Breadcrumb */}
        <TargetBreadcrumbs
          target={target}
          targetType={targetType}
          additionalBreadcrumbs={[
            { name: 'Corporate Subsidies & Loans', active: true },
          ]}
        />

        {/* Header */}
        <PageTextHeader
          data-cy={'subsidies_page_text_header'}
          title={HEADER_TITLE}
          description={HEADER_DESCRIPTION}
          marginBottom={'20px'}
        />

        {/* Subsidies & Loans Over Time */}
        <OverTimeCard
          data-cy={'subsidies_over_time_card'}
          show={showOverTimeCard}
          title={OVER_TIME_CARD_TITLE}
          description={OVER_TIME_CARD_DESCRIPTION}
          rowHeaders={OVER_TIME_TABLE_ROW_HEADERS}
          data={memoizedData}
          colorScheme={OVER_TIME_GRAPH_COLORS}
          legendComponent={
            <OverTimeLegend
              legendOrder={OVER_TIME_LEGEND_ORDER}
              totals={totals}
            />
          }
          marginBottom={'20px'}
        />

        <SecondCardRowsContainer>
          {/* Subsidy Type */}
          <PieCard
            data-cy={'subsidy_type_pie_card'}
            title={'Subsidy Type'}
            data={memoizedSubsidyTypeData}
            tooltipValueFormatter={percentageFormatter}
            marginRight={'20px'}
          />

          {/* Subsidy Size */}
          <PieCard
            data-cy={'subsidy_size_pie_card'}
            title={'Subsidy Size'}
            data={memoizedSubsidySizeData}
            tooltipValueFormatter={percentageFormatter}
            marginRight={'20px'}
          />

          {/* Total State & Local Subsidies */}
          <HeatMapCard
            data-cy={'total_state_local_subsidies_card'}
            title={'Total State & Local Subsidies'}
            data={memoizedSubsidyStatesData}
            geoUrl={USA_TOPO_JSON}
            legendData={SUBSIDIES_BUCKET_DATA}
            fillColor={geo =>
              getColorFromLegendData(
                geo,
                memoizedSubsidyStatesData,
                SUBSIDIES_BUCKET_DATA
              )
            }
          />
        </SecondCardRowsContainer>

        <SubsidiesRecordsCard
          data-cy='subsidies_records_card'
          stats={stats}
          title={RECORDS_TABLE_TITLE}
          description={RECORDS_TABLE_DESCRIPTION}
          data={memoizedSubsidiesData}
          loading={loadingRecords}
          maxPage={records ? records.max_page : 0}
          fetchData={loadSubsidiesData}
        />
      </SubsidiesContainer>
    </div>
  )
}

export default Subsidies
