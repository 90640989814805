import { Box } from '@ui/elements/grouping/Box'
import classNames from 'classnames'
import React, { ReactNode, useContext } from 'react'

import SanitizedHTML from '../../../../react/yb_components/security/SanitizedHTML'
// import theme from './FormRow.module.scss'
import './FormRow.module.scss'
import styled from 'styled-components'
import { DisclosureFormsContext } from '../../../../react/yb_stores/sfdr/disclosure_forms/DisclosureFormsStore'
interface IFormRowProps {
  name: string
  label: string
  subtext?: ReactNode
  preFieldNotes?: ReactNode
  postFieldNotes?: ReactNode
  className?: string
  error?: string
  required?: boolean
}

const S = {
  Container: styled.div`
    padding: 10px;
    display: grid;
    grid-template-columns: max-content 1fr;

    &:nth-child(odd) {
      ${props => !props.pdf && 'background: #f1f1f1;'}
    }

    .questionStatement {
      ${props => !props.pdf && 'max-width: 330px;'}
      color: #979ca6;
      font-size: 12px;
      margin-right: 10px;

      label {
        font-weight: bold;
        margin-bottom: 10px;
      }

      p {
        margin: 0;
      }
    }
  `,
}

export default function withFormRow<T>(InputComponent: React.FC<T>) {
  return function WithFormRow(props: IFormRowProps & T) {
    const {
      label,
      error,
      className,
      name,
      subtext = null,
      preFieldNotes = null,
      postFieldNotes = null,
      required = true,
      ...rest
    } = props
    const [{ isPdf }] = useContext(DisclosureFormsContext)

    const subTextIsString =
      (subtext && typeof subtext === 'string') || subtext instanceof String

    return (
      <S.Container
        pdf={isPdf}
        className={classNames('FormRow', className, {
          invalid: !!error,
        })}
      >
        <div className={'questionStatement'}>
          <label htmlFor={name} id={`${name}-label`}>
            <SanitizedHTML stringTemplate={label} />{' '}
            <Box isPresent={!required}>
              <small>(optional)</small>
            </Box>
          </label>
          <Box isPresent={!!subtext}>
            {subTextIsString ? (
              <SanitizedHTML stringTemplate={subtext as string} />
            ) : (
              subtext
            )}
          </Box>
        </div>
        <div>
          <Box isPresent={!!preFieldNotes}>
            <div>{preFieldNotes}</div>
          </Box>
          <InputComponent {...props} {...rest} required={required} id={name} />
          <Box isPresent={!!postFieldNotes}>
            <div>{postFieldNotes}</div>
          </Box>
        </div>
      </S.Container>
    )
  }
}
