import * as colors from '../styles/colors'

export const esg_scale = [
  { color: colors.ESG_SCALE_COLOR_BAD, label: 'Bad', min: 0, max: 42 },
  { color: colors.ESG_SCALE_COLOR_POOR, label: 'Poor', min: 42, max: 46 },
  {
    color: colors.ESG_SCALE_COLOR_BELOW_AVERAGE,
    label: 'Below Average',
    min: 46,
    max: 50,
  },
  {
    color: colors.ESG_SCALE_COLOR_ABOVE_AVERAGE,
    label: 'Above Average',
    min: 50,
    max: 54,
  },
  { color: colors.ESG_SCALE_COLOR_GOOD, label: 'Good', min: 54, max: 58 },
  {
    color: colors.ESG_SCALE_COLOR_SUPERIOR,
    label: 'Superior',
    min: 58,
    max: 62,
  },
  {
    color: colors.ESG_SCALE_COLOR_EXCELLENT,
    label: 'Excellent',
    min: 62,
    max: 100,
  },
]

const getScalePosition = (value, customScale) => {
  const parsedValue = parseFloat(value)
  if (parsedValue === 0) return customScale ? customScale[0] : esg_scale[0]

  const labelsScale = customScale || esg_scale

  for (let scale of labelsScale) {
    if (
      parsedValue > Math.round(scale.min) &&
      parsedValue <= Math.round(scale.max)
    ) {
      return scale
    }
  }
}

export const getScoreLabel = (value, customScale) => {
  return getScalePosition(value, customScale).label
}

export const getProgressColor = (value, customScale) => {
  return getScalePosition(value, customScale).color
}
