import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import AsyncSelect from 'react-select-3/async'

import Button from '../../../../../../components/styleguide/Button'
import InputLabel from '../../../../../../components/styleguide/InputLabel'
import SectionWrapper from '../../../../../../components/styleguide/SectionWrapper'
import StyledDialog from '../../../../../../components/styleguide/StyledDialog'
import WrapperColumn from '../../../../../../components/styleguide/WrapperColumn'
import YBDescription from '../../../../../../components/styleguide/YBDescription'
import {
  getComparisonsByNameQuery,
  putComparisonsUpdate,
} from '../../../../../../repositories/portfolio_repository'
import { StyledList } from './ReplacePortfolioModal.styles'

const ReplacePortfolioModal = ({
  show,
  portfolios,
  setPortfolios,
  onClose,
  ...props
}) => {
  const mapPortfoliosToOptions = () => {
    return Object.fromEntries(
      portfolios.map((portfolio, index) => [
        index,
        {
          id: portfolio.id,
          type: portfolio.type,
          name: portfolio.name,
        },
      ])
    )
  }

  const [defaultPortfolioOptions, setDefaultPortfolioOptions] = useState([])
  const [portfoliosSelected, setPortfoliosSelected] = useState(
    mapPortfoliosToOptions()
  )
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(false)

  useEffect(() => {
    if (show) {
      getComparisonsByNameQuery('').then(response =>
        setDefaultPortfolioOptions(response.data)
      )
    }
  }, [show])

  useEffect(() => {
    setPortfoliosSelected(mapPortfoliosToOptions())
  }, [portfolios])

  const buildSelectOption = index => {
    if (!portfoliosSelected?.[index]?.id) {
      return 0
    }

    return {
      label: portfoliosSelected?.[index]?.name,
      value: {
        id: portfoliosSelected?.[index]?.id,
        type: portfoliosSelected?.[index]?.type,
      },
    }
  }

  const onOptionsLoad = input => {
    return getComparisonsByNameQuery(input).then(response => {
      return response.data
    })
  }

  const onPortfolioChange = portfolioIndex => option => {
    setPortfoliosSelected({
      ...portfoliosSelected,
      [portfolioIndex]: {
        id: option?.value?.id,
        type: option?.value?.type,
        name: option?.value?.name,
      },
    })
  }

  const onUpdatePortfolios = () => {
    const comparisonsSelected = Object.values(portfoliosSelected).map(p => {
      return { id: p.id, type: p.type }
    })

    setIsUpdateDisabled(true)

    putComparisonsUpdate(comparisonsSelected)
      .then(response => {
        setPortfolios(response.data.portfolios)
        onClose()
      })
      .finally(() => {
        setIsUpdateDisabled(false)
      })
  }

  return (
    <StyledDialog
      show={show}
      size='md'
      width='auto'
      padding='20px'
      sectionsPadding='0px'
      onHide={onClose}
      data-cy={props['data-cy']}
    >
      <Modal.Header closeButton>
        <Modal.Title>Select Landscape Portfolios</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WrapperColumn>
          <YBDescription>
            {
              'Select five portfolios or Benchmarks that will appear as part of your landscape report.'
            }
          </YBDescription>

          <InputLabel
            label='PORTFOLIO / BENCHMARK'
            margin='5px 0px 14px 10px'
          />

          <StyledList>
            {[...Array(5).keys()].map((_, index) => (
              <li key={index}>
                <AsyncSelect
                  components={{ IndicatorSeparator: () => null }}
                  value={buildSelectOption(index)}
                  defaultOption={[buildSelectOption(index)]}
                  defaultOptions={defaultPortfolioOptions}
                  loadOptions={onOptionsLoad}
                  onChange={onPortfolioChange(index)}
                  placeholder={'Select a Portfolio or Benchmark'}
                />
              </li>
            ))}
          </StyledList>
        </WrapperColumn>

        <SectionWrapper justify='flex-end'>
          <Button
            tertiary
            label='Cancel'
            width='auto'
            onClick={onClose}
            data-cy='portfolio-comparisons-replace-cancel'
          />
          <Button
            disabled={isUpdateDisabled}
            label='UPDATE LANDSCAPE'
            width='auto'
            onClick={onUpdatePortfolios}
            data-cy='portfolio-comparisons-replace-update'
          />
        </SectionWrapper>
      </Modal.Body>
    </StyledDialog>
  )
}

export default ReplacePortfolioModal
