import React from 'react'
import styled from 'styled-components'

import {
  APP_COLOR_PRIMARY,
  FONT_COLOR_SECONDARY,
} from '../../../../../styles/colors'
import { adjustIntervals } from '../helpers'
import LabelInputRow from './LabelInputRow'

const StyledForm = styled.div`
  .header {
    margin-bottom: 10px;
    text-transform: uppercase;
    color: ${FONT_COLOR_SECONDARY};
    font-weight: bold;
    font-size: 12px;

    display: flex;

    #label-header {
      flex: 2 1 0;
    }

    div {
      flex: 1 1 0;
    }
  }

  button {
    float: right;
    text-transform: uppercase;
    color: white;

    font-size: 13px;
    font-weight: 900;

    line-height: 16px;
    height: 37px;
    width: 142px;

    border-radius: 10px;
    border: none;
    background-color: ${APP_COLOR_PRIMARY};
    :disabled {
      background-color: #607abd;
    }
  }
`

const LabelsForm = ({ labels, setLabels, dispatch }) => {
  const handleLabelChange = ({ changedLabel, action }) => {
    let dupLabels = [...labels]

    if (!changedLabel)
      changedLabel = {
        color: APP_COLOR_PRIMARY,
        label: '',
        min: 0,
        max: 0,
        index: dupLabels.length,
      }

    if (action == 'delete') {
      if (labels.length > 3) {
        dupLabels = dupLabels.filter(label => label.index != changedLabel.index)
        dupLabels = dupLabels.map((label, index) => ({ ...label, index }))
        adjustIntervals(dupLabels, dupLabels[0])
      } else return
    } else if (action == 'new') {
      dupLabels.push(changedLabel)
      dupLabels[dupLabels.length - 2].max =
        dupLabels[dupLabels.length - 2].min + 0.99
      adjustIntervals(dupLabels, dupLabels[0])
    } else {
      const updateIntervals =
        dupLabels[changedLabel.index].min != changedLabel.min ||
        dupLabels[changedLabel.index].max != changedLabel.max

      dupLabels[changedLabel.index] = changedLabel

      updateIntervals && adjustIntervals(dupLabels, changedLabel)
    }

    //Clearly set the ends of the 0 - 100 spectrum
    dupLabels[0].min = 0
    dupLabels[dupLabels.length - 1].max = 100

    dispatch({ type: 'display-labels-data', payload: dupLabels })
    setLabels(dupLabels)
  }

  return (
    <StyledForm>
      <div className='header'>
        <div id='label-header'> Label</div>
        <div> Minimum Score</div>
        <div> Maximum Score</div>
      </div>
      {labels.map((labelData, index) => (
        <LabelInputRow
          key={`label-form-row-${index}`}
          onChange={({ changedLabel, action }) =>
            handleLabelChange({ changedLabel, action })
          }
          {...labelData}
        />
      ))}
      {labels.length < 7 && (
        <button onClick={() => handleLabelChange({ action: 'new' })}>
          Add Label
        </button>
      )}
    </StyledForm>
  )
}

export default LabelsForm
