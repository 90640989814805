import React, { useState } from 'react';

import SectionWrapper from "../../../styleguide/SectionWrapper";
import WrapperColumn from "../../../styleguide/WrapperColumn";
import TextSection from "../../../styleguide/TextSection";
import TextAreaPlain from "../../../styleguide/TextAreaPlain";
import {Form} from "react-bootstrap";

const CustomReportFormSubmissionTableRow = ({item, onUpdateResponse, onUpdateNote, dataLabel}) => {
    const questionnaire = item ? item.questionnaire : null;
    const defaultSelectedOptionLabel = questionnaire && questionnaire.options && questionnaire.options.length > 0 ? questionnaire.options[0].label : null;

    const [rangeValue, setRangeValue] = useState(0)
    const [textValue, setTextValue] = useState("");
    const [selectedOptionLabel, setSelectedOptionLabel] = useState(defaultSelectedOptionLabel);

    const onUpdateTextField = (answer) => {
        setTextValue(answer.value);
        onUpdateResponse(answer)
    };

    const onUpdateRangeResponse = (answer) => {
        let divisor;

        if (questionnaire.options.length === 3) {
            if(answer.value < 33){
                answer.value = 0;
            } else if(answer.value > 33 && answer.value < 66){
                answer.value = 1;
            } else {
                answer.value = 2;
            }

            divisor = 2;
        }

        if (questionnaire.options.length === 2) {
            if (answer.value < 50) {
                answer.value = 0;
            } else {
                answer.value = 1;
            }

            divisor = 1;
        }

        const selectedOption = questionnaire.options.find(option => parseInt(option.value) === parseInt(answer.value));

        setRangeValue( (answer.value/divisor)*100 );
        onUpdateResponse(answer);
        setSelectedOptionLabel(selectedOption.label);
    }

    return (
        <>
            <SectionWrapper margin="0px 0px 40px 20px" justify="space-between" data-cy={`${dataLabel}-create-ifc-item-wrapper`}>
                <SectionWrapper flex="1">
                    <TextSection data-cy={`${dataLabel}-creazte-ifc-item-prompt`}>
                        <b>{ item.description.toUpperCase() }</b>
                        <p>{ questionnaire && questionnaire.prompt}</p>
                    </TextSection>
                </SectionWrapper>
                <SectionWrapper flex="1">
                    <TextSection align="center" data-cy={`${dataLabel}-create-ifc-input-wrapper`}>
                        <WrapperColumn styles={{align:"center"}} data-cy={`${dataLabel}-create-ifc-input-inner-wrapper`}>
                            {
                                questionnaire && questionnaire.options && questionnaire.options.length >0 &&
                                <SectionWrapper width="50%" data-cy={`${dataLabel}-create-ifc-input wrapper`}>
                                    <Form.Control
                                        data-cy={`${dataLabel}-create-ifc-value-range-slider`}
                                        onChange={e => onUpdateRangeResponse({ key: item.key, value:(e.target.value) })}
                                        type="range"
                                        value={rangeValue}
                                        id={item.id}
                                    />
                                    <SectionWrapper width="100%" justify="space-between" data-cy={`${dataLabel}-create-ifc-value-options`}>
                                        {
                                            questionnaire.options.map((option, index) => {
                                                return(<span>{index}</span>)
                                            })
                                        }
                                    </SectionWrapper>
                                </SectionWrapper>
                            }

                            {
                                !questionnaire.options &&
                                <Form.Control
                                    data-cy={`${dataLabel}-create-ifc-value-text-field`}
                                    onChange={e => onUpdateTextField({ key: item.key, value:(e.target.value) })}
                                    type="text-field"
                                    value={textValue}
                                    id={item.id}
                                />
                            }
                        </WrapperColumn>
                    </TextSection>
                </SectionWrapper>
                <SectionWrapper justify="space-around" flex="1" wrap="nowrap">
                    <TextSection width="50%" margin="0px 15px 0px 0px">
                        { selectedOptionLabel }
                    </TextSection>
                    <TextAreaPlain
                        width="50%"
                        height="25%"
                        data-cy={`${dataLabel}-create-ifc-create-note-input`}
                        height="50px"
                        onChange={e => onUpdateNote({  key: item.key, value: (e.target.value) })}
                    />
                </SectionWrapper>
            </SectionWrapper>
        </>
    )
};

export default CustomReportFormSubmissionTableRow;
