import React, { useState } from 'react';
import SectionWrapper from "../../components/styleguide/SectionWrapper";
import TextInput from "../../components/styleguide/TextInput";
import Button from "../../components/styleguide/Button";
import { postVerifyPassword } from "../../repositories/password_verifications";
import Link from "../styleguide/Link";

import WrapperColumn from "../styleguide/WrapperColumn";

import { invalidPasswordErrorMessage } from "../../constants/passwords";

const PasswordVerificationForm = ({onVerify}) => {
    const [password, setPassword] = useState("");
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    const onPasswordVerify = () => {
        setErrorMessage(null);

        postVerifyPassword({ password }).then(() => {
            onVerify();
        }).catch(() => {
            setErrorMessage(invalidPasswordErrorMessage);
        })
    };

    const onChangePassword = (newPassword) => {
        setPassword(newPassword);
        setErrorMessage(null);

        if (newPassword.length === 0) {
            setDisableSubmit(true);
        } else {
            setDisableSubmit(false);
        }
    }

    return(<>
        <WrapperColumn border="0px" padding="10px" align="center">
            <SectionWrapper><h3>Verify Your Password</h3></SectionWrapper>
            <SectionWrapper><h4>The change you are trying to make requires you to verify your password.</h4></SectionWrapper>

            <SectionWrapper width="30%">
                <TextInput
                           value={password}
                           type="password"
                           width="100%"
                           onChange={(id, value, e) => onChangePassword(value)}
                           error={errorMessage}
                           onKeyPress={onPasswordVerify}
                           data-cy="password-input"
                />
            </SectionWrapper>
            <SectionWrapper justify="space-between" width="50%">
                <Link href={"/app"}><h5>Cancel</h5></Link>
                <Button width="20%" label="Verify" disabled={disableSubmit} onClick={onPasswordVerify} data-cy="verify-button" />
            </SectionWrapper>
        </WrapperColumn>
    </>)
};

export default PasswordVerificationForm;
