import PropTypes from 'prop-types'
import React from 'react'

import { PaiDownloadsTab } from '../../pai_page_tabs/PaiDownloadsTab'
import { PaiEntitiesTab } from '../../pai_page_tabs/PaiEntitiesTab'
import { PaiEntityDetailTab } from '../../pai_page_tabs/PaiEntityDetailTab'
import { PaiPolicyIndicatorsTab } from '../../pai_page_tabs/PaiIndicatorsTab'
import { PaiOverviewTab } from '../../pai_page_tabs/PaiOverviewTab'
import { PaiPolicyStatementsTab } from '../../pai_page_tabs/PaiPolicyStatementsTab'

const tabFinder = (activeTab, portfolioId, snapshotId) => {
  switch (activeTab) {
    case 'dash':
      return (
        <PaiOverviewTab portfolioId={portfolioId} snapshotId={snapshotId} />
      )
    case 'pai_policy_statements':
      return (
        <PaiPolicyStatementsTab
          portfolioId={portfolioId}
          snapshotId={snapshotId}
        />
      )
    case 'pai_indicators':
      return (
        <PaiPolicyIndicatorsTab
          portfolioId={portfolioId}
          snapshotId={snapshotId}
        />
      )
    case 'pai_entities':
      return (
        <PaiEntitiesTab portfolioId={portfolioId} snapshotId={snapshotId} />
      )
    case 'pai_entity_detail':
      return (
        <PaiEntityDetailTab portfolioId={portfolioId} snapshotId={snapshotId} />
      )
    case 'pai_downloads':
      return (
        <PaiDownloadsTab portfolioId={portfolioId} snapshotId={snapshotId} />
      )
  }
}

export const PaiTabContent = ({ activeTab, portfolioId, snapshotId }) => {
  return <div>{tabFinder(activeTab, portfolioId, snapshotId)}</div>
}

PaiTabContent.propTypes = {
  activeTab: PropTypes.string.isRequired,
  portfolioId: PropTypes.string.isRequired,
  snapshotId: PropTypes.string.isRequired,
}
