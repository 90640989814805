import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FormControl } from 'react-bootstrap'

import SwitchButton from '../../../../../../../../components/styleguide/SwitchButton'
import { PAI_REQUIRED_OPTIONS } from '../../filters/PaiFilters.constants'
import { StyledFiltersContainer } from '../../filters/PaiFilters.styles'

export const PaiEntityDetailFilters = ({
  entityName,
  categories,
  onChange,
}) => {
  const [category, setCategory] = useState('all')
  const [displayRequirement, setDisplayRequirement] = useState('all')

  useEffect(() => {
    onChange({ category, displayRequirement })
  }, [category, displayRequirement])

  return (
    <StyledFiltersContainer>
      <div style={{ width: '300px' }}>
        <h4>{entityName}</h4>
      </div>
      <div style={{ width: '300px' }}>
        <label htmlFor='categories_select'>Indicator Categories</label>
        <FormControl
          as='select'
          size='sm'
          name='categories_select'
          value={category}
          id='categories_select'
          onChange={evt => setCategory(evt.target.value)}
        >
          <option value='all'>All</option>
          {categories.map(category => (
            <option key={category} value={category}>
              {category.charAt(0).toUpperCase()}
              {category.slice(1).toLowerCase()}
            </option>
          ))}
        </FormControl>
      </div>
      <div>
        <label>PAI Tables</label>
        <SwitchButton
          onClick={setDisplayRequirement}
          active={displayRequirement}
          options={PAI_REQUIRED_OPTIONS}
        />
      </div>
    </StyledFiltersContainer>
  )
}

PaiEntityDetailFilters.propTypes = {
  entityName: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}
