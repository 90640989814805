import React from 'react';
import propTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Card from '../styleguide/Card';
import Table from '../styleguide/Table';
import CardTitle from '../styleguide/CardTitle';
import HorizontalProgress from '../styleguide/HorizontalProgress';
import withErrorBoundary from '../../hocs/withErrorBoundary';

const StyledCard = styled(Card)`
  .asset-allocation-table {
    overflow: none;
    max-height: 90%;

    table {
      margin-top: 20px;

      thead {
        color: #6C757D;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;

        tr:first-child > th {
          padding-bottom: 0px;
        }

        tr:nth-child(2) > th {
          padding-top: 5px;
          width: 8vw;
        }

        tr {
          th:first-child {
            text-align: left;
            width: 20vw !important;
          }

          th:last-child {
            padding-right: 10px;
          }

          th > span {
            padding-top: 0px;
            font-size: 14px;
          }
        }
      }

      tbody {
        font-size: 12px;
        line-height: 14px;
        color: #6C757D;
        
        tr {
          height: 34px;

          td:first-child {
            text-align: left;
          }

          td {
            padding-right: 10px;
            text-align: right;
          }
        }
      }

      tfoot {
        background-color: #F8F9FA;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;

        td:first-child {
          text-align: left;
        }

        td {
          padding-right: 10px;
          border: none;
          text-align: right;
        }
      }

      ${props => props.hasShorts && css`
        .score-cell {
          display: inline-block;
          font-size: 0.86rem,
          font-weight: bold;
          text-align: right;
          width: 200px;

          > :first-child {
            display: inline-block;
            margin-right: 5px;
            vertical-align: middle;
          }

          > span {
            min-width: 30px;
            display: inline-block;
            text-align: right;
          }
        }
      `}
    }

  }
`;

const getAssetClassValue = (value) => {
  return value.toLocaleString("en-US", { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0})
}

const getTableRow = ({showValue, hasShorts, name, weight, value}) => {

  if (showValue && !hasShorts) {
    return [ name, renderScoreCell(weight.longs), getAssetClassValue(value.longs) ]
  } else if (showValue && hasShorts) {
    return [ name, weight.shorts, weight.longs, getAssetClassValue(value.shorts), getAssetClassValue(value.longs) ]
  } else {
    return [ name, renderScoreCell(weight) ]
  }
}

const renderScoreCell = value => {
  return (
    <div className="score-cell">
      <HorizontalProgress height={10} width={100} progress={value}/>
      <span>{value < 1 ? "< 1" : value}%</span>
    </div>
  );
}

const getTableHeaders = ({showValue, hasShorts}) => {
  if (showValue && !hasShorts) {
    return [{ label: "ASSET CLASS" }, { label: "WEIGHT" }, { label: "VALUE" }]
  } else if (showValue && hasShorts) {
    return [{ label: "ASSET CLASS" }, { label: "SHORT" }, { label: "LONG" }, { label: "SHORT" }, { label: "LONG" }]
  } else if (!showValue && hasShorts) {
    return [{ label: "ASSET CLASS" }, { label: "SHORT" }, { label: "LONG" }]
  } else {
    return [{ label: "ASSET CLASS" }, { label: "WEIGHT" }]
  }
}

const getTableFooter = ({totalWeight, totalValue}) => {
  const hasShorts = totalValue?.shorts != 0
  if (hasShorts) {
    return totalWeight.longs && [["Total Assets", `${totalWeight.shorts} %`, `${totalWeight.longs} %`, getAssetClassValue(totalValue.shorts), getAssetClassValue(totalValue.longs)]]
  } else {
    return totalWeight.longs && [["Total", renderScoreCell(totalWeight.longs), getAssetClassValue(totalValue.longs)]]
  }
}

const AssetClassAllocationCard = ({ total_weight, total_value, assetClasses, showValue = true }) => {
  const assets = assetClasses.allocations
  const totalWeight = assetClasses.totals.weight
  const totalValue = assetClasses.totals.value
  const hasShorts = assetClasses.totals?.value?.shorts != 0

  const headers = getTableHeaders({showValue, hasShorts})
  
  const body = assets.map(({ name, weight, value }) => getTableRow({showValue, hasShorts, name, weight, value}))

  const footer = getTableFooter({totalWeight, totalValue})

  const multiRowHeaders = hasShorts && (
    <tr>
      <th/>
      <th colspan="2"> WEIGHT (%) </th>
      { showValue && (<th colspan="2"> VALUE ($ USD) </th>) }
    </tr>
  )
  return (
    <StyledCard padding={25} hasShorts>
      <CardTitle title="ASSET CLASS ALLOCATION"/>
      <div className="asset-allocation-table">
        <Table
          showSort={false}
          multiRowHeaders={multiRowHeaders}
          headers={headers}
          body={body}
          footer={footer}
          look={{thTextAlign: 'center'}}
        />
      </div>
    </StyledCard>
  );
}

AssetClassAllocationCard.defaultProps = {
  assets: []
};

AssetClassAllocationCard.propTypes = {
  assets: propTypes.arrayOf(propTypes.shape({
    name: propTypes.string.isRequired,
    weight: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired
  })).isRequired,
};

export default withErrorBoundary(AssetClassAllocationCard);
