import React from 'react'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'

import { baseErrorCss } from '../styles/BaseForm.styles'

const StyledText = styled(Form.Text)`
  margin-top: 6px;
  font-size: 12px;
  line-height: 1.2;
  font-style: italic;

  ${baseErrorCss}
`

export const BaseErrorText = ({ ...props }) => {
  return <StyledText {...props}></StyledText>
}
