import React from 'react';
import styled from 'styled-components';

const StyledAlert = styled.div`
  &.alert {
    margin: .5rem 0;
  }
`

const Alert = ({type, message}) => {
  return (
    <StyledAlert className={`alert alert-${type}`}>
      {message}
    </StyledAlert>
  )
}

export default Alert;
