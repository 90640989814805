import styled from 'styled-components'

export const StyledCard = styled.div`
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  ${props => (props.paddingTop ? `padding-top: ${props.paddingTop}` : '')};
  ${props =>
    props.paddingRight ? `padding-right: ${props.paddingRight}` : ''};
  ${props => (props.paddingLeft ? `padding-left: ${props.paddingLeft}` : '')};
  ${props =>
    props.paddingBottom ? `padding-bottom: ${props.paddingBottom}` : ''};

  height: ${props => props.height};
  width: ${props => props.width};
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.05);
`
