import styled from 'styled-components';
import { BG_COLOR_PRIMARY, BG_COLOR_WHITE } from '../../../../../../styles/colors';

export const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 50px;
  li {
    padding: 5px 20px;
    border-radius: 2px;
    &:nth-child(odd) {
      background-color: ${BG_COLOR_PRIMARY};
    }
    &:nth-child(even) {
      background-color: ${BG_COLOR_WHITE};
    }
  }
`
