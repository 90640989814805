import axios from 'axios'

export const createRiskAssessment = (fundId, holdingId, data) => {
  return axios.post(
    `/app/api/hedge_fund/${fundId}/holdings/${holdingId}/risk_assessments`,
    { risk_assessment: data }
  )
}

export const updateRiskAssessment = (
  fundId,
  holdingId,
  assessmentId,
  values
) => {
  return axios
    .put(
      `/app/api/hedge_fund/${fundId}/holdings/${holdingId}/risk_assessments/${assessmentId}`,
      {
        id: assessmentId,
        risk_assessment: values,
      }
    )
    .then(res => res.data)
}

export const createFocusArea = (holdingId, data) => {
  if (data.initiative_id === 'custom') {
    data.initiative_id = null
  }

  return axios.post(`/app/api/holdings/${holdingId}/focus_areas`, {
    focus_area: data,
  })
}

export const createFocusAreaNote = (holdingId, focusAreaId, note) => {
  return axios.post(
    `/app/api/holdings/${holdingId}/focus_areas/${focusAreaId}/notes`,
    {
      note: note,
    }
  )
}

export const updateFocusArea = (
  holdingId,
  focusAreaId,
  editingType,
  value,
  date,
  note
) => {
  let payload = {}
  if (editingType == 'current_value') {
    payload = {
      current_value: value,
      current_as_of: new Date(),
      note: note,
    }
  } else {
    payload = {
      target_value: value,
      target_date: date,
      note: note,
    }
  }

  return axios
    .post(
      `/app/api/holdings/${holdingId}/focus_areas/${focusAreaId}/statuses`,
      payload
    )
    .then(res => res.data)
}

export const enableFocusArea = (holdingId, focusAreaId) => {
  return axios.put(
    `/app/api/holdings/${holdingId}/focus_areas/${focusAreaId}/enable`
  )
}

export const disableFocusArea = (holdingId, focusAreaId) => {
  return axios.put(
    `/app/api/holdings/${holdingId}/focus_areas/${focusAreaId}/disable`
  )
}
