import React, {useState, useEffect} from 'react';
import { postQueryFundHoldingEvents } from "../../../../../repositories/funds_repository";
import EventsTable from "../../../../../components/event/EventsTable";
import {FundTableWrapper, ModuleWrapper} from "../../../styles";

const HoldingActivityModule = ({holding}) => {
    const [events, setEvents] = useState([]);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);

    const perPage = 10;

    useEffect(() => {
        postQueryFundHoldingEvents({holding, page, perPage}).then((response) => {
            setEvents(response.data.events);
            setPageCount(response.data.page_count);
        })
    }, [0]);

    const onPaginatorChange = (newPage) => {
        postQueryFundHoldingEvents({holding: holding, page: newPage, perPage: perPage}).then((response) => {
            setEvents(response.data.events);
            setPage(newPage);
        })
    };

    return(
        <>
            <ModuleWrapper>
                <FundTableWrapper>
                    {
                        events && <EventsTable events={events}
                                               page={page} pageCount={pageCount}
                                               onPaginatorChange={onPaginatorChange} />
                    }
                </FundTableWrapper>
            </ModuleWrapper>
        </>
    )
};

export default HoldingActivityModule;
