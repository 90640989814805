import classNames from 'classnames'
import React from 'react'
// import theme from './RTSDiagramNode.module.scss'
import './RTSDiagramNode.module.scss'

interface IProps {
  label: string
  amount: string
  color?: string
  backgroundColor?: string
  className?: string
}

export const RTSDiagramNode: React.FC<IProps> = ({
  label,
  amount,
  backgroundColor,
  color,
  className = '',
}) => (
  <div
    className={classNames('RTSDiagramNode', className)}
    style={{
      color,
      backgroundColor,
    }}
  >
    <strong>{label}</strong>
    <span>{amount}</span>
  </div>
)
