import axios from 'axios'

export const postEntity = async payload => {
  try {
    const res = await axios.post('/app/api/entities', payload)
    return res.data
  } catch (e) {
    console.error(e)
  }
}

export const updateEntity = async (id, payload) => {
  try {
    const res = await axios.put(`/app/api/entities/${id}`, payload)
    return res.data
  } catch (e) {
    console.error(e)
  }
}

export const getEntityById = async id => {
  try {
    const res = await axios.get(`/app/api/entities/${id}`)
    return res.data
  } catch (e) {
    console.error(e)
  }
}

export const getCountriesList = async () => {
  try {
    const res = await axios.get('/app/api/countries')
    return res.data
  } catch (e) {
    console.error(e)
  }
}

export const getIndustyGroups = async () => {
  try {
    const res = await axios.get('/app/api/industry_groups/list')
    return res.data
  } catch (e) {
    console.error(e)
  }
}

export const getPeerCompanies = async id => {
  try {
    const res = await axios.get(
      `/app/api/industry_groups/peer_companies?trbc_activity_id=${id}`
    )
    return res.data
  } catch (e) {
    console.error(e)
  }
}

export const postUploadLogo = async payload => {
  try {
    const res = await axios.post('/app/api/file_upload', payload)
    return res.data
  } catch (e) {
    console.error(e)
  }
}

export const getImpactThemes = async () => {
  try {
    const res = await axios.get('/app/api/impact_themes')
    return res.data
  } catch (e) {
    console.error(e)
  }
}
