import qs from 'qs'
import React from 'react'

import Funnel from './components/Funnel'
import InTheMix from './components/InTheMix'
import LatestResearch from './components/LatestResearch'
import SearchByAssetClass from './components/SearchByAssetClass'
import SearchByImpactTheme from './components/SearchByImpactTheme'
import SearchByName from './components/SearchByName'
import SearchBySDG from './components/SearchBySDG'

const ManagersIndex = () => {
  const redirectToSearchPage = searchKey => searchValue => {
    window.location = `/app/managers/search?${qs.stringify({
      [searchKey]: searchValue,
    })}`
  }

  return (
    <div className='page__container'>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom'>
        <h1 className='h2'>Flat World Partners Manager Research</h1>
      </div>
      <div className='row'>
        <div className='col-md-8'>
          <LatestResearch />
          <hr />
          <InTheMix />
          <hr />
          <Funnel />
        </div>
        <div className='col-md-4'>
          <SearchByName onNameChange={redirectToSearchPage('query')} />
          <SearchByAssetClass
            onAssetClassChange={redirectToSearchPage('assetClassId')}
          />
          <SearchBySDG onSDGChange={redirectToSearchPage('sdg')} />
          <SearchByImpactTheme
            onImpactThemeChange={redirectToSearchPage('primaryThemeId')}
          />
        </div>
      </div>
    </div>
  )
}

export default ManagersIndex
