//LIBRARIES
import React, { useState } from 'react';
import _ from 'lodash'
import { reportingFrameworksDefault } from '../../../../constants/funds';
import { ModuleWrapper } from '../../styles';
import SaveButton from '../../components/SaveButton';
import { FormCheck } from 'react-bootstrap';
import SectionWrapper from '../../../../components/styleguide/SectionWrapper';
import TextSection from '../../../../components/styleguide/TextSection';
import CardTitle from '../../../../components/styleguide/CardTitle';
import Link from '../../../../components/styleguide/Link';
import { postReportingFramework } from "../../../../repositories/funds_repository";
import SDGFilter from '../../../../components/styleguide/SDGFilter';
import { BORDER_COLOR_PRIMARY } from '../../../../styles/colors';

const ReportingFrameworkModule = ({ fundId, callbacks, config }) => {
    const [sdgErrorsPresent, setSDGErrorsPresent] = useState(false);
    const [ifcPrinciplesIncluded, setIfcPrinciplesIncluded] = useState(false);
    const [SDGIncluded, setSDGIncluded] = useState(true);
    const [includedSDGs, setIncludedSDGs] = useState([]);

    const handleUnSDGToggle = ( sdgs ) => {
        if(sdgs.length > 4){
            sdgs = sdgs.slice(1)
        }

        setIncludedSDGs(sdgs);
    };

    const frameworkToggle = (event)  => {
        const name = event.target.id;

        if (name === "IFC Operating Principles for Impact Management") {
            setIfcPrinciplesIncluded(!ifcPrinciplesIncluded)
        } else {
            setSDGIncluded(!SDGIncluded)
        }
    }

    const SDGsInvalid = () => {
        if (SDGIncluded && includedSDGs.length === 0) {
            window.scrollTo(0,0);
            setSDGErrorsPresent(true);
            return true;
        } else {
            setSDGErrorsPresent(false);
            return false;
        }
    }

    const onSave = () => {
        if(!SDGsInvalid()){
            postReportingFramework(fundId, mapStateToPayload()).then(() => {
                _.each(callbacks, (callback) => {
                    callback();
                })
            });
        }
    };

    const mapStateToPayload = () => {
        let payload = { include_ifc_principles: ifcPrinciplesIncluded };

        if (includedSDGs) {
            payload.un_sdgs = includedSDGs;
        } else {
            payload.un_sdgs = [];
        }

        return payload;
    };

    const isChecked = (framework) => {
        if (framework === "IFC Operating Principles for Impact Management") {
            return ifcPrinciplesIncluded;
        } else {
            return SDGIncluded;
        }
    };

    return (
        <>
            <ModuleWrapper data-cy="reporting-framework-module-wrapper">
                <SectionWrapper data-cy="reporting-framework:title-wrapper" margin="10px 0px 10px 12px">
                 <CardTitle title="Reporting Frameworks"/>
                </SectionWrapper>
                 <TextSection data-cy="reporting-framework:explainer-wrapper" margin="0px 0px 15px 15px" width="95%">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tortor libero, pretium nec ullamcorper et, pharetra sed nisi. Vivamus imperdiet congue purus, eget consequat libero vehicula in. Aliquam vehicula ligula quis tincidunt aliquam. Vivamus eget massa faucibus, finibus ex in, egestas leo. Nulla neque nunc, semper non vehicula eget, feugiat id nisl. Sed aliquet risus vitae lacus pretium tempus. Morbi facilisis, sem id aliquam fringilla, arcu quam volutpat nisi, eget scelerisque mauris nunc in dolor. Sed ut erat quis urna pellentesque pharetra ac vel velit.
                  </TextSection>
                  {
                    sdgErrorsPresent && <div className="alert alert-danger">You Must Select At Least One SDG</div>
                  }
                {Object.keys(reportingFrameworksDefault).map((framework, i) => (
                    <SectionWrapper margin="10px 20px 10px 15px" key={`reporting-framework:framework-selection-wrapper-${i}`} data-cy={`reporting-framework:framework-selection-wrapper-${i}`}>
                        <SectionWrapper background={BORDER_COLOR_PRIMARY} data-cy={`reporting-framework:framework-selection-wrapper::framework-header-${i}`} align="center" width="100%" margin="10px 0px 10px 0px" justify="space-between">
                            <TextSection data-cy={`reporting-framework:framework-selection-wrapper::framework-title-${i}`} margin="0px 10px 0px 0px" width="auto">{framework}
                            </TextSection>
                            {
                                config.save && (
                                <SectionWrapper>
                                    <TextSection width="auto" margin="0px 10px 0px 0px" data-cy={`reporting-framework:framework-selection-wrapper::tab-wrapper-${i}`}>Enabled</TextSection>
                                    <FormCheck
                                        data-cy={`reporting-framework:framework-selection-wrapper::switch-${i}`}
                                        onChange={frameworkToggle}
                                        type="switch"
                                        checked={isChecked(framework)}
                                        id={framework}
                                        label={""}
                                    />
                                </SectionWrapper>
                                )
                            }
                        </SectionWrapper>
                        <SectionWrapper margin="0px 0px 0px 20px">
                            <TextSection fontSize="14px" data-cy={`reporting-framework:framework-selection-wrapper::framework-description-${i}`}>
                                {reportingFrameworksDefault[framework].description}
                                <Link data-cy={`reporting-framework:framework-selection-wrapper::more-info-line-${i}`} children="More Info &raquo;" styles={{margin:"0px 0px 0px 8px"}}/>
                            </TextSection>
                            <SectionWrapper margin="10px 0px 10px 0px" data-cy={`reporting-framework:framework-selection-wrapper::individual-framework-wrapper-${i}`}>
                                <SectionWrapper width="auto" data-cy={`reporting-framework:framework-selection-wrapper::form-wrapper${i}`}>
                                    {
                                            framework === "UN Sustainability Goals" && isChecked(framework) &&  (
                                                <SectionWrapper margin="0px 0px 0px 20px" width="80%" data-cy={`reporting-framework:framework-selection-wrapper::sdg-wrapper-${i}`}>
                                                    <TextSection data-cy={`reporting-framework:framework-selection-wrapper::sdg-instructions-${i}`} width="auto">Select up to 4 UN SDGs that your fund is aligned with:</TextSection>
                                                    <SDGFilter
                                                        showLogo={false}
                                                        allowModal={true}
                                                        data-cy={`reporting-framework:framework-selection-wrapper::sdg-filter-${i}`}
                                                        selected={includedSDGs}
                                                        setSelected={handleUnSDGToggle}
                                                        sdgCount={16}
                                                    />
                                                </SectionWrapper>
                                            )
                                    }
                                </SectionWrapper>
                            </SectionWrapper>
                        </SectionWrapper>
                    </SectionWrapper>
                ))}
            </ModuleWrapper>
                {
                    config.save && (
                        <SaveButton data-cy={`reporting-framework:save-button`} onSave={onSave} styles={{justify: "flex-end"}}/>
                    )
                }
        </>
    );
};

export default ReportingFrameworkModule;
