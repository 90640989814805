import React from 'react';
import { comparisonsTableColumnCount } from '../../../constants';
import { StyledCell } from './IconTableRow.styles';

const IconTableRow = ({ rowLabel, icons, portfolios, displayEmptyColumns }) => {
  const tableColumnCount = displayEmptyColumns ? comparisonsTableColumnCount : portfolios.length

  return (
    <tr>
      <td className="name-column">{ rowLabel }</td>
      { [...Array(tableColumnCount).keys()].map(i => {

        return (
          <StyledCell key={ `table-icon-cell-${i+1}` }>
            { icons(portfolios?.[i]) }
          </StyledCell>
        )
      })}
    </tr>
  )
}

export default IconTableRow
