import React from 'react';
import injectSheet from 'react-jss';
import HorizontalProgress from './HorizontalProgress';
import propTypes from 'prop-types';
import { FONT_COLOR_SECONDARY } from '../../styles/colors';
import cns from '../../helpers/classnames';

const HorizontalScore = ({ score, extra, classes, label, light, className, height, color }) => {
  return (
    <div className={cns(classes.score, className)}>
      <div className={classes.progress}>
        {label ? <span>{label}</span> : null}
        {extra ? <div className={classes.extra}>{extra}</div> : null}
        <HorizontalProgress height={height} light={light} progress={score} color={color} />
      </div>
    </div>
  );
}

HorizontalScore.propTypes = {
  score: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
  extra: propTypes.node,
  classes: propTypes.object.isRequired,
  label: propTypes.string,
  light: propTypes.bool
};

const style = {
  progress: {
    marginTop: '13px',
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      fontSize: '14px',
      fontWeight: 'bold',
    }
  },
  extra: {
    display: 'inline-block',
    float: 'right'
  }
};

export default injectSheet(style)(HorizontalScore);