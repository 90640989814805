import React from 'react';
import { addThousandSeparator } from '../../../helpers/number';

const ChartLegendPDF = ({ data }) => {
  return (
    <div className="chart-legend">
      {data.map(d => (
        <div key={d.label} className="legend-section">
          <div style={{ display: "inline-block"}}>
            <span className="legend-value" style={{ color: d.textColor }}>{d.value}</span>
            <br/>
            {d.unit &&<span className="legend-unit">&nbsp;{d.unit}</span>}
            <br/>
            <span className="label">{d.label}</span>
          </div>
          {!isNaN(d.benchmarkComparison) && (
            <div style={{ display: "inline-block", marginLeft: "60px", marginBottom: "10px"}}>
              <span style={{ color: `${d.benchmarkComparison > 0 ? "#D30D25" : "#0BD875"}` }}>{`${addThousandSeparator(d.benchmarkComparison)}%`}</span>
              <br/>
              <span className="legend-unit">vs. Benchmark</span>
              <br/>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default ChartLegendPDF;
