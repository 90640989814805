import React, { useEffect, useMemo } from 'react'
import Pagination from '../../../../../../../react/yb_components/styleguide/pagination/Pagination.jsx'
import { IconMap } from '@ui/components/presentation/IconMap'
import {
  SortedTableHead,
  Table,
  TableController,
  TableHead,
} from '@ui/components/presentation/Table'
import { ITableHeadSort } from '@ui/components/presentation/Table/components/SortedTableHead'
import { Link } from '@ui/elements/Link'
import uuid from '@utils/uuid'
import classNames from 'classnames'
import { useCallback, useState } from 'react'

import { HoldingDataFilters } from './components/HoldingDataFilters'
import { HoldingDataIcon } from './components/HoldingDataIcon'
import HoldingDataController, { IHoldingData } from './HoldingData.controller'
// import theme from './HoldingData.module.scss'
import './HoldingData.module.scss'
import styled from 'styled-components'
import { Accordion, Button, Card, Form } from 'react-bootstrap'
import {
  getHoldingsData,
  updateHoldingsData,
} from '../../../../../../../react/repositories/portfolio_repository.js'
import { IHoldingDataFiltersData } from './components/HoldingDataFilters/HoldingDataFilters.js'

const TableRenderer = new TableController()

const CustomCheck = styled(Form.Check)`
  .custom-switch .custom-control-label::after {
    background-color: white !important;
  }

  .custom-control-label::before {
    border-color: #e74c4c;
    background-color: #e74c4c;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #7bd600;
    background-color: #7bd600;
  }
`

const StyledCenteredDiv = styled.div`
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledInstrumentText = styled.div`
  ${props => (props.positive === true ? 'color: #7bd600;' : '')}
  ${props => (props.positive === false ? 'color: #e74c4c;' : '')}
`

export const HoldingData: React.FC<YB.IWithPortfolioIds> = ({
  portfolioId,
  snapshotId,
}) => {
  const [holdingsData, setHoldingsData] = useState<IHoldingData[] | null>(null)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [filters, setFilters] = useState<any>({})
  const [indicators, setIndicators] = useState<string[] | undefined>([])

  const sortingModel = useState<ITableHeadSort>({
    value: 'weight',
    order: 'desc',
  })
  const [sorting, setSorting] = sortingModel

  const header = useMemo(() => {
    return indicators?.map(elem => {
      return new TableHead(elem?.display_name, elem?.key)
    })
  }, [indicators])

  HoldingDataController.useGetDisclosures(
    portfolioId,
    snapshotId,
    filters,
    sorting,
    currentPage,
    useCallback((data, totalPages, currentPage, newIndicators) => {
      setHoldingsData(data)
      setTotalPages(totalPages || 1)
      setIndicators(newIndicators)
    }, [])
  )

  const getStatus = (value: boolean | string | null) => {
    if (value === true) return 'complete'
    if (value === false) return 'incomplete'
    return 'not_applicable'
  }

  const entityIndicators = elem =>
    indicators?.slice(3)?.map(indicatorElem => {
      return (
        <HoldingDataIcon
          key={uuid()}
          status={getStatus(elem[indicatorElem?.key])}
        />
      )
    }) || []

  const getCellBgColor = (index: number | null) => {
    let bgColor = 'transparent'
    if (index !== null) {
      if (index % 2 === 0) {
        bgColor = '#f1f1f1'
      }
    }
    return bgColor
  }

  const renderEntity = (
    elem: IHoldingData,
    parent: IHoldingData | null = null,
    index: number | null = null
  ) => {
    return [
      {
        marginLeft: parent ? '100px' : '',
        backgroundColor: getCellBgColor(index),
      },
      <>
        {parent ? Array.from({ length: 10 }).map(() => <>&nbsp;</>) : ''}
        <Link key={uuid()} href={`/app/instruments/${elem?.id}`}>
          {parent ? '●' : ''} {elem?.name || '—'}
        </Link>
      </>,
      <CustomCheck
        key={uuid()}
        id={uuid()}
        // id={`${elem?.id}_e_characteristics`}
        type='switch'
        onChange={() =>
          onSwitch(
            parent?.target_type || null,
            parent?.target_id || null,
            elem.target_type,
            elem.target_id,
            {
              e_characteristics: !elem.e_characteristics,
            }
          )
        }
        defaultChecked={elem?.e_characteristics}
      />,
      <CustomCheck
        key={uuid()}
        id={uuid()}
        // id={`${elem?.id}_s_characteristics`}
        type='switch'
        onChange={() =>
          onSwitch(
            parent?.target_type || null,
            parent?.target_id || null,
            elem.target_type,
            elem.target_id,
            {
              s_characteristics: !elem.s_characteristics,
            }
          )
        }
        defaultChecked={elem?.s_characteristics}
      />,
    ].concat(entityIndicators(elem))
  }

  const renderInstrument = (
    elem: IHoldingData,
    index: number | null = null
  ) => {
    const formatCell = value => (value ? `${(value * 100)?.toFixed(1)}%` : '–')

    const renderCell = aValue => {
      const value = parseFloat(aValue)
      return (
        <StyledInstrumentText
          key={uuid()}
          positive={value ? value >= 0.5 : null}
        >
          {formatCell(value)}
        </StyledInstrumentText>
      )
    }

    return {
      targetType: 'Instrument',
      target: [
        { backgroundColor: getCellBgColor(index) },
        <Link key={uuid()} href={`/app/instruments/${elem?.id}`}>
          {elem?.name || '—'}
        </Link>,
      ].concat(
        indicators
          ?.slice(1)
          ?.map(indicatorElem => renderCell(elem[indicatorElem?.key])) || []
      ),
      data: elem?.data?.map((e, localIndex) =>
        renderEntity(e, elem, localIndex)
      ),
    }
  }

  const renderElem = (elem: IHoldingData, index: number) => {
    switch (elem?.target_type) {
      case 'Entity':
        return renderEntity(elem, null, index)

      case 'Instrument':
        return renderInstrument(elem, index)

      default:
        return new Array(15).fill('–')
    }
  }
  const HoldingsDataContent = holdingsData?.map((elem, index) =>
    renderElem(elem, index)
  )

  TableRenderer.onRenderHeader = (tableHead: TableHead) => (
    <SortedTableHead data={tableHead} sortingModel={sortingModel} />
  )

  const onSwitch = (parentType, parentId, targetType, targetId, data) => {
    updateHoldingsData(
      portfolioId,
      snapshotId,
      parentType,
      parentId,
      targetType,
      targetId,
      data
    ).then(res => {
      // TODO: ICristi we don't support real time refresh yet
      // setHoldingsData(res?.data?.holdings_data)
    })
  }

  const onPageChange = page => {
    setCurrentPage(page)
  }

  const onSearchName = useCallback(
    (value: string) => {
      setFilters(prev => ({ ...prev, name: value }))
    },
    [setFilters]
  )

  const onFilterChange = useCallback(
    (newData: IHoldingDataFiltersData) => {
      const filters = Object.values(newData)
        ?.map(value => (value === 'all' ? null : value))
        ?.filter(value => value)
        ?.join(',')
      setFilters(prev => ({ ...prev, filters }))
    },
    [setFilters]
  )

  TableRenderer.onRenderRow = ([tdStyle, organization, ...rest]: any) => {
    return (
      <tr className={classNames('baseRow', 'localRow')} style={{ ...tdStyle }}>
        <td className='orgCell'>{organization}</td>
        {indicators?.slice(1)?.map((indicatorElem, index) => (
          <td className='contentCell'>{rest?.[index]}</td>
        ))}
      </tr>
    )
  }

  return (
    <>
      <p className='tabDetail'>
        This is the Holding Data Workspace. The table below allows you to
        categorize each holding in your portfolio according to the definitions
        provided in the PCD questionnaire. The questionnaire requires a “count”
        of all the holdings that would map to certain descriptions. For example,
        you may have your own ESG data and some of the holdings were selected
        using that screening process while some were screened out. Those
        holdings would be have to be classified accordingly and counted as a
        percent of the portfolio in order to answer the various questions in the
        SFDR questionnaires. Use the slider buttons or the checkboxes to
        properly categorize your holdings so that we can automate the required
        chart calculations in the document for you. The definitions of the
        various classifications are found <a href='#'>here</a>.
      </p>
      <HoldingDataFilters
        onSearchName={onSearchName}
        onChange={onFilterChange}
      />
      <div
        style={{
          width: '100%',
          overflowX: 'auto',
        }}
      >
        <Table
          name='HoldingDataTable'
          data={HoldingsDataContent} //new Array(10).fill(data)}
          header={header}
          renderer={TableRenderer}
        />
        <footer className='footer'>
          <StyledCenteredDiv>
            {/* @ts-ignore */}
            <Pagination
              max={totalPages}
              selected={currentPage}
              onChange={onPageChange}
            />
          </StyledCenteredDiv>
          <IconMap
            map={{
              True: <HoldingDataIcon status='complete' />,
              False: <HoldingDataIcon status='incomplete' />,
              Incomplete: <HoldingDataIcon status='not_applicable' />,
            }}
          />
        </footer>
      </div>
    </>
  )
}
