export const tableHeaders = [
  { label: 'NAME', sort: true, id: 'name' },
  { label: 'ASSET CLASS', sort: true, id: 'asset_class' },
  { label: 'SYMBOL', sort: true, id: 'symbol' },
  { label: 'WEIGHT', sort: true, id: 'weight' },
  { label: 'E', sort: true, id: 'env' },
  { label: 'S', sort: true, id: 'soc' },
  { label: 'G', sort: true, id: 'gov' },
  { label: 'TOTAL', sort: true, id: 'total' },
]
