import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import styled from 'styled-components'

import Button from '../../../../../../../components/styleguide/Button'
import SpinnerContainer from '../../../../../../../yb_components/styleguide/spinners/SpinnerContainer'

const StyledDiv = styled.div`
  textarea {
    border-radius: 6px;
    resize: none;
    height: 150px;
  }
  textarea:focus {
    border: 1px solid #ced4da;
    resize: none;
    outline: none;
  }

  .note-form {
    margin: 0;
    padding: 15px;
    border-radius: 5px;
    background-color: #f6f8fd;
    display: flex;
    flex-direction: column;
  }
  .spinner-container {
    height: 0px;
    transform: scale(0.4);
    bottom: 23px;
    left: 30%;
    position: relative;
  }
  .alert {
    font-size: 12px;
    float: left;
    max-width: 300px;
  }
`

export const NoteForm = ({ saveNote, payload, setNoteList }) => {
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const submitNote = () => {
    saveNote(value, setLoading, payload, setNoteList, setError, setValue)
  }
  return (
    <StyledDiv>
      <h6>Add new note:</h6>
      <div className='note-form' style={{ margin: '8px 0 12px 0' }}>
        <Form.Control
          id='target_notes'
          as='textarea'
          rows={8}
          value={value}
          onChange={e => setValue(e.target.value)}
          className='shadow-none'
          disabled={loading ? true : false}
        />
        <Button
          onClick={submitNote}
          disabled={value.length === 0 || loading ? true : false}
          label='Save'
          margin='10px 0 0 auto'
          width='90px'
          height='25px'
          fontSize='12px'
          borderRadius='4px'
        />
        <SpinnerContainer className={'spinner-container'} isLoading={loading}>
          <></>
        </SpinnerContainer>
        {loading === false && error && (
          <Alert className='alert' variant='danger'>
            {error}
          </Alert>
        )}
      </div>
    </StyledDiv>
  )
}
