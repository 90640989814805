import propTypes from 'prop-types'
import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'

import { APP_COLOR_PRIMARY } from '../../../../../styles/colors'

const Controls = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;

  .dialog-cancel-btn {
    padding: 8px 30px;
    margin: 0 0.5rem 0 0;
    border: 1px solid transparent;
    border-radius: 6px;
    background-color: transparent;
    font-size: 12px;
    line-height: 1.1;
    font-weight: 900;
    color: #979797;
    &:hover,
    &:focus,
    &:active {
      color: #7e7e7e !important;
      background-color: rgba(0, 0, 0, 0.1) !important;
      border-color: transparent !important;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.05) !important;
    }
  }
  .submit-btn {
    margin: 0;
    padding: 8px 30px;
    display: block;
    border-radius: 6px;
    font-size: 12px;
    line-height: 1.1;
    font-weight: 900;
    color: white;
    background-color: ${APP_COLOR_PRIMARY};
    border-color: ${APP_COLOR_PRIMARY};
    &:hover,
    &:focus,
    &:active {
      color: white;
      background-color: #000074;
    }
  }
`

const DialogControls = ({ onClose }) => {
  return (
    <Controls>
      <Button onClick={onClose} className='dialog-cancel-btn'>
        Cancel
      </Button>
      <Button type='submit' className='submit-btn'>
        Save Changes
      </Button>
    </Controls>
  )
}

DialogControls.propTypes = {
  onClose: propTypes.func.isRequired,
}

export default DialogControls
