import propTypes from 'prop-types'
import React, { useState } from 'react'
import Switch from 'react-switch'
import styled from 'styled-components'

import AddFocusAreaDialog from '../../../components/dialogs/AddFocusAreaDialog'
import AddFocusAreaNoteDialog from '../../../components/dialogs/AddFocusAreaNoteDialog'
import EditFocusAreaValueDialog from '../../../components/dialogs/EditFocusAreaValueDialog'
import AddMoreButton from '../../../components/styleguide/AddMoreButton'
import Card from '../../../components/styleguide/Card'
import CardTitle from '../../../components/styleguide/CardTitle'
import {
  disableFocusArea,
  enableFocusArea,
} from '../../../repositories/risk_assessments_repository'
import {
  APP_COLOR_PRIMARY,
  BG_COLOR_PRIMARY,
  BG_COLOR_SECONDARY,
  BG_COLOR_WHITE,
  BORDER_COLOR_PRIMARY,
  FONT_COLOR_SECONDARY,
} from '../../../styles/colors'

const StyledCard = styled(Card)`
  table {
    thead {
      th {
        &:last-child {
          text-align: center;
        }
      }
    }
    tbody {
      td {
        &:not(:last-child) {
          text-align: left;
        }

        div.status-switch {
          width: 50px;
          display: inline-flex;
          justify-content: flex-end;
          align-items: center;
          > :first-child {
            margin-right: 8px;
          }
        }

        img.ico-edit {
          border: 1px solid transparent;
          cursor: pointer;
          padding: 5px;
          margin: 5px;
          height: 30px;
          width: 30px;
          border-radius: 5px;
          :hover {
            border-color: ${BORDER_COLOR_PRIMARY};
          }
        }
      }
    }
  }
`

const StyledTable = styled.table`
  width: '100%';
  margin: 0;
  background-color: ${BG_COLOR_WHITE};
  font-size: 15.5px;

  border-spacing: 0;
  visibility: 'visible';
  animation: 'fadeIn' 0.5s linear;

  th {
    > span {
      color: ${FONT_COLOR_SECONDARY};
      font-size: 15.5px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      padding: 0.94rem 0;

      &.sortable {
        cursor: pointer;
      }
    }

    &:first-child {
      padding-left: 0.94rem;

      > span {
        justify-content: flex-start;
      }
    }

    &:not(:first-child) {
      text-align: 'left';
    }

    &:nth-child(1) {
      width: 5%;
    }
    &:nth-child(2) {
      width: 35%;
    }
    &:nth-child(3) {
      width: 20%;
    }
    &:nth-child(4) {
      width: 20%;
    }
    &:nth-child(5) {
      width: 20%;
    }

    &:last-child {
      padding-right: 0.94rem;
    }
  }

  tbody {
    visibility: 'visible';
  }

  tbody tr.even {
    background-color: ${BG_COLOR_WHITE};
  }
  tbody tr.odd {
    background-color: ${BG_COLOR_PRIMARY};
  }

  tbody tr.disabled {
    opacity: 0.5;
    transition: opacity 0.25s ease-in-out;
  }

  tbody tr.enabled {
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
  }

  td {
    min-height: 3rem;
    padding: 0.94rem 0;
    padding-left: 0;

    border-bottom: 1px solid ${BG_COLOR_SECONDARY};

    a.current-value,
    a.target-value {
      color: ${APP_COLOR_PRIMARY};

      &:hover {
        color: ${APP_COLOR_PRIMARY};
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &:nth-child(1) {
      padding-top: 1rem;
      vertical-align: top;
    }

    &:nth-child(2) {
      max-width: 250px;
      text-overflow: ellipsis;
      padding-right: 30px;
      padding-top: 0.94rem;
      vertical-align: top;
    }

    &:not(:first-child) {
      text-align: 'center';
    }
    &:first-child {
      padding-left: 0.94rem;
    }
    &:last-child {
      padding-right: 0.94rem;
    }

    .last-updated {
      font-size: 0.84rem;
      color: #aaa;
    }
  }

  tr.notes-row td {
    font-size: 0.8rem;

    .header {
      display: flex;
      justify-content: space-between;

      h4 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.8rem;
        color: #666;

        i {
          color: ${APP_COLOR_PRIMARY};
          margin-right: 5px;
          &.downward {
            transform: rotate(90deg);
            transition: transform 0.1s linear;
          }
        }
      }

      a {
        font-weight: normal;
        color: ${APP_COLOR_PRIMARY};

        &.add-note {
        }
        font-size: 0.84rem;
        font-weight: normal;
        color: ${APP_COLOR_PRIMARY};

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .notes {
    border-top: 1px solid #ddd;

    &.hidden {
      display: none;
      border-top: none;
    }

    .note {
      min-height: 2rem;
      margin: 5px 0 10px 10px;
      padding: 10px;
      font-size: 0.9rem;
      border-radius: 10px;
      background-color: #fff;

      .byline {
        display: block;
        text-align: right;
        font-style: italic;
        font-size: 0.8rem;
      }
    }
  }

  .edit-button {
    margin: 0 5px;
    cursor: pointer;
    color: ${APP_COLOR_PRIMARY};
  }

  tfoot td {
    font-weight: bold;
  }
`

const RiskAssessmentCard = ({
  title,
  riskAssessment,
  category,
  options,
  units,
  onCreateDialogSuccess,
  fundId,
  holdingId,
}) => {
  const [data, setData] = useState(riskAssessment)
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [showAddNoteDialog, setShowAddNoteDialog] = useState(false)
  const [editingRow, setEditingRow] = useState(0)
  const [editingType, setEditingType] = useState(null)
  const [addingNoteForFocusAreaId, setAddingNoteForFocusAreaId] = useState(null)

  const onSwitchChange = (enabled, focusAreaId) => {
    enabled
      ? enableFocusArea(holdingId, focusAreaId)
      : disableFocusArea(holdingId, focusAreaId)

    setData(
      data.map(d => {
        if (d.id === focusAreaId) {
          return {
            ...d,
            status: enabled,
          }
        } else {
          return d
        }
      })
    )
  }

  const onAddMoreClick = () => {
    setShowCreateDialog(true)
  }

  const onEditClick = (row, editingType) => {
    if (!data[row].status) return false

    setEditingType(editingType)
    setShowEditDialog(true)
    setEditingRow(row)
  }

  const onAddNoteClick = (fundId, holdingId, focusAreaId, row) => {
    if (!data[row].status) return false

    setShowAddNoteDialog(true)
    setAddingNoteForFocusAreaId(focusAreaId)
  }

  const onUpdateSuccess = res => {
    setData(
      data.map(d => {
        if (d.id === res.id) {
          return res
        } else {
          return d
        }
      })
    )
  }

  const onCreateSuccess = res => {
    setData([...data, res])

    onCreateDialogSuccess()
  }

  const onToggleNotesSectionClick = e => {
    let notesSection = e.target
      .closest('.notes-row')
      .getElementsByClassName('notes')[0]
    let notesToggleIcon = e.target
      .closest('.notes-row')
      .getElementsByClassName('toggle-icon')[0]

    notesSection.classList.contains('hidden')
      ? notesSection.classList.remove('hidden')
      : notesSection.classList.add('hidden')

    notesToggleIcon.classList.contains('downward')
      ? notesToggleIcon.classList.remove('downward')
      : notesToggleIcon.classList.add('downward')

    return true
  }

  const displayValueWithUnit = (value, unit) => {
    let out = ''

    if (value) {
      out += value.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })

      if (unit != null) {
        out += ` ${unit}`
      }
    } else {
      out = '—'
    }

    return out
  }

  return (
    <StyledCard>
      <CardTitle title={title} />

      <StyledTable>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>
              Estimated Last
              <br />
              Reported Value
            </th>
            <th>Target Value</th>
            <th>Current Value</th>
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => {
            let rowClassNames = [
              i % 2 ? 'even' : 'odd',
              d.status ? 'enabled' : 'disabled',
            ]

            return (
              <React.Fragment key={'focusArea-' + i}>
                <tr className={rowClassNames.join(' ')}>
                  <td rowSpan='2'>
                    <div className='status-switch'>
                      <Switch
                        onChange={onSwitchChange.bind(null, !d.status, d.id)}
                        checked={!!d.status}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={18}
                        width={36}
                      />
                    </div>
                  </td>
                  <td rowSpan='2'>
                    <strong>{d.name}</strong>
                    <br />
                    <p>{d.description}</p>
                  </td>

                  <td className='reported'>
                    {d.previous_value
                      ? displayValueWithUnit(d.previous_value, d.unit)
                      : '—'}
                  </td>

                  <td className='target'>
                    {d.target_value ? (
                      <>
                        <a
                          className='target-value'
                          onClick={onEditClick.bind(null, i, 'target_value')}
                          title='Edit Target Value'
                          disabled={!d.status ? 'disabled' : ''}
                        >
                          <strong>
                            {displayValueWithUnit(d.target_value, d.unit)}
                          </strong>
                        </a>
                        <br />
                        by {d.target_date}
                        {d.target_user ? (
                          <>
                            <br />
                            <span className='last-updated'>
                              (Last Updated by {d.target_user})
                            </span>
                          </>
                        ) : null}
                      </>
                    ) : (
                      <a
                        className='current-value'
                        onClick={onEditClick.bind(null, i, 'target_value')}
                        title='Add Target Value'
                      >
                        Add Target
                      </a>
                    )}
                  </td>

                  <td className='current'>
                    {d.current_value ? (
                      <>
                        <a
                          className='current-value'
                          onClick={onEditClick.bind(null, i, 'current_value')}
                          title='Edit Current Value'
                        >
                          <strong>
                            {displayValueWithUnit(d.current_value, d.unit)}
                          </strong>
                        </a>
                        <br />
                        as of {d.current_as_of}
                        {d.current_user ? (
                          <>
                            <br />
                            <span className='last-updated'>
                              (Last Updated by {d.current_user})
                            </span>
                          </>
                        ) : null}
                      </>
                    ) : (
                      <a
                        className='current-value'
                        onClick={onEditClick.bind(null, i, 'current_value')}
                        title='Add Current Value'
                      >
                        Add Current Value
                      </a>
                    )}
                  </td>
                </tr>

                <tr className={`${rowClassNames.join(' ')} notes-row`}>
                  <td colSpan='3'>
                    <div className='header'>
                      <h4 onClick={onToggleNotesSectionClick}>
                        <i className='fa fa-caret-right toggle-icon'></i>
                        Notes ({d.notes.length})
                      </h4>
                      <a
                        className='add-note'
                        onClick={onAddNoteClick.bind(
                          null,
                          fundId,
                          holdingId,
                          d.id,
                          i
                        )}
                      >
                        Add New Note
                      </a>
                    </div>

                    <div className='notes hidden'>
                      {d.notes.length > 0 &&
                        d.notes.map((note, j) => {
                          return (
                            <div className='note' key={'note-' + j}>
                              <p>{note.note}</p>
                              <span className='byline'>
                                — {note.username} ({note.created_at})
                              </span>
                            </div>
                          )
                        })}
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            )
          })}
        </tbody>
      </StyledTable>

      <AddMoreButton onClick={onAddMoreClick} />

      <AddFocusAreaNoteDialog
        holdingId={holdingId}
        focusAreaId={addingNoteForFocusAreaId}
        onClose={() => setShowAddNoteDialog(false)}
        onSuccess={onUpdateSuccess}
        open={showAddNoteDialog}
      />
      <AddFocusAreaDialog
        holdingId={holdingId}
        category={category}
        options={options}
        units={units}
        onClose={() => setShowCreateDialog(false)}
        onSuccess={onCreateSuccess}
        open={showCreateDialog}
      />
      <EditFocusAreaValueDialog
        holdingId={holdingId}
        data={data[editingRow]}
        onClose={() => setShowEditDialog(false)}
        onSuccess={onUpdateSuccess}
        editingType={editingType}
        open={showEditDialog}
      />
    </StyledCard>
  )
}

RiskAssessmentCard.defaultProps = {
  data: [],
  options: [],
}

RiskAssessmentCard.propTypes = {
  title: propTypes.string.isRequired,
  riskAssessment: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string.isRequired,
      unit: propTypes.string.isRequired,
      current_value: propTypes.number.isRequired,
      date: propTypes.string,
      target: propTypes.string,
      target_value: propTypes.number,
      status: propTypes.number.isRequired,
    })
  ).isRequired,
  category: propTypes.string.isRequired,
  options: propTypes.array.isRequired,
  units: propTypes.array.isRequired,
  onCreateDialogSuccess: propTypes.func.isRequired,
}

export default RiskAssessmentCard
