import React from 'react'
import styled, { css } from 'styled-components'

const Div = styled.div.attrs(({ left, top }) => ({
  style: {
    left: left,
    top: top,
  },
}))`
  ${({ inheritVisibility }) =>
    !inheritVisibility &&
    css`
      visibility: ${props => (props.show ? 'visible' : 'hidden')};
    `}
  ${props =>
    !props.noTransform && `transform: translate(-50%, calc(-100% - 12px));`}
  ${props => (props.width ? `width: ${props.width};` : '')}
  ${props => (props.height ? `height: ${props.height};` : '')}
  padding: ${props => props.padding + 'px'};
  position: ${props => props.position || 'absolute'};
  background-color: white;
  box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  ${props => (props.zIndex ? `z-index: ${props.zIndex};` : '')}
  ${props =>
    props.hideArrow !== true &&
    `
    &:after {
      ${props.setArrowStyle()}
      position: absolute;
      left: calc(50% - 20px);
      width: 40px;
      text-align: center;
      font-size: 24px;
      color: white;
      transform: scaleY(0.5);
    }
   `}
`

const Tooltip = ({
  x,
  y,
  show,
  padding,
  width,
  height,
  render,
  position,
  children,
  zIndex,
  inheritVisibility,
  noTransform,
  'data-cy': dataCy,
  hideArrow,
  arrowPosition = 'BOTTOM',
}) => {
  const left = x + 'px'
  const top = y + 'px'

  const arrowStyles = {
    TOP: {
      position: 'top: -19px',
      content: '▲',
      shadow: 'rgba(0, 0, 0, 0.2) 0 -2px 2px;',
    },
    RIGHT: {
      position: 'right: -19px',
      content: '▶',
      shadow: '2px 0px 2px rgba(0, 0, 0, 0.2);',
    },
    LEFT: {
      position: ' left: -19px',
      content: '◀',
      shadow: '-2px 0px 2px rgba(0, 0, 0, 0.2);',
    },
    BOTTOM: {
      position: 'bottom: -19px',
      content: '▼',
      shadow: '0px 2px 2px rgba(0, 0, 0, 0.2);',
    },
  }

  const setArrowStyle = () => {
    const style = arrowStyles[arrowPosition] || arrowStyles.BOTTOM
    return `content: '${style.content}'; ${style.position}; text-shadow: ${style.shadow};`
  }

  return (
    <Div
      style={{ visibility: '' }}
      data-cy={dataCy}
      show={show}
      left={left}
      top={top}
      padding={padding}
      width={width}
      height={height}
      position={position}
      zIndex={zIndex}
      inheritVisibility={inheritVisibility}
      noTransform={noTransform}
      hideArrow={hideArrow}
      setArrowStyle={setArrowStyle}
    >
      {render ? render() : children}
    </Div>
  )
}

Tooltip.defaultProps = {
  x: 0,
  y: 0,
  padding: 0,
}

export default Tooltip
