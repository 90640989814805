import React from 'react'
import styled from 'styled-components'

import { esg_scale } from '../../helpers/esg_scale'
import { FONT_COLOR_SECONDARY } from '../../styles/colors'

const ScaleCell = styled.div`
  width: ${props => 100 / props.labelsCount}%;
  display: inline-block;
  vertical-align: top;

  .color-box {
    height: 15px;
    background-color: ${props => props.color};
  }

  span {
    display: inline-block;
    width: 100%;
    font-size: 12px;
    margin-top: 10px;
    color: ${FONT_COLOR_SECONDARY};
    text-align: center;
    vertical-align: top;
    padding: 0 2px;
  }
`

const StyledContainer = styled.div`
  margin-top: 15px;
`

const ESGScale = ({ labels = esg_scale }) => {
  return (
    <StyledContainer>
      {labels.map(s => (
        <ScaleCell
          key={`${s.color}-${s.index}`}
          color={s.color}
          labelsCount={labels.length}
        >
          <div className='color-box' />
          <span>
            <strong>{`${s.min} to ${Math.round(s.max)}`}</strong>
            <br />
            {`${s.label}`}
          </span>
        </ScaleCell>
      ))}
    </StyledContainer>
  )
}

export default ESGScale
