const instrumentsLimit = 10
const sectorsLimit = 51
const mostRecentYear = 2019

const factorOptions = {
  'greenhouse-gas-emissions': {
    visibleFields: [
      'co2_scope_total',
      'co2_scope_12',
      'co2_scope_3',
      'renewable_energy_use_pct',
    ],
    sortDirection: 'asc',
    sortColumn: 'co2_scope_total',
  },
  'energy-management': {
    visibleFields: ['energy_use_total'],
    sortDirection: 'asc',
    sortColumn: 'energy_use_total',
  },
  'water-&-wastewater-management': {
    visibleFields: ['freshwater_withdrawal'],
    sortDirection: 'asc',
    sortColumn: 'freshwater_withdrawal',
  },
  'waste-&-hazardous-materials-management': {
    visibleFields: ['waste_total'],
    sortDirection: 'asc',
    sortColumn: 'waste_total',
  },
}

const factorOptionsPretty = Object.keys(factorOptions).map(opt => {
  return {
    value: opt,
    label: opt
      .replace(/-/g, ' ')
      .toLowerCase()
      .replace(/(?:^|\s|-)\S/g, x => x.toUpperCase()),
  }
})

const defaultFactor = factorOptions['greenhouse-gas-emissions']

const metricsComparator = (sortColumn, sortDirection) => {
  return (metrics1, metrics2) => {
    const metric1 = metrics1.find(metric => {
      return metric.label === sortColumn
    })

    const metric2 = metrics2.find(metric => {
      return metric.label === sortColumn
    })

    const metrics1Value = metric1.value
    const metrics2Value = metric2.value

    if (metrics1Value === null) {
      return 1
    } else if (metrics2Value === null) {
      return -1
    }

    if (sortDirection === 'desc') {
      return parseFloat(metrics2Value) - parseFloat(metrics1Value)
    } else {
      return parseFloat(metrics1Value) - parseFloat(metrics2Value)
    }
  }
}

export {
  defaultFactor,
  factorOptions,
  factorOptionsPretty,
  instrumentsLimit,
  metricsComparator,
  mostRecentYear,
  sectorsLimit,
}

export const SECTOR_SHOW_BREADCRUMBS = [
  { name: 'Home', link: '/app' },
  { name: 'Sectors', link: '/app/sectors' },
]

/* Subsidies */
export const SUBSIDIES_STATS_QUERY =
  '?over_time_format=array&total_state_format=properties'
export const SUBSIDIES_OVER_TIME_CARD_TITLE = 'Subsidies & Loans Over Time'
export const SUBSIDIES_OVER_TIME_CARD_DESCRIPTION =
  'Subsidies & Loans Over Time examines the categorization of subsidies and loans into three government levels (federal, state, local) \
  across the past 10 years of data. The larger numbers to the right indicate the total amount of subsidies and loans across all 10 years.'

/* Violations */
export const VIOLATIONS_STATS_QUERY =
  '?over_time_format=array&total_state_format=properties'
export const VIOLATIONS_OVER_TIME_CARD_TITLE = 'Violations Over Time'
export const VIOLATIONS_OVER_TIME_CARD_DESCRIPTION =
  'Violations Over Time examines the categorization of violations and fines into categorical buckets across the past 10 years of data.'

export const OVER_TIME_TABLE_ROW_HEADERS = [
  {
    /* Total */
    label: 'Total',
    key: 'total',
  },
]
