import axios from 'axios'
import qs from 'query-string'

export const getInstrument = id => {
  // return Promise.resolve({ data: { instrument: INSTRUMENT_SAMPLE }});
  return axios.get(`/app/api/instruments/${id}`)
}

export const getInstrumentById = id => {
  axios.get(`/app/api/instruments/${id}`).then(res => res.data)
}

export const getBadges = id => {
  return axios.get(`/app/api/instruments/${id}/badges`)
}

export const getInstrumentHoldings = (id, params) => {
  return axios.get(
    `/app/api/instruments/${id}/holdings?page=${params.page}${params.query}`
  )
}

export const getESGYearlyMetrics = id => {
  return axios.get(`/app/api/instruments/${id}/esg_yearly_metrics`)
}

export const getInstrumentHoldingsAssetAllocations = id => {
  return axios.get(`/app/api/instruments/${id}/allocation_grid.json`)
}

export const getInstrumentQuery = (input, extraParams = {}) => {
  const queryParams = Object.entries(extraParams)
    .map(([key, value]) => `&${key}=${JSON.stringify(value)}`)
    .join('')
  return axios.get(`/app/api/instruments/query?input=${input}${queryParams}`)
}
export const getInstrumentCo2Projections = id => {
  return axios.get(`/app/api/instruments/${id}/co2_projections`)
}

export const getEntityPeers = entityId => {
  return axios.get(`/app/api/entities/${entityId}/peers`)
}

export const updateEntityPeers = (entityId, peerIds) => {
  return axios.put(`/app/api/entities/${entityId}/peers/update_entity_peers`, {
    peer_ids: peerIds,
  })
}

export const getCarbonSummaryReportData = async instrumentId => {
  try {
    const res = await axios.get(
      `/app/api/instruments/${instrumentId}/carbon_report_summary`
    )
    return res.data
  } catch (e) {
    console.error(e)
  }
}

const random = (n = 17, i = 1) => parseInt(i + Math.random() * n)
// eslint-disable-next-line no-unused-vars
const INSTRUMENT_SAMPLE = {
  name: 'Apple',
  isin: 'ABC123456',
  scores: {
    governance: random(100),
    governance_delta: random(100),
    environmental: random(100),
    environmental_delta: random(100),
    social: random(100),
    social_delta: random(100),
    total: random(100),
    total_delta: random(100),
  },
  logo_url: 'http://lorempixel.com/100/100/abstract/',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  sdgs: {
    positive: [1, 2, 3],
    negative: [4, 5, 6],
  },
  impacts: {
    litigation: {
      value: random(2000),
      description: "More plastic bottles than it's peers",
      total: random(100000),
      total_perc: random(100),
      median: random(100000),
      median_perc: random(100),
    },
    trees_killed: {
      value: random(2000),
      description: 'Fewer trees killed',
      total: random(100000),
      total_perc: random(100),
      median: random(100000),
      median_perc: random(100),
    },
    pay_gap: {
      value: random(2000),
      description: 'Reduced gender pay gap',
      total: random(100000),
      total_perc: random(100),
      median: random(100000),
      median_perc: random(100),
    },
    waste: {
      value: random(100),
      description: 'More independence',
      total: random(100000),
      total_perc: random(100),
      median: random(100000),
      median_perc: random(100),
    },
  },
  thematics: {
    positive: {
      title: 'Fair Economics',
      bulletpoints: [
        { text: 'Provides full health care benefits to part-time workers' },
        { text: 'Have a high baseline wage for baristas' },
        { text: 'Adopts fair trade policies' },
        {
          text: 'Need to disclosure more about farmer compensation',
          type: 'warn',
        },
      ],
      metrics: [
        {
          importance: random(4),
          name: 'Carbon Eficiency',
          type: 'Direct',
          measure: random(10000),
          measure_perc: random(100),
          median: random(100),
        },
        {
          importance: random(4),
          name: 'Carbon Eficiency',
          type: 'Indirect',
          measure: random(10000),
          measure_perc: random(100),
          median: random(100),
          light: true,
        },
      ],
    },
    negative: {
      title: 'Fair Economics',
      bulletpoints: [
        {
          text: 'Do not Provide full health care benefits to part-time workers',
          type: 'danger',
        },
        {
          text: 'Do not Have a high baseline wage for baristas',
          type: 'danger',
        },
        { text: 'Do not Adopts fair trade policies', type: 'danger' },
        {
          text: 'Need to disclosure more about farmer compensation',
          type: 'warn',
        },
      ],
      metrics: [
        {
          importance: random(4),
          name: 'Carbon Eficiency',
          type: 'Direct',
          measure: random(10000),
          measure_perc: random(100),
          median: random(100),
        },
        {
          importance: random(4),
          name: 'Carbon Eficiency',
          type: 'Indirect',
          measure: random(10000),
          measure_perc: random(100),
          median: random(100),
          light: true,
        },
      ],
    },
  },
}

export const getDropdownOptions = ({ id, query }) => {
  return axios.get(
    `/app/api/instruments/${id}/utilities/dropdown_options?${qs.stringify(
      query
    )}`
  )
}
