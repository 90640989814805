import { staticDefaultWeights } from './CustomESGScoring.constants'

export const reducer = (state, action) => {
  const defaultState = { ...state, lastActionCalled: action.type }

  switch (action.type) {
    case 'settings-version-loaded':
      return { ...defaultState, settings_version: action.payload }
    case 'discard-preset':
      return {
        ...defaultState,
        esg_pillars: staticDefaultWeights,
        sector_esg_pillars: {},
        score_display_settings: null,
      }
    case 'sectors-loaded':
      return { ...defaultState, sectors: action.payload }
    case 'display-labels-data':
      return { ...defaultState, score_display_settings: action.payload }
    case 'ranking-setting-changed':
      return {
        ...defaultState,
        score_ranking_setting: action.payload,
        preview_disabled: false,
      }
    case 'initial-data-loaded':
      return {
        ...defaultState,
        esg_metrics_map: { ...action.payload.esg_metrics_map },
        esg_pillars: {
          ...state.esg_pillars,
          ...action.payload.default_pillar_weights?.overall,
          ...(action.payload.overall_weights.e
            ? action.payload.overall_weights
            : {}),
        },
        sector_esg_pillars: {
          ...state?.sector_esg_pillars,
          ...action.payload.sector_weights,
        },
        sector_intermediate_scores: {
          ...action.payload.companies_by_sector,
        },
        portfolio_scores: action.payload.latest_portfolios,
        target_information: {
          ...state.target_information,
          ...action.payload.target_information,
        },
        score_display_settings: action.payload.score_display_settings,
        score_ranking_setting: action.payload.score_ranking_setting,
        name: action.payload.name,
        description: action.payload.description,
        id: action.payload.id,
        default_pillar_weights: action.payload.default_pillar_weights,
      }
    case 'esg-pillar-changed-basic':
      return {
        ...defaultState,
        esg_pillars: {
          ...state.esg_pillars,
          ...action.payload,
        },
        default_pillar_weights: null,
        preview_disabled: false,
      }
    case 'esg-pillar-changed-advanced':
      return {
        ...defaultState,
        esg_pillars: {
          ...state.esg_pillars,
          advanced: {
            ...state.esg_pillars.advanced,
            ...action.payload.advanced,
          },
        },
        preview_disabled: false,
      }
    case 'sector-pillar-changed-basic':
      return {
        ...defaultState,
        sector_esg_pillars: {
          ...state?.sector_esg_pillars,
          [action.payload.sectorId]: {
            ...state.sector_esg_pillars?.[action.payload.sectorId],
            ...action.payload,
            customized: true,
          },
        },
        preview_disabled: false,
      }
    case 'sector-pillar-changed-advanced':
      return {
        ...defaultState,
        sector_esg_pillars: {
          ...state?.sector_esg_pillars,
          [action.payload.sectorId]: {
            ...state.esg_pillars,
            ...state.sector_esg_pillars?.[action.payload.sectorId],
            customized: true,
            advanced: { ...action.payload.advanced },
          },
        },
        preview_disabled: false,
      }
    case 'intermediate-scores-loaded':
      return {
        ...defaultState,
        portfolio_scores: action.payload?.portfolio_scores,
        sector_intermediate_scores: action.payload?.companies_by_sector,
        settings_version: action.payload?.settings_version,
      }
    case 'pillars-view-change':
      return {
        ...defaultState,
        view: action.payload,
      }
  }
}
