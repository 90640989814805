import React from 'react';
import YBOverviewCard from '../../../../../../yb_modules/portfolio/modules/comparisons/components/cards/yb_overview_card/YBOverviewCard';
import ESGScoresCard from '../../../../../../yb_modules/portfolio/modules/comparisons/components/cards/esg_scores_card/ESGScoresCard';
import SDGAlignmentCard from '../../../../../../yb_modules/portfolio/modules/comparisons/components/cards/sdg_alignment_card/SDGAlignmentCard';
import YearlyMetricCard from '../../../../../../yb_modules/portfolio/modules/comparisons/components/cards/yearly_metric_card/YearlyMetricCard';
import PDFContent from '../../../../../layout/pdf_content/PDFContent';
import PortfolioDisclosure from '../../../../../components/sections/PortfolioDisclosure'

const ComparisonsPDF = ({ portfolios }) => {
  return (
    <PDFContent landscape title="Portfolio Comparisons">
      <h2>YvesBlue Overview</h2>
      <YBOverviewCard portfolios={portfolios} isPdf={ true } />
      <div style={{pageBreakBefore: "always"}}></div>

      <h2>ESG Scores</h2>
      <ESGScoresCard portfolios={portfolios} isPdf={ true } />
      <div style={{pageBreakBefore: "always"}}></div>

      <h2>SDG Alignment</h2>
      <SDGAlignmentCard portfolios={portfolios} isPdf={ true } />
      <div style={{pageBreakBefore: "always"}}></div>

      <h2>Environmental</h2>
      <YearlyMetricCard portfolios={portfolios} metricType="e" isPdf={ true } />
      <div style={{pageBreakBefore: "always"}}></div>

      <h2>Social</h2>
      <YearlyMetricCard portfolios={portfolios} metricType="s" isPdf={ true } />
      <div style={{pageBreakBefore: "always"}}></div>

      <h2>Governance</h2>
      <YearlyMetricCard portfolios={portfolios} metricType="g" isPdf={ true } />
      <div style={{pageBreakBefore: "always"}}></div>

      <PortfolioDisclosure/>
    </PDFContent>
  )
}

export default ComparisonsPDF
