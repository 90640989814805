import styled from 'styled-components'

export const SignInContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .background-container {
    position: absolute;
    z-index: -1;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 50%;
    overflow: hidden;
    & > img {
      width: 100%;
    }
  }
  .body-container {
    flex: 2;
    display: flex;
    width: 100%;
    max-height: 88vh;
    .left-content {
      overflow: hidden;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 40px;
      max-height: 900px;
      form {
        min-height: 450px;
        transform: translate(
          ${props => (props.activeMainSection === 'sign-in' ? '0%' : '80%')},
          40%
        );
        transition: 0.5s;
        transition-timing-function: ease-in-out;
        padding: 0px 25%;
        &:first-child {
          overflow: hidden;
          margin-bottom: -10px;
        }
        .basic-inputs {
          transition: 0.5s;
          transition-timing-function: ease-in-out;
          transform: translate(
            ${props =>
              props.activeSignInSection === 'default' ? '0%' : '150%'},
            0px
          );
        }
        .code-inputs {
          transition: 0.5s;
          transition-timing-function: ease-in-out;
          transform: translate(
            ${props =>
              props.activeSignInSection === '2fa-code' ? '0%' : '-150%'},
            -150px
          );
          .code-input {
            &::-webkit-inner-spin-button,
            ::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            -moz-appearance: textfield;
          }
        }
        .log-in-button-container {
          transition: 0.5s;
          transition-timing-function: ease-in-out;
          transform: translate(0px, -120px);
          button {
            margin-top: 10px;
          }
        }
        .response-container {
          min-height: 74px;
          margin-top: 10px;
        }
        h1 {
          margin-bottom: 40px;
        }
        input {
          padding: 0px;
          border-radius: 0px;
          border: 0;
          outline: 0;
          background: transparent;
          border-bottom: 1px solid #e5e5e5;
          transition: 0.5s;
          transition-timing-function: ease-in-out;
        }
        input:focus {
          border-radius: 0px;
          border: 0;
          outline: 0;
          background: transparent;
          border-bottom: 1px solid #e5e5e5;
          border-color: #00d66e;
        }
        [placeholder]:focus::-webkit-input-placeholder {
          transition: text-indent 0.4s 0.4s ease;
          text-indent: -100%;
          opacity: 1;
        }
        [placeholder]::-webkit-input-placeholder {
          transition: text-indent 0.4s 0.4s ease;
          text-indent: 0%;
          opacity: 1;
        }
        label {
          font-size: 13px;
          color: #c2c2c2;
        }
        button {
          border-radius: 40px;
          transition: 0.4s ease;
          margin-top: 20px;
          margin-bottom: 10px;
          height: 49px;
        }
        button:hover {
          opacity: 0.5;
        }
        .password-reset-spinner {
          position: relative;
        }
      }
    }
    .right-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 58px;
      color: #ffffff;
      p {
        padding-top: 30px;
        max-width: 482px;
      }
      span {
        font-weight: 200;
      }
    }
  }
  .footer-container {
    span {
      padding: 0px 10px;
    }
    position: absolute;
    bottom: 5%;
    width: 50%;
    display: flex;
    justify-content: center;
  }
  a,
  .anchor-style {
    transition: 0.4s ease;
    all: unset;
    cursor: pointer;
    opacity: 1;
  }
  a,
  .anchor-style {
    &:hover {
      transition: 0.4s ease;
      opacity: 0.6;
    }
  }
  .small-grey-text {
    font-size: 14px;
    color: #979ca6;
  }
  @media (max-width: 1280px) {
    .background-container {
      & > img {
        height: 100%;
        width: auto;
      }
    }
  }
  @media (min-width: 1605px) {
    .forgot-your-password-title {
      min-height: 96px;
      padding-top: 50px;
    }
  }
`
