import axios from 'axios'
import PropTypes from 'prop-types'
import React, { forwardRef, useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Loading from '../../../components/styleguide/Loading'
import { timestamp } from '../../../helpers/shared'
import { downloadTemplate } from '../../../pdf'
import { PDF } from '../../../pdf/pdf'
import IconLink from '../../../yb_components/styleguide/links/IconLink'
import Pagination from '../../../yb_components/styleguide/pagination/Pagination'
import useCurrentTabRef from '../../../yb_hooks/target/useCurrentTabRef'
import { useQuery } from '../../../yb_hooks/useQuery'
import { AppContext } from '../../../yb_stores/app_store/AppStore'
import { CustomScoringModal } from './components/custom_scoring_modal/CustomScoringModal'
import { TcfdHoldingsCard } from './components/holdings_card/TcfdHoldingsCard'
import { TcfdTable } from './components/holdings_table/TcfdTable'
import { TcfdLegend } from './components/legend/TcfdLegend'
import { TcfdPortfolioScoreCard } from './components/tcfd_portfolio_score/TcfdPortfolioCard'
import { loadTcfdByPortfolioSnapshot } from './loaders/tcfd_loaders'

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px 15px 5px;
`

const StyledTitle = styled.div`
  font-size: 21px;
  font-weight: bold;
`

export const TcfdHoldingsTab = forwardRef(
  ({ portfolioId, snapshotId }, ref) => {
    const [customScores, setCustomScores] = useState(null)
    const [holdings, setHoldings] = useState([])
    const [pagination, setPagination] = useState({ current: 0, total: 0 })
    const [portfolioScores, setPortfolioScores] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [sorting, setSorting] = useState({})

    const history = useHistory()
    const location = useLocation()
    let query = useQuery()
    const [pdfLoading, setPdfLoading] = useState(false)
    const [appState] = useContext(AppContext)
    useCurrentTabRef(ref)

    const updateHoldings = () => {
      setModalShow(false)

      if (sorting.sort_field === 'weight' && sorting.sort_dir === 'desc') {
        history.go(0)
      } else {
        history.replace({
          pathname: window.location.pathname,
          search: `?sort_field=weight&sort_dir=desc&page=1`,
        })
      }
    }

    const initFromQueryParams = () => {
      setSorting({
        sort_dir: query.get('sort_dir'),
        sort_field: query.get('sort_field'),
      })

      loadTcfdByPortfolioSnapshot(
        query.get('page'),
        portfolioId,
        snapshotId,
        query.get('sort_dir'),
        query.get('sort_field'),
        setCustomScores,
        setHoldings,
        setIsLoading,
        setPagination,
        setPortfolioScores
      )
    }
    // update on location change
    useEffect(() => {
      initFromQueryParams()
    }, [location])

    const [modalShow, setModalShow] = useState(false)
    const openCustomScoringModal = () => {
      setModalShow(true)
    }

    const handleSortingChange = (sort_field, sort_dir) => {
      let sortField
      let sortDir
      if (sort_dir === 'none' && sort_field !== 'weight') {
        sortField = 'weight'
        sortDir = 'desc'
      } else if (sort_dir === 'none' && sort_field === 'weight') {
        sortField = 'weight'
        sortDir = 'asc'
      } else {
        sortField = sort_field
        sortDir = sort_dir
      }

      history.push({
        pathname: window.location.pathname,
        search: `?sort_field=${sortField}&sort_dir=${sortDir}&page=1`,
      })
    }

    const handlePaginationChange = page => {
      history.push({
        pathname: window.location.pathname,
        search: `?sort_field=${sorting.sort_field}&sort_dir=${sorting.sort_dir}&page=${page}`,
      })
    }

    const onExportPDF = () => {
      setPdfLoading(true)
      downloadTemplate(
        <PDF.TcfdTargetReport
          targetName={appState?.target?.name}
          benchmarkName={appState?.benchmark?.name}
          scores={portfolioScores}
          holdings={holdings}
        />,
        1,
        `yb-${appState?.target?.name
          ?.split(' ')
          ?.join('-')}-tcfd-holdings-${timestamp}`,
        snapshotId,
        'TCFD',
        `Portfolio::Snapshot-${snapshotId}`,
        () => {
          setPdfLoading(false)
        }
      )
    }

    const onExportCSV = () => {
      axios
        .get(
          `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/tcfd/csv`,
          { responseType: 'blob' }
        )
        .then(response => {
          const href = URL.createObjectURL(response.data)

          // create "a" HTML element with href to file & click
          const link = document.createElement('a')
          link.href = href
          const filename =
            response.headers['content-disposition'].match(
              /filename="(.+.csv)"/
            )[1]

          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link)
          URL.revokeObjectURL(href)
        })
    }

    return (
      <div style={{ marginTop: '20px' }}>
        <StyledTitleContainer>
          <StyledTitle>TCFD Scenario Report</StyledTitle>
          <div>
            <IconLink
              disable={isLoading}
              onClick={onExportPDF}
              src={'/ico-export.svg'}
            />
            <IconLink
              disable={isLoading}
              onClick={onExportCSV}
              src={'/ico-csv.svg'}
            />
          </div>
        </StyledTitleContainer>
        <header style={{ marginBottom: '20px' }}>
          <TcfdPortfolioScoreCard scores={portfolioScores} portfolio={true} />
        </header>
        <main style={{ marginBottom: '20px' }}>
          <TcfdHoldingsCard>
            <header>
              <h3>Transition & Physical Risk Scoring Model</h3>
              <div style={{ display: 'flex', gap: '1.25rem' }}>
                <Button
                  style={{ padding: 0, fontStyle: 'italic' }}
                  variant='link'
                  onClick={openCustomScoringModal}
                >
                  Configure Scoring Model
                </Button>
              </div>
            </header>
            <p
              style={{ margin: '0 0 20px', color: '#979ca6', lineHeight: 1.4 }}
            >
              All holdings in the portfolio have a score calculated for each
              pillar. The Market Risk, Reputation, and Physical Risk scores are
              computed from multiple data inputs as subscores, aggregating to
              their overall score. All pillar scores are customizable based on
              investment and risk priorities. Each holding’s pillar scores and
              subscores are compared against companies in the same sector as the
              holding.
            </p>
            <main>
              <TcfdTable
                holdings={holdings}
                isLoading={isLoading}
                portfolioId={portfolioId}
                snapshotId={snapshotId}
                sorting={{
                  sort_dir: sorting.sort_dir,
                  sort_field: sorting.sort_field,
                }}
                updateSort={handleSortingChange}
              />
            </main>
            <div
              style={{
                margin: '20px 0 0 0',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '140px',
              }}
            >
              <Pagination
                onChange={handlePaginationChange}
                max={pagination?.total}
                selected={pagination?.current}
                style={{ marginBottom: '12px' }}
              />
              <TcfdLegend />
            </div>
          </TcfdHoldingsCard>
        </main>
        {customScores && (
          <CustomScoringModal
            customScores={customScores}
            show={modalShow}
            onHide={() => setModalShow(false)}
            portfolioId={portfolioId}
            snapshotId={snapshotId}
            updateHoldings={updateHoldings}
          />
        )}
        <Loading show={pdfLoading} message={'Generating report...'} />
      </div>
    )
  }
)

TcfdHoldingsTab.displayName = 'TcfdHoldingsTab'

TcfdHoldingsTab.propTypes = {
  portfolioId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  snapshotId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
}
