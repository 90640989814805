import useFeedbackMessage from '@env/hooks/forms/useFeedbackMessage'
import useInputValidations from '@env/hooks/forms/useInputValidations'
import useRequiredValidation from '@env/hooks/forms/useRequiredValidation'
import useShouldDisplayInputFeedback from '@env/hooks/forms/useShouldDisplayInputFeedback'
import withFormControl from '@ui/components/compositions/withFormControl'
import withFormRow from '@ui/components/compositions/withFormRow/withFormRow'
import { InputFeedback } from '@ui/components/presentation/copy/InputFeedback'
import getTestingProps from '@utils/test/getTestingProps'
import React, { ChangeEvent, useState } from 'react'

import { Checkbox } from '../Checkbox'
// import theme from './CheckboxGroup.module.scss'
import './CheckboxGroup.module.scss'

export interface IProps extends YB.IFormControl<string[]> {
  name: string
  options: YB.IOption[]
  required?: boolean
  disabled?: boolean
}

const CheckboxGroup: React.FC<IProps> = ({
  options,
  name,
  required = true,
  validations = [],
  model = useState<string[]>([]),
  feedbackModel = useState<string>(''),
  formFeedbackModel = useState<string>(''),
  disabled = false,
}) => {
  const [checklist, setChecklist] = model
  const [isPristine, setIsPristine] = useState<boolean>(true)
  const feedbackMessage = useFeedbackMessage(feedbackModel, formFeedbackModel)
  const isInvalid = useShouldDisplayInputFeedback(
    feedbackMessage,
    isPristine,
    disabled
  )

  useInputValidations(
    checklist,
    useRequiredValidation(validations, required),
    feedbackModel
  )

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target
    let updatedChecklist: string[] = []

    if (checked) {
      updatedChecklist = [...checklist, value]
    } else {
      updatedChecklist = checklist.filter(check => check !== value)
    }

    setChecklist(updatedChecklist)
  }

  return (
    <div className='CheckboxGroup' {...getTestingProps(name)}>
      {options.map(option => {
        return (
          <Checkbox
            key={option.value}
            label={option.label}
            value={option.value}
            checked={checklist.includes(option.value)}
            name={name}
            onChange={handleCheckboxChange}
            onBlur={() => setIsPristine(false)}
            disabled={option.disabled || disabled}
          />
        )
      })}
      <InputFeedback isPresent={isInvalid} feedbackMessage={feedbackMessage} />
    </div>
  )
}

export default CheckboxGroup

export const CheckboxGroupControl = withFormControl(CheckboxGroup)

export const CheckboxGroupRow = withFormRow(CheckboxGroup)
