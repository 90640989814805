import React, { memo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import styled from 'styled-components';
import StackedBarComponent from '../../../components/charts/components/bar-chart/StackedBarElement';
import { FONT_COLOR_SECONDARY, SUBSIDIES_VIOLATIONS_COLOR_SCHEME } from '../../../styles/colors';
import useChartColoringMethods from '../../../yb_hooks/useChartColoringMethods';
import { ColoringMethod } from '../../../styles/enums';

const StyledContainer = styled.div`
  height: ${props => props.height || '250px'};
`;

const LongitudinalStackedGraph = ({
  data,
  keys,
  indexBy,
  dataFormatter = null,
  leftAxisValues,
  leftAxisFormatter = null,
  
  colorScheme = SUBSIDIES_VIOLATIONS_COLOR_SCHEME,
  coloringMethod = ColoringMethod.FROM_SCHEME,

  maxValue = 250000000,
  height,
  hideAxisBottom = false,
  margin = {
    top: 30,
    bottom: 30,
    right: -15,
    left: 88
  },
  'data-cy': dataCy
}) => {
  if (!data) return null;
  if (hideAxisBottom) margin.bottom = 7;

  /* Hooks */
  const { colors } = useChartColoringMethods(colorScheme, coloringMethod);

  return (
    <StyledContainer data-cy={dataCy} height={height}>
      <ResponsiveBar
        /* Data */
        data={data}
        keys={keys}
        indexBy={indexBy}

        /* Alignment & Display */
        maxValue={maxValue}
        margin={margin}
        padding={0.2}
        axisTop={null}
        axisBottom={!hideAxisBottom}
        axisRight={null}
        axisLeft={{
          format: leftAxisFormatter,
          tickValues: leftAxisValues,
          tickSize: 0,
          tickPadding: 10
        }}
        gridYValues={leftAxisValues}
        labelSkipWidth={12}
        labelSkipHeight={12}
        label={d => dataFormatter ? dataFormatter(d.value) : d.value}

        /* Colors & Style */
        colors={colors}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        theme={{
          tooltip: {
            container: {
              boxShadow: false,
              background: 'transparent',
            },
          },
          axis: {
            ticks: {
              text: {
                fill: FONT_COLOR_SECONDARY,
                fontSize: '12px'
              }
            },
          },
        }}

        /* Animation */
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        indexScale={{ type: 'band', round: true }}
        valueScale={{ type: 'linear' }}

        barComponent={(props) => <StackedBarComponent keys={keys} {...props}></StackedBarComponent>}
      />
    </StyledContainer>

  );
}

export default memo(LongitudinalStackedGraph);
