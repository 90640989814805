export const mapAccountToState = (account) => {
  return {
    email: account.email,
    new_email:account.email,
    first_name: account.first_name,
    last_name: account.last_name,
    current_password: '',
    new_password: '',
    confirm_password: '',
    department: account.department,
    title: account.title,
    avatar: null,
    avatar_url: account.avatar_url,
    two_factor_auth_enabled: account.two_factor_auth_enabled
  };
};


export const mapStateToFormData = (account, formData) => {

    formData.append("email", account.new_email);
    formData.append("first_name", account.first_name);
    formData.append("last_name", account.last_name);
    formData.append("current_password", account.current_password);
    formData.append("new_password", account.new_password);
    formData.append("confirm_password", account.confirm_password);
    formData.append("department", account.department);
    formData.append("title", account.title);


    return formData;

};
