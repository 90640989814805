export const headers = [
  {
    name: 'investment_name',
    title: 'Investment Name',
    width: '25%',
  },
  {
    name: 'asset_class_id',
    title: 'Asset Class',
    width: '15%',
  },
  {
    name: 'un_sdg',
    title: 'UN SDG',
    width: '10%',
  },
  {
    name: 'strategy_aum_dollars',
    title: 'Strategy AUM',
    width: '10%',
  },
  {
    name: 'first_impression',
    title: 'Matrix 1 Score',
    width: '5%',
  },
  {
    name: 'initial_evaluation',
    title: 'Matrix 2 Score',
    width: '5%',
  },
  {
    name: 'final_evaluation',
    title: 'Matrix 3 Score',
    width: '5%',
  },
]
