import axios from 'axios'

export const updateAccount = account => {
  return axios.put(`/app/api/account`, account)
}

export const getAccount = () => {
  return axios.get(`/app/api/account`)
}

export const postPasswordResetRequest = async account => {
  try {
    const res = await axios.post(`/app/api/passwords`, account)
    return res
  } catch (e) {
    return e.response
  }
}

export const postSignIn = async account => {
  try {
    const res = await axios.post(`/app/api/sign_in`, account)
    return res
  } catch (e) {
    return e.response
  }
}
