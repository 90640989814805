import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

import StyledDialog from '../../../components/styleguide/StyledDialog'
import Button from '../buttons/Button'

const CancelButton = styled.button`
  color: #979ca6;
  background: none;
  border: none;
  font-size: 16px;
`

const YBModal = ({
  show,
  setShow,
  title,
  children,
  submitText,
  onSubmit,
  submitDisabled,
  onHide,
}) => {
  const handleClose = () => {
    setShow(false)
    onHide && onHide()
  }

  return (
    <StyledDialog
      show={show}
      onHide={() => handleClose()}
      width={'720px'}
      justifyFooter={'right'}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>

      <Modal.Footer>
        <CancelButton onClick={() => handleClose()}>Cancel</CancelButton>
        <Button
          disabled={submitDisabled}
          text={submitText}
          onClick={onSubmit}
        />
      </Modal.Footer>
    </StyledDialog>
  )
}

export default YBModal
