import classNames from 'classnames'
import React, { useMemo } from 'react'

import { TableHead } from '../../Table.controller'
// import theme from './SortedTableHead.module.scss'
import './SortedTableHead.module.scss'

export interface ITableHeadSort {
  value: string
  order: 'asc' | 'desc'
}

export interface IProps {
  data: TableHead
  sortingModel: YB.TModel<ITableHeadSort>
}

export const SortedTableHead: React.FC<IProps> = ({
  data: tableHead,
  sortingModel,
}) => {
  const [sorting, setSorting] = sortingModel
  const isActive = useMemo(
    () => sorting.value === tableHead.value,
    [sorting, tableHead.value]
  )

  const getToggledOrder = (order: 'asc' | 'desc') =>
    order === 'asc' ? 'desc' : 'asc'

  const handleClick = () => {
    if (isActive) {
      setSorting({
        ...sorting,
        order: getToggledOrder(sorting.order),
      })
    } else {
      setSorting({
        ...sorting,
        value: tableHead.value,
      })
    }
  }

  return (
    <th
      className={classNames('SortedTableHead', {
        active: isActive,
        asc: sorting.order === 'asc',
        desc: sorting.order === 'desc',
      })}
    >
      <button type='button' onClick={handleClick}>
        {tableHead.label}
        <i className='sortingDirection' />
      </button>
    </th>
  )
}
