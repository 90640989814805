import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import Link from '../../../../../components/styleguide/Link'
import { abbreviateNumber } from '../../../../../helpers/number'
import { APP_COLOR_PRIMARY } from '../../../../../styles/colors'

/* global react_app_organization */
/*eslint no-undef: "error"*/

const StyledBoldText = styled.div`
  font-weight: bold;
`

const useHoldingsTable = ({
  /* Data */
  snapshotId,
  portfolioId,

  /* Holdings */
  setTitle,
  holdings,
  groupBy,
  limit,
}) => {
  const [selectedTableHeaders, setSelectedTableHeaders] = useState(
    getTableHeaders('standard')
  )
  const [selectedTableBody, setSelectedTableBody] = useState([])

  function getTitle(type) {
    switch (type) {
      case 'entity':
        return 'Holdings By Entity'
      case 'sector':
        return 'Holdings By Sector'
      default:
        return 'Holdings'
    }
  }

  function getDisplayedScore(score) {
    if (score === 0) {
      return Math.round(Number(score))
    } else if (!score || isNaN(score)) {
      return '—'
    } else {
      return Math.round(Number(score))
    }
  }

  function getTableHeaders(id) {
    if (id === 'standard') {
      return [
        { label: 'NAME', sort: true, id: 'name' },
        { label: 'Industry Group', sort: true, id: 'industry_group' },
        { label: 'Business Sector', sort: true, id: 'business_sector' },
        { label: 'WEIGHT', sort: true, id: 'weight' },
        { label: 'VALUE', sort: true, id: 'value' },
        { label: 'E', sort: true, id: 'e_score' },
        { label: 'S', sort: true, id: 's_score' },
        { label: 'G', sort: true, id: 'g_score' },
        { label: 'TOTAL', sort: true, id: 'total_score' },
      ]
    } else if (id === 'sector') {
      return [
        { label: 'NAME', sort: true, id: 'name' },
        { label: 'WEIGHT', sort: true, id: 'weight' },
        { label: 'VALUE', sort: true, id: 'value' },
        { label: 'E', sort: true, id: 'e_score' },
        { label: 'S', sort: true, id: 's_score' },
        { label: 'G', sort: true, id: 'g_score' },
        { label: 'TOTAL', sort: true, id: 'total_score' },
      ]
    } else {
      return [
        { label: 'NAME', sort: true, id: 'name' },
        { label: 'WEIGHT', sort: true, id: 'weight' },
        { label: 'VALUE', sort: true, id: 'value' },
        { label: 'E', sort: true, id: 'e_score' },
        { label: 'S', sort: true, id: 's_score' },
        { label: 'G', sort: true, id: 'g_score' },
        { label: 'TOTAL', sort: true, id: 'total_score' },
      ]
    }
  }

  function getTableBody(groupBy, chosenHoldings) {
    if (!groupBy || !chosenHoldings?.length) return []

    const startIndex = 0
    const endIndex = Math.min(startIndex + limit, chosenHoldings?.length)
    if (groupBy === 'sector') {
      return chosenHoldings
        .slice(startIndex, endIndex)
        .map(
          ({
            id,
            industry_group_id,
            industry_group_name,
            value,
            weight,
            scores,
            unmodeled,
          }) => {
            const realValue = parseFloat(value)
            return [
              <span
                key={`${id}-industry-group`}
                className='left ellipsis name title'
              >
                {industry_group_id != null ? (
                  <Link href={`/app/sectors/${industry_group_id}`}>
                    {industry_group_name}
                  </Link>
                ) : (
                  <StyledBoldText>
                    {industry_group_name ? industry_group_name : '—'}
                  </StyledBoldText>
                )}
              </span>,
              <span key={`${id}-weight`} className='weight offset'>
                {weight ? `${weight}%` : '—'}
              </span>,
              <span key={`${id}-value`} className='value offset'>
                {realValue ? '$' + abbreviateNumber(realValue, 2) : '—'}
              </span>,
              getDisplayedScore(!unmodeled && scores.e_score),
              getDisplayedScore(!unmodeled && scores.s_score),
              getDisplayedScore(!unmodeled && scores.g_score),
              <span key={`${id}-total-score`} className='total'>
                {getDisplayedScore(!unmodeled && scores.total_score)}
              </span>,
            ]
          }
        )
    } else if (groupBy === 'entity') {
      return chosenHoldings
        .slice(startIndex, endIndex)
        .map(({ id, entity_name, value, weight, scores, unmodeled }) => {
          const realValue = parseFloat(value)

          return [
            <span
              key={`${id}-entity-name`}
              className='left ellipsis name title'
            >
              <StyledBoldText>{entity_name ? entity_name : '—'}</StyledBoldText>
            </span>,
            <span key={`${id}-weight`} className='weight offset'>
              {weight ? `${weight}%` : '—'}
            </span>,
            <span key={`${id}-value`} className='value offset'>
              {realValue ? '$' + abbreviateNumber(realValue, 2) : '—'}
            </span>,
            getDisplayedScore(!unmodeled && scores.e_score),
            getDisplayedScore(!unmodeled && scores.s_score),
            getDisplayedScore(!unmodeled && scores.g_score),
            <span key={`${id}-total-score`} className='total'>
              {getDisplayedScore(!unmodeled && scores.total_score)}
            </span>,
          ]
        })
    } else {
      return chosenHoldings
        .slice(startIndex, endIndex)
        .map(
          ({
            name,
            industry_group,
            instrument_id,
            entity_id,
            business_sector,
            weight,
            value,
            scores,
            unmodeled,
            id,
            mapped_instrument_id,
          }) => {
            let name_text = '—'
            if (!(name == null) && !(name == '')) {
              name_text = name
            }
            const realValue = parseFloat(value)
            return [
              react_app_organization.organization.can_access_active_mgmt &&
              snapshotId ? (
                unmodeled &&
                entity_id == null &&
                mapped_instrument_id == null ? (
                  <span className='left ellipsis title'>
                    <a target='_blank'>{name_text}</a>
                  </span>
                ) : id ? (
                  <span
                    className='left ellipsis name title'
                    style={{ color: APP_COLOR_PRIMARY }}
                  >
                    {mapped_instrument_id && (
                      <svg
                        style={{
                          height: '15px',
                          marginBottom: '5px',
                          marginRight: '5px',
                        }}
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 512 512'
                      >
                        <title>This instrument is proxied.</title>
                        <path d='M280 176H208V256h72C302.1 256 320 238.1 320 216S302.1 176 280 176zM256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM280 304H208v56C208 373.3 197.3 384 184 384S160 373.3 160 360v-208C160 138.8 170.8 128 184 128h96c48.53 0 88 39.47 88 88S328.5 304 280 304z' />
                      </svg>
                    )}
                    <a
                      href={`/app/portfolios/${portfolioId}/snapshots/${snapshotId}/holdings/${id}`}
                    >
                      {name_text}
                    </a>
                  </span>
                ) : (
                  <span className='left ellipsis title'>
                    <a target='_blank'>{name_text}</a>
                  </span>
                )
              ) : id ? (
                <span
                  className='left ellipsis name title'
                  style={{ color: APP_COLOR_PRIMARY }}
                >
                  {mapped_instrument_id && (
                    <svg
                      style={{
                        height: '15px',
                        marginBottom: '5px',
                        marginRight: '5px',
                      }}
                      fill='currentColor'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 512 512'
                    >
                      <title>This instrument is proxied.</title>
                      <path d='M280 176H208V256h72C302.1 256 320 238.1 320 216S302.1 176 280 176zM256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM280 304H208v56C208 373.3 197.3 384 184 384S160 373.3 160 360v-208C160 138.8 170.8 128 184 128h96c48.53 0 88 39.47 88 88S328.5 304 280 304z' />
                    </svg>
                  )}
                  <a href={`/app/instruments/${instrument_id}`}>{name_text}</a>
                </span>
              ) : (
                <span className='left ellipsis title'>
                  <a target='_blank'>{name_text}</a>
                </span>
              ),

              industry_group ? (
                <span className='left ellipsis title'>{industry_group}</span>
              ) : (
                '—'
              ),
              business_sector ? (
                <span className='left ellipsis title'>{business_sector}</span>
              ) : (
                '—'
              ),
              <span key={`${id}-weight`} className='weight'>
                {weight}%
              </span>,
              <span key={`${id}-value`} className='value'>
                {realValue ? '$' + abbreviateNumber(realValue, 2) : '—'}
              </span>,
              getDisplayedScore(!unmodeled && scores.e_score),
              getDisplayedScore(!unmodeled && scores.s_score),
              getDisplayedScore(!unmodeled && scores.g_score),
              <span key={`${id}-total-score`} className='total'>
                {getDisplayedScore(!unmodeled && scores.total_score)}
              </span>,
            ]
          }
        )
    }
  }

  const onTableDataChange = useCallback(
    newGroupBy => {
      const header = getTableHeaders(newGroupBy)
      const body = getTableBody(newGroupBy, holdings)

      setSelectedTableHeaders(header)
      setSelectedTableBody(body)
      setTitle?.(getTitle(newGroupBy))
    },
    [holdings]
  )

  useEffect(() => {
    if (holdings && holdings.length > 0) {
      onTableDataChange(groupBy)
    }
  }, [onTableDataChange, holdings, groupBy])

  return {
    selectedTableHeaders,
    selectedTableBody,
    onTableDataChange,
  }
}

export default useHoldingsTable
