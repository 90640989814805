import React, { useEffect, useState } from 'react'

import withErrorBoundary from '../../../../hocs/withErrorBoundary'
import HoldingsTableCard from '../../../../yb_components/tables/holdings_table/HoldingsTableCard'
import { getNewPage } from '../../../research_center/helpers/paginator'
import { tableHeaders } from './FundsHoldingsCard.constants'

const FundsHoldingsCard = ({
  holdings,
  holdingsTotal,
  updateHoldings,
  loadingHoldings,
}) => {
  const [selectedPage, setSelectedPage] = useState(1)
  const [holdingsList, setHoldingsList] = useState(holdings)
  const [nPages, setNpages] = useState(0)
  const [holdingsSearch, setHoldingsSearch] = useState('')
  const [holdingsSort, setHoldingsSort] = useState('')
  const [holdingsQuery, setHoldingsQuery] = useState('')
  const [sort, setSort] = useState({ field: null, dir: null })
  const [display, setDisplay] = useState(false)
  // const [wrappedHoldingsList, setWrappedHoldingsList] = useState(wrappedHoldings);
  useEffect(() => {
    setNpages(holdingsTotal)
    setHoldingsQuery(holdingsSort, holdingsSearch)
  }, [holdingsSort, holdingsSearch])

  useEffect(() => {
    setNpages(holdingsTotal)
  }, [holdingsTotal])

  useEffect(() => {
    holdingsTotal > 0 ? setDisplay(true) : false
  }, [])

  const tableBody = holdingsList.map(
    (
      { instrument_id, asset_class, ticker, name, description, weight, scores },
      index
    ) => {
      let name_text = '—'
      if (!(name == null) && !(name == '')) {
        name_text = name
      } else {
        if (!(description == null) && !(description == '')) {
          name_text = description
        }
      }
      const formatScore = score =>
        !score || isNaN(score) ? '—' : Math.round((score * 1).toFixed(2))
      return [
        instrument_id ? (
          <span className='left ellipsis name' key={index}>
            <a
              target='_blank'
              href={`/app/instruments/${instrument_id}`}
              rel='noreferrer'
            >
              {name_text.length && name_text.length > 0 ? name_text : '-'}
            </a>
          </span>
        ) : (
          <span className='left ellipsis' key={index}>
            <a target='_blank'>
              {name_text.length && name_text.length > 0 ? name_text : '-'}
            </a>
          </span>
        ),
        <span className='weight' key={index}>
          {asset_class ? asset_class : '-'}
        </span>,
        <span className='left symbol' key={index}>
          {ticker == null ? '—' : ticker}
        </span>,
        <span className='weight' key={index}>
          {(weight * 1).toFixed(2)}%
        </span>,
        formatScore(scores.e_score),
        formatScore(scores.s_score),
        formatScore(scores.g_score),
        <span className='total' key={index}>
          {formatScore(scores.total_score)}
        </span>,
      ]
    }
  )

  const onPaginatorChange = value => {
    const newPage = getNewPage(value, selectedPage, nPages)
    setSelectedPage(newPage)
    updateHoldings(newPage, holdingsQuery)
  }

  const onSearch = value => {
    const newPage = 1
    const searchQuery = `&query=${value}`
    const query = searchQuery + holdingsSort
    setSelectedPage(newPage)
    setHoldingsSearch(searchQuery)
    updateHoldings(newPage, query)
  }

  useEffect(() => {
    setHoldingsList(holdings)
  }, [holdings])

  const onSort = (id, direction) => {
    setSort({ field: id, dir: direction })

    let colunmId = ''

    switch (id) {
      case 'asset_class':
        colunmId = 'asset_type'
        break
      case 'symbol':
        colunmId = 'ticker'
        break
      case 'env':
        colunmId = 'e_score'
        break
      case 'soc':
        colunmId = 's_score'
        break
      case 'gov':
        colunmId = 'g_score'
        break
      case 'total':
        colunmId = 'total_score'
        break
      default:
        colunmId = id
        break
    }

    const sortQuery = `&sort_field=${colunmId}&sort_dir=${direction}`
    setHoldingsSort(sortQuery)
    const finalQuery = holdingsSearch + sortQuery

    const newPage = 1
    setSelectedPage(newPage)
    setHoldingsQuery(finalQuery)

    updateHoldings(newPage, finalQuery)
  }

  return (
    display && (
      <HoldingsTableCard
        title={'Holdings'}
        onSearch={onSearch}
        onSort={onSort}
        selectedTableHeaders={tableHeaders}
        selectedTableBody={tableBody}
        loadingHoldings={loadingHoldings}
        sort={sort}
        holdingsTotal={holdings.length}
        nPages={nPages}
        selectedPage={selectedPage}
        onPaginatorChange={onPaginatorChange}
        tableType='standard'
      />
    )
  )
}

export default withErrorBoundary(FundsHoldingsCard)
