import React, { useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Button from '../../components/styleguide/Button'
import Card from '../../components/styleguide/Card'
import SpinnerContainer from '../../yb_components/styleguide/spinners/SpinnerContainer'
import {
  loadAddeparLink,
  validateAddeparRequest,
} from './integrations/addepar.loaders'

/* global react_app_organization */
/* eslint no-undef: "error"*/

const PageWrapper = styled.div`
  .card-wrapper {
    display: flex;
    flex-direction: column;
    button {
      margin-left: auto;
    }
  }
`

const AddeparPage = () => {
  const [link, setLink] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const url = useLocation().search
  const code = new URLSearchParams(url).get('code')

  useEffect(() => {
    if (code) {
      validateAddeparRequest(code, setLoading, setError)
    } else {
      loadAddeparLink(setLink, setLoading, setError)
    }
  }, [])

  const handleClick = () => {
    window.open(link)
  }

  const LoginButton = (
    <Button width={'400px'} onClick={handleClick}>
      Log In to Addepar to Enable Integration
    </Button>
  )

  return (
    <PageWrapper className='page__container'>
      {
        // eslint-disable-next-line no-undef
        react_app_organization.organization.addepar_integrated ? (
          <div>
            <h1>Integrations</h1>
            <Card>
              <div>
                <p>
                  <b>Addepar</b>
                </p>
                <p>Your Addepar account is already integrated.</p>
              </div>
            </Card>
          </div>
        ) : (
          <div>
            <h1>Integrations</h1>
            <Card>
              {loading ? (
                <SpinnerContainer isLoading={loading} />
              ) : link ? (
                <div className='card-wrapper'>
                  <p>
                    <b>Addepar</b>
                  </p>
                  <p>
                    The Addepar integration allows for the regular, automated
                    transfer of Portfolios and Holdings from Addepar into
                    YvesBlue. This integration utilizes OAuth to securely
                    authenticate you with Addepar. If you would like to learn
                    more about this feature, please reach out to our 
                    <a href="mailto:sales@yves.blue?subject=Addepar Integration">sales team</a>.
                  </p>
                  {LoginButton}
                </div>
              ) : code ? (
                error ? (
                  <div className='card-wrapper'>
                    <Alert variant='danger'>{error}</Alert>
                    {LoginButton}
                  </div>
                ) : (
                  <div className='card-wrapper'>
                    <p>
                      <b>Addepar</b>
                    </p>
                    <Alert variant='success'>Success!</Alert>
                  </div>
                )
              ) : (
                <Alert variant='danger'>{error}</Alert>
              )}
            </Card>
          </div>
        )
      }
    </PageWrapper>
  )
}

export default AddeparPage
