import React from 'react';
import styled from 'styled-components';
import { FONT_COLOR_PRIMARY, FONT_COLOR_SECONDARY } from '../../styles/colors';

const EventRow = styled.div`
  padding: 1rem 0;
  color: ${FONT_COLOR_SECONDARY};

  & .event-date { float: right; }
  & .event-user-name { color: ${FONT_COLOR_PRIMARY}; }
  & .event-initiative-name { color: ${FONT_COLOR_PRIMARY};}
  & .event-initiative-note {
    color: #333;
    font-style: italic;
  }
`;

export default EventRow;
