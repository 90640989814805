import styled from 'styled-components';
import Button from "../../../../../../components/styleguide/Button";

export const StyledButton = styled(Button)`
  position: absolute;
  top: -2.9rem;
  right: 0;
  padding: 0 15px;

  width: auto;
  text-transform: uppercase;

  img, .spinner-border {
    height: 20px;
    width: 20px;
    margin: 0 10px 0 0;
    vertical-align: top;
  }
`
