import React, {useState} from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import ReactGA from 'react-ga';
import StyledDialog from './StyledDialog';
import StyledExportButton from './StyledExportButton';
import { timestamp } from '../../helpers/shared'

const ExportReportDialog = ({displayed, name, setDisplayed, exportReport}) => {
  
  const handleClose = () => {
    setDisplayed(false)
  }

  return (
    <StyledDialog
      show={displayed}
      onHide={handleClose}
      width={700}
    >
      <Modal.Header closeButton>
        <Modal.Title>Customize Your Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          You can either download the full report (generally 10 to 15 pages) or a shorter two page
          report that shows the most important high-level information. Please click on a button below
          to download a report.
        </div>
        <StyledExportButton onClick={() => exportReport({reportVersion: 'full', timestamp})}>
          <div className="button-text">
            Full Report
          </div>
          <div className="button-icon">
            <i className="far fa-copy"></i>
          </div>
        </StyledExportButton>
        <StyledExportButton onClick={() => exportReport({reportVersion: 'short', timestamp})} className="float-right">
          <div className="button-text">
            Short Report
          </div>
          <div className="button-icon">
            <i className="far fa-file "></i>
          </div>
        </StyledExportButton>
      </Modal.Body>
    </StyledDialog>
  );
}

export default ExportReportDialog;