import React, { memo, useMemo } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import styled from 'styled-components'

import Card from '../../../components/styleguide/Card'
import CardTitle from '../../../components/styleguide/CardTitle'
import Link from '../../../components/styleguide/Link'
import TextSection from '../../../components/styleguide/TextSection'
import {
  abbreviateNumber,
  generateLastXYearsArray,
  generateSplittedArrayByMaxValue,
} from '../../../helpers/number'
import { FONT_COLOR_PRIMARY } from '../../../styles/colors'
import LongitudinalStackedGraph from '../../charts/longitudinal_stacked_graph/LongitudinalStackedGraph'
import LegendItem from '../../styleguide/legend_items/LegendItem'
import LongitudinalTable from '../../tables/longitudinal_table/LongitudinalTable'

const StyledContainer = styled.div`
  max-width: 1300px;
  margin-bottom: ${props => props.marginBottom || '0px'};
`

const StyledHeaderContainer = styled(Row)`
  margin-bottom: 30px;
`

const StyledTitleContainer = styled.div`
  display: inline;
`

const StyledTitleTextContainer = styled.span`
  padding-left: ${props => props.paddingLeft || '0px'};
  display: table-cell;
  vertical-align: middle;
`

const OverTimeCard = ({
  title,
  additionalTitleLink,
  description,
  data,
  rowHeaders,
  legendComponent,
  colorScheme,
  marginBottom,
  show = true,
  extendAsNeeded = true,
  maxValue = 250000001,
  'data-cy': dataCy,
}) => {
  if (!show) return null
  if (!data?.length) return null

  /* Formatters */
  const dataFormatter = value => {
    return `$${abbreviateNumber(value)}`
  }

  const roundLeftAxisNumber = value => {
    return Math.round(value / Math.pow(10, 7)) * Math.pow(10, 7)
  }

  const leftAxisFormatter = (value, dataMaxValue = 0) => {
    let fValue = dataMaxValue > maxValue ? roundLeftAxisNumber(value) : value
    const abbreviatedNumber = abbreviateNumber(fValue)
    return `$ ${abbreviatedNumber ? abbreviatedNumber : '0m'}`.toLowerCase()
  }

  /* Useful functions */
  const calculateDataMaxValue = data => {
    if (!data?.length || !extendAsNeeded) return maxValue

    let max = 0
    for (const elem of data) {
      _.each(elem, (value, key) => {
        if (max < parseFloat(value)) max = value
      })
    }

    if (max < 50000000) return max
    if (extendAsNeeded && maxValue < max) return roundLeftAxisNumber(max)
    return maxValue
  }

  /* Memoized variables */
  const memoizedDataMaxValue = useMemo(
    () => calculateDataMaxValue(data),
    [data]
  )
  const memoizedYearsArray = useMemo(() => generateLastXYearsArray(10, -1), [])
  const splitValue =
    memoizedDataMaxValue > maxValue || memoizedDataMaxValue < 50000000
      ? memoizedDataMaxValue / 6
      : 50000000
  const memoizedLeftAxisValues = useMemo(
    () => generateSplittedArrayByMaxValue(memoizedDataMaxValue, splitValue),
    [memoizedDataMaxValue, splitValue]
  )
  const rowHeadersGraphKeys = useMemo(
    () => rowHeaders.map(elem => (elem.omitFromChart ? null : elem.key)),
    []
  )

  /* Renders */
  const renderRowHeaderComponent = ({ label, color }) => {
    let displayDot = true
    let dotColor = 'transparent'

    if (color && color.dot) dotColor = color.dot
    else displayDot = false

    return (
      <LegendItem
        label={label}
        labelFontSize={'11px'}
        labelFontWeight={'bold'}
        labelColor={FONT_COLOR_PRIMARY}
        dotSize={'0.40rem'}
        dotColor={dotColor}
        displayDot={displayDot}
      />
    )
  }

  return (
    <StyledContainer marginBottom={marginBottom}>
      <Card data-cy={dataCy}>
        {/* Title */}
        <StyledTitleContainer>
          <StyledTitleTextContainer>
            <CardTitle
              title={title}
              fontSize='14px'
              textTransform='uppercase'
            />
          </StyledTitleTextContainer>
          {additionalTitleLink && (
            <StyledTitleTextContainer paddingLeft={'10px'}>
              <Link
                styles={{
                  margin: '10px',
                  fontSize: '12px',
                  fontWeight: 'normal',
                  display: 'table-cell',
                  verticalAlign: 'middle',
                }}
                href={additionalTitleLink?.href}
              >
                {additionalTitleLink?.label}
              </Link>
            </StyledTitleTextContainer>
          )}
        </StyledTitleContainer>

        <StyledHeaderContainer>
          {/* Description */}
          <Col md={8}>
            <TextSection fontSize={'14px'} color={FONT_COLOR_PRIMARY}>
              {description}
            </TextSection>
          </Col>

          {/* Legend Items */}
          {legendComponent}
        </StyledHeaderContainer>

        {/* Longitudinal Stacked Graph */}
        <LongitudinalStackedGraph
          data-cy={'longitudinal_stacked_graph'}
          maxValue={memoizedDataMaxValue}
          colorScheme={colorScheme}
          data={data}
          indexBy={'year'}
          keys={rowHeadersGraphKeys}
          dataFormatter={dataFormatter}
          leftAxisFormatter={value =>
            leftAxisFormatter(value, memoizedDataMaxValue)
          }
          leftAxisValues={memoizedLeftAxisValues}
          hideAxisBottom
        />

        {/* Longitudinal Table */}
        <LongitudinalTable
          data-cy={'longitudinal_table'}
          data={data}
          columnHeaders={memoizedYearsArray}
          rowHeaders={rowHeaders}
          indexBy={'year'}
          dataFormatter={dataFormatter}
          rowHeaderComponent={renderRowHeaderComponent}
        />
      </Card>
    </StyledContainer>
  )
}

export default memo(OverTimeCard)
