import styled from 'styled-components'

export const SubsidiesContainer = styled.div`
  padding: 20px 0;
`

export const SecondCardRowsContainer = styled.span`
  max-width: 100%;
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
`
