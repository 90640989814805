import React from 'react'
import ReactGA from 'react-ga'
import styled from 'styled-components'

import Table from '../../../../../components/styleguide/Table'
import Pagination from '../../../../styleguide/pagination/Pagination'

export const StyledTableContainer = styled.div`
  max-height: 1600px;
  width: ${props => (props.pdf ? '100%' : '60%')};
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`
const CarbonAuditTable = ({
  pdf,
  data,

  selectedChart,
  sortState,
  paginationState,
  onChange,
  displayPagination = false,
  displaySort = false,
  displayBenchmark = true,
  loading,
}) => {
  if (!data) return
  const { tableHeaders, tableBody, tableFooter } = data

  const [sort, setSort] = sortState
  const [pagination, setPagination] = paginationState

  return (
    <StyledTableContainer pdf={pdf}>
      <Table
        leftAlignHeader={[0]}
        rightAlignHeader={[1, 2, 3, 4]}
        onSort={(field, dir) => {
          setSort({ field, dir })
          onChange({
            sort_field: field,
            sort_dir: dir,
          })
        }}
        sort={sort}
        showSort={displaySort ? true : false}
        headers={tableHeaders}
        body={tableBody}
        footer={tableFooter}
        loading={loading}
        loadingRows={11}
        loadingColumns={5}
        alignNumbers={true}
        tooltipCol={1}
        look={{
          textAlign: 'left',
          thTextAlign: 'center',
          thFontSize: pdf ? '10px' : '12px',
          tdFontSize: pdf ? '9px' : '15px',
          thSpanPadding: '0px',
          thFourthChildWidth: displayBenchmark ? 'auto' : '10px',
          th: {
            sticky: true,
            backgroundColor: 'white',
            transform: 'translate(0, -10px)',
            padding: '10px 0px',
          },
          trEvenBackgroundColor: '#F1F1F1',
          tdPadding: `${
            selectedChart === 'holding' ? '0.25rem 0' : '0.86rem 0'
          }`,
          tfoot: {
            sticky: true,
            margin: '10 0 0 0',
            backgroundColor: 'white',
            transform: 'translate(0, 10px)',
            customCol: {
              nthChild: displayBenchmark
                ? selectedChart === 'holding'
                  ? 0
                  : 6
                : 0,
              padding: '0px 0px 0px 10px',
            },
          },
          lastRow: {
            textAlign: 'right',
            width: displayBenchmark
              ? selectedChart === 'holding'
                ? '40px'
                : 'auto'
              : '40px',
          },
          tdCustomStyle: {
            nthChild: displayBenchmark ? 6 : 0,
            textAlign: 'right !important',
            width: '10px',
          },
        }}
      />
      {displayPagination && (
        <div className='paginator-switch-wrapper'>
          <Pagination
            max={pagination?.totalPages}
            selected={pagination?.page}
            onChange={(newPage, direction) => {
              if (direction) {
                ReactGA.event({
                  category: 'Carbon Audit',
                  action: 'Pagination',
                  label: direction,
                })
              }
              setPagination(newPage)
            }}
          />
        </div>
      )}
    </StyledTableContainer>
  )
}

export default CarbonAuditTable
