import Color from 'color'
import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'

import { SdgIcon } from './SdgIcon'

export const SdgButton = ({ color, onToggleSdg, sdg, selected }) => {
  const buttonColor = Color(color)
  const hoverColor = buttonColor.darken(0.2).hex()
  const boxShadowColor = buttonColor.lighten(0.6).hex()

  const StyledButton = styled(Button)`
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 48px;
    aspect-ratio: 1/1;
    border: none;
    border-radius: 0;
    outline: none;
    overflow: hidden;
    background-color: transparent;
    color: white;

    svg {
      width: 100%;
      height: 100%;
      color: ${selected ? color : '#979ca6'};
      transition: color 0.15s ease-in-out;
    }

    &:focus,
    &:hover,
    &:active {
      color: white;
      background-color: transparent !important;
      border-color: transparent !important;
    }
    &:hover,
    &:active {
      svg {
        color: ${selected ? hoverColor : color};
      }
    }
    &:focus,
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0 0 0 0.2rem ${boxShadowColor}!important;
    }
    &:not(:disabled):not(.disabled):active {
      box-shadow: none;
    }
  `

  return (
    <StyledButton onClick={() => onToggleSdg(sdg, !selected)}>
      <SdgIcon sdg={sdg} tabIndex='-1' />
    </StyledButton>
  )
}
