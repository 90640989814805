import React  from 'react';
import styled from 'styled-components';
import { APP_COLOR_ERROR, BORDER_COLOR_PRIMARY } from '../../styles/colors';

const SelectInputGroup = styled.div`
  .select-input-error {
    font-size: 12px;
    white-space: nowrap;
    left: 0;
    bottom: -10;
    color: ${APP_COLOR_ERROR};
  }
`;

export default SelectInputGroup;
