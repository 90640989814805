import React from 'react';
import { HoldingTitleWrapper, HoldingHeaderWrapper, HoldingTitle  } from '../styles';
import SectionWrapper from '../../../../../components/styleguide/SectionWrapper';

const HoldingHeader = ({ holding, onExport }) => {
  return (
    <HoldingHeaderWrapper>
      <HoldingTitleWrapper>
        <HoldingTitle>{holding.name}</HoldingTitle>
      </HoldingTitleWrapper>
      <div>
        <SectionWrapper>{holding.logo}</SectionWrapper>
      </div>    
      </HoldingHeaderWrapper>    
    );
}

export default HoldingHeader;
