import React from 'react'
import styled from 'styled-components'

import {
  APP_COLOR_PRIMARY,
  BG_COLOR_PRIMARY,
  BORDER_COLOR_PRIMARY,
} from '../../styles/colors'

const Button = styled.button`
  /* button resets were added to global App in tgl/carbon-chart - remove after merge */
  background-color: white;
  border: none;
  outline: none;
  &:focus {
    outline: none;
  }

  width: 100%;
  margin-top: 20px;
  padding: 12px;
  background-color: ${BG_COLOR_PRIMARY};
  color: ${APP_COLOR_PRIMARY};
  font-weight: bold;
  font-size: 15px;
  border: 1px solid ${BORDER_COLOR_PRIMARY};
`

const AddMoreButton = ({ onClick }) => {
  return <Button onClick={onClick}>+ Add More</Button>
}

export default AddMoreButton
