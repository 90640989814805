import propTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { FONT_COLOR_SECONDARY } from '../../styles/colors'
import {
  ExplainerLink,
  LinkIcon,
  LinkText,
} from '../../yb_components/styleguide/links/Link.styles'
import { TargetType } from '../../yb_constants/enums/global_enums'
import Card from '../styleguide/Card'
import CardTitle from '../styleguide/CardTitle'
import ImpactCardValue from '../styleguide/ImpactCardValue'

const StyledCard = styled(Card)`
  min-height: ${props => `${props.minHeight}px`};
  .content {
    font-size: 15.5px;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    height: 160px;
    & > * {
      display: inline-block;
      &:first-child {
        vertical-align: top;
        padding-left: 2.25rem;
        width: 30%;
      }
      &:last-child {
        vertical-align: top;
        width: 60%;
        margin-left: 2rem;
      }
    }
    margin-bottom: 20px;
  }

  .card-note {
    font-size: 12px;
    text-align: center;
    color: ${FONT_COLOR_SECONDARY};
  }
`

const CustomExplainerLink = styled(ExplainerLink)`
  float: right;
`

const ImpactCard = ({
  targetType,
  title,
  value,
  holdingsPct,
  unit,
  description,
  icons,
  benchmarkDescription,
  explainerHref,
}) => {
  const contentMargin = title == 'WASTE' ? 0 : '2rem'

  const getCardNoteText = () => {
    switch (targetType) {
      case TargetType.PORTFOLIO_SNAPSHOT:
        return `This number was calculated using data available from ${holdingsPct}% of your long portfolio holdings.`

      default:
      case TargetType.INSTRUMENT:
        return `This number was calculated using data available from ${holdingsPct}% of the long instrument holdings.`
    }
  }

  return (
    <StyledCard minHeight={260} contentMargin={contentMargin}>
      <CardTitle title={title} />

      {explainerHref && (
        <CustomExplainerLink href={explainerHref} target='_blank'>
          <LinkIcon className='far fa-question-circle' />
          <LinkText>How is this calculated?</LinkText>
        </CustomExplainerLink>
      )}

      <div className='content'>
        <div>{icons}</div>
        <div>
          <ImpactCardValue
            value={value}
            unit={unit}
            benchmarkDescription={benchmarkDescription}
            description={description}
          />
        </div>
      </div>
      {!isNaN(holdingsPct) && !!holdingsPct && (
        <div className='card-note'>{getCardNoteText()}</div>
      )}
    </StyledCard>
  )
}

ImpactCard.propTypes = {
  title: propTypes.string.isRequired,
  subtitle: propTypes.string,
  name: propTypes.string,
  data: propTypes.shape({
    value: propTypes.number.isRequired,
    total: propTypes.number.isRequired,
    total_perc: propTypes.number.isRequired,
    median: propTypes.number.isRequired,
    median_perc: propTypes.number.isRequired,
  }),
  yearly: propTypes.bool,
  currency: propTypes.bool,
  percentage: propTypes.bool,
}

export default ImpactCard
