import range from 'range';
import { mapRiskAssessmentToState } from './risk_assessment_mapper';
import { mapInstrumentToState } from './instruments_mapper';

export const mapHedgeFundHoldingToState = hedgeFundHolding => {
  const initiatives = {
    environmental: [],
    social: [],
    governance: []
  };

  hedgeFundHolding.focus_areas.forEach(focus_area => {
    if(initiatives[focus_area.category]) {
      initiatives[focus_area.category].push(mapRiskAssessmentToState(focus_area));
    }
  });

  return {
    ...hedgeFundHolding,
    initiatives
  };
}

export const mapHedgeFundToState = hedgeFundHolding => {
  const initiatives = {
    environmental: [],
    social: [],
    governance: []
  };

  hedgeFundHolding.focus_areas.forEach(focus_area => {
    if(initiatives[focus_area.category]) {
      initiatives[focus_area.category].push(focus_area);
    }
  });

  return {
    ...hedgeFundHolding,
    initiatives
  };
}
