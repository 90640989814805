import propTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { APP_COLOR_PRIMARY } from '../../styles/colors'

const StyledRadio = styled.div`
  .container {
    text-align: left;
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 10pt;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: 1px solid #cdcdcd;
    border-radius: 50%;
  }
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: ${APP_COLOR_PRIMARY};
  }
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 6px;
    left: 6px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: white;
  }
  .label {
    padding-top: 3px;
  }
`

const handleChange = (e, setRadioButton) => {
  setRadioButton(e.target.value)
}
const MainRadioButton = ({
  label,
  checked,
  name = 'radio',
  value,
  setRadioButton,
}) => {
  return (
    <StyledRadio>
      <label className='container'>
        <div className='label'>
          <b>{label}</b>
        </div>
        <input
          type='radio'
          checked={checked}
          name={name}
          value={value}
          onChange={e => handleChange(e, setRadioButton)}
        ></input>
        <span className='checkmark'></span>
      </label>
    </StyledRadio>
  )
}

export default MainRadioButton
