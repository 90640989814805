import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { BaseFormGroup } from '../../../../../yb_components/styleguide/form/base/form_group/BaseFormGroup'
import { BaseErrorText } from '../../../../../yb_components/styleguide/form/base/help_text/BaseErrorText'
import { BaseHelpText } from '../../../../../yb_components/styleguide/form/base/help_text/BaseHelpText'
import { BaseCombobox } from '../../../../../yb_components/styleguide/form/base/inputs/BaseCombobox'
import { BaseLabel } from '../../../../../yb_components/styleguide/form/base/label/BaseLabel'
import { SdgGrid } from '../sdgs/SdgGrid'
import { StyledCard } from './FormCard'

export const ImpactSection = ({
  impactThemes,
  initialFormData,
  selectedSdgs,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext()

  const [sdgs, setSdgs] = useState(selectedSdgs)
  const [themeOptions] = useState(impactThemes)
  const [selectedThemes, setSelectedThemes] = useState([])
  const [selectedThemeObjs, setSelectedThemeObjs] = useState([])

  useEffect(() => {
    const data = getValues()
    const sdgData = data.sdg_alignment_ids
    setSdgs(sdgData)
    const themeData = data.impact_theme_ids
    setSelectedThemes(themeData)
    const themeObjData = data.impact_themes
    setSelectedThemeObjs(themeObjData)
  }, [initialFormData])

  const onToggleSdg = (num, newVal) => {
    let updatedSdgs

    if (newVal) updatedSdgs = [...sdgs, num]
    else updatedSdgs = sdgs.filter(el => el !== num)

    setSdgs(updatedSdgs)
  }

  useEffect(() => {
    setValue('sdg_alignment_ids', sdgs, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }, [sdgs])

  useEffect(() => {
    setValue('impact_theme_ids', selectedThemes, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }, [selectedThemes])

  const handleSelectThemes = val => {
    setSelectedThemeObjs(val)

    const idsArr = val.map(el => el.id)

    setValue('impact_theme_ids', idsArr, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  return (
    <StyledCard title='Impact Characteristics'>
      <section>
        <BaseFormGroup
          controlId='impact_themes_select'
          style={{ width: 'calc(50% - 10px)' }}
        >
          <BaseLabel>
            Impact Themes <small>(Required)</small>
          </BaseLabel>
          <Controller
            control={control}
            name='impact_theme_ids'
            render={({ field: { name, ref } }) => (
              <BaseCombobox
                inputRef={ref}
                getOptionValue={option => `${option.id}`}
                getOptionLabel={option =>
                  option.name != null ? `${option.name}` : null
                }
                options={themeOptions}
                placeholder={`Select Impact Themes`}
                isMulti
                maxMenuHeight={180}
                closeMenuOnSelect={false}
                onChange={val => handleSelectThemes(val)}
                value={selectedThemeObjs}
                name={name}
                control={control}
                rules={{ required: true }}
                className={errors.impact_theme_ids && 'has-error'}
              />
            )}
          />
          {errors.impact_theme_ids ? (
            <BaseErrorText>Please select 1 to 3 Impact Themes.</BaseErrorText>
          ) : (
            <BaseHelpText>Please select 1 to 3 Impact Themes.</BaseHelpText>
          )}
        </BaseFormGroup>
        <BaseFormGroup
          controlId='sdg_alignment'
          style={{ width: 'calc(50% - 10px)' }}
        >
          <BaseLabel>
            SDG Alignment <small>(Required)</small>
          </BaseLabel>
          <Controller
            control={control}
            name='sdg_alignment_ids'
            render={({ field: { name } }) => (
              <SdgGrid
                name={name}
                isInvalid={!!errors.sdg_alignment_ids}
                selectedSdgs={sdgs}
                onToggleSdg={onToggleSdg}
              />
            )}
          />
          {errors?.sdg_alignment_ids ? (
            <BaseErrorText>Please select 1 to 5 SDGs.</BaseErrorText>
          ) : (
            <BaseHelpText>Please select 1 to 5 SDGs.</BaseHelpText>
          )}
        </BaseFormGroup>
      </section>
    </StyledCard>
  )
}
