import axios from 'axios'

export const getAddeparLink = () => {
  return axios.get(
    `/app/api/organization/integrations/addepar/authorization_url`
  )
}

export const postAddeparCode = code => {
  return axios.post(
    `/app/api/organization/integrations/addepar/validate_auth_code`,
    {
      auth_code: code,
    }
  )
}
