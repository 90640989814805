import _ from 'lodash'
import isEqual from 'lodash/isEqual'
import { useEffect, useState } from 'react'

import { repeatArray } from '../../../../yb_helpers/arrays'

const useCachedColors = ({
  id,
  colorPalette = [],
  keys,
  cacheData,
  loading,
} = {}) => {
  const [colors, setColors] = useState(null)

  const generateColors = newKeys => {
    const colors = repeatArray(colorPalette, newKeys?.length)

    const newColors = {}
    keys.forEach((key, index) => {
      newColors[key] = colors[index]
    })

    return newColors
  }

  useEffect(() => {
    if (!id || !keys || loading) return

    let cachedColors = localStorage.getItem(id)
    if (cachedColors) cachedColors = JSON.parse(cachedColors)

    const newKeys = Object.keys(keys)

    if (
      (cacheData || !cachedColors) &&
      colorPalette?.length &&
      newKeys?.length
    ) {
      const newColors = generateColors(newKeys)

      if (!isEqual(newColors, cachedColors)) {
        localStorage.setItem(id, JSON.stringify(newColors))
      }
      setColors(newColors)
    } else {
      const intersection = _.intersection(keys, Object.keys(cachedColors))
      if (intersection?.length === keys?.length) setColors(cachedColors)
      else {
        const newColors = generateColors(newKeys)
        setColors(newColors)
      }
    }
  }, [id, keys, cacheData, loading, colorPalette])

  return { colors }
}

export default useCachedColors
