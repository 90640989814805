import React, { useEffect } from 'react'

import { getProgressColor } from '../../../helpers/esg_scale'
import {
  APP_COLOR_PRIMARY,
  ESG_SCALE_COLOR_ABOVE_AVERAGE,
  ESG_SCALE_COLOR_BELOW_AVERAGE,
} from '../../../styles/colors'

const ESGScoreBreakdownBar = ({
  e_score,
  s_score,
  g_score,
  index,
  customESGLabels,
}) => {
  useEffect(() => renderGraph(), [])

  const getScore = score => {
    return getProgressColor(score, customESGLabels)
  }

  const renderGraph = () => {
    const ctx = document
      .getElementById(`score_breakdown-${index}`)
      .getContext('2d')
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['E', 'S', 'G'],
        datasets: [
          {
            label: 'Score',
            datalabels: {
              anchor: 'end',
              align: 'end',
            },
            data: [
              (+e_score).toFixed(2),
              (+s_score).toFixed(2),
              (+g_score).toFixed(2),
            ],
            backgroundColor: [
              getScore(e_score),
              getScore(s_score),
              getScore(g_score),
            ],
            borderColor: 'rgba(74, 74, 74, 0.2)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        tooltips: false,
        responsive: true,
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 100,
                stepSize: 25,
                beginAtZero: true,
              },
            },
          ],
        },
      },
    })
  }

  return <canvas id={`score_breakdown-${index}`}></canvas>
}

export default ESGScoreBreakdownBar
