import React, { useState } from 'react'
import { FormControl } from 'react-bootstrap'

import Modal from '../../../../yb_components/styleguide/modal/Modal'
import { sendWeights, submitData } from '../../custom_esg_scoring_repository'
import { StyledModalContent } from '../../CustomESGScoring.styles'

const CreatePresetModal = ({
  show,
  setShow,
  data,
  targetType,
  setIntermediateScoresLoading,
  dispatch,
}) => {
  const [name, setName] = useState()
  const [description, setDescription] = useState()
  const [timer, setTimer] = useState()

  const handleSubmit = ({ name, description }) => {
    data = { ...data, name, description, form_action: 'create_preset' }
    submitData({ data })
      ?.then(res => {
        setShow(false)
        setName()
        setDescription()
        dispatch({
          type: 'initial-data-loaded',
          payload: {
            esg_metrics_map: res.data.esg_setting.esg_metrics_map,
            overall_weights: res.data.esg_setting.overall_weights,
            sector_weights: res.data.esg_setting.sector_weights,
            companies_by_sector: res.data.esg_setting.companies_by_sector,
            latest_portfolios: res.data.esg_setting.latest_portfolios,
            score_display_settings: res.data.esg_setting.score_display_settings,
            score_ranking_setting: res.data.esg_setting.score_ranking_setting,
            //preserve the initial esg setting if in Portfolio context
            id: targetType == 'Portfolio' ? data.id : res.data.esg_setting.id,
          },
        })
      })
      .then(() =>
        sendWeights({
          data,
          setIntermediateScoresLoading,
          setTimer,
          dispatch,
        })
      )
  }

  return (
    <Modal
      show={show}
      setShow={setShow}
      title='Create Preset'
      submitText='Save Changes'
      onSubmit={() => handleSubmit({ name, description })}
    >
      <StyledModalContent>
        <div className='description'>
          Enter a name and description for the preset you're saving. The preset
          will be available to other users within your organization and the
          label will appear on the portfolio reports.
        </div>
        <label>Name</label>
        <FormControl
          type='text'
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <label>Description</label>
        <FormControl
          as='textarea'
          rows={5}
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
      </StyledModalContent>
    </Modal>
  )
}

export default CreatePresetModal
