import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { TcfdTableColorCell } from './TcfdTableColorCell'

const StyledLabelContainer = styled.td`
  padding: 0 10px;
  width: 245px;
  border-right: none;
  text-align: left;
  line-height: 1.2;
  overflow: ellipsis;
`

const StyledWeight = styled.td`
  width: 80px;
  border-left: none;
  color: #979797;
  text-align: center;
`

export const TcfdTableRow = ({ row }) => {
  const renderColorCells = data =>
    data.map(({ number, id }) => (
      <TcfdTableColorCell key={id} number={number} />
    ))

  return (
    <tr>
      <StyledLabelContainer>
        <span>{row.name || '—'}</span>
      </StyledLabelContainer>
      <StyledWeight>{(+row.weight)?.toFixed(2) + '%' || '—'}</StyledWeight>
      {renderColorCells([
        { number: row.overall_score, id: 0 },
        {
          number: row.policy_and_legislation_score,
          id: 1,
        },
        {
          number: row.technology_and_adaptation_score,
          id: 2,
        },
        {
          number: row.market_risk_1_score,
          id: 3,
        },
        {
          number: row.market_risk_2_score,
          id: 4,
        },
        {
          number: row.market_risk_3_score,
          id: 5,
        },
        { number: row.reputation_1_score, id: 6 },
        { number: row.reputation_2_score, id: 7 },
        {
          number: row.physical_risk_1_score,
          id: 8,
        },
        {
          number: row.physical_risk_2_score,
          id: 9,
        },
      ])}
    </tr>
  )
}

TcfdTableRow.propTypes = {
  row: PropTypes.object.isRequired,
}
