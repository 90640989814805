import styled from 'styled-components'

import { getPositioningTransform } from './TooltipWrapper.helpers'

export const TooltipContent = styled.div`
  display: none;
  position: absolute;
  ${props => props.margin && `margin: ${props.margin};`}

  opacity: 0;
  transition: opacity 0.5s;

  ${props =>
    props.wrapper?.width &&
    props.wrapper?.height &&
    props.content?.width &&
    props.content?.height &&
    getPositioningTransform(
      props.positioning,
      props.wrapper,
      props.content,
      props.margin
    )};
`

export const StyledContainer = styled.div`
  ${props =>
    props.clickFunctionality !== true &&
    `:hover ${TooltipContent} {
       display: block;
       opacity: 1;
    }`}
  ${props =>
    props.clickFunctionality &&
    props.displayTooltip &&
    `
    ${TooltipContent} {
      display: block;
      opacity: 1;
    }
  `}
  ${props => props.zIndex && `z-index: ${props.zIndex}; `}
`
