import { mapInstrumentToState } from '../../../mappers/instruments_mapper'
import { getImpactBenchmark } from '../../../repositories/benchmark_repository'
import {
  getBadges,
  getCarbonSummaryReportData,
  getEntityPeers,
  getInstrument,
  getInstrumentCo2Projections,
  getInstrumentHoldings,
  getInstrumentHoldingsAssetAllocations,
} from '../../../repositories/instruments_repository'
import { getTcfdInstrument } from '../../portfolio/tcfd/repositories/tcfd_repository'

const loadBenchmark = ({ impactBenchmarkId, setImpactBenchmark, setError }) => {
  getImpactBenchmark({ impactBenchmarkId })
    .then(res => {
      setImpactBenchmark(mapInstrumentToState(res.data))
    })
    .catch(err => {
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

export const loadInstrument = ({
  instrumentId,
  setInstrument,
  setImpactBenchmark,
  setError,
}) => {
  getInstrument(instrumentId)
    .then(res => {
      setInstrument(mapInstrumentToState(res.data))
      loadBenchmark({
        impactBenchmarkId: res.data.benchmark_id,
        setImpactBenchmark,
        setError,
      })
    })
    .catch(err => {
      console.error(err)
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

export const loadBadges = ({ instrumentId, setBadges, setError }) => {
  getBadges(instrumentId)
    .then(res => setBadges(res.data.badges))
    .catch(err => {
      console.error(err)
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

export const loadInstrumentHoldings = (
  instrumentId,
  setHoldings,
  setHoldingsTotal,
  setError,
  page,
  query,
  setLoadingHoldings
) => {
  setLoadingHoldings(true)
  getInstrumentHoldings(instrumentId, { page: page, query: query })
    .then(res => {
      setLoadingHoldings(false)
      setHoldings(res.data.holdings)
      setHoldingsTotal(res.data.total_pages)
    })
    .catch(err => {
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

export const loadInstrumentHoldingAssetAllocations = (
  instrumentId,
  setAssetAllocations,
  setError
) => {
  getInstrumentHoldingsAssetAllocations(instrumentId)
    .then(res => {
      const data = res.data.map(d => {
        return { name: d.name, weight: d.weight, value: d.count }
      })
      setAssetAllocations(data)
    })
    .catch(err => {
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

export const loadInstrumentCo2Projections = (
  instrumentId,
  setCo2Projections,
  setError
) => {
  getInstrumentCo2Projections(instrumentId)
    .then(res => {
      setCo2Projections(res.data)
    })
    .catch(err => {
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

export const loadEntityPeers = (entityId, setPeers, setError) => {
  if (entityId == null) return

  getEntityPeers(entityId)
    .then(result => setPeers(result.data))
    .catch(err => {
      const error = err?.response?.status || 502
      setError(error)
    })
}

export const loadTcfdHoldings = async (
  setHoldings,
  setPortfolioScores,
  setCustomScores,
  instrumentId
) => {
  try {
    const res = await getTcfdInstrument(instrumentId)
    setHoldings && setHoldings(res.holding_scores)
    setPortfolioScores && setPortfolioScores(res.overall_scores)
    setCustomScores && setCustomScores(res.custom_scores)
  } catch (e) {
    console.error(e)
  }
}

export const loadCarbonSummaryReportData = async (
  setCarbonSummaryReport,
  instrumentId
) => {
  try {
    const res = await getCarbonSummaryReportData(instrumentId)
    setCarbonSummaryReport(res)
  } catch (e) {
    setCarbonSummaryReport(e)
  }
}
