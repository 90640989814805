import React from 'react';
import injectSheet from 'react-jss';
import propTypes from 'prop-types';

const CircularProgress = ({ classes, children }) => {
  return (
    <div className={classes.progress}>
      <div className={classes.center}>
        {children}
      </div>
    </div>
  );
}

CircularProgress.propTypes = {
  progress: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
  chidren: propTypes.node,
  color: propTypes.string,
  size: propTypes.number
};

const style = {
  progress: {
    position: 'relative',
    display: 'inline-block',
    backgroundColor: props => props.color,
    backgroundImage: props => {
      let progress = parseInt(props.progress);
      if(progress < 50) {
        return `linear-gradient(${90 + 360 * progress / 100}deg, transparent 50%, lightgrey 50%), linear-gradient(90deg, lightgrey 50%, transparent 50%)`;
      } else {
        return `linear-gradient(${90 + 360 * (progress - 50) / 100}deg, transparent 50%, ${props.color} 50%), linear-gradient(90deg, lightgrey 50%, transparent 50%)`;
      }
    },
    height: props => props.size + 'rem',
    width: props => props.size + 'rem',
    borderRadius: '50%'
  },
  center: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    height: props => (props.size - props.stroke) + 'rem',
    width: props => (props.size - props.stroke) + 'rem',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    margin: 'auto',
    borderRadius: '50%'
  }
};

export default injectSheet(style)(CircularProgress);