// LIBRARIES
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Select from 'react-select-3'

import Alert from '../../../../components/styleguide/Alert'
import Button from '../../../../components/styleguide/Button'
import DatePicker from '../../../../components/styleguide/DatePicker'
import InputLabel from '../../../../components/styleguide/InputLabel'
import SectionWrapper from '../../../../components/styleguide/SectionWrapper'
import SelectInputGroup from '../../../../components/styleguide/SelectInputGroup'
import TextArea from '../../../../components/styleguide/TextArea'
// STYLE GUIDE COMPONENTS
import TextInput from '../../../../components/styleguide/TextInput'
import WrapperColumn from '../../../../components/styleguide/WrapperColumn'
// LOOKUPS
import {
  countryCodes,
  defaultInvestmentStage,
  defaultInvestmentStatus,
  developmentFocuses,
  impactThemes,
  investmentStages,
  investmentStatuses,
  investmentTypes,
} from '../../../../constants/funds'
// REPOSITORY CALLS
import { postFund, putFund } from '../../../../repositories/funds_repository'
// COMPONENTS
import SaveButton from '../../components/SaveButton'
import { PEErrorService } from '../../errorService'
// STYLES
import { ConfirmDialog, ModuleCardTitle, ModuleWrapper } from '../../styles'

const ConfigurationSection = ({ callbacks, fund, config }) => {
  const [errors, setErrors] = useState([])
  const [setInceptionDate] = useState([])
  const [address, setAddress] = useState(fund.configuration.address)
  const [description, setDescription] = useState(fund.configuration.description)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const disableForm = config.actionType === 'edit' && !config.canUpdate

  const onCreate = () => {
    postFund({ fund })
      .then(response => {
        callbacks.forEach(cb => cb(response.data.id))
      })
      .catch(error => {
        if (error && error.response) {
          if (Object.keys(error.response.data).includes('name')) {
            error.response.data['fund_name'] = error.response.data.name
          }
          PEErrorService.setErrors(error.response.data, setErrors)
          window.scrollTo(0, 0)
        }
      })
  }

  const onUpdate = () => {
    setShowConfirmModal(true)
  }

  const updateFund = () => {
    setShowErrorMessage(false)

    putFund({ fund })
      .then(response => {
        callbacks && callbacks.forEach(cb => cb(response.data))
        setShowConfirmModal(false)
        setShowSuccessMessage(true)
        window.scrollTo(0, 0)
      })
      .catch(error => {
        if (error && error.response) {
          setShowErrorMessage(true)
          PEErrorService.setErrors(error.response.data, setErrors)
          setShowConfirmModal(false)
          window.scrollTo(0, 0)
        }
      })
  }

  const updateInceptionDate = (date, id) => {
    setInceptionDate(date)
    fund.setConfigurationParam(id, date)
  }

  const getActionType = () => {
    switch (config.actionType) {
      case 'create':
        return onCreate
      case 'edit':
        return onUpdate
    }
  }

  const inceptionDateClean = () => {
    if (typeof fund.configuration.inception_date === 'string') {
      return fund.configuration.inception_date.replace(/-/g, '/')
    } else {
      return fund.configuration.inception_date
    }
  }

  const changeAddress = (id, value) => {
    fund.setConfigurationParam(id, value)
    setAddress(value)
  }

  const changeDescription = (id, value) => {
    fund.setConfigurationParam(id, value)
    setDescription(value)
  }

  return (
    <>
      {showSuccessMessage && (
        <Alert type='success'>Fund successfully updated.</Alert>
      )}

      {showErrorMessage && (
        <Alert type='error'>There was an error updating your fund.</Alert>
      )}
      <form>
        <fieldset disabled={disableForm}>
          <ModuleWrapper>
            <ModuleCardTitle title='General Information' />
            <SectionWrapper
              margin='5px 0px 5px 10px'
              disabled={true}
              width='100%'
            >
              <TextInput
                error={errors.fund_name}
                marginRight='30px'
                label='Fund Name *'
                width='40%'
                id='name'
                defaultValue={fund.name}
                onChange={(id, value) => {
                  fund.setName(value)
                }}
              />
              <TextInput
                label='Firm'
                error={errors.firm}
                width='40%'
                id='firm'
                defaultValue={fund.configuration.firm}
                onChange={(id, value) => fund.setConfigurationParam(id, value)}
              />
              <TextInput
                error={errors.administrator}
                label='Administrator *'
                width='40%'
                id='administrator'
                defaultValue={fund.configuration.administrator}
                onChange={(id, value) => fund.setConfigurationParam(id, value)}
              />
              <SectionWrapper margin='2.5px 0px 0px 35px' width='39.5%'>
                <DatePicker
                  error={errors.inception_date}
                  label='Inception Date *'
                  width='100%'
                  id='inception_date'
                  date={inceptionDateClean()}
                  disabled={disableForm}
                  onChange={(value, id) => updateInceptionDate(value, id)}
                />
              </SectionWrapper>
            </SectionWrapper>
            <SectionWrapper margin='0px 0px 5px 10px' width='100%'>
              <SectionWrapper width='40%' margin='0px 40px 0px 0px'>
                <TextArea
                  label='Address'
                  error={errors.address}
                  width='100%'
                  rows={5}
                  id='address'
                  marginRight='20px'
                  value={address}
                  onChange={(id, value) => changeAddress(id, value)}
                />
              </SectionWrapper>
              <TextArea
                label='Description'
                error={errors.description}
                width='40%'
                rows={5}
                id='description'
                value={description}
                onChange={(id, value) => changeDescription(id, value)}
              />
            </SectionWrapper>
            <SectionWrapper
              margin='5px 0px 5px 10px'
              width='100%'
            ></SectionWrapper>
          </ModuleWrapper>
          <ModuleWrapper>
            <ModuleCardTitle title='Investment Strategy' />
            <SectionWrapper margin='5px 0px 5px 10px' width='82.5%'>
              <InputLabel label='Investment Type' />
              <Select
                styles={{
                  container: provided => ({
                    ...provided,
                    width: '100%',
                    marginBottom: '16.5px',
                  }),
                  input: provided => ({
                    ...provided,
                    paddingTop: '8px',
                    paddingBottom: '4.75px',
                  }),
                }}
                onChange={selected => {
                  fund.setInvestmentStrategyParam(
                    'investment_type',
                    selected.value
                  )
                }}
                id='investment_type'
                error={errors.investment_type}
                options={investmentTypes}
                defaultValue={investmentTypes.find(
                  i => i.value === fund.investment_strategy.investment_type
                )}
              />
            </SectionWrapper>
            <SectionWrapper margin='0px 0px 5px 10px' width='100%'>
              <TextInput
                error={errors.investment_benchmark}
                marginRight='30px'
                label='Investment Benchmark *'
                width='40%'
                id='investment_benchmark'
                onChange={(id, value) =>
                  fund.setInvestmentStrategyParam(id, value)
                }
                defaultValue={fund.investment_strategy.investment_benchmark}
              />
              <TextInput
                marginRight='30px'
                label='Impact Benchmark *'
                width='40%'
                id='impact_benchmark'
                error={errors.impact_benchmark}
                onChange={(id, value) =>
                  fund.setInvestmentStrategyParam(id, value)
                }
                defaultValue={fund.investment_strategy.impact_benchmark}
              />
              <WrapperColumn
                styles={{ margin: '14.25px 0px 0px 0px', width: '40%' }}
              >
                <SelectInputGroup error={errors.development_focus}>
                  <InputLabel label='Development Focus *' />
                  <Select
                    styles={{
                      container: provided => ({
                        ...provided,
                        width: '100%',
                        marginBottom: '5px',
                      }),
                      input: provided => ({
                        ...provided,
                        paddingTop: '8px',
                        paddingBottom: '4.75px',
                      }),
                    }}
                    onChange={selected => {
                      fund.setInvestmentStrategyParam(
                        'development_focus',
                        selected.value
                      )
                    }}
                    id='development_focus'
                    options={developmentFocuses}
                  />
                  <div className='select-input-error'>
                    {errors.development_focus}
                  </div>
                </SelectInputGroup>
              </WrapperColumn>
              <WrapperColumn
                styles={{ margin: '14.25px 0px 0px 30px', width: '40%' }}
              >
                <SelectInputGroup error={errors.impact_theme}>
                  <InputLabel label='Impact Theme *' />
                  <Select
                    styles={{
                      container: provided => ({
                        ...provided,
                        width: '100%',
                        marginBottom: '5px',
                      }),
                      input: provided => ({
                        ...provided,
                        paddingTop: '8px',
                        paddingBottom: '4.75px',
                      }),
                    }}
                    onChange={selected => {
                      fund.setInvestmentStrategyParam(
                        'impact_theme',
                        selected.value
                      )
                    }}
                    id='impact_theme'
                    options={impactThemes}
                    error={errors.impact_theme}
                  />
                  <div className='select-input-error'>
                    {errors.impact_theme}
                  </div>
                </SelectInputGroup>
              </WrapperColumn>
              <TextInput
                marginRight='30px'
                label='Target Return'
                width='40%'
                id='target_return'
                error={errors.target_return}
                onChange={(id, value) =>
                  fund.setInvestmentStrategyParam(id, value)
                }
                defaultValue={fund.investment_strategy.target_return}
              />
              <TextInput
                marginRight='30px'
                label='Fund Term'
                type='number'
                append='Years'
                width='40%'
                id='investment_years'
                error={errors.investment_years}
                onChange={(id, value) =>
                  fund.setInvestmentStrategyParam(id, value)
                }
                defaultValue={fund.investment_strategy.investment_years}
              />
              <TextInput
                prepend='$'
                append='.00'
                type='number'
                marginRight='30px'
                label='AUM *'
                width='40%'
                id='aum'
                error={errors.aum}
                onChange={(id, value) =>
                  fund.setInvestmentStrategyParam(id, value)
                }
                defaultValue={fund.investment_strategy.aum}
              />
              <TextInput
                type='number'
                marginRight='30px'
                label='Target AUM'
                width='40%'
                id='target_aum'
                error={errors.target_aum}
                onChange={(id, value) =>
                  fund.setInvestmentStrategyParam(id, value)
                }
                defaultValue={fund.investment_strategy.target_aum}
              />
            </SectionWrapper>
          </ModuleWrapper>
          <SectionWrapper wrap='nowrap' width='100%'>
            <ModuleWrapper width='48%' margin='15px 10px 15px 0px'>
              <ModuleCardTitle title='Investment Status & Stage' />
              <SectionWrapper margin='5px 0px 5px 10px' width='83%'>
                <InputLabel label='Investment Status' />
                <Select
                  styles={{
                    container: provided => ({
                      ...provided,
                      width: '100%',
                      marginBottom: '16.5px',
                    }),
                    input: provided => ({
                      ...provided,
                      paddingTop: '8px',
                      paddingBottom: '4.75px',
                    }),
                  }}
                  onChange={selected => {
                    fund.setStatus(selected.value)
                  }}
                  id='status'
                  error={errors.investment_status}
                  options={investmentStatuses}
                  defaultValue={
                    fund.status
                      ? investmentStatuses.find(i => i.value === fund.status)
                      : defaultInvestmentStatus
                  }
                />
                <InputLabel label='Investment Stage' />
                <Select
                  styles={{
                    container: provided => ({
                      ...provided,
                      width: '100%',
                      marginBottom: '16.5px',
                    }),
                    input: provided => ({
                      ...provided,
                      paddingTop: '8px',
                      paddingBottom: '4.75px',
                    }),
                  }}
                  onChange={selected => {
                    fund.setStage(selected.value)
                  }}
                  id='stage'
                  error={errors.investment_stage}
                  options={investmentStages}
                  defaultValue={
                    fund.stage
                      ? investmentStages.find(i => i.value === fund.stage)
                      : defaultInvestmentStage
                  }
                />
              </SectionWrapper>
            </ModuleWrapper>
            <ModuleWrapper width='51%'>
              <ModuleCardTitle title='Geography' />
              <SectionWrapper margin='5px 0px 5px 10px' width='83%'>
                <InputLabel label='Focus' />
                <Select
                  styles={{
                    container: provided => ({
                      ...provided,
                      width: '100%',
                      marginBottom: '16.5px',
                    }),
                    input: provided => ({
                      ...provided,
                      paddingTop: '8px',
                      paddingBottom: '4.75px',
                    }),
                  }}
                  onChange={selected => {
                    fund.setGeographyParam('focus', selected.value)
                  }}
                  id='focus'
                  options={countryCodes}
                  defaultValue={countryCodes.find(
                    i => i.value === fund.geography.focus
                  )}
                />

                <InputLabel label='Domicile' />
                <Select
                  styles={{
                    container: provided => ({
                      ...provided,
                      width: '100%',
                      marginBottom: '16.5px',
                    }),
                    input: provided => ({
                      ...provided,
                      paddingTop: '8px',
                      paddingBottom: '4.75px',
                    }),
                  }}
                  onChange={selected => {
                    fund.setGeographyParam('domicile', selected.value)
                  }}
                  id='focus'
                  options={countryCodes}
                  defaultValue={countryCodes.find(
                    i => i.value === fund.geography.domicile
                  )}
                />
              </SectionWrapper>
            </ModuleWrapper>
          </SectionWrapper>

          <ModuleWrapper width='100%'>
            <ModuleCardTitle title='Terms and Conditions' />
            <SectionWrapper
              margin='5px 0px 5px 10px'
              width='86%'
              justify='space-between'
            >
              <TextInput
                prepend='$'
                append='.00'
                type='number'
                error={errors.minimum_investment}
                marginRight='30px'
                label='Minimum Investment'
                id='minimum_investment'
                onChange={(id, value) =>
                  fund.setTermsOfAgreementParam(id, value)
                }
                defaultValue={fund.terms_of_agreement.minimum_investment}
              />
              <TextInput
                append='%'
                type='number'
                error={errors.performance_fee}
                marginRight='30px'
                label='Performance Fee'
                id='performance_fee'
                onChange={(id, value) =>
                  fund.setTermsOfAgreementParam(id, value)
                }
                defaultValue={fund.terms_of_agreement.performance_fee}
              />
              <TextInput
                append='%'
                type='number'
                error={errors.management_fee}
                marginRight='30px'
                label='Management Fee'
                id='management_fee'
                onChange={(id, value) =>
                  fund.setTermsOfAgreementParam(id, value)
                }
                defaultValue={fund.terms_of_agreement.management_fee}
              />
            </SectionWrapper>
          </ModuleWrapper>
        </fieldset>
      </form>
      {config.sub && !disableForm && (
        <SectionWrapper justify='flex-end' width='100%'>
          <Button onClick={getActionType()} width='20%' label='Save Changes' />
        </SectionWrapper>
      )}
      {!config.sub && !disableForm && <SaveButton onSave={getActionType()} />}
      {showConfirmModal && (
        <ConfirmDialog
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Are You Sure You Want To Make These Updates To Your Fund?
            </Modal.Title>
          </Modal.Header>
          <SectionWrapper justify='center' margin='20px 0px 0px 0px'>
            <Button
              margin='0px 15px 0px 0px'
              width='30%'
              label='Confirm'
              onClick={updateFund}
            />
            <Button
              width='30%'
              label='Close'
              onClick={() => setShowConfirmModal(false)}
            />
          </SectionWrapper>
        </ConfirmDialog>
      )}
    </>
  )
}

export default ConfigurationSection
