import React from 'react'

import Link from '../../../../components/styleguide/Link'
import { EMPTY_CONTENT_PLACEHOLDER } from '../../constants/page'

export const getPresManagerDB = data => {
  return data.map(elem => [
    <Link key={elem.id} href={`managers/${elem.id}`}>
      {elem.name || EMPTY_CONTENT_PLACEHOLDER}
    </Link>,
    elem.asset_class?.name || EMPTY_CONTENT_PLACEHOLDER,

    elem.strategy_aum_dollars || EMPTY_CONTENT_PLACEHOLDER,
    elem.first_impression_score || EMPTY_CONTENT_PLACEHOLDER,
    elem.initial_evaluation_score || EMPTY_CONTENT_PLACEHOLDER,
    elem.final_evaluation_score || EMPTY_CONTENT_PLACEHOLDER,
  ])
}
