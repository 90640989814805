import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { APP_COLOR_PRIMARY } from '../../../styles/colors'

const StyledLink = styled.div`
  :not(:last-child) {
    margin-right: 17px;
  }
  opacity: ${props => props.opacity};
  width: 60px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
  div {
    margin-top: 8px;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    color: #7b7b7b;
    text-transform: uppercase;
    text-align: center;
  }
  ${({ disable }) =>
    !disable &&
    `
  :hover {
    text-decoration: none;
    img {
      filter: invert(0) sepia(100%) saturate(10000%) hue-rotate(219deg)
        brightness(80%) contrast(114%);
    }
    div {
      color: ${APP_COLOR_PRIMARY};
    }
  }
`}
`

const IconLink = ({ src, text, onClick, disable, className }) => {
  const [opacityIcon, setOpacityIcon] = useState('1')

  useEffect(() => {
    if (disable) {
      setOpacityIcon('0.5')
    } else {
      setOpacityIcon('1')
    }
  }, [disable])
  return (
    <StyledLink
      {...(!disable ? { onClick: onClick } : {})}
      disable={disable}
      opacity={opacityIcon}
      className={className}
    >
      <img src={src} />
      {text && <div>{text}</div>}
    </StyledLink>
  )
}

export default IconLink
