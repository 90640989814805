import axios from 'axios'
import qs from 'query-string'

import { mapPortfoliosToState } from '../../../../mappers/portfolio_mapper'

export const loadPortfolios = (
  { setIsLoading, setPortfolios, setTotalPages },
  query
) => {
  setIsLoading?.(true)
  axios
    .get('/app/api/portfolios' + query)
    .then(res => {
      setPortfolios(mapPortfoliosToState(res.data.portfolios))
      setTotalPages(res.data.total_pages)
      setIsLoading?.(false)
    })
    .catch(err => console.error(err))
}

export const validateScore = score => {
  const f = parseFloat(score)?.toFixed(1)
  return isNaN(f) ? '—' : `${f}`
}
