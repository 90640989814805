import React from 'react'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'

const StyledText = styled(Form.Text)`
  margin-top: 6px;
  color: #979ca6;
  font-size: 12px;
  line-height: 1.2;
  font-style: italic;
`

export const BaseHelpText = ({ ...props }) => {
  return <StyledText {...props}></StyledText>
}
