import React  from 'react';
import styled from 'styled-components';
import { FONT_COLOR_PRIMARY } from '../../../../styles/colors';
import { Modal } from 'react-bootstrap';


export const HoldingHeaderWrapper = styled.div`
  display:flex;
  align-items:center;
  justify-content : space-between;
  marginTop: 1.25rem;
  marginBottom: 0.5rem;
  padding: 40px;
  padding-bottom:0;
  & > *: {
    margin-right : 10px;
  }
`

export const HoldingTitle = styled.div`
  padding-right: 20;
  max-width: 300;
  font-size: 36px;
  font-weight: bold;
  color: ${FONT_COLOR_PRIMARY};
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const HoldingTitleWrapper = styled.div`
  vertical-align: top,
  display: inline-block,
  & > *: {
    vertical-align: top
  }
`

export const CustomMetricDialog = styled(Modal)`

  .modal-content{
    width:700px;
    font-size:16px;
    margin-top:35px;
    min-height:150px
  }

  .modal-header {
    font-size: 26px;
  }

  .modal-footer {
    border-top: none;
    margin-top: 50px;
    justify-content: center;
  }
`;
