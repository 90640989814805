import { errorMessages } from "../constants";

export const validateInput = (id, value, errors) => {
   const newErrors = [...errors];
   switch(id){
    case 'email':
        if(!/^\w+([ A-Za-z0-9_@./#&!?#&+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,300})+$/.test(value) && !errors.includes(errorMessages.email)){
            newErrors.push(errorMessages.email);
        } else if (/^\w+([ A-Za-z0-9_@./#&!?#&+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,300})+$/.test(value)) {
            const index = errors.indexOf(errorMessages.email);
            index !== -1 && newErrors.splice(index, 1);
        }
        break;
   }
   return newErrors;
}
   