import styled from 'styled-components';
import { FONT_COLOR_SECONDARY } from '../../../styles/colors';
import { IMAGE_SIZE } from './ImageFileInput.constants';

export const StyledImageFileInput = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${props => props.margin || '0'};
  padding: ${props => props.padding || '0 0 18px 0'};

  input[type="file"] {
    cursor: pointer;
  }

  label {
    cursor: pointer;
    align-self: flex-start;
  }
`

export const StyledLogoPlaceholder = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${IMAGE_SIZE}px;
  height: ${IMAGE_SIZE}px;
  text-transform: uppercase;
  font-weight: 700;
  color: ${FONT_COLOR_SECONDARY};
`

export const StyledImage = styled.img`
  width: ${IMAGE_SIZE}px;
  height: ${IMAGE_SIZE}px;
  object-fit: contain;
`

export const StyledRemoveLink = styled.a`
  color: red;
  font-weight: 500;
  cursor: pointer;
  align-self: flex-start;

  &:hover {
    color: red;
    text-decoration: underline;
  }

  i {
    margin-right: 5px;
  }
`

export const ErrorMessage = styled.span`
  color: red;
`

export const WarningMessage = styled.span`
  color: orange;
`
