import React, { memo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import qs from 'query-string';

import Pagination from '../../styleguide/pagination/Pagination';
import RecordsTableContent from './components/RecordsTableContent';
import { useCallback } from 'react';
import FiltersBar from '../../styleguide/table_filters/FiltersBar';

const StyledContainer = styled.div`
    align-items: center;
`;

const RecordsTable = ({ data, columns, loading, maxPage, fetchData, "data-cy": dataCy }) => {
    const [page, setPage] = useState(1);
    const [sortField, setSortField] = useState(null);
    const [sortDir, setSortDir] = useState(null);
    const [filter, setFilter] = useState({});

    const getQueryString = () => {
        const mainQuery = `?${qs.stringify({
            page,
            sort_field: sortField,
            sort_dir: sortDir,

        })}`;
        const filterQuery = (filter != {}) ? `&${qs.stringify(filter, { arrayFormat: 'bracket' })}` : '';

        return `${mainQuery}${filterQuery}`;
    }

    /* Use Effects */
    useEffect(() => {
        if (fetchData) fetchData(getQueryString());
    }, [page, sortField, sortDir, filter]);

    /* Events */
    const onChangePage = useCallback((page) => {
        setPage(page)
    }, []);

    const onChangeSortBy = useCallback((sortBy) => {
        if (!sortBy) return;
        let fSortField = '';
        let fSortDir = '';

        if (sortBy.length > 0) {
            fSortField = sortBy[0].id;
            fSortDir = sortBy[0].desc ? 'desc' : 'asc';
        }
        setSortField(fSortField)
        setSortDir(fSortDir)
    }, [data, columns]);

    const onChangeFilter = useCallback(({ accessor, properties, isMulti }) => {
        let cFilter = { ...filter };
        if (!properties) {
            if (!accessor) return;
            delete cFilter[accessor];
        }
        else {
            let fProperties = properties;

            if (isMulti) fProperties = properties.map((v) => v.value);
            else {
                fProperties = properties.value;
            }

            cFilter[accessor] = fProperties;
        }

        setPage(1);
        setFilter(cFilter);
    }, [data, columns]);

    const onClearFilters = useCallback(() => {
        setFilter({});
    }, [data, columns]);

    return (
        <StyledContainer data-cy={dataCy}>
            <FiltersBar
                data-cy="records_table_filters_bar"
                columns={columns}
                onChange={onChangeFilter}
                onClearAll={onClearFilters}
                loading={loading}

                marginBottom={'20px'}
            />
            <RecordsTableContent
                data-cy="records_table_content"
                data={data}
                columns={columns}
                onChangeSortBy={onChangeSortBy}
                loading={loading}

                marginBottom={'20px'}
            />
            <Pagination
                data-cy="records_table_pagination"
                max={maxPage}
                selected={page}
                onChange={onChangePage}
            />
        </StyledContainer>
    );
}

export default memo(RecordsTable);