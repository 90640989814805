import React from 'react';
import SectionWrapper from '../../../components/styleguide/SectionWrapper';
import { LinkToggle } from '../styles';

const ToggleLinks = ({ onChange, toggles, dataLabel, activeTab }) => {
    return(
        <SectionWrapper data-cy={dataLabel}>
            {
                toggles.map((toggle,i) => {
                    return(
                        <LinkToggle
                            active={toggle === activeTab} 
                            onClick={() => {onChange(toggle)}}
                            data-cy={`${dataLabel}:link-toggle-${i}`}
                            key={`${dataLabel}:link-toggle-${i}`}
                        >
                            {toggle}
                        </LinkToggle>
                    )
                })
            }
        </SectionWrapper>
    )
}

export default ToggleLinks;