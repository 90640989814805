import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { getTcfdColor } from '../../utils/tcdf_colors'

const StyledList = styled.div`
  margin: 0;
  padding: 0;
  width: auto;
  display: flex;
  list-style-type: none;

  .legend-cell {
    margin: 0;
    padding: 0;
    width: 70px;
    height: auto;
    text-align: center;
  }
  span {
    font-size: 12px;
    line-height: 1.2;
    color: #6c757d;
  }
`
const ColorBlock = styled.div`
  margin: 0 0 2px 0;
  width: 100%;
  height: 15px;
  background-color: ${props => props.bgColor || '#ffffff'};
`

export const TcfdLegend = ({ style }) => {
  return (
    <StyledList style={style}>
      <li className='legend-cell'>
        <ColorBlock bgColor={getTcfdColor(0)}></ColorBlock>
        <span>0</span>
      </li>
      <li className='legend-cell'>
        <ColorBlock bgColor={getTcfdColor(1)}></ColorBlock>
        <span>1</span>
      </li>
      <li className='legend-cell'>
        <ColorBlock bgColor={getTcfdColor(2)}></ColorBlock>
        <span>2</span>
      </li>
      <li className='legend-cell'>
        <ColorBlock bgColor={getTcfdColor(3)}></ColorBlock>
        <span>3</span>
      </li>
      <li className='legend-cell'>
        <ColorBlock bgColor={getTcfdColor(4)}></ColorBlock>
        <span>4</span>
      </li>
      <li className='legend-cell'>
        <ColorBlock bgColor={getTcfdColor(null)}></ColorBlock>
        <span>No Score</span>
      </li>
    </StyledList>
  )
}

TcfdLegend.propTypes = {
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}
