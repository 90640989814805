import React, { useRef, useState } from 'react'
import { Overlay, Tooltip } from 'react-bootstrap'
import styled from 'styled-components'

import { SDGDescriptions } from '../../constants'
import { generateArrayOfNumbers } from '../../helpers/objects_and_arrays'
import { SelectLabel } from '../../modules/research_center/styles/styles'
import {
  ExplainerLink,
  LinkText,
} from '../../yb_components/styleguide/links/Link.styles'
import WrapperColumn from '../styleguide/WrapperColumn'
import CardTitle from './CardTitle'
import TextSection from './TextSection'

const StyledContainer = styled.div`
  ${props => props.padding && `padding: ${props.padding};`}
  width: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    & > img {
      width: 320;
      mix-blend-mode: multiply;
    }
    & .chip {
      float: right;
      margin-top: 25;
    }
    .sdg-label {
      transition: 0.2s;
      transition-timing-function: ease-in-out;
      margin: 0px 0px 5px 2px;
    }
    ${props =>
      props.sdg === 0 &&
      props.hover &&
      `
        .sdg-label{
          transform: translate(0px, -15px);
        }`}
    .select-all-container {
      transition: 0.2s;
      transition-timing-function: ease-in-out;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
    ${props =>
      props.sdg === 15 &&
      props.hover &&
      `
        .select-all-container{
          transform: translate(0px, -15px);
        }`}
    ${props =>
      props.sdg === 15 &&
      props.hover &&
      `
        .select-all-container{
          transform: translate(0px, -15px);
        }`}
  }
  .tool-tip-header {
    color: #2e384d;
    text-align: center;
  }
  .sdg {
    opacity: 1;
    margin-top: 5;
    & img {
      width: ${100 / 16 + '%'};
      filter: grayscale(100%);
      opacity: 0.3;
      cursor: pointer;
      transition: 0.2s;
      transition-timing-function: ease-in-out;
      &.-selected {
        filter: none;
        opacity: 1;
        z-index: 1;
        position: relative;
      }
      ${props =>
        props.hover &&
        `
        &:hover {
          transform: scale(1.4);
          z-index: 2;
          position: relative;
          opacity: 1;
        }
      `}
    }
  }
`

const SDGFilter = ({
  padding,
  selected,
  setSelected,
  sdgCount = 16,
  allowModal = false,
  showLogo = true,
  allowHover,
  allowSelectAll,
}) => {
  const [hoveredSDG, setHoveredSDG] = useState(-1)
  const target = useRef(null)
  const initialState = generateArrayOfNumbers(sdgCount)

  const onIconClick = e => {
    const { id } = e.target
    let newSelected
    if (selected.length == sdgCount) {
      newSelected = selected.filter(s => s == id)
      setSelected(newSelected)
    } else {
      newSelected = selected.filter(s => s != id)
      if (newSelected.length == selected.length) {
        newSelected.push(+id)
      }
      newSelected.length === 0
        ? setSelected(initialState)
        : setSelected(newSelected)
    }
  }

  const onSelectAll = () => {
    setSelected(initialState)
  }

  const hoverStart = (sdg, e) => {
    target.current = e.target
    setHoveredSDG(sdg)
  }

  const hoverEnd = () => {
    setHoveredSDG(-1)
  }

  const renderSDGs = () => {
    const sdgs = []

    for (let i = 0; i < sdgCount; i++) {
      sdgs.push(
        <React.Fragment key={`sdg-img-${i}`}>
          <img
            onMouseEnter={e => hoverStart(i, e)}
            onMouseLeave={() => hoverEnd()}
            id={i + 1}
            className={selected.indexOf(i + 1) > -1 ? '-selected' : ''}
            src={`/sdg_icons/${i + 1}.png`}
            onClick={onIconClick}
          />
          <Overlay
            key={`sdg-overlay-wrapper-${i}`}
            target={target.current}
            show={hoveredSDG > -1 && allowModal}
            placement='top-start'
          >
            {({ placement, ...props }) => (
              <Tooltip
                key={`sdg-tooltip-${i}`}
                placement={placement}
                id={`sdg-${i + 1}`}
                {...props}
              >
                <div
                  key={`sdg-img-${i}`}
                  style={{
                    backgroundColor: 'white',
                    border: '1px solid rgba(0, 0, 0, 0.05)',
                    borderRadius: '6px',
                  }}
                >
                  <WrapperColumn styles={{ margin: '10px 10px 10px 15px' }}>
                    <CardTitle
                      className='tool-tip-header'
                      title={`${
                        hoveredSDG > -1 && SDGDescriptions[hoveredSDG + 1].title
                      }: ${
                        hoveredSDG > -1 &&
                        SDGDescriptions[hoveredSDG + 1].header
                      }`}
                    />
                    <TextSection fontSize='14px' align='left'>
                      {hoveredSDG > -1 &&
                        SDGDescriptions[hoveredSDG + 1].description}
                    </TextSection>
                  </WrapperColumn>
                </div>
              </Tooltip>
            )}
          </Overlay>
        </React.Fragment>
      )
    }
    return sdgs
  }

  return (
    <StyledContainer padding={padding} hover={allowHover} sdg={hoveredSDG}>
      <div className='header'>
        {showLogo ? (
          <img style={{ marginBottom: '15px' }} src='/ico_goals.svg' />
        ) : (
          <SelectLabel className='sdg-label'>SDGS</SelectLabel>
        )}
        {allowSelectAll && selected.length !== sdgCount && (
          <div className='select-all-container'>
            <ExplainerLink onClick={onSelectAll} fontStyle={'initial'}>
              <LinkText>Select All</LinkText>
            </ExplainerLink>
          </div>
        )}
      </div>
      <div className='sdg' ref={target}>
        {renderSDGs()}
      </div>
    </StyledContainer>
  )
}

export default SDGFilter
