import { RESEARCH_CENTER_COMPANY_TYPES } from '../constants/enums'
import {
  carbonHeaders,
  esgHeaders,
  MANAGER_DB_HEADERS,
  sdgHeaders,
  summaryHeaders,
} from '../constants/header-constants'

export const renderHeaders = (companyType, dataChoice) => {
  if (companyType === RESEARCH_CENTER_COMPANY_TYPES.PRIVATE) {
    return MANAGER_DB_HEADERS
  } else {
    switch (dataChoice) {
      case 'SUMMARY':
        return summaryHeaders
      case 'ESG':
        return esgHeaders
      case 'SDG':
        return sdgHeaders
      case 'CARBON':
        return carbonHeaders
      default:
        return summaryHeaders
    }
  }
}
