import React, { useState, useEffect } from 'react';
import { SDG_COLORS } from '../../../styles/colors';
import SDGIcon from '../../../components/styleguide/SDGIcon';
import withErrorBoundary from '../../../hocs/withErrorBoundary';
import * as d3 from 'd3';
import XAxisLabels from '../../../components/charts/components/XAxisLabels';
import YAxisLine from '../../../components/charts/components/YAxisLine';


const LINE_COLORS = {
  0: '#C2F4CD',
  1: '#C2F4CD',
  4: 'grey',
  7: '#FED4B2',
  8: '#FED4B2',
  default: '#E4E6E5'
};

const SDGS = {
  1: "No Poverty",
  2: "Zero Hunger",
  3: "Good Health",
  4: "Quality Education",
  5: "Gender Equality",
  6: "Clean Water",
  7: "Clean Energy",
  8: "Good Jobs",
  9: "Good Infrastructure",
  10: "Reduced Inequality",
  11: "Sustainable Communities",
  12: "Responsible Consumption",
  13: "Climate Action",
  14: "Life Below Water",
  15: "Life On Land",
  16: "Strong Institutions",
  17: "Partnership For The Goals",
}

const SDGExposureCard = ({ data, benchmarkData, width, title, benchmarkName }) => {


  const sdgPadding = 0;

  const chartHeight = 350;
  const [chartWidth, setChartWidth] = useState(width);

  const onResize = () => {
    const chartEl = document.getElementById("sdg-chart");

    if(!chartEl) {
      console.error(`Chart with id '${chartId}' is null`);
      return;
    }

    setChartWidth(chartEl.clientWidth);
  }

  useEffect(() => {
    onResize();
  })

  useEffect(() => {
    window.addEventListener("resize", () => onResize());
    return () => window.removeEventListener('resize', onResize());
  });

  const length = data && Object.keys(data).length;

  const x_scale = d3.scaleLinear()
  .domain([-100, 100])
  .range([225, chartWidth - 25])

  const x_scale_bubbles = d3.scaleLinear()
  .domain([100, -100])
  .range([225, chartWidth - 25])

  const y_scale = d3.scaleLinear()
  .domain([0, length])
  .range([chartHeight - 50, 0]);

  const labelHeight = 40;

  const finalLabel = (label, ypos, post) => {
    return [
      <text
        key={`${ypos}-icon-label`}
        className="SDG-Exposure-Labels-side-pdf"
        x={54}
        y={ypos + 10.5}
      >
        {label[0]}{post}
      </text>,

      <text
        key={`${ypos}-icon-label-second`}
        className="SDG-Exposure-Labels-side-pdf"
        x={54}
        y={ypos + 15.5}
      >
        {label[1]}
      </text>
    ]
  }

  const SDGTextLabel = (label, ypos) => {

    if(label.includes("And")){
      label = label.split("And");
      return finalLabel(label,ypos, "And");
    }

    label = label.split(" ");
    return finalLabel(label,ypos, "");
  }


  const renderIcons = () => {
    if(!chartWidth) return null;

    return data && Object.keys(data).reverse().map((sdg, i) => {
      const size = data && Object.keys(data).length;
      let sdg_number = sdg.split("_")[1];
      let ypos = y_scale(i + 0.7) + 10;
      let labelPos = size > 12 ? ypos - 0.5 : ypos;
      let height = 20;

      return (
        <React.Fragment key={`${i}-icon-data`}>
          <SDGIcon key={`${i}-icon-value`} sdg={sdg_number} width={15} height={height} x={38} y={ypos} />
          {SDGTextLabel( SDGS[sdg_number], labelPos )}
        </React.Fragment>
      )
    })

  }


  const renderLines = () => {
    let classes;
    return [-100, -75, -50, -25, 0, 25, 50, 75, 100].map((n, i) => {
      if(n === -100 || n === -50){
        classes = "SDG-Line-Chart-Grid-Regular-Green sdg-line-pdf"
      } else if(n === 50 || n === 100){
        classes = "SDG-Line-Chart-Grid-Regular-Beige sdg-line-pdf"
      } else if(n === 0){
        classes = "SDG-Line-Chart-Grid-Regular-Middle sdg-line-pdf"
      } else {
        classes = "SDG-Line-Chart-Grid-Regular sdg-line-pdf"
      }

      return (<YAxisLine
      key={`${i}-y-axis`}
      classes={classes}
      xstart={x_scale(n) + 50}
      ystart={12.5}
      yend={chartHeight - 30}
      />)
    })

  }

  const renderBubbles = () => {
    let start;
    let end;
    let ypos;
    let offset;
    const size = data && Object.keys(data).length;

    return data && Object.keys(data).reverse().map((sdg, i) => {
      offset = 20;
      ypos = y_scale(i + 0.7) + offset;

      return <circle
      key={`${i}-bubble`}
      cx={x_scale(data[sdg]*(-100)) + 50}
      cy={ypos}
      r={3}
      fill={SDG_COLORS[sdg.split("_")[1]]}
      />
    })
  }

  const renderBenchmark = () => {
    let start;
    let end;
    let ypos;

    return Object.keys(data).reverse().map((sdg, i) => {
      start = y_scale(i + 0.7) + 15;
      end = (start + 10); 
      ypos = (start + end) / 2;
      return (
        <YAxisLine
          key={`${i}-y-axis`}
          xstart={x_scale(benchmarkData[sdg]*(-100)) + 50}
          ystart={start}
          yend={end}
          style={{stroke: SDG_COLORS[sdg.split("_")[1]], strokeWidth: "2px"}}
        />)
    })
  }

  const labelFunc = (i) => {
    const values = [-100, -75, -50, -25, 0, 25, 50, 75, 100];
    const offset = 48;
    const zeroOffset = values[i] === 0 ? 2 : 0
    return x_scale(values[i] - offset - zeroOffset );
  }


  return (
    <section className="SDG-Card-Wrapper-pdf">
      <span className="SDG-Exposure-Title">{title}</span>

      <div className="sdg-exposure-body-pdf" style={{marginTop:'20px'}}>
        <div id="sdg-chart-pdf">
          <svg className="SDG-Exposure-Chart-SVG-pdf">
            <XAxisLabels
            xscale={labelFunc}
            ystart={10}
            values={['-100%', '-75%', '-50%', '-25%', '0%', '+25%', '+50%', '+75%', '+100%'].reverse()}
            linedist={0}
            offset={0}
            classes="SDG-Exposure-Labels-pdf"
            />

            <XAxisLabels
            xscale={labelFunc}
            ystart={chartHeight - 25}
            values={['-100%', '-75%', '-50%', '-25%', '0%', '+25%', '+50%', '+75%', '+100%'].reverse()}
            linedist={0}
            offset={0}
            classes="SDG-Exposure-Labels-pdf"
            />
            <rect fill="#F3FFF4" x={x_scale(-104)} y={12.5} width={45} height={chartHeight - 43} />
            <rect fill="#FFF8F1" x={x_scale(100) + 50} y={12.5} width={24*2}  height={chartHeight - 43}/>
            {renderLines()}
            {renderBubbles()}
            {benchmarkData && renderBenchmark()}
            {renderIcons()}
          </svg>
        </div>
        {benchmarkData && (
          <div className="legend-container">
            <div className="SDG-Exposure-Legend-Title">LEGEND</div>
            <div className="legend-labels">
              <div className="float-left legend-bubble" />
              <span className="float-left">Portfolio</span>
              
              <span className="float-right">{benchmarkName}</span>
              <div className="float-right legend-line" />
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

SDGExposureCard.defaultProps = {
  width: 100
};

export default withErrorBoundary(SDGExposureCard);