import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { emptyScorePlaceholder } from '../modules/instrument/components/peers/constants'

dayjs.extend(utc)

export const timestamp = dayjs().utc().format('YYYYMMDDHHmmss')

export const displayValueOrPlaceholder = (
  value,
  decimals = false,
  placeholder = emptyScorePlaceholder
) => {
  if (isNaN(value)) return placeholder
  else if (decimals) return Math.round(value)
  return value
}

export const formatTextCell = value => (value !== null ? value : '—')

export const scrollToComponent = (id, distance) => {
  if (
    window.location.href.substring(
      window.location.href.lastIndexOf('#') + 1
    ) === id
  ) {
    window.scrollTo(0, distance)
  }
}
