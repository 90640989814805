import _ from 'lodash'
import propTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import ReactGA from 'react-ga'
import injectSheet from 'react-jss'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Card from '../../components/styleguide/Card'
import TypeToggle from '../../components/styleguide/TypeToggle'
import {
  generateArrayOfNumbers,
  isObjEqual,
} from '../../helpers/objects_and_arrays'
import { BreadcrumbNav } from '../../yb_components/styleguide/breadcrumbs/BreadcrumbNav'
import FilledButton from '../../yb_components/styleguide/buttons/filled_button/FilledButton'
import PageContainer from '../../yb_components/styleguide/containers/PageContainer'
import FlatSearchBar from '../../yb_components/styleguide/inputs/FlatSearchBar'
import {
  ExplainerLink,
  LinkText,
} from '../../yb_components/styleguide/links/Link.styles'
import Pagination from '../../yb_components/styleguide/pagination/Pagination'
import FlatTable from '../../yb_components/tables/flat_table/FlatTable'
import { buildQueryString } from '../../yb_helpers/queries'
import useTimerDebouncing from '../../yb_hooks/useTimerDebouncing'
import FilterShow from './components/FilterShow'
import QuickSearches from './components/QuickSearches'
import {
  FILTER_TYPES,
  RESEARCH_CENTER_COMPANY_TYPES,
  RESEARCH_CENTER_DATA_CHOICES,
} from './constants/enums'
import {
  COMPANY_TYPES,
  QuickSearchesList,
  QuickSearchesValues,
} from './constants/form-constants'
import { BREADCRUMB_LINKS } from './constants/page'
import {
  changeFilters,
  makeFilterQueryObject,
  makeSingleValue,
} from './helpers/filter-helpers'
import { getPresInstruments } from './helpers/mappers/instrument-mapper'
import {
  loadManagerDBFunds,
  loadResearch,
  loadUtilities,
} from './helpers/requests'
import { renderHeaders } from './helpers/table-helpers'
import {
  style,
  StyledButtonContainer,
  StyledH1,
  StyledHeaderSection,
  StyledPaginatorContainer,
  toggleContainerLook,
} from './styles/styles'

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_KEY)

const CustomFlatTable = styled(FlatTable)`
  --min-height: 590px;
`

/* global react_app_organization */
const ResearchCenter = () => {
  /* Location Hooks */
  const history = useHistory()
  const location = useLocation()
  const { state } = location
  const decodedSearchQuery = decodeURIComponent(
    state?.searchQuery ? state?.searchQuery : ''
  )

  /* State Hooks */
  const [isLoading, setIsLoading] = useState(true)
  const [presIndustries, setPresIndustries] = useState([])
  const [filtersOptions, setFiltersOptions] = useState({
    industries: [],
    marketCap: [],
    revenues: [],
    sectors: [],
    companyTypes: [],
    positiveBadges: [],
    negativeBadges: [],
    instrumentTypes: [],
    countries: [],
    sdgs: [],
    fundAssetClasses: [],
    fundSectors: [],
  })

  const [instruments, setInstruments] = useState([])
  const [presInstruments, setPresInstruments] = useState([])
  const [dataChoice, setDataChoice] = useState(
    RESEARCH_CENTER_DATA_CHOICES.SUMMARY
  )

  const [totalPages, setTotalPages] = useState(100)
  const [currentPage, setCurrentPage] = useState(1)
  const [sort, setSort] = useState({ field: null, dir: null })

  const initialFilterState = {
    MARKET_CAP: [],
    BUSINESS_SECTOR: [],
    INDUSTRY_GROUP: [],
    REVENUE: [],
    SDG: generateArrayOfNumbers(16),
    POSITIVE_BADGE: [],
    NEGATIVE_BADGE: [],
    INSTRUMENT_TYPE: [],
    FUND_ASSET_CLASS: [],
    FUND_SECTOR: [],
    COUNTRY_OF_HQ: [],
    COMPANY_TYPE: [_.first(COMPANY_TYPES)],
    SEARCH_QUERY: decodedSearchQuery ? [decodedSearchQuery] : [],
    E_SCORE: [0, 100],
    S_SCORE: [0, 100],
    G_SCORE: [0, 100],
    TOTAL_SCORE: [0, 100],
  }

  const [filters, setFilters] = useState({ ...initialFilterState })

  const [companyType, setCompanyType] = useState([_.first(COMPANY_TYPES)])

  /* Effect Hooks */
  useEffect(() => {
    if (decodedSearchQuery) {
      let newState = { ...state }
      delete newState.searchQuery
      history.replace({ ...location, state: newState })
    }
  }, [])

  useEffect(() => {
    loadUtilities({
      setFiltersOptions,
      setPresIndustries,
      query: [
        'market_caps',
        'industries',
        'sectors',
        'positive_badges',
        'negative_badges',
        'instrument_types',
        'sdgs',
        'country_of_hq',
        'funds_asset_classes',
        'funds_sectors',
      ],
    })
  }, [])

  const memoCompanyType = useMemo(
    () => _.first(companyType)?.value || RESEARCH_CENTER_COMPANY_TYPES.PUBLIC,
    [companyType]
  )

  const memoDisplayButton = useMemo(
    () => isObjEqual(filters, initialFilterState) === false,
    [filters, companyType]
  )

  useEffect(() => {
    if (memoCompanyType === RESEARCH_CENTER_COMPANY_TYPES.PRIVATE) {
      loadManagerDBFunds({
        params: {
          assetClassId: filters.FUND_ASSET_CLASS?.map(elem => elem?.id),
          secondaryThemeId: filters.FUND_SECTOR?.map(elem => elem?.id),
          sdg: filters.SDG,
          query: _.first(filters?.SEARCH_QUERY),

          page: currentPage,
          sorting: { order: sort?.dir, field: sort?.field },
          limit: 10,
        },
        setFunds: setPresInstruments,
        setTotalPages,
        setIsLoading,
      })
    } else {
      loadResearch(
        {
          setInstruments,
          setPresInstruments,
          setDataChoice,
          setTotalPages,
          setCurrentPage,
          setIsLoading,
        },
        buildQueryString({
          page: currentPage,
          filters: makeFilterQueryObject(filters),
          searchQuery: _.first(filters?.SEARCH_QUERY),
          sort,
        }),
        dataChoice
      )
    }
  }, [currentPage, filters, sort, companyType])

  useEffect(() => {
    setPresInstruments(getPresInstruments(dataChoice, instruments))
  }, [dataChoice, instruments])

  const doSearch = val => {
    let newFilters = { ...filters }
    const value = Array.isArray(val) ? val : [val]
    setFilters(changeFilters(value, FILTER_TYPES.SEARCH_QUERY, newFilters))
  }

  const [searchQuery, setSearchQuery] = useTimerDebouncing(
    filters.SEARCH_QUERY,
    doSearch
  )

  /* Events */
  const filterIndustry = val => {
    if (val === null) val = []
    const newIndustries = _.first(val)
      ? filtersOptions?.industries.filter(i => i.bus_sec == _.first(val).id)
      : []
    setPresIndustries(newIndustries)
  }

  const selectChangeBusiness = val => {
    if (val === null) val = []

    val = makeSingleValue(val, FILTER_TYPES.BUSINESS_SECTOR, filters)
    filterIndustry(val)
    let newFilters = { ...filters }
    newFilters[FILTER_TYPES.INDUSTRY_GROUP] = []
    setFilters(changeFilters(val, FILTER_TYPES.BUSINESS_SECTOR, newFilters))
  }

  const clearValues = () => {
    setSearchQuery([])
    setFilters(changeFilters([], 'clear-all', initialFilterState))
  }

  const selectChange = (val, type, prevState) => {
    if (val === null) val = []

    if (type == FILTER_TYPES.COMPANY_TYPE) {
      val = makeSingleValue(val, type, filters)
      if (val?.length <= 0) return
      setSort({ field: null, dir: null })
      setFilters({ ...initialFilterState, SEARCH_QUERY: [] })
      setCompanyType(val)
      return
    }

    let newFilters = prevState ? { ...prevState } : { ...filters }
    setFilters(changeFilters(val, type, newFilters))
  }

  const selectMultipleChange = (arr, prevState) => {
    let newFilters = prevState ? { ...prevState } : { ...filters }
    arr.map(obj => {
      if (obj.val === null) obj.val = []

      if (obj.type == FILTER_TYPES.COMPANY_TYPE) {
        obj.val = makeSingleValue(obj.val, obj.type, filters)
        if (obj.val?.length <= 0) return
        setSort({ field: null, dir: null })
        setFilters({ ...filters, SEARCH_QUERY: [] })
        setCompanyType(obj.val)
        return
      }
      newFilters = changeFilters(obj.val, obj.type, newFilters)
    })
    setFilters(newFilters)
  }

  const quickSearchClickHandler = search => {
    ReactGA.event({
      category: 'Research Center',
      action: 'Quick Search',
      label: search,
    })
    switch (search) {
      case 'Mutual Funds / ETFs':
        selectChange(
          QuickSearchesValues.mutualFunds.filter.instrumentType,
          FILTER_TYPES.INSTRUMENT_TYPE,
          initialFilterState
        )
        break
      case 'Top 5 Capital Markets':
        selectChange(
          QuickSearchesValues.topFiveCapitalMarkets.filter.countryType,
          FILTER_TYPES.COUNTRY_OF_HQ,
          initialFilterState
        )
        break
      case 'Bonds':
        selectChange(
          QuickSearchesValues.Bonds.filter.instrumentType,
          FILTER_TYPES.INSTRUMENT_TYPE,
          initialFilterState
        )
        break
      case 'High ESG Score Mega Caps':
        selectMultipleChange(
          [
            {
              val: QuickSearchesValues.HighEsgScore.filter.totalEsg,
              type: FILTER_TYPES.TOTAL_SCORE,
            },
            {
              val: QuickSearchesValues.HighEsgScore.filter.marketCap,
              type: FILTER_TYPES.MARKET_CAP,
            },
          ],
          initialFilterState
        )
        break
      default:
        break
    }
  }

  const onDataChoiceChange = value => {
    if (value !== dataChoice) {
      setDataChoice(value)
    }
  }

  const getExportCsvQueryString = () => {
    const queryString = buildQueryString({
      page: currentPage,
      filters: makeFilterQueryObject(filters),
      searchQuery: _.first(filters?.SEARCH_QUERY),
      sort,
    })
    const queryBase = '/app/api/research_center/csv'
    return queryBase + queryString
  }

  const onNavigate = url => window.location.assign(url)

  const formatData = tableBody => {
    if (dataChoice === 'ESG') {
      const tableBodyCopy = tableBody.map(array => {
        return array.map((item, index) => {
          if (index > 2 && !isNaN(item)) {
            return Math.round(Number(item))
          } else {
            return item
          }
        })
      })
      return tableBodyCopy
    } else if (dataChoice === 'SDG') {
      const tableBodyCopy = tableBody.map(array => {
        return array.map((item, index) => {
          if (index > 0 && !isNaN(item)) {
            return Math.round(Number(item) * 100) / 100
          } else {
            return item
          }
        })
      })
      return tableBodyCopy
    } else {
      return tableBody
    }
  }

  /* Render */
  return (
    <PageContainer>
      <BreadcrumbNav links={BREADCRUMB_LINKS} />
      <Card padding={'20px 10px'} margin={'20px 0 20px 0'}>
        <StyledHeaderSection margin={'0px 0px 15px 0px'} padding={'0px 10px'}>
          <StyledH1>Research Center</StyledH1>
          <div>
            <StyledButtonContainer>
              <FilledButton
                disabled={
                  memoCompanyType === RESEARCH_CENTER_COMPANY_TYPES.PRIVATE
                }
                label={'Export CSV'}
                onClick={() => onNavigate(getExportCsvQueryString())}
              />
            </StyledButtonContainer>
          </div>
        </StyledHeaderSection>
        <StyledHeaderSection margin={'0px 0px 5px 0px'}>
          <QuickSearches
            onClick={quickSearchClickHandler}
            searchesList={QuickSearchesList}
            {...(companyType[0].value === 'private'
              ? { customStyle: 'visibility: hidden;' }
              : {})}
          />

          {memoDisplayButton && (
            <ExplainerLink
              fontSize='12px'
              padding='0px'
              margin='0 10px 0 0'
              onClick={clearValues}
              fontStyle={'initial'}
            >
              <LinkText>Clear All Filters</LinkText>
            </ExplainerLink>
          )}
        </StyledHeaderSection>
        <FlatSearchBar
          value={searchQuery}
          onChange={setSearchQuery}
          placeholder='Search for...'
          padding={'0px 10px'}
          margin={'0px 0 25px 0'}
        />
        <FilterShow
          canAccessManagerDb={
            react_app_organization?.organization?.can_access_manager_db
          }
          selectChange={selectChange}
          selectChangeBusiness={selectChangeBusiness}
          filtersOptions={filtersOptions}
          filters={filters}
          industry={presIndustries}
          companyType={companyType}
        />
      </Card>

      <Card padding={20}>
        <TypeToggle
          companyType={memoCompanyType}
          toggleContainerLook={toggleContainerLook}
          onDataChoiceChange={onDataChoiceChange}
        />
        <CustomFlatTable
          onSort={(field, direction) =>
            setSort({ field: field, dir: direction })
          }
          sort={sort}
          headers={renderHeaders(memoCompanyType, dataChoice)}
          body={formatData(presInstruments)}
          isLoading={isLoading}
          noDataText={
            <>
              <p>No instruments were found for your search query.</p>
              <p>Please try again.</p>
            </>
          }
        />
        <StyledPaginatorContainer>
          <Pagination
            max={totalPages}
            selected={currentPage}
            onChange={value => setCurrentPage(value)}
          />
        </StyledPaginatorContainer>
      </Card>
    </PageContainer>
  )
}

ResearchCenter.defaultProps = {
  classes: {},
}

ResearchCenter.propTypes = {
  classes: propTypes.object.isRequired,
}

export default injectSheet(style)(ResearchCenter)
