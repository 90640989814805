import React from 'react';
import SectionWrapper from '../../../components/styleguide/SectionWrapper';
import Button from '../../../components/styleguide/Button';

const SaveButton = ({ beforeChildren, onSave, afterChildren, styles = {} }) => {
    return (
        <SectionWrapper justify={styles.justify} width={styles.width}>
            {beforeChildren}
            <Button width="20%" label="Save & Continue" onClick={onSave}/>
            {afterChildren}
        </SectionWrapper>
    )
};

export default SaveButton;