import React from 'react';
import { comparisonsTableColumnCount } from '../../../constants';
import { StyledAddLink, StyledReplaceLink } from './HeaderRow.styles';

const HeaderRow = ({ portfolios, openReplaceModal, isPdf=false }) => {
  const renderNamesOrLinks = () => {
    const headers = [ <th key={'header-name-0'} /> ]
    const addLink =
      <StyledAddLink onClick={ openReplaceModal }>
        Add Portfolio or Benchmark
      </StyledAddLink>
    const blankColumn = isPdf ? '—' : addLink

    for (let i = 0; i < comparisonsTableColumnCount; i++) {
      headers.push( <th key={`header-name-${i + 1}`} >{ portfolios[i] ? portfolios[i]?.name : blankColumn }</th> )
    }

    return headers
  }

  const renderReplaceLinks = () => {
    const links = [ <th key={'header-link-0'}/> ]
    const replaceLink =
      <StyledReplaceLink onClick={ openReplaceModal }>
        (Replace Portfolio)
      </StyledReplaceLink>

    for (let i = 0; i < comparisonsTableColumnCount; i++) {
      links.push( <th key={`header-link-${i + 1}`}>{ portfolios[i] && replaceLink }</th> )
    }

    return links
  }

  return (
    <thead>
      <tr>
        { renderNamesOrLinks() }
      </tr>
      { !isPdf && (
        <tr>
          { renderReplaceLinks() }
        </tr>
      )}
    </thead>
  )
}

export default HeaderRow
