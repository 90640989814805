import React from 'react'
import styled from 'styled-components'

import {
  APP_COLOR_PRIMARY,
  FONT_COLOR_SECONDARY,
} from '../../../../../../styles/colors'
import { HtmlFileIcon } from '../../icons/HtmlFileIcon'
import { PdfFileIcon } from '../../icons/PdfFileIcon'
import { WordFileIcon } from '../../icons/WordFileIcon'

const StyledDiv = styled.div`
  header {
    padding: 2px 10px;
    height: 25px;
    display: flex;
    align-items: center;
    background-color: ${FONT_COLOR_SECONDARY};

    h4 {
      margin: 0;
      color: #fff;
      font-size: 15px;
      line-height: 1.1;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .description {
    font-size: 12px;
    line-height: 1.2;
    color: ${FONT_COLOR_SECONDARY};
  }

  .download-link {
    color: ${APP_COLOR_PRIMARY};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 12px;

    &:not(:last-child) {
      margin-right: 45px;
    }

    svg {
      width: 60px;
      height: 45px;
      margin-bottom: 10px;
    }
  }

  .downloads-container {
    display: flex;
    justify-content: space-around;
  }

  .buttons-container {
    display: flex;
  }
`

const downloads = [
  { name: `Microsoft Word`, icon: <WordFileIcon />, type: 'word_file' },
  { name: 'PDF', icon: <PdfFileIcon />, type: 'pdf_file' },
  { name: 'HTML', icon: <HtmlFileIcon />, type: 'html_file' },
]

export const PaiDownloadsCard = ({
  tableName,
  description,
  portfolioId,
  snapshotId,
}) => {
  return (
    <StyledDiv>
      <header>
        <h4>{tableName}</h4>
      </header>
      <div style={{ padding: '10px' }}>
        <p className='description'>{description}</p>
        <div className='downloads-container'>
          <div className='buttons-container'>
            {downloads.map(download => (
              <a
                key={download.type}
                download
                href={`/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/pai/indicators/${download.type}?table=${tableName}`}
                className='download-link'
              >
                {download.icon}
                {download.name}
              </a>
            ))}
          </div>
        </div>
      </div>
    </StyledDiv>
  )
}
