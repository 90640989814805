import React from 'react'
import injectSheet from 'react-jss'

import Button from '../../../components/styleguide/Button'
import ExportButton from '../../../components/styleguide/ExportButton'
import ExportReportDialog from '../../../components/styleguide/ExportReportDialog'
import InstrumentTitle from '../../../components/styleguide/InstrumentTitle'
import SDGAlignment from '../../../components/styleguide/SDGAlignment'

const InstrumentHeader = ({
  classes,
  instrument,
  onExport,
  riskAssessmentLink = false,
  showExport,
  setShowExport,
}) => {
  /* global react_app_organization */

  return (
    <div className={classes.container}>
      <InstrumentTitle
        className='instrument-title'
        name={instrument.name}
        isin={instrument.isin}
      />
      <SDGAlignment
        className='portfolio-sdgs'
        horizontal
        sdgs={instrument.sdgs}
      />
      <div className='btns-container'>
        <ExportButton
          onClick={() => setShowExport(true)}
          fileType='PDF'
          className='export-btn'
        />
        {react_app_organization.organization.can_access_active_mgmt &&
          riskAssessmentLink && (
            <Button
              onClick={() => window.location.assign(riskAssessmentLink)}
              width={240}
              label='ENGAGEMENT PORTAL'
            />
          )}
      </div>
      <ExportReportDialog
        displayed={showExport}
        name={instrument.name}
        setDisplayed={setShowExport}
        exportReport={onExport}
      />
    </div>
  )
}

const style = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1.25rem',
    marginBottom: '1.25rem',
    padding: 0,
    paddingBottom: 0,
    '& > *': {
      marginRight: '10px',
    },
    '& .instrument-title': {
      paddingRight: 20,
      maxWidth: '400px',
    },
    '& .btns-container': {
      minWidth: 190,
      float: 'right',
      marginLeft: '10px',
      '& .crud-btn': {
        width: 150,
        height: 40,
      },

      '& :not(:first-child)': {
        marginLeft: 15,
      },
    },
  },
}

export default injectSheet(style)(InstrumentHeader)
