import React from 'react';

const PortfolioCreatedEvent = ({ event }) => {
    return (
        <>
      <span className="event-user-name">
        {event.context.full_name}
      </span> uploaded new portfolio {event.targetExists ?
            <a href={`/app/portfolios/${event.data.portfolio_id}/snapshots/${event.data.snapshot_id}`}>
                {event.data.portfolio_name}
            </a> :
            <span className="event-user-name">{event.data.portfolio_name}</span>
        }.
            <span className="event-date">{event.formattedTimestamp}</span>
        </>
    );
};

export default PortfolioCreatedEvent;
