//LIBRARIES
import React, { useState, useEffect } from 'react';

//REPOSITORY
import { getFundForm, postCustomReportFormSubmissions, postCustomReportFormNotes, getHoldingForm } from '../../../../../repositories/funds_repository';

//COMPONENTS
import SectionWrapper from '../../../../../components/styleguide/SectionWrapper';
import TextSection from '../../../../../components/styleguide/TextSection';

import CustomReportFormSubmissionTable
    from "../../../../../components/custom_report/form/submission/CustomReportFormSubmissionTable";

const IFCPrincipleCreate = ({ config, target, changeFrameworkToShow, dataLabel, targetType }) => {
    const [form, setForm] = useState(null);

    useEffect(() => {
        if(target && target.id){
            if(targetType === "fund"){
                getFundForm({ fundID: target.id, formKey: "ifc_principle" })
                .then((response) => {
                    setForm(response.data);
                })
                .catch((error) => {
                    console.log(error);
                })
            } else {
                getHoldingForm({ fundID: target.fundId, holdingID: target.id, formKey: "ifc_principle"  })
                .then((response) => {
                    setForm(response.data);
                })
                .catch((error) => {
                    console.log(error);
                })
            }
        }
    } , [0]);

    const onSubmit = (submission) => {
        const formID = form.id;
        postCustomReportFormSubmissions({ formID, submission })
            .then((response) => {
                changeFrameworkToShow()
            })
            .catch((error) => {
                console.log(error);
            });
    }
    return (
        <>
            {
                <>
                    <TextSection width="auto" margin="0px 0px 30px 0px" data-cy={`${dataLabel}-create-form-description`}>
                    </TextSection>

                    {
                        form && <CustomReportFormSubmissionTable dataLabel={dataLabel}
                                                                 form={form}
                                                                 onSubmit={onSubmit}
                                                                 onCancel={changeFrameworkToShow}
                                                                 showDatePicker={true} />
                    }

                </>
            }
        </>
    )
}

export default IFCPrincipleCreate;
