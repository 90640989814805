import { GRAPH_COLORS, DATE_FORMAT } from "./constants";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

export const mapReportDataToESGLineGraph = reportData => {

  let esgScoreDataForGraph = []
  let esgScores = []
  let asOfDates = []

  if (reportData.length > 0) {
    reportData.map(snapshot => {
      asOfDates.push(snapshot?.as_of)
      
      esgScores.push({
        e: parseFloat(snapshot?.esg_score?.e_score || 0),
        s: parseFloat(snapshot?.esg_score?.s_score || 0),
        g: parseFloat(snapshot?.esg_score?.g_score || 0),
      })
    })

    if (esgScores) {
      ['e', 's', 'g'].map((category) => {
        let data = []
        esgScores.map((score, index) => {
          data.push({
            x: dayjs(asOfDates[index]).utc().format(DATE_FORMAT),
            y: score[category]
          })
        })
        
        esgScoreDataForGraph.push({
          color: GRAPH_COLORS[category],
          id: category,
          data: data
        })
      })
    }
  }

  return esgScoreDataForGraph
}

