export const TargetType = {
  INSTRUMENT: 'instruments',
  TRBC_INDUSTRY_GROUP: 'sectors',
  PORTFOLIO_SNAPSHOT: 'portfolio_snapshots',
}

export const TargetClass = {
  INSTRUMENT: 'Instrument',
  PORTFOLIO_SNAPSHOT: 'Portfolio::Snapshot',
}
