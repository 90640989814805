import FormService from '@services/Form'
import { ClosedQuestionButtonsControl } from '@ui/components/interactive/ClosedQuestionButtons'
import { DatePickerControl } from '@ui/components/interactive/DatePicker/DatePicker'
import { FormActions } from '@ui/components/interactive/FormActions'
import { SelectControl } from '@ui/components/interactive/Select'
import { TextAreaControl } from '@ui/components/interactive/TextArea'
import { AlertCard } from '@ui/components/presentation/cards/AlertCard'
import { Modal } from '@ui/components/presentation/modals/Modal'
import { Box } from '@ui/elements/grouping/Box'
import getTestingProps from '@utils/test/getTestingProps'
import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'

import { IDisclosure } from '../../Disclosures.controller'
import Controller from './CreateDisclosureModal.controller'
// import theme from './CreateDisclosureModal.module.scss'
import './CreateDisclosureModal.module.scss'

export interface IProps {
  disclosures: IDisclosure[] | null
  showModel: YB.TModel<boolean>
  onSubmit: (data: ICreateDisclosureData) => void
}

export interface ICreateDisclosureData {
  disclosure_base: string
  report_type: string
  as_of: string
  description: string
}

const Form = new FormService<ICreateDisclosureData>()

const REPORT_TYPES: [YB.IOption, YB.IOption] = [
  {
    label: 'Pre-Contractual Disclosure',
    value: 'pre_contractual',
  },
  { label: 'Periodic Disclosure', value: 'periodic' },
]

export const CreateDisclosureModal: React.FC<IProps> = ({
  disclosures,
  showModel,
  onSubmit,
}) => {
  const [isPresent, setIsPresent] = showModel
  const [showSubmitCard, setShowSubmitCard] = React.useState<boolean>(false)
  const [reportType] = Form.useInputModel<string>(REPORT_TYPES[0]?.value)
  const disclosureBases = useMemo(() => {
    const result = [{ label: 'New Disclosure', value: '-1' }]
    disclosures?.map(elem => {
      result.push({
        label: `${elem?.type_name} - ${elem?.as_of} - ${_.truncate(
          elem?.description,
          { length: 30 }
        )}`,
        value: (elem?.id ? elem?.id : '-1') as string,
      })
    })
    return result
  }, [disclosures])
  const [disclosureBase, disclosureBaseFeedback] = Form.useInputModel<string>(
    disclosureBases?.[0].value
  )
  const [reportDescription, reportDescriptionFeedback] =
    Form.useInputModel<string>('')
  const [asOfDate, asOfDateFeedback] = Form.useInputModel<string>('')
  const [minAsOfDate, maxAsOfDate] = Controller.useMinMaxInputAttributes()
  const formModel = {
    disclosure_base_id: disclosureBase,
    report_type: reportType,
    as_of: asOfDate,
    description: reportDescription,
  }
  const formData = Form.useFormData(formModel)
  const [formDataSnapshot, setFormDataSnapshot] =
    Form.useFormDataSnapshot(formData)

  const isFormValid = Form.useFormValidity([
    disclosureBaseFeedback,
    reportDescriptionFeedback,
    asOfDateFeedback,
  ])

  const handleSubmit = () => {
    onSubmit?.(formData)
  }
  const hideAlertCard = () => setShowSubmitCard(false)

  const handleHide = () => {
    Form.setFormModel(formModel, formDataSnapshot)
    setIsPresent(false)
    hideAlertCard()
  }

  useEffect(() => {
    setFormDataSnapshot(formData)
  }, [])

  return (
    <Modal
      show={isPresent}
      title='Create new disclosure'
      onHide={handleHide}
      {...getTestingProps('createDisclosureModal')}
      className='CreateDisclosureModal'
    >
      <p className='explanation'>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum in
        suscipit sem. Mauris molestie pulvinar faucibus. Phasellus rutrum
        lobortis est et pretium.
      </p>
      <ClosedQuestionButtonsControl
        label='Report type'
        name='reportType'
        model={reportType}
        options={REPORT_TYPES}
      />
      <SelectControl
        label='Disclosure base'
        name='disclosureBase'
        model={disclosureBase}
        feedbackModel={disclosureBaseFeedback}
        options={disclosureBases}
      />
      <DatePickerControl
        name='asOfDate'
        label='As of date'
        min={minAsOfDate}
        max={maxAsOfDate}
        validations={[Controller.dateBetweenDisclosureRange]}
        model={asOfDate}
        feedbackModel={asOfDateFeedback}
      />
      <TextAreaControl
        label='Report description'
        name='reportDescription'
        rows={5}
        model={reportDescription}
        feedbackModel={reportDescriptionFeedback}
      />
      <Box isPresent={!showSubmitCard}>
        <FormActions
          submitLabel='Create disclosure'
          onCancel={handleHide}
          onSubmit={handleSubmit}
          canSubmit={isFormValid}
        />
      </Box>
    </Modal>
  )
}
