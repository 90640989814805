import React from 'react';

const HoldingUpdatedEvent = ({ event }) => {
    if (event.context.on_fund) {
        return(<><span>{ event.context.full_name } updated {event.target.name}.</span></>)
    } else {
        return (
            <>
        <span className="event-user-name">
            {event.context.full_name}
        </span> updated <span className="event-user-name">
            {event.data.holding_name}
        </span> in { event.targetExists ? <a href={`/app/portfolios/${event.data.portfolio_id}/snapshots/${event.data.snapshot_id}`}>{event.data.portfolio_name}</a> : event.data.portfolio_name }.
                <span className="event-date">{event.formattedTimestamp}</span>
            </>
        );
    }
};

export default HoldingUpdatedEvent;
