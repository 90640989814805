import React, { useMemo, useRef, useEffect, memo } from 'react';
import { useSortBy, useTable } from 'react-table'
import _ from 'lodash';
import { Spinner } from 'react-bootstrap';
import { StyledTable } from './RecordsTableContent.styles';

const RecordsTableContent = ({ data, columns, onChangeSortBy, marginBottom, loading, minRows = 10, "data-cy": dataCy }) => {
    if (!data || !columns) return null;

    /* Hooks */
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy }
    } = useTable({ columns, data, manualSortBy: true }, useSortBy)

    /* Use Effects */
    useEffect(() => {
        if (onChangeSortBy) onChangeSortBy(sortBy);
    }, [data, sortBy]);

    /* Render */
    const renderDummyRows = useMemo(() => {
        if (columns?.length && minRows && !loading) {
            return _.range(minRows - data?.length).map((value, index) => {
                let label = '';
                if (!data.length && index === (minRows / 2) - 1) label = 'No records data found';
                return (
                    <tr key={`tr${value}`}>
                        <td key={`td${value}`} colSpan={columns.length}>
                            <div className="dummy-data">
                                {label}
                            </div>
                        </td>
                    </tr>
                )
            })
        }
        return null;
    }, [data, columns, loading]);

    return (
        <StyledTable data-cy={dataCy} marginBottom={marginBottom} {...getTableProps()}>

            {/* Header */}
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th data-cy={`records_table_header_${column.id}`} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                <div className="inline-content">
                                    {column.render('Header')}
                                    {column.isSorted && !column.isSortedDesc ? <i className="fas fa-sort-down icon"></i> : null}
                                    {column.isSortedDesc ? <i className="fas fa-sort-up icon"></i> : null}
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>

            {/* Body */}
            {loading ? (
                <tbody className="loading-icon-container" {...getTableBodyProps()}>
                    <tr>
                        <td colSpan={columns?.length}>
                            <div className="loading-icon-div">
                                <Spinner
                                    className="loading-icon"
                                    animation='border'
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            ) : (
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, rowIndex) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell, cellIndex) => {
                                    return (
                                        <td data-cy={`records_table_cell_${rowIndex}_${cellIndex}`} {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                    {renderDummyRows}
                </tbody>
            )}
        </StyledTable>
    )
}

export default memo(RecordsTableContent);
