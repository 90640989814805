import React from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import CardTitle from '../../../components/styleguide/CardTitle'
import Link from '../../../components/styleguide/Link'

const StyledContainer = styled.div`
  padding-left: 10px;
  display: flex;
  align-items: baseline;
  ul {
    padding-left: 10px;
    list-style-type: none;
    display: flex;
    gap: 10px;
  }
  a {
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
  }
  ${props => props.customStyle && props.customStyle}
`

const QuickSearches = ({ onClick, searchesList, customStyle }) => {
  return (
    <StyledContainer customStyle={customStyle}>
      <CardTitle
        style={{ color: '#979CA6', fontSize: '14px' }}
        title={'QUICK SEARCHES:'}
      />
      <ul>
        {searchesList.map(item => {
          return (
            <li key={uuidv4()}>
              <Link onClick={() => onClick(item.label)}>{item.label}</Link>
            </li>
          )
        })}
      </ul>
    </StyledContainer>
  )
}

export default QuickSearches
