import { getTcfdDataSheet } from "../../../repositories/tcfd_repository"

export const loadTcfdDataSheet = (name, setData, setError, setLoading, selectedPage, limit, sortField, sortDirection) => {
    setLoading(true)
    getTcfdDataSheet(name, selectedPage, limit, sortField, sortDirection)
      .then(res => {
        setLoading(false)
        setData(res)
      })
      .catch(err => {
        setLoading(false)
        const error = err.response ? err.response.status : 502
        setError(err)
      })
  }