import styled from 'styled-components'

import { APP_COLOR_PRIMARY } from '../../styles/colors'

export const StyledContainer = styled.div`
  padding: 20px;
`

export const Title = styled.div`
  float: left;
  margin-top: 35px;
  margin-bottom: 35px;
  font-weight: bold;
  font-size: 30px;
  line-height: 16px;
  padding-left: 140px;
  padding-right: 140px;
`

export const GridContainer = styled.div`
  padding-left: 140px;
  padding-right: 140px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(8, 1fr);
  clear: both;
`

export const StyledBar = styled.div`
  position: fixed;
  bottom: 0;
  right: 20px;
  left: 20px;
  margin-top: 20px;
  background-color: white;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  height: 60px;

  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;

  button:last-child {
    margin-right: 13px;
  }
  .run-button {
    background-color: #979ca6;
  }
  .reset-link {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: ${APP_COLOR_PRIMARY};
    cursor: pointer;
  }
`

export const RectangularButton = styled.button`
  text-transform: uppercase;
  color: white;

  font-size: 13px;
  font-weight: 700;

  line-height: 16px;
  padding: 10px 40px 10px 40px;

  border-radius: 10px;
  border: none;
  background-color: ${APP_COLOR_PRIMARY};

  :disabled {
    background-color: #607abd;
  }
`

export const PresetsContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  padding-right: 140px;

  float: right;
  img {
    height: 25px;
  }
`

export const StyledModalContent = styled.div`
  .description {
    color: #979ca6;
  }

  label {
    font-size: 12px;
    margin-top: 10px;
    font-weight: 700;
    text-transform: uppercase;
    color: #979ca6;
  }
`
