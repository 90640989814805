export const adjustIntervals = (labels, changedLabel) => {
  let i = 1
  while (labels[changedLabel.index + i]) {
    labels[changedLabel.index + i].min = Math.round(
      labels[changedLabel.index + i - 1].max
    )
    if (
      labels[changedLabel.index + i].min >=
        labels[changedLabel.index + i].max &&
      labels[changedLabel.index + i].min < 100
    ) {
      labels[changedLabel.index + i].max =
        labels[changedLabel.index + i].min + 0.99
    }

    i += 1
  }
}
