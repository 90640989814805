import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { CustomMetricDialog } from './styles';
import WrapperColumn from '../../../../../components/styleguide/WrapperColumn';
import TextInput from '../../../../../components/styleguide/TextInput';
import TextArea from '../../../../../components/styleguide/TextArea';
import DatePicker from '../../../../../components/styleguide/DatePicker'
import Button from '../../../../../components/styleguide/Button';
import SectionWrapper from '../../../../../components/styleguide/SectionWrapper';


const UpdateTargetModal = ({ show, setShowUpdateTargetModal, createNewMetricTarget, dataLabel, currentValue, defaultDate }) => {
    
    const [updatedMetricTarget, setUpdatedMetricTarget] = useState({});

    const updateMetricTarget = (id, value) => {
        const newUpdatedMetricTarget = { ...updatedMetricTarget };
        newUpdatedMetricTarget[id] = value;
        setUpdatedMetricTarget(newUpdatedMetricTarget);
    }

    return (
        show && <CustomMetricDialog show={show} onHide={() => setShowUpdateTargetModal(false)} data-cy={`${dataLabel}-custom-metric-modal`}>
            <Modal.Header closeButton data-cy={`${dataLabel}-custom-metric-modal-header`}>
                <Modal.Title data-cy={`${dataLabel}-custom-metric-modal-title`}>Updated Target Value</Modal.Title>
            </Modal.Header>
            <WrapperColumn data-cy={`${dataLabel}-custom-metric-modal-top-wrapper`}>
                <SectionWrapper margin="10px 10px 5px 10px;" justify="space-between" data-cy={`${dataLabel}-custom-metric-modal-number-wrapper`}>
                    <SectionWrapper flex="1" data-cy={`${dataLabel}-custom-metric-modal::number-row`}>
                        <TextInput
                            type="number"
                            width="80%"
                            data-cy={`${dataLabel}-custom-metric-modal-number-input`}
                            label="Target Value"
                            id="target_value"
                            defaultValue={currentValue}
                            onChange={(id, value) => { updateMetricTarget(id, value) }}
                        />
                    </SectionWrapper>
                    <SectionWrapper flex="1" data-cy={`${dataLabel}-custom-metric-modal::target_date`}>
                        <SectionWrapper flex="1" margin="5px 0px 0px 0px" data-cy={`${dataLabel}-custom-metric-modal::target_date::wrapper`}>
                            <DatePicker
                                width="80%"
                                label="Target Date"
                                showYearDropdown={true}
                                data-cy={`${dataLabel}-custom-metric-modal::target_date-input`}
                                id="target_date"
                                date={updatedMetricTarget.target_date || defaultDate}
                                onChange={(value, id) => { updateMetricTarget(id, value) }}
                            />
                        </SectionWrapper>
                    </SectionWrapper>
                </SectionWrapper>
                <SectionWrapper margin="-12px 10px 5px 10px" flex="1" data-cy={`${dataLabel}-custom-metric-modal::note-wrapper`}>
                    <TextArea
                        label="Note"
                        width="90%"
                        data-cy={`${dataLabel}-custom-metric-modal::target_note`}
                        id="target_note"
                        rows={5}
                        onChange={(id, value) => { updateMetricTarget(id, value) }}
                    />
                </SectionWrapper>
                <SectionWrapper width="90%" justify="flex-end" margin="0px 0px 12px 0px" data-cy={`${dataLabel}-custom-metric-modal::button-wrapper`}>
                    <Button data-cy={`${dataLabel}-custom-metric-modal::cancel-button`} margin="0px 15px 0px 0px" width="30%" label="Cancel" onClick={() => setShowUpdateTargetModal(false)}/>
                    <Button data-cy={`${dataLabel}-custom-metric-modal::submit-button`} width="30%" label="Update Metric" onClick={() => createNewMetricTarget(updatedMetricTarget)}/>
                </SectionWrapper>
            </WrapperColumn>
        </CustomMetricDialog>
    )
}

export default UpdateTargetModal;
