import { objectToCamelCase, objectToSnakeCase } from '@utils/object'
import { useEffect } from 'react'

import {
    getSFDRFundConfiguration,
    updateSFDRFundConfiguration,
} from '../../../../../../../react/repositories/portfolio_repository'
import { TMetadata } from '../DisclosureDetail/data'
import { IFundConfigurationForm } from './FundConfiguration'

class FundConfigurationController {
    useDefaultValues(
        portfolioId: string,
        snapshotId: string,
        onSuccess: (data: IFundConfigurationForm, metadata: TMetadata) => void
    ) {
        return useEffect(() => {
            getSFDRFundConfiguration(portfolioId, snapshotId)
                .then(response => {
                    if (!response?.data?.fund_configuration) return

                    const form = objectToCamelCase({
                        ...response?.data?.fund_configuration,
                        fundBenchmark:
                            response?.data?.fund_configuration?.fund_benchmark
                                ?.value,
                    }) as IFundConfigurationForm

                    const metadata = {
                        fundBenchmark:
                            response?.data?.fund_configuration?.fund_benchmark
                                ?.metadata,
                    } as TMetadata

                    onSuccess(form, metadata)
                })
                .catch(err => console.log(err))
        }, [portfolioId, snapshotId, onSuccess])
    }

    updateFundConfiguration(
        portfolioId: string,
        snapshotId: string,
        form: IFundConfigurationForm
    ) {
        const formattedBody = objectToSnakeCase(form)
        delete formattedBody.fund_benchmark
        formattedBody.impact_benchmark_id = form.fundBenchmark

        return updateSFDRFundConfiguration(
            portfolioId,
            snapshotId,
            formattedBody
        )
    }
}

export default new FundConfigurationController()
