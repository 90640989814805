import { SFDR } from '@ui/screens/Portfolios/RegulatoryFrameworks/SFDR'
import React from 'react'

import CarbonReport from '../components/tabs/CarbonReport'
import PortfolioHoldings from '../components/tabs/PortfolioHoldings'
import SnapshotSummary from '../components/tabs/SnapshotSummary'
import { RegulatoryDashTab } from '../regulatory/RegulatoryDashTab'
import { TcfdHoldingDetailTab } from '../tcfd/TcfdHoldingDetailTab'
import { TcfdHoldingsTab } from '../tcfd/TcfdHoldingsTab'

export const portfolioTabNavProps = (
  snapshotId,
  portfolioId,
  snapshot,
  impactBenchmark,
  setLoading,
  setError,
  summaryHoldingsFilter,
  setSummaryHoldingsFilter,
  holdingsFilters,
  setHoldingsFilters,
  onCarbonFilterChange,
  activeValue,
  holdingId,
  showCarbon,
  showEngagmentAndManage,
  handleUrlOnTabChange,
  navOptions,
  {
    summaryRef,
    holdingsRef,
    carbonReportMetricsRef,
    tcfdHoldingDetailRef,
    tcfdHoldingsRef,
    regulatoryDashRef,
  },
  organization
) => {
  const PORTFOLIO_TAB_NAV = {
    withContent: true,
    activeValue: activeValue,
    onChange: handleUrlOnTabChange,
    tabs: [
      {
        key: 'summary',
        text: 'SUMMARY',
        content: (
          <SnapshotSummary
            snapshotId={snapshotId}
            portfolioId={portfolioId}
            snapshot={snapshot}
            impactBenchmark={impactBenchmark}
            setLoading={setLoading}
            setError={setError}
            ref={summaryRef}
            holdingsFilters={summaryHoldingsFilter}
            setHoldingsFilters={setSummaryHoldingsFilter}
          />
        ),
      },
      {
        key: 'holdings',
        text: 'HOLDINGS',
        content: (
          <PortfolioHoldings
            limit={25}
            snapshotId={snapshotId}
            portfolioId={portfolioId}
            snapshot={snapshot}
            impactBenchmark={impactBenchmark}
            setLoading={setLoading}
            setError={setError}
            holdingsFilters={holdingsFilters}
            setHoldingsFilters={setHoldingsFilters}
            ref={holdingsRef}
          />
        ),
      },
    ],
  }

  if (organization?.can_access_regulatory_frameworks) {
    PORTFOLIO_TAB_NAV.tabs.push({
      key: 'regulatory_tab',
      text: 'REGULATORY FRAMEWORKS',
      subMenu: [
        {
          key: 'regulatory_pai',
          text: 'I. PAI',
          href: `/app/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/pai/overview`,
          link: true,
          content: (
            <RegulatoryDashTab
              portfolioId={portfolioId}
              snapshotId={snapshotId}
              ref={regulatoryDashRef}
              activePaiTab={navOptions}
            />
          ),
        },
        {
          key: 'regulatory_sfdr',
          text: 'II. SFDR',
          href: `/app/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/sfdr/overview`,
          link: true,
          content: (
            <SFDR
              tab={navOptions?.subTab}
              detailId={navOptions?.detailId}
              portfolioId={portfolioId}
              snapshotId={snapshotId}
            />
          ),
        },
      ],
    })
  }

  if (showCarbon) {
    PORTFOLIO_TAB_NAV.tabs.push({
      key: 'carbon_report',
      text: 'CARBON REPORT',
      subMenu: [
        {
          key: 'carbon_report_metrics',
          text: 'I. Metrics',
          content: (
            <CarbonReport
              snapshot={snapshot}
              setLoading={setLoading}
              impactBenchmark={impactBenchmark}
              onCategoryClick={onCarbonFilterChange}
              ref={carbonReportMetricsRef}
            />
          ),
        },
        {
          key: 'carbon_report_tcfd',
          text: 'II. TCFD Scenario Report',
          content: holdingId ? (
            <TcfdHoldingDetailTab
              holdingId={holdingId}
              portfolioId={portfolioId}
              snapshotId={snapshotId}
              ref={tcfdHoldingDetailRef}
            />
          ) : (
            <TcfdHoldingsTab
              portfolioId={portfolioId}
              snapshotId={snapshotId}
              ref={tcfdHoldingsRef}
            />
          ),
        },
        {
          key: 'modeling_scenarios',
          text: 'III. Modeling & Scenarios',
          link: true,
          disabled: true,
        },
      ],
    })
  }

  // PORTFOLIO_TAB_NAV.tabs.push(
  //   {
  //     key: 'esg_factors',
  //     text: 'ESG FACTORS',
  //     link: true,
  //     disabled: true,
  //   },
  //   {
  //     key: 'sasb_materiality',
  //     text: 'SASB MATERIALITY',
  //     link: true,
  //     disabled: true,
  //   }
  // )

  if (showEngagmentAndManage) {
    PORTFOLIO_TAB_NAV.tabs.push([
      {
        key: 'engagement',
        text: 'ENGAGEMENT',
        href: `/app/portfolios/${portfolioId}/snapshots/${snapshot.id}/engagement`,
        link: true,
      },
      {
        key: 'manage',
        text: 'MANAGE',
        href: '#link',
        link: true,
        disabled: true,
      },
    ])
  }

  return PORTFOLIO_TAB_NAV
}
