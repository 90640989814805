import React, { useState } from 'react'
import styled from 'styled-components'

import usePlaceholderBadge from './badges/usePlaceholderBadge'
import TextView from './TextView'
import Tooltip from './Tooltip'

const BadgeContainer = styled.div`
  position: relative;
  display: inline-block;
`

const BadgeIcon = ({ badge, size, withTooltip }) => {
  const { path } = usePlaceholderBadge(badge)
  const [showTooltip, setShowTooltip] = useState(false)

  const onHover = () => setShowTooltip(true)
  const onLoseHover = () => setShowTooltip(false)

  const containerHandlers = withTooltip
    ? {
        onMouseEnter: onHover,
        onMouseLeave: onLoseHover,
      }
    : {}

  return (
    <BadgeContainer {...containerHandlers}>
      <img src={path} height={size} width={size} />
      {withTooltip && (
        <Tooltip
          show={showTooltip}
          padding={10}
          width={'max-content'}
          x={size / 2}
          y={0}
          render={() => <TextView bold center size={14} text={badge?.name} />}
        />
      )}
    </BadgeContainer>
  )
}

BadgeIcon.defaultProps = {
  size: 40,
}

export default BadgeIcon
