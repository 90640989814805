import styled from 'styled-components'

import {
  APP_COLOR_PRIMARY,
  FONT_COLOR_PRIMARY,
  FONT_COLOR_SECONDARY,
} from '../../../../../../../styles/colors'

export const StyledFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 40px;

  label {
    display: block;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: ${FONT_COLOR_SECONDARY};
  }

  input,
  select {
    height: 30px;
    border: 1px solid #e2e3e7;
    border-radius: 6px;
  }

  input[type='search'] {
    padding-left: 32px;
    color: ${FONT_COLOR_PRIMARY};
    background: url("data:image/svg+xml, %3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M9.37336 9.92641H10.0057L14 14.1393L12.8073 15.3948L8.80503 11.1903V10.5246L8.58891 10.2887C7.67639 11.1145 6.49171 11.6116 5.20297 11.6116C2.32933 11.6116 0 9.15966 0 6.13477C0 3.10989 2.32933 0.657959 5.20297 0.657959C8.07662 0.657959 10.4059 3.10989 10.4059 6.13477C10.4059 7.49134 9.93368 8.73837 9.14923 9.69891L9.37336 9.92641ZM1.60098 6.13484C1.60098 8.23288 3.2099 9.92648 5.20304 9.92648C7.19617 9.92648 8.80509 8.23288 8.80509 6.13484C8.80509 4.0368 7.19617 2.3432 5.20304 2.3432C3.2099 2.3432 1.60098 4.0368 1.60098 6.13484Z' fill='%23979CA6' /%3E%3C/svg%3E")
      no-repeat center left 10px;

    &::placeholder {
      color: ${FONT_COLOR_SECONDARY};
    }
  }

  select {
    background: url("data:image/svg+xml,%3Csvg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.55657 5.70725L10.5573 1.70657C10.9475 1.31623 10.9475 0.683087 10.5573 0.292752C10.1671 -0.0975839 9.53391 -0.0975839 9.14271 0.292752L5.84979 3.58653L2.55687 0.292752C2.16668 -0.0975839 1.5335 -0.0975839 1.1423 0.292752C0.947714 0.487407 0.849915 0.743533 0.849915 0.999659C0.849915 1.25476 0.947714 1.51089 1.1423 1.70657L5.14301 5.70725C5.5332 6.09758 6.16638 6.09758 6.55657 5.70725Z' fill='%23979CA6'/%3E%3C/svg%3E%0A")
      no-repeat center right 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .btn-group button {
    padding: 0.25rem 1rem;
    height: 30px;
    min-width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.1;
    color: ${FONT_COLOR_SECONDARY};
    background-color: '#fff';
    border-radius: 0;
    border: 1px solid #e2e3e7;

    &.active {
      color: ${APP_COLOR_PRIMARY};
      background-color: #f7f8f9;
      pointer-events: none;
    }

    &:first-child {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
    &:last-child {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    &:hover {
      color: ${FONT_COLOR_PRIMARY};
    }
  }

  h4 {
    margin: 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: ${FONT_COLOR_SECONDARY};
  }
`
