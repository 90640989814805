import { normalizeNumber, translateBoolNumber } from './numbers'

export const formatImpact = (impactValue, unit) => {
  const numberImpactValue = Number(impactValue)
  const isValidNumber = !isNaN(numberImpactValue)

  if (impactValue === null || impactValue === undefined) {
    return '—'
  }

  switch (unit) {
    case 'bool':
      return isValidNumber
        ? translateBoolNumber(numberImpactValue)
        : impactValue
    default:
      return normalizeNumber(impactValue)
  }
}
