export const sdgs = [
  { num: 1, color: '#b93538' },
  { num: 2, color: '#dca83b' },
  { num: 3, color: '#619b46' },
  { num: 4, color: '#b52f34' },
  { num: 5, color: '#ec4d33' },
  { num: 6, color: '#26bce1' },
  { num: 7, color: '#fbc20b' },
  { num: 8, color: '#952742' },
  { num: 9, color: '#eb703a' },
  { num: 10, color: '#dc1367' },
  { num: 11, color: '#fc9c24' },
  { num: 12, color: '#be8b2e' },
  { num: 13, color: '#4f7c4b' },
  { num: 14, color: '#0a96d8' },
  { num: 15, color: '#73b757' },
  { num: 16, color: '#2f699b' },
]
