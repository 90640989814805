import _ from 'lodash'

import { mapInstrumentToState } from '../../../mappers/instruments_mapper'
import { mapSnapshotToState } from '../../../mappers/portfolio_mapper'
import { getImpactBenchmark } from '../../../repositories/benchmark_repository'
import {
  getBadges,
  getCarbonAuditDropdownOptions,
  getCarbonReportSummary,
  getSnapshot,
  getSnapshotCorporateEntities,
  getSnapshotHoldings,
  getSnapshotIndustryGroups,
} from '../../../repositories/portfolio_repository'
import { getTcfdPortfolioOverallScore } from '../tcfd/repositories/tcfd_repository'

export const loadSnapshot = (
  setSnapshot,
  setImpactBenchmark,
  setError,
  snapshot_id,
  portfolio_id
) => {
  getSnapshot(snapshot_id, portfolio_id)
    .then(res => {
      setSnapshot(mapSnapshotToState(res.data.snapshot))
      loadBenchmark({
        impactBenchmarkId: res.data.snapshot.impact_benchmark_id,
        snapshotId: snapshot_id,
        setImpactBenchmark,
        setError,
      })
    })
    .catch(err => {
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

export const loadBenchmark = ({
  impactBenchmarkId,
  snapshotId = null,
  setImpactBenchmark,
  setError,
}) => {
  getImpactBenchmark({ impactBenchmarkId, snapshotId })
    .then(res => {
      setImpactBenchmark(mapInstrumentToState(res.data))
    })
    .catch(err => {
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

export const loadBadges = (snapshotId, portfolioId, setBadges, setError) => {
  getBadges(snapshotId, portfolioId)
    .then(res => {
      setBadges(res.data.badges)
    })
    .catch(err => {
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

const pageAndSortParams = (
  page,
  sortField,
  sortDir,
  searchValue,
  holdingsPerPage,
  filters
) => {
  return _({
    page,
    sort_field: sortField,
    sort_dir: sortDir,
    query: searchValue,
    limit: holdingsPerPage,
    ...filters,
  })
    .omitBy(_.isNil)
    .value()
}

export const loadPortfolioHoldings = ({
  setData,
  setTotal,
  setError,
  setLoading,
  query,
}) => {
  const params = window.location.pathname.split('/')
  isNaN(params[params.length - 1]) && params.pop()
  setLoading?.(true)
  getSnapshotHoldings(
    params[params.length - 1],
    params[params.length - 3],
    query
  )
    .then(res => {
      setLoading?.(false)
      setData?.(res.data.holdings)
      setTotal?.(res.data.total)
    })
    .catch(err => {
      setLoading?.(false)
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

export const loadPortfolioCorporateEntities = ({
  setData,
  setTotal,
  setError,
  setLoading,
  query = '',
}) => {
  const params = window.location.pathname.split('/')
  isNaN(params[params.length - 1]) && params.pop()
  setLoading?.(true)
  getSnapshotCorporateEntities(
    params[params.length - 1],
    params[params.length - 3],
    query
  )
    .then(res => {
      setLoading?.(false)
      setData?.(res.data.holdings)
      setTotal?.(res.data.total)
    })
    .catch(err => {
      setLoading?.(false)
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

export const loadPortfolioIndustryGroups = ({
  setData,
  setTotal,
  setError,
  setLoading,
  query,
}) => {
  const params = window.location.pathname.split('/')
  isNaN(params[params.length - 1]) && params.pop()
  setLoading?.(true)

  getSnapshotIndustryGroups(
    params[params.length - 1],
    params[params.length - 3],
    query
  )
    .then(res => {
      setLoading?.(false)
      setData?.(res.data.holdings)
      setTotal?.(res.data.total)
    })
    .catch(err => {
      setLoading?.(false)
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

export const loadPortfolioModeledHoldings = (
  holdingsPerPage,
  portfolioId,
  snapshotId,
  modeledType,
  setHoldings,
  setHoldingsTotal,
  setError,
  page,
  sortField,
  sortDir,
  query = '',
  setLoadingHoldings = null
) => {
  setLoadingHoldings ? setLoadingHoldings(true) : ''
  getSnapshotHoldings(snapshotId, portfolioId, {
    modeled_type: modeledType,
    ...pageAndSortParams(page, sortField, sortDir, query, holdingsPerPage),
  })
    .then(res => {
      setLoadingHoldings ? setLoadingHoldings(false) : ''
      setHoldings(res.data.holdings)
      setHoldingsTotal(res.data.total)
    })
    .catch(err => {
      setLoadingHoldings ? setLoadingHoldings(false) : ''
      const error = err?.response?.status || 502
      setError(error)
    })
}

export const loadTop10ModeledHoldings = (
  snapshotId,
  portfolioId,
  setTop10ModeledHoldings,
  setError
) => {
  getSnapshotHoldings(snapshotId, portfolioId, {
    limit: 10,
    page: 1,
    sort_field: 'weight',
    modeled_type: 'modeled',
  })
    .then(res => {
      setTop10ModeledHoldings(res.data.holdings)
    })
    .catch(err => {
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

export const loadCarbonAuditDropdownOptions = (
  snapshotId,
  setCarbonAuditDropdownOptions
) => {
  getCarbonAuditDropdownOptions(snapshotId)
    .then(res => {
      setCarbonAuditDropdownOptions(res.data.trbcIndustryGroup)
    })
    .catch(err => {
      console.error(err)
    })
}

export const loadTcfdHoldings = async (
  setHoldings,
  setPortfolioScores,
  setCustomScores,
  portfolioId,
  snapshotId
) => {
  try {
    const res = await getTcfdPortfolioOverallScore(portfolioId, snapshotId)
    setHoldings && setHoldings(res.holding_scores)
    setPortfolioScores && setPortfolioScores(res.portfolio_scores)
    setCustomScores && setCustomScores(res.custom_scores)
  } catch (e) {
    console.error(e)
  }
}

export const loadReportSummaryCard = ({
  snapshotId,
  portfolioId,
  setData,
  dispatch,
}) => {
  getCarbonReportSummary({ snapshotId, portfolioId })
    .then(res => {
      setData(res.data)

      dispatch({
        type: 'summary-loaded',
        payload: res.data,
      })
    })
    .catch(err => {
      setData(err)
    })
}
