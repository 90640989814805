import React from 'react'
import styled from 'styled-components'

import Card from '../../../components/styleguide/Card'
import CardTitle from '../../../components/styleguide/CardTitle'
import ESGTable from '../../../components/styleguide/ESGTable'
import SpinnerContainer from '../../../yb_components/styleguide/spinners/SpinnerContainer'

const StyledCard = styled(Card)`
  grid-column: span 8;

  .scale-container {
    margin-bottom: 30px;
  }
  margin-bottom: 60px;
`
const PortfolioScoreResults = ({ loading, scores }) => {
  let entities, esgScores
  if (scores) {
    entities = []
    esgScores = []
    scores.map(score => {
      entities.push({ name: score?.target_name })
      esgScores.push({
        e: score.e_score,
        s: score.s_score,
        g: score.g_score,
        total: score.total_score,
      })
    })
  }

  return (
    <StyledCard>
      <CardTitle title='Score Customization Results' />
      <SpinnerContainer isLoading={loading}>
        <ESGTable entities={entities} esgScores={esgScores} />
      </SpinnerContainer>
    </StyledCard>
  )
}

export default PortfolioScoreResults
