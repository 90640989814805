import { Link } from '@ui/elements/Link'
import getTestingProps from '@utils/test/getTestingProps'
import classNames from 'classnames'
import React from 'react'

import './ModuleTabs.module.scss'

export interface IModuleTab {
  id: string
  label: string
  route: string
  isExternal?: boolean
}

export interface IProps {
  title: string
  active: string
  tabs: IModuleTab[]
}

export const ModuleTabs: React.FC<IProps> = ({ title, tabs, active }) => {
  return (
    <div className={'ModuleTabs'}>
      <h3>{title}</h3>
      <nav {...getTestingProps('sfdrNav')}>
        <ul>
          {tabs.map(tab => (
            <li key={tab.label}>
              <Link
                href={tab.route}
                className={classNames({
                  active: [tab.id, `${tab.id}_detail`].includes(active),
                })}
              >
                {tab.label}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}
