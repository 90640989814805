import axios from 'axios'
import _ from 'lodash'
import qs from 'qs'

import { searchFunds } from '../../../repositories/managers_repository'
import {
  COMPANY_TYPES,
  REVENUE_BUCKETS_OPTIONS,
} from '../constants/form-constants'
import { getPresInstruments } from './mappers/instrument-mapper'
import { getPresManagerDB } from './mappers/manager-db-mapper'

const loadResearch = (
  {
    setInstruments,
    setTotalPages,
    setPresInstruments,
    setCurrentPage,
    setIsLoading,
  },
  queryString,
  dataChoice
) => {
  setIsLoading(true)
  const queryBase = '/app/api/research_center'
  const query = queryBase + queryString
  axios
    .get(query)
    .then(res => {
      setInstruments(res.data.research_center)
      dataChoice === !dataChoice ? 'SUMMARY' : dataChoice
      setPresInstruments(
        getPresInstruments(dataChoice, res.data.research_center)
      )
      setCurrentPage(res.data.current_page)
      setTotalPages(res.data.total_pages)
      setIsLoading(false)
    })
    .catch(err => console.error(err))
}

const filterValidSDGS = sdgs => {
  const validSDGS = _.range(1, 17)
  return sdgs.filter(sdg => validSDGS.includes(sdg?.value))
}

const loadUtilities = ({ setFiltersOptions, snapshotId, query }) => {
  let queryString =
    'market_caps=true&industries=true&sectors=true&trbc_industry_groups=true&positive_badges=true&negative_badges=true&instrument_types=true&sdgs=true&country_of_hq=true&funds_asset_classes=true&funds_sectors=true'

  if (query) {
    let queryObj = {}
    if (Array.isArray(query)) {
      query?.forEach(elem => {
        queryObj[elem] = true
      })
    } else {
      queryObj = query
    }
    queryString = qs.stringify(queryObj)
  }

  if (snapshotId) {
    queryString = queryString + `&target=Portfolio::Snapshot&snapshot_id=${snapshotId}`
  }

  axios
    .get(`/app/api/utilities/dropdown_options?${queryString}`)
    .then(res => {
      setFiltersOptions({
        companyTypes: COMPANY_TYPES,
        revenues: res?.data?.revenues || REVENUE_BUCKETS_OPTIONS,
        industries: res.data.industries,
        presIndustries: res.data.industries,
        marketCap: res.data.marketCaps,
        sectors: res.data.sectors,
        negativeBadges: res.data.negativeBadges,
        positiveBadges: res.data.positiveBadges,
        instrumentTypes: res.data.instrumentTypes,
        assetClasses: res.data.assetClasses,
        trbcBusinessSector: res.data.trbcBusinessSector,
        trbcIndustryGroup: res.data.trbcIndustryGroup,
        fundAssetClasses: res.data.funds_asset_classes,
        fundSectors: res.data.funds_sectors,
        countries: res.data.countries,
        scores: res.data.scores,
        sdgs: filterValidSDGS(res.data.sdgs),
      })
    })
    .catch(err => console.error(err))
}

const loadManagerDBFunds = ({
  params,
  setFunds,
  setTotalPages,
  setIsLoading,
}) => {
  setIsLoading(true)
  searchFunds(params).then(res => {
    setFunds(getPresManagerDB(res.data.funds))
    setTotalPages(res.data.total_pages)
    setIsLoading(false)
  })
}

export { loadManagerDBFunds, loadResearch, loadUtilities }
