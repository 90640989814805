import PropTypes from 'prop-types'
import React from 'react'

import { StyledTable } from './SpeedDialTable.styles'

export const SpeedDialTable = ({
  tableData,
  tableHeadItems,
  portfolioId,
  snapshotId,
}) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          {tableHeadItems.map(item => (
            <th key={item}>{item}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData.map(({ holding_id, name, value }, index) => (
          <tr key={index}>
            <td>
              <a
                style={{ width: '240px' }}
                href={`/app/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/pai/entities/${holding_id}`}
              >
                {name}
              </a>
            </td>
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  )
}

SpeedDialTable.propTypes = {
  tableData: PropTypes.array.isRequired,
  tableHeadItems: PropTypes.array.isRequired,
  portfolioId: PropTypes.array.isRequired,
  snapshotId: PropTypes.array.isRequired,
}
