import dayjs from 'dayjs'
import React, { useRef } from 'react'
import styled from 'styled-components'

import FileLinesSvg from '../../../../../../public/ico_file_lines.svg'
import MicroscopeSvg from '../../../../../../public/ico_microscope.svg'
import Card from '../../../components/styleguide/Card'
import Link from '../../../components/styleguide/Link'
import {
  BG_COLOR_TERTIARY,
  DARK_GREY_1,
  FONT_COLOR_SECONDARY,
  LIGHT_GREEN_1,
} from '../../../styles/colors'
import RoundBackgroundIcon from '../../../yb_components/styleguide/icons/RoundBackgroundIcon'

export const StyledCard = styled(Card)`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &:hover {
    cursor: pointer;
    a {
      text-decoration: underline;
    }
  }

  & > div:first-child {
    margin-right: 0.875rem;
  }
`

export const Date = styled.div`
  margin-left: auto;
  font-size: 0.875rem;
  color: ${FONT_COLOR_SECONDARY};
`

const ListItem = ({ item, ...props }) => {
  const linkRef = useRef()

  const icons = {
    release_note: {
      icon: <FileLinesSvg />,
      iconColor: LIGHT_GREEN_1,
    },
    methodology_update: {
      icon: <MicroscopeSvg />,
      iconColor: DARK_GREY_1,
      backgroundColor: BG_COLOR_TERTIARY,
    },
  }
  const formattedDate = dayjs(item.updatedAt).format('MMMM D, YYYY')

  return (
    <StyledCard onClick={() => linkRef.current.click()} {...props}>
      <RoundBackgroundIcon data-cy='icon' {...icons[item.type]} />
      <Link
        ref={linkRef}
        href={item.url}
        target='_blank'
        data-cy='name'
        styles={{ fontWeight: 'normal' }}
      >
        {item.title}
      </Link>
      <Date data-cy='date'>{formattedDate}</Date>
    </StyledCard>
  )
}

export default ListItem
