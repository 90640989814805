// LIBRARIES
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Select from 'react-select-3'

import Alert from '../../../../../components/styleguide/Alert'
import Button from '../../../../../components/styleguide/Button'
import InputLabel from '../../../../../components/styleguide/InputLabel'
import SectionWrapper from '../../../../../components/styleguide/SectionWrapper'
import TextArea from '../../../../../components/styleguide/TextArea'
import TextInput from '../../../../../components/styleguide/TextInput'
import WrapperColumn from '../../../../../components/styleguide/WrapperColumn'
import {
  countryCodes,
  industries,
  pipelineStateOptions,
  verticals,
} from '../../../../../constants/funds'
import { editFundHolding } from '../../../../../repositories/funds_repository'
import { PEErrorService } from '../../../errorService'
import { Holding } from '../../../models/Holding'
import { ConfirmDialog, ModuleCardTitle, ModuleWrapper } from '../../../styles'

const HoldingConfigurationSection = ({ holding, updateHolding }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [headquartersAddress, setHeadquartersAddress] = useState(
    holding.headquarters_address
  )
  const [description, setDescription] = useState(holding.description)
  const [errors, setErrors] = useState({})
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const onUpdate = () => {
    setShowConfirmModal(true)
  }

  const onUpdateConfirm = () => {
    holding.description = description
    holding.headquarters_address = headquartersAddress
    const fundId = holding.fund_id
    const holdingId = holding.id
    setShowSuccessMessage(false)
    setShowErrorMessage(false)
    setErrors({})

    editFundHolding({ fundId, holding, holdingId })
      .then(response => {
        const replacementHolding = new Holding(response.data)
        replacementHolding.setPipelineState(response.data.state)
        setShowSuccessMessage(true)
        window.scrollTo(0, 0)
        updateHolding(replacementHolding)
      })
      .catch(error => {
        error.response &&
          PEErrorService.setErrors(error.response.data, setErrors)
        setShowErrorMessage(true)
        window.scrollTo(0, 0)
      })

    setShowConfirmModal(false)
  }

  const getIndustry = industry => {
    return industries.find(el => el.value === industry)
  }

  const getPrimaryMarket = market => {
    return countryCodes.find(el => el.value === market)
  }

  const getVertical = vertical => {
    return verticals.find(el => el.value === vertical)
  }

  const getPipelineState = pipelineState => {
    return pipelineStateOptions.find(el => el.value === pipelineState)
  }

  return (
    <>
      <form>
        <fieldset disabled={!holding.current_app_user_can_update}>
          <ModuleWrapper width='100%' data-cy='holding-configuration-container'>
            {showSuccessMessage && (
              <Alert type='success'>Holding updated.</Alert>
            )}

            {showErrorMessage && (
              <Alert type='error'>
                An error occured when updating your holding.
              </Alert>
            )}

            <ModuleCardTitle
              title='General Information'
              data-cy='holding-configuration-module-card-title-one'
            />
            <SectionWrapper
              margin='5px 0px 5px 10px'
              width='100%'
              data-cy='holding-configuration-section-name-wrapper'
            >
              <TextInput
                error={errors.name}
                marginRight='30px'
                label='Name'
                width='40%'
                id='name'
                defaultValue={holding.name}
                onChange={(id, value) => {
                  holding.setName(value)
                }}
              />
            </SectionWrapper>
            <SectionWrapper
              margin='0px 0px 5px 10px'
              width='100%'
              data-cy='holding-configuration-section-address-wrapper'
            >
              <SectionWrapper
                width='40%'
                margin='0px 40px 0px 0px'
                data-cy='holding-configuration-section-address-wrapper-inner'
              >
                <TextArea
                  label='Address'
                  error={errors.headquarters_address}
                  width='100%'
                  rows={5}
                  id='address'
                  marginRight='20px'
                  value={headquartersAddress}
                  onChange={(id, value) => setHeadquartersAddress(value)}
                />
              </SectionWrapper>
            </SectionWrapper>
            <SectionWrapper
              margin='5px 0px 5px 10px'
              width='100%'
              data-cy='holding-configuration-module-wrapper-spacer'
            ></SectionWrapper>
          </ModuleWrapper>
          <ModuleWrapper width='100%' data-cy='holding-strategy-container'>
            <ModuleCardTitle
              title='Strategy & Description'
              data-cy='holding-configuration-module-card-title-two'
            />
            <SectionWrapper
              margin='0px 0px 5px 10px'
              width='100%'
              data-cy='holding-configuration-section-sdg-wrapper'
            >
              <WrapperColumn
                styles={{ margin: '14.25px 0px 0px 0px', width: '40%' }}
              >
                <InputLabel
                  label='Holding Geography'
                  data-cy='holding-input-label-primary_sdg'
                />
                <Select
                  styles={{
                    container: provided => ({
                      ...provided,
                      width: '100%',
                      marginBottom: '16.5px',
                    }),
                    input: provided => ({
                      ...provided,
                      paddingTop: '8px',
                      paddingBottom: '4.75px',
                    }),
                  }}
                  onChange={option => {
                    holding.setPrimaryMarket(option.value)
                  }}
                  id='primary_market'
                  options={countryCodes}
                  defaultValue={getPrimaryMarket(holding.primary_market)}
                />
              </WrapperColumn>
              <WrapperColumn
                styles={{ margin: '14.25px 0px 0px 35px', width: '40%' }}
              >
                <InputLabel
                  label='Pipeline State'
                  data-cy='holding-input-label-primary_sdg'
                />
                <Select
                  styles={{
                    container: provided => ({
                      ...provided,
                      width: '100%',
                      marginBottom: '16.5px',
                    }),
                    input: provided => ({
                      ...provided,
                      paddingTop: '8px',
                      paddingBottom: '4.75px',
                    }),
                  }}
                  onChange={option => {
                    holding.setPipelineState(option.value)
                  }}
                  id='pipeline_state'
                  options={pipelineStateOptions}
                  defaultValue={getPipelineState(holding.pipeline_state)}
                />
              </WrapperColumn>
              <WrapperColumn
                styles={{ margin: '14.25px 0px 0px 0px', width: '84%' }}
                data-cy='holding-configuration-section-vertical-wrapper-inner'
              >
                <InputLabel
                  label='Holding Vertical'
                  data-cy='holding-input-label-vertical'
                />
                <Select
                  styles={{
                    container: provided => ({
                      ...provided,
                      width: '100%',
                      marginBottom: '16.5px',
                    }),
                    input: provided => ({
                      ...provided,
                      paddingTop: '8px',
                      paddingBottom: '4.75px',
                    }),
                  }}
                  onChange={selected => {
                    holding.setVertical(selected.value)
                  }}
                  id='vertical'
                  options={verticals}
                  error={errors.vertical}
                  defaultValue={getVertical(holding.vertical)}
                />
              </WrapperColumn>

              <WrapperColumn
                styles={{ margin: '14.25px 0px 0px 0px', width: '84%' }}
                data-cy='holding-configuration-section-sector-wrapper-inner'
              >
                <InputLabel
                  label='Holding Industry'
                  data-cy='holding-input-label-sector'
                />
                <Select
                  styles={{
                    container: provided => ({
                      ...provided,
                      width: '100%',
                      marginBottom: '16.5px',
                    }),
                    input: provided => ({
                      ...provided,
                      paddingTop: '8px',
                      paddingBottom: '4.75px',
                    }),
                  }}
                  onChange={selected => {
                    holding.setIndustry(selected.value)
                  }}
                  id='industry'
                  options={industries}
                  error={errors.industry}
                  defaultValue={getIndustry(holding.industry)}
                />
              </WrapperColumn>

              <WrapperColumn
                styles={{ margin: '14.25px 0px 0px 0px', width: '84%' }}
                data-cy='holding-configuration-section-weight-wrapper-inner'
              >
                <TextInput
                  error={errors.weight}
                  marginRight='30px'
                  label='Weight'
                  type='number'
                  width='40%'
                  id='name'
                  defaultValue={holding.weight}
                  onChange={(id, value) => {
                    holding.setWeight(value)
                  }}
                />
              </WrapperColumn>

              <WrapperColumn
                styles={{ margin: '14.25px 0px 0px 0px', width: '84%' }}
                data-cy='holding-configuration-section-investment-amount-wrapper-inner'
              >
                <TextInput
                  error={errors.value}
                  marginRight='30px'
                  label='Investment Amount'
                  type='number'
                  width='40%'
                  id='name'
                  defaultValue={holding.value}
                  onChange={(id, value) => {
                    holding.setValue(value)
                  }}
                />
              </WrapperColumn>
            </SectionWrapper>
            <SectionWrapper
              margin='0px 0px 5px 10px'
              width='84%'
              data-cy='holding-configuration-section-description-wrapper'
            >
              <TextArea
                label='Description'
                error={errors.description}
                width='100%'
                rows={5}
                id='description'
                marginRight='20px'
                value={description}
                onChange={(id, value) => setDescription(value)}
              />
            </SectionWrapper>
          </ModuleWrapper>
          <SectionWrapper
            justify='flex-end'
            width='100%'
            data-cy='holding-configuration-button-wrapper'
          >
            <Button onClick={onUpdate} width='20%' label='Save Changes' />
          </SectionWrapper>
          {showConfirmModal && (
            <ConfirmDialog
              show={showConfirmModal}
              onHide={() => setShowConfirmModal(false)}
              data-cy='holding-configuration-dialog'
            >
              <Modal.Header
                closeButton
                data-cy='holding-configuration-dialog-header'
              >
                <Modal.Title data-cy='holding-configuration-dialog-title'>
                  Are You Sure You Want To Make These Updates To Your Holding?
                </Modal.Title>
              </Modal.Header>
              <SectionWrapper
                justify='center'
                margin='20px 0px 0px 0px'
                data-cy='holding-configuration-dialog-button-wrapper'
              >
                <Button
                  margin='0px 15px 0px 0px'
                  width='30%'
                  label='Confirm'
                  onClick={onUpdateConfirm}
                />
                <Button
                  width='30%'
                  label='Close'
                  onClick={() => setShowConfirmModal(false)}
                />
              </SectionWrapper>
            </ConfirmDialog>
          )}
        </fieldset>
      </form>
    </>
  )
}

export default HoldingConfigurationSection
