import React, {Fragment, useState, useRef} from 'react';
import styled from 'styled-components';
import { FONT_COLOR_PRIMARY, FONT_COLOR_SECONDARY } from '../../styles/colors';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

const truncate = (str, length) => {
  if (str) {
    if (typeof str !== 'string') {
      str = str.toString()
    }
    let dots = str.length > length ? '...' : '';
    
    return str.substring(0, length) + dots;
  } else {
    return '';
  }
}

const Div = styled.div`
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  text-align: ${props => props.textAlign};
  color: ${props => props.color};
  margin-top: ${props => props.marginTop}; 
  margin-bottom: ${props => props.marginBottom};
`;

const TextView = ({ text, size, bold, secondary, right, center, marginTop, className, truncateLength, marginBottom }) => {
  const fontWeight = bold ? 'bold' : 'normal';
  const fontSize = isNaN(size) ? size : size + 'px';
  const fontColor = secondary ? FONT_COLOR_SECONDARY : FONT_COLOR_PRIMARY;
  const textAlign = right ? 'right' : (center ? 'center' : 'left');
  marginTop = isNaN(marginTop) ? marginTop : marginTop + 'px';

  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <Fragment>
      <Div
        fontSize={fontSize}
        fontWeight={fontWeight}
        color={fontColor}
        textAlign={textAlign}
        marginTop={marginTop}
        marginBottom={marginBottom}
        className={className}
        ref={target}
        onMouseEnter={() => (text.length >= truncateLength ? setShow(true) : '')}
        onMouseLeave={() => setShow(false)}
      >
        {(truncateLength ? truncate(text, truncateLength) : text)}
      </Div>
      <Overlay target={target.current} show={show} placement="bottom">
        {props => (
          <Tooltip id="overlay-example" {...props}>
            {text}
          </Tooltip>
        )}
      </Overlay>
    </Fragment>
  );
}

TextView.defaultProps = {
  size: 14,
  color: 'primary',
  marginTop: 0
};

export default TextView;
