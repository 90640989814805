import propTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import injectSheet from 'react-jss'

import cns from '../../helpers/classnames'
import { BG_COLOR_SECONDARY } from '../../styles/colors'

const Paginator = ({ classes, max, selected, onChange, className }) => {
  const [inputValue, setInputValue] = useState(selected)
  const [maxVal, setMaxVal] = useState(max)

  useEffect(() => {
    setInputValue(selected)
    setMaxVal(max)
  }, [selected, max])

  const onInputBlur = e => {
    e.preventDefault()

    if (isNaN(inputValue)) return setInputValue(selected)

    const page = Math.max(1, Math.min(max, parseInt(inputValue)))

    if (page != inputValue) {
      setInputValue(page)
    }

    onChange(page)
  }

  const onControlClick = control => {
    let page
    switch (control) {
      case 'first':
        page = 1
        break
      case 'previous':
        page = Math.max(1, parseInt(selected) - 1)
        break
      case 'next':
        page = Math.min(max, parseInt(selected) + 1)
        break
      case 'last':
        page = max
        break
    }

    onChange(page)
    setInputValue(page)
  }

  const renderPages = () => {
    return (
      <form onSubmit={onInputBlur} className={classes.input}>
        <input
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          onBlur={onInputBlur}
        />
        of {maxVal}
      </form>
    )
  }

  return (
    <div className={cns(classes.container, className)}>
      <span onClick={onControlClick.bind(null, 'first')}>
        <img src='/left-arrow-to-solid.svg' />
      </span>
      <span onClick={onControlClick.bind(null, 'previous')}>
        <img src='/left-arrow.svg' />
      </span>
      {renderPages()}
      <span onClick={onControlClick.bind(null, 'next')}>
        <img src='/right-arrow.svg' />
      </span>
      <span onClick={onControlClick.bind(null, 'last')}>
        <img src='/right-arrow-to-solid.svg' />
      </span>
    </div>
  )
}

Paginator.defaultProps = {
  onChange: () => {},
  max: 0,
  selected: 0,
}

Paginator.propTypes = {
  max: propTypes.number.isRequired,
  selected: propTypes.number.isRequired,
  onChange: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
}

const style = {
  container: {
    fontSize: '14px',
    lineHeight: '17px',
    color: '#6C757D',
    display: 'inline-block',
    userSelect: 'none',
    '& span': {
      cursor: 'pointer',
      display: 'inline-block',
      textAlign: 'center',
      height: 30,
      minWidth: 30,
      lineHeight: '30px',
      padding: '0 10px',
      '&.selected': {
        backgroundColor: BG_COLOR_SECONDARY,
      },
    },
  },
  input: {
    display: 'inline-block',
    padding: '0 10px',
    '& input': {
      height: '30px',
      background: '#FFFFFF',
      border: '1px solid #E6E7EA',
      borderRadius: '3px',
      maxWidth: 40,
      textAlign: 'center',
      marginRight: 10,
      outline: 'none',
    },
  },
}

export default injectSheet(style)(Paginator)
