import withFormControl from '@ui/components/compositions/withFormControl'
import withFormRow from '@ui/components/compositions/withFormRow/withFormRow'
import {
  BaseInput,
  IBaseInputProps,
} from '@ui/components/interactive/BaseInput'
import classNames from 'classnames'
import React from 'react'

// import theme from './NumberInput.module.scss'
import './NumberInput.module.scss'

export interface IProps extends Omit<IBaseInputProps, 'type'> {
  showArrows?: boolean
}

const NumberInput: React.FC<IProps> = ({
  showArrows,
  className,
  ...baseInputProps
}) => {
  return (
    <BaseInput
      {...baseInputProps}
      className={classNames(className, {
        hideArrows: !showArrows,
      })}
      type='number'
    />
  )
}

export default NumberInput

export const NumberInputControl = withFormControl<IProps>(NumberInput)

export const NumberInputRow = withFormRow<IProps>(NumberInput)
