import styled from 'styled-components'

export const StyledForm = styled.form`
  margin: 0 0 2rem;
  padding: 5px;
  background-color: #f1f1f1;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  .form-control {
    height: 30px;
    width: 100%;
    border: none;
    border-radius: 5px;
  }
  .user-select {
    padding: 7px 12px;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    color: #2e384d;
    -webkit-appearance: none; // hide arrow
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.01 5.304L8.3 2.065a.8.8 0 000-1.144.833.833 0 00-1.163 0L4.428 3.587 1.72.921a.833.833 0 00-1.164 0 .8.8 0 000 1.144l3.29 3.239a.831.831 0 001.163 0z' fill='%23979CA6'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;

    @media screen and (min-width: 768px) {
      max-width: 220px;
    }
  }
  .user-search-input {
    padding-right: 2px;
    padding-left: 27px;
    max-width: 100%;
    flex-grow: 1;
    font-size: 14px;
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.047 10.377h.745l4.708 4.717-1.406 1.406-4.717-4.707v-.746l-.254-.264a6.105 6.105 0 01-3.99 1.481 6.132 6.132 0 116.131-6.132 6.105 6.105 0 01-1.481 3.99l.264.255zm-9.16-4.245a4.24 4.24 0 004.245 4.245 4.24 4.24 0 004.245-4.245 4.24 4.24 0 00-4.245-4.245 4.24 4.24 0 00-4.245 4.245z' fill='%2394979B'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 5px center;

    &::placeholder {
      color: #828282;
    }
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      background: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.828 5.171a.999.999 0 00-1.414 0L8 6.585 6.586 5.171a1 1 0 10-1.415 1.414L6.586 8 5.171 9.414a1 1 0 001.415 1.414L8 9.414l1.414 1.414a1 1 0 001.414-1.414L9.414 8l1.414-1.415a.999.999 0 000-1.414zM8 14A6 6 0 118 2a6 6 0 010 12zM8 0C3.589 0 0 3.588 0 8c0 4.411 3.589 8 8 8s8-3.589 8-8c0-4.412-3.589-8-8-8z' fill='%23C0C3CA'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 0 center;
      background-size: contain;
      opacity: 0;
      pointer-events: none;
      &:hover {
        cursor: pointer;
      }
    }
    &:focus::-webkit-search-cancel-button {
      opacity: 1;
      pointer-events: all;
    }
  }
`
