import styled from 'styled-components';

export const StyledLandscape = styled.div`
  @page landscape {
    size: A4 landscape;
    margin-left: 0;
    margin-right: 0;
  }

  page: landscape;
`

export const StyledPortrait = styled.div`
  @page portrait {
    size: A4 portrait;
    margin-left: 0;
    margin-right: 0;
  }

  page: portrait;
`

export const StyledHeading = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

export const DocumentTitle = styled.h1`
  margin-bottom: 1rem;
`

export const StyledContent = styled.div`
  padding: 0 .5in;

  h2 {
    padding-top: 12px;
    border-top: 1px solid #C0C3CA;
    margin: 0;
    margin-bottom: 12px;
  }
`
