import propTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { addThousandSeparator } from '../../helpers/number'
import { WASTE_EXPLAINER_URL } from '../../yb_constants/urls/explainers'
import ImpactCard from './ImpactCard'

const ImpactCardContainer = styled.div`
  img {
    padding: 0px 15px 0px 15px;
  }
`
const WasteImpactCard = ({
  targetType,
  data,
  minHeight,
  benchmarkName,
  benchmarkData,
}) => {
  const total = parseInt(data.total)
  const benchmarkComparison = (
    ((data.total - benchmarkData?.total) / data.total) *
    100
  ).toFixed(2)
  const benchmarkComparisonAbs = Math.abs(benchmarkComparison).toFixed(2)

  return (
    <ImpactCardContainer>
      <ImpactCard
        targetType={targetType}
        value={total < 1 ? '< 1' : addThousandSeparator(total)}
        title='WASTE'
        holdingsPct={data.holdings_ownership_pct}
        unit={total == 1 ? 'African Bush Elephant' : 'African Bush Elephants'}
        description='equivalent weight of waste produced by<br/> the companies held in this portfolio <br/>in a year.'
        benchmarkDescription={
          benchmarkName && {
            text: `${benchmarkComparisonAbs}% ${
              benchmarkComparison > 0 ? 'more' : 'less'
            } than the ${benchmarkName}.`,
            color: '#D02F44',
          }
        }
        minHeight={minHeight}
        icons={
          <div className='icons'>
            <div>
              <img src={`/impact_icons/ico_waste.svg`} />
            </div>
          </div>
        }
        explainerHref={WASTE_EXPLAINER_URL}
      />
    </ImpactCardContainer>
  )
}

WasteImpactCard.propTypes = {
  data: propTypes.shape({
    total: propTypes.oneOfType([
      propTypes.number.isRequired,
      propTypes.string.isRequired,
    ]),
    total_perc: propTypes.oneOfType([
      propTypes.number.isRequired,
      propTypes.string.isRequired,
    ]),
  }).isRequired,
}

export default WasteImpactCard
