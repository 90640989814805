import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { APP_COLOR_PRIMARY } from '../../styles/colors'

const StyledLink = styled.a`
  color: ${props => props.styles.color || APP_COLOR_PRIMARY} !important;
  font-weight: ${props => props.styles.fontWeight || 'bold'};
  ${props =>
    props.styles.margin ? `margin: ${props.styles.margin};` : ''}
  ${props =>
    props.styles.fontSize ? `font-size: ${props.styles.fontSize};` : ''}
  ${props => (props.styles.display ? `display: ${props.styles.display};` : '')}
    ${props =>
    props.styles.verticalAlign
      ? `vertical-align: ${props.styles.verticalAlign};`
      : ''}

    cursor: pointer;
  :hover {
    color: ${props => props.styles.hoverColor || APP_COLOR_PRIMARY} !important;
    text-decoration:  ${props => props.styles.hoverUnderline || 'underline'} !important;
  }
`

const Link = forwardRef(
  ({ href, target, children, onClick, styles, className, ...props }, ref) => (
    <StyledLink
      className={className}
      styles={styles || {}}
      href={href}
      target={target}
      onClick={onClick}
      ref={ref}
      data-cy={props['data-cy']}
    >
      {children}
    </StyledLink>
  )
)
Link.displayName = 'Link'

export default Link
