import React from 'react';

const PortfolioDeletedEvent = ({ event }) => {
    return (
        <>
      <span className="event-user-name">
        {event.context.full_name}
      </span> destroyed portfolio <span className="event-user-name">
        {event.data.deleted_portfolio.name}
      </span>
            <span className="event-date">{ event.formattedTimestamp }</span>
        </>
    );
};

export default PortfolioDeletedEvent;
