import { useCallback, useEffect, useState } from 'react'

const usePaginationState = ({ page, totalPages, onChange }) => {
  const paginationState = useState({
    page: page || 1,
    totalPages: totalPages || 1,
  })
  const [pagination, setPagination] = paginationState

  useEffect(() => {
    setPagination(prevState => ({ ...prevState, totalPages }))
  }, [setPagination, totalPages])

  const setLocalPagination = useCallback(
    newPage => {
      setPagination(prevState => ({ ...prevState, page: newPage }))
      onChange({ page: newPage })
    },
    [onChange, setPagination]
  )

  return [pagination, setLocalPagination, setPagination]
}

export default usePaginationState
