import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledArrowsContainer = styled.div`
  margin-left: 4px;
  width: 9px;
  display: flex;
  flex-direction: column;

  & img {
    height: 9px;

    &:first-child {
      transform: rotateZ(180deg);
      margin-bottom: -2px;
    }
  }
`

export const SortArrows = ({ direction }) => {
  return (
    <StyledArrowsContainer>
      <img
        className='asc'
        src={`/ico_sort_${direction == 'asc' ? 'enabled' : 'disabled'}.svg`}
      />
      <img
        className='desc'
        src={`/ico_sort_${direction == 'desc' ? 'enabled' : 'disabled'}.svg`}
      />
    </StyledArrowsContainer>
  )
}

SortArrows.propTypes = {
  direction: PropTypes.string.isRequired,
}
