import moment from 'moment'

class CreateDisclosureModalController {
    private static minMaxAsOfDate: Date[] = [
        moment().subtract(3, 'year').toDate(),
        moment().add(3, 'year').toDate(),
    ]

    dateBetweenDisclosureRange(date: string) {
        const disclosureDate = new Date(date)
        const [rangeStart, rangeEnd] =
            CreateDisclosureModalController.minMaxAsOfDate

        if (disclosureDate < rangeStart || disclosureDate > rangeEnd) {
            return 'Date must be within 3 years of today'
        }
    }

    useMinMaxInputAttributes() {
        return CreateDisclosureModalController.minMaxAsOfDate.map(date =>
            moment(date).format('YYYY-MM-DD')
        )
    }
}

export default new CreateDisclosureModalController()
