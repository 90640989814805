import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'

import {
  APP_COLOR_PRIMARY,
  FONT_COLOR_PRIMARY,
  FONT_COLOR_SECONDARY,
} from '../../styles/colors'
import Card from './Card'

const StyledTabs = styled.div`
  width: 100%;
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
  .title {
    font-size: 24px;
    line-height: 29px;
    color: ${FONT_COLOR_PRIMARY};
    margin-bottom: 20px;
  }

  .tabs-container {
    display: flex;
    justify-content: flex-${props => (props.tabsPosition == 'left' ? 'start' : 'end')};
    margin-bottom: -1px;
    div,
    a {
      cursor: pointer;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: 23px;
      font-weight: 900;
      font-size: 14px;
      line-height: 17px;
      display: inline-block;
      color: ${FONT_COLOR_SECONDARY};
    }

    a {
      text-decoration: none;
    }

    a[disabled] {
      color: #dadadb;
    }
    .active {
      color: ${APP_COLOR_PRIMARY};
      border-bottom: 2px solid ${APP_COLOR_PRIMARY};
    }

    .pinned {
      margin-right: auto;
    }
  }
`

const StyledContent = styled(({ withCard, ...rest }) =>
  withCard ? <Card {...rest} /> : <div {...rest} />
)`
  margin-top: 12px;
`

const Tabs = ({
  title,
  tabs,
  pinTabsUntil,
  activeTab,
  showContentInCard = true,
  onChange,
  tabsPosition = 'right',
  links,
  style,
  headerComponent,
  headerComponentCondition = true,
  ...props
}) => {
  const [active, setActive] = useState(Object.keys(tabs)?.[0])

  const currentTab = activeTab || active

  const handleClick = tab => {
    if (!activeTab || !onChange) {
      setActive(tab)
    } else {
      onChange(tab)
    }
  }

  const tabClassNames = tab => {
    return [
      currentTab === tab ? 'active' : '',
      pinTabsUntil === tab ? 'pinned' : '',
    ]
      .join(' ')
      .trim()
  }

  return (
    <>
      <StyledTabs
        style={style}
        data-cy={props['data-cy']}
        tabsPosition={tabsPosition}
      >
        {title && <span className='title'>{title}</span>}

        {headerComponent && headerComponentCondition && headerComponent}

        <div className='tabs-container' data-cy='tabs-container'>
          {Object.keys(tabs).map((tab, i) =>
            links?.includes(tab) ? (
              tabs[tab]
            ) : (
              <div
                key={i}
                className={tabClassNames(tab)}
                data-cy={tab.toLowerCase().split(' ').join('-')}
                onClick={() => handleClick(tab)}
              >
                {tab}
              </div>
            )
          )}
        </div>
      </StyledTabs>
      <StyledContent withCard={showContentInCard} data-cy='tab-content'>
        {tabs[currentTab]}
      </StyledContent>
    </>
  )
}

Tabs.propTypes = {
  title: PropTypes.string,
  tabs: PropTypes.object,
  pinTabsUntil: PropTypes.string,
  activeTab: PropTypes.string,
  showContentInCard: PropTypes.bool,
  onChange: PropTypes.func,
  links: PropTypes.object,
  tabsPosition: PropTypes.oneOf(['right', 'left']),
  headerComponent: PropTypes.object,
  headerComponentCondition: PropTypes.bool,
  'data-cy': PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default Tabs
