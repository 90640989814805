import React from 'react'
import PropTypes from 'prop-types';


import XAxisLine from './xAxisLine'


const VerticalGrid = ({amount, classes, linedist, offset, xstart, xend, yscale}) => {

    
    return (
        <>
        {   
            Array(amount).fill().map((_, i) => {
                return <XAxisLine 
                key={i}
                xend={xend}
                classes={classes} 
                xstart={xstart}
                ystart={yscale(i) + (i*linedist) + offset}
                />
            }


            )
        }
        </>
    )
}

VerticalGrid.propTypes = {
    amount: PropTypes.number,
    classes : PropTypes.string,
    linedist : PropTypes.number,
    offset : PropTypes.number,
    xstart : PropTypes.number,
    xend : PropTypes.number,
    yscale : PropTypes.func,

}

export default VerticalGrid;