import React, { useState, useEffect } from 'react';
import { postFundQueryEvents } from "../../../../repositories/funds_repository";
import { FundTableWrapper, ModuleWrapper } from "../../styles";
import EventsTable from "../../../../components/event/EventsTable";
import {getNewPage} from "../../../research_center/helpers/paginator";

const FundActivitySection = ({fund}) => {
    const [events, setEvents] = useState(null);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const perPage = 10;

    useEffect(() => {
        postFundQueryEvents({fundID: fund.id, page: page, perPage: perPage}).then((response) => {
            setEvents(response.data.events);
            setPageCount(response.data.page_count);
        })
    }, []);

    const onPaginatorChange = (newPage) => {
        postFundQueryEvents({fundID: fund.id, page: newPage, perPage: perPage}).then((response) => {
            setEvents(response.data.events);
            setPage(newPage);
        })
    }

    return(
        <ModuleWrapper>
            <FundTableWrapper>
                { events && <EventsTable events={events}
                                         page={page}
                                         pageCount={pageCount}
                                         onPaginatorChange={onPaginatorChange} /> }
            </FundTableWrapper>
        </ModuleWrapper>
    )
};

export default FundActivitySection;
