export const SORT_SWITCH_OPTIONS = [
  { id: 'standard', label: 'By Position' },
  { id: 'entity', label: 'By Entity' },
  { id: 'sector', label: 'By Sector' },
]

export const POSITION_SWITCH_OPTIONS = [
  { id: 'all', label: 'All Positions', value: 'all' },
  { id: 'long', label: 'Long Positions Only', value: 'long' },
  { id: 'short', label: 'Short Positions Only', value: 'short' },
]

export const MODELED_SWITCH_OPTIONS = [
  { id: 'all', label: 'All Holdings', value: 'all' },
  { id: 'modeled', label: 'Modeled Only', value: 'modeled' },
  { id: 'unmodeled', label: 'Unmodeled Only', value: 'unmodeled' },
]

export const INITIAL_SORT_SPACE = { sort_field: 'weight', sort_dir: 'desc' }

export const HOLDINGS_FILTERS_COPY = {
  asset_type: {
    title: 'Asset Type',
    description:
      'Select the asset types you want to view. The asset types available to filter match the specific instrument types in your portfolio.',
  },
  coverage: {
    title: 'Coverage',
    description:
      'View just the modeled or unmodeled holdings in your portfolio. Modeled holdings have impact data such as ESG or carbon footprint metrics.',
  },
  properties: {
    title: 'Properties',
    description:
      'Filter by basic properties of holdings including long and short positions, market cap, revenue, sector, industry group and country of headquarters.',
  },
  metrics: {
    title: 'Metrics',
    description:
      'Narrow down your holdings with YvesBlue metrics including positive or negative impact badges and E, S, G, or Total ESG score.',
  },
}

export const FORM_FILTERS_DEFAULT = {
  market_cap: [],
  revenue: [],
  industry_group: [],
  business_sector: [],
  country_of_hq: [],
  positive_badge: [],
  negative_badge: [],
  e_score: [],
  s_score: [],
  g_score: [],
  total_score: [],
  coverage: 'all',
  position_type: 'all',
  asset_type: {},
}

export const FilterTypes = {
  MARKET_CAP: 'market_cap',
  REVENUE: 'revenue',
  INDUSTRY_GROUP: 'industry_group',
  BUSINESS_SECTOR: 'business_sector',
  COUNTRY_OF_HQ: 'country_of_hq',
  POSITIVE_BADGE: 'positive_badge',
  NEGATIVE_BADGE: 'negative_badge',
  E_SCORE: 'e_score',
  S_SCORE: 's_score',
  G_SCORE: 'g_score',
  TOTAL_SCORE: 'total_score',
  COVERAGE: 'coverage',
  POSITION_TYPE: 'position_type',
  ASSET_TYPE: 'asset_type',
}
