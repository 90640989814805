export const errorMessages = {
  email: 'You must enter a valid e-mail',
  confirmEmail: 'Your e-mail addresses must match',
}

//testing commits

export const tabNames = {
  YB_OVERVIEW: 'YVESBLUE OVERVIEW',
  ESG_SCORES: 'ESG SCORES',
  SDG_ALIGNMENT: 'SDG ALIGNMENT',
  ENVIRONMENTAL: 'ENVIRONMENTAL',
  SOCIAL: 'SOCIAL',
  GOVERNANCE: 'GOVERNANCE',
}

export const esgScoreFullNames = {
  e: 'Environmental',
  s: 'Social',
  g: 'Governance',
  total: 'Total ESG Score',
}

export const SDGDescriptions = {
  1: {
    description:
      'Funds and companies targeting this SDG will typically be looking to reduce levels of extreme poverty, increase access to utilities and basic services like heat, water, electricity, flooring, access to natural resources as well as protection mechanisms like inheritance. Funds and companies targeting zero poverty may also look to increase the resilience of vulnerable populations to climate disruptions as well as socioeconomic disasters. The interventions may be policy-driven, infrastructure or direct lending, etc.',
    title: 'SDG 1',
    yb_identifier: 'sdg_1',
    shortName: 'No Poverty',
    header: 'End poverty in all its forms everywhere',
  },
  2: {
    description:
      'Funds and companies targeting this SDG will typically be looking to provide safe, nutritious and affordable food to stop the effects of malnuroushment, to bolster incomes and sustainability practices for smallhold and local farmers, to desimminate and make free and accessible tech and genetic (seed) resources and to stabilize prices.',
    title: 'SDG 2',
    yb_identifier: 'sdg_2',
    shortName: 'Zero Hunger',
    header: 'Zero hunger',
  },
  3: {
    description:
      'Funds and companies targeting this SDG address one of the myriad ways health is threatened around the world, typically be looking to reduce maternal and childhood mortality rates, including through cheaper treatments and vaccine development for AIDS, tuberculosis, hepatitis and other water-borne and communicable and neglected tropical diseases. They may provide universal access to family planning, reproductive and educational services or to promote mental health and well-being. Alternately they may address the risks from global risks and deaths from traffic accidents or look to reduce the number of deaths and illnesses from hazardous chemicals and air, water and soil pollution and contamination or reduce the prevalence of tobacco, guns and other lethal products. Other than all that, they may work to better address global health risks and coordinate across nations.',
    title: 'SDG 3',
    yb_identifier: 'sdg_3',
    shortName: 'Good Health',
    header: 'Ensure healthy lives and promote well-being for all at all ages',
  },
  4: {
    description:
      'Funds and companies targeting this SDG may help provide free and equitable education for all starting from pre-primary. They may also provide or facilitate affordable technical, vocational training or university education. There are many ways to facilitate these objectives, including providing additional online learning tools, including breaking down structural or cultural barriers for marginalized groups that historically have not received sufficient resources (of which racial integration is a common obstacled). Direct and unrestricted investment in communities should be prioritized where possible.',
    title: 'SDG 4',
    yb_identifier: 'sdg_4',
    shortName: 'Quality Education',
    header: 'Quality education',
  },
  5: {
    description:
      'Funds and companies targeting this SDG work towards eliminating gender *and sexual-based violence of any kind. In addition, they may target giving equal access in decisionmaking and investment bodies of all kinds. This can be reducing structural barriers through education, policy or more positively with investments. As often is the case, investment may be the strongest signal among competing priorities.',
    title: 'SDG 5',
    yb_identifier: 'sdg_5',
    shortName: 'Gender Equality',
    header:
      'Achieve gender equality and empower all women, *gender queer and other LGBTQIA individuals',
  },
  6: {
    description:
      'Funds and companies targeting this SDG seek to provide safe affordable and equitable access to water for all, especially those in subjugated communities, those in critical situations or with health issues. This may be reducing chemical usage around water sources, preventing corporate usurping of water access, protection of important freshwater resources like aquifers, promoting food sources that are less water-intensive or investing in developing techniques like filtering and de-salinization that increase stock of potable water.',
    title: 'SDG 6',
    yb_identifier: 'sdg_6',
    shortName: 'Clean Water',
    header: 'Ensure access to water and sanitation for all',
  },
  7: {
    description:
      'Funds and companies targeting this SDG seek to provide safe affordable and equitable access to water for all, especially those in subjugated communities, those in critical situations or with health issues. This may be reducing chemical usage around water sources, preventing corporate usurping of water access, protection of important freshwater resources like aquifers, promoting food sources that are less water-intensive or investing in developing techniques like filtering and de-salinization that increase stock of potable water.',
    title: 'SDG 7',
    yb_identifier: 'sdg_7',
    shortName: 'Clean Energy',
    header:
      'Ensure access to affordable, reliable, sustainable and modern energy',
  },
  8: {
    description:
      'Funds and companies targeting this SDG look to provide economic growth that serves to reduce inequalities, especially in developing countries. They should promote equal pay for equal value, eliminate child labor and build sustainable and efficient supply chains, ensure access to banking and financial services to all, or promote local products and opportunities.',
    title: 'SDG 8',
    yb_identifier: 'sdg_8',
    shortName: 'Good Jobs',
    header:
      'Promote inclusive and sustainable economic growth, employment and decent work for all',
  },
  9: {
    description:
      'Funds and companies targeting this SDG should priotize infrastructure and innovation, providing incentives that reduce corporate rent-seeking and promote research and development. They may prioritize publicly available resources (traditional utilities like water, heat and electricity as well as newer ones like the internet) and and more open intellectual property as the underpinnings of societal value.',
    title: 'SDG 9',
    yb_identifier: 'sdg_9',
    shortName: 'Good Infrastructure',
    header:
      'Build resilient infrastructure, promote sustainable industrialization, foster scientific development and provide better institutional controls to facilitate',
  },
  10: {
    description:
      'Funds and companies targeting this SDG seek to improve the economic mobility of disadvantaged populations and eliminate exploitative and predatory financial practices, including excessive fees, artificial accomplishment and vocational barriers, regressive tax policies, and other anti-egalitarian mechanisms.',
    title: 'SDG 10',
    yb_identifier: 'sdg_10',
    shortName: 'Reduced Inequality',
    header: 'Reduce inequality within and among countries',
  },
  11: {
    description:
      'Funds and companies targeting this SDG may focus on food security, public transportation, waste management, disaster management, housing and provision of natural spaces, increasing traffic safety and air quality above all providing this affordably. Cultural preservation and diversity also help make cities resilient and long-lasting.',
    title: 'SDG 11',
    yb_identifier: 'sdg_11',
    shortName: 'Sustainable Communities',
    header: 'Make cities inclusive, safe, resilient and sustainable',
  },
  12: {
    description:
      "Funds and companies targeting this SDG work to make sure that consumer and industrial processes have the least waste and environmental damage associated with them from 'cradle to grave'. This means especially working to make sure corporations are being held responsible for their products throught the cycle rather than shifting that burden to consumers and municipalities and that they are adequately paying for the resources they use. Additionally, anything that reduces production of items intended to be single-use is a plus, particularly in the packaging aspects. Tradeoffs between materials are important, specifically the provision of renewable materials and reduced water and fiber use.",
    title: 'SDG 12',
    yb_identifier: 'sdg_12',
    shortName: 'Responsible Consumption',
    header: 'Ensure sustainable consumption and production patterns',
  },
  13: {
    description:
      'Funds and companies targeting this SDG work to reduce CO2 emissions globally as well as to mitigate and protect climate-related disasters and dislocations. Any mitigation around climate crises should not be pursued at the cost to preventative measures. Focus should be on developing markets without robust infrastructure and technology. Low-tech and solutions that integrate local cultures and conditions should be preferred, especially and most importantly that give agency to those cultures and local actors.',
    title: 'SDG 13',
    yb_identifier: 'sdg_13',
    shortName: 'Climate Action',
    header: 'Take urgent action to combat climate change and its impacts',
  },
  14: {
    description:
      'Funds and companies targeting this SDG may work to prevent over-fishing as well as ocean pollution, including water sources that lead to the ocean. Plastic pollution is a crucial area here, both inland and coastal, from sources like packaging, fishing nets, as well as tyres. The overall aim is to maximize natural biodiversity and to minimize species loss but there are many avenues.',
    title: 'SDG 14',
    yb_identifier: 'sdg_14',
    shortName: 'Life Below Water',
    header:
      'Conserve and sustainably use the oceans, seas and marine resources',
  },
  15: {
    description:
      'Funds and companies targeting this SDG look to clean up and restore contaminated sites as well as protecting existing animals and ecosystems. Soil health is a crucial area here and one that can be addressed by removing chemicals used on the land and the organisms that live off of it. Reducing poaching and trafficking is a key aim, but also making sure protective mechanisms are in place to prevent more mundane human destruction of animals and habitats through encroachment and overuse.',
    title: 'SDG 15',
    yb_identifier: 'sdg_15',
    shortName: 'Life On Land',
    header:
      'Sustainably manage forests, combat desertification, halt and reverse land degradation, halt biodiversity loss',
  },
  16: {
    description:
      'Funds and companies targeting this SDG may target legal mechanisms and institutional protections to reduce and eliminate death, torture and unjust incarceration. Strengthen mechanisms against organized crime and allow for more investment in social goods than police and military arms. Diverting assets from entities heaviliy involved in corruption, bribery or regulatory capture through excessive lobbying or influence peddling is a huge component of effective governance.',
    title: 'SDG 16',
    yb_identifier: 'sdg_16',
    shortName: 'Strong Institutions',
    header: 'Promote just, peaceful and inclusive societies',
  },
}
