import withFormControl from '@ui/components/compositions/withFormControl'
import withFormRow from '@ui/components/compositions/withFormRow/withFormRow'
import withInputValidations from '@ui/components/compositions/withInputValidations'
import {
    BaseInput,
    IBaseInputProps,
} from '@ui/components/interactive/BaseInput'

import Controller from './URLInput.controller'

type IURLInputProps = Omit<IBaseInputProps, 'type'>

const URLInput = withInputValidations<IBaseInputProps, string>(
    BaseInput,
    [Controller.matchesURL],
    { type: 'url' }
)

export default URLInput

export const URLInputControl = withFormControl<IURLInputProps>(URLInput)

export const URLInputRow = withFormRow<IURLInputProps>(URLInput)
