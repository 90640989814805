import _ from 'lodash'
import { useHistory } from 'react-router'

/**
 * Hook used to give the ability to navigate programmatically
 * @returns navigateTo() function that can be called to navigate to another page (based on the given url)
 */
const useNavigation = () => {
  const history = useHistory()

  const navigateTo = url => {
    history.push(url)
    history.go(_.first(history))
  }

  return { navigateTo }
}

export default useNavigation
