import axios from 'axios'

export const getTcfdByPortfolioSnapshot = async (
  page,
  portfolioId,
  snapshotId,
  sortDir,
  sortField
) => {
  const dir = sortDir ?? 'desc'
  const field = sortField ?? 'weight'
  const pageNum = page ?? '1'

  try {
    const res = await axios.get(
      `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/tcfd?sort_field=${field}&sort_dir=${dir}&page=${pageNum}`
    )
    return res.data
  } catch (e) {
    console.error(e)
  }
}

export const updateTcfdCustomWeight = async (
  portfolioId,
  snapshotId,
  payload
) => {
  try {
    const res = await axios.post(
      `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/tcfd_setting`,
      payload
    )
    return res.data
  } catch (e) {
    console.error(e)
  }
}

export const getTcfdHoldingDetails = async (
  holdingId,
  portfolioId,
  snapshotId
) => {
  try {
    const res = await axios.get(
      `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/holdings/${holdingId}/tcfd`
    )
    return res.data
  } catch (e) {
    console.error(e)
  }
}

export const getTcfdInstrument = async instrumentId => {
  try {
    const res = await axios.get(`/app/api/instruments/${instrumentId}/tcfd`)
    return res.data
  } catch (e) {
    console.error(e)
  }
}

export const getTcfdPortfolioOverallScore = async (snapshotId, portfolioId) => {
  try {
    const res = await axios.get(
      `/app/api/portfolios/${snapshotId}/snapshots/${portfolioId}/tcfd`
    )
    return res.data
  } catch (e) {
    console.error(e)
  }
}

export const getTcfdDataSheet = async (
  name,
  page,
  limit,
  sortField,
  sortDirection
) => {
  try {
    const res = await axios.get(
      `/app/api/carbon_report/tcfd/${name}?page=${page}&limit=${limit}&sort_field=${sortField}&sort_dir=${sortDirection}`
    )
    return res.data
  } catch (e) {
    console.error(e)
  }
}

export const postTcfdNote = async (
  value,
  setLoading,
  sector,
  holdingId,
  portfolioId,
  snapshotId,
  setNoteList,
  setError,
  setValue
) => {
  setLoading(true)
  setError(false)
  const payload = {
    tcfd_sector: sector,
    body: value,
  }
  try {
    const res = await axios.post(
      `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/holdings/${holdingId}/tcfd/note`,
      payload
    )
    setNoteList(res.data.peers_tcfd_data[sector].notes)
    setValue('')
  } catch (e) {
    setError(e.response.data)
    console.error(e)
  } finally {
    setLoading(false)
  }
}
