import React, { useState } from 'react'

import IconLink from '../../../../../../yb_components/styleguide/links/IconLink'
import DeleteSnapshotDialog from '../../../../snapshot/components/DeleteSnapshotDialog'

const DeleteSnapshotCell = ({
  snapshotId,
  portfolioId,
  asOf,
  reportData,
  setReportData,
}) => {
  const [showDialog, setShowDialog] = useState(false)

  const handleDelete = id => {
    const tempData = [...reportData].filter(snapshot => snapshot.id != id)
    setReportData(tempData)
  }

  return (
    <>
      <td className='icon-cell'>
        <IconLink
          onClick={() => {
            setShowDialog(true)
          }}
          src={'/ico-delete.svg'}
        />
      </td>
      <DeleteSnapshotDialog
        snapshotId={snapshotId}
        portfolioId={portfolioId}
        asOf={asOf}
        show={showDialog}
        setShow={setShowDialog}
        onDelete={() => handleDelete(snapshotId)}
        redirectTo={reportData?.length == 1 ? '/app' : null}
      />
    </>
  )
}

export default DeleteSnapshotCell
