import styled, { keyframes } from 'styled-components';
import { BG_COLOR_PRIMARY } from '../../styles/colors';

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    display: none;
  }
`;

const Alert = styled.div`
    padding:0.75rem 1.25rem;
    width:100%;
    animation: ${fadeOut} 3.25s linear forwards;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: ${props => 
        {
            if(props.type === "error"){
                return `#721c24;`;
            } else if (props.type === "success"){
                return `#004085;`;
            }
        }
    };
    background-color: ${props => 
        {
            if(props.type === "warning"){
                return `#f8d7da;`
            } else if (props.type === "success"){
                return `${BG_COLOR_PRIMARY};`;
            }
        }
    };
    border-color: ${props => 
        {
            if(props.type === "warning"){
                return `#f5c6cb;`
            } else if (props.type === "success"){
                return `${BG_COLOR_PRIMARY};`;
            }
        }
    };
`;

export default Alert;
