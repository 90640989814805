import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {
  PAI_COLOR_GREEN,
  PAI_COLOR_RED,
  PAI_COLOR_YELLOW,
} from '../../utils/pai_colors'

const StyledList = styled.div`
  --pai-table-gray-dark: #6c757d;

  margin: 0;
  padding: 0;
  width: auto;
  display: flex;
  list-style-type: none;

  .legend-cell {
    margin: 0;
    padding: 0;
    width: 70px;
    height: auto;
    text-align: center;
  }
  strong {
    margin: 0;
    display: block;
    font-size: 12px;
    line-height: 1;
    color: var(--pai-table-gray-dark);
  }
  span {
    font-size: 12px;
    line-height: 1;
    color: var(--pai-table-gray-dark);
  }
`
const ColorBlock = styled.div`
  margin: 0 0 6px 0;
  width: 100%;
  height: 15px;
  background-color: ${props => props.bgColor || '#ffffff'};
`

export const PaiLegend = ({ style }) => {
  return (
    <StyledList style={style}>
      <li className='legend-cell'>
        <ColorBlock bgColor={PAI_COLOR_RED}></ColorBlock>
        <strong>Low</strong>
        <span>(&lt; 33%)</span>
      </li>
      <li className='legend-cell'>
        <ColorBlock bgColor={PAI_COLOR_YELLOW}></ColorBlock>
        <strong>Medium</strong>
        <span>(34% - 66%)</span>
      </li>
      <li className='legend-cell'>
        <ColorBlock bgColor={PAI_COLOR_GREEN}></ColorBlock>
        <strong>High</strong>
        <span>(&gt; 67%)</span>
      </li>
    </StyledList>
  )
}

PaiLegend.propTypes = {
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}
