import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Card from '../../../../../components/styleguide/Card'
import { FONT_COLOR_SECONDARY } from '../../../../../styles/colors'

const StyledDiv = styled(Card)`
  margin: 0;
  padding: 1.25rem;
  border-radius: 6px;
  background-color: #fff;

  header {
    margin: 0 0 1.25rem 0;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;

    h3 {
      margin: 0;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.2;
      text-transform: uppercase;
    }
    p {
      margin: 0;
      color: ${FONT_COLOR_SECONDARY};
    }
  }

  main {
    overflow: hidden;
    overflow-x: scroll;
  }
`

export const TcfdHoldingsCard = ({ children }) => {
  return <StyledDiv>{children}</StyledDiv>
}

TcfdHoldingsCard.propTypes = {
  children: PropTypes.node.isRequired,
}
