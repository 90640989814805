import React from 'react';
import { Col } from 'react-bootstrap';
import { abbreviateNumber } from '../../../helpers/number';
import { APP_COLOR_PRIMARY } from '../../../styles/colors';
import OverTimeLegendItem from '../../../yb_components/styleguide/legend_items/OverTimeLegendItem';

const OverTimeLegend = ({ totals, propertyName }) => {
  if (!totals || !propertyName) return null;

  let label = 'Total';
  let propertyValue = 'total'

  let elem = totals.find((totalElem) => totalElem[propertyName] === propertyValue);
  if (!elem) elem = { subsidy_level: propertyValue, amount: 0 };

  const abbreviatedNumber = abbreviateNumber(elem.amount);

  return (
    <>
      <Col />
      <Col />
      <OverTimeLegendItem
        key={`${label}${elem.amount}`}
        label={label}
        bigLabel={`$ ${abbreviatedNumber || '0 M'}`}
        color={APP_COLOR_PRIMARY}
      />
    </>
  );
}

export default OverTimeLegend;
