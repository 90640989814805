const summaryHeaders = [
  { label: 'COMPANY NAME', id: 'name', sort: true },
  { label: 'TICKER', id: 'ticker', sort: true },
  { label: 'ISIN', id: 'isin', sort: true },
  { label: 'MARKET CAP', id: 'market_cap', sort: true },
  { label: 'REVENUE', id: 'revenue', sort: true },
  { label: 'COUNTRY', id: 'country_of_hq', sort: true },
  { label: 'SECTOR', id: 'business_sector', sort: true },
  { label: 'INDUSTRY GROUP', id: 'industry_group', sort: true },
]

const esgHeaders = [
  { label: 'COMPANY NAME', id: 'name', sort: true },
  { label: 'TICKER', id: 'ticker', sort: true },
  { label: 'ISIN', id: 'isin', sort: true },
  { label: 'E SCORE', id: 'e_score', sort: true },
  { label: 'S SCORE', id: 's_score', sort: true },
  { label: 'G SCORE', id: 'g_score', sort: true },
  { label: 'TOTAL SCORE', id: 'total_score', sort: true },
]

const sdgHeaders = [
  { label: 'COMPANY NAME', id: 'name', sort: true },

  { label: 'SDG 1', id: 'sdg_1', sort: true },
  { label: 'SDG 2', id: 'sdg_2', sort: true },
  { label: 'SDG 3', id: 'sdg_3', sort: true },
  { label: 'SDG 4', id: 'sdg_4', sort: true },
  { label: 'SDG 5', id: 'sdg_5', sort: true },
  { label: 'SDG 6', id: 'sdg_6', sort: true },
  { label: 'SDG 7', id: 'sdg_7', sort: true },
  { label: 'SDG 8', id: 'sdg_8', sort: true },
  { label: 'SDG 9', id: 'sdg_9', sort: true },
  { label: 'SDG 10', id: 'sdg_10', sort: true },
  { label: 'SDG 11', id: 'sdg_11', sort: true },
  { label: 'SDG 12', id: 'sdg_12', sort: true },
  { label: 'SDG 13', id: 'sdg_13', sort: true },
  { label: 'SDG 14', id: 'sdg_14', sort: true },
  { label: 'SDG 15', id: 'sdg_15', sort: true },
  { label: 'SDG 16', id: 'sdg_16', sort: true },
]

const carbonHeaders = [
  { label: 'COMPANY NAME', id: 'name', sort: true },
  { label: 'TICKER', id: 'ticker', sort: true },
  { label: 'ISIN', id: 'isin', sort: true },
  { label: 'SCOPE 1', id: 'co2_scope_1_modeled', sort: true },
  { label: 'SCOPE 2', id: 'co2_scope_2_modeled', sort: true },
  { label: 'SCOPE 3', id: 'co2_scope_3_modeled', sort: true },
  { label: 'TOTAL', id: 'co2_scope_total', sort: true },
]

const MANAGER_DB_HEADERS = [
  { label: 'INVESTMENT NAME', id: 'investment_name', sort: true },
  { label: 'ASSET CLASS', id: 'asset_class_id', sort: true },
  { label: 'STRATEGY AUM', id: 'strategy_aum_dollars', sort: true },
  { label: 'MATRIX 1 SCORE', id: 'first_impression', sort: true },
  { label: 'MATRIX 2 SCORE', id: 'initial_evaluation', sort: true },
  { label: 'MATRIX 3 SCORE', id: 'final_evaluation', sort: true },
]

export {
  carbonHeaders,
  esgHeaders,
  MANAGER_DB_HEADERS,
  sdgHeaders,
  summaryHeaders,
}
