import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _find from 'lodash.find';
import _isEmpty from 'lodash.isempty';

import BaseDialog from './BaseDialog';
import TextView from '../styleguide/TextView';
import SelectInput from '../styleguide/SelectInput';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextInput from '../styleguide/TextInput';
import InputLabel from '../styleguide/InputLabel';
import SwitchButton from '../styleguide/SwitchButton';
import Button from '../styleguide/Button';
import Span from '../styleguide/Span';
import { BG_COLOR_SECONDARY, APP_COLOR_PRIMARY, FONT_COLOR_PRIMARY } from '../../styles/colors';
import { createFocusArea } from '../../repositories/risk_assessments_repository';
import { mapStateToRiskAssessment } from '../../mappers/risk_assessment_mapper';
import DialogControls from './DialogControls';

const DialogContent = styled.div`
  .create-initiative-initiative-select {
    color: ${APP_COLOR_PRIMARY};
    font-weight: bold;
    option {
      color: ${FONT_COLOR_PRIMARY};
    }
  }
  .create-initiative-publish-form-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .create-initiative-publish-label {
      margin-bottom: 0;
    }
  }
  .create-initiative-current-unit-input-group {
    display: flex;
    align-items: flex-end;
    > * {
      width: 100%;
    }
  }
  .create-initiative-form-controls {
    margin-top:
  }
`;

const uploadInitiative = (holdingId, data)  => {

  if (data.initiative_id === "custom") {
    data.custom_initiative = {
      name: data.initiative_name,
      unit: data.unit,
      category: data.category,
      description: data.description
    };
  }

  return createFocusArea(holdingId, data);
};

const getInitialValues = (options, category) => {
  const values = {
    target_value: "",
    current_value: "",
    description: "",
    initiative_name: "",
    publish: false,
    category
  }

  if(options.length === 1) {
    values.initiative = "custom";
    values.unit = null;
  } else {
    const opt = options[0];

    values.initiative_id = opt.id;
    values.initiative_label = opt.label;
    values.target_value = opt.target || (opt.target_value ? `${opt.target_value} ${opt.target_unit}` : "");
    values.unit = opt.unit;
  }

  return values;
}

const AddFocusAreaDialog = ({ open, options, holdingId, category, units, onClose, onSuccess }) => {
  const [isCustom, setIsCustom] = useState(options.length === 1);
  const [values, setValues] = useState(getInitialValues(options, category));

  const [errors, setErrors] = useState({
    target_value: "",
    current_value: "",
    unit: "",
    description: ""
  });

  useEffect(() => {
    setIsCustom(options.length === 1);
    setValues(getInitialValues(options, category));
  }, [options]);

  const onInputChange = (id, value) => {
    const newValues = { ...values };
    let newErrors = { ...errors };

    if (id === "target-unit") {
        newValues.unit = value;
        setValues(newValues);
    } else if (id === "initiative_id") {
        setIsCustom(value === "custom");

      if (value === "custom") {
        newValues.unit = "kg";
        setValues(newValues);

        null
      } else {
        let newInitiative = null;
        options.forEach((init) => {
          if (init.id == value) {
            newInitiative = init;
          }
        });

        if (newInitiative) {
          newValues.unit = newInitiative.unit;
        } else {
          newValues.unit = null;
        }
      }
    }

    newValues[id] = value;
    newErrors[id] = "";

    setValues(newValues);
    setErrors(newErrors);
  }

  const onFormSubmit = e => {
    e.preventDefault();

    const validation = {};

    if(isCustom && !values.target_value) validation.target_value = "Target Value is required";
    if(isCustom && !values.unit) validation.unit = "Unit is required";
    if(isCustom && !values.initiative_name) validation.initiative_name = "Name is required";
    if(isCustom && !values.description) validation.description = "Description is required";
    if(!values.current_value) validation.current_value = "Current Value is required";

    if(_isEmpty(validation)) {
      uploadInitiative(holdingId, { ...values, custom: isCustom, holding_id: holdingId, category })
          .then(res => {
            onSuccess(res.data);
            onClose();
          });

    } else {
      setErrors(validation);
    }
  }

  const onCloseDialog = () => {
    setErrors({});
    setValues(getInitialValues(options, category));
    onClose();
  }

  const renderTargetValue = () => {
    // if(isCustom) {
    return (
        <>
          <Col xs={3}>
            <TextInput
                id="target_value"
                value={values.target_value}
                error={errors.target_value}
                type="number"
                onChange={onInputChange}
                label="TARGET VALUE"
            />
          </Col>
          <Col className="create-initiative-current-unit-input-group" xs={3}>
            <SelectInput
                onChange={onInputChange}
                value={values.unit}
                error={errors.unit}
                disabled={!isCustom}
                id="target-unit"
                options={unitOptions()}
            />
          </Col>
        </>
    );
    // } else {
    //   return (
    //     <Col xs={6}>
    //       <SelectInput
    //         disabled
    //         onChange={onInputChange}
    //         id="target_value"
    //         options={[{ id: values.initiative, label: values.target_value }]}
    //         label="TARGET VALUE FOR YOUR INITIATIVE"
    //         value={values.initiatiative}
    //       />
    //     </Col>
    //   );
    // }
  }
  const switchOptions = [
    { id: "published", label: "Published"},
    { id: "unpublished", label: "Unpublished"}
  ];

  const unitOptions = () => {
    if (isCustom) {
      return [{ id: "kg", label: "kg" }, { id: "%", label: "%" }] ;
    }
    else {
      return [{ id: values.unit, label: values.unit }];
    }
  };

  return (
      <BaseDialog padding={25} title="Add New Initiative" open={open} onClose={onCloseDialog} render={() => (
          <DialogContent>
            {/* <TextView marginTop={10} secondary text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum in suscipit sem. Mauris molestie pulvinar faucibus. Phasellus rutrum lobortis est et pretium." /> */}
            <form onSubmit={onFormSubmit}>
              <Row>
                <Col xs={6}>
                  <SelectInput
                      id="initiative_id"
                      className="create-initiative-initiative-select"
                      options={options}
                      value={values.initiative_id}
                      onChange={onInputChange}
                      label="SELECT INITIATIVE"
                  />
                </Col>
                <Col xs={6}>
                  {isCustom ?
                      <TextInput
                          value={values.initiative_name}
                          id="initiative_name"
                          onChange={onInputChange}
                          label="INITIATIVE NAME"
                          error={errors.initiative_name}
                      />
                      : null
                  }
                </Col>
              </Row>
              <Row>
                {renderTargetValue()}
                <Col xs={3}>
                  <TextInput
                      id="current_value"
                      value={values.current_value}
                      type="number"
                      onChange={onInputChange}
                      label="CURRENT VALUE"
                      error={errors.current_value}
                  />
                </Col>
                <Col className="create-initiative-current-unit-input-group" xs={3}>
                  <SelectInput
                      onChange={onInputChange}
                      value={values.unit}
                      error={errors.unit}
                      disabled={!isCustom}
                      id="target-unit"
                      options={unitOptions()}
                  />
                </Col>
              </Row>
              {
                isCustom &&
                  <Row>
                  <Col xs={12}>
                    <TextInput
                        onChange={onInputChange}
                        value={values.description}
                        error={errors.description}
                        id="description"
                        label="DESCRIBE YOUR INITIATIVE IN A FEW WORDS" placeholder="Description"
                    />
                  </Col>
                </Row>
              }
              <DialogControls onClose={onClose} />
            </form>
          </DialogContent>
      )} />
  );
};
export default AddFocusAreaDialog;
