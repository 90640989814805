/**
 * Spit array into several groups / chunks
 *
 * @param {array[]} arr The array that will be splitted
 * @param {number} size The size of each group / chunk
 * @returns A matrix containing several arrays resulted from the splitting process
 */
export const splitArrayInGroups = (arr, size) => {
  if (!arr?.length) return []

  var result = []
  for (var i = 0; i < arr.length; i += size) result.push(arr.slice(i, i + size))
  return result
}

/**
 * Expands the array with the same elements based on the newLength property
 *
 * @param {*} arr The array that will be repeated
 * @param {*} newLength The new length of the repeatd array
 * @returns the generated array
 */
export const repeatArray = (arr, newLength) => {
  let result = []
  for (let index = 0; index < newLength; index++) {
    result.push(arr[index % arr.length])
  }
  return result
}
