import styled from 'styled-components';

export const StyledCell = styled.td`
  font-size: 18px;
  font-weight: 700;
  border: none;
  width: 16%;

  td& svg, td& img {
    padding: 1px;
  }
`
