import React, { useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import { ESG_SCALE_COLOR_BAD, ESG_SCALE_COLOR_EXCELLENT, ESG_SCALE_COLOR_ABOVE_AVERAGE } from '../../styles/colors';
import calculateSize from 'calculate-size';
import numeral from 'numeral';

const newLabelCoords = { x: 10, y: 45 };

const Global = createGlobalStyle`
  .rev-segment-tooltip {
    font-weight: bold;
    background: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    transform: translate(-50px, -60px);
    text-align: center;

    :after {
      content: "▼";
      position: absolute;
      bottom: -12px;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 20px;
      color: white;
      transform: scaleY(0.5);
      text-shadow: 0px 2px 2px rgba(0, 0, 0 ,0.2);
    }
  }
`;

function moveOriginalLabels(data) {
  const container = document.getElementById('treemap-chart-container');
  Array.prototype.forEach.call(container.getElementsByTagName('text'), function (text, j) {
    var bounds = text.getBBox();
    var rect = text.parentNode.getElementsByTagName('rect')[0];
    if ((rect.getAttribute('fill') !== '#cccccc') && (text.getAttribute('text-anchor') === 'middle')) {
      text.setAttribute('fill', 'transparent');
      text.setAttribute('font-weight', 'bold');
      text.setAttribute('x', parseFloat(rect.getAttribute('x')) + newLabelCoords.x + (bounds.width / 2));
      text.setAttribute('y', parseFloat(rect.getAttribute('y')) + newLabelCoords.y);
    }
  });
}

function addChildLabels(data) {
  const container = document.getElementById('treemap-chart-container');
  // hold new labels
  var childCount = [];
  var childLabels = [];

  // svg namespace
  var svgNS = container.getElementsByTagName('svg')[0].namespaceURI;

  // find existing / build new labels
  Array.prototype.forEach.call(container.getElementsByTagName('text'), function (text, j) {
    if (text.getAttribute('text-anchor') === 'middle') {
      var rect = text.parentNode.getElementsByTagName('rect')[0];

      // exclude top node
      if (rect.getAttribute('fill') !== '#cccccc') {
        rect.setAttribute('stroke-width', 5);
        moveOriginalLabels(data);

        var textContent;
        var label;
        for (var i = 0; i < data.getNumberOfRows(); i++) {
          if (data.getValue(i, 0).indexOf(text.textContent.replace('…', '')) > -1) {
            textContent = data.getValue(i, 5) + '%';
            label = data.getValue(i, 0);
          }
        }

        // add child count
        var textCount = document.createElementNS(svgNS, 'text');
        textCount.setAttribute('fill', 'white');
        textCount.setAttribute('font-family', 'Lato');
        textCount.setAttribute('font-size', '24');
        textCount.setAttribute('font-weight', 'bold');
        textCount.setAttribute('width', 50);
        textCount.setAttribute('x', parseFloat(rect.getAttribute('x')) + newLabelCoords.x);
        textCount.setAttribute('y', parseFloat(text.getAttribute('y')) - parseFloat(text.getAttribute('font-size')) - 2);
        textCount.textContent = textContent;

        childCount.push([text, textCount]);

        function breakText(text, parent) {
          const width = parent.getAttribute('width');
          const parts = text.split(' ');
          const brokenText = [''];

          // get the length of the first word
          const size = calculateSize(parts[0], {
            font: 'Lato',
            fontSize: 12,
            fontWeight: 'bold'
          });

          // if first word won't fit return ellipsis
          if (size.width > width - 10) return [getEllipsis(text, parent)];
          // else break text
          else {
            let lineCount = 0;
            for (let i = 0; i < parts.length; i++) {
              const lineText = brokenText[lineCount] + ' ' + parts[i];
              const lineSize = calculateSize(lineText, {
                font: 'Lato',
                fontSize: 12,
                fontWeight: 'bold'
              });

              if (lineSize.width > width - 15) {
                lineCount++;
                brokenText[lineCount] = parts[i];
              } else {
                brokenText[lineCount] = lineText;
              }
            }

            return brokenText.map(t => t.trim());
          }

        }

        function getEllipsis(label, parent) {
          var ellipsis = '';

          for (let i = 0; i < label.length; i++) {
            const text = ellipsis + label[i];
            const size = calculateSize(text, {
              font: 'Lato',
              fontSize: 12,
              fontWeight: 'bold'
            });

            if (size.width < parent.getAttribute('width') - 20) {
              ellipsis = text;
            }
          }

          if (ellipsis.length < label.length) {
            ellipsis = ellipsis.trim() + '…';
          }

          return ellipsis;
        }

        // add 'Children' label
        const brokenText = breakText(label, rect);
        brokenText.forEach((t, i) => {
          var textLabel = document.createElementNS(svgNS, 'text');
          textLabel.setAttribute('fill', 'white');
          textLabel.setAttribute('font-family', 'Arial');
          textLabel.setAttribute('font-size', 12);
          textLabel.setAttribute('font-weight', 'bold');
          textLabel.setAttribute('x', parseFloat(rect.getAttribute('x')) + newLabelCoords.x);
          textLabel.setAttribute('y', parseFloat(textCount.getAttribute('y')) + ((i + 1) * parseFloat(textLabel.getAttribute('font-size'))) + 2);
          textLabel.textContent = t;
          childLabels.push([text, textLabel]);
        })
      }
    }
  });

  // append new labels
  childCount.forEach(function (text) {
    text[0].parentNode.appendChild(text[1]);
  });
  childLabels.forEach(function (text) {
    text[0].parentNode.appendChild(text[1]);
  });
}

const TreemapChart = ({ data: rawData, className }) => {

  const renderTootips = (value) => {
    return `
      <div class="rev-segment-tooltip">$${numeral(value).format('0a').toUpperCase()}</div>
    `;
  }

  useEffect(() => {

    if (window.google) {
      google.charts.load('current', {
        packages: ['treemap']
      }).then(function () {

        function getMinimumDisplayPercentage(percent) {
          const PERCENTAGE_THRESHOLD = 5;
          const MINIMUM_DISPLAY_PERCENTAGE = 8;

          return (percent <= PERCENTAGE_THRESHOLD) ? MINIMUM_DISPLAY_PERCENTAGE : percent
        }

        const data = rawData.map(r => [r.title, 'Global', getMinimumDisplayPercentage(r.percent), r.value, r.tooltip, r.percent]);
        var dataTable = google.visualization.arrayToDataTable([
          ['Location', 'Parent', 'DisplayPercentage', 'Score', 'Tooltip', 'RealPercentage'],
          ['Global', null, 0, 0, 0, 9],
          ...data
        ]);

        var container = document.getElementById('treemap-chart-container');
        var tree = new google.visualization.TreeMap(container);

        var observer = new MutationObserver(() => moveOriginalLabels(dataTable));
        observer.observe(container, {
          childList: true,
          subtree: true
        });

        google.visualization.events.addListener(tree, 'ready', () => addChildLabels(dataTable));
        google.visualization.events.addListener(tree, 'select', () => {
          tree.setSelection([]);
          addChildLabels(dataTable);
        });


        drawTree();
        window.addEventListener('resize', drawTree);

        function drawTree() {
          tree.draw(dataTable, {
            minColor: ESG_SCALE_COLOR_BAD,
            midColor: ESG_SCALE_COLOR_ABOVE_AVERAGE,
            maxColor: ESG_SCALE_COLOR_EXCELLENT,
            headerHeight: 0,
            height: 350,
            minColorValue: 0,
            maxColorValue: 100,
            width: '100%',
            fontColor: 'white',
            fontFamily: 'Lato',
            generateTooltip: row => {
              return renderTootips(dataTable.getValue(row, 4))
            }
          });
        }
      });
    }

  }, []);

  return (
    <>
      <Global />
      <div className={className} id="treemap-chart-container" />
    </>
  );
}

export default TreemapChart;

