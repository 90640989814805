import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'

import ErrorPage from '../../../../components/styleguide/ErrorPage'
import TextView from '../../../../components/styleguide/TextView'
import Tooltip from '../../../../components/styleguide/Tooltip'
import { getPowerCompanyTransitions } from '../../../../repositories/portfolio_repository'
import { LinkIcon } from '../../../../yb_components/styleguide/links/Link.styles'
import Pagination from '../../../../yb_components/styleguide/pagination/Pagination'
import SpinnerContainer from '../../../../yb_components/styleguide/spinners/SpinnerContainer'
import TooltipWrapper from '../../../../yb_components/styleguide/tooltips/tooltip_wrapper/TooltipWrapper'
import { isValidHttpUrl } from '../../../../yb_helpers/isValidHttpUrl'
import CarbonReportCard from './components/CarbonReportCard'
import CarbonReportLink from './components/CarbonReportLink'
import {
  CARBON_REPORT_TEXTS,
  DEFAULT_SORT,
  powerCompanyTransitionTableHeader,
} from './constants'
import { formatSort, getPdfSortFieldText } from './helpers'
import {
  CarbonReportTable,
  ModuleDescription,
  NoDataText,
  renderTh,
  StyledExplainerLink,
} from './styles'

const StyledCarbonReportCard = styled(CarbonReportCard)`
  grid-column-start: 4;
  grid-column: span 8;
`

const StyledNote = styled.div`
  &:hover {
    ${props =>
      props.disableHover
        ? ''
        : css`
            cursor: pointer;
            text-decoration: underline;
          `}
  }
`

const loadPowerCompanyTransition = ({
  snapshotId,
  portfolioId,
  setData,
  setError,
  setIsLoading,
  setTotalPages,
  page,
  sort,
  dispatch,
}) => {
  setIsLoading(true)
  getPowerCompanyTransitions({ snapshotId, portfolioId, page, ...sort })
    .then(res => {
      setIsLoading(false)
      setData(res?.data?.data)
      setTotalPages(res?.data?.total_pages)
      dispatch({
        type: 'power-company-transitions-loaded',
        payload: {
          data: res?.data?.data,
          sort: sort,
        },
      })
    })
    .catch(err => {
      const error = err?.response ? err?.response?.status : 502
      setError(error)
    })
}

const PowerCompanyTransition = ({
  snapshotId,
  portfolioId,
  pdf,
  defaultData,
  defaultSort,
  dispatch,

  title = CARBON_REPORT_TEXTS?.powerCompanyTransitions.title,
  description = CARBON_REPORT_TEXTS?.powerCompanyTransitions.description,
}) => {
  const [isLoading, setIsLoading] = useState(!pdf)
  const [error, setError] = useState()
  const [data, setData] = useState(defaultData || [])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [sort, setSort] = useState(defaultSort || DEFAULT_SORT)

  useEffect(() => {
    if (defaultData) setData(defaultData)
  }, [defaultData])

  useEffect(
    () =>
      !pdf
        ? loadPowerCompanyTransition({
            snapshotId,
            portfolioId,
            setData,
            setError,
            setIsLoading,
            setTotalPages,
            page,
            sort,
            dispatch,
          })
        : () => {},
    [page, sort]
  )

  const getSourceLink = elem => {
    if (!elem?.source) return '—'

    const validUrl = isValidHttpUrl(elem?.source)
    let label = 'Company Website'
    if (elem?.source?.includes('.pdf')) label = 'Filling'

    if (!validUrl)
      return (
        <TooltipWrapper tooltip={renderTooltip(elem?.source)} margin={10}>
          <StyledNote className='name'>Source</StyledNote>
        </TooltipWrapper>
      )
    return (
      <CarbonReportLink
        pdf={pdf}
        name={label}
        href={elem?.source}
        target='_blank'
        fontWeight='normal'
      />
    )
  }

  const renderTooltip = note => {
    if (!note?.length) return

    return (
      <Tooltip noTransform inheritVisibility position={'relative'} padding={10}>
        <TextView bold text={'Additional notes:'} />
        <TextView secondary size={12} text={note} />
      </Tooltip>
    )
  }

  const formattedPdfSortField = useMemo(
    () =>
      getPdfSortFieldText(
        powerCompanyTransitionTableHeader(pdf),
        defaultSort,
        pdf
      ),
    [defaultSort, pdf]
  )

  return (
    <StyledCarbonReportCard
      title={title}
      pdf={pdf}
      pdfSubTitle={{ label: `(Top 10 Holdings By ${formattedPdfSortField})` }}
    >
      {!pdf && (
        <StyledExplainerLink
          pdf={pdf}
          href='http://help.yves.blue/en/articles/6698762-carbon-report-portfolio-summary-on-yvesblue#h_197c337769'
          target='_blank'
        >
          <LinkIcon className='fa fa-question' />
        </StyledExplainerLink>
      )}

      <ModuleDescription marginBottom={'20px'} pdf={pdf}>
        {description}
      </ModuleDescription>
      {error ? (
        <ErrorPage code={error} message={error == 404 ? 'NOT FOUND' : ''} />
      ) : (
        <>
          {isLoading && <br />}
          <SpinnerContainer isLoading={isLoading}>
            {data?.length ? (
              <>
                <CarbonReportTable pdf={pdf}>
                  <thead>
                    <tr>
                      {powerCompanyTransitionTableHeader(pdf).map(elem =>
                        renderTh(
                          elem,
                          sort,
                          (sortField, sortDir) =>
                            setSort(formatSort(sortField, sortDir)),
                          pdf
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map(elem => {
                      const percentRenewable = parseFloat(
                        elem?.percent_renewable
                      )
                      const percentWeight = parseFloat(elem?.weight)
                      return (
                        <tr key={elem?.id}>
                          <td>
                            {elem?.id ? (
                              <CarbonReportLink
                                pdf={pdf}
                                name={elem?.name}
                                href={`/app/instruments/${elem?.id}#report-summary-card`}
                                target='_blank'
                              />
                            ) : (
                              elem?.name || '—'
                            )}
                          </td>
                          {pdf && (
                            <td className='number'>
                              {isNaN(percentWeight)
                                ? '—'
                                : `${percentWeight?.toFixed(2)}%`}
                            </td>
                          )}
                          <td className='name'>
                            {elem?.trbc_industry_group_name || '—'}
                          </td>
                          <td className='number'>
                            <StyledNote disableHover={!elem?.note}>
                              <TooltipWrapper
                                tooltip={renderTooltip(elem?.note)}
                                margin={10}
                              >
                                {isNaN(percentRenewable)
                                  ? '—'
                                  : `${percentRenewable?.toFixed(2)}%`}
                              </TooltipWrapper>
                            </StyledNote>
                          </td>
                          <td>{getSourceLink(elem)}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </CarbonReportTable>
                {!pdf && (
                  <Pagination
                    max={totalPages}
                    selected={page}
                    onChange={e => setPage(e)}
                  />
                )}
              </>
            ) : (
              <NoDataText margin={'70px'}>
                Your portfolio does not include any <br /> publicly listed power
                companies.
              </NoDataText>
            )}
          </SpinnerContainer>
        </>
      )}
      {/* </StyledCard> */}
    </StyledCarbonReportCard>
  )
}

export default PowerCompanyTransition
