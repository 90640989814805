import React, { forwardRef } from 'react'

// eslint-disable-next-line react/display-name
export const OpenInNewIcon = forwardRef((props, ref) => (
  <svg
    ref={ref}
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.85714 0.857143C6.85714 0.383839 7.24018 0 7.71429 0H11.1188C11.258 0 11.3705 0.0230357 11.4696 0.0650893C11.5473 0.106607 11.6652 0.167946 11.7482 0.249107C11.7482 0.250446 11.7482 0.251786 11.7509 0.252857C11.917 0.419464 11.9759 0.636964 12 0.854464C12 0.855536 12 0.856339 12 0.857143V4.28571C12 4.75982 11.617 5.14286 11.1429 5.14286C10.6688 5.14286 10.2857 4.75982 10.2857 4.28571V2.92768L5.74821 7.4625C5.41339 7.79732 4.87232 7.79732 4.5375 7.4625C4.20268 7.12768 4.20268 6.58661 4.5375 6.25179L9.07232 1.71429H7.71429C7.24018 1.71429 6.85714 1.33045 6.85714 0.857143ZM0 2.57143C0 1.62455 0.767411 0.857143 1.71429 0.857143H4.28571C4.75982 0.857143 5.14286 1.24098 5.14286 1.71429C5.14286 2.18839 4.75982 2.57143 4.28571 2.57143H1.71429V10.2857H9.42857V7.71429C9.42857 7.24018 9.81161 6.85714 10.2857 6.85714C10.7598 6.85714 11.1429 7.24018 11.1429 7.71429V10.2857C11.1429 11.2313 10.3741 12 9.42857 12H1.71429C0.767411 12 0 11.2313 0 10.2857V2.57143Z'
      fill='currentColor'
    />
  </svg>
))
