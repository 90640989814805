import styled, { css } from 'styled-components'

import Button from '../../../../components/styleguide/Button'
import { APP_COLOR_PRIMARY } from '../../../../styles/colors'

const FilledButton = styled(Button)`
  text-transform: uppercase;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '10px')};
  padding: 0 15px;
  font-size: 14px;
  transition: 0.3s;
  width: ${props => (props.width ? props.width : '100%')};
  ${props =>
    !props.disabled &&
    css`
      :hover {
        background-color: white;
        color: ${APP_COLOR_PRIMARY};
        border: 1px solid ${APP_COLOR_PRIMARY};
      }
    `}
`

export default FilledButton
