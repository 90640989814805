import React from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Span from '../styleguide/Span';
import Button from '../styleguide/Button';
import { BG_COLOR_SECONDARY } from '../../styles/colors';

const Controls = styled(Row)`
  margin-top: 20px;
  .dialog-cancel-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    span {
      cursor: pointer;
      padding: 5px 10px;
      :hover {
        background-color: ${BG_COLOR_SECONDARY};
      }
    }
  }
`;

const DialogControls = ({ onClose }) => {
  return (
    <Controls>
      <Col className="dialog-cancel-btn" xs={4}>
        <Span onClick={onClose} color="secondary" text="Cancel"/>
      </Col>
      <Col xs={4}>
        <Button type="submit" label="SAVE CHANGES" />
      </Col>
    </Controls>
  );
}

export default DialogControls;
