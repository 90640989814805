import axios from 'axios';

export const getAPIKeys = () => {
    const url = `/app/api/api_keys`;
    return axios.get(url);
}

export const postAPIKeys = ({name}) => {
    const url = `/app/api/api_keys`;
    return axios.post(url, { name: name });
};

export const deleteAPIKey = ({keyID}) => {
    const url = `/app/api/api_keys/${keyID}`;
    return axios.delete(url);
};
