import React from 'react'
import styled from 'styled-components'

import { range } from '../../../helpers/objects_and_arrays'

const StyledA = styled.a`
  cursor: pointer;
`

const KaminariPagination = ({
  currentPage,
  totalPages,
  fetchPage = () => {},
}) => {
  const firstAndPrevious = () => (
    <>
      <li className='page-item'>
        <StyledA className='page-link' onClick={() => fetchPage(1)}>
          « First
        </StyledA>
      </li>
      <li className='page-item'>
        <StyledA
          className='page-link'
          onClick={() => fetchPage(currentPage - 1)}
        >
          ‹ Prev
        </StyledA>
      </li>
    </>
  )

  const nextAndLast = () => (
    <>
      <li className='page-item'>
        <StyledA
          className='page-link'
          onClick={() => fetchPage(currentPage + 1)}
        >
          Next ›
        </StyledA>
      </li>
      <li className='page-item'>
        <StyledA className='page-link' onClick={() => fetchPage(totalPages)}>
          Last »
        </StyledA>
      </li>
    </>
  )

  const pageSpread = () => (
    <li className='page-item disabled'>
      <a className='page-link'>…</a>
    </li>
  )

  const pages = () => {
    const pagesRange = range({
      start: Math.max(1, currentPage - 4),
      end: Math.min(currentPage + 4, totalPages),
    })

    return (
      <>
        {!pagesRange.includes(1) && pageSpread()}
        {pagesRange.map(page => (
          <li
            key={page}
            className={`page-item ${currentPage === page ? 'active' : ''}`}
          >
            <StyledA className='page-link' onClick={() => fetchPage(page)}>
              {page}
            </StyledA>
          </li>
        ))}
        {!pagesRange.includes(totalPages) && pageSpread()}
      </>
    )
  }

  return (
    totalPages > 0 && (
      <nav>
        <ul className='pagination justify-content-center'>
          {currentPage !== 1 && firstAndPrevious()}
          {pages()}
          {currentPage !== totalPages && nextAndLast()}
        </ul>
      </nav>
    )
  )
}

export default KaminariPagination
