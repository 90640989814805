import React  from 'react';
import styled from 'styled-components';

const SectionWrapper = styled.div`
    display:flex;
    flex-wrap: ${props => props.wrap || "wrap"};
    justify-content:${props => props.justify};
    align-items:${props => props.align};
    margin:${props => props.margin};
    padding:${props => props.padding};
    width:${props => props.width};
    height:${props => props.height};
    flex:${props => props.flex};
    background:${props => props.background};
    max-width: ${props => props.maxWidth};
`

export default SectionWrapper;
