import uuid from '@utils/uuid'
import React from 'react'

// import theme from './Table.module.scss'
import './Table.module.scss'
import { Accordion, Button, Card } from 'react-bootstrap'
import styled from 'styled-components'
export interface ITableController<TRowData> {
  onRenderHeader: THeaderRenderer
  onRenderSection: TSectionRenderer<TRowData>
  onRenderCategory: TCategoryRenderer<TRowData>
  onRenderRow: TRowRenderer<TRowData>
}

const btn = styled.button`
  all: unset;
  cursor: pointer;
`

export class TableController<TRowData> implements ITableController<TRowData> {
  onRenderSection(
    section: TTableSection<TRowData>,
    maxColSpan: number,
    depth = 1
  ): React.ReactNode {
    if (section instanceof TableCategory) {
      return this.onRenderCategory(section, maxColSpan, depth)
    } else {
      if (Array.isArray(section)) return this.onRenderRow(section)
      else if (section?.targetType === 'Instrument') {
        return this.onRenderCarousel(section, maxColSpan, depth)
      }
    }
  }

  onRenderRow(rowData: TRowData): React.ReactNode {
    return (
      <tr className='tableRow' key={uuid()}>
        {Object.values(rowData as object).map(node => (
          <td key={uuid()}>{node as React.ReactNode}</td>
        ))}
      </tr>
    )
  }

  onRenderHeader(header: TTableHead): React.ReactNode {
    if (header instanceof TableHead) {
      return <th>{header.label}</th>
    }

    return <th>{header}</th>
  }

  onRenderCarousel(
    category: TableCategory<TRowData>,
    maxColSpan: number,
    depth = 1
  ): React.ReactNode {
    return (
      <>
        <tr>
          <td colSpan={maxColSpan} style={{ padding: 0 }}>
            <Accordion>
              <Accordion.Toggle
                as={btn}
                style={{ padding: 0, margin: 0 }}
                eventKey='0'
                style={{ width: '100%' }}
              >
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      {new Array(maxColSpan).fill(0).map(() => (
                        <td style={{ padding: '0px' }} />
                      ))}
                    </tr>
                  </thead>
                  <tbody>{this.onRenderRow(category?.target)}</tbody>
                </table>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey='0'>
                <div style={{ width: '100%' }}>
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr style={{ padding: 0 }}>
                        {new Array(maxColSpan).fill(0).map(() => (
                          <td style={{ padding: '0px' }} />
                        ))}
                      </tr>
                    </thead>

                    <tbody style={{ paddingLeft: '10px' }}>
                      {category?.data?.map(section =>
                        this.onRenderRow(section)
                      )}
                    </tbody>
                  </table>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </td>
        </tr>

        {/* <tr className='category' data-depth={depth}>
          <td colSpan={maxColSpan}>{category?.name}</td>
        </tr> */}
        {/* {this.onRenderRow(category?.target)} */}
      </>
    )
  }

  onRenderCategory(
    category: TableCategory<TRowData>,
    maxColSpan: number,
    depth = 1
  ): React.ReactNode {
    return (
      <>
        <tr className='category' data-depth={depth}>
          <td colSpan={maxColSpan}>{category.name}</td>
        </tr>
        {category.data.map(section =>
          this.onRenderSection(section, maxColSpan, depth + 1)
        )}
      </>
    )
  }
}

export default new TableController()

export class TableCategory<TRowData> {
  name: string
  data: TTableSection<TRowData>[]

  constructor(name: string, data: TTableSection<TRowData>[]) {
    this.name = name
    this.data = data
  }
}

export class TableHead {
  label: string | null = null
  value?: string

  constructor(label: string | null, value?: string) {
    this.label = label
    this.value = value
  }
}

export type TTableSection<TRowData> = TableCategory<TRowData> | TRowData

export type TRowRenderer<TRowData> = (row: TRowData) => React.ReactNode

export type THeaderRenderer = (header: TTableHead) => React.ReactNode

export type TCategoryRenderer<TRowData> = (
  category: TableCategory<TRowData>,
  maxColSpan: number,
  depth?: number
) => React.ReactNode

export type TSectionRenderer<TRowData> = (
  section: TTableSection<TRowData>,
  maxColSpan: number,
  depth?: number
) => React.ReactNode

export type TTableHead = TableHead | string

export type TTableHeader = TTableHead[]
