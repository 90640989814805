import FormService from '@services/Form'
import { BaseInputRow } from '@ui/components/interactive/BaseInput'
import { CurrencyInputRow } from '@ui/components/interactive/CurrencyInput'
import { FormActions } from '@ui/components/interactive/FormActions'
import { URLInputRow } from '@ui/components/interactive/URLInput'
import { ExternalLink } from '@ui/components/presentation/links/ExternalLink'
import getTestingProps from '@utils/test/getTestingProps'
import uuid from '@utils/uuid'
import React, { useCallback, useState } from 'react'

import { StyledBar } from '../../../../../../../react/yb_modules/custom_esg_scoring/CustomESGScoring.styles'
import { FundAlignmentPickerRow } from './components/FundAlignmentPicker'
import FundConfigurationController from './FundConfiguration.controller'
// import theme from './FundConfiguration.module.scss'
import './FundConfiguration.module.scss'

export interface IFundConfigurationForm {
  productName: string
  legalEntityId: string
  fundAlignment: string
  // fundBenchmark: string
  fundAum: string
  benchmarkMethodologyUrl: string
}
// interface IMetadata {
//     fundBenchmark?: TMetadata
// }

const Form = new FormService<IFundConfigurationForm>()
export type IProps = YB.IWithPortfolioIds

export const FundConfiguration: React.FC<IProps> = ({
  portfolioId,
  snapshotId,
}) => {
  /* State */
  const [revision, setRevision] = useState<string>(uuid())
  const [productName, productNameFeedback] = Form.useInputModel<string>('')
  const [legalEntityId, legalEntityIdFeedback] = Form.useInputModel<string>('')
  // const [fundBenchmark, fundBenchmarkFeedback] =
  //     Form.useInputModel<string>('option_2')
  const [fundAum, fundAumFeedback] = Form.useInputModel<string>('')
  const [benchmarkMethodologyUrl, benchmarkMethodologyUrlFeedback] =
    Form.useInputModel<string>('')
  const [fundAlignment] = Form.useInputModel<string>('6')
  const [, setMetadata] = useState<any>({})

  const formModel = {
    productName,
    fundAlignment,
    legalEntityId,
    // fundBenchmark,
    fundAum,
    benchmarkMethodologyUrl,
  }
  const formData = Form.useFormData(formModel)
  const [formDataSnapshot] = Form.useFormDataSnapshot(formData)

  const isFormValid = Form.useFormValidity([
    productNameFeedback,
    legalEntityIdFeedback,
    // fundBenchmarkFeedback,
    fundAumFeedback,
    benchmarkMethodologyUrlFeedback,
  ])

  FundConfigurationController.useDefaultValues(
    portfolioId,
    snapshotId,
    useCallback((newFormModel, newMetadata) => {
      Form.setFormModel(formModel, newFormModel)
      setMetadata(newMetadata)
    }, [])
  )

  /* Events */
  const handleCancel = () => {
    Form.setFormModel(formModel, formDataSnapshot)
    setRevision(uuid())
  }

  const handleSubmit = () => {
    FundConfigurationController.updateFundConfiguration(
      portfolioId,
      snapshotId,
      formData
    ).then(() =>
      window.location.assign(
        `app/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/sfdr/disclosures`
      )
    )
  }

  /* Render */
  return (
    <>
      <p
        className='tabDetail'
        {...getTestingProps('fundConfigurationDescription')}
      ></p>
      <div key={revision}>
        <BaseInputRow
          label='Project Name'
          name='productName'
          model={productName}
          feedbackModel={productNameFeedback}
          subtext={
            <p>
              Here give your work session a name that will help to distinguish
              this work from future periods when you need to do work in this
              system. The Pre-contractual disclosure is updated whenever there
              are changes that need to be made and the Periodic disclosure is
              updated annually.
            </p>
          }
        />
        <BaseInputRow
          label='Legal Entity Identifier'
          name='legalEntityId'
          model={legalEntityId}
          feedbackModel={legalEntityIdFeedback}
          subtext={
            <p>
              Products registered in Europe must mark their disclosures with the
              legal entity identification number for the product. The Legal
              Entity Identifier (LEI) is a reference code — like a bar code —
              used across markets and jurisdictions to uniquely identify a
              legally distinct entity that engages in a financial transaction.
              The number can be found in the registration documents for the
              product usually registered in Ireland or Luxembourg.
            </p>
          }
        />
        <FundAlignmentPickerRow
          label='Fund Alignment'
          name='fundAlignment'
          model={fundAlignment}
          subtext={
            <p>
              Please let us know what Article your product is aligned with by
              clicking one of these radio buttons.
            </p>
          }
        />
        {/* <SelectRow
                    label='Fund Benchmark'
                    name={'fundBenchmark'}
                    model={fundBenchmark}
                    feedbackModel={fundBenchmarkFeedback}
                    subtext={
                        <p>
                            Quam adipiscing vitae proin sagittis nisl rhoncus
                            mattis rhoncus. Magna etiam tempor orci eu lobortis.
                        </p>
                    }
                    options={metadata?.fundBenchmark?.options as YB.IOption[]}
                /> */}
        <CurrencyInputRow
          label='Fund AUM'
          name='fundAum'
          model={fundAum}
          feedbackModel={fundAumFeedback}
          placeholder='0.000'
          decimalsLimit={3}
          subtext={
            <p>
              The total amount invested in this product will assist in
              conducting the necessary calculations for the Principal Adverse
              Indicators and for various calculated responses that are automated
              for you in the Pre-contractual Disclosures and Periodic
              Disclosures.
            </p>
          }
        />
        <URLInputRow
          label=' What methodology is used to calculate the fund benchmark?'
          name='methodology'
          placeholder='https://www.example.com'
          model={benchmarkMethodologyUrl}
          feedbackModel={benchmarkMethodologyUrlFeedback}
          // rows={12}
          subtext={
            <p>
              Indicate the environmental and/or social characteristics promoted
              by the financial product and whether a reference benchmark has
              been designated for the purpose of attaining the environmental or
              social characteristics promoted by the financial product.{' '}
              <ExternalLink href=''>More info</ExternalLink>
            </p>
          }
        />
      </div>
      <StyledBar>
        <FormActions
          canSubmit={isFormValid}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          submitLabel='Save and continue'
        />
      </StyledBar>
    </>
  )
}
