import React from 'react'

import {
  abbreviateNumber,
  addOrderOfMagnitudeMask,
  addThousandSeparator,
} from '../../../helpers/number'
import { TargetType } from '../../../yb_constants/enums/global_enums'
import { OWNERSHIP_PERCENTAGE_THRESHOLD } from '../../../yb_constants/target'
import Card from '../Card'

export const isValidImpact = (impact, ownership_percentage_threshold) => {
  return (
    impact &&
    !isNaN(impact.total) &&
    impact.total > 0 &&
    impact.holdings_ownership_pct > ownership_percentage_threshold
  )
}

const ImpactAssessment = ({
  targetType,
  impacts,
  ownership_percentage_threshold = OWNERSHIP_PERCENTAGE_THRESHOLD,
  benchmarkImpacts,
  benchmarkName,
}) => {
  if (
    !impacts ||
    (!isValidImpact(impacts.trees_killed, ownership_percentage_threshold) &&
      !isValidImpact(impacts.pay_gap, ownership_percentage_threshold) &&
      !isValidImpact(!impacts.waste, ownership_percentage_threshold) &&
      !isValidImpact(!impacts.water_usage, ownership_percentage_threshold) &&
      !isValidImpact(!impacts.hazardous_waste, ownership_percentage_threshold))
  )
    return null

  const renderTreesKilledCard = () => {
    const benchmarkComparison =
      benchmarkImpacts &&
      impacts.trees_killed.total - benchmarkImpacts?.trees_killed?.total
    const benchmarkComparisonAbs = Math.abs(benchmarkComparison)

    const text = `${addOrderOfMagnitudeMask(
      Math.round(benchmarkComparisonAbs)
    )} ${benchmarkComparison > 0 ? 'more' : 'less'} than the ${benchmarkName}.`
    const color = benchmarkComparison > 0 ? '#D02F44' : '#00D679'

    return (
      <div className='impact-card-content'>
        <div className='impact-card-icons'>
          <img className='impact-icon' src={`/impact_icons/ico_carbon.svg`} />
        </div>
        <div className='impact-card-value'>
          <span>
            {addOrderOfMagnitudeMask(Math.round(impacts.trees_killed.total))}{' '}
            <span>trees</span>
          </span>
          <br />
          <span>
            that must be planted to sequester the annual carbon emissions.
          </span>
          {benchmarkComparison && (
            <div className='impact-benchmark' style={{ color: color }}>
              {text}
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderGenderCard = () => {
    const benchmarkComparison =
      benchmarkImpacts &&
      benchmarkImpacts?.pay_gap?.total - impacts.pay_gap.total
    const benchmarkComparisonAbs = Math.abs(benchmarkComparison)

    const text = `${parseFloat(Math.round(benchmarkComparisonAbs)).toFixed(
      1
    )}x ${
      benchmarkComparison > 0 ? 'more' : 'less'
    } likely than the ${benchmarkName}.`
    const color = benchmarkComparison > 0 ? '#D02F44' : '#00D679'

    return (
      <div className='impact-card-content'>
        <div className='impact-card-icons'>
          <img className='impact-icon' src='/impact_icons/ico_gender.svg' />
        </div>
        <div className='impact-card-value'>
          <span>
            {parseFloat(Math.round(impacts.pay_gap.total)).toFixed(1)}{' '}
            <span className='gender-gap-unit'>×</span>
          </span>
          <br />
          <span>
            more likely for male employees to <br /> be promoted to management{' '}
            <br /> than female employees.
          </span>
          {benchmarkComparison && (
            <div className='impact-benchmark' style={{ color: color }}>
              {text}
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderWasteCard = () => {
    const benchmarkComparison =
      benchmarkImpacts &&
      (
        ((impacts.waste.total - benchmarkImpacts?.waste?.total) /
          impacts.waste.total) *
        100
      ).toFixed(2)
    const benchmarkComparisonAbs = Math.abs(benchmarkComparison)

    const text = `${benchmarkComparisonAbs}% ${
      benchmarkComparison > 0 ? 'more' : 'less'
    } than the ${benchmarkName}.`
    const color = benchmarkComparison > 0 ? '#D02F44' : '#00D679'

    const total = parseInt(impacts.waste.total)
    const value = total < 1 ? '< 1' : addThousandSeparator(total)
    const unit = total == 1 ? 'African Elephant' : 'African Elephants'

    return (
      <div className='impact-card-content'>
        <div className='impact-card-icons'>
          <img className='impact-icon' src={`/impact_icons/ico_waste.svg`} />
        </div>
        <div className='impact-card-value'>
          <span>
            {value} {unit}
          </span>
          <br />
          <span>
            equivalent weight of waste produced by
            <br /> the companies held in this portfolio <br />
            in a year.
          </span>
          {benchmarkComparison && (
            <div className='impact-benchmark' style={{ color: color }}>
              {text}
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderWaterUsageCard = () => {
    const benchmarkComparison =
      benchmarkImpacts &&
      (
        ((impacts.water_usage.total - benchmarkImpacts?.water_usage?.total) /
          impacts.water_usage.total) *
        100
      ).toFixed(2)
    const benchmarkComparisonAbs = Math.abs(benchmarkComparison)

    const text = `${Math.round(benchmarkComparisonAbs)}% ${
      benchmarkComparison > 0 ? 'more' : 'less'
    } than the ${benchmarkName}.`
    const color = benchmarkComparison > 0 ? '#D02F44' : '#00D679'

    const total = addThousandSeparator(
      Math.round(parseInt(impacts.water_usage.total))
    )
    return (
      <div className='impact-card-content'>
        <div className='impact-card-icons'>
          <img
            className='impact-icon'
            src={`/impact_icons/ico_water_usage.svg`}
          />
        </div>
        <div className='impact-card-value'>
          <span>
            {total} <span className='gender-gap-unit'>×</span>
          </span>
          <br />
          <span>
            a person&apos;s annual freshwater <br /> resources in North Africa.
          </span>
          {benchmarkComparison && (
            <div className='impact-benchmark' style={{ color: color }}>
              {text}
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderHazardousWasteCard = () => {
    const benchmarkComparison =
      benchmarkImpacts &&
      (
        ((impacts.hazardous_waste.total -
          benchmarkImpacts?.hazardous_waste?.total) /
          impacts.hazardous_waste.total) *
        100
      ).toFixed(2)
    const benchmarkComparisonAbs = Math.abs(benchmarkComparison)

    const text = `${Math.round(benchmarkComparisonAbs)}% ${
      benchmarkComparison > 0 ? 'more' : 'less'
    } than the ${benchmarkName}.`
    const color = benchmarkComparison > 0 ? '#D02F44' : '#00D679'

    return (
      <div className='impact-card-content'>
        <div className='impact-card-icons'>
          <img
            className='impact-icon'
            src={`/impact_icons/ico_haz_waste.svg`}
          />
        </div>
        <div className='impact-card-value'>
          <span>
            $ {abbreviateNumber(impacts.hazardous_waste.total * 1.0, 0)}{' '}
          </span>
          <br />
          <span>
            required to cleanup the average amount of hazardous waste emitted by
            this portfolio in a year.
          </span>
          {benchmarkComparison && (
            <div className='impact-benchmark' style={{ color: color }}>
              {text}
            </div>
          )}
        </div>
      </div>
    )
  }

  const dataToRender = [
    {
      renderFunc: () => renderTreesKilledCard(),
      impact: impacts?.trees_killed,
      className: 'trees-killed-card',
      title: 'CARBON',
    },
    {
      renderFunc: () => renderGenderCard(),
      impact: impacts?.pay_gap,
      className: 'gender-gap-card',
      title: 'GENDER PROMOTION GAP',
    },
    {
      renderFunc: () => renderWasteCard(),
      impact: impacts?.waste,
      className: 'waste-card',
      title: 'WASTE',
    },
    {
      renderFunc: () => renderWaterUsageCard(),
      impact: impacts?.water_usage,
      className: 'waste-card',
      title: 'WATER USAGE',
    },
    {
      renderFunc: () => renderHazardousWasteCard(),
      impact: impacts?.hazardous_waste,
      className: 'hazardous-waste-card',
      title: 'HAZARDOUS WASTE',
    },
  ]

  const getCardNoteText = holdingsPct => {
    switch (targetType) {
      case TargetType.PORTFOLIO_SNAPSHOT:
        return `This number was calculated using data available from ${holdingsPct}% of your long portfolio holdings.`

      default:
      case TargetType.INSTRUMENT:
        return `This number was calculated using data available from ${holdingsPct}% of the long instrument holdings.`
    }
  }

  return (
    <section className='impact-assessment'>
      <h2>Impact Assessment</h2>
      {dataToRender.map(data =>
        isValidImpact(data?.impact, ownership_percentage_threshold) ? (
          <Card className={data.className} title={data.title}>
            {data.renderFunc()}
            <div className='impact-card-note'>
              {getCardNoteText(data.impact.holdings_ownership_pct)}
            </div>
          </Card>
        ) : null
      )}
    </section>
  )
}

export default ImpactAssessment
