import { IBaseInputProps } from '@ui/components/interactive/BaseInput'
import classNames from 'classnames'
import { ChangeEvent, useContext, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import SanitizedHTML from '../../../../react/yb_components/security/SanitizedHTML'
// import theme from './Checkbox.module.scss'
import './Checkbox.module.scss'
import React from 'react'
import styled, { css } from 'styled-components'
import { APP_COLOR_PRIMARY } from '../../../../react/styles/colors'
import { DisclosureFormsContext } from '../../../../react/yb_stores/sfdr/disclosure_forms/DisclosureFormsStore'

const S = {
  Container: styled.div``,

  Input: styled.input`
    margin: 0 10px 0 0;
    ${props =>
      props.pdf &&
      css`
        margin: 0 10px 0 0;
        min-height: 0px;
        padding: 0px;
        width: 1px !important;
        height: 1px !important;
        border-radius: 9999px;
        background-color: white;

        :checked {
          background-color: ${APP_COLOR_PRIMARY};
        }
      `}
  `,
}

interface IProps
  extends Omit<
    IBaseInputProps,
    'type' | 'id' | 'validations' | 'feedbackModel' | 'formFeedbackModel'
  > {
  label: string
  name: string
}

const Checkbox: React.FC<IProps> = ({
  value,
  label,
  model = useState<string>(''),
  className = '',
  onChange,
  ...inputProps
}) => {
  const id = useMemo(uuidv4, [])
  const [, setSelected] = model
  const [{ isPdf }] = useContext(DisclosureFormsContext)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.value)
    onChange && onChange(event)
  }

  return (
    <S.Container pdf={isPdf}>
      <div className='Checkbox'>
        <S.Input
          {...inputProps}
          pdf={isPdf}
          id={id}
          type='checkbox'
          value={value}
          className={classNames({
            [className]: !!className,
          })}
          onChange={handleChange}
        />
        <label htmlFor={id}>
          <SanitizedHTML stringTemplate={label} />
        </label>
      </div>
    </S.Container>
  )
}

export default Checkbox
