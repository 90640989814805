/* eslint-disable */
import React from 'react'
import EventRow from './EventRow'

import FocusAreaDisabledEvent from './templates/FocusAreaDisabledEvent'
import FocusAreaEnabledEvent from './templates/FocusAreaEnabledEvent'
import FocusAreaNoteAddedEvent from './templates/FocusAreaNoteAddedEvent'
import FocusAreaUpdatedEvent from './templates/FocusAreaUpdatedEvent'
import FocusAreaCreatedEvent from './templates/FocusAreaCreatedEvent'
import GenericEvent from './templates/GenericEvent'
import HoldingDestroyedEvent from './templates/HoldingDestroyedEvent'
import HoldingUpdatedEvent from './templates/HoldingUpdatedEvent'
import HoldingKeyPersonCreatedEvent from './templates/holding/key_person/KeyPersonCreatedEvent'
import HoldingKeyPersonConfirmedEmailEvent from './templates/holding/key_person/HoldingKeyPersonConfirmedEmailEvent'
import PDFDownloadedEvent from './templates/PDFDownloadedEvent'
import UserCreatedEvent from './templates/UserCreatedEvent'
import UserDisabledEvent from './templates/UserDisabledEvent'
import UserLoginEvent from './templates/UserLoginEvent'
import UserUpdatedEvent from './templates/UserUpdatedEvent'
import HoldingCreatedEvent from './templates/holding/HoldingCreatedEvent'
import HoldingUpdatedStateEvent from './templates/holding/HoldingUpdatedStateEvent'
import FundExportedHoldingsEvent from './templates/fund/FundExportedHoldingsEvent'

import PortfolioDeletedEvent from "./templates/PortfolioDeletedEvent";
import PortfolioCreatedEvent from "./templates/PortfolioCreatedEvent";
import SnapshotCreatedEvent from "./templates/portfolio/snapshot/SnapshotCreatedEvent";
import SnapshotUpdatedEvent from "./templates/portfolio/snapshot/SnapshotUpdatedEvent";

// Custom Report
import CustomReportFormItemValueCreatedEvent from './templates/custom_report/form_item/value/CustomReportFormItemValueCreatedEvent'

import CustomReportFormItemTargetCreatedEvent from './templates/custom_report/form_item/target/CustomReportFormItemTargetCreatedEvent'

import CustomReportFormItemNoteCreatedEvent from './templates/custom_report/form_item/note/CustomReportFormItemNoteCreatedEvent'

import CustomReportFormSubmissionCreatedEvent from './templates/custom_report/form_submission/CustomReportFormSubmissionCreatedEvent'

// Publications
import PublicationCreatedEvent from './templates/publication/PubliationCreatedEvent'
import PublicationFormSubmittedEvent from './templates/publication/PublicationFormSubmittedEvent'

// Funds
import FundCreatedEvent from './templates/fund/FundCreatedEvent'
import FundUpdatedEvent from './templates/fund/FundUpdatedEvent'
import FundSetAllMembersAsReaderEvent from './templates/fund/FundSetAllMembersAsReaderEvent'
import FundUpdatedMemberRoleEvent from './templates/fund/FundUpdatedMemberRoleEvent'
import FundOnboardedMemberEvent from './templates/fund/FundOnboardedMemberEvent'
import FundDestroyedHoldingEvent from './templates/fund/FundDestroyedHoldingEvent'

export class Event {
  KINDS = {
    // 'custom_report-form_item_values-create':  '',
    // 'custom_report-forms-create':             '',
    // 'custom_reports-create':                  '',
    // 'fund-reporting_frameworks-create':       '',
    // 'funds-create':                           '',
    // 'funds-delete':                           '',
    // 'funds-onboard_member':                   '',
    // 'funds-update_member_role':               '',
    // 'holdings-create':                        '',

    // USER EVENTS
    'User-login': UserLoginEvent,
    'User-create': UserCreatedEvent,
    'User-destroy': UserDisabledEvent,
    'User-update': UserUpdatedEvent,

    // HOLDING EVENTS
    'Holding-destroy': HoldingDestroyedEvent,
    'Holding-update': HoldingUpdatedEvent,
    'Holding-create': HoldingCreatedEvent,
    'Holding-update_state': HoldingUpdatedStateEvent,
    'Holding::KeyPerson-create': HoldingKeyPersonCreatedEvent,
    'Holding::KeyPerson-confirm_email': HoldingKeyPersonConfirmedEmailEvent,

    // FOCUS AREA EVENTS
    'FocusArea-create_note': FocusAreaNoteAddedEvent,
    'FocusArea-create': FocusAreaCreatedEvent,
    'FocusArea-update': FocusAreaUpdatedEvent,
    'FocusArea-disable': FocusAreaDisabledEvent,
    'FocusArea-enable': FocusAreaEnabledEvent,

    // PORTFOLIO UPLOAD EVENTS

    // PORTFOLIO EVENTS
    'Portfolio-create': PortfolioCreatedEvent,
    'Portfolio-destroy': PortfolioDeletedEvent,
    'Portfolio::Snapshot-download_pdf': PDFDownloadedEvent,
    'Portfolio::Snapshot-create': SnapshotCreatedEvent,
    'Portfolio::Snapshot-update': SnapshotUpdatedEvent,

    // CUSTOM REPORT EVENTS
    'CustomReport::FormItemTarget-create':
      CustomReportFormItemTargetCreatedEvent,
    'CustomReport::FormItemValue-create': CustomReportFormItemValueCreatedEvent,
    'CustomReport::FormItemNote-create': CustomReportFormItemNoteCreatedEvent,
    'CustomReport::FormSubmission-create':
      CustomReportFormSubmissionCreatedEvent,

    // PUBLICATION EVENTS
    'Publication-create': PublicationCreatedEvent,
    'Publication-submit_form': PublicationFormSubmittedEvent,

    // FUND EVENTS
    'Fund-create': FundCreatedEvent,
    'Fund-update': FundUpdatedEvent,
    'Fund-set_all_members_as_reader': FundSetAllMembersAsReaderEvent,
    'Fund-update_member_role': FundUpdatedMemberRoleEvent,
    'Fund-onboard_member': FundOnboardedMemberEvent,
    'Fund-destroy_holding': FundDestroyedHoldingEvent,
    'Fund-export_holdings': FundExportedHoldingsEvent,
  }

  constructor(event_data) {
    this.action = event_data.action
    this.context = event_data.context
    this.createdAt = event_data.created_at
    this.data = event_data.data
    this.id = event_data.id
    this.target = event_data.target
    this.targetID = event_data.target_id
    this.targetType = event_data.target_type
    this.targetExists = event_data.target_exists
    this.parentTargetExists = event_data.parent_target_exists
    this.formattedTimestamp = null
    this.type = null
    this.component = null

    this.setFormattedTimestamp()
    this.setType()
    this.setComponent()
  }

  setType() {
    this.type = this.targetType + '-' + this.action
  }

  setComponent() {
    this.component =
      this.type in this.KINDS ? this.KINDS[this.type] : GenericEvent
  }

  setFormattedTimestamp() {
    this.formattedTimestamp = new Date(this.createdAt).toLocaleTimeString(
      'en-US',
      { timeStyle: 'short' }
    )
  }

  getEventData() {
    return {
      action: this.action,
      context: this.context,
      createdAt: this.createdAt,
      data: this.data,
      formattedTimestamp: this.formattedTimestamp,
      id: this.id,
      target: this.target,
      targetID: this.targetID,
      targetType: this.targetType,
      targetExists: this.targetExists,
      parentTargetExists: this.parentTargetExists
    }
  }

  render() {
    if (this.component == GenericEvent) return null

    return (
      <EventRow key={`${this.targetID}-${this.createdAt}`}>
        <this.component key={this.id} event={this.getEventData()} />
      </EventRow>
    )
  }
}
