import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'

import { abbreviateNumber } from '../../../../../../helpers/number'
import { default as peersText } from '../../../constants/tcfd_peer_data.json'
import { tcfdPeerDisplay } from '../../../constants/tcfd_peer_display'
import { getTcfdColor } from '../../../utils/tcdf_colors'
import { StyledPeersTable } from './PeersTable.styles'

const StyledSpan = styled.span`
  color: ${props => props.color || '#afafaf'};
`

export const PeersTable = ({ peers, risk }) => {
  const getText = (risk, string) => {
    if (string != null && string.length) return peersText[risk][string]
    else return ''
  }

  const [singleCompany] = useState(peers?.[0])
  if (!peers?.[0]) return null

  return (
    <StyledPeersTable>
      <div className='heading-column'>
        <main style={{ borderRight: '1px solid #E4E6E5' }}>
          {Object.entries(peers?.[0]?.[risk])?.map(([key]) => (
            <div className='heading-row' key={key}>
              <span>{getText(risk, key)}</span>
            </div>
          ))}
        </main>
      </div>

      <div className='content-column'>
        <main>
          {Object.entries(singleCompany[risk]).map(([key, value]) => (
            <div className='content-row' key={key}>
              {tcfdPeerDisplay[key] === 'long_text' && (
                <StyledSpan style={{ fontWeight: 'normal', fontSize: '14px' }}>
                  {value}
                </StyledSpan>
              )}
              {tcfdPeerDisplay[key] === 'text' && (
                <StyledSpan>{value}</StyledSpan>
              )}
              {tcfdPeerDisplay[key] === 'num' && (
                <StyledSpan>{abbreviateNumber(value)}</StyledSpan>
              )}
              {tcfdPeerDisplay[key] === 'tcfd' && (
                <StyledSpan color={getTcfdColor(value)}>
                  {abbreviateNumber(value) ?? (
                    <StyledSpan color={'#dddddd'}>—</StyledSpan>
                  )}
                </StyledSpan>
              )}
            </div>
          ))}
        </main>
      </div>
    </StyledPeersTable>
  )
}

PeersTable.propTypes = {
  openReplacePeerModal: PropTypes.func.isRequired,
  peers: PropTypes.array.isRequired,
  risk: PropTypes.string.isRequired,
}
