import axios from 'axios'

export const updateCurrentOrganization = ({ name }) => {
  const url = `/app/api/organization/settings`
  return axios.put(url, { name: name })
}

export const updateBrandingLogos = params => {
  return axios.put('/app/api/organization/settings/update_attachments', params)
}

export const getBradingLogoBlob = url => {
  return axios.get(url, { responseType: 'blob' })
}

export const getOrganizationUsers = async params => {
  const res = await axios.get(`/app/api/organization/users`, { params })
  return res.data
}

export const createOrganizationUser = async data => {
  try {
    const res = await axios.post(`/app/api/organization/users`, data)
    return res
  } catch (e) {
    return e.response
  }
}

export const updateOrganizationUser = async (data, id) => {
  try {
    const res = await axios.put(`/app/api/organization/users/${id}`, data)
    return res
  } catch (e) {
    return e.response
  }
}

export const getOrganizationUserById = async id => {
  const res = await axios.get(`/app/api/organization/users/${id}`)
  return res.data
}

export const disableOrganizationUser = async user => {
  const res = await axios.delete(`/app/api/organization/users/${user.id}`)
  return res.data
}

export const enableOrganizationUser = async user => {
  const data = user
  data.status = 'active'
  const res = await axios.put(`/app/api/organization/users/${user.id}`, data)
  return res.data
}

export const resetOrganizationUserPassword = async id => {
  const res = await axios.post(
    `/app/api/organization/users/${id}/reset_password`
  )
  return res
}
