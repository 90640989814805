import _ from 'lodash'
import React from 'react'

const WordSplittedArcLinkLabel = ({
  data,
  splitInWords = false,
  maxWords = 2,
  maxWordLength = 12,
  abbreviateLabels = true,
  boldHeader = false,
}) => {
  if (!data?.label) return null
  const abbreviateWord = word => {
    const labelAngle = (data.arc.startAngle + data.arc.endAngle) / 2

    const HORIZONTAL_MAX_LENGTH = 5
    const OBLIQUE_MAX_LENGTH = 6
    const PIE_SLICE_SIZE = Math.PI / 5

    if (
      (labelAngle > PIE_SLICE_SIZE * 2 && labelAngle < PIE_SLICE_SIZE * 3) ||
      (labelAngle > PIE_SLICE_SIZE * 7 && labelAngle < PIE_SLICE_SIZE * 8)
    ) {
      if (word?.length > HORIZONTAL_MAX_LENGTH)
        return word.substring(0, HORIZONTAL_MAX_LENGTH) + '..'
    } else if (
      (labelAngle > PIE_SLICE_SIZE && labelAngle < PIE_SLICE_SIZE * 2) ||
      (labelAngle > PIE_SLICE_SIZE * 3 && labelAngle < PIE_SLICE_SIZE * 4) ||
      (labelAngle > PIE_SLICE_SIZE * 6 && labelAngle < PIE_SLICE_SIZE * 7) ||
      (labelAngle > PIE_SLICE_SIZE * 8 && labelAngle < PIE_SLICE_SIZE * 9)
    ) {
      if (word?.length > OBLIQUE_MAX_LENGTH)
        return word.substring(0, OBLIQUE_MAX_LENGTH) + '..'
    } else if (word?.length > maxWordLength) {
      return word.substring(0, maxWordLength) + '..'
    }
    return word
  }

  const renderRow = (word, dyValue = 0, props) => {
    return (
      <tspan key={`${word}${dyValue}`} x='0' dy={dyValue + 'em'} {...props}>
        {abbreviateLabels ? abbreviateWord(word) : word}
      </tspan>
    )
  }

  /* If the word is to small then render it */
  if (data.label.length < maxWordLength || !splitInWords) {
    ;<React.Fragment>{renderRow(data.name)}</React.Fragment>
  }

  /* If the word is to big then split it */
  const splittedLabel = data.label.split(/,| |\//)
  const fMaxWords =
    splittedLabel.length < maxWords ? splittedLabel.length : maxWords
  return (
    <React.Fragment>
      {_.range(fMaxWords).map((value, index) => {
        let aWord = splittedLabel[index]

        const SIZE = 1
        let dyValue = 0
        let fontWeight = 'normal'

        /* Check size for fitting the word corectly */
        if (splittedLabel.length > 1) {
          if (index === 0) {
            dyValue = -SIZE
            if (boldHeader) fontWeight = 'bold'
          } else {
            dyValue = SIZE
          }
        }

        return renderRow(aWord, dyValue, { fontWeight })
      })}
    </React.Fragment>
  )
}

export default WordSplittedArcLinkLabel
