import numeral from 'numeral'

export const computeColor = (score, neutralIsZero = false) => {
  const green = '25, 135, 84'
  const red = '176, 42, 55'

  if (neutralIsZero) {
    if (score < 0) {
      return `rgba(${red}, ${-score / 2 / 100 + 0.5})`
    } else {
      return `rgba(${green}, ${score / 2 / 100 + 0.5})`
    }
  } else {
    if (score < 50) {
      return `rgba(${red}, ${(100 - score) / 100})`
    } else {
      return `rgba(${green}, ${score / 100})`
    }
  }
}

export const formatRangeScore = rangeScore => {
  if (rangeScore === 0) return '0'
  if (!rangeScore) return null
  return numeral(rangeScore).format('0[.]0a').toUpperCase()
}

export const mapAndPopulatePeerObject = (obj, populateNumber) => {
  return Array.from({ length: populateNumber }, (_, i) => {
    return [
      i,
      {
        id: obj[i]?.id || '',
        name: obj[i]?.name || '',
      },
    ]
  })
}

export const sortArrayValuesFromObj = (
  obj = {},
  array = [],
  nonIterable = []
) => {
  const sortedArray = Object.entries(obj).reduce((acc, [, value]) => {
    if (value?.id !== '') {
      const foundItem = array.find(item => item?.id === value?.id)
      acc.push(foundItem)
    }
    return acc
  }, [])

  return nonIterable.length > 0
    ? [...nonIterable, ...sortedArray]
    : [...sortedArray]
}

export const findId = (id, obj) => {
  return Object.values(obj).some(val => {
    if (val.id === id) {
      return true
    }
    if (typeof val === 'object') {
      return findId(id, val)
    }
    return false
  })
}
