import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { RISK_ITEMS } from '../TcfdHoldingDetail.constants'
import TcfdHoldingDetailListItem from './TcfdHoldingDetailListItem'

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`

export const TcfdHoldingDetailList = ({ peerData }) => {
  return (
    <StyledList>
      {RISK_ITEMS.map(el => (
        <TcfdHoldingDetailListItem key={el.key} item={el} peerData={peerData} />
      ))}
    </StyledList>
  )
}

TcfdHoldingDetailList.propTypes = {
  peerData: PropTypes.array.isRequired,
}
