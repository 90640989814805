import React from 'react';
import styled from 'styled-components';
import { FONT_COLOR_SECONDARY } from '../../styles/colors';

const Label = styled.label`
  color: ${FONT_COLOR_SECONDARY};
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0;
  margin: ${props => props.margin};
`;

const InputLabel = ({ label, className, ...props }) => {
  return (
    <Label className={className} {...props}>{label}</Label>
  );
}

export default InputLabel;
