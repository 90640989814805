import React from 'react';
import SDGAlignment from '../SDGAlignment';
import BrandingPrimaryLogo from '../../layout/branding_primary_logo/BrandingPrimaryLogo';

const InstrumentHeading = ({ instrument}) => {
  return (
    <section className="portfolio-heading">
      <div className="portfolio-heading__information">
        <h1>
          {instrument.name}
        </h1>

        <SDGAlignment sdgs={instrument.sdgs}/>
      </div>

      <BrandingPrimaryLogo />
    </section>
  );
}

export default InstrumentHeading;
