import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import _find from 'lodash.find'

import { createPortfolioFolder } from '../helpers/folders'
import { mapTargetToState } from './target_mapper'

dayjs.extend(utc)

export const mapSnapshotToState = p => {
  return {
    as_of: p.as_of,
    portfolio_id: p.portfolio_id,
    impact_benchmark_id: p.impact_benchmark_id,
    badges_count: p.badges_count,
    holdings_total: p.holdings_total,
    total_value: parseFloat(p.total_value),
    total_weight: (parseFloat(p.total_weight) * 100).toFixed(0),
    modeled_weight: (parseFloat(p.modeled_weight) * 100).toFixed(1),
    unmodeled_weight: (parseFloat(p.unmodeled_weight) * 100).toFixed(1),
    cash_weight: (parseFloat(p.cash_weight) * 100).toFixed(1),
    asset_classes: _mapAssetClasses(p),
    wrapped_holdings: _mapWrappedHoldings(p),
    holdings: _mapHoldings(p),
    esg_scores: _mapESGScores(p),

    ...mapTargetToState(p),
  }
}

const _mapAssetClasses = portfolio => {
  if (!portfolio?.asset_classes?.allocations) return null

  let result = {}

  result.allocations = portfolio?.asset_classes?.allocations.map(
    ({ name, weight, value }) => ({
      name,
      weight: {
        shorts: (parseFloat(weight.shorts) * 100).toFixed(0),
        longs: (parseFloat(weight.longs) * 100).toFixed(0),
      },
      value: {
        shorts: parseFloat(value.shorts),
        longs: parseFloat(value.longs),
      },
    })
  )

  const totals = portfolio?.asset_classes?.totals
  result.totals = {
    weight: {
      shorts: (parseFloat(totals?.weight?.shorts) * 100).toFixed(0),
      longs: (parseFloat(totals?.weight?.longs) * 100).toFixed(0),
    },
    value: {
      shorts: parseFloat(totals?.value?.shorts),
      longs: parseFloat(totals?.value?.longs),
    },
  }

  return result
}

const _mapESGScores = portfolio => {
  const esgScores = portfolio?.esg_scores

  let result = {
    overall: {
      total_score: parseFloat(esgScores?.overall?.total_score).toFixed(0),
      e_score: parseFloat(esgScores?.overall?.e_score).toFixed(0),
      s_score: parseFloat(esgScores?.overall?.s_score).toFixed(0),
      g_score: parseFloat(esgScores?.overall?.g_score).toFixed(0),
    },
  }
  if (esgScores.shorts) {
    Object.assign(result, {
      shorts: {
        total_score: parseFloat(esgScores?.shorts?.total_score)?.toFixed(2),
        e_score: parseFloat(esgScores?.shorts?.e_score)?.toFixed(2),
        s_score: parseFloat(esgScores?.shorts?.s_score)?.toFixed(2),
        g_score: parseFloat(esgScores?.shorts?.g_score)?.toFixed(2),
      },
    })
  }

  if (esgScores.longs) {
    Object.assign(result, {
      longs: {
        total_score: parseFloat(esgScores?.longs?.total_score)?.toFixed(2),
        e_score: parseFloat(esgScores?.longs?.e_score)?.toFixed(2),
        s_score: parseFloat(esgScores?.longs?.s_score)?.toFixed(2),
        g_score: parseFloat(esgScores?.longs?.g_score)?.toFixed(2),
      },
    })
  }
  return result
}

const _mapHoldings = portfolio => {
  if (!portfolio.holdings || !portfolio.holdings.length) return null

  return portfolio.holdings.map(h => ({
    id: h.instrument_id,
    holding_id: h.id,
    asset_class: h.asset_class,
    symbol: h.ticker,
    name: h.name,
    description: h.description,
    weight: (parseFloat(h.weight) * 100).toFixed(2),
    value: parseFloat(h.value),

    sdgs: {
      positive: h.sdgs.positive,
      negative: h.sdgs.negative,
    },

    esg_score: {
      total_score: parseFloat(h.scores.total).toFixed(2),
      e_score: parseFloat(h.scores.e).toFixed(2),
      s_score: parseFloat(h.scores.s).toFixed(2),
      g_score: parseFloat(h.scores.g).toFixed(2),
    },
  }))
}

const _mapWrappedHoldings = portfolio => {
  if (!portfolio.wrapped_holdings || !portfolio.wrapped_holdings.length)
    return null

  return portfolio.wrapped_holdings.map(h => ({
    id: h.instrument_id,
    entity_id: h.entity_id,
    name: h.entity_name,
    weight: (parseFloat(h.weight) * 100).toFixed(2),
    value: parseFloat(h.value),

    esg_score: {
      total_score: parseFloat(h.scores.total).toFixed(2),
      e_score: parseFloat(h.scores.e).toFixed(2),
      s_score: parseFloat(h.scores.s).toFixed(2),
      g_score: parseFloat(h.scores.g).toFixed(2),
    },
  }))
}

export const mapPortfolioToFormState = portfolio => {
  return {
    name: { value: portfolio.name, error: null },
    description: { value: portfolio.description, error: null },
    holdings: portfolio.holdings.map(
      ({ holding_id, instrument_name, isin, weight, description }) => {
        return {
          holding_id,
          description,
          value: isin,
          label: instrument_name,
          weight: (weight * 100).toFixed(2),
          deleted: false,
          error: null,
        }
      }
    ),
  }
}

export const mapStateToPortfolio = values => {
  return {
    portfolio: {
      name: values.name.value || '',
      description: values.description.value || '',
      holdings: values.holdings
        .filter(h => !h.deleted)
        .map(h => {
          const holding = {
            name: h.label,
            description: h.description,
            isin: h.value,
            weight: parseFloat(h.weight) / 100,
          }

          if (!h.new) holding.id = h.holding_id

          return holding
        }),
    },
  }
}

export const mapPortfoliosToState = portfolios =>
  portfolios.map(p => {
    return {
      id: p.id,
      name: p.name,
      as_of: dayjs(p.as_of).utc().format('MM/DD/YY'),
      snapshot_id: p.snapshot_id,
      favorite: p.favorite,
      total_score: parseFloat(p.total_score).toFixed(1),
      e_score: parseFloat(p.e_score).toFixed(1),
      s_score: parseFloat(p.s_score).toFixed(1),
      g_score: parseFloat(p.g_score).toFixed(1),
    }
  })

export const mapPortfolioFoldersToState = (portfolios, impactBenchmarks) => {
  const sections = {
    // my_portfolios: { id: 'my_portfolios', name: 'MY PORTFOLIOS', folders: [], portfolios: [] },
    shared_portfolios: {
      id: 'shared_portfolios',
      name: 'SHARED PORTFOLIOS',
      folders: [],
      portfolios: [],
    },
    benchmarks: {
      id: 'benchmarks',
      name: 'BENCHMARKS',
      folders: [],
      portfolios: [],
    },
  }

  portfolios.forEach(r => {
    const { id, name, kind, current_snapshot_id, folderInfo, status } = r

    // GET FOLDER TYPE, DEFAULTS TO 'SHARED PORTFOLIOS'
    const type = (folderInfo && folderInfo.type) || 'shared_portfolios'

    // GET PORTFOLIO SECTION
    const section = sections[type]

    // IF PORTFOLIO IS INSIDE FOLDER
    if (folderInfo && folderInfo.folder) {
      // FIND FOLDER OBJECT
      let folder = _find(section.folders, { name: folderInfo.folder })

      // IF FOLDER DOES NOT EXIST IN OBJECT, CREATE FOLDER
      if (!folder) {
        folder = createPortfolioFolder(folderInfo, section)
      }

      // PUSH PORTFOLIO TO FOLDER LEVEL
      folder.portfolios.push({ id, name, kind, status, current_snapshot_id })
    } else {
      // PUSH PORTFOLIO TO SECTION LEVEL
      section.portfolios.push({ id, name, kind, status, current_snapshot_id })
    }
  })

  impactBenchmarks.forEach(b => {
    const { targetId, targetType, name } = b
    sections.benchmarks.portfolios.push({
      id: targetId,
      targetId: targetId,
      targetType: targetType,
      name,
    })
  })

  return sections
}
