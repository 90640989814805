import qs from 'query-string'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import injectSheet from 'react-jss'

import AssetClassAllocationCard from '../../components/cards/AssetClassAllocationCard'
import Co2ProjectionsCard from '../../components/cards/Co2ProjectionsCard'
import ESGScoreCard from '../../components/cards/ESGScoreCard'
import InstrumentSummaryCard from '../../components/cards/InstrumentSummaryCard'
import RevenueSegmentCard from '../../components/cards/RevenueSegmentCard'
import SDGExposureCard from '../../components/cards/SDGExposureCard'
import SubsidyViolationCard from '../../components/cards/SubsidyViolationCard'
import TVLTargetCard from '../../components/cards/TVLTargetCard'
import Badges from '../../components/styleguide/badges/Badges'
import DisclosureFooter from '../../components/styleguide/DisclosureFooter'
import ErrorPage from '../../components/styleguide/ErrorPage'
import Loading from '../../components/styleguide/Loading'
import SectionTitle from '../../components/styleguide/SectionTitle'
import YBBreadcrumb from '../../components/styleguide/YBBreadcrumb'
import { tabNames } from '../../constants'
import { scrollToComponent } from '../../helpers/shared'
import { downloadTemplate } from '../../pdf/index'
import InstrumentTemplate from '../../pdf/templates/InstrumentTemplate'
import { getESGYearlyMetrics } from '../../repositories/instruments_repository'
import { BG_COLOR_PRIMARY } from '../../styles/colors'
import CarbonAuditCard from '../../yb_components/cards/carbon_audit_card/CarbonAuditCard'
import { CarbonAuditTarget } from '../../yb_components/cards/carbon_audit_card/CarbonAuditCard.constants'
import { TargetClass, TargetType } from '../../yb_constants/enums/global_enums'
import useCarbonAudit from '../../yb_hooks/modules/target/useCarbonAudit'
import SustainableGPTUI from '../../yb_modules/sustainable_gpt_ui/SustainableGPTUI'
import AppStore, { AppContext } from '../../yb_stores/app_store/AppStore'
import { loadESGYearlyMetrics } from '../helpers/ESGMetricsHelper'
import { formatPortfolioCarbonSummaryCard } from '../portfolio/components/carbon_report/helpers'
import { TcfdPortfolioScoreCard } from '../portfolio/tcfd/components/tcfd_portfolio_score/TcfdPortfolioCard'
import FundsHoldingsCard from './components/funds_holdings_card/FundsHoldingsCard'
import ImpactsSection from './components/ImpactsSection'
import InstrumentHeader from './components/InstrumentHeader'
import Peers from './components/Peers'
import {
  formatCompanyCarbonSummaryCard,
  renderCompanyCarbonReportWidget,
} from './helpers/carbon_report'
import {
  loadBadges,
  loadCarbonSummaryReportData,
  loadEntityPeers,
  loadInstrument,
  loadInstrumentCo2Projections,
  loadInstrumentHoldingAssetAllocations,
  loadInstrumentHoldings,
  loadTcfdHoldings,
} from './loaders/holding_loaders'

/* global react_app_organization */
/*eslint no-undef: "error"*/

const Instrument = ({
  instrumentId,
  breadcrumbs = {},
  riskAssessmentLink = null,
}) => {
  const [instrument, setInstrument] = useState(null)
  const [impactBenchmark, setImpactBenchmark] = useState(null)
  const [badges, setBadges] = useState({ positive: [], negative: [] })
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState({ show: false })
  const [holdings, setHoldings] = useState(null)
  const [holdingsTotal, setHoldingsTotal] = useState(0)
  const [loadingHoldings, setLoadingHoldings] = useState(true)
  const [ESGYearlyMetrics, setESGYearlyMetrics] = useState(null)
  const [asssetAllocations, setAssetAllocations] = useState(null)
  const [co2Projections, setCo2Projections] = useState(null)
  const [showExport, setShowExport] = useState(false)
  const [currentPeersTab, setCurrentPeersTab] = useState(tabNames.ESG_SCORES)
  const [peers, setPeers] = useState([])
  const [tcfdScores, setTcfdScores] = useState(null)
  const [carbonSummaryReportData, setCarbonSummaryReportData] = useState(null)
  const originatingInstrumentName = qs.parse(location.search)?.redirected_from
  const [state] = useContext(AppContext)
  const [loadingState, setLoadingState] = useState({ carbonAudit: false })
  const {
    carbonAuditData,
    carbonAuditDropdownOptions,
    carbonAuditPerformers,
    onCarbonAuditChange,
    onDownloadCSV,
  } = useCarbonAudit({
    targetId: instrumentId,
    targetType: TargetClass.INSTRUMENT,
    setError,
    setLoading: val => setLoadingState({ ...loadingState, carbonAudit: val }),
  })

  useEffect(
    loadInstrument.bind(null, {
      instrumentId,
      setInstrument,
      setImpactBenchmark,
      setError,
    }),
    []
  )
  useEffect(loadBadges.bind(null, { instrumentId, setBadges, setError }), [])

  useEffect(() => {
    loadTcfdHoldings(null, setTcfdScores, null, instrumentId)
  }, [])

  useEffect(() => {
    loadCarbonSummaryReportData(setCarbonSummaryReportData, instrumentId)
  }, [])

  useEffect(
    () =>
      loadInstrumentHoldings(
        instrumentId,
        setHoldings,
        setHoldingsTotal,
        setError,
        1,
        '', // empty query params first initialization.
        setLoadingHoldings
      ),
    []
  )
  useEffect(
    () =>
      loadInstrumentHoldingAssetAllocations(
        instrumentId,
        setAssetAllocations,
        setError
      ),
    []
  )
  useEffect(
    () =>
      loadInstrumentCo2Projections(instrumentId, setCo2Projections, setError),
    []
  )
  useEffect(
    () => loadEntityPeers(instrument?.entity_id, setPeers, setError),
    [instrument]
  )

  const updateHoldings = (page, holdingsQuery) => {
    loadInstrumentHoldings(
      instrumentId,
      setHoldings,
      setHoldingsTotal,
      setError,
      page,
      holdingsQuery,
      setLoadingHoldings
    )
  }

  if (instrument?.proxied_instrument_id)
    window.location.replace(
      `/app/instruments/${
        instrument?.proxied_instrument_id
      }?redirected_from=${encodeURIComponent(instrument.name)}`
    )

  useEffect(() => {
    document.title = document.title.replace(/(&amp;)/g, '&')
  }, [])
  useEffect(
    () =>
      loadESGYearlyMetrics({
        targetId: instrumentId,
        setESGYearlyMetrics,
        getESGYearlyMetrics,
        setError,
      }),
    []
  )

  const memoCarbonSummaryData = useMemo(
    () =>
      carbonSummaryReportData?.has_holdings
        ? formatPortfolioCarbonSummaryCard(carbonSummaryReportData)
        : formatCompanyCarbonSummaryCard(carbonSummaryReportData),
    [carbonSummaryReportData]
  )

  const exportReport = ({ reportVersion, timestamp }) => {
    setLoading({ show: true, message: 'Generating Report...' })
    setShowExport(false)
    downloadTemplate(
      <AppStore initialState={state}>
        <InstrumentTemplate
          instrument={instrument}
          impactBenchmark={impactBenchmark}
          badges={badges}
          version={reportVersion}
          peersTab={currentPeersTab}
          peers={peers}
          assets={asssetAllocations}
          ESGYearlyMetrics={ESGYearlyMetrics}
          co2Projections={co2Projections}
          carbonAuditData={carbonAuditData}
          tcfdScores={tcfdScores}
          carbonReportData={memoCarbonSummaryData}
        />
      </AppStore>,
      reportVersion,
      `yb-${instrument.name.split(' ').join('-')}-${timestamp}`,
      instrument.id,
      'Instrument',
      instrument.name,
      () => {
        setLoading({ show: false })
      }
    )
  }

  if (error)
    return <ErrorPage code={error} message={error == 404 ? 'NOT FOUND' : ''} />
  if (!instrument) return null

  const {
    esg_score,
    impacts,
    revenue_segments,
    subsidies,
    violations,
    sdg_exposures,
    tvl_insight_scores,
  } = instrument

  const SDGExposureHeight = () => {
    return Object.keys(sdg_exposures).length < 2
      ? '200px'
      : `${Math.ceil(130 * ((Object.keys(sdg_exposures).length + 1) / 16))}vh`
  }

  let correctRiskAssessmentLink = riskAssessmentLink
    ? riskAssessmentLink
    : instrument?.entity_id != null
    ? `/app/entities/${instrument.entity_id}/esg`
    : null
  const canRenderCarbonAudit =
    carbonAuditData?.table?.data?.length != null && instrument?.has_holdings

  return (
    !instrument?.proxied_instrument_id && (
      <div className='page__container' style={{ padding: '1.25rem' }}>
        {originatingInstrumentName && (
          <div className='alert alert-primary'>
            {instrument.name} is a proxy for {originatingInstrumentName}. Read
            more here to understand why some instruments are proxies.{' '}
            <a
              href='http://help.yves.blue/en/articles/4837119-faq-what-is-a-proxy-instrument'
              target='_blank'
              rel='noreferrer'
            >
              More info
            </a>
          </div>
        )}
        <YBBreadcrumb breadcrumbs={breadcrumbs} />

        <InstrumentHeader
          setShowExport={setShowExport}
          showExport={showExport}
          onExport={exportReport}
          instrument={instrument}
          riskAssessmentLink={correctRiskAssessmentLink}
        />

        <div>
          <Row className='mb-4'>
            {instrument.description ||
            (asssetAllocations && asssetAllocations.length > 0) ? (
              <Col md={12} lg={6}>
                {instrument.description && (
                  <InstrumentSummaryCard instrument={instrument} />
                )}
                {asssetAllocations && asssetAllocations.length > 0 && (
                  <AssetClassAllocationCard
                    showValue={false}
                    assets={asssetAllocations}
                  />
                )}
              </Col>
            ) : null}

            {esg_score && (
              <Col md={12} lg={6}>
                <ESGScoreCard
                  scores={esg_score}
                  customESGLabels={instrument.custom_esg_labels}
                />
              </Col>
            )}
          </Row>

          {react_app_organization.organization.can_access_sustainable_gpt && (
            <Row>
              <Col md={12} lg={12}>
                <SustainableGPTUI />
              </Col>
            </Row>
          )}

          {instrument?.entity_id != null && peers?.length > 1 && (
            <Row className='mb-4'>
              <Peers
                instrument={instrument}
                peers={peers}
                currentPeersTab={currentPeersTab}
                setCurrentPeersTab={setCurrentPeersTab}
                setPeersCallback={setPeers}
              />
            </Row>
          )}
          {
            // eslint-disable-next-line no-undef
            tcfdScores &&
              react_app_organization.organization.can_access_carbon_report && (
                <Row className='mb-4'>
                  <TcfdPortfolioScoreCard
                    scores={tcfdScores}
                    instrumentName={instrument.name}
                    margin={'0 15px 0 15px'}
                  />
                </Row>
              )
          }
          {carbonSummaryReportData && (
            <Row className='mb-4' id='report-summary-card'>
              <Col md={12} lg={12}>
                {scrollToComponent('report-summary-card', 1000)}
                {react_app_organization.organization.can_access_carbon_report &&
                  renderCompanyCarbonReportWidget(memoCarbonSummaryData)}
              </Col>
            </Row>
          )}
          <Row className='mb-4'>
            <Col md={12} lg={12}>
              {canRenderCarbonAudit && (
                <CarbonAuditCard
                  loading={loadingState?.carbonAudit}
                  display={CarbonAuditTarget.INSTRUMENT}
                  data={carbonAuditData}
                  onChange={onCarbonAuditChange}
                  sectorOptions={carbonAuditDropdownOptions}
                  performers={carbonAuditPerformers}
                  onDownloadCSV={onDownloadCSV}
                  target={instrument}
                  benchmark={impactBenchmark}
                />
              )}
            </Col>

            {/* <Col md={12} lg={12}>
            <SelectedHeadlinesCard name={instrument.name} instrument={instrument} />
          </Col> */}

            {subsidies && violations && (
              <Col md={12} lg={12} className='mb-4'>
                <SubsidyViolationCard
                  subsidies={subsidies}
                  subsidiesAdditionalTitleLink={{
                    label: 'More Info »',
                    href: `/app/instruments/${instrument.id}/subsidies`,
                  }}
                  violations={violations}
                  violationsAdditionalTitleLink={{
                    label: 'More Info »',
                    href: `/app/instruments/${instrument.id}/violations`,
                  }}
                />
              </Col>
            )}

            {revenue_segments && (
              <Col md={12} lg={6}>
                <RevenueSegmentCard segments={revenue_segments} />
              </Col>
            )}
          </Row>

          {sdg_exposures && <SectionTitle title='SDG EXPOSURES' />}

          <div className='row mb-4'>
            {sdg_exposures && (
              <Col xs={12}>
                <SDGExposureCard
                  cardHeight={SDGExposureHeight()}
                  data={sdg_exposures}
                  iconWidth={45}
                  heightMin={40}
                  heightMax={50}
                  sdgXpos={50}
                  labelPosOffset={5}
                  fontClass='SDG-Exposure-Labels-Instrument'
                  xLAbelPos={5.5}
                  bubbleSize={0.008}
                  secondLastValue={75}
                  chartSubtract={70}
                  zeroOffset={7}
                  title={'  '}
                  svgClass={'SDG-Exposure-Chart-SVG-instrument'}
                  wrapperClass={'SDG-Card-Wrapper-instrument'}
                />
              </Col>
            )}
          </div>

          <div className='row mb-4'>
            {impacts && (
              <Col xs={12} className='mb-4'>
                <ImpactsSection
                  targetType={TargetType.INSTRUMENT}
                  impacts={instrument.impacts}
                  name={instrument?.name}
                />
              </Col>
            )}

            <div className='row mb-4' style={{ padding: '0px 15px' }}>
              <TVLTargetCard
                target={instrument}
                data={tvl_insight_scores}
                benchmarkName={impactBenchmark?.name}
                benchmarkData={impactBenchmark?.tvl_insight_scores}
                description={
                  'These ESG-like scores utilize Truvalue Labs long-term momentum scores to \
                    measure ESG performance over time. These momentum scores are based on \
                    daily tracking scores measuring sentiment analysis for daily news \
                    controversies and reports on a company level.'
                }
              />
            </div>

            {/* {
            rep_risk_data &&
                <RepRiskBarGraphInstrumentCard
                  data={rep_risk_data}
                  benchmark={rep_risk_benchmark_data}
                />
          } */}

            {badges.positive?.length > 0 && (
              <Col xs={6} className='mb-4'>
                <Badges
                  title={'POSITIVE IMPACT BADGES'}
                  badges={badges?.positive}
                  holdingsTotal={instrument?.holdings_total}
                />
              </Col>
            )}

            {badges.negative?.length > 0 && (
              <Col xs={6} className='mb-4'>
                <Badges
                  title={'NEGATIVE IMPACT BADGES'}
                  badges={badges?.negative}
                  holdingsTotal={instrument?.holdings_total}
                />
              </Col>
            )}

            {/* {
            hasESGYearlyMetrics(ESGYearlyMetrics) && (
              <ESGMetricsCard ESGYearlyMetrics={ESGYearlyMetrics} />
            )
          } */}

            {co2Projections &&
              co2Projections.projections &&
              co2Projections.projections.length > 1 && (
                <Col xs={12} className='mb-4'>
                  <Co2ProjectionsCard projections={co2Projections} />
                </Col>
              )}
            {holdings && (
              <Col xs={12} className='mb-4'>
                <FundsHoldingsCard
                  holdings={holdings}
                  holdingsTotal={holdingsTotal}
                  updateHoldings={updateHoldings}
                  loadingHoldings={loadingHoldings}
                />
              </Col>
            )}
          </div>
          <DisclosureFooter />
        </div>

        <Loading show={loading.show} message={loading.message} />
      </div>
    )
  )
}

const style = {
  body: {
    backgroundColor: BG_COLOR_PRIMARY,
    padding: 40,
    paddingTop: 0,
    '& > .row': {
      marginBottom: 20,

      '& > *': {
        marginTop: 20,
      },
    },
    '& > .section': {
      marginTop: 50,
    },
    '& > :last-child': {
      marginBottom: 100,
    },
  },
}

export default injectSheet(style)(Instrument)
