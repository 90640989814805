const green = '25, 135, 84'
const red = '176, 42, 55'

export const computeESGColor = score => {
  if (score < 50) {
    return `rgba(${red}, ${(100 - score) / 100})`
  } else {
    return `rgba(${green}, ${score / 100})`
  }
}

export const computeSDGColor = score => {
  if (score < 0) {
    return `rgba(${red}, ${((-score / 2) / 100) + 0.5})`
  } else {
    return `rgba(${green}, ${((score / 2) / 100) + 0.5})`
  }
}

export const computeCarbonBudgetColor = score => {
  if (score <= 0) {
    return `rgba(${green}, ${((-score / 2) / 100) + 0.5})`
  } else {
    return `rgba(${red}, ${((score / 2) / 100) + 0.5})`
  }
}
