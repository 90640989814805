import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

import { timestamp } from '../../../../helpers/shared'
import { downloadTemplateFromRef } from '../../../../pdf'
import { PDF } from '../../../../pdf/pdf'
import useCurrentTabRef from '../../../../yb_hooks/target/useCurrentTabRef'
import TemplateRenderContainer from '../../../../yb_modules/portfolio/modules/longitudinal_report/components/TemplateRenderContainer'
import { AppContext } from '../../../../yb_stores/app_store/AppStore'
import { loadReportSummaryCard } from '../../loaders/portfolio_loaders'
import BudgetProjections from '../carbon_report/BudgetProjections'
import CarbonOwnership from '../carbon_report/CarbonOwnership'
import CarbonTaxExposure from '../carbon_report/CarbonTaxExposure'
import { formatPortfolioCarbonSummaryCard } from '../carbon_report/helpers'
import LeadersLaggards from '../carbon_report/LeadersLaggards'
import PowerCompanyTransition from '../carbon_report/PowerCompanyTransition'
import RenewableEnergyPurchasing from '../carbon_report/RenewableEnergyPurchasing'
import ReportSummaryCard from '../carbon_report/ReportSummaryCard'
import ScienceBasedTargetsInitiative from '../carbon_report/ScienceBasedTargetsInitiative'
import SectorAllocation from '../carbon_report/SectorAllocation'
import SolutionCompanyHoldings from '../carbon_report/SolutionCompanyHoldings'
import { REPORT_SUMMARY_CARD_COPY } from './CarbonReport.constants'

export const StyledCarbonReport = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(12, 1fr);
`
const exportReport = ({ setLoading, snapshot, ref }) => {
  setLoading({ show: true, message: 'Generating Report...' })

  downloadTemplateFromRef(
    ref,
    'full',
    `yb-${snapshot.name.split(' ').join('-')}-Carbon-Report-${timestamp}`,
    snapshot.id,
    'CarbonReport',
    snapshot.name,
    () => {
      setLoading({ show: false })
    }
  )
}

const initialState = {}

const reducer = (state, action) => {
  switch (action.type) {
    case 'sector-allocation-loaded':
      return { ...state, sectorAllocations: action.payload }
    case 'solution-companies-loaded':
      return { ...state, solutionCompanies: action.payload }
    case 'budget-projections-loaded':
      return { ...state, budgetProjections: action.payload }
    case 'carbon-tax-rates-loaded':
      return { ...state, carbonTaxRates: action.payload }
    case 'leaders-laggards-loaded':
      return { ...state, leadersLaggards: action.payload }
    case 'holding-ownerships-loaded':
      return { ...state, holdingOwnerships: action.payload }
    case 'renewable-energy-loaded':
      return { ...state, renewableEnergy: action.payload }
    case 'sbti-loaded':
      return { ...state, sbti: action.payload }
    case 'power-company-transitions-loaded':
      return { ...state, powerCompanyTransitions: action.payload }
    case 'summary-loaded':
      return { ...state, summary: action.payload }
  }
}

const CarbonReport = forwardRef(
  ({ snapshot, setLoading, impactBenchmark, onCategoryClick }, ref) => {
    const templateRef = useRef()
    const [state, dispatch] = useReducer(reducer, initialState)
    const [carbonSummaryReportData, setCarbonSummaryReportData] = useState({})
    const portfolioId = snapshot.portfolio_id
    const snapshotId = snapshot.id
    const [appState] = useContext(AppContext)
    useCurrentTabRef(ref)

    useEffect(
      () =>
        loadReportSummaryCard({
          snapshotId,
          portfolioId,
          setData: setCarbonSummaryReportData,
          dispatch,
        }),
      []
    )

    useImperativeHandle(ref, () => ({
      exportPDFReport() {
        exportReport({ setLoading, ref: templateRef, snapshot })
      },
    }))

    const memoCarbonSummaryData = useMemo(
      () => formatPortfolioCarbonSummaryCard(carbonSummaryReportData),
      [carbonSummaryReportData]
    )

    return (
      <>
        <StyledCarbonReport>
          <ReportSummaryCard
            dataProp={memoCarbonSummaryData}
            title={REPORT_SUMMARY_CARD_COPY.title}
            moduleDescription={REPORT_SUMMARY_CARD_COPY.moduleDescription}
            explainerLink={
              'http://help.yves.blue/en/articles/6698762-carbon-report-portfolio-summary-on-yvesblue#h_465f905ff3'
            }
          />

          <CarbonOwnership
            snapshotId={snapshotId}
            portfolioId={portfolioId}
            dispatch={dispatch}
          />

          <SectorAllocation
            impactBenchmark={impactBenchmark}
            snapshotId={snapshotId}
            portfolioId={portfolioId}
            dispatch={dispatch}
          />

          <SolutionCompanyHoldings
            snapshotId={snapshotId}
            portfolioId={portfolioId}
            dispatch={dispatch}
          />

          <PowerCompanyTransition
            snapshotId={snapshotId}
            portfolioId={portfolioId}
            dispatch={dispatch}
          />

          <LeadersLaggards
            impactBenchmark={impactBenchmark}
            snapshotId={snapshotId}
            portfolioId={portfolioId}
            dispatch={dispatch}
            onCategoryClick={onCategoryClick}
          />

          <ScienceBasedTargetsInitiative
            snapshotId={snapshotId}
            portfolioId={portfolioId}
            dispatch={dispatch}
          />

          <BudgetProjections
            snapshotId={snapshotId}
            portfolioId={portfolioId}
            dispatch={dispatch}
          />

          <CarbonTaxExposure
            snapshotId={snapshotId}
            portfolioId={portfolioId}
            dispatch={dispatch}
          />

          <RenewableEnergyPurchasing
            snapshotId={snapshotId}
            portfolioId={portfolioId}
            dispatch={dispatch}
          />
        </StyledCarbonReport>
        <TemplateRenderContainer ref={templateRef}>
          <PDF.CarbonReport
            /* Target Data */
            targetName={appState?.target?.name}
            benchmarkName={appState?.benchmark?.name}
            /* Data */
            snapshot={snapshot}
            summaryData={memoCarbonSummaryData}
            impactBenchmark={impactBenchmark}
            sectorAllocations={state?.sectorAllocations}
            solutionCompanies={state?.solutionCompanies}
            budgetProjections={state?.budgetProjections}
            carbonTaxRates={state?.carbonTaxRates}
            leadersLaggards={state?.leadersLaggards}
            holdingOwnerships={state?.holdingOwnerships}
            renewableEnergy={state?.renewableEnergy}
            powerCompanyTransitions={state?.powerCompanyTransitions}
            sbti={state?.sbti}
          />
        </TemplateRenderContainer>
      </>
    )
  }
)

CarbonReport.displayName = 'CarbonReport'

export default CarbonReport
