/* eslint-disable react/display-name */
import { Card } from '@exponentialtech/yb-component-library'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'

import useCurrentTabRef from '../../../yb_hooks/target/useCurrentTabRef'
import { PaiTabContent } from './pai/components/pai_tab_nav/PaiTabContent'
import { PaiTabNav } from './pai/components/pai_tab_nav/PaiTabNav'

export const RegulatoryDashTab = forwardRef(
  ({ activePaiTab, portfolioId, snapshotId }, ref) => {
    useCurrentTabRef(ref)

    return (
      <Card>
        <header>
          <h4 className='card-title' style={{ flexGrow: 0 }}>
            Principal Adverse Impacts (PAI)
          </h4>
          <PaiTabNav
            activeTab={activePaiTab}
            portfolioId={portfolioId}
            snapshotId={snapshotId}
          />
        </header>
        <PaiTabContent
          activeTab={activePaiTab}
          portfolioId={portfolioId}
          snapshotId={snapshotId}
        />
      </Card>
    )
  }
)

RegulatoryDashTab.propTypes = {
  activePaiTab: PropTypes.string.isRequired,
  portfolioId: PropTypes.string.isRequired,
  snapshotId: PropTypes.string.isRequired,
}
