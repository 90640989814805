import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import styled from 'styled-components'

import Card from '../../../components/styleguide/Card'
import Table from '../../../components/styleguide/Table'
import { getManagerDBFunds } from '../../../repositories/managers_repository'
import { FONT_COLOR_SECONDARY } from '../../../styles/colors'
import H2 from '../../../yb_components/styleguide/headings/H2'
import SpinnerContainer from '../../../yb_components/styleguide/spinners/SpinnerContainer'

const StyledH2 = styled(H2)`
  margin-bottom: 1.375rem;
`

const ManagerDBFunds = () => {
  const [funds, setFunds] = useState(null)

  useEffect(() => {
    const queryParams = {
      order_by: 'updated_at',
      order: 'desc',
      limit: 10,
    }

    getManagerDBFunds(queryParams).then(response => {
      setFunds(response.data)
    })
  }, [])

  return (
    <>
      <Row>
        <Col md={12}>
          <StyledH2>Latest Updates</StyledH2>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <SpinnerContainer isLoading={funds == null}>
            <FundCard funds={funds} />
          </SpinnerContainer>
        </Col>
      </Row>
    </>
  )
}

const FundCard = ({ funds }) => {
  const tableBody = () => {
    let fundRows = [['No funds were found.']]

    if (funds?.length > 0) {
      fundRows = funds.map(fund => [
        <>
          <FundId>{fund.id}</FundId>
          <FundName>{fund.name}</FundName>
          <FundDate>{fund.updated_at}</FundDate>
          <FundLink href={`app/managers/${fund.id}`}>More Info »</FundLink>
        </>,
      ])
    }

    return fundRows
  }

  const onRowClick = row => {
    const fundId = row?.[0]?.props?.children?.[0]?.props?.children
    if (fundId != null) {
      document.location.href = `/app/managers/${fundId}`
    }
  }

  return (
    <Card>
      <StyledTable
        showSort={false}
        headers={[]}
        body={tableBody()}
        onRowClick={onRowClick}
      />
    </Card>
  )
}

const FundId = styled.div`
  display: none;
`

const FundName = styled.div`
  font-size: 1.1rem;
  font-weight: 700;
`

const FundDate = styled.div`
  font-size: 0.8rem;
  color: ${FONT_COLOR_SECONDARY};
`

const FundLink = styled.a`
  font-size: 0.8rem;
`

const StyledTable = styled(Table)`
  tr {
    cursor: pointer;
  }
`

export default ManagerDBFunds
