import dayjs from 'dayjs'
import React from 'react'
import styled from 'styled-components'

import Table from '../../../../../../../components/styleguide/Table'
import UserImg from '../../../../../../../components/styleguide/UserImg'

dayjs().format()

const StyledDiv = styled.div`
  .note-item {
    width: 210px;
    display: flex;
    gap: 10px;
    p {
      font-size: 12px;
    }
    p:nth-child(1) {
      font-size: 10px;
      margin: 0px;
    }
    p:nth-child(2) {
      font-size: 8px;
      margin: 0px;
      color: #979797;
    }
  }
  td:first-child {
    width: 210px;
  }
  .note-list {
    overflow: hidden;
    border-radius: 5px;
    margin-top: 10px;
  }
`

export const NotesList = ({ data }) => {
  const getBody = data =>
    data && data.length > 0
      ? data?.map((note, i) => [
          <div className='note-item' key={i}>
            <UserImg
              src={
                note.user_avatar_url
                  ? note.user_avatar_url
                  : '/icons/ico_user_alt.svg'
              }
            />
            <div>
              <p>{note.user_full_name}</p>
              <p>{dayjs(note.created_at).format('MMMM D, YYYY h:mma')}</p>
            </div>
          </div>,
          <p key={`p-${i}`} style={{ textAlign: 'left' }}>
            {note.body}
          </p>,
        ])
      : [['There are no current notes.']]
  return (
    <StyledDiv>
      <h6>Internal Notes:</h6>
      <div className='note-list' style={{ margin: '8px 0 12px 0' }}>
        <Table
          look={{
            tdFontSize: '12px',
            trEvenBackgroundColor: '#F6F8FD',
            tableMargin: '0px',
            verticalAlign: 'top',
            rowAlternation: 'odd',
          }}
          leftAlignHeader={[0]}
          rightAlignHeader={[1, 2, 3]}
          alignNumbers={true}
          body={getBody(data)}
          headers={[]}
        />
      </div>
    </StyledDiv>
  )
}
