import { ModuleTabs } from '@ui/components/interactive/navigation/ModuleTabs'
import { ExternalLink } from '@ui/components/presentation/links/ExternalLink'
import { Button, EButtonVariants } from '@ui/elements/Button'
import classNames from 'classnames'
import React, { useMemo, useState } from 'react'

import { ModuleCard } from '../../../../../ui/components/presentation/cards/ModuleCard/ModuleCard'
import { DisclosureDetail } from './components/DisclosureDetail'
import { Disclosures } from './components/Disclosures'
import { CreateDisclosureModal } from './components/Disclosures/components/CreateDisclosureModal'
import { FundConfiguration } from './components/FundConfiguration'
import { HoldingAlignmentDetail } from './components/HoldingAlignmentDetail'
import { HoldingData } from './components/HoldingData/HoldingData'
import { SFDROverview } from './components/SFDROverview'
import Controller, { SFDR_MODULES } from './SFDR.controller'
// import theme from './SFDR.module.scss'
import './SFDR.module.scss'
import DisclosureFormsStore from '../../../../../react/yb_stores/sfdr/disclosure_forms/DisclosureFormsStore'

export interface IProps {
  snapshotId: string
  portfolioId: string
  disclosureId: string
  tab: string
}

type TActionBarTab = 'fund_configuration' | 'disclosures_detail'

export const SFDR: React.FC<IProps> = ({
  portfolioId,
  snapshotId,
  detailId,
  tab,
}) => {
  const sfdrTabs = Controller.useTabNav(portfolioId, snapshotId)
  const displayCreateModel = useState<boolean>(false)
  const hasActionBar = useMemo(
    () =>
      [
        SFDR_MODULES.FUND_CONFIGURATION,
        SFDR_MODULES.DISCLOSURES_DETAIL,
      ].includes(tab as TActionBarTab),
    [tab]
  )
  const [, set] = displayCreateModel

  const showModal = () => set(true)

  return (
    <>
      <div
        className={classNames('SFDR', {
          withActionBar: hasActionBar,
        })}
      >
        <ModuleCard>
          <header>
            <ModuleTabs
              active={tab}
              title='Sustainable Finance Disclosure Regulation (SFDR)'
              tabs={sfdrTabs}
            />
          </header>
          <DisclosureFormsStore>
            {{
              [SFDR_MODULES.OVERVIEW]: (
                <SFDROverview
                  portfolioId={portfolioId}
                  snapshotId={snapshotId}
                />
              ),
              [SFDR_MODULES.FUND_CONFIGURATION]: (
                <FundConfiguration
                  portfolioId={portfolioId}
                  snapshotId={snapshotId}
                />
              ),
              [SFDR_MODULES.HOLDING_DATA]: (
                <HoldingData
                  portfolioId={portfolioId}
                  snapshotId={snapshotId}
                />
              ),
              [SFDR_MODULES.HOLDING_DATA_DETAIL]: <HoldingAlignmentDetail />,
              [SFDR_MODULES.DISCLOSURES]: (
                <Disclosures
                  portfolioId={portfolioId}
                  snapshotId={snapshotId}
                />
              ),
              [SFDR_MODULES.DISCLOSURES_DETAIL]: (
                <DisclosureDetail
                  portfolioId={portfolioId}
                  snapshotId={snapshotId}
                  disclosureId={detailId}
                />
              ),
            }[tab] || null}
          </DisclosureFormsStore>
        </ModuleCard>
      </div>
      <CreateDisclosureModal showModel={displayCreateModel} />
    </>
  )
}
