import React from 'react'
import Dropzone from 'react-dropzone'
import styled from 'styled-components'

import {
  baseErrorCss,
  baseFocusCss,
} from '../../../../../yb_components/styleguide/form/base/styles/BaseForm.styles'

const StyledDiv = styled.div`
  margin: 0;
  padding: 0;
  min-height: 180px;
  display: flex;
  /* overflow: hidden; */

  .internal-wrapper {
    padding: 10px;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #e6e7ea;
    border-radius: 6px;
    line-height: 1.2;
    transition: background-color 0.15s ease-in-out;

    &:hover,
    &:focus {
      background-color: #f4f6f9;
      cursor: pointer;
    }

    ${baseFocusCss}

    &.has-error {
      ${baseErrorCss}
    }
  }
  strong {
    display: block;
    font-size: 14px;
  }
  span {
    display: inline-block;
    font-size: 12px;
  }
`

export const BaseFileDropZone = ({ errors, handleDropFile, id }) => {
  return (
    <StyledDiv id={id}>
      <Dropzone onDrop={acceptedFiles => handleDropFile(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <div
            className={
              errors.status ? 'has-error internal-wrapper' : 'internal-wrapper'
            }
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <strong>Drag & Drop or Click to Upload an Image</strong>
            <span>(PNG, JPG or GIF at least 300px wide)</span>
          </div>
        )}
      </Dropzone>
    </StyledDiv>
  )
}
