import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { UnitHelper } from '../data_grid/UnitHelper'
import { PreviousDataCell } from './PreviousDataCell'

const StyledGrid = styled.div`
  margin: 0 0 1.75rem 0;
  width: 100%;

  .grid-head-row {
    height: auto;
    min-height: 20px;
    grid-column: 1 / 5;
    display: grid;
    grid-template-columns: 4fr 3fr 3fr 4fr;
    background-color: #edeef3;
  }
  .grid-head-cell {
    padding: 0.24rem 10px;
    display: flex;
    align-items: center;
  }
  .grid-head-cell span {
    display: inline-block;
    font-size: 11px;
    line-height: 12px;
    text-transform: uppercase;
    font-weight: 700;
    color: #6c757d;
  }
  // grid data
  .grid-data-row {
    grid-column: 1 / 5;
    display: grid;
    grid-template-columns: 4fr 3fr 3fr 4fr;
    background-color: white;
    color: black;

    &:nth-of-type(even) {
      background-color: #f6f8fd;
    }
    &.inactive {
      color: #6c757d;
    }
  }
  .grid-data-cell {
    padding: 0.5rem 10px;
    min-height: 80px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;

    &.center-content {
      justify-content: center;
    }
    span {
      height: auto;
      display: inline-block;
      font-size: 12px;
      line-height: 1.1;
      color: #333333;

      &.metric-span {
        font-size: 14px;
        line-height: 17px;
        color: black;
      }
      &.cell-list-span {
        display: block;
        color: #333333;
      }
      &.cell-date-span {
        display: block;
        white-space: nowrap;
        color: #333333;
      }
    }
  }
`

export const SasbDetailsGrid = ({ metric }) => {
  const [showIndicatorNumber, setShowIndicatorNumber] = useState(false)
  useEffect(() => {
    if (metric.sub_metrics.length === 1) setShowIndicatorNumber(false)
    else setShowIndicatorNumber(true)
  }, [metric])

  return (
    <StyledGrid>
      {/* header */}
      <div className='grid-head-row' role='row'>
        <div className='grid-head-cell' role='columnheader'>
          <span>Metric Description</span>
        </div>
        <div className='grid-head-cell' role='columnheader'>
          <span>Target</span>
        </div>
        <div className='grid-head-cell' role='columnheader'>
          <span>Current Value</span>
        </div>
        <div className='grid-head-cell' role='columnheader'>
          <span>Previous Reported Data</span>
        </div>
      </div>
      {/* body */}
      {metric.sub_metrics != null && metric.sub_metrics.length
        ? metric.sub_metrics.map((el, i) => (
            <div className='grid-data-row' key={el.id} role='row'>
              <div className='grid-data-cell' role='cell'>
                {showIndicatorNumber ? (
                  <span className='metric-span'>
                    ({i + 1}) {el.prompt}
                  </span>
                ) : (
                  <span className='metric-span'>{el.prompt}</span>
                )}
              </div>
              <div className='grid-data-cell' role='cell'>
                <div>
                  {el.target_value != null ? (
                    <React.Fragment key={el.id}>
                      <span key={`indicator_${el.id}`}>
                        <UnitHelper measure={el.target_value} unit={el.unit} />
                      </span>
                      {el.target_due_date && (
                        <span className='cell-date-span' key={`link_${el.id}`}>
                          (by {dayjs(el.target_due_date).format('MMMM D, YYYY')}
                          )
                        </span>
                      )}
                    </React.Fragment>
                  ) : (
                    <span>—</span>
                  )}
                </div>
              </div>
              <div className='grid-data-cell' role='cell'>
                <div>
                  {el.current_value != null ? (
                    <React.Fragment key={el.id}>
                      <span key={`indicator_${el.id}`}>
                        <UnitHelper measure={el.current_value} unit={el.unit} />
                      </span>
                      {el.current_value_as_of && (
                        <span className='cell-date-span' key={`link_${el.id}`}>
                          (as of{' '}
                          {dayjs(el.current_value_as_of).format('MMMM D, YYYY')}
                          )
                        </span>
                      )}
                    </React.Fragment>
                  ) : (
                    <span>—</span>
                  )}
                </div>
              </div>
              <div className='grid-data-cell' role='cell'>
                <div style={{ width: '200px', height: '60px' }}>
                  <PreviousDataCell
                    previousData={el.previously_reported_data}
                  />
                </div>
              </div>
            </div>
          ))
        : null}
    </StyledGrid>
  )
}

SasbDetailsGrid.propTypes = {
  metric: PropTypes.object.isRequired,
}
