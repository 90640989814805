export const sanitizedTcfdNumber = number => {
  if (number == null) return null
  else return Math.round(Number(number))
}

export const getTcfdAccentColor = number => {
  const cleanNumber = sanitizedTcfdNumber(number)
  return cleanNumber == null ? '#6c757d' : '#fff'
}

export const getTcfdColor = number => {
  const cleanNumber = sanitizedTcfdNumber(number)
  switch (cleanNumber) {
    case 0:
      return '#7BD600'
    case 1:
      return '#BED600'
    case 2:
      return '#EAD836'
    case 3:
      return '#E7924C'
    case 4:
      return '#E74C4C'
    case null:
    default:
      return '#DDDDDD'
  }
}

export const getTcfdHoverColor = number => {
  const cleanNumber = sanitizedTcfdNumber(number)
  switch (cleanNumber) {
    case 0:
      return '#4f8a00'
    case 1:
      return '#7a8a00'
    case 2:
      return '#bfae14'
    case 3:
      return '#cb6b1b'
    case 4:
      return '#cb1b1b'
    case null:
    default:
      return '#b7b7b7'
  }
}
