import React, { useState, useRef } from 'react';
import _ from 'lodash';
import { FormControl } from 'react-bootstrap';
import WrapperColumn from '../../../components/styleguide/WrapperColumn';
import InputLabel from '../../../components/styleguide/InputLabel';
import SectionWrapper from '../../../components/styleguide/SectionWrapper';
import { ErrorMessage, StyledImageFileInput, StyledImage, StyledLogoPlaceholder, StyledRemoveLink, WarningMessage } from './ImageFileInput.styles';
import { NONE, ERROR, WARNING } from './ImageFileInput.constants';

const ImageFileInput = ({ label, accept, defaultPreviewSrc, validations, onChange, ...props }) => {
  const [selectedFileUrl, setSelectedFileUrl] = useState(null)
  const [warnings, setWarnings] = useState([])
  const [errors, setErrors] = useState([])
  const fileInputRef = useRef()

  const onChangeCallback = e => {
    const file = e.target.files[0]

    if (file) {
      setSelectedFileUrl(URL.createObjectURL(file))
      validateInput(file).then( validationMessages =>
        onChange(file, validationErrors(validationMessages), validationWarnings(validationMessages))
      )
    } else {
      setSelectedFileUrl(NONE)
      setErrors([])
      setWarnings([])
      onChange(file, [])
    }
  }

  const validateInput = async file => {
    if (_.isEmpty(validations)) return;

    const validationMessages = []
    const fileImage = new Image()
    const fileSize = () => (file.size / 1024 / 1024).toFixed(2) // MB

    const imageValidationPromise = new Promise(resolve => {
      fileImage.onload = imageEvent => {
        const img = imageEvent.target

        if (img.width < validations.min?.width || img.height < validations.min?.height) {
          const {message, messageType} = validations.min
          validationMessages.push({message, messageType})
        }
        if (validations.widthPerHeight && img.width !== img.height * validations.widthPerHeight.value) {
          const {message, messageType} = validations.widthPerHeight
          validationMessages.push({message, messageType})
        }
        if (validations.maxFileSize && file.size > validations.maxFileSize.value) {
          const {message, messageType} = validations.maxFileSize
          validationMessages.push({message, messageType})
        }

        if ( !_.isEmpty(validationErrors(validationMessages)) ) {
          validationMessages.push({
            message: `Uploaded file is ${img.width}x${img.height} pixels, ${fileSize()}MB size`,
            messageType: ERROR,
          })
        }

        setErrors(validationErrors(validationMessages))
        setWarnings(validationWarnings(validationMessages))
        resolve()
      }

      const fileUrl = URL.createObjectURL(file)
      fileImage.src = fileUrl
    })

    await imageValidationPromise
    return validationMessages
  }

  const validationErrors = validationMessages => validationMessages.filter(message => message.messageType === ERROR).map(message => message.message)
  const validationWarnings = validationMessages => validationMessages.filter(message => message.messageType === WARNING).map(message => message.message)

  const removeImage = () => {
    fileInputRef.current.value = ""

    // Call onChange event on file input
    var event = new Event('change', {bubbles: true});
    fileInputRef.current.dispatchEvent(event);
  }

  const filePreviewSrc = selectedFileUrl || defaultPreviewSrc
  const keyifiedLabel = label.toLowerCase().replace(/ /gi, '-')

  return (
    <StyledImageFileInput {...props}>
      <InputLabel label={ label } htmlFor="fileInput"/>
      <SectionWrapper wrap="nowrap" align="center">
        { !filePreviewSrc || filePreviewSrc === NONE ? (
          <StyledLogoPlaceholder data-cy={`${keyifiedLabel}-image-file-preview`}>No logo</StyledLogoPlaceholder>
        ) : (
          <StyledImage src={ filePreviewSrc } data-cy={`${keyifiedLabel}-image-file-preview`} ></StyledImage>
        )}
        <WrapperColumn styles={{margin: "0 0 0 10px", alignSelf: 'stretch', justify: 'space-around'}}>
          <FormControl id="fileInput" type="file" accept={ accept } ref={ fileInputRef } onChange={ onChangeCallback } data-cy={`${keyifiedLabel}-file-input`}/>
          <StyledRemoveLink onClick={ removeImage } data-cy={`${keyifiedLabel}-remove-link`}>
            <i className="fa fa-times"/>
            Remove file
          </StyledRemoveLink>
        </WrapperColumn>
      </SectionWrapper>
      { warnings.map( (warning, i) =>
        <WarningMessage key={`warning-message-${i}`} data-cy={`${keyifiedLabel}-warning-message-${i}`}>{ warning }</WarningMessage>
      )}
      { errors.map( (error, i) =>
        <ErrorMessage key={`error-message-${i}`} data-cy={`${keyifiedLabel}-error-message-${i}`}>{ error }</ErrorMessage>
      )}
    </StyledImageFileInput>
  )
}

ImageFileInput.defaultProps = {
  defaultPreviewSrc: NONE,
  label: "Photo",
  accept: "image/*",
  onChange: ()=>{},
}

export default ImageFileInput
