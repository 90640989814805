import React from 'react'
import Modal from 'react-bootstrap/Modal'
import styled from 'styled-components'

const StyledDialog = styled(props => {
  var propsClone = Object.assign({}, props)
  delete propsClone.sectionsPadding
  return <Modal {...propsClone} />
})`
  .modal-dialog {
    max-width: ${props => (props.width ? props.width : '500px')};
  }
  .modal-content {
    width: ${props => (props.width ? props.width : '800px')};
    font-size: 16px;
    margin-top: 35px;
    margin: ${props => props.margin};
    padding: ${props => props.padding || '15px'};
  }

  .modal-header {
    border-bottom: none;
    font-size: 26px;
    padding: ${props => props.sectionsPadding || '1rem'};
  }

  .modal-title {
    font-size: 18px;
    font-weight: bold;
  }

  .modal-body {
    padding: ${props => props.sectionsPadding || '1rem'};
  }

  .modal-description {
    font-size: 30px;
  }

  .modal-footer {
    border-top: none;
    justify-content: ${props => props.justifyFooter || 'center'};
    padding: ${props => props.sectionsPadding || '1rem'};
  }
  .loading-icon {
    margin-right: auto;
  }
`

export default StyledDialog
