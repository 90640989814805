import React from 'react';
import styled from 'styled-components';
import { APP_COLOR_PRIMARY, FONT_COLOR_PRIMARY, FONT_COLOR_SECONDARY } from '../../styles/colors';
import { addUnit } from '../../helpers/styling';

const StyledSpan = styled.span`
  color: ${props => props.color};
  font-weight: ${props => props.fontWeight};
  font-size: ${props => props.fontSize};
`;

const predefColors = {
  app: APP_COLOR_PRIMARY,
  primary: FONT_COLOR_PRIMARY,
  secondary: FONT_COLOR_SECONDARY
};

const Span = ({ color, bold, thin, text, size, onClick, className }) => {

  const fontColor = predefColors[color] || color;
  const fontWeight = bold ? 'bold' : (thin ? 'thin' : 'normal');
  const fontSize = addUnit(size);
  return (
    <StyledSpan
      className={className}
      fontSize={fontSize}
      color={fontColor}
      fontWeight={fontWeight}
      onClick={onClick}
    >
      {text}
    </StyledSpan>
  );
}

Span.defaultProps = {
  color: 'primary'
}

export default Span;
