import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  margin: 0;
  padding: 10px;
  border-radius: 4px;
  background-color: ${props => props.bgColor || '#DDDDDD'};
  color: ${props => props.color || '#212529'};

  h5 {
    margin: 0 0 10px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    color: ${props => props.color || '#212529'};
  }
`

export const Tile = ({ bgColor, children, className, color, style }) => {
  return (
    <StyledDiv
      bgColor={bgColor}
      className={className}
      color={color}
      style={style}
    >
      {children}
    </StyledDiv>
  )
}

Tile.propTypes = {
  bgColor: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}
