import { ITableHeadSort } from '@ui/components/presentation/Table/components/SortedTableHead'
import { useEffect } from 'react'

import {
  createSFDRDisclosure,
  getSFDRDisclosures,
  publishSFDRDisclosure,
} from '../../../../../../../react/repositories/portfolio_repository'
import { ICreateDisclosureData } from './components/CreateDisclosureModal/CreateDisclosureModal'
import { IDisclosuresFiltersData } from './components/DisclosuresFilters/DisclosuresFilters'
import { IPublishDisclosureData } from './components/PublishDisclosureModal/PublishDisclosureModal'

export interface IDisclosure {
  id: number
  type_name: 'Pre-Contractual Disclosure' | 'Periodic Disclosure'
  type: 'pre_contractual' | 'periodic'
  description: string
  state: 'Draft' | 'Published'
  publish_date: string
  last_edited_date: string
  as_of: string
  additional_info: string
  report_url: string
}

class DisclosuresController {
  useGetDisclosures = (
    portfolioId: string,
    snapshotId: string,
    filters: IDisclosuresFiltersData | undefined,
    sorting: ITableHeadSort,
    page: number | undefined,
    onSuccess: (
      data: IDisclosure[],
      isConfigured?: boolean,
      periodicDisclosure?: { previous: string; next: string },
      totalPages?: number,
      currentPage?: number,
      fund_alignment?: string
    ) => void
  ) =>
    useEffect(() => {
      getSFDRDisclosures(portfolioId, snapshotId, {
        ...filters,
        sort_field: sorting?.value,
        sort_dir: sorting?.order,
        page: page,
      }).then(response => {
        const {
          disclosures,
          periodic_disclosure,
          total_pages,
          current_page,
          is_configured,
          fund_alignment,
        } = response?.data
        onSuccess(
          disclosures as IDisclosure[],
          is_configured,
          periodic_disclosure,
          total_pages,
          current_page,
          fund_alignment
        )
      })
    }, [portfolioId, snapshotId, filters, sorting, page, onSuccess])

  createSFDRDisclosure(
    portfolioId: string,
    snapshotId: string,
    disclosure: ICreateDisclosureData
  ) {
    return createSFDRDisclosure(portfolioId, snapshotId, disclosure)
  }

  publishSFDRDisclosure(
    portfolioId: string,
    snapshotId: string,
    disclosure: IPublishDisclosureData
  ) {
    return publishSFDRDisclosure(portfolioId, snapshotId, disclosure)
  }
}

export default new DisclosuresController()
