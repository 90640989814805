import React, { useEffect, useReducer, useState } from 'react'

import YBBreadcrumb from '../../components/styleguide/YBBreadcrumb'
import IconLink from '../../yb_components/styleguide/links/IconLink'
import SpinnerContainer from '../../yb_components/styleguide/spinners/SpinnerContainer'
import ESGScorePillars from './components/esg_score_pillars/ESGScorePillars'
import PortfolioScoreResults from './components/PortfolioScoreResults'
import CreatePresetModal from './components/presets/CreatePresetModal'
import DiscardPresetModal from './components/presets/DiscardPresetModal'
import LoadPresetModal from './components/presets/LoadPresetModal'
import ScoreDisplaySettings from './components/score_display_settings/ScoreDisplaySettings'
import ScoreRankingSettings from './components/ScoreRankingSettings'
import SectorWeights from './components/sector_weights/SectorWeights'
import SubmitBar from './components/SubmitBar'
import {
  getBreadcrumbs,
  getESGSetting,
  validateWeights,
} from './custom_esg_scoring_repository'
import { reducer } from './CustomESGScoring.reducer'
import {
  GridContainer,
  PresetsContainer,
  StyledContainer,
  Title,
} from './CustomESGScoring.styles'

const loadESGSettings = ({ targetId, targetType, dispatch, setIsLoading }) => {
  getESGSetting(targetId, targetType).then(res => {
    dispatch({
      type: 'initial-data-loaded',
      payload: {
        esg_metrics_map: res.data.esg_setting.esg_metrics_map,
        overall_weights: res.data.esg_setting.overall_weights,
        sector_weights: res.data.esg_setting.sector_weights,
        companies_by_sector: res.data.esg_setting.companies_by_sector,
        latest_portfolios: res.data.esg_setting.latest_portfolios,
        score_display_settings: res.data.esg_setting.score_display_settings,
        score_ranking_setting: res.data.esg_setting.score_ranking_setting,
        target_information: {
          targetId: res.data.esg_setting.target_id,
          targetType: res.data.esg_setting.target_type,
          targetName: res.data.esg_setting.target_name,
        },
        id: res.data.esg_setting.id,
        name: res.data.name,
        description: res.data.description,
        default_pillar_weights: res.data.esg_setting.default_pillar_weights,
      },
    })
    setIsLoading(false)
  })
}

const CustomESGScoring = ({ targetType, targetId }) => {
  const initialState = {
    view: 'advanced',
    preview_disabled: true,
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  const [isLoading, setIsLoading] = useState(true)
  const [intermediateScoresLoading, setIntermediateScoresLoading] =
    useState(false)
  const [showCreate, setShowCreate] = useState(false)
  const [showLoad, setShowLoad] = useState(false)
  const [showDiscard, setShowDiscard] = useState(false)

  useEffect(() => {
    loadESGSettings({
      targetId,
      targetType,
      dispatch,
      setIsLoading,
    })
  }, [])

  return (
    <StyledContainer>
      <SpinnerContainer isLoading={isLoading}>
        {state?.target_information && (
          <YBBreadcrumb
            breadcrumbs={getBreadcrumbs(state?.target_information)}
          />
        )}
        <Title>{targetType} ESG Score Calculation Settings</Title>
        <PresetsContainer>
          <IconLink
            text={'Create Preset'}
            src={'/file-upload.svg'}
            onClick={() => validateWeights(state) && setShowCreate(true)}
          />
          <IconLink
            text={'Load Preset'}
            src={'/pdf.svg'}
            onClick={() => setShowLoad(true)}
          />
          <IconLink
            text={'Discard Preset'}
            src={'/trash-icon-v2.svg'}
            onClick={() => setShowDiscard(true)}
          />
        </PresetsContainer>
        <GridContainer>
          <ScoreRankingSettings
            setting={state?.score_ranking_setting}
            dispatch={dispatch}
          />
          <ScoreDisplaySettings
            loadedLabels={state?.score_display_settings}
            dispatch={dispatch}
          />
          <ESGScorePillars
            loadedESGPillars={state?.esg_pillars}
            esgMetricsMap={state?.esg_metrics_map}
            defaultPillarWeights={state.default_pillar_weights}
            dispatch={dispatch}
          />
          <SectorWeights
            sectorWeights={state?.sector_esg_pillars}
            loading={intermediateScoresLoading}
            sectorIntermediateScores={state?.sector_intermediate_scores}
            defaultWeights={state?.esg_pillars}
            defaultPillarWeights={state?.default_pillar_weights}
            esgMetricsMap={state?.esg_metrics_map}
            sectors={state?.sectors}
            dispatch={dispatch}
          />
          {state?.portfolio_scores?.length > 0 && (
            <PortfolioScoreResults
              loading={intermediateScoresLoading}
              scores={state?.portfolio_scores}
            />
          )}
        </GridContainer>
        <SubmitBar
          data={state}
          intermediateScoresLoading={intermediateScoresLoading}
          setIntermediateScoresLoading={setIntermediateScoresLoading}
          previewDisabled={state?.preview_disabled}
          dispatch={dispatch}
        />

        <CreatePresetModal
          dispatch={dispatch}
          show={showCreate}
          data={state}
          setShow={setShowCreate}
          targetType={targetType}
          setIntermediateScoresLoading={setIntermediateScoresLoading}
        />
        <LoadPresetModal
          dispatch={dispatch}
          show={showLoad}
          setShow={setShowLoad}
          data={state}
          targetType={targetType}
        />
        <DiscardPresetModal
          dispatch={dispatch}
          show={showDiscard}
          setShow={setShowDiscard}
        />
      </SpinnerContainer>
    </StyledContainer>
  )
}

export default CustomESGScoring
