import React from 'react'
import styled, { css } from 'styled-components'

import Card from '../../../../../components/styleguide/Card'
import CardTitle from '../../../../../components/styleguide/CardTitle'
import { H3, H4 } from '../../../../../pdf/yb_styles/typography'

const StyledCard = styled(Card)`
  grid-column: span 12;
  table thead tr {
    text-transform: initial;
  }
`

const StyledContainer = styled.div`
  margin-top: 20px;
  grid-column: span 12;
  table thead tr {
    text-transform: initial;
  }
  ${props =>
    props.width &&
    css`
      .content {
        display: flex;
        justify-content: space-between;
        table {
          width: ${props.width};
        }
      }
    `}
  ${props =>
    props.trHeight &&
    css`
      .content table tbody tr {
        height: ${props.trHeight};
      }
    `}
`

const InlineStyle = css`
  display: inline;
  margin-left: 10px;
`

const StyledHeader = styled.div`
  ${H3}
  ${props => props.inline && InlineStyle}
  text-transform: capitalize;
  font-weight: 700;
`

const StyledSubHeader = styled.div`
  ${H4}
  ${props => props.inline && InlineStyle}
`

const CarbonReportCard = ({
  children,
  title,
  pdf,
  pdfSubTitle,
  className,
  width,
  trHeight,
}) => {
  const renderCard = pdf => {
    return pdf ? (
      <StyledContainer width={width} trHeight={trHeight}>
        <StyledHeader inline={pdfSubTitle?.inline}>{title}</StyledHeader>
        {pdfSubTitle && (
          <StyledSubHeader inline={pdfSubTitle?.inline}>
            {pdfSubTitle?.label}
          </StyledSubHeader>
        )}
        {children}
      </StyledContainer>
    ) : (
      <StyledCard className={className}>
        <CardTitle title={title} />
        {children}
      </StyledCard>
    )
  }

  return renderCard(pdf)
}

export default CarbonReportCard
