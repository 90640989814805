import useFeedbackMessage from '@env/hooks/forms/useFeedbackMessage'
import useInputValidations from '@env/hooks/forms/useInputValidations'
import useRequiredValidation from '@env/hooks/forms/useRequiredValidation'
import useShouldDisplayInputFeedback from '@env/hooks/forms/useShouldDisplayInputFeedback'
import withFormControl from '@ui/components/compositions/withFormControl'
import withFormRow from '@ui/components/compositions/withFormRow/withFormRow'
import { InputFeedback } from '@ui/components/presentation/copy/InputFeedback'
import getTestingProps from '@utils/test/getTestingProps'
import classnames from 'classnames'
import React, { ChangeEvent, useState } from 'react'

// import theme from './Select.module.scss'
import './Select.module.scss'

export interface IProps
  extends YB.IFormControl<string>,
    React.DetailedHTMLProps<
      React.SelectHTMLAttributes<HTMLSelectElement>,
      HTMLSelectElement
    > {
  options: YB.IOption[]
  name: string
}

const Select: React.FC<IProps> = ({
  disabled,
  name,
  options = [],
  model = useState<string>(options[0]?.value),
  feedbackModel = useState<string>(''),
  formFeedbackModel = useState<string>(''),
  validations = [],
  className = '',
  required = true,
  ...inputProps
}) => {
  const [value, setValue] = model
  const [isPristine, setIsPristine] = useState(true)
  const feedbackMessage = useFeedbackMessage(feedbackModel, formFeedbackModel)
  const isInvalid = useShouldDisplayInputFeedback(
    feedbackMessage,
    isPristine,
    disabled
  )

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setValue(event.target.value)
  }

  useInputValidations(
    value,
    useRequiredValidation(validations, required),
    feedbackModel
  )

  return (
    <>
      <select
        {...inputProps}
        name={name}
        {...getTestingProps(name)}
        required={required}
        aria-required={required}
        disabled={disabled}
        value={value}
        className={classnames('Select', {
          invalid: isInvalid,
          [className]: !!className,
        })}
        onChange={handleChange}
        onBlur={() => setIsPristine(false)}
      >
        {options.map(option => (
          <option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ))}
      </select>
      <InputFeedback isPresent={isInvalid} feedbackMessage={feedbackMessage} />
    </>
  )
}

export default Select

export const SelectControl = withFormControl<IProps>(Select)

export const SelectRow = withFormRow<IProps>(Select)
