import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import Button from '../../../../components/styleguide/Button'
import SectionWrapper from '../../../../components/styleguide/SectionWrapper'
import StyledDialog from '../../../../components/styleguide/StyledDialog'
import TextArea from '../../../../components/styleguide/TextArea'
import TextInput from '../../../../components/styleguide/TextInput'
import WrapperColumn from '../../../../components/styleguide/WrapperColumn'
import WrapperRow from '../../../../components/styleguide/WrapperRow'
import YBSelect from '../../../../components/styleguide/YBSelect'
import {
  assetClassOptions,
  defaultAssetClass,
} from '../../../../constants/portfolios'
import {
  FONT_COLOR_SECONDARY,
  FONT_COLOR_TERTIARY,
} from '../../../../styles/colors'

const InstrumentCreateModal = ({
  instrumentName,
  holdings,
  setHoldings,
  setModalOpen,
  setCreatedInstrumentName,
  show,
}) => {
  const [name, setName] = useState(instrumentName || null)
  const [isin, setIsin] = useState('')
  const [figi, setFigi] = useState('')
  const [ticker, setTicker] = useState('')
  const [description, setDescription] = useState('')
  const [assetClass, setAssetClass] = useState(defaultAssetClass)

  const isEnabled = name.length > 0

  const getFormData = () => {
    return {
      instrument_name: name,
      isin,
      figi,
      ticker,
      description,
      asset_class: assetClass,
    }
  }
  const resetState = () => {
    setName(instrumentName || null)
    setIsin('')
    setFigi('')
    setTicker('')
    setDescription('')
    setAssetClass(defaultAssetClass)
  }

  const handleModalSave = () => {
    resetState()
    let newHoldings = [...holdings]
    let newHolding = newHoldings.find(
      holding => holding.instrument_name == null
    )

    // eslint-disable-next-line no-undef
    _.assign(newHolding, getFormData())
    newHolding.instrument_id = null
    setHoldings(newHoldings)
    setModalOpen(false)
    setCreatedInstrumentName('')
  }

  const handleModalClose = () => {
    resetState()
    setModalOpen(false)
    setCreatedInstrumentName('')
  }

  return (
    <StyledDialog
      show={show}
      onHide={handleModalClose}
      size='lg'
      padding='10px'
      sectionsPadding='0px'
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Custom Instrument</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WrapperColumn styles={{ justify: 'center' }}>
          <WrapperRow
            styles={{ color: FONT_COLOR_TERTIARY, margin: '10px 0 0 0' }}
          >
            Custom instruments are utilized for showing the unmodeled assets in
            a portfolio. Currently, any custom instruments will not have ESG/SDG
            indicators and will not be part of the total portfolio calculations
            for scores.
          </WrapperRow>
          <WrapperRow grow>
            <WrapperColumn styles={{ padding: '0 10px 0 0' }}>
              <TextInput
                label='NAME'
                value={name}
                padding='0'
                margin='10px 0'
                onChange={(_, value) => setName(value)}
              />
            </WrapperColumn>
            <WrapperColumn styles={{ padding: '0 0 0 10px' }}>
              <YBSelect
                label='ASSET CLASS'
                placeholder='ASSET CLASS'
                defaultValue={assetClassOptions[0]}
                menuShouldScrollIntoView={false}
                options={assetClassOptions}
                isSearchable={false}
                margin='10px 0'
                onChange={event => setAssetClass(event.value)}
              />
            </WrapperColumn>
          </WrapperRow>
          <WrapperRow grow>
            <WrapperColumn styles={{ padding: '0 10px 0 0' }}>
              <TextInput
                label='TICKER'
                value={ticker}
                padding='0'
                margin='10px 0'
                onChange={(_, value) => setTicker(value)}
              />
            </WrapperColumn>
            <WrapperColumn styles={{ padding: '0 10px' }}>
              <TextInput
                label='ISIN'
                value={isin}
                padding='0'
                margin='10px 0'
                onChange={(_, value) => setIsin(value)}
              />
            </WrapperColumn>
            <WrapperColumn styles={{ padding: '0 0 0 10px' }}>
              <TextInput
                label='FIGI'
                value={figi}
                padding='0'
                margin='10px 0'
                onChange={(_, value) => setFigi(value)}
              />
            </WrapperColumn>
          </WrapperRow>
          <WrapperRow grow>
            <WrapperColumn>
              <TextArea
                label='DESCRIPTION'
                value={description}
                rows={3}
                onChange={(_, value) => setDescription(value)}
              />
            </WrapperColumn>
          </WrapperRow>
        </WrapperColumn>
        <SectionWrapper justify='flex-end'>
          <Button
            label='Cancel'
            tertiary
            color={FONT_COLOR_SECONDARY}
            width='10%'
            margin='0px 20px 0px 0px'
            onClick={handleModalClose}
          />
          <Button
            label='SAVE CHANGES'
            width='25%'
            disabled={!isEnabled}
            onClick={() => handleModalSave()}
          />
        </SectionWrapper>
      </Modal.Body>
    </StyledDialog>
  )
}

export default InstrumentCreateModal
