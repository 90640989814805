import React from 'react';
import styled from 'styled-components';
import { BORDER_COLOR_PRIMARY } from '../../../styles/colors';

export const MultiPartDisplayRowHeader = styled.div`
    display:flex;
    background-color:${BORDER_COLOR_PRIMARY};

    > span {
        margin: 0px 5px 0px 0px;
    }
`
