import React from 'react';
import { esgScoreFullNames } from '../../../../../../../constants';
import { emptyScorePlaceholder } from '../../../constants';
import { StyledMainTable } from '../../comparisons/Comparisons.styles';
import HeaderRow from '../../table_rows/header_row/HeaderRow';
import ScoreTableRow from '../../table_rows/score_table_row/ScoreTableRow';

const ESGScoresCard = ({ portfolios, openReplaceModal=()=>{}, isPdf=false }) => {
  const retrieveScore = metricType => portfolio => {
    return portfolio?.esg_score?.[`${metricType}_score`] || emptyScorePlaceholder
  }

  return (
    <StyledMainTable look={{ sideCellsPadding: '0' }} isPdf={ isPdf }>
      <HeaderRow portfolios={ portfolios } openReplaceModal={ openReplaceModal } isPdf={ isPdf } />
      <tbody>
        {['e', 's', 'g', 'total'].map( metricType => (
          <ScoreTableRow
            key={ `table-row-${metricType}` }
            scoreName={ esgScoreFullNames[metricType].toUpperCase() }
            retrieveScore={ retrieveScore(metricType) }
            portfolios={ portfolios }
          />
        ))}
      </tbody>
    </StyledMainTable>
  )
}

export default ESGScoresCard
