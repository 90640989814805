import FormService, { TInputModel } from '@services/Form'
import { DynamicFieldRow } from '@ui/components/interactive/DynamicField'
import {
  FieldTypes,
  TDynamicFieldTypes,
} from '@ui/components/interactive/DynamicField/DynamicField'
import { hasOwnProp } from '@utils/object'
import React, { ReactNode, useContext, useEffect, useMemo } from 'react'
import _ from 'lodash'

import { saveSFDRAnswer } from '../../../../../../../../../react/repositories/portfolio_repository'
import {
  IForm,
  IQuestion,
  TFormAnswer,
  TFormTrigger,
  TQuestionnaireRow,
} from '../../data'
import { ArticleFormQuestionRow } from './components/ArticleFormQuestionRow'
import { DisclosureFormsContext } from '../../../../../../../../../react/yb_stores/sfdr/disclosure_forms/DisclosureFormsStore'
import { DisclosureFormsActions } from '../../../../../../../../../react/yb_stores/sfdr/disclosure_forms/actions/DisclosureFormsActions'

type TDynamicModels = Record<string, TInputModel<unknown>>
type TFieldMetadata = Record<string, TQuestionnaireRow>
type TFormModel = Record<string, unknown>
export type TModels = {
  values: Record<string, YB.TModel<unknown>>
  feedbacks: YB.TModel<string>[]
}

class ArticleQuestionnaireController {
  useDynamicModel(
    Form: FormService<unknown>,
    rows: TQuestionnaireRow[],
    answers: TFormAnswer
  ): [TDynamicModels, TFieldMetadata] {
    const inputs: TDynamicModels = {}
    const metadata: TFieldMetadata = {}

    rows?.forEach(row => {
      if (hasOwnProp('fields', row)) {
        const form = row as IForm

        inputs[form.key] = Form.useInputModel<TFormModel>({})
        metadata[form.key] = form
      } else {
        const question = row as IQuestion

        inputs[question.key] = Form.useInputModel(
          answers?.[question?.key] || ''
        )
        metadata[question.key] = question
      }
    })

    return [inputs, metadata]
  }

  useDynamicQuestionnaire(
    inputs: TDynamicModels,
    medatada: TFieldMetadata
  ): [ReactNode[], TModels] {
    // TODO: ICristi Remove model tracker if is no longer used
    const models: TModels = {
      values: {},
      feedbacks: [],
    }
    const rows: ReactNode[] = []

    const [formState, dispatchFormState] = useContext(DisclosureFormsContext)
    const { triggers, localAnswers } = formState

    Object.keys(inputs).forEach(key => {
      const [model, feedback] = inputs[key]
      const rowMetadata = medatada[key]

      // TODO: ICristi Remove model tracker if is no longer used
      models.values[key] = model
      models.feedbacks.push(feedback)
      const [inputState] = model

      useEffect(() => {
        if (key) {
          dispatchFormState({
            type: DisclosureFormsActions.ADD_LOCAL_ANSWER,
            payload: { [key]: inputState },
          })
        }
      }, [inputState])

      const isDisabled = data => {
        let localDisabled = false

        if (triggers[data?.key]) {
          const { key: triggerKey, value: triggerValue } = triggers[data?.key]

          let currentValue = localAnswers?.[triggerKey]
          currentValue = Array.isArray(currentValue)
            ? _.first(currentValue)
            : currentValue

          if (Array.isArray(currentValue))
            localDisabled = !currentValue.includes(triggerValue)
          else localDisabled = currentValue !== triggerValue
        }

        return localDisabled
      }

      let mainFieldDisabled = () => {
        let localDisabled = false

        if (FieldTypes.includes(rowMetadata.type)) {
          return isDisabled(rowMetadata)
        } else {
          localDisabled = true
          rowMetadata?.fields?.forEach(field => {
            if (!isDisabled(field)) {
              localDisabled = false
            }
          })
        }

        return localDisabled
      }

      if (FieldTypes.includes(rowMetadata.type)) {
        if (!mainFieldDisabled()) {
          rows.push(
            <DynamicFieldRow
              type={rowMetadata.type as TDynamicFieldTypes}
              name={rowMetadata.id}
              label={rowMetadata.label || ''}
              model={model as YB.TModel<string | string[]>}
              placeholder={rowMetadata?.placeholder}
              metadata={rowMetadata.metadata}
              feedbackModel={feedback}
              subtext={rowMetadata.subText}
              preFieldNotes={<p>{rowMetadata.rtsInstructions}</p>}
              // ICristi: Temporarily removed
              // postFieldNotes={
              //   <Box isPresent={!rowMetadata.sampleAnswerUrl}>
              //     <ExternalLink href={rowMetadata.sampleAnswerUrl as string}>
              //       Check a sample answer
              //     </ExternalLink>
              //   </Box>
              // }
            />
          )
        }
      } else {
        if (!mainFieldDisabled()) {
          rows.push(
            <ArticleFormQuestionRow
              name={rowMetadata.id}
              subtext={rowMetadata.subText}
              label={rowMetadata.label || ''}
              form={rowMetadata as IForm}
              model={model as YB.TModel<Record<string, unknown>>}
              feedbackModel={feedback}
            />
          )
        } else rows.push(<></>)
      }
    })

    // TODO: ICristi Remove model return if is no longer required
    return [rows, models]
  }
}

export default new ArticleQuestionnaireController()
