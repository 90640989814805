import React from 'react'
import styled from 'styled-components'

import withErrorBoundary from '../../hocs/withErrorBoundary'
import {
  ExplainerLink,
  LinkIcon,
} from '../../yb_components/styleguide/links/Link.styles'
import TreemapChart from '../charts/TreemapChart'
import Card from '../styleguide/Card'
import CardTitle from '../styleguide/CardTitle'
import RevenueSegmentScale from '../styleguide/RevenueSegmentScale'

const StyledCard = styled(Card)`
  .revenue-segment-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .revenue-segment-chart {
    margin-top: 20px;
    width: 105%;
    margin-left: -8px;
  }
`

const StyledExplainerLink = styled(ExplainerLink)`
  float: right;
  margin-left: 10px;
`

const RevenueSegmentCard = ({ segments }) => {
  return (
    <StyledCard padding={20}>
      <div className='revenue-segment-header'>
        <CardTitle title='REVENUE SEGMENTS' />
        <RevenueSegmentScale />
        <div className='float-right'>
          <StyledExplainerLink
            href='http://help.yves.blue/en/articles/6056965-revenue-segment-sustainability'
            target='_blank'
          >
            <LinkIcon className='far fa-question-circle' />
          </StyledExplainerLink>
          <br />
        </div>
      </div>
      <TreemapChart data={segments} className='revenue-segment-chart' />
    </StyledCard>
  )
}

export default withErrorBoundary(RevenueSegmentCard)
