import { area } from 'd3-shape'
import React from 'react'

import { CHART_HIGH_RISK } from '../../../../styles/colors'
import { DEFAULT_MAX_VALUE } from '../ScoresStackedGraph.constants'

const GraphArea = ({ yScale, width }) => {
  const areaCoords = [
    { x: 0, y0: DEFAULT_MAX_VALUE * 0.8, y1: DEFAULT_MAX_VALUE },
    { x: width, y0: DEFAULT_MAX_VALUE * 0.8, y1: DEFAULT_MAX_VALUE },
  ]

  const areaGenerator = area()
    .x(area => area.x)
    .y0(area => area.y0)
    .y1(area => yScale(area.y1))

  return (
    <path
      d={areaGenerator(areaCoords)}
      fill={CHART_HIGH_RISK}
      style={{ mixBlendMode: 'multiply', pointerEvents: 'none' }}
      opacity={0.5}
    />
  )
}

export default GraphArea
