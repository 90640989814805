export default function usRequiredValidation<T>(
    validations: YB.TInputValidation<T>[],
    required: boolean,
    feedbackMessage = 'This field is required'
) {
    if (required) {
        validations.push(value => {
            if (!required) return ''

            if (Array.isArray(value)) {
                if (!value.length) {
                    return feedbackMessage
                }
            } else if (!value) {
                return feedbackMessage
            }
        })
    }

    return validations
}
