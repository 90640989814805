import React from 'react'

import ESGShortsLongsTable from '../../components/tables/ESGShortsLongsTable'
import {
  esg_scale,
  getProgressColor,
  getScoreLabel,
} from '../../helpers/esg_scale'
import CircularProgress from './CircularProgress'
import ESGScale from './ESGScale'
import HorizontalProgress from './HorizontalProgress'

const ESGScore = ({
  scores,
  shortsScores,
  longsScores,
  benchmarkScores,
  benchmarkName,
  customESGLabels,
}) => {
  const benchmarkDifference = scores.total_score - benchmarkScores?.total_score
  const hasShorts = !!shortsScores

  return (
    <div className='esg-score'>
      <div className='total-score'>
        <div className='score'>
          {scores.total_score}
          <span className='score-label'>
            {getScoreLabel(Math.round(+scores.total_score), customESGLabels)}
          </span>
        </div>
        <div className='progress'>
          <div className='label bold'>OVERALL ESG SCORE</div>
          <HorizontalProgress
            height={10}
            width={220}
            progress={scores.total_score}
            color={getProgressColor(
              Math.round(+scores.total_score),
              customESGLabels
            )}
          />
          {benchmarkScores?.total_score && (
            <div
              style={{ color: benchmarkDifference > 0 ? '#00D679' : '#D02F44' }}
            >
              {benchmarkDifference > 0 ? '+' : ''}
              {` vs. ${benchmarkScores.total_score} for ${benchmarkName}`}
            </div>
          )}
        </div>
      </div>

      <div className='breakdown-score'>
        <span>
          <CircularProgress
            size={60}
            stroke={8}
            progress={scores.e_score}
            color={getProgressColor(
              Math.round(+scores.e_score),
              customESGLabels
            )}
          >
            <div className='score'>
              {scores.e_score}
              <span className='score-label'>
                {getScoreLabel(Math.round(+scores.e_score), customESGLabels)}
              </span>
            </div>
          </CircularProgress>
          <span className='breakdown-score-label'>ENVIRONMENTAL</span>

          {benchmarkScores?.e_score && (
            <div
              style={{
                color:
                  scores.e_score - benchmarkScores?.e_score > 0
                    ? '#00D679'
                    : '#D02F44',
              }}
            >
              {scores.e_score - benchmarkScores?.e_score > 0 ? '+' : ''}
              {` vs. ${benchmarkScores.e_score} for ${benchmarkName}`}
            </div>
          )}
        </span>

        <span>
          <CircularProgress
            size={60}
            stroke={8}
            progress={scores.s_score}
            color={getProgressColor(
              Math.round(+scores.s_score),
              customESGLabels
            )}
          >
            <div className='score'>
              {scores.s_score}
              <br />
              <span className='score-label'>
                {getScoreLabel(Math.round(+scores.s_score), customESGLabels)}
              </span>
            </div>
          </CircularProgress>
          <span className='breakdown-score-label'>SOCIAL</span>

          {benchmarkScores?.s_score && (
            <div
              style={{
                color:
                  scores.s_score - benchmarkScores?.s_score > 0
                    ? '#00D679'
                    : '#D02F44',
              }}
            >
              {scores.s_score - benchmarkScores?.s_score > 0 ? '+' : ''}
              {` vs. ${benchmarkScores.s_score} for ${benchmarkName}`}
            </div>
          )}
        </span>

        <span>
          <CircularProgress
            size={60}
            stroke={8}
            progress={scores.g_score}
            color={getProgressColor(
              Math.round(+scores.g_score),
              customESGLabels
            )}
          >
            <div className='score'>
              {scores.g_score}
              <br />
              <span className='score-label'>
                {getScoreLabel(Math.round(+scores.g_score), customESGLabels)}
              </span>
            </div>
          </CircularProgress>
          <span className='breakdown-score-label'>GOVERNANCE</span>
          {benchmarkScores?.g_score && (
            <div
              style={{
                color:
                  scores.g_score - benchmarkScores?.g_score > 0
                    ? '#00D679'
                    : '#D02F44',
              }}
            >
              {scores.g_score - benchmarkScores?.g_score > 0 ? '+' : ''}
              {` vs. ${benchmarkScores.g_score} for ${benchmarkName}`}
            </div>
          )}
        </span>
      </div>
      {hasShorts && (
        <>
          <div className='shorts-header'>Shorts / Longs ESG Scores</div>
          <ESGShortsLongsTable
            customESGLabels={customESGLabels}
            shorts={shortsScores}
            longs={longsScores}
          />
        </>
      )}
      <ESGScale scale={customESGLabels || esg_scale} />
    </div>
  )
}

export default ESGScore
