import styled from 'styled-components'

export const StyledGrid = styled.div`
  --grid-columns: 48px 2fr 2fr 1fr 1fr 2fr;
  color: #212529;

  .grid-row {
    display: grid;
    grid-template-columns: var(--grid-columns);
    grid-template-rows: auto;
    grid-gap: 0.5rem;
  }
  .grid-column {
    display: flex;
    align-items: center;
    &.center {
      justify-content: center;
    }
  }
  header {
    font-size: 14px;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 900;
    border-bottom: 2px solid #d8d8d8;

    .grid-column {
      min-height: 40px;
    }
  }
  main {
    margin: 0 0 1.5rem;
    font-size: 1rem;
    line-height: 1.2;

    .grid-row {
      border-bottom: 1px solid #f0f0f0;
      &:last-child {
        border: none;
      }
    }
    .grid-column {
      min-height: 80px;
      display: flex;
    }
  }
  .button-grid {
    margin: 0 auto;
    width: auto;
    max-width: 240px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 0.5rem;
  }
`
