import styled from 'styled-components'

import { FONT_COLOR_SECONDARY } from '../../styles/colors'

const YBDescription = styled.div`
  margin: ${props => props.margin || '0px'};
  font-size: 14px;
  color: ${FONT_COLOR_SECONDARY};
  line-height: 24px;
`

export default YBDescription
