import React from 'react';

const Footer = () => {
  const organization = react_app_organization.organization
  const logo = organization?.secondary_brand_logo?.url

  return (
    organization?.can_access_cobranded_reports &&
      <div className="footer-container">
        <img className="logo" src={logo}/>
      </div>
  )
}

export default Footer
