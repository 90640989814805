import React from 'react'

import Card from '../styleguide/Card'
import CardTitle from '../styleguide/CardTitle'
import SkeletonBox from '../styleguide/skeleton/SkeletonBox'

const PortfolioProcessedCard = ({
  title,
  children,
  id,
  snapshotId,
  skeleton,
}) => {
  const navigate = () => {
    window.location = `/app/portfolios/${id}/snapshots/${snapshotId}`
  }

  return (
    <div {...(skeleton ? {} : { onClick: navigate })} skeleton={skeleton}>
      <Card
        padding='20px 36px'
        height='398px'
        style={{ marginBottom: '20px', cursor: 'pointer' }}
      >
        {skeleton ? (
          <SkeletonBox width='266px' margin='10px 0 5px 6px' height='18px' />
        ) : (
          <CardTitle fontSize='18px' title={title} />
        )}
        {children}
      </Card>
    </div>
  )
}

export default PortfolioProcessedCard
