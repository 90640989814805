export const GRAPH_COLORS = {
    e: 'rgba(0, 177, 186, 1)',
    s: 'rgba(255, 118, 89, 1)',
    g: 'rgba(0, 47, 167, 1)'
};

export const METRICS_FOR_REPORT = {
    e: ['co2_scope_total', 'waste_total', ],
    s: ['turnover_of_employees', 'employee_satisfaction', 'avg_training_hrs'],
    g: ['board_strict_indep', 'board_indep', ]
}

export const METRIC_CATEGORIES = {
    e: 'ENVIRONMENTAL',
    s: 'SOCIAL',
    g: 'GOVERNANCE'
}

export const DATE_FORMAT = 'MMMM D YYYY'
