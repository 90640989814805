import React, { useMemo, useState } from 'react'

import {
  getPaiEntity,
  updatePaiEntityIndicator,
} from '../../../../../repositories/portfolio_repository'
import { FONT_COLOR_SECONDARY } from '../../../../../styles/colors'
import SpinnerContainer from '../../../../../yb_components/styleguide/spinners/SpinnerContainer'
import {
  getNow,
  getRemainingLoadingTime,
} from '../../../../../yb_helpers/loader'
import { useUnsavedChangesGuard } from '../../../../../yb_hooks/security'
import ErrorMessage from '../components/tables/error_message/ErrorMessage'
import { PaiEntityDetailFilters } from '../components/tables/pai_entity_detail/filters/PaiEntityDetailFilters'
import { PaiEntityDetailTable } from '../components/tables/pai_entity_detail/PaiEntityDetailTable'

export const PaiEntityDetailTab = () => {
  let [, portfolioId, snapshotId, holdingId] = window.location.pathname.match(
    /app\/portfolios\/(\d+)\/snapshots\/(\d+)\/regulatory\/pai\/entities\/(\d+)\/?$/
  )
  const [impactYears, setImpactYears] = useState({})
  const [holdingName, setHoldingName] = useState('')
  const [changedIndicators, setChangedIndicators] = useState({})
  const [categories, setCategories] = useState([])
  const [tableData, setTableData] = useState([])
  const [tableDataSnapshot, setTableDataSnapshot] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [hasFetchError, setHasFetchError] = useState(false)
  const errorMessage = useMemo(
    () => hasFetchError && <ErrorMessage />,
    [hasFetchError]
  )
  const hasChanges = useMemo(
    () => !!Object.keys(changedIndicators).length,
    [changedIndicators]
  )

  const removeIndicator = indicatorId => {
    const ammendedIndicators = { ...changedIndicators }

    delete ammendedIndicators[indicatorId]

    setChangedIndicators(ammendedIndicators)
  }

  const handleIndicatorChange = (indicatorId, indicatorData) => {
    if (!indicatorData) return removeIndicator(indicatorId)

    setChangedIndicators({
      ...changedIndicators,
      [indicatorId]: indicatorData,
    })
  }

  useUnsavedChangesGuard(hasChanges)

  const fetchPaiEntityDetail = query => {
    const now = getNow()

    setIsLoading(true)
    setHasFetchError(false)
    getPaiEntity(portfolioId, snapshotId, holdingId, query)
      .then(({ data }) => {
        const {
          holding_name,
          categories,
          table_data,
          current_year,
          last_year,
        } = data

        const ammendedTableData = ammendTableData(table_data)

        setCategories(categories)
        setTableData(ammendedTableData)
        setHoldingName(holding_name)
        setImpactYears({
          current: current_year,
          previous: last_year,
        })
        if (!tableDataSnapshot.length) {
          setTableDataSnapshot(table_data)
        }
      })
      .catch(() => {
        setTableData([])
        setHasFetchError(true)
      })
      .finally(() => {
        const remainingLoadingTime = getRemainingLoadingTime(now)

        setTimeout(() => setIsLoading(false), remainingLoadingTime)
      })
  }

  const ammendTableData = data =>
    data.map(group => ({
      ...group,
      category_group_data: group.category_group_data.map(category => ({
        ...category,
        category_data: category.category_data.map(indicator => {
          const indicatorChanges = changedIndicators[indicator.indicator_id]

          return indicatorChanges ? indicatorChanges : indicator
        }),
      })),
    }))

  const extractIndicators = data => {
    const hash = {}

    data.forEach(({ category_group_data: categories }) => {
      categories.forEach(({ category_data: indicators }) => {
        indicators.forEach(indicator => {
          hash[indicator.indicator_id] = indicator
        })
      })
    })

    return hash
  }

  const handleSave = () => {
    const now = getNow()
    const indicatorsHash = extractIndicators(tableDataSnapshot)

    const indicators = Object.values({
      ...indicatorsHash,
      ...changedIndicators,
    })

    setIsSaving(true)
    updatePaiEntityIndicator(portfolioId, snapshotId, holdingId, indicators)
      .then(() => {
        setChangedIndicators({})
      })
      .catch(() =>
        alert('There was an issue saving your changes, please try again.')
      )
      .finally(() => {
        const remainingTime = getRemainingLoadingTime(now)

        setTimeout(() => setIsSaving(false), remainingTime)
      })
  }

  return (
    <main>
      <p style={{ margin: '0 0 30px', color: FONT_COLOR_SECONDARY }}>
        Use the tables below to select the indicators for submission. In
        accordance with the SFDR regulation, all financial market participants
        must disclose the 14 mandatory climate and other environment-related
        indicators from Table 1, one indicator from Table 2 (additional climate
        and other environment-related indicators), and one indicator from Table
        3 (additional social and employee, respect for human rights,
        anti-corruption and anti-bribery matters). Check units carefully before
        entering custom data for PAI indicators. If you have REITS, sovereigns
        or supranationals in your portfolio, you are required to select specific
        indicators.
      </p>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <PaiEntityDetailFilters
          entityName={holdingName}
          categories={categories}
          onChange={fetchPaiEntityDetail}
        />
        <SpinnerContainer isLoading={isLoading}>
          <PaiEntityDetailTable
            data={tableData}
            impactYears={impactYears}
            disabled={isSaving}
            onSave={handleSave}
            noDataText={errorMessage}
            hasChanges={hasChanges}
            onIndicatorChange={handleIndicatorChange}
          />
        </SpinnerContainer>
      </div>
    </main>
  )
}
