import React from 'react'

import * as S from './ModuleCard.styles'

export interface IProps {
  title?: string
}

export const ModuleCard: React.FC<IProps> = ({
  children,
  title,
  className,
}) => {
  return (
    <S.Container className={className}>
      {title && <h5>{title}</h5>}
      {children}
    </S.Container>
  )
}
