import styled from 'styled-components';
import Container from '../../../../../../components/styleguide/Container';

export const StyledContainer = styled(Container)`
  padding: 20px;
  min-height: 100vh;
`
export const PageTitle = styled.h2`
  font-weight: 700;
`
