import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'

export const PillButton = ({ children, ...props }) => {
  const StyledButton = styled(Button)`
    padding: 4px 30px;
    min-height: 38px;
    border-radius: 22px;
    font-size: 13px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
  `

  return <StyledButton {...props}>{children}</StyledButton>
}

PillButton.propTypes = {
  children: PropTypes.node.isRequired,
}
