import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { TcfdHoldingDetailListItem } from './list_item/TcfdHoldingDetailListItem'

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const TcfdHoldingDetailList = ({
  isLoading,
  openReplacePeerModal,
  riskApi,
  riskHardcode,
  selectedItem,
  saveNote
}) => {
  return (
    <StyledList>
      {riskHardcode.map(el => (
        <TcfdHoldingDetailListItem
          isLoading={isLoading}
          key={el.id}
          riskHardcode={el}
          row={isLoading ? {} : riskApi.find(item => item.id === el.id)}
          open={el.id === selectedItem}
          openReplacePeerModal={openReplacePeerModal}
          saveNote={saveNote}
        />
      ))}
    </StyledList>
  )
}

TcfdHoldingDetailList.propTypes = {
  isLoading: PropTypes.bool,
  openReplacePeerModal: PropTypes.func.isRequired,
  riskApi: PropTypes.array.isRequired,
  riskHardcode: PropTypes.array.isRequired,
  selectedItem: PropTypes.number,
}
