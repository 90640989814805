import React from 'react';
import styled from 'styled-components';
import SectionWrapper from '../SectionWrapper';
import { BG_COLOR_PRIMARY, BG_COLOR_WHITE } from '../../../styles/colors';

export const MultiPartDisplayRow = styled(SectionWrapper)`
    flex:1;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background: ${props => 
        {
            if(props.parity === "even"){
                return `${BG_COLOR_WHITE};`;
            } else if (props.parity === "odd"){
                return `${BG_COLOR_PRIMARY};`;
            }
        }
    };
    opacity: ${props => props.disabled && '0.5;'};
`
