import React from 'react'

import ESGScore from '../ESGScore'

const ESGScorePortfolio = ({
  scores,
  shortsScores,
  longsScores,
  benchmarkScores,
  benchmarkName,
  customESGLabels,
}) => {
  return (
    <section className='small portfolio-esg-score'>
      <h2>ESG Score</h2>

      <ESGScore
        benchmarkScores={benchmarkScores}
        benchmarkName={benchmarkName}
        scores={scores}
        shortsScores={shortsScores}
        longsScores={longsScores}
        customESGLabels={customESGLabels}
      />
    </section>
  )
}

export default ESGScorePortfolio
