import PropTypes from 'prop-types'
import React from 'react'

import Footer from '../../../../pdf/components/Footer'
import Header from '../../../../pdf/components/Header'
import PeersESGMetric from '../../../../pdf/components/PeersESGMetric'
import PeersESGScore from '../../../../pdf/components/PeersESGScore'
import PeersSDGAlignment from '../../../../pdf/components/PeersSDGAlignment'
import InstrumentDisclousure from '../../../../pdf/components/sections/InstrumentDisclosure'
import BrandingPrimaryLogo from '../../../../pdf/layout/branding_primary_logo/BrandingPrimaryLogo'

const PeersTemplatePDF = ({
  instrument,
  peers,
  dataDisplayType = 'zscore',
  dataProvider = 'ideal-ratings',
}) => {
  return (
    <div className='landscape'>
      <Header />
      <Footer />

      <div className='pdf-content peers-pdf'>
        <div className='peers-heading'>
          <h1> {instrument.name} </h1>
          <BrandingPrimaryLogo />
        </div>

        <PeersESGScore peers={peers} />
        <div style={{ pageBreakBefore: 'always' }}></div>

        <PeersSDGAlignment peers={peers} />
        <div style={{ pageBreakBefore: 'always' }}></div>

        <PeersESGMetric
          peers={peers}
          dataDisplayType={dataDisplayType}
          metricType='e'
          dataProvider={dataProvider}
        />
        <div style={{ pageBreakBefore: 'always' }}></div>

        <PeersESGMetric
          peers={peers}
          dataDisplayType={dataDisplayType}
          metricType='s'
          dataProvider={dataProvider}
        />
        <div style={{ pageBreakBefore: 'always' }}></div>

        <PeersESGMetric
          peers={peers}
          dataDisplayType={dataDisplayType}
          metricType='g'
          dataProvider={dataProvider}
        />
        <div style={{ pageBreakBefore: 'always' }}></div>

        <InstrumentDisclousure />
      </div>
    </div>
  )
}

PeersTemplatePDF.propTypes = {
  peers: PropTypes.array.isRequired,
  instrument: PropTypes.object.isRequired,
  dataDisplayType: PropTypes.string.isRequired,
  dataProvider: PropTypes.string.isRequired,
}

export default PeersTemplatePDF
