import styled from 'styled-components'

import { BG_COLOR_WHITE } from '../../../../../styles/colors'

export const StyledCard = styled.div`
  margin: 0;
  padding: 1.25rem;
  border-radius: 6px;
  box-shadow: 0 2px 9px 0 rgb(0 0 0 / 5%);
  border: none;
  background-color: ${BG_COLOR_WHITE};

  h3 {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.2;
    text-transform: uppercase;
  }
`
