import React, { useEffect, useState } from 'react'

import YBSelect from '../../../../components/styleguide/YBSelect'
import Modal from '../../../../yb_components/styleguide/modal/Modal'
import { getPresets, loadPreset } from '../../custom_esg_scoring_repository'
import { StyledModalContent } from '../../CustomESGScoring.styles'

const LoadPresetModal = ({ show, dispatch, targetType, data, setShow }) => {
  const [presets, setPresets] = useState()
  const [selectedPresetId, setSelectedPresetId] = useState()
  const [disabledButton, setDisabledButton] = useState(false)

  useEffect(() => {
    getPresets().then(res => setPresets(res.data.esg_setting_presets))
  }, [])

  const selectOptions = presets?.map(preset => ({
    label: preset.name,
    value: preset.id,
  }))

  const handleSubmit = () => {
    const payload = { preset_id: selectedPresetId }
    setDisabledButton(true)
    loadPreset({ data: payload }).then(res => {
      setSelectedPresetId()
      setShow(false)
      setDisabledButton(false)
      dispatch({
        type: 'initial-data-loaded',
        payload: {
          esg_metrics_map: res.data.esg_setting.esg_metrics_map,
          overall_weights: res.data.esg_setting.overall_weights,
          sector_weights: res.data.esg_setting.sector_weights,
          companies_by_sector: res.data.esg_setting.companies_by_sector,
          latest_portfolios: res.data.esg_setting.latest_portfolios,
          score_display_settings: res.data.esg_setting.score_display_settings,
          score_ranking_setting: res.data.esg_setting.score_ranking_setting,
          //preserve the initial esg setting if in Portfolio context
          id: targetType == 'Portfolio' ? data.id : res.data.esg_setting.id,
        },
      })
    })
  }

  return (
    <Modal
      show={show}
      setShow={setShow}
      title='Load Preset'
      submitText='Save Changes'
      onSubmit={() => handleSubmit()}
      submitDisabled={disabledButton}
    >
      <StyledModalContent>
        <div className='description'>
          Are you sure you want to load this preset? Loading a preset will overwrite any of the custom changes you've made.
        </div>
        <YBSelect
          placeholder={'Select Preset'}
          options={selectOptions}
          onChange={e => setSelectedPresetId(e.value)}
        />
      </StyledModalContent>
    </Modal>
  )
}

export default LoadPresetModal
