import React from 'react'
import injectSheet from 'react-jss'
import CreatableSelect from 'react-select-3/creatable'

import Button from '../../../components/styleguide/Button'
import {
  BG_COLOR_PRIMARY,
  BG_COLOR_SECONDARY,
  FONT_COLOR_SECONDARY,
} from '../../../styles/colors'
import { accounts, titles } from '../constants'

const AccountForm = ({
  account,
  classes,
  handleChange,
  handleSubmit,
  handleAvatarChange,
  errors,
}) => {
  const handleChangeLocal = (value, id) => {
    if (value.__isNew__) {
      handleChange({ target: { id, value: value.value } })
    } else {
      handleChange({ target: { id, value: value.name } })
    }
  }
  return (
    <div className={classes.container}>
      <form
        id='account-form'
        method='PUT'
        encType='multipart/form-data'
        onSubmit={e => {
          e.preventDefault()
        }}
      >
        <div className='form-row'>
          <div className='col col-4'>
            <label htmlFor='email'>Email Address</label>
            <input
              type='text'
              id='email'
              className='form-control'
              value={account.email}
              onChange={handleChange}
              style={
                errors.includes('You must enter a valid e-mail')
                  ? { borderColor: '#a94442' }
                  : null
              }
            />
          </div>
          <div className='col col-4'>
            <label htmlFor='email'>Confirm Email Address</label>
            <input
              type='text'
              id='new_email'
              className='form-control'
              value={account.new_email}
              onChange={handleChange}
              style={
                errors.includes('Your e-mail addresses must match')
                  ? { borderColor: '#a94442' }
                  : null
              }
            />
          </div>
        </div>

        <div className='form-row'>
          <div className='col col-4'>
            <label htmlFor='first_name'>First Name</label>
            <input
              type='text'
              id='first_name'
              className='form-control'
              value={account.first_name}
              onChange={handleChange}
            />
          </div>
          <div className='col col-4'>
            <label htmlFor='last_name'>Last Name</label>
            <input
              type='text'
              id='last_name'
              className='form-control'
              value={account.last_name}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className='form-row'>
          <div className='col col-4'>
            <label htmlFor='department'>Department</label>
            <CreatableSelect
              value={{ value: account.department, label: account.department }}
              onChange={value => handleChangeLocal(value, 'department')}
              options={accounts}
            />
          </div>
          <div className='col col-4'>
            <label htmlFor='department'>Job Title</label>
            <CreatableSelect
              value={{ value: account.title, label: account.title }}
              onChange={value => handleChangeLocal(value, 'title')}
              options={titles}
            />
          </div>
        </div>

        <div className='form-row'>
          <div className='col col-4'>
            <label htmlFor='new_password'>Current Password</label>
            <input
              type='password'
              id='current_password'
              className='form-control'
              value={account.current_password}
              onChange={handleChange}
            />
          </div>

          <div className='col col-4'>
            <label htmlFor='new_password'>New Password</label>
            <input
              type='password'
              id='new_password'
              className='form-control'
              value={account.new_password}
              onChange={handleChange}
            />
          </div>
          <div className='col col-4'>
            <label htmlFor='confirm_password'>Confirm Password</label>
            <input
              type='password'
              id='confirm_password'
              className='form-control'
              value={account.confirm_password}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className='form-row'>
          <img alt='avatar' width='100' height='100' src={account.avatar_url} />

          <div className='col col-4'>
            <label htmlFor='avatar'>Profile Photo</label>
            <input
              type='file'
              id='avatar'
              name='avatar'
              accept='image/*'
              onChange={handleAvatarChange}
            />
          </div>
        </div>

        <div className='form-row'>
          <Button
            onClick={handleSubmit}
            disabled={errors && errors.length > 0}
            type='submit'
            className='submit-btn'
            label='Save Changes'
          />
        </div>
      </form>
    </div>
  )
}

const style = {
  container: {
    '& input[type=text], & input[type=password]': {
      background: BG_COLOR_PRIMARY,
      border: `1px solid ${BG_COLOR_SECONDARY}`,
      borderRadius: 6,
      height: 50,
      marginRight: 58,
    },
    '& .form-row': {
      marginTop: 25,
    },
    '& .form-row > .col': {
      paddingRight: 25,
    },
    '& label': {
      color: FONT_COLOR_SECONDARY,
      fontSize: 12,
      letterSpacing: 0,
      textTransform: 'uppercase',
    },
    '& .submit-btn': {
      textTransform: 'uppercase',
      width: 'fit-content',
    },
  },
}

export default injectSheet(style)(AccountForm)
