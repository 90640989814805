import Color from 'color'
import propTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

const sharedStyles = css`
  padding: 0.25rem;
  width: auto;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.25rem;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  outline: none;
  font-size: 10px;
  line-height: 1.2;
  font-weight: 900;
  text-transform: uppercase;
  &:hover,
  &:focus {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    .icon-holder {
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }
`
export const StyledButton = ({ children, color, disabled, onClick }) => {
  const buttonColor = Color(color)
  const Result = styled.button`
    ${sharedStyles}
    color: ${buttonColor.hex()};

    &:focus {
      box-shadow: 0 0 0 0.2rem ${buttonColor.lighten(0.5).hex()};
    }
    &:hover,
    &:focus {
      color: ${buttonColor.darken(0.4).hex()};
      .icon-holder {
        background-color: ${buttonColor.darken(0.4).hex()};
      }
    }
    &:disabled,
    &[disabled] {
      color: ${buttonColor.lighten(0.3).hex()};
      .icon-holder {
        background-color: ${buttonColor.lighten(0.3).hex()};
      }
    }
  `
  return (
    <Result onClick={e => onClick(e)} disabled={disabled}>
      {children}
    </Result>
  )
}

export const StyledAnchor = ({ children, color, href }) => {
  const buttonColor = Color(color)
  const Result = styled.a`
    ${sharedStyles}
    color: ${buttonColor.hex()};
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem ${buttonColor.lighten(0.5).hex()};
    }
    &:hover,
    &:focus {
      text-decoration: none;
      color: ${buttonColor.darken(0.4).hex()};
      .icon-holder {
        background-color: ${buttonColor.darken(0.4).hex()};
      }
    }
    &:disabled,
    &[disabled] {
      color: ${buttonColor.lighten(0.3).hex()};
      .icon-holder {
        background-color: ${buttonColor.lighten(0.3).hex()};
      }
    }
  `
  return <Result href={href}>{children}</Result>
}

export const StyledIconHolder = ({ color, src }) => {
  const buttonColor = Color(color)
  const Result = styled.div`
    width: 100%;
    height: 1.25rem;
    background-color: ${buttonColor.hex()};
    -webkit-mask: url(${src}) no-repeat center;
    mask: url(${src}) no-repeat center;
    mask-size: contain;
  `
  return <Result src={src} className='icon-holder' />
}

StyledButton.propTypes = {
  children: propTypes.node,
  src: propTypes.string,
  color: propTypes.string,
  onClick: propTypes.func,
  disabled: propTypes.bool,
}
StyledButton.defaultProps = {
  color: '#979797',
}
StyledAnchor.propTypes = {
  children: propTypes.node,
  src: propTypes.string,
  color: propTypes.string,
  href: propTypes.string,
}
StyledAnchor.defaultProps = {
  color: '#979797',
}
