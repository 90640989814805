import React from 'react';
import styled from 'styled-components';
import PieChart from '../../charts/pie_chart/PieChart';

import Card from '../../../components/styleguide/Card';
import CardTitle from '../../../components/styleguide/CardTitle';

const StyledContainer = styled.div`
  width: 325px;
  margin-right: ${props => props.marginRight || '0px'};
`;

const PieCard = ({ title, data, marginRight, tooltipValueFormatter, 'data-cy': dataCy }) => {
  if (!data?.length) return null;

  return (
    <StyledContainer marginRight={marginRight}>
      <Card padding={0} paddingTop={'20px'} paddingBottom={'20px'} data-cy={dataCy}>

        {/* Title */}
        <CardTitle title={title} fontSize="14px" textTransform='uppercase' paddingLeft={'20px'} />

        {/* Pie Chart */}
        <PieChart
          data-cy="card_pie_chart"
          data={data}
          tooltipValueFormatter={tooltipValueFormatter}
        />

      </Card>
    </StyledContainer>
  );
}

export default PieCard;
