import React from 'react'
import styled from 'styled-components'

import { headers } from './SearchResultsTable.constants'

const StyledA = styled.a`
  cursor: pointer;
`

const SearchResultsTable = ({ funds, sorting, setSorting = () => {} }) => {
  if (!funds) return null

  const onHeaderClick = name => () => {
    if (sorting.field === name) {
      setSorting({
        ...sorting,
        order: sorting.order === 'asc' ? 'desc' : 'asc',
      })
    } else {
      setSorting({ field: name, order: 'asc' })
    }
  }

  const renderAscIcon = (
    <i className='fal fa-sort-alpha-down ml-1 d-inline-block'></i>
  )

  const renderDescIcon = (
    <i className='fal fa-sort-alpha-up ml-1 d-inline-block'></i>
  )

  return (
    <div className='table-responsive'>
      <table className='table table-striped table-sm'>
        <thead>
          <tr>
            {headers.map(header => (
              <th key={header.name} style={{ width: header.width }}>
                {header.name === 'un_sdg' ? (
                  header.title
                ) : (
                  <StyledA onClick={onHeaderClick(header.name)}>
                    {header.title}
                    {sorting.field === header.name &&
                      (sorting.order === 'asc'
                        ? renderAscIcon
                        : renderDescIcon)}
                  </StyledA>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {funds.map(fund => (
            <tr key={`fund-${fund.id}`}>
              <td>
                <a href={fund.link}>{fund.name}</a>
              </td>
              <td>
                <span className='badge badge-secondary'>
                  {fund.asset_class?.name}
                </span>
              </td>
              <td>
                {fund.sdgs
                  .filter(sdg => sdg.index && sdg.index < 18)
                  .map(sdg => (
                    <img
                      className='d-inline-block m-1'
                      key={`fund-${fund.id}-sdg-${sdg.index}`}
                      title={sdg.name}
                      height='25'
                      width='25'
                      src={`/sdg_icons/${sdg.index}-50.png`}
                    />
                  ))}
              </td>
              <td>{fund.strategy_aum_dollars}</td>
              <td>{fund.first_impression_score}</td>
              <td>{fund.initial_evaluation_score}</td>
              <td>{fund.final_evaluation_score}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default SearchResultsTable
