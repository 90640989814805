import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

import Card from '../../../../../../components/styleguide/Card'
import CardTitle from '../../../../../../components/styleguide/CardTitle'
import Link from '../../../../../../components/styleguide/Link'
import Table from '../../../../../../components/styleguide/Table'
import Tooltip from '../../../../../../components/styleguide/Tooltip'
import { TooltipHeaders } from '../../../CarbonAuditCard.constants'
import {
  formatCellValue,
  renderBudgetIcon,
} from '../../../CarbonAuditCard.helpers'

const StyledCard = styled(Card)`
  position: absolute;
  z-index: 1;
  .header-container {
    display: flex;
    justify-content: space-between;
  }
  .title-container {
    display: flex;
    gap: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    align-items: center;
  }
`

const getBody = data =>
  data?.map(holding => [
    holding?.id ? (
      <Link href={`/app/instruments/${holding?.id}`} target='_blank'>
        {_.truncate(holding?.label, { length: 30 })}
      </Link>
    ) : (
      <span>{_.truncate(holding?.label, { length: 30 })}</span>
    ),
    formatCellValue(holding?.budget, 2),
    formatCellValue(holding?.emitted, 2),
    formatCellValue(holding?.vs_budget, 2),
    renderBudgetIcon(holding?.emitted, holding?.budget),
  ]) || []

const LabelTooltip = ({ label, performers, onClose }) => {
  const renderTable = (title, data) => {
    return (
      <>
        <CardTitle title={title} fontSize='16px' />
        <Table
          look={{
            thFontSize: '12px',
            tdFontSize: '15px',
            trEvenBackgroundColor: '#F1F1F1',
            tableMargin: '10px 0px 0px 0px',
          }}
          leftAlignHeader={[0]}
          rightAlignHeader={[1, 2, 3]}
          alignNumbers={true}
          headers={TooltipHeaders}
          body={getBody(data)}
        />
      </>
    )
  }

  return (
    <Tooltip hideArrow noTransform inheritVisibility x='-130'>
      <StyledCard width='600px' height='fit-content'>
        <div className='header-container'>
          <div className='title-container'>
            <CardTitle title='Sector:' fontSize='18px' />
            {label}
          </div>
          <span
            onClick={onClose}
            style={{ fontSize: '18px', cursor: 'pointer' }}
            aria-hidden='true'
          >
            ×
          </span>
        </div>

        {performers?.performers ? (
          renderTable('Performers', performers?.performers)
        ) : (
          <>
            {renderTable('Best Performers', performers?.top_performers)}
            {renderTable('Worst Performers', performers?.worst_performers)}
          </>
        )}
      </StyledCard>
    </Tooltip>
  )
}

export default LabelTooltip
