import React from 'react';
import { abbreviateNumber } from '../../../../helpers/number';
import TextView from '../../../styleguide/TextView';
import Tooltip from '../../../styleguide/Tooltip';

export const StackedBarComponent = ({ x, y, width, height, color, data, showTooltip, hideTooltip, keys, ellipseSize = 8 }) => {

  const isLastRow = () => {
    for (const key of keys) {
      const lastRowKey = data?.data?.[key] ? key : null;

      if (lastRowKey) {
        return lastRowKey === data.id;
      }
    }
    return false;
  };

  let renderBottomSvg = '';
  if (isLastRow()) {
    renderBottomSvg = `
      h -${width + ellipseSize}
    `;
  }
  else {
    renderBottomSvg = `
      q 0 -${ellipseSize} -${ellipseSize} -${ellipseSize} 
      h -${width - ellipseSize}
      q -${ellipseSize} 0 -${ellipseSize} ${ellipseSize} `;
  }

  return (
    <g
      transform={`translate(${x}, ${y})`}
      onMouseMove={(event) => {
        showTooltip(
          <>
            <Tooltip padding={10} show={true} >
              <TextView bold center text={`$${abbreviateNumber(data.value)}`} />
            </Tooltip>
          </>,
          event);
      }}
      onMouseLeave={(event) => {
        hideTooltip();
      }}
    >
      <path
        d={`
            M${-(ellipseSize / 2)}, 0
            q 0 -${ellipseSize} ${ellipseSize} -${ellipseSize}
            h ${width - ellipseSize}
            q ${ellipseSize} 0 ${ellipseSize} ${ellipseSize}
            v ${height}
            
            ${renderBottomSvg}
            z
          `}
        fill={color}
      />

    </g>
  );
};

export default StackedBarComponent;
