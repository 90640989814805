import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React from 'react'

import ReportPage from '../../../../yb_layout/report_page/ReportPage'
import TcfdScores from '../tcfd_target_report/components/TcfdScores'
import { TcfdHoldingDetailList } from './components/TcfdHoldingDetailList'

const TcfdHoldingDetail = ({
  title = 'TCFD Report',
  scores,
  peerData,
  targetName,
  holdingName,
  benchmarkName,
  asOf,
}) => {
  return (
    <ReportPage
      title={title}
      targetName={targetName}
      holdingName={holdingName}
      benchmarkName={benchmarkName}
      asOf={asOf}
    >
      <TcfdScores scores={scores} />
      <TcfdHoldingDetailList peerData={peerData} />
    </ReportPage>
  )
}

TcfdHoldingDetail.propTypes = {
  title: PropTypes.string,
  scores: PropTypes.array.isRequired,
  peerData: PropTypes.array.isRequired,
  targetName: PropTypes.string,
  holdingName: PropTypes.string,
  benchmarkName: PropTypes.string,
  asOf: PropTypes.instanceOf(dayjs.Dayjs),
}

export default TcfdHoldingDetail
