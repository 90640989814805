import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React from 'react'

import IconLink from '../../../../../../yb_components/styleguide/links/IconLink'
import DeleteSnapshotCell from './DeleteSnapshotCell'
import { AsOfHeaderRow } from './styles'

dayjs.extend(utc)

const YBOverviewCustomHeader = ({
  isPdf,
  asOfDates,
  snapshotIds,
  portfolioId,
  reportData,
  setReportData,
}) => {
  return (
    <>
      <AsOfHeaderRow>
        <td className='name-column' />
        {asOfDates.map((asOf, index) => (
          <td key={`as-of-${index}`}>
            <a
              className='as-of-link'
              href={`/app/portfolios/${portfolioId}/snapshots/${snapshotIds[index]}`}
            >
              {dayjs(asOf).utc().format('MMMM D, YYYY')}
            </a>
          </td>
        ))}
      </AsOfHeaderRow>
      <AsOfHeaderRow>
        <td />
        {!isPdf &&
          snapshotIds.map((snapshotId, index) => (
            <DeleteSnapshotCell
              snapshotId={snapshotId}
              portfolioId={portfolioId}
              asOf={dayjs(asOfDates[index]).utc().format('MMMM D, YYYY')}
              reportData={reportData}
              setReportData={setReportData}
            />
          ))}
      </AsOfHeaderRow>
    </>
  )
}

export default YBOverviewCustomHeader
