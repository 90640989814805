import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { esgScoreFullNames } from '../../constants'
import { BG_COLOR_PRIMARY, BORDER_COLOR_SECONDARY } from '../../styles/colors'

const computeColor = (score, neutralIsZero = false) => {
  const green = '25, 135, 84'
  const red = '176, 42, 55'

  if (neutralIsZero) {
    if (score < 0) {
      return `rgba(${red}, ${-score / 2 / 100 + 0.5})`
    } else {
      return `rgba(${green}, ${score / 2 / 100 + 0.5})`
    }
  } else {
    if (score < 50) {
      return `rgba(${red}, ${(100 - score) / 100})`
    } else {
      return `rgba(${green}, ${score / 100})`
    }
  }
}

const StyledCell = styled.td`
  color: ${props => props.color};
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  width: 15vw;
  border: none;
`

const StyledTable = styled.table`
  thead tr,
  tbody tr:nth-child(odd) {
    border-bottom: 2px solid white;
  }

  tbody tr:nth-child(even) {
    border-bottom: 2px solid ${BG_COLOR_PRIMARY};
  }

  tr:first-child th {
    padding-bottom: 0;
  }

  tr:nth-child(2) th {
    padding-top: 0;
    line-height: 15px;
  }

  th:not(:first-child, :last-child),
  td:not(:first-child, :last-child) {
    border-width: 0 1px;
    border-style: dashed;
    border-color: ${BORDER_COLOR_SECONDARY};
  }

  th {
    text-align: center !important;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
  }

  td.name-column {
    text-align: right;
    color: #6c757d;
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    border: none !important;
    text-transform: uppercase;
    padding: 0.94rem;
    width: 100px;
  }

  td.sdg-column {
    display: flex;
    align-items: center;

    div.sdg-name {
      padding-left: 10px;
      text-align: left;
      vertical-align: middle;
    }
  }
`
const ESGTable = ({ entities, esgScores }) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          <th className='name-column' />
          {entities.map((entity, index) => (
            <th key={`esg-table-entity-${index}`}>
              {_.truncate(entity.name, { length: 14 })}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {['e', 's', 'g', 'total'].map((metricType, index) => (
          <tr key={`${metricType}-${index}`}>
            <td className='name-column'>
              {esgScoreFullNames[metricType].toUpperCase()}
            </td>
            {esgScores.map((esgScore, index) => (
              <StyledCell
                key={`table-cell-${metricType}-${index}`}
                color={computeColor(esgScore[metricType])}
              >
                {Number(esgScore[metricType]).toFixed(0)}
              </StyledCell>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  )
}

export default ESGTable
