import React, { useState } from 'react'

import { sendWeights, submitData } from '../custom_esg_scoring_repository'
import { RectangularButton, StyledBar } from '../CustomESGScoring.styles'
import YBModal from '../../../yb_components/styleguide/modal/Modal'

const SubmitBar = ({
  data,
  intermediateScoresLoading,
  setIntermediateScoresLoading,
  dispatch,
  previewDisabled,
}) => {
  const [disabledButton, setDisabledButton] = useState(false)
  const [timer, setTimer] = useState()
  const [showConfirm, setShowConfirm] = useState(false)

  const handleIntermediateScores = () => {
    sendWeights({
      data,
      setIntermediateScoresLoading,
      setTimer,
      setDisabledButton,
      dispatch,
    })
  }

  const handleSubmit = () => {
    submitData({ data, setDisabledButton }).then(() => {
      window.location.href = `/app`
    })
    setShowConfirm(false)
  }

  return (
    <StyledBar>
      <div className='reset-link' onClick={() => window.location.reload()}>
        Reset Changes
      </div>
      <RectangularButton
        onClick={() => handleIntermediateScores()}
        className='run-button'
        disabled={
          previewDisabled || disabledButton || intermediateScoresLoading
        }
      >
        Preview Results
      </RectangularButton>
      <RectangularButton
        disabled={disabledButton}
        onClick={() => setShowConfirm(true)}
      >
        Save Changes and Update Portfolios
      </RectangularButton>
      <YBModal
        show={showConfirm}
        setShow={setShowConfirm}
        submitText="Confirm"
        title="Custom ESG Scores"
        onSubmit={handleSubmit}
      >
        <div>
          Calculating ESG scores for your organization's portfolios generally takes 5 to 10 minutes, during which you will not be able to access them.
        </div>
      </YBModal>
    </StyledBar>
  )
}

export default SubmitBar
