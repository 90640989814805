import React from 'react';
import SectionWrapper from '../SectionWrapper';
import styled from 'styled-components';
import { FONT_COLOR_SECONDARY } from '../../../styles/colors';

export const MultiPartDisplayHeader = styled(SectionWrapper)`
    justify-content:space-between;
    color:${FONT_COLOR_SECONDARY};
    width:100%;
    font-size:15.5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    padding: 0.94rem 0;
`
