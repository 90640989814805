import styled from 'styled-components';
import { BTN_COLOR_PRIMARY } from '../../../../../../../styles/colors';

export const StyledAddLink = styled.a`
  cursor: pointer;
  color: ${BTN_COLOR_PRIMARY};
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
`

export const StyledReplaceLink = styled.a`
  cursor: pointer;
  color: ${BTN_COLOR_PRIMARY};
  font-size: 10px;
  font-weight: 400;
`
