import { AppActions } from '../actions/AppActions'

const AppReducer = (state, action) => {
  switch (action.type) {
    case AppActions.UPDATE_BADGES:
      return { ...state, badges: action.payload.badges }

    case AppActions.SET_CURRENT_TAB_REF:
      return { ...state, currentTabRef: action.payload }

    case AppActions.SET_TARGET:
      return { ...state, target: action.payload }

    case AppActions.SET_BENCHMARK:
      return { ...state, benchmark: action.payload }

    default:
      return state
  }
}

export default AppReducer
