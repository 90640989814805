import Color from 'color'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import {
  disableOrganizationUser,
  enableOrganizationUser,
  getOrganizationUsers,
  resetOrganizationUserPassword,
} from '../../../repositories/organizations_repository'
import { BreadcrumbNav } from '../../../yb_components/styleguide/breadcrumbs/BreadcrumbNav'
import PageCard from '../../../yb_components/styleguide/cards/PageCard'
import Pagination from '../../../yb_components/styleguide/pagination/Pagination'
import { confirm } from './components/modals/ConfirmModal'
import { ManageUsersTable } from './components/tables/ManageUsersTable'
import { ManageUsersTableFilters } from './components/tables/ManageUsersTableFilters'

const buttonBgColor = Color('#6682ca')
const StyledHeader = styled.header`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  #add_user_button {
    min-width: 100px;
    min-height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
    color: white;
    background-color: ${buttonBgColor.hex()};
    border-color: ${buttonBgColor.hex()};
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      background-color: ${buttonBgColor.darken(0.15).hex()};
      border-color: ${buttonBgColor.darken(0.15).hex()};
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }
`

const OrganizationUsersPage = ({ organization }) => {
  const breadcrumbLinks = [
    {
      url: '/app',
      text: 'Home',
      active: false,
    },
    {
      url: '/app/organization',
      text: `${organization?.name ? organization.name : 'Organization'}`,
      active: true,
    },
    {
      url: '/app/organization/users',
      text: 'User Management',
      active: true,
    },
  ]

  const [usersList, setUsersList] = useState([])
  const [tableLoading, setTableLoading] = useState(true)
  const [pagination, setPagination] = useState({
    limit: 10,
    page: 1,
    total_pages: 1,
  })
  const [tableFilters, setTableFilters] = useState({
    query: '',
    role: '',
    status: '',
  })
  useEffect(() => {
    const loadUsersList = async () => {
      const res = await getOrganizationUsers()
      if (res != null) {
        setUsersList(res.data)
        setPagination({
          limit: Number(res.limit),
          page: Number(res.page),
          total_pages: Number(res.total_pages),
        })
        setTableLoading(false)
      }
    }
    loadUsersList()
  }, [])

  const handlePaginationChange = async val => {
    setTableLoading(true)
    const pageData = Object.assign({})
    pageData.page = val
    const params = { ...tableFilters, ...pageData }
    const res = await getOrganizationUsers(params)
    if (res != null) {
      setUsersList(res.data)
      setPagination({
        limit: Number(res.limit),
        page: Number(res.page),
        total_pages: Number(res.total_pages),
      })
    } else {
      setUsersList([])
    }
    setTableLoading(false)
  }

  const handleFilterChange = async filters => {
    setTableLoading(true)
    const pageData = Object.assign({})
    pageData.page = 1
    setTableFilters(filters)
    const params = { ...filters, ...pageData }

    const res = await getOrganizationUsers(params)
    if (res != null) {
      setUsersList(res.data)
      setPagination({
        limit: Number(res.limit),
        page: Number(res.page),
        total_pages: Number(res.total_pages),
      })
    } else {
      setUsersList([])
    }
    setTableLoading(false)
  }

  const toggleUser = async (user, status) => {
    try {
      const res = await (status == 'active'
        ? disableOrganizationUser(user)
        : enableOrganizationUser(user))

      const toast_message =
        res.status == 'active' ? 'User enabled' : 'User disabled'
      toast.success(toast_message)

      setUsersList(
        usersList.map(obj =>
          obj.id === user.id ? { ...obj, status: res.status } : obj
        )
      )
    } catch (e) {
      console.error(e)
      toast.error(`Update user status failed (status: ${status})`)
    }
  }

  const handleUpdateUser = async user => {
    if (user.status === 'active') {
      const res = await confirm({
        title: 'Disable User Access',
        content:
          'Disabling access will immediately prevent the user from accessing YvesBlue. The only way they will be able to regain access is by you or another administrator enabling their access.',
        buttonText: 'Disable Access',
      })
      if (res) toggleUser(user, user.status)
    } else {
      const res = await confirm({
        title: 'Enable User Access',
        content:
          'Enabling access will allow the user to access the YvesBlue application. They will receive an e-mail alerting them that their access has been restored. They will need to reset their password.',
        buttonText: 'Enable Access',
      })
      if (res) toggleUser(user, user.status)
    }
  }

  const handleResetPassword = async user => {
    // confirm modal
    const res = await confirm({
      title: 'Reset User Password',
      content:
        'Resetting a user’s password will send them an e-mail with a link to reset their password and log into YvesBlue. They will have 48 hours to reset their password before the link expires.',
      buttonText: 'Reset Password',
    })
    if (res) {
      try {
        const res = await resetOrganizationUserPassword(user.id)
        if (res.status == 204)
          toast.success(`A password reset link has been sent to ${user.email}.`)
        else toast.error('Reset password failed')
      } catch (e) {
        console.error(e)
        toast.error('Reset password failed')
      }
    }
  }

  return (
    <div className='page__container'>
      <header style={{ padding: '1.25rem 0' }}>
        <BreadcrumbNav links={breadcrumbLinks} />
      </header>
      <PageCard>
        <StyledHeader>
          <h1>User Management</h1>
          <a href={`/app/organization/users/new`} id='add_user_button'>
            Add User
          </a>
        </StyledHeader>
        <ManageUsersTableFilters onFilterChange={handleFilterChange} />
        <ManageUsersTable
          users={usersList}
          loading={tableLoading}
          onUpdateUser={handleUpdateUser}
          onResetPassword={handleResetPassword}
        />
        <Pagination
          max={pagination.total_pages}
          selected={pagination.page}
          onChange={handlePaginationChange}
        />
      </PageCard>
    </div>
  )
}

export default OrganizationUsersPage
