import useFeedbackMessage from '@env/hooks/forms/useFeedbackMessage'
import useInputValidations from '@env/hooks/forms/useInputValidations'
import useRequiredValidation from '@env/hooks/forms/useRequiredValidation'
import useShouldDisplayInputFeedback from '@env/hooks/forms/useShouldDisplayInputFeedback'
import useModelValue from '@env/hooks/models/useModelValue'
import withFormControl from '@ui/components/compositions/withFormControl'
import withFormRow from '@ui/components/compositions/withFormRow/withFormRow'
import { InputFeedback } from '@ui/components/presentation/copy/InputFeedback'
import getTestingProps from '@utils/test/getTestingProps'
import React, { useState } from 'react'

import RadioButton from '../RadioButton/RadioButton'
// import theme from './RadioButtonGroup.module.scss'
import './RadioButtonGroup.module.scss'

export interface IProps extends YB.IFormControl<string> {
  name: string
  options: YB.IOption[]
  required?: boolean
  disabled?: boolean
}

const RadioButtonGroup: React.FC<IProps> = ({
  options,
  name,
  required = true,
  validations = [],
  model = useState<string>(''),
  feedbackModel = useState<string>(''),
  formFeedbackModel = useState<string>(''),
  disabled = false,
}) => {
  const [isPristine, setIsPristine] = useState<boolean>(true)
  const feedbackMessage = useFeedbackMessage(feedbackModel, formFeedbackModel)
  const isInvalid = useShouldDisplayInputFeedback(
    feedbackMessage,
    isPristine,
    disabled
  )

  useInputValidations(
    useModelValue(model),
    useRequiredValidation(validations, required),
    feedbackModel
  )

  return (
    <div className='RadioButtonGroup' {...getTestingProps(name)}>
      {options.map(option => {
        return (
          <RadioButton
            key={option.value}
            model={model}
            label={option.label}
            value={option.value}
            disabled={option.disabled || disabled}
            name={name}
            required={false}
            onBlur={() => setIsPristine(false)}
          />
        )
      })}
      <InputFeedback feedbackMessage={feedbackMessage} isPresent={isInvalid} />
    </div>
  )
}

export default RadioButtonGroup

export const RadioButtonGroupControl = withFormControl(RadioButtonGroup)

export const RadioButtonGroupRow = withFormRow(RadioButtonGroup)
