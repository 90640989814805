import styled, { css } from 'styled-components'

export const FullSize = css`
  width: 100%;
  height: 100%;
`

export const AlignCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AlignRight = css`
  display: flex;
  justify-content: flex-end;
`

export const HLine = styled.div`
  width: 100%;
  border-top: 2px solid #d9d9d9;
  margin-bottom: 18px;
`
