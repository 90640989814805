import React, { useMemo } from 'react'
import styled from 'styled-components'

import Card from '../../../components/styleguide/Card'
import ExportButton from '../../../components/styleguide/ExportButton'
import SectionTitle from '../../../components/styleguide/SectionTitle'
import SwitchButton from '../../../components/styleguide/SwitchButton'
import withErrorBoundary from '../../../hocs/withErrorBoundary'
import AssetTypeFilter from '../../../modules/portfolio/components/portfolio_holdings_card/components/asset_type_filter/AssetTypeFilter'
import CoverageFilter from '../../../modules/portfolio/components/portfolio_holdings_card/components/coverage_filter/CoverageFilter'
import MetricsFilter from '../../../modules/portfolio/components/portfolio_holdings_card/components/metrics_filter/MetricsFilter'
import PropertiesFilter from '../../../modules/portfolio/components/portfolio_holdings_card/components/properties_filter/PropertiesFilter'
import { findFirstFilter } from '../../../modules/portfolio/components/portfolio_holdings_card/helpers'
import {
  FilterTypes,
  SORT_SWITCH_OPTIONS,
} from '../../../modules/portfolio/components/portfolio_holdings_card/PortfolioHoldingsCard.constants'
import HoldingsSearchBar from '../../styleguide/holdings-search-bar/holdingsSearchBar'
import {
  ExplainerLink,
  LinkIcon,
  LinkText,
} from '../../styleguide/links/Link.styles'
import Paginator from '../../styleguide/pagination/Pagination'
import FlatTable from '../flat_table/FlatTable'
import Section from './HoldingsTableCard.styles'

const CustomFlatTable = styled(FlatTable)`
  --min-height: 590px;

  .header-row {
    .th-4 {
      > span {
        justify-content: center !important;
      }
    }
  }
`

const StyledExplainerLink = styled(ExplainerLink)`
  float: right;
  margin-bottom: 10px;
`

const StyledFooterCol = styled.div`
  width: 100%;
  flex-grow: 1;
`

const HoldingsTableCard = ({
  /* Table */
  title = 'Holdings',
  selectedTableHeaders,
  selectedTableBody,
  loadingHoldings,
  holdingsTotal,
  onSearch,
  onExportHoldings,

  /* Pagination */
  nPages,
  selectedPage,
  onPaginatorChange,

  /* Sorting */
  sort,
  onSort,

  /* Filters */
  showFilters,
  filtersOptions,
  formControlFilters = {},
  onFiltersUpdate,
  onClearFilters,

  /* Switches */
  groupBy,
  onGroupByChange,
}) => {
  const propertiesDefaultData = useMemo(() => {
    return {
      [FilterTypes.POSITION_TYPE]:
        formControlFilters[[FilterTypes.POSITION_TYPE]],
      [FilterTypes.MARKET_CAP]: formControlFilters[[FilterTypes.MARKET_CAP]],
      [FilterTypes.REVENUE]: formControlFilters[[FilterTypes.REVENUE]],
      [FilterTypes.INDUSTRY_GROUP]:
        formControlFilters[[FilterTypes.INDUSTRY_GROUP]],
      [FilterTypes.BUSINESS_SECTOR]:
        formControlFilters[[FilterTypes.BUSINESS_SECTOR]],
      [FilterTypes.COUNTRY_OF_HQ]:
        formControlFilters[[FilterTypes.COUNTRY_OF_HQ]],
    }
  }, [formControlFilters])

  const metricsDefaultData = useMemo(() => {
    return {
      [FilterTypes.POSITIVE_BADGE]:
        formControlFilters[[FilterTypes.POSITIVE_BADGE]],
      [FilterTypes.NEGATIVE_BADGE]:
        formControlFilters[[FilterTypes.NEGATIVE_BADGE]],
      [FilterTypes.E_SCORE]: formControlFilters[[FilterTypes.E_SCORE]],
      [FilterTypes.S_SCORE]: formControlFilters[[FilterTypes.S_SCORE]],
      [FilterTypes.G_SCORE]: formControlFilters[[FilterTypes.G_SCORE]],
      [FilterTypes.TOTAL_SCORE]: formControlFilters[[FilterTypes.TOTAL_SCORE]],
    }
  }, [formControlFilters])

  const displayClearAll = useMemo(() => {
    return findFirstFilter(formControlFilters)
  }, [formControlFilters])

  return (
    <Section>
      <Card className='holdings-card' padding={20}>
        <div className='header-container'>
          <SectionTitle title={title || 'Holdings'} />
          <div className='right-header-container'>
            {onExportHoldings && (
              <ExportButton
                onClick={onExportHoldings}
                fileType='CVS'
                className='export-btn'
                label='DOWNLOAD HOLDINGS'
                icon={<i className='fal fa-file-excel csv-icon'></i>}
                fontSize={'13px'}
                height={'40'}
                padding={'0 13px !important'}
              />
            )}
            <StyledExplainerLink
              href='http://help.yves.blue/en/articles/6210671-holdings-table-explainer'
              target='_blank'
            >
              <LinkIcon className='far fa-question-circle' />
            </StyledExplainerLink>
          </div>
        </div>
        {showFilters === undefined && onSearch && (
          <HoldingsSearchBar onSearch={onSearch} height='40px' />
        )}
        {showFilters && (
          <div className='filter-buttons-wrapper'>
            <HoldingsSearchBar onSearch={onSearch} height='40px' />

            <AssetTypeFilter
              options={filtersOptions?.assetClasses}
              defaultData={formControlFilters?.asset_type}
              onUpdate={onFiltersUpdate}
            />

            <CoverageFilter
              defaultData={formControlFilters?.coverage}
              onUpdate={onFiltersUpdate}
            />

            <PropertiesFilter
              options={filtersOptions}
              defaultData={propertiesDefaultData}
              onUpdate={onFiltersUpdate}
            />

            <MetricsFilter
              options={filtersOptions}
              defaultData={metricsDefaultData}
              onUpdate={onFiltersUpdate}
            />
            {displayClearAll ? (
              <ExplainerLink
                fontSize='14px'
                padding='8px 20px'
                margin='0 10px 0 0'
                onClick={onClearFilters}
              >
                <LinkText>Clear All</LinkText>
              </ExplainerLink>
            ) : (
              <div style={{ width: '93px' }}></div>
            )}
          </div>
        )}

        <CustomFlatTable
          className='holdings-table'
          onSort={(field, direction) => onSort(field, direction)}
          headers={selectedTableHeaders}
          body={selectedTableBody}
          isLoading={loadingHoldings}
          sort={sort}
          holdingsTotal={holdingsTotal}
          noDataText={
            <>
              <div>No holdings were found for your search query.</div>
              <div>Please try again.</div>
            </>
          }
        />
        <div className='paginator-container'>
          <StyledFooterCol />

          <StyledFooterCol>
            <Paginator
              max={nPages}
              selected={selectedPage}
              onChange={onPaginatorChange}
            />
          </StyledFooterCol>

          {onGroupByChange && (
            <StyledFooterCol className='switch-wrapper'>
              <SwitchButton
                onClick={onGroupByChange}
                active={groupBy}
                options={SORT_SWITCH_OPTIONS}
              />
            </StyledFooterCol>
          )}
        </div>
      </Card>
    </Section>
  )
}

export default withErrorBoundary(HoldingsTableCard)
