import React, { useMemo } from 'react';
import styled from 'styled-components';

import RecordsCard from '../../../../yb_components/cards/records_card/RecordsCard';
import { addFilterOptionsToColumns, bucketToFilterSelectOptions } from '../../../../yb_helpers/data_converters';
import { addThousandSeparator } from '../../../../helpers/number';
import { titleCase } from '../../../../yb_helpers/strings';
import { FONT_COLOR_PRIMARY } from '../../../../styles/colors';
import { VIOLATIONS_BUCKET_DATA } from '../Violations.constants';

const RecordsTableCell = styled.div`
    margin-right: ${props => props.marginRight || '0px'};
    display: flex;
    justify-content: ${props => props.justify || 'center'};
    color: ${FONT_COLOR_PRIMARY};
    font-weight: ${props => props.header ? 'bold' : 'normal'};
    font-size: 11px;
    text-align: center;
`;

const ViolationsRecordsCard = (props) => {

    const renderCell = (label, cellOptions) => {
        let fLabel = (!label || label === '') ? '—' : label;
        return (
            <RecordsTableCell {...cellOptions}>
                {fLabel}
            </RecordsTableCell>
        )
    }

    const VIOLATIONS_RECORDS_COLUMNS = [
        {
            accessor: 'govt_level',
            Header: props => renderCell('Government Level', { header: true }),
            Cell: props => renderCell(titleCase(props.value)),
            filter: {
                placeholder: 'All Government Levels',
                isMulti: true
            }
        },
        {
            accessor: 'civil_criminal',
            Header: props => renderCell('Type', { header: true }),
            Cell: props => renderCell(titleCase(props.value)),
            filter: {
                placeholder: 'All Offense Types',
                isMulti: true
            }
        },
        {
            accessor: 'offense_group',
            Header: props => renderCell('Offense Group / Primary Offense', { header: true }),
            Cell: props => {
                let primaryOffense = props?.row?.original?.primary_offense;
                if (primaryOffense) primaryOffense = ` / ${titleCase(primaryOffense)}`;

                return renderCell(`${titleCase(props.value)}${primaryOffense}`)
            },
            filter: {
                placeholder: 'All Offense Groups',
                isMulti: true
            }
        },
        {
            accessor: 'agency',
            Header: props => renderCell('Agency', { header: true }),
            Cell: props => renderCell(props.value),
            filter: {
                placeholder: 'All Agencies',
                isMulti: true
            }
        },
        {
            accessor: 'pen_year',
            Header: props => renderCell('Year', { header: true }),
            Cell: props => renderCell(props.value),
            filter: {
                placeholder: 'All Years',
                isMulti: true
            }
        },
        {
            accessor: 'state',
            Header: props => renderCell('State', { header: true }),
            Cell: props => renderCell(props.value),
            filter: {
                placeholder: 'All States',
                labelFormatter: (value) => value,
                isMulti: true
            },
        },
        {
            accessor: 'penalty',
            Header: props => renderCell('Amount', { header: true, justify: 'flex-end' }),
            Cell: props => renderCell(`$ ${addThousandSeparator(props.value)}.00`, { justify: 'flex-end', marginRight: '10px' }),
            filter: {
                placeholder: 'All Amounts',
                selectOptions: useMemo(() => bucketToFilterSelectOptions(VIOLATIONS_BUCKET_DATA, 1000), []),
                isMulti: true
            }
        },
    ];

    /* Data & Memoized data */
    const filterLabelFormatter = (value) => isNaN(value) ? titleCase(value) : value;
    const memoizedRecordsColumns = useMemo(() => addFilterOptionsToColumns(VIOLATIONS_RECORDS_COLUMNS, props.stats?.filter_values, filterLabelFormatter), [props.stats]);

    /* Render */
    return (
        <RecordsCard
            {...props}
            columns={memoizedRecordsColumns}
        />
    );
}

export default ViolationsRecordsCard;