import { BaseInputControl } from '@ui/components/interactive/BaseInput'
import { MultipleOptionSwitchControl } from '@ui/components/interactive/MultipleOptionSwitch'
import React, { useEffect, useMemo } from 'react'

// import theme from './HoldingDataFilters.module.scss'
import './HoldingDataFilters.module.scss'
// import SwitchButton from '../../../../../../../../../react/components/styleguide/SwitchButton'
import styled from 'styled-components'
import FormService from '@services/Form'
import { IDisclosuresFiltersData } from '../../../Disclosures/components/DisclosuresFilters/DisclosuresFilters'
import { FILTERS } from './HoldingDataFilters.constants'
import { useDebounce } from '../../../../../../../../../react/yb_hooks/inputs/useDebounce.js'

const S = {
  FiltersContainer: styled.div`
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    margin-bottom: 0px;
    padding-bottom: 0px;

    > *:not(:last-child) {
      margin-left: 0;
      margin-right: $gap;
    }
  `,
}

const Form = new FormService()

export interface IHoldingDataFiltersData {
  environmentallySustainable: string
  goodGovernance: string
  oecdAligned: string
  sociallySustainable: string
  sustainableInvestment: string
  taxonomyAligned: string
  taxonomyAlignedFossilGas: string
  taxonomyAlignedNuclear: string
}

interface IProps {
  onSearchName: (value: string) => string
  onChange: (formData: IDisclosuresFiltersData) => void
}

const HoldingDataFiltersComp: React.FC<IProps> = ({
  onSearchName,
  onChange,
}) => {
  const [environmentallySustainable] = Form.useInputModel<string>('all')
  const [goodGovernance] = Form.useInputModel<string>('all')
  const [oecdAligned] = Form.useInputModel<string>('all')
  const [sociallySustainable] = Form.useInputModel<string>('all')
  const [sustainableInvestment] = Form.useInputModel<string>('all')
  const [taxonomyAligned] = Form.useInputModel<string>('all')
  const [taxonomyAlignedFossilGas] = Form.useInputModel<string>('all')
  const [taxonomyAlignedNuclear] = Form.useInputModel<string>('all')

  const formMap = [
    environmentallySustainable,
    goodGovernance,
    oecdAligned,
    sociallySustainable,
    sustainableInvestment,
    taxonomyAligned,
    taxonomyAlignedFossilGas,
    taxonomyAlignedNuclear,
  ]

  const formModel = useMemo(
    () => ({
      environmentally_sustainable: environmentallySustainable,
      good_governance: goodGovernance,
      oecd_aligned: oecdAligned,
      socially_sustainable: sociallySustainable,
      sustainable_investment: sustainableInvestment,
      taxonomy_aligned: taxonomyAligned,
      taxonomy_aligned_fossil_gas: taxonomyAlignedFossilGas,
      taxonomy_aligned_nuclear: taxonomyAlignedNuclear,
    }),
    [
      environmentallySustainable,
      goodGovernance,
      oecdAligned,
      sociallySustainable,
      sustainableInvestment,
      taxonomyAligned,
      taxonomyAlignedFossilGas,
      taxonomyAlignedNuclear,
    ]
  )

  const formData = useMemo(() => Form.useFormData(formModel), [formModel])

  useEffect(() => {
    onChange?.(formData)
  }, [formData, onChange])

  const { debouncedOnChange: debouncedSearchName } = useDebounce({
    onChange: onSearchName,
  })

  return (
    <div className='HoldingDataFilters'>
      <BaseInputControl
        style={{ width: '200px' }}
        name='entity'
        label='Entity name'
        placeholder='Search Entities'
        required={false}
        showOptionalBadge={false}
        onChange={e => debouncedSearchName(e?.target?.value)}
      />

      <S.FiltersContainer>
        {FILTERS.map((filter, index) => {
          return (
            <MultipleOptionSwitchControl
              style={{ marginRight: '15px' }}
              model={formMap?.[index]}
              label={filter?.label?.replaceAll('_', ' ')}
              name={filter?.label}
              options={[
                { label: 'All', value: 'all' },
                ...filter?.values.map((elem: string) => {
                  return { label: elem?.replaceAll('_', ' '), value: elem }
                }),
              ]}
            />
          )
        })}
      </S.FiltersContainer>

      {/* <SwitchButton
        active={'alignment'}
        options={[
          { label: 'Alignment', value: 'alignment' },
          { label: 'Completion', value: 'completion' },
        ]}
      /> */}
    </div>
  )
}

const HoldingDataFilters = React.memo(HoldingDataFiltersComp)
export { HoldingDataFilters }
