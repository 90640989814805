import styled from 'styled-components'

export const StyledTabNav = styled.div`
  margin: 0;
  padding: 0;

  .nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-bottom: 1px solid #dbdbdb;

    .nav-item {
      padding: 0 10px;
      &:first-child {
        padding: 0 10px 0 20px;
      }
      &:last-child {
        padding: 0 20px 0 10px;
      }
    }
    .nav-link {
      margin-bottom: -1px;
      padding: 0.75rem 0;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 14px;
      line-height: 16.8px;
      color: #979ca6;
      &:hover {
        color: #6c757d;
        transition: color 0.15s ease-in-out;
      }
      &.active {
        border-bottom: 2px solid #002fa7;
        color: #002fa7;
        &:hover {
          cursor: default;
        }
      }
      &.disabled {
        color: #bdc2cc;
      }
    }
  }
  // place a this div.tab-nav-spacer to create right/left aligned sections
  .tab-nav-spacer {
    padding: 0 10px;
    flex-grow: 1;
  }
`
