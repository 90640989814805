import React from 'react';
import * as d3 from 'd3';
import numeral from 'numeral';
import LineChartBody from '../../../components/charts/components/line-chart/LineChartBody';

const SubsidiesChartPDF = ({ data, benchmarkData, color, className, benchmarkName, targetType }) => {
  const height = 150,
        width = 328,
        padding = 20;

  // D3 setup
  const scaleData = data
  const max = d3.max(scaleData.map(d => parseFloat(d.amount)));

  // create ticks
  const ticks = d3.ticks(0, max, 2);
  if(ticks[ticks.length - 1] < max) {
    ticks.push(ticks[ticks.length - 1] + ticks[1]);
  }

  // calculate label length based on tick values
  const labelLength = ticks.map(t => numeral(t).format('a0')).reduce((prev, current) => {
    return current.length > prev ? current.length : prev
  }, 0);
  
  // calculate draw area offsets and dimensions
  const yOffset = 20;
  const xOffset = 10 * labelLength;
  const dataWidth = width - (2 * padding) - xOffset;
  const dataHeight = height - (2 * padding) - yOffset;

  const yAxis = d3.scaleLinear()
    .domain([0, ticks[ticks.length - 1]])
    .range([dataHeight, 0]);

  const xAxis = d3.scaleLinear()
    .domain([0, data.length])
    .range([0, dataWidth]);

  // render methods
  const renderLabels = () => {
    return (
      <>
        {renderYLabels()}
        {renderXLabels()}
      </>
    )
  }

  const renderData = () => {
    return (
      <g>
        {renderGrid()}
        {renderBars()}
      </g>
    )
  }

  const renderBenchmark = () => {
    const columnWidth = (dataWidth / benchmarkData.length);
    const barWidth = 0.7 * columnWidth;
    const data = benchmarkData.map((bd, i) => ({x: i , y: bd.amount}))
    
    const xFuncLine = (d) => {
      return xAxis(d) + barWidth - 0.28*barWidth
    }
    return benchmarkData && (
        <LineChartBody
          data={data}
          xscale={xFuncLine}
          yscale={yAxis}
          radius={2}
          svgstyles={{dotStroke:"#E7BB4C", dotFill:"#E7BB4C", lineStroke:"#E7BB4C", strokeDasharray: "10,10", lineFill: "none", lineWidth:2.5, circleWidth:3.25}}
        />
      )
  }

  const renderGrid = () => {
    const grid = ticks.map((t, i) => 
      <line key={i} x1={0} y1={yAxis(t)} x2={dataWidth} y2={yAxis(t)} className="chart-grid-line" />
    );
    
    return grid;
  }

  const renderBars = () => {
    return data.map((d, i) => {
      const columnWidth = (dataWidth / data.length);
      const barWidth = 0.7 * columnWidth;
      const barHeight = dataHeight - yAxis(parseFloat(d.amount));
      const x = xAxis(i) + (columnWidth - barWidth) / 2;
      
      return renderRoundedBar(x, dataHeight, barWidth, barHeight, 5, i);
      
    });
  }

  const renderRoundedBar = (x, y, width, height, radius = 5, key) => {
    let rectHeight = 0;

    if(!height) return null;
    else if(height > radius) {
      rectHeight = height - radius;
    } else {
      rectHeight = 0;
    }

    return (
      <g key={key} transform={`translate(${x}, ${y})`}>
        <path fill={color} d={`
          M 0 0
          v ${-rectHeight}
          q 0 ${-radius} ${radius} ${-radius}
          h ${width - (2 * radius)}
          q ${radius} 0 ${radius} ${radius}
          v ${rectHeight}
        `}/>
      </g>
    );

  }

  const renderXLabels = () => {
    const y = padding + dataHeight + 5;
    const date = new Date();
    const currentYear = date.getFullYear();
    
    return (
      <text className="bold">
        <tspan x={0} y={y}>{currentYear - 10}</tspan>
        <tspan x={dataWidth / 2} y={y} textAnchor="middle">{data[~~ (data.length / 2)]?.year}</tspan>
        <tspan x={dataWidth} y={y} textAnchor="end">{data[data.length - 1]?.year}</tspan>
      </text>
    )
  }

  const renderYLabels = () => {
    return (
      <text>
        {ticks.map((t, i) => <tspan key={i} textAnchor="end" x={-5} y={yAxis(t) + 3}>$ {numeral(t).format('a0').toUpperCase()}</tspan>)}
      </text>
    )
  }
  

  return (
    <div className={className}>
      <svg height={height} width={width}>
        <g transform={`translate(${padding + xOffset}, ${padding})`}>
          {renderLabels()}
          {renderData()}
          {benchmarkData && renderBenchmark()}
        </g>
      </svg>
      <div className="legend-bubble" style={{ backgroundColor: color }} />
      <span>{targetType}</span>
      {benchmarkName && (
        <>
          <div className="legend-bubble" style={{ backgroundColor: '#E7BB4C' }} />
          <span>{benchmarkName}</span>
        </>
      )}
    </div>
  );
}

export default SubsidiesChartPDF;