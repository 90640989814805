/**
 * RGB Linear Blending
 * 
 * @param {number} p Percentage (values between -1.0 and +1.0)
 * @param {string} c0 First color in RGB color
 * @param {string} c1 Second color in RGB color
 * 
 * @returns The RGB formatted value
 */
export const RGB_Linear_Blend=(p, c0, c1) => {
  var i=parseInt,r=Math.round,P=1-p,[a,b,c,d]=c0.split(","),[e,f,g,h]=c1.split(","),x=d||h,j=x?","+(!d?h:!h?d:r((parseFloat(d)*P+parseFloat(h)*p)*1000)/1000+")"):")";
  return"rgb"+(x?"a(":"(")+r(i(a[3]=="a"?a.slice(5):a.slice(4))*P+i(e[3]=="a"?e.slice(5):e.slice(4))*p)+","+r(i(b)*P+i(f)*p)+","+r(i(c)*P+i(g)*p)+j;
}

/**
 * Transform HEX to RGB format
 * 
 * @param {*} hex The HEX value
 * 
 * @returns The RGB formatted value
 */
export const hexToRGBA = (hex) => {
  var c;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('');
      if(c.length== 3){
          c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
  }
  throw new Error('Bad Hex');
}
