import React, { useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

import Card from '../../components/styleguide/Card'
import CardTitle from '../../components/styleguide/CardTitle'
import EventGrouping from '../../modules/events/EventGrouping'
import { Event } from '../../modules/events/Event'

import Pagination from "../../yb_components/styleguide/pagination/Pagination";
import SelectInput from '../../components/styleguide/SelectInput'
// import Button from '../../components/styleguide/Button'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import {
  APP_COLOR_PRIMARY,
  APP_COLOR_PRIMARY_300,
  FONT_COLOR_PRIMARY,
} from '../../styles/colors'

const StyledEventLogBody = styled.div`
  position: relative;

  & .heading-container {
    margin: 0 0 1.25rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > h2 {
      margin: 0 1rem 0 0;
      display: inline-block;
      flex-grow: 1;
      font-size: 26px;
      font-weight: bold;
      line-height: 40px;
      color: ${FONT_COLOR_PRIMARY};
    }
    > .download-button {
      padding: 6px 1.5rem;
      min-height: 30px;
      display: flex;
      flex-direction: row;
      justify-content: justify;
      align-items: center;
      border: 1px solid ${APP_COLOR_PRIMARY};
      border-radius: 21.5px;
      background-color: #fff;
      text-transform: uppercase;
      color: ${APP_COLOR_PRIMARY};
      font-size: 12px;
      line-height: 1.1;
      &:hover,
      &:active {
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        background-color: ${APP_COLOR_PRIMARY_300};
        color: ${APP_COLOR_PRIMARY};
      }
    }
    & .download-icon {
      margin: 0 10px 0 0;
      width: 15px;
      height: 15px;
      fill: ${APP_COLOR_PRIMARY};
    }
  }
  & .filters-container {
    margin: 0 0 1.25rem;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: justify;
    align-items: center;
    gap: 5px;
    border-radius: 6px;
    background-color: #f1f1f1;
    /* > input {
      min-height: 30px;
      flex-grow: 1;
      border: none;
      outline: none;
      border-radius: 5px;
    } */
  }
  #event_type_select,
  #user_select {
    margin: 0;
    min-width: 210px;
  }
  #time_select {
    min-width: 140px;
  }
  & .no-results-container {
    margin: 0;
    padding: 0 1.25rem;
    text-align: center;
    p {
      text-align: center;
      font-style: italic;
      font-size: 1rem;
    }
    button {
      margin: 0 auto;
      display: block;
    }
  }
  .loading-icon {
    margin: 1rem auto;
  }
`

const EventLogBody = ({
  events,
  showFilter,
  pageCount,
  showPageNumber,
  currentPage,
  actionTypes,
  onChange,
  onDownload,
  cardTitle,
  loading,
}) => {
  const defaultOption = {
    id: 'all',
    label: 'All Events',
  }
  const defaultUserOption = {
    id: 'all',
    label: 'All Users',
  }
  const defaultTimeOption = {
    id: 'all',
    label: 'All Time',
  }

  const [filter, setFilter] = useState({})
  const [selectedEvent, setSelectedEvent] = useState(defaultOption)
  const [selectedUser, setSelectedUser] = useState(defaultUserOption)
  const [selectedTime, setSelectedTime] = useState(defaultTimeOption)

  const groupedEvents = {}
  let dates = []

  const showSeeMore = currentPage < pageCount
  const showSeeLess = currentPage > 1

  events.forEach((event) => {
    const formattedDate = new Date(event.created_at).toLocaleDateString(
      'en-US',
      { dateStyle: 'full' }
    )

    if (groupedEvents[formattedDate]) {
      groupedEvents[formattedDate] = groupedEvents[formattedDate].concat(event)
    } else {
      groupedEvents[formattedDate] = [event]
      dates = dates.concat(formattedDate)
    }
  })

  const eventComponents = dates.map((date, index) => {
    const events = groupedEvents[date]
    return (
      <div key={index}>
        <CardTitle title={date} />
        <EventGrouping>
          {events.map((e) => new Event(e).render())}
        </EventGrouping>
      </div>
    )
  })

  const onUserChange = (event, eventID) => {
    const newFilter = Object.assign({}, filter)

    if (eventID === 'all') {
      const newFilter = Object.assign({}, filter)
      newFilter.user_ids = null
      setFilter(newFilter)
      setSelectedUser(defaultUserOption)

      onChange({ newFilter: newFilter, newPage: 1 })
    } else {
      const newUser = organizationUserOptions().find(
        (user) => user.id === parseInt(eventID)
      )

      newFilter.user_ids = [newUser.id]
      setFilter(newFilter)
      setSelectedUser(newUser)

      onChange({ newFilter: newFilter, newPage: 1 })
    }
  }

  const onEventTypeChange = (event, eventTypeID) => {
    const newEvent = actionTypes.find(
      (eventActionType) => eventActionType.id === eventTypeID
    )

    setSelectedEvent(newEvent)
    const newFilter = Object.assign({}, filter)

    if (eventTypeID === 'all') {
      newFilter.action_types = null
      newFilter.target_types = null
    } else {
      newFilter.action_types = [newEvent.action_type]
      newFilter.target_types = [newEvent.target_type]
    }

    setFilter(newFilter)

    onChange({ newFilter: newFilter, newPage: 1 })
  }

  const onTimeChange = (event, eventTypeID) => {
    // get time from select
    const newTime = timeOptions.find((opt) => opt.id === eventTypeID)
    // set time option in state
    setSelectedTime(newTime)
    // turn into usable time for api
    var apiTimestamp = null
    switch (eventTypeID) {
      case 'all':
        apiTimestamp = null
        break
      // case 'last_24_hours':
      //   apiTimestamp = dayjs().subtract(1, 'day').format()
      //   console.log('apiTimestamp', apiTimestamp)
      //   break
      case 'last_30_days':
        apiTimestamp = dayjs().subtract(30, 'day').format()
        break
      case 'last_6_months':
        apiTimestamp = dayjs().subtract(6, 'month').format()
        break
      case 'last_year':
        apiTimestamp = dayjs().subtract(1, 'year').format()
        break
      default:
        apiTimestamp = null
    }
    // make api call
    const newFilter = Object.assign({}, filter)
    newFilter.min_logged_at = apiTimestamp
    // return to page 1
    setFilter(newFilter)
    onChange({ newFilter: newFilter, newPage: 1 })
  }

  const resetFilters = () => {
    // reset state
    const newFilter = Object.assign({}, filter)
    newFilter.action_types = null
    newFilter.target_types = null
    newFilter.user_ids = null
    newFilter.min_logged_at = null
    setFilter(newFilter)
    // reset selects
    setSelectedEvent(defaultOption)
    setSelectedUser(defaultUserOption)
    setSelectedTime(defaultTimeOption)
    // make api call
    onChange({ newFilter: newFilter, newPage: 1 })
  }

  const organizationUserOptions = () => {
    let users = react_app_organization.users.map((user) => {
      return {
        id: user.id,
        label: user.full_name,
      }
    })

    users.unshift({ id: 'all', label: 'All Users' })
    return users
  }

  const timeOptions = [
    defaultTimeOption,
    // { id: 'last_24_hours', label: 'Last 24 Hours' }, // for testing
    { id: 'last_30_days', label: 'Last 30 Days' },
    { id: 'last_6_months', label: 'Last 6 Months' },
    { id: 'last_year', label: 'Last Year' },
  ]

  const handlePaginationChange = (val) => {
    // send request for next page
    onChange({ newFilter: filter, newPage: val })
  }

  const onDownloadClick = () => {
    onDownload({ filter: filter, page: currentPage })
  }

  return (
    <StyledEventLogBody id='event_log_body'>
      <Card>
        <header className='heading-container'>
          <h2>{cardTitle}</h2>
          <Button
            className='download-button'
            variant='outline-primary'
            onClick={onDownloadClick}
          >
            <img
              src='/icons/ico_download.svg'
              alt='download icon'
              className='download-icon'
            />
            Download
          </Button>
        </header>
        {showFilter && (
          <div className='filters-container'>
            <SelectInput
              onChange={onEventTypeChange}
              value={selectedEvent.id}
              id='event_type_select'
              options={actionTypes}
              eventsFilter={true}
              label='Select Event Types'
            />
            <SelectInput
              onChange={onUserChange}
              value={selectedUser.id}
              id='user_select'
              options={organizationUserOptions()}
              eventsFilter={true}
              label='Select Team Members'
            />
            <SelectInput
              onChange={onTimeChange}
              value={selectedTime.id}
              id='time_select'
              options={timeOptions}
              eventsFilter={true}
              label='Select Time Frame'
            />
          </div>
        )}

        {loading && (
          <div style={{ textAlign: 'center' }}>
            <Spinner
              className='loading-icon'
              animation='border'
              variant='secondary'
            />
          </div>
        )}

        {!loading && events.length > 0 && (
          <StyledEventLogBody>
            {eventComponents}
            <Pagination
              max={Number(pageCount)}
              selected={Number(currentPage)}
              onChange={handlePaginationChange}
            />
          </StyledEventLogBody>
        )}

        {!loading && !events.length && (
          <div className='no-results-container'>
            <p>There are no events to display.</p>
            <Button className='btn btn-secondary' onClick={resetFilters}>
              Reset Filters
            </Button>
          </div>
        )}
      </Card>
    </StyledEventLogBody>
  )
}

export default EventLogBody
