import propTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledH2 = styled.h2`
  font-size: 1.5rem;
`

const H2 = ({ className, children }) => (
  <StyledH2 className={className}>{children}</StyledH2>
)

H2.propTypes = {
  className: propTypes.string,
  children: propTypes.string.isRequired,
}

export default H2
