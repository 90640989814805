import { Button, EButtonVariants, IButtonProps } from '@ui/elements/Button'
import React from 'react'

import { HtmlFileIcon } from '../../../../../react/modules/portfolio/regulatory/pai/icons/HtmlFileIcon'
import { PdfFileIcon } from '../../../../../react/modules/portfolio/regulatory/pai/icons/PdfFileIcon'
import { WordFileIcon } from '../../../../../react/modules/portfolio/regulatory/pai/icons/WordFileIcon'
// import theme from './IconButton.module.scss'
import './IconButton.module.scss'

const IconVariants = {
  cloudUpload: 'far fa-cloud-upload',
  edit: 'far fa-edit',
  filePreview: 'far fa-search',
  cs: 'bi bi-filetype-cs',
  csv: 'bi bi-filetype-csv',
  xlsx: 'bi bi-filetype-xlsx',
  exe: 'bi bi-filetype-exe',
  docx: <WordFileIcon />,
  html: <HtmlFileIcon />,
  pdf: <PdfFileIcon />,
} as const

export interface IProps extends Omit<IButtonProps, 'variant'> {
  variant: keyof typeof IconVariants
}

export const IconButton: React.FC<IProps> = ({ variant, ...props }) => {
  return (
    <Button {...props} variant={EButtonVariants.text} className='IconButton'>
      {typeof IconVariants[variant] === 'string' ? (
        <i className={IconVariants[variant] as string} />
      ) : (
        IconVariants[variant]
      )}
    </Button>
  )
}
