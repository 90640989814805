import React from 'react'

import { abbreviateNumber, roundUpNearest10 } from '../../../../helpers/number'
import { SortDir } from '../../tcfd/components/holdings_table/tcfd_table_head/SortButton'
import { DEFAULT_SORT } from './constants'
import { StyledCellSubValue } from './styles'

export const formatSort = (
  sort_field,
  sort_dir,
  defaultSort = DEFAULT_SORT
) => {
  if (sort_dir === SortDir.NONE) {
    return defaultSort
  }

  return {
    sort_field,
    sort_dir,
  }
}

export const capitalizeFirstLetter = str => {
  // converting first letter to uppercase
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1)

  return capitalized
}

export const formatChartLabel = (d, barData) => {
  const value = barData[d.id]
  if (value != 0) {
    return d.id == 'Zero Emissions' ? (
      `${d.id} (${value}%)`
    ) : (
      <>
        <tspan x='50%' dy='-0.5em'>
          {d.id}
        </tspan>
        <tspan x='50%' dy='1.2em'>
          ({value}%)
        </tspan>
      </>
    )
  }
}

export const getPdfSortFieldText = (sortHeader, sort, pdf) => {
  if (pdf) {
    return (
      sortHeader.find(elem => elem?.field === sort?.sort_field)?.rawText || '—'
    )
  }
  return '—'
}

export const formatValue = (value, decimals = 2) => {
  if (value === 0) {
    return value
  } else if (value === null) {
    return '—'
  } else {
    const parsedValue = value ? parseFloat(value) : null
    return parsedValue || !isNaN(parsedValue)
      ? parsedValue?.toFixed(decimals)
      : '—'
  }
}

export const formatPortfolioCarbonSummaryCard = data => {
  if (data?.err?.response) {
    return data
  } else {
    const keys = Object.keys(data)
    const formattedData = {
      dataBreakdown: [],
    }
    const lowerTextStyle = { fontWeight: 400, fontSize: '18px' }

    const climateSolutionDifferenceRender = (value, benchmarkValue) => {
      const fValue = isNaN(parseFloat(value)) ? null : parseFloat(value)
      const fBenchmarkValue = isNaN(parseFloat(benchmarkValue))
        ? null
        : parseFloat(benchmarkValue)

      let positive = false
      let difference = 0
      if (fValue && fBenchmarkValue) {
        difference = (fValue / fBenchmarkValue) * 100
        positive = difference > 10
        difference = Math.abs(difference)
      }

      return (
        <StyledCellSubValue positive={positive}>
          {positive
            ? `More than ${roundUpNearest10(
                difference
              )}% invested in solution companies.`
            : `Less than ${roundUpNearest10(
                difference
              )}% invested in solution companies.`}
        </StyledCellSubValue>
      )
    }

    keys.forEach(key => {
      if (key === 'carbon_footprint') {
        formattedData.footPrint = {
          value: data[key].target,
          benchmarkValue: data[key].benchmark,
          symbol: 'tCO2e',
        }
      }

      key === 'climate_solution_companies' &&
        formattedData.dataBreakdown.push({
          text: `${formatValue(data[key].target, 0)}/${formatValue(
            data[key].target_total,
            0
          )}`,
          upperText: 'CLIMATE SOLUTION COMPANIES',
          value: data[key].target,
          benchmarkValue: data[key].target_total,
          customComparativeFunction: climateSolutionDifferenceRender,
        })

      key === 'carbon_intensity' &&
        formattedData.dataBreakdown.push({
          text: (
            <>
              <span style={{ display: 'block' }}>
                {formatValue(data[key].target)}
              </span>
              <span style={lowerTextStyle}>tCO2e / $M</span>
            </>
          ),
          upperText: 'CARBON INTENSITY',
          value: data[key].target,
          benchmarkValue: data[key].benchmark,
        })

      key === 'annual_carbon_cost' &&
        formattedData.dataBreakdown.push({
          text: '$' + abbreviateNumber(data[key].target, 2),
          upperText: 'ANNUAL CARBON COST',
          value: data[key].target,
          benchmarkValue: data[key].benchmark,
        })
      key === 'renewable_energy_usage' &&
        // Benchmark and target keys inverted due to specific section functionality.
        formattedData.dataBreakdown.push({
          text: `${formatValue(data[key].target)}%`,
          upperText: 'AVERAGE RENEWABLE ENERGY USAGE',
          value: data[key].benchmark,
          benchmarkValue: data[key].target,
          customComparativeStrings: {
            green: 'more than the benchmark.',
            red: 'less than the benchmark.',
          },
        })
    })
    return formattedData
  }
}
