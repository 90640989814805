import uuid from '@utils/uuid'
import React from 'react'

import { Link } from '../../../../elements/Link'
// import theme from './KeyValueList.module.scss'
import './KeyValueList.module.scss'

export interface IProps {
  items: YB.IKeyValueRecord<React.ReactNode>[]
  keyTitle: string
  valueTitle: string
}

export const KeyValueList: React.FC<IProps> = ({
  items,
  keyTitle,
  valueTitle,
}) => (
  <ul className='KeyValueList'>
    <li>
      <span>{keyTitle}</span>
      <span>{valueTitle}</span>
    </li>
    {items.map(item => (
      <li key={uuid()}>
        <div className='key'>
          {item.href ? <Link href={item.href}>{item.key}</Link> : item.key}
        </div>
        <div className='value'>{item.value}</div>
      </li>
    ))}
  </ul>
)
