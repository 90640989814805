import styled, { css } from 'styled-components'

import Link from '../../../components/styleguide/Link'
import { APP_COLOR_PRIMARY, BORDER_COLOR_PRIMARY } from '../../../styles/colors'

/* Styled Components */
export const LinkIcon = styled.i`
  display: inline;
`
export const LinkText = styled.div`
  display: inline;
`

/* CSS */
const CommonVariantStyle = css`
  display: flex;
  align-items: center;

  /* Apply underline decoration just for text */
  :hover {
    text-decoration: none !important;
  }

  :hover > ${LinkText} {
    text-decoration: underline !important;
  }
`

/* Styled Variants */
export const ExplainerLink = styled(Link)`
  ${props => props.padding && `padding:${props.padding}`};
  ${props => props.margin && `margin:${props.margin}`};
  ${props => props.visibility && `visibility:${props.visibility}`};
  ${CommonVariantStyle}

  ${LinkIcon} {
    font-size: 18px;
  }

  ${LinkText} {
    margin-left: 5px;
    font-size: ${props => (props.fontSize ? props.fontSize : '12px')};
    font-style: ${props => (props.fontStyle ? props.fontStyle : 'italic')};;
    font-weight: normal;
  }
`

export const FilledExplainerLink = styled.a`
  text-decoration: none !important;
  /* TODO: is the props condition needed? (if we don't display the explainer) */
  width: ${props => (props.pdf ? '12px' : '16px')};
  height: ${props => (props.pdf ? '12px' : '16px')};
  background-color: ${BORDER_COLOR_PRIMARY};
  border-radius: 100px;
  border: none;
  cursor: pointer;

  align-items: center;
  justify-content: center;
  display: flex;

  color: white;
  font-size: ${props => (props.pdf ? '6px' : '10px')};

  transition: transform 0.2s;
  :hover {
    color: white;
    text-decoration: none !important;
    background-color: ${APP_COLOR_PRIMARY};

    transform: scale(1.2);
  }
`
