import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import ReactGA from 'react-ga'

import FilterTableButton from '../../../../../../yb_components/styleguide/buttons/filter_table_button/FilterTableButton'
import { makeSingleValue } from '../../../../../research_center/helpers/filter-helpers'
import { countFilters } from '../../helpers'
import FilterTooltipWrapper from '../FilterTooltipWrapper'
import PropertiesTooltip from './PropertiesTooltip'

const PropertiesFilter = ({ defaultData, options, onUpdate }) => {
  /* State */
  const [isOpen, setIsOpen] = useState(false)
  const [curData, setCurData] = useState({})
  const [sectorSelected, setSectorSelected] = useState(false)
  const [industryOptions, setIndustryOptions] = useState(
    options.trbcIndustryGroup
  )

  useEffect(() => {
    setCurData({ ...defaultData })

    if (defaultData?.business_sector?.length > 0) setSectorSelected(true)
    else setSectorSelected(false)
  }, [defaultData])

  const filterCount = useMemo(() => {
    return countFilters(curData)
  }, [curData])

  const filterLabel = useMemo(() => {
    if (filterCount) {
      return filterCount > 1
        ? `${filterCount} Properties`
        : `${filterCount} Property`
    } else return 'Properties'
  }, [filterCount])

  /* Events */
  const onChange = (filterType, value) => {
    const updatedCurData = { ...curData, [filterType]: value }
    setCurData(updatedCurData)
  }

  const filterIndustry = val => {
    if (val === null) val = []
    const newIndustries = _.first(val)
      ? options?.trbcIndustryGroup.filter(i => i.bus_sec == _.first(val).id)
      : []
    setIndustryOptions(newIndustries)
  }

  const selectChangeBusiness = (type, val) => {
    if (val === null) {
      val = []
      setIndustryOptions([])
      setSectorSelected(false)
      setCurData({ ...curData, industry_group: [], business_sector: [] })
    } else {
      setSectorSelected(true)
      val = makeSingleValue(val, type, curData)
      filterIndustry(val)
      let newFilters = { ...curData }
      newFilters[type] = val
      setCurData({ ...newFilters })
    }
  }

  const onCancel = () => {
    ReactGA.event({
      category: 'Portfolio Holdings Table',
      action: 'Close Modal',
      label: `Properties`,
    })
    setCurData(defaultData)
    setIsOpen(false)
  }

  const onUpdateLocal = () => {
    onUpdate?.(curData, 'properties')
    setIsOpen(false)
  }

  /* Render */
  const Tooltip = (
    <PropertiesTooltip
      data={curData}
      options={options}
      onChange={onChange}
      onCancel={onCancel}
      onUpdate={onUpdateLocal}
      sectorSelected={sectorSelected}
      selectChangeBusiness={selectChangeBusiness}
      industryOptions={industryOptions}
    />
  )

  return (
    <FilterTooltipWrapper
      onClickOutside={onCancel}
      displayTooltip={isOpen}
      tooltip={Tooltip}
    >
      <FilterTableButton
        label={filterLabel}
        active={filterCount && !isOpen}
        onClick={() => setIsOpen(prev => !prev)}
      />
    </FilterTooltipWrapper>
  )
}

export default PropertiesFilter
