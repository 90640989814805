import React from 'react'
import Select from 'react-select-3'

import Card from '../../../../../../components/styleguide/Card'
import SectionTitle from '../../../../../../components/styleguide/SectionTitle'
import SwitchButton from '../../../../../../components/styleguide/SwitchButton'
import Tooltip from '../../../../../../components/styleguide/Tooltip'
import FilledButton from '../../../../../../yb_components/styleguide/buttons/filled_button/FilledButton'
import {
  industryStyles,
  marketCapStyles,
} from '../../../../../research_center/styles/select-styles'
import {
  SelectLabel,
  SelectLabelWrapper,
  SelectWrapper,
  SelectWrapperInner,
} from '../../../../../research_center/styles/styles'
import {
  FilterTypes,
  HOLDINGS_FILTERS_COPY,
  POSITION_SWITCH_OPTIONS,
} from '../../PortfolioHoldingsCard.constants'

const PropertiesTooltip = ({
  data,
  options,
  onChange,
  onCancel,
  onUpdate,
  sectorSelected,
  selectChangeBusiness,
  industryOptions,
}) => {
  return (
    <Tooltip hideArrow noTransform inheritVisibility x='-230'>
      <Card width='720px' height='fit-content'>
        <SectionTitle
          title={HOLDINGS_FILTERS_COPY.properties.title}
          className='margin-left margin-right filter-title'
        />
        <p className='margin-left margin-right filter-description'>
          {HOLDINGS_FILTERS_COPY.properties.description}
        </p>
        <div className='margin-left margin-right'>
          <SelectLabel>POSITION TYPE</SelectLabel>
          <SwitchButton
            onClick={value => onChange(FilterTypes.POSITION_TYPE, value)}
            active={data?.position_type}
            options={POSITION_SWITCH_OPTIONS}
          />
        </div>
        <SelectWrapper>
          <SelectWrapperInner>
            <SelectLabelWrapper>
              <SelectLabel>MARKET CAP</SelectLabel>
              <Select
                placeholder={'All (select to filter)'}
                isMulti
                value={data?.market_cap}
                menuShouldScrollIntoView={false}
                styles={marketCapStyles}
                options={options?.marketCap}
                isSearchable={false}
                onChange={val => onChange(FilterTypes.MARKET_CAP, val)}
              />
            </SelectLabelWrapper>
            <SelectLabelWrapper>
              <SelectLabel>REVENUE</SelectLabel>
              <Select
                placeholder={'All (select to filter)'}
                isMulti
                value={data?.revenue}
                menuShouldScrollIntoView={false}
                styles={marketCapStyles}
                options={options?.revenues}
                isSearchable={false}
                onChange={val => onChange(FilterTypes.REVENUE, val)}
              />
            </SelectLabelWrapper>
          </SelectWrapperInner>
          <SelectWrapperInner>
            <SelectLabelWrapper>
              <SelectLabel>SECTOR</SelectLabel>
              <Select
                isMulti
                placeholder={'All (select to filter)'}
                value={data?.business_sector}
                menuShouldScrollIntoView={false}
                styles={marketCapStyles}
                options={options?.trbcBusinessSector}
                isSearchable={false}
                onChange={val =>
                  selectChangeBusiness(FilterTypes.BUSINESS_SECTOR, val)
                }
              />
            </SelectLabelWrapper>
            <SelectLabelWrapper>
              <SelectLabel>INDUSTRY GROUP</SelectLabel>
              <Select
                isMulti
                placeholder={
                  sectorSelected
                    ? 'Select to filter'
                    : 'Please select a sector first'
                }
                value={data?.industry_group}
                menuShouldScrollIntoView={false}
                styles={industryStyles}
                options={industryOptions}
                isSearchable={false}
                isDisabled={!sectorSelected}
                onChange={val => onChange(FilterTypes.INDUSTRY_GROUP, val)}
              />
            </SelectLabelWrapper>
          </SelectWrapperInner>
          <SelectWrapperInner>
            <SelectLabelWrapper>
              <SelectLabel>COUNTRY OF HEADQUARTERS</SelectLabel>
              <Select
                placeholder={'All (select to filter)'}
                isMulti
                value={data?.country_of_hq}
                menuShouldScrollIntoView={false}
                styles={marketCapStyles}
                options={options?.countries}
                isSearchable={false}
                onChange={val => onChange(FilterTypes.COUNTRY_OF_HQ, val)}
              />
            </SelectLabelWrapper>
          </SelectWrapperInner>
        </SelectWrapper>
        <div className='form-buttons-wrapper margin-right'>
          <div className='unstyled-button cancel-button'>
            <button onClick={() => onCancel('properties')}>Cancel</button>
          </div>
          <FilledButton
            onClick={() => {
              onUpdate('properties')
            }}
            label='UPDATE'
            borderRadio={'25px'}
            width='150px'
          />
        </div>
      </Card>
    </Tooltip>
  )
}

export default PropertiesTooltip
