import { isNumeral } from 'numeral'
import PropTypes from 'prop-types'
import React from 'react'

import { MainTable } from '../../components/tables/styles'
import { esgScoreFullNames } from '../../constants'
import { abbreviateNumber } from '../../helpers/number'
import { displayValueOrPlaceholder } from '../../helpers/shared'
import {
  dataMapping,
  emptyScorePlaceholder,
} from '../../modules/instrument/components/peers/constants'
import PeersHeaderRow from '../../modules/instrument/components/peers/PeersHeaderRow'
import PeersScoreTableRowPDF from './PeersScoreTableRowPDF'

const metricRank = (
  entity,
  dataProvider,
  dataDisplayType,
  metricType,
  index
) => {
  return parseFloat(
    parseFloat(
      entity[dataTypeMap(dataProvider, dataDisplayType)]?.[metricType]?.[index]
        ?.value
    ).toFixed(2)
  )
}

const dataTypeMap = (dataProvider, dataDisplayType) => {
  return dataMapping(dataProvider)[dataDisplayType]
}

const PeersESGMetric = ({
  peers,
  metricType,
  dataDisplayType = 'zscore',
  dataProvider = 'ideal-ratings',
}) => {
  const metrics =
    peers[0]?.[dataTypeMap(dataProvider, dataDisplayType)]?.[metricType]

  const retrieveTopRowScore = entity => {
    return displayValueOrPlaceholder(
      entity.esg_score[`${metricType}_score`],
      true
    )
  }

  const retrieveScore = index => entity => {
    let metricScore = metricRank(
      entity,
      dataProvider,
      dataDisplayType,
      metricType,
      index
    )
    return displayValueOrPlaceholder(metricScore)
  }

  const retrieveScoreRange = index => entity => {
    let metricScoreRange =
      entity?.[dataTypeMap(dataProvider, dataDisplayType)]?.[metricType]?.[
        index
      ]?.value_range

    return metricScoreRange
  }

  const scoreFormatter = value => {
    if (value == emptyScorePlaceholder) return value
    return isNumeral(value) ? Math.round(value) : abbreviateNumber(value)
  }

  return (
    <div>
      <h2>Peer Analysis - {esgScoreFullNames[metricType]}</h2>
      <MainTable look={{ tdPadding: '10px' }}>
        <PeersHeaderRow isPdf peers={peers} />
        <tbody>
          <PeersScoreTableRowPDF
            scoreName={`${esgScoreFullNames[metricType]} Score`}
            retrieveScore={retrieveTopRowScore}
            peers={peers}
          />
          {metrics?.map((metric, index) => (
            <PeersScoreTableRowPDF
              key={`table-row-metric-table-${index}`}
              dataDisplayType={dataDisplayType}
              scoreName={metric.name}
              showScorePercentage={false}
              scoreFormatter={scoreFormatter}
              normUnits={metric.units}
              normUnitsShort={metric.units_short}
              retrieveScore={retrieveScore(index)}
              retrieveScoreRange={retrieveScoreRange(index)}
              peers={peers}
            />
          ))}
        </tbody>
      </MainTable>
    </div>
  )
}

PeersESGMetric.propTypes = {
  peers: PropTypes.array.isRequired,
  metricType: PropTypes.string.isRequired,
  dataDisplayType: PropTypes.string.isRequired,
  dataProvider: PropTypes.string.isRequired,
}

export default PeersESGMetric
