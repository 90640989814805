import { SubsidyLevelColor } from '../../../styles/colors'

export const SUBSIDIES_STATS_QUERY =
  '?over_time_format=array&total_state_format=properties&include=filter_values'

export const SUBSIDY_LEVEL_FEDERAL_LABEL = 'Federal'
export const SUBSIDY_LEVEL_STATE_LABEL = 'State'
export const SUBSIDY_LEVEL_LOCAL_LABEL = 'Local'
export const SUBSIDY_LEVEL_MULTIPLE_LABEL = 'Multiple'
export const SUBSIDY_LEVEL_TOTAL_LABEL = 'Total'

export const SubsidyLevel = {
  FEDERAL: 'federal',
  STATE: 'state',
  LOCAL: 'local',
  MULTIPLE: 'multiple',
}

/* OVER TIME CARD */

export const HEADER_TITLE = 'Corporate Subsidies & Loans'

export const HEADER_DESCRIPTION =
  'Utilizing data from Good Jobs First, the Corporate Subsidies & Loans report examines the how much money corporations \
  are getting from over 1,000 taxpayer-funded assistance programs across federal, state and local governments. Data is \
  supplied by Good Jobs First and is updated on a quarterly basis.'

export const OVER_TIME_CARD_TITLE = 'Subsidies & Loans over time'

export const OVER_TIME_CARD_DESCRIPTION =
  'Subsidies & Loans Over Time examines the categorization of subsidies and loans into three government levels (federal, \
  state, local) across the past 10 years of data. The larger numbers to the right indicate the total amount of subsidies \
  and loans across all 10 years.'

export const OVER_TIME_LEGEND_ORDER = [
  SubsidyLevel.FEDERAL,
  SubsidyLevel.STATE,
  SubsidyLevel.LOCAL,
]
export const OVER_TIME_GRAPH_COLORS = [
  SubsidyLevelColor.FEDERAL,
  SubsidyLevelColor.STATE,
  SubsidyLevelColor.LOCAL,
  SubsidyLevelColor.MULTIPLE,
]

export const OVER_TIME_TABLE_ROW_HEADERS = [
  {
    /* Federal */
    label: SUBSIDY_LEVEL_FEDERAL_LABEL,
    key: SubsidyLevel.FEDERAL,
    dotted: true,
    color: {
      dot: SubsidyLevelColor.FEDERAL,
    },
  },
  {
    /* State */
    label: SUBSIDY_LEVEL_STATE_LABEL,
    key: SubsidyLevel.STATE,
    dotted: true,
    color: {
      dot: SubsidyLevelColor.STATE,
    },
  },
  {
    /* Local */
    label: SUBSIDY_LEVEL_LOCAL_LABEL,
    key: SubsidyLevel.LOCAL,
    dotted: true,
    color: {
      dot: SubsidyLevelColor.LOCAL,
    },
  },
  {
    /* Local */
    label: SUBSIDY_LEVEL_MULTIPLE_LABEL,
    key: SubsidyLevel.MULTIPLE,
    dotted: true,
    color: {
      dot: SubsidyLevelColor.MULTIPLE,
    },
  },
  {
    /* Total */
    label: SUBSIDY_LEVEL_TOTAL_LABEL,
    key: 'total',
    omitFromChart: true,
  },
]

/* PIE/MAP CARDS */

export const SUBSIDIES_BUCKET_DATA = [
  { label: 'No Subsidies', dotColor: '#F3F6FE', upperBound: 0 },
  { label: '0 - $50M', dotColor: '#CCD5ED', lowerBound: 0, upperBound: 50 },
  {
    label: '$50M - $100M',
    dotColor: '#99ACDC',
    lowerBound: 50,
    upperBound: 100,
  },
  {
    label: '$100M - $250M',
    dotColor: '#6682CA',
    lowerBound: 100,
    upperBound: 250,
  },
  {
    label: '$250M - $500M',
    dotColor: '#3359B9',
    lowerBound: 250,
    upperBound: 500,
  },
  { label: '$500M +', dotColor: '#002FA7', lowerBound: 500 },
]

/* RECORDS CARD */

export const RECORDS_TABLE_TITLE = 'Subsidy Records'

export const RECORDS_TABLE_DESCRIPTION =
  'Corporate Subsidy & Loan data collected by Good Jobs First is available for deeper exploration and filtering by different categorical indices.'
