import { useEffect, useState } from 'react'

class DisclosureFiltersController {
  useCompileYearOptions = (): YB.IOption[] => {
    const [yearOptions, setYearOptions] = useState<YB.IOption[]>([])

    useEffect(() => {
      const currentYear = new Date().getFullYear()
      const minYear = currentYear - 3
      const maxYear = currentYear + 3
      let trackYear = minYear
      const yearOptions: YB.IOption[] = []

      yearOptions.push({
        label: 'All',
        value: 'all',
      })

      while (trackYear <= maxYear) {
        yearOptions.push({
          label: trackYear.toString(),
          value: trackYear.toString(),
        })
        trackYear++
      }

      setYearOptions(yearOptions)
    }, [])

    return yearOptions
  }
}

export default new DisclosureFiltersController()
