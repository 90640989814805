import styled from 'styled-components'

export const UserAgreementContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('/user_agreement/blurred-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top left;
`
