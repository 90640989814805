import React from 'react'
import styled, { css } from 'styled-components'

const ActiveStyle = css`
  transform: scaleX(1.05);
  filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4));
`

const CenterOriginStyle = css`
  transform-origin: center;
`

const StyledG = styled.g`
  .section:hover {
    cursor: pointer;
    ${ActiveStyle}
    ${CenterOriginStyle}
  }

  .section {
    transition: all 0.5s;
    ${CenterOriginStyle}
  }

  .section-active {
    ${ActiveStyle}
    ${CenterOriginStyle}

    text {
      font-size: 13px;
      font-weight: bold;
    }
  }

  .label {
    font-size: 12px;
    font-weight: 500;
    font-family: Lato;
    fill: white;
    pointer-events: none;
  }
`

export const BarComponent = ({
  x,
  y,
  width,
  height,
  color,
  data,
  showTooltip,
  hideTooltip,
  onClick,
  activeId,
  labelSkipHeight,
  tooltipComponent,
}) => {
  return (
    <StyledG
      onClick={() => onClick?.(data)}
      transform={`translate(${x}, ${y})`}
      onMouseMove={event => {
        showTooltip(tooltipComponent(data), event)
      }}
      onMouseLeave={() => {
        hideTooltip()
      }}
    >
      <g
        className={data?.id === activeId ? 'section section-active' : 'section'}
        width={width}
        height={height}
        rx='0'
        ry='0'
      >
        <rect
          width={width}
          height={height}
          rx='0'
          ry='0'
          fill={color}
          strokeWidth='0'
          textAnchor={'middle'}
        ></rect>
        {height > labelSkipHeight && (
          <text
            className='label'
            x={width / 2}
            y={height / 2}
            textAnchor='middle'
            dominantBaseline='central'
          >
            <tspan x='50%' dy='-0.5em'>
              {data?.id}
            </tspan>
            <tspan x='50%' dy='1.2em'>
              {data?.value}%
            </tspan>
          </text>
        )}
      </g>
    </StyledG>
  )
}

export default BarComponent
