import styled from 'styled-components'

import { BG_COLOR_WHITE } from '../../../../styles/colors'

export const StyledNotesList = styled.div`
  margin: 0 0 1.75rem 0;
  width: 100%;

  @media screen and (min-width: 992px) {
    margin: 0;
  }

  .notes-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    > li {
      padding: 10px;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      background-color: #f6f8fd;
      &:nth-of-type(even) {
        background-color: ${BG_COLOR_WHITE};
      }
    }
  }

  .user-section {
    width: 160px;
    min-width: 160px;
  }
  .media {
    .user-avatar {
      margin: 0 10px 0 0;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      overflow: hidden;
    }
    .user-avatar-placeholder {
      margin: 0 10px 0 0;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      overflow: hidden;
      background-color: #002fa7;
    }
    h6 {
      margin: 0 0 2px;
      font-size: 10px;
      line-height: 12px;
      font-weight: 400;
    }
    .date {
      margin: 0;
      display: block;
      font-size: 8px;
      line-height: 10px;
      color: #979797;
    }
  }
  .note-content {
    flex-grow: 1;
    color: #2b2928;
    font-size: 12px;
    line-height: 14.4px;
    p {
      margin-bottom: 12px;
    }
    p:last-child {
      margin: 0;
    }
  }

  .empty-alert {
    padding: 10px;
    border-radius: 5px;
    background-color: #f6f8fd;

    h5 {
      margin: 0 0 10px 0;
      font-size: 12px;
      line-height: 14.4px;
      font-weight: 700;
    }
    p {
      margin: 0 0 3px;
      font-size: 12px;
      line-height: 14.4px;
      color: #979797;
    }
  }
`
