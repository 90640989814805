import { Box } from '@ui/elements/grouping/Box'
import classnames from 'classnames'
import React from 'react'
import { Modal as BootsrapModal, ModalProps } from 'react-bootstrap'
// import theme from './Modal.module.scss'
import './Modal.module.scss'

export interface IProps extends ModalProps {
  title?: string
  dismissable?: boolean
  onHide(): void
}

export const Modal: React.FC<IProps> = ({
  title,
  onHide,
  children,
  className,
  dismissable = true,
  ...props
}) => {
  return (
    <BootsrapModal {...props} className={classnames('Modal', className)}>
      <header>
        <Box isPresent={!!title}>
          <h6>{title}</h6>
        </Box>
        <Box isPresent={dismissable}>
          <button onClick={onHide}>
            <i className='fal fa-times'></i>
          </button>
        </Box>
      </header>
      <div className='body'>{children}</div>
    </BootsrapModal>
  )
}
