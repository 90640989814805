import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Card from '../../../../components/styleguide/Card'
import CardTitle from '../../../../components/styleguide/CardTitle'
import ESGScale from '../../../../components/styleguide/ESGScale'
import LabelsForm from './components/LabelsForm'
import { adjustIntervals } from './helpers'
import { defaultLabels } from './ScoreDisplaySettings.constants'

const StyledCard = styled(Card)`
  grid-column: span 4;

  .scale-container {
    margin-bottom: 30px;
  }
`

const validateIntervals = (labels, setLabels) => {
  let dupLabels = [...labels]
  adjustIntervals(dupLabels, dupLabels[0])
  setLabels(dupLabels)
}

const ScoreDisplaySettings = ({ loadedLabels, dispatch }) => {
  const [labels, setLabels] = useState(loadedLabels || defaultLabels)

  useEffect(() => setLabels(loadedLabels || defaultLabels))
  return (
    <StyledCard onBlur={() => validateIntervals(labels, setLabels)}>
      <CardTitle title='SCORE DISPLAY SETTINGS' />
      <div className='scale-container'>
        <ESGScale labels={labels} />
      </div>
      <LabelsForm labels={labels} setLabels={setLabels} dispatch={dispatch} />
    </StyledCard>
  )
}

export default ScoreDisplaySettings
