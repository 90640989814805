export const DEFAULT_ESG_SCORE_EXPLAINER_URL =
  'https://help.yves.blue/en/articles/5855130'

export const PEERS_MODULE_EXPLAINER_URL =
  'https://help.yves.blue/en/articles/5855368'

/* Impact Assessment */
export const GENDER_GAP_EXPLAINER_URL =
  'https://help.yves.blue/en/articles/4721434-impact-assessment-explainer-gender-gap'

export const HAZARDOUS_WASTE_EXPLAINER_URL =
  'https://help.yves.blue/en/articles/2772689-impact-assessment-hazardous-waste'

export const CARBON_EXPLAINER_URL =
  'https://help.yves.blue/en/articles/4705871-impact-assessment-metrics-carbon'

export const WASTE_EXPLAINER_URL =
  'https://help.yves.blue/en/articles/4707884-impact-assessment-metrics-waste'

export const WASTER_USAGE_EXPLAINER_URL =
  'https://help.yves.blue/en/articles/4708296-impact-assessment-metrics-water'
