import styled from 'styled-components'

import { FONT_COLOR_SECONDARY } from '../../../../../styles/colors'

export const StyledHTML = styled.div`
  font-size: 12px;
  color: ${FONT_COLOR_SECONDARY};

  ol {
    padding: 0;
    margin: 5px 0 0 0;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    li {
      background: transparent !important;
      list-style-type: decimal;
      display: list-item;
      margin-left: 15px;
      padding: 0 0 5px 5px;
    }
  }
`
