import React from 'react'
import styled from 'styled-components'

import Link from '../../components/styleguide/Link'
import { addThousandSeparator } from '../../helpers/number'
import { APP_COLOR_PRIMARY, FONT_COLOR_SECONDARY } from '../../styles/colors'
import SubsidiesChart from './SubsidiesChart'

const StyledLegend = styled.div`
  display: inline-block;
  padding-left: 15px;
  .chart-legend {
    display: inline-block;
    margin-right: 0.63rem;
    height: 0.63rem;
    width: 0.63rem;
    border-radius: 50%;
    background-color: ${props => props.color || APP_COLOR_PRIMARY};
  }
  .chart-label {
    color: ${FONT_COLOR_SECONDARY};
  }
`

const StyledTitleContainer = styled.div`
  margin-top: 20px;
`

const StyledTitleTextContainer = styled.div`
  padding-left: ${props => props.paddingLeft || '0px'};
  display: table-cell;
  vertical-align: middle;
`

const SubsidiesChartContainer = ({
  title,
  additionalTitleLink,
  data,
  id,
  total,
  unit,
  benchmarkComparison,
  benchmarkData,
  color,
  benchmarkName,
}) => {
  const getTargetName = () => {
    if (window.location.href.includes('snapshot')) {
      return 'Snapshot'
    } else if (window.location.href.includes('instrument')) {
      return 'Instrument'
    }
  }

  return (
    <>
      <StyledTitleContainer>
        <StyledTitleTextContainer>
          <div className='graph-title'>{title}</div>
        </StyledTitleTextContainer>
        {additionalTitleLink && (
          <StyledTitleTextContainer paddingLeft={'7px'}>
            <Link
              styles={{
                fontSize: '12px',
                fontWeight: 'normal',
                verticalAlign: 'middle',
              }}
              href={additionalTitleLink.href}
              target='_blank'
            >
              {additionalTitleLink.label}
            </Link>
          </StyledTitleTextContainer>
        )}
      </StyledTitleContainer>

      <div className='labels-container'>
        <div className='larger-text'>
          {' '}
          {`$${(total | 0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}{' '}
        </div>
        <div className='font-size-14'> {unit} </div>
      </div>
      {!isNaN(benchmarkComparison) && (
        <div className='labels-container ml-5'>
          <div
            className={`larger-text ${
              benchmarkComparison > 0 ? 'red' : 'green'
            }`}
          >
            {benchmarkComparison > 0 ? '+' : ''}
            {addThousandSeparator(benchmarkComparison)}%
          </div>
          <div className='font-size-14'>vs. Benchmark</div>
        </div>
      )}
      <SubsidiesChart
        benchmarkData={benchmarkData}
        className='tracker-chart'
        id={id}
        data={data}
        color={color}
      />
      <StyledLegend color={color}>
        <div className='chart-legend' />
        <span className='chart-label'>{getTargetName()}</span>
      </StyledLegend>
      {benchmarkName && (
        <StyledLegend color={'#E7BB4C'}>
          <div className='chart-legend' />
          <span className='chart-label'>Benchmark ({benchmarkName})</span>
        </StyledLegend>
      )}
    </>
  )
}

export default SubsidiesChartContainer
