import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import styled from 'styled-components'

import { getPortfolios } from '../../repositories/portfolio_repository'
import H2 from '../../yb_components/styleguide/headings/H2'
import PortfolioList from './components/PortfolioList'

const PortfolioWrapper = styled.div`
  width: 100%;

  h2 {
    margin-bottom: 1.375rem;
  }
`

const skeletonComponents = []

for (let i = 0; i <= 2; i++) {
  skeletonComponents.push(<PortfolioList key={i} skeleton={true} />)
}

const Portfolios = () => {
  const [portfolios, setPortfolios] = useState([])
  const query = '?sort_field=created_at&sort_dir=desc'

  useEffect(() => {
    getPortfolios({ query }).then(response => {
      setPortfolios(response.data.portfolios)
    })
  }, [])

  useEffect(() => {
    const shortPoll = setInterval(() => {
      getPortfolios({ query }).then(response => {
        setPortfolios(response.data.portfolios)
      })
    }, 5000)
    const destroyTimer = () => {
      clearInterval(shortPoll)
    }
    return destroyTimer
  }, [])

  return (
    <PortfolioWrapper>
      <Row>
        <Col md={12}>
          <H2 className='page-header'>Your Portfolios</H2>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {portfolios?.length > 0
            ? portfolios.map(portfolio => (
                <PortfolioList key={portfolio.id} portfolio={portfolio} />
              ))
            : skeletonComponents}
        </Col>
      </Row>
    </PortfolioWrapper>
  )
}

export default Portfolios
