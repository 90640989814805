import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import ReleaseNotesAndUpdates from '../../yb_modules/release_notes/release_notes_and_updates/ReleaseNotesAndUpdates'
import SustainableGPTUI from '../../yb_modules/sustainable_gpt_ui/SustainableGPTUI'
import DashboardFunds from '../managers/funds/DashboardFunds'
import Portfolios from '../portfolio/Portfolios'

const Dashboard = () => {
  /* global react_app_organization */
  /*eslint no-undef: "error"*/
  const hasManagerDBAccess =
    react_app_organization?.organization?.can_access_manager_db

  const hasSustainableGPTAccess =
    react_app_organization?.organization?.can_access_sustainable_gpt

  return (
    <div style={{ paddingTop: '20px' }} className='page__container'>
      <Row>
        <Col md={7}>
          <Portfolios />
        </Col>

        <Col md={5}>
          {hasSustainableGPTAccess && <SustainableGPTUI />}
          <ReleaseNotesAndUpdates />
          {hasManagerDBAccess && <DashboardFunds />}
        </Col>
      </Row>
    </div>
  )
}

export default Dashboard
