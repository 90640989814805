import React from 'react';

const CircularProgress = ({ children, size, stroke, progress, color }) => {
  progress = parseInt(progress);
  const bgImage = progress < 50 ?
  `linear-gradient(${90 + 360 * progress / 100}deg, transparent 50%, lightgrey 50%), linear-gradient(90deg, lightgrey 50%, transparent 50%)` :
  `linear-gradient(${90 + 360 * (progress - 50) / 100}deg, transparent 50%, ${color} 50%), linear-gradient(90deg, lightgrey 50%, transparent 50%)`;

  const outerStyle = {
    backgroundColor: color,
    backgroundImage: bgImage, 
    height: size,
    width: size,
  };

  const innerStyle = {
    height: size - stroke,
    width: size - stroke
  };

  return (
    <div style={outerStyle} className="circular-progress">
      <div style={innerStyle} className="circular-progress-center">
        {children}
      </div>
    </div>
  );
}

export default CircularProgress;