import React from 'react'

const PDFDownloadedEvent = ({ event }) => {
  let targetURL
  if (event.targetType === 'Portfolio::Snapshot') {
    targetURL = `/app/portfolios/${event.data.portfolio_id}/snapshots/${event.data.snapshot_id}`
  } else if (event.targetType === 'Instrument') {
    targetURL = `/app/instruments/${event.targetID}`
  }

  return (
    <>
      <span className='event-user-name'>{event.context.full_name}</span>{' '}
      downloaded a PDF for{' '}
      {event.targetExists ? (
        <a href={targetURL}>{event.data.name}</a>
      ) : (
        event.data.name
      )}
      .<span className='event-date'>{event.formattedTimestamp}</span>
    </>
  )
}

export default PDFDownloadedEvent
