import React, { useEffect, useMemo, useState } from 'react'
import ReactGA from 'react-ga'

import FilterTableButton from '../../../../../../yb_components/styleguide/buttons/filter_table_button/FilterTableButton'
import FilterTooltipWrapper from '../FilterTooltipWrapper'
import AssetTypeTooltip from './AssetTypeTooltip'

const AssetTypeFilter = ({ defaultData, options, onUpdate }) => {
  /* State */
  const [isOpen, setIsOpen] = useState(false)
  const [curData, setCurData] = useState({})

  useEffect(() => {
    setCurData({ ...defaultData })
  }, [defaultData])

  const filterCount = useMemo(() => {
    return Object.keys(curData).length
  }, [curData])

  const filterLabel = useMemo(() => {
    if (filterCount) {
      return filterCount > 1
        ? `${filterCount} Holding Types`
        : `${filterCount} Holding Type`
    } else return 'Asset Type'
  }, [filterCount])

  /* Events */
  const onChange = id => {
    if (curData?.[id]) delete curData[id]
    else curData[id] = true
    setCurData({ ...curData })
  }

  const onCancel = () => {
    ReactGA.event({
      category: 'Portfolio Holdings Table',
      action: 'Close Modal',
      label: `Asset Type`,
    })
    setCurData({ ...defaultData })
    setIsOpen(false)
  }

  const onUpdateLocal = () => {
    onUpdate?.({ asset_type: curData }, 'asset_type')
    setIsOpen(false)
  }

  /* Render */
  const Tooltip = (
    <AssetTypeTooltip
      data={curData || {}}
      options={options}
      onChange={onChange}
      onCancel={onCancel}
      onUpdate={onUpdateLocal}
    />
  )

  return (
    <FilterTooltipWrapper
      onClickOutside={onCancel}
      displayTooltip={isOpen}
      tooltip={Tooltip}
    >
      <FilterTableButton
        label={filterLabel}
        active={filterCount && !isOpen}
        onClick={() => setIsOpen(prev => !prev)}
      />
    </FilterTooltipWrapper>
  )
}

export default AssetTypeFilter
