import styled from "styled-components";
import { APP_COLOR_PRIMARY, FONT_COLOR_SECONDARY } from '../../../styles/colors';

/* Big Label */
export const BigLabel = styled.div`
  font-size: ${props => props.fontSize || '22px'};
  font-weight: bold;
  color: ${props => props.color || APP_COLOR_PRIMARY};
`;

/* Map Label */
export const MapDot = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.3rem;
  height: ${props => props.size || '40px'};
  width: ${props => props.size || '23px'};
  background-color: ${props => props.color || APP_COLOR_PRIMARY};
`;

/* Dotted Label */
export const DottedLabelContainer = styled.div`
  display: inline-block;
`

export const Dot = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.3rem;
  height: ${props => props.size || '0.63rem'};
  width: ${props => props.size || '0.63rem'};
  border-radius: 100%;
  background-color: ${props => props.color || APP_COLOR_PRIMARY};
`;

export const DotLabel = styled.span`
  font-size: ${props => props.fontSize || '17px'};
  color: ${props => props.color || FONT_COLOR_SECONDARY};
  font-weight: ${props => props.fontWeight || 'normal'};
`;