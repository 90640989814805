import React from 'react'

import SDGIcon from '../../../../components/styleguide/SDGIcon'
import { MainTable } from '../../../../components/tables/styles'
import { SDGDescriptions } from '../../../../constants'
import { emptyScorePlaceholder } from './constants'
import PeersHeaderRow from './PeersHeaderRow'
import ScoreTableRow from './ScoreTableRow'

const sdgScore = (entity, sdgIndex) => {
  const sdgValue = entity.sdg_exposure[`sdg_${sdgIndex}`]
  return sdgValue && !isNaN(sdgValue)
    ? Math.round(sdgValue * 100)
    : emptyScorePlaceholder
}

const PeersSDGAlignment = ({ peers, openReplacePeerModal }) => {
  const sdgIcon = sdg => (
    <>
      <div>
        <SDGIcon sdg={sdg} height={50} width={50} />
      </div>
      <div className='sdg-name'>{SDGDescriptions[sdg].shortName}</div>
    </>
  )

  const retrieveScore = sdg => entity => {
    return sdgScore(entity, sdg)
  }

  return (
    <MainTable look={{}}>
      <PeersHeaderRow
        peers={peers}
        openReplacePeerModal={openReplacePeerModal}
      />
      <tbody>
        {Object.keys(SDGDescriptions).map(sdg => (
          <ScoreTableRow
            key={`table-row-${sdg}`}
            extraClassNames={['sdg-column']}
            neutralIsZero={true}
            scoreName={sdgIcon(sdg)}
            retrieveScore={retrieveScore(sdg)}
            peers={peers}
          />
        ))}
      </tbody>
    </MainTable>
  )
}

export default PeersSDGAlignment
