import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { useMemo } from 'react'

import { hasESGs } from '../../modules/helpers/ESGMetricsHelper'
import { TcfdPortfolioScoreCard } from '../../modules/portfolio/tcfd/components/tcfd_portfolio_score/TcfdPortfolioCard'
import { useCarbonAuditTable } from '../../yb_components/cards/carbon_audit_card/hooks/useCarbonAuditTable'
import { TargetType } from '../../yb_constants/enums/global_enums'
import { OWNERSHIP_PERCENTAGE_THRESHOLD } from '../../yb_constants/target'
import { splitArrayInGroups } from '../../yb_helpers/arrays'
import BadgesPDF from '../components/BadgesPDF'
import SDGExposureCardPDF from '../components/charts/SDGExposureCardPDF'
import Co2ProjectionsCardPdf from '../components/Co2ProjectionsCardPDF'
import Footer from '../components/Footer'
import Header from '../components/Header'
import AssetAllocation from '../components/sections/AssetAllocation'
import ESGScore from '../components/sections/ESGScorePortfolio'
import HoldingsTable from '../components/sections/HoldingsTable'
import ImpactAssessment, {
  isValidImpact,
} from '../components/sections/ImpactAssessment'
import PortfolioDisclosure from '../components/sections/PortfolioDisclosure'
import PortfolioHeading from '../components/sections/PortfolioHeading'
import SubsidiesViolationsSectionPDF from '../components/sections/SubsidiesViolationsSectionPDF'
import TopHoldingsSDGExposuresTable from '../components/sections/TopHoldingsSDGExposuresTable'
import TVLTargetCardPDF from '../components/sections/TVLTargetCardPDF'
import { PDF } from '../pdf'

dayjs.extend(utc)

/* global react_app_organization */
/*eslint no-undef: "error"*/

const PortfolioTemplate = ({
  portfolio,
  impactBenchmark,
  badges,
  top10ModeledHoldings: top10ModeledHoldings,
  version,
  co2Projections,
  carbonAuditData,
  tcfdScores,
  tcfdUrl,
}) => {
  let asOf

  if (portfolio?.as_of) {
    asOf = dayjs(portfolio?.as_of).utc()
  }

  const positive_badges_chunks = useMemo(
    () => splitArrayInGroups(badges?.positive, 6),
    [badges]
  )
  const negative_badges_chunks = useMemo(
    () => splitArrayInGroups(badges?.negative, 6),
    [badges]
  )

  const asset_allocations_chunks = useMemo(
    () => splitArrayInGroups(portfolio?.asset_classes?.allocations, 12),
    [portfolio?.asset_classes?.allocations]
  )

  const { first_asset_allocations_chunk, rest_asset_allocations_arr } =
    useMemo(() => {
      const first_asset_allocations_chunk = asset_allocations_chunks?.shift()
      const rest_asset_allocations_arr = asset_allocations_chunks?.flat()
      return {
        first_asset_allocations_chunk,
        rest_asset_allocations_arr,
      }
    }, [asset_allocations_chunks])

  const cachedColors = JSON.parse(
    localStorage.getItem(`carbon_audit_colors_${carbonAuditData?.groupBy}`)
  )

  const { tableHeaders, tableBody, tableFooter } = useCarbonAuditTable({
    selectedChart: carbonAuditData?.groupBy,
    colors: cachedColors,
    data: carbonAuditData,
    targetName: 'Portfolio',
    includeBenchmark: carbonAuditData?.includeBenchmark,
  })

  return (
    <div className='document'>
      <Header date={asOf} />
      <Footer />

      <div className='pdf-content'>
        <PortfolioHeading
          name={portfolio?.name}
          benchmark={impactBenchmark?.name}
          sdgs={portfolio?.sdgs}
        />

        <div
          style={{ maxHeight: '400px' }}
          className={
            portfolio?.asset_classes.allocations.length > 7
              ? 'portfolio-asset-wrapper-long'
              : 'portfolio-asset-wrapper'
          }
        >
          <AssetAllocation
            totalWeight={portfolio?.asset_classes?.totals?.weight}
            totalValue={portfolio?.asset_classes?.totals?.value}
            assets={first_asset_allocations_chunk}
            modeledWeight={portfolio?.modeled_weight}
            unmodeledWeight={portfolio?.unmodeled_weight}
            cashWeight={portfolio?.cash_weight}
          />

          {portfolio?.esg_scores && hasESGs(portfolio?.esg_scores) && (
            <ESGScore
              customESGLabels={portfolio?.custom_esg_labels}
              benchmarkScores={impactBenchmark?.esg_score}
              benchmarkName={impactBenchmark?.name}
              scores={portfolio?.esg_scores?.overall}
              shortsScores={portfolio?.esg_scores?.shorts}
              longsScores={portfolio?.esg_scores?.longs}
            />
          )}

          <AssetAllocation
            totalWeight={portfolio?.asset_classes?.totals?.weight}
            totalValue={portfolio?.asset_classes?.totals?.value}
            assets={rest_asset_allocations_arr}
            modeledWeight={portfolio?.modeled_weight}
            unmodeledWeight={portfolio?.unmodeled_weight}
            cashWeight={portfolio?.cash_weight}
          />
        </div>
        {tcfdScores &&
          react_app_organization.organization.can_access_carbon_report && (
            <div style={{ pageBreakBefore: 'always' }}>
              <TcfdPortfolioScoreCard
                scores={tcfdScores}
                tcfdLink={tcfdUrl}
                pdfStyle={true}
                portfolio={true}
              />
            </div>
          )}
        {version == 'full' &&
          portfolio &&
          portfolio?.tvl_insight_scores &&
          portfolio?.tvl_insight_scores.length > 0 && (
            <>
              <div style={{ pageBreakBefore: 'always' }}></div>
              <div className='portfolio-tvl-wrapper'>
                <TVLTargetCardPDF
                  target={portfolio}
                  data={portfolio?.tvl_insight_scores}
                  benchmarkName={impactBenchmark?.name}
                  benchmarkData={impactBenchmark?.tvl_insight_scores}
                  description={
                    "These ESG-like scores utilize Truvalue Labs long-term momentum scores to \
                    measure ESG performance over time. These momentum scores are based on \
                    daily tracking scores measuring sentiment analysis for daily news \
                    controversies and reports on a company level. The momentum scores are \
                    then aggregated up based on the portfolio's holdings into a portfolio \
                    level indicator."
                  }
                />
              </div>
            </>
          )}

        {version == 'full' && (
          <>
            <div style={{ pageBreakBefore: 'always' }}></div>
            <h2>Carbon Audit</h2>
            <PDF.CarbonAudit
              targetName={'Portfolio'}
              colors={cachedColors}
              chartData={carbonAuditData?.chartFormat}
              tableData={{
                headers: tableHeaders,
                body: tableBody,
                footer: tableFooter,
              }}
              switchSelections={{
                chart: carbonAuditData?.groupBy,
                degree: carbonAuditData?.degree,
                budget: carbonAuditData?.budget,
                calculationMethod: carbonAuditData?.calculationMethod,
              }}
            />
          </>
        )}

        {portfolio?.impacts &&
          (isValidImpact(
            portfolio?.impacts?.trees_killed,
            OWNERSHIP_PERCENTAGE_THRESHOLD
          ) ||
            isValidImpact(
              portfolio?.impacts?.pay_gap,
              OWNERSHIP_PERCENTAGE_THRESHOLD
            ) ||
            isValidImpact(
              portfolio?.impacts?.waste,
              OWNERSHIP_PERCENTAGE_THRESHOLD
            ) ||
            isValidImpact(
              portfolio?.impacts?.water_usage,
              OWNERSHIP_PERCENTAGE_THRESHOLD
            ) ||
            isValidImpact(
              portfolio?.impacts?.hazardous_waste,
              OWNERSHIP_PERCENTAGE_THRESHOLD
            )) && (
            <>
              <div style={{ pageBreakBefore: 'always' }}></div>
              <ImpactAssessment
                targetType={TargetType.PORTFOLIO_SNAPSHOT}
                impacts={portfolio?.impacts}
                name={portfolio?.name}
              />
            </>
          )}

        {/* Positive Impact Badges */}
        {positive_badges_chunks.map((positive_badges, index) => (
          <>
            <div style={{ pageBreakBefore: 'always' }}></div>
            <BadgesPDF
              key={index}
              title={'POSITIVE IMPACT BADGES'}
              badges={positive_badges}
              holdingsTotal={portfolio?.holdings_total}
            />
          </>
        ))}

        {/* Negative Impact Badges */}
        {negative_badges_chunks.map((negative_badges, index) => (
          <>
            <div style={{ pageBreakBefore: 'always' }}></div>
            <BadgesPDF
              key={index}
              title={'NEGATIVE IMPACT BADGES'}
              badges={negative_badges}
              holdingsTotal={portfolio?.holdings_total}
            />
          </>
        ))}

        {version == 'short' && (
          <>
            {(positive_badges_chunks?.length > 0 ||
              negative_badges_chunks?.length > 0) && (
              <div style={{ pageBreakBefore: 'always' }}></div>
            )}
            <h2>Carbon Audit</h2>
            <PDF.CarbonAudit
              colors={cachedColors}
              chartData={carbonAuditData?.chartFormat}
              tableData={{
                headers: tableHeaders,
                body: tableBody,
                footer: tableFooter,
              }}
              switchSelections={{
                chart: carbonAuditData?.groupBy,
                degree: carbonAuditData?.degree,
                budget: carbonAuditData?.budget,
                calculationMethod: carbonAuditData?.calculationMethod,
              }}
            />
          </>
        )}

        {version == 'full' && <div style={{ pageBreakBefore: 'always' }}></div>}

        {version == 'full' &&
          portfolio?.sdg_exposures &&
          Object.keys(portfolio?.sdg_exposures).length > 0 && (
            <div>
              <SDGExposureCardPDF
                title='SDG Exposure: Portfolio'
                data={portfolio?.sdg_exposures}
                benchmarkData={impactBenchmark?.sdg_exposures}
                benchmarkName={impactBenchmark?.name}
                width={60}
              />
            </div>
          )}

        {co2Projections?.projections?.length > 1 && (
          <>
            <div style={{ pageBreakBefore: 'always' }}></div>
            <Co2ProjectionsCardPdf projections={co2Projections} />
          </>
        )}

        {version == 'full' && (
          <>
            {top10ModeledHoldings && (
              <TopHoldingsSDGExposuresTable holdings={top10ModeledHoldings} />
            )}
            {portfolio?.subsidies && portfolio?.violations && (
              <SubsidiesViolationsSectionPDF
                subsidies={portfolio?.subsidies}
                benchmark_subsidies={impactBenchmark?.subsidies}
                violations={portfolio?.violations}
                benchmark_violations={impactBenchmark?.violations}
                benchmarkName={impactBenchmark?.name}
                targetType='Snapshot'
              />
            )}
          </>
        )}

        {/* {
                version == 'full' && hasESGYearlyMetrics(ESGYearlyMetrics) && (
                <>
                    <ESGMetricsTable isPDF={true} ESGYearlyMetrics={ESGYearlyMetrics} metricType={"e"} />

              <ESGMetricsTable isPDF={true} ESGYearlyMetrics={ESGYearlyMetrics} metricType={"s"} />

                    <ESGMetricsTable isPDF={true} ESGYearlyMetrics={ESGYearlyMetrics} metricType={"g"} />
                </>
            )
        } */}

        <div style={{ pageBreakBefore: 'always' }}></div>

        {top10ModeledHoldings && (
          <HoldingsTable holdings={top10ModeledHoldings} />
        )}
        <PortfolioDisclosure />
      </div>
    </div>
  )
}

export default PortfolioTemplate
