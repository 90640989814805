import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid'
import InstrumentCreateModal from "./InstrumentCreateModal";
import Button from '../../../../components/styleguide/Button';
import SectionWrapper from '../../../../components/styleguide/SectionWrapper';
import SectionTitle from '../../../../components/styleguide/SectionTitle';
import { MainTable } from '../../../../components/tables/styles';
import Paginator from '../../../../components/styleguide/Paginator';
import { StyledHoldingsEditTableCard, Icon} from '../../styles/edit/styles';
import TableBody from './TableBody';
import TextInput from '../../../../components/styleguide/TextInput';
import { addThousandSeparator } from '../../../../helpers/number';

const HoldingsEditTable = ({holdings, setHoldings, }) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [sortColumn, setSortColumn] = useState(null)
  const [sortDirection, setSortDirection] = useState('asc')
  const [instrumentModalOpen, setInstrumentModalOpen] = useState(false)
  const [createdInstrumentName, setCreatedInstrumentName] = useState('')

  const onNewHoldingClick = () => {
    if (!holdings.find((holding) => holding.instrument_name == null)) {
      const newHolding = { instrument_name: null, description: '', value: 0, key: uuidv4() }
      const newHoldings = holdings.slice()
      newHoldings.unshift(newHolding)
      setHoldings(newHoldings)
    }
  }

  const handleColumnClick = (columnName) => {
    let newDirection = "asc"

    if (sortColumn === columnName && sortDirection === "asc") {
      newDirection = "desc"
    }

    const sortedHoldings = holdings.sort((holding1, holding2) => {
      
      if (newDirection === "asc" && columnName !== "value") {
        return ('' + holding1[columnName]).localeCompare(holding2[columnName])
      } else if (columnName !== "value") {
        return ('' + holding2[columnName]).localeCompare(holding1[columnName])
      } else if (newDirection === "asc" && columnName === "value") {
        return parseFloat(holding1[columnName]) - parseFloat(holding2[columnName]) 
      } else if (columnName === "value") {
        return parseFloat(holding2[columnName]) - parseFloat(holding1[columnName])
      }
    })

    setHoldings(sortedHoldings)
    setSortDirection(newDirection)
    setSortColumn(columnName)
  }

  const onPaginatorChange = (page) => {
    setPageNumber(page)
  }

  const pageSize = 10
  const pageCount = Math.ceil(holdings.length / pageSize)

  const paginate = (holdings, pageSize, pageNumber) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return holdings.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
  };

  const paginatedHoldings = paginate(holdings, pageSize, pageNumber)

  let sortIcon = sortDirection === "asc" ?
    (<Icon className="fas fa-angle-up"></Icon>) :
    (<Icon className="fas fa-angle-down"></Icon>)

  const valueReducer = (accumulator, currentValue) => {
    return accumulator + parseFloat(currentValue.value || 0);
  }
  const totalValue = holdings.reduce(valueReducer, 0).toFixed(2)

  return (
    <>
      {createdInstrumentName && (
        <InstrumentCreateModal
          show={ instrumentModalOpen }
          instrumentName={ createdInstrumentName }
          holdings={holdings}
          setHoldings={setHoldings}
          setModalOpen={setInstrumentModalOpen}
          setCreatedInstrumentName={setCreatedInstrumentName}
        />
      )}

      <StyledHoldingsEditTableCard>
        <SectionWrapper justify="space-between" margin="10px 0px 15px 0px">
          <SectionTitle className="section-title"  title="Holdings"/>
          <Button width="20%" secondary onClick={ onNewHoldingClick } >
            <img src="/ico_add_holding.svg"/>
            ADD HOLDING
          </Button>
        </SectionWrapper>
        <SectionWrapper>
          <MainTable
            look={{textAlign:'left', tdPadding:'0px 0px 0px 10px', width:'100%'}}
          >
            <thead>
              <tr>
                <th onClick={() => handleColumnClick('instrument_name')}>Holding {sortColumn === 'instrument_name' && sortIcon}</th>
                <th onClick={() => handleColumnClick('description')}>Description {sortColumn === 'description' && sortIcon}</th>
                <th onClick={() => handleColumnClick('value')}>Value {sortColumn === 'value' && sortIcon}</th>
              </tr>
            </thead>
            <tbody>
              <TableBody
                paginatedHoldings={paginatedHoldings}
                holdings={holdings}
                setHoldings={setHoldings}
                setInstrumentModalOpen={setInstrumentModalOpen}
                setCreatedInstrumentName={setCreatedInstrumentName}
              />
            </tbody>
          </MainTable>
        </SectionWrapper>
        <hr/>
        <SectionWrapper justify="space-between">
          <Paginator
            className="research-paginator"
            max={pageCount}
            selected={pageNumber}
            onChange={onPaginatorChange}
          />
          <div className="portfolio-total-value">
            <span className="label">TOTAL PORTFOLIO VALUE</span>
            <span className="input">
              <TextInput
                height="50px"
                className="text-right"
                padding="0px 10px 0px 10px"
                prepend="$"
                value={ addThousandSeparator(totalValue) }
                disabled
              />
            </span>
          </div>
        </SectionWrapper>
      </StyledHoldingsEditTableCard>
    </>
  )
}

export default HoldingsEditTable
