import Color from 'color'
import styled from 'styled-components'

export const StyledHeader = styled.header`
  padding: 1.25rem 1.25rem 0.75rem;
  h4 {
    margin: 0;
    color: #2e384d;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
  }
`
export const StyledMain = styled.main`
  padding: 0 1.25rem;
  p {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    color: #979ca6;
  }
`
const confirmBgColor = Color('#002FA7')
const cancelColor = Color('#979CA6')
export const StyledFooter = styled.footer`
  padding: 0.75rem 1.25rem 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;

  .cancel-button {
    margin: 0 10px 0 0;
    min-height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: ${cancelColor.hex()};
    text-decoration: underline auto rgba(0, 0, 0, 0);
    transition: text-decoration-color 0.15s ease-in-out;
    &:hover,
    &:focus,
    &:active {
      color: ${cancelColor.darken(0.15).hex()};
      text-decoration-color: ${cancelColor.darken(0.15).hex()};
      transition: color 0.15s ease-in-out,
        text-decoration-color 0.15s ease-in-out;
    }
  }
  .confirm-button {
    padding: 6px 1rem;
    min-height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
    color: white;
    background-color: ${confirmBgColor.hex()};
    border-color: ${confirmBgColor.hex()};
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      background-color: ${confirmBgColor.darken(0.15).hex()};
      border-color: ${confirmBgColor.darken(0.15).hex()};
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }
`
