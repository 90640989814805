import React, { ReactNode } from 'react'

// import theme from './IconMap.module.scss'
import './IconMap.module.scss'

export interface IProps {
  map: Record<string, ReactNode>
}

export const IconMap: React.FC<IProps> = ({ map }) => {
  return (
    <ul className='IconMap'>
      {Object.entries(map).map(([label, icon]) => (
        <li key={label}>
          <div>{icon}</div>
          {label}
        </li>
      ))}
    </ul>
  )
}
