import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';

import withErrorBoundary from '../../hocs/withErrorBoundary'
import YAxisLine from '../charts/components/YAxisLine';
import XAxis from '../charts/components/xAxisLine'
import SDGIcon from '../styleguide/SDGIcon';
import SDGAlignment from '../styleguide/SDGAlignment';
import { SDG_COLORS } from '../../styles/colors';
import SqrButtonCase from '../../modules/fund/components/SqrButtonCase';
import { exposureButtons } from '../../constants/sectors';
import { Overlay, Tooltip } from 'react-bootstrap';
import WrapperColumn from '../styleguide/WrapperColumn';
import CardTitle from '../styleguide/CardTitle';
import TextSection from '../styleguide/TextSection';
import { SDGDescriptions } from '../../constants';

const SDGExposureCardHorizontal = ({ sdgAlignments, wrapperClass = 'SDG-Card-Wrapper-Horizontal', svgClass = "SDG-Exposure-Chart-SVG-Horizontal", bubbleSize=0.01, showToggle, changeType, type, bubbleSizeCluster = 0.003 }) => {

    const [ chartHeight, setChartHeight ] = useState(100);
    const [ chartWidth, setChartWidth ] = useState(100);
    const [ exposureType, setExposureType ] = useState("Overall");
    const [hoveredSDG, setHoveredSDG] = useState(-1);
    const target = useRef(null);


    const sdgAmount = 16;
    const graphStart = 25;
    const ceiling = 1.09;
    const floor = -1.09;


    const onResize = () => {
      const chartEl = document.getElementById("sdg-chart-horizontal");
  
      if(!chartEl) {
        console.error(`Chart with id '${chartId}' is null`);
        return;
      }
  
      setChartWidth(chartEl.clientWidth);
      setChartHeight(chartEl.clientHeight);
    }
  
    useEffect(() => {
      onResize();
    })
  
    useEffect(() => {
      window.addEventListener("resize", () => onResize());
      return () => window.removeEventListener('resize', onResize());
    });
    
    const y_scale = d3.scaleLinear()
    .domain([ceiling, floor])
    .range([0, chartHeight*0.75]);

    const x_scale = d3.scaleLinear()
    .domain([1, sdgAmount])
    .range([0, chartWidth*0.92]);

    const createMiddleLine = () => {
      const ystart = (chartHeight*0.75)/2;
      let currStart = graphStart;
      let lastStart = 5;
      const dashes = [];
      const iconGap = 20;
      const startMargin = 10;
      while(currStart + startMargin < chartWidth){
        currStart = lastStart + iconGap;
        lastStart = lastStart + iconGap;
        dashes.push(
          <XAxis
            classes="SDG-Line-Chart-Grid-Horizontal"
            ystart={ystart}
            xstart={currStart} 
            xend={currStart + 10} 
            key={`middle-line-sector-sdg-card-${currStart}`}
          />
        )
      }
      return dashes;
    }

    const renderIcons = () => {
      if(!chartWidth) return null;
      const sdgNumbers = Array(sdgAmount).fill(null).map((item, index) => index + 1);
      return sdgNumbers.map((sdg, i) => {
        const xPos = x_scale(sdg) + graphStart;  
        const height = 60;
        const iconWidth = 45;
  
        return (
          <SDGIcon key={`${i}-icon-value`} sdg={sdg} width={iconWidth} height={height} x={xPos} y={chartHeight*0.78}/>
        )
      })
  
    }

    const hoverStart = (sdg,e) => {
      target.current = e.target;
      sdg = { sdg, name: e.target.dataset.name, value: e.target.dataset.value }
      setHoveredSDG(sdg);
    }
  
    const hoverEnd = () => {
      setHoveredSDG(null);
    }

    const renderDots = () => {
      if(type === "cluster"){
        let name;
        return sdgAlignments.map((sdgAlignment, i) => {
          name = sdgAlignment.name;
          return Object.values(sdgAlignment.sdgs).map((sdg, j) => {
            const iconMidWay = 25;
            const xPos = x_scale(j + 1) + iconMidWay + graphStart;  
            const yPos = y_scale(sdg);
            return <circle
              key={`${i}-${j}-bubble-sector-horizontal`}
              cx={xPos}
              cy={yPos}
              r={chartWidth*bubbleSizeCluster}
              fill={SDG_COLORS[j + 1]}
              data-name={name}
              data-value={sdg}
              onMouseEnter={(e) => hoverStart(i, e)}
              onMouseLeave={(e) => hoverEnd()}
            />
          });
        }).flat();
      } else {
        return Object.values(sdgAlignments).map((sdg, i) => {
          const iconMidWay = 25;
          const xPos = x_scale(i + 1) + iconMidWay + graphStart;  
          const yPos = y_scale(sdg);
          return <circle
            key={`${i}-bubble-sector-horizontal`}
            cx={xPos}
            cy={yPos}
            r={chartWidth*bubbleSize}
            fill={SDG_COLORS[i + 1]}
          />
        })
      }
    }

    const changeExposureType = (type) => {
      setExposureType(type);
      changeType(type);
    }

    const renderToolTips = () => {
      for(let i = 0; i < sdgAmount; i ++){
        return <Overlay key={`sdg-overlay-wrapper-${i}`} target={target.current} show={hoveredSDG && hoveredSDG.value && hoveredSDG.name && true} placement="top-start">
        {({placement, scheduleUpdate, arrowProps, outOfBoundaries, show, ...props}) => (
            <Tooltip key={`sdg-exposure-horizontal-tooltip-${i}`} placement={placement} id={`sdg-${i + 1}`} {...props}>
              <div key={`sdg-img-${i}`} style={{backgroundColor:"white", border: "1px solid rgba(0, 0, 0, 0.05)",   borderRadius:"6px"}}>
              <WrapperColumn styles={{margin:"10px 10px 10px 15px"}}>
                {
                  hoveredSDG && hoveredSDG.name && hoveredSDG.value &&
                    <CardTitle styles={{color: '#2E384D', textAlign: 'center'}}
                  title={`${hoveredSDG && hoveredSDG.name}: ${hoveredSDG && hoveredSDG.value && (+hoveredSDG.value).toFixed(2)}`}
                  />
                }
              </WrapperColumn>                
              </div>
              </Tooltip>
          )}
        </Overlay> 
      }  
    }


    return (
        <div className={wrapperClass} style={{minHeight:'430px'}}>
        <div className="SDG-Exposure-Horizontal-Top-Wrapper">
          <span className="SDG-Exposure-Title-Horizontal">SDG Exposures</span>
          {
            showToggle && (
              <SqrButtonCase
                onChange={(exposureType) => changeExposureType(exposureType)}
                buttons={exposureButtons}
                activeButton={exposureType}
              />
            )
          }
        </div>
  
        <div className="sdg-exposure-body-horizontal">
            <svg className={svgClass} id="sdg-chart-horizontal">
              <rect fill="#FFF8F1" x={graphStart} y={y_scale(floor*0.6)} width={chartWidth} height={(chartHeight*0.75)*0.2} />
              <rect fill="#F3FFF4" x={graphStart} y={y_scale(ceiling*0.95)} width={chartWidth} height={(chartHeight*0.75)*0.2} />
                <text
                  x={8}
                  y={10}
                  className="SDG-Exposure-Labels-Horizontal"
                  >
                  1
                </text>
                <XAxis
                  classes="SDG-Line-Chart-Grid-Horizontal"
                  ystart={5}
                  xstart={graphStart} 
                  xend={chartWidth} 
                />
                <XAxis
                  classes="SDG-Line-Chart-Grid-Horizontal"
                  ystart={chartHeight*0.75}
                  xstart={graphStart} 
                  xend={chartWidth} 
                />
                {createMiddleLine()}
                {renderIcons()}
                {renderDots()}
                <text
                  x={8}
                  y={chartHeight*0.75 + 5}
                  className="SDG-Exposure-Labels-Horizontal"
                >
                -1
                </text> 
                <div ref={target}>
                  {renderToolTips()}
                </div>
            </svg>
          </div>
  
      </div>
    )

}

export default withErrorBoundary(SDGExposureCardHorizontal);
