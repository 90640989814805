import React from 'react';
import injectSheet from 'react-jss';
import cns from '../../helpers/classnames';
import { FONT_COLOR_PRIMARY } from '../../styles/colors';
import propTypes from 'prop-types';

const DeltaIndicator = ({ classes, className, delta, down, hidePercentage }) => {

  return (
    <div className={cns(classes.indicator, className)}>
      <span>
        {delta}{hidePercentage ? '' : '%'} 
      </span>
      <img src={`/ico_arrow_${down ? 'down' : 'up'}.png`}/>
    </div>
  );
}

DeltaIndicator.propTypes = {
  delta: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
  down: propTypes.bool,
  hidePercentage: propTypes.bool,
  className: propTypes.string,
  classes: propTypes.object.isRequired
}

const style = {
  indicator: {
    display: 'inline-block',
    fontSize: '0.88rem',
    letterSpacing: -0.5,
    verticalAlign: 'middle',
    color: FONT_COLOR_PRIMARY,
    '& img': {
      marginLeft: 3,
      verticalAlign: 'middle',
      height: '0.63rem',
      width: '0.63rem'
    }
  }
};

export default injectSheet(style)(DeltaIndicator);