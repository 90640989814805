import { ModuleCard } from '../ModuleCard/ModuleCard'
import styled from 'styled-components'

export const Container = styled(ModuleCard)`
  .progress {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 20px;

    strong {
      font-weight: 400;
      font-size: 36px;
      color: #2e384d;
    }
  }

  .unavailableMessage {
    text-align: center;
    font-weight: bold;
    grid-row: none;
  }
`
