export const PEErrorService = {

    errorMap:{
        cant_be_blank: `must have a value`,
        name_cant_be_blank: `must have a value`,
        has_already_been_taken: `has already been taken`,
        cant_be_zero: `can't be zero`,
        invalid_format: `has an invalid format`
    },

    nameMap:{
        administrator: "Administrator",
        aum:"AUM",
        impact_benchmark: "Impact Benchmark",
        inception_date: "Inception Date",
        investment_benchmark: "Investment Benchmark",
        fund_name: "Fund Name",
        development_focus: "Development Focus",
        impact_theme: "Impact Theme",
        holding_name: "Holding Name",
        holdings: "Holding Name",
        value: "Value",
        email: "Email",
        weight: "Multiplier",
        first_name: "First Name",
        last_name: "Last Name",
        title: "Title",
        email: "This e-mail",
        weight: "Multiplier",
        name: "Name",
        organization: "Organization"
    },

    setErrors(errors, callback){
        const finalErrors = {};
        Object.keys(errors).map((error) => {
            const errorMessage = errors[error];
            finalErrors[error] = `${this.nameMap[error]} ${this.errorMap[errorMessage]}`
        });
        callback(finalErrors);
    },
}
