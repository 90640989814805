import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { StyledLandscape, StyledPortrait, StyledContent, StyledHeading, DocumentTitle } from './PDFContent.styles';
import BrandingPrimaryLogo from '../branding_primary_logo/BrandingPrimaryLogo';

const PDFContent = ({ landscape, title, children }) => {
  const PDFContainer = landscape ? StyledLandscape : StyledPortrait;

  return (
    <PDFContainer>
      <Header/>
      <Footer/>

      <StyledContent>
        <StyledHeading>
          <DocumentTitle>{ title }</DocumentTitle>
          <BrandingPrimaryLogo/>
        </StyledHeading>

        { children }
      </StyledContent>
    </PDFContainer>
  )
}

export default PDFContent;
