import BSContainer from 'react-bootstrap/Container'
import styled from 'styled-components'

import { APP_COLOR_PRIMARY, FONT_COLOR_SECONDARY } from '../../styles/colors'

export const BadgeContainer = styled(BSContainer)`
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 1440px;
  & > :not(:first-child) {
    margin-top: 20px;
  }

  .portfolio-filter {
    color: ${FONT_COLOR_SECONDARY};
    cursor: pointer;
  }

  .badges-card {
    padding-top: 10px;
    overflow-x: scroll;
    align-items: center;
    .badges-table {
      th {
        padding-left: 10px;
        padding-right: 10px;

        > span {
          justify-content: flex-start;
        }
      }

      td {
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;

        :first-child div {
          width: 100%;
        }
      }
    }
  }

  .badges-rank-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .paginator {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .remove-filter {
    background-color: ${APP_COLOR_PRIMARY};
    border-color: ${APP_COLOR_PRIMARY};
  }

  .badges-source {
    font-size: 12px;
    color: ${FONT_COLOR_SECONDARY};
  }
`
