import React from 'react';
import Card from '../Card';

const HoldingsTable = ({ holdings }) => {

  const newHoldings = holdings && holdings.sort((a,b) => {
    return (parseFloat(a.weight) < parseFloat(b.weight)) ? 1 : -1;
  }).map((h,i) => {
    return i < 10 ? h : null;
  }).filter((nh) => {
    return nh
  })

  return (
    <section className="holdings-table">
      <h2>Holdings: Top 10 Modeled Holdings</h2>
      <table>
        <thead>
          <tr>
            <th>NAME</th>
            <th>Industry Group</th>
            <th>Business Sector</th>
            <th>WEIGHT</th>
            <th>VALUE</th>
            <th>E</th>
            <th>S</th>
            <th>G</th>
            <th>TOTAL</th>
          </tr>
        </thead>

        <tbody>
        {newHoldings && newHoldings.map((h, i) => {
          const realValue = parseFloat(h.value);
          return (<tr key={h.id}>

                <td className="name">{  h.name }</td>
                <td className="weight">{h.industry_group ? `${h.industry_group}` : "-"}</td>
                <td className="weight">{h.industry_group ? `${h.business_sector}` : "-"}</td>
                <td className="weight">{h.weight ? `${h.weight}%` : "-"}</td>
                <td className="value">{realValue.toLocaleString("en-US", { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>
                <td className="e-score">{ !h.scores.e_score ||
                isNaN(h.scores.e_score) ? "—" : h.scores.e_score}</td>
                <td className="s-score">{!h.scores.s_score || isNaN(h.scores.s_score) ? "—" : h.scores.s_score}</td>
                <td className="g-score">{!h.scores.g_score ||
                isNaN(h.scores.g_score) ? "—" : h.scores.g_score}</td>
                <td className="total">{!h.scores.total_score ||
                isNaN(h.scores.total_score) ? "—" : h.scores.total_score}</td>
              </tr>);
          })}
        </tbody>
      </table>
  </section>
  );
}

export default HoldingsTable;
