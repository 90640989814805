import { linearGradientDef } from '@nivo/core'
import { ResponsiveLine } from '@nivo/line'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

export const PreviousDataCell = ({ previousData }) => {
  const [chartData, setChartData] = useState([])
  useEffect(() => {
    const data = previousData
      .filter(el => el != null)
      .map((el, i) => {
        return { x: i, y: Number(el) }
      })
      .filter(el => el.y)
    const arr = [
      {
        id: 'chart',
        data: data,
      },
    ]
    setChartData(arr)
  }, [previousData])

  return (
    <>
      {chartData.length && chartData[0].data.length > 1 && (
        <ResponsiveLine
          data={chartData}
          interactive={false}
          animate={false}
          margin={{ top: 10, right: 0, bottom: 10, left: 0 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false,
          }}
          axisTop={null}
          axisRight={null}
          enableGridX={false}
          enableGridY={false}
          enablePoints={false}
          axisBottom={false}
          axisLeft={false}
          useMesh={false}
          enableArea={true}
          areaBaselineValue={-2}
          // areaOpacity={.05}
          colors={'#007DBE'}
          defs={[
            linearGradientDef('gradientA', [
              { offset: 0, color: '#7580FF', opacity: 0.7 },
              { offset: 100, color: '#7580FF', opacity: 0 },
            ]),
          ]}
          fill={[{ match: '*', id: 'gradientA' }]}
        />
      )}
    </>
  )
}

PreviousDataCell.propTypes = {
  previousData: PropTypes.array.isRequired,
}
