export const BREADCRUMB_LINKS = [
  {
    url: '/app',
    text: 'Home',
    active: false,
  },
  {
    url: '/app/research_center',
    text: 'Research Center',
    active: false,
  },
]

export const CONTENT = {
  reputation_1: {
    title: 'Reputation I: Transition Pathway Initiative',
    paragraph:
      'The TCFD defines reputation as anything the company would do that would be perceived as being out of line with climate goals.The Transition Pathway Initiative (TPI) rates sectors, and corporations on the actions they take transitioning to a low-carbon economy for climate change. This risk score is calculated directly by the TPI framework and is rapidly becoming the go-to corporate climate action benchmark.',
  },
  reputation_2: {
    title: 'Reputation II: Industry Environmental Impacts',
    paragraph:
      'Reputation risk stems from the impact that such industries are causing on the environment. TRBC Industry Groups are scored on the notion that reputation risk could stem from the impacts that such industries are causing to the environment. Each activity classification has a subscore based on the general impact on each environmental impact category. These scores are averaged to determine the final score.',
  },
  market_risk_1: {
    title: 'Market Risk I: Old/New Economy',
    paragraph:
      'TCFD disclosures require companies to explain how the business will experience changing market conditions in the face of intensifying changing consumer preferences and climate regulation. YvesBlue scores TRBC activities risks based on if companies are classified as Old Economy, predicted to be heavily regulated and demand is waning in favor of new solutions, or New Economy, companies that arose out of the need to address climate change.',
  },
  market_risk_2: {
    title: 'Market Risk II: Ebbing Market Demand',
    paragraph:
      'The TCFD defines market risk as changing consumer behavior and uncertainty through market signals. The risk to certain industries lies in the failure to recognize or the slowness to act on climate-driven consumer choices. YvesBlue scores TRBC activities based on adaptations to evolving consumer preferences, as portfolios with cleaner, more innovative industries are likely to see more growth and opportunities over time. Instruments are mapped to the risk score for its activity.',
  },
  market_risk_3: {
    title: 'Market Risk III: The Price of Carbon',
    paragraph:
      'Depending on a corporation’s country of incorporation, the price of carbon and its potential to increase can create large risks to its cost of operations. A carbon-intensive company operating in a location with a trading system or a tax may see the costs of this rise. The risk score for a portfolio holding is determined by the price of carbon within the country of its incorporation.',
  },
  physical_risk_1: {
    title: 'Physical Risk I: Climate Risk for Industries',
    paragraph:
      'Physical Risk includes risks to operational, supply, and risk to PP&E in the event of sudden and severe climate events or gradual effects of climate change over time. The internal scoring of the 800+ TRBC activity classifications for technology risk are based on the Encore categories of climate effects. Each activity classification has a subscore based on the general impact on each environmental impact category. These scores are averaged together to determine the final score.',
  },
  policy_and_legislation: {
    title: 'Policy & Litigation',
    paragraph:
      'The TCFD framework requires that companies disclose any regulations and policies that apply to their corporation and the effect of those regulations and policies. The Grantham Research Institute on Climate Change and the Environment has over a decade of global <a href="https://climate-laws.org" target="_blank" rel="noopener noreferrer" >data on climate laws, policies and litigation cases<a>. Instruments are mapped to its country of incorporation and industry group. The greater the number of regulations and policies that exist for that sector, the greater its risk score.',
  },
  technology_and_adaptation: {
    title: 'Technology and Adaptation',
    paragraph:
      'TCFD disclosures require companies to detail what technology adaptations the corporation will have to make in the face of intensified regulation or industry-driven trends to reduce carbon emissions. YvesBlue scores TRBC activities based on the expectation of significant adaptations, as portfolios with cleaner, more innovative industries are likely to see more growth and opportunities over time. Instruments are mapped to the risk score for its activity.',
  },
}
