import { Member } from "../models/Member";

export const MemberFactory = {
    build: (params) => {
        const newMember= new Member({});
        newMember.setId(params.id);
        newMember.setFirstName(params.first_name);
        newMember.setLastName(params.last_name);
        newMember.setTitle(params.title);
        newMember.setDepartment(params.department);
        newMember.setEmail(params.email);

        return newMember;
    }
};
