import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React from 'react'

import { UnitHelper } from './UnitHelper'

dayjs().format()

export const TargetedValueCell = ({
  enabled,
  subMetrics,
  metricDetailsLink,
}) => {
  const arr = subMetrics.map(el => el.target_value).filter(val => val != null)
  const showAddLink = arr.length === 0 && enabled
  const showNum = subMetrics.length > 1 && enabled

  return enabled && subMetrics.length ? (
    <span>
      {showAddLink ? (
        <a href={metricDetailsLink}>Add Targeted Value</a>
      ) : (
        subMetrics
          .map(({ target_value, id, unit, target_due_date }, i) =>
            target_value != null ? (
              <React.Fragment key={id}>
                {showNum ? (
                  <span className='cell-list-span' key={`indicator_${id}`}>
                    ({i + 1}) <UnitHelper measure={target_value} unit={unit} />
                  </span>
                ) : (
                  <span className='cell-list-span' key={`indicator_${id}`}>
                    <UnitHelper measure={target_value} unit={unit} />
                  </span>
                )}
                {target_due_date != null && (
                  <span className='cell-date-span' key={`link_${id}`}>
                    (by{' '}
                    <a href={metricDetailsLink}>
                      {dayjs(target_due_date).format('MMMM D, YYYY')}
                    </a>
                    )
                  </span>
                )}
              </React.Fragment>
            ) : null
          )
          .filter(val => val != null)
      )}
    </span>
  ) : (
    <span>—</span>
  )
}

TargetedValueCell.propTypes = {
  enabled: PropTypes.bool.isRequired,
  subMetrics: PropTypes.array.isRequired,
  metricDetailsLink: PropTypes.string.isRequired,
}
