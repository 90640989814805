import styled from 'styled-components';


const TextSection = styled.div`
    font-size: ${props => props.fontSize || '16px'};
    font-weight: ${props => props.fontWeight };
    color: ${props => props.color || '#2E384D'};

    width:  ${props => props.width || '100%'};
    
    padding: ${props => props.padding};
    white-space: ${props => props.wrap};
    margin: ${props => props.margin};
    transform: ${props => props.transform};
    text-align: ${props => props.align};
`;


export default TextSection;