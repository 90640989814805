// import '../../../../../../node_modules/react-toastify/dist/ReactToastify.min.css'
// styles at project root ./app/assets/stylesheets/app/application.scss

import React from 'react'
import { ToastContainer } from 'react-toastify'

export const YBToastContainer = () => {
  return (
    <ToastContainer
      autoClose={4000}
      closeOnClick={true}
      hideProgressBar={true}
      pauseOnHover={false}
      theme={'colored'}
    />
  )
}
