/* eslint-disable react/jsx-key */
import _sortBy from 'lodash.sortby'
import React, { useEffect, useState } from 'react'
import Select from 'react-select-3'

import Button from '../../../../components/styleguide/Button'
import CardTitle from '../../../../components/styleguide/CardTitle'
import SectionWrapper from '../../../../components/styleguide/SectionWrapper'
import Table from '../../../../components/styleguide/Table'
import TextSection from '../../../../components/styleguide/TextSection'
import WrapperColumn from '../../../../components/styleguide/WrapperColumn'
import {
  memberTableHeaders,
  roleTypeLabels,
  roleTypes,
} from '../../../../constants/funds'
import {
  getFundMembers,
  putFundMember,
  setAllMembersToReaders,
} from '../../../../repositories/funds_repository'
import SaveButton from '../../components/SaveButton'
import { Member } from '../../models/Member'
import { FundTableWrapper, ModuleWrapper } from '../../styles'
import CreateMemberModal from './components/CreateMemberModal'

const MemberSection = ({ fundId, callbacks, config = {} }) => {
  const [showModal, setShowModal] = useState(false)
  const [membersList, setMembersList] = useState(null)

  const canUpdate = config.action === 'create' || config.canUpdate

  useEffect(() => {
    getFundMembers(fundId).then(response => {
      createMemberList(response.data)
    })
  }, [fundId])

  const createMemberList = membersJSON => {
    const newMembers = membersJSON.map(memberJSON => {
      return new Member(memberJSON)
    })
    setMembersList(newMembers)
  }

  const addMemberToList = newMember => {
    const newMembers = membersList.slice()
    newMembers.push(newMember)
    setMembersList(newMembers)
  }

  const onModalSubmit = newMember => {
    addMemberToList(newMember)
    setShowModal(false)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const onRoleEdit = (member, newRole) => {
    putFundMember({
      memberId: member.id,
      newRole: newRole,
      fundId: fundId,
      // eslint-disable-next-line no-unused-vars
    }).then(response => {
      const index = membersList.findIndex(m => m.id === member.id)
      const newMembersList = membersList.slice()
      membersList[index].role_type = newRole
      setMembersList(newMembersList)
    })
  }

  const onSubmit = () => {
    callbacks.forEach(cb => {
      cb()
    })
  }

  const roleTypeValue = member => {
    if (!canUpdate) {
      return roleTypes[member.role_type]
    } else if (member.role_type === 'owner') {
      return 'Owner'
    } else {
      return (
        <Select
          menuPortalTarget={document.querySelector('body')}
          onChange={value => {
            onRoleEdit(member, value.value)
          }}
          options={roleTypeLabels}
          value={{
            value: member.role_type,
            label: roleTypes[member.role_type],
          }}
        />
      )
    }
  }

  const tableRows = () => {
    return membersList.map(member => {
      return [
        <span>
          {member.first_name && member.last_name
            ? `${member.first_name} ${member.last_name}`
            : '-'}
        </span>,
        <span>{member.title ? member.title : '-'}</span>,
        <span>{member.department ? member.department : '-'}</span>,
        <span>{member.email ? member.email : '-'}</span>,
        <span>{roleTypeValue(member)}</span>,
      ]
    })
  }

  const onSetAllMembersToReadersClick = () => {
    setAllMembersToReaders({ fundId })
      .then(response => {
        createMemberList(response.data)
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  const onSort = (id, direction) => {
    // eslint-disable-next-line no-undef
    let sorted = _sortBy(members, m => {
      return m[id]
    })

    if (direction == 'asc') {
      sorted = sorted.reverse()
    }

    setMembersList(sorted)
  }

  return (
    <>
      <ModuleWrapper width='100%'>
        {membersList && (
          <FundTableWrapper margin={'0px 0px 0px 0px'}>
            <Table
              look={{ textAlign: 'left' }}
              headers={memberTableHeaders}
              body={tableRows()}
              onSort={onSort}
              className='member-holdings-table'
            />
          </FundTableWrapper>
        )}
        {membersList && membersList.length < 1 && (
          <WrapperColumn
            styles={{
              justify: 'center',
              align: 'center',
              margin: '10px 0px 30px 0px',
            }}
          >
            <div>You have no holdings, yet.</div>
            <Button
              width='20%'
              label='Add A User'
              onClick={() => setShowModal(true)}
            />
          </WrapperColumn>
        )}

        {showModal && (
          <CreateMemberModal
            fundId={fundId}
            onSubmit={onModalSubmit}
            onHide={closeModal}
            show={showModal}
          />
        )}
      </ModuleWrapper>
      {canUpdate && (
        <>
          {membersList && callbacks && callbacks.length > 0 ? (
            <SaveButton
              styles={{ justify: 'space-between' }}
              onSave={onSubmit}
              beforeChildren={[
                <Button
                  width='20%'
                  label='Set All Members as Readers'
                  onClick={onSetAllMembersToReadersClick}
                />,
                membersList && membersList.length > 0 ? (
                  <Button
                    width='20%'
                    label='Add A User'
                    onClick={() => setShowModal(true)}
                  />
                ) : null,
              ]}
            />
          ) : (
            membersList && (
              <SectionWrapper
                justify='space-between'
                width={config.buttonWrapperWidth}
              >
                <Button
                  width={config.buttonWidth || '25%'}
                  label='Set All Members as Readers'
                  onClick={onSetAllMembersToReadersClick}
                />
                {membersList && membersList.length > 0 ? (
                  <Button
                    width={config.buttonWidth || '20%'}
                    label='Add A User'
                    onClick={() => setShowModal(true)}
                  />
                ) : null}
              </SectionWrapper>
            )
          )}
        </>
      )}
      {membersList && (
        <WrapperColumn styles={{ margin: '45px 0px 0px 0px', width: '100%' }}>
          <CardTitle title='Access Levels' />
          <WrapperColumn styles={{ width: '100%' }}>
            <SectionWrapper margin='25px 0px 25px 32px'>
              <CardTitle title='Admin' />
              <TextSection width='75%' margin='auto auto auto 16px'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                at lorem quis sem consequat condimentum quis malesuada lectus.
                Sed dui nulla, porttitor non tristique in, faucibus eget dolor.
                Proin hendrerit fringilla purus mollis gravida. Donec finibus
                nulla lacus, id semper ligula pellentesque non. Suspendisse
                imperdiet quam at tristique accumsan.
              </TextSection>
            </SectionWrapper>
            <SectionWrapper margin='25px 0px 25px 32px'>
              <CardTitle title='Writer' />
              <TextSection width='75%' margin='0px 0px 0px 16px'>
                Vestibulum hendrerit nulla a congue porta. Cras scelerisque
                scelerisque felis, sed facilisis orci tincidunt non. Nam quis
                efficitur tortor, id volutpat lectus. Maecenas quis ullamcorper
                nisl. Pellentesque vel rhoncus nibh. Vestibulum quis ipsum a
                sapien sollicitudin ullamcorper. Sed gravida orci sit amet purus
                lacinia, a viverra nunc suscipit.
              </TextSection>
            </SectionWrapper>
            <SectionWrapper margin='25px 0px 25px 32px' align='flex-start'>
              <CardTitle title='Reader' />
              <TextSection width='75%' margin='0px 0px 0px 16px'>
                Duis nisi enim, auctor non massa eu, laoreet varius est. Donec
                lacinia, urna sed vehicula maximus, mauris nibh molestie mauris,
                vitae fermentum enim ligula in sem. Aenean vitae ultrices massa.
                Ut a magna dui. Ut cursus urna nulla, quis eleifend nulla
                pulvinar nec. Nam malesuada vulputate feugiat. Nunc vestibulum,
                nunc et vehicula sodales, lorem metus interdum elit, non
                facilisis mauris lectus vitae nulla. Vivamus aliquam nulla eu
                nisl rhoncus, id hendrerit justo accumsan.
              </TextSection>
            </SectionWrapper>
          </WrapperColumn>
        </WrapperColumn>
      )}
    </>
  )
}

export default MemberSection
