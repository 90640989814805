import React from 'react';
import styled from 'styled-components';
import { BORDER_COLOR_PRIMARY, APP_COLOR_PRIMARY, FONT_COLOR_PRIMARY } from '../../styles/colors';

const Arrow = styled.div`

    width: 200px;
    height: 40px;
    display:flex;
    align-items:center;
    justify-content:center;
    text-align:center;
    position: relative;
    border: ${props => props.selected ? `2px solid ${APP_COLOR_PRIMARY}` : `2px solid ${BORDER_COLOR_PRIMARY}`};
    background:${props => props.selected ? `${APP_COLOR_PRIMARY}` : `white`};
    color:${props => props.selected ? `white` : `${FONT_COLOR_PRIMARY}`};

    &:after{
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: ${props => props.selected ? `18px solid ${APP_COLOR_PRIMARY}` : `18px solid ${BORDER_COLOR_PRIMARY}`};
        border-top: 18px solid transparent;
        border-bottom: 18px solid transparent;
    }

    &:before {
        content: "";
        position: absolute;
        right: -20px;
        bottom: 0;
        width: 0;
        height: 0;
        //border-left: ${props => props.selected ? `18px solid ${APP_COLOR_PRIMARY}` : `18px solid ${BORDER_COLOR_PRIMARY}`};
        border-top: 18px solid transparent;
        border-bottom: 18px solid transparent;
      }
    
`;

const WizardSteps = ({ steps, selected, id }) => {
    return(
        <>
            {
                steps.map((step, i) => {
                   return <Arrow key={`wizard-steps-${i}-${id}`} selected={i === selected}>{`${step}`}</Arrow>
                })
            }
        </>
    )

};

export default WizardSteps;