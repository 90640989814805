import styled from 'styled-components'

import { FONT_COLOR_SECONDARY } from '../../../../../../../styles/colors'

export const StyledTable = styled.table`
  margin-bottom: 30px;

  th {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: ${FONT_COLOR_SECONDARY};
  }
  td,
  th {
    text-align: center;

    &:first-child {
      text-align: left;
    }
  }
  td {
    padding: 4px 10px;
    height: 50px;
    font-size: 15px;
    line-height: 18px;
    color: #979797;
  }
  .group {
    height: 25px;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
    background-color: #979797;
  }
  .category {
    height: 25px;
    font-weight: 700;
    color: white;
    background: #bbbbbb;
  }
  .explanations-trigger {
    margin-left: 4px;
    font-size: 12px;
    line-height: 14px;
    text-decoration: underline;
  }
`
