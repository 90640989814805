import {
  postPasswordResetRequest,
  postSignIn,
} from '../../../repositories/account_repository'

export const loadPasswordResetRequest = (account, setRes, setLoading) => {
  const email = {
    email: account,
  }

  setLoading(true)

  postPasswordResetRequest(email)
    .then(res => {
      setLoading(false)
      setRes(res)
    })
    .catch(err => {
      setLoading(false)
      setRes(err)
    })
}

export const loadSignInRequest = (
  user,
  password,
  setRes,
  setLoading,
  code,
  backUpCode
) => {
  const userCredentials = {
    app_user: {
      email: user,
      password: password,
    },
  }

  if (backUpCode) {
    userCredentials.app_user.otp_backup_code_attempt = code
  } else if (code) {
    userCredentials.app_user.otp_secret_attempt = code
  }

  setLoading(true)

  postSignIn(userCredentials)
    .then(res => {
      setLoading(false)
      setRes(res)
    })
    .catch(err => {
      setLoading(false)
      setRes(err)
    })
}
