import React from 'react'
// import theme from './HoldingDataIcon.module.scss'
import './HoldingDataIcon.module.scss'

const ICONS = {
  complete: 'fas fa-check-circle',
  not_applicable: 'fas fa-minus-circle',
  incomplete: 'fas fa-times-circle',
}

interface IProps {
  status: keyof typeof ICONS
}

export const HoldingDataIcon: React.FC<IProps> = ({ status }) => (
  <span className='HoldingDataIcon'>
    <i className={ICONS[status]} />
  </span>
)
