import PropTypes from 'prop-types'
import React from 'react'

import iconSdg1 from './sdgIcons/filled-sdg-01.svg'
import iconSdg2 from './sdgIcons/filled-sdg-02.svg'
import iconSdg3 from './sdgIcons/filled-sdg-03.svg'
import iconSdg4 from './sdgIcons/filled-sdg-04.svg'
import iconSdg5 from './sdgIcons/filled-sdg-05.svg'
import iconSdg6 from './sdgIcons/filled-sdg-06.svg'
import iconSdg7 from './sdgIcons/filled-sdg-07.svg'
import iconSdg8 from './sdgIcons/filled-sdg-08.svg'
import iconSdg9 from './sdgIcons/filled-sdg-09.svg'
import iconSdg10 from './sdgIcons/filled-sdg-10.svg'
import iconSdg11 from './sdgIcons/filled-sdg-11.svg'
import iconSdg12 from './sdgIcons/filled-sdg-12.svg'
import iconSdg13 from './sdgIcons/filled-sdg-13.svg'
import iconSdg14 from './sdgIcons/filled-sdg-14.svg'
import iconSdg15 from './sdgIcons/filled-sdg-15.svg'
import iconSdg16 from './sdgIcons/filled-sdg-16.svg'
import iconSdg17 from './sdgIcons/filled-sdg-17.svg'

const icons = {
  1: iconSdg1,
  2: iconSdg2,
  3: iconSdg3,
  4: iconSdg4,
  5: iconSdg5,
  6: iconSdg6,
  7: iconSdg7,
  8: iconSdg8,
  9: iconSdg9,
  10: iconSdg10,
  11: iconSdg11,
  12: iconSdg12,
  13: iconSdg13,
  14: iconSdg14,
  15: iconSdg15,
  16: iconSdg16,
  17: iconSdg17,
}

export const SdgIcon = ({ sdg }) => {
  // const { Icon } = icons[sdg]

  const Icon = icons[sdg]

  return <Icon />
}

SdgIcon.propTypes = {
  sdg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}
