import React, { useState, useEffect } from 'react';
import SectionWrapper from '../../../../components/styleguide/SectionWrapper';
import ConfigurationModule from '../configuration/ConfigurationModule';
import SqrButtonCase from '../../components/SqrButtonCase';
import { fundManageButtons } from '../../../../constants/funds';
import MemberModule from '../member/MemberSection';
import { ModuleWrapper } from '../../styles';
import ReportingFrameworkModule from '../reporting_framework/ReportingFrameworkSection';

const ManageFundSection = ({ fund, updateFund }) => {
    const [currTab, setCurrTab] = useState("Fund Information")

    const currModule = () => {
        switch(currTab){
            case "Fund Information":
                return <ConfigurationModule config={{ sub:true, actionType : "edit", canUpdate : fund.current_app_user_can_update }} fund={fund}/>
            case "Sharing & Securty":
                return <MemberModule config={{fundTableMargin:'0px', buttonWrapperWidth:"100%", canUpdate : fund.current_app_user_can_update}} fundId={fund.id}/>
            case "Fund Status Bar":
                return <ModuleWrapper width="100%" height="100%">Fund Status</ModuleWrapper>
            case "Default Metrics":
                return <ModuleWrapper width="100%" height="100%">Metrics</ModuleWrapper>
            case "Reporting Frameworks":
                return <ReportingFrameworkModule fundId={fund.id} config={{}}/>
            case "Pipeline":
                return <ModuleWrapper width="100%" height="100%">Pipeline</ModuleWrapper>
        }
    };

    return(
        <SectionWrapper data-cy="manage-fund-wrapper">
            <SectionWrapper data-cy="manage-fund-side-button-wrapper" width="20%" height="25%" margin="45px 0px 0px 0px">
                <SqrButtonCase
                    onChange={(tab) => setCurrTab(tab)}
                    buttons={fundManageButtons}
                    dataLabel="manage-fund-side-button::manage-buttons"
                    activeButton={currTab}
                    styles={{width:'100%'}}
                />
            </SectionWrapper>
            <SectionWrapper width="80%" data-cy="manage-fund-module-wrapper" >
                {currModule()}
            </SectionWrapper>
        </SectionWrapper>
    )
}

export default ManageFundSection;
