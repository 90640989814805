import React from 'react'
import styled from 'styled-components'

const StyledOl = styled.ol`
  column-count: 3;
  column-gap: 20px;
`

const Disclosures = () => {
  return (
    <>
      <div className='disclosures'>
        <h2>Explanation and Disclaimers</h2>
        <p>
          <strong>Peers Module:</strong>
        </p>
        <p>
          Each instrument page provides the ability to view the company of
          interest along with several peer companies that are in the same
          industry group and market cap but rather than with conventional
          financial metrics, this comps tool provides you with ESG scores, SDG
          scores and some environmental, social and governance revenue
          normalized scores. The module provides five tabs:
        </p>
        <div>
          <StyledOl>
            <li>The ESG Score Tab</li>
            <li>The SDG Alignment Tab</li>
            <li>The Environmental Tab</li>
            <li>The Social Tab</li>
            <li>The Governance Tab</li>
          </StyledOl>
        </div>
        <p>
          <strong>1. The ESG Score Tab:</strong>
        </p>
        <p>
          What you see when you click on the ESG Score will be the total ESG
          Score, the average of the E, S and G pillars. Please see our Scoring
          Methodology Explainer for further details on what is a Z-score, its
          meaning and how it might be used in conjunction with other features in
          the YvesBlue Application.
        </p>
        <p>
          Just below each color scaled Z-Score score will be a range of one half
          standard deviation above to one half standard deviation below the
          company's Z-Score to indicate the range of company Z-Scores on either
          side of the company in question. For example, if the company has a
          Z-score of 50, a half std. dev. move to the left captures a Z-Score of
          49.99 and a half std. dev. to the right captures a 51.2 meaning that a
          move by a half std. dev. in either direction might not be normally
          distributed.
        </p>
        <p>
          <strong>2. The SDG Alignment Tab:</strong>
        </p>
        <p>
          The scores provided in this tab are the percent alignment of that
          company to 16 UN Sustainable Development Goals or SDGs. We know that
          SDGs are actually meant for countries to apply on a macro scale but
          investors want to know if the companies in their portfolios are
          somehow relevant to these broad themes in a general sense. For
          example, a company with very low water usage in an otherwise water
          intensive industry should be very aligned to SDG 6 Clean Water and
          Sanitation. Conversely, a company that very energy intensive relative
          to its industry won't be very aligned to SDG 12 Responsible
          Consumption and Production. We also make modifications based market
          segment. For example, Hannon Armstrong Sustainable Infrastructure Inc.
          (HASI, NYSE) is very aligned to SDG 7 and 13 for carbon reduction and
          climate action.
        </p>
        <p>
          In this model, YvesBlue has selected a small group of at least 3 ESG
          Data metrics that would be relevant to determining alignment. Then for
          each SDG, the model measures how well the company has been performing
          against these metrics. The Peers Module shows you the percent
          alignment to each of the 16 SDGs. You can compare that to similar
          companies that we have provided or you can select your own to compare.
        </p>
        <p>
          <strong>3. The Environmental Tab:</strong>
        </p>
        <p>
          The Environmental Scores Peers Tab provides key metrics in the E
          pillar for which many investors have asked. At the top, the E pillar
          total score is provided from our ESG scores. Then each row below that
          shows the Z-Score that each company got for:
        </p>
        <div>
          <StyledOl>
            <li>Carbon Scope 1</li>
            <li>Carbon Scope 2</li>
            <li>Carbon Scope 3</li>
            <li>Purchased Renewable Energy</li>
            <li>Produced Renewable Energy</li>
            <li>Water Discharged</li>
            <li>Water Withdrawal</li>
            <li>Hazardous Waste</li>
            <li>Waste Intensity</li>
            <li>Waste Recycling</li>
          </StyledOl>
        </div>
        <p>
          Below each Z-Score will again be a range of other company's scores in
          the sector on either side of that score. This occurs the same way for
          the metrics in the S and G tabs.
        </p>
        <p>
          <strong>SDG Exposure:</strong>
        </p>
        <p>
          To calculate alignment to the U.N. Sustainable Development Goals
          (SDGs) we first map company metrics to each of the 16 SDGs. We rank
          companies on these metrics (e.g. 70th percentile on CO2) relative to
          their ESG Data industry group, averaging across metrics for that SDG.
          If a company is in the top/bottom quartile across the metrics it would
          receive a positive/negative alignment to that SDG.
        </p>
        <p>
          <strong>ESG Scores & Metrics:</strong>
        </p>
        <p>
          ESG scores represent YvesBlue’s (YB) view on the sustainability of
          companies, based on a set of primary quantitative indicators (e.g. CO2
          Scope3 at 15% weight, Women Managers at 5%, etc.). The system ranks
          companies against those in their ESG Data industry group
          classification (e.g. ‘Oil & Gas’) within the ESG reporting universe of
          ~8,000 companies based on data from ESG Data and others. Where company
          values are not available, we use proprietary methodologies to model
          the values and rank. The ESG score weights at 45%/30%/25% for E/S/G
          pillars respectively. There are relatively few companies with scores
          above 80 or below 20 as it requires high rankings across several
          different categories across the E, S and G pillars. An example of a
          high rating company is Sonova Holding AG (81), who designs hearing aid
          systems and has very high levels of diversity and low levels emissions
          relative to their industry group. An example of a low rating company,
          especially relative to some other scoring systems is Alphabet (28).
          The system penalizes them for lack of offsetting Scope 3 emissions,
          for the massive amount of lobbying, and for poor diversity numbers.
          The algorithm probably overly discounts their renewable energy
          purchases (which appear to be high quality Power Purchase Agreements)
          but the advantage of a quantitative system is that it is traceable,
          unlike many that rely on analyst opinions.
        </p>
        <p>
          <strong>Company Emissions & Scope:</strong>
        </p>
        <p>
          Company emissions (air pollution with the potential to contribute to
          global heating) refer to tonnes of CO2-equivalent emissions unless
          otherwise indicated (tCO2e). These are divided into 3 ‘scopes’,
          Scope1: emissions from facilities or operations under direct control
          by the company (onsite), Scope2: indirect emissions from generation of
          energy the company uses, Scope3: remaining emissions attributable to
          sources not directly owned or controlled by the company, including
          employee travel, waste generation and other upstream and downstream
          emissions not included in Scopes 1 & 2.{' '}
        </p>
        <p>
          <strong>Carbon Burden:</strong>
        </p>
        <p>
          These statistics allow you to compare the carbon intensity of the
          portfolio versus its benchmark. ‘Reported’ emissions represent total
          annual company emissions for the portfolio or benchmark. ‘Owned’
          emissions takes this a step farther and determines how much of these
          emissions the portfolio or benchmark ‘owns’ from the actual size of
          the investments. For these calculations we assume a portfolio size of
          $100M.
        </p>
      </div>
      <div>
        <div style={{ pageBreakBefore: 'always' }}></div>
        <p>
          <strong>RepRisk:</strong>
        </p>
        <p>
          RepRisk Founded in 1998 and headquartered in Switzerland, RepRisk is a
          pioneer in ESG data science that leverages the combination of AI and
          machine learning with human intelligence to systematically analyze
          public information and identify material ESG risks. RepRisk’s flagship
          product, the RepRisk ESG Risk Platform, is the world’s largest and
          most comprehensive due diligence database on ESG and business conduct
          risks, with expertise in 20 languages and coverage of 135,000+ public
          and private companies and 30,000+ infrastructure projects. For more
          than a decade, the world’s leading financial institutions and
          corporations have trusted RepRisk for due diligence and risk
          management across their operations, business relationships, and
          investments. To learn more, please visit www.reprisk.com.
          <br />
        </p>
        <p>
          <strong>Disclaimer:</strong>
        </p>
        <p>
          The information in this report is for general information purposes
          only. It is not intended as personal financial or investment advice
          and should not be construed or relied on as such. No information
          contained within should be construed or relied upon as providing
          recommendations in relation to any financial product or investment
          strategy.{' '}
        </p>
        <p>Source: ESG Data augmented by additional sources.</p>
      </div>
    </>
  )
}

export default Disclosures
