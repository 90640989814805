import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'

import { getInTheMix } from '../../../repositories/managers_repository'
import SpinnerContainer from '../../../yb_components/styleguide/spinners/SpinnerContainer'
import KaminariPagination from './KaminariPagination'

const InTheMix = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [inTheMix, setInTheMix] = useState(null)

  useEffect(() => {
    getInTheMix().then(result => {
      setInTheMix(result.data)
    })
  }, [])

  const fetchPage = page => {
    getInTheMix(page).then(result => {
      setInTheMix(result.data)
      setCurrentPage(page)
    })
  }

  return (
    <>
      <h3>In The Mix</h3>
      <SpinnerContainer isLoading={!inTheMix}>
        <table className='table table-sm table-striped'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Asset Class</th>
              <th>Score</th>
              <th>UN SDG</th>
            </tr>
          </thead>

          <tbody>
            {(inTheMix?.funds || []).map(fund => (
              <tr key={fund.id}>
                <td>
                  <a href={fund.link}>{fund.name}</a>
                </td>
                <td>
                  <span className='badge badge-secondary'>
                    {fund.asset_class_name}
                  </span>
                </td>
                <td>{fund.in_the_mix_score}</td>
                <td>
                  {fund.sdgs
                    .filter(sdg => sdg.index && sdg.index < 18)
                    .map(sdg => (
                      <img
                        className='d-inline-block m-1'
                        key={sdg.index}
                        title={sdg.name}
                        height='25'
                        width='25'
                        src={`/sdg_icons/${sdg.index}-50.png`}
                      />
                    ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <small className='d-block text-muted text-right'>
          As of {dayjs(inTheMix?.as_of).format('MMMM D, YYYY')}
        </small>
        <KaminariPagination
          currentPage={currentPage}
          totalPages={inTheMix?.total_pages}
          fetchPage={fetchPage}
        />
      </SpinnerContainer>
    </>
  )
}

export default InTheMix
