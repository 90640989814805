export const normalizeNumber = number =>
  new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 3,
    maxFractionDigits: 3,
  }).format(number)

export const translateBoolNumber = boolNumber => {
  if (boolNumber === 0) return 'No'
  return 'Yes'
}
