import propTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import withErrorBoundary from '../../hocs/withErrorBoundary'
import {
  ExplainerLink,
  LinkIcon,
  LinkText,
} from '../../yb_components/styleguide/links/Link.styles'
import { DEFAULT_ESG_SCORE_EXPLAINER_URL } from '../../yb_constants/urls/explainers'
import Card from '../styleguide/Card'
import CardTitle from '../styleguide/CardTitle'
import ESGOverallScore from '../styleguide/ESGOverallScore'
import ESGScale from '../styleguide/ESGScale'
import ESGShortsLongsTable from '../tables/ESGShortsLongsTable'

const StyledCard = styled(Card)`
  .content {
    margin: 10px 0;
  }

  .shorts-longs-container {
    margin-top: 20px;
    margin-bottom: 25px;
  }

  .clear-both {
    clear: both;
  }
`

const CustomExplainerLink = styled(ExplainerLink)`
  float: right;
  margin-top: 5px;
`

const ESGScoreCard = ({
  scores,
  shorts_scores,
  longs_scores,
  benchmarkScores,
  benchmarkName,
  customESGLabels,
  portfolioId,
}) => {
  const hasShorts = shorts_scores
  const title = hasShorts ? 'OVERALL ESG SCORE' : 'ESG SCORE'

  let height = benchmarkName ? 440 : 400

  if (hasShorts) {
    height += 200
  }

  return (
    <StyledCard height={height} padding={20}>
      <div className='content'>
        <CardTitle title={title} />
        <div className='float-right'>
          {portfolioId &&
            react_app_organization.organization.can_customize_esg_scores && (
              <ExplainerLink
                href={`/app/portfolios/${portfolioId}/custom_esg_scoring`}
              >
                <LinkIcon className='fas fa-edit' />
                <LinkText>Customize portfolio scores</LinkText>
              </ExplainerLink>
            )}
          <CustomExplainerLink
            href={DEFAULT_ESG_SCORE_EXPLAINER_URL}
            target='_blank'
          >
            <LinkIcon className='far fa-question-circle' />
            <LinkText>What do these scores mean?</LinkText>
          </CustomExplainerLink>
          <br />
        </div>
        <div className='clear-both' />
        <ESGOverallScore
          full
          scores={scores}
          benchmarkScores={benchmarkScores}
          benchmarkName={benchmarkName}
          customESGLabels={customESGLabels}
        />
        {hasShorts && (
          <div className='shorts-longs-container'>
            <CardTitle title='SHORTS / LONGS ESG SCORES' />
            <ESGShortsLongsTable
              customESGLabels={customESGLabels}
              shorts={shorts_scores}
              longs={longs_scores}
            />
          </div>
        )}
        <ESGScale labels={customESGLabels} />
      </div>
    </StyledCard>
  )
}

ESGScoreCard.propTypes = {
  scores: propTypes.shape({
    e_score: propTypes.oneOfType([propTypes.number, propTypes.string])
      .isRequired,
    s_score: propTypes.oneOfType([propTypes.number, propTypes.string])
      .isRequired,
    g_score: propTypes.oneOfType([propTypes.number, propTypes.string])
      .isRequired,
  }).isRequired,
  classes: propTypes.object.isRequired,
}

export default withErrorBoundary(ESGScoreCard)
