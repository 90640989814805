import React, { useEffect, useState } from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'

import Button from '../../../components/styleguide/Button'
import DatePicker from '../../../components/styleguide/DatePicker'
import InputLabel from '../../../components/styleguide/InputLabel'
import SectionTitle from '../../../components/styleguide/SectionTitle'
import SectionWrapper from '../../../components/styleguide/SectionWrapper'
import StyledDialog from '../../../components/styleguide/StyledDialog'
import TextArea from '../../../components/styleguide/TextArea'
import TextInput from '../../../components/styleguide/TextInput'
import YBSelect from '../../../components/styleguide/YBSelect'
import { buildSelectOptions } from '../../../helpers/builders'
import { getImpactBenchmarks } from '../../../repositories/benchmark_repository'
import { putPortfolioUpdate } from '../../../repositories/portfolio_repository'
import { FONT_COLOR_SECONDARY } from '../../../styles/colors'
import { Footer, PortfolioEditContainer } from '../styles/edit/styles'
import FileInput from './components/FileInput'
import HoldingsEditTable from './components/HoldingsEditTable'

const PortfolioEdit = ({ snapshot }) => {
  const [name, setName] = useState(snapshot.name)
  const [asOf, setAsOf] = useState(snapshot.as_of)
  const [description, setDescription] = useState(snapshot.description)
  const [snapshotUploadFile, setSnapshotUploadFile] = useState(null)
  const [confirmModalIsOpen, setConfirmModalOpen] = useState(false)
  const [holdings, setHoldings] = useState(snapshot.holdings)
  const [benchmark, setBenchmark] = useState(snapshot.impact_benchmark)
  const [benchmarks, setBenchmarks] = useState([])
  const [areBenchmarksLoading, setAreBenchmarksLoading] = useState(false)

  useEffect(() => {
    document.title = 'Edit Portfolio | YvesBlue'
    fetchBenchmarks()
  }, [])

  const fetchBenchmarks = () => {
    setAreBenchmarksLoading(true)
    getImpactBenchmarks()
      .then(res => buildSelectOptions(res.data.impact_benchmarks))
      .then(benchmark_options => setBenchmarks(benchmark_options))
      .then(() => setAreBenchmarksLoading(false))
  }

  const handleSubmit = event => {
    event.preventDefault()
    setConfirmModalOpen(true)
  }

  const onModalConfirm = () => {
    const presentHoldings = holdings.filter(
      holding => holding.instrument_name || holding.value !== 0
    )
    const valueReducer = (accumulator, currentValue) => {
      return accumulator + Number(currentValue.value)
    }
    const totalValue = holdings.reduce(valueReducer, 0).toFixed(2)

    const body = {
      portfolio_snapshot: {
        name,
        impact_benchmark_id: benchmark.id,
        description,
        snapshot_upload_file: snapshotUploadFile,
        total_value: totalValue,
        holdings: presentHoldings,
        as_of: asOf,
      },
    }

    putPortfolioUpdate(snapshot.id, snapshot.portfolio_id, body).then(() => {
      setConfirmModalOpen(false)
      window.location.href = `/app`
    })
  }

  return (
    <>
      <PortfolioEditContainer>
        <Breadcrumb>
          <Breadcrumb.Item href='/app'>Home</Breadcrumb.Item>
          <Breadcrumb.Item href={`/app/portfolios`}>Portfolios</Breadcrumb.Item>
          <Breadcrumb.Item
            href={`/app/portfolios/${snapshot.portfolio_id}/snapshots/${snapshot.id}`}
          >
            {snapshot.name}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Edit</Breadcrumb.Item>
        </Breadcrumb>
        <StyledDialog
          show={confirmModalIsOpen}
          size='lg'
          onHide={() => {
            setConfirmModalOpen(false)
          }}
        >
          <SectionWrapper margin='20px'>
            <Modal.Title>
              <SectionTitle
                className='section-title'
                title='Are You Sure You Want To Update Your Portfolio?'
              />
            </Modal.Title>
            <Modal.Body>
              Please note that updating your snapshot will cause it to be
              reprocessed and scores and reported data may change.
              <SectionWrapper justify='center' margin='30px 0px 0px 0px'>
                <Button
                  margin='0px 10px 0px 0px'
                  width='20%'
                  secondary
                  onClick={onModalConfirm}
                  label='Save'
                />
                <Button
                  width='20%'
                  secondary
                  onClick={() => {
                    setConfirmModalOpen(false)
                  }}
                  label='Cancel'
                />
              </SectionWrapper>
            </Modal.Body>
          </SectionWrapper>
        </StyledDialog>
        <SectionTitle className='section-title' title='Edit Portfolio' />
        <Row>
          <Col md={5}>
            <TextInput
              height='50px'
              label='NAME'
              value={name}
              onChange={(_, value) => {
                setName(value)
              }}
            />
          </Col>
          <Col md={4}>
            <YBSelect
              label='BENCHMARK'
              height='50px'
              defaultValue={{ value: benchmark.id, label: benchmark.name }}
              isSearchable={false}
              options={benchmarks}
              isDisabled={areBenchmarksLoading}
              isLoading={areBenchmarksLoading}
              onChange={({ value, label }) =>
                setBenchmark({ id: value, name: label })
              }
            />
          </Col>
          <Col md={{ offset: 1, span: 2 }}>
            <DatePicker
              label='As Of'
              width='100%'
              id='as_of'
              date={asOf}
              onChange={value => setAsOf(value)}
            />
          </Col>
          <Col md={6}>
            <TextArea
              label='DESCRIPTION'
              value={description}
              rows={3}
              onChange={(_, value) => {
                setDescription(value)
              }}
            />
          </Col>
          <Col md={6}>
            <FileInput
              label='SNAPSHOT UPLOAD'
              onChange={e => setSnapshotUploadFile(e.target.files[0])}
            />
          </Col>
        </Row>

        <HoldingsEditTable
          snapshot={snapshot}
          holdings={holdings}
          setHoldings={setHoldings}
        />
      </PortfolioEditContainer>
      <Footer>
        <Button
          onClick={() => {
            window.location.href = `/app/portfolios/${snapshot.portfolio_id}/snapshots/${snapshot.id}`
          }}
          label='Cancel'
          secondary
          color={FONT_COLOR_SECONDARY}
          width='15%'
          margin='20px 15px 0px 0px'
        />
        <Button
          onClick={handleSubmit}
          label='Save Changes'
          width='15%'
          margin='20px 50px 0px 0px'
        />
      </Footer>
    </>
  )
}

export default PortfolioEdit
