import React from 'react'
import styled from 'styled-components'

import {
  ExplainerLink,
  LinkIcon,
} from '../../yb_components/styleguide/links/Link.styles'
import NoteCard from '../cards/NoteCard'
import Co2ProjectionsChart from '../charts/Co2ProjectionsChart'
import Card from '../styleguide/Card'
import CardTitle from '../styleguide/CardTitle'
import SectionWrapper from '../styleguide/SectionWrapper'
import TextSection from '../styleguide/TextSection'
import TextView from '../styleguide/TextView'

const CustomExplainerLink = styled(ExplainerLink)`
  float: right;
  margin-top: 5px;
`

const Co2ProjectionsCard = ({ projections }) => {
  const title = 'Current & Projected CO₂ Emissions'

  const commitment_and_comments_present =
    projections && (projections.commitments || projections.comments)
  const innerHeight = !commitment_and_comments_present && '100%'
  const yPosXaxis = !commitment_and_comments_present && '72%'
  const xPosXaxis = commitment_and_comments_present && '47%'
  const yPos = !commitment_and_comments_present && '30%'
  const long_projection_text =
    (projections.commitments && projections.commitments.length > 450) ||
    (projections.comments && projections.comments.length > 450)
  const commitment_and_comments_height = long_projection_text ? 975 : 800
  const commitment_and_comments_margin = long_projection_text
    ? '-265px 0px 0px 0px'
    : '-185px 0px 0px 0px'

    
  return (
    <Card
      height={
        commitment_and_comments_present ? commitment_and_comments_height : 550
      }
    >
      <CardTitle title={title.toUpperCase()} />
      <div className='float-right'>
        <CustomExplainerLink
          href='http://help.yves.blue/en/articles/6075942-the-company-carbon-projection'
          target='_blank'
        >
          <LinkIcon className='far fa-question-circle' />
        </CustomExplainerLink>
        <br />
      </div>
      <TextView
        marginBottom={'5px'}
        marginTop={'5px'}
        size={15.5}
        text={`
              "Projected Total CO₂ Emissions" shows the company's current emissions trajectory to 2050 compared
              to what its emissions would need to be if the company were to operate within the global carbon budget
              ("Budgeted CO₂ Emissions"), a declining yearly limit of global emissions over the next 8 years. "Committed
              CO₂ Emissions" shows if the company has made a carbon reduction commitment from its current trajectory.
            `}
      />
      <div style={{ height: innerHeight }} className='line-graph-wrapper'>
        <div style={{ top: yPos }} className='line-chart-y-axis-co2'>
          CO₂ Emissions (metric tonnes)
        </div>
        <div
          style={{ height: innerHeight }}
          className='line-chart-inner-wrapper'
        >
          <Co2ProjectionsChart projections={projections.projections} />
          <div
            style={{ top: yPosXaxis, left: xPosXaxis }}
            className='line-chart-x-axis-co2'
          >
            Time (years)
          </div>
        </div>
      </div>
      {commitment_and_comments_present && (
        <SectionWrapper
          wrap='nowrap'
          margin={commitment_and_comments_margin}
          width='100%'
        >
          {projections && projections.commitments && (
            <NoteCard
              maxWidth={!projections.comments && '550px;'}
              margin='0px 15px 0px 0px'
              width='50%'
              flex='1'
            >
              <CardTitle title='SBTI Commitment Notes:' />
              <TextSection
                style={{ height: '80%' }}
                height='80%'
                fontSize='14px'
                fontWeight='normal'
              >
                {projections.commitments}
              </TextSection>
            </NoteCard>
          )}
          {projections && projections.comments && (
            <NoteCard width='50%' flex='1'>
              <CardTitle title='YvesBlue Comments:' />
              <TextSection
                style={{ height: '80%' }}
                fontSize='14px'
                fontWeight='normal'
              >
                {projections.comments}
              </TextSection>
            </NoteCard>
          )}
        </SectionWrapper>
      )}
    </Card>
  )
}

export default Co2ProjectionsCard
