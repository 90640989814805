import styled from 'styled-components'

export const StyledTable = styled.table`
  font-family: Lato;
  font-style: normal;

  tr:nth-child(even) {
    background: white;
  }
  tr:nth-child(odd) {
    background: #f8f9fb;
  }
`

export const MetricCategoryTableHeader = styled.th`
  border-radius: 2px;
  color: #2e384d;
  background: #e8e9eb;
  height: 48px;
  padding: 15px 26px;
`

export const MetricNameCell = styled.td`
  color: #00127c;
  font-weight: normal;
  font-size: 15px;
  padding-left: 27px;
  width: 25vw;
`

export const MetricCell = styled.td`
  width: 10vw;
`

export const MetricTableRow = styled.tr`
  height: 48px;
`

export const AsOfHeaderRow = styled.tr`
  background: white !important;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: #002fa7;
  padding-bottom: 12px;
  height: 30px;

  .as-of-link {
    margin-bottom: 10px;
  }
  a {
    color: #002fa7;
    text-decoration: none;
  }
  .icon-cell {
    border-top: 1px solid #dbdbdb;
  }
`
