import axios from "axios";

export const getNewOtpSecret = () => {
    const url = `/app/api/otps/secrets/new`;
    return axios.get(url);
};

export const postOtpSecrets = ({ otpSecret, otpAttempt }) => {
    const url = `/app/api/otps/secrets`;

    const params = {
        otp:
            {
                secret_attempt: otpAttempt
            }
    };

    const headers = { Authorization: 'Bearer ' + otpSecret };

    return axios.post(url, params,{ headers: headers})
};

export const deleteOtpSecrets = () => {
    const url = `/app/api/otps/secrets`;
    return axios.delete(url);
};
