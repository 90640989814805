import styled from 'styled-components'

export const StyledSection = styled.section`
  display: flex;
  gap: 1.25rem;
  flex-direction: column;
  @media screen and (min-width: 992px) {
    flex-direction: row;
  }

  aside {
    margin: 0 auto;
    max-width: 170px;
  }
  form {
    width: 100%;
  }
  main {
    margin: 0 0 1.25rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(max(300px, 30vw), 1fr));
    grid-template-rows: auto;
    grid-gap: 1.25rem;
  }
  .split-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    grid-template-rows: auto;
    grid-gap: 1.25rem;
  }
  .form-group {
    margin: 0;
    flex-grow: 1;
  }
  .form-label {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #979ca6;
  }
  .form-control {
    padding: 0.5rem 1.25rem;
    min-height: 3rem;
    border-radius: 6px;
    border: 1px solid #e6e7ea;
    background-color: #f4f6f9;
    font-size: 14px;
    line-height: 1.5;
    color: #2e384d;
    &.is-invalid {
      background-position: right 1.25rem center;
    }
  }
  .user-select {
    padding: 0.5rem 1.25rem;
    -webkit-appearance: none; // hide arrow
    /* background-image: url("data:image/svg+xml,%3Csvg width='9' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.01 5.304L8.3 2.065a.8.8 0 000-1.144.833.833 0 00-1.163 0L4.428 3.587 1.72.921a.833.833 0 00-1.164 0 .8.8 0 000 1.144l3.29 3.239a.831.831 0 001.163 0z' fill='%23979CA6'/%3E%3C/svg%3E"); */
    background-size: 16px 7px;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1l8 7 8-7' stroke='%23979CA6' stroke-width='2'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 1.25rem center;
    &.is-invalid {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
    &:hover {
      cursor: pointer;
    }
    &:disabled {
      cursor: auto;
      color: #979ca6;
    }
  }
  .submit-btn {
    padding: 6px 24px;
    min-height: 3rem;
    float: right;
    border: 1px solid #002fa7;
    border-radius: 25px;
    background-color: #002fa7;
    font-weight: 900;
    font-size: 14px;
    line-height: 1.4;
    text-transform: uppercase;
  }
`
