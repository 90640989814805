import React from 'react';

const ErrorModule = ({errors}) => {
    return (
        <>
            {
                errors.map((error, i) => (
                    <div key={i} className="alert alert-danger">{error}</div>
                ))
            }
        </>
    );
};

export default ErrorModule;