import React, { forwardRef } from 'react'

// eslint-disable-next-line react/display-name
export const HtmlFileIcon = forwardRef(() => (
  <svg
    width='46'
    height='60'
    viewBox='0 0 46 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18.6183 32.4258C17.8892 31.6934 16.7073 31.6934 15.9793 32.4258L10.3793 38.0508C9.65017 38.7832 9.65017 39.9703 10.3793 40.7016L15.9793 46.3266C16.3433 46.6875 16.8217 46.875 17.3 46.875C17.7783 46.875 18.2553 46.692 18.6195 46.3256C19.3487 45.5932 19.3487 44.4061 18.6195 43.6748L14.3367 39.375L18.6172 35.0754C19.3533 34.3477 19.3533 33.1523 18.6183 32.4258ZM43.1183 14.6836L30.6933 2.20312C29.2817 0.790078 27.3917 0 25.4083 0H7.96667C3.8425 0 0.5 3.35859 0.5 7.5L0.500758 52.5C0.500758 56.6414 3.84326 60 7.96743 60H37.8333C41.9575 60 45.3 56.6414 45.3 52.5V19.9805C45.3 17.9883 44.5183 16.0898 43.1183 14.6836ZM26.6333 3.99375C27.1557 4.1768 27.6444 4.44422 28.0473 4.84887L40.4723 17.3293C40.8783 17.7305 41.1467 18.2227 41.3217 18.75H28.5C27.4733 18.75 26.6333 17.9063 26.6333 16.875V3.99375ZM41.5667 52.5C41.5667 54.5672 39.8913 56.25 37.8333 56.25H7.96667C5.90867 56.25 4.23333 54.5672 4.23333 52.5V7.5C4.23333 5.43281 5.90867 3.75 7.96667 3.75H22.9V16.875C22.9 19.9805 25.4083 22.5 28.5 22.5H41.5667V52.5ZM27.1817 35.0742L31.4633 39.375L27.1828 43.6746C26.4537 44.407 26.4537 45.5941 27.1828 46.3254C27.5433 46.6875 28.0217 46.875 28.5 46.875C28.9783 46.875 29.4553 46.692 29.8195 46.3256L35.4195 40.7006C36.1487 39.9682 36.1487 38.7811 35.4195 38.0498L29.8195 32.4248C29.0903 31.6924 27.9085 31.6924 27.1805 32.4248C26.4525 33.1573 26.4467 34.3477 27.1817 35.0742Z'
      fill='currentColor'
    />
  </svg>
))
