import axios from 'axios';

export const getSubsidiesStats = (id, { query, targetPath }) => {
    const fTargetPath = targetPath ? targetPath : 'instruments';
    return axios.get(`/app/api/${fTargetPath}/${id}/subsidies/stats${query}`);
};

export const getSubsidies = (id, { query, targetPath}) => {
    const fTargetPath = targetPath ? targetPath : 'instruments';
    return axios.get(`/app/api/${fTargetPath}/${id}/subsidies${query}`);
};
