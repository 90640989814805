import React from 'react'
import { Form } from 'react-bootstrap'
import { useController } from 'react-hook-form'
import styled from 'styled-components'

import { baseFocusCss } from '../styles/BaseForm.styles'

const StyledInput = styled(Form.Control)`
  margin: 0;
  padding: 10px;
  min-height: 48px;
  font-size: 14px;
  border: 1px solid #e6e7ea;
  border-radius: 6px;

  ${baseFocusCss}

  // placeholder
  ::placeholder {
    color: #979797;
    font-style: italic;
  }

  // hide number input spinbox arrows
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export const BaseInput = props => {
  const { field } = useController(props)

  return <StyledInput {...field} {...props} className='form-control' />
}
