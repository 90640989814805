import PropTypes from 'prop-types'
import qs from 'query-string'
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Nav } from 'react-bootstrap'
import styled from 'styled-components'

import { hedgeFundUnits } from '../../../helpers/unit'
import { getEntityById } from '../../../repositories/entities_repository'
import { StyledTabNav } from '../../../yb_components/styleguide/navs/tab_nav/TabNav'
import { StyledCard } from './components/cards/RiskAssessmentCard.styles'
import RiskAssessmentTable from './components/tables/RiskAssessmentTable'
import { mapInitiativesToState } from './mappers/initiatives_mapper'
import { getEsgByEntityId, getInitiatives } from './repository/esg_respository'

const StyledHeader = styled.header`
  margin: 1.25rem 0 0;
  .breadcrumb {
    margin: 0 0 1.25rem 0;
    padding-left: 1.25rem;
  }
  a {
    color: #002fa7;
  }
  h1 {
    margin: 0 0 1rem 0;
    font-weight: 700;
    font-size: 30px;
    line-height: 1.1;
  }
`

const CUSTOM_OPTION = { id: 'custom', label: 'Custom Initiative' }

const loadInitiatives = ({ setInitiatives, entityId }) => {
  if (entityId != null) {
    getInitiatives({ entityId }).then(res => {
      setInitiatives(mapInitiativesToState(res.initiatives))
    })
  }
}
// breadcrumb
const portfolioName = qs.parse(window.location.search).portfolioName
const portfolioId = qs.parse(window.location.search).portfolioId

export const EntityEsgPage = ({ entityId }) => {
  // load entity data
  const [entity, setEntity] = useState(null)
  const loadEntityById = async () => {
    const res = await getEntityById(entityId)
    setEntity(res)
  }
  // load esg data
  const [esgData, setEsgData] = useState(null)
  const loadEsgByEntityId = async () => {
    const res = await getEsgByEntityId(entityId)
    setEsgData(res)
  }
  useEffect(() => {
    loadEntityById()
    loadEsgByEntityId()
  }, [])

  const [initiatives, setInitiatives] = useState(null)
  useEffect(loadInitiatives.bind(null, { setInitiatives, entityId }), [])

  const [options, setOptions] = useState({
    environmental: [CUSTOM_OPTION],
    social: [CUSTOM_OPTION],
    governance: [CUSTOM_OPTION],
    units: hedgeFundUnits,
  })

  useEffect(() => {
    if (initiatives) {
      setOptions({
        environmental: [...initiatives.environmental, CUSTOM_OPTION],
        social: [...initiatives.social, CUSTOM_OPTION],
        governance: [...initiatives.governance, CUSTOM_OPTION],
        units: hedgeFundUnits,
      })
    }
  }, [initiatives])

  const onCreateDialogSuccess = () => {
    loadInitiatives({ setInitiatives, entityId })
  }

  return (
    <div className='page__container'>
      <StyledHeader>
        <Breadcrumb>
          <Breadcrumb.Item href='/app'>Home</Breadcrumb.Item>
          {portfolioId && portfolioName && (
            <>
              <Breadcrumb.Item href='/app/portfolios'>
                Portfolios
              </Breadcrumb.Item>
              <Breadcrumb.Item href={'/app/portfolios/' + portfolioId}>
                {portfolioName}
              </Breadcrumb.Item>
            </>
          )}
          {entity && <Breadcrumb.Item active>{entity.name}</Breadcrumb.Item>}
          <Breadcrumb.Item active>Engagement Portal</Breadcrumb.Item>
        </Breadcrumb>
        {entity ? (
          <h1>{entity.name}</h1>
        ) : (
          <div style={{ margin: '0 0 1rem', height: '33px' }}></div>
        )}
      </StyledHeader>
      {/* tab nav */}
      <StyledTabNav style={{ marginBottom: '20px' }} data-cy='tab_nav'>
        <Nav defaultActiveKey='esg_factors' as='ul'>
          <Nav.Item>
            <Nav.Link eventKey='esg_factors'>ESG Factors</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey='sasb_materiality'
              href={entity ? `/app/entities/${entity.id}/sasb` : `#`}
              disabled={!entity}
            >
              SASB Materiality
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='pai' disabled>
              PAI
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='board_vote_database' disabled>
              Board Vote Database
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </StyledTabNav>
      <StyledCard data-cy='risk_assessment_card'>
        <h3 style={{ marginBottom: '1rem' }}>ESG Factors</h3>
        {esgData && (
          <>
            <RiskAssessmentTable
              onCreateDialogSuccess={onCreateDialogSuccess}
              options={options.environmental}
              units={options.units}
              riskAssessment={esgData.environmental}
              entityId={entityId}
              category='environmental'
              title='Environmental Issues'
              dataCy='environmental_issues_table'
            />
            <RiskAssessmentTable
              onCreateDialogSuccess={onCreateDialogSuccess}
              options={options.social}
              units={options.units}
              riskAssessment={esgData.social}
              entityId={entityId}
              category='social'
              title='Social Issues'
              dataCy='social_issues_table'
            />
            <RiskAssessmentTable
              onCreateDialogSuccess={onCreateDialogSuccess}
              options={options.governance}
              units={options.units}
              riskAssessment={esgData.governance}
              entityId={entityId}
              category='governance'
              title='Governance Issues'
              dataCy='governance_issues_table'
            />
          </>
        )}
      </StyledCard>
    </div>
  )
}

EntityEsgPage.propTypes = {
  entityId: PropTypes.number.isRequired,
}
