import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as d3 from 'd3';
import numeral from 'numeral';

import ChartGridLine from '../styleguide/ChartGridLine';
import ChartLabel from '../styleguide/ChartLabel';
import ChartDataColumn from '../styleguide/ChartDataColumn';
import Tooltip from '../styleguide/Tooltip';
import TextView from '../styleguide/TextView';
import LineChartBody from './components/line-chart/LineChartBody';

const Div = styled.div`
  position: relative;
`;

const getChartData = (data, benchmarkData, width, height) => {
  const padding = 10,
        yLabelsWidth = 50,
        xLabelsHeight = 20;

  const chartX1 = padding + yLabelsWidth,
        chartX2 = width - padding,
        chartY1 = padding,
        chartY2 = height - padding - xLabelsHeight,
        chartWidth = width - yLabelsWidth - 2 * padding,
        chartHeight = height - 2 * padding,
        dataWidth = Math.round(chartWidth / data.length);

  // D3 setup
  const scaleData = data
  const max = d3.max(scaleData.map(d => parseFloat(d.amount)));

  // create ticks
  const ticks = d3.ticks(0, max, 2);
  if(ticks[ticks.length - 1] < max) {
    ticks.push(ticks[ticks.length - 1] + ticks[1]);
  }

  const xAxis = d3.scaleLinear()
      .domain([0, data.length])
      .range([chartX1, chartX2]);

  const yAxis = d3.scaleLinear()
      .domain([0, ticks[ticks.length - 1]])
      .range([chartY2, chartY1]);

  return {
    xAxis, yAxis, ticks,
    dimensions: {
        padding,
        yLabelsWidth,
        chartX1,
        chartY1,
        chartX2,
        chartY2,
        chartWidth,
        chartHeight,
        dataWidth,
    }
  };
}

const SubsidiesChart = ({ data, color, benchmarkData, className, id }) => {
  const height = 170,
        chartId = id + "-bar-chart";

  const [chartData, setChartData] = useState(null);
  const [hoverEl, setHoverEl] = useState(null);

  const resizeChart = () => {
    const onResize = () => {
      const chartEl = document.getElementById(chartId);
  
      if(!chartEl) {
        console.error(`Chart with id '${chartId}' is null`);
        return;
      }
      
      setChartData(getChartData(data, benchmarkData, chartEl.clientWidth, height));
    }
  
    // set initial width
    onResize();
  
    window.addEventListener('resize', onResize);
  
    return () => window.removeEventListener('resize', onResize);
  }

  useEffect(() => resizeChart(), []);

  const renderGrid = () => {
    const { ticks, yAxis, dimensions: { chartX1, chartX2 }} = chartData;

    return ticks.map(t => {
      return <ChartGridLine key={t} x1={chartX1} y1={yAxis(t)} x2={chartX2} y2={yAxis(t)} />
    });
  }

  const renderYLabels = () => {
    const { yAxis, xAxis, ticks } = chartData;
    return (
      <text>
        {ticks.map(t => {
          return <ChartLabel key={t} textAnchor="end" x={xAxis(0) - 10} y={yAxis(t) + 3}  label={'$' + numeral(t).format('0[.]0a').toUpperCase()} />
        })}
      </text>
    );
  }

  const renderXLabels = () => {
    const { xAxis, yAxis } = chartData;

    const y = yAxis(0) + 16;
    return (
      <text>
        <ChartLabel x={xAxis(0)} y={y} label={data[0]?.year}/>
        <ChartLabel x={xAxis(data.length / 2)} y={y} label={data[~~ (data.length / 2)]?.year} textAnchor="middle"/>
        <ChartLabel x={xAxis(data.length)} y={y} label={data[data.length - 1]?.year} textAnchor="end"/>
      </text>
    );
  }

  const renderData = () => {
    const { xAxis, yAxis, dimensions: { dataWidth, padding }} = chartData

    return data.map((d, i) => {
      const barWidth = 0.6 * dataWidth;
      const barHeight = yAxis(0) - yAxis(parseFloat(d.amount));
      const x = xAxis(i);

      return (
        <ChartDataColumn
          key={i}
          transform={`translate(${(dataWidth - barWidth) / 2})`}
          radius={5}
          x={x}
          y={yAxis(0)}
          width={barWidth}
          height={barHeight}
          color={color}
          onMouseEnter={() => setHoverEl(i)}
          onMouseLeave={() => setHoverEl(null)}
        />
      );
    });
  }

  const renderTooltips = () => {
    const { xAxis, yAxis, dimensions: { dataWidth } } = chartData;
    return data.map((d, i) => {
      const y = yAxis(d.amount);
      const x = xAxis(i) + Math.round(dataWidth / 2);
      return <Tooltip key={i} padding={10} show={hoverEl === i} x={x} y={y} render={() => 
        <>
          <TextView bold center text={<>${numeral(d.amount).format('a0.[0]').toUpperCase()}</>}/>
        </>
      }/>
    });
  }

  const renderBenchmark = () => {
    const { xAxis, yAxis, dimensions: { dataWidth, padding }} = chartData

    const barWidth = 0.6 * dataWidth;
    const data = benchmarkData.map((bd, i) => ({x: i , y: bd.amount}))
    
    const xFuncLine = (d) => {
      return xAxis(d) + barWidth - 0.15*barWidth
    }

    return benchmarkData && (
        <LineChartBody
          data={data}
          xscale={xFuncLine}
          yscale={yAxis}
          radius={3}
          svgstyles={{dotStroke:"#E7BB4C", dotFill:"#E7BB4C", lineStroke:"#E7BB4C", strokeDasharray: "10,10", lineFill: "none", lineWidth:2.5, circleWidth:3.25}}
        />
      )
  }
  
  return (
    <Div className={className}>
      <svg id={chartId} height={height} width="100%">
        {chartData && <>
          {renderGrid()}
          {renderYLabels()}
          {renderXLabels()}
          {renderData()}
          {benchmarkData && renderBenchmark()}
        </>}
      </svg>
      {chartData && renderTooltips()}
    </Div>
  );
}

export default SubsidiesChart;