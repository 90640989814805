import {
  ESG_SCALE_COLOR_ABOVE_AVERAGE,
  ESG_SCALE_COLOR_BAD,
  ESG_SCALE_COLOR_BELOW_AVERAGE,
  ESG_SCALE_COLOR_EXCELLENT,
  ESG_SCALE_COLOR_GOOD,
  ESG_SCALE_COLOR_POOR,
  ESG_SCALE_COLOR_SUPERIOR,
} from '../../../../styles/colors'

export const defaultLabels = [
  {
    index: 0,
    label: 'Bad',
    min: 0,
    max: 41.99,
    color: ESG_SCALE_COLOR_BAD,
  },
  {
    index: 1,
    label: 'Poor',
    min: 42,
    max: 45.99,
    color: ESG_SCALE_COLOR_POOR,
  },
  {
    index: 2,
    label: 'Below Average',
    min: 46,
    max: 49.99,
    color: ESG_SCALE_COLOR_BELOW_AVERAGE,
  },
  {
    index: 3,
    label: 'Above Average',
    min: 50,
    max: 53.99,
    color: ESG_SCALE_COLOR_ABOVE_AVERAGE,
  },
  {
    index: 4,
    label: 'Good',
    min: 54,
    max: 57.99,
    color: ESG_SCALE_COLOR_GOOD,
  },
  {
    index: 5,
    label: 'Superior',
    min: 58,
    max: 61.99,
    color: ESG_SCALE_COLOR_SUPERIOR,
  },
  {
    index: 6,
    label: 'Excellent',
    min: 62,
    max: 100,
    color: ESG_SCALE_COLOR_EXCELLENT,
  },
]
