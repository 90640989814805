import React, { useState, useEffect } from "react";

import SectionWrapper from "../../../components/styleguide/SectionWrapper";
import Card from "../../../components/styleguide/Card";
import CardTitle from "../../../components/styleguide/CardTitle";
import Table from "../../../components/styleguide/Table";
import TextInput from "../../../components/styleguide/TextInput";
import Button from "../../../components/styleguide/Button";
import Link from "../../../components/styleguide/Link";
import { getAPIKeys, postAPIKeys, deleteAPIKey } from "../../../repositories/api_keys_repository";

import WrapperColumn from "../../../components/styleguide/WrapperColumn";

const OrganizationAPIKeysCard = () => {
    const [APIKeys, setAPIKeys] = useState([]);
    const [generatedAPIKey, setGeneratedAPIKey] = useState(null);
    const [newAPIKeyName, setNewAPIKeyName] = useState("");
    const [disableSubmit, setDisableSubmit] = useState(true);

    useEffect(() => {
        getAPIKeys().then((response) => {
            setAPIKeys(response.data);
        })
    }, []);

    const tableRows = () => {
        return APIKeys.map((APIKey) => {
            return([
                <span>{APIKey.name}</span>,
                <Link onClick={() => onDeleteAPIKey(APIKey.id)}>DELETE</Link>
            ])
        })
    }

    const onDeleteAPIKey = (keyID) => {
        deleteAPIKey({keyID}).then((response) => {
            let newAPIKeys = APIKeys.filter((key) =>  key.id !== keyID);
            setAPIKeys(newAPIKeys);

            if (generatedAPIKey && generatedAPIKey.id === keyID) {
                setGeneratedAPIKey(null);
            }
        });
    };

    const onCopyToClipboardClick = () => {
        navigator.clipboard.writeText(generatedAPIKey.auth_token);
    };

    const onSubmit = () => {
        postAPIKeys({ name: newAPIKeyName }).then((response) => {
            const generatedKey = response.data;

            let newAPIKeys = APIKeys.slice();
            newAPIKeys.unshift(generatedKey);

            onNewAPIKeyNameChange("");
            setAPIKeys(newAPIKeys);
            setGeneratedAPIKey(generatedKey);
        })
    };

    const onNewAPIKeyNameChange = (name) => {
        setNewAPIKeyName(name);

        if (name.length > 0) {
            setDisableSubmit(false);
        } else {
            setDisableSubmit(true);
        }
    }

    const tableHeaders = [{ label: "NAME" }, {label: ""}];

    return(<Card>
        <CardTitle title="API Keys"/>
        <WrapperColumn styles={{ width: "50%" }}>
            <SectionWrapper margin="0 0 20px 0">
                API Tokens can be used to retrieve data from the YvesBlue REST API.  To ensure
                security, when you generate a new API token, it will only be displayed once, so ensure
                you write it down.
            </SectionWrapper>

            {
                generatedAPIKey &&
                    <SectionWrapper direction="column" border="1px solid" borderRadius="10px" margin="0 0 20px 0" padding="10px">
                        <SectionWrapper direction="column">
                            <b>API Key "{ generatedAPIKey.name }" generated.</b>
                        </SectionWrapper>
                        <SectionWrapper margin="0 0 30px 0">
                            This is the only time you will be able to see this key, so please write it down:
                        </SectionWrapper>
                        <SectionWrapper>
                            { generatedAPIKey.auth_token.match(/.{1,5}/g).join(" ")}
                            <div style={ { margin: "0 0 0 5px"}}>
                                <Link onClick={onCopyToClipboardClick}>Copy to Clipboard</Link>
                            </div>
                        </SectionWrapper>
                    </SectionWrapper>
            }
            <SectionWrapper >
                {
                    APIKeys.length > 0 && <Table body={tableRows()} headers={tableHeaders}/>
                }

                {
                    APIKeys.length === 0 && <>You have no API Keys, yet.</>
                }

            </SectionWrapper>

            <SectionWrapper>
                <SectionWrapper direction="column">
                    <TextInput label="API Key Name"
                               value={newAPIKeyName}
                               onChange={(id, value, e) => onNewAPIKeyNameChange(value) }
                    />
                    <Button label="Generate API Key" disabled={disableSubmit} onClick={onSubmit}/>
                </SectionWrapper>
            </SectionWrapper>

        </WrapperColumn>
    </Card>)
};

export default OrganizationAPIKeysCard;
