import propTypes from 'prop-types'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { confirmable, createConfirmation } from 'react-confirm'

import { StyledFooter, StyledHeader, StyledMain } from './ConfirmModal.styles'

export const ConfirmModal = ({ details, show, proceed }) => {
  return (
    <Modal
      id='confirm_modal'
      show={show}
      onHide={() => proceed(false)}
      centered
    >
      {details?.title && (
        <StyledHeader>
          <h4>{details.title}</h4>
        </StyledHeader>
      )}
      {details?.content && (
        <StyledMain>
          <p>{details.content}</p>
        </StyledMain>
      )}
      <StyledFooter>
        <Button
          variant='link'
          onClick={() => proceed(false)}
          className='cancel-button'
        >
          Cancel
        </Button>
        <Button
          variant='primary'
          onClick={() => proceed(true)}
          className='confirm-button'
        >
          {details?.buttonText ? details.buttonText : 'Confirm'}
        </Button>
      </StyledFooter>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  details: propTypes.object,
  show: propTypes.bool,
  proceed: propTypes.func,
}

export function confirm(details) {
  return createConfirmation(confirmable(ConfirmModal))({
    details,
  })
}
