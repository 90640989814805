import React from 'react';

const Card = ({ title, children, className, full, style = {} }) => {
  return (
    <div style={style} className={`card ${className || ""} ${full ? 'full' : ''}`}>
      <div className="card-box">
        {title ? <h3>{title}</h3> : null}
        {children}
      </div>
    </div>
  );
}

export default Card;