import getTestingProps from '@utils/test/getTestingProps'
import React from 'react'

import {
  default as Controller,
  TableController,
  TTableHeader,
  TTableSection,
} from './Table.controller'
// import theme from './Table.module.scss'
import './Table.module.scss'

export interface IProps {
  header: TTableHeader
  data: TTableSection<unknown>[]
  maxColSpan?: number
  renderer?: TableController<unknown>
  name: string
}

export const Table: React.FC<IProps> = ({
  header,
  data,
  name,
  renderer = Controller,
  maxColSpan = header.length,
}) => {
  return (
    <table className='Table' {...getTestingProps(name)}>
      <thead>
        <tr>
          {header?.map(headerData => renderer.onRenderHeader(headerData))}
        </tr>
      </thead>
      <tbody>
        {data?.map(section => renderer.onRenderSection(section, maxColSpan))}
      </tbody>
    </table>
  )
}
