import classNames from 'classnames'
import React from 'react'

// import theme from './AlertCard.module.scss'
import './AlertCard.module.scss'

const AlertCardType = {
  success: classNames('far fa-check-circle', 'success'), // far fa-badge-check
  warning: classNames('far fa-engine-warning', 'warning'),
  danger: classNames('fas fa-exclamation', 'danger'),
  error: classNames('fas fa-times-octagon', 'danger'),
} as const

export interface IProps extends YB.IWithChildren {
  title: string
  type: keyof typeof AlertCardType
  layout?: 'horizontal' | 'vertical'
  className?: string
}

const AlertCard: React.FC<IProps> = ({
  title,
  type,
  children,
  layout = 'vertical',
  className = '',
}) => {
  return (
    <div
      className={classNames('AlertCard', className, {
        horizontal: layout === 'horizontal',
      })}
    >
      <i className={classNames(AlertCardType[type], 'icon')} />
      <div>
        <h2>{title}</h2>
        <div className='content'>{children}</div>
      </div>
    </div>
  )
}

export default AlertCard
