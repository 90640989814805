import dayjs from 'dayjs'
import Honeybadger from 'honeybadger-js'
import qs from 'qs'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Card from '../../../../components/styleguide/Card'
import DeletePortfolioDialog from '../../../../components/styleguide/DeletePortfolioDialog'
import Link from '../../../../components/styleguide/Link'
import { putToggleFavorite } from '../../../../repositories/portfolio_repository'
import { BreadcrumbNav } from '../../../../yb_components/styleguide/breadcrumbs/BreadcrumbNav'
import FilledButton from '../../../../yb_components/styleguide/buttons/filled_button/FilledButton'
import FlatSearchBar from '../../../../yb_components/styleguide/inputs/FlatSearchBar'
import Pagination from '../../../../yb_components/styleguide/pagination/Pagination'
import FlatTable from '../../../../yb_components/tables/flat_table/FlatTable'
import { buildQueryString } from '../../../../yb_helpers/queries'
import FavoriteTableCell from './components/FavoriteTableCell'
import { BREADCRUMB_LINKS, HEADERS } from './PortfolioIndex.constants'
import { loadPortfolios, validateScore } from './PortfolioIndex.helpers'
import {
  StyledAsOfCell,
  StyledButtonContainer,
  StyledContainer,
  StyledH1,
  StyledPaginatorContainer,
  StyledTitleContainer,
} from './PortfolioIndex.styles'

const CustomFlatTable = styled(FlatTable)`
  --min-height: 660px;
`

const PortfolioIndex = () => {
  /* Hooks */
  const [portfolios, setPortfolios] = useState([])
  const [sort, setSort] = useState({ field: 'name', dir: 'asc' })
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [showDelete, setShowDelete] = useState(false)
  const [targetPortfolio, setTargetPortfolio] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [favoriteLimitReached, setFavoriteLimitReached] = useState(false)

  const location = useLocation()
  const history = useHistory()

  const locationSearch = qs.parse(location?.search, { ignoreQueryPrefix: true })
  const [searchQuery, setSearchQuery] = useState(
    locationSearch?.search ? locationSearch.search : null
  )

  useEffect(
    loadPortfolios.bind(
      null,
      { setIsLoading, setPortfolios, setTotalPages },
      buildQueryString({ sort, page: currentPage, searchQuery })
    ),
    [sort, currentPage, searchQuery]
  )

  useEffect(() => {
    let pathName = `/app/portfolios`
    if (searchQuery) pathName += `?search=${searchQuery}`
    if (location?.pathname !== pathName) history.replace({ pathname: pathName })
  }, [searchQuery])

  /* Events */
  const onNavigate = url => window.location.assign(url)
  const onSearch = value => setSearchQuery(value)
  const onPaginatorChange = value => setCurrentPage(value)
  const onDeletePortfolio = portfolio => {
    setTargetPortfolio(portfolio)
    setShowDelete(true)
  }

  const onFavoriteClick = (portfolioId, isFavorite) => {
    putToggleFavorite(portfolioId, isFavorite)
      .then(res => {
        const updatedPortfolio = res?.data
        if (!updatedPortfolio) setFavoriteLimitReached(true)
        else setFavoriteLimitReached(false)

        const newPortfolios = portfolios.map(portfolio =>
          portfolio.id === updatedPortfolio.id ? updatedPortfolio : portfolio
        )
        setPortfolios(newPortfolios)
      })
      .catch(err => {
        console.error('err', err)
        Honeybadger.notify(err)
      })
  }

  /* Render Functions */
  const computeTableBody = (portfolios, onDeletePortfolio) => {
    return portfolios.map(p => [
      <FavoriteTableCell
        isFavorite={p.favorite}
        key={p.id}
        onFavoriteClick={onFavoriteClick}
        portfolio={p}
      />,
      <StyledAsOfCell key={p.id}>
        {p.as_of ? dayjs(p.as_of).format('MMMM D, YYYY') : '—'}
      </StyledAsOfCell>,
      validateScore(p.total_score),
      validateScore(p.e_score),
      validateScore(p.s_score),
      validateScore(p.g_score),
      <Link key={p.id} onClick={() => onDeletePortfolio(p)}>
        Delete
      </Link>,
    ])
  }

  /* Memoized Data */
  const memoizedTableBody = useMemo(
    () => computeTableBody(portfolios, onDeletePortfolio),
    [portfolios]
  )

  return (
    <StyledContainer>
      <BreadcrumbNav links={BREADCRUMB_LINKS} />
      {favoriteLimitReached && (
        <div className='alert alert-primary'>
          You have reached the maximum limit of favorite portfolios.
        </div>
      )}
      <Card className='portfolios-list' padding={20} margin='20px 0 0 0'>
        <StyledTitleContainer>
          <StyledH1>Portfolios</StyledH1>
          <div>
            <StyledButtonContainer marginRight={'10px'}>
              <FilledButton
                label='Upload New Portfolio'
                onClick={() =>
                  onNavigate('/app/portfolios/snapshot_uploads/new')
                }
              />
            </StyledButtonContainer>
            <StyledButtonContainer>
              <FilledButton
                label='Create Landscape'
                onClick={() => onNavigate('/app/portfolios/comparisons')}
              />
            </StyledButtonContainer>
          </div>
        </StyledTitleContainer>

        <FlatSearchBar defaultSearch={searchQuery} onSubmit={onSearch} />

        <CustomFlatTable
          headers={HEADERS}
          body={memoizedTableBody}
          sort={sort}
          onSort={(field, dir) => setSort({ field, dir })}
          isLoading={isLoading}
          data={portfolios}
          noDataText={
            <>
              <div>No portfolios were found for your search query.</div>
              <div>Please try again.</div>
            </>
          }
        />
        <StyledPaginatorContainer>
          <Pagination
            max={totalPages}
            selected={currentPage}
            onChange={onPaginatorChange}
          />
        </StyledPaginatorContainer>
      </Card>

      {targetPortfolio && (
        <DeletePortfolioDialog
          title={'Delete Portfolio: ' + targetPortfolio.name}
          displayed={showDelete}
          portfolios={portfolios}
          portfolioName={targetPortfolio.name}
          portfolioId={targetPortfolio.id}
          snapshotId={targetPortfolio.snapshot_id}
          setDisplayed={setShowDelete}
          setPortfolios={setPortfolios}
        />
      )}
    </StyledContainer>
  )
}

export default PortfolioIndex
