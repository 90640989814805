import React, { useState } from 'react'

import ErrorPage from '../../components/styleguide/ErrorPage'
import { putUserAgreementAccept } from '../../repositories/user_agreement_repository'
import useNavigation from '../../yb_hooks/useNavigation'
import ClickwrapModal from './components/ClickwrapModal'
import { UserAgreementContainer } from './UserAgreement.styles'

const UserAgreement = () => {
  /* Hooks */
  const { navigateTo } = useNavigation()
  const [error, setError] = useState(null)

  /* Events */
  const onAcceptAgreement = () => {
    putUserAgreementAccept()
      .then(response => {
        if (response.status == 200) navigateTo('/app')
      })
      .catch(err => {
        setError(err.response ? err.response.status : 502)
      })
  }

  const onCloseAgreement = () => {
    navigateTo('/app/sign_out')
  }

  /* Render */
  if (error)
    return <ErrorPage code={error} message={error == 404 ? 'NOT FOUND' : ''} />
  return (
    <div style={{ position: 'relative' }}>
      <UserAgreementContainer></UserAgreementContainer>
      <ClickwrapModal
        data-cy={'user_agreement_clickwrap'}
        show={true}
        onAccept={onAcceptAgreement}
        onClose={onCloseAgreement}
      />
    </div>
  )
}

export default UserAgreement
