import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { BodyText } from '../../../../../../yb_styles/typography'
import { TcfdTableHead } from './TcfdTableHead'
import { TcfdTableRow } from './TcfdTableRow'

const StyledContainer = styled.div`
  margin-top: 50px;
`

const StyledTable = styled.table`
  border-collapse: collapse;
  height: 500px;
  margin-bottom: 40px;
`

const StyledTitle = styled.div`
  ${BodyText}
  font-weight: bold;
  text-transform: uppercase;
`

export const TcfdTable = ({ holdings }) => {
  return (
    <StyledContainer>
      <StyledTitle>Top 20 Holdings Risk Scores</StyledTitle>
      <StyledTable>
        <TcfdTableHead />
        <tbody>
          {holdings.map((row, index) => (
            <TcfdTableRow row={row} key={index} />
          ))}
        </tbody>
      </StyledTable>
    </StyledContainer>
  )
}

TcfdTable.propTypes = {
  holdings: PropTypes.array.isRequired,
}
