import React from 'react';
import withErrorBoundary from '../../../hocs/withErrorBoundary';
import Card from '../../../components/styleguide/Card';
import CardTitle from '../../../components/styleguide/CardTitle';
import SectionWrapper from '../../../components/styleguide/SectionWrapper';
import TextSection from '../../../components/styleguide/TextSection'
import WrapperColumn from '../../../components/styleguide/WrapperColumn';

const IndustryGroupDetails = ({ summary, subsectors }) => {
    return(
        <Card style={{width:'48.5%', marginRight:'20px'}} height={summary ? "250px" : "200px"}>
            {summary && ( <CardTitle title={"Summary"}/> )}
            <WrapperColumn styles={{justify:"space-between", height:"100%"}}>
                {summary && (
                    <SectionWrapper flex="1" margin="5px 0px 0px 0px">
                        <TextSection>
                            {summary}
                        </TextSection>
                    </SectionWrapper>
                )}
                <SectionWrapper flex="1" margin="10px 0px 20px 0px">
                    <TextSection>
                        <b>
                            Subsectors:
                        </b>
                    </TextSection>
                    <TextSection>
                        {
                            subsectors && subsectors.map((subsector, i) => {
                                return i === (subsectors.length - 1) ? `${subsector}` : `${subsector}, `
                            })
                        }
                    </TextSection>
                </SectionWrapper>
            </WrapperColumn>
        </Card>
    )
};

export default withErrorBoundary(IndustryGroupDetails);
