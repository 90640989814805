import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import ErrorPage from '../../../../components/styleguide/ErrorPage'
import { addThousandSeparator } from '../../../../helpers/number'
import { getCarbonOwnership } from '../../../../repositories/portfolio_repository'
import {
  FilledExplainerLink,
  LinkIcon,
} from '../../../../yb_components/styleguide/links/Link.styles'
import Pagination from '../../../../yb_components/styleguide/pagination/Pagination'
import SpinnerContainer from '../../../../yb_components/styleguide/spinners/SpinnerContainer'
import CarbonReportCard from './components/CarbonReportCard'
import CarbonReportLink from './components/CarbonReportLink'
import {
  CARBON_OWNERSHIP_TABLE_HEADER,
  CARBON_REPORT_TEXTS,
  DEFAULT_SORT,
} from './constants'
import { formatSort, getPdfSortFieldText } from './helpers'
import {
  CarbonReportTable,
  ModuleDescription,
  NoDataText,
  renderTh,
} from './styles'

const StyledExplainerLink = styled(FilledExplainerLink)`
  float: right;
`

export const loadCarbonOwnership = ({
  snapshotId,
  portfolioId,
  setError,
  setIsLoading,
  setData,
  dispatch,
  page,
  sort,
  setTotalPages,
}) => {
  setIsLoading(true)
  getCarbonOwnership({ snapshotId, portfolioId, page, ...sort })
    .then(res => {
      setData(res?.data?.data)
      setTotalPages(res?.data?.total_pages)
      dispatch({
        type: 'holding-ownerships-loaded',
        payload: {
          data: res?.data?.data,
          sort: sort,
        },
      })
    })
    .catch(err => {
      const error = err.response ? err.response.status : 502
      setError(error)
    })
    .finally(() => {
      setIsLoading(false)
    })
}

const CarbonOwnership = ({
  snapshotId,
  portfolioId,
  pdf,
  defaultData,
  defaultSort,
  dispatch,

  title = CARBON_REPORT_TEXTS?.carbonOwnership.title,
  description = CARBON_REPORT_TEXTS?.carbonOwnership.description,
}) => {
  /* Hooks */
  const [isLoading, setIsLoading] = useState(!pdf)
  const [error, setError] = useState()
  const [data, setData] = useState(defaultData || [])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [sort, setSort] = useState(defaultSort || DEFAULT_SORT)

  useEffect(() => {
    if (defaultData) setData(defaultData)
  }, [defaultData])

  useEffect(
    () =>
      !pdf
        ? loadCarbonOwnership({
            snapshotId,
            portfolioId,
            setError,
            setIsLoading,
            setData,
            dispatch,
            page,
            sort,
            setTotalPages,
          })
        : () => {},
    [page, sort]
  )

  const formatData = (data, decimal = null) => {
    const result = parseFloat(data)
    if (isNaN(result)) return '—'
    if (decimal) return result?.toFixed(2)
    return result
  }

  const StyledImg = styled.i`
    ${props => (props.color ? `color: ${props.color};` : '')}
    margin-left: 5px;
  `

  const formatWithArrow = value => {
    let fValue = parseFloat(value)
    fValue = isNaN(fValue) ? 0 : fValue

    if (fValue > 0)
      return (
        <>
          +{value} %
          <StyledImg className='fas fa-arrow-alt-up' color={'#e74c4c'} />
        </>
      )
    else if (fValue < 0)
      return (
        <>
          {value} %
          <StyledImg className='fas fa-arrow-alt-down' color={'#00d66e'} />
        </>
      )
    return '—'
  }

  const formattedPdfSortField = useMemo(
    () => getPdfSortFieldText(CARBON_OWNERSHIP_TABLE_HEADER, defaultSort, pdf),
    [defaultSort, pdf]
  )

  return (
    <CarbonReportCard
      title={title}
      pdfSubTitle={{ label: `(Top 10 Holdings By ${formattedPdfSortField})` }}
      pdf={pdf}
    >
      {!pdf && (
        <StyledExplainerLink
          pdf={pdf}
          href='http://help.yves.blue/en/articles/6698762-carbon-report-portfolio-summary-on-yvesblue#h_f5ba87d0d3'
          target='_blank'
        >
          <LinkIcon className='fa fa-question' />
        </StyledExplainerLink>
      )}

      <ModuleDescription marginBottom={'10px'} pdf={pdf}>
        {description}
      </ModuleDescription>
      {error ? (
        <ErrorPage code={error} message={error == 404 ? 'NOT FOUND' : ''} />
      ) : (
        <SpinnerContainer isLoading={isLoading}>
          {data?.length ? (
            <>
              <CarbonReportTable pdf={pdf}>
                <thead>
                  <tr>
                    {CARBON_OWNERSHIP_TABLE_HEADER.map(elem =>
                      renderTh(
                        elem,
                        sort,
                        (sortField, sortDir) =>
                          setSort(formatSort(sortField, sortDir)),
                        pdf
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {data?.map((elem, index) => {
                    return (
                      <tr key={elem.id || index}>
                        <td className='name'>
                          <CarbonReportLink
                            title={title}
                            pdf={pdf}
                            name={elem?.name}
                            href={`/app/instruments/${elem?.instrument_id}#report-summary-card`}
                          />
                        </td>

                        <td className='number'>
                          {formatData(elem?.weight, 2)}%
                        </td>

                        <td className='number'>
                          {addThousandSeparator(
                            formatData(elem?.owned_emissions, 2)
                          )}
                        </td>

                        <td className='number'>
                          {formatData(elem?.portion_of_portfolio_emissions, 2)}%
                        </td>

                        <td className='number'>
                          {formatData(elem?.owned_intensity, 2)}
                        </td>

                        <td className='number'>
                          {addThousandSeparator(formatData(elem?.intensity, 2))}
                        </td>

                        <td className='number'>
                          {formatWithArrow(
                            addThousandSeparator(
                              formatData(elem?.intensity_vs_industry, 2)
                            )
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </CarbonReportTable>
              {!pdf && (
                <Pagination
                  max={totalPages}
                  selected={page}
                  onChange={e => setPage(e)}
                />
              )}
            </>
          ) : (
            <NoDataText>No data available</NoDataText>
          )}
        </SpinnerContainer>
      )}
    </CarbonReportCard>
  )
}

export default CarbonOwnership
