import React from 'react'

import Header from '../../../../../../pdf/components/Header'
import PortfolioDisclosure from '../../../../../../pdf/components/sections/PortfolioDisclosure'
import PDFContent from '../../../../../../pdf/layout/pdf_content/PDFContent'
import { DocumentTitle } from '../../../../../../pdf/layout/pdf_content/PDFContent.styles'
import YBOverviewCard from '../../../comparisons/components/cards/yb_overview_card/YBOverviewCard'
import LongitudinalESGLineGraph from '../graph/LongitudinalESGLineGraph'
import YBOverviewCustomHeader from '../table/YBOverviewCustomHeader'

const PortfolioLongitudinalReportTemplate = ({
  reportData,
  esgGraphData,
  asOfDates,
  snapshotIds,
  portfolioId,
  portfolio,
}) => {
  return (
    <PDFContent landscape>
      <Header />
      <DocumentTitle>{`${portfolio.name} Longitudinal Report`}</DocumentTitle>

      <LongitudinalESGLineGraph data={esgGraphData} />

      <div style={{ pageBreakBefore: 'always' }}></div>
      <YBOverviewCard
        isPdf={true}
        portfolios={reportData}
        openReplaceModal={false}
        displayEmptyColumns={false}
        customHeader={
          <YBOverviewCustomHeader
            isPdf={true}
            asOfDates={asOfDates}
            snapshotIds={snapshotIds}
            portfolioId={portfolioId}
          />
        }
      />

      <div style={{ pageBreakBefore: 'always' }}></div>
      <PortfolioDisclosure />
    </PDFContent>
  )
}

export default PortfolioLongitudinalReportTemplate
