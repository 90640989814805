import _ from 'lodash'

export const hasOwnProp = (key: string, object: unknown) => {
    return Object.prototype.hasOwnProperty.call(object, key)
}

export const objectToSnakeCase = (obj: any) =>
    _.mapKeys(obj, (v, k) => _.snakeCase(k))

export const objectToCamelCase = (obj: any) =>
    _.mapKeys(obj, (v, k) => _.camelCase(k))
