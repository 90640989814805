import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

import {
  APP_COLOR_PRIMARY,
  FONT_COLOR_SECONDARY,
} from '../../../../../styles/colors'

const StyledList = styled.div`
  grid-column: span 2;
  padding-right: 20px;
  border-right: 1px solid #d3d3d3;

  .list-container {
    height: 850px;
    overflow: scroll;
  }

  ul {
    margin-top: 10px;
    list-style-type: none;
    padding: 0;

    li.active {
      background-color: ${APP_COLOR_PRIMARY};
      color: white;
    }

    li {
      cursor: pointer;
      font-weight: bold;
      font-size: 12px;
      color: ${FONT_COLOR_SECONDARY};
      padding: 8px;
      border: 1px solid #eeeeee;

      :not(:last-child) {
        border-bottom: none;
      }

      :hover {
        background-color: ${APP_COLOR_PRIMARY};
        color: white;
      }

      .customized {
        float: right;
        padding-left: 5px;
        padding-right: 5px;
        background-color: #979797;
        color: white;
        font-weight: 900;
        font-size: 9px;
        border-radius: 12.4148px;
        text-transform: uppercase;
      }
    }
  }
`
const SectorsList = ({
  sectors,
  currentSector,
  setCurrentSector,
  sectorWeights,
}) => {
  return (
    <StyledList>
      <div className='subsection-title'>SECTORS</div>
      <div className='list-container'>
        <ul>
          {sectors.map((sector, index) => (
            <li
              key={`sector-list-${index}`}
              className={currentSector.id == sector.id ? 'active' : ''}
              onClick={() => setCurrentSector(sector)}
            >
              {_.truncate(sector.name, { length: 30 })}
              {sectorWeights?.[sector.id]?.customized && (
                <div className='customized'>Customized</div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </StyledList>
  )
}

export default SectorsList
