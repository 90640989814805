import { CHART_COLOR_GREEN, CHART_COLOR_ORANGE, APP_COLOR_PRIMARY } from "../../../styles/colors";

export const createCo2Body = (projections) => {
    return projections.reduce((acc, el) => {
        if(el.co2_total){
            acc[0].data.push({x: el.fiscal_year, y: +el.co2_total});
        }
        if(el.co2_total_commit){
            acc[1].data.push({x: el.fiscal_year, y: +el.co2_total_commit});
        }
        if(el.co2_budget){
            acc[2].data.push({x: el.fiscal_year, y: +el.co2_budget});
        }
        return acc;
    },
    [{data:[], color: CHART_COLOR_GREEN, radius: 3,  key:"co_total_body" },{data:[], color: CHART_COLOR_ORANGE, radius: 3, key:"co_commit_body"},{data:[], color: APP_COLOR_PRIMARY, radius: 3, key:"co_budget_body"}]
    )
}
