import { validateInput } from "../../../helpers/validators";

export class Member{
    constructor(params){
        this.id = params.id || "";
        this.first_name = params.first_name || "";
        this.last_name = params.last_name || "";
        this.title = params.title || "";
        this.department = params.department || "";
        this.email = params.email || "";
        this.role_type = params.role_type || "";
        this.errors = {};
    }

    // INSTANCE METHODS
    toJSON(){
        return {
            first_name: this.first_name,
            last_name: this.last_name,
            title: this.title,
            department: this.department,
            email: this.email,
        }
    };

    present(value){
        return value && value.length > 0
    }

    // SETTERS
    setId(value) {
        this.id = value;
    }

    setRoleType(value) {
        this.role_type = value;
    }

    setFirstName(value){
        if(this.present(value)){
             this.first_name = value;
        } else {
            this.errors.first_name = "First name needs to be present";
        }
    };

    setLastName(value){
        if(this.present(value)){
             this.last_name = value;
        } else {
            this.errors.last_name = "Last name needs to be present";
        }
    };

    setTitle(value){
        if(this.present(value)){
             this.title = value;
        } else {
            this.errors.title = "Title needs to be present";
        }
    };

    setDepartment(value){
        if(this.present(value)){
             this.department = value;
        } else {
            this.errors.department = "Department needs to be present";
        }
    };

    setEmail(value){
        if(this.present(value) && validateInput("email", value, []).length < 1 ){
             this.email = value;
        } else {
            this.errors.email = "Email needs to be valid";
        }
    };
};
