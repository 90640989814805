import React from 'react'
import styled from 'styled-components'

import {
  APP_COLOR_DANGER,
  BORDER_COLOR_PRIMARY,
  FONT_COLOR_PRIMARY,
  FONT_COLOR_SECONDARY,
} from '../../styles/colors'
import InputLabel from './InputLabel'

const InputGroup = styled.div`
  position: relative;
  /* padding-bottom: 18px; */
  padding-bottom: ${props => (props.eventsFilter ? '0' : '18px')};
  /* margin-top: 10px; */
  margin-top: ${props => (props.eventsFilter ? '0' : '10px')};

  .select-input-error {
    position: absolute;
    font-size: 12px;
    white-space: nowrap;
    left: 0;
    bottom: 0;
    color: ${APP_COLOR_DANGER};
  }
`

const SelectContainer = styled.div`
  position: relative;
`

const Select = styled.select`
  display: block;
  width: 100%;
  background-color: white;
  /* padding: 10px; */
  padding: ${props => (props.eventsFilter ? '6px 13px' : '10px')};
  border: ${props => (props.eventsFilter ? 'none' : 'solid')};
  border-radius: 5px;
  border-width: 1px;
  border-color: ${props =>
    props.error ? APP_COLOR_DANGER : BORDER_COLOR_PRIMARY};
  -webkit-appearance: none;
  color: ${props => (!props.value ? FONT_COLOR_SECONDARY : null)};
  option {
    color: ${FONT_COLOR_PRIMARY};
  }
  :focus {
    outline: ${props =>
      props.eventsFilter ? '0 0 0 0.2rem rgb(0 123 255 / 50%)' : 'none'};
  }
  ::-ms-expand {
    display: none;
  }
  :disabled {
    opacity: 0.8;
  }
`

const DropdownArrow = styled.img`
  position: absolute;
  /* right: 10px; */
  right: ${props => (props.eventsFilter ? '13px' : '10px')};
  top: 0;
  bottom: 0;
  margin: auto;
`

const SelectInput = ({
  id,
  label,
  className,
  options,
  placeholder,
  value,
  onChange,
  disabled,
  error,
  eventsFilter,
}) => {
  return (
    <InputGroup eventsFilter={eventsFilter}>
      {label && !eventsFilter ? (
        <InputLabel label={label} eventsFilter={eventsFilter} />
      ) : null}
      <SelectContainer>
        <Select
          error={error}
          disabled={disabled}
          id={id}
          value={value || ''}
          onChange={e => onChange(e.target.id, e.target.value, e)}
          className={className}
          eventsFilter={eventsFilter}
        >
          {placeholder ? (
            <option key={0} id={0} value={''} hidden>
              {placeholder}
            </option>
          ) : null}
          {options.map(opt => (
            <option key={opt.id} id={opt.id} value={opt.id}>
              {opt.label}
            </option>
          ))}
        </Select>
        <DropdownArrow
          eventsFilter={eventsFilter}
          src={`/ico_dropdown_arrow${disabled ? '_disabled' : ''}.svg`}
        />
      </SelectContainer>
      <div className='select-input-error'>{error}</div>
    </InputGroup>
  )
}

SelectInput.defaultProps = {
  options: [],
  eventsFilterFilter: false,
}

export default SelectInput
