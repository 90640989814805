import React from 'react'
import LineChart from './components/LineChart'
import { abbreviateNumber } from '../../helpers/number';
import { createCo2Body } from './helpers/bodyCreators';
import CircleChartKeyPDF from '../sections/CircleChartKey';


const Co2ProjectionsChart = ({projections, wrapperClass = "Co2-Projection-Graph-Key", markerWrapper = "Co2-Projection-Graph-Key-Marker-Wrapper"}) => {

    const range = [...new Set( projections.reduce((acc,el) => {

        el.co2_total && acc.push(+el.co2_total);
        el.co2_total_commit && acc.push(+el.co2_total_commit);
        el.co2_budget && acc.push(+el.co2_budget);
        return acc;

    }, []).sort((a,b) => {return a - b}))];

    const step = (range[range.length - 1] - range[0])/5;

    const showLabels = ["0", abbreviateNumber(step), abbreviateNumber(step*2), abbreviateNumber(step*3), abbreviateNumber(step*4), abbreviateNumber(step*5)];

    const x_domain = projections.map(p => p.fiscal_year).sort((a,b) => {return a - b});

    const body = createCo2Body(projections);

    const xAxisShowLabels = x_domain.map((x,i) => {
        if((i%5) === 0){
            return x;
        }else{
            return '';
        }

    });

    let co2_total_length = 0;

    let co2_total_commit_length = 0;

    let co2_budget_length = 0;

    let counter = 0;

    while( (co2_total_length < 1 || co2_total_commit_length < 1 || co2_budget_length < 1) && counter < projections.length ){
        const p = projections[counter];
        if(p.co2_total){
            co2_total_length++;
        }

        if(p.co2_total_commit){
            co2_total_commit_length++;
        }

        if(p.co2_budget){
            co2_budget_length++;
        }
        counter++;
    }

    const Co2ChartLabels = [
        {className:'key-circle-green', name:"Projected Total CO₂ Emissions", count:co2_total_length},
        {className:'key-circle-blue', name: "Budgeted CO₂ Emissions", count:co2_budget_length},
        {className:'key-circle-orange', name:"Committed CO₂ Emissions", count:co2_total_commit_length},
    ]

    return (
        <LineChart
            body={body}
            id="Co2-Projection-id-Graph"
            y_domain={range}
            x_domain={x_domain}
            showLabels={showLabels}
            step={step}
            majorHeight='80%'
            xAxisShowLabels={xAxisShowLabels}
        >
            <CircleChartKeyPDF
                classes={{wrapper: wrapperClass , markerWrapper: markerWrapper}}
                labels={Co2ChartLabels}
                useKey="Co2ProjectionsChart"
            />
        </LineChart>
    )


}

export default Co2ProjectionsChart;
