import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import styled from 'styled-components'

import AssetClassAllocationCard from '../../../../components/cards/AssetClassAllocationCard'
import ESGScoreCard from '../../../../components/cards/ESGScoreCard'
import RevenueSegmentCard from '../../../../components/cards/RevenueSegmentCard'
import SDGExposureCard from '../../../../components/cards/SDGExposureCard'
import SubsidyViolationCard from '../../../../components/cards/SubsidyViolationCard'
import TopHoldingsSDGExposureCard from '../../../../components/cards/TopHoldingsSDGExposureCard'
import TVLTargetCard from '../../../../components/cards/TVLTargetCard'
import Badges from '../../../../components/styleguide/badges/Badges'
import DisclosureFooter from '../../../../components/styleguide/DisclosureFooter'
import ExportReportDialog from '../../../../components/styleguide/ExportReportDialog'
import SectionTitle from '../../../../components/styleguide/SectionTitle'
import { downloadTemplate } from '../../../../pdf/index'
import PortfolioTemplate from '../../../../pdf/templates/PortfolioTemplate'
import { getESGYearlyMetrics } from '../../../../repositories/portfolio_repository'
import CarbonAuditCard from '../../../../yb_components/cards/carbon_audit_card/CarbonAuditCard'
import { CarbonAuditTarget } from '../../../../yb_components/cards/carbon_audit_card/CarbonAuditCard.constants'
import { TargetClass } from '../../../../yb_constants/enums/global_enums'
import useCarbonAudit from '../../../../yb_hooks/modules/target/useCarbonAudit'
import useCurrentTabRef from '../../../../yb_hooks/target/useCurrentTabRef'
import AppStore, { AppContext } from '../../../../yb_stores/app_store/AppStore'
import {
  hasESGs,
  loadESGYearlyMetrics,
} from '../../../helpers/ESGMetricsHelper'
import ImpactsSection from '../../../instrument/components/ImpactsSection'
import {
  loadBadges,
  loadTcfdHoldings,
  loadTop10ModeledHoldings,
} from '../../loaders/portfolio_loaders'
import { TcfdPortfolioScoreCard } from '../../tcfd/components/tcfd_portfolio_score/TcfdPortfolioCard'
import PortfolioHoldings from './PortfolioHoldings'

/* global react_app_organization */
/*eslint no-undef: "error"*/

const StyledContent = styled.div`
  .row.module-row {
    &:first-child {
      margin-top: 20px;
    }
    margin-bottom: 1.25rem;
  }
  .section {
    margin-top: 50px;
  }
  :last-child {
    margin-bottom: 100px;
  }
  .sdg-exposures-title {
    margin-bottom: 20px;
  }
`

const SnapshotSummary = forwardRef(
  (
    {
      snapshot,
      snapshotId,
      portfolioId,
      impactBenchmark,
      setLoading,
      setError,
    },
    ref
  ) => {
    const [badges, setBadges] = useState({ positive: [], negative: [] })
    const [ESGYearlyMetrics, setESGYearlyMetrics] = useState(null)
    const [top10ModeledHoldings, setTop10ModeledHoldings] = useState([])
    const [showExport, setShowExport] = useState(false)
    const [co2Projections] = useState(null)
    const [tcfdScores, setTcfdScores] = useState(null)

    useCurrentTabRef(ref)
    const [state] = useContext(AppContext)
    const [loadingState, setLoadingState] = useState({ carbonAudit: false })

    const setLocalLoading = useCallback(
      val => setLoadingState({ ...loadingState, carbonAudit: val }),
      [loadingState]
    )

    const {
      carbonAuditPerformers,
      carbonAuditData,
      carbonAuditDropdownOptions,
      onCarbonAuditChange,
      onDownloadCSV,
    } = useCarbonAudit({
      targetId: { portfolioId, snapshotId },
      targetType: TargetClass.PORTFOLIO_SNAPSHOT,
      setError,
      setLoading: setLocalLoading,
    })

    useEffect(
      () => loadBadges(snapshotId, portfolioId, setBadges, setError),
      []
    )

    useEffect(() => {
      loadTcfdHoldings(null, setTcfdScores, null, portfolioId, snapshotId)
    }, [])

    useEffect(
      () =>
        loadESGYearlyMetrics({
          targetId: snapshotId,
          parentTargetId: portfolioId,
          setESGYearlyMetrics,
          getESGYearlyMetrics,
          setError,
        }),
      []
    )
    useEffect(
      () =>
        loadTop10ModeledHoldings(
          snapshotId,
          portfolioId,
          setTop10ModeledHoldings,
          setError
        ),
      []
    )

    const exportReport = ({ reportVersion, timestamp }) => {
      setShowExport(false)
      setLoading({ show: true, message: 'Generating Report...' })
      downloadTemplate(
        <AppStore initialState={state}>
          <PortfolioTemplate
            version={reportVersion}
            portfolio={snapshot}
            impactBenchmark={impactBenchmark}
            badges={badges}
            rep_risk_benchmark={impactBenchmark?.tvl_insight_scores}
            top10ModeledHoldings={top10ModeledHoldings}
            ESGYearlyMetrics={ESGYearlyMetrics}
            co2Projections={co2Projections}
            carbonAuditData={carbonAuditData}
            tcfdScores={tcfdScores}
            tcfdUrl={`/app/portfolios/${portfolioId}/snapshots/${snapshotId}/carbon/tcfd`}
          />
        </AppStore>,
        reportVersion,
        `yb-${snapshot.name.split(' ').join('-')}-${timestamp}`,
        snapshot.id,
        'Portfolio::Snapshot',
        snapshot.name,
        () => {
          setLoading({ show: false })
        }
      )
    }

    useImperativeHandle(ref, () => {
      return {
        exportPDFReport() {
          setShowExport(true)
        },
      }
    })

    const SDGExposureHeight = () => {
      return Object.keys(sdg_exposures).length < 2
        ? '200px'
        : `${Math.ceil(130 * ((Object.keys(sdg_exposures).length + 1) / 16))}vh`
    }

    const {
      esg_scores,
      revenue_segments,
      subsidies,
      violations,
      sdg_exposures,
      asset_classes,
      tvl_insight_scores,
    } = snapshot

    const canRenderCarbonAudit = carbonAuditData?.table?.data?.length != null

    return (
      <>
        <>
          <StyledContent>
            <Row className='module-row'>
              {asset_classes && (
                <Col md={12} lg={6}>
                  <AssetClassAllocationCard assetClasses={asset_classes} />
                </Col>
              )}

              {esg_scores && hasESGs(esg_scores) && (
                <Col md={12} lg={6}>
                  <ESGScoreCard
                    customESGLabels={snapshot?.custom_esg_labels}
                    benchmarkScores={impactBenchmark?.esg_score}
                    benchmarkName={impactBenchmark?.benchmark_name}
                    scores={esg_scores.overall}
                    shorts_scores={esg_scores?.shorts}
                    longs_scores={esg_scores?.longs}
                    portfolioId={portfolioId}
                  />
                </Col>
              )}
            </Row>

            {tvl_insight_scores && tvl_insight_scores.length > 0 && (
              <Row className='module-row'>
                <TVLTargetCard
                  target={snapshot}
                  data={tvl_insight_scores}
                  benchmarkName={impactBenchmark?.name}
                  benchmarkData={impactBenchmark?.tvl_insight_scores}
                  description={
                    "These ESG-like scores utilize Truvalue Labs long-term momentum scores to \
                measure ESG performance over time. These momentum scores are based on \
                daily tracking scores measuring sentiment analysis for daily news \
                controversies and reports on a company level. The momentum scores are \
                then aggregated up based on the portfolio's holdings into a portfolio \
                level indicator."
                  }
                />
              </Row>
            )}

            <Row>
              <Col md={12} lg={12}>
                {canRenderCarbonAudit && (
                  <CarbonAuditCard
                    loading={loadingState?.carbonAudit}
                    display={CarbonAuditTarget.PORTFOLIO}
                    data={carbonAuditData}
                    onChange={onCarbonAuditChange}
                    sectorOptions={carbonAuditDropdownOptions}
                    performers={carbonAuditPerformers}
                    onDownloadCSV={onDownloadCSV}
                    target={snapshot}
                    benchmark={impactBenchmark}
                  />
                )}
              </Col>
            </Row>

            {
              // eslint-disable-next-line no-undef
              tcfdScores &&
                react_app_organization.organization
                  .can_access_carbon_report && (
                  <Row className='module-row'>
                    <TcfdPortfolioScoreCard
                      scores={tcfdScores}
                      portfolio={true}
                      tcfdLink={`/app/portfolios/${portfolioId}/snapshots/${snapshotId}/carbon/tcfd`}
                      margin={'0 15px 0 15px'}
                    />
                  </Row>
                )
            }

            <Row className='module-row'>
              {subsidies && violations && (
                <Col md={12} lg={12}>
                  <SubsidyViolationCard
                    subsidies={subsidies}
                    benchmark_subsidies={impactBenchmark?.subsidies}
                    violations={violations}
                    benchmark_violations={impactBenchmark?.violations}
                    benchmarkName={impactBenchmark?.benchmark_name}
                    subsidiesAdditionalTitleLink={{
                      label: 'More Info »',
                      href: `/app/portfolios/${portfolioId}/snapshots/${snapshotId}/subsidies`,
                    }}
                    violationsAdditionalTitleLink={{
                      label: 'More Info »',
                      href: `/app/portfolios/${portfolioId}/snapshots/${snapshotId}/violations`,
                    }}
                  />
                </Col>
              )}

              {revenue_segments && (
                <Col md={12} lg={6}>
                  <RevenueSegmentCard segments={revenue_segments} />
                </Col>
              )}
            </Row>
            <SectionTitle
              title='SDG EXPOSURES'
              className='sdg-exposures-title'
            />

            <Row className='module-row'>
              {sdg_exposures && (
                <Col xs={6}>
                  <SDGExposureCard
                    cardHeight={SDGExposureHeight()}
                    data={sdg_exposures}
                    benchmarkData={impactBenchmark?.sdg_exposures}
                    benchmarkName={impactBenchmark?.benchmark_name}
                  />
                </Col>
              )}
              {top10ModeledHoldings && (
                <Col xs={6}>
                  <TopHoldingsSDGExposureCard holdings={top10ModeledHoldings} />
                </Col>
              )}
            </Row>

            <Row className='module-row'>
              {badges.positive?.length > 0 && (
                <Col xs={12} md={6}>
                  <Badges
                    title={'POSITIVE IMPACT BADGES'}
                    badges={badges.positive}
                    snapshotId={snapshot.id}
                    holdingsTotal={snapshot.holdings_total}
                  />
                </Col>
              )}

              {badges.negative?.length > 0 && (
                <Col xs={12} md={6}>
                  <Badges
                    title={'NEGATIVE IMPACT BADGES'}
                    badges={badges.negative}
                    snapshotId={snapshot.id}
                    holdingsTotal={snapshot.holdings_total}
                  />
                </Col>
              )}
            </Row>

            <Row className='module-row'>
              <Col xs={12}>
                <ImpactsSection
                  benchmarkImpacts={impactBenchmark?.impacts}
                  benchmarkName={impactBenchmark?.benchmark_name}
                  targetType={TargetClass.PORTFOLIO_SNAPSHOT}
                  impacts={snapshot.impacts}
                  name={snapshot?.name}
                />
              </Col>
            </Row>

            <Row className='module-row'>
              <Col xs={12}>
                <PortfolioHoldings
                  snapshotId={snapshotId}
                  portfolioId={portfolioId}
                  snapshot={snapshot}
                />
              </Col>
            </Row>
          </StyledContent>
          <DisclosureFooter />
          <ExportReportDialog
            displayed={showExport}
            name={snapshot.name}
            setDisplayed={setShowExport}
            portfolioId={snapshot.id}
            exportReport={exportReport}
          />
        </>
      </>
    )
  }
)

SnapshotSummary.displayName = 'SnapshotSummary'

export default SnapshotSummary
