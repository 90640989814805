import {
  getTcfdByPortfolioSnapshot,
  getTcfdHoldingDetails,
} from '../repositories/tcfd_repository'


export const loadTcfdByPortfolioSnapshot = async (
  page,
  portfolioId,
  snapshotId,
  sortDir,
  sortField,
  setCustomScores,
  setHoldings,
  setIsLoading,
  setPagination,
  setPortfolioScores
) => {
  setIsLoading(true)

  try {
    const res = await getTcfdByPortfolioSnapshot(
      page,
      portfolioId,
      snapshotId,
      sortDir,
      sortField
    )
    setHoldings(res.holding_scores)
    setPortfolioScores(res.portfolio_scores)
    setCustomScores(res.scoring_weights)
    setPagination({ current: res.current_page, total: res.total_pages })
    setIsLoading(false)
  } catch (e) {
    setIsLoading(false)
    console.error(e)
  }
}

export const loadHoldingDetails = async (
  holdingId,
  portfolioId,
  snapshotId,
  setEntity,
  setIsLoading,
  setLinks,
  setListItems,
  setPortfolioScores,
  setPeersData
) => {
  setIsLoading(true)

  try {
    const res = await getTcfdHoldingDetails(holdingId, portfolioId, snapshotId)

    const arr = []
    const listObj = res.peers_tcfd_data[0]
    for (const item in listObj) {
      const idObj = { id: item }
      const peersObj = { peers: res.peers_tcfd_data }
      const newObj = { ...listObj[item], ...idObj, ...peersObj }
      arr.push(newObj)
    }

    setListItems(arr)
    setEntity(res.entity_data)
    setLinks({
      prev: res.links.prev_holding_id
        ? `/app/portfolios/${portfolioId}/snapshots/${snapshotId}/carbon/tcfd/holdings/${res.links.prev_holding_id}`
        : null,
      next: res.links.next_holding_id
        ? `/app/portfolios/${portfolioId}/snapshots/${snapshotId}/carbon/tcfd/holdings/${res.links.next_holding_id}`
        : null,
      home: `/app/portfolios/${portfolioId}/snapshots/${snapshotId}/carbon/tcfd`,
    })
    setPortfolioScores(res.overall_scores)
    setPeersData?.(res?.peers_tcfd_data)
    setIsLoading(false)
  } catch (e) {
    setIsLoading(false)
    console.error(e)
  }
}