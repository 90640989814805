import React from 'react'
import {
  BaseInput,
  IBaseInputProps,
} from '@ui/components/interactive/BaseInput'
import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

// import theme from './RadioButton.module.scss'
import './RadioButton.module.scss'

interface IProps
  extends Omit<
    IBaseInputProps,
    'type' | 'id' | 'validations' | 'feedbackModel' | 'formFeedbackModel'
  > {
  label: string
  name: string
  value: string
}

const RadioButton: React.FC<IProps> = ({
  value,
  label,
  model = useState<string>(''),
  className = '',
  ...inputProps
}) => {
  const id = useMemo(uuidv4, [])
  const [selected, setSelected] = model

  const handleChange = () => {
    setSelected(value)
  }

  return (
    <div className='RadioButton'>
      <BaseInput
        {...inputProps}
        checked={value === selected}
        id={id}
        type='radio'
        value={value}
        className={classNames({
          [className]: !!className,
        })}
        onChange={handleChange}
      />
      <label style={{ textTransform: 'none' }} htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

export default RadioButton
