import React from 'react'

import TooltipWrapper from '../../../../../yb_components/styleguide/tooltips/tooltip_wrapper/TooltipWrapper'

const FilterTooltipWrapper = ({
  displayTooltip,
  children,
  tooltip,
  onClickOutside,
  ...props
}) => {
  return (
    <TooltipWrapper
      tooltip={tooltip}
      clickFunctionality={true}
      positioning='BOTTOM'
      displayTooltip={displayTooltip}
      zIndex={1}
      margin='10px 0px'
      onClickOutside={onClickOutside}
      {...props}
    >
      {children}
    </TooltipWrapper>
  )
}

export default FilterTooltipWrapper
