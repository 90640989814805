import PropTypes from 'prop-types'
import React from 'react'
import { Modal } from 'react-bootstrap'

import { CustomScoringTable } from './CustomScoringTable'

export const CustomScoringModal = ({
  customScores,
  portfolioId,
  snapshotId,
  updateHoldings,
  ...props
}) => {
  return (
    <Modal {...props} size='lg' aria-labelledby='custom_scoring_modal' centered>
      <Modal.Header style={{ padding: '1.25rem 1.25rem 0', border: 'none' }}>
        <h4
          style={{
            margin: 0,
            fontSize: '1rem',
            lineHeight: 1.2,
            fontWeight: 700,
          }}
          id='custom_scoring_modal'
        >
          Configure TCFD Scoring Parameters
        </h4>
        <button
          onClick={props.onHide}
          type='button'
          className='close'
          style={{ padding: '10px 1rem .25rem' }}
        >
          <span aria-hidden='true'>×</span>
          <span className='sr-only'>Close</span>
        </button>
      </Modal.Header>
      <Modal.Body style={{ padding: '1.25rem' }}>
        <p style={{ marginBottom: '1.25rem', color: '#979CA6' }}>
          These settings allow a user to set default weights for TCFD pillars to
          compute the composite TCFD score. A manager can use this to customize
          the TCFD Risk score and to include in their forecast model.
        </p>
        <CustomScoringTable
          model={customScores}
          onHide={props.onHide}
          portfolioId={portfolioId}
          snapshotId={snapshotId}
          updateHoldings={updateHoldings}
        />
      </Modal.Body>
    </Modal>
  )
}

CustomScoringModal.propTypes = {
  customScores: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  portfolioId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  snapshotId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  updateHoldings: PropTypes.func.isRequired,
}
