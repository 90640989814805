import styled from 'styled-components';

export const SDGContainer = styled.div`
  position: relative;

  // clearfix
  &::after {
    content: '';
    clear: both;
    display: table;
  }
`

export const IconContainer = styled.div`
  display: inline-block;
  float: left;
`

export const SDGName = styled.div`
  padding-left: 10px;
  text-align: left;

  position: absolute;
  top: 50%;
  left: 50px;
  transform: translateY(-50%);
`
