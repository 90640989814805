import React from 'react'

const FocusAreaUpdatedEvent = ({ event }) => {
  return (
    <>
      <span className='event-user-name'>{event.context.full_name}</span> updated
      the{' '}
      <span className='event-initiative-name'>
        {event.data.initiative_name}
      </span>{' '}
      initiative for{' '}
      {event.data.entity_id &&
        (event.targetExists ? (
          <a href={`/app/entities/${event.data.entity_id}/esg`}>
            {event.data.entity_name}
          </a>
        ) : (
          event.data.entity_name
        ))}
      {event.data.holding_id &&
        (event.targetExists ? (
          <a
            href={`/app/portfolios/${event.data.portfolio_id}/snapshots/${event.data.snapshot_id}/holdings/${event.data.holding_id}`}
          >
            {event.data.holding_name}
          </a>
        ) : (
          event.data.holding_name
        ))}
      <span className='event-date'>{event.formattedTimestamp}</span>
    </>
  )
}

export default FocusAreaUpdatedEvent
