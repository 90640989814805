import styled from 'styled-components'

import { APP_COLOR_PRIMARY, BG_COLOR_WHITE } from '../../../../styles/colors'

export const InfoTable = styled.table`
  margin: 0 0 1.75rem 0;
  width: 100%;

  // table heading
  .grid-title-container {
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 39px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #ccd5ed;
    th {
      padding: 0;
      height: auto;
    }
    h4 {
      margin: 0;
      font-size: 18px;
      line-height: 0;
      color: black;
      font-weight: 700;
      text-transform: none;
    }
  }
  // table head
  .grid-head-row {
    height: auto;
    min-height: 20px;
    grid-column: 1 / 6;
    display: grid;
    grid-template-columns: 66px 3fr 1fr 1fr 1fr 116px;
    background-color: #edeef3;
  }
  th {
    padding: 0.24rem 10px;
    display: flex;
    align-items: center;
    font-size: 11px;
    line-height: 12px;
    text-transform: uppercase;
    font-weight: 700;
    color: #6c757d;

    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }
  // table body
  tbody > tr {
    grid-column: 1 / 6;
    display: grid;
    grid-template-columns: 66px 3fr 1fr 1fr 1fr 116px;
    background-color: white;
    color: black;

    &.even {
      background-color: #f6f8fd;
    }
  }
  tr.disabled {
    color: #6c757d;
    td,
    h5,
    .target-value,
    .current-value,
    .cell-date-span,
    .last-updated {
      color: #6c757d;
    }
    a {
      :hover {
        text-decoration: none;
        cursor: default;
      }
    }
  }
  td {
    padding: 0.5rem 10px;
    min-height: 80px;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    line-height: 17px;
    color: #333333;

    &:first-child {
      padding-left: 20px;
    }

    &.center-content {
      justify-content: center;
    }
  }

  .cell-date-span {
    display: block;
    font-size: 0.75rem;
    white-space: nowrap;
    color: #333333;
  }
  .last-updated {
    display: block;
    font-size: 0.75rem;
    color: #333333;
  }

  h5 {
    margin: 0 0 0.25rem;
    font-size: 1rem;
    font-weight: bold;
    color: #000;
  }

  p:last-child {
    margin-bottom: 0;
  }

  a {
    margin: 0 0 0.25rem;
    height: auto;
    display: inline-block;
    font-size: 14px;
    line-height: 1.2;
    color: ${APP_COLOR_PRIMARY};

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  // table footer
  tfoot {
    td {
      padding: 10px 20px;
    }
  }

  // notes
  .notes-toggle-button {
    margin: 0 0 0 -10px;
    padding: 5px 10px;
    width: calc(100% + 10px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    color: ${APP_COLOR_PRIMARY};
    border: 1px solid transparent;
    font-size: 14px;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      background-color: rgba(0, 47, 167, 0.1);
      color: ${APP_COLOR_PRIMARY};
    }
    .notes-toggle-button-span {
      position: relative;
      display: inline-block;
    }
    .badge {
      position: absolute;
      top: -2px;
    }
    svg {
      margin-bottom: -2px; // make up for empty space at bottom of svg
      width: 12px;
      &.open {
        transform: rotateX(180deg);
      }
    }
  }
  .notes-row {
    margin: 0;
    padding: 0;
    background-color: ${BG_COLOR_WHITE};
    &.hidden {
      display: none;
    }
    > td {
      vertical-align: top;
      &:first-child {
        padding: 0.5rem 10px;
      }
    }
  }
  .notes-td {
    margin: 0;
    padding: 0;
    grid-column: 2 / 7;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr 116px;
    border-radius: 5px;
    /* background-color: rgb(246, 248, 253); */
  }
  .notes-heading {
    margin: 0 0 10px;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 900;
  }
  .notes-list-container {
    padding: 10px;
    width: 100%;
    grid-column: 1 / 3;
  }
  .notes-grid-form {
    padding: 10px;
    width: 100%;
    grid-column: 3 / 6;
  }
  .toggle-all-button {
    margin: 0 -6px 0 0;
    padding: 2px 6px;
    font-size: 11px;
    line-height: 12px;
    text-transform: uppercase;
    font-weight: 700;
    color: ${APP_COLOR_PRIMARY};
    border: 1px solid transparent;
    background-color: transparent;
    border-radius: 1rem;
    &:hover,
    &:focus,
    &:active {
      background-color: rgba(0, 47, 167, 0.1);
      color: ${APP_COLOR_PRIMARY};
    }
  }

  .btn-primary.add-more-cell-button {
    margin: 0;
    padding: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: ${APP_COLOR_PRIMARY};
    border: 1px solid rgba(0, 47, 167, 0.1);
    border-radius: 6px;
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active {
      border: 1px solid rgba(0, 47, 167, 0.1);
      box-shadow: none;
      outline: none;
      background-color: rgba(0, 47, 167, 0.1);
      color: ${APP_COLOR_PRIMARY};
    }

    svg {
      margin-right: 6px;
    }
  }
`
