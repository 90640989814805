import React, { useMemo } from 'react'
import styled from 'styled-components'

import { FONT_COLOR_SECONDARY } from '../../../../../../../styles/colors'
import Box from '../../../../../../../yb_components/styleguide/containers/Box'
import SortArrows from '../../../../../../../yb_components/styleguide/sort_arrows/SortArrows'
import { ColorBlock } from '../color_block/ColorBlock'

const StyledTable = styled.table`
  td,
  th {
    padding: 4px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.center {
      justify-content: center;
    }
  }

  th {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: ${FONT_COLOR_SECONDARY};
  }

  td {
    font-size: 14px;
    line-height: 17px;
    color: ${FONT_COLOR_SECONDARY};

    a {
      font-weight: 700;
    }
  }

  tr {
    display: grid;
    grid-template-columns: 1fr 1fr 175px 175px;
  }

  tbody tr {
    &:nth-child(even) {
      background-color: #fff;
    }
    &:nth-child(odd) {
      background-color: #f1f1f1;
    }
  }

  .edit-link {
    display: block;
    color: ${FONT_COLOR_SECONDARY};
    transition: color 0.2s ease-in-out;

    & > svg {
      width: 20px;
      height: auto;
    }

    &:hover,
    &:focus {
      color: #5b5b5b;
    }
  }
`

const StyledCenteredDiv = styled.div`
  margin-bottom: 30px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SyledNoDataFound = styled.div`
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: 16px;
  color: ${FONT_COLOR_SECONDARY};
  text-align: center;
`

export const PaiEntitiesTable = ({
  portfolioId,
  snapshotId,
  holdings,
  impactYears,
  noDataText,
  sortModel,
}) => {
  const hasData = useMemo(() => !!holdings.length, [holdings])

  return (
    <>
      <StyledTable>
        <thead>
          <tr>
            <th>
              Holdings <SortArrows sortModel={sortModel} name='target_name' />
            </th>
            <th>
              Sector <SortArrows sortModel={sortModel} name='sector' />
            </th>
            <th className='center'>
              {impactYears.current} Data Completion{' '}
              <SortArrows
                sortModel={sortModel}
                name='current_year_completion'
              />
            </th>
            <th className='center'>
              {impactYears.previous} Data Completion{' '}
              <SortArrows sortModel={sortModel} name='last_year_completion' />
            </th>
          </tr>
        </thead>
        <tbody>
          {holdings.map(row => (
            <tr key={row.holding_id}>
              <td>
                <a
                  href={`/app/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/pai/entities/${row.holding_id}`}
                >
                  {row.name}
                </a>
              </td>
              <td>{row.sector}</td>
              <td style={{ padding: 0 }}>
                <ColorBlock number={row.completion_current_year} />
              </td>
              <td style={{ padding: 0 }}>
                <ColorBlock number={row.completion_last_year} />
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
      <Box isPresent={!hasData}>
        <StyledCenteredDiv>
          <SyledNoDataFound>
            {noDataText || (
              <>
                <p>No items were found for your search query.</p>
                <p>Please try again.</p>
              </>
            )}
          </SyledNoDataFound>
        </StyledCenteredDiv>
      </Box>
    </>
  )
}
