import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CustomMetricDialog } from './styles';
import WrapperColumn from '../../../../../components/styleguide/WrapperColumn';
import TextInput from '../../../../../components/styleguide/TextInput';
import TextArea from '../../../../../components/styleguide/TextArea';
import Button from '../../../../../components/styleguide/Button';
import SectionWrapper from '../../../../../components/styleguide/SectionWrapper';


const UpdateCurrentModal = ({ show, setShowCurrentModal, createNewCurrentValue, dataLabel, currentValue }) => {
    
    const [updatedMetricValue, setUpdatedMetricValue] = useState({});

    const updateMetricValue = (id, value) => {
        const newUpdatedMetricValue = { ...updatedMetricValue };
        newUpdatedMetricValue[id] = value;
        setUpdatedMetricValue(newUpdatedMetricValue);
    }



    return (
        show && <CustomMetricDialog show={show} onHide={() => setShowCurrentModal(false)} data-cy={`${dataLabel}-update-value-modal`}>
            <Modal.Header closeButton data-cy={`${dataLabel}-update-value-modal-header`}>
                <Modal.Title data-cy={`${dataLabel}-update-value-modal-title`}>Updated Current Value</Modal.Title>
            </Modal.Header>
            <WrapperColumn data-cy={`${dataLabel}-update-value-modal-input-top-wrapper`}>
                <SectionWrapper margin="10px 10px 5px 10px;" justify="space-between" data-cy={`${dataLabel}-update-value-modal-curr-value-wrapper`}>
                    <TextInput
                        width="30%"
                        label="Current Value"
                        data-cy={`${dataLabel}-update-value-modal-current-value-wrapper`}
                        id="current_value"
                        defaultValue={currentValue}
                        onChange={(id, value) => {updateMetricValue(id, value)}}
                    />
                </SectionWrapper>
                <SectionWrapper margin="-12px 10px 5px 10px" flex="1" data-cy={`${dataLabel}-update-value-modal-note-wrapper`}>
                    <TextArea
                        label="Note"
                        width="90%"
                        id="value_note"
                        data-cy={`${dataLabel}-update-value-modal-current-value-note-input`}
                        rows={5}
                        onChange={(id, value) => {updateMetricValue(id, value)}}
                    />
                </SectionWrapper>
                <SectionWrapper width="90%" justify="flex-end" margin="0px 0px 12px 0px" data-cy={`${dataLabel}-update-value-modal-current-value-button-wrapper`}>
                    <Button data-cy={`${dataLabel}-update-value-modal-current-value-cancel`} margin="0px 15px 0px 0px" width="30%" label="Cancel" onClick={() => setShowCurrentModal(false)}/>
                    <Button data-cy={`${dataLabel}-update-value-modal-current-value-submit`} width="30%" label="Update Metric" onClick={() => createNewCurrentValue(updatedMetricValue)}/>
                </SectionWrapper>
            </WrapperColumn>
        </CustomMetricDialog>
    )
}

export default UpdateCurrentModal;
