import { useEffect } from 'react'

const unsavedChangesGuard = event => {
  event.preventDefault()
  event.returnValue = ''
}

const addUnsavedChangesGuard = () =>
  window.addEventListener('beforeunload', unsavedChangesGuard)

const removeUnsavedChangesGuard = () =>
  window.removeEventListener('beforeunload', unsavedChangesGuard)

export const useUnsavedChangesGuard = hasChanges => {
  useEffect(() => {
    if (hasChanges) {
      addUnsavedChangesGuard()
    } else {
      removeUnsavedChangesGuard()
    }

    return removeUnsavedChangesGuard
  }, [hasChanges])
}
