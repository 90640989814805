import React from 'react'

import { ESGChartLabels } from '../../../components/charts/constants/ChartLabels'
import CircleChartKeyPDF from '../../../components/sections/CircleChartKey'
import ScoresStackedGraph from '../../../yb_components/charts/scores_stacked_graph/ScoresStackedGraph'
import NewsEventsList from '../../../yb_components/lists/news-events-list/NewsEventsList'
import TrendList from '../../../yb_components/lists/trend-list/TrendList'
import { MAX_WEEKS_IN_A_YEAR } from '../../../yb_constants/time'

const TVLTargetCardPDF = ({
  target,
  data,
  benchmarkData,
  benchmarkName,
  description,
}) => {
  return (
    <div style={{ width: '700px' }}>
      <h2>News Sentiment-based Reputation Risk</h2>
      <p className='pdf-description' style={{ marginBottom: '0' }}>
        {description}
      </p>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <CircleChartKeyPDF
          isPdfTemplate
          classes={{
            wrapper: 'ESG-chart-key-pdf-wrapper-rep-risk',
            markerWrapper: 'ESG-chart-key-pdf-marker-wrapper-rep-risk',
          }}
          style={{ marginBottom: '20px' }}
          labels={ESGChartLabels}
          useKey='RepRiskBarGraphPortfolioPDF'
        />

        <div
          style={{ marginBottom: '10px' }}
          className='ESG-chart-key-pdf-marker-wrapper-benchmark'
        >
          <div className='key-circle-gold'></div>
          <div style={{ fontSize: '10px' }}>Benchmark ({benchmarkName})</div>
        </div>
      </div>

      <div>
        <ScoresStackedGraph
          isPdfTemplate
          data={data?.slice(0, MAX_WEEKS_IN_A_YEAR)}
          benchmarkData={benchmarkData?.slice(0, MAX_WEEKS_IN_A_YEAR)}
          width={720}
          height={250}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div style={{ width: '50%', marginRight: '30px' }}>
          <TrendList
            isPdfTemplate
            title='BEST PERFORMING SASB CATEGORIES (WITH TREND)'
            data={target?.best_sasb_categories}
          />
        </div>
        <div style={{ width: '50%' }}>
          <TrendList
            isPdfTemplate
            title='WORST PERFORMING SASB CATEGORIES (WITH TREND)'
            data={target?.worst_sasb_categories}
          />
        </div>
      </div>
      <div>
        <NewsEventsList
          isPdfTemplate
          title={'MOST RECENT NEWS EVENTS FROM MATERIAL CATEGORIES'}
          data={target?.tvl_sasb_spotlight_events}
          maxCategoriesNr={5}
        />
      </div>
    </div>
  )
}

export default TVLTargetCardPDF
