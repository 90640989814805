import PropTypes from 'prop-types'
import React from 'react'
import { ProgressBar } from 'react-bootstrap'
import styled from 'styled-components'

import { FONT_COLOR_PRIMARY } from '../../../../../../../styles/colors'
import LoadingSpinner from '../../../../../../../yb_components/styleguide/spinners/LoadingSpinner'

const StyledDiv = styled.div`
  h4 {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: ${FONT_COLOR_PRIMARY};
  }
  .progress-label-container {
    display: flex;
    align-items: center;
  }
  .progress-label {
    display: inline-block;
    font-size: 36px;
    line-height: 1.1;
    letter-spacing: -0.45px;
    color: #2e384d;
  }
  .progress-bar-container {
    margin-left: 15px;
  }
  .progress {
    width: 200px;
    border-radius: 8px;
    background-color: #e0e6f8;

    .progress-bar {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`

export const ProgressIndicator = ({ progress: rawProgress, isLoading }) => {
  const progress = Math.round(Number(rawProgress) * 100)

  return (
    <StyledDiv>
      <h4>Reporting Progress</h4>
      <div className='progress-label-container'>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <span className='progress-label'>{progress}%</span>
        )}
        <div className='progress-bar-container'>
          <ProgressBar now={progress} />
        </div>
      </div>
    </StyledDiv>
  )
}

ProgressIndicator.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  progress: PropTypes.number.isRequired,
}
