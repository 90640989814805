import styled from "styled-components";
import { APP_COLOR_PRIMARY, FONT_COLOR_PRIMARY, FONT_COLOR_SECONDARY } from '../../../../styles/colors';

export const StyledTable = styled.table`
    margin-bottom: ${props => props.marginBottom || '0px'};
    height: 550px;

    thead {
        align-items: start;

        th {
            height: 50px;
            background-color: #CCD5ED;

            & .inline-content {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            & .icon {
                margin-left: 5px;
                color: ${FONT_COLOR_PRIMARY};
            }
        }
    }

    tbody {
        align-items: start;
        height: 500px;

        tr {
            height: 50px;
    
            &:nth-child(even) {
                background-color: #F6F8FD;
            }
        }

        & .dummy-data {
            font-size: 11px;
            display: flex;
            justify-content: center;
            color: ${FONT_COLOR_SECONDARY}
        }
    }

    .loading-icon-container {
        height: 500px;

        td {
            justify-content: center;
            align-items: center;

            & .loading-icon-div {
                align-items: center;
                justify-content: center;
                display: flex;
                
                & .loading-icon {
                    color: ${APP_COLOR_PRIMARY};
                }
            }
        }
    }
`;
