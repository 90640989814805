import React from "react";
import Container from "../../../components/styleguide/Container";
import CardTitle from "../../../components/styleguide/CardTitle";
import OtpBackupCodesIndex from "./components/OtpBackupCodesIndex";
import TwoFactorCard from "./components/styled/TwoFactorCard";

const TwoFactorOtpBackupCodesIndex = () => {
    return(
        <Container>
            <TwoFactorCard>
                <CardTitle title="Backup Codes"/>
                <OtpBackupCodesIndex/>
            </TwoFactorCard>
        </Container>
    )
};

export default TwoFactorOtpBackupCodesIndex;
