import propTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import cns from '../../helpers/classnames'

const Container = ({ children, classes, className, ...props }) => {
  return (
    <div
      className={cns(classes.container, className)}
      data-cy={props['data-cy']}
    >
      <div className={classes.content}>{children}</div>
    </div>
  )
}

Container.propTypes = {
  children: propTypes.node.isRequired,
  className: propTypes.string,
  classes: propTypes.object.isRequired,
}

const style = {
  // '@global': {
  //   body: {
  //     backgroundColor: '#F4F6F9',
  //     color: FONT_COLOR_PRIMARY,
  //     fontFamily: 'Lato',
  //     '& .yb-app-content': {
  //       padding: 0,
  //     },
  //   },
  // },
  content: {
    display: 'inline-block',
    width: '100%',
    // maxWidth: 1280,
  },
  container: {
    display: 'inline-block',
    margin: '0 auto',
    width: '100%',
    maxWidth: '1440px',
    backgroundColor: 'transparent',
  },
}

export default injectSheet(style)(Container)
