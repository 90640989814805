//LIBRARIES
import React, { useState, useEffect } from 'react';

//COMPONENTS
import IFCPrincipleFundShow from './IFC_Principles/IFCPrincipleShow';
import IFCPrincipleCreate from './IFC_Principles/IFCPrincipleCreate';


const FundShowReportingFrameworkTab = ({ config, fund, dataLabel }) => {
    const [framework, setFramework] = useState(config.framework);

    useEffect(() => {
        setFramework(config.framework);
    }, [config.framework]);
    
    const currFramework = () => {
        switch(framework){
            case "Impact":
                return <div>impact</div>
            case "IFC Principles":
                return  <IFCPrincipleFundShow targetType="fund" dataLabel={dataLabel} changeFrameworkToCreate={() => setFramework("IFC Principles Create")} target={fund}/>
            case "IFC Principles Create":
                return <IFCPrincipleCreate dataLabel={dataLabel} changeFrameworkToShow={() => setFramework("IFC Principles")} target={fund} targetType="fund"/>
            case "SASB":
                return <div>SASB</div>
            default:
                <></>
        }
    }

    return (
        <>
            {currFramework()}
        </>
    )
}

export default FundShowReportingFrameworkTab;