import React from 'react';
import PropTypes from 'prop-types';

import * as d3 from 'd3';
import XAxisTicks from './Ticks';
import XAxisLabels from './XAxisLabels';
import YAxisLabels from './YAxisLabels';
import YAxisLine from './YAxisLine';
import LineChartBody from './line-chart/LineChartBody';
import useResize from '../helpers/useResize';
import { APP_COLOR_PRIMARY, FONT_COLOR_SECONDARY } from '../../../styles/colors';
import XAxis from './xAxisLine';
import VerticalGrid from './VerticalGrid';



const LineChart = ({body, id, y_domain, x_domain , showLabels, children, xAxisShowLabels, majorHeight = '100%', majorWidth = '100%', useXStart = false, xLabelOffset = 0, showXAxisTicks = true }) => {

    const {height, width} = useResize(id);

    const x_scale = d3.scalePoint()
    .domain(x_domain)
    .range([width*0.05, width*0.97])
    
    const y_scale = d3.scaleLinear()
    .domain([0, y_domain[y_domain.length -1]])
    .range([height*0.8, height*0.1])

    const yLabels = [height*(6/7), height*(5/7), height*(4/7), height*(3/7), height*(2/7), height*(1/7)];


    const xStart = x_scale.range()[0];

    const yAxisFunc = (i) => {
        return yLabels[i];
    }

    const yAxisFuncLine = (point) => {
        if(point === 0){
            return height*0.852;
        }
        return y_scale(point);
    }


    const xAxisFunc = (i) => {
        return x_scale(x_domain[i]);
    }

    const xAxisFuncLine = (year) => {
        return x_scale(year) + 12;
    }

    const xFuncTick = (i) => {
        return x_scale(x_domain[i]) + 12; 
    }

    const xFuncTickMain = (i) => {
        if(i%5 === 0){
            return (x_scale(x_domain[i]) + 12);
        } else {
            return  (x_scale(x_domain[0]) + 12);
        }
    }

    return(
        <div style={{height:'90%', width:'100%'}}>
            {children}
            <svg style={{height:majorHeight, width:majorWidth}} id={id}>
                <YAxisLabels
                    values={showLabels}
                    classes="Line-Chart-YAxisLabels"
                    linedist={ 0 }
                    offset={ 0 }
                    xstart={useXStart ? xStart - 15 : null}
                    yscale={ yAxisFunc }
                />    
                <VerticalGrid  
                    amount={showLabels.length}
                    xstart={xStart - 5}
                    xend={xStart}
                    yscale={yAxisFunc}
                    linedist={0}
                    offset={-4}
                    classes="ESG-Line-Chart-xAxis"
                />                         
                <XAxisLabels
                    values={xAxisShowLabels}
                    classes="Line-Chart-XAxisLabels"
                    linedist={ 0 }
                    offset={ 0 }
                    ystart={ height*0.9 + xLabelOffset }
                    xscale={ xAxisFunc  }
                />
                {
                    showXAxisTicks && (
                    <>
                    <XAxisTicks
                        amount={x_domain.length}
                        xscale={xFuncTick}
                        y1={height*0.86}
                        y2={height*0.865}
                        stroke={FONT_COLOR_SECONDARY}
                        strokeWidth={3}
                    />
                    <XAxisTicks
                        amount={xAxisShowLabels.length}
                        xscale={xFuncTickMain}
                        y1={height*0.86}
                        y2={height*0.87}
                        stroke={APP_COLOR_PRIMARY}
                        strokeWidth={3}
                    />
                    </>
                    )
                }               
                <XAxis 
                    xend={width}
                    classes="Line-Chart-Axis"
                    xstart={xStart}
                    ystart={height*0.86}
                />                  
                <YAxisLine
                    xstart={xStart}
                    ystart={height*0.86}
                    yend={height*0.05}
                    classes="Line-Chart-Axis"
                />
                {
                    body.map((b,i) => {
                        return  <LineChartBody
                        key={`${b.key}-${i}`}
                        data={b.data}
                        xscale={xAxisFuncLine}
                        yscale={yAxisFuncLine}
                        radius={b.radius}
                        svgstyles={{dotStroke:b.color, dotFill:b.color, lineStroke:b.color, lineFill: "none", lineWidth:2.5, circleWidth:3.25}}
                    />
                    })
                }
            </svg>
        </div>
    )
}

LineChart.propTypes = {

    body:PropTypes.arrayOf(
        PropTypes.shape({
            data: PropTypes.arrayOf(
                PropTypes.shape({
                x: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
                y: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),            
            })),
            radius:PropTypes.number,
            color:PropTypes.string
        })
    ),
    id:PropTypes.string,
    y_domain:PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ])),
    x_domain:PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ])), 
    showLabels:PropTypes.arrayOf(PropTypes.string),
    children:PropTypes.element

}

export default LineChart;
