import React, { useEffect, useRef, useState } from 'react'
import Form from 'react-bootstrap/Form'
import ReactSlider from 'react-slider'
import styled from 'styled-components'

import { SelectLabel } from '../../modules/research_center/styles/styles'
import {
  ExplainerLink,
  LinkText,
} from '../../yb_components/styleguide/links/Link.styles'

const StyledContainer = styled.div`
  padding: 0px 5px 0px 15px;
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 5px;
    & > div {
      margin-left: 0px;
    }
    & > a {
      margin-right: 0px;
    }
  }
  .value-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    padding: 0px 15px;
    input {
      width: 44px;
      height: 25px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #828282;
      border: 1px solid #e6e7ea;
      border-radius: 6px;
      padding-right: 0px;
      padding-left: 8px;
    }
    .small-padding-left {
      padding-left: 4px;
    }
    .large-padding-left {
      padding-left: 12px;
    }
  }
  ${props => props.customStyle && props.customStyle}
`

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 25px;
`

const StyledThumb = styled.div`
  height: 28px;
  line-height: 28px;
  width: 28px;
  text-align: center;
  background-color: #ffffff;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
  bottom: 9px;
  box-shadow: 0px 4px 18px rgb(0 0 0 / 20%);
  transition: box-shadow 0.5s;
  &:focus {
    outline: 0;
    outline: none;
  }
  &:hover {
    box-shadow: 0px 4px 18px rgb(0 0 0 / 40%);
  }
`

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${props =>
    props.index === 2
      ? ' rgba(60, 60, 67, 0.18);'
      : props.index === 1
      ? '#0A7AFF;'
      : ' rgba(60, 60, 67, 0.18);'};
  border-radius: 999px;
  height: 4px;
`

const Thumb = (props, state) => (
  <StyledThumb {...props}>{state.valueNow}</StyledThumb>
)

const Track = (props, state) => <StyledTrack {...props} index={state.index} />

const DualRangeSlider = ({
  value = [0, 100],
  displayValue,
  onChange,
  title = false,
  clearValue = false,
  customStyle = ``,
}) => {
  const defaultValue = [0, 100]
  const clearButtonCondition =
    defaultValue[0] !== value[0] || defaultValue[1] !== value[1]

  const minInput = useRef()
  const maxInput = useRef()
  const [minInputValue, setMinInputValue] = useState()
  const [maxInputValue, setMaxInputValue] = useState()
  const [sliderValue, setSliderValue] = useState(value)

  useEffect(() => {
    setMinInputValue(`${sliderValue[0]}%`)
    setMaxInputValue(`${sliderValue[1]}%`)
  }, [sliderValue])

  useEffect(() => {
    setSliderValue(value)
  }, [value])

  const handleKeyDown = (e, id) => {
    if (e.key === 'Enter') {
      if (id === 'minRange') {
        onChange([Number(e.target.value), value[1]])
        minInput.current.blur()
      } else {
        onChange([value[0], Number(e.target.value)])
        maxInput.current.blur()
      }
    }
  }

  const hanleInputChange = (e, setInput) => {
    if (isNaN(e.target.value) == false && e.target.value <= 100) {
      setInput(`${e.target.value}`)
    }
  }

  const clearInputField = setInput => {
    setInput('')
  }

  const handlePadding = id => {
    let paddingStyle = {}
    if (sliderValue[id] === 100) {
      paddingStyle = { className: 'small-padding-left' }
    }
    if (sliderValue[id].toString().length === 1) {
      paddingStyle = { className: 'large-padding-left' }
    }
    return paddingStyle
  }

  const handleSliderChange = val => {
    setSliderValue(val)
  }

  return (
    <StyledContainer customStyle={customStyle}>
      <div className='header-container'>
        {title && <SelectLabel>{title}</SelectLabel>}
        {clearValue && clearButtonCondition && (
          <ExplainerLink
            fontSize='12px'
            padding='0px'
            margin='0 10px 0 0'
            onClick={() => onChange(defaultValue)}
            fontStyle={'initial'}
          >
            <LinkText>Clear</LinkText>
          </ExplainerLink>
        )}
      </div>
      {displayValue && (
        <div className='value-wrapper'>
          <Form.Control
            ref={minInput}
            type='text'
            value={minInputValue}
            onChange={e => hanleInputChange(e, setMinInputValue)}
            onKeyDown={e => handleKeyDown(e, 'minRange')}
            onFocus={() => clearInputField(setMinInputValue)}
            {...handlePadding(0)}
          />
          <Form.Control
            ref={maxInput}
            type='text'
            value={maxInputValue}
            onChange={e => hanleInputChange(e, setMaxInputValue)}
            onKeyDown={e => handleKeyDown(e, 'maxRange')}
            onFocus={() => clearInputField(setMaxInputValue)}
            {...handlePadding(1)}
          />
        </div>
      )}
      <StyledSlider
        defaultValue={value}
        value={sliderValue}
        onChange={handleSliderChange}
        onAfterChange={onChange}
        renderTrack={Track}
        renderThumb={Thumb}
      />
    </StyledContainer>
  )
}

export default DualRangeSlider
