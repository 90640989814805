import React, { useEffect, useMemo, useState } from 'react'

import ErrorPage from '../../../../components/styleguide/ErrorPage'
import { abbreviateNumber } from '../../../../helpers/number'
import { getRenewableEnergy } from '../../../../repositories/portfolio_repository'
import { LinkIcon } from '../../../../yb_components/styleguide/links/Link.styles'
import Pagination from '../../../../yb_components/styleguide/pagination/Pagination'
import SpinnerContainer from '../../../../yb_components/styleguide/spinners/SpinnerContainer'
import CarbonReportCard from './components/CarbonReportCard'
import CarbonReportLink from './components/CarbonReportLink'
import {
  CARBON_REPORT_TEXTS,
  DEFAULT_SORT,
  renewableEnergyTableHeader,
} from './constants'
import { formatSort, getPdfSortFieldText } from './helpers'
import {
  CarbonReportTable,
  ModuleDescription,
  renderTh,
  StyledExplainerLink,
} from './styles'

export const loadRenewableEnergy = ({
  snapshotId,
  portfolioId,
  setError,
  setIsLoading,
  setData,
  dispatch,
  page,
  sort,
  setTotalPages,
}) => {
  getRenewableEnergy({ snapshotId, portfolioId, page, ...sort })
    .then(res => {
      setIsLoading(false)
      setData(res?.data?.data)
      setTotalPages(res?.data?.total_pages)
      dispatch({
        type: 'renewable-energy-loaded',
        payload: { data: res?.data?.data, sort: sort },
      })
    })
    .catch(err => {
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

const RenewableEnergyPurchasing = ({
  pdf,
  snapshotId,
  portfolioId,

  defaultData,
  defaultSort,
  dispatch,

  title = CARBON_REPORT_TEXTS?.renewableEnergy.title,
  description = CARBON_REPORT_TEXTS?.renewableEnergy.description,
}) => {
  /* Hooks */
  const [isLoading, setIsLoading] = useState(!pdf)
  const [error, setError] = useState()
  const [data, setData] = useState(defaultData || [])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [sort, setSort] = useState(defaultSort || DEFAULT_SORT)

  useEffect(() => {
    if (defaultData) setData(defaultData)
  }, [defaultData])

  useEffect(
    () =>
      !pdf
        ? loadRenewableEnergy({
            snapshotId,
            portfolioId,
            setError,
            setIsLoading,
            setData,
            dispatch,
            page,
            sort,
            setTotalPages,
          })
        : () => {},
    [page, sort]
  )

  const formatValue = (value, symbol = ' MWh', abbreviate = true) => {
    let fValue = parseFloat(value)

    const formatNumericValue = v => {
      if (!abbreviate) return v?.toFixed(2).toLocaleString()
      return abbreviateNumber(v, 2)
    }

    fValue =
      !isNaN(fValue) && fValue != 0
        ? `${formatNumericValue(fValue)}${symbol}`
        : null
    return fValue
  }

  /* Render */
  const renderRows = () => {
    return (
      <>
        {data?.map(elem => (
          <tr key={elem?.id}>
            <td className='name'>
              {elem?.id != null ? (
                <CarbonReportLink
                  title={title}
                  pdf={pdf}
                  name={elem?.name}
                  href={`/app/instruments/${elem?.id}#report-summary-card`}
                />
              ) : (
                <div>{elem?.name || '—'}</div>
              )}
            </td>
            {pdf && (
              <td className='number'>
                {formatValue(elem?.weight, '%') || '—'}
              </td>
            )}
            <td className='number'>
              {formatValue(elem?.renewables_purchased) || '—'}
            </td>
            <td className='number'>
              {formatValue(elem?.renewables_produced) || '—'}
            </td>
            <td className='number'>
              {formatValue(elem?.total_energy_usage) || '—'}
            </td>
            <td className='number'>
              {formatValue(
                elem?.renewables_of_total_energy_usage,
                '%',
                false
              ) || '—'}
            </td>
          </tr>
        ))}
      </>
    )
  }

  const formattedPdfSortField = useMemo(
    () =>
      getPdfSortFieldText(renewableEnergyTableHeader(pdf), defaultSort, pdf),
    [defaultSort, pdf]
  )

  return (
    <CarbonReportCard
      title={title}
      pdf={pdf}
      pdfSubTitle={{ label: `(Top 20 Holdings By ${formattedPdfSortField})` }}
    >
      {!pdf && (
        <StyledExplainerLink
          pdf={pdf}
          href='http://help.yves.blue/en/articles/6698762-carbon-report-portfolio-summary-on-yvesblue#h_78ba215e8f'
          target='_blank'
        >
          <LinkIcon className='fa fa-question' />
        </StyledExplainerLink>
      )}

      <ModuleDescription marginBottom={'10px'} pdf={pdf}>
        {description}
      </ModuleDescription>

      {error ? (
        <ErrorPage code={error} message={error == 404 ? 'NOT FOUND' : ''} />
      ) : (
        <>
          {isLoading && <br />}
          <SpinnerContainer isLoading={isLoading}>
            <CarbonReportTable pdf={pdf}>
              <thead>
                <tr>
                  {renewableEnergyTableHeader(pdf).map(elem =>
                    renderTh(
                      elem,
                      sort,
                      (sortField, sortDir) =>
                        setSort(formatSort(sortField, sortDir)),
                      pdf
                    )
                  )}
                </tr>
              </thead>
              <tbody>{renderRows()}</tbody>
            </CarbonReportTable>
            {!pdf && (
              <Pagination
                max={totalPages}
                selected={page}
                onChange={e => setPage(e)}
              />
            )}
          </SpinnerContainer>
        </>
      )}
    </CarbonReportCard>
  )
}

export default RenewableEnergyPurchasing
