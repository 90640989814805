import React, { useState, useEffect } from 'react';
import { getHoldingFormPublications } from "../../../../repositories/holdings_repository";
import Table from "../../../../components/styleguide/Table";
import AddHoldingFormPublicationModal from "../components/AddHoldingFormPublicationModal";
import SectionWrapper from "../../../../components/styleguide/SectionWrapper";
import Button from "../../../../components/styleguide/Button";
import { FundTableWrapper, ModuleWrapper } from '../../styles';

const HoldingFormPublicationModule = ({ holding }) => {
    const [publications, setPublications] = useState(null);
    const [showModal, setShowModal] = useState(null);
    const [showTable, setShowTable] = useState(false);

    useEffect(() => {
        getHoldingFormPublications(holding.id).then((response) => {
            setPublications(response.data);
            setShowTable(true);
        });
    }, [holding]);

    const tableRows = () => {
        return publications.map((publication) => {
            return [
                <span>{publication.name}</span>,
                <span>{publication.holding_key_person.full_name} </span>,
                <span>{publication.custom_report_form.description}</span>,
                <span>{publication.expires_at || "---"}</span>,
                <span>{publication.used_at || "---"}</span>,
                <span>{publication.url}</span>,
            ];
        })
    };

    const headers = [
        { label: "NAME" },
        { label: "RESPONDENT" },
        { label: "FORM" },
        { label: "EXPIRES AT" },
        { label: "USED AT" },
        { label: "URL" },
    ];

    const handleModalShow = () => {
        setShowModal(true);
    };

    const handleModalHide = () => {
        setShowModal(false);
    };

    const handleModalSubmit = (newPublication) => {
        const newPublications = Array.from(publications);
        newPublications.push(newPublication);

        setShowModal(false);
        setPublications(newPublications);
    };

    if (holding.confirmedKeyPeople().length === 0) {
        return(<div>You must add a key person to the holding to access this tab.</div>)
    }

    return(
        <>
            {
            showTable && publications && publications.length > 0 &&
                <ModuleWrapper>
                    <FundTableWrapper margin="0px;">
                        <Table body={tableRows()} headers={headers} />
                    </FundTableWrapper>
                </ModuleWrapper>
            }
            {
            showTable && publications && publications.length === 0 &&
                <div>You have no publications, yet.</div>
            }
            {
            showTable &&
                <SectionWrapper justify="flex-end">
                    {
                        holding.current_app_user_can_update &&
                        <SectionWrapper className="button-wrapper" width="15%" margin-top="15px">
                            <Button label="Create Report" onClick={handleModalShow}/>
                        </SectionWrapper>
                    }
                    <AddHoldingFormPublicationModal holding={holding} show={showModal} onModalHide={handleModalHide} onModalSubmit={handleModalSubmit}/>
                </SectionWrapper>
            }
        </>
    )
};

export default HoldingFormPublicationModule;
