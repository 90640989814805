import {
  AlertCard,
  IAlertCardProps,
} from '@ui/components/presentation/cards/AlertCard'
import { IModalProps, Modal } from '@ui/components/presentation/modals/Modal'
import React from 'react'

import { FormActions } from '../FormActions'
// import theme from './Alert.module.scss'
import './Alert.module.scss'

interface IAlertProps
  extends IAlertCardProps,
    Omit<IModalProps, 'dismissable'> {
  title: string
  onSubmit(data?: unknown): void
}

const Alert: React.FC<IAlertProps> = ({
  children,
  title,
  type,
  onHide,
  onSubmit,
  ...modalProps
}) => {
  return (
    <Modal
      {...modalProps}
      onHide={onHide}
      dismissable={false}
      className={'Alert'}
    >
      <AlertCard title={title} type={type}>
        {children}
      </AlertCard>
      <FormActions
        canSubmit={true}
        onCancel={onHide}
        submitLabel='Confirm'
        onSubmit={onSubmit}
      />
    </Modal>
  )
}

export default Alert
