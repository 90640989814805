import React from 'react';

const HorizontalProgress = ({ height, width, progress, color }) => {

  const commonStyle = {
    height,
    width,
    borderRadius: height / 2
  }

  return (
    <div style={commonStyle} className="horizontal-progress">
      <div style={{ ...commonStyle, width: progress + '%', backgroundColor: color }} className="horizontal-progress-percentage"/>
    </div>
  );
}

export default HorizontalProgress;