import React from 'react'

import { peersTableColumnCount } from './constants'
import { StyledReplacePeer } from './styles'

const PeersHeaderRow = ({ peers, openReplacePeerModal, isPdf }) => {
  const replacePeerAnchors = () => {
    const anchors = []
    const replacePeerAnchor = (
      <StyledReplacePeer onClick={openReplacePeerModal}>
        (Replace Peer)
      </StyledReplacePeer>
    )

    for (let i = 0; i < peersTableColumnCount; i++) {
      anchors.push(
        i < 2 ? <th key={i} /> : <th key={i}> {replacePeerAnchor} </th>
      )
    }

    return anchors
  }

  return (
    <thead>
      <tr>
        <th />
        {peers.map(entity => (
          <th key={entity.id}>{entity.name}</th>
        ))}
      </tr>
      {!isPdf && <tr>{replacePeerAnchors()}</tr>}
    </thead>
  )
}

export default PeersHeaderRow
