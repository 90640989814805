export const USER_STATUS_OPTIONS = [
  {
    value: '',
    text: 'All Users',
  },
  {
    value: 'active',
    text: 'Active Users',
  },
  {
    value: 'inactive',
    text: 'Inactive Users',
  },
]
