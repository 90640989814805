import useFeedbackMessage from '@env/hooks/forms/useFeedbackMessage'
import useInputValidations from '@env/hooks/forms/useInputValidations'
import useRequiredValidation from '@env/hooks/forms/useRequiredValidation'
import useShouldDisplayInputFeedback from '@env/hooks/forms/useShouldDisplayInputFeedback'
import withFormControl from '@ui/components/compositions/withFormControl'
import withFormRow from '@ui/components/compositions/withFormRow'
import { InputFeedback } from '@ui/components/presentation/copy/InputFeedback'
import getTestingProps from '@utils/test/getTestingProps'
import classnames from 'classnames'
import React, { ChangeEvent, FocusEvent, useContext, useState } from 'react'
import { APP_COLOR_PRIMARY } from '../../../../react/styles/colors'

// import theme from './BaseInput.module.scss'
import './BaseInput.module.scss'
import styled, { css } from 'styled-components'
import { DisclosureFormsContext } from '../../../../react/yb_stores/sfdr/disclosure_forms/DisclosureFormsStore'

const S = {
  BaseInput: styled.div`
    .BaseInput {
      padding: 5px 10px;
      border: 1px solid #e6e7ea;
      border-radius: 6px;

      &.invalid {
        border: 1px solid #ec7280;

        &:focus {
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(#ec7280, 0.25);
        }
      }

      ${props =>
        props.pdf
          ? css`
              min-height: 35px;
              width: 100%; ;
            `
          : css`
              width: 100%;
            `}

      ${props => {
        switch (props?.type) {
          case 'radio':
          case 'checkbox':
            return (
              props.pdf &&
              css`
                min-height: 0px;
                padding: 0px;
                width: 15px;
                height: 15px !important;
                border-radius: 9999px;
                background-color: white;

                :checked {
                  background-color: ${APP_COLOR_PRIMARY};
                }
              `
            )

          default:
            return (
              props.pdf &&
              css`
                width: 100%;
              `
            )
        }
      }}
    }
  `,
}
export interface IProps
  extends YB.IFormControl<string>,
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > {
  name: string
}

const BaseInput: React.FC<IProps> = ({
  name,
  onChange,
  onBlur,
  model = useState<string>(''),
  feedbackModel = useState<string>(''),
  formFeedbackModel = useState<string>(''),
  validations = [],
  disabled = false,
  className = '',
  type = 'text',
  required = true,
  placeholder,
  ...inputProps
}) => {
  /* State */
  const [{ isPdf }] = useContext(DisclosureFormsContext)

  const [value, setValue] = model
  const [isPristine, setIsPristine] = useState(true)
  const feedbackMessage = useFeedbackMessage(feedbackModel, formFeedbackModel)
  const isInvalid = useShouldDisplayInputFeedback(
    feedbackMessage,
    isPristine,
    disabled
  )

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    onChange && onChange(event)
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setIsPristine(false)
    onBlur && onBlur(event)
  }

  useInputValidations(
    value,
    useRequiredValidation(validations, required),
    feedbackModel
  )

  return (
    <S.BaseInput type={type} pdf={isPdf}>
      <input
        {...inputProps}
        {...getTestingProps(name)}
        disabled={disabled}
        name={name}
        required={required}
        aria-required={required}
        type={type}
        value={value}
        className={classnames('BaseInput', {
          invalid: isInvalid,
          [className]: !!className,
        })}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder?.label}
      />
      <InputFeedback isPresent={isInvalid} feedbackMessage={feedbackMessage} />
    </S.BaseInput>
  )
}

export default BaseInput

export const BaseInputControl = withFormControl<IProps>(BaseInput)

export const BaseInputRow = withFormRow<IProps>(BaseInput)
