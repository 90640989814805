import React from 'react';
import styled from 'styled-components';
import { FONT_COLOR_SECONDARY, ESG_SCALE_COLOR_BAD, ESG_SCALE_COLOR_POOR, ESG_SCALE_COLOR_BELOW_AVERAGE, ESG_SCALE_COLOR_ABOVE_AVERAGE, ESG_SCALE_COLOR_GOOD, ESG_SCALE_COLOR_SUPERIOR, ESG_SCALE_COLOR_EXCELLENT } from '../../styles/colors';
import { esg_scale } from '../../helpers/esg_scale';

const ScaleCell = styled.div`
  width: ${100/5}%;
  display: inline-block;
  vertical-align: top;

  .color-box {
    height: 15px;
    background-color: ${props => props.color};
  }

  span {
    display: inline-block;
    width: 100%;
    font-size: 12px;
    margin-top: 5px;
    color: ${FONT_COLOR_SECONDARY};
    text-align: center;
    vertical-align: top;
    padding: 0 2px;
  }
`;

const StyledContainer = styled.div`
  width: calc(100% - 200px);
`;

const RevenueSegmentScale = () => {

  const scale = [
    esg_scale[0],
    esg_scale[2],
    esg_scale[3],
    esg_scale[4],
    esg_scale[6]
  ];

  return (
    <StyledContainer>
      {scale.map((s, i) => (
        <ScaleCell key={s.color} color={s.color}>
          <div className="color-box" />
          {i === 0 && <span>Less Sustainable</span>}
          {i === scale.length - 1 && <span>More Sustainable</span>}
        </ScaleCell>
      ))}
    </StyledContainer>
  );
}

export default RevenueSegmentScale;
