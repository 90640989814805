import React from 'react'

export const PaiEntityDetailTableHead = ({ impactYears }) => {
  return (
    <thead>
      <tr>
        <th></th>
        <th></th>
        <th></th>
        <th colSpan='3'>ESG Data</th>
        <th colSpan='3'>Ideal Ratings</th>
        <th colSpan='3'>Custom Data</th>
      </tr>
      <tr>
        <th>Indicators</th>
        <th>PAI Table</th>
        <th>Unit</th>
        <th>{impactYears.current} Impact</th>
        <th></th>
        <th>{impactYears.previous} Impact</th>
        <th>{impactYears.current} Impact</th>
        <th></th>
        <th>{impactYears.previous} Impact</th>
        <th>{impactYears.current} Impact</th>
        <th></th>
        <th>{impactYears.previous} Impact</th>
      </tr>
    </thead>
  )
}
