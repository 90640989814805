import axios from 'axios'

/**
 * This method checks if the file exists
 *
 * @param {*} path The path to the specific file
 * @returns False if the file doesn't exists
 */
export const fileExists = (path, callback) => {
  axios
    .get(window.location.origin + path)
    .then(response => {
      if (response.status == 200) {
        callback?.(true)
      }
    })
    .catch(() => {
      callback?.(false)
    })
}
