export const addUnit = value => {
  return isNaN(value) ? value : value + 'px';
}

export const titelize = (value, spacer) => {
  return value.split(spacer).map((word) => {return word.charAt(0).toUpperCase() + word.slice(1)}).join(" ")
}

/**
 * Returns a color based on the legend values
 * 
 * @param {*} geo GeoJSON/Geography element
 * @param {*} data Data from where we take the values
 * @param {*} legendData The legend data
 * @param {*} noDataColor Color to be returned in case no data is found for an element
 * @param {*} scaleNr The scale number we multiply the upper & lowerBound property of an legendData element
 * 
 * @returns Color
 */
export const getColorFromLegendData = (geo, data, legendData, scaleNr = 1000000, noDataColor = '#F3F6FE') => {
  if (!data) return noDataColor;
  const value = data[geo.properties.abbreviation];

  if (value && legendData?.length) {
      for (const { dotColor, lowerBound, upperBound } of legendData) {
          const color = dotColor;
          if (upperBound !== null
              && lowerBound !== null
              && value > (lowerBound * scaleNr)
              && value <= (upperBound * scaleNr)) {
              return color;
          }
          else if (!upperBound && lowerBound !== null
              && value > lowerBound * scaleNr) {
              return color;
          }
          else if (upperBound !== null && !lowerBound
              && value <= upperBound * scaleNr) {
              return color;
          }
      }
  }
  return noDataColor;
}