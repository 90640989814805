import axios from 'axios'

export const getImpactBenchmark = ({
  impactBenchmarkId,
  snapshotId = null,
}) => {
  const query = snapshotId ? `?portfolio_snapshot_id=${snapshotId}` : ''
  return axios.get(`/app/api/impact_benchmarks/${impactBenchmarkId}${query}`)
}

export const getImpactBenchmarks = () => {
  return axios.get('/app/api/impact_benchmarks/')
}
