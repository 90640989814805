import PropTypes from 'prop-types'
import React from 'react'

export const TcfdWeightCell = ({ row }) => {
  return (
    row && (
      <td
        style={{
          minWidth: '88px',
          borderLeft: 'none',
          color: '#979797',
          textAlign: 'center',
          fontWeight: '700',
        }}
      >
        {parseFloat(row.weight).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + '%' || '—'}
      </td>
    )
  )
}

TcfdWeightCell.propTypes = {
  row: PropTypes.object.isRequired,
}
