import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import ReactGA from 'react-ga'
import styled from 'styled-components'

import useNavigation from '../../../yb_hooks/useNavigation'

const StyledInputGroup = styled(InputGroup)`
  --input-white: #f1f1f1;
  --input-group-box-shadow: none;

  height: 34px;
  border-radius: 5px;
  background-color: var(--input-white);

  input {
    padding: 0.375rem 1.25rem;
    height: 34px;
    display: flex;
    align-items: center;
    border: 1px solid var(--input-white);
    background-color: var(--input-white);
    font-size: 14px;
    line-height: 1.1;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

    &::placeholder {
      color: #c2c2c2;
    }
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
    &:focus {
      border: 1px solid var(--input-white);
      background-color: var(--input-white);
      box-shadow: var(--input-group-box-shadow);
    }
  }

  button {
    padding: 0.25rem 10px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid var(--input-white);
    background-color: var(--input-white);
    color: #94979b;

    svg {
      width: 16px;
      height: 16px;
      transition: fill 0.15s ease-in-out;
    }
    &:hover,
    &:focus,
    &:active {
      border-color: #e4e4e4;
      background-color: #e4e4e4;
      color: #7b7e82;
    }
    &:focus {
      box-shadow: var(--input-group-box-shadow);
    }
  }
`

export const SearchInput = () => {
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const { navigateTo } = useNavigation()

  const handleSubmit = e => {
    e.preventDefault()
    if (query.length < 2) return
    setLoading(true)
    ReactGA.event({
      category: 'Main App',
      action: 'Search',
      label: `searched for:${query}`,
    })

    navigateTo({
      pathname: '/app/research_center',
      state: { searchQuery: encodeURIComponent(query) },
    })
  }
  const handleQueryInput = value => {
    setQuery(value)
  }
  useEffect(() => {
    setQuery('')
    setLoading(false)
  }, [])

  return (
    <form onSubmit={e => handleSubmit(e)} data-cy='header_search_form'>
      <StyledInputGroup>
        <FormControl
          type='search'
          placeholder='Search Content'
          aria-label='Search content'
          onChange={e => handleQueryInput(e.target.value)}
        />
        <InputGroup.Append>
          <button className='btn' type='submit' disabled={loading}>
            <svg
              width='17'
              height='17'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11.047 10.377h.745l4.708 4.717-1.406 1.406-4.717-4.707v-.746l-.254-.264a6.105 6.105 0 0 1-3.99 1.481 6.132 6.132 0 1 1 6.131-6.132 6.105 6.105 0 0 1-1.481 3.99l.264.255Zm-9.16-4.245a4.24 4.24 0 0 0 4.245 4.245 4.24 4.24 0 0 0 4.245-4.245 4.24 4.24 0 0 0-4.245-4.245 4.24 4.24 0 0 0-4.245 4.245Z'
                fill='currentColor'
              />
            </svg>
          </button>
        </InputGroup.Append>
      </StyledInputGroup>
    </form>
  )
}
