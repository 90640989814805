import React from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'

import Card from '../../../components/styleguide/Card'
import CardTitle from '../../../components/styleguide/CardTitle'
import HeatMapChart from '../../charts/heat_map_chart/HeatMapChart'
import HeatMapLegend from './components/HeatMapLegend'

const StyledContainer = styled.div`
  width: 610px;
`

const HeatMapCard = ({
  title,
  data,
  geoUrl,
  legendData,
  fillColor,
  'data-cy': dataCy,
}) => {
  if (!data) return null

  return (
    <StyledContainer>
      <Card data-cy={dataCy}>
        {/* Title */}
        <CardTitle title={title} fontSize='14px' textTransform='uppercase' />

        <Row>
          <Col md={8}>
            {/* Map Chart */}
            <HeatMapChart
              data-cy='heat_map_chart'
              data={data}
              geoUrl={geoUrl}
              fillColor={fillColor}
            />
          </Col>
          <Col>
            {/* Map legend */}
            <HeatMapLegend data-cy='heat_map_legend' data={legendData} />
          </Col>
        </Row>
      </Card>
    </StyledContainer>
  )
}

export default HeatMapCard
