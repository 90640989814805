import React, { useMemo, useState } from 'react'

import { getPaiIndicators } from '../../../../../repositories/portfolio_repository'
import { FONT_COLOR_SECONDARY } from '../../../../../styles/colors'
import SpinnerContainer from '../../../../../yb_components/styleguide/spinners/SpinnerContainer'
import {
  getNow,
  getRemainingLoadingTime,
} from '../../../../../yb_helpers/loader'
import { useUnsavedChangesGuard } from '../../../../../yb_hooks/security'
import { PaiLegend } from '../components/pai_legend/PaiLegend'
import ErrorMessage from '../components/tables/error_message/ErrorMessage'
import { PaiIndicatorsFilters } from '../components/tables/pai_indicators/filters/PaiIndicatorsFilters'
import { PaiIndicatorsTable } from '../components/tables/pai_indicators/PaiIndicatorsTable'

export const PaiPolicyIndicatorsTab = ({ portfolioId, snapshotId }) => {
  const [indicatorChanges, setIndicatorChanges] = useState({ x: false })
  const [impactYears, setImpactYears] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [hasFetchError, setHasFetchError] = useState(false)
  const errorMessage = useMemo(
    () => hasFetchError && <ErrorMessage />,
    [hasFetchError]
  )
  const hasChanges = useMemo(() => {
    const flags = Object.values(indicatorChanges)

    return flags.length ? flags.every(Boolean) : false
  }, [indicatorChanges])
  const [indicators, setIndicators] = useState([])
  const [categories, setCategories] = useState([])

  const fetchIndicators = query => {
    const now = getNow()

    setIsLoading(true)
    setHasFetchError(false)

    getPaiIndicators(portfolioId, snapshotId, query)
      .then(({ data }) => {
        setIndicators(data.table_data)
        setCategories(data.categories)
        setImpactYears({
          current: data.current_year,
          previous: data.last_year,
        })
      })
      .catch(() => {
        setIndicators([])
        setHasFetchError(true)
      })
      .finally(() => {
        const remainingLoadingTime = getRemainingLoadingTime(now)

        setTimeout(() => setIsLoading(false), remainingLoadingTime)
      })
  }

  useUnsavedChangesGuard(hasChanges)

  return (
    <main>
      <div
        style={{
          display: 'flex',
          alignItems: 'top',
          gap: '20px',
        }}
      >
        <p style={{ color: `${FONT_COLOR_SECONDARY}`, flexGrow: 1 }}>
          This is a preview of your entire portfolio’s Principal Adverse
          Impacts. The completion percentage identifies the data sufficiency
          needs across your portfolio. To explore which holdings need additional
          data to reach completion, navigate to Entities to see a data
          completion breakdown at the company level. Use Explanations and
          Actions Taken feature to enter descriptions of the actions taken
          during the reference period and actions planned or targets set by the
          financial market participant for the next reference period to avoid or
          reduce the principal adverse impacts identified.
        </p>
        <PaiLegend style={{ marginTop: '4px' }} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <PaiIndicatorsFilters
          onChange={fetchIndicators}
          categories={categories}
        />
        <SpinnerContainer isLoading={isLoading}>
          <PaiIndicatorsTable
            impactYears={impactYears}
            data={indicators}
            explanationData={{
              portfolioId,
              snapshotId,
              impactYears,
            }}
            noDataText={errorMessage}
            setIndicatorChanges={setIndicatorChanges}
          />
          <PaiLegend style={{ alignSelf: 'flex-end' }} />
        </SpinnerContainer>
      </div>
    </main>
  )
}
