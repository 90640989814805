export const CARBON_REPORT_PDF_TEXTS = {
  carbonOwnership: {
    title: 'Carbon Emissions Ownership & Intensity For Top 10 Holdings',
    description:
      'The portfolio summary provides a basic overview of the carbon intensity of the portfolio holdings and of the portfolio itself. Intensity is the measure of units of carbon emissions per unit of revenue and the carbon footprint is the overall amount of carbon that the portfolio is responsible for based on the shares that are held in each investment.',
  },
  sectorAllocation: {
    title: 'Carbon Attribution',
    description:
      'Portfolio Managers use attribution to understand how their sector weight allocations and company selection in those sectors contributed to better returns than the benchmark. This module does the same thing but uses sector carbon intensity (carbon/revenues) to express the same analysis.',
  },
  carbonTaxRates: {
    title: 'Carbon Tax Exposure',
    description:
      "Many countries have a carbon tax or price in place already. The product of a company's annual emissions and the price is shown as a percent of the company's enterprise value.",
  },
  renewableEnergy: {
    title: 'Renewable Energy Purchasing',
  },
  powerCompanyTransitions: {
    title: 'Power Company Transition',
  },
  solutionCompanies: {
    title: 'Solution Company Holdings',
  },
  sbti: {
    title: 'Science Based Targets Initiative',
  },
  budgetProjections: {
    title: '2030 Budget Projections',
    description:
      'Each of your holding companies has its own carbon budget extending to 2050. This metric counts in your portfolio how many holdings will be over budget by 2030 and how many will still be over budget by 2050. You see which ones Intersect with their budgeted emissions by 2030 and 2050, which of your holdings is projected to be Well Below budget or even at zero by 2050.',
  },
  leadersLaggards: {
    title: 'Leaders and Laggards',
  },
}
