import React from 'react'

import {
  StyledContainer,
  StyledIconContainer,
  StyledLabel,
  StyledRow,
  StyledScore,
  StyledScoreContainer,
  StyledTitle,
} from './TrendList.styles'

const TrendList = ({
  title,
  data,
  isPdfTemplate = false,
  'data-cy': dataCy,
}) => {
  if (!data?.length) return null

  const renderTrendIcon = slope => {
    const trendIcon = {
      positive: <i className='fas fa-arrow-up positive-icon' />,
      negative: <i className='fas fa-arrow-down negative-icon' />,
      steady: '–',
    }

    let icon = trendIcon.steady
    if (slope > 0) icon = trendIcon.positive
    else if (slope < 0) icon = trendIcon.negative

    return <StyledIconContainer>{icon}</StyledIconContainer>
  }

  return (
    <StyledContainer isPdfTemplate={isPdfTemplate} data-cy={dataCy}>
      <StyledTitle isPdfTemplate={isPdfTemplate}>{title}</StyledTitle>

      {data.map(elem => (
        <StyledRow key={elem.key} isPdfTemplate={isPdfTemplate}>
          <StyledLabel isPdfTemplate={isPdfTemplate}>{elem.name}</StyledLabel>
          <StyledScoreContainer isPdfTemplate={isPdfTemplate}>
            <StyledScore isPdfTemplate={isPdfTemplate}>
              {Math.round(elem.score)}%
            </StyledScore>
            {renderTrendIcon(parseFloat(elem.slope))}
          </StyledScoreContainer>
        </StyledRow>
      ))}
    </StyledContainer>
  )
}

export default TrendList
