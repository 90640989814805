import React from 'react'

import YBBreadcrumb from '../../../components/styleguide/YBBreadcrumb'
import { TargetType } from '../../../yb_constants/enums/global_enums'

const TargetBreadcrumbs = ({
  target,
  targetType,
  additionalBreadcrumbs = [],
}) => {
  if (!target) return null

  switch (targetType) {
    case TargetType.TRBC_INDUSTRY_GROUP:
      return (
        <YBBreadcrumb
          breadcrumbs={[
            { name: 'Home', link: '/app' },
            { name: 'Sectors', link: '/app/sectors' },
            { name: target?.name, link: `/app/sectors/${target?.id}` },
            ...additionalBreadcrumbs,
          ]}
        />
      )

    case TargetType.PORTFOLIO_SNAPSHOT:
      return (
        <YBBreadcrumb
          breadcrumbs={[
            { name: 'Home', link: '/app' },
            { name: 'Portfolios', link: '/app/portfolios' },
            {
              name: target?.name,
              link: `/app/portfolios/${target?.portfolio_id}/snapshots/${target?.id}`,
            },
            ...additionalBreadcrumbs,
          ]}
        />
      )

    case TargetType.INSTRUMENT:
    default:
      return (
        <YBBreadcrumb
          breadcrumbs={[
            { name: 'Home', link: '/app' },
            { name: 'Companies', active: true },
            { name: target?.name, link: `/app/instruments/${target?.id}` },
            ...additionalBreadcrumbs,
          ]}
        />
      )
  }
}

export default TargetBreadcrumbs
