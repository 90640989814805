//LIBRARIES
import React from 'react';

//COMPONENTS
import SectionWrapper from '../../../../../components/styleguide/SectionWrapper';
import TextSection from '../../../../../components/styleguide/TextSection';
import { blankMessages } from '../../../../../constants/funds';


const IFCPrincipleItem = ({ item, dataLabel="" }) => {

    return (
        <>
            <SectionWrapper margin="0px 0px 0px 20px" justify="space-between" data-cy={`${dataLabel}-item-key-label-wrapper`}>
                <TextSection width="33%">
                    <b>{ item.description.toUpperCase() }</b>
                </TextSection>
                <TextSection width="33%" dataLabel={`${dataLabel}-item-blank-wrapper-one`}>
                </TextSection>
                <TextSection width="33%" dataLabel={`${dataLabel}-item-blank-wrapper-two`}>
                </TextSection>
            </SectionWrapper>

            <SectionWrapper margin="0px 0px 40px 20px" justify="space-between" data-cy={`${dataLabel}-description-wrapper`}>
            <TextSection width="33%" data-cy={`${dataLabel}-description`}>
                { item && item.questionnaire_prompt }
            </TextSection>
            <TextSection width="33%" align="center" data-cy={`${dataLabel}-item-value`}>
                {item && item.value && parseFloat(item.value).toFixed(0)}
            </TextSection>
            <TextSection width="33%" data-cy={`${dataLabel}-note-value`}>
                {item && item.note || blankMessages.ifc_principles}
            </TextSection>
        </SectionWrapper>
        </>

    )
}

export default IFCPrincipleItem;
