import React from 'react';
import Co2ProjectionsChart from '../../components/charts/Co2ProjectionsChart';



const Co2ProjectionsCardPdf = ({ projections }) => {

    return(
        <>
            <span className="Co2-PDF-Header">Current &amp; Projected CO<sub>2</sub> Emissions</span>
            <p className="pdf-description" style={{marginBottom:'-10px'}}>
                Projected Total CO<sub>2</sub> Emissions shows the company's current emissions trajectory to 2050
                compared to what its emissions would need to be if the company were to operate within the global carbon
                budget ("Budgeted CO<sub>2</sub> Emissions"), a declining yearly limit of global emissions over the next
                8 years. "Committed CO<sub>2</sub> Emissions" shows if the company has made a carbon reduction
                commitment from its current trajectory.
            </p>
            <div className="line-graph-wrapper-pdf">
            <div className="line-chart-y-axis-co2-pdf">CO₂ Emissions (metric tonnes)</div>
                <div className="line-chart-inner-wrapper">
                <Co2ProjectionsChart
                    projections={projections.projections}
                    wrapperClass="Co2-Projection-Graph-Key-pdf"
                    markerClass="Co2-Projection-Graph-Key-Marker-Wrapper-pdf"
                />
                <div className="line-chart-x-axis-co2-pdf">Time (years)</div>
                </div>
            </div>
            {
                projections && (projections.commitments || projections.comments) &&
                <div className="projections-pdf-commitments">
                    {
                        projections && projections.commitments && 
                        <p className="pdf-description pdf-description-yb-comments pdf-commitments">
                            <span className="Co2-PDF-Header-sub">SBTI Commitment Notes:</span>
                            <br/>
                            {projections.commitments}
                        </p>
                    }
                    {
                        projections && projections.comments && 
                        <p className="pdf-description pdf-description-yb-comments">
                            <span className="Co2-PDF-Header-sub">YvesBlue Comments:</span>
                            <br/>
                            {projections.comments}
                        </p>
                    }
                </div>
            }
        </>
    )
}

export default Co2ProjectionsCardPdf;
