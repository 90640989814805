import React, { useState } from 'react';
import styled from 'styled-components';
import SDG1 from './sdgIcons/filled-sdg-01.svg';
import SDG2 from './sdgIcons/filled-sdg-02.svg';
import SDG3 from './sdgIcons/filled-sdg-03.svg';
import SDG4 from './sdgIcons/filled-sdg-04.svg';
import SDG5 from './sdgIcons/filled-sdg-05.svg';
import SDG6 from './sdgIcons/filled-sdg-06.svg';
import SDG7 from './sdgIcons/filled-sdg-07.svg';
import SDG8 from './sdgIcons/filled-sdg-08.svg';
import SDG9 from './sdgIcons/filled-sdg-09.svg';
import SDG10 from './sdgIcons/filled-sdg-10.svg';
import SDG11 from './sdgIcons/filled-sdg-11.svg';
import SDG12 from './sdgIcons/filled-sdg-12.svg';
import SDG13 from './sdgIcons/filled-sdg-13.svg';
import SDG14 from './sdgIcons/filled-sdg-14.svg';
import SDG15 from './sdgIcons/filled-sdg-15.svg';
import SDG16 from './sdgIcons/filled-sdg-16.svg';
import SDG17 from './sdgIcons/filled-sdg-17.svg';
import withErrorBoundary from '../../hocs/withErrorBoundary';
import Tooltip from './Tooltip';
import TextView from './TextView';
import { SDGDescriptions } from '../../constants';

const sdgIcons = {
  1: SDG1,
  2: SDG2,
  3: SDG3,
  4: SDG4,
  5: SDG5,
  6: SDG6,
  7: SDG7,
  8: SDG8,
  9: SDG9,
  10: SDG10,
  11: SDG11,
  12: SDG12,
  13: SDG13,
  14: SDG14,
  15: SDG15,
  16: SDG16,
  17: SDG17,
}

const IconContainer = styled.div`
  position: relative;
  display: inline-block;
`

// Warning: 'withTooltip' being true will break the ability of SDGIcon to be rendered within <svg> tags
const SDGIcon = ({sdg, height, width, x, y, withTooltip}) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const Icon = sdgIcons[sdg];
  const renderIcon = () => (
    <Icon height={height} width={width} x={x} y={y}/>
  )

  const onHover = () => setShowTooltip(true)
  const onLoseHover = () => setShowTooltip(false)
  const containerHandlers = withTooltip ?
    {
      onMouseEnter: onHover,
      onMouseLeave: onLoseHover
    } : {}


  return (
    withTooltip ? (
      <IconContainer {...containerHandlers}>
        { renderIcon() }
          <Tooltip show={showTooltip} padding={10} width={'max-content'} x={width / 2} y={0}>
            <TextView bold center size={14} text={ `${sdg} - ${SDGDescriptions[sdg]?.shortName}` } />
          </Tooltip>
      </IconContainer>
    ) : (
      renderIcon()
    )
  )
};

SDGIcon.defaultProps = {
  height: 40,
  width: 40,
};

export default withErrorBoundary(SDGIcon);
