import React  from 'react';
import styled from 'styled-components';
import { FONT_COLOR_SECONDARY } from '../../styles/colors';

const BreakLine = styled.div`
    height:2px;
    width:100%;
    border-top: 2px solid ${FONT_COLOR_SECONDARY}; 
`

export default BreakLine;
