import React from 'react'

import { tabNames } from '../../constants'
import PeersESGMetric from './PeersESGMetric'
import PeersESGScore from './PeersESGScore'
import PeersSDGAlignment from './PeersSDGAlignment'

const PeersPDFContainer = ({ tabName, peers }) => {
  const tabToPrint = () => {
    switch (tabName) {
      case tabNames.ESG_SCORES:
        return <PeersESGScore peers={peers} />
      case tabNames.SDG_ALIGNMENT:
        return <PeersSDGAlignment peers={peers} />
      case tabNames.ENVIRONMENTAL:
        return <PeersESGMetric peers={peers} metricType='e' />
      case tabNames.SOCIAL:
        return <PeersESGMetric peers={peers} metricType='s' />
      case tabNames.GOVERNANCE:
        return <PeersESGMetric peers={peers} metricType='g' />
    }
  }

  return <div className='peers-pdf'>{tabToPrint()}</div>
}

export default PeersPDFContainer
