import React, { useState } from 'react';
import styled from 'styled-components';
import {
  ComposableMap,
  Geographies,
  Geography,
} from "react-simple-maps";

import { abbreviateNumber } from '../../../helpers/number';
import Tooltip from '../../../components/styleguide/Tooltip';
import TextView from '../../../components/styleguide/TextView';

const StyledContainer = styled.div`
  height: ${props => props.height || '270px'};
  align-items: center;
  justify-content: center;
  display: flex;
`;

const HeatMapChart = ({
  data,
  geoUrl,
  height,
  projection = 'geoAlbersUsa',
  projectionConfig = { scale: 1000 },
  fillColor = () => '#F3F6FE',
  'data-cy': dataCy
}) => {
  if (!data || !geoUrl) return null;

  /* Hooks */
  const [tooltipContent, setTooltipContent] = useState(null);
  const [tooltipCoords, setTooltipCoords] = useState({ x: 0, y: 0 });

  /* Events */
  const onMouseMoveMap = ({ clientX, clientY }) => setTooltipCoords({ x: clientX, y: clientY - 5 });

  const onMouseEnterGeography = (geo) => {
    const { name, abbreviation } = geo.properties;
    if (data[abbreviation]) setTooltipContent({ label: `$${abbreviateNumber(data[abbreviation])}`, subLabel: name });
  }

  const onMouseLeaveGeography = () => setTooltipContent(null);

  /* Render */
  return (
    <StyledContainer height={height} data-cy={dataCy}>
      <ComposableMap
        height={500}
        projection={projection}
        projectionConfig={projectionConfig}
        onMouseMove={onMouseMoveMap}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) => (
            <>
              {geographies.map(geo => {
                const fill = fillColor(geo, data);
                
                return (
                  <Geography
                    /* Data */
                    id={geo.rsmKey}
                    key={geo.rsmKey}
                    geography={geo}

                    /* Events */
                    onMouseEnter={() => onMouseEnterGeography(geo)}
                    onMouseLeave={onMouseLeaveGeography}

                    /* Style */
                    fill={fill}
                    stroke={"#000"}
                    strokeWidth={1.5}
                    style={{
                      pressed: {
                        fill: "#7C99E5",
                        outline: "none"
                      }
                    }}
                  />
                )
              })}
            </>
          )}
        </Geographies>
      </ComposableMap>

      {/* Mouse Tooltip */}
      {tooltipContent && (
        <Tooltip data-cy={'heatmap_chart_tooltip'} padding={10} width={'100px'} show={true} x={tooltipCoords.x} y={tooltipCoords.y} position={'fixed'} zIndex={10000}>
          <TextView bold center text={tooltipContent.label} />
          <TextView center text={tooltipContent.subLabel} />
        </Tooltip>
      )}

    </StyledContainer >
  );
}

export default HeatMapChart;
