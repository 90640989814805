import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import { queryStringParse } from '../../../helpers/objects_and_arrays'

const StyledA = styled.a`
  cursor: pointer;

  i {
    margin-left: 0.25rem;
  }
`

const SearchByName = ({ onNameChange = () => {} }) => {
  const [nameFilter, setNameFilter] = useState(queryStringParse().query)
  const nameSearchRef = useRef()

  const onSearch = () => {
    setNameFilter(nameSearchRef.current.value)
    onNameChange(nameSearchRef.current.value)
  }

  const clearSearchValue = () => {
    setNameFilter(null)
    onNameChange(null)
  }

  return (
    <>
      <div className='card mb-4'>
        <h6 className='card-header'>Search by Fund Name</h6>
        <div className='card-body'>
          <div className='form-inline'>
            <div className='form-group mb-2'>
              <input
                placeholder='Search'
                className='form-control form-control-sm'
                type='text'
                onKeyPress={e => e.key == 'Enter' && onSearch()}
                ref={nameSearchRef}
              />
              <button
                className='btn btn-secondary btn-sm ml-1'
                onClick={onSearch}
              >
                Go
              </button>
            </div>
          </div>

          {nameFilter && (
            <StyledA onClick={clearSearchValue}>
              {nameFilter}
              <i className='fal fa-times-circle'></i>
            </StyledA>
          )}
        </div>
      </div>
    </>
  )
}

export default SearchByName
