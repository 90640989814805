import React from 'react'

import AdvancedView from './ESGPillarsInput.AdvancedView'
import BasicView from './ESGPillarsInput.BasicView'

const ESGPillarsInput = ({
  view,
  dispatch,
  loadedESGPillars,
  action,
  propsDefaultWeights,
  esgMetricsMap,
  currentSectorId = 'overall',
}) => {
  const moduleViews = {
    basic: (
      <BasicView
        dispatch={dispatch}
        loadedESGPillars={loadedESGPillars}
        action={action}
        propsDefaultWeights={propsDefaultWeights}
      />
    ),
    advanced: (
      <AdvancedView
        dispatch={dispatch}
        loadedPillars={loadedESGPillars}
        action={action}
        propsDefaultWeights={propsDefaultWeights}
        esgMetricsMap={esgMetricsMap}
        currentSectorId={currentSectorId}
      />
    ),
  }

  return moduleViews[view]
}

export default ESGPillarsInput
