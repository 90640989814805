import React, { useState, useEffect } from "react";

// STYLEGUIDE COMPONENTS
import Button from "../../../components/styleguide/Button";
import CardTitle from "../../../components/styleguide/CardTitle";
import Container from "../../../components/styleguide/Container";
import Link from "../../../components/styleguide/Link";
import SectionWrapper from "../../../components/styleguide/SectionWrapper";
import Alert from "../../../components/styleguide/Alert";
import WrapperColumn from "../../../components/styleguide/WrapperColumn";

// STYLED COMPONENTS
import TwoFactorCard from "./components/styled/TwoFactorCard";

// PAGE COMPONENTS
import OtpSecretShow from "./components/OtpSecretShow";
import OtpSecretAttemptsNew from "./components/otp_secrets/attempts/OtpSecretAttemptsNew";
import OtpBackupCodesIndex from "./components/OtpBackupCodesIndex";

// REPOSITORIES
import { getNewOtpSecret } from "../../../repositories/otp_secrets_repository";

// CONSTANTS
import { stepTitles } from "../../../constants/two_factor";

const TwoFactorIndex = () => {
    const [otpSecret, setOtpSecret] = useState(null);
    const [qrCode, setQrCode] = useState(null);
    const [loading, setLoading] = useState(true);
    const [step, setStep] = useState(0);
    const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
    const [alert, setAlert] = useState("");

    useEffect(() => {
        generateNewOptSecret();
    }, []);

    const generateNewOptSecret = () => {
        getNewOtpSecret().then((response) => {
            setOtpSecret(response.data.decrypted_secret);
            setQrCode(response.data.qr_code);
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.otp_secret[0] === "must_be_blank") {
                setTwoFactorEnabled(true);
            }
        });
    };

    const onNext = () => {
        setStep(step + 1);
    };

    const onBackToAccountEdit = () => {
        window.location.href="/app/account/edit";
    };

    const getComponent = () => {
        if (step === 0) {
            return <OtpSecretShow otpSecret={otpSecret} qrCode={qrCode} />
        } else if (step === 1) {
            return <OtpSecretAttemptsNew callback={onNext} otpSecret={otpSecret} showNextButton={true}/>
        } else if (step === 2) {
            return <OtpBackupCodesIndex showBackToAccountSettingsLink={true}/>
        } else {
            return <SectionWrapper/>
        }
    };

    return(
        <Container>
            <TwoFactorCard padding={30}>
                <CardTitle title={stepTitles[step]} />
                {
                    alert.length > 0 && <Alert type="success">{ alert }</Alert>
                }

                {
                    twoFactorEnabled && <div>Two Factor already enabled.  To disable or get One Time Backup Codes, go to Account Settings.</div>
                }

                {
                    !loading && !twoFactorEnabled &&
                    <WrapperColumn styles={{height:"100%", justify:"space-between"}}>
                        { getComponent() }

                        {
                            step === 0 &&
                            <SectionWrapper justify="space-between" margin="80px 0 0 0" width="100%">
                                <Link onClick={onBackToAccountEdit}>Cancel</Link>
                                <Button width="20%" label="Next" onClick={onNext}/>
                            </SectionWrapper>
                        }

                        {
                            step === 3 &&
                            <SectionWrapper justify="flex-end" margin="80px 0 0 0" width="100%">
                                <Button width="50%" label="Back to Account Settings" onClick={onBackToAccountEdit}/>
                            </SectionWrapper>
                        }
                    </WrapperColumn>
                }
            </TwoFactorCard>
        </Container>
    )
};

export default TwoFactorIndex;
