import styled from 'styled-components';

export const StyledCell = styled.td`
  color: ${props => props.color};
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  border: none;
  width: 16%;
`
