import useFeedbackMessage from '@env/hooks/forms/useFeedbackMessage'
import useInputValidations from '@env/hooks/forms/useInputValidations'
import useRequiredValidation from '@env/hooks/forms/useRequiredValidation'
import useShouldDisplayInputFeedback from '@env/hooks/forms/useShouldDisplayInputFeedback'
import withFormControl from '@ui/components/compositions/withFormControl/withFormControl'
import withFormRow from '@ui/components/compositions/withFormRow/withFormRow'
import { InputFeedback } from '@ui/components/presentation/copy/InputFeedback'
import getTestingProps from '@utils/test/getTestingProps'
import React, { useState } from 'react'
import ReactCurrencyInput, {
  CurrencyInputProps,
} from 'react-currency-input-field'

// import theme from './CurrencyInput.module.scss'
import './CurrencyInput.module.scss'

export interface IProps extends CurrencyInputProps, YB.IFormControl<string> {
  name: string
  currency?: TCurrency
}

type TCurrency = '$' | '€' | '£'

const CurrencyInput: React.FC<IProps> = ({
  name,
  required = true,
  model = useState<string>(''),
  feedbackModel = useState<string>(''),
  formFeedbackModel = useState<string>(''),
  currency = '$',
  validations = [],
  disabled,
  ...reactCurrencyInputProps
}) => {
  const [value, setValue] = model
  const [isPristine, setIsPristine] = useState(true)
  const feedbackMessage = useFeedbackMessage(feedbackModel, formFeedbackModel)
  const isInvalid = useShouldDisplayInputFeedback(
    feedbackMessage,
    isPristine,
    disabled
  )

  const handleBlur = () => {
    setIsPristine(false)
  }

  useInputValidations(
    value,
    useRequiredValidation(validations, required),
    feedbackModel
  )

  return (
    <div>
      <div className='CurrencyInput'>
        <span className='currencyBadge'>{currency}</span>
        <ReactCurrencyInput
          {...getTestingProps(name)}
          {...reactCurrencyInputProps}
          className='input'
          disabled={disabled}
          value={value}
          onBlur={handleBlur}
          onValueChange={value => setValue(value ?? '')}
        />
      </div>
      <InputFeedback isPresent={isInvalid} feedbackMessage={feedbackMessage} />
    </div>
  )
}

export default CurrencyInput

export const CurrencyInputControl = withFormControl(CurrencyInput)

export const CurrencyInputRow = withFormRow(CurrencyInput)
