import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _find from 'lodash.find';
import _isEmpty from 'lodash.isempty';

import BaseDialog from './BaseDialog';
import TextView from '../styleguide/TextView';
import SelectInput from '../styleguide/SelectInput';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextInput from '../styleguide/TextInput';
import TextAreaPlain from "../styleguide/TextAreaPlain";

import InputLabel from '../styleguide/InputLabel';
import { BG_COLOR_SECONDARY, APP_COLOR_PRIMARY, FONT_COLOR_PRIMARY, BORDER_COLOR_PRIMARY } from '../../styles/colors';
import { updateFocusArea } from '../../repositories/risk_assessments_repository';
import { mapStateToRiskAssessment } from '../../mappers/risk_assessment_mapper';
import DialogControls from './DialogControls';

const DialogContent = styled.div`
  .edit-risk-assessment-dialog-unit-input-group {
    display: flex;
    align-items: flex-end;
    > * {
      width: 100%;
    }
  }
  .edit-risk-assessment-dialog-previous-values-list {
    margin-top: 10px;
    ul {
      li {
        padding: 5px 0;
        + li {
          border-top: 1px solid ${BORDER_COLOR_PRIMARY}
        }
      }
    }
  }
`;

const saveChanges = (holdingId, focusAreaId, dataType, value, date, note) => {
  return updateFocusArea(holdingId, focusAreaId, dataType, value, date, note);
}

const EditFocusAreaValueDialog = ({ holdingId, data, onClose, onSuccess, editingType, open }) => {
  const [value, setValue] = useState(data[editingType]);
  const [note, setNote] = useState(null);
  const [date, setDate]   = useState((editingType === "current_value") ? data.current_as_of : data.target_date);
  const [error, setError] = useState("");
  const [dateError, setDateError] = useState("");

  const onInputChange = (id, value) => {
    (id == "value") ? setValue(value) : setDate(value);
  }

  const onNoteChange = (id, value) => {
    setNote(value);
  }

  const onModalClose = () => {
    setValue(data[editingType]);
    setNote("");
    setDate((editingType === "current_value") ? data.current_as_of : data.target_date);
    onClose();
  }

  useEffect(() => {
    setValue(data[editingType]);
  }, [open]);

  const typeLabel = (editingType === "current_value") ? "Current Value" : "Target Value";

  const onFormSubmit = e => {
    e.preventDefault();

    if(value === "") setError(`${typeLabel} is required`);
    else if ((date === "") && editingType === "target") setDateError("Date is required");
    else {
      saveChanges(holdingId, data.id, editingType, value, date, note)
        .then(res => {
          onModalClose();
          onSuccess(res);
        });

    }
  }

  return (
    <BaseDialog padding={25} title={`Edit ${typeLabel}`} open={open} onClose={onModalClose} render={() => (
      <DialogContent>
        <form onSubmit={onFormSubmit}>
          <Row>
            <Col xs={5}>
              <TextInput error={error} onChange={onInputChange} type="number" id="value" label="NEW VALUE" value={value} />
            </Col>
            <Col className="edit-risk-assessment-dialog-unit-input-group" xs={3}>
              <SelectInput value={data.unit} options={[{ id: data.unit, label: data.unit }]} disabled />
            </Col>
            { editingType === "target_value" ?
              <Col className="edit-risk-assessment-dialog-unit-input-group" xs={4}>
                <TextInput label="NEW DATE" type="date" id="date" onChange={onInputChange} error={dateError} value={date} />
              </Col> :
              null
            }
          </Row>
          <Row>
            <Col xs={5}>
              <TextInput onChange={onNoteChange} id="note" label="NOTE" value={note} />
            </Col>
          </Row>
          <DialogControls onClose={onModalClose} />
        </form>
      </DialogContent>
    )} />
  );
}

EditFocusAreaValueDialog.defaultProps = {
  data: {
    previous_values: []
  }
};

export default EditFocusAreaValueDialog;
