import React from 'react';
import RevenueSegmentsChartPDF from '../charts/RevenueSegmentsChartPDF';

import { ESG_SCALE_COLOR_BAD, ESG_SCALE_COLOR_BELOW_AVERAGE, ESG_SCALE_COLOR_ABOVE_AVERAGE, ESG_SCALE_COLOR_GOOD, ESG_SCALE_COLOR_EXCELLENT, } from '../../../styles/colors';

const COLOR_SCALE = [
  ESG_SCALE_COLOR_BAD,
  ESG_SCALE_COLOR_BELOW_AVERAGE,
  ESG_SCALE_COLOR_ABOVE_AVERAGE,
  ESG_SCALE_COLOR_GOOD,
  ESG_SCALE_COLOR_EXCELLENT,
];

const RevenueSegmentsSectionPDF = ({ segments }) => {
  if(!segments) return null;
  
  return (
    <section className="small revenue-segments-section">
      <div className="revenue-segments-title">
        <h2>Revenue Segments</h2>
        <div className="revenue-segments-scale">
          <span>
            {COLOR_SCALE.map(c => <span key={c} style={{ backgroundColor: c}}className="revenue-segments-scale-item"/>)}
          </span>
          <div className="revenue-segments-scale-labels">
            <span>Less<br/>Sustainable</span>
            <span>More<br/>Sustainable</span>
          </div>
        </div>
      </div>
      <RevenueSegmentsChartPDF colors={COLOR_SCALE} data={segments} />
    </section>
  );
}

export default RevenueSegmentsSectionPDF;