export const VIOLATIONS_STATS_QUERY =
  '?over_time_format=array&include=filter_values&total_state_format=properties'

export const HEADER_TITLE = 'Corporate Violations'

export const HEADER_DESCRIPTION =
  'Utilizing data from Good Jobs First, the Corporate Violations report examines the how much money corporations \
  are getting from over 1,000 taxpayer-funded assistance programs across federal, state and local governments. Data is \
  supplied by Good Jobs First and is updated on a quarterly basis.'

export const OVER_TIME_CARD_TITLE = 'Violations over time'

export const OVER_TIME_CARD_DESCRIPTION =
  'Violations Over Time examines the categorization of violations and fines into categorical buckets across the past 10 years of data.'

export const VIOLATIONS_BUCKET_DATA = [
  { label: 'No Penalties', dotColor: '#F3F6FE', upperBound: 0 },
  { label: '0 - $100K', dotColor: '#CCD5ED', lowerBound: 0, upperBound: 100 },
  {
    label: '$100K - $250K',
    dotColor: '#99ACDC',
    lowerBound: 100,
    upperBound: 250,
  },
  {
    label: '$250K - $500K',
    dotColor: '#6682CA',
    lowerBound: 250,
    upperBound: 500,
  },
  {
    label: '$500K - $1M',
    dotColor: '#3359B9',
    lowerBound: 500,
    upperBound: 1000,
  },
  { label: '$1M +', dotColor: '#002FA7', lowerBound: 1000 },
]

/* RECORDS CARD */

export const RECORDS_TABLE_TITLE = 'Violation Records'

export const RECORDS_TABLE_DESCRIPTION =
  'Corporate Violation & Fine data collected by Good Jobs First is available for deeper exploration and filtering by different categorical indices.'
