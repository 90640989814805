import React from 'react'
import styled from 'styled-components'

import SkeletonBox from './SkeletonBox'

const ChartContainer = styled.div`
  margin-left: 6px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-left: 5px;
  .chart-row {
    align-items: center;
    display: flex;
    gap: 10px;
    margin-top: 15px;
    &:first-child {
      margin-top: 10px;
    }
  }
  .chart-footer {
    padding-left: 20px;
    display: flex;
    justify-content: space-around;
    position: relative;
    bottom: 15px;
  }
`

const SkeletonChart = () => {
  const chartSection = []

  for (let i = 0; i <= 5; i++) {
    i < 5
      ? chartSection.push(
          <div className='chart-row' key={i}>
            <SkeletonBox height='9px' width='19px' />
            <SkeletonBox width='305px' height='1.51px' />
          </div>
        )
      : chartSection.push(
          <div className='chart-footer' key={i}>
            <SkeletonBox width='40px' height='12px' />
            <SkeletonBox width='40px' height='12px' />
            <SkeletonBox width='40px' height='12px' />
          </div>
        )
  }

  return <ChartContainer>{chartSection}</ChartContainer>
}

export default SkeletonChart
