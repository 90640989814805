import React from 'react'
import Alert from 'react-bootstrap/Alert'
import styled from 'styled-components'

const StyledNotice = styled.div`
  margin-top: 20px;
  font-size: 14px;
`

const ESGCustomizationNotice = ({mainBatch}) => {
  return (
    <StyledNotice className='page__container'>
      <Alert variant='primary' style={{ marginBottom: '0' }}>
        {mainBatch ?
          "The first phase of your organization-wide ESG customization is processing right now. Your portfolios will be accessible as they are individually processed, generally within 5 to 10 minutes."
          :
          "The last phase of your organization-wide ESG score customization is processing right now. This message will disappear when the process completes, generally within 15 to 25 minutes."
        }
      </Alert>
    </StyledNotice>
  )
}

export default ESGCustomizationNotice
