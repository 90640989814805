import { ITableHeadSort } from '@ui/components/presentation/Table/components/SortedTableHead'
import { useEffect } from 'react'

import {
  createSFDRDisclosure,
  getHoldingsData,
  getSFDRDisclosures,
  publishSFDRDisclosure,
} from '../../../../../../../react/repositories/portfolio_repository'
import { ICreateDisclosureData } from './components/CreateDisclosureModal/CreateDisclosureModal'
import { IDisclosuresFiltersData } from './components/DisclosuresFilters/DisclosuresFilters'
import { IPublishDisclosureData } from './components/PublishDisclosureModal/PublishDisclosureModal'

export interface IHoldingData {
  id: number
  name: string
  e_characteristics: boolean
  s_characteristics: boolean
  sustainable_investment: boolean
  environmentally_sustainable: boolean
  socially_sustainable: boolean
  fossil_gas: boolean
  nuclear: boolean
  taxonomy_aligned: boolean
  good_governance: boolean
  oecd_aligned: boolean
  sovereign: boolean
}

class HoldingDataController {
  useGetDisclosures = (
    portfolioId: string,
    snapshotId: string,
    filters: any,
    sorting: any,
    page: any,
    onSuccess: (
      data: IHoldingData[],
      totalPages?: number,
      currentPage?: number,
      indicators?: string[]
    ) => void
  ) =>
    useEffect(() => {
      getHoldingsData(portfolioId, snapshotId, {
        ...filters,
        sort_field: sorting?.value,
        sort_dir: sorting?.order,
        page: page,
      }).then(response => {
        const { holdings_data, total_pages, current_page, indicators } =
          response?.data
        onSuccess(
          holdings_data as IHoldingData[],
          total_pages,
          current_page,
          indicators
        )
      })
    }, [portfolioId, snapshotId, filters, sorting, page, onSuccess])
}

export default new HoldingDataController()
