import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { getScoreText } from '../../utils/scores_display'
import { getTcfdColor } from '../../utils/tcdf_colors'

const StyledDiv = styled.div`
  margin: ${props => (props.pdfStyle ? '10px 0 25px 0' : 0)};
  padding: 1.25rem;
  border-radius: 1.25rem;
  background-color: #fafafa;
  width: ${props => (props.pdfStyle ? '375px' : 'auto')};

  h4 {
    font-weight: 700;
    font-size: ${props =>
      props.pdfStyle
        ? '14px'
        : props.titleFontSize
        ? props.titleFontSize
        : '1rem'};
    line-height: ${props => (props.pdfStyle ? 'normal' : '1.2')};
    text-transform: uppercase;
    margin: ${props => (props.pdfStyle ? '0' : 'auto')};
  }

  main {
    justify-content: space-between;
    width: ${props => (props.pdfStyle ? '355px' : '410px')};
    height: 104px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: justify;

    & .value-wrapper {
      padding: 10px 0;
      width: auto;
      text-align: center;
    }
    & .icon-wrapper {
      padding: 20px;
    }
  }

  span {
    display: block;
    color: #979ca6;
    font-size: 14px;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 700;
    max-width: 166px;
  }
`

const DisplayValue = styled.span`
  &.value {
    color: ${props => props.textColor || '#2e384d'};
    font-size: 42px;
    font-weight: 700;
  }
`

export const TcfdOverallScoreWell = ({
  scores,
  portfolio = false,
  pdfStyle = false,
  titleFontSize,
}) => {
  const overallScoreText = portfolio
    ? 'Overall Portfolio Score'
    : 'Overall Company Score'
  const sectorScoreText = portfolio
    ? 'Overall Benchmark Score'
    : 'Sector Average Score'
  return (
    <StyledDiv pdfStyle={pdfStyle} titleFontSize={titleFontSize}>
      <header>
        <h4>Overall Score</h4>
      </header>
      <main>
        <div className='value-wrapper'>
          <DisplayValue
            className='value'
            textColor={getTcfdColor(scores?.overall_score)}
          >
            {getScoreText(scores?.overall_score)}
          </DisplayValue>
          <span>{overallScoreText}</span>
        </div>
        <div className='value-wrapper'>
          <DisplayValue className='value'>&#160;</DisplayValue>
          <span>vs.</span>
        </div>
        <div className='value-wrapper'>
          <DisplayValue
            className='value'
            textColor={getTcfdColor(scores?.benchmark_overall_score)}
          >
            {getScoreText(scores?.benchmark_overall_score)}
          </DisplayValue>
          <span>{sectorScoreText}</span>
        </div>
      </main>
    </StyledDiv>
  )
}

TcfdOverallScoreWell.propTypes = {
  portfolio: PropTypes.bool,
  scores: PropTypes.object.isRequired,
  instrument: PropTypes.bool,
  pdfStyle: PropTypes.bool,
  titleFontSize: PropTypes.string,
}
