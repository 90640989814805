import propTypes from 'prop-types'
import React from 'react'

import {
  StyledAnchor,
  StyledButton,
  StyledIconHolder,
} from './ButtonIconTop.styles'

export const ButtonIconTop = ({ src, text, color, disabled, onClick, href }) =>
  href != null && href.length ? (
    <StyledAnchor href={href} color={color} src={src}>
      <StyledIconHolder color={color} src={src} />
      {text}
    </StyledAnchor>
  ) : (
    <StyledButton
      disabled={disabled}
      onClick={e => onClick(e)}
      color={color}
      src={src}
    >
      <StyledIconHolder color={color} src={src} />
      {text}
    </StyledButton>
  )

ButtonIconTop.propTypes = {
  src: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
  color: propTypes.string,
  disabled: propTypes.bool,
  onClick: propTypes.func, // for buttons (<button>)
  href: propTypes.string, // for links (<a>)
}
ButtonIconTop.defaultProps = {
  color: '#979797',
  disabled: false,
  href: null,
}
