import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import ReactGA from 'react-ga'

import YBSelect from '../../components/styleguide/YBSelect'
import { buildSelectOptions } from '../../helpers/builders'
import {
  getPortfolioUploadDetails,
  postPortfolioUpload,
} from '../../repositories/portfolio_repository'

const PortfolioNew = () => {
  const [error, setError] = useState(null)
  const [benchmarks, setBenchmarks] = useState([])

  useEffect(() => {
    getPortfolioUploadDetails().then(response => {
      setBenchmarks(buildSelectOptions(response.data.impact_benchmarks))
    })
  }, [])

  const onUpload = e => {
    e.preventDefault()
    setError(null)
    ReactGA.event({ category: 'Portfolio', action: 'upload' })

    const formData = new FormData(document.getElementById('upload_form'))

    postPortfolioUpload(formData)
      .then(response => {
        if (response.data.redirect_to) {
          let redirectURL = response.data.redirect_to

          window.location = redirectURL
        }
      })
      .catch(error => setError(error.response.data?.message))
  }

  const renderError = () => {
    return (
      error && (
        <div
          className='alert alert-success'
          role='alert'
          data-cy='layout-alert-success'
        >
          {error}
        </div>
      )
    )
  }

  return (
    <div className='page__container'>
      {renderError()}
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom'>
        <h1 className='h2'>New Portfolio</h1>
      </div>
      <Row>
        <Col md={4}>
          <form id='upload_form'>
            <div className='form-group'>
              <label htmlFor={'portfolio_snapshot_upload_name'}>
                Portfolio Name:
              </label>
              <input
                id='portfolio_snapshot_upload_name'
                name='portfolio_snapshot_upload[name]'
                className='form-control'
                data-cy='portfolio_snapshot_upload_name'
                type='text'
              />
            </div>
            <div className='form-group'>
              <Row>
                <Col md={6}>
                  <div className='form-group'>
                    <label htmlFor='portfolio_snapshot_upload_label'>
                      Format:
                    </label>
                  </div>

                  <div className='form-group'>
                    <div className='form-check-inline'>
                      <input
                        id='portfolio_snapshot_upload_format_market_value'
                        name='portfolio_snapshot_upload[format]'
                        className='form-check-input'
                        type='radio'
                        value='market_value'
                      />
                      <label
                        className='form-check-label'
                        data-cy='portfolio_snapshot_upload_format_market_value'
                        htmlFor='portfolio_snapshot_upload_format_market_value'
                      >
                        Market Value
                      </label>
                    </div>

                    <div className='form-check-inline'>
                      <input
                        id='portfolio_snapshot_upload_format_weight'
                        name='portfolio_snapshot_upload[format]'
                        className='form-check-input'
                        type='radio'
                        value='weight'
                        defaultChecked
                      />
                      <label
                        className='form-check-label'
                        data-cy='portfolio_snapshot_upload_format_weight'
                        htmlFor='portfolio_snapshot_upload_format_weight'
                      >
                        Weight
                      </label>
                    </div>
                  </div>
                </Col>

                <Col md={6}>
                  <label htmlFor='portfolio_snapshot_upload_as_of'>
                    As Of Date:
                  </label>
                  <input
                    id='portfolio_snapshot_upload_as_of'
                    name='portfolio_snapshot_upload[as_of]'
                    className='form-control'
                    data-cy='portfolio_snapshot_upload_as_of'
                    type='date'
                    defaultValue={dayjs()
                      .utc()
                      .startOf('day')
                      .format('YYYY-MM-DD')}
                  />
                </Col>
              </Row>
            </div>

            <div className='form-group'>
              <Row>
                <Col md={10}>
                  <label htmlFor='portfolio_snapshot_upload_impact_benchmark_id'>
                    Benchmark:
                  </label>
                  {benchmarks.length > 0 ? (
                    <YBSelect
                      id='portfolio_snapshot_upload_impact_benchmark_id'
                      name='portfolio_snapshot_upload[impact_benchmark_id]'
                      data-cy='portfolio_snapshot_upload_impact_benchmark'
                      key={benchmarks[0]}
                      isSearchable={false}
                      options={benchmarks}
                      defaultValue={benchmarks[0]}
                    />
                  ) : (
                    <YBSelect isDisabled isLoading />
                  )}
                </Col>
                <Col md={2} />
              </Row>
            </div>
            <div className='form-group'>
              <input
                id='portfolio_snapshot_upload_file'
                name='portfolio_snapshot_upload[file]'
                data-cy='portfolio_snapshot_upload_file'
                className='form-control-file'
                type='file'
              />
            </div>
            <div className='form-group'>
              <button
                className='btn btn-success'
                data-cy='button-success'
                onClick={onUpload}
              >
                Create Portfolio
              </button>
              <a
                href='/app'
                className='btn btn-outline-secondary ml-1'
                data-cy='button-cancel'
              >
                Cancel
              </a>
            </div>
          </form>
        </Col>
        <Col md={4}>
          <div className='card' data-cy='upload-template-card'>
            <h4 className='card-header' data-cy='upload-template-card-header'>
              Upload Template
            </h4>
            <div className='card-body'>
              <p>
                {
                  "To upload your holdings, it is important that they're in the correct format. You can use this template file to create your upload file:"
                }
              </p>
              <a
                href='/single-portfolio-upload-template.xlsx'
                title='Excel Single Portfolio Upload Template'
                data-cy='single-portfolio-upload-template-link'
              >
                <i className='fal fa-file-excel mr-1'></i>
                Single Portfolio Upload Template for Excel
              </a>

              <hr />

              <p>
                If you are trying to upload multiple portfolios at one time, you
                can use this template to create your upload file:
              </p>
              <a
                href='/multiple-portfolio-upload-template.xlsx'
                title='Excel Multiple Portfolio Upload Template'
                data-cy='multiple-portfolio-upload-template-link'
              >
                <i className='fal fa-file-excel mr-1'></i>
                Multiple Portfolio Upload Template for Excel
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default PortfolioNew
