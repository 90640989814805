import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  margin: 0;
  padding: 1.25rem;
  border-radius: 6px;
  background-color: #fff;

  header {
    margin: 0 0 1.25rem 0;

    h3 {
      margin: 0 0 0.5rem 0;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.2;
      text-transform: uppercase;
    }
    p {
      margin: 0;
      color: #979ca6;
    }
  }
`

export const TcfdHoldingDetailCard = ({ children }) => {
  return <StyledDiv>{children}</StyledDiv>
}

TcfdHoldingDetailCard.propTypes = {
  children: PropTypes.node.isRequired,
}
