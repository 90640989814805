class PercentageInputController {
    betweenRange(value: string): string {
        const number = Number(value) || 0

        if (number >= 0 && number <= 100) return ''

        return 'Select a percentage between 0 and 100'
    }
}

export default new PercentageInputController()
