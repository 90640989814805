import styled from 'styled-components'

import Button from '../../../../components/styleguide/Button'
import Card from '../../../../components/styleguide/Card'
import {
  BG_COLOR_PRIMARY,
  BG_COLOR_WHITE,
  BORDER_COLOR_SECONDARY,
  BTN_COLOR_PRIMARY,
  FONT_COLOR_TERTIARY,
  LIST_HEADER_PRIMARY,
} from '../../../../styles/colors'

export const StyleMeasureType = styled.div`
  font-weight: normal;
  font-size: 10px;
`

export const StyledCell = styled.td`
  color: ${props => props.color};
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  width: 15vw;
  border: none;
`

export const StyledSecondaryCellText = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-transform: lowercase;
  color: ${FONT_COLOR_TERTIARY};
`

export const StyledCard = styled(Card)`
  thead tr,
  tbody tr:nth-child(odd) {
    border-bottom: 2px solid white;
  }

  tbody tr:nth-child(even) {
    border-bottom: 2px solid ${BG_COLOR_PRIMARY};
  }

  tr:first-child th {
    padding-bottom: 0;
  }

  tr:nth-child(2) th {
    padding-top: 0;
    line-height: 15px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    border-width: 0 1px;
    border-style: dashed;
    border-color: ${BORDER_COLOR_SECONDARY};
  }

  th {
    text-align: center !important;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
  }

  td.name-column {
    text-align: right;
    color: #6c757d;
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    border: none !important;
    text-transform: uppercase;
    padding: 0.94rem;
  }

  td.sdg-column {
    display: flex;
    align-items: center;

    div.sdg-name {
      padding-left: 10px;
      text-align: left;
      vertical-align: middle;
    }
  }
`
export const StyledPeers = styled.div`
  width: 100%;
  padding: 0px 15px;
  position: relative;
`

export const StyledReplacePeer = styled.a`
  cursor: pointer;
  color: ${BTN_COLOR_PRIMARY};
  font-size: 10px;
`
export const PeerFooter = styled.footer`
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin: 20px 0 0;
`

export const StyledDownloadLandscape = styled(Button)`
  width: auto;
  height: 40px;
  font-weight: bold;
  padding: 0 20px;
  text-transform: uppercase;

  img,
  .spinner-border {
    height: 20px;
    width: 20px;
    margin-right: 5px;
    vertical-align: top;
  }
`

export const StyledList = styled.ul`
  list-style: none;
  padding: 0;

  li {
    padding: 10px 20px;
    border-radius: 2px;

    &:first-child {
      background-color: ${LIST_HEADER_PRIMARY};
      font-weight: 700;
    }

    &:nth-child(odd):not(:first-child) {
      background-color: ${BG_COLOR_PRIMARY};
    }

    &:nth-child(even) {
      background-color: ${BG_COLOR_WHITE};
    }
  }
`
