import React from "react";
import SectionWrapper from "../../../../components/styleguide/SectionWrapper";
import Link from "../../../../components/styleguide/Link";

const OtpSecretShow = ({ otpSecret, qrCode }) => {
    const formattedOtpSecret = () => {
        return otpSecret.match(/.{1,4}/g).join(" ");
    };

    const onCopyToClipboardClick = () => {
        navigator.clipboard.writeText(otpSecret);
    };

    return(<>
        <SectionWrapper>
            Open Google Authenticator on your mobile device and scan this QR code.
        </SectionWrapper>
        <SectionWrapper margin="40px 0 0 0" align="center">
            <SectionWrapper>
                <img src={qrCode} height="200" width="200" />
            </SectionWrapper>
            <SectionWrapper>
                <div>
                    Or enter the following code manually into your app: <br/>
                    { formattedOtpSecret() } <br />
                    <Link onClick={onCopyToClipboardClick}>Copy to Clipboard</Link>

                </div>
            </SectionWrapper>
        </SectionWrapper>
        <SectionWrapper>
            When the registration is successful, the app will begin showing a 6-digit verification code.
        </SectionWrapper>
    </>)
};

export default OtpSecretShow;
