import {defaultFactor, mostRecentYear, sectorsLimit} from "./constants";
import { searchESGYearlyMetrics } from "../../repositories/sectors_repository";

const SectorService = {
    getMetrics: (params={}) => {
        const queryParams = {
            fiscal_year: params["mostRecentYear"] || mostRecentYear,
            target_type: params["targetType"] || "TRBCIndustryGroup",
            industry_group_id: params["industryGroupId"],
            sort_direction: params["sortDirection"] || defaultFactor.sortDirection,
            sort_column: params["sortColumn"] || defaultFactor.sortColumn,
            visible_fields: params["visibleFields"] || defaultFactor.visibleFields,
            limit: params["limit"] || sectorsLimit
        };

        return searchESGYearlyMetrics(queryParams);
    },
};

export default SectorService;