import React from 'react';
import styled from 'styled-components';

const StyledLogo = styled.img`
  height: 150px;
  width: 150px;
  object-fit: contain;
  background: url(${props => props.logo}) no-repeat center;
  background-size: contain;
`

const BrandingPrimaryLogo = () => {
  const organization = react_app_organization.organization
  const primaryLogo = organization?.primary_brand_logo?.url

  return (
    organization?.can_access_cobranded_reports && primaryLogo && (
      <StyledLogo logo={primaryLogo}/>
  ))
}

export default BrandingPrimaryLogo
