import React from 'react';

const PublicationFormSubmittedEvent = ({event}) => {
    const publication = event.target;
    const keyPerson = publication.holding_key_person;

    return(<><span>{keyPerson.full_name}, a key person on {event.context.holding_name}, submitted the {publication.name} report.</span></>)
};

export default PublicationFormSubmittedEvent;
