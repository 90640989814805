import React from 'react';
import { FONT_COLOR_SECONDARY, FONT_COLOR_PRIMARY } from '../../styles/colors';
import styled from 'styled-components';

const StyledTitle = styled.div`
  font-size: 26px;
  font-weight: bold;
  line-height: 1.3;
  color: ${FONT_COLOR_PRIMARY};
  max-width: 200px;
`
const PortfolioTitle = ({ name }) => {
  return (
    <StyledTitle>
      {name}
    </StyledTitle>
  );
}

export default PortfolioTitle;
