import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import styled from 'styled-components'

import DeletePortfolioDialog from '../../../components/styleguide/DeletePortfolioDialog'
import PortfolioTitle from '../../../components/styleguide/PortfolioTitle'
import SDGAlignment from '../../../components/styleguide/SDGAlignment'
import { APP_COLOR_PRIMARY, FONT_COLOR_SECONDARY } from '../../../styles/colors'
import IconLink from '../../../yb_components/styleguide/links/IconLink'
import useCurrentTabRef from '../../../yb_hooks/target/useCurrentTabRef'

dayjs.extend(utc)

const PortfolioInfo = styled.div`
  font-size: 18px;
  color: ${FONT_COLOR_SECONDARY};
  a {
    margin-left: 5px;
    color: ${APP_COLOR_PRIMARY};
  }
`
const IconsContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
`

const PortfolioHeader = ({
  snapshot,
  portfolioId,
  impactBenchmark,
  sdgs,
  displayIcons = { options: false, sdgs: false },
}) => {
  const [showDelete, setShowDelete] = useState(false)
  const [disableExport, setDisableExport] = useState(true)
  const [currentTabRef] = useCurrentTabRef()

  useEffect(() => {
    if (impactBenchmark) {
      setDisableExport(false)
    }
  }, [impactBenchmark])

  const renderSDG = () => (
    <SDGAlignment className='snapshot-sdgs' horizontal sdgs={sdgs} />
  )

  const renderUpdateButton = () => (
    <IconLink
      onClick={() => window.location.assign(`${snapshotPath}/edit`)}
      src={'/ico-update.svg'}
    />
  )

  const renderExportButton = () => (
    <IconLink
      disable={disableExport}
      onClick={() => currentTabRef.current.exportPDFReport()}
      src={'/ico-export.svg'}
    />
  )

  const renderDeleteButton = () => (
    <IconLink onClick={() => setShowDelete(true)} src={'/ico-delete.svg'} />
  )

  const snapshotPath = `/app/portfolios/${portfolioId}/snapshots/${snapshot.id}`
  return (
    <Row style={{ marginBottom: '10px' }}>
      <Col md={5}>
        <PortfolioTitle className='snapshot-title' name={snapshot.name} />

        <PortfolioInfo>
          Benchmark:{' '}
          {impactBenchmark && (
            <a
              href={'/app/instruments/' + impactBenchmark?.id}
              target='_blank'
              rel='noreferrer'
            >
              {impactBenchmark?.benchmark_name}
            </a>
          )}
        </PortfolioInfo>

        <PortfolioInfo>
          As Of:{' '}
          <a href={`/app/portfolios/${portfolioId}/longitudinal_report`}>
            {dayjs(snapshot.as_of).utc().format('MMM D, YYYY')}
          </a>
        </PortfolioInfo>
      </Col>
      {currentTabRef?.current?.exportPDFReport && (
        <>
          <Col md={4}>{displayIcons?.sdgs && renderSDG()}</Col>
          <Col md={3}>
            <IconsContainer>
              {displayIcons?.options && [
                renderUpdateButton(),
                renderExportButton(),
                renderDeleteButton(),
              ]}
            </IconsContainer>
          </Col>
        </>
      )}

      <DeletePortfolioDialog
        displayed={showDelete}
        title={'Delete Portfolio: ' + snapshot.name}
        portfolioName={snapshot.name}
        setDisplayed={setShowDelete}
        portfolioId={snapshot.portfolio_id}
        snapshotId={snapshot.id}
        origin={'/app'}
      />
    </Row>
  )
}
export default PortfolioHeader
