import React from 'react'
import { FormControl } from 'react-bootstrap'
import styled from 'styled-components'

import { BG_COLOR_SECONDARY } from '../../../../../styles/colors'
import ColorPicker from '../../../../../yb_components/styleguide/colorpickers/ColorPicker'

const StyledInputRow = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;

  .label {
    flex: 2 1 0;
  }

  .score {
    flex: 1 1 0;
  }

  .center-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .remove-row {
    img {
      cursor: pointer;
    }
  }

  input {
    border-color: ${BG_COLOR_SECONDARY};
    border-width: 1.5px;
    border-radius: 6px;
  }
`
const LabelInputRow = ({ color, label, min, max, index, onChange }) => {
  const labelData = {
    color,
    label,
    min,
    max,
    index,
  }

  const handleLocalChange = ({ value, field, remove = false }) => {
    if (['min', 'max'].includes(field) && (value < 0 || value > 100)) {
      value = 0
    }
    const updatedData = { ...labelData, [field]: value }
    onChange({ changedLabel: updatedData, action: remove && 'delete' })
  }

  return (
    <StyledInputRow>
      <div className='color-input center-icon'>
        <ColorPicker
          color={labelData.color}
          onChange={color =>
            handleLocalChange({ value: color, field: 'color' })
          }
        />
      </div>
      <div className='label'>
        <FormControl
          type='text'
          value={labelData.label}
          onChange={e =>
            handleLocalChange({ value: e.target.value, field: 'label' })
          }
        />
      </div>
      <div className='score'>
        <FormControl
          type='number'
          value={labelData.min}
          disabled
          onChange={e =>
            handleLocalChange({ value: e.target.value, field: 'min' })
          }
        />
      </div>
      <div className='score'>
        <FormControl
          type='number'
          value={labelData.max}
          onChange={e =>
            handleLocalChange({ value: e.target.value, field: 'max' })
          }
        />
      </div>
      <div
        className='remove-row center-icon'
        onClick={() => handleLocalChange({ remove: true })}
      >
        <img src='/trash-icon-v2.svg' />
      </div>
    </StyledInputRow>
  )
}

export default LabelInputRow
