import { css } from 'styled-components'

export const baseFocusCss = css`
  &:focus {
    border-color: #002174;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(38 78 180 / 50%);
  }
`

export const baseErrorCss = css`
  color: var(--danger);
  border-color: var(--danger);
  outline: 0;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
  }
`
