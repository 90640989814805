import React from 'react';
import { abbreviateNumber } from '../../../../helpers/number';
import { SubsidyLevelColor } from '../../../../styles/colors';
import { SubsidyLevel, SUBSIDY_LEVEL_FEDERAL_LABEL, SUBSIDY_LEVEL_LOCAL_LABEL, SUBSIDY_LEVEL_STATE_LABEL } from '../Subsidies.constants';
import OverTimeLegendItem from '../../../../yb_components/styleguide/legend_items/OverTimeLegendItem';

const OverTimeLegend = ({ legendOrder, totals }) => {
  return (
    <>
      {legendOrder.map((subsidyLevel) => {
        let elem = totals.find((totalElem) => totalElem.subsidy_level === subsidyLevel);
        if (!elem) elem = { subsidy_level: subsidyLevel, amount: 0 };

        let color = '#FFF';
        let label = null;

        switch (elem.subsidy_level) {
          case SubsidyLevel.FEDERAL: {
            color = SubsidyLevelColor.FEDERAL;
            label = SUBSIDY_LEVEL_FEDERAL_LABEL;
            break;
          }

          case SubsidyLevel.STATE: {
            color = SubsidyLevelColor.STATE;
            label = SUBSIDY_LEVEL_STATE_LABEL;
            break;
          }

          case SubsidyLevel.LOCAL: {
            color = SubsidyLevelColor.LOCAL;
            label = SUBSIDY_LEVEL_LOCAL_LABEL;
            break;
          }

          default:
            return null;
        }

        const abbreviatedNumber = abbreviateNumber(elem.amount);
        return (
          <OverTimeLegendItem
            key={`${label}${elem.amount}`}
            label={label}
            bigLabel={`$ ${abbreviatedNumber || '0 M'}`}
            color={color}
          />
        );
      })}
    </>
  );
}

export default OverTimeLegend;
