import React, { useState, useEffect } from 'react';

import { style, MiddleSpan } from './styles'
import { Container } from 'react-bootstrap';
import SectionWrapper from '../../components/styleguide/SectionWrapper';
import Link from '../../components/styleguide/Link';
import SideArrow from '../../components/menu/SideArrow';
import WizardSteps from '../../components/styleguide/WizardSteps';
import { steps } from '../../constants/funds';

// MODELS
import { Fund } from './models/Fund';

// Sections
import HoldingModule from './sections/holding/HoldingSection';
import MemberModule from "./sections/member/MemberSection";
import ConfigurationModule from "./sections/configuration/ConfigurationModule";
import ReportingFrameworkModule from "./sections/reporting_framework/ReportingFrameworkSection";

const FundNew = () => {
    const [stepIndex, setStepIndex] = useState(0);
    const [fundId, setFundId] = useState("");

    useEffect(() => {
        if(!window.location.origin.includes("herokuapp") && !window.location.origin.includes("localhost")){
            window.onbeforeunload = function() {
                return "";
            }
        }

    });

    const moveToNextStep = (newFund) => {
        setStepIndex(stepIndex + 1);
    };

    const onConfigurationSubmit = (fundId) => {
        setFundId(fundId);
        setStepIndex(stepIndex + 1);
    };

    const viewFund = () => {
        window.onbeforeunload = null;
        window.location = `/app/funds/${fundId}`
    }

    return(
        <Container>
            <SectionWrapper margin="10px 0px 10px 0px">
                <Link href='/app'>Home</Link>
                <MiddleSpan> <SideArrow/> </MiddleSpan>
                <span>New Private Equity Portfolio</span>
            </SectionWrapper>
            <SectionWrapper margin="0px 0px 10px 0px">
                <WizardSteps id="funds" selected={stepIndex} steps={steps}/>
            </SectionWrapper>
            {
                stepIndex === 0 && <ConfigurationModule config={{ actionType: "create" }} callbacks={[onConfigurationSubmit]} fund={new Fund({})} />
            }
            {
                stepIndex === 1 && <ReportingFrameworkModule config={ {save: true }} callbacks={ [moveToNextStep] } fundId={ fundId } />
            }
            {
                stepIndex === 2 && <HoldingModule config={{ save:true, action: "create", add:false, pipeline: true, investmentType:"Basic" }} callback={moveToNextStep} fundId={fundId} />
            }
            {
                stepIndex === 3 && <MemberModule config={{action: "create", fundTableMargin:"5px 0px 0px 0px"}} callbacks={[viewFund]} fundId={fundId} />
            }
        </Container>
    )
};

export default FundNew;
