import React, { useState, useEffect } from 'react';
import Table from '../../../../../components/styleguide/Table';
import { Form, InputGroup, FormControl  } from 'react-bootstrap';
import SectionWrapper from '../../../../../components/styleguide/SectionWrapper';
import CardTitle from '../../../../../components/styleguide/CardTitle';
import RangeSlider from 'react-bootstrap-range-slider';

const GoalsHoldingTable = ({ goals, onUpdateGoals, dataLabel } ) => {

    const changegGoalWithInput = (event, number) => {
        const value = event.target.value.split("%")[0];
        // if(event.key === "Enter" && !isNaN(value)){
            event.target.value = value;
            onUpdateGoals(event, number)
        // }
    }

    const headers = [
        { label: "SDG", sort: false, id: "SDG" },
        { label: "WEIGHT" , sort: false, id: "weight" },
    ];

    const total = () => {
        const total = goals.reduce((acc,el) => {
            return acc + el.weight;
        }, 0)
        return `${total}%`;
    }

    const footer = [
        [
            "Total",
            total()
        ]
    ]

    const tableRows = () => {
        return goals.map((goal) => {
            return [
                <span data-cy={`${dataLabel}-number-row`}>SDG{goal.number}: {goal.description}</span>,
                <SectionWrapper data-cy={`${dataLabel}-form-wrapper`} wrap="nowrap" align="center">
                    <SectionWrapper width="90px">
                        <RangeSlider
                            data-cy={`${dataLabel}-range-slider`}
                            onAfterChange={(e) => onUpdateGoals(e, goal.number)} 
                            onMouseUp={(e) => onUpdateGoals(e, goal.number)} 
                            inputProps={{defaultValue:goal.weight}}
                            id={goal.number}
                            tooltip="off"
                        />
                    </SectionWrapper>
                    <SectionWrapper width="110px" data-cy={`${dataLabel}-percentage-wrapper`} wrap="nowrap" max margin="0px 0px 0px 5px">
                        <InputGroup>
                            <FormControl 
                                data-cy={`${dataLabel}-perecentage-view`}                          
                                id={goal.number}
                                type="text" 
                                defaultValue={`${goal.weight}`}
                                onBlur={(event) => {
                                    changegGoalWithInput(event, goal.number);
                                }}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>  
                    </SectionWrapper>
                </SectionWrapper>
              ];
        });
    };

    return(
        <>
            <CardTitle data-cy={`${dataLabel}-cardtitle`} title="SDG Revenue Alignment"/>
            <Table
                data-cy={`${dataLabel}-card-table`} 
                body={tableRows()}
                headers={headers}
                footer={footer}
            />
        </>
    )
}

export default GoalsHoldingTable