import React  from 'react';
import { NotesWrapper } from './styles';


const NotesSection = ({ note, dataLabel }) => {

    return (
        <NotesWrapper data-cy={`${dataLabel}-custom-form-display::notes-wrapper-one`} >
            <div className="note" data-cy={`${dataLabel}-custom-form-display:note-one`} >
                <p data-cy={`${dataLabel}-custom-form-display::note-body-one`} >{note && note.body}</p>
                <span data-cy={`${dataLabel}-custom-form-display::note-byline-one`}  className="byline">
                    — {note && note.author_name} ({note && note.created_at})
                </span>
            </div>
        </NotesWrapper>
    )
}

export default NotesSection;