import React from 'react'
import styled from 'styled-components'

import Link from '../../../../components/styleguide/Link'
import { abbreviateNumber } from '../../../../helpers/number'
import { FONT_COLOR_SECONDARY } from '../../../../styles/colors'
import { RESEARCH_CENTER_DATA_CHOICES } from '../../constants/enums'
import { EMPTY_CONTENT_PLACEHOLDER } from '../../constants/page'

const StyledSecondaryText = styled.div`
  color: ${FONT_COLOR_SECONDARY};
`

const summaryInstruments = instruments => {
  return instruments.map(i => [
    <Link key={i.id} href={`instruments/${i.id}`}>
      {i.name || EMPTY_CONTENT_PLACEHOLDER}
    </Link>,
    <StyledSecondaryText key={i.ticker}>
      {i.ticker || EMPTY_CONTENT_PLACEHOLDER}
    </StyledSecondaryText>,
    i.isin || EMPTY_CONTENT_PLACEHOLDER,
    abbreviateNumber(i.market_cap) || EMPTY_CONTENT_PLACEHOLDER,
    abbreviateNumber(i.revenue) || EMPTY_CONTENT_PLACEHOLDER,
    i.country_of_hq || EMPTY_CONTENT_PLACEHOLDER,
    i.economic_sector || EMPTY_CONTENT_PLACEHOLDER,
    i.industry_group || EMPTY_CONTENT_PLACEHOLDER,
  ])
}

const esgInstruments = instruments => {
  return instruments.map(i => [
    <Link key={i.id} href={`instruments/${i.id}`}>
      {i.name || EMPTY_CONTENT_PLACEHOLDER}
    </Link>,
    <StyledSecondaryText key={i.ticker}>
      {i.ticker || EMPTY_CONTENT_PLACEHOLDER}
    </StyledSecondaryText>,
    i.isin || EMPTY_CONTENT_PLACEHOLDER,
    (+i.esg_scores.e_score).toFixed(2) || EMPTY_CONTENT_PLACEHOLDER,
    (+i.esg_scores.s_score).toFixed(2) || EMPTY_CONTENT_PLACEHOLDER,
    (+i.esg_scores.g_score).toFixed(2) || EMPTY_CONTENT_PLACEHOLDER,
    (+i.esg_scores.total_score).toFixed(2) || EMPTY_CONTENT_PLACEHOLDER,
  ])
}

const sdgInstruments = instruments => {
  return instruments.map(i => [
    <Link key={i.id} href={`instruments/${i.id}`}>
      {i.name || EMPTY_CONTENT_PLACEHOLDER}
    </Link>,
    i.sdgs.sdg_1 || EMPTY_CONTENT_PLACEHOLDER,
    i.sdgs.sdg_2 || EMPTY_CONTENT_PLACEHOLDER,
    i.sdgs.sdg_3 || EMPTY_CONTENT_PLACEHOLDER,
    i.sdgs.sdg_4 || EMPTY_CONTENT_PLACEHOLDER,
    i.sdgs.sdg_5 || EMPTY_CONTENT_PLACEHOLDER,
    i.sdgs.sdg_6 || EMPTY_CONTENT_PLACEHOLDER,
    i.sdgs.sdg_7 || EMPTY_CONTENT_PLACEHOLDER,
    i.sdgs.sdg_8 || EMPTY_CONTENT_PLACEHOLDER,
    i.sdgs.sdg_9 || EMPTY_CONTENT_PLACEHOLDER,
    i.sdgs.sdg_10 || EMPTY_CONTENT_PLACEHOLDER,
    i.sdgs.sdg_11 || EMPTY_CONTENT_PLACEHOLDER,
    i.sdgs.sdg_12 || EMPTY_CONTENT_PLACEHOLDER,
    i.sdgs.sdg_13 || EMPTY_CONTENT_PLACEHOLDER,
    i.sdgs.sdg_14 || EMPTY_CONTENT_PLACEHOLDER,
    i.sdgs.sdg_15 || EMPTY_CONTENT_PLACEHOLDER,
    i.sdgs.sdg_16 || EMPTY_CONTENT_PLACEHOLDER,
  ])
}

const carbonInstruments = instruments => {
  return instruments.map(i => [
    <Link key={i.id} href={`instruments/${i.id}`}>
      {i.name || EMPTY_CONTENT_PLACEHOLDER}
    </Link>,
    <StyledSecondaryText key={i.ticker}>
      {i.ticker || EMPTY_CONTENT_PLACEHOLDER}
    </StyledSecondaryText>,
    i.isin || EMPTY_CONTENT_PLACEHOLDER,
    abbreviateNumber(i.carbon.scope_1) || EMPTY_CONTENT_PLACEHOLDER,
    abbreviateNumber(i.carbon.scope_2) || EMPTY_CONTENT_PLACEHOLDER,
    abbreviateNumber(i.carbon.scope_3) || EMPTY_CONTENT_PLACEHOLDER,
    abbreviateNumber(
      parseFloat(i.carbon.scope_1 || '') +
        parseFloat(i.carbon.scope_2 || '') +
        parseFloat(i.carbon.scope_3 || '')
    ),
  ])
}

const getPresInstruments = (dataChoice, instruments) => {
  switch (dataChoice) {
    case RESEARCH_CENTER_DATA_CHOICES.SUMMARY:
      return summaryInstruments(instruments)
    case RESEARCH_CENTER_DATA_CHOICES.ESG:
      return esgInstruments(instruments)
    case RESEARCH_CENTER_DATA_CHOICES.SDG:
      return sdgInstruments(instruments)
    case RESEARCH_CENTER_DATA_CHOICES.CARBON:
      return carbonInstruments(instruments)
    default:
      return [[]]
  }
}

export { getPresInstruments }
