import React from 'react'
import styled from 'styled-components'

import Card from '../../../components/styleguide/Card'
import { FONT_COLOR_SECONDARY } from '../../../styles/colors'

const StyledCard = styled(Card)`
  display: flex;
  align-items: center;
`

const EmptySectionContainer = styled.div`
  text-align: center;
  color: ${FONT_COLOR_SECONDARY};
  font-size: 0.875rem;
  margin: auto;
`

const EmptySectionCard = ({ ...props }) => (
  <StyledCard {...props}>
    <EmptySectionContainer>Nothing to show here yet.</EmptySectionContainer>
  </StyledCard>
)

export default EmptySectionCard
