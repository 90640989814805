import styled from 'styled-components'
import { FONT_COLOR_SECONDARY } from '../../../styles/colors';


export const KeyCircle = styled.div`
    width:${props => props.styles && props.styles.width || '10px'};
    height:${props => props.styles && props.styles.height|| '10px'};
    margin-top:${props => props.styles && props.styles.marginRight|| '2px'};
    margin-right:${props => props.styles && props.styles.marginRight|| '5px'};
    border-radius:50%;
    background-color: ${props => props.color};
    display: inline-block;
`

export const KeyMarkerWrapper = styled.div`
    display:flex;
    align-items:center;
    font-size:${props => props.styles && props.styles.fontSize || '14px'};;
    margin-right: ${props => props.styles && props.styles.marginRight || '14px'};
    margin-left: ${props => props.styles && props.styles.marginLeft || '10px'};
    margin-bottom:${props => props.styles && props.styles.marginBottom || '5px'};
    color:${FONT_COLOR_SECONDARY};
`

export const KeyMarkerWrapperSolo = styled(KeyMarkerWrapper)`
    padding: 0;
    margin: 0;
`