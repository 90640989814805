import React from 'react';
import { StyledMainTable } from '../../comparisons/Comparisons.styles';
import HeaderRow from '../../table_rows/header_row/HeaderRow';
import ScoreTableRow from '../../table_rows/score_table_row/ScoreTableRow';
import { esgScoreFullNames } from '../../../../../../../constants';
import { emptyScorePlaceholder } from '../../../constants';
import IconTableRow from '../../table_rows/icon_table_row/IconTableRow';
import SDGIcon from '../../../../../../../components/styleguide/SDGIcon';
import BadgeIcon from '../../../../../../../components/styleguide/BadgeIcon';
import { computeCarbonBudgetColor } from '../../../helpers';

const YBOverviewCard = ({ portfolios, openReplaceModal=()=>{}, isPdf=false, displayEmptyColumns=true, customHeader }) => {
  const retrieveESGScore = metricType => portfolio => {
    return portfolio?.esg_score?.[`${metricType}_score`] || emptyScorePlaceholder
  }

  const retrieveCarbonBudgetScore = portfolio => {
    let carbonRatio = ((portfolio?.carbon_budget?.total_emissions /portfolio?.carbon_budget?.total_budget - 1) * 100).toFixed(2)
    if ( isNaN(carbonRatio) ) carbonRatio = emptyScorePlaceholder

    return carbonRatio
  }

  const iconSize = 37

  const sdgExposureIcons = sentiment => portfolio => {
    const icons = portfolio?.sdgs?.[sentiment]?.map(sdg => (
      <SDGIcon key={`sdg-icon-${sdg}`} sdg={sdg} height={iconSize} width={iconSize} withTooltip />
    ))

    return icons?.length > 0 ? icons : emptyScorePlaceholder
  }

  const badgeIcons = sentiment => portfolio => {
    const icons = portfolio?.badges?.[sentiment]?.map(badge => (
      <BadgeIcon key={`badge-icon-${badge.key}`} badge={badge} size={iconSize} withTooltip />
    ))
    
    return icons?.length > 0 ? icons : emptyScorePlaceholder
  }

  return (
    <StyledMainTable look={{ sideCellsPadding: '0' }} isPdf={ isPdf }>
      {customHeader}
      {!customHeader && <HeaderRow portfolios={ portfolios } openReplaceModal={ openReplaceModal } isPdf={ isPdf } />}
      <tbody>
        {['e', 's', 'g', 'total'].map(metricType => (
          <ScoreTableRow
            key={ `overview-table-row-${metricType}` }
            scoreName={ esgScoreFullNames[metricType].toUpperCase() }
            retrieveScore={ retrieveESGScore(metricType) }
            portfolios={ portfolios }
            displayEmptyColumns={displayEmptyColumns}
          />
        ))}
        <ScoreTableRow
          computeColor={ computeCarbonBudgetColor }
          scoreName={ 'Carbon Budget' }
          scoreDescription={ '(% Over/Under Budget)' }
          retrieveScore={ retrieveCarbonBudgetScore }
          portfolios={ portfolios }
          displayEmptyColumns={displayEmptyColumns}
        />
        {['positive', 'negative'].map(sentiment => (
          <IconTableRow
            key={ `overview-table-row-${sentiment}-sdg-exposure` }
            rowLabel={ `${sentiment} SDG Exposure` }
            icons={ sdgExposureIcons(sentiment) }
            portfolios={ portfolios }
            displayEmptyColumns={displayEmptyColumns}
          />
        ))}
        {['positive', 'negative'].map(sentiment => (
          <IconTableRow
            key={ `overview-table-row-${sentiment}-badges` }
            rowLabel={ `${sentiment} Badges` }
            icons={ badgeIcons(sentiment) }
            portfolios={ portfolios }
            displayEmptyColumns={displayEmptyColumns}
          />
        ))}
      </tbody>
    </StyledMainTable>
  )
}

export default YBOverviewCard
