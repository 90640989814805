import { Bar, ResponsiveBar } from '@nivo/bar'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import {
  abbreviateNumber,
  addThousandSeparator,
} from '../../../../helpers/number'
import { FONT_COLOR_SECONDARY } from '../../../../styles/colors'

const StyledContainer = styled.div`
  width: ${props => `${props.width}px` || '900px'};
  height: ${props => `${props.height}px` || '500px'};
`

const PDF_DIMENSIONS = { height: 183, width: 328 }

const CarbonAuditChart = ({ pdf, data, colors, width = 550, height = 650 }) => {
  const parseValue = value => {
    return parseFloat(value) || 0
  }

  const maxValue = useMemo(() => {
    if (!data?.totals) return 50000
    const { emissions, budget, benchmark } = data?.totals

    return Math.max(
      parseValue(emissions?.longs),
      parseValue(budget?.longs),
      parseValue(benchmark?.longs)
    )
  }, [data])

  const minValue = useMemo(() => {
    if (!data?.totals) return -50000
    const { emissions, budget, benchmark } = data?.totals

    return Math.min(
      parseValue(emissions?.shorts),
      parseValue(budget?.shorts),
      parseValue(benchmark?.shorts)
    )
  }, [data])

  /* Render */
  if (!data) return <></>

  let barProps = {
    margin: { top: 10, right: 20, bottom: 40, left: 70 },
    width: width,
    height: height,
    maxValue: maxValue + maxValue * 0.1,
    minValue: minValue + minValue * 0.1,
    keys: data?.keys,
    data: data?.data,
    indexBy: 'target',
    enableLabel: false,
    labelTextColor: 'inherit:darker(1.4)',
    labelSkipWidth: 16,
    labelSkipHeight: 16,
    tooltipFormat: value => {
      const fValue = parseFloat(value)
      return isNaN(fValue) ? fValue : addThousandSeparator(fValue, 2)
    },
    padding: 0.4,
    colors: bar => colors?.[bar?.id],
    /* Style */
    theme: {
      axis: {
        ticks: {
          text: {
            color: FONT_COLOR_SECONDARY,
            fontSize: '1rem',
            fontWeight: 400,
            fill: FONT_COLOR_SECONDARY,
          },
        },
      },
    },
    /* Axis */
    axisLeft: {
      format: v => `${abbreviateNumber(v)}`,
      tickSize: 0,
      tickPadding: 10,
    },
    axisBottom: null,
    markers: [
      {
        axis: 'y',
        value: 0,
        lineStyle: { stroke: FONT_COLOR_SECONDARY, strokeWidth: 1 },
      },
    ],
  }
  let dimensionsProps = { width, height }

  if (pdf) {
    dimensionsProps = { ...dimensionsProps, ...PDF_DIMENSIONS }
    if (width) dimensionsProps = { ...dimensionsProps, width }
    if (height) dimensionsProps = { ...dimensionsProps, height }
    barProps = { ...barProps, ...dimensionsProps }
  }

  return (
    <>
      <StyledContainer {...dimensionsProps}>
        {pdf ? <Bar {...barProps} /> : <ResponsiveBar {...barProps} />}
      </StyledContainer>
    </>
  )
}

export default CarbonAuditChart
