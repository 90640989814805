import styled from 'styled-components'

export const StyledTable = styled.table`
  border-collapse: collapse;
  background-color: white;

  tr:nth-child(even) {
    background-color: #eeeeee;
  }
  td,
  th {
    padding: 8px 10px;
    border: 1px solid #eeeeee;
    color: #979ca6;
    font-size: 12px;
  }
  th {
    text-align: center;
  }
  td {
    text-align: right;
  }
  .metric {
    text-align: left;
  }
  .sub-risk {
    padding: 8px 10px 8px 32px;
  }
  .custom-weight-cell {
    padding: 0;
    position: relative;

    input {
      padding: 8px 20px;
      width: 100%;
      border: none;
      outline: none;
      background: transparent;
      text-align: right;
      color: #979ca6;

      &[type='number'] {
        -moz-appearance: textfield;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      &:focus {
        color: #333333;
        box-shadow: inset 0px 0px 0px 2px #acd1fb;
      }
      ::-moz-selection,
      ::selection {
        color: #333333;
        background: #acd1fb;
      }
    }

    .percent-sign {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 11px;
      z-index: 100000;
      pointer-events: none;
    }
  }
`
