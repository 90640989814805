import React, { Reducer, createContext, useReducer } from 'react'

import DisclosureFormsReducer from './reducers/DisclosureFormsReducer'
import {
  TFormAnswer,
  TFormTrigger,
} from '../../../../ui/screens/Portfolios/RegulatoryFrameworks/SFDR/components/DisclosureDetail/data'
import { DisclosureFormsActions } from './actions/DisclosureFormsActions'

export type TDisclosureFormsState = {
  triggers: TFormTrigger
  answers: TFormAnswer
  localAnswers: TFormAnswer
  isPdf: boolean
}

const initialStateLocal: TDisclosureFormsState = {
  triggers: {},
  answers: {},
  localAnswers: {},
  isPdf: false,
}

export const DisclosureFormsContext = createContext<
  [
    TDisclosureFormsState,
    React.Dispatch<{
      type: DisclosureFormsActions
      payload: any
    }>
  ]
>(initialStateLocal)

const DisclosureFormsStore = ({ initialState = null, children }) => {
  const [state, dispatch] = useReducer<
    Reducer<
      TDisclosureFormsState,
      { type: DisclosureFormsActions; payload: any }
    >
  >(DisclosureFormsReducer, initialState || initialStateLocal)

  return (
    <DisclosureFormsContext.Provider value={[state, dispatch]}>
      {children}
    </DisclosureFormsContext.Provider>
  )
}

export default DisclosureFormsStore
