export default function useFeedbackMessage(
    ...feedback: YB.TFeedbackModel[]
): string {
    for (let i = 0; i < feedback.length; i = i + 1) {
        const [feedbackMessage] = feedback[i]

        if (feedbackMessage) return feedbackMessage
    }

    return ''
}
