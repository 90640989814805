import React from 'react'

export default function withInputValidations<T, M>(
    InputComponent: React.FC<T>,
    validations: YB.TInputValidation<M>[],
    overrideProps?: Partial<T>
) {
    return function WithInputValidations(props: T) {
        const ammendedProps = Object.assign({}, props, overrideProps)

        return <InputComponent {...ammendedProps} validations={validations} />
    }
}
