import React, { FC, useMemo } from 'react'
import classNames from 'classnames'

import './Button.module.scss'

export enum EButtonVariants {
  text = 'text',
  primary = 'primary',
  secondary = 'secondary',
  link = 'link',
}

const ButtonVariants = {
  text: '',
  primary: 'custom-btn-primary',
  secondary: 'custom-btn-secondary',
  link: 'custom-btn-link',
} as const

export interface IProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  testId?: string
  disabled?: boolean
  variant: keyof typeof ButtonVariants
  type?: 'button' | 'submit' | 'reset'
}

export const Button: FC<IProps> = ({
  testId,
  children,
  className,
  disabled,
  variant: variantId,
  type = 'button',
  ...htmlButtonProps
}) => {
  const variant = useMemo(() => ButtonVariants[variantId], [variantId])

  return (
    <button
      type={type}
      disabled={disabled}
      {...htmlButtonProps}
      data-cy={testId}
      className={classNames('custom-btn', variant, className)}
    >
      {children}
    </button>
  )
}
