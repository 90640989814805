import React from 'react'

import usePlaceholderBadge from '../../components/styleguide/badges/usePlaceholderBadge'

const BadgesPDF = ({ badges, title, holdingsTotal }) => {
  return (
    <div className='card' style={{ width: '100%' }}>
      <div className='card-box'>
        <h3>{title}</h3>
        <div className={'badges-list-container'}>
          {badges?.map((badge, index) => (
            <BadgeComponent
              key={`badges-${index}`}
              badge={badge}
              holdingsTotal={holdingsTotal}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const BadgeComponent = ({ badge, holdingsTotal = null }) => {
  const { path } = usePlaceholderBadge(badge)

  const formattedBadges = badge['holdings']
    ?.filter(badge => badge?.indexOf('(holding of ') < 0)
    .slice(0, 15)
    .join(', ')

  return (
    <div className='badge-container'>
      <img
        className='badge-image'
        style={{ width: 80 }}
        onClick={() =>
          window.location.assign('/app/badges?badge=' + badge['key'])
        }
        src={path}
      />
      <div className='badge-info'>
        <b>{badge['name']}</b>
        <br></br>
        <div>{badge['description']}</div>
        <br></br>

        {holdingsTotal != null && holdingsTotal > 0 && (
          <>
            {badge['holding_count'] > 0 && (
              <div style={{ display: 'inline-flex' }}>
                <div className='holdings-counter'>
                  {badge['holding_count']}/{holdingsTotal} Holdings
                </div>
              </div>
            )}

            <div
              className='holdings-list'
              style={{ marginTop: badge['holding_count'] > 0 ? 10 : 0 }}
            >
              {formattedBadges && (
                <>
                  <strong>First 15 Holdings:</strong>
                  <p>{formattedBadges}</p>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
export default BadgesPDF
