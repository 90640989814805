import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import IconLink from '../../../../../../yb_components/styleguide/links/IconLink'
import {
  BORDER_COLOR_TERTIARY,
  FONT_COLOR_TERTIARY,
} from '../../../../../../styles/colors'
import { ListPagination } from './ListPagination'

const StyledDiv = styled.div`
  margin: 0 0 10px 0;
  padding: 2px 10px;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: ${BORDER_COLOR_TERTIARY};
  color: ${FONT_COLOR_TERTIARY};

  .no-margin {
    margin-right: 0;
  }
  h4 {
    margin: 0 0.75rem 0 0;
    display: inline-block;
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 700;
    color: ${FONT_COLOR_TERTIARY};
    opacity: ${props => (props.isLoading ? 0 : 1)};
  }
  .entity-details {
    font-size: 14px;
    line-height: 1.2;
    font-style: italic;
    color: ${FONT_COLOR_TERTIARY};
    opacity: ${props => (props.isLoading ? 0 : 1)};
  }
  .actions {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-self: flex-end;
    opacity: ${props => (props.isLoading ? 0 : 1)};
  }
`

export const TcfdHoldingDetailListHeader = ({
  entityCountry,
  entityName,
  entitySector,
  entityTrbcActivity,
  isLoading,
  links,
  onExportPDF,
  onExportCSV,
}) => {
  return (
    <StyledDiv isLoading={isLoading}>
      <div style={{ flexGrow: 1 }}>
        <h4>{entityName}</h4>
        <span className='entity-details'>
          ({entityCountry && <span>Country: {entityCountry}; </span>}
          {entitySector && <span>Sector: {entitySector}; </span>}
          {entityTrbcActivity && <span>Activity: {entityTrbcActivity}</span>})
        </span>
      </div>

      <div
        className='actions'
        style={{
          display: 'flex',
          gap: '10px',
          justifySelf: 'flex-end',
          alignItems: 'center',
        }}
      >
        <ListPagination links={links} />
        <IconLink
          className="no-margin"
          disable={isLoading}
          onClick={onExportPDF}
          src={'/ico-export.svg'}
        />
        <IconLink
          disable={isLoading}
          onClick={onExportCSV}
          src={'/ico-csv.svg'}
        />
      </div>
    </StyledDiv>
  )
}

TcfdHoldingDetailListHeader.propTypes = {
  entityCountry: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
  entitySector: PropTypes.string.isRequired,
  entityTrbcActivity: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  links: PropTypes.object.isRequired,
  onExportPDF: PropTypes.func,
}
