import React, { useEffect, useState } from 'react'

// Components
import CardTitle from '../../components/styleguide/CardTitle'
import SectionWrapper from '../../components/styleguide/SectionWrapper'
import TextSection from '../../components/styleguide/TextSection'
import {
  currentInvestmentsToggles,
  defaultStartFundShow,
  fundPipelineButtons,
  fundShowTabNames,
} from '../../constants/funds'
// Repository
import { getFund } from '../../repositories/funds_repository'
import DetailsBox from './components/DetailsBox'
import FundHeader from './components/FundHeader'
import PEShowModuleWrapper from './components/PEShowModuleWrapper'
import SqrButtonCase from './components/SqrButtonCase'
import TabHeader from './components/TabHeader'
import ToggleLinks from './components/ToggleLinks'
// Models
import { Fund } from './models/Fund'
import FundActivitySection from './sections/activity/FundActivitySection'
// Modules
import HoldingSection from './sections/holding/HoldingSection'
import ManageFundSection from './sections/manage/ManageFundSection'
import FundShowReportingFrameworkTab from './sections/reporting_framework/FundShowReportingFrameworkTab'

const FundShow = ({ id }) => {
  const initialFund = new Fund({})
  const [fund, setFund] = useState(initialFund)
  const [currTab, setCurrTab] = useState(defaultStartFundShow.currTab)
  const [activeToggle, setActiveToggle] = useState(
    defaultStartFundShow.activeToggle
  )
  const [activeReportingToggle] = useState(
    defaultStartFundShow.activeReportingToggle
  )
  const [activePipelineButton, setactivePipelineButton] = useState(
    defaultStartFundShow.pipelinebutton
  )

  useEffect(() => {
    getFund({ id }).then(response => {
      const nextFund = new Fund(response.data)
      setFund(nextFund)
    })
  }, [])

  const changeTab = tab => {
    setCurrTab(tab)
  }

  const currModule = () => {
    switch (currTab) {
      case 'Reporting':
        return (
          <FundShowReportingFrameworkTab
            dataLabel='fund-show'
            fund={fund}
            config={{ framework: activeReportingToggle }}
          />
        )
      case 'Current Investments':
        return (
          <HoldingSection
            config={{
              save: false,
              add: false,
              investmentType: activeToggle,
              canUpdate: fund.current_app_user_can_update,
            }}
            margin='0px 0px 15px 0px'
            fundId={fund.id}
            fundName={fund.name}
          />
        )
      case 'Pipeline':
        return (
          <HoldingSection
            config={{
              save: false,
              add: true,
              pipeline: true,
              canUpdate: fund.current_app_user_can_update,
              filter: activePipelineButton,
              investmentType: 'Basic',
            }}
            margin='0px 0px 15px 0px'
            fundId={fund.id}
            fundName={fund.name}
          />
        )
      case 'Team Activity':
        return <FundActivitySection fund={fund} />
      case 'Manage':
        return <ManageFundSection fund={fund} />
    }
  }

  const changeHoldingsInvestements = toggle => {
    setActiveToggle(toggle)
  }

  const changeHoldingsPipeline = button => {
    setactivePipelineButton(button)
  }

  const currModuleTopTab = () => {
    switch (currTab) {
      case 'Current Investments':
        return (
          <ToggleLinks
            toggles={currentInvestmentsToggles}
            onChange={changeHoldingsInvestements}
            dataLabel='current-investments-toggle'
            activeTab={activeToggle}
          />
        )
      case 'Pipeline':
        return (
          <SqrButtonCase
            buttons={fundPipelineButtons}
            onChange={changeHoldingsPipeline}
            dataLabel='pipeline-buttons'
            activeButton={activePipelineButton}
          />
        )
      case 'Team Activity':
        return <div></div>
      case 'Manage':
        return <div></div>
    }
  }

  const tabName = tab => {
    switch (tab) {
      case 'Manage':
        return 'Fund Management'
      // case "Reporting":
      //     return reportingFrameworkFundShowHeaders[activeReportingToggle]
      default:
        return tab
    }
  }
  return (
    <div className='page__container' data-cy='fund-show-container'>
      <FundHeader
        data-cy='fund-show-fund-header'
        fund={fund}
        onExport={() => {
          console.log('TO DO')
        }}
      />
      <SectionWrapper
        data-cy='fund-show-section-wrapper:description-section'
        margin='10px 0px 32px 0px'
        justify='space-between'
      >
        <TextSection
          width='48%'
          data-cy='fund-show-section-wrapper:description-section::text-description'
        >
          {fund.configuration.description}
        </TextSection>
        <DetailsBox
          dataLabel='fund-show-section-wrapper:description-section::fund-details'
          look={{ tableWidth: '50%' }}
          details={fund.createDetailsHashMap()}
        />
      </SectionWrapper>
      <TabHeader
        dataLabel='fund-show:tab-header'
        tabs={fundShowTabNames}
        onChange={changeTab}
        activeTab={currTab}
      />
      <PEShowModuleWrapper data-cy='fund-pe-show-tab-module'>
        <SectionWrapper
          margin='10px 0px 15px 0px'
          justify='space-between'
          align='center'
          data-cy='fund-show-section-wrapper::header-module'
        >
          <CardTitle
            data-cy='fund-show-tab-header-card-title'
            title={tabName(currTab)}
          ></CardTitle>
          {currModuleTopTab()}
        </SectionWrapper>
        {currModule()}
      </PEShowModuleWrapper>
    </div>
  )
}

export default FundShow
