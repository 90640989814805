import React from 'react'

const Disclosures = () => {
  return (
    <section className='disclosures'>
      <h2>Explanation and Disclaimers</h2>
      <p>
        <strong>Unmodeled Holdings:</strong>
      </p>
      <p>
        Either an unrecognized identifier or not within our ratings universe.
        These will still be shown in the Top10 Holdings section and the
        portfolio ESG, carbon and other scores are adjusted so that the total
        weighting sums to 100%.
      </p>
      <p>
        <strong>SDG Exposure & Exposures:</strong>
      </p>
      <p>
        To calculate alignment to the U.N. Sustainable Development Goals (SDGs)
        we first map company metrics to each of the 16 SDGs. We rank companies
        on these metrics (e.g. 70th percentile on CO2) relative to their ESG
        Data industry group, averaging across metrics for that SDG. If a company
        is in the top/bottom quartile across the metrics it would receive a
        positive/negative alignment to that SDG. Portfolio level alignment
        represents top/bottom 3 SDGs as weighted sums of holding score x weight.
        SDG Exposures shows companies that are effectively betting against the
        issues represented, converting a rank of 0 to -100%, 50 to 0 and 100 to
        +100%. At the portfolio level, anything more than +25% or less than -25%
        is likely significant with broad benchmarks often registering minimal
        exposure.
      </p>
      <p>
        <strong>ESG Scores & Metrics:</strong>
      </p>
      <p>
        ESG scores represent YvesBlue’s (YB) view on the sustainability of
        companies and portfolios, based on a set of primary quantitative
        indicators (e.g. CO2 Scope3 at 15% weight, Women Managers at 5%, etc.).
        The system ranks companies against those in their ESG Data industry
        group classification (e.g. ‘Oil & Gas’) within the ESG reporting
        universe of ~8,000 companies based on data from ESG Data and others.
        Where company values are not available, we use proprietary methodologies
        to model the values and rank. The ESG score weights at 45%/30%/25% for
        E/S/G pillars respectively. Portfolio scores are the weighted sums of
        company rankings x holding weights. Ratings may shift some over time as
        companies report/delist and YB methodologies are updated.
      </p>
      <p>
        <strong>Carbon Burden & Budget:</strong>
      </p>
      <p>
        The ‘Burden’ parses how much the portfolio ‘owns’ based on the size of
        the positions and the company emissions it invests in, useful for
        offsetting impact. The ‘Budget’ instead focuses more on how emissions
        are divided across sectors and how this compares to levels that will
        limit the world to 2 degrees Celsius of global warming. YB calculates
        this through a proprietary methodology and emissions targets calculated
        by the global scientific body UN IPCC. The Budget represents the current
        allowable amount, shrinking 9-10% every year until 2030 to represent
        cuts necessary to avoid furthering the climate crisis. Different sector
        overruns may yield different conclusions, like if Energy is actually
        under its budget but Automobiles & Auto Parts is way above that may
        indicate holdings have done an adequate job of sourcing renewables but
        has not focused on other high emitting areas like transportation.
      </p>
      <p>
        <strong>Company Emissions & Scope: </strong>
      </p>
      <p>
        Company emissions (air pollution with the potential to contribute to
        global heating) refer to tonnes of CO2-equivalent emissions unless
        otherwise indicated (tCO2e). These are divided into 3 ‘scopes’, Scope1:
        emissions from facilities or operations under direct control by the
        company (onsite), Scope2: indirect emissions from generation of energy
        the company uses, Scope3: remaining emissions attributable to sources
        not directly owned or controlled by the company, including employee
        travel, waste generation and other upstream and downstream emissions not
        included in Scopes 1 & 2.{' '}
      </p>
      <p>
        <strong>Impact Assessment:</strong>
      </p>
      <p>
        Impact statistics are aggregated from portfolio holding impacts relative
        to industry group median and meant to give context to metrics presented
        elsewhere. For ‘Trees’ we utilize U.S. EPA conversion factors to
        calculate how much CO2 a tree absorbs over its life. ‘Waste’ references
        direct waste produced by companies. ‘Gender Gap’ is calculated off of
        current disparities in management, with (generally) 1:1 ideal, below
        1.5:1 decent and 2:1 bad.
      </p>
      <strong>RepRisk</strong>:
      <p>
        RepRisk Founded in 1998 and headquartered in Switzerland, RepRisk is a
        pioneer in ESG data science that leverages the combination of AI and
        machine learning with human intelligence to systematically analyze
        public information and identify material ESG risks. RepRisk’s flagship
        product, the RepRisk ESG Risk Platform, is the world’s largest and most
        comprehensive due diligence database on ESG and business conduct risks,
        with expertise in 20 languages and coverage of 135,000+ public and
        private companies and 30,000+ infrastructure projects. For more than a
        decade, the world’s leading financial institutions and corporations have
        trusted RepRisk for due diligence and risk management across their
        operations, business relationships, and investments. To learn more,
        please visit www.reprisk.com.
        <br />
      </p>
      <p>
        <strong>Disclaimer:</strong>
      </p>
      <p>
        Underlying ESG data is sourced from our ESG Data and others, including
        independent and non-profit data providers.
      </p>
      <p>
        <strong>Disclaimer:</strong>
      </p>
      <p>
        The information in this report is for general information purposes only.
        It is not intended as personal financial or investment advice and should
        not be construed or relied on as such. No information contained within
        should be construed or relied upon as providing recommendations in
        relation to any financial product or investment strategy.
      </p>
      <p>Source: ESG Data augmented by additional sources.</p>
    </section>
  )
}

export default Disclosures
