import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { StyledNotesList } from './NotesList.styles'

dayjs().format()

export const NotesList = ({ notes }) => {
  const [notesData, setNotes] = useState([])
  useEffect(() => {
    setNotes(notesData)
  }, [notes])

  return (
    <StyledNotesList className='notes-list-container'>
      <h5 className='notes-heading'>Notes</h5>
      <ul className='notes-list'>
        {notes != null && notes.length ? (
          notes.map((el, i) => (
            <li key={i}>
              <div className='user-section'>
                <div className='media'>
                  {el.user_avatar_url != null ? (
                    <img
                      src={el.user_avatar_url}
                      alt='User avatar'
                      className='user-avatar'
                      loading='lazy'
                      width='25'
                      height='25'
                    />
                  ) : (
                    <div className='user-avatar-placeholder'>
                      <img
                        src='/icons/ico_user_alt.svg'
                        loading='lazy'
                        width='9'
                        height='9'
                      />
                    </div>
                  )}
                  <div className='media-body'>
                    <h6>{el.username}</h6>
                    <span className='date'>
                      {dayjs(el.created_at).format('MMMM D, YYYY h:mma')}
                    </span>
                  </div>
                </div>
              </div>
              <div className='note-content'>
                <p>{el.note}</p>
              </div>
            </li>
          ))
        ) : (
          <div className='empty-alert'>
            <h5>There are no notes</h5>
          </div>
        )}
      </ul>
    </StyledNotesList>
  )
}

NotesList.propTypes = { notes: PropTypes.array.isRequired }
