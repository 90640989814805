import React  from 'react';
import styled from 'styled-components';
import SectionWrapper from '../styleguide/SectionWrapper'

const NoteCard = styled(SectionWrapper)`
    border-radius: 10px;
    padding:15px;
    background: #F6F6F9;
    max-width: ${props => props.maxWidth};
`

export default NoteCard;
