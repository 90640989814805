import { hasOwnProp } from '@utils/object'

import { IField, IFollowUpField, IFormField } from '../../../../data'

class ArticleFormQuestionRowController {
    getFieldMetaType = (
        ambiguousField: IField | IFormField | IFollowUpField
    ) => {
        if (hasOwnProp('trigger', ambiguousField)) {
            return 'followUpField'
        } else if (hasOwnProp('followUpFields', ambiguousField)) {
            return 'formField'
        } else {
            return 'field'
        }
    }
}

export default new ArticleFormQuestionRowController()
