import React, { useEffect, useState } from 'react'
import { FormControl } from 'react-bootstrap'

import SwitchButton from '../../../../../../../../components/styleguide/SwitchButton'
import {
  PAI_COMPLETION_OPTIONS,
  // PAI_ENTITIES_TYPES_OPTIONS,
} from '../../filters/PaiFilters.constants'
import { StyledFiltersContainer } from '../../filters/PaiFilters.styles'

export const PaiEntitiesFilters = ({ sectors = [], onChange, impactYears }) => {
  // const [entityType, setEntityType] = useState('all')
  const [completionRate, setCompletionRate] = useState('all')
  const [sector, setSector] = useState('all')
  const [entityName, setEntityName] = useState('')
  const [search, setSearch] = useState('')
  const [searchTimer, setSearchTimer] = useState(null)

  useEffect(() => {
    clearTimeout(searchTimer)

    setSearchTimer(
      setTimeout(() => {
        setSearch(entityName)
      }, 1000)
    )
  }, [entityName])

  useEffect(() => {
    onChange({
      entityName: search,
      completionRate,
      // entityType,
      trbcIndustryGroupId: sector,
    })
  }, [search, completionRate, sector])

  return (
    <StyledFiltersContainer>
      <div style={{ width: '300px' }}>
        <label htmlFor='entities_search'>Entity Name</label>
        <FormControl
          as='input'
          size='sm'
          value={entityName}
          name='entities_search'
          id='entities_search'
          placeholder='Search Entities'
          type='search'
          onChange={event => setEntityName(event.target.value)}
        />
      </div>
      {/* <div>
        <label>Entity Type</label>
        <SwitchButton
          onClick={setEntityType}
          active={entityType}
          options={PAI_ENTITIES_TYPES_OPTIONS}
        />
      </div> */}
      <div>
        <label>{impactYears.current} Completion Amount</label>
        <SwitchButton
          onClick={setCompletionRate}
          active={completionRate}
          options={PAI_COMPLETION_OPTIONS}
        />
      </div>
      <div style={{ width: '300px' }}>
        <label htmlFor='sectors_select'>Sectors</label>
        <FormControl
          as='select'
          size='sm'
          value={sector}
          name='sectors_select'
          id='sectors_select'
          onChange={event => setSector(event.target.value)}
        >
          <option value='all'>All</option>
          {sectors.map(sector => (
            <option key={sector.id} value={sector.id}>
              {sector.name}
            </option>
          ))}
        </FormControl>
      </div>
    </StyledFiltersContainer>
  )
}
