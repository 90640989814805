import axios from 'axios'
import qs from 'qs'

export const getManagerDBFund = id => {
  return axios.get(`/app/api/managers/funds/${id}`)
}

export const getManagerDBFunds = (jsonQueryParams = {}) => {
  return axios.get(`/app/api/managers/funds?${qs.stringify(jsonQueryParams)}`)
}

export const deleteFundFile = (fundId, fileId) => {
  return axios.delete(
    `/admin/api/managers/funds/${fundId}/delete_file?file_id=${fileId}`
  )
}

export const getLatestResearch = (page = 1) => {
  return axios.get(`/app/api/managers/funds/latest_research?page=${page}`)
}

export const getInTheMix = (page = 1) => {
  return axios.get(`/app/api/managers/funds/in_the_mix?page=${page}`)
}

export const getFunnelStates = () => {
  return axios.get('/app/api/managers/funds/funnel_states')
}

export const searchFunds = params => {
  return axios.post(`/app/api/managers/funds/search`, {
    ...params,
    asset_class_id: params.assetClassId,
    primary_impact_theme_id: params.primaryThemeId,
    secondary_impact_theme_id: params.secondaryThemeId,
    query: params.query,
  })
}

export const getAssetClasses = () => {
  return axios.get('/app/api/managers/funds/asset_classes')
}

export const getImpactThemes = () => {
  return axios.get('/app/api/managers/funds/impact_themes')
}
