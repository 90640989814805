import React, { useState } from 'react';
import TextSection from "../../../styleguide/TextSection";
import SectionWrapper from "../../../styleguide/SectionWrapper";
import Button from "../../../styleguide/Button";
import CustomReportFormSubmissionTableRow from "./CustomReportFormSubmissionTableRow";
import DatePicker from "../../../styleguide/DatePicker"
import moment from 'moment';

const CustomReportFormSubmissionTable = ({form, dataLabel, onSubmit, onCancel}) => {

    const dateString = moment.utc().local().format();

    const [asOf, setAsOf] = useState(dateString);
    const [totalScore, setTotalScore] = useState(0);

    const defaultScores = form.items.map
    ((item) => {
        return {key: item.key, value: 0, note:""};
    });

    const [scores, setScores]= useState(defaultScores);
    const showTotalScore = form.key === "ifc_principle";
    const formItems = form.items;

    const getTotalScore = (newStates) => {
        let totalScore = 0;

        for (let i = 0; i < newStates.length; i++) {
            totalScore += newStates[i].value;
        }

        return totalScore;
    }

    const submitReport = () => {
        const submission = {
            id: form.id,
            as_of: asOf,
            item_values: scores
        };

        onSubmit(submission);
    }

    const updateResponse = (answer) => {
        const newScores = scores.slice();
        const newResponse = newScores.find((r) => r.key === answer.key);
        newResponse.value = answer.value;
        setScores(newScores);

        if (showTotalScore) {
            const newTotalScore = getTotalScore(newScores);
            setTotalScore(newTotalScore);
        }
    }

    const updateNote = (answer) => {
        const newResponse = scores.find((r) => r.key === answer.key);
        newResponse.note = answer.value;
    }

    const onAsOfChange = (value, id) => {
        const date = moment.utc(value).local();
        const now = moment();
        date.set({
            hour:   now.get('hour'),
            minute: now.get('minute'),
            second: now.get('second')
        });
        setAsOf(date);
    }

    return(
        <>
            {
                <>
                    <SectionWrapper>
                        <SectionWrapper flex="auto" margin="20px 0px 20px 20px" justify="space-between" data-cy={`${dataLabel}-main-custom-report-form-submission-table-wrapper`}>
                            <TextSection width="33%" data-cy={`${dataLabel}-custom-report-form-submission-table-question-wrapper`}>
                                <h5>Question</h5>
                            </TextSection>
                            <TextSection width="33%" align="center" data-cy={`${dataLabel}-custom-report-form-submission-table-score-label`}>
                                <h5>Score</h5>
                            </TextSection>
                            <SectionWrapper width="33%">
                                <TextSection wrap="normal" width="50%" data-cy={`${dataLabel}-custom-report-form-submission-table-note-label`}>
                                    <h5>Score <br/> Explanation:</h5>
                                </TextSection>
                                <TextSection width="50%" data-cy={`${dataLabel}-custom-report-form-submission-table-note-label`}>
                                    <h5>Notes</h5>
                                </TextSection>
                            </SectionWrapper>
                        </SectionWrapper>

                        {
                            formItems.map((item, i) => {
                                return <CustomReportFormSubmissionTableRow
                                    item={item}
                                    showTopPanel={i === 0}
                                    onUpdateNote={updateNote}
                                    onUpdateResponse={updateResponse}
                                    key={`${dataLabel}-custom-report-form-submission-table-row-${i}`}
                                    dataLabel={`${dataLabel}-custom-report-form-submission-table-row-${i}`}
                                />
                            })
                        }

                    </SectionWrapper>

                    <SectionWrapper flex="auto" align="center" justify="space-between" data-cy={`${dataLabel}-submit-wrapper`}>
                        <SectionWrapper>
                            <DatePicker
                                label="Created Date *"
                                width="100%"
                                id="created_date"
                                date={asOf}
                                onChange={(value, id) => onAsOfChange(value, id)}
                            />
                        </SectionWrapper>

                        { showTotalScore && <>Total Score: {totalScore}</>  }
                        <SectionWrapper justify="flex-end">
                            {
                                onCancel && <div onClick={onCancel}
                                                 style={{color: "blue", "text-decoration": "underline", cursor: "pointer", alignSelf:"center"}}
                                                 data-cy={`${dataLabel}-cancel-button`}>
                                                    Cancel
                                            </div>

                            }
                            <Button width="auto" margin="0px 0px 0px 10px" onClick={submitReport} label="Submit Report" data-cy={`${dataLabel}-submit-button`}/>
                        </SectionWrapper>
                    </SectionWrapper>
                </>
            }
        </>
    )
};
export default CustomReportFormSubmissionTable;
