export const BREADCRUMB_LINKS = [
  {
    url: '/app',
    text: 'Home',
    active: false,
  },
  {
    text: 'Research Center',
    active: true,
  },
]

export const EMPTY_CONTENT_PLACEHOLDER = '—'
