import React from 'react';

const UserLoginEvent = ( { event} ) => {
  return (
    <>
      <span className="event-user-name">
        {event.context.full_name}
      </span> logged in.
      <span className="event-date">{event.formattedTimestamp}</span>
    </>
  );
};

export default UserLoginEvent;
