import React from 'react';
import styled from 'styled-components';
import Heading1 from "../../../components/styleguide/Heading1";
import TextSection from '../../../components/styleguide/TextSection';
import { FONT_COLOR_PRIMARY, FONT_COLOR_SECONDARY } from '../../../styles/colors';

const Container = styled.div`
  max-width: 1100px;
  margin-bottom: ${props => props.marginBottom || '0px'};
`;

const PageTextHeader = ({ title, description, marginBottom, 'data-cy': dataCy }) => {
  return (
    <Container marginBottom={marginBottom} data-cy={dataCy}>
      <Heading1 text={title} color={FONT_COLOR_PRIMARY} />
      <TextSection fontSize={'16px'} color={FONT_COLOR_SECONDARY} width={'70%'}>{description}</TextSection>
    </Container>
  );
}

export default PageTextHeader;
