import propTypes from 'prop-types'
import React, { memo, useMemo, useState } from 'react'
import ReactGA from 'react-ga'
import Select from 'react-select-3'

import CardTitle from '../../../components/styleguide/CardTitle'
import ExportButton from '../../../components/styleguide/ExportButton'
import Link from '../../../components/styleguide/Link'
import SwitchButton from '../../../components/styleguide/SwitchButton'
import TextView from '../../../components/styleguide/TextView'
import { timestamp } from '../../../helpers/shared'
import withErrorBoundary from '../../../hocs/withErrorBoundary'
import { downloadTemplate } from '../../../pdf'
import { PDF } from '../../../pdf/pdf'
import ReportPage from '../../../pdf/yb_layout/report_page/ReportPage'
import { STACKED_CHART_SET_TABLEAU } from '../../../styles/colors'
import { LinkIcon } from '../../styleguide/links/Link.styles'
import SpinnerContainer from '../../styleguide/spinners/SpinnerContainer'
import {
  BUDGET_SWITCH_OPTIONS,
  CALCULATION_METHOD_SWITCH_OPTIONS,
  CarbonAuditTabs,
  DEGREE_SWITCH_OPTIONS,
  DESCRIPTION,
  DISCLAIMER,
  SWITCH_OPTIONS,
} from './CarbonAuditCard.constants'
import {
  CustomSwitchButton,
  StyledCard,
  StyledCheckBox,
  StyledExplainerLink,
} from './CarbonAuditCard.styles'
import CarbonAuditTable from './components/carbon_audit_table/CarbonAuditTable'
import CarbonAuditChart from './components/CarbonAuditChart'
import CarbonAuditLegend from './components/CarbonAuditLegend'
import useCachedColors from './hooks/useCachedColors'
import { useCarbonAuditTable } from './hooks/useCarbonAuditTable'
import usePaginationState from './hooks/usePaginationState'

const DEFAULT_SORT = { sort_field: 'name', sort_dir: 'asc' }

const CarbonAuditCard = ({
  /* Data */
  data,
  performers,
  sectorOptions,
  target,
  benchmark,

  pdf,
  loading,
  display,

  onChange,
  onDownloadCSV,
}) => {
  /* State */
  const [selectedCalculatedMethod, setSelectedCalculatedMethod] =
    useState('enterprise_value')
  const [selectedDegree, setSelectedDegree] = useState(
    DEGREE_SWITCH_OPTIONS[0].id
  )
  const [selectedChart, setSelectedChart] = useState(
    data?.groupBy || CarbonAuditTabs.SECTOR
  )

  const [selectedBudget, setSelectedBudget] = useState('all')
  const [displayBenchmark, setDisplayBenchmark] = useState(true)
  const [includeShort, setIncludeShort] = useState(true)
  const [sector, setSector] = useState([])
  const [loadingPDF, setLoadingPDF] = useState(false)

  // Sort
  const sortState = useState({ field: 'name', dir: 'asc' })
  const [, setSort] = sortState

  // Pagination
  const paginationState = usePaginationState({
    totalPages: data?.table?.total_pages,
    onChange,
  })

  const chartData = data?.chartFormat
  const dataLength = useMemo(
    () =>
      Math.max(
        chartData?.totals?.emissions?.totalCount,
        chartData?.totals?.budget?.totalCount,
        chartData?.totals?.benchmark?.totalCount
      ),
    [chartData]
  )

  const { colors: cachedColors } = useCachedColors({
    id: `carbon_audit_colors_${data?.groupBy}`,
    colorPalette: STACKED_CHART_SET_TABLEAU,
    length: dataLength,
    keys: chartData?.keys,
    cacheData: data?.hasAllData,
    loading,
  })

  const { tableHeaders, tableBody, tableFooter } = useCarbonAuditTable({
    selectedChart,
    performers,
    colors: cachedColors,
    data,
    targetName: display,
    includeBenchmark: displayBenchmark,
  })

  /* Events */
  const onSetDefaultPageSort = () => {
    const [, , setPagination] = paginationState
    setPagination(prev => ({ ...prev, page: 1 }))
    setSort(DEFAULT_SORT)
  }

  const handleSelectChange = value => {
    onSetDefaultPageSort()
    setSector(value)
    const ids = value?.map(v => v.id)

    onChange({
      sectors: ids ? [...ids] : null,
      page: 1,
      ...DEFAULT_SORT,
    })
  }

  const onDownloadPDF = ({ reportVersion }) => {
    ReactGA.event({
      category: 'Carbon Audit',
      action: 'PDF Download',
    })
    setLoadingPDF(true)
    downloadTemplate(
      <ReportPage
        title={'Carbon Audit'}
        targetName={target?.name}
        benchmarkName={benchmark?.name}
      >
        <PDF.CarbonAudit
          report
          targetName={display}
          selectedChart={selectedChart}
          colors={cachedColors}
          chartData={chartData}
          tableData={{
            headers: tableHeaders,
            body: tableBody,
            footer: tableFooter,
          }}
          switchSelections={{
            chart: data?.groupBy,
            degree: data?.degree,
            budget: data?.budget,
            calculationMethod: data?.calculationMethod,
          }}
        />
      </ReportPage>,
      reportVersion,
      `yb-${target?.name?.split(' ')?.join('-')}-CarbonAudit-${timestamp}`,
      target?.id,
      'Report',
      target?.name,
      () => {
        setLoadingPDF(false)
      }
    )
  }

  /* Render */
  return (
    <>
      {pdf && <div style={{ pageBreakBefore: 'always' }}></div>}
      <StyledCard padding={20} pdf={pdf}>
        <div className='carbon-budget-card-header'>
          <CardTitle className='title' title='CARBON AUDIT' />
          {pdf ? null : (
            <div className='carbon-budget-card-sub-right-header'>
              <div className='float-right'>
                <StyledExplainerLink
                  href='http://help.yves.blue/en/articles/4739688'
                  target='_blank'
                >
                  <LinkIcon className='far fa-question-circle' />
                </StyledExplainerLink>
              </div>
            </div>
          )}
        </div>

        <TextView
          size={pdf ? 10 : 15.5}
          className='carbon-budget-description'
          secondary
          text={DESCRIPTION}
        />
        <div className='carbon-budget-filter-card'>
          <div>
            <SwitchButton
              loading={loading}
              onClick={id => {
                setSelectedChart(id)
                onSetDefaultPageSort()
                onChange({ group_by: id, page: 1, ...DEFAULT_SORT })
              }}
              active={selectedChart}
              options={SWITCH_OPTIONS}
            />

            <CustomSwitchButton
              loading={loading}
              onClick={id => {
                setSelectedDegree(id)
                onSetDefaultPageSort()
                onChange({ degree: id, page: 1, ...DEFAULT_SORT })
              }}
              active={selectedDegree}
              options={DEGREE_SWITCH_OPTIONS}
            />

            <CustomSwitchButton
              loading={loading}
              onClick={id => {
                setSelectedBudget(id)
                onSetDefaultPageSort()
                onChange({ budget: id, page: 1, ...DEFAULT_SORT })
              }}
              active={selectedBudget}
              options={BUDGET_SWITCH_OPTIONS}
            />

            <CustomSwitchButton
              loading={loading}
              onClick={id => {
                setSelectedCalculatedMethod(id)
                onSetDefaultPageSort()
                onChange({ calculation_method: id, page: 1, ...DEFAULT_SORT })
              }}
              active={selectedCalculatedMethod}
              options={CALCULATION_METHOD_SWITCH_OPTIONS}
            />
          </div>
          <div>
            <span id='show-only'>SHOW ONLY:</span>
            <div id='dropdown-container'>
              <Select
                isMulti
                value={sector}
                menuShouldScrollIntoView={false}
                className='select-container'
                options={sectorOptions}
                isSearchable={false}
                placeholder={'All Sectors'}
                onChange={handleSelectChange}
              />
            </div>
            <StyledCheckBox
              type={'checkbox'}
              id={'display_benchmark_check_input'}
              label={'Display Benchmark'}
              value={displayBenchmark}
              checked={displayBenchmark}
              {...(selectedChart === 'holding' && { disabled: true })}
              onChange={() => {
                if (selectedChart !== 'holding') {
                  setDisplayBenchmark(prev => !prev)
                  onSetDefaultPageSort()
                  onChange({
                    include_benchmark: !displayBenchmark,
                    page: 1,
                    ...DEFAULT_SORT,
                  })
                }
              }}
            />
            <StyledCheckBox
              type={'checkbox'}
              id={'include_short_check_input'}
              label={'Include Short Positions'}
              checked={includeShort}
              value={includeShort}
              onChange={() => {
                setIncludeShort(prev => !prev)
                onSetDefaultPageSort()
                onChange({
                  include_shorts: !includeShort,
                  page: 1,
                  ...DEFAULT_SORT,
                })
              }}
            />
          </div>
        </div>
        <div className='carbon-budget-body'>
          <div className='carbon-budget-graph'>
            <CarbonAuditChart
              height={600}
              pdf={pdf}
              data={chartData}
              colors={cachedColors}
            />

            <CarbonAuditLegend
              targetName={display}
              selectedChart={selectedChart}
              data={chartData}
              pdf={pdf}
            />

            <TextView
              className='carbon-budget-disclaimer'
              secondary
              size={pdf ? 9 : 12}
              text={DISCLAIMER}
            />
          </div>

          <CarbonAuditTable
            data={{ tableHeaders, tableBody, tableFooter }}
            pdf={pdf}
            loading={loading}
            onChange={onChange}
            sortState={sortState}
            paginationState={paginationState}
            displayPagination={selectedChart === 'scope' ? false : true}
            displaySort={selectedChart === 'scope' ? false : true}
            displayBenchmark={displayBenchmark}
            selectedChart={selectedChart}
          />
        </div>
        <div className='footer'>
          <Link
            target='blank'
            href='https://help.yves.blue/en/articles/4739688-the-yvesblue-carbon-budget-analysis-and-company-projections'
          >
            Methodology Document{' '}
            <i className='fal fa-external-link new-window-icon' />
          </Link>
          <div>
            <SpinnerContainer loading={loadingPDF}>
              <ExportButton
                disabled={loadingPDF}
                onClick={onDownloadPDF}
                fileType='CVS'
                className='export-btn'
                label='DOWNLOAD PDF'
                fontSize={'13px'}
                height={'40'}
                padding={'0 13px !important'}
                icon={<i className='fal fa-file-pdf'></i>}
              />
            </SpinnerContainer>
            <ExportButton
              onClick={onDownloadCSV}
              fileType='CVS'
              className='export-btn'
              label='DOWNLOAD CSV'
              icon={<i className='fal fa-file-excel csv-icon'></i>}
              fontSize={'13px'}
              height={'40'}
              padding={'0 13px !important'}
            />
          </div>
        </div>
      </StyledCard>
      {pdf && <div style={{ pageBreakBefore: 'always' }}></div>}
    </>
  )
}

CarbonAuditCard.propTypes = {
  data: propTypes.object,
  performers: propTypes.object,
  sectorOptions: propTypes.array,
  target: propTypes.object,
  benchmark: propTypes.object,

  pdf: propTypes.bool,
  loading: propTypes.bool,
  display: propTypes.string.isRequired,

  onChange: propTypes.func,
  onDownloadCSV: propTypes.func,
}

export default withErrorBoundary(memo(CarbonAuditCard))
