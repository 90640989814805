import styled from 'styled-components'

import {
  FONT_COLOR_SECONDARY,
  FONT_COLOR_TERTIARY,
} from '../../../../../../styles/colors'

export const ListItem = styled.li`
  padding-right: 10px;
  position: relative;

  .list-item-grid {
    padding-bottom: 10px;
    height: 220px;
    overflow-y: hidden;
    position: relative;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr minmax(400px, 480px) minmax(400px, 480px);
    grid-template-rows: auto auto;

    &.open {
      height: auto;
    }
  }

  aside {
    padding: 10px;
    grid-row: 1 / 3;

    h5 {
      margin: 0 0 0.25rem 0;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: ${FONT_COLOR_TERTIARY};
    }
    p {
      margin-bottom: 0.5rem;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: ${FONT_COLOR_SECONDARY};
    }
  }

  .pillar-link {
    display: block;
    font-size: 15px;
    line-height: 18px;
    text-decoration: underline;
    & svg {
      margin: -2px 0 0 4px;
    }
  }
  .pillar-data-link {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 1.75rem;
    text-decoration: underline;
  }
  .resource-link {
    text-decoration: underline;
    & svg {
      margin: -4px 0 0 4px;
    }
  }

  .list-heading {
    margin-bottom: 4px;
    display: block;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: ${FONT_COLOR_SECONDARY};
  }
  ul {
    margin: 0;
    padding: 0 1rem;
    list-style-type: disc;
    color: ${FONT_COLOR_SECONDARY};
  }
  li {
    font-size: 15px;
    line-height: 18px;
  }
`
