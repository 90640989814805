import debounce from 'lodash.debounce'
import propTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'

import { USER_ROLE_OPTIONS } from '../../constants/userRoleOptions'
import { USER_STATUS_OPTIONS } from '../../constants/userStatusOptions'
import { StyledForm } from './ManageUsersTableFilters.styles'

export const ManageUsersTableFilters = ({ onFilterChange }) => {
  const [filters, setFilters] = useState({
    query: '',
    role: '',
    status: '',
  })
  const handleUpdateFilters = (filter, val) => {
    const updatedFilter = Object.assign({})
    updatedFilter[`${filter}`] = val
    setFilters(Object.assign({}, filters, updatedFilter))
  }
  useEffect(() => {
    onFilterChange(filters)
  }, [filters])
  // handle query search input
  const handleQueryInput = value => {
    handleUpdateFilters('query', value)
  }
  // debounce query search input
  const debouncedQueryInput = useMemo(() => debounce(handleQueryInput, 300), [])
  // stop handleQueryInput debouncer after unmounting
  useEffect(() => {
    return () => {
      debouncedQueryInput.cancel()
    }
  }, [])

  return (
    <StyledForm>
      {/* search input */}
      <Form.Control
        type='search'
        placeholder='Search for...'
        className='user-search-input'
        onChange={e => debouncedQueryInput(e.target.value)}
      />
      {/* user role select */}
      <Form.Control
        as='select'
        name='role'
        id='user_role_select'
        className='user-select'
        onChange={e => handleUpdateFilters('role', e.target.value)}
      >
        {USER_ROLE_OPTIONS.map(opt => (
          <option value={opt.value} key={opt.text}>
            {opt.text}
          </option>
        ))}
      </Form.Control>
      {/* user status select */}
      <Form.Control
        as='select'
        name='status'
        id='user_status_select'
        className='user-select'
        onChange={e => handleUpdateFilters('status', e.target.value)}
      >
        {USER_STATUS_OPTIONS.map(opt => (
          <option value={opt.value} key={opt.text}>
            {opt.text}
          </option>
        ))}
      </Form.Control>
    </StyledForm>
  )
}

ManageUsersTableFilters.propTypes = {
  onFilterChange: propTypes.func.isRequired,
}
