import React from 'react'
import PropTypes from 'prop-types';


const XAxisTicks = ({amount, classes, y1, y2, xscale, stroke, strokeWidth, keyName}) => {

    const xAxisTicks = () => {

        return (
            <>
            {   
                 Array(amount).fill().map((_, i) => 
                 <line
                 key={`${keyName}-${i}`}
                 x1={xscale(i)}
                 x2={xscale(i)}
                 y1={y1}
                 y2={y2}
                 stroke={stroke}
                 strokeWidth={strokeWidth}
                 classes={classes}
                 >

                 </line>
                )
            }
            </>
        )
    }



    return (
        <>  
            {xAxisTicks()}
        </>
    )
}

XAxisTicks.propTypes = {
    amount : PropTypes.number,
    classes : PropTypes.string,
    y1 : PropTypes.number,
    y2 : PropTypes.number,
    xscale : PropTypes.func,
    stroke : PropTypes.string,
    strokeWidth : PropTypes.number,
    keyName: PropTypes.string

}


export default XAxisTicks;

