import React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  overflow: hidden;
  border-radius: 5px;
  width: 29.69px;
  height: 25px;
  img {
    width: 100%;
  }
`

const UserImg = ({ src }) => {
  return (
    <StyledDiv>
      <img src={src} />
    </StyledDiv>
  )
}

export default UserImg
