import React from 'react';
import injectSheet from 'react-jss';
import { BG_COLOR_SECONDARY, APP_COLOR_PRIMARY, APP_COLOR_PRIMARY_LIGHT } from '../../styles/colors';
import propTypes from 'prop-types';
import cns from '../../helpers/classnames';

const HorizontalProgress = ({ classes, className, progress }) => {
  classes.progress = progress < 1 ? 0 : classes.progress;
  return (
    <div className={cns(classes.container, className)}>
      <div className={classes.progress}/>
    </div>
  );
}

HorizontalProgress.propTypes = {
  progress: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
  light: propTypes.bool,
  height: propTypes.oneOfType([propTypes.string, propTypes.number]),
  width: propTypes.oneOfType([propTypes.string, propTypes.number]),
  classes: propTypes.object.isRequired
};

const style = {
  container: {
    position: 'relative',
    display: 'inline-block',
    backgroundColor: BG_COLOR_SECONDARY,
    height: props => props.height || '0.94rem',
    width: props => props.width || '100%',
    borderRadius: 7.5,
    overflow: 'hidden'
  },
  progress: {
    width: props => `${props.progress}%`,
    height: '100%',
    backgroundColor: props => props.color || APP_COLOR_PRIMARY,
    borderRadius: 7.5
  }
};

export default injectSheet(style)(HorizontalProgress);
