import PropTypes from 'prop-types'
import qs from 'query-string'
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Nav } from 'react-bootstrap'
import styled from 'styled-components'

import { getEntityById } from '../../../repositories/entities_repository'
import {
  getMetricById,
  updateSasbMetric,
} from '../../../repositories/sasb_metric_tracking_repository'
import { StyledTabNav } from '../../../yb_components/styleguide/navs/tab_nav/TabNav'
import { SasbDetailsGrid } from './components/details_grid/SasbDetailsGrid'
import { NotesList } from './components/notes_list/NotesList'
import { StyledSasbCard } from './components/sasb_card/SasbCard'
import { SdgList } from './components/sdg_list/SdgList'
import { TargetsForm } from './components/targets_form/TargetsForm'

const StyledHeader = styled.header`
  .breadcrumb {
    margin: 0 0 1.75rem 0;
  }
  a {
    color: #002fa7;
  }
  h1 {
    margin: 0 0 1rem 0;
    font-weight: 700;
    font-size: 30px;
    line-height: 1.1;
  }
`
const StyledMain = styled.main`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  .grid-wrapper {
    grid-column: 1 / 3;
  }
  .notes-wrapper {
    grid-column: 1 / 3;

    @media screen and (min-width: 992px) {
      grid-column: 1 / 2;
    }
  }
  .form-wrapper {
    grid-column: 1 / 3;

    @media screen and (min-width: 992px) {
      grid-column: 2 / 3;
    }
  }
`

const SasbDetailsPage = ({ entityId, metricId }) => {
  const [entity, setEntity] = useState(null)
  // request entity info
  useEffect(() => {
    const loadEntityById = async () => {
      const res = await getEntityById(entityId)
      setEntity(res)
    }
    loadEntityById()
  }, [])

  const portfolioName = qs.parse(window.location.search).portfolioName
  const portfolioId = qs.parse(window.location.search).portfolioId
  const sasbReturnLink =
    portfolioId && portfolioName
      ? `/app/entities/${entityId}/sasb?portfolioId=${portfolioId}&portfolioName=${portfolioName}`
      : `/app/entities/${entityId}/sasb`

  const [metric, setMetric] = useState(null)
  const loadMetricData = async () => {
    const res = await getMetricById(Number(metricId))
    const metricData = res
    setMetric(metricData)
  }

  useEffect(() => {
    loadMetricData()
  }, [])

  const updateMetric = async (metricId, metricData) => {
    const res = await updateSasbMetric(metricId, metricData)
    setMetric(res)
  }

  return (
    <div className='page__container'>
      <StyledHeader>
        <Breadcrumb>
          <Breadcrumb.Item href='/app'>Home</Breadcrumb.Item>
          {portfolioId && portfolioName && (
            <>
              <Breadcrumb.Item href='/app/portfolios'>
                Portfolios
              </Breadcrumb.Item>
              <Breadcrumb.Item href={'/app/portfolios/' + portfolioId}>
                {portfolioName}
              </Breadcrumb.Item>
            </>
          )}
          {entity && <Breadcrumb.Item active>{entity.name}</Breadcrumb.Item>}
          <Breadcrumb.Item active>Risk Assessments</Breadcrumb.Item>
          <Breadcrumb.Item active>SASB Materiality</Breadcrumb.Item>
        </Breadcrumb>
        {entity ? (
          <h1>{entity.name}</h1>
        ) : (
          <div style={{ margin: '0 0 1rem', height: '33px' }}></div>
        )}
      </StyledHeader>
      {/* tab nav */}
      <StyledTabNav style={{ marginBottom: '20px' }}>
        <Nav defaultActiveKey='sasb_materiality' as='ul'>
          <Nav.Item>
            <Nav.Link
              eventKey='esg_factors'
              href={entity ? `/app/entities/${entity.id}/esg` : '#'}
            >
              ESG Factors
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey='sasb_materiality'
              href={entity ? sasbReturnLink : '#'}
            >
              SASB Materiality
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='pai' disabled>
              PAI
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='board_vote_database' disabled>
              Board Vote Database
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </StyledTabNav>
      {/* card */}
      <StyledSasbCard className='sasb-details-card'>
        <header>
          <img
            className='sasb-logo-left'
            src='/img/logos/sasb_logo.svg'
            alt='SASB logo'
          />
          <h3 className='card-heading'>SASB Materiality</h3>
          <div className='header-content'>
            <div className='prompt-list-wrapper'>
              {metric && metric.sub_metrics.length > 1 ? (
                metric.sub_metrics.map((el, i) => (
                  <span className='prompt-list-span' key={el.id}>
                    ({i + 1}) {el.prompt}
                  </span>
                ))
              ) : (
                <span>{metric && metric.sub_metrics[0].prompt}</span>
              )}
            </div>
            <div className='sdg-wrapper'>
              {metric && <SdgList sdgs={metric.sdgs} />}
            </div>
            <div className='info-wrapper'>
              <dl>
                <dt>Dimension:</dt>
                <dd>{metric?.dimension}</dd>
              </dl>
              <dl>
                <dt>SASB General Issue Category:</dt>
                <dd>{metric?.general_issue_category_name}</dd>
              </dl>
            </div>
          </div>
        </header>
        <StyledMain>
          <div className='grid-wrapper'>
            {metric && <SasbDetailsGrid metric={metric} />}
          </div>
          <div className='notes-wrapper'>
            {metric && <NotesList metric={metric} />}
          </div>
          <div className='form-wrapper'>
            {metric && (
              <TargetsForm metric={metric} updateMetric={updateMetric} />
            )}
          </div>
        </StyledMain>
      </StyledSasbCard>
    </div>
  )
}

SasbDetailsPage.propTypes = {
  entityId: PropTypes.number.isRequired,
  metricId: PropTypes.number.isRequired,
}

export default SasbDetailsPage
