import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
  font-weight: bold;
  font-size: ${props => props.fontSize};
  text-transform: ${props => props.textTransform};
  white-space: ${props => props.wrap};
  ${props => props.paddingLeft ? `padding-left: ${props.paddingLeft}` : ''};
`;

const CardTitle = ({ title, className, fontSize = '16px', wrap, textTransform = 'none', style = {}, ...props }) => {
  return (
    <StyledSpan style={style} wrap={wrap} fontSize={fontSize} className={className} textTransform={textTransform} {...props}>{title}</StyledSpan>
  );
}

export default CardTitle;
