import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Card from '../../../../components/styleguide/Card'
import CardTitle from '../../../../components/styleguide/CardTitle'
import ESGTable from '../../../../components/styleguide/ESGTable'
import Toggle from '../../../../components/styleguide/Toggle'
import { FONT_COLOR_PRIMARY } from '../../../../styles/colors'
import SpinnerContainer from '../../../../yb_components/styleguide/spinners/SpinnerContainer'
import { getSectors } from '../../custom_esg_scoring_repository'
import ESGPillarsInput from '../ESGPillarsInput'
import SectorsList from './components/SectorsList'

const StyledCard = styled(Card)`
  overflow: hidden;
  grid-column: span 8;

  .scale-container {
    margin-bottom: 30px;
  }

  .content {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
  }

  .description {
    color: #979797;
  }

  .subsection-title {
    font-weight: 900;
    font-size: 12px;
    text-transform: uppercase;
    color: ${FONT_COLOR_PRIMARY};
  }

  .top-companies {
    margin-top: 60px;
    margin-bottom: 10px;
    clear: both;
  }

  .sector-content {
    grid-column: span 6;
    padding-left: 20px;
  }
`

const loadSectors = (dispatch, setCurrentSector) => {
  getSectors().then(res => {
    dispatch({ type: 'sectors-loaded', payload: res.data })
    setCurrentSector(res.data[0])
  })
}

const SectorWeights = ({
  dispatch,
  sectorWeights,
  loading,
  sectors,
  sectorIntermediateScores,
  defaultPillarWeights,
  defaultWeights,
  esgMetricsMap,
}) => {
  const [currentSector, setCurrentSector] = useState()
  const [view, setView] = useState('basic')

  const handleViewChange = toggleKey => {
    setView(toggleKey)
  }

  useEffect(() => {
    loadSectors(dispatch, setCurrentSector)
  }, [])

  const formatEntitiesScores = ({ scores }) => {
    const entities = []
    const esgScores = []

    if (
      currentSector &&
      sectorIntermediateScores &&
      sectorIntermediateScores[currentSector?.id]
    ) {
      scores.map(score => {
        entities.push({ name: score?.target_name })
        esgScores.push({
          e: score.e_score,
          s: score.s_score,
          g: score.g_score,
          total: score.total_score,
        })
      })
    }

    return [entities, esgScores]
  }

  let [topEntities, topEsgScores] = formatEntitiesScores({
    scores: sectorIntermediateScores[currentSector?.id]?.best,
  })
  let [worstEntities, worstEsgScores] = formatEntitiesScores({
    scores: sectorIntermediateScores[currentSector?.id]?.worst,
  })

  return (
    <StyledCard>
      <CardTitle title='CUSTOMIZE SECTOR WEIGHTS' />
      <Toggle
        values={[
          { label: 'Pillar', key: 'basic' },
          { label: 'Metric', key: 'advanced' },
        ]}
        active={view}
        onChange={handleViewChange}
      />
      <div className='description'>
        These settings allows a user to further customize ESG score by sector.
        This refinement would help the sector based investment team to reflect
        their views on the importance of each pillar in computation of ESG score
        and integrate that in the investment process.
      </div>

      {sectors && currentSector && (
        <div className='content'>
          <SectorsList
            sectors={sectors}
            currentSector={currentSector}
            sectorWeights={sectorWeights}
            setCurrentSector={setCurrentSector}
          />
          <div className='sector-content'>
            <div className='subsection-title'>{currentSector?.name}</div>

            <ESGPillarsInput
              action={{
                type: 'sector-pillar-changed',
                payload: { sectorId: currentSector?.id },
              }}
              dispatch={dispatch}
              view={view}
              loadedESGPillars={sectorWeights?.[currentSector?.id]}
              propsDefaultWeights={
                defaultPillarWeights?.[currentSector?.id] || defaultWeights
              }
              esgMetricsMap={esgMetricsMap}
              currentSectorId={currentSector?.id}
            />
            <div className='subsection-title top-companies'>
              Top Companies in “{currentSector?.name}” Sector
            </div>
            <SpinnerContainer isLoading={loading}>
              {topEntities?.length > 0 ? (
                <ESGTable entities={topEntities} esgScores={topEsgScores} />
              ) : (
                <div className='text-center'>
                  There are no companies in this sector
                </div>
              )}
            </SpinnerContainer>
            {topEntities?.length == 5 && (
              <>
                <div className='subsection-title top-companies'>
                  Worst Companies in “{currentSector?.name}” Sector
                </div>
                <SpinnerContainer isLoading={loading}>
                  {worstEntities?.length > 0 ? (
                    <ESGTable
                      entities={worstEntities}
                      esgScores={worstEsgScores}
                    />
                  ) : (
                    <div className='text-center'>
                      There are no companies in this sector
                    </div>
                  )}
                </SpinnerContainer>
              </>
            )}
          </div>
        </div>
      )}
    </StyledCard>
  )
}

export default SectorWeights
