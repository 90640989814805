
const getNewPage = ( value, currentPage, totalPages ) => {
    let newPage;
    switch(value) {
      case 'first': newPage = 1; break;
      case 'last': newPage = totalPages; break;
      case 'previous': newPage = Math.max(1, currentPage - 1); break;
      case 'next': newPage = Math.min(totalPages, currentPage + 1); break;
      default: newPage = +value;
    }
    return newPage;
}



export { getNewPage }

