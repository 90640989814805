export const mostRecentFiscalYear = 2019

export const REPORT_SUMMARY_CARD_COPY = {
  title: 'COMPANY CARBON REPORT CARD',
  companyDescription:
    'The Company Carbon Report Card provides quick reference and context for entity level climate related performance. These metrics represent risk or opportunity amidst various drivers such as carbon footprint and intensity, progress towards achieving zero emissions by 2030, climate leader status, and the annual cost of carbon.',
  fundDescription:
    'The Fund Carbon Report Card provides quick reference and context for the fund’s climate related performance. These metrics use the S&P 500 as a benchmark and represent risk or opportunity amidst various drivers such as carbon footprint and intensity, the number of climate solution companies in the fund, annual carbon cost, and average renewable energy usage.',
}

export const BudgetProjectionTypes = {
  unmodeled: {
    name: 'Unmodeled',
    key: 0,
  },
  'zero emissions': {
    name: 'Zero Emissions',
    key: 1,
  },
  'well bellow': {
    name: 'Well Below Budget By 2030',
    key: 2,
  },
  'under budget': {
    name: 'Under Budget by 2030',
    key: 3,
  },
  'meet budget': {
    name: 'Meet Budget by 2030',
    key: 4,
  },
  'over budget': {
    name: 'Over Budget by 2030',
    key: 5,
  },
}

export const ClimateChangeTypes = {
  leader: {
    name: 'Leader',
    key: 0,
  },
  ally: {
    name: 'Ally',
    key: 1,
  },
  underperformer: {
    name: 'Underperformer',
    key: 2,
  },
  laggard: {
    name: 'Laggard',
    key: 3,
  },
  unscored: {
    name: 'Unscored',
    key: 4,
  },
}
