import styled from 'styled-components'

export const StyledForm = styled.div`
  --input-group-gray: #c8ced4;

  margin: 0;

  h3 {
    margin: 0 0 10px 0;
    font-size: 14px;
    line-height: 1.1;
    font-weight: 700;
  }
  form {
    padding: 10px;
    border-radius: 5px;
    background-color: #f6f8fd;
  }

  .form-section {
    padding: 0 0 12px 0;
    border-bottom: 1px solid #e4e8ea;

    &:not(:first-child) {
      padding: 12px 0;
    }
    &:last-child {
      padding: 12px 0 0;
      border: none;
    }
  }
  h5 {
    margin: 0 0 10px 0;
    font-size: 12px;
    line-height: 14.4px;
    font-weight: 700;
  }
  .form-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    grid-gap: 10px;

    @media screen and (min-width: 1200px) {
      grid-template-columns: calc(50% - 5px) calc(50% - 5px); // hack
      grid-template-rows: auto auto;
    }
  }
  .form-group {
    margin: 0;
  }
  label {
    margin: 0 0 3px;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #979797;
  }
  .form-control {
    font-size: 14px;
  }
  .input-group {
    > input {
      height: 29px;
      border-radius: 6px 0px 0px 6px;
      border: 1px solid var(--input-group-gray);
    }
  }
  .input-group-append {
    height: 29px;
    border-radius: 0px 6px 6px 0px;
    background-color: var(--input-group-gray);
  }
  .input-group-text {
    padding: 5px 4px;
    border-radius: 0px 6px 6px 0px;
    font-size: 10px;
    line-height: 12px;
    color: white;
    background-color: var(--input-group-gray);
  }
  // datepicker
  input[type='date'] {
    padding-right: 4px;
    height: 29px;
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid var(--input-group-gray);
    // make gray box for icon to be in front of in datetimepicker inputs
    background-image: url('/icons/ico_input_gray_box.svg');
    background-size: 38px 40px;
    background-position: right -6px top 0;
    background-repeat: no-repeat;
  }
  // put yb icon in place of webkit standard datepicker icon; chromium only
  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.871 8.438H3.555c-.217 0-.395-.159-.395-.352V6.914c0-.193.178-.351.395-.351H4.87c.218 0 .395.158.395.351v1.172c0 .193-.177.351-.395.351zm3.555-.352V6.914c0-.193-.178-.351-.395-.351H6.715c-.218 0-.395.158-.395.351v1.172c0 .193.177.351.395.351H8.03c.217 0 .395-.158.395-.351zm3.16 0V6.914c0-.193-.178-.351-.395-.351H9.874c-.217 0-.394.158-.394.351v1.172c0 .193.177.351.394.351h1.317c.217 0 .395-.158.395-.351zm-3.16 2.812V9.727c0-.194-.178-.352-.395-.352H6.715c-.218 0-.395.158-.395.352v1.171c0 .194.177.352.395.352H8.03c.217 0 .395-.158.395-.352zm-3.16 0V9.727c0-.194-.177-.352-.395-.352H3.555c-.217 0-.395.158-.395.352v1.171c0 .194.178.352.395.352H4.87c.218 0 .395-.158.395-.352zm6.32 0V9.727c0-.194-.178-.352-.395-.352H9.874c-.217 0-.394.158-.394.352v1.171c0 .194.177.352.394.352h1.317c.217 0 .395-.158.395-.352zm3.16-7.617v10.313c0 .776-.708 1.406-1.58 1.406H1.58C.708 15 0 14.37 0 13.594V3.28c0-.776.708-1.406 1.58-1.406h1.58V.352c0-.194.178-.352.395-.352H4.87c.218 0 .395.158.395.352v1.523H9.48V.352c0-.194.177-.352.394-.352h1.317c.217 0 .395.158.395.352v1.523h1.58c.872 0 1.58.63 1.58 1.406zm-1.58 10.137v-8.73H1.58v8.73c0 .097.089.176.197.176H12.97c.108 0 .197-.08.197-.176z' fill='%23fff'/%3E%3C/svg%3E");

    &:hover {
      cursor: pointer;
    }
  }
  .datetimepicker-button {
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--input-group-gray);
    border-radius: 0px 6px 6px 0px;
    background-color: var(--input-group-gray);
  }
  textarea {
    border-radius: 6px;
  }
  .submit-button {
    margin: 0 0 0 auto;
    padding: 8px 30px;
    display: block;
    border-radius: 6px;
    font-size: 10px;
    line-height: 1.1;
    font-weight: 900;
    background-color: #002fa7;
    border-color: #002fa7;

    &:active {
      background-color: #002fa7;
      border-color: #002fa7;
    }
  }
`
