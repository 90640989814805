import propTypes from 'prop-types'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'

import { InfoTable } from '../../../components/tables/InfoTable.styles'
import {
  disableFocusArea,
  enableFocusArea,
} from '../../repository/esg_respository'
import AddFocusAreaDialog from '../modals/AddFocusAreaDialog'
import EditFocusAreaValueDialog from '../modals/EditFocusAreaValueDialog'
import { RiskAssessmentRow } from './RiskAssessmentRow'

const RiskAssessmentTable = ({
  title,
  riskAssessment,
  category,
  options,
  units,
  onCreateDialogSuccess,
  entityId,
  dataCy,
}) => {
  const [data, setData] = useState(riskAssessment)
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [editingRow, setEditingRow] = useState(0)
  const [editingType, setEditingType] = useState(null)

  const onSwitchChange = (enabled, focusAreaId) => {
    enabled
      ? enableFocusArea(entityId, focusAreaId)
      : disableFocusArea(entityId, focusAreaId)

    setData(
      data.map(d => {
        if (d.id === focusAreaId) {
          return {
            ...d,
            status: enabled,
          }
        } else {
          return d
        }
      })
    )
  }

  const onAddMoreClick = () => {
    setShowCreateDialog(true)
  }

  const onEditClick = (row, editingType) => {
    if (!data[row].status) return false

    setEditingType(editingType)
    setShowEditDialog(true)
    setEditingRow(row)
  }

  const onUpdateSuccess = res => {
    setData(
      data.map(d => {
        if (d.id === res.id) return res
        else return d
      })
    )
  }

  const onCreateSuccess = res => {
    setData([...data, res])
    onCreateDialogSuccess()
  }
  const [showNotes, setShowNotes] = useState(false)
  const toggleAllNotes = () => {
    setShowNotes(!showNotes)
  }

  return (
    <>
      <InfoTable data-cy={dataCy}>
        <thead>
          <tr className='grid-title-container'>
            <th>
              <h4>{title}</h4>
            </th>
          </tr>
          <tr className='grid-head-row'>
            <th className='grid-head-cell'></th>
            <th className='grid-head-cell'></th>
            <th className='grid-head-cell'>
              <span>
                Estimated Last <span className='no-wrap'>Reported Value</span>
              </span>
            </th>
            <th className='grid-head-cell'>Target Value</th>
            <th className='grid-head-cell'>Current Value</th>
            <th
              className='grid-head-cell'
              style={{ justifyContent: 'flex-end' }}
            >
              <button
                className='btn toggle-all-button'
                onClick={() => toggleAllNotes()}
              >
                {showNotes ? 'Hide All' : 'Show All'}
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => {
            let rowClassNames = [
              i % 2 ? 'even' : 'odd',
              d.status ? 'enabled' : 'disabled',
            ]

            return (
              <RiskAssessmentRow
                row={d}
                i={i}
                classNames={rowClassNames.join(' ')}
                key={'focusArea-' + i}
                entityId={entityId}
                onSwitchChange={onSwitchChange}
                onEditClick={onEditClick}
                showNotesParent={showNotes}
                onSuccess={onUpdateSuccess}
              />
            )
          })}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <Button className='add-more-cell-button' onClick={onAddMoreClick}>
                <svg
                  width='8'
                  aria-hidden='true'
                  focusable='false'
                  role='img'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 448 512'
                >
                  <path
                    fill='currentColor'
                    d='M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z'
                  ></path>
                </svg>{' '}
                Add More
              </Button>
            </td>
          </tr>
        </tfoot>
      </InfoTable>
      <AddFocusAreaDialog
        entityId={entityId}
        category={category}
        options={options}
        units={units}
        onClose={() => setShowCreateDialog(false)}
        onSuccess={onCreateSuccess}
        open={showCreateDialog}
        data-cy='add_focus_area_dialog'
      />
      <EditFocusAreaValueDialog
        entityId={entityId}
        data={data[editingRow]}
        onClose={() => setShowEditDialog(false)}
        onSuccess={onUpdateSuccess}
        editingType={editingType}
        open={showEditDialog}
        data-cy='edit_focus_area_dialog'
      />
    </>
  )
}

RiskAssessmentTable.defaultProps = {
  data: [],
  options: [],
}

RiskAssessmentTable.propTypes = {
  title: propTypes.string.isRequired,
  riskAssessment: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string.isRequired,
      unit: propTypes.string.isRequired,
      current_value: propTypes.number.isRequired,
      date: propTypes.string,
      target: propTypes.string,
      target_value: propTypes.number,
      status: propTypes.number.isRequired,
    })
  ).isRequired,
  category: propTypes.string.isRequired,
  options: propTypes.array.isRequired,
  units: propTypes.array.isRequired,
  onCreateDialogSuccess: propTypes.func.isRequired,
  entityId: propTypes.number.isRequired,
  dataCy: propTypes.string.isRequired,
}

export default RiskAssessmentTable
