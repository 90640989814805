import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import ErrorPage from '../../../../components/styleguide/ErrorPage'
import withErrorBoundary from '../../../../hocs/withErrorBoundary'
import { getSolutionCompaniesHoldings } from '../../../../repositories/portfolio_repository'
import {
  FilledExplainerLink,
  LinkIcon,
} from '../../../../yb_components/styleguide/links/Link.styles'
import Pagination from '../../../../yb_components/styleguide/pagination/Pagination'
import SpinnerContainer from '../../../../yb_components/styleguide/spinners/SpinnerContainer'
import { CarbonReportTable, ModuleDescription } from '../carbon_report/styles'
import CarbonReportCard from './components/CarbonReportCard'
import CarbonReportLink from './components/CarbonReportLink'
import { CARBON_REPORT_TEXTS } from './constants'

const StyledCarbonReportCard = styled(CarbonReportCard)`
  .missing-companies-content {
    text-align: center;
    div {
      margin-bottom: 15px;
    }
  }

  table {
    margin-bottom: 10px;
    thead tr {
      text-transform: none;
    }
  }
`

const StyledExplainerLink = styled(FilledExplainerLink)`
  float: right;
`

const StyledCardGridContainer = styled.div`
  ${props => (props.pdf ? '' : ' grid-column-start: 1; grid-column: span 4;')}
`

export const loadSolutionCompanies = ({
  snapshotId,
  portfolioId,
  setHoldingCategories,
  setTotalPages,
  page,
  setError,
  dispatch,
  setIsLoading,
}) => {
  getSolutionCompaniesHoldings({ snapshotId, portfolioId, page })
    .then(res => {
      setIsLoading(false)
      setHoldingCategories(res.data.solution_companies)
      setTotalPages(res.data.total_pages)
      dispatch({
        type: 'solution-companies-loaded',
        payload: { data: res.data.solution_companies },
      })
    })
    .catch(err => {
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

const SolutionCompanyHoldings = ({
  portfolioId,
  snapshotId,
  pdf,
  dispatch,

  defaultData,
  title = CARBON_REPORT_TEXTS?.solutionCompanies.title,
  description = CARBON_REPORT_TEXTS?.solutionCompanies.description,
}) => {
  const [holdingCategories, setHoldingCategories] = useState(defaultData || [])
  const [isLoading, setIsLoading] = useState(!pdf)
  const [error, setError] = useState()
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    if (defaultData) setHoldingCategories(defaultData)
  }, [defaultData])

  useEffect(
    () =>
      !pdf
        ? loadSolutionCompanies({
            portfolioId,
            snapshotId,
            setTotalPages,
            page,
            setHoldingCategories,
            setError,
            dispatch,
            setIsLoading,
          })
        : () => {},
    [page]
  )

  return (
    <StyledCardGridContainer pdf={pdf}>
      <StyledCarbonReportCard title={title} pdf={pdf}>
        {/* <StyledCard> */}
        {/* 
    <CardTitle title='SOLUTION COMPANY HOLDINGS' /> */}
        {!pdf && (
          <StyledExplainerLink
            pdf={pdf}
            href='http://help.yves.blue/en/articles/6698762-carbon-report-portfolio-summary-on-yvesblue#h_96b16bcfd3'
            target='_blank'
          >
            <LinkIcon className='fa fa-question' />
          </StyledExplainerLink>
        )}

        <ModuleDescription marginBottom={'20px'} pdf={pdf}>
          {description}
        </ModuleDescription>
        {error ? (
          <>
            <ErrorPage code={error} message={error == 404 ? 'NOT FOUND' : ''} />
          </>
        ) : (
          <>
            {isLoading && <br />}
            <SpinnerContainer isLoading={isLoading}>
              {holdingCategories.length > 0 &&
                holdingCategories.map((category, index) => (
                  <CarbonReportTable
                    pdf={pdf}
                    key={`solution-comp-cat-${index}`}
                  >
                    <thead>
                      <tr>
                        <th colSpan={2}>{category.category}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {category.holdings.map((holding, index) => (
                        <tr key={`solution-comp-holding-${index}`}>
                          <td>
                            <CarbonReportLink
                              pdf={pdf}
                              name={holding.name}
                              href={`/app/instruments/${holding.instrument_id}/#report-summary-card`}
                              target='_blank'
                            />
                          </td>
                          <td className='number'>{holding.weight}%</td>
                        </tr>
                      ))}
                    </tbody>
                  </CarbonReportTable>
                ))}

              {holdingCategories.length == 0 && (
                <div className='missing-companies-content'>
                  <div
                    style={{
                      textAlign: 'center',
                      marginTop: pdf ? '70px' : '20px',
                    }}
                  >
                    There are no companies in your portfolio that are <br />
                    considered solution companies.
                  </div>
                  {/* !pdf && <Link href='#'>View All Solution Companies</Link> */}
                </div>
              )}
              {holdingCategories.length > 0 && !pdf && (
                <Pagination
                  max={totalPages}
                  selected={page}
                  onChange={e => setPage(e)}
                />
              )}
            </SpinnerContainer>
          </>
        )}
        {/* </StyledCard> */}
      </StyledCarbonReportCard>
    </StyledCardGridContainer>
  )
}

export default withErrorBoundary(SolutionCompanyHoldings)
