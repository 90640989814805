import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'

import { queryStringParse } from '../../../helpers/objects_and_arrays'
import { getImpactThemes } from '../../../repositories/managers_repository'

const StyledA = styled.a`
  cursor: pointer;
`

const SearchByImpactTheme = ({ onImpactThemeChange = () => {} }) => {
  const [impactThemes, setImpactThemes] = useState([])
  const [selectedPrimaryTheme, setSelectedPrimaryTheme] = useState({})
  const [selectedSecondaryTheme, setSelectedSecondaryTheme] = useState({})

  useEffect(() => {
    getImpactThemes().then(result => {
      setImpactThemes(result.data)
      const primaryTheme =
        result.data.find(
          theme => theme.id === parseInt(queryStringParse().primaryThemeId)
        ) || {}
      setSelectedPrimaryTheme(primaryTheme)
    })
  }, [])

  const onPrimaryClick = primaryTheme => () => {
    setSelectedSecondaryTheme({})
    if (primaryTheme.id === selectedPrimaryTheme.id) {
      setSelectedPrimaryTheme({})
      onImpactThemeChange(null, null)
    } else {
      setSelectedPrimaryTheme(primaryTheme)
      onImpactThemeChange(primaryTheme.id, null)
    }
  }

  const primaryThemeClass = primaryImpactThemeId => {
    return `badge badge-${
      primaryImpactThemeId === selectedPrimaryTheme.id ? 'primary' : 'secondary'
    }`
  }

  const onSecondaryClick = secondaryTheme => () => {
    if (secondaryTheme.id === selectedSecondaryTheme.id) {
      setSelectedSecondaryTheme({})
      onImpactThemeChange(selectedPrimaryTheme.id, null)
    } else {
      setSelectedSecondaryTheme(secondaryTheme)
      onImpactThemeChange(selectedPrimaryTheme.id, secondaryTheme.id)
    }
  }

  const secondaryThemeClass = secondaryImpactThemeId => {
    return `badge badge-${
      secondaryImpactThemeId === selectedSecondaryTheme.id
        ? 'primary'
        : 'secondary'
    }`
  }

  const impactThemeBadge = (kind, impactTheme) => {
    const className =
      kind === 'primary'
        ? primaryThemeClass(impactTheme.id)
        : secondaryThemeClass(impactTheme.id)
    const onClick =
      kind === 'primary'
        ? onPrimaryClick(impactTheme)
        : onSecondaryClick(impactTheme)

    return (
      <li key={`${kind}-impact-theme-${impactTheme.id}`}>
        <StyledA className={className} onClick={onClick}>
          {impactTheme.name}
        </StyledA>
      </li>
    )
  }

  return (
    <div className='card mb-4'>
      <h6 className='card-header'>Search By Impact Theme</h6>
      <div className='card-body'>
        <ul className='impact-theme__primary-list'>
          {impactThemes.map(primaryImpactTheme => (
            <Fragment key={`impact-theme-${primaryImpactTheme.id}`}>
              {impactThemeBadge('primary', primaryImpactTheme)}
              {selectedPrimaryTheme.id === primaryImpactTheme.id &&
                selectedPrimaryTheme.children?.length > 0 && (
                  <li>
                    <ul className='impact-theme__secondary-list'>
                      {selectedPrimaryTheme.children.map(secondaryImpactTheme =>
                        impactThemeBadge('secondary', secondaryImpactTheme)
                      )}
                    </ul>
                  </li>
                )}
            </Fragment>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default SearchByImpactTheme
