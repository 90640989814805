import React, { useState, useEffect } from 'react';
import SectionWrapper from '../../../../../components/styleguide/SectionWrapper';
import CardTitle from '../../../../../components/styleguide/CardTitle';
import TextSection from '../../../../../components/styleguide/TextSection';
import { FormCheck } from 'react-bootstrap';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import WrapperColumn from '../../../../../components/styleguide/WrapperColumn';
import UpdateTargetModal from './UpdateTargetModal';
import UpdateCurrentModal from './UpdateCurrentModal';
import AddNoteModal from './AddNoteModal';
import { NotesHeader, NotesWrapper, ActionLinkCustomForm } from './styles';
import { MultiPartDisplayRow } from '../../../../../components/styleguide/MultiPartDisplay/MultiPartDisplayRow';
import NotesSection from './NoteSection';



const MultiPartDisplayCustomFormRow = ({ item, config, updateTarget, updateValue, createNewNote, parity, dataLabel, current_user }) => {

    const [showNotes, setShowNotes] = useState(false);
    const [showUpdateTargetModal, setShowUpdateTargetModal] = useState(false);
    const [showUpdateCurrentModal, setShowUpdateCurrentModal] = useState(false);
    const [showAddNoteModal, setShowAddNoteModal] = useState(false);
    const [noteCount, setNoteCount] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [tempNotes, setTempNotes] = useState([]);

    useEffect(() => {
        setNoteCount(item.note_count || 0);
    },[item.note_count])

    useEffect(() => {
        return () => {
            if(item.notes && item.notes.length < 2){
                item.notes.concat(tempNotes);
            }
            setTempNotes(item.notes);
        }
    },[item.notes])

    useEffect(() => {
        return () => {
            setTempNotes(item.notes);
        }
    },[item.notes])

    const getDate = (date) => {
        return new Date(date.replace(/-/g, '\/').replace(/T.+/, '')).toLocaleDateString('en-us').replace(/-/g, '/');
    }

    const closeModal = () => {
        setTempNotes(item.notes);
        setShowUpdateTargetModal(false);
        setShowUpdateCurrentModal(false);
        setShowAddNoteModal(false);
    }

    const createNewMetricTarget = ( target ) => {
        closeModal();
        updateTarget(target, item.id);
    };

    const createNewMetricValue = ( target ) => {
        closeModal();
        updateValue(target, item.id);
    };

    const addNewNote = ( note ) => {
        const newNotes = item.notes;
        newNotes.push({author_name: `${current_user.first_name} ${current_user.last_name}`, body:note, created_at: new Date().toISOString() });
        closeModal();
        setShowNotes(true);
        setTempNotes(newNotes);
        createNewNote(note, item.id);
    }

    const notesPresent = () => {
        return  ( (item.notes && item.notes[0]) || (tempNotes && tempNotes[0]) );
    }

    return (
        <>
            <MultiPartDisplayRow parity={parity} disabled={disabled} data-cy={`${dataLabel}-multi-part-display-row`}>
                <SectionWrapper wrap="nowrap" flex="2" data-cy={`${dataLabel}-multi-part-display-first-column-wrapper`}>
                    <SectionWrapper margin="0px 0px 0px 15px" data-cy={`${dataLabel}-multi-part-display-form-check-wrapper`}>
                        <FormCheck
                            onChange={() => {setDisabled(!disabled)}}
                            type="switch"
                            id={item.key}
                            checked={!disabled}
                            label={""}
                        />
                    </SectionWrapper>
                    <SectionWrapper data-cy={`${dataLabel}-multi-part-display-description-wrapper`}>
                        <TextSection width="80%" margin="0px 0px 0px 0px" data-cy={`${dataLabel}-custom-form-description`}>
                            <CardTitle title={item.key}/>
                            <br/>
                            {item.description}
                        </TextSection>
                    </SectionWrapper>
                </SectionWrapper>
                <WrapperColumn styles={{flex:'3', align:'stretch'}} data-cy={`${dataLabel}-custom-form-display::three-column-wrapper`}>
                    <SectionWrapper flex="3" wrap="nowrap" data-cy={`${dataLabel}-custom-form-display::three-column-wrapper::main-column`}>

                        <SectionWrapper wrap="nowrap" flex="1" data-cy={`${dataLabel}-custom-form-display::target-value`}>
                            {
                                item.target && item.target.value && item.target.due_date ? (
                                    <WrapperColumn data-cy={`${dataLabel}-custom-form-display::target-value-wrapper`}>
                                        <SectionWrapper data-cy={`${dataLabel}-custom-form-display::target-value-link-wrapper`}>
                                            {
                                                !config.canUpdate ? <div>{(+item.target.value).toFixed(2)} {item.unit}</div> :
                                                    <ActionLinkCustomForm onClick={() => {!disabled && setShowUpdateTargetModal(true)}} data-cy={`${dataLabel}-custom-form-display::target-link`}>
                                                        {(+item.target.value).toFixed(2)} {item.unit}
                                                    </ActionLinkCustomForm>
                                            }
                                        </SectionWrapper>
                                        <TextSection width="auto" data-cy={`${dataLabel}-custom-form-display::target-date-link`}> by {getDate(item.target.due_date)}</TextSection>
                                    </WrapperColumn>
                                ) :
                                <SectionWrapper data-cy={`${dataLabel}-custom-form-display::add-target-wrapper`}>
                                    {
                                        !config.canUpdate ? <div>--</div> :
                                            <ActionLinkCustomForm onClick={() => {!disabled && setShowUpdateTargetModal(true)}} data-cy={`${dataLabel}-custom-form-display::add-target-link`}>
                                                Add Target
                                            </ActionLinkCustomForm>
                                    }
                                </SectionWrapper>
                            }
                        </SectionWrapper>

                        <SectionWrapper wrap="nowrap" flex="1" data-cy={`${dataLabel}-custom-form-display::current-value-wrapper`}>
                            {
                                item.current_value && item.current_value.value && item.current_value.as_of ? (
                                    <WrapperColumn data-cy={`${dataLabel}-custom-form-display::current-value-wrapper::link-wrapper`}>
                                        <SectionWrapper data-cy={`${dataLabel}-custom-form-display::current-value-wrapper::current_value-link-wrapper`}>
                                            {
                                                !config.canUpdate ? <div>{(+item.current_value.value).toFixed(2)} {item.unit}</div> :
                                                    <ActionLinkCustomForm data-cy={`${dataLabel}-custom-form-display::current-value-wrapper::value-link`} onClick={() => {!disabled && setShowUpdateCurrentModal(true)}}>
                                                        {(+item.current_value.value).toFixed(2)} {item.unit}
                                                    </ActionLinkCustomForm>
                                            }
                                        </SectionWrapper>
                                        <TextSection width="auto" data-cy={`${dataLabel}-custom-form-display::current-value-wrapper::value-date-link`}>
                                            by {getDate(item.current_value.as_of)}
                                        </TextSection>
                                    </WrapperColumn>
                                ) :
                                <SectionWrapper data-cy={`${dataLabel}-custom-form-display::current-value-wrapper::add-current-value-link`}>
                                    {
                                        !config.canUpdate ? <div>--</div> :
                                            <ActionLinkCustomForm onClick={() => {!disabled && setShowUpdateCurrentModal(true)}}>
                                                Add Current Value
                                            </ActionLinkCustomForm>
                                    }
                                </SectionWrapper>
                            }
                        </SectionWrapper>

                        <SectionWrapper wrap="nowrap" flex="1" data-cy={`${dataLabel}-custom-form-display::historical-values`}>
                            <WrapperColumn data-cy={`${dataLabel}-custom-form-display::historical-values-wrapper`}>
                                <SectionWrapper justify="center" margin="5px 0px 8px 0px" data-cy={`${dataLabel}-custom-form-display::historical-values::sparkline-wrapper`}>
                                    {
                                        item.historical_values && item.historical_values.length > 1 ?
                                        <Sparklines data-cy={`${dataLabel}-custom-form-display::historical-values::sparkline`} data={item.historical_values} svgWidth={200} svgHeight={45}>
                                            <SparklinesLine color="blue" />
                                        </Sparklines>
                                        :
                                        <span data-cy={`${dataLabel}-custom-form-display::historical-values::no-historical-values`} style={{flex:"1", textAlign:"center"}}>--</span>
                                    }
                                </SectionWrapper>
                            </WrapperColumn>
                        </SectionWrapper>

                    </SectionWrapper>
                        <WrapperColumn styles={{margin:"20px 0px 0px 0px"}} data-cy={`${dataLabel}-custom-form-display::notes-wrapper`}>
                            <NotesHeader data-cy={`${dataLabel}-custom-form-display::notes-header`}>
                                <SectionWrapper flex="1" justify="space-between"  data-cy={`${dataLabel}-notes-display`} >
                                    <h4  data-cy={`${dataLabel}-custom-form-display::notes-display-header-wrap`} >
                                        {
                                            showNotes ?
                                            <i className="fa fa-caret-right toggle-icon downward" data-cy={`${dataLabel}-custom-form-display::notes-show-icon`} onClick={() => !disabled && setShowNotes(false)} ></i>
                                            :
                                            <i className="fa fa-caret-right toggle-icon" data-cy={`${dataLabel}-custom-form-display::notes-hide-icon`} onClick={() => {!disabled &&setShowNotes(true)}}></i>

                                        }
                                        Notes ({noteCount})
                                    </h4>
                                    {
                                        config.canUpdate &&
                                        <a data-cy={`${dataLabel}-custom-form-display::add-note-link`} className="add-note" onClick={() => {!disabled && setShowAddNoteModal(true)}}>Add New Note</a>

                                    }
                                </SectionWrapper>
                            </NotesHeader>
                            {
                                showNotes && (
                                    <>
                                        {
                                            ( (item.notes && item.notes[0]) || (tempNotes && tempNotes[0]) ) && (
                                                <NotesSection
                                                    note={item.notes && item.notes[0] || (tempNotes && tempNotes[0])}
                                                    dataLabel={dataLabel}
                                                />
                                            )
                                        }
                                                                                {
                                            ( (item.notes && item.notes[1]) || (tempNotes && tempNotes[1]) ) && (
                                                <NotesSection
                                                    note={item.notes && item.notes[1] || (tempNotes && tempNotes[1])}
                                                    dataLabel={dataLabel}
                                                />
                                            )
                                        }
                                        {/* {
                                            item && item.notes && item.notes.length > 2 && (
                                                <SectionWrapper justify="space-between">
                                                    <ActionLink>View All Notes</ActionLink>
                                                </SectionWrapper>
                                            )
                                        } */}

                                    </>
                                )
                            }
                        </WrapperColumn>
                </WrapperColumn>
                </MultiPartDisplayRow>
            <UpdateTargetModal
                dataLabel={dataLabel}
                currentValue={item && item.target && item.target.value}
                defaultDate={item && item.target && item.target.due_date}
                show={showUpdateTargetModal}
                setShowUpdateTargetModal={setShowUpdateTargetModal}
                createNewMetricTarget={createNewMetricTarget}
            />
            <UpdateCurrentModal
                dataLabel={dataLabel}
                currentValue={item && item.current_value && item.current_value.value}
                show={showUpdateCurrentModal}
                setShowCurrentModal={setShowUpdateCurrentModal}
                createNewCurrentValue={createNewMetricValue}
            />
            <AddNoteModal
                dataLabel={dataLabel}
                show={showAddNoteModal}
                setShowAddNoteModal={setShowAddNoteModal}
                addNewNote={addNewNote}
            />
        </>
    )
}
export default MultiPartDisplayCustomFormRow;
