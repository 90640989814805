export const HEADERS = [
  { label: 'PORTFOLIO NAME', sort: true, id: 'name' },
  { label: 'HOLDINGS AS OF', sort: true, id: 'as_of' },
  { label: 'TOTAL', sort: true, id: 'total_score' },
  { label: 'E', sort: true, id: 'e_score' },
  { label: 'S', sort: true, id: 's_score' },
  { label: 'G', sort: true, id: 'g_score' },
  { label: '' },
]

export const BREADCRUMB_LINKS = [
  {
    url: '/app',
    text: 'Home',
    active: false,
  },
  {
    text: 'Portfolios',
    active: true,
  },
]
