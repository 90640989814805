import propTypes from 'prop-types'
import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import styled from 'styled-components'

export const BreadcrumbNav = ({ links, marginBottom }) => {
  const StyledBreadcrumb = styled(Breadcrumb)`
    .breadcrumb {
      margin: 0;
      margin-bottom: ${marginBottom != null ? marginBottom + 'px' : '0'};
      padding: 0.75rem 1.25rem;
      border-radius: 6px;
      color: #68717a;
      font-size: 16px;
    }
    .breadcrumb-item > a {
      color: #002fa7;
    }
  `

  return (
    <StyledBreadcrumb>
      {links.map(link => (
        <Breadcrumb.Item href={link.url} key={link.url} active={link.active}>
          {link.text}
        </Breadcrumb.Item>
      ))}
    </StyledBreadcrumb>
  )
}

BreadcrumbNav.propTypes = {
  links: propTypes.array.isRequired,
  marginBottom: propTypes.number,
}
