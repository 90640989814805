import { objectToSnakeCase } from '@utils/object'
import axios from 'axios'
import _ from 'lodash'
import qs from 'query-string'

import {
  formatObjectCasing,
  objectToFormData,
  objectToURLParams,
} from './utils'

export const getBadges = (id, portfolioId) => {
  return axios.get(`/app/api/portfolios/${portfolioId}/snapshots/${id}/badges`)
}

export const getSnapshotCo2Projections = (id, portfolioId) => {
  return axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${id}/co2_projections`
  )
}

export const getPortfolios = ({ query }) => {
  return axios.get(`/app/api/portfolios${query}`)
}

export const getSnapshot = (id, portfolioId) => {
  return axios.get(`/app/api/portfolios/${portfolioId}/snapshots/${id}`)
}

export const deletePortfolio = (id, portfolioId) => {
  return axios.delete(`/app/api/portfolios/${portfolioId}`)
}

///Holdings Data CALL - Standard
export const getSnapshotHoldings = (
  id,
  portfolioId,
  customRequestParameters
) => {
  let defaultParameters = {
    page: 1,
    limit: 10,
    sort_field: 'weight',
    sort_dir: 'desc',
    modeled_type: 'all',
    query: '',
  }

  const formattedParams = {}
  _.each(customRequestParameters, (val, key) => {
    if (Array.isArray(val)) {
      formattedParams[key] = val?.join(',')
    } else formattedParams[key] = val
  })

  const mainQuery = `/app/api/portfolios/${portfolioId}/snapshots/${id}/holdings?${qs.stringify(
    { ...defaultParameters, ...formattedParams }
  )}`

  return axios.get(mainQuery)
}

///Holdings Data CALL - Entities
export const getSnapshotCorporateEntities = (
  id,
  portfolioId,
  customRequestParameters
) => {
  let defaultParameters = {
    page: 1,
    limit: 10,
    sort_field: 'weight',
    sort_dir: 'desc',
    query: '',
  }
  let options = Object.assign(defaultParameters, customRequestParameters)

  const mainQuery = `/app/api/portfolios/${portfolioId}/snapshots/${id}/corporate_entity_holdings?${qs.stringify(
    options
  )}`

  return axios.get(mainQuery)
}

///Holdings Data CALL - Industry
export const getSnapshotIndustryGroups = (
  id,
  portfolioId,
  customRequestParameters
) => {
  let defaultParameters = {
    page: 1,
    limit: 10,
    sort_field: 'weight',
    sort_dir: 'desc',
    query: '',
  }
  let options = Object.assign(defaultParameters, customRequestParameters)

  const mainQuery = `/app/api/portfolios/${portfolioId}/snapshots/${id}/industry_group_holdings?${qs.stringify(
    options
  )}`
  return axios.get(mainQuery)
}

export const getESGYearlyMetrics = (id, portfolioId) => {
  return axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${id}/esg_yearly_metrics`
  )
}

export const getCarbonAuditDropdownOptions = snapshotiId => {
  return axios.get(
    `/app/api/utilities/dropdown_options?portfolio_snapshot_id=${snapshotiId}&trbc_industry_groups=true`
  )
}

export const putPortfolioUpdate = (id, portfolioId, { portfolio_snapshot }) => {
  const url = `/app/api/portfolios/${portfolioId}/snapshots/${id}`

  const formData = new FormData()
  formData.append('portfolio_snapshot[name]', portfolio_snapshot.name)
  formData.append(
    'portfolio_snapshot[impact_benchmark_id]',
    portfolio_snapshot.impact_benchmark_id
  )
  formData.append(
    'portfolio_snapshot[description]',
    portfolio_snapshot.description
  )
  portfolio_snapshot.snapshot_upload_file &&
    formData.append(
      'portfolio_snapshot[file]',
      portfolio_snapshot.snapshot_upload_file
    )
  formData.append(
    'portfolio_snapshot[total_value]',
    portfolio_snapshot.total_value
  )
  formData.append(
    'portfolio_snapshot[holdings]',
    JSON.stringify(portfolio_snapshot.holdings)
  )
  formData.append('portfolio_snapshot[as_of]', portfolio_snapshot.as_of)

  return axios.put(url, formData)
}

export const getPortfolioComparisons = () => {
  return axios.get('/app/api/portfolios/comparisons')
}

export const getComparisonsByNameQuery = input => {
  return axios.get(`/app/api/portfolios/comparisons/find_by?input=${input}`)
}

export const putComparisonsUpdate = comparisonsSelected => {
  return axios.put(`/app/api/portfolios/comparisons`, {
    comparisons: comparisonsSelected,
  })
}

// CARBON REPORT

export const getSolutionCompaniesHoldings = ({
  portfolioId,
  snapshotId,
  page,
}) => {
  return axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/solution_companies?page=${page}&limit=5`
  )
}

export const getBudgetProjections = ({
  portfolioId,
  snapshotId,
  page,
  limit = 10,
  sort_field,
  sort_dir,
  projection,
}) => {
  return axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/budget_projections?${qs.stringify(
      { page, limit, sort_field, sort_dir, projection }
    )}`
  )
}

export const getCarbonTaxRates = ({
  portfolioId,
  snapshotId,
  page,
  limit = 20,
  sort_field,
  sort_dir,
}) => {
  return axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/carbon_tax_rates?${qs.stringify(
      { page, limit, sort_field, sort_dir }
    )}`
  )
}

export const getLeadersLaggards = ({
  portfolioId,
  snapshotId,
  page,
  category,
  sort,
}) => {
  console.log(sort)
  return axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/leaders_laggards?category=${category}&page=${page}&limit=20&sort_field=${sort.sort_field}&sort_dir=${sort.sort_dir}`
  )
}

export const getSectorAllocations = ({
  portfolioId,
  snapshotId,
  page,
  limit = 10,
  sort_field,
  sort_dir,
}) => {
  return axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/sector_allocations?${qs.stringify(
      { page, limit, sort_field, sort_dir }
    )}`
  )
}

export const getCarbonOwnership = ({
  portfolioId,
  snapshotId,
  page,
  limit = 10,
  sort_field,
  sort_dir,
}) => {
  return axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/holding_ownership?${qs.stringify(
      { page, limit, sort_field, sort_dir }
    )}`
  )
}

export const getRenewableEnergy = ({
  portfolioId,
  snapshotId,
  page,
  limit = 20,
  sort_field,
  sort_dir,
}) => {
  return axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/renewable_energy?${qs.stringify(
      { page, limit, sort_field, sort_dir }
    )}`
  )
}

export const getSbti = ({
  portfolioId,
  snapshotId,
  page,
  limit = 10,
  sort_field,
  sort_dir,
  category,
}) => {
  return axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/sbti?${qs.stringify(
      { page, limit, sort_field, sort_dir, committment: category }
    )}`
  )
}

export const getPowerCompanyTransitions = ({
  portfolioId,
  snapshotId,
  page,
  limit = 10,
  sort_field,
  sort_dir,
}) => {
  return axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/power_company_transitions?${qs.stringify(
      { page, limit, sort_field, sort_dir }
    )}`
  )
}

export const getCarbonReportSummary = ({ portfolioId, snapshotId }) => {
  return axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/carbon_report_summary`
  )
}

export const getPortfolioUploadDetails = () => {
  return axios.get('/app/api/portfolios/snapshot_uploads/new')
}

export const postPortfolioUpload = uploadForm => {
  return axios.post('/app/api/portfolios/snapshot_uploads', uploadForm)
}

export const putToggleFavorite = (portfolioId, isFavorite) => {
  return axios.put(`/app/api/portfolios/${portfolioId}/toggle_favorite`, {
    is_favorite: isFavorite,
  })
}

export const getDropdownOptions = ({ portfolioId, snapshotId, query }) => {
  return axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/utilities/dropdown_options?${qs.stringify(
      query
    )}`
  )
}

// PAI
export const getPolicyStatements = (portfolioId, snapshotId) => {
  return axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/pai/policies`
  )
}

export const savePolicyStatements = (
  portfolioId,
  snapshotId,
  policyStatements
) => {
  return axios.put(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/pai/policies`,
    objectToFormData(policyStatements)
  )
}

export const getPaiIndicators = (portfolioId, snapshotId, query = {}) => {
  return axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/pai/indicators?${objectToURLParams(
      query
    )}`
  )
}

export const getIndicatorExplanationActions = (
  portfolioId,
  snapshotId,
  indicatorId
) => {
  return axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/pai/indicators/${indicatorId}/explanations_actions`
  )
}

export const updateIndicatorExplanationActions = (
  portfolioId,
  snapshotId,
  indicatorId,
  explanationDetails
) => {
  return axios.put(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/pai/indicators/${indicatorId}/explanations_actions`,
    objectToFormData(explanationDetails)
  )
}

export const getPaiEntities = (portfolioId, snapshotId, query = {}) => {
  return axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/pai/holdings?${objectToURLParams(
      query
    )}`
  )
}

export const getPaiEntity = (
  portfolioId,
  snapshotId,
  holdingId,
  query = {}
) => {
  return axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/pai/holdings/${holdingId}?${objectToURLParams(
      query
    )}`
  )
}

export const updatePaiEntityIndicator = (
  portfolioId,
  snapshotId,
  holdingId,
  indicators
) =>
  axios.put(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/pai/holdings/${holdingId}`,
    {
      indicator_data: indicators.map(formatObjectCasing),
    }
  )

export const getPaiOverview = (portfolioId, snapshotId) =>
  axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/pai`
  )

export const getSFDRFundConfiguration = (portfolioId, snapshotId) =>
  axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/sfdr/fund_configuration`
  )

export const updateSFDRFundConfiguration = (
  portfolioId,
  snapshotId,
  fundConfiguration
) =>
  axios.put(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/sfdr/fund_configuration`,
    {
      fund_configuration: objectToSnakeCase(fundConfiguration),
    }
  )

export const getSFDRDisclosures = (portfolioId, snapshotId, options) =>
  axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/sfdr/disclosures?${qs.stringify(
      options
    )}`
  )

export const createSFDRDisclosure = (portfolioId, snapshotId, disclosure) =>
  axios.post(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/sfdr/disclosures`,
    {
      disclosure,
    }
  )

export const publishSFDRDisclosure = (portfolioId, snapshotId, disclosure) => {
  const { id, publish_date, additional_info, report_url } = disclosure
  return axios.put(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/sfdr/disclosures/${id}/publish`,
    {
      disclosure: {
        publish_date,
        additional_info,
        report_url,
      },
    }
  )
}

export const getSFDRDisclosureForms = (portfolioId, snapshotId, disclosureId) =>
  axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/sfdr/disclosures/${disclosureId}/forms`
  )

export const saveSFDRAnswer = (
  portfolioId,
  snapshotId,
  disclosureId,
  answers,
  questionnaireId
) => {
  return axios.put(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/sfdr/disclosures/${disclosureId}/answer`,
    {
      answers,
      questionnaire_id: questionnaireId,
    }
  )
}

export const getHoldingsData = (portfolioId, snapshotId, options) =>
  axios.get(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/sfdr/holdings_data?${qs.stringify(
      { page: 1, limit: 10, ...options }
    )}`
  )

export const updateHoldingsData = (
  portfolioId,
  snapshotId,
  parent_type,
  parent_id,
  target_type,
  target_id,
  data = {}
) => {
  return axios.put(
    `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/sfdr/holdings_data`,
    {
      parent_type,
      parent_id,
      target_type,
      target_id,
      ...data,
    }
  )
}
