import qs from 'query-string'

/**
 * Builds valid query string
 *
 * @param {*} page The current page
 * @param {*} sort The sort field and sort direction
 * @param {*} filters Query parameters -> e.g. { sector: 'utilities' }
 * @param {*} searchVal Search value
 *
 * @returns Valid query string
 */
export const buildQueryString = ({ page, sort, filters, searchQuery }) => {
  const query = { page, ...filters }

  if (searchQuery) query['search_query'] = searchQuery

  if (sort?.field && sort?.dir) {
    query['sort_field'] = sort.field
    query['sort_dir'] = sort.dir
  }

  return `?${qs.stringify(query)}`
}
