import React  from 'react';
import styled from 'styled-components';
import SectionWrapper from '../../../../../components/styleguide/SectionWrapper';
import { APP_COLOR_PRIMARY, FONT_COLOR_PRIMARY  } from '../../../../../styles/colors';
import { Modal } from 'react-bootstrap';


export const NotesHeader = styled(SectionWrapper)`
  border-top: 1px solid #ddd;
  h4 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
    color: #666;
  };
  
  i {
    color: ${APP_COLOR_PRIMARY};
    margin-right: 5px;
    &.downward {
      transform: rotate(90deg);
      transition: transform .1s linear;
    };
  };

  a {
    font-weight: normal;
    color: ${APP_COLOR_PRIMARY};
    &.add-note {
        font-size: 0.84rem;
        font-weight: normal;
        color: ${APP_COLOR_PRIMARY};
        &:hover {
            font-size: 0.84rem;
            text-decoration: underline;
            cursor: pointer;
            color: ${APP_COLOR_PRIMARY};
        };
    };
  };

  div {
    padding:1rem;
  }

`

export const NotesWrapper = styled.div`
    border-top: 1px solid #ddd;
    flex:1;
    display:flex;
    flex-direction:column;

    &.hidden {
        display: none;
        border-top: none;
    }

    .note {
        min-height: 2rem;
        margin: 5px 0 10px 10px;
        padding: 10px;
        font-size: 0.90rem;
        border-radius: 10px;
    };

    .byline {
        display: block;
        text-align: right;
        font-style: italic;
        font-size: 0.8rem;
    }
`

export const ActionLinkCustomForm = styled.a`
        color: ${APP_COLOR_PRIMARY} !important;
        font-size:15.5px;
        &:hover {
            color: ${APP_COLOR_PRIMARY};
            text-decoration: underline;
            cursor: pointer;
        }
    }
`

export const HoldingHeaderWrapper = styled.div`
  display:flex;
  align-items:center;
  justify-content : space-between;
  marginTop: 1.25rem;
  marginBottom: 0.5rem;
  padding: 40px;
  padding-bottom:0;
  & > *: {
    margin-right : 10px;
  }
`

export const HoldingTitle = styled.div`
  padding-right: 20;
  max-width: 300;
  font-size: 36px;
  font-weight: bold;
  color: ${FONT_COLOR_PRIMARY};
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const HoldingTitleWrapper = styled.div`
  vertical-align: top,
  display: inline-block,
  & > *: {
    vertical-align: top
  }
`

export const CustomMetricDialog = styled(Modal)`

  .modal-content{
    width:700px;
    font-size:16px;
    margin-top:35px;
    min-height:150px
  }

  .modal-header {
    font-size: 26px;
  }

  .modal-footer {
    border-top: none;
    margin-top: 50px;
    justify-content: center;
  }
`;
