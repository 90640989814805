import React from 'react';
import numeral from 'numeral';
import ChartLegend from '../charts/ChartLegendPDF';
import SubsidiesChart from '../charts/SubsidiesChartPDF';

const SubsidiesViolationsSectionPDF = ({ subsidies, benchmark_subsidies, violations, benchmark_violations, benchmarkName, targetType }) => {
  if(!subsidies || !violations) return null;
  const subsidiesBenchmarkComparison = Math.round((subsidies.total - benchmark_subsidies?.total)/subsidies.total * 100);
  const violationsBenchmarkComparison = Math.round((violations.total - benchmark_violations?.total)/violations.total * 100);

  const legend = [
      { value: `$${numeral(subsidies.total).format('a0').toUpperCase()}`, benchmarkComparison: subsidiesBenchmarkComparison, color: '#002fa7', unit: 'last 10 years', label: 'Subsidies' },
      { value: `$${numeral(violations.total).format('a0').toUpperCase()}`, benchmarkComparison: violationsBenchmarkComparison, color: '#8aabff', unit: 'last 10 years', label: 'Penalties & Fines' }
  ];

  return (
    <section className="subsidies-violations">
      <h2>Corporate Subsides, Penalties & Fines</h2>
      <ChartLegend data={legend} />
      <SubsidiesChart
        className="subsidies-chart"
        color="#002fa7"
        data={subsidies.timeline}
        benchmarkData={benchmark_subsidies?.timeline}
        benchmarkName={benchmarkName}
        targetType={targetType}
      />
      <SubsidiesChart className="subsidies-chart"
        color="#8aabff"
        data={violations.timeline}
        benchmarkData={benchmark_violations?.timeline}
        benchmarkName={benchmarkName}
        targetType={targetType}
      />
      <p className="label disclaimer right">Source: Good Jobs First Subsidy Tracker/Violation Tracker</p>
    </section>
  );
}

export default SubsidiesViolationsSectionPDF;
