import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { FONT_COLOR_SECONDARY } from '../../../../../../styles/colors'

const StyledIcon = styled.i`
  margin-left: 3px;
`

const StyledNav = styled.nav`
  margin-left: 64px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: baseline;
  justify-content: flex-start;
  text-transform: uppercase;
  font-size: 14px;

  a {
    &.disabled {
      color: ${FONT_COLOR_SECONDARY};
      text-decoration: underline;
      pointer-events: none;
    }
  }
`

export const PaiTabNav = ({ activeTab, portfolioId, snapshotId }) => {
  const TABS_LIST = [
    {
      key: 'dash',
      text: 'Overview',
      link: `/app/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/pai/overview`,
    },
    {
      key: 'pai_policy_statements',
      text: 'Policy Statements',
      link: `/app/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/pai/policy_statements`,
    },
    {
      key: 'pai_indicators',
      text: 'Indicators',
      link: `/app/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/pai/indicators`,
    },
    {
      key: 'pai_entities',
      text: 'Entities',
      link: `/app/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/pai/entities`,
    },
    {
      key: 'pai_downloads',
      text: 'Downloads',
      link: `/app/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/pai/downloads`,
    },
    {
      key: 'pai_documentation',
      text: 'Documentation',
      link: `https://help.yves.blue/en/articles/6820667-principal-adverse-impacts-pai-at-yvesblue`,
      external: true,
    },
  ]

  return (
    <StyledNav>
      {TABS_LIST.map(({ key, link, text, external }) => (
        <a
          key={key}
          href={link}
          target={external ? '_blank' : '_self'}
          className={activeTab === key ? 'disabled' : ''}
          rel='noreferrer'
        >
          {text}
          {external && <StyledIcon className='fal fa-external-link' />}
        </a>
      ))}
    </StyledNav>
  )
}

PaiTabNav.propTypes = {
  activeTab: PropTypes.string.isRequired,
  portfolioId: PropTypes.string,
  snapshotId: PropTypes.string,
}
