import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import ErrorPage from '../../../../components/styleguide/ErrorPage'
import { getSectorAllocations } from '../../../../repositories/portfolio_repository'
import {
  FilledExplainerLink,
  LinkIcon,
} from '../../../../yb_components/styleguide/links/Link.styles'
import Pagination from '../../../../yb_components/styleguide/pagination/Pagination'
import SpinnerContainer from '../../../../yb_components/styleguide/spinners/SpinnerContainer'
import CarbonReportCard from './components/CarbonReportCard'
import CarbonReportLink from './components/CarbonReportLink'
import {
  CARBON_REPORT_TEXTS,
  DEFAULT_SORT,
  SECTOR_ALLOCATIONS_TABLE_HEADER,
} from './constants'
import { formatSort, getPdfSortFieldText } from './helpers'
import { CarbonReportTable, ModuleDescription, renderTh } from './styles'

const StyledSectorNameDiv = styled.div`
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
`

const CustomExplainerLink = styled(FilledExplainerLink)`
  float: right;
`

export const loadSectorAllocations = ({
  snapshotId,
  portfolioId,
  setError,
  setIsLoading,
  setData,
  dispatch,
  page,
  sort,
  setTotalPages,
  setTotals,
}) => {
  getSectorAllocations({ snapshotId, portfolioId, page, ...sort })
    .then(res => {
      setIsLoading(false)
      setData(res?.data?.sector_allocations)
      setTotals(res?.data?.totals)
      setTotalPages(res?.data?.total_pages)
      dispatch({
        type: 'sector-allocation-loaded',
        payload: {
          data: res?.data?.sector_allocations,
          totals: res?.data?.totals,
          sort: sort,
        },
      })
    })
    .catch(err => {
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

const SectorAllocation = ({
  pdf,
  snapshotId,
  portfolioId,
  impactBenchmark,

  defaultData,
  defaultTotals,
  defaultSort,
  dispatch,

  title = CARBON_REPORT_TEXTS?.sectorAllocation.title,
  description = CARBON_REPORT_TEXTS?.sectorAllocation.description,
}) => {
  /* Hooks */
  const [isLoading, setIsLoading] = useState(!pdf)
  const [error, setError] = useState()
  const [data, setData] = useState(defaultData || [])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totals, setTotals] = useState(defaultTotals || {})
  const [sort, setSort] = useState(defaultSort || DEFAULT_SORT)

  useEffect(
    () =>
      !pdf
        ? loadSectorAllocations({
            snapshotId,
            portfolioId,
            setError,
            setIsLoading,
            setData,
            dispatch,
            page,
            sort,
            setTotalPages,
            setTotals,
          })
        : () => {},
    [page, sort]
  )

  useEffect(() => {
    if (defaultData) setData(defaultData)
    if (defaultTotals) setTotals(defaultTotals)
  }, [defaultData, defaultTotals])

  const formatValue = (
    value,
    symbol = '',
    emptySymbol = '—',
    precision = 2
  ) => {
    let fValue = parseFloat(value)

    const formatNumericValue = v => {
      if (precision) return v?.toFixed(precision)
      return v
    }

    fValue =
      !isNaN(fValue) && fValue != 0
        ? `${parseFloat(formatNumericValue(fValue)).toLocaleString()}${symbol}`
        : emptySymbol
    return fValue
  }

  /* Render */
  const renderRow = (elem, boldName = false) => {
    const { target, benchmark, sector, combined_effect } = elem
    return (
      <tr key={sector?.id}>
        <td className='name'>
          {sector?.id != null ? (
            <CarbonReportLink
              title={title}
              pdf={pdf}
              name={sector?.name}
              href={`/app/sectors/${sector?.id}`}
            />
          ) : (
            <StyledSectorNameDiv bold={boldName}>
              {sector?.name}
            </StyledSectorNameDiv>
          )}
        </td>
        <td className='number'>
          {formatValue(target?.weight * 100, '%', '0.00%')}
        </td>
        <td className='number'>
          {formatValue(benchmark?.weight * 100, '%', '0.00%')}
        </td>
        <td className='empty' />
        <td className='number'>{formatValue(target?.carbon_to_revenue)}</td>
        <td className='number'>{formatValue(benchmark?.carbon_to_revenue)}</td>
        <td className='empty' />
        <td className='number'>{formatValue(target?.sector_allocation)}</td>
        <td className='number'>{formatValue(target?.company_selection)}</td>
        <td className='number'>{formatValue(combined_effect)}</td>
        <td className='number'>{formatValue(target?.total_effect)}</td>
      </tr>
    )
  }

  const renderRows = () => {
    let result = data?.map(elem => renderRow(elem))
    result.push(renderRow({ ...totals, sector: { name: 'Total' } }, true))
    return result
  }

  const benchmarkName = impactBenchmark?.name?.toUpperCase() || 'BENCHMARK'
  const formattedPdfSortField = useMemo(
    () =>
      getPdfSortFieldText(SECTOR_ALLOCATIONS_TABLE_HEADER(), defaultSort, pdf),
    [defaultSort, pdf]
  )

  return (
    <CarbonReportCard
      title={title}
      pdf={pdf}
      pdfSubTitle={{ label: `(Top 10 Holdings By ${formattedPdfSortField})` }}
    >
      {!pdf && (
        <CustomExplainerLink
          pdf={pdf}
          href='http://help.yves.blue/en/articles/6698762-carbon-report-portfolio-summary-on-yvesblue#h_53486c7521'
          target='_blank'
        >
          <LinkIcon className='fa fa-question' />
        </CustomExplainerLink>
      )}
      <br />

      <ModuleDescription pdf={pdf}>{description}</ModuleDescription>

      {error ? (
        <ErrorPage code={error} message={error == 404 ? 'NOT FOUND' : ''} />
      ) : (
        <>
          {isLoading && <br />}
          <SpinnerContainer isLoading={isLoading}>
            <CarbonReportTable pdf={pdf}>
              <thead>
                <tr>
                  <th className='name'></th>
                  <th colSpan={2} className='multi-column-header'>
                    WEIGHT
                    <br />
                    (%)
                  </th>
                  <th className='empty' />
                  <th colSpan={2} className='multi-column-header'>
                    CARBON TO REVENUE <br /> (TCO<sub>2</sub>E / mUSD)
                  </th>
                  <th className='empty' />
                  <th colSpan={4} className='multi-column-header'>
                    ATTRIBUTION ANALYSIS <br /> (TCO<sub>2</sub>E / mUSD)
                  </th>
                </tr>
                <tr>
                  {SECTOR_ALLOCATIONS_TABLE_HEADER(benchmarkName).map(elem =>
                    renderTh(
                      elem,
                      sort,
                      (sortField, sortDir) =>
                        setSort(formatSort(sortField, sortDir)),
                      pdf
                    )
                  )}
                </tr>
              </thead>
              <tbody>{renderRows()}</tbody>
            </CarbonReportTable>
            {!pdf && (
              <Pagination
                max={totalPages}
                selected={page}
                onChange={e => setPage(e)}
              />
            )}
          </SpinnerContainer>
        </>
      )}
    </CarbonReportCard>
  )
}

export default SectorAllocation
