import React, { useState, useEffect} from 'react';
import { getAllIndustryGroups } from '../../repositories/sectors_repository';
import withErrorBoundary from '../../hocs/withErrorBoundary';
import Link from '../../components/styleguide/Link';
import { SectorTitle, StyledContainer } from './styles';

const loadIndustryGroups = (setIndustryGroups) => {
  getAllIndustryGroups().then((response) => {
    setIndustryGroups(response.data);
  }).catch((error) => {
    console.log(error)
  })
};

const SectorIndex = () => {
  const [ industryGroups, setIndustryGroups ] = useState([]);
  useEffect(() => loadIndustryGroups(setIndustryGroups), []);

  return(
    <StyledContainer>
      <SectorTitle> Business Sectors </SectorTitle>
      <div>
        {
          industryGroups.map((industryGroup) => {
            return(
              <div>- <Link href={`/app/sectors/${industryGroup.id}`}> {industryGroup.name}</Link> ({industryGroup.id }) </div>
            )
          })
        }
      </div>
    </StyledContainer>
  )
};

export default withErrorBoundary(SectorIndex);
