import propTypes from 'prop-types'
import styled from 'styled-components'

import AddButton from '../../../components/styleguide/AddButton'
import SectionTitle from '../../../components/styleguide/SectionTitle'
import {
  APP_COLOR_PRIMARY,
  BORDER_COLOR_TERTIARY,
  FONT_COLOR_SECONDARY,
} from '../../../styles/colors'

export const StyledH1 = styled.div`
  margin: 0;
  display: inline-block;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 700;
`

export const StyledButtonContainer = styled.div`
  float: left;
`

export const StyledHeaderSection = styled.div`
  ${props => props.margin && `margin: ${props.margin};`}
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${props => props.padding && `padding: ${props.padding};`}
`

export const StyledSearchBarContainer = styled.div`
  .search-bar {
    width: 100%;
    height: 50px;

    background-color: #fff;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    border: 5px solid ${BORDER_COLOR_TERTIARY};

    > input {
      font-size: 14px;
    }

    > img {
      width: 20px;
      height: 20px;
    }
  }
`

export const StyledPaginatorContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`

const HideWrapper = styled.div`
  width: 100%;
`
const SelectWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  width: 100%;
  ${props => props.customStyle && props.customStyle}
`

const ShowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const SelectWrapperInner = styled.div`
  display: flex;
  width: 100%;
  margin-right: ${props => props.marginRight ? props.marginRight : '10px'};
  margin-bottom: 10px;
  ${ props => props.zIndex && `
    position: relative;
    z-index: ${props.zIndex};
  `}
`



const ChipWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`

const FilterChip = styled.div`
  background-color: ${props => props.bgColor};
  color: ${props => props.fontColor};
  margin-left: 8px;
  margin-bottom: 10px;
  padding-top: 5px;
  display: flex;
  white-space: nowrap;
  font-size: 0.88rem;
  line-height: 1.44;
  height: 1.84rem;
  border-radius: 12.5px;
  font-weight: bold;
`

const XButton = styled.div`
  border: 1px solid transparent;
  outline: none;
  -webkit-appearance: none;
  font-weight: bold;
  margin-right: 2px;
  padding: 0px 10px;

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    height: 14px;
    margin-top: 2px;
    width: 2px;
    background-color: ${FONT_COLOR_SECONDARY};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`
const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
`

const Header = styled.h1`
  font-size: 14px;
  margin-top: 3px;
  margin-left: 16px;
  color: #2e384d;
  font-weight: bold;
`

const SelectLabel = styled.div`
  margin-left: ${props => props.marginLeft || '13px'};
  margin: ${props => props.margin || '0 0 5px 13px'};
  font-weight: ${props => props.fontWeight};
  color: ${props => props.color || FONT_COLOR_SECONDARY};
  letter-spacing: 0;
  font-size: 12px;
`

const SelectLabelWrapper = styled.div`
  width: 100%;
  ${props => props.margin && `margin: ${props.margin}`}
`

const AddButtonShow = styled(AddButton)`
  float: right;
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
`

const AddButtonHide = styled(AddButton)`
  margin: 10px;
  margin-right: 35px;
`

const SectionTitleResearch = styled(SectionTitle)`
  margin-top: 32px;
  font-size: 28;
`

let tableStyles = {
  textAlign: 'left',
  tdPadding: '0.7rem',
  tdPaddingLeft: '0px',
  tableWidth: '95%',
  tableMargin: 'auto',
  out: false,
}

const toggleContainerLook = {
  marginTop: '20px',
  marginLeft: '15px',
  marginBottom: '10px',
}

const style = {
  mainContent: {
    margin: 'auto',
    width: '95%',
  },

  researchSearch: {
    marginTop: '32px',
    width: '440px',
    height: '48px',
    backgroundColor: '#FFF',
    float: 'right',
  },

  mainContainer: {
    maxWidth: 'none',
    height: '100vh',

    textAlign: 'left',

    '& .ESG, & .SUMMARY, & .CARBON': {
      '& tr': {
        cursor: 'pointer',
      },

      textAlign: 'left',

      '& td:nth-child(1)': {
        width: '20%',
        minWidth: '120px',
        maxWidth: '120px',
        textAlign: 'left',
      },

      '& td:nth-child(2)': {
        width: '20%',
        minWidth: '120px',
        maxWidth: '120px',
        textAlign: 'left',
      },

      '& td:nth-child(3)': {
        width: '20%',
        minWidth: '120px',
        maxWidth: '120px',
        textAlign: 'left',
      },

      '& th': {
        '& span': {
          whiteSpace: 'nowrap',
        },
      },
    },

    '& .SUMMARY': {
      '& td:nth-child(5)': {
        paddingRight: '20px',
      },
    },

    '& .SDG': {
      whiteSpace: 'nowrap',
      textAlign: 'left',

      '& td:nth-child(1)': {
        width: '25%',
        minWidth: '120px',
        maxWidth: '120px',
        whiteSpace: 'normal',
      },

      '& td:nth-child(2)': {
        width: '5%',
        minWidth: '0px',
        maxWidth: '150x',
      },
      '& td:nth-child(3)': {
        width: '5%',
        minWidth: '0px',
        maxWidth: '150px',
      },
    },

    '& .research-paginator': {
      float: 'right',
      marginRight: '18px',
      marginBottom: '15px',
    },

    '& .SUMMARY': {
      '& td:nth-child(3)': {
        color: APP_COLOR_PRIMARY,
      },
      '& th:nth-child(4)': {
        paddingRight: '0.86rem',
      },
    },
  },

  tableContainer: {
    backgroundColor: 'white',
    overflow: 'auto',
  },
}

const filterStyle = {
  mainContent: {
    maxWidth: 'none',
    width: '100%',
  },

  mainContainer: {
    // border: '1px solid #D8D9DD',
    // margin: '15px 0px',
    // display: 'flex',
    // alignItems: 'center',
    // backgroundColor: BG_COLOR_WHITE,
  },
}

FilterChip.propTypes = {
  bgColor: propTypes.string,
  fontColor: propTypes.string,
}

SelectLabel.propTypes = {
  color: propTypes.string,
}

export {
  AddButtonHide,
  AddButtonShow,
  ChipWrapper,
  FilterChip,
  filterStyle,
  Header,
  HideWrapper,
  SectionTitleResearch,
  SelectLabel,
  SelectLabelWrapper,
  SelectWrapper,
  SelectWrapperInner,
  ShowWrapper,
  style,
  tableStyles,
  toggleContainerLook,
  TopWrapper,
  XButton,
}
