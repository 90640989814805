import styled from 'styled-components'

export const StyledTable = styled.table`
  th,
  td {
    &:first-child {
      text-align: left;
    }
  }
  th {
    padding: 6px 8px;
    min-width: 94px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #555555;
    background-color: #dddddd;
  }
  td {
    padding: 6px 8px;
    height: 26px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #979797;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    a {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  tr {
    background-color: #fff;
    &:nth-child(even) {
      background: #f1f1f1;
    }
  }
`
