import { Button, Modal } from 'react-bootstrap'
import styled from 'styled-components'

import CardTitle from '../../components/styleguide/CardTitle'
import SectionWrapper from '../../components/styleguide/SectionWrapper'
import {
  APP_COLOR_PRIMARY,
  APP_COLOR_PRIMARY_DARK,
  FONT_COLOR_PRIMARY,
} from '../../styles/colors'

export const MiddleSpan = styled.span`
  margin: 0px 6px 0px 6px;
`

export const ModuleWrapper = styled.div`
  border: 1px solid #d8d9dd;
  margin: ${props => props.margin || '15px 0px'};
  padding: ${props => props.padding};
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: ${props => props.width};
  height: ${props => props.height};
`

export const ModuleCardTitle = styled(CardTitle)`
  margin: 5px 0px 5px 10px;
`

export const FundHeaderWrapper = styled.div`
  margin: 20px 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const FundTitle = styled.div`
  margin: 0;
  padding-right: 20;
  font-size: 36px;
  font-weight: bold;
  color: ${FONT_COLOR_PRIMARY};
`

export const FundTitleWrapper = styled.div`
  vertical-align: top;
  display: inline-block;
  > * {
    vertical-align: top;
  }
`

export const ExportButtonHeaderWrapper = styled.div``

export const AddPipelineDialog = styled(Modal)`
  .modal-content {
    width: 700px;
    font-size: 16px;
    margin-top: 35px;
    min-height: ${props => props.minHeight || '500px'};
  }

  .modal-header {
    font-size: 26px;
  }

  .modal-footer {
    border-top: none;
    margin-top: 50px;
    justify-content: center;
  }
`

export const DeletePipelineDialog = styled(Modal)`
  .modal-content {
    width: 700px;
    font-size: 16px;
    margin-top: 35px;
    min-height: 150px;
  }

  .modal-header {
    font-size: 26px;
  }

  .modal-footer {
    border-top: none;
    margin-top: 50px;
    justify-content: center;
  }
`

export const ConfirmDialog = styled(Modal)`
  .modal-content {
    width: 700px;
    font-size: 16px;
    margin-top: 35px;
    min-height: 200px;
  }

  .modal-header {
    font-size: 26px;
  }

  .modal-footer {
    border-top: none;
    margin-top: 50px;
    justify-content: center;
  }
`

export const TableButton = styled.span`
  color: #002fa7;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`

export const FundTableWrapper = styled.div`
  margin: ${props => props.margin || '0px 0px 10px 0px'};
  table {
    min-height: ${props => props.minHeight};
    table-layout: fixed;
    thead {
      background-color: #d8d8d8;
      th {
        text-align: left;
        padding: 8px 8px 8px 12px;
        :nth-child(2) {
          width: ${props => props.secondChildWidth || 'auto'};
        }
        margin-right: 80px;
        > span {
          color: #333;
          display: flex;
          justify-content: flex-start;
        }
        :nth-child(-n + 4) {
          > span {
            justify-content: flex-start;
          }
        }
        text-align: left;
      }
    }
    tbody {
      text-align: left;
      td {
        // white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-left: 18px;
        text-align: left !important;
      }
    }
  }
`

export const StyledLink = styled.span`
  color: ${APP_COLOR_PRIMARY};
  font-weight: bold;
  cursor: pointer;

  :hover {
    color: ${APP_COLOR_PRIMARY} !important;
    text-decoration: underline !important;
  }
`

export const LinkToggle = styled(StyledLink)`
  border-right: 1px solid black;
  padding-right: 5px;
  margin-right: 5px;
  color: ${props => (props.active ? 'black' : null)};
`

export const HoldingPaginatorDLWrapper = styled(SectionWrapper)`
  position: relative;
  justify-content: center;
  .download-wrapper {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .button-wrapper {
    position: absolute;
    top: 12px;
    left: 5px;
  }
`
export const SqrButton = styled(Button)`
  border-radius: 0;
  border: 1px solid ${APP_COLOR_PRIMARY_DARK};
  width: ${props => props.width};
  white-space: no-wrap;
  padding: ${props => props.padding};
`

export const ActionLink = styled.div`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`
