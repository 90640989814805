import propTypes from 'prop-types'
import React, { forwardRef } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import styled from 'styled-components'

const StyledList = styled.ul`
  --link-color: #838383;
  --link-color-active: #333333;

  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  list-style-type: none;

  li {
    padding: 0 5px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--link-color);
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;

    &.active {
      border-bottom: 3px solid black;
    }
  }
  .navbar-target {
    padding: 12px 0;
    height: 40px;
    position: relative;
    display: block;
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
    color: var(--link-color);
    background-color: transparent;
    text-decoration: none;
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;

    &:after {
      content: attr(title);
      height: 0;
      display: block;
      overflow: hidden;
      visibility: hidden;
      font-weight: 700;
    }
    &:hover,
    &:active {
      color: var(--link-color-active);
    }
    &:focus-visible {
      outline: none;
      background-color: rgb(0, 0, 0, 0.1);
      color: var(--link-color-active);
    }

    &.has-dropdown {
      padding: 12px 14px 12px 0;
      outline: none;
      box-shadow: none;
      border: none;
    }
    svg {
      position: absolute;
      right: 0;
      top: 18px;
      transition: fill 0.15s ease-in-out;
    }

    &.active {
      font-weight: 700;
      color: var(--link-color-active);
    }
  }

  .dropdown.show {
    .navbar-target {
      color: var(--link-color-active);
    }
  }
`
const DropdownMenu = styled(Dropdown.Menu)`
  --dropdown-border-color: #dddddd;
  --link-color: #333333;
  --link-color-highlight: #d6eeff;

  padding: 6px 0;
  min-width: 224px;
  inset: -10px auto auto -10px !important;
  border: 1px solid var(--dropdown-border-color);
  box-shadow: 0px 4px 12px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  background: #ffffff;

  a {
    padding: 4px 10px;
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    line-height: 16px;
    color: var(--link-color);

    &:hover,
    &:focus,
    &:active {
      color: var(--link-color);
      background-color: var(--link-color-highlight);
    }
    &:focus-visible {
      outline: none;
    }
  }
  .dropdown-divider {
    margin: 6px 0;
    border-top-color: var(--dropdown-border-color);
  }
`

export const NavbarLinks = ({ links }) => {
  // eslint-disable-next-line react/display-name
  const DropdownButton = forwardRef(({ children, onClick, active }, ref) => (
    <button
      className={
        active
          ? 'navbar-target has-dropdown active'
          : 'navbar-target has-dropdown'
      }
      title={children}
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
      <svg width='8' height='5' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4 4.666a.664.664 0 0 1-.472-.195L.195 1.138a.666.666 0 1 1 .943-.943L4 3.058 6.862.196a.666.666 0 1 1 .943.942L4.472 4.472A.665.665 0 0 1 4 4.666Z'
          fill='currentColor'
        />
      </svg>
    </button>
  ))

  return (
    <StyledList>
      {links.map(
        ({ title, href, active, primaryLinks, secondaryLinks }, index) => (
          <li key={index} className={active ? 'active' : ''}>
            {href != null ? (
              <a
                className={active ? 'navbar-target active' : 'navbar-target'}
                href={active ? null : href}
                title={title}
              >
                {title}
              </a>
            ) : (
              <Dropdown data-cy='navbar_dropdown'>
                <Dropdown.Toggle
                  as={DropdownButton}
                  active={active}
                  id={`link_${index}`}
                >
                  {title}
                </Dropdown.Toggle>
                <DropdownMenu data-cy='navbar_dropdown_menu'>
                  {primaryLinks &&
                    primaryLinks.map(({ name, href }, index) => (
                      <Dropdown.Item key={index} href={href}>
                        {name}
                      </Dropdown.Item>
                    ))}
                  {primaryLinks &&
                    primaryLinks.length > 0 &&
                    secondaryLinks && <Dropdown.Divider />}
                  {secondaryLinks &&
                    secondaryLinks.map(({ name, href }, index) => (
                      <Dropdown.Item key={index} href={href}>
                        {name}
                      </Dropdown.Item>
                    ))}
                </DropdownMenu>
              </Dropdown>
            )}
          </li>
        )
      )}
    </StyledList>
  )
}

NavbarLinks.propTypes = {
  links: propTypes.array.isRequired,
}
