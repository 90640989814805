export const mapEventsToState = (events) => {
  return events.map((event) => ({
    id: event.id,
    kind: event.kind,
    action: event.action,
    context: event.context,
    data: event.data,
    target: event.target,
    target_type: event.target_type,
    target_id: event.target_id,
    created_at: event.created_at,
    target_exists: event.target_exists,
    parent_target_exists: event.parent_target_exists
  }));
};
