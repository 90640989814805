import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import BreakLine from '../../../../../components/styleguide/BreakLine'
import Button from '../../../../../components/styleguide/Button'
import DatePicker from '../../../../../components/styleguide/DatePicker'
import SectionWrapper from '../../../../../components/styleguide/SectionWrapper'
import TextArea from '../../../../../components/styleguide/TextArea'
import TextInput from '../../../../../components/styleguide/TextInput'
import WrapperColumn from '../../../../../components/styleguide/WrapperColumn'
import { CustomMetricDialog } from './styles'

const AddMetricsModal = ({
  show,
  setShowAddMetriclModal,
  postMetric,
  dataLabel,
}) => {
  const [newMetricValue, setNewMetricValue] = useState({})

  const createNewMetric = (id, value) => {
    const newNewMetricValue = { ...newMetricValue }
    newNewMetricValue[id] = value
    setNewMetricValue(newNewMetricValue)
  }

  return (
    show && (
      <CustomMetricDialog
        show={show}
        onHide={() => setShowAddMetriclModal(false)}
        data-cy={`${dataLabel}-add-metric-modal`}
      >
        <Modal.Header closeButton data-cy={`${dataLabel}-add-metric-header`}>
          <Modal.Title data-cy={`${dataLabel}-add-metric-title`}>
            Add Metric
          </Modal.Title>
        </Modal.Header>
        <WrapperColumn
          styles={{ flex: '1' }}
          data-cy={`${dataLabel}-add-metric-input-wrapper`}
        >
          <WrapperColumn
            styles={{ margin: '10px 10px 5px 10px;', flex: '1' }}
            data-cy={`${dataLabel}-add-metric-input-existing-metric-wrapper`}
          >
            {/* <InputLabel data-cy={`${dataLabel}-add-metric-input-existing-metric-label`} label="Existing Metric"/>
                        <Select
                            data-cy={`${dataLabel}-add-metric-input-existing-metric-select`}
                            styles={
                                {
                                    container: (provided) => ({...provided, width:'98%', marginBottom:'16.5px'}),
                                    input: (provided) => ({...provided, paddingTop:'8px', paddingBottom:'4.75px'})
                                }
                            }
                            onChange={(selected) => {createNewMetric("metric_name",selected)}}
                            id="existing_metric"
                            options={[]}
                            // defaultValue={}
                        /> */}
          </WrapperColumn>
          {/* <SectionWrapper flex="1" justify="center" margin="0px 10px 0px 10px;"                             data-cy={`${dataLabel}-add-metric-input-or`}>
                        <span>-- OR --</span>
                    </SectionWrapper> */}
          <SectionWrapper
            flex='1'
            margin='-25px 10px 5px 10px;'
            data-cy={`${dataLabel}-add-metric-input-existing-metric-name-wrapper`}
          >
            <TextInput
              label='Metric Name'
              data-cy={`${dataLabel}-add-metric-input-existing-metric-name-input`}
              width='98%'
              id='metric_name'
              onChange={(id, value) => {
                createNewMetric(id, value)
              }}
            />
          </SectionWrapper>
          <SectionWrapper
            flex='1'
            margin='-25px 10px 0px 10px;'
            data-cy={`${dataLabel}-add-metric-input-existing-unit-wrapper`}
          >
            <TextInput
              label='Unit'
              width='30%'
              data-cy={`${dataLabel}-add-metric-input-existing-unit-input`}
              id='unit'
              onChange={(id, value) => {
                createNewMetric(id, value)
              }}
            />
          </SectionWrapper>
          <SectionWrapper
            flex='1'
            margin='-25px 10px 0px 10px;'
            data-cy={`${dataLabel}-add-metric-input-description-wrapper`}
          >
            <TextArea
              label='Description'
              width='98%'
              data-cy={`${dataLabel}-add-metric-input-existing-description-input`}
              rows={5}
              id='description'
              onChange={(id, value) => {
                createNewMetric(id, value)
              }}
            />
          </SectionWrapper>
          <SectionWrapper
            flex='1'
            data-cy={`${dataLabel}-add-metric-breakline-wrapper`}
          >
            <BreakLine data-cy={`${dataLabel}-add-metric-breakline`} />
          </SectionWrapper>
          <SectionWrapper
            flex='1'
            margin='5px 10px 5px 10px;'
            justify='space-between'
            data-cy={`${dataLabel}-add-metric-input-current_value-wrapper`}
          >
            <TextInput
              width='30%'
              type='number'
              label='Current Value'
              data-cy={`${dataLabel}-add-metric-input-value-input`}
              id='current_value'
              onChange={(id, value) => {
                createNewMetric(id, value)
              }}
            />
          </SectionWrapper>
          <SectionWrapper
            flex='1'
            margin='-25px 10px 5px 10px;'
            justify='space-between'
            data-cy={`${dataLabel}-add-metric-bottom-wrapper`}
          >
            <SectionWrapper
              flex='1'
              data-cy={`${dataLabel}-add-metric-input-target-value-wrapper`}
            >
              <TextInput
                width='60%'
                type='number'
                data-cy={`${dataLabel}-add-metric-target-value-input`}
                label='Target Value'
                id='target_value'
                onChange={(id, value) => {
                  createNewMetric(id, value)
                }}
              />
            </SectionWrapper>
            <SectionWrapper
              flex='1'
              margin='5px 0px 0px 0px'
              data-cy={`${dataLabel}-add-metric-input-target-date-wrapper`}
            >
              <DatePicker
                width='60%'
                label='Target Date'
                showYearDropdown={true}
                data-cy={`${dataLabel}-add-metric-target-date-input`}
                id='target_date'
                date={newMetricValue.target_date}
                onChange={(value, id) => {
                  createNewMetric(id, value)
                }}
              />
            </SectionWrapper>
          </SectionWrapper>
          <SectionWrapper
            margin='-25px 10px 5px 10px'
            flex='1'
            data-cy={`${dataLabel}-add-metric-input-target-value-note-wrapper`}
          >
            <TextArea
              label='Note'
              width='98%'
              id='value_note'
              data-cy={`${dataLabel}-add-metric-note-input`}
              rows={5}
              onChange={(id, value) => {
                createNewMetric(id, value)
              }}
            />
          </SectionWrapper>
          <SectionWrapper
            width='98%'
            justify='flex-end'
            margin='0px 0px 12px 0px'
          >
            <Button
              data-cy={`${dataLabel}-add-metric-cancel`}
              margin='0px 15px 0px 0px'
              width='30%'
              label='Cancel'
              onClick={() => setShowAddMetriclModal(false)}
            />
            <Button
              data-cy={`${dataLabel}-add-metric-submit`}
              width='30%'
              label='Add Metric'
              onClick={() => postMetric(newMetricValue)}
            />
          </SectionWrapper>
        </WrapperColumn>
      </CustomMetricDialog>
    )
  )
}

export default AddMetricsModal
