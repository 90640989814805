import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { OpenInNewIcon } from '../../../../../../yb_components/styleguide/icons/OpenInNewIcon'
import { getScoreText } from '../../../utils/scores_display'
import { getTcfdAccentColor, getTcfdColor } from '../../../utils/tcdf_colors'
import { Tile } from '../../tiles/Tile'
import { PeersTable } from '../peers/PeersTable'
import { MoreButton } from './MoreButton'
import { TcfdListItemNoteCard } from './notes/TcfdListItemNoteCard'
import { ScoreDisplay } from './ScoreDisplay'
import { ListItem } from './TcfdHoldingDetalListItem.styles'

export const TcfdHoldingDetailListItem = ({
  isLoading,
  open,
  openReplacePeerModal,
  row,
  riskHardcode,
  saveNote,
}) => {
  const [isOpen, setIsOpen] = useState(open)

  const onClickMore = e => {
    e.preventDefault()
    setIsOpen(!isOpen)
  }
  useEffect(() => {
    setIsOpen(open)
  }, [open])

  return (
    <ListItem>
      <div className={isOpen ? 'list-item-grid open' : 'list-item-grid'}>
        <aside>
          <h5>{riskHardcode.name}</h5>
          <div
            dangerouslySetInnerHTML={{ __html: riskHardcode.description }}
          ></div>
          <a
            className='pillar-link'
            href={riskHardcode.methodology_link}
            target='_blank'
            rel='noreferrer'
          >
            Methodology
            <OpenInNewIcon />
          </a>
          <a
            className='pillar-data-link'
            target='_blank'
            rel='noreferrer'
            href={riskHardcode.pillar_data_link}
          >
            Pillar Data
          </a>
        </aside>
        {isLoading ? (
          <Tile bgColor={'#f0f0f0'}></Tile>
        ) : (
          <Tile
            bgColor={getTcfdColor(row?.score)}
            color={getTcfdAccentColor(row?.score)}
          >
            <h5>Company Score</h5>
            <ScoreDisplay>
              <div className='value-container'>
                <span className='value'>{getScoreText(row?.score)}</span>
              </div>
              <p>{riskHardcode.score_text[parseInt(row?.score)]}</p>
            </ScoreDisplay>
          </Tile>
        )}
        {isLoading ? (
          <Tile bgColor={'#f0f0f0'}></Tile>
        ) : (
          <Tile
            bgColor={getTcfdColor(row?.sector_score)}
            color={getTcfdAccentColor(row?.sector_score)}
          >
            <h5>Sector Score</h5>
            <ScoreDisplay>
              <div className='value-container'>
                <span className='value'>{getScoreText(row?.sector_score)}</span>
              </div>
              <p>{riskHardcode.score_text[parseInt(row?.sector_score)]}</p>
            </ScoreDisplay>
          </Tile>
        )}
        {isLoading ? (
          <Tile
            bgColor={'#ffffff'}
            color={'#2E384D'}
            style={{ paddingBottom: '10px', gridColumn: '2 / 3' }}
          ></Tile>
        ) : (
          <Tile
            bgColor={'#ffffff'}
            color={'#2E384D'}
            style={{ paddingBottom: '10px', gridColumn: '2 / 3' }}
          >
            <PeersTable
              peers={row?.peers}
              risk={row?.id}
              openReplacePeerModal={openReplacePeerModal}
            />
          </Tile>
        )}
        <TcfdListItemNoteCard
          tcfdSector={riskHardcode.id}
          noteData={row?.notes}
          saveNote={saveNote}
        />
      </div>
      <MoreButton
        isOpen={isOpen}
        onClick={isLoading ? e => {} : e => onClickMore(e)}
      />
    </ListItem>
  )
}

TcfdHoldingDetailListItem.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  openReplacePeerModal: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  riskHardcode: PropTypes.object.isRequired,
}
