import { useContext, useEffect, useState } from 'react'

import { fileExists } from '../../../yb_helpers/files'
import { AppActions } from '../../../yb_stores/app_store/actions/AppActions'
import { AppContext } from '../../../yb_stores/app_store/AppStore'

const usePlaceholderBadge = badge => {
  const [state, dispatch] = useContext(AppContext)
  const [path, setPath] = useState(state?.badges[badge?.key]?.iconPath || '')

  useEffect(() => {
    if (!path?.length) {
      const badgePath = `/badges/badge-${badge?.key}.svg`
      fileExists(badgePath, exists => {
        let newPath = ''
        if (exists) newPath = badgePath
        else {
          newPath = `/badges/badge-${
            badge?.sentiment || 'positive'
          }-placeholder.svg`
        }

        setPath(newPath)
      })
    }
  }, [])

  useEffect(() => {
    if (!state['badges'][badge?.key]?.iconPath && path?.length) {
      state['badges'][badge?.key] = {
        iconPath: path,
      }
      dispatch({
        type: AppActions.UPDATE_BADGES,
        payload: {
          badges: { ...state?.badges },
        },
      })
    }
  }, [path])

  return { path }
}
export default usePlaceholderBadge
