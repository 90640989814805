import React from 'react'
import PropTypes from 'prop-types';


const YAxisLabels = ({values, classes, linedist, offset, xstart, yscale}) => {



    return (
        <>
        {   
            values.map((v, i) => 
                <text
                key={i}
                className={classes} 
                x={xstart}
                y={yscale(i) + (i*linedist) + offset}
                >
                    {v}
                </text>
            )
        }
        </>
    )
}

YAxisLabels.propTypes = {
    values: PropTypes.array,
    classes: PropTypes.string,
    linedist : PropTypes.number,
    offset : PropTypes.number,
    xstart : PropTypes.number,
    ystart : PropTypes.func
}


export default YAxisLabels;

