import propTypes from 'prop-types'
import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

import { BG_COLOR_WHITE } from '../../../../../styles/colors'

export const StyledDialog = styled(Modal)`
  .modal-content {
    padding: 1.25rem;
    border: none;
    border-radius: 6px;
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.2);
  }
  .modal-header {
    margin: 0 0 1.25rem;
    padding: 0;
    border: none;
    h3 {
      margin: 0;
      font-weight: bold;
      font-size: 18px;
      line-height: 1.2;
    }
    .close {
      margin: -6px -0.25rem -0.25rem auto;
      padding: 0.25rem;
    }
  }
  .base-dialog {
    background-color: ${BG_COLOR_WHITE};
  }
`

const BaseDialog = ({ title, open, render, onClose, dataCy }) => {
  return (
    <StyledDialog show={open} onHide={onClose} centered data-cy={dataCy}>
      <Modal.Header closeButton>
        <h3>{title}</h3>
      </Modal.Header>
      <div className='base-dialog'>{render()}</div>
    </StyledDialog>
  )
}

BaseDialog.defaultProps = {
  padding: 0,
}

BaseDialog.propTypes = {
  title: propTypes.string.isRequired,
  open: propTypes.bool.isRequired,
  render: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
  dataCy: propTypes.string.isRequired,
}

export default BaseDialog
