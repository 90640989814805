import React, { useEffect, useState } from 'react'

import Link from '../../components/styleguide/Link'
import { getFunds } from '../../repositories/funds_repository'

const FundIndex = () => {
  const [funds, setFunds] = useState([])

  useEffect(() => {
    getFunds().then(response => {
      setFunds(response.data)
    })
  }, [1])

  return (
    <div className='page__container'>
      <header>
        <h1 className='h2'>Funds</h1>
      </header>
      <main>
        {funds.map(fund => {
          return (
            <div key={fund.id}>
              <Link href={`/app/funds/${fund.id}`}> {fund.name}</Link>
            </div>
          )
        })}
      </main>
    </div>
  )
}

export default FundIndex
