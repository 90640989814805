import React from 'react'

import {
  Button,
  EButtonVariants,
  IButtonProps,
} from '../../../../elements/Button'
// import theme from './CancelButton.module.scss'
import './CancelButton.module.scss'

export type TProps = Omit<IButtonProps, 'variant' | 'disabled'>

export const CancelButton: React.FC<TProps> = ({
  children = 'Cancel',
  ...buttonProps
}) => (
  <Button
    {...buttonProps}
    variant={EButtonVariants.text}
    className='CancelButton'
  >
    {children}
  </Button>
)
