import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import ErrorPage from '../../../../components/styleguide/ErrorPage'
import Paginator from '../../../../components/styleguide/Paginator'
import withErrorBoundary from '../../../../hocs/withErrorBoundary'
import { getLeadersLaggards } from '../../../../repositories/portfolio_repository'
import {
  APP_COLOR_PRIMARY,
  BG_COLOR_LIGHT_BLUE,
} from '../../../../styles/colors'
import WordSplittedArcLinkLabel from '../../../../yb_components/charts/pie_chart/components/WordSplittedArcLinkLabel'
import PieChart from '../../../../yb_components/charts/pie_chart/PieChart'
import {
  ExplainerLink,
  FilledExplainerLink,
  LinkIcon,
  LinkText,
} from '../../../../yb_components/styleguide/links/Link.styles'
import SpinnerContainer from '../../../../yb_components/styleguide/spinners/SpinnerContainer'
import { percentageFormatter, titleCase } from '../../../../yb_helpers/strings'
import CarbonReportCard from './components/CarbonReportCard'
import CarbonReportLink from './components/CarbonReportLink'
import {
  CARBON_REPORT_TEXTS,
  DEFAULT_SORT,
  leadersLaggardsTableHeader,
} from './constants'
import {
  capitalizeFirstLetter,
  formatSort,
  getPdfSortFieldText,
} from './helpers'
import { CarbonReportTable, ModuleDescription, renderTh } from './styles'

/* Styled Components */
const StyledCarbonReportCard = styled(CarbonReportCard)`
  ${props => (props.height ? `height: ${props.height};` : '')}
  grid-column: span 12;

  table thead tr {
    text-transform: initial;
  }
`

const StyledContent = styled.div`
  display: flex;
  margin-top: 35px;

  & > div {
    width: 100%;
  }

  .pie-container {
    ${props => (props.pdf ? 'width: 540px;' : 'width: 30%;')}
  }

  .portfolio-pie-container {
    cursor: pointer;
    g > path:nth-child(${props => props.activeSlice}) {
      transform: scale(1.03);
      filter: drop-shadow(2px 2px 2px black);
    }
    g > path {
      transition: 0.08s;
    }
  }

  .pie-title {
    font-weight: 900;
    text-align: center;
    margin-bottom: 0px;
  }

  .pie-upper-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #2e384d;
    margin: 0px;
  }

  .table-wrapper {
    width: 100%;
    & > a {
      position: absolute;
    }
  }

  .table-title {
    font-weight: 700;
    text-align: center;
    margin-bottom: 0px;
    caption-side: top;
    color: #2e384d;
    padding: 0px;
  }

  table {
    height: 90%;
    ${props => (props.pdf ? 'width: 540px' : 'width: 100%;')}
    table-layout: fixed;
    tr {
      height: ${props => (props.pdf ? '40px' : '30px')};
      td {
        a {
          font-weight: 400 !important;
        }
      }
    }
    .align-left {
      text-align: left;
    }
    .align-right {
      text-align: right;
    }
    .align-center {
      text-align: center;
    }
  }
  .paginator-switch-wrapper {
    display: flex;
    & > div {
      margin: auto;
    }
  }
`

const CustomExplainerLink = styled(FilledExplainerLink)`
  float: right;
`

/* Constants */
const PIE_MARGIN = { top: 30, right: 55, bottom: 30, left: 55 }

const CUSTOM_PIE_COLOR_SCHEME = {
  color1: APP_COLOR_PRIMARY,
  color2: BG_COLOR_LIGHT_BLUE,
}

const PIE_HEIGHT = '290px'
const CATEGORY_KEYS = [
  'leader',
  'ally',
  'underperformer',
  'laggard',
  'unscored',
]

/* Loaders */
export const loadLeadersLaggards = ({
  snapshotId,
  portfolioId,
  setTargetData,
  setBenchmarkData,
  setError,
  setIsLoading,
  isLoading,
  dispatch,
  page,
  setHoldingsTotal,
  category,
  sort,
}) => {
  if (isLoading) return

  setIsLoading?.(true)
  getLeadersLaggards({ snapshotId, portfolioId, page, category, sort })
    .then(res => {
      setHoldingsTotal(res.data.target.holdings.total_pages)
      setTargetData(res.data.target)
      setBenchmarkData(res.data.benchmark)

      dispatch({
        type: 'leaders-laggards-loaded',
        payload: {
          targetData: res.data.target,
          benchmarkData: res.data.benchmark,
          sort: sort,
        },
      })
    })
    .catch(err => {
      const error = err.response ? err.response.status : 502
      setError(error)
    })
    .finally(() => {
      setIsLoading?.(false)
    })
}

/* Component */
const LeadersLaggards = ({
  pdf,
  portfolioId,
  snapshotId,
  dispatch,
  impactBenchmark,
  defaultData,
  defaultBenchmarkData,
  defaultSort,
  defaultImpactBenchmark,

  title = CARBON_REPORT_TEXTS?.leadersLaggards.title,
  description = CARBON_REPORT_TEXTS?.leadersLaggards.description,
}) => {
  /* Hooks */
  const [isLoading, setIsLoading] = useState(!pdf)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [error, setError] = useState()
  const [targetData, setTargetData] = useState(defaultData || [])
  const [benchmarkData, setBenchmarkData] = useState(defaultBenchmarkData || [])
  const [selectedPage, setSelectedPage] = useState(1)
  const [holdingsTotal, setHoldingsTotal] = useState(1)
  const [category, setCategory] = useState('all')
  const [activeSlice, setActiveSlice] = useState(0)
  const [sort, setSort] = useState(defaultSort || DEFAULT_SORT)

  useEffect(() => {
    if (defaultData) setTargetData(defaultData)
  }, [defaultData])

  useEffect(() => {
    if (defaultBenchmarkData) setBenchmarkData(defaultBenchmarkData)
  }, [defaultBenchmarkData])

  useEffect(
    () =>
      !pdf
        ? loadLeadersLaggards({
            snapshotId,
            portfolioId,
            setTargetData,
            setBenchmarkData,
            setError,
            setIsLoading,
            dispatch,
            category,
            setHoldingsTotal,
            page: selectedPage,
            sort,
          })
        : () => {},
    [sort]
  )

  /* Helper Methods */
  const formatData = data => {
    if (!data) return []

    let result = []
    for (let [index, key] of CATEGORY_KEYS.entries()) {
      const formattedValue = data?.[key] ? Math.round(data[key] * 100) : 0
      result.push({
        index: index,
        id: key,
        label: `${formattedValue}% ${titleCase(key)}`,
        value: formattedValue,
      })
    }

    return result
  }

  const onPaginatorChange = newPage => {
    setSelectedPage(newPage)
    loadLeadersLaggards({
      snapshotId,
      portfolioId,
      setTargetData,
      setBenchmarkData,
      setError,
      setIsLoading: setIsTableLoading,
      isLoading: isTableLoading,
      dispatch,
      category,
      setHoldingsTotal,
      page: newPage,
      sort,
    })
  }

  const handleCategoryClick = cat => {
    const selectedCategory = (
      typeof cat === 'string' ? cat : cat.id
    ).toLowerCase()
    setSelectedPage(1)
    setCategory(selectedCategory)
    setActiveSlice(
      selectedCategory === 'all'
        ? 0
        : CATEGORY_KEYS.indexOf(selectedCategory) + 1
    )
    loadLeadersLaggards({
      snapshotId,
      portfolioId,
      setTargetData,
      setBenchmarkData,
      setError,
      setIsLoading: setIsTableLoading,
      isLoading: isTableLoading,
      dispatch,
      category: selectedCategory,
      setHoldingsTotal,
      page: 1,
      sort: DEFAULT_SORT,
    })
  }

  /* Memoized data */
  const memoTargetData = useMemo(() => formatData(targetData), [targetData])
  const memoBenchmarkData = useMemo(
    () => formatData(benchmarkData),
    [benchmarkData]
  )

  /* Render */
  const renderCategories = targetData => {
    return targetData?.holdings?.data.map(item => (
      <tr key={item.key}>
        <td className='align-left'>
          <CarbonReportLink
            pdf={pdf}
            name={item?.name && titleCase(item?.name)}
            href={`/app/instruments/${item?.id}#report-summary-card`}
          />
        </td>
        {pdf && <td className='number'>{item?.weight}%</td>}
        {category === 'all' && (
          <td className='align-left'>
            <CarbonReportLink
              pdf={pdf}
              name={item?.category && titleCase(item?.category)}
              onClick={() => handleCategoryClick(item?.category)}
              fontWeight='normal'
            />
          </td>
        )}
      </tr>
    ))
  }

  const renderContent = () => {
    const table = (
      <div className='table-wrapper'>
        <CarbonReportTable pdf={pdf}>
          <caption className='table-title'>
            {`${capitalizeFirstLetter(category)} Companies in Portfolio`}
          </caption>
          <thead>
            <tr>
              {category === 'all'
                ? leadersLaggardsTableHeader(pdf).map(elem =>
                    renderTh(
                      elem,
                      sort,
                      (sortField, sortDir) =>
                        setSort(formatSort(sortField, sortDir)),
                      pdf
                    )
                  )
                : leadersLaggardsTableHeader(pdf)
                    .slice(0, -1)
                    .map(elem =>
                      renderTh(
                        elem,
                        sort,
                        (sortField, sortDir) =>
                          setSort(formatSort(sortField, sortDir)),
                        pdf
                      )
                    )}
            </tr>
          </thead>
          <tbody>{renderCategories(targetData)}</tbody>
        </CarbonReportTable>
        {category !== 'all' && (
          <ExplainerLink
            fontSize='14px'
            padding='8px 20px'
            margin='0 10px 0 0'
            style={{ position: 'absolute' }}
            onClick={() => handleCategoryClick('all')}
          >
            <LinkText>Show All Categories</LinkText>
          </ExplainerLink>
        )}
        {!pdf && (
          <div className='paginator-switch-wrapper'>
            <Paginator
              max={holdingsTotal}
              selected={selectedPage}
              onChange={onPaginatorChange}
            />
          </div>
        )}
      </div>
    )

    const commonPieProps = {
      colorScheme: CUSTOM_PIE_COLOR_SCHEME,
      height: PIE_HEIGHT,
      margin: PIE_MARGIN,
      abbreviateLabels: false,
      tooltipValueFormatter: percentageFormatter,
      tooltipLabelFormatter: label => _.last(label?.split(' ')),
      tooltipStyle: { width: '115px' },
      arcLinkLabelsDiagonalLength: 10,
      arcLinkLabelsTextColor: '#2E384D',
      arcLinkLabelsStraightLength: 30,
      arcLinkLabelsTextOffset: 10,
      arcLinkLabel: e => (
        <WordSplittedArcLinkLabel
          key={e.label}
          data={e}
          abbreviateLabels={false}
          boldHeader
        />
      ),
      theme: {
        labels: {
          text: {
            fill: 'white',
            fontWeight: 400,
            fontSize: 13,
            fontFamily: 'Lato',
          },
        },
        tooltip: {
          container: {
            background: '#333',
          },
        },
      },
    }
    if (pdf) commonPieProps.width = '540px'

    const targetPieChart = (
      <span className='pie-container portfolio-pie-container'>
        <p className='pie-title'>Portfolio</p>
        <PieChart
          {...commonPieProps}
          data={memoTargetData}
          onClick={handleCategoryClick}
        />
      </span>
    )

    const benchmarkPieChart = (
      <span className='pie-container'>
        <p className='pie-upper-title'>Benchmark:</p>
        <p className='pie-title'>
          {impactBenchmark?.name || defaultImpactBenchmark?.name || 'Benchmark'}
        </p>
        <PieChart {...commonPieProps} data={memoBenchmarkData} />
      </span>
    )

    return (
      <>
        <div>
          {targetPieChart}
          {benchmarkPieChart}
        </div>
        <SpinnerContainer isLoading={isTableLoading}>{table}</SpinnerContainer>
      </>
    )
  }

  const formattedPdfSortField = useMemo(
    () =>
      getPdfSortFieldText(leadersLaggardsTableHeader(pdf), defaultSort, pdf),
    [defaultSort, pdf]
  )

  return (
    <StyledCarbonReportCard
      pdf={pdf}
      title={title}
      width={'540px'}
      pdfSubTitle={{
        label: `(Top 10 Holdings By ${formattedPdfSortField})`,
        inline: true,
      }}
    >
      {!pdf && (
        <CustomExplainerLink
          pdf={pdf}
          href='http://help.yves.blue/en/articles/6698762-carbon-report-portfolio-summary-on-yvesblue#h_5daaace01b'
          target='_blank'
        >
          <LinkIcon className='fa fa-question' />
        </CustomExplainerLink>
      )}

      <ModuleDescription pdf={pdf}>{description}</ModuleDescription>
      {error ? (
        <ErrorPage code={error} message={error == 404 ? 'NOT FOUND' : ''} />
      ) : (
        <>
          {isLoading && <br />}
          <SpinnerContainer isLoading={isLoading}>
            <StyledContent activeSlice={activeSlice} pdf={pdf}>
              {renderContent()}
            </StyledContent>
          </SpinnerContainer>
        </>
      )}
    </StyledCarbonReportCard>
  )
}

export default withErrorBoundary(LeadersLaggards)
