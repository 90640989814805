import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Container from '../../components/styleguide/Container';
import ErrorPage from '../../components/styleguide/ErrorPage';
import {
  getPortfolioEvents,
  exportPortfolioEvents,
} from '../../repositories/events_repository'
import { getSnapshot } from '../../repositories/portfolio_repository';
import { mapSnapshotToState } from '../../mappers/portfolio_mapper';

import Nav from 'react-bootstrap/Nav';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { FONT_COLOR_PRIMARY } from '../../styles/colors';
import EventLog from './EventLog'
import { PortfolioEventActionTypes } from './constants'

const StyledContainer = styled(Container)`
  padding: 1.25rem;
  position: relative;

  .page-title {
    margin: 0 0 1rem;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.2;
    color: ${FONT_COLOR_PRIMARY};
  }
`;

const loadSnapshot = (setSnapshot, setError, snapshotId, portfolioId) => {
  getSnapshot(snapshotId, portfolioId)
    .then(res => {
      setSnapshot(mapSnapshotToState(res.data.snapshot));
    })
    .catch((err) => {
      const error = err.response ? err.response.status : 502
      setError(error)
    })
}

const PortfolioEventLog = ({ classes, snapshotId = null, portfolioId = null }) => {
  const [snapshot, setSnapshot] = useState(null);
  const [error, setError] = useState(false)

  const onExportPortfolioEvents = ({ filter, page }) => {
    return exportPortfolioEvents({
      filter: filter,
      page: page,
      portfolioId: portfolioId,
    })
  }

  useEffect(loadSnapshot.bind(null, setSnapshot, setError, snapshotId, portfolioId), []);

  if (!snapshot) {
    if(!error) return null;
    return <ErrorPage code={error} message={error == 404 ? 'NOT FOUND' : ''} />
  }

  return (
    <StyledContainer>
      <Breadcrumb>
        <Breadcrumb.Item href='/app'>Home</Breadcrumb.Item>
        <Breadcrumb.Item href={`/app/portfolios/${portfolioId}/snapshots/${snapshot.id}`}>
          {snapshot.name}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Engagement Log</Breadcrumb.Item>
      </Breadcrumb>

      <div className='name-container'>
        <h1 className='page-title'>{snapshot.name}</h1>
        {/* 
        these tabs aren't in the designs, but i'm leaving the code here because i don't feel like writing it again
        <Nav
          variant='pills'
          defaultActiveKey={'/app/portfolios/' + portfolio.id + '/engagement'}
        >
          <Nav.Item>
            <Nav.Link href={'/app/portfolios/' + portfolio.id}>
              Reporting
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href={'/app/portfolios/' + portfolio.id + '/engagement'}>
              Engagement Log
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='manage' disabled>
              Manage
            </Nav.Link>
          </Nav.Item>
        </Nav> */}
      </div>

      <EventLog
        getEvents={getPortfolioEvents}
        targetID={portfolioId}
        exportEvents={onExportPortfolioEvents}
        eventActionTypes={PortfolioEventActionTypes}
        cardTitle={'Portfolio Event Log'}
      />
    </StyledContainer>
  )
}

export default PortfolioEventLog
