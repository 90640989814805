import React from 'react';

const HoldingUpdatedStateEvent = ({ event }) => {
    const holding = event.target;
    const oldState = event.data.old_state;
    const newState = event.data.new_state;

    return (
        <>{ event.context.full_name } updated the state of { holding.name } from "{ oldState }" to "{ newState }". </>
    );

};

export default HoldingUpdatedStateEvent;
