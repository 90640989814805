import { MessageCard } from '@ui/components/presentation/cards/MessageCard'
import { Box } from '@ui/elements/grouping/Box'
import React, { useEffect } from 'react'

import SpinnerContainer from '../../../../../../../react/yb_components/styleguide/spinners/SpinnerContainer'
import { SFDRDashboard } from './components/SFDRDashboard'
import Controller from './SFDROverview.controller'
// import theme from './SFDROverview.module.scss'
import './SFDROverview.module.scss'

export type IProps = YB.IWithPortfolioIds

export const SFDROverview: React.FC<IProps> = ({ portfolioId, snapshotId }) => {
  const [, , isOverviewLoading] = Controller.useOverviewData(
    portfolioId,
    snapshotId
  )
  const isConfigured = true

  useEffect(() => {
    // fetchOverview()
  }, [])

  return (
    <>
      <p className='tabDetail'>
        Welcome to the SFDR Compliance Documents Workspace. The next several
        pages will assist you in working through the three supplements that your
        European investment product is required to add to its standard
        documentation and marketing collateral. The purpose of this regulation
        is to provide more clarity to potential investors about the validity of
        the sustainability claims being made. The three compliance documents are
        heavily reliant on the information that you got about your portfolio
        from the PAI calculations and from the EU Taxonomy calculations. If you
        have not yet used the PAI workspace you can navigate to that and get
        that set up so that you can work on these documents.
      </p>
      <SpinnerContainer isLoading={isOverviewLoading} className='spinner'>
        <Box isPresent={isConfigured}>
          <SFDRDashboard portfolioId={portfolioId} snapshotId={snapshotId} />
        </Box>
        <Box isPresent={!isConfigured}>
          <MessageCard>
            <p>
              Visit the{' '}
              <a
                href={`/app/portfolios/${portfolioId}/snapshots/${snapshotId}/regulatory/sfdr/fund_configuration`}
              >
                Fund Configuration
              </a>{' '}
              page to begin your SFDR regulation process.
            </p>
          </MessageCard>
        </Box>
      </SpinnerContainer>
    </>
  )
}
