import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { NoteForm } from './NoteForm'
import { NotesList } from './NotesList'

const StyledDiv = styled.div`
  display: flex;
  grid-column: 1 / 4;
  grid-row: 3;
  padding: 10px;
  gap: 10px;
  > div {
    flex: 1;
  }
  h6 {
    margin: 0 0 10px 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 12px;
  }
`

export const TcfdListItemNoteCard = ({ noteData, saveNote, tcfdSector }) => {
  const [noteList, setNoteList] = useState(noteData)
  useEffect(() => {
    setNoteList(noteData)
  }, [noteData])
  return (
    <StyledDiv>
      <NotesList data={noteList} />
      <NoteForm
        saveNote={saveNote}
        payload={tcfdSector}
        setNoteList={setNoteList}
      />
    </StyledDiv>
  )
}
