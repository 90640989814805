const getKeys = data => {
  if (!data?.length) return []
  const result = {}

  data?.forEach(barData => {
    Object.keys(barData)?.forEach(key => {
      if (key !== 'target') result[key] = true
    })
  })

  return Object.keys(result)
}

export const mapCarbonAuditToState = target => {
  const { chart, totals } = target

  return {
    groupBy: target?.group_by,
    budget: target?.budget,
    calculationMethod: target?.calculation_method,
    degree: target?.degree,
    hasAllData: target?.has_all_data,
    includeBenchmark: target?.include_benchmark,
    includeShorts: target?.include_shorts,
    chartFormat: {
      keys: getKeys(chart?.data),
      data: chart?.data,
      totals,
    },
    table: target?.table,
  }
}
