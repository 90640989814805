import React from 'react'
import styled, { css } from 'styled-components'

import SwitchButton from '../../../../components/styleguide/SwitchButton'
import TextView from '../../../../components/styleguide/TextView'
import {
  BUDGET_SWITCH_OPTIONS,
  CALCULATION_METHOD_SWITCH_OPTIONS,
  DEGREE_SWITCH_OPTIONS,
  DESCRIPTION,
  SWITCH_OPTIONS,
} from '../../../../yb_components/cards/carbon_audit_card/CarbonAuditCard.constants'
import CarbonAuditChart from '../../../../yb_components/cards/carbon_audit_card/components/CarbonAuditChart'
import CarbonAuditLegend from '../../../../yb_components/cards/carbon_audit_card/components/CarbonAuditLegend'
import { BodyText } from '../../../yb_styles/typography'
import CarbonAuditTable from './components/CarbonAuditTable'

const StyledChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const StyledLegendContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.report
      ? css`
          margin-left: 30px;
          margin-top: -20px;
          margin-bottom: 20px;
        `
      : ''}
`

const StyledTextView = styled(TextView)`
  ${props => (props.report ? BodyText : 'font-size: 10px;')}
  padding-top: 2px;
  margin-bottom: 10px;
`

export const StyledSwitchContainer = styled.div`
  display: flex;
`

export const CustomSwitchButton = styled(SwitchButton)`
  margin-left: 15px;
  ${BodyText}
`

const CarbonAudit = ({
  targetName,
  report,
  chartData,
  tableData,
  colors,

  switchSelections,
}) => {
  let switchStyle = {
    height: '24px',
    lineHeight: '22px',
  }
  if (!report)
    switchStyle = {
      height: '18px',
      lineHeight: '16px',
      fontSize: '10px',
      padding: '0 5px',
    }

  return (
    <>
      <StyledTextView report={report} secondary text={DESCRIPTION} />

      <StyledSwitchContainer>
        {switchSelections?.chart && (
          <SwitchButton
            active={switchSelections?.chart}
            options={SWITCH_OPTIONS}
            switchStyle={switchStyle}
          />
        )}

        {switchSelections?.degree && (
          <CustomSwitchButton
            active={switchSelections?.degree}
            options={DEGREE_SWITCH_OPTIONS}
            switchStyle={switchStyle}
          />
        )}

        {switchSelections?.budget && (
          <CustomSwitchButton
            active={switchSelections?.budget}
            options={BUDGET_SWITCH_OPTIONS}
            switchStyle={switchStyle}
          />
        )}

        {switchSelections?.calculationMethod && (
          <CustomSwitchButton
            active={switchSelections?.calculationMethod}
            options={CALCULATION_METHOD_SWITCH_OPTIONS}
            switchStyle={switchStyle}
          />
        )}
      </StyledSwitchContainer>

      <StyledChartContainer>
        <CarbonAuditChart
          pdf={true}
          height={320}
          width={550}
          data={chartData}
          colors={colors}
        />
      </StyledChartContainer>

      <StyledLegendContainer report={report}>
        <CarbonAuditLegend
          pdf={'report'}
          data={chartData}
          targetName={targetName}
          selectedChart={switchSelections?.chart}
        />
      </StyledLegendContainer>

      <CarbonAuditTable
        report={report}
        width={300}
        selectedChart={switchSelections?.chart}
        headers={tableData?.headers?.map(e => ({ ...e, sort: false }))}
        body={tableData?.body}
        footer={tableData?.footer}
      />
    </>
  )
}

export default CarbonAudit
