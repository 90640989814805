import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

import Button from '../../../components/styleguide/Button'
import SectionWrapper from '../../../components/styleguide/SectionWrapper'
import StyledDialog from '../../../components/styleguide/StyledDialog'
import WrapperColumn from '../../../components/styleguide/WrapperColumn'
import YBDescription from '../../../components/styleguide/YBDescription'

const StyledContent = styled.div`
  max-height: 55vh;
  padding: 10px;
  background-color: #f0f3fa;
  font-size: 14px;
  overflow: auto;
`

const ClickwrapModal = ({ show, onClose, onAccept, 'data-cy': dataCy }) => {
  return (
    <StyledDialog
      centered
      show={show}
      size='lg'
      width='auto'
      padding='20px'
      sectionsPadding='0px'
      onHide={onClose}
      data-cy={dataCy}
    >
      <Modal.Header>
        <Modal.Title data-cy={'clickwrap_title'}>
          YvesBlue User Agreement
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WrapperColumn>
          <YBDescription
            data-cy={'clickwrap_description'}
            margin='0px 0px 10px 0px'
          >
            Please review the agreement below to begin using YvesBlue.
          </YBDescription>

          <StyledContent data-cy={'clickwrap_content'}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              fermentum feugiat dolor vel ornare. Sed malesuada urna eget eros
              scelerisque molestie. Donec ullamcorper elit a est accumsan
              aliquet. Morbi placerat mi quis ante gravida, eget aliquam ante
              venenatis. Donec a justo fringilla, sodales urna ac, convallis
              magna. Curabitur sed fermentum nibh. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Aliquam molestie ac tortor eu
              consequat. Curabitur quis ultrices tellus. Donec vitae ullamcorper
              nunc. Nam at sapien urna. Aenean enim dolor, lobortis ut tortor
              in, varius pharetra elit.
            </p>

            <p>
              Duis sed libero id mi tristique vulputate in vel ex. Donec viverra
              velit sit amet justo tristique, sed efficitur sapien placerat.
              Cras non tellus arcu. Orci varius natoque penatibus et magnis dis
              parturient montes, nascetur ridiculus mus. Morbi a tellus a odio
              mollis congue ac nec velit. Nam augue quam, convallis in pretium
              vitae, ultricies non odio. Quisque eget eros vitae elit feugiat
              cursus quis quis dui.
            </p>

            <p>
              Sed accumsan diam sit amet velit ultrices, sodales lacinia eros
              condimentum. Aenean sit amet libero efficitur leo lobortis
              placerat ut non sapien. Aliquam sit amet ante enim. Integer
              lacinia ut massa vitae congue. Vestibulum ante ipsum primis in
              faucibus orci luctus et ultrices posuere cubilia curae; Donec
              tristique, libero non volutpat fringilla, diam justo imperdiet
              elit, et sagittis nisi lacus non lorem. Praesent iaculis, erat
              sodales sagittis fringilla, sapien purus aliquet ipsum, sed
              euismod mi mauris quis leo. Suspendisse vel efficitur felis.
            </p>

            <p>
              Aliquam vel eleifend eros, a auctor elit. Morbi sollicitudin
              feugiat enim, nec finibus ex sodales at. Donec ut ex iaculis,
              finibus quam vel, accumsan neque. Nam ultrices nulla a enim porta
              luctus. Cras semper nunc
            </p>

            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              fermentum feugiat dolor vel ornare. Sed malesuada urna eget eros
              scelerisque molestie. Donec ullamcorper elit a est accumsan
              aliquet. Morbi placerat mi quis ante gravida, eget aliquam ante
              venenatis. Donec a justo fringilla, sodales urna ac, convallis
              magna. Curabitur sed fermentum nibh. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Aliquam molestie ac tortor eu
              consequat. Curabitur quis ultrices tellus. Donec vitae ullamcorper
              nunc. Nam at sapien urna. Aenean enim dolor, lobortis ut tortor
              in, varius pharetra elit.
            </p>

            <p>
              Duis sed libero id mi tristique vulputate in vel ex. Donec viverra
              velit sit amet justo tristique, sed efficitur sapien placerat.
              Cras non tellus arcu. Orci varius natoque penatibus et magnis dis
              parturient montes, nascetur ridiculus mus. Morbi a tellus a odio
              mollis congue ac nec velit. Nam augue quam, convallis in pretium
              vitae, ultricies non odio. Quisque eget eros vitae elit feugiat
              cursus quis quis dui.
            </p>

            <p>
              Sed accumsan diam sit amet velit ultrices, sodales lacinia eros
              condimentum. Aenean sit amet libero efficitur leo lobortis
              placerat ut non sapien. Aliquam sit amet ante enim. Integer
              lacinia ut massa vitae congue. Vestibulum ante ipsum primis in
              faucibus orci luctus et ultrices posuere cubilia curae; Donec
              tristique, libero non volutpat fringilla, diam justo imperdiet
              elit, et sagittis nisi lacus non lorem. Praesent iaculis, erat
              sodales sagittis fringilla, sapien purus aliquet ipsum, sed
              euismod mi mauris quis leo. Suspendisse vel efficitur felis.
            </p>

            <p>
              Aliquam vel eleifend eros, a auctor elit. Morbi sollicitudin
              feugiat enim, nec finibus ex sodales at. Donec ut ex iaculis,
              finibus quam vel, accumsan neque. Nam ultrices nulla a enim porta
              luctus. Cras semper nunc
            </p>

            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              fermentum feugiat dolor vel ornare. Sed malesuada urna eget eros
              scelerisque molestie. Donec ullamcorper elit a est accumsan
              aliquet. Morbi placerat mi quis ante gravida, eget aliquam ante
              venenatis. Donec a justo fringilla, sodales urna ac, convallis
              magna. Curabitur sed fermentum nibh. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Aliquam molestie ac tortor eu
              consequat. Curabitur quis ultrices tellus. Donec vitae ullamcorper
              nunc. Nam at sapien urna. Aenean enim dolor, lobortis ut tortor
              in, varius pharetra elit.
            </p>

            <p>
              Duis sed libero id mi tristique vulputate in vel ex. Donec viverra
              velit sit amet justo tristique, sed efficitur sapien placerat.
              Cras non tellus arcu. Orci varius natoque penatibus et magnis dis
              parturient montes, nascetur ridiculus mus. Morbi a tellus a odio
              mollis congue ac nec velit. Nam augue quam, convallis in pretium
              vitae, ultricies non odio. Quisque eget eros vitae elit feugiat
              cursus quis quis dui.
            </p>

            <p>
              Sed accumsan diam sit amet velit ultrices, sodales lacinia eros
              condimentum. Aenean sit amet libero efficitur leo lobortis
              placerat ut non sapien. Aliquam sit amet ante enim. Integer
              lacinia ut massa vitae congue. Vestibulum ante ipsum primis in
              faucibus orci luctus et ultrices posuere cubilia curae; Donec
              tristique, libero non volutpat fringilla, diam justo imperdiet
              elit, et sagittis nisi lacus non lorem. Praesent iaculis, erat
              sodales sagittis fringilla, sapien purus aliquet ipsum, sed
              euismod mi mauris quis leo. Suspendisse vel efficitur felis.
            </p>

            <p>
              Aliquam vel eleifend eros, a auctor elit. Morbi sollicitudin
              feugiat enim, nec finibus ex sodales at. Donec ut ex iaculis,
              finibus quam vel, accumsan neque. Nam ultrices nulla a enim porta
              luctus. Cras semper nunc
            </p>
          </StyledContent>
        </WrapperColumn>

        <SectionWrapper margin='20px 0px 0px 0px' justify='flex-end'>
          <Button
            tertiary
            label='Log Out'
            width='auto'
            onClick={onClose}
            data-cy={`${dataCy}_logout_button`}
          />
          <Button
            label='Accept and Begin Using YvesBlue'
            width='auto'
            onClick={onAccept}
            data-cy={`${dataCy}_accept_button`}
          />
        </SectionWrapper>
      </Modal.Body>
    </StyledDialog>
  )
}

export default ClickwrapModal
