import React from 'react'
import {
    Button,
    EButtonVariants,
    IButtonProps,
} from '../../../../elements/Button'

export interface IProps extends Omit<IButtonProps, 'variant' | 'type'> {
    onClick: () => void
}

export const SaveButton: React.FC<IProps> = ({
    children = 'Save Changes',
    onClick,
    ...buttonProps
}) => (
    <Button
        {...buttonProps}
        type='submit'
        onClick={onClick}
        variant={EButtonVariants.primary}
    >
        {children}
    </Button>
)
