import qs from 'query-string'
import React, { useEffect, useMemo, useState } from 'react'
import ReactGA from 'react-ga'

import withErrorBoundary from '../../../../hocs/withErrorBoundary'
import HoldingsTableCard from '../../../../yb_components/tables/holdings_table/HoldingsTableCard'
import { getNewPage } from '../../../research_center/helpers/paginator'
import { formatFilters } from './helpers'
import useHoldingsTable from './hooks/useHoldingsTable'
import {
  FORM_FILTERS_DEFAULT,
  INITIAL_SORT_SPACE,
} from './PortfolioHoldingsCard.constants'

const PortfolioHoldingsCard = ({
  /* Portfolio */
  snapshotId,
  portfolioId,

  /* Holdings */
  holdings,
  onUpdateHoldings,
  loading,
  limit = 10,
  totalCount,

  /* Search */
  searchQuery,
  setSearchQuery,

  /* Filters */
  filtersOptions,
}) => {
  /* State */
  const [selectedPage, setSelectedPage] = useState(1)
  const [sort, setSort] = useState(INITIAL_SORT_SPACE)
  const [nPages, setNPages] = useState(0)
  const [groupBy, setGroupBy] = useState('standard')
  const [csvEndpoint, setCsvEndpoint] = useState('holdings')
  const [tableTitle, setTableTitle] = useState('')

  useEffect(() => {}, [])

  const defaultSessionFilters = useMemo(
    () => JSON.parse(localStorage.getItem('portfolio_holdings_table_filters')),
    []
  )

  const [formControlFilters, setFormControlFilters] = useState({
    ...FORM_FILTERS_DEFAULT,
    ...defaultSessionFilters,
  })

  const { selectedTableHeaders, selectedTableBody, onTableDataChange } =
    useHoldingsTable({
      snapshotId,
      portfolioId,

      setTitle: setTableTitle,
      holdings,
      groupBy,
      limit,
    })

  const queryParams = useMemo(() => {
    return {
      limit,
      page: selectedPage,
      query: searchQuery,
      sort_dir: sort.dir,
      sort_field: sort.field,
      ...formatFilters(formControlFilters),
    }
  }, [sort, searchQuery, selectedPage, limit, formControlFilters])

  useEffect(() => {
    let pages = Math.ceil(totalCount / limit)
    setNPages(pages)
  }, [groupBy, totalCount, limit])

  useEffect(() => {
    localStorage.setItem(
      'portfolio_holdings_table_filters',
      JSON.stringify(formControlFilters)
    )

    onUpdateHoldings(groupBy, { ...queryParams, ...sort })
  }, [onUpdateHoldings, groupBy, queryParams, sort])

  /* Events */
  const onSort = (id, direction) => {
    let field = id
    switch (id) {
      case 'env':
        field = 'e_score'
        break
      case 'soc':
        field = 's_score'
        break
      case 'gov':
        field = 'g_score'
        break
      case 'total':
        field = 'total_score'
        break
      default:
        field = id
    }
    setSort({ field: field, dir: direction })
    ReactGA.event({
      category: 'Portfolio Holdings Table',
      action: 'CSV Download',
      label: `SortId: ${id}, sortDir: ${direction}`,
    })
  }

  const onSearch = value => {
    ReactGA.event({
      category: 'Portfolio Holdings Table Filter Search',
      action: 'Search',
    })
    const newPage = 1
    setSelectedPage(newPage)
    setSearchQuery(value)
  }

  const onPaginatorChange = value => {
    ReactGA.event({
      category: 'Portfolio Holdings Table',
      action: 'Pagination',
      label: `${value}`,
    })
    const newPage = getNewPage(value, selectedPage, nPages)
    setSelectedPage(newPage)
  }

  const onExportHoldings = () => {
    const exportCsvQueryString = `/app/api/portfolios/${portfolioId}/snapshots/${snapshotId}/${csvEndpoint}/csv?${qs.stringify(
      queryParams
    )}`
    ReactGA.event({
      category: 'Portfolio Holdings Table',
      action: 'CSV Download',
    })
    window.location.assign(exportCsvQueryString)
  }

  const onFiltersUpdate = (newFilters, type) => {
    let filterValues
    ReactGA.event({
      category: 'Portfolio Holdings Table',
      action: 'Submit Filter',
      label: `${type}`,
    })
    switch (type) {
      case 'asset_type':
        filterValues = Object.entries(newFilters[type])
          .map(arr => arr[0])
          .join(', ')
        ReactGA.event({
          category: 'Portfolio Holdings Table',
          action: 'Filter',
          label: `filter: ${type}, values: [${filterValues}]`,
        })
        break
      case 'coverage':
        ReactGA.event({
          category: 'Portfolio Holdings Table',
          action: 'Filter',
          label: `filter: ${type}, values: [${newFilters.coverage}]`,
        })
        break
      case 'metrics':
      case 'properties':
        filterValues = Object.entries(newFilters)
          .map(arr => {
            if (Array.isArray(arr[1])) {
              const labelsArray = []
              arr[1].map(val => {
                labelsArray.push(val.label)
              })
              const finalArray = [arr[0], `[${labelsArray.join(', ')}]`]
              return finalArray.join(':')
            } else {
              return arr.join(':')
            }
          })
          .join(', ')
        ReactGA.event({
          category: 'Portfolio Holdings Table',
          action: 'Filter',
          label: `filter: ${type}, values: [${filterValues}]`,
        })
        break
      default:
        break
    }
    setFormControlFilters({ ...formControlFilters, ...newFilters })
  }

  const onClearFilters = () => {
    ReactGA.event({
      category: 'Portfolio Holdings Table',
      action: 'Clear Filter',
    })
    setFormControlFilters(FORM_FILTERS_DEFAULT)
    onUpdateHoldings(groupBy, { page: 1 })
  }

  const onGroupByChange = newGroupBy => {
    switch (newGroupBy) {
      case 'sector':
        setCsvEndpoint('industry_group_holdings')
        break

      case 'entity':
        setCsvEndpoint('corporate_entity_holdings')
        break

      default:
        setCsvEndpoint('holdings')
        break
    }
    setGroupBy(newGroupBy)
    onTableDataChange(newGroupBy)
    setSelectedPage(1)
    setSort(INITIAL_SORT_SPACE)
  }

  /* Render */
  return (
    <HoldingsTableCard
      /* Table */
      title={tableTitle}
      selectedTableHeaders={selectedTableHeaders}
      selectedTableBody={selectedTableBody}
      loadingHoldings={loading}
      holdingsTotal={totalCount}
      onSearch={onSearch}
      onExportHoldings={onExportHoldings}
      /* Pagination */
      nPages={nPages}
      selectedPage={selectedPage}
      onPaginatorChange={onPaginatorChange}
      /* Sorting */
      sort={sort}
      onSort={onSort}
      /* Filters */
      showFilters={groupBy === 'standard'}
      filtersOptions={filtersOptions}
      formControlFilters={formControlFilters}
      onFiltersUpdate={onFiltersUpdate}
      onClearFilters={onClearFilters}
      /* Group By */
      groupBy={groupBy}
      onGroupByChange={onGroupByChange}
    />
  )
}

export default withErrorBoundary(PortfolioHoldingsCard)
