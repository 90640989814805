import FormService from '@services/Form'
import withFormRow from '@ui/components/compositions/withFormRow/withFormRow'
import {
  DynamicFieldControl,
  TDynamicFieldTypes,
} from '@ui/components/interactive/DynamicField/DynamicField'
import classNames from 'classnames'
import _ from 'lodash'
import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  IField,
  IFollowUpField,
  IForm,
  IFormField,
  TFormAnswer,
} from '../../../../data'
import Controller from './ArticleFormQuestionRow.controller'
// import theme from './ArticleFormQuestionRow.module.scss'
import './ArticleFormQuestionRow.module.scss'
import { DisclosureFormsContext } from '../../../../../../../../../../../react/yb_stores/sfdr/disclosure_forms/DisclosureFormsStore'
import { DisclosureFormsActions } from '../../../../../../../../../../../react/yb_stores/sfdr/disclosure_forms/actions/DisclosureFormsActions'

type TDynamicMap = {
  [key: string]: unknown
}

interface IProps {
  form: IForm
  model: YB.TModel<Record<string, unknown>>
  feedbackModel: YB.TModel<string>
  models: TModels
}

type TModels = {
  values: Record<string, YB.TModel<string | string[]>>
  feedbacks: YB.TModel<string>[]
}

type TWithKey = { key: string }

const ArticleFormQuestion: React.FC<IProps> = ({
  form,
  model,
  feedbackModel,
}) => {
  const Form = new FormService<Record<string, unknown>>()
  const [state, setState] = model
  const [formDataSnapshot, setFormDataSnapshot] = useState(state)
  const [, setFeedback] = feedbackModel
  const disabledFields: string[] = []

  const [formState, dispatchFormState] = useContext(DisclosureFormsContext)
  const { triggers, localAnswers } = formState

  const models: TModels = {
    values: {},
    feedbacks: [],
  }

  const trackModel = (
    field: IField,
    disabled: boolean
  ): [YB.TModel<string | string[]>, YB.TModel<string>] => {
    const defaultValue = field.type === 'checkbox' ? [] : ''
    const inputModel = useState<string | string[]>(field.value || defaultValue)
    const inputFeedback = useState('')

    models.values[field.key] = inputModel

    if (!disabled) {
      models.feedbacks.push(inputFeedback)
    }

    return [inputModel, inputFeedback]
  }

  const trackDisabledField = ({ key }: TWithKey, disabled: boolean) => {
    if (disabled) disabledFields.push(key)
  }

  const renderField = (field: IField, disabled = false) => {
    const [inputModel, inputFeedback] = trackModel(field, disabled)
    const [inputState] = inputModel

    useEffect(() => {
      if (field?.key) {
        dispatchFormState({
          type: DisclosureFormsActions.ADD_LOCAL_ANSWER,
          payload: { [field?.key]: inputState },
        })
      }
    }, [inputState])

    trackDisabledField(field, disabled)

    return (
      <DynamicFieldControl
        label={field.label || ''}
        className={classNames({ hidden: disabled })}
        key={field.id}
        name={field.key}
        type={field.type as TDynamicFieldTypes}
        disabled={!!disabled}
        model={inputModel}
        feedbackModel={inputFeedback}
        metadata={field.metadata}
        placeholder={field?.placeholder || {}}
      />
    )
  }

  const doesTriggerMatch = (
    trigger: string,
    parentValue: string | string[]
  ) => {
    if (Array.isArray(parentValue)) return parentValue.includes(trigger)
    return trigger === parentValue
  }

  const isValueValid = (value: string | string[]) => {
    if (Array.isArray(value)) return !!value.length
    return !!value
  }

  const isFieldDisabled = (
    trigger: string,
    parentValue: string | string[],
    disabledTree: boolean
  ): boolean =>
    !doesTriggerMatch(trigger, parentValue) ||
    !isValueValid(parentValue) ||
    disabledTree

  const renderFollowUpField = (
    { field, trigger }: IFollowUpField,
    parent: IFormField,
    disabledTree = false
  ): ReactNode => {
    const [parentValue] = models.values[parent.key]
    const disabled = isFieldDisabled(trigger, parentValue, disabledTree)

    return (
      <li className={disabled ? 'hidden' : 'item'}>
        {renderAmbiguousField(field, disabled)}
      </li>
    )
  }

  const renderFormField = (field: IFormField, disabled = false) => {
    let mainFieldDisabled = useMemo(() => {
      let localDisabled = false
      if (triggers[field?.key]) {
        const { key: triggerKey, value: triggerValue } = triggers[field?.key]

        let currentValue = localAnswers?.[triggerKey]
        currentValue = Array.isArray(currentValue)
          ? _.first(currentValue)
          : currentValue

        if (Array.isArray(currentValue))
          localDisabled = !currentValue.includes(triggerValue)
        else localDisabled = currentValue !== triggerValue
      }

      return disabled || localDisabled
    }, [localAnswers, triggers, disabled])

    useEffect(() => {
      if (mainFieldDisabled != undefined) {
        if (mainFieldDisabled) {
          dispatchFormState({
            type: DisclosureFormsActions.REMOVE_VISIBLE_FIELD,
            payload: field?.key,
          })
        } else {
          dispatchFormState({
            type: DisclosureFormsActions.ADD_VISIBLE_FIELD,
            payload: { [field?.key]: true },
          })
        }
      }
    }, [mainFieldDisabled])

    return (
      <div key={field.id} className={classNames({ hidden: mainFieldDisabled })}>
        {renderField(field, mainFieldDisabled)}
        <ul>
          {field?.followUpFields?.map((followup: IFollowUpField) => {
            return renderFollowUpField(followup, field, mainFieldDisabled)
          })}
        </ul>
      </div>
    )
  }

  const renderAmbiguousField = (
    field: IFormField | IField | IFollowUpField,
    disabled = false
  ) => {
    const metaType = Controller.getFieldMetaType(field)

    switch (metaType) {
      case 'field':
        return renderField(field as IField, disabled)
      case 'formField':
        return renderFormField(field as IFormField, disabled)
      default:
        return null
    }
  }

  const content = form?.fields?.map(f => (
    <li className='item' key={f.id}>
      {renderFormField(f)}
    </li>
  ))

  const formData = Form.useFormData(models.values)
  const formValidity = Form.useFormValidity(models.feedbacks)

  const ammendFormData = (formData: Record<string, unknown>) => {
    const ammendedData: Record<string, unknown> = {}

    Object.keys(formData).forEach(key => {
      if (!disabledFields.includes(key)) {
        ammendedData[key] = formData[key]
      }
    })

    return ammendedData
  }

  useEffect(() => {
    if (!_.isEqual(formDataSnapshot, formData)) {
      setFormDataSnapshot(formData)
      setState(ammendFormData(formData))
    }

    if (!formValidity) {
      setFeedback('Please fill in all required fields')
    } else {
      setFeedback('')
    }
  }, [formData, formValidity])

  return <ul className='ArticleFormQuestionRow'>{content}</ul>
}

export const ArticleFormQuestionRow = withFormRow(ArticleFormQuestion)
