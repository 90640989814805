import React from 'react'
import { FormGroup } from 'react-bootstrap'
import styled from 'styled-components'

const StyledFormGroup = styled(FormGroup)`
  margin: 0;
`

export const BaseFormGroup = ({ children, ...props }) => {
  return <StyledFormGroup {...props}>{children}</StyledFormGroup>
}
