import withFormControl from '@ui/components/compositions/withFormControl/withFormControl'
import withFormRow from '@ui/components/compositions/withFormRow/withFormRow'
import _ from 'lodash'
import React, { useMemo } from 'react'

import { BaseInput } from '../BaseInput'
import { CheckboxGroup } from '../ChekboxGroup'
import { CurrencyInput } from '../CurrencyInput'
import { NumberInput } from '../NumberInput'
import { PercentageInput } from '../PercentageInput'
import { RadioButtonGroup } from '../RadioButtonGroup'
import { Select } from '../Select'
import { TextArea } from '../TextArea'
import { URLInput } from '../URLInput'
import { TreeDiagram } from '../../presentation/TreeDiagram/TreeDiagram'
import { RTSDiagramNode } from '../../../screens/Portfolios/RegulatoryFrameworks/SFDR/components/DisclosureDetail/components/RTSDiagramNode'
import { PieCharts } from '../../presentation/PieCharts'

export const FieldTypes = [
  'currency',
  'percentage',
  'numeric',
  'string',
  'textarea',
  'radio',
  'checkbox',
  'select',
  'url',
  'pie_chart',
]

export type TDynamicFieldTypes = typeof FieldTypes[number]

interface IProps {
  name: string
  type: TDynamicFieldTypes
  model: YB.TModel<string | string[]>
  feedbackModel: YB.TModel<string>
  metadata: Record<string, unknown>
  disabled?: boolean
  required?: boolean
}

export const DynamicField: React.FC<IProps> = ({
  name,
  model,
  type,
  metadata,
  feedbackModel,
  disabled = false,
  required = false,
  placeholder,
}) => {
  const singleValue = useMemo(() => {
    const firstElem = _.first(model)
    const secondElem = model?.[1]

    return Array.isArray(firstElem) && firstElem?.length
      ? [_.first(firstElem), secondElem]
      : model
  }, [model])

  const props = {
    name,
    id: name,
    model: singleValue as YB.TModel<string>,
    feedbackModel,
    disabled,
    required,
    placeholder,
  }

  const formatBranch = branch => {
    const branches = branch?.branches?.map(localBranch => {
      return formatBranch(localBranch)
    })

    const node = (
      <RTSDiagramNode
        label={branch?.node?.label}
        amount={branch?.node?.amount}
        color={branch?.node?.color}
        backgroundColor={branch?.node?.backgroundColor}
      />
    )

    let returnValue = {}
    if (branches) {
      returnValue = {
        node,
        branches,
      }
    } else {
      returnValue = node
    }
    return returnValue
  }

  const formattedTreeMetadata = useMemo(() => {
    let result = { node: null, branches: [] }

    if (type === 'tree_diagram') {
      result = formatBranch(metadata)
    }

    return [result]
  }, [type, metadata])

  switch (type) {
    case 'string':
      return <BaseInput {...props} />
    case 'url':
      return <URLInput {...props} />
    case 'numeric':
      return <NumberInput {...props} />
    case 'percentage':
      return <PercentageInput {...props} />
    case 'currency':
      return <CurrencyInput placeholder='0.000' {...props} />
    case 'textarea':
      return <TextArea rows={5} {...props} />
    case 'select':
      return <Select {...props} options={metadata.options as YB.IOption[]} />
    case 'radio':
      return (
        <RadioButtonGroup
          {...props}
          options={metadata.options as YB.IOption[]}
        />
      )
    case 'checkbox':
      return (
        <CheckboxGroup
          {...props}
          options={metadata.options as YB.IOption[]}
          model={model as YB.TModel<string[]>}
        />
      )

    case 'tree_diagram':
      return <TreeDiagram branches={formattedTreeMetadata} />

    case 'pie_chart':
      return <PieCharts data={metadata} />

    default:
      throw `Invalid question type: ${type}`
  }
}

export const DynamicFieldRow = withFormRow(DynamicField)

export const DynamicFieldControl = withFormControl(DynamicField)
