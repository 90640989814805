import React, {useState} from "react";

import SectionWrapper from "../../../components/styleguide/SectionWrapper";
import Card from "../../../components/styleguide/Card";
import CardTitle from "../../../components/styleguide/CardTitle";
import TextInput from "../../../components/styleguide/TextInput";
import Button from "../../../components/styleguide/Button";
import Alert from "../../../components/styleguide/Alert";
import Link from "../../../components/styleguide/Link";
import { PEErrorService } from "../../fund/errorService";
import { updateCurrentOrganization } from "../../../repositories/organizations_repository";
import { ORGANIZATION_UPDATED } from "../../../constants/organization_constants";

import WrapperColumn from "../../../components/styleguide/WrapperColumn";

const OrganizationDetailsCard = ({organization}) => {
    const [organizationName, setOrganizationName] = useState(organization.name);
    const [defaultOrganizationName, setDefaultOrganizationName] = useState(organization.name);
    const [alert, setAlert] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [errors, setErrors] = useState([]);

    const onSubmit = () => {
        setErrors([]);

        updateCurrentOrganization({ name: organizationName }).then(() => {
            setDefaultOrganizationName(organizationName);
            setAlert(ORGANIZATION_UPDATED);
        }).catch((error) => {
            PEErrorService.setErrors(error.response.data, setErrors);
        });
    };

    const onOrganizationNameChange = (name) => {
        setOrganizationName(name);
        setErrors([]);

        if (name.length === 0) {
            setDisableSubmit(true);
        } else {
            setDisableSubmit(false);
        }
    }

    const onCancelChanges = () => {
        onOrganizationNameChange(defaultOrganizationName);
        setErrorMessage(null);
    }

    return(<Card>
        <CardTitle title="Organization Details"/>
        {
            alert && <Alert> { alert } </Alert>
        }
        <WrapperColumn width="50%">
            <SectionWrapper>
                <TextInput label="Organization Name"
                           value={organizationName}
                           onChange={(id, value, e) => onOrganizationNameChange(value)}
                           width="50%"
                           error={errors.name}
                />
            </SectionWrapper>

            <SectionWrapper justify="end" align="baseline">
                <Button width="20%" disabled={disableSubmit} label="Save Changes" onClick={onSubmit}/>

                <div style={{margin: "0px 0px 0px 10px"}}>
                    <Link onClick={onCancelChanges}>Cancel Changes</Link>
                </div>
            </SectionWrapper>
        </WrapperColumn>
    </Card>)
};

export default OrganizationDetailsCard;
