import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

import { addUnit } from '../../helpers/styling'

export const StyledDialog = styled(Modal)`
  .base-dialog {
    padding: ${props => props.padding};
  }

  .modal-content {
    width: 700px;
    font-size: 16px;
    margin-top: 35px;
    min-height: 250px;
  }

  .modal-header {
    font-size: 26px;
  }

  .modal-footer {
    border-top: none;
    margin-top: 50px;
    justify-content: center;
  }
`

const BaseDialog = ({ title, open, render, onClose, padding }) => {
  return (
    <StyledDialog show={open} onHide={onClose} padding={addUnit(padding)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <div className='base-dialog'>{render()}</div>
    </StyledDialog>
  )
}

BaseDialog.defaultProps = {
  padding: 0,
}

export default BaseDialog
