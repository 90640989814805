import React from 'react'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'

import Card from '../../../../../../components/styleguide/Card'
import SectionTitle from '../../../../../../components/styleguide/SectionTitle'
import Tooltip from '../../../../../../components/styleguide/Tooltip'
import FilledButton from '../../../../../../yb_components/styleguide/buttons/filled_button/FilledButton'
import { HOLDINGS_FILTERS_COPY } from '../../PortfolioHoldingsCard.constants'

const StyledCheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  & label {
    color: #979ca6;
    font-size: 14px;
  }
`

const AssetTypeTooltip = ({
  data,
  options,

  onChange,
  onCancel,
  onUpdate,
}) => {
  return (
    <Tooltip hideArrow noTransform inheritVisibility x='-250'>
      <Card width='756px'>
        <SectionTitle
          title={HOLDINGS_FILTERS_COPY.asset_type.title}
          className='filter-title'
        />
        <p className='filter-description'>
          {HOLDINGS_FILTERS_COPY.asset_type.description}
        </p>
        <StyledCheckboxContainer>
          {options?.map(item => {
            if (`${item.label}` !== 'null') {
              return (
                <>
                  <Form.Check
                    type={'checkbox'}
                    id={item.value}
                    label={`${item.label} (${item?.count ? item?.count : 0})`}
                    value={item.value}
                    checked={data?.[item.value] ? true : false}
                    onChange={() => onChange?.(item?.value)}
                  />
                </>
              )
            }
          })}
        </StyledCheckboxContainer>
        <div className='form-buttons-wrapper'>
          <div className='unstyled-button cancel-button'>
            <button onClick={() => onCancel?.('asset-type')}>Cancel</button>
          </div>
          <FilledButton
            onClick={() => onUpdate?.('asset-type')}
            label='UPDATE'
            borderRadio={'25px'}
            width='150px'
          />
        </div>
      </Card>
    </Tooltip>
  )
}

export default AssetTypeTooltip
