import React from 'react'
import styled from 'styled-components'

import TextView from '../../../../components/styleguide/TextView'
import Tooltip from '../../../../components/styleguide/Tooltip'
import {
  ExplainerLink,
  LinkText,
} from '../../../../yb_components/styleguide/links/Link.styles'
import TooltipWrapper from '../../../../yb_components/styleguide/tooltips/tooltip_wrapper/TooltipWrapper'
import { DEFAULT_ESG_SCORE_EXPLAINER_URL } from '../../../../yb_constants/urls/explainers'
import { computeColor, formatRangeScore } from './helpers'
import { StyledCell, StyledSecondaryCellText, StyleMeasureType } from './styles'

const CustomExplainerLink = styled(ExplainerLink)`
  float: right;

  ${LinkText} {
    margin-top: 5px;
  }
`

const ScoreTableRow = ({
  peers,
  scoreName,
  retrieveScore,
  retrieveScoreRange,
  normUnits,
  normUnitsShort,
  neutralIsZero = false,
  showTooltip = false,
  scoreFormatter,
  extraClassNames = [],
  dataDisplayType = 'zscore',
}) => {
  const renderTooltip = () => {
    if (!showTooltip) return

    return (
      <Tooltip
        noTransform
        inheritVisibility
        height={'120px'}
        width={'220px'}
        position={'relative'}
        padding={10}
        arrowPosition={'TOP'}
        y={'20'}
      >
        <TextView bold text={'What kind of score is this?'} />
        <TextView
          secondary
          size={12}
          text={
            'Normalized Z-Score. Z-Scores are used to standardize different types of data so they can be used together.'
          }
        />

        <CustomExplainerLink
          href={DEFAULT_ESG_SCORE_EXPLAINER_URL}
          target='_blank'
        >
          <LinkText>More information</LinkText>
        </CustomExplainerLink>
      </Tooltip>
    )
  }

  return (
    <>
      <tr>
        <td className={`name-column ${extraClassNames.join(' ')}`}>
          {scoreName}

          <StyleMeasureType>
            {normUnits ? `(${normUnits})` : ''}
          </StyleMeasureType>
        </td>
        {peers.map(entity => {
          const score = retrieveScore(entity)
          const scoreRange = retrieveScoreRange?.(entity)
          const fMin = formatRangeScore(scoreRange?.min)
          const fMax = formatRangeScore(scoreRange?.max)

          return (
            <>
              <StyledCell
                key={`table-cell-${entity.id}`}
                color={
                  dataDisplayType == 'zscore'
                    ? computeColor(score, neutralIsZero)
                    : '#979CA6'
                }
              >
                <TooltipWrapper tooltip={renderTooltip()} margin={10}>
                  {scoreFormatter ? scoreFormatter?.(score) : score}
                  <StyledSecondaryCellText>
                    {dataDisplayType == 'zscore' &&
                      fMin &&
                      fMax &&
                      `(${fMin} to ${fMax}${
                        normUnitsShort ? ` ${normUnitsShort}` : ''
                      })`}
                  </StyledSecondaryCellText>
                </TooltipWrapper>
              </StyledCell>
            </>
          )
        })}
      </tr>
    </>
  )
}

export default ScoreTableRow
