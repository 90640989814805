import React from 'react'
import styled from 'styled-components'
import { APP_COLOR_PRIMARY, BG_COLOR_PRIMARY } from '../../styles/colors'

const EventGrouping = styled.div`
  margin: 10px 0;
  & div + div {
    border-top: 2px solid ${BG_COLOR_PRIMARY};
  }
  & a {
    color: ${APP_COLOR_PRIMARY};
  }
`

export default EventGrouping
