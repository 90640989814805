import useInputValidations from '@env/hooks/forms/useInputValidations'
import { useMemo, useState } from 'react'

export type TFormValidation<IFormData> = (
  formData: IFormData
) => string | undefined

export type TFormValidations<IFormData> = {
  [key: string]: [YB.TModel<string>, TFormValidation<IFormData>[]]
}

export type TFormModels = Record<string, YB.TModel<unknown>>

export type TInputModel<T> = [
  YB.TModel<T>,
  YB.TModel<string>,
  YB.TModel<string>
]

export default class Form<IFormData> {
  useFormValidity(feedbackMessages: YB.TModel<string>[]): boolean {
    return useMemo(
      () => !feedbackMessages.some(message => !!message?.[0]),
      [feedbackMessages]
    )
  }

  useFormData(formModel: TFormModels): IFormData {
    const formData: Record<string, unknown> = {}

    for (const model in formModel) {
      const value = formModel?.[model]?.[0]

      formData[model] = value
    }

    return formData as IFormData
  }

  useFormDataSnapshot(formData: IFormData) {
    return useState<IFormData>(formData)
  }

  useInputModel<T>(defaultValue: T): TInputModel<T> {
    return [
      useState<T>(defaultValue),
      useState<string>(''),
      useState<string>(''),
    ]
  }

  useFormValidations(
    formValidations: TFormValidations<IFormData>,
    formData: IFormData
  ) {
    Object.values(formValidations).forEach(formValidation => {
      const [feedbackModel, validations] = formValidation

      useInputValidations(formData, validations, feedbackModel)
    })
  }

  setFormModel(formModel: TFormModels, formData: IFormData) {
    for (const model in formModel) {
      const [, set] = formModel[model]
      const value = formData[model as keyof IFormData]

      set(value)
    }
  }
}
