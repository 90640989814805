import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import { FONT_COLOR_SECONDARY } from '../../../../../styles/colors'
import { tcfdRiskHardcodeItems } from '../../constants/tcfd_holding_details_data'
import { updateTcfdCustomWeight } from '../../repositories/tcfd_repository'
import { StyledTable } from './CustomScoringTable.styles'

const CancelButton = styled.button`
  color: ${FONT_COLOR_SECONDARY};
  background: none;
  border: none;
  font-size: 16px;
`

export const CustomScoringTable = ({
  model,
  onHide,
  portfolioId,
  snapshotId,
  updateHoldings,
}) => {
  const sanitizeModelNumber = num => {
    if (num == null) return
    return Math.round(num * 100)
  }

  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      policy_and_legislation: sanitizeModelNumber(
        model?.policy_and_legislation.weight
      ),
      technology_and_adaptation: sanitizeModelNumber(
        model?.technology_and_adaptation.weight
      ),
      market_risk_1: sanitizeModelNumber(model?.market_risk_1.weight),
      market_risk_2: sanitizeModelNumber(model?.market_risk_2.weight),
      market_risk_3: sanitizeModelNumber(model?.market_risk_3.weight),
      reputation_1: sanitizeModelNumber(model?.reputation_1.weight),
      reputation_2: sanitizeModelNumber(model?.reputation_2.weight),
      physical_risk_1: sanitizeModelNumber(model?.physical_risk_1.weight),
      physical_risk_2: sanitizeModelNumber(model?.physical_risk_2.weight),
    },
  })

  const onFormSubmit = async data => {
    for (const key in data) {
      if (data[key].length < 1) data[key] = 0
      if (data[key] > 0) data[key] = data[key] / 100
      data[key] = Number(data[key])
    }

    try {
      await updateTcfdCustomWeight(portfolioId, snapshotId, data)
      updateHoldings()
    } catch (e) {
      console.error(e)
    }
    onHide()
  }

  const [customScoresSum, setCustomScoresSum] = useState(100)

  // watches form for sums !== 100
  watch(data => {
    let sum = 0
    for (const key in data) {
      sum += Number(data[key])
    }
    setCustomScoresSum(sum)
  })

  const handleCancel = e => {
    e.preventDefault()
    onHide()
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <StyledTable>
        <thead>
          <tr>
            <th>Metric</th>
            <th>YB Standard Weight</th>
            <th>Original Weight</th>
            <th>Custom Weight</th>
          </tr>
        </thead>
        <tbody>
          {tcfdRiskHardcodeItems.map(el => (
            <tr key={el.id}>
              <td className='metric'>
                <strong>{el.name}</strong>
              </td>
              <td>{sanitizeModelNumber(model?.[el.id].yb_weight)}%</td>
              <td>{sanitizeModelNumber(model?.[el.id].weight)}%</td>
              <td className='custom-weight-cell'>
                <input
                  type='number'
                  id={el.id}
                  name={el.id}
                  {...register(el.id)}
                  min='0'
                  max='100'
                  step='1'
                />
                <span className='percent-sign'>%</span>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td style={{ border: 'none' }} colSpan='3'>
              <strong
                style={{
                  color: customScoresSum !== 100 ? 'var(--danger)' : '',
                }}
              >
                Total Weight:
              </strong>
            </td>
            <td
              style={{ color: customScoresSum !== 100 ? 'var(--danger)' : '' }}
            >
              {customScoresSum}%
            </td>
          </tr>
        </tfoot>
      </StyledTable>
      <p
        style={{
          margin: '.5rem 0 1rem',
          width: '100%',
          fontSize: '12px',
          lineHeight: 1.2,
          color: 'var(--danger)',
          textAlign: 'right',
          visibility: customScoresSum === 100 ? 'hidden' : 'visible',
        }}
      >
        Custom Weights must add up to 100%.
      </p>
      <footer
        style={{
          padding: '20px 0 0',
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'flex-end',
          gap: '10px',
        }}
      >
        <CancelButton
          style={{
            padding: '12px 32px',
            borderRadius: '25px',
            fontSize: '14px',
          }}
          onClick={e => handleCancel(e)}
        >
          Cancel
        </CancelButton>
        <Button
          style={{
            padding: '12px 32px',
            borderRadius: '25px',
            fontSize: '14px',
            fontWeight: 700,
          }}
          disabled={customScoresSum !== 100}
          type='submit'
        >
          SAVE CHANGES
        </Button>
      </footer>
    </form>
  )
}

CustomScoringTable.propTypes = {
  model: PropTypes.object.isRequired,
  onHide: PropTypes.func,
  portfolioId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  snapshotId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  updateHoldings: PropTypes.func.isRequired,
}
