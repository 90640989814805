import { APP_COLOR_PRIMARY, BG_COLOR_SECONDARY, FONT_COLOR_SECONDARY } from "../../../styles/colors";

export const defaultStyles = (hasValue) => {
    return ({
        container: () => ({ width: '100%', marginLeft: '5px' }),
        input: () => ({ height: 40 }),
        menu: style => ({ ...style, top: null, marginTop: 0, width: '30%' }),
        singleValue: () => ({
            padding: '5px',
            color: '#3D3D3D',
            fontSize: '10px',

            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineWidth: '10px'
        }),
        placeholder: () => ({
            padding: '5px',
            color: FONT_COLOR_SECONDARY,
            fontSize: '10px',

            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineWidth: '10px'
        }),
        indicatorSeparator: () => ({ display: 'none' }),
        clearIndicator: () => ({}),
        selectContainer: () => ({}),
        valueContainer: () => ({
            width: '70%',
            justifyContent: 'center',
            display: hasValue ? 'block' : 'flex'
        }),
        indicatorsContainer: () => ({ width: '30%' }),
        dropdownIndicator: () => ({ width: '20%' }),

        multiValueLabel: style => ({ ...style, color: APP_COLOR_PRIMARY }),
        control: styles => ({
            ...styles,
            height: hasValue ? 'auto' : 30,
            minHeight: hasValue ? 'auto' : 30,
            width: '100%',
            backgroundColor: 'white',
            marginLeft: '10px',
            borderRadius: '9.5px',
            borderColor: '#3D3D3D',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: "#E9EFFF",
            color: APP_COLOR_PRIMARY,
            borderRadius: '20.5px',
            fontWeight: 'bold',
            padding: '3.5px',
            maxWidth: '50px',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            ':hover': {
                backgroundColor: APP_COLOR_PRIMARY,
                color: FONT_COLOR_SECONDARY,
                borderRadius: '12.5px',
                fontWeight: 'bold'
            },
        })
    })
};