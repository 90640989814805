import React from 'react'

import Modal from '../../../../yb_components/styleguide/modal/Modal'
import { StyledModalContent } from '../../CustomESGScoring.styles'

const DiscardPresetModal = ({ show, dispatch, setShow }) => {
  const handleSubmit = () => {
    dispatch({ type: 'discard-preset', payload: {} })
    setShow(false)
  }
  return (
    <Modal
      show={show}
      setShow={setShow}
      title='Discard Preset'
      submitText='Discard Preset'
      onSubmit={() => handleSubmit()}
    >
      <StyledModalContent>
        <div className='description'>
          Are you sure you want to discard preset changes? The settings will
          return to Yves Blue defaults
        </div>
      </StyledModalContent>
    </Modal>
  )
}

export default DiscardPresetModal
