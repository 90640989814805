export const PAI_ENTITIES_TYPES_OPTIONS = [
  { id: 'all', label: 'All', active: true },
  { id: 'companies', label: 'Companies', active: false },
  { id: 'funds', label: 'Funds', active: false },
]

export const PAI_REQUIRED_OPTIONS = [
  { id: 'all', label: 'All', active: true },
  { id: 'Table 1', label: 'Table 1', active: false },
  { id: 'Table 2', label: 'Table 2', active: false },
  { id: 'Table 3', label: 'Table 3', active: false },
]

export const PAI_COMPLETION_OPTIONS = [
  { id: 'all', label: 'All', active: true },
  { id: 'low', label: 'Low', active: false },
  { id: 'medium', label: 'Medium', active: false },
  { id: 'high', label: 'High', active: false },
]
