import dayjs from 'dayjs'
import React from 'react'

const SnapshotUpdatedEvent = ({ event }) => {
  const formattedAsOf = dayjs(event.data.snapshot_as_of).format('MMM D, YYYY')

  return (
    <>
      <span className='event-user-name'>{event.context.full_name}</span> updated
      the portfolio snapshot{' '}
      {event.targetExists ? (
        <a
          href={`/app/portfolios/${event.data.portfolio_id}/snapshots/${event.data.snapshot_id}`}
        >
          {event.data.portfolio_name}
        </a>
      ) : (
        <span className='event-user-name'>{event.data.portfolio_name}</span>
      )}{' '}
      with the as of {formattedAsOf}.
      <span className='event-date'>{event.formattedTimestamp}</span>
    </>
  )
}

export default SnapshotUpdatedEvent
