import React from 'react';
import injectSheet from 'react-jss';

const SortButton = ({ classes, active }) => {
  return (
    <div className={classes.container}>
      <div>
        <img className="asc" src={`/ico_sort_${active == "asc" ? "enabled" : "disabled"}.svg`}/>
        <img className="desc" src={`/ico_sort_${active == "desc" ? "enabled" : "disabled"}.svg`}/>
      </div>
    </div>
  );
}

const style = { 
  container: {
    marginLeft: 8,

    '& > *': {
      display: 'flex',
      flexDirection: 'column',

      '& img': {
        height: 9,

        '&:first-child': {
          transform: 'rotateZ(180deg)',
          marginBottom: -2
        }
      }
    }
  } 
};

export default injectSheet(style)(SortButton);