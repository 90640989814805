export const APP_COLOR_PRIMARY = '#002fa7'
export const APP_COLOR_PRIMARY_DARK = '#00127C'
export const APP_COLOR_PRIMARY_LIGHT = '#8aabff'

export const APP_COLOR_PRIMARY_300 = '#dae4ff'

export const APP_COLOR_SUCCESS = '#45D56E'
export const APP_COLOR_WARN = '#E7BB4B'
export const APP_COLOR_DANGER = '#E7924C'
export const APP_COLOR_ERROR = `#a94442`
export const FONT_COLOR_TERTIARY = '#6C757D'

export const FONT_COLOR_PRIMARY = '#2E384D'
export const FONT_COLOR_SECONDARY = '#979CA6'
export const FONT_COLOR_WHITE = '#FFF'
export const FONT_COLOR_ACTIVE = '#00D66E'
export const FONT_COLOR_GREEN = '#00BA60'

export const BTN_COLOR_PRIMARY = '#002fa7'
export const BORDER_COLOR_SECONDARY = '#E4E6E5'
export const BORDER_COLOR_TERTIARY = '#f0f0f0'
export const BORDER_COLOR_LIGHT = '#EDEEF3'
export const BORDER_COLOR_GRAY_ALT = '#E8ECF2'

export const BG_COLOR_PRIMARY = '#F8F9FA'
export const BG_COLOR_SECONDARY = '#E6E7EA'
export const BG_COLOR_TERTIARY = '#F2F2F2'
export const BG_COLOR_WHITE = '#FFF'
export const BG_COLOR_LIGHT_BLUE = '#F3F6FF'
export const BG_COLOR_DARK_BLUE = '#E2E7F5'
export const BG_COLOR_GRAY_ALT = '#F4F6F9'
export const BORDER_COLOR_PRIMARY = '#D8D8D8'
export const LIST_HEADER_PRIMARY = '#D0D8EF'

export const CHART_COLOR_GRID_LINE = '#D8D8D8'

export const CHART_COLOR_GREEN = '#00B1BA'
export const CHART_COLOR_ORANGE = '#FF7659'
export const CHART_HIGH_RISK = '#FFF6F6'

export const CHART_COLOR_E_SCORE = '#00B1BA'
export const CHART_COLOR_S_SCORE = '#FF7659'
export const CHART_COLOR_G_SCORE = '#002FA7'
export const CHART_COLOR_BENCHMARK = '#E7BB4C'

export const LIGHT_GREEN_1 = '#00D66E'

export const DARK_GREY_1 = '#2E384D'

export const LIGHT_GREY_5 = '#979CA6'

export const ESG_SCALE_COLOR_BAD = '#C0C2CB'
export const ESG_SCALE_COLOR_POOR = '#B3B8C9'
export const ESG_SCALE_COLOR_BELOW_AVERAGE = '#92A0C6'
export const ESG_SCALE_COLOR_ABOVE_AVERAGE = '#6E7FC0'
export const ESG_SCALE_COLOR_GOOD = '#586CBB'
export const ESG_SCALE_COLOR_SUPERIOR = '#2242AF'
export const ESG_SCALE_COLOR_EXCELLENT = '#002FA7'

export const POSITIVE_TREND_COLOR = '#20c997'
export const NEGATIVE_TREND_COLOR = '#b02a37'

export const BENCHMARK_COMPARISON_POSITIVE_COLOR = '#20c997'
export const BENCHMARK_COMPARISON_NEGATIVE_COLOR = '#D02F44'

export const SubsidyLevelColor = {
  FEDERAL: '#8097D3',
  STATE: '#002FA7',
  LOCAL: '#8AABFF',
  MULTIPLE: '#3359B9',
}

export const SUBSIDIES_VIOLATIONS_COLOR_SCHEME = [
  '#8097D3',
  '#002FA7',
  '#8AABFF',
  '#3359B9',
  '#6682CA',
  '#99ACDC',
]

export const PIE_COLOR_SCHEME = {
  color1: BG_COLOR_LIGHT_BLUE,
  color2: APP_COLOR_PRIMARY,
}

export const HEAT_MAP_COLOR_SCHEME = [
  '#CCD5ED',
  '#99ACDC',
  '#6682CA',
  '#3359B9',
  '#002FA7',
]

export const CARBON_GRADIENT_BLUE = ['#00127C', '#002FA7', '#8097D3', '#8AABFF']

export const SDG_COLORS = [
  APP_COLOR_PRIMARY,
  '#E5243B',
  '#DDA63A',
  '#4C9F38',
  '#C5192D',
  '#FF3A21',
  '#26BDE2',
  '#FCC30B',
  '#A21942',
  '#FD6925',
  '#DD1367',
  '#FD9D24',
  '#BF8B2E',
  '#3F7E44',
  '#0A97D9',
  '#56C02B',
  '#00689D',
  '#19486A',
]

export const STACKED_CHART_SET_GREEN = [
  '#03DE79',
  '#03D666',
  '#03CB61',
  '#02BE5B',
  '#02B757',
  '#02B155',
  '#02A34F',
  '#00C4CD',
  '#04BAC2',
  '#00AEB6',
  '#00A9B0',
  '#00A0A7',
  '#00979D',
  '#007E83',
  '#0E8CFA',
  '#0084F6',
  '#007BE4',
  '#0072D3',
  '#006CC7',
  '#0066BC',
  '#005BA7',
  '#3960F6',
  '#355BEC',
  '#2D51DD',
  '#2A4CCF',
  '#1D3CB8',
  '#002A9D',
  '#001371',
]

export const STACKED_CHART_SET_RED = [
  '#AE101F',
  '#C91627',
  '#CF1C2E',
  '#D52536',
  '#DA2D3D',
  '#E23144',
  '#E83547',
  '#FC4122',
  '#FF5031',
  '#FF583A',
  '#FD6145',
  '#FF6A4F',
  '#FF745A',
  '#FF7E66',
  '#FD6F12',
  '#F77A25',
  '#F58334',
  '#F68D40',
  '#F7964F',
  '#F89F5D',
  '#FFAB5D',
  '#DF9802',
  '#E5A010',
  '#EAAC23',
  '#F4B832',
  '#F5BD41',
  '#F6C452',
  '#F8CB66',
]

export const STACKED_CHART_SET_DARK = [
  '#494554',
  '#4C4957',
  '#504D5A',
  '#53515D',
  '#565560',
  '#5A5963',
  '#5D5E67',
  '#61626A',
  '#64666D',
  '#676A70',
  '#6B6E73',
  '#6E7276',
  '#72787A',
  '#777D7E',
  '#7B8382',
  '#7F8886',
  '#848E8A',
  '#88938E',
  '#8D9992',
  '#919E96',
  '#95A49A',
  '#9AA99E',
  '#9EAFA2',
]

export const STACKED_CHART_SET_TABLEAU = [
  '#4F9746',
  '#FFB572',
  '#F08326',
  '#96C4E5',
  '#446E9D',
  '#D2AC9C',
  '#936B55',
  '#CE9CC1',
  '#A76F97',
  '#F9B7CC',
  '#CD678A',
  '#B2A7A2',
  '#6E6563',
  '#FF928F',
  '#DD4D4F',
  '#7BB4AD',
  '#408D89',
  '#EFC758',
  '#AD8E28',
  '#81CB72',
  '#4F9746',
  '#FFB572',
  '#F08326',
  '#96C4E5',
  '#446E9D',
  '#D2AC9C',
  '#936B55',
  '#CE9CC1',
  '#A76F97',
  '#F9B7CC',
]
