import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {
  APP_COLOR_PRIMARY,
  BG_COLOR_PRIMARY,
  BG_COLOR_SECONDARY,
  FONT_COLOR_SECONDARY,
} from '../../styles/colors'

const COLORS = {
  active: {
    text: APP_COLOR_PRIMARY,
    bg: BG_COLOR_PRIMARY,
  },
  inactive: {
    text: FONT_COLOR_SECONDARY,
    bg: 'white',
  },
}

const StyledDiv = styled.div`
  ${props => props.customStyle && props.customStyle}
`

const SwitchOption = styled.button`
  height: 38px;
  padding: 10px;
  color: ${props => props.color};
  background-color: ${props => props.bgColor};
  padding: 0 15px;
  line-height: 38px;
  border: 1px solid ${BG_COLOR_SECONDARY};
  font-weight: bold;
  ${props => props.disabled && 'opacity: 0.6;'}
  &:first-child {
    border-radius: 19px 0 0 19px;
  }

  &:last-child {
    border-radius: 0 19px 19px 0;
  }
`

const SwitchButton = ({
  loading,
  options,
  active,
  onClick,
  className,
  customStyle,
  disabledOptions,
  switchStyle,
}) => {
  return (
    <StyledDiv className={className} customStyle={customStyle}>
      {options.map(opt => {
        let disabled
        disabledOptions &&
          disabledOptions.forEach(id => {
            if (id === opt.id) {
              disabled = true
            }
          })

        if (disabled === undefined) {
          disabled = loading
        }
        const { text, bg } = opt.id === active ? COLORS.active : COLORS.inactive
        return (
          <SwitchOption
            disabled={disabled}
            onClick={onClick?.bind(null, opt.id)}
            key={opt.id}
            id={opt.id}
            color={text}
            bgColor={bg}
            style={{ ...switchStyle }}
          >
            {opt.label}
          </SwitchOption>
        )
      })}
    </StyledDiv>
  )
}

SwitchOption.propTypes = {
  loading: PropTypes.bool,
  options: PropTypes.array,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  customStyle: PropTypes.string,
  disabledOptions: PropTypes.array,
}

export default SwitchButton
