import { Col, Row } from 'react-bootstrap'
import styled, { css } from 'styled-components'

import {
  BG_COLOR_PRIMARY,
  BORDER_COLOR_LIGHT,
  NEGATIVE_TREND_COLOR,
  POSITIVE_TREND_COLOR,
} from '../../../styles/colors'

export const StyledContainer = styled.div`
  margin-top: ${props => (props.isPdfTemplate ? '30px' : '0px')};
  margin-bottom: 20px;
  width: 100%;
  display: inline-block;
  flex-direction: row;
`

export const AlignCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledTitle = styled.div`
  ${AlignCenter}
  height: ${props => (props.isPdfTemplate ? '30px' : '50px')};
  margin-bottom: ${props => (props.isPdfTemplate ? '0px' : '0px')};
  font-size: ${props => (props.isPdfTemplate ? '11px' : '14px')};
  font-weight: bold;
  justify-content: flex-start;
`

export const StyledRow = styled(Row)`
  height: ${props => (props.isPdfTemplate ? '35px' : '50px')};
  border-bottom: 1px solid ${BORDER_COLOR_LIGHT};
  margin-left: 5px;

  :nth-child(odd) {
    background-color: ${BG_COLOR_PRIMARY};
  }
`

export const StyledLabel = styled.div`
  ${AlignCenter}
  height: 100%;
  margin-left: 20px;
  font-size: ${props => (props.isPdfTemplate ? '11px' : '14px')};
  font-weight: bold;

  justify-content: flex-start;
`

export const StyledScoreContainer = styled(Col)`
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => (props.isPdfTemplate ? '-24px' : '0px')};
  margin-right: ${props => (props.isPdfTemplate ? '10px' : '0px')};
`
export const StyledScore = styled.div`
  ${AlignCenter}
  font-size: ${props => (props.isPdfTemplate ? '11px' : '14px')};
  font-weight: bold;
`

export const StyledIconContainer = styled.div`
  ${AlignCenter}
  margin-top: ${props => (props.isPdfTemplate ? '30px' : '0px')};
  font-size: ${props => (props.isPdfTemplate ? '7px' : '10px')};
  margin-left: 10px;

  .positive-icon {
    color: ${POSITIVE_TREND_COLOR};
  }

  .negative-icon {
    color: ${NEGATIVE_TREND_COLOR};
  }
`
