import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'

import { getLatestResearch } from '../../../repositories/managers_repository'
import SpinnerContainer from '../../../yb_components/styleguide/spinners/SpinnerContainer'
import KaminariPagination from './KaminariPagination'

const LatestResearch = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [latestResearch, setLatestResearch] = useState(null)

  useEffect(() => {
    getLatestResearch().then(result => {
      setLatestResearch(result.data)
    })
  }, [])

  const fetchPage = page => {
    getLatestResearch(page).then(result => {
      setLatestResearch(result.data)
      setCurrentPage(page)
    })
  }

  return (
    <>
      <h3>Latest Research</h3>
      <SpinnerContainer isLoading={!latestResearch}>
        <table className='table table-sm table-striped'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Asset Class</th>
              <th>Score</th>
              <th>Analyst</th>
            </tr>
          </thead>
          <tbody>
            {(latestResearch?.funds || []).map(fund => (
              <tr key={fund.id}>
                <td>
                  <a href={fund.link}>{fund.name}</a>
                </td>
                <td>
                  <span className='badge badge-secondary'>
                    {fund.asset_class_name}
                  </span>
                </td>
                <td>{fund.latest_matrix_score}</td>
                <td>
                  {fund.lead_analyst.email && (
                    <a href={`mailto:${fund.lead_analyst.email}`}>
                      {fund.lead_analyst.full_name}
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <small className='d-block text-muted text-right'>
          As of {dayjs(latestResearch?.as_of).format('MMMM D, YYYY')}
        </small>
        <KaminariPagination
          currentPage={currentPage}
          totalPages={latestResearch?.total_pages}
          fetchPage={fetchPage}
        />
      </SpinnerContainer>
    </>
  )
}

export default LatestResearch
