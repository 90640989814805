import React, { isValidElement, ReactNode, useMemo } from 'react'
import PieChart from '../../../../react/yb_components/charts/pie_chart/PieChart'
import { ColoringMethod } from '../../../../react/styles/enums'
import styled from 'styled-components'
import { FONT_COLOR_SECONDARY } from '../../../../react/styles/colors'

interface IProps {
  data: {
    excluding_sovereigns: {
      non_taxonomy_aligned: number
      taxonomy_aligned_fossil_gas: number
      taxonomy_aligned_nuclear: number
      taxonomy_aligned_other: number
    }
    including_sovereigns: {
      non_taxonomy_aligned: number
      taxonomy_aligned_fossil_gas: number
      taxonomy_aligned_nuclear: number
      taxonomy_aligned_other: number
    }
  }
}

const COLOR_PALLETTE = ['#7F997E', '#4c704d', '#013300', '#d9d9d9']

const PIE_CHART_DEFAULT_PROPS = {
  colorScheme: COLOR_PALLETTE,
  coloringMethod: ColoringMethod.FROM_SCHEME,
  margin: { top: 20, bottom: 20, right: 40, left: 40 },
  width: '300px',
  height: '200px',
  arcLinkLabel: e => `${e.value}%`,
  legends: [
    {
      anchor: 'left',
      direction: 'column',
      justify: false,
      translateX: 0,
      translateY: 0,
      itemWidth: 100,
      itemHeight: 20,
      itemsSpacing: 7,
      symbolSize: 15,
      itemDirection: 'left-to-right',
    },
  ],
}

const S = {
  PieChartContainer: styled.div`
    display: inline;
    width: 50%;
    height: 300px;
  `,

  Title: styled.div`
    color: ${FONT_COLOR_SECONDARY};
    font-size: 12px;
    text-align: center;
  `,

  Bold: styled.div`
    font-weight: bold;
  `,

  Center: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
}

export const PieCharts: React.FC<IProps> = ({ data }) => {
  const formatData = aData => {
    return [
      {
        index: 0,
        id: 'taxonomy_aligned_fossil_gas',
        label: 'Taxonomy-aligned: Fossil gas',
        value: (aData?.taxonomy_aligned_fossil_gas || 0)?.toFixed(2),
      },
      {
        index: 1,
        id: 'taxonomy_aligned_nuclear',
        label: 'Taxonomy-aligned: Nuclear',
        value: (aData?.taxonomy_aligned_nuclear || 0)?.toFixed(2),
      },
      {
        index: 2,
        id: 'taxonomy_aligned_other',
        label: 'Taxonomy-aligned (no fossil gas & nuclear)',
        value: (aData?.taxonomy_aligned_other || 0)?.toFixed(2),
      },
      {
        index: 3,
        id: 'non_taxonomy_aligned',
        label: 'Non Taxonomy-aligned',
        value: (aData?.non_taxonomy_aligned || 0)?.toFixed(2),
      },
    ]
  }

  const excluding_sovereigns = useMemo(
    () => formatData(data?.excluding_sovereigns),
    [data]
  )

  const including_sovereigns = useMemo(
    () => formatData(data?.including_sovereigns),
    [data]
  )

  return (
    <div>
      <S.PieChartContainer
        style={{
          float: 'left',
        }}
      >
        <S.Title>
          1. Taxonomy-alignment of investments <br />
          <S.Bold>including sovereign bonds*</S.Bold>
        </S.Title>
        <S.Center>
          <PieChart {...PIE_CHART_DEFAULT_PROPS} data={including_sovereigns} />
        </S.Center>
      </S.PieChartContainer>
      <S.PieChartContainer
        style={{
          float: 'right',
        }}
      >
        <S.Title>
          1. Taxonomy-alignment of investments <br />
          <S.Bold>excluding sovereign bonds*</S.Bold>
        </S.Title>
        <S.Center>
          <PieChart {...PIE_CHART_DEFAULT_PROPS} data={excluding_sovereigns} />
        </S.Center>
      </S.PieChartContainer>
    </div>
  )
}
