import propTypes from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

import { getProgressColor } from '../../helpers/esg_scale'
import CircularProgress from './CircularProgress'
import DeltaIndicator from './DeltaIndicator'

const ESGCircularScore = ({
  progress,
  benchmarkScore,
  benchmarkName,
  delta,
  label,
  classes,
  size,
  customESGLabels,
}) => {
  const benchmarkDifference = progress - benchmarkScore

  return (
    <div className={classes.score}>
      <CircularProgress
        color={getProgressColor(Math.round(+progress), customESGLabels)}
        size={size}
        progress={+progress}
        stroke={0.14 * size}
      >
        <label className={classes.center}>{progress}</label>
      </CircularProgress>
      <label>{label}</label>
      {benchmarkScore && (
        <div style={{ color: benchmarkDifference > 0 ? '#00D679' : '#D02F44' }}>
          {`vs. ${benchmarkScore} for ${benchmarkName}`}
        </div>
      )}
      {delta && (
        <DeltaIndicator className='delta' down={delta < 50} delta={delta} />
      )}
    </div>
  )
}

ESGCircularScore.defaultProps = {
  size: 6,
}

ESGCircularScore.propTypes = {
  progress: propTypes.oneOfType([propTypes.string, propTypes.number])
    .isRequired,
  delta: propTypes.oneOfType([propTypes.string, propTypes.number]),
  label: propTypes.string.isRequired,
  classes: propTypes.object.isRequired,
}

const style = {
  score: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > label': {
      width: '100%',
      textAlign: 'center',
      fontSize: '0.75rem',
      fontWeight: 'bold',
      marginTop: '0.63rem',
      margin: 0,
    },
    '& .delta': {
      display: 'block',
      textAlign: 'center',
    },
  },
  center: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '1.13rem',
    margin: 0,
  },
}

export default injectSheet(style)(ESGCircularScore)
