import styled from 'styled-components'

export const Container = styled.div`
  grid-template-rows: min-content;
  background: #fafafa;
  padding: 10px;
  border-radius: 10px;

  h5 {
    text-transform: uppercase;
    font-size: 12px;
    color: #2e384d;
    font-weight: bold;
  }

  p {
    color: #979ca6;
    font-size: 14px;
  }
`
