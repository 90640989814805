import _ from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'

import { abbreviateNumber } from '../../../../../../helpers/number'
import { getTcfdColor } from '../../../../../../modules/portfolio/tcfd/utils/tcdf_colors'
import {
  BORDER_COLOR_SECONDARY,
  FONT_COLOR_TERTIARY,
} from '../../../../../../styles/colors'
import { AlignCenter } from '../../../../../yb_styles/layout'
import { PeersCellType } from '../TcfdHoldingDetail.constants'

const CellSize = css`
  width: 152px;
  height: 40px;
`

const StyledContainer = styled.div`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    padding: 10px;
  }

  tr:nth-child(even) {
    background-color: initial;
  }
`

const StyledRowHeader = styled.td`
  ${CellSize}
  color: ${FONT_COLOR_TERTIARY};
  border-right: 1px dashed ${BORDER_COLOR_SECONDARY};
  padding-right: 20px;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  text-transform: uppercase;
`

const StyledRowCell = styled.div`
  ${AlignCenter}
  ${CellSize}
  text-align: center;

  color: ${props => props.color || '#afafaf'};
  font-size: ${props => props.fontSize || '24px'};
`

const TcfdPeerTable = ({ item, data }) => {
  const getTypeStyle = (value, type) => {
    let color, fontSize, fValue
    fValue = value

    switch (type) {
      case PeersCellType.COLORED:
        if (value !== null && value !== undefined) color = getTcfdColor(value)
        break

      case PeersCellType.TEXT:
        break

      case PeersCellType.NUMBER:
      case PeersCellType.DEFAULT:
      default:
        fValue = abbreviateNumber(value)
        break
    }

    return { color, fontSize, fValue }
  }

  const renderRowCell = attrKey => {
    const elem = _.first(data)
    let value = elem?.[item?.key]?.[attrKey?.key]
    value = isNaN(parseInt(value)) ? value : parseInt(value)
    const { color, fontSize, fValue } = getTypeStyle(value, attrKey?.type)

    return (
      <td key={`${attrKey?.key}`}>
        <StyledRowCell color={color} fontSize={fontSize}>
          {fValue ?? '—'}
        </StyledRowCell>
      </td>
    )
  }

  return (
    <StyledContainer>
      <table>
        <tr>
          <th />
          <th />
        </tr>

        {item?.attrKeys?.map(attrKey => {
          return (
            <tr key={attrKey?.key}>
              <StyledRowHeader>{attrKey?.name}</StyledRowHeader>
              {renderRowCell(attrKey)}
            </tr>
          )
        })}
      </table>
    </StyledContainer>
  )
}

export default TcfdPeerTable
