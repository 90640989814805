import Form from 'react-bootstrap/Form'
import styled, { css } from 'styled-components'

import Card from '../../../components/styleguide/Card'
import SwitchButton from '../../../components/styleguide/SwitchButton'
import { FONT_COLOR_SECONDARY } from '../../../styles/colors'
import { ExplainerLink } from '../../styleguide/links/Link.styles'
import SpinnerContainer from '../../styleguide/spinners/SpinnerContainer'

export const StyledCard = styled(Card)`
  ${props => (props.pdf ? '' : 'height: fit-content;')}
  margin-bottom: 1.5rem;

  .carbon-budget-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .carbon-budget-card-sub-right-header {
    display: flex;
  }
  .carbon-budget-legend {
    padding: ${props => (props.pdf ? '0 80px 0 80px' : '0 0 0 80px')};
    display: flex;
    flex: 1;
    justify-content: space-around;
    margin-bottom: 30px;
    > * {
      vertical-align: top;
    }
  }
  .carbon-budget-description {
    margin: 10px 0;
  }

  .carbon-budget-disclaimer {
    margin-top: 10px;
  }

  .carbon-budget-filter-card {
    padding: 10px;
    background-color: #f8f9fa;
    margin-bottom: 20px;
    & > div {
      display: flex;
    }
    & > div:last-child {
      align-items: center;
      margin-top: 10px;
    }
    #dropdown-container {
      width: 240px;
      margin-left: 10px;
    }
    #show-only {
      color: #979ca6;
      font-weight: 700;
      font-size: 12px;
    }
  }

  .carbon-budget-body {
    ${props =>
      props.pdf
        ? ''
        : css`
            height: 100%;
            display: flex;
            justify-content: space-between;
          `}
  }

  .carbon-budget-graph {
    width: ${props => (props.pdf ? '100%' : '40%')};
    ${props =>
      props.pdf
        ? css`
            padding-left: 20%;
            padding-right: 20%;
          `
        : ''}
  }

  .over-budget-icon {
    color: #e74c4c;
  }
  .below-budget-icon {
    color: #00d66e;
  }
  .footer {
    display: flex;
    background-color: #f8f9fa;
    padding: 10px;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
    button {
      height: 33px;
    }
    i {
      margin-right: 5px;
      font-size: 16px;
    }
    .new-window-icon {
      margin-left: 5px;
    }
    > div {
      display: flex;
      gap: 10px;
    }
  }
`

export const CustomSpinnerContainer = styled(SpinnerContainer)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledExplainerLink = styled(ExplainerLink)`
  float: right;
  margin-left: 15px;
`

export const CustomSwitchButton = styled(SwitchButton)`
  margin-left: 15px;
`

export const StyledCheckBox = styled(Form.Check)`
  ${props => props.disabled && `opacity: 0.5;`}
  cursor: pointer;
  margin-right: 30px;
  input[type='checkbox'] {
    cursor: pointer;
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: ${props =>
      props.isChecked ? 'transparent' : FONT_COLOR_SECONDARY};
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: ${FONT_COLOR_SECONDARY};
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid ${FONT_COLOR_SECONDARY};
    border-radius: 0.15em;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
  }

  input[type='checkbox']::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 60ms transform ease-in-out;
    box-shadow: inset 1em 1em white;
    /* Windows High Contrast Mode */
    background-color: #979ca6;
  }

  input[type='checkbox']:checked::before {
    transform: scale(1);
  }

  label {
    cursor: pointer;
    color: #979ca6;
    font-size: 14px;
    position: relative;
    bottom: 5px;
    left: 30px;
  }
`
