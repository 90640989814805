import React, { useEffect } from 'react'

const renderHeader = ({ showValue, hasShorts }) => {
  if (hasShorts) {
    return (
      <>
        <tr>
          <th />
          <th style={{ paddingBottom: 0 }} colSpan='2'>
            WEIGHT (%)
          </th>
          {showValue && (
            <th style={{ paddingBottom: 0 }} colSpan='2'>
              VALUE ($ USD)
            </th>
          )}
        </tr>
        <tr>
          <th>ASSET CLASS</th>
          <th>SHORT</th>
          <th>LONG</th>
          {showValue && (
            <>
              <th>SHORT</th>
              <th>LONG</th>
            </>
          )}
        </tr>
      </>
    )
  } else {
    return (
      <tr>
        <th>ASSET CLASS</th>
        <th>WEIGHT</th>
        {showValue && <th>VALUE</th>}
      </tr>
    )
  }
}

const renderRow = ({ asset, index, showValue, hasShorts }) => {
  if (hasShorts) {
    return (
      <tr key={index}>
        <td>{asset.name}</td>
        <td className='weight'>
          <span className='weight'>
            {asset.weight.shorts.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
            %
          </span>
        </td>
        <td className='weight'>
          <span className='weight'>
            {asset.weight.longs.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
            %
          </span>
        </td>

        {showValue && (
          <>
            <td className='value'>
              <span className='value'>
                {asset.value.shorts.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </span>
            </td>
            <td className='value'>
              <span className='value'>
                {asset.value.longs.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </span>
            </td>
          </>
        )}
      </tr>
    )
  } else {
    return (
      <tr key={index}>
        <td>{asset.name}</td>
        <td className='weight'>
          <span className='weight'>
            {asset.weight.longs.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
            %
          </span>
        </td>

        {showValue && (
          <td className='value'>
            <span className='value'>
              {asset.value.longs.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </span>
          </td>
        )}
      </tr>
    )
  }
}

const renderTotals = ({ showValue, totalWeight, totalValue, hasShorts }) => {
  if (hasShorts) {
    return (
      showValue && (
        <tr className='total'>
          <td>Total</td>
          <td className='weight'>
            <span className='weight'>{totalWeight.shorts}%</span>
          </td>
          <td className='weight'>
            <span className='weight'>{totalWeight.longs}%</span>
          </td>
          {showValue && (
            <>
              <td className='value'>
                <span className='value'>
                  {totalValue.shorts.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </span>
              </td>
              <td className='value'>
                <span className='value'>
                  {totalValue.longs.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </span>
              </td>
            </>
          )}
        </tr>
      )
    )
  } else {
    return (
      showValue && (
        <tr className='total'>
          <td>Total</td>
          <td className='weight'>
            <span className='weight'>{totalWeight.longs}%</span>
          </td>
          {showValue && (
            <td className='value'>
              <span className='value'>
                {totalValue.longs.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </span>
            </td>
          )}
        </tr>
      )
    )
  }
}

const AssetAllocation = ({
  totalWeight,
  totalValue,
  assets,
  long,
  showValue = true,
  modeledWeight = null,
  unmodeledWeight = null,
  cashWeight = null,
}) => {
  const hasShorts = totalValue?.shorts != 0

  useEffect(() => {
    Object.defineProperty(assets, 'chunk', {
      value: function (chunkSize) {
        var R = []
        for (var i = 0; i < this.length; i += chunkSize)
          R.push(this.slice(i, i + chunkSize))
        return R
      },
    })
  }, [assets])

  return (
    <>
      {assets.chunk(12).map((aAssets, aIndex) => (
        <>
          <section
            className={
              long ? 'small asset-allocation-long' : 'small asset-allocation'
            }
            style={{ maxHeight: '600px' }}
          >
            {aIndex < 1 || aIndex % 2 === 0 ? (
              <h2>Asset Class Allocation</h2>
            ) : (
              <h2 style={{ height: '31px' }}></h2>
            )}
            <table key={aIndex}>
              <thead>{renderHeader({ showValue, hasShorts })}</thead>

              <tbody>
                {aAssets.map((a, i) => {
                  if (i === 14) {
                    return (
                      <>
                        <div>
                          {renderRow({
                            asset: a,
                            index: i,
                            showValue,
                            hasShorts,
                          })}
                        </div>
                      </>
                    )
                  }

                  return renderRow({
                    asset: a,
                    index: i,
                    showValue,
                    hasShorts,
                  })
                })}
                {renderTotals({
                  totalWeight,
                  totalValue,
                  hasShorts,
                  showValue,
                })}
              </tbody>
            </table>

            {!hasShorts && modeledWeight && unmodeledWeight && cashWeight && (
              <p style={{ fontSize: '0.90rem', color: '#979CA6' }}>
                {modeledWeight}% of uploaded portfolio value was modeled, with{' '}
                {unmodeledWeight}% unmodeled and {cashWeight}% cash. Modeled
                holdings are those able to be tied to entities with sufficient
                ESG data in the system to score. Modeled portfolio value is
                scaled up for certain analytics to reach 100%.
              </p>
            )}
          </section>
        </>
      ))}
    </>
  )
}

export default AssetAllocation
