export const tcfdPeerDisplay = {
  policy_and_legislation: 'tcfd',
  laws_and_policies: 'num',
  litigation_cases: 'num',
  sum: 'num',
  score: 'tcfd',
  sector_score: 'tcfd',
  technology_and_adaptation: 'tcfd',
  market_risk_1: 'tcfd',
  market_risk_2: 'tcfd',
  market_risk_3: 'tcfd',
  country: 'text',
  annual_emissions: 'num',
  annual_revenue: 'num',
  price_of_carbon: 'num',
  percentage_cost_of_carbon_vs_revenue: 'tcfd',
  reputation_1: 'tcfd',
  reputation_2: 'tcfd',
  industry_group: 'long_text',
  carbon_budget: 'tcfd',
  ocean_plastic: 'tcfd',
  water_pollution: 'tcfd',
  land_use: 'tcfd',
  deforestation: 'tcfd',
  biodiversity: 'tcfd',
  toxic_release: 'tcfd',
  e_waste: 'tcfd',
  overfishing: 'tcfd',
  chemicals_in_product: 'tcfd',
  heavy_water_use: 'tcfd',
  severe_operational_risk: 'tcfd',
  physical_risk_1: 'tcfd',
  sea_level_rise: 'tcfd',
  extreme_weather: 'tcfd',
  forest_fire: 'tcfd',
  drought: 'tcfd',
  flooding: 'tcfd',
  acute: 'tcfd',
  chronic: 'tcfd',
}
