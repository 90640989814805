import propTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button, Form, FormControl } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  createOrganizationUser,
  updateOrganizationUser,
} from '../../../../../repositories/organizations_repository'
import { UserAvatar } from '../../../../../yb_components/styleguide/user_avatars/UserAvatar'
import { USER_ROLE_OPTIONS } from '../../constants/userRoleOptions'
import { StyledSection } from './CreateUpdateUserForm.styles'

export const CreateUpdateUserForm = ({ user, isEditing }) => {
  const {
    register,
    getValues,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      avatar_url: user.avatar_url,
      department: user.department,
      email: user.email,
      first_name: user.first_name,
      full_name: user.full_name,
      last_name: user.last_name,
      status: user.status,
      title: user.title,
    },
  })
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState(null)
  // initials for user avatar when no full_name
  const [initials, setInitials] = useState('')
  useEffect(() => {
    const firstInitial =
      values?.first_name != null && values?.first_name.length
        ? values.first_name.charAt(0)
        : ''
    const lastInitial =
      values?.last_name != null && values?.last_name.length
        ? values.last_name.charAt(0)
        : ''
    setInitials(`${firstInitial}${lastInitial}`)
  }, [values])

  const [statusSelectDisabled, setStatusSelectDisabled] = useState(false)
  useEffect(() => {
    if (user?.status === 'active' || !isEditing) setStatusSelectDisabled(true)
  }, [user, isEditing])

  const onSubmit = async data => {
    // replace empty strings with null
    Object.keys(data).forEach(key => {
      if (data[key] === '') data[key] = null
    })
    if (isEditing) {
      try {
        setLoading(true)
        const res = await updateOrganizationUser(data, user.id)
        if (res.status === 200) {
          toast.success('User updated')
          window.location.href = '/app/organization/users'
        } else if (
          res.status === 422 &&
          res?.data?.email[0] === 'has_already_been_taken'
        ) {
          // show email taken error
          setEmailError()
        } else toast.error('Update user failed')
        setLoading(false)
      } catch (e) {
        console.error('err:', e)
        toast.error('Update user failed')
        setLoading(false)
      }
    } else {
      try {
        setLoading(true)
        const res = await createOrganizationUser(data)
        if (res?.status === 201) {
          toast.success('New user created')
          window.location.href = '/app/organization/users'
        } else if (
          res.status === 422 &&
          res?.data?.email[0] === 'has_already_been_taken'
        ) {
          // show email taken error
          setEmailError()
        } else toast.error('Create user failed')
        setLoading(false)
      } catch (e) {
        console.error('err:', e)
        toast.error('Create user failed')
        setLoading(false)
      }
    }
  }
  const onError = (errors, e) => {
    console.error('errors', errors, e)
  }
  const setEmailError = () => {
    // set error on form
    setError(
      'email',
      {
        type: 'has_already_been_taken',
        message: 'This email has already been registered',
      },
      { shouldFocus: true }
    )
    // show error toast
    toast.error('Create user failed. This email has already been registered.')
  }

  return (
    <StyledSection>
      <aside>
        <UserAvatar user={user} initials={initials} width={170} />
      </aside>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <main>
          <Form.Group>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type='text'
              {...register('first_name', { required: true, maxLength: 100 })}
              isInvalid={!!errors.first_name}
              onBlur={() => setValues(getValues())}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type='text'
              {...register('last_name', { required: true, maxLength: 100 })}
              isInvalid={!!errors.last_name}
              onBlur={() => setValues(getValues())}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type='email'
              {...register('email', {
                required: true,
                maxLength: 100,
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
              isInvalid={!!errors.email}
            />
            <FormControl.Feedback type='invalid'>
              {errors?.email?.type === 'has_already_been_taken'
                ? errors.email.message
                : `Please provide a valid email address`}
            </FormControl.Feedback>
          </Form.Group>
          <div className='split-wrapper'>
            <Form.Group>
              <Form.Label>Role</Form.Label>
              <Form.Control
                as='select'
                name='role_type'
                id='user_role_select'
                className='user-select'
                {...register('role_type', { required: true })}
                isInvalid={!!errors.role_type}
                defaultValue={user?.role ? user.role : 'writer'}
              >
                {user?.role !== 'owner' ? (
                  USER_ROLE_OPTIONS.filter(
                    opt => opt.value.length > 0 && opt.value !== 'owner'
                  ).map(opt => (
                    <option value={opt.value} key={opt.text}>
                      {opt.text}
                    </option>
                  ))
                ) : (
                  <option value={'owner'} key='Owner'>
                    Owner
                  </option>
                )}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Form.Control
                as='select'
                name='user_status_select'
                id='user_status_select'
                className='user-select'
                {...register('status', { required: true })}
                isInvalid={!!errors.status}
                defaultValue={'active'}
                disabled={statusSelectDisabled}
              >
                <option value='active'>Active</option>
                <option value='inactive'>Inactive</option>
              </Form.Control>
            </Form.Group>
          </div>
          <Form.Group>
            <Form.Label>Job Title</Form.Label>
            <Form.Control
              type='text'
              ref={register}
              {...register('title', { maxLength: 100 })}
              isInvalid={!!errors.title}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Department</Form.Label>
            <Form.Control
              type='text'
              {...register('department', { maxLength: 100 })}
              isInvalid={!!errors.department}
            />
          </Form.Group>
        </main>
        <Button
          variant='primary'
          type='submit'
          className='submit-btn'
          disabled={loading}
        >
          {isEditing ? 'Update User' : 'Create User'}
        </Button>
      </Form>
    </StyledSection>
  )
}

CreateUpdateUserForm.propTypes = {
  user: propTypes.object,
  isEditing: propTypes.bool,
}
CreateUpdateUserForm.defaultProps = {
  user: {},
  isEditing: false,
}
