import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'

import { formatObjectCasing } from '../../../../../../../repositories/utils'
import { APP_COLOR_PRIMARY } from '../../../../../../../styles/colors'
import { formatImpact } from '../../../../../../../yb_helpers/impact'

const StyledRadio = styled(Form.Check)`
  &:checked,
  &::before {
    color: #fff;
    border-color: ${APP_COLOR_PRIMARY};
    background-color: ${APP_COLOR_PRIMARY};
  }
`

const StyledTr = styled.tr`
  td {
    text-align: left;
  }

  .right {
    text-align: right;
  }
`

const PaiEntityDetailRow = ({
  indicator: {
    data_source: dataSource,
    indicator_id: indicatorId,
    impact_last_year_custom: impactLastYearCustom,
    impact_current_year_custom: impactCurrentYearCustom,
    indicator_name: name,
    unit,
  },
  indicator,
  onChange,
  disabled,
}) => {
  const isBool = unit === 'bool'
  const alignment = !isBool ? 'right' : null

  const [indicatorData, setIndicatorData] = useState({
    dataSource,
    impactCurrentYearCustom,
    impactLastYearCustom,
  })
  const [indicatorDataSnapshot] = useState(indicatorData)

  useEffect(() => {
    let hasChanges = false

    for (const field in indicatorDataSnapshot) {
      if (indicatorDataSnapshot[field] !== indicatorData[field]) {
        hasChanges = true
        break
      }
    }

    onChange(
      indicatorId,
      hasChanges ? { ...indicator, ...formatObjectCasing(indicatorData) } : null
    )
  }, [indicatorData])

  const handleIndicatorDataChange = (field, value) => {
    setIndicatorData({
      ...indicatorData,
      [field]: value,
    })
  }

  return (
    <StyledTr>
      <td>{name}</td>
      <td style={{ width: '120px' }}>{indicator.table}</td>
      <td>{unit ?? '—'}</td>
      <td style={{ width: '120px' }} className={alignment}>
        {formatImpact(indicator.impact_current_year_refinitiv, unit)}
      </td>
      <td>
        <StyledRadio
          type='radio'
          checked={indicatorData.dataSource === 'refinitiv'}
          name={indicatorId}
          disabled={disabled}
          onChange={() => handleIndicatorDataChange('dataSource', 'refinitiv')}
          id='data_vendor_refinitiv_radio'
        />
      </td>
      <td style={{ width: '120px' }} className={alignment}>
        {formatImpact(indicator.impact_last_year_refinitiv, unit)}
      </td>
      <td style={{ width: '120px' }} className={alignment}>
        {formatImpact(indicator.impact_current_year_ideal, unit)}
      </td>
      <td>
        <StyledRadio
          type='radio'
          checked={indicatorData.dataSource === 'idealratings'}
          disabled={disabled}
          onChange={() =>
            handleIndicatorDataChange('dataSource', 'idealratings')
          }
          name={indicatorId}
          id='data_vendor_ideal_radio'
        />
      </td>
      <td style={{ width: '120px' }} className={alignment}>
        {formatImpact(indicator.impact_last_year_ideal, unit)}
      </td>
      <td style={{ width: '120px' }}>
        <Form.Control
          id='impact_current_year'
          value={indicatorData.impactCurrentYearCustom}
          type='number'
          min='0'
          disabled={disabled}
          onChange={evt =>
            handleIndicatorDataChange(
              'impactCurrentYearCustom',
              Math.abs(evt.target.value)
            )
          }
        />
      </td>
      <td>
        <StyledRadio
          type='radio'
          checked={indicatorData.dataSource === 'custom'}
          disabled={disabled}
          onChange={() => handleIndicatorDataChange('dataSource', 'custom')}
          name={indicatorId}
          id='data_vendor_custom_radio'
        />
      </td>
      <td style={{ width: '120px' }}>
        <Form.Control
          id='impact_last_year'
          value={indicatorData.impactLastYearCustom}
          type='number'
          min='0'
          disabled={disabled}
          onChange={evt =>
            handleIndicatorDataChange(
              'impactLastYearCustom',
              Math.abs(evt.target.value)
            )
          }
        />
      </td>
    </StyledTr>
  )
}

export default PaiEntityDetailRow
