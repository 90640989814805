import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { APP_COLOR_PRIMARY, FONT_COLOR_SECONDARY } from '../../styles/colors'
import Chip from './Chip'

const ToggleContainer = styled.div`
  margin-top: ${props => props.look.marginTop};
  margin-bottom: ${props => props.look.marginBottom};
  margin-right: ${props => props.look.marginRight};
  margin-left: ${props => props.look.marginLeft};
`

const TypeToggle = ({
  companyType,
  toggleContainerLook,
  onDataChoiceChange,
}) => {
  const [selected, setSelected] = useState('SUMMARY')

  const onDataChoiceChangeLocal = property => {
    if (property !== selected) {
      setSelected(property)
    }
    onDataChoiceChange(property)
  }

  const renderChip = (label, isActive) => {
    let selectedStyle = {}
    if (selected === label || isActive)
      selectedStyle = {
        marginRight: `32px`,
        borderBottom: `2px solid ${APP_COLOR_PRIMARY}`,
        color: APP_COLOR_PRIMARY,
      }
    else selectedStyle = { marginRight: `32px` }

    return (
      <Chip
        key={label}
        label={label}
        borderColor='white'
        fontColor={FONT_COLOR_SECONDARY}
        style={selectedStyle}
        colorHover={APP_COLOR_PRIMARY}
        borderRadius='0px'
        onClick={isActive ? null : () => onDataChoiceChangeLocal(label)}
      />
    )
  }

  return (
    <ToggleContainer look={toggleContainerLook}>
      {companyType === 'private'
        ? renderChip('SUMMARY', true)
        : [
            renderChip('SUMMARY'),
            renderChip('ESG'),
            renderChip('SDG'),
            renderChip('CARBON'),
          ]}
    </ToggleContainer>
  )
}

export default TypeToggle
