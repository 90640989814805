import React, { useMemo } from 'react'
import styled from 'styled-components'

import { FONT_COLOR_SECONDARY } from '../../../../../../../styles/colors.js'
import Box from '../../../../../../../yb_components/styleguide/containers/Box.jsx'
import { StyledBar } from '../../../../../../../yb_modules/custom_esg_scoring/CustomESGScoring.styles.js'
import { StyledTable } from '../styles/PaiIndicatorsTable.styles'
import PaiEntityDetailRow from './PaiEntityDetailRow'
import { PaiEntityDetailTableHead } from './PaiEntityDetailTableHead'

const StyledCenteredDiv = styled.div`
  margin-bottom: 30px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SyledNoDataFound = styled.div`
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: 16px;
  color: ${FONT_COLOR_SECONDARY};
  text-align: center;
`

export const PaiEntityDetailTable = ({
  data = [],
  impactYears,
  onSave: handleSave,
  disabled,
  noDataText,
  hasChanges,
  onIndicatorChange: handleIndicatorChange,
}) => {
  const hasData = useMemo(() => !!data.length, [data])

  return (
    <>
      <Box isPresent={hasData}>
        <StyledTable>
          <PaiEntityDetailTableHead impactYears={impactYears} />
          <tbody>
            {data.map(group => (
              <React.Fragment key={group.category_group_name}>
                <tr>
                  <td colSpan='13' className='group'>
                    {group.category_group_name}
                  </td>
                </tr>
                {group.category_group_data.map(cat => (
                  <React.Fragment key={cat.category_name}>
                    <tr>
                      <td colSpan='13' className='category'>
                        {cat.category_name}
                      </td>
                    </tr>
                    {cat.category_data.map(indicator => (
                      <PaiEntityDetailRow
                        key={indicator.indicator_id}
                        indicator={indicator}
                        disabled={disabled}
                        onChange={handleIndicatorChange}
                      />
                    ))}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </StyledTable>
      </Box>
      <Box isPresent={!hasData}>
        <StyledCenteredDiv>
          <SyledNoDataFound>
            {noDataText || (
              <>
                <p>No items were found for your search query.</p>
                <p>Please try again.</p>
              </>
            )}
          </SyledNoDataFound>
        </StyledCenteredDiv>
      </Box>
      <StyledBar>
        <button
          className='btn btn-primary'
          disabled={!hasChanges || disabled}
          onClick={handleSave}
        >
          Save changes
        </button>
      </StyledBar>
    </>
  )
}
