import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types';


const Box = styled.div`
  background-color: #d6d6d6;
  width: ${props => props.width};
  height: ${props => props.height};
  ${props => props.margin && `margin: ${props.margin}`};
  @keyframes fadeInOut {
      from {opacity: 0.6;}
      to {opacity: 1;}
    }
  animation-name: fadeInOut;
  animation-duration: 0.4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
`

const SkeletonBox = ({ height = '80px', width = '80px', margin }) => {
  return <Box height={height} width={width} margin={margin}></Box>
}

SkeletonBox.propTypes = {
  height: propTypes.string,
  width: propTypes.string,
  margin: propTypes.string,
}

export default SkeletonBox
