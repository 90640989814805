import React, { forwardRef } from 'react'

// eslint-disable-next-line react/display-name
export const PdfFileIcon = forwardRef(() => (
  <svg
    fill='none'
    height='25'
    viewBox='0 0 19 25'
    width='19'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18.058 4.78422L13.9621 0.688342C13.5227 0.248975 12.9272 0 12.3072 0H2.34329C1.0496 0.00488186 0 1.05448 0 2.34817V22.6567C0 23.9504 1.0496 25 2.34329 25H16.403C17.6967 25 18.7463 23.9504 18.7463 22.6567V6.44405C18.7463 5.82406 18.4974 5.22359 18.058 4.78422ZM16.9547 5.8924C17.0572 5.99492 17.1256 6.11697 17.1597 6.25366H12.4976V1.59149C12.6343 1.62566 12.7563 1.69401 12.8588 1.79652L16.9547 5.8924ZM16.403 23.4378H2.34329C1.91369 23.4378 1.56219 23.0863 1.56219 22.6567V2.34817C1.56219 1.91857 1.91369 1.56708 2.34329 1.56708H10.9354V6.64421C10.9354 7.2935 11.4577 7.81586 12.107 7.81586H17.1841V22.6567C17.1841 23.0863 16.8326 23.4378 16.403 23.4378ZM15.329 15.0801C14.67 14.4308 12.644 14.6309 11.7311 14.753C10.6962 14.1281 10.0127 13.2689 9.52939 11.9898C9.73931 11.1111 10.1152 9.68561 9.84183 8.82152C9.62703 7.44972 7.90373 7.6157 7.66452 8.48955C7.42043 9.38293 7.64987 10.6571 8.07459 12.2876C7.49365 13.6741 6.62468 15.5536 6.01933 16.613C5.00391 17.1353 3.37825 18.0434 3.1488 19.1711C2.97793 19.9912 4.26674 21.0945 5.74107 19.4835C6.18532 19.0002 6.68326 18.2728 7.26909 17.2623C8.57254 16.8327 10.0078 16.2956 11.2722 16.0906C12.3413 16.6764 13.596 17.0621 14.4259 17.0621C15.7782 17.067 15.8368 15.5878 15.329 15.0801V15.0801ZM4.13982 19.4249C4.42785 18.6487 5.53603 17.7456 5.87288 17.4331C4.79399 19.1564 4.13982 19.4591 4.13982 19.4249ZM8.78735 8.57255C9.21207 8.57255 9.16813 10.4032 8.88987 10.8963C8.63601 10.1006 8.64577 8.57255 8.78735 8.57255ZM7.4009 16.3493C7.95255 15.3827 8.42609 14.2404 8.80687 13.2396C9.28041 14.1037 9.88576 14.7872 10.5204 15.2656C9.33411 15.495 8.30404 16.0027 7.4009 16.3493ZM14.8897 16.0613C14.8897 16.0613 14.6065 16.403 12.7661 15.6171C14.7676 15.4706 15.0947 15.9295 14.8897 16.0613Z'
      fill='currentColor'
    />
  </svg>
))
