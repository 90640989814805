import axios from "axios";

export const getOtpBackupCodes = () => {
    const url = '/app/api/otps/backup_codes';
    return axios.get(url);
};

export const postOptBackupCodes = () => {
    const url = '/app/api/otps/backup_codes';
    return axios.post(url);
};

export const postOtpBackupCodesExport = () => {
    const url = '/app/api/otps/export_backup_codes';

    return axios({
        url: url,
        method: "POST",
        responseType: "blob"
    });
};
