import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'

// this button is too tall, making the <label> component too tall
// for now, add a -2px margin-top or absolutely position the button
// to line the button text up vertically with the label text

const StyledButton = styled(Button)`
  margin: 0;
  padding: 2px 8px;
  border-radius: 6px;
  font-size: 10px;
  line-height: 1.1;
  font-weight: 700;
  text-transform: uppercase;
`

export const BaseLabelButton = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>
}
