import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import ReactGA from 'react-ga'

import { mapCarbonAuditToState } from '../../../mappers/carbon_audit_mapper'
import {
  downloadCSV,
  getCarbonAuditData,
  getCarbonAuditPerformers,
} from '../../../repositories/carbon_audit_repository'
import { getDropdownOptions as getInstrumentDropdownOptions } from '../../../repositories/instruments_repository'
import { getDropdownOptions as getSnapshotDropdownOptions } from '../../../repositories/portfolio_repository'
import { TargetClass } from '../../../yb_constants/enums/global_enums'

const loadCarbonAuditData = ({
  query,
  targetId,
  targetType,
  setCarbonAuditData,
  setError,
  setLoading,
}) => {
  setLoading?.(true)
  getCarbonAuditData({
    target_id: targetId,
    target_type: targetType,
    ...query,
  })
    .then(res => {
      setCarbonAuditData?.(mapCarbonAuditToState(res.data))
      setLoading?.(false)
    })
    .catch(err => {
      const error = err.response ? err.response.status : 502
      setError?.(error)
    })
}

const loadCarbonAuditPerformers = ({
  ids,
  targetId,
  targetType,
  setCarbonAuditPerformers,
  setError,
}) => {
  getCarbonAuditPerformers({
    target_id: targetId,
    target_type: targetType,
    ids,
  })
    .then(res => {
      setCarbonAuditPerformers?.(res.data)
    })
    .catch(err => {
      const error = err.response ? err.response.status : 502
      setError?.(error)
    })
}

const loadDropdownOptions = ({ targetId, targetType, setDropdownOptions }) => {
  const query = {
    trbc_industry_groups: true,
  }
  switch (targetType) {
    case TargetClass.INSTRUMENT:
      getInstrumentDropdownOptions({ query, id: targetId })
        .then(res => {
          setDropdownOptions(res.data)
        })
        .catch(err => {
          console.error(err)
        })

      break

    case TargetClass.PORTFOLIO_SNAPSHOT:
    default:
      getSnapshotDropdownOptions({ query, ...targetId })
        .then(res => {
          setDropdownOptions(res.data)
        })
        .catch(err => {
          console.error(err)
        })
      break
  }
}

/**
 * Hook used to include Carbon Audit data and requests handling
 *
 * @param {*} targetId The id of the current target
 * @param {*} targetType The type of the current target (e.g. 'Instrument', 'Portfolio::Snapshot')
 * @param {*} setError A method that handles the error message
 * @returns navigateTo() function that can be called to navigate to another page (based on the given url)
 */
const useCarbonAudit = ({ targetId, targetType, setError, setLoading }) => {
  const [carbonAuditData, setCarbonAuditData] = useState(null)
  const [carbonAuditPerformers, setCarbonAuditPerformers] = useState(null)
  const [query, setQuery] = useState({ group_by: 'sector' })

  const [carbonAuditDropdownOptions, setCarbonAuditDropdownOptions] = useState(
    []
  )

  const setLocalCarbonAuditData = useCallback(
    data => {
      setCarbonAuditData(data)

      if (data?.groupBy === 'sector') {
        const ids = _.filter(data?.table?.data?.map(e => e?.id))?.join(',')
        loadCarbonAuditPerformers({
          ids,
          targetId: localTargetId,
          targetType,
          setCarbonAuditPerformers,
          setError,
          setLoading,
        })
      }
    },
    [localTargetId, setError, setLoading, targetType]
  )

  const localTargetId =
    targetType === TargetClass.PORTFOLIO_SNAPSHOT
      ? targetId?.snapshotId
      : targetId

  useEffect(() => {
    loadDropdownOptions({
      targetId,
      targetType,
      setDropdownOptions: data =>
        setCarbonAuditDropdownOptions(data?.trbcIndustryGroup),
    })
  }, [])

  useEffect(() => {
    loadCarbonAuditData({
      targetId: localTargetId,
      targetType,
      setCarbonAuditData: setLocalCarbonAuditData,
      setError,
      setLoading,
    })
  }, [])

  const onCarbonAuditChange = useCallback(
    newQuery => {
      let action = 'Filter'
      let label = ''

      if (newQuery?.group_by) {
        label = `${newQuery.group_by}`
      } else if (newQuery?.degree) {
        label = `${newQuery.degree === 'a' ? '1.5c' : '<1.5c'}`
      } else if (newQuery?.budget) {
        label = `${newQuery.budget}`
      } else if (newQuery?.calculation_method) {
        label = `${newQuery.calculation_method}`
      } else if (newQuery?.include_shorts) {
        label = `include_shorts: ${newQuery.include_shorts}`
      } else if (newQuery?.include_benchmark) {
        label = `include_benchmark: ${newQuery.include_benchmark}`
      } else if (newQuery?.sectors) {
        label = `Sector dropdown: ${newQuery.sectors.toString()}`
      } else if (newQuery?.sort_field) {
        action = 'sort'
        label = `${newQuery.sort_field}: ${newQuery.sort_dir}`
      } else if (newQuery?.page) {
        action = 'Pagination'
        label = 'Page'
        ReactGA.event({
          category: 'Carbon Audit',
          action: action,
          label: 'Page',
          value: newQuery.page,
        })
      }

      if (action !== 'Pagination') {
        ReactGA.event({
          category: 'Carbon Audit',
          action: action,
          label: label,
        })
      }

      setQuery(prevQuery => {
        const resultQuery = {
          ...prevQuery,
          ...newQuery,
        }

        loadCarbonAuditData({
          query: resultQuery,
          targetId: localTargetId,
          targetType,
          setCarbonAuditData: setLocalCarbonAuditData,
          setError,
          setLoading,
        })

        return resultQuery
      })
    },
    [localTargetId, targetType, setError, setLoading, setLocalCarbonAuditData]
  )

  const onDownloadCSV = useCallback(() => {
    ReactGA.event({
      category: 'Carbon Audit',
      action: 'CSV Download',
    })
    downloadCSV({
      target_id: localTargetId,
      target_type: targetType,
      ...query,
    })
  }, [localTargetId, query, targetType])

  return {
    carbonAuditData: carbonAuditData || {},
    carbonAuditPerformers,
    carbonAuditDropdownOptions,
    onCarbonAuditChange,
    onDownloadCSV,
  }
}

export default useCarbonAudit
