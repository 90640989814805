import propTypes from 'prop-types'
import React from 'react'

import { addOrderOfMagnitudeMask } from '../../helpers/number'
import { CARBON_EXPLAINER_URL } from '../../yb_constants/urls/explainers'
import ImpactCard from './ImpactCard'

const TreesKilledImpactCard = ({
  targetType,
  data,
  minHeight,
  benchmarkData,
  benchmarkName,
}) => {
  const benchmarkComparison = data.total - benchmarkData?.total
  const benchmarkComparisonAbs = Math.abs(benchmarkComparison)

  return (
    <ImpactCard
      targetType={targetType}
      title='CARBON'
      value={addOrderOfMagnitudeMask(Math.round(data.total)) + ' trees'}
      holdingsPct={data.holdings_ownership_pct}
      description='that must be planted to sequester <br/> the annual carbon emissions.'
      benchmarkDescription={
        benchmarkName && {
          text: `${addOrderOfMagnitudeMask(benchmarkComparisonAbs)} ${
            benchmarkComparison > 0 ? 'more' : 'less'
          } than the ${benchmarkName}.`,
          color: benchmarkComparison > 0 ? '#D02F44' : '#00D679',
        }
      }
      minHeight={minHeight}
      icons={
        <div>
          <img src={`/impact_icons/ico_carbon.svg`} />
        </div>
      }
      explainerHref={CARBON_EXPLAINER_URL}
    />
  )
}

TreesKilledImpactCard.propTypes = {
  data: propTypes.shape({
    total: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
    total_perc: propTypes.oneOfType([propTypes.number, propTypes.string])
      .isRequired,
    median_perc: propTypes.oneOfType([propTypes.number, propTypes.string])
      .isRequired,
  }).isRequired,
  name: propTypes.string.isRequired,
}

export default TreesKilledImpactCard
