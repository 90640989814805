import React from 'react'

import PortfolioProcessedCard from '../../../components/cards/PortfolioProcessedCard'
import PortfolioStatusCard from '../../../components/cards/PortfolioStatusCard'
import SectionWrapper from '../../../components/styleguide/SectionWrapper'
import TextSection from '../../../components/styleguide/TextSection'
import { portfolioStatuses } from '../../../constants/portfolios'
import { StyledLink } from '../../fund/styles'
import PortfolioInfo from './PortfolioInfo'

const PortfolioList = ({ portfolio, skeleton }) => {
  const navigate = link => {}

  const getStatusCard = (status, name) => {
    switch (status) {
      case portfolioStatuses.pending:
      case portfolioStatuses.processing:
        return (
          <PortfolioStatusCard title={name}>
            <SectionWrapper
              height='121px'
              style={{ minWidth: '600px' }}
              justify='center'
              align='center'
            >
              <TextSection
                height='100%'
                align='center'
                color={'#979797'}
                fontSize='18px'
              >
                This portfolio is currently {portfolio?.status}.
              </TextSection>
            </SectionWrapper>
          </PortfolioStatusCard>
        )
      case portfolioStatuses.failure:
        return (
          <PortfolioStatusCard width='720px' title={name} height='200px'>
            <SectionWrapper
              height='121px'
              style={{ minWidth: '600px' }}
              align='center'
            >
              <SectionWrapper>
                <TextSection
                  wrap='nowrap'
                  align='center'
                  color={'#979797'}
                  fontSize='18px'
                >
                  There was an error processing this portfolio our support team
                  has been
                </TextSection>
                <TextSection
                  wrap='nowrap'
                  align='center'
                  color={'#979797'}
                  fontSize='18px'
                >
                  notified, we will look into this issue and reach out to
                  provide assistance.
                </TextSection>
              </SectionWrapper>
            </SectionWrapper>
          </PortfolioStatusCard>
        )
      case portfolioStatuses.not_modelable:
        return (
          <PortfolioStatusCard title={name}>
            <SectionWrapper height='80%' justify='center' align='center'>
              <SectionWrapper>
                <TextSection align='center' color={'#979797'} fontSize='18px'>
                  We are currently unable to report on this portfolio as less
                  than 20% of its &apos;
                </TextSection>
                <TextSection align='center' color={'#979797'} fontSize='18px'>
                  holdings are modelable. You can{' '}
                  <StyledLink onClick={() => navigate()}>
                    read more about this error here
                  </StyledLink>{' '}
                  and if you
                </TextSection>
                <TextSection align='center' color={'#979797'} fontSize='18px'>
                  would like, can{' '}
                  <StyledLink onClick={() => navigate()}>
                    file a support ticket
                  </StyledLink>{' '}
                  to receive assistance.
                </TextSection>
              </SectionWrapper>
            </SectionWrapper>
          </PortfolioStatusCard>
        )
    }
  }

  return (
    <>
      {portfolio?.status === portfolioStatuses.successfully_processed ? (
        <PortfolioProcessedCard
          id={portfolio.id}
          snapshotId={portfolio.snapshot_id}
          title={portfolio.name}
        >
          <PortfolioInfo portfolio={portfolio} />
        </PortfolioProcessedCard>
      ) : skeleton ? (
        <PortfolioProcessedCard
          skeleton={skeleton}
        >
          <PortfolioInfo skeleton={skeleton} />
        </PortfolioProcessedCard>
      ) : (
        <>{getStatusCard(portfolio?.status, portfolio.name)}</>
      )}
    </>
  )
}

export default PortfolioList
