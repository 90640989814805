import propTypes from 'prop-types'
import React from 'react'
import { Spinner } from 'react-bootstrap'

import { ButtonIconTop } from '../../../../../yb_components/styleguide/buttons/button_icon_top/ButtonIconTop'
import { UserAvatar } from '../../../../../yb_components/styleguide/user_avatars/UserAvatar'
import { StyledGrid } from './ManageUsersTable.styles'

export const ManageUsersTable = ({
  users,
  loading,
  onUpdateUser,
  onResetPassword,
}) => {
  // eslint-disable-next-line no-undef
  const appUser = react_app_user?.user
  const tableHeaders = [
    { text: '', center: false },
    { text: 'User Name', center: false },
    { text: 'Email Address', center: false },
    { text: 'Role', center: true },
    { text: 'Status', center: true },
    { text: 'Tools', center: true },
  ]

  return (
    <StyledGrid role='table'>
      <header>
        <div className='grid-row' role='row'>
          {tableHeaders &&
            tableHeaders.map(({ text, center }) => (
              <div
                className={`grid-column ${center ? 'center' : ''}`}
                role='columnheader'
                aria-sort='none'
                key={text}
              >
                <span>{text}</span>
              </div>
            ))}
        </div>
      </header>
      {loading ? (
        <main
          style={{
            padding: '59px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner animation='border' variant='secondary' />
        </main>
      ) : (
        <main>
          {users &&
            users.map(user => (
              <div className='grid-row' key={user.id} role='row'>
                <div className='grid-column center' role='cell'>
                  <UserAvatar width={28} user={user} />
                </div>
                <div className='grid-column' role='cell'>
                  <strong>{user.full_name}</strong>
                </div>
                <div className='grid-column' role='cell'>
                  <span style={{ wordBreak: 'break-all' }}>{user.email}</span>
                </div>
                <div className='grid-column center' role='cell'>
                  <span style={{ textTransform: 'capitalize' }}>
                    {user.role === 'reader' ? 'Read-Only' : user.role}
                  </span>
                </div>
                <div className='grid-column center' role='cell'>
                  <span style={{ textTransform: 'capitalize' }}>
                    {user.status}
                  </span>
                </div>
                <div className='grid-column' role='cell'>
                  <div className='button-grid'>
                    <ButtonIconTop
                      src={'/icons/ico_button_edit.svg'}
                      text={'Edit'}
                      disabled={false}
                      href={`/app/organization/users/${user.id}`}
                    />
                    {user.status === 'active' ? (
                      <ButtonIconTop
                        src={'/icons/ico_lock.svg'}
                        text={'Disable Access'}
                        onClick={e => onUpdateUser(user, e)}
                        disabled={appUser.id === user.id}
                      />
                    ) : (
                      <ButtonIconTop
                        src={'/icons/ico_lock_open.svg'}
                        text={'Enable Access'}
                        onClick={e => onUpdateUser(user, e)}
                      />
                    )}
                    <ButtonIconTop
                      src={'/icons/ico_key.svg'}
                      text={'Reset Password'}
                      disabled={user.status === 'active' ? false : true}
                      onClick={e => onResetPassword(user, e)}
                    />
                  </div>
                </div>
              </div>
            ))}
        </main>
      )}
    </StyledGrid>
  )
}

ManageUsersTable.propTypes = {
  users: propTypes.array.isRequired,
  onUpdateUser: propTypes.func,
  onResetPassword: propTypes.func,
  loading: propTypes.bool,
}
ManageUsersTable.defaultProps = {
  users: [],
}
