import React, { useState, useEffect } from 'react';
import Card from '../../../components/styleguide/Card';
import CardTitle from '../../../components/styleguide/CardTitle';
import SectionWrapper from '../../../components/styleguide/SectionWrapper';
import Button from '../../../components/styleguide/Button';
import WrapperColumn from '../../../components/styleguide/WrapperColumn';
import ImageFileInput from '../../../yb_components/styleguide/image_file_input/ImageFileInput';
import { getBradingLogoBlob, updateBrandingLogos } from '../../../repositories/organizations_repository';
import Alert from '../../../yb_components/styleguide/alert/Alert';
import Link from '../../../components/styleguide/Link';
import {
  ALERT_DISPLAY_TIME,
  primaryLogoValidations, secondaryLogoValidations, allowedFileTypes
} from './OrganizationBrandingLogosCard.constants';

const OrganizationBrandingLogosCard = () => {
  const [mountCount, setMountCount] = useState(0)
  const [organization, setOrganization] = useState(react_app_organization.organization)
  const [primaryLogo, setPrimaryLogo] = useState({})
  const [secondaryLogo, setSecondaryLogo] = useState({})
  const [afterSaveMessage, setAfterSaveMessage] = useState(null)

  useEffect(() => {
    loadBradingLogoBlob(organization.primary_brand_logo?.url, setPrimaryLogo)
    loadBradingLogoBlob(organization.secondary_brand_logo?.url, setSecondaryLogo)
  }, [mountCount])

  const loadBradingLogoBlob = (url, setLogo) => {
    if(url) {
      getBradingLogoBlob(url)
        .then(response => response.data)
        .then(blob => setLogo({file: blob}))
    } else {
      setLogo({})
    }
  }

  const onLogoChange = (logo, setLogo) => (file, errors) => {
    setLogo({
      ...logo,
      file,
      isValid: errors?.length === 0
    })
  }

  const onChangePrimaryLogo = onLogoChange(primaryLogo, setPrimaryLogo)
  const onChangeSecondaryLogo = onLogoChange(secondaryLogo, setSecondaryLogo)

  const onSave = () => {
    const formData = new FormData()
    primaryLogo?.file && formData.append('primary_brand_logo', primaryLogo.file)
    secondaryLogo?.file && formData.append('secondary_brand_logo', secondaryLogo.file)

    updateBrandingLogos(formData).then(response => {
      setOrganization(response.data.organization)
      setAfterSaveMessage({
        type: 'success',
        text: 'Logos saved successfully!',
      })
    }).catch(() => {
      setAfterSaveMessage({
        type: 'danger',
        text: 'There was a problem saving the logos. Please try again later.',
      })
    }).finally(() => {
      setTimeout(() => setAfterSaveMessage(null), ALERT_DISPLAY_TIME)
    })
  }

  const onCancel = () => setMountCount(mountCount + 1)

  const isSaveDisabled = (primaryLogo?.isValid === undefined && secondaryLogo?.isValid === undefined) ||
                        (primaryLogo?.isValid !== undefined && !primaryLogo?.isValid) ||
                        (secondaryLogo?.isValid !== undefined && !secondaryLogo?.isValid)

  return (
    <Card key={`branding-logos-card-${mountCount}`} data-cy="organization-branding-card">
      <CardTitle title="Organization Branding"/>

      <WrapperColumn>
        {afterSaveMessage && <Alert type={afterSaveMessage.type} message={afterSaveMessage.text}/>}
        <SectionWrapper margin="10px 0 6px 0">
          <ImageFileInput
            label={ 'Primary Logo' }
            defaultPreviewSrc={ organization.primary_brand_logo?.url }
            validations={ primaryLogoValidations }
            accept={ allowedFileTypes }
            onChange={ onChangePrimaryLogo }
          />
          <ImageFileInput
            margin={'0 auto'}
            label={ 'Secondary Logo' }
            defaultPreviewSrc={ organization.secondary_brand_logo?.url }
            validations={ secondaryLogoValidations }
            accept={ allowedFileTypes }
            onChange={ onChangeSecondaryLogo }
          />
        </SectionWrapper>
        <SectionWrapper align="center">
          <Button primary width="20%" label="Save Changes" disabled={ isSaveDisabled } onClick={ onSave } data-cy="organization-branding-save"/>
          <Link styles={{margin: "0 0 0 10px"}} onClick={onCancel} data-cy="organization-branding-cancel">Cancel Changes</Link>
        </SectionWrapper>
      </WrapperColumn>
    </Card>
  )
}

export default OrganizationBrandingLogosCard
