import React, { createContext, useReducer } from 'react'

import AppReducer from './reducers/AppReducer'

const initialStateLocal = {
  badges: {},
  currentTabRef: null,
  target: null,
  benchmark: null,
}

export const AppContext = createContext(initialStateLocal)

const AppStore = ({ initialState = null, children }) => {
  const [state, dispatch] = useReducer(
    AppReducer,
    initialState || initialStateLocal
  )

  return (
    <AppContext.Provider value={[state, dispatch]}>
      {children}
    </AppContext.Provider>
  )
}

export default AppStore
