import React, { useState } from 'react'
import { Button, Form, FormControl } from 'react-bootstrap'
import style from 'styled-components'

import { updateIndicatorExplanationActions } from '../../../../../../../repositories/portfolio_repository'
import {
  getNow,
  getRemainingLoadingTime,
} from '../../../../../../../yb_helpers/loader'

const StyledDiv = style.div`
  display: flex;

  .form-group {
    flex: 1;

      label {
        text-transform: uppercase;
        font-size: 12px;
      }

      &:first-child {
        margin-right: 20px;
      }
  }
`

const StyledFooter = style.div`
  display: flex;
  justify-content: flex-end;
`

export const RowAttachment = ({
  indicatorModel,
  setIndicatorChanges,
  explanationData: { portfolioId, snapshotId },
}) => {
  const [indicator, setIndicator] = indicatorModel
  const [isSaving, setIsSaving] = useState(false)
  const [explanationResponse, setExplanationResponse] = useState(
    indicator.explanations_text
  )
  const [actionsResponse, setAcitonsResponse] = useState(indicator.actions_text)
  const [explanationSnapshot, setExplanationSnapshot] = useState({
    explanations_text: explanationResponse,
    actions_text: actionsResponse,
  })

  const resetResponses = () => {
    const { explanations_text, actions_text } = explanationSnapshot

    setExplanationResponse(explanations_text)
    setAcitonsResponse(actions_text)
    untrackUnsavedChanges()
  }

  const handleSubmit = () => {
    setIsSaving(true)
    const now = getNow()

    const updatedResponses = {
      explanations_text: explanationResponse,
      actions_text: actionsResponse,
    }

    updateIndicatorExplanationActions(
      portfolioId,
      snapshotId,
      indicator.indicator_id,
      {
        explanations_text: explanationResponse,
        actions_text: actionsResponse,
      }
    )
      .then(() => {
        const updatedIndicator = {
          ...indicator,
          ...updatedResponses,
        }

        setIndicator(updatedIndicator)
        setExplanationSnapshot(updatedResponses)
        untrackUnsavedChanges()
      })
      .finally(() => {
        const remainingLoadingTime = getRemainingLoadingTime(now)

        setTimeout(() => setIsSaving(false), remainingLoadingTime)
      })
  }

  const trackUnsavedChanges = () => {
    setIndicatorChanges({
      [indicator.indicator_id]: true,
    })
  }

  const untrackUnsavedChanges = () => {
    setIndicatorChanges({
      [indicator.indicator_id]: false,
    })
  }

  const handleExplanationChange = explanation => {
    setExplanationResponse(explanation)

    if (explanation === explanationSnapshot.explanations_text) {
      untrackUnsavedChanges()
    } else {
      trackUnsavedChanges()
    }
  }

  const handleActionsTakenChange = actions => {
    setAcitonsResponse(actions)

    if (actions === explanationSnapshot.actions_text) {
      untrackUnsavedChanges()
    } else {
      trackUnsavedChanges()
    }
  }

  return (
    <>
      <StyledDiv>
        <Form.Group>
          <label htmlFor='explanation_textarea'>Explanation</label>
          <FormControl
            disabled={isSaving}
            as='textarea'
            id='explanation_textarea'
            rows='4'
            value={explanationResponse}
            onChange={evt => handleExplanationChange(evt.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <label htmlFor='explanation_textarea'>Actions Taken</label>
          <FormControl
            disabled={isSaving}
            as='textarea'
            id='actions_textarea'
            rows='4'
            value={actionsResponse}
            onChange={evt => handleActionsTakenChange(evt.target.value)}
          />
        </Form.Group>
      </StyledDiv>
      <StyledFooter>
        <button
          disabled={isSaving}
          className='btn cancel-btn'
          onClick={resetResponses}
        >
          Cancel
        </button>
        <Button disabled={isSaving} variant='primary' onClick={handleSubmit}>
          Save Changes
        </Button>
      </StyledFooter>
    </>
  )
}
