import React from 'react'
import PropTypes from 'prop-types';


const XAxis = ({classes, xstart, xend, ystart}) => {


    return ( <line 
        x1={xstart} 
        x2={xend}
        y1={ystart} 
        y2={ystart}
        className={classes}
        />
    )
}

XAxis.propTypes = {
    classes: PropTypes.string,
    xstart : PropTypes.number,
    xend : PropTypes.number,
    ystart : PropTypes.number

}


export default XAxis;

