import React, { memo } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import usePlaceholderBadge from './usePlaceholderBadge'

const BadgeComponent = ({ badge, holdingsTotal = null, snapshotId }) => {
  const { path } = usePlaceholderBadge(badge)
  let location = `/app/badges?badge=${badge['key']}&badgeId=${badge['badge_id']}`

  if (snapshotId) {
    location = location + `&snapshot_id=${snapshotId}`
  }

  return (
    <li onClick={() => window.location.assign(location)} className='badge-card'>
      <img
        style={{ height: 80, width: 80 }}
        className='badge-image'
        src={path}
      />
      <div className='display-grid'>
        <b>{badge['name']}</b>
        <Col xs={10} className={'description'}>
          {badge['description']}
        </Col>
        {holdingsTotal != null &&
          holdingsTotal > 0 &&
          badge['holding_count'] > 0 && (
            <Row>
              <Col xs={5}>
                <div className='holdings-counter'>
                  {badge['holding_count']}/{holdingsTotal} Holdings
                </div>
              </Col>
            </Row>
          )}
      </div>
    </li>
  )
}

export default memo(BadgeComponent)
