import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'

import Box from '../../../../../../../yb_components/styleguide/containers/Box'
import { formatImpact } from '../../../../../../../yb_helpers/impact'
import { EditIcon } from '../../../icons/EditIcon'
import { ColorBlock } from '../color_block/ColorBlock'
import { RowAttachment } from './RowAttachment'

const StyledTableRow = styled.tr`
  &.even {
    background: #fff;
  }

  &.odd {
    background: #f1f1f1;
  }

  .explanations-trigger {
    padding: 0;
    display: inline-block;
    text-decoration: underline;
  }
  td {
    text-align: left;
  }
  .right {
    text-align: right;
  }
`

const StyledTd = styled.td`
  padding: 10px !important;
`

const StyledExplanationsCell = styled.div`
  display: flex;
  align-items: center;
`

export const DataRow = ({
  row,
  index,
  explanationData,
  setIndicatorChanges,
}) => {
  const indicatorModel = useState(row)
  const [indicator] = indicatorModel
  const isBool = indicator.unit === 'bool'
  const alignment = !isBool ? 'right' : null
  const [expanded, setExpanded] = useState(false)
  const rowOrderedStyle = index % 2 === 0 ? 'even' : 'odd'
  const explanationsButtonLabel = useMemo(() => {
    let prefix = ''

    if (expanded) {
      prefix = 'Hide'
    } else if (indicator.explanations_text || indicator.actions_text) {
      prefix = 'Edit'
    } else {
      prefix = 'Add'
    }

    return `${prefix} Explanation & Actions Taken`
  }, [indicator, expanded])

  const toggleRowAttachment = () => setExpanded(!expanded)

  return (
    <>
      <StyledTableRow className={rowOrderedStyle}>
        <td>{indicator.indicator_name}</td>
        <td style={{ width: '125px' }}>{indicator.table}</td>
        <td style={{ width: '125px' }}>{indicator.unit}</td>
        <td style={{ width: '150px' }} className={alignment}>
          {formatImpact(indicator.impact_current_year, indicator.unit)}
        </td>
        <td style={{ width: '150px' }} className={alignment}>
          {formatImpact(indicator.impact_last_year, indicator.unit)}
        </td>
        <td style={{ padding: 0 }}>
          <ColorBlock number={indicator.completion_percentage} />
        </td>
        <td style={{ width: '200px', color: '#000' }}>
          <StyledExplanationsCell>
            <EditIcon />
            <Button
              variant='link'
              className='explanations-trigger'
              href='#'
              onClick={toggleRowAttachment}
            >
              {explanationsButtonLabel}
            </Button>
          </StyledExplanationsCell>
        </td>
      </StyledTableRow>
      <Box isPresent={expanded}>
        <StyledTableRow className={rowOrderedStyle}>
          <StyledTd colSpan={7}>
            <RowAttachment
              explanationData={explanationData}
              indicatorModel={indicatorModel}
              setIndicatorChanges={setIndicatorChanges}
            />
          </StyledTd>
        </StyledTableRow>
      </Box>
    </>
  )
}

DataRow.propTypes = {
  row: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  launchExplanationModal: PropTypes.func.isRequired,
  setIndicatorChanges: PropTypes.func.isRequired,
  explanationData: PropTypes.object.isRequired,
}
