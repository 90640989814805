import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import injectSheet from 'react-jss'

import GenderGapImpactCard from '../../../components/cards/GenderGapImpactCard'
import HazardousWasteImpactCard from '../../../components/cards/HazardousWasteImpactCard'
import TreesKilledImpactCard from '../../../components/cards/TreesKilledImpactCard'
import WasteImpactCard from '../../../components/cards/WasteImpactCard'
import WaterUsageImpactCard from '../../../components/cards/WaterUsageImpactCard'
import SectionTitle from '../../../components/styleguide/SectionTitle'
import withErrorBoundary from '../../../hocs/withErrorBoundary'
import { FONT_COLOR_PRIMARY } from '../../../styles/colors'
import { OWNERSHIP_PERCENTAGE_THRESHOLD } from '../../../yb_constants/target'

const ImpactSection = ({
  ownership_percentage_threshold = OWNERSHIP_PERCENTAGE_THRESHOLD,
  targetType,
  classes,
  impacts,
  name,
}) => {
  const cards = []

  const isValidImpact = impact => {
    return (
      impact &&
      !isNaN(impact.total) &&
      impact.total > 0 &&
      impact.holdings_ownership_pct > ownership_percentage_threshold
    )
  }

  if (isValidImpact(impacts.trees_killed)) {
    cards.push(
      <TreesKilledImpactCard
        targetType={targetType}
        name={name}
        data={impacts.trees_killed}
      />
    )
  }

  if (isValidImpact(impacts.pay_gap)) {
    cards.push(
      <GenderGapImpactCard targetType={targetType} data={impacts.pay_gap} />
    )
  }

  if (isValidImpact(impacts.waste)) {
    cards.push(
      <WasteImpactCard
        targetType={targetType}
        type='waste'
        data={impacts.waste}
      />
    )
  }

  if (isValidImpact(impacts.water_usage)) {
    cards.push(
      <WaterUsageImpactCard
        targetType={targetType}
        type='water_usage'
        data={impacts.water_usage}
      />
    )
  }

  if (isValidImpact(impacts.hazardous_waste)) {
    cards.push(
      <HazardousWasteImpactCard
        targetType={targetType}
        type='hazardous_waste'
        data={impacts.hazardous_waste}
      />
    )
  }

  if (!cards.length) return null

  return (
    <div className={classes.section}>
      <SectionTitle title='Impact Assessment' />
      <Container fluid className='impacts-container'>
        <Row>
          {cards.map((c, i) => (
            <Col key={i} xs={6}>
              {c}
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

const style = {
  section: {
    '& .impacts-container': {
      padding: 0,
      '& > * > *': {
        marginTop: 20,
      },
    },
  },
  description: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: FONT_COLOR_PRIMARY,
  },
}

export default injectSheet(style)(withErrorBoundary(ImpactSection))
