import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { TcfdOverallScoreWell } from '../../../../../../modules/portfolio/tcfd/components/tcfd_portfolio_score/TcfdOverallScoreWell'
import { TcfdPortfolioRiskList } from '../../../../../../modules/portfolio/tcfd/components/tcfd_portfolio_score/TcfdPortfolioRiskList'

const StyledContainer = styled.div`
  max-height: 180px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

const TcfdScores = ({ scores, portfolio }) => {
  return (
    <StyledContainer>
      <TcfdOverallScoreWell
        scores={scores}
        titleFontSize={'14px'}
        portfolio={portfolio}
      />
      <TcfdPortfolioRiskList scores={scores} />
    </StyledContainer>
  )
}

TcfdScores.propTypes = {
  scores: PropTypes.array.isRequired,
  portfolio: PropTypes.bool,
}

export default TcfdScores
