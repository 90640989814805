import debounce from 'lodash.debounce'
import { useEffect, useMemo } from 'react'

const useDebounce = ({ onChange, wait = 400 }) => {
  const debouncedOnChange = useMemo(() => debounce(onChange, wait), [])
  useEffect(() => {
    return () => {
      debouncedOnChange.cancel()
    }
  }, [])

  return { debouncedOnChange }
}

export { useDebounce }
