import propTypes from 'prop-types'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import cns from '../../helpers/classnames'
import { MainTable } from '../tables/styles'
import SkeletonBox from './skeleton/SkeletonBox'
import SortButton from './SortButton'

const Table = ({
  showSort = true,
  headers,
  multiRowHeaders,
  body,
  footer,
  onSort,
  className,
  look,
  sort = {  },
  onRowClick = () => {},
  customBody,
  loading = false,
  loadingColumns = 4,
  loadingRows = 10,
  rightAlignHeader = [],
  leftAlignHeader = [],
  alignNumbers = false,
}) => {
  const sortField = sort?.sortField ? sort.sortField : sort?.field
  const sortDirection = sort?.sortDirection ? sort.sortDirection : sort?.dir

  const renderTableData = section => {
    let tableHeaders = headers
    let tableBody = body
    if (loading && section === 'body') {
      tableBody = new Array(loadingRows).fill(
        new Array(loadingColumns).fill({})
      )
    } else if (loading && section === 'headers') {
      tableHeaders = new Array(loadingColumns).fill({})
    }
    return section === 'body' ? tableBody : tableHeaders
  }

  const onSortClick = (sortable, id) => {
    let dir
    if (sortable) {
      dir = sort?.dir == 'asc' ? 'desc' : 'asc'
    }
    if (onSort) {
      onSort(id, dir)
    }
  }

  const handleSortClick = (sortable, sortId) => {
    if (!sortable) return

    onSortClick(sortable, sortId)
  }

  return (
    <MainTable className={className} look={look || {}}>
      <thead>
        {multiRowHeaders}
        <tr className={'header-row'}>
          {renderTableData('headers').map(
            ({ label, sort, id, className, span }, i) => (
              <th
                {...(rightAlignHeader.includes(i) || (loading && i !== 0)
                  ? { style: { textAlign: 'right' } }
                  : {})}
                {...(leftAlignHeader.includes(i)
                  ? { style: { textAlign: 'left' } }
                  : {})}
                className={`th-${i}`}
                key={i}
                colSpan={span || 1}
              >
                <span
                  className={`${cns(sort ? 'sortable' : null, className)}`}
                  onClick={() => handleSortClick(sort, id)}
                >
                  {loading ? (
                    <SkeletonBox width='82px' height='23px' />
                  ) : typeof label === 'string' ? (
                    label.toUpperCase()
                  ) : (
                    label
                  )}{' '}
                  {showSort && sort ? (
                    <SortButton
                      active={id == sortField ? sortDirection : false}
                    />
                  ) : null}
                </span>
              </th>
            )
          )}
        </tr>
      </thead>
      {customBody ? (
        <tbody>
          <tr>
            <td colSpan={headers?.length}>{!loading && customBody}</td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {renderTableData('body').map((row, i) => (
            <tr
              {...(loading !== true && { onClick: () => onRowClick(row) })}
              key={`${i}${Date.now()}`}
            >
              {row.map((data, j) =>
                loading ? (
                  <td
                    className={`skeleton-td`}
                    key={uuidv4()}
                    {...(/\d/.test(data) && alignNumbers
                      ? { style: { textAlign: 'right' } }
                      : {})}
                  >
                    <SkeletonBox
                      width='82px'
                      height='23px'
                      margin={j === 0 ? '' : '0 0 0 auto'}
                    />
                  </td>
                ) : (
                  <td
                    className={`td-${j}`}
                    key={uuidv4()}
                    {...(/\d/.test(data) && alignNumbers
                      ? { style: { textAlign: 'right' } }
                      : {})}
                  >
                    <span>{data}</span>
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      )}
      <tr></tr>
      <tfoot>
        {footer &&
          footer.map((row, i) => (
            <tr onClick={() => onRowClick(row)} key={`${i}${Date.now()}`}>
              {row.map((data, j) => (
                <td
                  {...((/\d/.test(data) && alignNumbers) || data === '-'
                    ? { style: { textAlign: 'right' } }
                    : {})}
                  key={`${i}${j}${data}`}
                >
                  {!loading && data}
                </td>
              ))}
            </tr>
          ))}
      </tfoot>
    </MainTable>
  )
}

Table.propTypes = {
  headers: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.node.isRequired,
      sort: propTypes.bool,
    })
  ).isRequired,
  body: propTypes.arrayOf(propTypes.arrayOf(propTypes.node).isRequired)
    .isRequired,
  footer: propTypes.arrayOf(propTypes.arrayOf(propTypes.node)),
  className: propTypes.string,
  loading: propTypes.bool,
  loadingRows: propTypes.number,
  loadingColumns: propTypes.number,
}

export default Table
