import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { postHoldingKeyPeople } from "../../../../repositories/holdings_repository";

import { PEErrorService } from "../../errorService";
import SectionWrapper from "../../../../components/styleguide/SectionWrapper";
import { AddPipelineDialog } from "../../styles";

import TextInput from "../../../../components/styleguide/TextInput";
import Button from "../../../../components/styleguide/Button";
const AddHoldingKeyPersonModal = ({show, onModalHide, onModalSubmit, holding}) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [title, setTitle] = useState("");
    const [errors, setErrors] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const onFormSubmit = () => {
        setErrors([]);
        setDisableSubmit(true);

        const newKeyPerson = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            title: title
        };

        postHoldingKeyPeople({holdingID: holding.id, keyPerson: newKeyPerson}).then((response) => {
            newKeyPerson.id = response.data.id;
            newKeyPerson.full_name = `${newKeyPerson.first_name} ${newKeyPerson.last_name}`;

            setDisableSubmit(false);
            onModalSubmit(newKeyPerson);
            clearPerson();
        }).catch((error) => {
            PEErrorService.setErrors(error.response.data, setErrors);
            setDisableSubmit(false);
        });
    };

    const clearPerson = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setTitle("");
    }

    const onHide = () => {
        clearPerson()
        setErrors([]);
        setDisableSubmit(false);
        onModalHide();
    }

    return(
        <AddPipelineDialog minHeight="300px" show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Key Person</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SectionWrapper align="center" margin="5px 0px 5px 0px" width="100%">
                        <TextInput
                            error={errors.first_name}
                            marginRight="30px"
                            label="First Name"
                            width="45%"
                            id="first-name"
                            value={firstName}
                            onChange={(id, value, e) => { setFirstName(value) }}
                        />

                        <TextInput
                            error={errors.last_name}
                            label="Last Name"
                            width="50%"
                            id="last-name"
                            value={lastName}
                            onChange={(id, value, e) => { setLastName(value) }}
                        />


                        <TextInput
                            error={errors.email}
                            marginRight="30px"
                            label="Email"
                            width="45%"
                            id="email"
                            value={email}
                            onChange={(id, value, e) => { setEmail(value) }}
                        />

                        <TextInput
                            error={errors.title}
                            label="Title"
                            width="50%"
                            id="title"
                            value={title}
                            onChange={(id, value, e) => { setTitle(value) }}
                        />
                    </SectionWrapper>
                    <SectionWrapper justify="space-between" margin="10px 47px 10px 12px">
                        <Button width="20%" label="Cancel" onClick={onHide}/>
                        <Button width="30%" label="Save Key Person" onClick={onFormSubmit} disabled={disableSubmit}/>
                    </SectionWrapper>
                </Modal.Body>
        </AddPipelineDialog>
    )
};

export default AddHoldingKeyPersonModal;
