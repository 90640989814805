import React from 'react'

import { abbreviateNumber, addThousandSeparator } from '../../../helpers/number'
import {
  STACKED_CHART_SET_GREEN,
  STACKED_CHART_SET_RED,
  STACKED_CHART_SET_TABLEAU,
} from '../../../styles/colors'
import { repeatArray } from '../../../yb_helpers/arrays'
import {
  OVER_BUDGET_COLOR,
  UNDER_BUDGET_COLOR,
} from './CarbonAuditCard.constants'

/* Methods */
export const getStandardData = data => {
  const { budgets, emissions, benchmark } = data

  let emissionsColor =
    emissions.total > budgets?.total
      ? STACKED_CHART_SET_RED[1]
      : STACKED_CHART_SET_GREEN[0]

  return {
    budgets: {
      children: [
        {
          label: 'Portfolio Budget Longs',
          value: budgets?.longs_total,
          color: STACKED_CHART_SET_TABLEAU[0],
        },
        {
          label: 'Portfolio Budget Shorts',
          value: budgets?.shorts_total,
          color: STACKED_CHART_SET_TABLEAU[0],
        },
      ],
      total: budgets?.total,
    },
    emissions: {
      children: [
        {
          label: 'Portfolio Emissions Longs',
          value: emissions?.longs_total,
          color: emissionsColor,
        },
        {
          label: 'Portfolio Emissions Shorts',
          value: emissions?.shorts_total,
          color: emissionsColor,
        },
      ],
      total: emissions?.total,
    },
    benchmark: {
      children: [
        {
          label: 'Benchmark Longs',
          value: benchmark?.longs_total,
          color: emissionsColor,
        },
        {
          label: 'Benchmark Shorts',
          value: benchmark?.shorts_total,
          color: emissionsColor,
        },
      ],
      total: emissions?.total,
    },
  }
}

export const getStandardColor = data => {
  const { budgets, emissions } = data

  return emissions.total > budgets.total
    ? repeatArray(STACKED_CHART_SET_RED, budgets?.children?.length)
    : repeatArray(STACKED_CHART_SET_GREEN, budgets?.children?.length)
}

export const formatCellValue = (number, decimals) => {
  if (!number && number != 0) return '-'
  const fNumber = parseFloat(number)
  if (isNaN(fNumber)) return '-'

  if (Math.abs(fNumber) > 1000000000) {
    return abbreviateNumber(Math.round(fNumber))
  }

  if (decimals) {
    return addThousandSeparator(fNumber, decimals)
  }

  return fNumber.toLocaleString()
}

export const renderBudgetIcon = (amount, budget) => {
  let delta = amount - budget
  if (delta > 0)
    return (
      <i
        style={{ color: OVER_BUDGET_COLOR }}
        className='fas fa-arrow-alt-up over-budget-icon'
      />
    )
  else
    return (
      <i
        style={{ color: UNDER_BUDGET_COLOR }}
        className='fas fa-arrow-alt-down below-budget-icon'
      />
    )
}
