import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import AsyncSelect from 'react-select-3/async'

import Button from '../../../../components/styleguide/Button'
import InputLabel from '../../../../components/styleguide/InputLabel'
import SectionWrapper from '../../../../components/styleguide/SectionWrapper'
import StyledDialog from '../../../../components/styleguide/StyledDialog'
import WrapperColumn from '../../../../components/styleguide/WrapperColumn'
import YBDescription from '../../../../components/styleguide/YBDescription'
import { getEntities } from '../../../../repositories/entities_repository'
import { updateEntityPeers } from '../../../../repositories/instruments_repository'
import SpinnerContainer from '../../../../yb_components/styleguide/spinners/SpinnerContainer'
import {
  findId,
  mapAndPopulatePeerObject,
  sortArrayValuesFromObj,
} from './helpers'
import { StyledList } from './styles'

const ReplacePeerModal = ({ show, peers, setGlobalPeers, onClose }) => {
  const [currentEntity, ...entityPeers] = peers

  const mapPeersToSelectOptions = () => {
    return Object.fromEntries(mapAndPopulatePeerObject(entityPeers, 4))
  }

  const [peersSelected, setPeersSelected] = useState(mapPeersToSelectOptions())
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    setPeersSelected(mapPeersToSelectOptions())
    setErrorMessage('')
  }, [show])

  const handleInstrumentChange = peerIndex => option => {
    setPeersSelected({
      ...peersSelected,
      [peerIndex]: { id: option?.value, name: option?.label },
    })
  }

  const onLoadOptions = input => {
    const queryParams = `name=${input}`
    return getEntities(queryParams).then(response => {
      return response.reduce((result, entity) => {
        if (
          entity.id !== currentEntity.id &&
          !findId(entity.id, peersSelected)
        ) {
          return [...result, { label: entity.name, value: entity.id }]
        }
        return result
      }, [])
    })
  }

  const handleUpdatePeers = () => {
    const newPeersIds = Object.values(peersSelected).map(peer => {
      return peer.id
    })
    setIsUpdateDisabled(true)
    setErrorMessage('')
    updateEntityPeers(currentEntity.id, newPeersIds)
      .then(response => {
        setGlobalPeers(
          sortArrayValuesFromObj(peersSelected, response.data, [currentEntity])
        )
      })
      .catch(() => {
        setErrorMessage('Something went wrong. Please try again later.')
      })
      .finally(() => {
        setIsUpdateDisabled(false)
        onClose()
      })
  }

  const renderError = () =>
    errorMessage.length > 0 && (
      <div className='alert alert-danger'> {errorMessage} </div>
    )
  return (
    peers?.length > 1 && (
      <StyledDialog
        show={show}
        size='md'
        width='auto'
        padding='20px'
        sectionsPadding='0px'
        onHide={isUpdateDisabled ? () => {} : onClose}
        {...(isUpdateDisabled ? { backdrop: 'static' } : {})}
        {...(isUpdateDisabled ? { keyboard: false } : {})}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Peer Companies</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WrapperColumn>
            <YBDescription>
              {`Select four companies that will appear as peers for ${currentEntity.name} throughout the website and your downloaded reports.`}
            </YBDescription>

            <InputLabel label='COMPANY' margin='5px 0px 14px 10px' />

            <StyledList>
              <li> {currentEntity.name} </li>
              {Object.entries(peersSelected).map(([index, peer]) => (
                <li key={index}>
                  <AsyncSelect
                    components={{ IndicatorSeparator: () => null }}
                    value={{ label: peer.name, value: peer.id }}
                    defaultOptions={[{ label: peer.name, value: peer.id }]}
                    loadOptions={onLoadOptions}
                    onChange={handleInstrumentChange(index)}
                  />
                </li>
              ))}
            </StyledList>

            {renderError()}
          </WrapperColumn>
          <SectionWrapper justify='flex-end'>
            <SpinnerContainer
              size='2rem'
              className='loading-icon'
              isLoading={isUpdateDisabled}
            />
            <Button
              tertiary
              label='Cancel'
              width='auto'
              disabled={isUpdateDisabled}
              onClick={onClose}
            />
            <Button
              disabled={isUpdateDisabled}
              label='UPDATE PEERS'
              width='auto'
              onClick={handleUpdatePeers}
            />
          </SectionWrapper>
        </Modal.Body>
      </StyledDialog>
    )
  )
}

export default ReplacePeerModal
