import React from 'react'

import { formatTextCell } from '../../../helpers/shared'
import { titleCase } from '../../../yb_helpers/strings'
import { formatValue } from '../../portfolio/components/carbon_report/helpers'
import ReportSummaryCard from '../../portfolio/components/carbon_report/ReportSummaryCard'
import { StyledCellSubValue } from '../../portfolio/components/carbon_report/styles'
import {
  BudgetProjectionTypes,
  ClimateChangeTypes,
  REPORT_SUMMARY_CARD_COPY,
} from '../constants'

export const formatCompanyCarbonSummaryCard = data => {
  if (data?.err?.response) {
    return data
  } else if (data) {
    const keys = Object.keys(data)
    const formattedData = {
      dataBreakdown: [],
    }
    const lowerTextStyle = { fontWeight: 400, fontSize: '18px' }

    const carbonBudgetComparison = (target, sector) => {
      let positive = false
      if (
        BudgetProjectionTypes[target]?.key < BudgetProjectionTypes[sector]?.key
      ) {
        positive = true
      } else if (
        BudgetProjectionTypes[target]?.key > BudgetProjectionTypes[sector]?.key
      ) {
        positive = false
      } else {
        positive = 'neutral'
      }
      if (positive === 'neutral' && sector) {
        return (
          <StyledCellSubValue color='#2E384D;'>
            {`Overall, the sector is ${titleCase(
              BudgetProjectionTypes[sector]?.name
            )}.`}
          </StyledCellSubValue>
        )
      } else if (sector) {
        return (
          <StyledCellSubValue positive={positive}>
            {`Overall, the sector is ${titleCase(
              BudgetProjectionTypes[sector]?.name
            )}.`}
          </StyledCellSubValue>
        )
      }
    }

    const climateChangeStrategy = (target, sector) => {
      let positive = false
      if (ClimateChangeTypes[target]?.key < ClimateChangeTypes[sector]?.key) {
        positive = true
      } else if (
        ClimateChangeTypes[target]?.key > ClimateChangeTypes[sector]?.key
      ) {
        positive = false
      } else {
        positive = 'neutral'
      }
      const vowelRegex = '^[aieouAIEOU].*'

      const article = sector?.match(vowelRegex) ? 'an' : 'a'

      if (positive === 'neutral' && sector) {
        return (
          <StyledCellSubValue color='#2E384D;'>
            {`Overall, the sector is ${article} ${titleCase(sector)}.`}
          </StyledCellSubValue>
        )
      } else if (sector) {
        return (
          <StyledCellSubValue positive={positive}>
            {`Overall, the sector is ${article} ${titleCase(sector)}.`}
          </StyledCellSubValue>
        )
      }
    }

    keys.forEach(key => {
      key === 'carbon_footprint' &&
        (formattedData.footPrint = {
          value: formatValue(data[key]?.target),
          benchmarkValue: data[key]?.sector,
          symbol: 'tCO2e',
          customComparativeStrings: {
            green: 'less than the sector average.',
            red: 'more than the sector average.',
          },
        })

      key === 'carbon_intensity' &&
        formattedData.dataBreakdown.push({
          text: (
            <>
              <span style={{ display: 'block' }}>
                {formatValue(data[key]?.target)}
              </span>
              <span style={lowerTextStyle}>tCO2e / $M</span>
            </>
          ),
          upperText: 'CARBON INTENSITY',
          value: data[key]?.target,
          benchmarkValue: data[key]?.sector,
          customComparativeStrings: {
            green: 'less than the sector average.',
            red: 'more than the sector average.',
          },
        })

      if (key === 'carbon_budget') {
        const label = titleCase(data[key]?.target)

        formattedData.dataBreakdown.push({
          text: formatTextCell(label),
          upperText: '2030 CARBON BUDGET',
          value: data[key]?.target,
          benchmarkValue: data[key]?.sector,
          customComparativeFunction: carbonBudgetComparison,
        })
      }

      key === 'climate_change_strategy' &&
        formattedData.dataBreakdown.push({
          text: formatTextCell(titleCase(data[key]?.target)),
          upperText: 'CLIMATE CHANGE STRATEGY',
          value: data[key]?.target,
          benchmarkValue: data[key]?.sector,
          customComparativeFunction: climateChangeStrategy,
        })
      key === 'annual_carbon_cost' &&
        formattedData.dataBreakdown.push({
          text: (
            <>
              <span style={{ display: 'block' }}>
                {formatValue(data[key]?.target)}
              </span>
              <span style={lowerTextStyle}>$ / tCO2e</span>
            </>
          ),
          upperText: 'ANNUAL CARBON COST',
          value: data[key]?.target,
          benchmarkValue: data[key]?.sector,
          customComparativeStrings: {
            green: 'less than the sector average.',
            red: 'more than the sector average.',
          },
        })
    })
    return formattedData
  }
}

export const renderCompanyCarbonReportWidget = (data, pdf = false) => {
  return !data?.has_holdings ? (
    <ReportSummaryCard
      pdf={pdf}
      dataProp={data}
      title={REPORT_SUMMARY_CARD_COPY.title}
      moduleDescription={REPORT_SUMMARY_CARD_COPY.companyDescription}
      explainerLink={
        'http://help.yves.blue/en/articles/6698762-carbon-report-portfolio-summary-on-yvesblue#h_809c4a6535'
      }
    />
  ) : (
    <ReportSummaryCard
      dataProp={data}
      title={REPORT_SUMMARY_CARD_COPY.title}
      moduleDescription={REPORT_SUMMARY_CARD_COPY.fundDescription}
      explainerLink={
        'http://help.yves.blue/en/articles/6698762-carbon-report-portfolio-summary-on-yvesblue#h_0cf611909d'
      }
    />
  )
}
