import DOMPurify from 'dompurify'
import React, { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import styled from 'styled-components'

import Card from '../../../../../components/styleguide/Card'
import { BreadcrumbNav } from '../../../../../yb_components/styleguide/breadcrumbs/BreadcrumbNav'
import Paginator from '../../../../../yb_components/styleguide/pagination/Pagination'
import LoadingSpinner from '../../../../../yb_components/styleguide/spinners/LoadingSpinner'
import FlatTable from '../../../../../yb_components/tables/flat_table/FlatTable'
import { getNewPage } from '../../../../research_center/helpers/paginator'
import {
  BREADCRUMB_LINKS,
  CONTENT,
} from './constants/tcfd_data_sheet.constants'
import { loadTcfdDataSheet } from './loaders/tcfd_data_sheet_loaders'

const PageContainer = styled.div`
  h1 {
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
    color: #2e384d;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  th:nth-child(2) span {
    justify-content: center;
  }
  .header-paragraph {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 15.5px;
    line-height: 22px;
    color: #979ca6;
    margin-bottom: 40px;
  }
  .paginator-wrapper {
    display: flex;
    & > div {
      margin: auto;
    }
  }
  th > span {
    color: #979ca6 !important;
  }
`

export const TcfdDataSheetPage = ({ dataName }) => {
  const params = window.location.pathname.split('/')
  const name = params[params.length - 1]

  const [sort, setSort] = useState({ field: 'id', dir: 'asc' })
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [tableHeaders, setTableHeaders] = useState(null)
  const [tableBody, setTableBody] = useState(null)
  const [numberOfPages, setNumberOfPages] = useState(null)
  const [selectedPage, setSelectedPage] = useState(1)

  const limit = 10

  useEffect(() => {
    BREADCRUMB_LINKS.push({
      text: `${CONTENT[dataName].title}`,
      active: true,
    })
  }, [])

  useEffect(() => {
    loadTcfdDataSheet(
      name,
      setData,
      setError,
      setLoading,
      selectedPage,
      limit,
      sort.field,
      sort.dir
    )
  }, [])

  useEffect(() => {
    setNumberOfPages(data?.total_pages)
    formatTableData(data?.tcfd_data)

    if (data?.field_map) {
      setTableHeaders(data.field_map)
    }
  }, [data])

  useEffect(() => {
    loadTcfdDataSheet(
      name,
      setData,
      setError,
      setLoading,
      1,
      limit,
      sort.field,
      sort.dir
    )
  }, [sort])

  const formatTableData = dataArg => {
    const body = []

    if (dataArg) {
      dataArg.forEach(item => {
        const internalBodyArray = []
        for (const prop in item) {
          internalBodyArray.push(item[prop])
        }
        body.push(internalBodyArray)
      })
      setTableBody(body)
    }
  }

  const onPaginatorChange = value => {
    const newPage = getNewPage(value, selectedPage, numberOfPages)
    setSelectedPage(newPage)
    loadTcfdDataSheet(
      name,
      setData,
      setError,
      setLoading,
      newPage,
      limit,
      sort.field,
      sort.dir
    )
  }

  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank')
      node.setAttribute('rel', 'noopener')
    }
  })

  return (
    <PageContainer className='page__container' style={{ marginTop: '20px' }}>
      <header style={{ marginBottom: '20px' }}>
        <BreadcrumbNav links={BREADCRUMB_LINKS} />
        <h1>{CONTENT[dataName].title}</h1>
        <p
          className='header-paragraph'
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(CONTENT[dataName].paragraph),
          }}
        ></p>
      </header>
      <main style={{ marginBottom: '20px' }}>
        {tableBody && tableHeaders ? (
          <Card>
            <FlatTable
              onSort={(field, direction) =>
                setSort({ field: field, dir: direction })
              }
              sort={sort}
              headers={tableHeaders}
              body={tableBody}
              isLoading={loading}
              noDataText={<p>No items were found.</p>}
            />
            <div className='paginator-wrapper'>
              <Paginator
                max={numberOfPages}
                selected={selectedPage}
                onChange={onPaginatorChange}
              />
            </div>
          </Card>
        ) : loading ? (
          <LoadingSpinner />
        ) : (
          error && (
            <Alert variant='danger'>
              Our apologies, there was an error. We’ve been notified but if you
              need assistance, please email{' '}
              <a href='mailto:support@yves.blue'>support@yves.blue</a>.
            </Alert>
          )
        )}
      </main>
    </PageContainer>
  )
}
