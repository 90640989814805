import React from 'react';
import styled from 'styled-components';

const ChartDataColumn = ({ x, y, height, width, radius, color, ...props }) => {
  let rectHeight = 0;

  if(!height) return null;
  else if(height > radius) {
    rectHeight = height - radius;
  } else {
    rectHeight = 0;
  }

  return (
    <path fill={color} {...props} d={`
      M ${x} ${y}
      v ${-rectHeight}
      q 0 ${-radius} ${radius} ${-radius}
      h ${width - (2 * radius)}
      q ${radius} 0 ${radius} ${radius}
      v ${rectHeight}
    `} />
  );
}

ChartDataColumn.defaultProps = {
  color: 'black'
};

export default ChartDataColumn;