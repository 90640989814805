import React from 'react'

import Card from '../styleguide/Card'
import CardTitle from '../styleguide/CardTitle'

const PortfolioStatusCard = ({ title, children }) => {
  return (
    <Card padding='20px 36px' height='200px' style={{ marginBottom: '20px' }}>
      <CardTitle fontSize='18px' title={title} />
      {children}
    </Card>
  )
}

export default PortfolioStatusCard
