import React from 'react';
import YBBreadcrumb from '../../../../../../components/styleguide/YBBreadcrumb';
import Comparisons from '../comparisons/Comparisons';
import { PageTitle, StyledContainer } from './PortfolioComparisonsIndex.styles';

const PortfolioComparisonsIndex = () => {
  const breadcrumbs = [
    { link: "/app", name: "Home", active: false },
    { link: "/app/portfolios", name: "Portfolios", active: false },
    { link: null, name: "Comparisons", active: true },
  ]

  return (
    <StyledContainer>
      <YBBreadcrumb data-cy="portfolio-comparisons-breadcrumb" breadcrumbs={ breadcrumbs } />
      <PageTitle> Portfolio Comparisons </PageTitle>
      <Comparisons/>
    </StyledContainer>
  )
}

export default PortfolioComparisonsIndex
