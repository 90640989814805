import React from 'react'
import styled from 'styled-components'

import { FONT_COLOR_SECONDARY } from '../../../../../styles/colors'
import { PaiDownloadsCard } from '../components/downloads_card/PaiDownloadsCard'

const StyledMain = styled.main`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
`

export const PaiDownloadsTab = ({ portfolioId, snapshotId }) => {
  const params = { portfolioId, snapshotId }

  return (
    <>
      <p style={{ color: `${FONT_COLOR_SECONDARY}`, flexGrow: 1 }}>
        Financial market participants are required to report on their
        performance in the previous reference period by June 30th each year and
        for the first time in 2023. PAI Tables 1, 2, and 3 are required to be
        published directly on the financial market participant’s website in a
        separate section titled, ‘Principal adverse sustainability impacts
        statement’.
      </p>
      <StyledMain>
        <PaiDownloadsCard
          tableName={'Table 1'}
          description='The indicators related to principal adverse impacts on sustainability factors as set out in Table 1 and Policy Statements.'
          {...params}
        />
        <PaiDownloadsCard
          tableName={'Table 2'}
          description='One additional indicator related to principal adverse impacts on a climate or other environment related sustainability factor that qualifies as principal as set out in Table 2.'
          {...params}
        />
        <PaiDownloadsCard
          tableName={'Table 3'}
          description='One additional indicator related to principal adverse impacts on a social, employee, human rights, anti-corruption or anti-bribery sustainability factor that qualifies as principal as set out in Table 3.'
          {...params}
        />
      </StyledMain>
    </>
  )
}
