import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React from 'react'

import { UnitHelper } from './UnitHelper'

dayjs().format()

export const CurrentValueCell = ({
  enabled,
  subMetrics,
  metricDetailsLink,
}) => {
  // show single dash when no estimated_last_reported_value(s) present
  const arr = subMetrics.map(el => el.current_value).filter(val => val != null)
  const showAddLink = arr.length === 0 && enabled
  const showNum = subMetrics.length > 1 && enabled

  return enabled && subMetrics.length ? (
    <span>
      {showAddLink ? (
        <a href={metricDetailsLink}>Add Current Value</a>
      ) : subMetrics.length ? (
        subMetrics.map((el, i) => {
          if (el.current_value != null)
            return (
              <React.Fragment key={el.id}>
                {showNum ? (
                  <span className='cell-list-span' key={`indicator_${el.id}`}>
                    ({i + 1}){' '}
                    <UnitHelper measure={el.current_value} unit={el.unit} />
                  </span>
                ) : (
                  <span className='cell-list-span' key={`indicator_${el.id}`}>
                    <UnitHelper measure={el.current_value} unit={el.unit} />
                  </span>
                )}
                {el.current_value_as_of != null && (
                  <span className='cell-date-span' key={`link_${el.id}`}>
                    (as of{' '}
                    <a href={metricDetailsLink}>
                      {dayjs(el.current_value_as_of).format('MMMM D, YYYY')}
                    </a>
                    )
                  </span>
                )}
              </React.Fragment>
            )
        })
      ) : (
        <span>—</span>
      )}
    </span>
  ) : (
    <span>—</span>
  )
}

CurrentValueCell.propTypes = {
  enabled: PropTypes.bool,
  subMetrics: PropTypes.array,
  metricDetailsLink: PropTypes.string.isRequired,
}
