import React from 'react'

const ESGScale = ({ scale }) => {
  const labelsCount = scale.length
  return (
    <div className='esg-scale'>
      {scale.map(s => (
        <div
          key={s.color}
          className='scale-item'
          style={{ width: `${100 / labelsCount}%` }}
        >
          <div style={{ backgroundColor: s.color }} className='color-box' />
          <span className='label'>
            {`${s.min} to ${Math.round(s.max)}`}
            <br />
            {`${s.label}`}
          </span>
        </div>
      ))}
    </div>
  )
}

export default ESGScale
