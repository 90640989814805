import React from 'react';
import styled from 'styled-components'


const WrapperRow = styled.div`
    display:flex;
    justify-content:${props => props.styles && props.styles.justify};
    margin: ${props => props.styles && props.styles.margin};
    align-items:${props => props.styles && props.styles.align};
    width: ${props => props.styles && props.styles.width};
    height: ${props => props.styles && props.styles.height};
    color: ${props => props.styles && props.styles.color};
    background-color: ${props => props.styles && props.styles.bgColor};
    padding: ${props => props.styles && props.styles.padding};
    border-radius:  ${props => props.styles && props.styles.borderRadius};
    border: ${props => props.styles && props.styles.border};
    box-shadow: ${props => props.styles && props.styles.boxShadow};
    max-height: ${props => props.styles && props.styles.maxHeight};
    min-height: ${props => props.styles && props.styles.minHeight};

    ${props => props.grow && `
      & > * {
        flex-grow: 1;
        flex-basis: 0;
      }
    `}
`

export default WrapperRow;
