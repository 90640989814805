import React, { useState, useEffect } from 'react';
import _sortBy from 'lodash.sortby';
import { Sparklines, SparklinesLine } from 'react-sparklines';


import { ModuleWrapper, FundTableWrapper, TableButton, HoldingPaginatorDLWrapper, StyledLink, DeletePipelineDialog } from "../../styles";
import Button from "../../../../components/styleguide/Button";
import ErrorModule from '../../components/ErrorModule';
import SaveButton from '../../components/SaveButton';
import WrapperColumn from '../../../../components/styleguide/WrapperColumn';
import { Modal } from "react-bootstrap";


// COMPONENTS
import HoldingCreateModal from "./components/HoldingCreateModal";

// MODELS
import { Holding } from "../../models/Holding";

// CONSTANTS
import { pipelineStatesTitleized, holdingTableHeaders } from "../../../../constants/funds";

// REPOSITORY CALLS
import Table from '../../../../components/styleguide/Table';
import { queryHoldings, deleteFundHolding, exportHoldings } from '../../../../repositories/funds_repository';
import Paginator from '../../../../components/styleguide/Paginator';
import { getNewPage } from '../../../research_center/helpers/paginator';
import SectionWrapper from '../../../../components/styleguide/SectionWrapper';
import Link from '../../../../components/styleguide/Link';
import HoldingPipelineEditModal from './components/HoldingPipelineEditModal';
import YBSpan from '../../../../components/styleguide/YBSpan';
import { exportFundHoldings } from '../../../../helpers/export_holdings_helper';

const HoldingSection = ({ fundId, callback, margin, config, fundName }) => {
    const [holdingInModal, setHoldingInModal] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [errors, setErrors] = useState([]);
    const [holdingsList, setHoldingsList] = useState([]);
    const [selected, setSelected] = useState(1);
    const [nPages, setNPages] = useState(0);
    const [modalType, setModalType] = useState("create");
    const [showPipelineModal, setShowPipelineModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [tempDeleteHolding, setTempDeleteHolding] = useState(null);
    const [fundHasHoldings, setFundHasHoldings] = useState(false);
    const [revenue, setRevenue] = useState([30, 20, 30]);
    const [targetGrowth, setTargetGrowth] = useState([90,110,212,32]);
    const [yoYGrowth, setYoYGrowth] = useState([50,80,90,30]);

    const canUpdate = (config.action === "create" || config.canUpdate );

    useEffect(() => {
        fundId &&
        getNewHoldings()
    }, [fundId, config.filter, config.investmentType]);

    const getNewHoldings = (page = 1, direction = "asc") => {
        queryHoldings(
            getHoldingQuery(page, direction)
        ).then((response) => {
            const holdingsParams = response.data.queried_holdings;
            let list = _.map(holdingsParams, (holdingParams) => {
                const newHolding = new Holding(holdingParams);
                if(holdingParams.last_updated){
                    newHolding.setLastUpdated(holdingParams.last_updated)
                }
                return newHolding;
            });

            if(list.length > 0){
                setFundHasHoldings(true);
            } else {
                setFundHasHoldings(false);
            }
            if(config.filter){
                list = list.filter((holding) => holding.pipeline_state.split("_").join(" ") === config.filter.toLowerCase());
            }
            setHoldingsList(list);
            setNPages(1);
        })
    };

    const getHoldingQuery = (page, direction) => {
        switch(config.investmentType){
            case "Basic":
                    return {
                        fund_id: fundId,
                        fields: ["name", "vertical", "industry", "primary_market", "pipeline_state", "id", "value"],
                        sort_field: "name",
                        sort_direction: direction,
                        page: page,
                        limit: "10"
                    };
            case "Impact":
                    return {
                        fund_id: fundId,
                        fields: ["name", "id"],
                        last_updated: "true",
                        sort_field: "name",
                        sort_direction: direction,
                        page: page,
                        limit: "10"
                    };
            case "Financial":
                    return {
                        fund_id: fundId,
                        fields: ["name", "weight", "value", "id"],
                        reports:[
                            {
                                key: "default",
                                items: ["revenue"]
                            },
                        ],
                        sort_field: "name",
                        sort_direction: direction,
                        page: page,
                        limit: "10"
                    };
        }
    }

    const handleModalShow = () => {
        setShowModal(true);
        setModalType("create");
    };

    const closeModal = () => {
        setHoldingInModal(null);
        setShowModal(false);
        setShowPipelineModal(false);
        setTempDeleteHolding(null);
        setShowDeleteModal(false);
    };

    const onSave = () => {
        callback();
    };

    const replaceHolding = (newHolding) => {
        const holdingIndex = holdingsList.reduce((acc, el, index) => {
            if(el.id === newHolding.id){
                acc = index;
            };
            return acc;
        }, -1);
        const newHoldingsList = holdingsList.slice();
        newHoldingsList[holdingIndex] = newHolding;
        setHoldingsList(newHoldingsList);
    }

    const onModalSubmit = (newHolding, type) => {
        if(type === "edit"){
            if(config.filter){
                if(newHolding.pipeline_state.split("_").join(" ") === config.filter.toLowerCase()){
                    replaceHolding(newHolding);
                } else {
                    const newList = holdingsList.filter((holding) => holding.id !== newHolding.id);
                    setHoldingsList(newList);
                }
            } else {
                replaceHolding(newHolding);
            }
        }else{
            if(config.filter){
                if(
                    (newHolding.pipeline_state && newHolding.pipeline_state.split("_").join(" ") === config.filter.toLowerCase()) ||
                    (newHolding.state && newHolding.state.split("_").join(" ") === config.filter.toLowerCase())
                ){
                    addHoldingToList(newHolding);
                }
            } else {
                addHoldingToList(newHolding);
            }
        }
        setShowModal(false);
        setFundHasHoldings(true);
        setShowPipelineModal(false);
        setHoldingInModal(null);
    };

    const getCurrentFields = () => {
        switch(config.investmentType){
            case "Basic":
                return ["name", "vertical", "industry", "primary_market", "pipeline_state", "id", "value"];
            case "Impact":
                return ["name", "id"];
            case "Financial":
                return ["name", "weight", "value", "id"];
        }
    }

    const exportToCSV = () => {
        const fields = getCurrentFields();
        const params = {
            fields: fields,
            sort_field: name,
            sort_direction: "desc",
            page: selected,
            // "limit": "10",
            file_type: "csv"
        }
        exportFundHoldings({ fundID: fundId, params, type:"csv", fundName });
    }


    const addHoldingToList = (newHolding) => {
        const newHoldings = holdingsList.slice();
        newHoldings.push(newHolding);
        setHoldingsList(newHoldings);
    };

    const removeHolding = (removeHolding) => {
        const newHoldings = holdingsList.filter((holding) => holding.id !== removeHolding.id);
        setHoldingsList(newHoldings);
    }

    const onDelete = (holdingToDelete) => {
        setShowDeleteModal(true);
        setTempDeleteHolding(holdingToDelete)
    };

    const deleteHolding = () => {
        deleteFundHolding(fundId, tempDeleteHolding.id).then(() => {
            removeHolding(tempDeleteHolding);
            closeModal();
            if(holdingsList.length === 1){
                setFundHasHoldings(false);
            }
        })
        .catch((error) => {
            setErrors(error.response.data);
        })
    };

    const onEdit = (holding) => {
        setModalType("edit");
        setShowModal(true);
        setHoldingInModal(holding);
    }

    const onMove = (holding) => {
        setShowPipelineModal(true);
        setHoldingInModal(holding);
    }

    const tableRows = () => {
        return holdingsList.map((holding) => {
            if(config.save){
                return [
                    <span data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-name`} className="left ellipsis name title">{holding.name ? holding.name : "-"}</span>,
                    <span data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-vertical`} >{holding.vertical ? holding.vertical : "-"}</span>,
                    <span data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-industry`} >{holding.industry ? holding.industry : "-"}</span>,
                    <span data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-primary_market`} >{holding.primary_market ? holding.primary_market.toUpperCase() : "-"}</span>,
                    <span data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-pipeline-state`} >{holding.pipeline_state ? pipelineStatesTitleized[holding.pipeline_state] : "-"}</span>,
                ];
            }
            else{
                switch(config.investmentType){
                    case "Basic":
                        let rows = [
                            <span data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-name`}  className="left ellipsis name title"><a href={`/app/funds/${fundId}/holdings/${holding.id}`}>{holding.name ? holding.name : "-"}</a></span>,
                            <span data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-vertical`} >{holding.vertical ? holding.vertical : "-"}</span>,
                            <span data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-industry`} >{holding.industry ? holding.industry : "-"}</span>,
                            <span data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-primary_market`} >{holding.primary_market ? holding.primary_market.toUpperCase()  : "-"}</span>,
                            <span data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-pipeline_state`} >{holding.pipeline_state ? pipelineStatesTitleized[holding.pipeline_state] : "-"}</span>,
                        ]

                        if (canUpdate) {
                            rows.push(<span data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-edit-wrapper`} >
                                <TableButton data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-edit-button`}  onClick={() => onEdit(holding)}>Edit</TableButton>
                                <span>  </span>
                                <TableButton data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-move-button`}  onClick={() => onMove(holding)}>Move</TableButton>
                            </span>)
                            rows.push(
                                <TableButton data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-delete-button`}  onClick={() => onDelete(holding)}>DELETE</TableButton>
                            )
                        } else {
                            rows.push(<TableButton/>);
                            rows.push(<TableButton/>);
                        }

                        return rows;
                    case "Impact":
                        return [
                            <span data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-name`} className="left ellipsis name title"><a href={`/app/funds/${fundId}/holdings/${holding.id}`}>{holding.name ? holding.name : "-"}</a></span>,
                            <span data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-metric_one`} >{holding.key_metric_one ? holding.key_metric_one : "-"}</span>,
                            <span data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-target_growth_wrapper`} >
                                {
                                    targetGrowth && targetGrowth.length > 0 ? (
                                    <>
                                    <YBSpan margin="0px 0px 0px 0px">{targetGrowth[targetGrowth.length - 1]}%</YBSpan>
                                    <Sparklines data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-key_metric_1_sparklines`}  data={targetGrowth} svgWidth={90} svgHeight={35}>
                                        <SparklinesLine data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-target_growth_sparkline`}  color="blue" />
                                    </Sparklines>
                                    </> ) : "-"
                                }
                            </span>,
                            <span data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-metric_two`}>{holding.key_metric_two ? holding.key_metric_two : "-"}</span>,
                            <span>
                                {
                                    targetGrowth && targetGrowth.length > 0 ? (
                                    <>
                                    <YBSpan margin="0px 0px 0px 0px">{targetGrowth[targetGrowth.length - 1]}%</YBSpan>
                                    <Sparklines data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-key_metric_two_sparklines`} data={targetGrowth} svgWidth={90} svgHeight={35}>
                                        <SparklinesLine color="blue" />
                                    </Sparklines>
                                    </> ) : "-"
                                }
                            </span>,
                            holding.last_updated ?
                            <span>
                                {new Date(holding.last_updated.datetime).getUTCFullYear()}-
                                {new Date(holding.last_updated.datetime).getUTCMonth()}-
                                {new Date(holding.last_updated.datetime).getUTCDate()}
                                <br/>
                                ({holding.last_updated.name})
                            </span> :
                            <span>-</span>,
                            <span></span>,
                            <span></span>

                        ]
                    case "Financial":
                        return [
                            <span data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-name`} className="left ellipsis name title"><a href={`/app/funds/${fundId}/holdings/${holding.id}`}>{holding.name ? holding.name : "-"}</a></span>,
                            <span data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-value`}>{holding.value ? parseInt(holding.value).toFixed(2) : "-"}</span>,
                            <span data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-weight`} >{holding.weight ? parseInt(holding.weight).toFixed(2) : "-"}</span>,
                            <span>
                                {
                                    holding.reports &&
                                    holding.reports[0] &&
                                    holding.reports[0].items &&
                                    holding.reports[0].items[0].values &&
                                    holding.reports[0].items[0].values.length > 0 ? (
                                    <>
                                    <YBSpan margin="0px 0px 0px 0px">{ holding.reports[0].items[0].values[holding.reports[0].items[0].values.length - 1]}k</YBSpan>
                                    <Sparklines data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-revenue_sparklines`} data={holding.reports[0].items[0].values} svgWidth={90} svgHeight={35}>
                                        <SparklinesLine data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-revenue_sparkline`} color="blue" />
                                    </Sparklines>
                                    </> ) : "-"
                                }
                            </span>,
                            <span>
                                {
                                    targetGrowth && targetGrowth.length > 0 ? (
                                    <>
                                    <YBSpan margin="0px 0px 0px 0px">{targetGrowth[targetGrowth.length - 1]}%</YBSpan>
                                    <Sparklines data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-target_growth_sparklines`} data={targetGrowth} svgWidth={90} svgHeight={35}>
                                        <SparklinesLine data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-target_growth_sparkline`} color="blue" />
                                    </Sparklines>
                                    </> ) : "-"
                                }
                            </span>,
                            <span>
                                {
                                    yoYGrowth && yoYGrowth.length > 0 ? (
                                    <>
                                    <YBSpan margin="0px 0px 0px 0px">{yoYGrowth[yoYGrowth.length - 1]}%</YBSpan>
                                    <Sparklines data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-yoy-sparklines`}  data={yoYGrowth} svgWidth={90} svgHeight={35}>
                                        <SparklinesLine data-cy={`PE-holding-table-${config.investmentType}-${holding.id}-yoy-sparkline`}  color="blue" />
                                    </Sparklines>
                                    </> ) : "-"
                                }
                            </span>,
                            <span></span>,
                            <span></span>
                        ];
                }
            }
        });
    };

    const onSort = (id, direction) => {
        let sorted = _sortBy(holdingsList, h => {
            return h[id];
        });

        if(direction == "asc") {
            sorted = sorted.reverse();
        }

        setHoldingsList(sorted);
    };

    const onPaginatorChange = (value) => {
        const newPage = getNewPage(value, selected, nPages);
        setSelected(newPage);
        getNewHoldings(newPage);
    }

    const getHeaders = () => {
        if(config.investmentType === "Basic" && !config.save){
            return holdingTableHeaders['basicshow'];
        } else {
            return holdingTableHeaders[config.investmentType.toLowerCase()];
        }
    }

    return(
        <>
            <ErrorModule errors={errors}/>
            <ModuleWrapper margin={margin}>
                <FundTableWrapper margin={config.save && !config.add && "0px 0px 0px 0px"} minHeight={config.save && !config.add && holdingsList.length === 0 && "300px"} secondChildWidth={ config.investmentType === "Financial" ? "200px" : null }>
                    <Table
                        headers={getHeaders()}
                        body={tableRows()}
                        onSort={onSort}
                        showSort={!config.save}
                        className="fund-holdings-table"
                    />
                    {
                        holdingsList.length > 0 &&
                        <HoldingPaginatorDLWrapper>
                            {
                                config.add && canUpdate && (
                                    <SectionWrapper className="button-wrapper" width="15%">
                                        <Button label="Add To Pipeline" onClick={handleModalShow}/>
                                    </SectionWrapper>
                                )
                            }
                           {
                               !config.save && (
                                <>
                                <SectionWrapper>
                                    <Paginator max={nPages} selected={selected} onChange={onPaginatorChange}/>
                                </SectionWrapper>
                                <SectionWrapper className="download-wrapper" margin="18px 0px 0px 0px" align="center">
                                    <StyledLink onClick={exportToCSV}>Download</StyledLink>
                                </SectionWrapper>
                                </>
                               )
                            }
                        </HoldingPaginatorDLWrapper>
                    }
                </FundTableWrapper>

                {
                    !fundHasHoldings &&
                    <WrapperColumn styles={{justify:'center', align:'center', margin:'30px 0px 30px 0px'}}>
                        <div>You have no holdings, yet.</div>
                        { canUpdate && <div onClick={handleModalShow} style={{color: "blue", "textDecoration": "underline", cursor: "pointer"}}>Add a Holding</div> }
                    </WrapperColumn>
                }
                {
                    fundHasHoldings && holdingsList.length < 1 && config.filter &&
                    <WrapperColumn styles={{justify:'center', align:'center', margin:'30px 0px 30px 0px'}}>
                        <div>You have no holdings that are in the {config.filter.toLowerCase()} state, yet.</div>
                        {
                            canUpdate && <div onClick={handleModalShow} style={{color: "blue", "textDecoration": "underline", cursor: "pointer"}}>Add a Holding</div>
                        }
                    </WrapperColumn>
                }
                {
                    showModal && <HoldingCreateModal
                        errors={errors}
                        holding={holdingInModal}
                        show={showModal}
                        onSubmit={onModalSubmit}
                        onHide={closeModal}
                        fundId={fundId}
                        config={{type:modalType, ...config}}
                    />
                }
                {
                    showPipelineModal && <HoldingPipelineEditModal
                        errors={errors}
                        holding={holdingInModal}
                        show={showPipelineModal}
                        onSubmit={onModalSubmit}
                        onHide={closeModal}
                        fundId={fundId}
                        config={{type:modalType, ...config}}
                    />
                }
                {
                    showDeleteModal && <DeletePipelineDialog show={showDeleteModal} onHide={closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Are You Sure You Want To Delete This Holding?</Modal.Title>
                        </Modal.Header>
                        <SectionWrapper justify="center" margin="20px 0px 0px 0px">
                            <Button margin="0px 15px 0px 0px" width="30%" label="Delete" onClick={deleteHolding}/>
                            <Button width="30%" label="Close" onClick={closeModal}/>
                        </SectionWrapper>
                    </DeletePipelineDialog>
                }
            </ModuleWrapper>
            {
                config.save && canUpdate && (
                    <SaveButton
                        styles={{justify:"space-between"}}
                        onSave={onSave}
                        beforeChildren={<Button width="20%" label="Add To Pipeline" onClick={handleModalShow}/>}
                    />
                )
            }
        </>
    )
};
export default HoldingSection;
