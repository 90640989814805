import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { getScoreText } from '../../utils/scores_display'
import { getTcfdColor } from '../../utils/tcdf_colors'
import { StyledRisks } from './TcfdPortfolioRiskList.styles'

const RiskValue = styled.span`
  &.risk-value {
    color: ${props => props.textColor || '#2e384d'};
    font-size: 36px;
    line-height: 1.1;
    opacity: ${props => (props.isLoading ? '0' : '1')};
    transition: opacity 0.15s ease-in-out;
  }
`

export const TcfdPortfolioRiskList = ({ scores, pdfStyle = false }) => {
  return scores ? (
    <StyledRisks pdfStyle={pdfStyle}>
      <div className='risk-item'>
        <RiskValue
          className='risk-value'
          textColor={getTcfdColor(scores.policy_and_legislation_score)}
        >
          {getScoreText(scores.policy_and_legislation_score)}
        </RiskValue>
        <span className='risk-text'>
          Policy &<br />
          Litigation
        </span>
      </div>
      <div className='risk-item'>
        <RiskValue
          className='risk-value'
          textColor={getTcfdColor(scores.technology_and_adaptation_score)}
        >
          {getScoreText(scores.technology_and_adaptation_score)}
        </RiskValue>
        <span className='risk-text'>
          Technology &<br />
          Adaptation
        </span>
      </div>
      <div className='risk-item'>
        <RiskValue
          className='risk-value'
          textColor={getTcfdColor(scores.market_risk_overall)}
        >
          {getScoreText(scores.market_risk_overall)}
        </RiskValue>
        <span className='risk-text'>
          Market
          <br />
          Risks
        </span>
      </div>
      <div className='risk-item'>
        <RiskValue
          className='risk-value'
          textColor={getTcfdColor(scores.reputation_overall)}
        >
          {getScoreText(scores.reputation_overall)}
        </RiskValue>
        <span className='risk-text'>
          Reputation
          <br />
          Risks
        </span>
      </div>
      <div className='risk-item'>
        <RiskValue
          className='risk-value'
          textColor={getTcfdColor(scores.physical_risk_1_score)}
        >
          {getScoreText(scores.physical_risk_1_score)}
        </RiskValue>
        <span className='risk-text'>
          Physical
          <br />
          Risks
        </span>
      </div>
    </StyledRisks>
  ) : null
}

TcfdPortfolioRiskList.propTypes = {
  scores: PropTypes.object.isRequired,
  pdfStyle: PropTypes.bool,
}
