import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import styled from 'styled-components'

import withErrorBoundary from '../../hocs/withErrorBoundary'
import {
  APP_COLOR_PRIMARY,
  APP_COLOR_PRIMARY_LIGHT,
  FONT_COLOR_SECONDARY,
} from '../../styles/colors'
import {
  ExplainerLink,
  LinkIcon,
} from '../../yb_components/styleguide/links/Link.styles'
import SubsidiesChartContainer from '../charts/SubsidiesChartContainer'
import Card from '../styleguide/Card'
import CardTitle from '../styleguide/CardTitle'
import Link from '../styleguide/Link'
import TextView from '../styleguide/TextView'

const StyledCard = styled(Card)`
  #SubsidyViolationTitle {
    margin-top: 50px;
  }
  .tracker-chart {
    margin-top: 10px;
    padding-right: 10px;
  }

  .chart-x-labels {
    display: flex;
    justify-content: space-between;
    padding-left: 70px;

    span {
      font-size: 15.5px;
      font-weight: bold;
      color: ${FONT_COLOR_SECONDARY};
    }
  }

  .subsidy-violation-legend {
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
  }

  .labels-container {
    text-align: right;
    width: 25%;
    display: inline-block;
  }

  .font-size-14 {
    font-size: 14px;
  }

  .larger-text {
    font-size: 18px;
  }

  .graph-title {
    font-size: 20px;
    font-weight: bold;
  }

  .red {
    color: #d30d25;
  }

  .green {
    color: #0bd875;
  }
`

const StyledExplainerLink = styled(ExplainerLink)`
  float: right;
`

const SubsidyViolationCard = ({
  subsidies,
  subsidiesAdditionalTitleLink,
  benchmark_subsidies,
  violations,
  violationsAdditionalTitleLink,
  benchmark_violations,
  benchmarkName,
}) => {
  const subsidiesBenchmarkComparison = Math.round(
    ((subsidies.total - benchmark_subsidies?.total) / subsidies.total) * 100
  )
  const violationsBenchmarkComparison = Math.round(
    ((violations.total - benchmark_violations?.total) / violations.total) * 100
  )

  return (
    <StyledCard padding={20}>
      <CardTitle title='CORPORATE SUBSIDIES, PENALTIES & FINES' />
      <div className='float-right'>
        <StyledExplainerLink
          href='https://help.yves.blue/en/articles/5918544-corporate-subsidies-penalties-fines'
          target='_blank'
        >
          <LinkIcon className='far fa-question-circle' />
        </StyledExplainerLink>
        <br />
      </div>
      <TextView
        marginTop='10px'
        size={15.5}
        text='Our fines data covers ~400k US federal, state, and local environmental, worker protection, fraud and other criminal and civil cases totaling $600B. Our subsidies data covers corporate subsidies and economic assistance across the US with ~600k awards, 1k programs and 3k parent companies covered, from 2000 to the present and updated quarterly.'
      />
      <Row>
        <Col md={6}>
          <SubsidiesChartContainer
            title='Corporate Subsidies'
            additionalTitleLink={subsidiesAdditionalTitleLink}
            data={subsidies.timeline}
            benchmarkData={benchmark_subsidies?.timeline}
            id={'subsidies'}
            total={subsidies.total}
            unit={'last 10 years'}
            benchmarkComparison={subsidiesBenchmarkComparison}
            color={APP_COLOR_PRIMARY}
            benchmarkName={benchmarkName}
          />
        </Col>
        <Col md={6}>
          <SubsidiesChartContainer
            title='Penalties & Fines'
            additionalTitleLink={violationsAdditionalTitleLink}
            data={violations.timeline}
            benchmarkData={benchmark_violations?.timeline}
            id={'violations'}
            total={violations.total}
            unit={'last 10 years'}
            benchmarkComparison={violationsBenchmarkComparison}
            color={APP_COLOR_PRIMARY_LIGHT}
            benchmarkName={benchmarkName}
          />
        </Col>
      </Row>
      <TextView
        marginTop={10}
        secondary
        right
        size={12}
        text={
          <>
            Source: Good Jobs First&nbsp;
            <Link href='https://www.goodjobsfirst.org/subsidy-tracker'>
              Subsidy Tracker
            </Link>{' '}
            /&nbsp;
            <Link href='https://www.goodjobsfirst.org/violation-tracker'>
              Violation Tracker
            </Link>
          </>
        }
      />
    </StyledCard>
  )
}

export default withErrorBoundary(SubsidyViolationCard)
