import { useLayoutEffect, useState } from 'react'

/**
 * This hook adds the posibility of getting a container size based on his ref and updating the state so we will se the result updated on the page
 *
 * @param {*} containerRef The ref of the container (div, span etc.)
 * @returns [width, height] The width and the height of the container in array format
 */
function useContainerRefSize(containerRef) {
  const [size, setSize] = useState([0, 0])

  const updateSize = () => {
    setSize([
      containerRef?.current?.offsetWidth,
      containerRef?.current?.offsetHeight,
    ])
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return size
}

export default useContainerRefSize
