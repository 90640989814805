import React, { useContext, useEffect, useState } from 'react'
import Spinner from 'react-bootstrap/Spinner'

import ErrorPage from '../../../../../../components/styleguide/ErrorPage'
import Tabs from '../../../../../../components/styleguide/Tabs'
import { tabNames } from '../../../../../../constants'
import { timestamp } from '../../../../../../helpers/shared'
import { downloadTemplate } from '../../../../../../pdf'
import ComparisonsPDF from '../../../../../../pdf/yb_modules/portfolio/modules/comparisons/comparisons_pdf/ComparisonsPDF'
import { getPortfolioComparisons } from '../../../../../../repositories/portfolio_repository'
import AppStore, {
  AppContext,
} from '../../../../../../yb_stores/app_store/AppStore'
import ESGScoresCard from '../cards/esg_scores_card/ESGScoresCard'
import SDGAlignmentCard from '../cards/sdg_alignment_card/SDGAlignmentCard'
import YBOverviewCard from '../cards/yb_overview_card/YBOverviewCard'
import YearlyMetricCard from '../cards/yearly_metric_card/YearlyMetricCard'
import DownloadLandscape from '../download_landscape/DownloadLandscape'
import ReplacePortfolioModal from '../replace_portfolio_modal/ReplacePortfolioModal'
import { SpinnerContainer, StyledContainer } from './Comparisons.styles'

const Comparisons = () => {
  const [portfolios, setPortfolios] = useState(null)
  const [isGeneratingReport, setIsGeneratingReport] = useState(false)
  const [isReplaceModalOpen, setIsReplaceModalOpen] = useState(false)
  const [errorCode, setErrorCode] = useState(null)
  const [state] = useContext(AppContext)

  useEffect(() => {
    getPortfolioComparisons()
      .then(res => {
        setPortfolios(res.data.portfolios)
      })
      .catch(err => {
        const errorCode = err?.response?.status || 502
        setErrorCode(errorCode)
      })
  }, [])

  const openReplaceModal = () => {
    setIsReplaceModalOpen(true)
  }

  const downloadLandscape = () => {
    setIsGeneratingReport(true)
    downloadTemplate(
      <AppStore initialState={state}>
        <ComparisonsPDF portfolios={portfolios} />
      </AppStore>,
      'full',
      `yb-Portfolio-Comparisons-${timestamp}`,
      react_app_user?.user?.id,
      'User',
      'User::Configuration[:portfolio_comparisons]',
      () => setIsGeneratingReport(false)
    )
  }

  return (
    <StyledContainer data-cy='portfolio-comparisons'>
      {portfolios == null ? (
        errorCode == null ? (
          <SpinnerContainer>
            {' '}
            <Spinner animation='border' />{' '}
          </SpinnerContainer>
        ) : (
          <ErrorPage code={errorCode} />
        )
      ) : (
        <>
          <Tabs
            data-cy='portfolio-comparisons-tabs'
            title=' '
            tabs={{
              [tabNames.YB_OVERVIEW]: (
                <YBOverviewCard
                  portfolios={portfolios}
                  openReplaceModal={openReplaceModal}
                />
              ),
              [tabNames.ESG_SCORES]: (
                <ESGScoresCard
                  portfolios={portfolios}
                  openReplaceModal={openReplaceModal}
                />
              ),
              [tabNames.SDG_ALIGNMENT]: (
                <SDGAlignmentCard
                  portfolios={portfolios}
                  openReplaceModal={openReplaceModal}
                />
              ),
              [tabNames.ENVIRONMENTAL]: (
                <YearlyMetricCard
                  portfolios={portfolios}
                  metricType='e'
                  openReplaceModal={openReplaceModal}
                />
              ),
              [tabNames.SOCIAL]: (
                <YearlyMetricCard
                  portfolios={portfolios}
                  metricType='s'
                  openReplaceModal={openReplaceModal}
                />
              ),
              [tabNames.GOVERNANCE]: (
                <YearlyMetricCard
                  portfolios={portfolios}
                  metricType='g'
                  openReplaceModal={openReplaceModal}
                />
              ),
            }}
            pinTabsUntil={tabNames.YB_OVERVIEW}
          />
          <DownloadLandscape
            isBusy={isGeneratingReport}
            onClick={downloadLandscape}
            data-cy='portfolio-comparisons-download-landscape-button'
          />
          <ReplacePortfolioModal
            data-cy='portfolio-comparisons-replace-modal'
            show={isReplaceModalOpen}
            portfolios={portfolios}
            setPortfolios={setPortfolios}
            onClose={() => {
              setIsReplaceModalOpen(false)
            }}
          />
        </>
      )}
    </StyledContainer>
  )
}

export default Comparisons
