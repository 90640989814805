export const tcfdRiskHardcodeItems = [
  {
    id: 'policy_and_legislation',
    name: 'Policy & Litigation',
    score_text: {
      0: 'Companies in this industry and country are exposed to minimal to no law, policy, and litigation risks.',
      1: 'Companies in this industry and country are exposed to low law, policy, and litigation risks.',
      2: 'Companies in this industry and country are exposed to improving law, policy, and litigation risks.',
      3: 'Companies in this industry and country are exposed to moderate law, policy, and litigation risks.',
      4: 'Companies in this industry and country are exposed to severe law, policy, and litigation risks.',
    },
    pillar_data_link: '/app/research_center/data/policy_and_legislation',
    methodology_link:
      'https://help.yves.blue/en/articles/6387564-tcfd-report#h_c725574a42',
    description:
      '<p>The TCFD framework requires that companies disclose any regulations and policies that apply to the business and what the effect of those will be. Climate Change Laws of the World <a href="https://climate-laws.org/" target="_blank">data</a> covers climate-related laws and policies.</p>',
  },
  {
    id: 'technology_and_adaptation',
    name: 'Technology & Adaptation',
    score_text: {
      0: 'Companies in these industries have no capex requirements to adapt to climate regulations on products and/or processes.',
      1: 'Companies in these industries have minimal to no capex requirements to adapt processes and products.',
      2: 'Companies in these industries have low to minimal capex requirements to adapt products and processes.',
      3: 'Companies in these industries have less extreme capex requirements to adapt processes and products.',
      4: 'Companies in these industries have the highest capex requirements to adapt to regulation and changing market needs.',
    },
    pillar_data_link: '/app/research_center/data/technology_and_adaptation',
    methodology_link:
      'https://help.yves.blue/en/articles/6387564-tcfd-report#h_cf4ce301c4',
    description:
      '<p>TCFD disclosures require companies to detail what technology adaptations the corporation will have to make in the face of intensified regulation or industry-driven trends to reduce carbon emissions. Portfolios with cleaner, more innovative industries will likely see more growth and opportunities over time.</p>',
  },
  {
    id: 'market_risk_1',
    name: 'Market Risk I: Old / New Economy',
    score_text: {
      0: 'New Economy: Companies and sectors that have risen out of the need to address climate change.',
      1: 'Green but has impacts, has potential to change and/or provide climate or environmental benefit.',
      2: 'Neutral (as in no opinion) or Necessary, but it depends on the company in question, the sector has mixed impacts but is not outdated but not climate forward either. Or there are no viable alternatives right now, although there may be in the future.',
      3: 'Mixed: Sectors are perhaps still necessary but causing significant impact or are at risk due to climate change but aren’t as impactful or deleterious as extreme sectors such as Chemicals or Coal Utilities, for example.',
      4: 'Old Economy: Companies and sectors that are predicted to be regulated out of existence or for which demand is waning in favor of new solutions, stranded assets.',
    },
    pillar_data_link: '/app/research_center/data/market_risk_1',
    methodology_link:
      'https://help.yves.blue/en/articles/6387564-tcfd-report#h_3139387227',
    description:
      '<p>A company’s TCFD disclosures are required to explain how its business will experience changing market conditions in the face of changing consumer preferences. Portfolios with cleaner, more innovative industries will likely see more growth and opportunities over time.</p>',
  },
  {
    id: 'market_risk_2',
    name: 'Market Risk II: Ebbing Market Demand',
    score_text: {
      0: 'A sample market group was rebased to all adults. That rebased number was normalized to 0-4. Zero indicates that zero percent of adults would change their buying behavior based on climate concerns.',
      1: 'Between 1% and 20% of all adults responded that they would change their buying habits in these areas based on climate concerns.',
      2: 'Between 20% and 25% of all adults responded that they would change their buying habits in these areas based on climate concerns.',
      3: 'Between 25% and 30% of all adults responded that they would change their buying habits in these areas based on climate concerns.',
      4: 'That rebased number ranged between 30% and 50% of all adults responding that they would change buying habits in these areas based on climate concerns.',
    },
    pillar_data_link: '/app/research_center/data/market_risk_2',
    methodology_link:
      'https://help.yves.blue/en/articles/6387564-tcfd-report#h_e8584f4dd6',
    description:
      '<p>TCFD defines market risk as changing consumer behavior and uncertainty through market signals. The risk to certain industries lies in the failure to recognize or the slowness to act on climate-driven consumer choices.</p>',
  },
  {
    id: 'market_risk_3',
    name: 'Market Risk III: The Price of Carbon',
    score_text: {
      0: 'The holding’s price of carbon in its country of incorporation is less than or equal to $1.',
      1: 'The holding’s price of carbon in its country of incorporation is between $1 and $4.',
      2: 'The holding’s price of carbon in its country of incorporation is between $4 and $8.',
      3: 'The holding’s price of carbon in its country of incorporation is between $8 and $50.',
      4: 'The holding’s price of carbon in its country of incorporation is greater than $50.',
    },
    pillar_data_link: '/app/research_center/data/market_risk_3',
    methodology_link:
      'https://help.yves.blue/en/articles/6387564-tcfd-report#h_ee944446d6',
    description:
      '<p>Depending on a corporation’s country of incorporation, the price of carbon and its potential to increase can create large risks to its cost of operations. A carbon-intensive company operating in a location with a trading system or a tax may see the costs of this rise.</p>',
  },
  {
    id: 'reputation_1',
    name: 'Reputation Risk I: Transition Pathway Initiative',
    score_text: {
      0: 'Integration of strategic and holistic assessment of risks and opportunities into business decisions.',
      1: 'Improving operational practices by setting and reporting on quantitative targets and nominating a board to oversee climate change policy.',
      2: 'Developing basic capacity, management systems & processes, and reports on practice & performance.',
      3: 'Acknowledging climate change as a business issue.',
      4: 'Unaware of (or not acknowledging) climate change as a business issue.',
    },
    pillar_data_link: '/app/research_center/data/reputation_1',
    methodology_link:
      'https://help.yves.blue/en/articles/6387564-tcfd-report#h_29e4294f9b',
    description:
      '<p>The Transition Pathway Initiative (TPI) is a global initiative led by asset owners which assesses companies’ preparedness for the transition to a low-carbon economy. Rapidly becoming the go-to corporate climate action benchmark, a good TPI Score is reputationally good.</p>',
  },
  {
    id: 'reputation_2',
    name: 'Reputation Risk II: Industry Environmental Impacts',
    score_text: {
      0: 'Minimal to no impact on environmental impact categories.',
      1: 'Low impact on environmental impact categories.',
      2: 'Improving impact on environmental impact categories.',
      3: 'Moderate impact on environmental impact categories.',
      4: 'Severe impact on environmental impact categories.',
    },
    pillar_data_link: '/app/research_center/data/reputation_2',
    methodology_link:
      'https://help.yves.blue/en/articles/6387564-tcfd-report#h_0a1e64d403',
    description:
      '<p>Industries are scored on the notion that reputation risk could stem from how closely these industries are associated with environmental impacts in public opinion. An industry’s environmental effect can shift consumer preferences, stigmatize the sector, and increase stakeholder concern.</p>',
  },
  {
    id: 'physical_risk_1',
    name: 'Physical Risk I: Climate Risk for Industries',
    score_text: {
      0: 'Minimal to no risks.',
      1: 'Risks as standard under any condition or company-specific depending on the location of a company’s operations.',
      2: 'Minor risks.',
      3: 'Significant risks but not catastrophic to operations or assets.',
      4: 'Extreme enterprise risks.',
    },
    pillar_data_link: '/app/research_center/data/physical_risk_1',
    methodology_link:
      'https://help.yves.blue/en/articles/6387564-tcfd-report#h_cf5ed6e0bb',
    description:
      '<p>Operational, supply, and risk to property, plant, and equipment in the event of sudden and severe climate events or gradual effects of climate change over time. In a given industry, impacts will vary widely depending on location and circumstance.</p>',
  },
]
