import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { BaseFormGroup } from '../../../../../yb_components/styleguide/form/base/form_group/BaseFormGroup'
import { BaseErrorText } from '../../../../../yb_components/styleguide/form/base/help_text/BaseErrorText'
import { BaseHelpText } from '../../../../../yb_components/styleguide/form/base/help_text/BaseHelpText'
import { BaseCombobox } from '../../../../../yb_components/styleguide/form/base/inputs/BaseCombobox'
import { BaseInput } from '../../../../../yb_components/styleguide/form/base/inputs/BaseInput'
import { BaseLabel } from '../../../../../yb_components/styleguide/form/base/label/BaseLabel'
import { StyledCard } from './FormCard'

const assetTypes = [
  { id: 51, name: 'Alternative' },
  { id: 52, name: 'Cash' },
  { id: 48, name: 'Cash (Unmodeled)' },
  { id: 50, name: 'Cash Equivalents' },
  { id: 44, name: 'Corporate Bond' },
  { id: 1, name: 'Equity' },
  { id: 47, name: 'ETF' },
  { id: 11, name: 'Fixed Income' },
  { id: 21, name: 'Money Market' },
  { id: 46, name: 'Mutual Fund' },
  { id: 53, name: 'Private Equity' },
  { id: 45, name: 'Sovereign Bond' },
]

export const MarketSection = ({ initialFormData }) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext()

  const [assetTypeObj, setAssetTypeObj] = useState({})

  useEffect(() => {
    if (initialFormData.asset_type_id != null) {
      setAssetTypeObj(
        assetTypes.find(el => el.id === initialFormData.asset_type_id)
      )
    }
  }, [initialFormData])

  useEffect(() => {
    setValue('asset_type_id', assetTypeObj.id, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }, [assetTypeObj])

  return (
    <StyledCard title='Financial Market Identifiers' className='market-card'>
      <section>
        {/* asset type */}
        <BaseFormGroup controlId='asset_type_id_select'>
          <BaseLabel>
            Asset Type <small>(Required)</small>
          </BaseLabel>
          <BaseCombobox
            placeholder={'Select Asset Type'}
            value={assetTypes.find(el => el.id === assetTypeObj.id)}
            getOptionValue={option => `${option.id}`}
            getOptionLabel={option => `${option.name}`}
            options={assetTypes}
            onChange={val => setAssetTypeObj(val)}
          />
          {errors.asset_type_id && (
            <BaseErrorText>Asset Type is required.</BaseErrorText>
          )}
        </BaseFormGroup>
        {/* ticker */}
        <BaseFormGroup controlId='ticker_input'>
          <BaseLabel>Ticker</BaseLabel>
          <BaseInput type='text' control={control} name='ticker' />
        </BaseFormGroup>
        {/* isin */}
        <BaseFormGroup controlId='isin_input'>
          <BaseLabel>ISIN</BaseLabel>
          <BaseInput
            type='text'
            control={control}
            name='isin'
            rules={{
              type: 'focus',
              minLength: 12,
              maxLength: 12,
              pattern: '/^[a-zA-Z0-9]+$/',
            }}
            isInvalid={!!errors.isin}
          />
          {errors.isin ? (
            <BaseErrorText>
              Please provide the full 12 character code.
            </BaseErrorText>
          ) : (
            <BaseHelpText>
              Please provide the full 12 character code.
            </BaseHelpText>
          )}
        </BaseFormGroup>
        {/* cusip */}
        <BaseFormGroup controlId='cusip_input'>
          <BaseLabel>CUSIP</BaseLabel>
          <BaseInput
            type='text'
            control={control}
            name='cusip'
            rules={{
              type: 'focus',
              minLength: 6,
              maxLength: 9,
            }}
            isInvalid={!!errors.cusip}
          />
          {errors.cusip ? (
            <BaseErrorText>
              Please provide either the CUSIP-6 or the full 9 character code.
            </BaseErrorText>
          ) : (
            <BaseHelpText>
              Please provide either the CUSIP-6 or the full 9 character code.
            </BaseHelpText>
          )}
        </BaseFormGroup>
      </section>
      <section>
        {/* figi */}
        <BaseFormGroup controlId='figi_input'>
          <BaseLabel>FIGI</BaseLabel>
          <BaseInput
            type='text'
            control={control}
            name='figi'
            rules={{
              type: 'focus',
              minLength: 12,
              maxLength: 12,
            }}
            isInvalid={!!errors.figi}
          />
          {errors.figi && (
            <BaseErrorText>
              Please provide the full 12 character code.
            </BaseErrorText>
          )}
        </BaseFormGroup>
        {/* ric */}
        <BaseFormGroup controlId='ric_input'>
          <BaseLabel>RIC</BaseLabel>
          <BaseInput
            type='text'
            control={control}
            name='ric'
            rules={{
              type: 'focus',
              pattern: /[A-Z]{1,4}\.[A-Z]{1,2}/,
            }}
            isInvalid={!!errors.ric}
          />
          {errors.ric && (
            <BaseErrorText>
              Please provide a valid RIC. This field is case sensitive.
            </BaseErrorText>
          )}
        </BaseFormGroup>
        {/* sedol */}
        <BaseFormGroup controlId='sedol_input'>
          <BaseLabel>SEDOL</BaseLabel>
          <BaseInput
            type='text'
            control={control}
            name='sedol'
            rules={{
              type: 'focus',
              minLength: 7,
              maxLength: 7,
              pattern: /^[a-zA-Z0-9]+$/,
              message: 'Please provide a valid SEDOL.',
            }}
            isInvalid={!!errors.sedol}
          />
          {errors.sedol?.message && (
            <BaseErrorText>{errors.sedol.message}</BaseErrorText>
          )}
        </BaseFormGroup>
        {/* loanx */}
        <BaseFormGroup controlId='loanx_id_input'>
          <BaseLabel>LOANX ID</BaseLabel>
          <BaseInput type='text' control={control} name='loanx' />
        </BaseFormGroup>
      </section>
    </StyledCard>
  )
}
