import _ from 'lodash'
import propTypes from 'prop-types'
import React, { useMemo } from 'react'
import Select from 'react-select-3'

import DualRangeSlider from '../../../components/styleguide/DualRangeSlider'
import SDGFilter from '../../../components/styleguide/SDGFilter'
import { FILTER_TYPES, RESEARCH_CENTER_COMPANY_TYPES } from '../constants/enums'
import { HEAD_SELECT_COUNTRIES } from '../constants/form-constants'
import { filterArrByLabel, sortByLabel } from '../helpers/filter-helpers'
import {
  industryStyles,
  marketCapStyles,
  sectorStyles,
} from '../styles/select-styles'
import {
  HideWrapper,
  SelectLabel,
  SelectLabelWrapper,
  SelectWrapper,
  SelectWrapperInner,
} from '../styles/styles'

const FilterShow = ({
  canAccessManagerDb,
  selectChange,
  selectChangeBusiness,
  filters,
  filtersOptions,
  industry,
  companyType,
}) => {
  const { countries } = filtersOptions

  const countriesOptions = useMemo(() => {
    return [
      ...sortByLabel(HEAD_SELECT_COUNTRIES),
      {
        label: '-',
        options: [
          ...sortByLabel(filterArrByLabel(countries, HEAD_SELECT_COUNTRIES)),
        ],
      },
    ]
  }, [countries])

  const renderInstrumentsFilters = () => {
    const sectorSelected = filters?.BUSINESS_SECTOR?.length > 0
    return (
      <>
        <SelectWrapper>
          <SelectWrapperInner zIndex={5} marginRight={'0px'}>
            <SelectLabelWrapper>
              <SelectLabel>MARKET CAP</SelectLabel>
              <Select
                placeholder={'All (select to filter)'}
                isMulti
                value={filters?.MARKET_CAP}
                menuShouldScrollIntoView={false}
                styles={marketCapStyles}
                options={filtersOptions?.marketCap}
                isSearchable={false}
                onChange={val => selectChange(val, FILTER_TYPES.MARKET_CAP)}
              />
            </SelectLabelWrapper>
            <SelectLabelWrapper>
              <SelectLabel>REVENUE</SelectLabel>
              <Select
                placeholder={'All (select to filter)'}
                isMulti
                value={filters?.REVENUE}
                menuShouldScrollIntoView={false}
                styles={marketCapStyles}
                options={filtersOptions?.revenues}
                isSearchable={false}
                onChange={val => selectChange(val, FILTER_TYPES.REVENUE)}
              />
            </SelectLabelWrapper>
            <SelectLabelWrapper>
              <SelectLabel>SECTOR</SelectLabel>
              <Select
                isMulti
                value={filters?.BUSINESS_SECTOR}
                placeholder={'All (select to filter)'}
                className='select-container'
                menuShouldScrollIntoView={false}
                isSearchable={false}
                styles={sectorStyles}
                options={(filtersOptions?.sectors || []).sort((a, b) =>
                  a.label[0] > b.label[0] ? 1 : -1
                )}
                onChange={val => selectChangeBusiness(val)}
              />
            </SelectLabelWrapper>
            <SelectLabelWrapper>
              <SelectLabel>INDUSTRY GROUP</SelectLabel>
              <Select
                isMulti
                value={filters?.INDUSTRY_GROUP}
                menuShouldScrollIntoView={false}
                isDisabled={!sectorSelected}
                className='select-container'
                styles={industryStyles}
                options={industry.sort((a, b) =>
                  a.label[0] > b.label[0] ? 1 : -1
                )}
                isSearchable={false}
                placeholder={
                  sectorSelected
                    ? 'All (select to filter)'
                    : 'Please select a sector first'
                }
                onChange={val => selectChange(val, FILTER_TYPES.INDUSTRY_GROUP)}
              />
            </SelectLabelWrapper>
          </SelectWrapperInner>
        </SelectWrapper>
        <SelectWrapper>
          <SelectWrapperInner zIndex={4} marginRight={'0px'}>
            <SelectLabelWrapper>
              <SelectLabel>POSITIVE IMPACT BADGES</SelectLabel>
              <Select
                placeholder={'All (select to filter)'}
                isMulti
                value={filters?.POSITIVE_BADGE}
                menuShouldScrollIntoView={false}
                styles={marketCapStyles}
                options={filtersOptions?.positiveBadges}
                isSearchable={false}
                onChange={val => selectChange(val, FILTER_TYPES.POSITIVE_BADGE)}
              />
            </SelectLabelWrapper>

            <SelectLabelWrapper>
              <SelectLabel>NEGATIVE IMPACT BADGES</SelectLabel>
              <Select
                placeholder={'All (select to filter)'}
                isMulti
                value={filters?.NEGATIVE_BADGE}
                menuShouldScrollIntoView={false}
                styles={marketCapStyles}
                options={filtersOptions?.negativeBadges}
                isSearchable={false}
                onChange={val => selectChange(val, FILTER_TYPES.NEGATIVE_BADGE)}
              />
            </SelectLabelWrapper>
          </SelectWrapperInner>
        </SelectWrapper>
        <SelectWrapper>
          <SelectWrapperInner zIndex={3} marginRight={'0px'}>
            {canAccessManagerDb && (
              <SelectLabelWrapper>
                <SelectLabel>COMPANY TYPE</SelectLabel>
                <Select
                  isMulti
                  value={companyType}
                  placeholder={'All (select to filter)'}
                  className='select-container'
                  menuShouldScrollIntoView={false}
                  isSearchable={false}
                  styles={sectorStyles}
                  options={filtersOptions?.companyTypes}
                  onChange={val => selectChange(val, FILTER_TYPES.COMPANY_TYPE)}
                />
              </SelectLabelWrapper>
            )}

            <SelectLabelWrapper>
              <SelectLabel>COUNTRY</SelectLabel>
              <Select
                placeholder={'All (select to filter)'}
                isMulti
                value={filters?.COUNTRY_OF_HQ}
                menuShouldScrollIntoView={false}
                styles={marketCapStyles}
                options={countriesOptions}
                isSearchable={false}
                onChange={val => selectChange(val, FILTER_TYPES.COUNTRY_OF_HQ)}
              />
            </SelectLabelWrapper>

            <SelectLabelWrapper>
              <SelectLabel>INSTRUMENT TYPE</SelectLabel>
              <Select
                placeholder={'All (select to filter)'}
                isMulti
                value={filters?.INSTRUMENT_TYPE}
                menuShouldScrollIntoView={false}
                styles={marketCapStyles}
                options={filtersOptions?.instrumentTypes}
                isSearchable={false}
                onChange={val =>
                  selectChange(val, FILTER_TYPES.INSTRUMENT_TYPE)
                }
              />
            </SelectLabelWrapper>
          </SelectWrapperInner>
        </SelectWrapper>
        <SelectWrapper>
          <SelectWrapperInner>
            <SelectLabelWrapper>
              <DualRangeSlider
                displayValue={true}
                value={filters?.E_SCORE}
                onChange={val => selectChange(val, FILTER_TYPES.E_SCORE)}
                title={`ENVIRONMENTAL SCORE`}
                clearValue={true}
                customStyle={'padding-left: 13px;'}
              />
            </SelectLabelWrapper>
            <SelectLabelWrapper>
              <DualRangeSlider
                displayValue={true}
                value={filters?.S_SCORE}
                onChange={val => selectChange(val, FILTER_TYPES.S_SCORE)}
                title={'SOCIAL SCORE'}
                clearValue={true}
              />
            </SelectLabelWrapper>
            <SelectLabelWrapper>
              <DualRangeSlider
                displayValue={true}
                value={filters?.G_SCORE}
                onChange={val => selectChange(val, FILTER_TYPES.G_SCORE)}
                title={'GOVERNANCE SCORE'}
                clearValue={true}
              />
            </SelectLabelWrapper>
            <SelectLabelWrapper>
              <DualRangeSlider
                displayValue={true}
                value={filters?.TOTAL_SCORE}
                onChange={val => selectChange(val, FILTER_TYPES.TOTAL_SCORE)}
                title={'TOTAL ESG SCORE'}
                clearValue={true}
              />
            </SelectLabelWrapper>
          </SelectWrapperInner>
        </SelectWrapper>
        <SelectWrapper customStyle={'margin-top: 5px'}>
          <SDGFilter
            selected={filters?.SDG}
            setSelected={val => selectChange(val, FILTER_TYPES.SDG)}
            sdgCount={16}
            showLogo={false}
            padding={'0px 10px'}
            allowModal={false}
            allowHover={true}
            allowSelectAll={true}
          />
        </SelectWrapper>
      </>
    )
  }
  const renderFundsFilters = () => (
    <>
      <SelectWrapper>
        <SelectWrapperInner zIndex={3} marginRight={'0px'}>
          {canAccessManagerDb && (
            <SelectLabelWrapper>
              <SelectLabel>COMPANY TYPE</SelectLabel>
              <Select
                isMulti
                value={companyType}
                placeholder={'All (select to filter)'}
                className='select-container'
                menuShouldScrollIntoView={false}
                isSearchable={false}
                styles={sectorStyles}
                options={filtersOptions?.companyTypes}
                onChange={val => selectChange(val, FILTER_TYPES.COMPANY_TYPE)}
              />
            </SelectLabelWrapper>
          )}

          <SelectLabelWrapper>
            <SelectLabel>SECTOR</SelectLabel>
            <Select
              placeholder={'All (select to filter)'}
              isMulti
              value={filters?.FUND_SECTOR}
              menuShouldScrollIntoView={false}
              styles={marketCapStyles}
              options={filtersOptions?.fundSectors}
              isSearchable={false}
              onChange={val => selectChange(val, FILTER_TYPES.FUND_SECTOR)}
            />
          </SelectLabelWrapper>

          <SelectLabelWrapper>
            <SelectLabel>ASSET CLASS</SelectLabel>
            <Select
              placeholder={'All (select to filter)'}
              isMulti
              value={filters?.FUND_ASSET_CLASS}
              menuShouldScrollIntoView={false}
              styles={marketCapStyles}
              options={filtersOptions?.fundAssetClasses}
              isSearchable={false}
              onChange={val => selectChange(val, FILTER_TYPES.FUND_ASSET_CLASS)}
            />
          </SelectLabelWrapper>
        </SelectWrapperInner>
      </SelectWrapper>
      <SelectWrapper>
        <SDGFilter
          selected={filters?.SDG}
          setSelected={val => selectChange(val, FILTER_TYPES.SDG)}
          sdgCount={16}
          showLogo={false}
          padding={'0px 10px'}
          allowModal={false}
          allowHover={true}
          allowSelectAll={true}
        />
      </SelectWrapper>
    </>
  )

  return (
    <HideWrapper>
      {_.first(companyType)?.value === RESEARCH_CENTER_COMPANY_TYPES.PUBLIC
        ? renderInstrumentsFilters()
        : renderFundsFilters()}
    </HideWrapper>
  )
}

FilterShow.propTypes = {
  filters: propTypes.shape({
    MARKET_CAP: propTypes.array.isRequired,
    BUSINESS_SECTOR: propTypes.array.isRequired,
    INDUSTRY_GROUP: propTypes.array.isRequired,
  }).isRequired,
  sectorSelected: propTypes.bool.isRequired,
  selectChange: propTypes.func.isRequired,
  classes: propTypes.object,
}

export default FilterShow
