import React from 'react'
import { useFormContext } from 'react-hook-form'

import { BaseFormGroup } from '../../../../../yb_components/styleguide/form/base/form_group/BaseFormGroup'
import { BaseInput } from '../../../../../yb_components/styleguide/form/base/inputs/BaseInput'
import { BaseLabel } from '../../../../../yb_components/styleguide/form/base/label/BaseLabel'
import { StyledCard } from './FormCard'

export const FinancialSection = () => {
  const { control } = useFormContext()

  return (
    <StyledCard title='Base Financial Information' className='financial-card'>
      <section>
        <span style={{ marginTop: '30px' }} className='left-label'>
          Revenue
        </span>
        <BaseFormGroup controlId='revenue_2017'>
          <BaseLabel aria-hidden='true'>2017</BaseLabel>
          <BaseInput
            type='number'
            aria-label='Revenue 2017'
            control={control}
            name='revenue_2017'
          />
        </BaseFormGroup>
        <BaseFormGroup controlId='revenue_2018'>
          <BaseLabel aria-hidden='true'>2018</BaseLabel>
          <BaseInput
            type='number'
            aria-label='Revenue 2018'
            control={control}
            name='revenue_2018'
          />
        </BaseFormGroup>
        <BaseFormGroup controlId='revenue_2019'>
          <BaseLabel aria-hidden='true'>2019</BaseLabel>
          <BaseInput
            type='number'
            aria-label='Revenue 2019'
            control={control}
            name='revenue_2019'
          />
        </BaseFormGroup>
        <BaseFormGroup controlId='revenue_2020'>
          <BaseLabel aria-hidden='true'>2020</BaseLabel>
          <BaseInput
            type='number'
            aria-label='Revenue 2020'
            control={control}
            name='revenue_2020'
          />
        </BaseFormGroup>
        <BaseFormGroup controlId='revenue_2021'>
          <BaseLabel aria-hidden='true'>2021</BaseLabel>
          <BaseInput
            type='number'
            aria-label='Revenue 2021'
            control={control}
            name='revenue_2021'
          />
        </BaseFormGroup>
      </section>
      <section>
        <span className='left-label'>
          <span>Market Cap / </span>
          <span style={{ display: 'block' }}>Company Valuation</span>
        </span>
        <BaseFormGroup controlId='valuation_2017'>
          <BaseInput
            type='number'
            aria-label='Market Cap 2017'
            control={control}
            name='valuation_2017'
          />
        </BaseFormGroup>
        <BaseFormGroup controlId='valuation_2018'>
          <BaseInput
            type='number'
            aria-label='Market Cap 2018'
            control={control}
            name='valuation_2018'
          />
        </BaseFormGroup>
        <BaseFormGroup controlId='valuation_2019'>
          <BaseInput
            type='number'
            aria-label='Market Cap 2019'
            control={control}
            name='valuation_2019'
          />
        </BaseFormGroup>
        <BaseFormGroup controlId='valuation_2020'>
          <BaseInput
            type='number'
            aria-label='Market Cap 2020'
            control={control}
            name='valuation_2020'
          />
        </BaseFormGroup>
        <BaseFormGroup controlId='valuation_2021'>
          <BaseInput
            type='number'
            aria-label='Market Cap 2021'
            control={control}
            name='valuation_2021'
          />
        </BaseFormGroup>
      </section>
    </StyledCard>
  )
}
