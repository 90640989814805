import axios from 'axios'
import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import ReactGA from 'react-ga'

import Button from './Button'
import StyledDialog from './StyledDialog'

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_KEY)

const handleDelete = ({
  portfolioId,
  origin,
  handleClose,
  portfolios,
  setPortfolios,
}) => {
  ReactGA.event({
    category: 'Portfolio',
    action: 'delete',
    label: `${portfolioId}`,
  })

  axios.delete(`/app/api/portfolios/${portfolioId}`).then(response => {
    if (response.status == 200) {
      if (portfolios.length > 0) {
        const filteredPortfolios = portfolios.filter(p => p.id != portfolioId)
        setPortfolios(filteredPortfolios)
      }
      if (origin) {
        window.location.assign(origin)
      } else {
        handleClose()
      }
    }
  })
}

const DeletePortfolioDialog = ({
  displayed,
  title,
  portfolioName,
  setDisplayed,
  portfolioId,
  snapshotId,
  origin,
  portfolios = [],
  setPortfolios,
}) => {
  const [disabledDelete, setDisabledDelete] = useState(true)
  const handleClose = () => {
    setDisplayed(false)
    setDisabledDelete(true)
    location.reload()
  }

  return (
    <StyledDialog show={displayed} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete this portfolio? Once deleted, you will
          no longer have access to it and it will not be able to be recovered.
        </p>
        <Form.Check
          type={'checkbox'}
          id={`default-checkbox`}
          label={`Yes, permanently delete the portfolio named ${portfolioName}.`}
          onChange={() => setDisabledDelete(!disabledDelete)}
        />
        <Modal.Footer>
          <Button
            width={'25%'}
            secondary
            onClick={handleClose}
            label={'Cancel'}
          />
          <Button
            disabled={disabledDelete}
            width={'25%'}
            onClick={() =>
              handleDelete({
                portfolioId,
                snapshotId,
                origin,
                handleClose,
                portfolios,
                setPortfolios,
              })
            }
            className='crud-btn'
            label='DELETE'
            outline
          />
        </Modal.Footer>
      </Modal.Body>
    </StyledDialog>
  )
}

DeletePortfolioDialog.propTypes = {}

export default DeletePortfolioDialog