import { ExternalLinkIcon } from '../../../../../ui/elements/icons/ExternalLinkIcon'
import { Link, ILinkProps } from '../../../../../ui/elements/Link'
import React from 'react'

export interface IProps extends Omit<ILinkProps, 'download' | 'target'> {}

export const ExternalLink: React.FC<IProps> = ({ children, ...linkProps }) => (
    <Link {...linkProps} target='_blank'>
        {children} <ExternalLinkIcon />
    </Link>
)
