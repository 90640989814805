import React from 'react'

const ErrorMessage = () => (
  <p>
    Our apologies, there was an error. We’ve been notified but if you need
    assistance, please email{' '}
    <a href='mailto:support@yves.blue'>support@yves.blue</a>.
  </p>
)

export default ErrorMessage
