import styled from 'styled-components';

/* STYLE */
export const StyledTable = styled.table`
  display: table;
  border-spacing: 0px;
  table-layout:fixed;
`;

export const StyledTr = styled.tr`
  ${props => props.width ? `width: ${props.width};` : ''}
  display: table-cell;
  background-color: white;
  &:nth-child(even) {
    background-color: white;
  }
`;

export const StyledTrHeader = styled.tr`

`;

export const StyledTh = styled.th`
  padding-left: 5px;
  padding-right: 5px;
`;

export const StyledTd = styled.td`
  height: var(--row-height, 50px);
  display: block;
  border-top: 1px solid #EDEEF3;

  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 5px;
  padding-right: 5px;
  &:last-child {
    font-weight: bold;
  }
`;

export const StyledCell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: ${props => props.justifyContent || `center`};
  align-items: center;
`;

export const StyledDataCell = styled(StyledCell)`
  background-color: #F6F8FD;
  font-size: 11px;
`;

export const StyledHeaderCell = styled(StyledCell)`
  color: #7B7B7B;
  font-weight: bold;
  font-size: 12px;
`;

export const StyledRowHeaderCell = styled(StyledCell)`
  background-color: transparent;
  font-weight: bold;
  font-size: 11px;
  justify-content: start;
`;
