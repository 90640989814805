import styled from 'styled-components'
import { APP_COLOR_PRIMARY } from '../../styles/colors';

const StyledExportButton = styled.div`
  width: 200px;
  height: 200px;
  border: 1px solid ${APP_COLOR_PRIMARY};
  border-radius: 20px;
  display: inline-block;
  margin-top: 15px;
  cursor: pointer;

  .button-text {
    text-align: center;
    font-size: 20px;
    margin-top: 30px;
  }
  .button-icon {
    text-align: center;
    font-size: 50px;
    margin-top: 10px;
  }
`

export default StyledExportButton
