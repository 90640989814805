import * as d3 from 'd3'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import withErrorBoundary from '../../hocs/withErrorBoundary'
import { SDG_COLORS } from '../../styles/colors'
import {
  ExplainerLink,
  LinkIcon,
} from '../../yb_components/styleguide/links/Link.styles'
import XAxisLabels from '../charts/components/XAxisLabels'
import YAxisLine from '../charts/components/YAxisLine'
import SDGIcon from '../styleguide/SDGIcon'

const StyledExplainerLink = styled(ExplainerLink)`
  float: right;
  margin-bottom: 0px;
`

const SDGS = {
  1: 'No Poverty',
  2: 'Zero Hunger',
  3: 'Good Health',
  4: 'Quality Education',
  5: 'Gender Equality',
  6: 'Clean Water',
  7: 'Clean Energy',
  8: 'Good Jobs',
  9: 'Good Infrastructure',
  10: 'Reduced Inequality',
  11: 'Sustainable Communities',
  12: 'Responsible Consumption',
  13: 'Climate Action',
  14: 'Life Below Water',
  15: 'Life On Land',
  16: 'Strong Institutions',
  17: 'Partnership For The Goals',
}

const SDGExposureCard = ({
  data,
  benchmarkData,
  benchmarkName,
  width,
  cardHeight = '100vh',
  title = 'Portfolio',
  iconWidth = 37.895,
  heightMin = 30,
  heightMax = 40,
  sdgXpos = 42,
  labelPosOffset = 0,
  fontClass = 'SDG-Exposure-Labels',
  xLAbelPos = 0,
  bubbleSize = 0.014,
  zeroOffset = 3,
  secondLastValue = 75,
  chartSubtract = 70,
  svgClass = 'SDG-Exposure-Chart-SVG',
  wrapperClass = 'SDG-Card-Wrapper',
}) => {
  const [chartHeight, setChartHeight] = useState(400)
  const [chartWidth, setChartWidth] = useState(width)

  const onResize = () => {
    const chartEl = document.getElementById('sdg-chart')

    if (!chartEl) {
      console.error(`Chart is null`)
      return
    }

    setChartWidth(chartEl.clientWidth)
    setChartHeight(chartEl.clientHeight)
  }

  useEffect(() => {
    onResize()
  })

  useEffect(() => {
    window.addEventListener('resize', () => onResize())
    return () => window.removeEventListener('resize', onResize())
  })

  const length = Object.keys(data) && Object.keys(data).length

  const x_scale = d3
    .scaleLinear()
    .domain([-100, 100])
    .range([145, chartWidth - 25])

  const y_scale = d3
    .scaleLinear()
    .domain([0, length])
    .range([chartHeight - 50, 0])

  const labelHeight = 40

  const finalLabel = (label, ypos, post) => {
    const start = ypos
    const end = ypos + labelHeight
    ypos = (start + end) / 2

    return [
      <text
        key={`${ypos}-icon-label`}
        className={fontClass}
        x={sdgXpos}
        y={ypos}
      >
        {label[0]}
        {post}
      </text>,

      <text
        key={`${ypos}-icon-label-second`}
        className={fontClass}
        x={sdgXpos}
        y={ypos + 15}
      >
        {label[1]}
      </text>,
    ]
  }

  const SDGTextLabel = (label, ypos) => {
    if (label.includes('And')) {
      label = label.split('And')
      return finalLabel(label, ypos, 'And')
    }

    label = label.split(' ')
    return finalLabel(label, ypos, '')
  }

  const renderIcons = () => {
    if (!chartWidth) return null
    // eslint-disable-next-line no-unused-vars
    const ystart = chartHeight - chartSubtract
    return Object.keys(data)
      .reverse()
      .map((sdg, i) => {
        const size = Object.keys(data).length
        let sdg_number = sdg.split('_')[1]
        let offset = size > 12 ? 30 : 30

        let ypos = y_scale(i + 1) + offset

        let height = size > 12 ? heightMin : heightMax
        let labelPos = size > 12 ? ypos - 7 : ypos

        return (
          <React.Fragment key={`${i}-icon-data`}>
            <SDGIcon
              key={`${i}-icon-value`}
              sdg={sdg_number}
              width={iconWidth}
              height={height}
              x={0}
              y={ypos}
            />
            {SDGTextLabel(SDGS[sdg_number], labelPos + labelPosOffset)}
          </React.Fragment>
        )
      })
  }

  const chartWidthBeigeReal = () => {
    const width = x_scale(-50) - x_scale(-100)
    return width < 0 ? 1 : width
  }

  const chartWidthGreenReal = () => {
    const width = x_scale(100.1) - x_scale(50)
    return width < 0 ? 1 : width
  }

  const renderLines = () => {
    let classes
    return [-100, -75, -50, -25, 0, 25, 50, secondLastValue, 100.1].map(
      (n, i) => {
        if (i === 0 || i === 2) {
          classes = 'SDG-Line-Chart-Grid-Regular-Beige'
        } else if (i === 6 || i === 8) {
          classes = 'SDG-Line-Chart-Grid-Regular-Green'
        } else if (i === 4) {
          classes = 'SDG-Line-Chart-Grid-Regular-Middle'
        } else {
          classes = 'SDG-Line-Chart-Grid-Regular'
        }

        return (
          <YAxisLine
            key={`${i}-y-axis`}
            classes={classes}
            xstart={x_scale(n)}
            ystart={20}
            yend={chartHeight - 30}
          />
        )
      }
    )
  }

  const renderBubbles = () => {
    let start
    let end
    let ypos

    return Object.keys(data)
      .reverse()
      .map((sdg, i) => {
        start = y_scale(i + 1) + 30
        end = start + labelHeight
        ypos = (start + end) / 2
        return (
          <circle
            key={`${i}-bubble`}
            cx={x_scale(data[sdg] * 100)}
            cy={ypos}
            r={chartWidth * bubbleSize}
            fill={SDG_COLORS[sdg.split('_')[1]]}
          />
        )
      })
  }

  const renderBenchmark = () => {
    let start
    let end
    // eslint-disable-next-line no-unused-vars
    let ypos

    return Object.keys(data)
      .reverse()
      .map((sdg, i) => {
        start =
          y_scale(i + 1) + 30 + labelHeight / 2 - chartWidth * bubbleSize - 4
        end = start + 2 * chartWidth * bubbleSize + 8
        ypos = (start + end) / 2
        return (
          <YAxisLine
            key={`${i}-y-axis`}
            xstart={x_scale(benchmarkData[sdg] * 100)}
            ystart={start}
            yend={end}
            style={{
              stroke: SDG_COLORS[sdg.split('_')[1]],
              strokeWidth: '3px',
            }}
          />
        )
      })
  }

  const labelFunc = i => {
    const values = [-100, -75, -50, -25, 0, 25, 50, 75, 100]
    const offset = values[i] === 0 ? zeroOffset : 10
    return x_scale(values[i] - offset + xLAbelPos)
  }

  return (
    <div className={wrapperClass} style={{ height: cardHeight }}>
      <span className='SDG-Exposure-Title'>{title.toUpperCase()}</span>
      <div className='float-right'>
        <StyledExplainerLink
          href='https://help.yves.blue/en/articles/5013759-the-yvesblue-public-equity-sdg-model'
          target='_blank'
        >
          <LinkIcon className='far fa-question-circle' />
        </StyledExplainerLink>
        <br />
      </div>
      <div className='sdg-exposure-body'>
        <div id='sdg-chart' className={benchmarkData ? 'height-90p' : ''}>
          <svg className={svgClass}>
            <XAxisLabels
              xscale={labelFunc}
              ystart={10}
              values={[
                '-100%',
                '-75%',
                '-50%',
                '-25%',
                '0%',
                '+25%',
                '+50%',
                '+75%',
                '+100%',
              ]}
              linedist={0}
              offset={0}
              classes={fontClass}
            />

            <XAxisLabels
              xscale={labelFunc}
              ystart={chartHeight - 15}
              values={[
                '-100%',
                '-75%',
                '-50%',
                '-25%',
                '0%',
                '+25%',
                '+50%',
                '+75%',
                '+100%',
              ]}
              linedist={0}
              offset={0}
              classes={fontClass}
            />
            <rect
              fill='#FFF8F1'
              x={x_scale(-100)}
              y={20}
              width={chartWidthBeigeReal()}
              height={chartHeight - 50}
            />
            <rect
              fill='#F3FFF4'
              x={x_scale(50)}
              y={20}
              width={chartWidthGreenReal()}
              height={chartHeight - 50}
            />
            {renderLines()}
            {renderBubbles()}
            {benchmarkData && renderBenchmark()}
            {renderIcons()}
          </svg>
        </div>
        {benchmarkData && (
          <div className='legend-container'>
            <div className='SDG-Exposure-Legend-Title'>LEGEND</div>
            <div className='legend-labels'>
              <div className='float-left legend-bubble' />
              <span className='float-left'>Portfolio</span>

              <span className='float-right benchmark-legend'>
                {benchmarkName}
              </span>
              <div className='float-right legend-line' />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

SDGExposureCard.defaultProps = {
  width: 100,
}

export default withErrorBoundary(SDGExposureCard)
