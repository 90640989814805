import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Select from 'react-select-3'

import Button from '../../../../components/styleguide/Button'
import DatePicker from '../../../../components/styleguide/DatePicker'
import InputLabel from '../../../../components/styleguide/InputLabel'
import SectionWrapper from '../../../../components/styleguide/SectionWrapper'
import TextInput from '../../../../components/styleguide/TextInput'
import { postHoldingFormPublications } from '../../../../repositories/holdings_repository'
import { PEErrorService } from '../../errorService'
import { AddPipelineDialog } from '../../styles'

const AddHoldingFormPublicationModal = ({
  show,
  onModalHide,
  onModalSubmit,
  holding,
  dataLabel,
}) => {
  let minExpiresAt = new Date()
  let defaultExpiresAt = new Date()

  // configure the min and default expiration dates
  minExpiresAt.setDate(minExpiresAt.getDate() + 1)
  defaultExpiresAt.setDate(minExpiresAt.getDate() + 7)
  defaultExpiresAt = defaultExpiresAt.toISOString().substr(0, 10)

  const firstKeyPerson = holding.key_people[0]
  const firstForm = holding.forms[0]

  const [name, setName] = useState('')
  const [keyPerson, setKeyPerson] = useState({
    value: firstKeyPerson.id,
    label: firstKeyPerson.full_name,
  })
  const [form, setForm] = useState({
    value: firstForm.id,
    label: firstForm.description,
  })
  const [errors, setErrors] = useState([])
  const [expiresAt, setExpiresAt] = useState(defaultExpiresAt)
  const [alert, setAlert] = useState(null)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const setValuesToDefault = () => {
    setName('')
    setKeyPerson({ value: firstKeyPerson.id, label: firstKeyPerson.full_name })
    setForm({ value: firstForm.id, label: firstForm.description })
    setExpiresAt(defaultExpiresAt)
  }

  const onSubmit = e => {
    e.preventDefault()

    const publication = {
      name: name,
      custom_report_form_id: form.value,
      holding_key_person_id: keyPerson.value,
      expires_at: expiresAt,
    }

    setDisableSubmit(true)

    postHoldingFormPublications(publication)
      .then(response => {
        setErrors([])
        setValuesToDefault()

        setDisableSubmit(false)
        onModalSubmit(response.data)
      })
      .catch(error => {
        if (error.response.data['holding_key_person']) {
          setAlert('THIS KEY PERSON IS NOT CONFIRMED')
        }

        PEErrorService.setErrors(error.response.data, setErrors)
        setDisableSubmit(false)
      })
  }

  const onHide = () => {
    setValuesToDefault()
    setErrors([])
    setDisableSubmit(false)
    onModalHide()
  }

  const getKeyPersonOptions = () => {
    return holding.confirmedKeyPeople().map(keyPerson => {
      return { label: keyPerson.full_name, value: keyPerson.id }
    })
  }

  const getFormOptions = () => {
    return holding.formsWithItems().map(form => {
      return { label: form.description, value: form.id }
    })
  }

  const expiresAtClean = () => {
    if (typeof expiresAt === 'string') {
      return expiresAt.replace(/-/g, '/')
    } else {
      return expiresAt
    }
  }

  return (
    <AddPipelineDialog show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alert && alert.length > 0 && (
          <div className='alert alert-danger'>{alert}</div>
        )}
        <SectionWrapper>
          <TextInput
            error={errors.name}
            label='Name'
            width='100%'
            marginBottom='0px'
            marginTop='0px'
            id='name'
            value={name}
            // eslint-disable-next-line no-unused-vars
            onChange={(id, value, e) => {
              setName(value)
            }}
          />
        </SectionWrapper>

        <SectionWrapper>
          <InputLabel label='Key Person' />
          <Select
            styles={{
              container: provided => ({
                ...provided,
                width: '100%',
                marginBottom: '16.5px',
              }),
              input: provided => ({
                ...provided,
                paddingTop: '8px',
                paddingBottom: '4.75px',
              }),
            }}
            onChange={input => {
              setKeyPerson({ value: input.value, label: input.label })
            }}
            options={getKeyPersonOptions()}
            value={{ value: keyPerson.value, label: keyPerson.label }}
          />
        </SectionWrapper>

        <SectionWrapper>
          <InputLabel label='Form' />
          <Select
            styles={{
              container: provided => ({
                ...provided,
                width: '100%',
                marginBottom: '16.5px',
              }),
              input: provided => ({
                ...provided,
                paddingTop: '8px',
                paddingBottom: '4.75px',
              }),
            }}
            onChange={input => {
              setForm({ value: input.value, label: input.label })
            }}
            options={getFormOptions()}
            value={{ value: form.value, label: form.label }}
          />
        </SectionWrapper>

        <SectionWrapper>
          <DatePicker
            error={errors.inception_date}
            label='Expires At'
            // width="15%"
            id='expires_at'
            date={expiresAtClean()}
            data-cy={`${dataLabel}-expires-at-date-picker`}
            onChange={value => setExpiresAt(value)}
          />
        </SectionWrapper>

        <SectionWrapper justify='space-between'>
          <Button width='20%' label='Cancel' onClick={onHide} />
          <Button
            width='30%'
            label='Save Report'
            disabled={disableSubmit}
            onClick={onSubmit}
          />
        </SectionWrapper>
      </Modal.Body>
    </AddPipelineDialog>
  )
}

export default AddHoldingFormPublicationModal
