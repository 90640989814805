import axios from 'axios'

export const putESGSettings = data => {
  return axios.put(`/app/api/esg_settings`, data)
}

export const getESGSetting = (targetId, targetType) => {
  return axios.get(
    `/app/api/esg_settings?target_id=${targetId}&target_type=${targetType}`
  )
}

export const getPresets = () => {
  return axios.get(`/app/api/esg_settings/preset_list`)
}

export const loadPreset = ({ data }) => {
  return axios.put(`/app/api/esg_settings/load_preset`, data)
}

export const getSectors = () => {
  return axios.get(`/app/api/industry_groups`)
}

export const postIntermediateSettings = data => {
  return axios.post(`/app/api/esg_settings/calculate_intermediate_scores`, data)
}

export const getIntermediateSettings = data => {
  return axios.get(
    `/app/api/esg_settings/fetch_intermediate_scores?settings_version=${data}`
  )
}

export const getBreadcrumbs = targetInfo => {
  if (targetInfo.targetType == 'Organization')
    return [
      { name: 'Home', link: '/app' },
      { name: 'Organization', active: true },
      { name: 'Custom ESG Scoring', active: true },
    ]
  else if (targetInfo.targetType == 'Portfolio')
    return [
      { name: 'Home', link: '/app' },
      { name: 'Portfolios', link: '/app/portfolios' },
      {
        name: targetInfo.targetName,
        link: `/app/portfolios/${targetInfo.targetId}`,
      },
      { name: 'Custom ESG Scoring', active: true },
    ]
}

export const validateWeights = data => {
  let valid = true
  const getAlertMessage = (reference, name) => {
    if (reference) {
      let environmentTotal
      let socialTotal
      let governanceTotal

      if (reference?.advanced) {
        environmentTotal = 0
        data.esg_metrics_map.overall.environment.map(
          metric =>
            (environmentTotal += Number(reference?.advanced?.[metric.metric]))
        )
        socialTotal = 0
        data.esg_metrics_map.overall.social.map(
          metric =>
            (socialTotal += Number(reference?.advanced?.[metric.metric]))
        )
        governanceTotal = 0
        data.esg_metrics_map.overall.governance.map(
          metric =>
            (governanceTotal += Number(reference?.advanced?.[metric.metric]))
        )
      }
      const pillarsTotal =
        Number(reference?.e) + Number(reference?.s) + Number(reference?.g)
      if (pillarsTotal != 100 && pillarsTotal != 0)
        alert(`${name} Pillars do not add up to 100%!`)
      else if (environmentTotal && Math.round(environmentTotal) != 100)
        alert(`${name} metrics for Environment do not add up to 100%!`)
      else if (socialTotal && Math.round(socialTotal) != 100)
        alert(`${name} metrics for Social do not add up to 100%!`)
      else if (governanceTotal && Math.round(governanceTotal) != 100)
        alert(`${name} metrics for Governance do not add up to 100%!`)
      else return true
    } else {
      return true
    }
  }
  valid = valid && getAlertMessage(data.esg_pillars, 'Overall')

  data.sectors.map(sector => {
    valid =
      valid && getAlertMessage(data.sector_esg_pillars[sector.id], sector.name)
  })

  return valid
}
export const submitData = ({ data, setDisabledButton }) => {
  if (validateWeights(data)) {
    setDisabledButton && setDisabledButton(true)

    return putESGSettings({
      ...data,
      target_type: data.target_information.targetType,
      target_id: data.target_information.targetId,
    })
  }
}

export const sendWeights = ({
  data,
  setIntermediateScoresLoading,
  setTimer,
  dispatch,
}) => {
  if (validateWeights(data)) {
    setIntermediateScoresLoading(true)

    postIntermediateSettings({
      sector_esg_pillars: data?.sector_esg_pillars,
      esg_pillars: data?.esg_pillars,
      score_ranking_setting: data?.score_ranking_setting,
    })
      .then(res => {
        return res.data.settings_version
      })
      .then(settingsVersion => {
        startPolling(settingsVersion, dispatch, setIntermediateScoresLoading)
      })
  }
}

const startPolling = (
  settingsVersion,
  dispatch,
  setIntermediateScoresLoading
) => {
  const timer = setInterval(
    () =>
      callIntermediateScores(
        settingsVersion,
        timer,
        dispatch,
        setIntermediateScoresLoading
      ),
    2500
  )
}
const callIntermediateScores = (
  settingsVersion,
  timer,
  dispatch,
  setIntermediateScoresLoading
) => {
  getIntermediateSettings(settingsVersion).then(res => {
    if (res.data?.intermediate_scores) {
      clearInterval(timer)
      dispatch({
        type: 'intermediate-scores-loaded',
        payload: res.data.intermediate_scores,
      })
      setIntermediateScoresLoading(false)
    }
  })
}
export const destroyPreset = presetId => {
  return axios.delete(`/app/api/organization/esg_setting_presets/${presetId}`)
}
