import React, { useMemo } from 'react'
import styled from 'styled-components'

import { addThousandSeparator } from '../../../../helpers/number'
import { FONT_COLOR_PRIMARY } from '../../../../styles/colors'
import RecordsCard from '../../../../yb_components/cards/records_card/RecordsCard'
import {
  addFilterOptionsToColumns,
  bucketToFilterSelectOptions,
} from '../../../../yb_helpers/data_converters'
import { titleCase } from '../../../../yb_helpers/strings'
import { SUBSIDIES_BUCKET_DATA } from '../Subsidies.constants'

const RecordsTableCell = styled.div`
  margin-right: ${props => props.marginRight || '0px'};
  display: flex;
  justify-content: ${props => props.justify || 'center'};
  color: ${FONT_COLOR_PRIMARY};
  font-weight: ${props => (props.header ? 'bold' : 'normal')};
  font-size: 11px;
  text-align: center;
`

const SubsidiesRecordsCard = props => {
  const renderCell = (label, cellOptions) => {
    let fLabel = !label || label === '' ? '—' : label
    return <RecordsTableCell {...cellOptions}>{fLabel}</RecordsTableCell>
  }

  const SUBSIDIES_RECORDS_COLUMNS = [
    {
      accessor: 'subsidy_level',
      Header: props => renderCell('Subsidy Level', { header: true }),
      Cell: props => renderCell(titleCase(props.value)),
      filter: {
        placeholder: 'All Subsidy Levels',
        isMulti: true,
      },
    },
    {
      accessor: 'subsidy_type',
      Header: props => renderCell('Type', { header: true }),
      Cell: props => renderCell(titleCase(props.value)),
      filter: {
        placeholder: 'All Types',
        isMulti: true,
      },
    },
    {
      accessor: 'program',
      Header: props => renderCell('Program', { header: true }),
      Cell: props => renderCell(props.value),
      filter: {
        placeholder: 'All Programs',
        isMulti: true,
      },
    },
    {
      accessor: 'agency',
      Header: props => renderCell('Agency', { header: true }),
      Cell: props => renderCell(props.value),
      filter: {
        placeholder: 'All Agencies',
        isMulti: true,
      },
    },
    {
      accessor: 'sub_year',
      Header: props => renderCell('Year', { header: true }),
      Cell: props => renderCell(props.value),
      filter: {
        placeholder: 'All Years',
        isMulti: true,
      },
    },
    {
      accessor: 'state',
      Header: props => renderCell('State', { header: true }),
      Cell: props => renderCell(props.value),
      filter: {
        placeholder: 'All States',
        labelFormatter: value => value,
        isMulti: true,
      },
    },
    {
      accessor: 'subsidy',
      Header: props =>
        renderCell('Amount', { header: true, justify: 'flex-end' }),
      Cell: props =>
        renderCell(
          `$ ${props?.value ? addThousandSeparator(props?.value) : '0'}.00`,
          {
            justify: 'flex-end',
            marginRight: '10px',
          }
        ),
      filter: {
        placeholder: 'All Amounts',
        selectOptions: useMemo(
          () => bucketToFilterSelectOptions(SUBSIDIES_BUCKET_DATA),
          []
        ),
        isMulti: true,
      },
    },
  ]

  /* Data & Memoized data */
  const filterLabelFormatter = value =>
    isNaN(value) ? titleCase(value) : value
  const memoizedRecordsColumns = useMemo(
    () =>
      addFilterOptionsToColumns(
        SUBSIDIES_RECORDS_COLUMNS,
        props.stats?.filter_values,
        filterLabelFormatter
      ),
    [props.stats]
  )

  /* Render */
  return (
    <RecordsCard
      columns={memoizedRecordsColumns}
      data-cy='subsidies_records_card'
      {...props}
    />
  )
}

export default SubsidiesRecordsCard
