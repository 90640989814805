import React from 'react';
import injectSheet from 'react-jss';
import propTypes from 'prop-types';

const Loading = ({ classes, show, message, full }) => {
  if(!show) return null;

  return (
    <div className={classes.loading}>

      <span>{message}</span>
      
    </div>
  );
}

Loading.defaultProps = {
  show: false
};

Loading.propTypes = {
  show: propTypes.bool.isRequired,
  message: propTypes.string,
  classes: propTypes.object.isRequired
}

const style = { 
  loading: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflowY: 'scroll',
    height: props => `${props.full ? null : '150vh'}`,
    backgroundColor: props => `rgba(0, 0, 0, ${props.message ? '.5' : '.1'})`,
    zIndex: 999,
    '& span': {
      display: 'inline-block',
      width: '100%',
      textAlign: 'center',
      fontSize: 36,
      fontWeight: 'bold',
      color: 'white',
      marginTop: '25vh'
    }
  }
};

export default injectSheet(style)(Loading);