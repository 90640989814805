
import React, { useState } from "react";
import SectionWrapper from "../../../components/styleguide/SectionWrapper";
import Button from "../../../components/styleguide/Button";
import {ConfirmDialog} from "../../fund/styles";
import {Modal} from "react-bootstrap";
import { deleteOtpSecrets } from "../../../repositories/otp_secrets_repository";
import Alert from "../../../components/styleguide/Alert";
import { TwoFactorAuthDisabledMessage } from "../../../constants/two_factor";

const TwoFactorForm = ({user, onTwoFactorDisable}) => {
    const [twoFactorAuthEnabled, setTwoFactorAuthEnabled] = useState(user.two_factor_auth_enabled);
    const [showConfirmModal, setShowConfirmModal] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);

    const onConfigClick = () => {
        window.location.href="/app/settings/two_factor";
    };

    const onBackupCodesClick = () => {
        window.location.href="/app/settings/two_factor/otp_backup_codes";
    };

    const onDisableClick = () => {
        setShowConfirmModal(true);
    };

    const onDisable = () => {
        deleteOtpSecrets().then((response) => {
            setShowConfirmModal(false);
            setTwoFactorAuthEnabled(false);
            onTwoFactorDisable();
        });
    }

    return(
        <>
            <SectionWrapper justify="space-between">
                <SectionWrapper width="40%">
                    Two-Factor Authentication provides enhanced security by requiring two forms of identification
                    when you log into YvesBlue.
                </SectionWrapper>

                {
                    !twoFactorAuthEnabled && <Button label="Configure 2FA" width="20%" onClick={onConfigClick}/>
                }
                {
                    twoFactorAuthEnabled && <Button label="Turn Off 2FA" width="20%" onClick={onDisableClick}/>
                }

            </SectionWrapper>

            <SectionWrapper margin="20px 0 0 0" justify="space-between">
                <SectionWrapper width="40%">
                    Backup codes are one-time-use codes that you should keep with you physically.  You can use each
                    code one time while logging in, if you are unable to access Google Authenticator.  If you use
                    them all up, you are able to generate a new set at any time.
                </SectionWrapper>

                <Button label="Backup Codes" width="20%" disabled={!twoFactorAuthEnabled} onClick={onBackupCodesClick}/>
            </SectionWrapper>

            {
                showConfirmModal && <ConfirmDialog show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are You Sure You Want To Disable Two Factor Authentication (2FA)?</Modal.Title>
                    </Modal.Header>
                    <SectionWrapper justify="center" margin="20px 0px 0px 0px">
                        <Button margin="0px 15px 0px 0px" width="30%" label="Confirm" onClick={onDisable}/>
                        <Button width="30%" label="Close" onClick={() => setShowConfirmModal(false)}/>
                    </SectionWrapper>
                </ConfirmDialog>


            }
        </>
    )
};

export default  TwoFactorForm;

