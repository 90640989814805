import styled from 'styled-components'

import Button from '../../../../components/styleguide/Button'
import {
  APP_COLOR_PRIMARY,
  BORDER_COLOR_LIGHT,
  BORDER_COLOR_PRIMARY,
  BORDER_COLOR_TERTIARY,
  FONT_COLOR_PRIMARY,
} from '../../../../styles/colors'
import LoadingSpinner from '../../../../yb_components/styleguide/spinners/LoadingSpinner'

/* Styled Components */
export const StyledContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  padding: 20px;
`

export const StyledH1 = styled.div`
  margin: 0;
  display: inline-block;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 700;
`

export const StyledTitleContainer = styled.div`
  margin-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const StyledAsOfCell = styled.div`
  padding-left: 15px;
  display: flex;
  align-content: center;
  justify-content: flex-start;
`

export const StyledPaginatorContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`

export const StyledButtonContainer = styled.div`
  margin-right: ${props => props.marginRight || '0px'};
  float: left;
`
