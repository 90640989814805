import React, { useState } from "react";

import TextInput from "../../../../../../components/styleguide/TextInput";
import Button from "../../../../../../components/styleguide/Button";
import SectionWrapper from "../../../../../../components/styleguide/SectionWrapper";
import { postOtpSecrets } from "../../../../../../repositories/otp_secrets_repository";
import WrapperColumn from "../../../../../../components/styleguide/WrapperColumn";

import { invalidOtpSecretAttemptMessage } from "../../../../../../constants/two_factor";
import Link from "../../../../../../components/styleguide/Link";

const OtpSecretAttemptsNew = ({otpSecret, callback, showNextButton}) => {
    const [attempt, setAttempt] = useState("");
    const [errorMsg, setErrorMsg] = useState(null);

    const onNext = () => {
        postOtpSecrets({ otpSecret: otpSecret, otpAttempt: attempt }).then((response) => {
            callback();
        }).catch((error) => {
            setErrorMsg(invalidOtpSecretAttemptMessage);
        });
    };

    const onBackToAccountEdit = () => {
        window.location.href="/app/account/edit";
    };

    return(<>
        <>
            <WrapperColumn styles={{height: "100%", justify: "space-between"}}>
                <WrapperColumn>
                    Enter a code from Google Authenticator to verify registration was successful.
                    <TextInput width="20%" error={errorMsg} value={attempt} onChange={(id, value, e) => setAttempt(value) }/>
                </WrapperColumn>

                {
                    showNextButton &&
                    <SectionWrapper justify="space-between" width="100%" margin="0 0 5px 0">
                        <Link onClick={onBackToAccountEdit}>Cancel</Link>
                        <Button width="20%" label="Next" onClick={onNext}/>
                    </SectionWrapper>
                }
            </WrapperColumn>
        </>
    </>)
};

export default OtpSecretAttemptsNew;
