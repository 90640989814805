export const accounts = [
    {name:"Investment Banking", label: "Investment Banking"},
    {name:"Financial Advisory", label: "Financial Advisory"},
    {name:"Underwriting", label: "Underwriting"},
    {name:"Equity Underwriting", label: "Equity Underwriting"},
    {name:"Debt Underwriting", label: "Debt Underwriting"},
    {name:"Institutional Client Services", label: "Institutional Client Services"},
    {name:"Fixed Income Client Execution", label: "Fixed Income Client Execution"},
    {name:"Currency Client Execution", label: "Currency Client Execution"},
    {name:"Commodoities Client Execution", label: "Commodoities Client Execution"},
    {name:"Equities Client Execution", label: "Equities Client Execution"},
    {name:"Investing & Lending", label: "Investing & Lending"},
    {name:"Equity Securities", label: "Equity Securities"},
    {name:"Debt Securities & Loans", label: "Debt Securities & Loans"},
    {name:"Investment Management", label: "Investment Management"},
    {name:"Business Banking", label: "Business Banking"},
    {name:"Debt Securities & Loans", label: "Debt Securities & Loans"},
    {name:"Commercial Lending", label: "Commercial Lending"},
    {name:"Mortgage Lending", label: "Mortgage Lending"},
    {name:"Consumer Lending", label: "Consumer Lending"},
  ]

  export const titles = [
    {name:"Institutional Fixed Income Sales Trader", label: "Institutional Fixed Income Sales Trader"},
    {name:"Equity Derivatives Market Specialist", label: "Equity Derivatives Market Specialist"},
    {name:"Fixed Income Sales", label: "Fixed Income Sales"},
    {name:"Senior Vice President", label: "Senior Vice President"},
    {name:"Equity Sales Trader", label: "Equity Sales Trader"},  
    {name:"Junior Equity Sales Trader", label: "Junior Equity Sales Trader"},
    {name:"Sales Assistant (Institutional Sales and Trading)", label: "Sales Assistant (Institutional Sales and Trading)"},
    {name:"Production Equity Sales Trader", label: "Production Equity Sales Trader"},
    {name:"Equity Sales and Trading Compliance Officer", label: "Equity Sales and Trading Compliance Officer"},
    {name:"Equity Sales Trader", label: "Equity Sales Trader"},
    {name:"Investment Specialist", label: "Investment Specialist"},
    {name:"Institutional Global Equities Sales Trader", label: "Institutional Global Equities Sales Trader"},
    {name:"Trader", label: "Trader"},
    {name:"Equity Trader", label: "Equity Trader"},
    {name:"Fixed Income Trader", label: "Fixed Income Trader"},
    {name:"Head of Trading", label: "Head of Trading"},
    {name:"Equity Analyst", label: "Equity Analyst"},
    {name:"Research Associate", label: "Research Associate"},
    {name:"Portfolio Manager", label: "Portfolio Manager"},
    {name:"Private Equity Associate (Internship)", label: "Private Equity Associate (Internship)"},
    {name:"Acquisitions Associate", label: "Acquisitions Associate"},
    {name:"Analyst, Global Equity Products", label: "Analyst, Global Equity Products"},
    {name:"Senior Analyst – Investment Analytics", label: "Senior Analyst – Investment Analytics"},
    {name:"Private Equity Analyst – Paid Internship", label: "Private Equity Analyst – Paid Internship"},
    {name:"Research Associate", label: "Research Associate"},
    {name:"Research Associate Gold & Precious Minerals", label: "Research Associate Gold & Precious Minerals"},
    {name:"Research Analyst", label: "Research Analyst"},
    {name:"Research Coordinator", label: "Research Coordinator"},
    {name:"Analyst", label: "Analyst"},
    {name:"Director", label: "Director"},
    {name:"Managing Director", label: "Managing Director"},
  ]