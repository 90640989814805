import { InfoLink } from '@exponentialtech/yb-component-library'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Card from '../../../../../components/styleguide/Card'
import Link from '../../../../../components/styleguide/Link'
import { FONT_COLOR_SECONDARY } from '../../../../../styles/colors'
import { TcfdLegend } from '../legend/TcfdLegend'
import { TcfdOverallScoreWell } from './TcfdOverallScoreWell'
import { TcfdPortfolioRiskList } from './TcfdPortfolioRiskList'

const StyledDiv = styled(Card)`
  margin: ${props => (props.margin ? props.margin : 0)};
  padding: 1.25rem;
  border-radius: 6px;
  background-color: #fff;

  .title-container {
    padding: 0 40px 0 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
    gap: 10px;

    a {
      ${props => props.pdfStyle && 'margin-left: 10px;'}
      font-size: 12px;
      font-weight: normal;
    }
  }

  header {
    margin: 0 0 1.25rem 0;
    position: relative;

    h3 {
      margin: 0 0 1.25rem 0;
      font-weight: 700;
      font-size: ${props => (props.pdfStyle ? '14px' : '1rem')};
      line-height: 1.2;
      text-transform: uppercase;
    }
    p {
      margin: 0;
      color: ${FONT_COLOR_SECONDARY};
    }
  }

  main {
    display: flex;
    align-items: row;
    gap: 1.25rem;
  }

  .tcfd-portfolio-main {
    flex-direction: ${props => (props.pdfStyle ? 'column' : 'row')};
  }
`

export const TcfdPortfolioScoreCard = ({
  instrumentName = '',
  portfolio,
  scores,
  margin,
  tcfdLink = '',
  pdfStyle = false,
}) => {
  const overallScoreTitle = instrumentName
    ? `${instrumentName} TCFD Risk Report`
    : portfolio
    ? 'TCFD OVERALL PORTFOLIO SCORE'
    : 'Overall Company Score'
  const cardText = portfolio
    ? 'TCFD Scenario Reporting on YvesBlue offers portfolio managers practical tools related to TCFD screening and disclosures, enabling due diligence in the absence of a scenario disclosure by the portfolio’s holding companies. YvesBlue focuses on five pillars to score risk. A portfolio risk score is computed as an aggregate of the individual pillar scores and compared against a customizable benchmark. Scores range from 0 to 4, with 0 representing the lowest risk for a pillar and 4 representing the highest risk for a pillar.'
    : 'TCFD Scenario Reporting on YvesBlue offers portfolio managers practical tools related to TCFD screening and disclosures, enabling due diligence in the absence of a scenario disclosure by the portfolio’s holding companies. YvesBlue focuses on five pillars to score risk. A company risk score is computed as an aggregate of the individual pillar scores and compared against the average score within its sector. Scores range from 0 to 4, with 0 representing the lowest risk for a pillar and 4 representing the highest risk for a pillar.'

  return (
    <StyledDiv margin={margin} pdfStyle={pdfStyle}>
      <header>
        <div className='title-container'>
          <h3>{overallScoreTitle}</h3>
          {tcfdLink.length > 0 && (
            <Link href={tcfdLink}>View Full Report »</Link>
          )}
        </div>
        <InfoLink
          href={
            'https://help.yves.blue/en/articles/6387564-tcfd-scenario-reporting-on-yvesblue'
          }
          style={{ position: 'absolute', top: '0', right: '0' }}
          target={'_blank'}
        />
        <p style={{ marginBottom: '.75rem' }}>{cardText}</p>
      </header>
      <main className='tcfd-portfolio-main'>
        <TcfdOverallScoreWell
          scores={scores}
          instrument={instrumentName}
          portfolio={portfolio}
          pdfStyle={pdfStyle}
        />
        <TcfdPortfolioRiskList scores={scores} pdfStyle={pdfStyle} />
      </main>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <TcfdLegend />
      </div>
    </StyledDiv>
  )
}

TcfdPortfolioScoreCard.propTypes = {
  instrument: PropTypes.string,
  margin: PropTypes.string,
  portfolio: PropTypes.bool,
  scores: PropTypes.object.isRequired,
  tcfdLink: PropTypes.string,
  pdfStyle: PropTypes.bool,
}
