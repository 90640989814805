import axios from 'axios'

import qs from 'query-string'

export const createDownloadPdfEvent = (event) => {
  return axios.post(`/app/api/download_pdf_events`, event)
}

export const getOrganizationEvents = (query) => {
  return axios
    .post(`/app/api/organization/query_events`, query)
    .then((res) => res.data)
  // .catch((error) => {
  //   console.log('error!', error)
  // })
}

export const exportOrganizationEvents = ({ filter, page }) => {
  const params = { filter: filter, page: page }
  return axios.post(`/app/api/organization/export_events`, params)
}

export const getPortfolioEvents = (params) => {
  return axios
    .post(`/app/api/portfolios/${params.target_id}/query_events`, params)
    .then((res) => res.data)
}

export const exportPortfolioEvents = ({ filter, page, portfolioId }) => {
  const params = { filter: filter, page: page }
  return axios.post(`/app/api/portfolios/${portfolioId}/export_events`, params)
}
