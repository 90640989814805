import React from 'react'

import { FONT_COLOR_SECONDARY } from '../../styles/colors'
import TextSection from './TextSection'

const DisclosureFooter = () => {
  return (
    <TextSection fontSize={'12px'} color={FONT_COLOR_SECONDARY}>
      <strong>Data Sources &amp; Disclaimers:</strong>
      <p>
        <strong>Fundamental and ESG Data:</strong> ESG data augmented by
        additional sources.
        <br />
        <strong>RepRisk</strong>: RepRisk Founded in 1998 and headquartered in
        Switzerland, RepRisk is a pioneer in ESG data science that leverages the
        combination of AI and machine learning with human intelligence to
        systematically analyze public information and identify material ESG
        risks. RepRisk’s flagship product, the RepRisk ESG Risk Platform, is the
        world’s largest and most comprehensive due diligence database on ESG and
        business conduct risks, with expertise in 20 languages and coverage of
        135,000+ public and private companies and 30,000+ infrastructure
        projects. For more than a decade, the world’s leading financial
        institutions and corporations have trusted RepRisk for due diligence and
        risk management across their operations, business relationships, and
        investments. To learn more, please visit www.reprisk.com.
        <br />
      </p>
      <p>
        <em>
          The information in this report is for general information purposes
          only. It is not intended as personal financial or investment advice
          and should not be construed or relied on as such. No information
          contained within should be construed or relied upon as providing
          recommendations in relation to any financial product or investment
          strategy.
        </em>
      </p>
    </TextSection>
  )
}

export default DisclosureFooter
