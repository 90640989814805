import styled from 'styled-components';

const AddButton = styled.button`

    border: 1px solid transparent;
    outline: none;
    -webkit-appearance: none;
    font-weight: bold;    
    max-height: 30px;

    color: ${props => props.color};
    background-color: ${props => props.bgColor};

    &:focus {
      outline: none;
    }
`;

export default AddButton;