import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { TcfdLegend } from '../../../../../modules/portfolio/tcfd/components/legend/TcfdLegend'
import ReportPage from '../../../../yb_layout/report_page/ReportPage'
import { AlignRight } from '../../../../yb_styles/layout'
import { TcfdTable } from './components/holdings/TcfdTable'
import TcfdScores from './components/TcfdScores'

const StyldeTcfdContainer = styled.div`
  ${AlignRight}
`

const TcfdTargetReport = ({
  title = 'TCFD Portfolio Report',
  scores,
  holdings,
  targetName,
  benchmarkName,
  asOf,
}) => {
  let localDate = asOf
  if (!localDate) localDate = dayjs()

  return (
    <ReportPage
      title={title}
      targetName={targetName}
      benchmarkName={benchmarkName}
      asOf={localDate}
    >
      <TcfdScores portfolio scores={scores} />
      <TcfdTable holdings={holdings} />

      <StyldeTcfdContainer>
        <TcfdLegend />
      </StyldeTcfdContainer>
    </ReportPage>
  )
}

TcfdTargetReport.propTypes = {
  title: PropTypes.string,
  scores: PropTypes.array.isRequired,
  holdings: PropTypes.array.isRequired,
  targetName: PropTypes.string,
  benchmarkName: PropTypes.string,
  asOf: PropTypes.instanceOf(dayjs.Dayjs),
}

export default TcfdTargetReport
