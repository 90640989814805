import propTypes from 'prop-types'
import React from 'react'

import { addThousandSeparator } from '../../helpers/number'
import { WASTER_USAGE_EXPLAINER_URL } from '../../yb_constants/urls/explainers'
import ImpactCard from './ImpactCard'

const WaterUsageImpactCard = ({
  targetType,
  data,
  minHeight,
  benchmarkName,
  benchmarkData,
}) => {
  const benchmarkComparison = (
    ((data.total - benchmarkData?.total) / data.total) *
    100
  ).toFixed(2)
  const benchmarkComparisonAbs = Math.abs(benchmarkComparison).toFixed(2)

  return (
    <ImpactCard
      targetType={targetType}
      value={addThousandSeparator(
        Math.round(parseFloat(data.total).toFixed(2))
      )}
      title='WATER USAGE'
      unit='×'
      holdingsPct={data.holdings_ownership_pct}
      description="a person's annual freshwater <br/> resources in North Africa."
      benchmarkDescription={
        benchmarkName && {
          text: `${benchmarkComparisonAbs}% ${
            benchmarkComparison > 0 ? 'more' : 'less'
          } than the ${benchmarkName}.`,
          color: '#D02F44',
        }
      }
      minHeight={minHeight}
      icons={
        <div className='icons'>
          <div>
            <img src={`/impact_icons/ico_water_usage.svg`} />
          </div>
        </div>
      }
      className='card'
      explainerHref={WASTER_USAGE_EXPLAINER_URL}
    />
  )
}

WaterUsageImpactCard.propTypes = {
  data: propTypes.shape({
    total: propTypes.oneOfType([
      propTypes.number.isRequired,
      propTypes.string.isRequired,
    ]),
    total_perc: propTypes.oneOfType([
      propTypes.number.isRequired,
      propTypes.string.isRequired,
    ]),
  }).isRequired,
}

export default WaterUsageImpactCard
