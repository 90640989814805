import React from 'react';
import styled from 'styled-components';
import { FONT_COLOR_SECONDARY } from '../../styles/colors';

const StyledCardValue = styled.div`
  display: inline-block;

  .not-reported {
    font-size: 1rem;
    font-weight: bold;
    color: FONT_COLOR_SECONDARY;
  }

  .value {
    font-size: 2.5rem;
    line-height: 1;
    .unit: {
      font-size: 1.5rem;
    }
  }

  .description {
    margin-top: 0.5rem;
    font-size: 15.5px;
    padding-right: 1rem;
    color: FONT_COLOR_SECONDARY;
  }
  .impact-benchmark {
    margin-top: 5px;
    font-weight: 700;
  }
`

const ImpactCardValue = ({ value, unit, description, benchmarkDescription }) => {
  let formattedValue = value < 1 ? '< 1' : value
  return (
    <StyledCardValue>
      <div className="value">
        {unit == '$' && (
          <><span className="label">{unit}</span> {formattedValue} <br/></>
        )}
        {unit != '$' && (
          <>{formattedValue} <span className="label">{unit}</span> <br/></>
        )}
      </div>
      <div className="description" dangerouslySetInnerHTML={{ __html: description }}/>
      {benchmarkDescription && (
        <div className="impact-benchmark" style={{color: benchmarkDescription?.color}}>{benchmarkDescription?.text}</div>
      )}
    </StyledCardValue>
  );
}

export default ImpactCardValue;
