//LIBRARIES
import React from 'react';

//COMPONENTS
import TextSection from '../../../../../components/styleguide/TextSection';
import LineChart from '../../../../../components/charts/components/LineChart';

//COLORS
import { CHART_COLOR_GREEN, APP_COLOR_PRIMARY } from '../../../../../styles/colors';

const IFCLineChart = ({ alignments, dataLabel, header }) => {
    
    const combinedAlignments = alignments.fundAlignments && alignments.holdingAlignments && [...alignments.fundAlignments, ...alignments.holdingAlignments];

    const range = combinedAlignments && combinedAlignments.length > 0 && combinedAlignments.map((alignment) => alignment.y).sort((a, b) => a - b );

    const domain = combinedAlignments && combinedAlignments.length > 0 && combinedAlignments.map((alignment) => alignment.x);

    const body = [
        {
            data: alignments.fundAlignments,
            color: CHART_COLOR_GREEN, 
            radius: 3,  
            key:"IFC-Line-Graph-Fund" 
        },
        {
            data: alignments.holdingAlignments,
            color: APP_COLOR_PRIMARY,
            radius: 3,  
            key:"IFC-Line-Graph-Holding" 
        }
    ]

    const showLabels = [];

    const xAxisShowLabels = [];

    return (
        <>
            <TextSection margin="0px 0px 0px 35px">{header}</TextSection>
            <LineChart
                body={body}
                id={`IFC-Line-Graph-${dataLabel}`}
                y_domain={range}
                x_domain={domain}
                showLabels={showLabels}
                majorHeight='100%'
                majorWidth='95%'
                useXStart={true}
                xLabelOffset={5}
                showXAxisTicks={false}
                xAxisShowLabels={xAxisShowLabels}
            />
        </>
    )
}

export default IFCLineChart;