import React from 'react'
import styled, { css } from 'styled-components'

import {
  BENCHMARK_COMPARISON_NEGATIVE_COLOR,
  BENCHMARK_COMPARISON_POSITIVE_COLOR,
  FONT_COLOR_SECONDARY,
} from '../../../../styles/colors'
import { FilledExplainerLink } from '../../../../yb_components/styleguide/links/Link.styles'
import { SortButton } from '../../tcfd/components/holdings_table/tcfd_table_head/SortButton'

const TABLE_STRIPE_COLOR = '#F1F1F1'

export const CarbonReportTable = styled.table`
  margin-bottom: 10px;

  tr {
    height: 50px;
  }
  .number {
    text-align: right;
    padding: 0px 10px;
  }
  .name {
    width: 300px;
  }
  .multi-column-header {
    border-bottom: 1px solid black;
    text-align: center;
  }
  .empty {
    padding: 0px 20px;
  }
  tbody {
    tr:nth-child(odd) {
      background-color: ${TABLE_STRIPE_COLOR};
    }
    tr:nth-child(even) {
      background-color: white !important;
    }
    td {
      padding: 0px 10px;
    }
  }
  thead {
    th {
      padding: 0px 10px;
      span {
        ${props =>
          props.pdf &&
          css`
            font-size: 12px;
          `}
      }
    }
    tr {
      color: ${FONT_COLOR_SECONDARY};
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
`

export const ModuleDescription = styled.div`
  margin-top: 10px;
  ${props =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom};` : ''}
  color: ${props => (props.pdf ? '#2E384D' : FONT_COLOR_SECONDARY)};
`

export const NoDataText = styled.div`
  text-align: center;
  margin-top: ${props => (props.margin ? props.margin : '20px')};
`

export const StyledExplainerLink = styled(FilledExplainerLink)`
  float: right;
`

export const renderTh = (elem, sort, onUpdateSort, pdf) => {
  if (elem?.empty) return <th className='empty' />
  return (
    <th>
      <SortButton
        {...elem}
        sorting={sort}
        updateSort={onUpdateSort}
        pdf={pdf}
      />
    </th>
  )
}

export const StyledCellSubValue = styled.div`
  color: ${props =>
    props.positive
      ? BENCHMARK_COMPARISON_POSITIVE_COLOR
      : props.color
      ? props.color
      : BENCHMARK_COMPARISON_NEGATIVE_COLOR};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`

export let COMMOM_BAR_PROPS = {
  margin: { top: 5, bottom: 5, right: 5, left: 5 },
  reverse: true,
  enableGridY: false,
  padding: 0,
  labelTextColor: 'white',
  label: d => (
    <>
      <tspan x='50%' dy='-0.5em'>
        {`${d.id}\n`}
      </tspan>
      <tspan x='50%' dy='1.2em'>
        {`(${Math.round(parseFloat(d.value))}%)`}
      </tspan>
    </>
  ),
  labelSkipHeight: 18,
  axisBottom: null,
  axisLeft: null,
  theme: {
    labels: {
      text: {
        fontSize: 12,
        fontWeight: 500,
        fontFamily: 'Lato',
      },
    },
  },
  isInteractive: true,
  tooltip: e => (
    <>
      <span
        style={{
          display: 'block',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >{`${Math.round(parseFloat(e.value))}%`}</span>
      <span>{e.id}</span>
    </>
  ),
}
