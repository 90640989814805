import propTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { APP_COLOR_PRIMARY } from '../../styles/colors'

const StyledChip = styled.div`
  display: inline-block;
  text-align: center;
  font-size: 0.88rem;
  padding: 0 13px;
  line-height: 1.44;
  height: 1.44rem;
  border-radius: ${props => props.borderRadius || '12.5px'};
  border: ${props => props.borderStyle || `1px solid #e3e3e3`};
  color: ${props => props.fontColor || APP_COLOR_PRIMARY};
  background-color: ${props => props.bgColor || 'white'};
  border-color: ${props => props.borderColor || APP_COLOR_PRIMARY};
  font-weight: ${props => props.fontWeight || 'bold'};
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  user-select: 'none';

  &:hover {
    border-bottom: ${props => props.borderHover};
    color: ${props => props.colorHover};
  }
`

const Chip = ({
  label,
  fontColor,
  bgColor,
  borderColor,
  colorHover,
  fontWeight,
  borderHover,
  borderRadius,
  style,
  borderStyle,
  className,
  onClick,
}) => {
  return (
    <StyledChip
      fontColor={fontColor}
      bgColor={bgColor}
      borderRadius={borderRadius}
      borderHover={borderHover}
      borderColor={borderColor}
      fontWeight={fontWeight}
      onClick={onClick}
      colorHover={colorHover}
      className={className}
      border={borderStyle}
      style={style}
    >
      {label}
    </StyledChip>
  )
}

Chip.propTypes = {
  label: propTypes.node.isRequired,
  className: propTypes.string,
  onClick: propTypes.func,
  active: propTypes.bool,
  bold: propTypes.bool,
}

export default Chip
