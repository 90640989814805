import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'

import { getPaiEntities } from '../../../../../repositories/portfolio_repository'
import { FONT_COLOR_SECONDARY } from '../../../../../styles/colors'
import Pagination from '../../../../../yb_components/styleguide/pagination/Pagination'
import SpinnerContainer from '../../../../../yb_components/styleguide/spinners/SpinnerContainer'
import {
  getNow,
  getRemainingLoadingTime,
} from '../../../../../yb_helpers/loader'
import { PaiLegend } from '../components/pai_legend/PaiLegend'
import ErrorMessage from '../components/tables/error_message/ErrorMessage'
import { PaiEntitiesFilters } from '../components/tables/pai_entities/filters/PaiEntitiesFilters'
import { PaiEntitiesTable } from '../components/tables/pai_entities/PaiEntitiesTable'

export const PaiEntitiesTab = ({ portfolioId, snapshotId }) => {
  const [impactYears, setImpactYears] = useState({})
  const [holdings, setHoldings] = useState([])
  const [sectors, setSectors] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [hasFetchError, setHasFetchError] = useState(false)
  const [querySnapshot, setQuerySnapshot] = useState({})
  const sortModel = useState({ name: 'target_name', direction: 'asc' })
  const [sort] = sortModel
  const errorMessage = useMemo(
    () => hasFetchError && <ErrorMessage />,
    [hasFetchError]
  )

  const fetchPaiEntities = (query, page = currentPage) => {
    setQuerySnapshot(query)
    setCurrentPage(page)

    const now = getNow()
    const paginatedQuery = {
      ...query,
      page,
      limit: 10,
      sort_field: sort.name,
      sort_dir: sort.direction,
    }

    setIsLoading(true)
    setHasFetchError(false)

    getPaiEntities(portfolioId, snapshotId, paginatedQuery)
      .then(({ data }) => {
        const { pai_holdings, sectors, total_pages, current_year, last_year } =
          data

        setHoldings(pai_holdings)
        setSectors(sectors)
        setTotalPages(total_pages)
        setImpactYears({
          current: current_year,
          previous: last_year,
        })
      })
      .catch(() => {
        setHasFetchError(true)
        setHoldings([])
        setTotalPages(0)
      })
      .finally(() => {
        const remainingLoadingTime = getRemainingLoadingTime(now)

        setTimeout(() => setIsLoading(false), remainingLoadingTime)
      })
  }

  const handlePaginationUpdate = updatedPage => {
    setCurrentPage(updatedPage)
    fetchPaiEntities(querySnapshot, updatedPage)
  }

  const handleFiltersUpdate = updatedFilters => {
    fetchPaiEntities(updatedFilters, 1)
  }

  useEffect(() => {
    fetchPaiEntities(querySnapshot)
  }, [sort])

  return (
    <main>
      <div
        style={{
          display: 'flex',
          alignItems: 'top',
          gap: '20px',
        }}
      >
        <p style={{ color: `${FONT_COLOR_SECONDARY}`, flexGrow: 1 }}>
          View the completion status of principal adverse sustainability impacts
          of each holding in your portfolio. Filter by Completion Amount to
          identify which holdings have the greatest data sufficiency needs and
          navigate directly to each of those holdings. The Entity Type filter
          and Sector dropdown enable further narrowing down of the list of
          holdings in the portfolio.
        </p>
        <PaiLegend style={{ marginTop: '4px' }} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <PaiEntitiesFilters
          impactYears={impactYears}
          sectors={sectors}
          onChange={handleFiltersUpdate}
        />
        <SpinnerContainer isLoading={isLoading}>
          <PaiEntitiesTable
            holdings={holdings}
            impactYears={impactYears}
            portfolioId={portfolioId}
            snapshotId={snapshotId}
            noDataText={errorMessage}
            sortModel={sortModel}
          />
          <footer
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Pagination
              max={totalPages}
              selected={currentPage}
              onChange={handlePaginationUpdate}
            />
            <PaiLegend />
          </footer>
        </SpinnerContainer>
      </div>
    </main>
  )
}

PaiEntitiesTab.propTypes = {
  portfolioId: PropTypes.string,
  snapshotId: PropTypes.string,
}
