import React from 'react'
import { Form } from 'react-bootstrap'
import styled from 'styled-components'

export const BaseLabel = ({ children, ...props }) => {
  const StyledLabel = styled(Form.Label)`
    margin: 0 0 10px;
    padding: 0;
    min-height: 14px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    font-size: 12px;
    line-height: 1.1;
    font-weight: 700;
    text-transform: uppercase;
    color: #979ca6;

    small {
      margin-left: 6px;
      font-weight: 400;
      text-transform: none;
    }
  `

  return <StyledLabel {...props}>{children}</StyledLabel>
}
