import React from 'react';

const SDGAlignment = ({ sdgs }) => {
  return (
    <div className="sdg-alignment">
      { sdgs.positive && sdgs.positive.length != 0 && (
        <div>
          <div className="label bold">
            Positive SDG Exposure
          </div>
          {sdgs.positive.map(sdg => (
            <img className="sdg" key={Math.random()} src={`/sdg_icons/${sdg}-white.svg`} />
          ))}
        </div>
      )}

      {
        sdgs.negative && sdgs.negative.length != 0 && (
          <div>

            <div className="label bold">
              Negative SDG Exposure
            </div>
            {sdgs.negative.map(sdg => (
              <img className="sdg" key={Math.random()} src={`/sdg_icons/${sdg}-white.svg`} />
            ))}
          </div>
        )
      }
    </div>
  );
}

export default SDGAlignment;
