import withFormRow from '@ui/components/compositions/withFormRow/withFormRow'
import React, { useState } from 'react'

import { FundAlignmentRadio } from '../FundAlignmentRadio'
// import theme from './FundAlignmentPicker.module.scss'
import './FundAlignmentPicker.module.scss'

interface IProps {
  model: YB.TModel<string>
}

const FundAlignmentPicker: React.FC<IProps> = ({ model = useState('') }) => {
  return (
    <div className='FundAlignmentPicker'>
      <FundAlignmentRadio
        article={6}
        isComplient={true}
        model={model}
        description={
          'Article 6 covers funds which do not integrate any kind of sustainability into the investment process.'
        }
      />
      <FundAlignmentRadio
        article={8}
        isComplient={false}
        model={model}
        description={
          'An article 8 product promotes environmental and/or social characteristics. If your product includes holdings that were selected on the basis of ESG scoring for example.'
        }
      />
      <FundAlignmentRadio
        article={9}
        isComplient={false}
        model={model}
        description={
          'An article 9 product must have a sustainable investment objective and must contribute to either an environmental or social objective and an index has been designated as a reference benchmark to measure performance of the product in things like carbon emissions for example.'
        }
      />
    </div>
  )
}

export const FundAlignmentPickerRow = withFormRow<IProps>(FundAlignmentPicker)
