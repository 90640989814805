import React from 'react';

const HoldingDestroyed = ({ event }) => {
  return (
    <>
      <span className="event-user-name">
        {event.context.full_name}
      </span> deleted <span className="event-user-name">
        {event.data.holding_name}
      </span> in { event.parentTargetExists ?  <a href={`/app/portfolios/${event.data.portfolio_id}/snapshots/${event.data.snapshot_id}`}>{event.data.portfolio_name}</a> : event.data.portfolio_name }.

      <span className="event-date">{ event.formattedTimestamp }</span>
    </>
  );
};

export default HoldingDestroyed;
