import React, { forwardRef } from 'react'

// eslint-disable-next-line react/display-name
export const WordFileIcon = forwardRef(() => (
  <svg
    width='46'
    height='60'
    viewBox='0 0 46 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M21.3616 29.3086L16.2283 42.1992L13.7316 29.6367C13.5311 28.6223 12.5521 27.9574 11.5348 28.166C10.5233 28.3594 9.86762 29.3555 10.0695 30.3633L13.8028 49.1133C13.9651 49.9263 14.6395 50.5359 15.4618 50.6133C16.3076 50.6902 17.0601 50.2123 17.367 49.4414L23.1 35.0508L28.833 49.4531C29.12 50.168 29.8083 50.625 30.5666 50.625C30.6231 50.625 30.6796 50.6232 30.738 50.6177C31.5602 50.5408 32.2348 49.9311 32.397 49.1177L36.1303 30.3677C36.3326 29.3514 35.6764 28.3649 34.6662 28.161C33.6417 27.9487 32.67 28.6151 32.4693 29.6317L29.9668 42.1942L24.8335 29.3036C24.1616 27.8789 21.9333 27.8789 21.3616 29.3086ZM43.3183 14.6836L30.8933 2.20312C29.4816 0.790078 27.5916 0 25.6083 0H8.16662C4.04245 0 0.699951 3.35742 0.699951 7.5L0.70071 52.5C0.70071 56.6426 4.04321 60 8.16738 60H38.0333C42.1575 60 45.5 56.6426 45.5 52.5V19.9805C45.5 17.9883 44.7183 16.0898 43.3183 14.6836ZM26.8333 3.99375C27.3556 4.17727 27.8443 4.44445 28.2473 4.8491L40.6723 17.3296C41.0783 17.7305 41.3466 18.2227 41.5216 18.75H28.7C27.6733 18.75 26.8333 17.9063 26.8333 16.875V3.99375ZM41.7666 52.5C41.7666 54.5672 40.0913 56.25 38.0333 56.25H8.16662C6.10862 56.25 4.43328 54.5672 4.43328 52.5V7.5C4.43328 5.43281 6.10862 3.75 8.16662 3.75H23.1V16.875C23.1 19.9805 25.6083 22.5 28.7 22.5H41.7666V52.5Z'
      fill='currentColor'
    />
  </svg>
))
