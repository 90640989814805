import React, { useState, useEffect, useRef } from 'react';
import TextSection from '../../../../components/styleguide/TextSection';
import TabHeader from '../../components/TabHeader';
import HoldingHeader from './components/HoldingHeader'
import SectionWrapper from '../../../../components/styleguide/SectionWrapper';
import { Container } from 'react-bootstrap';
import DetailsBox from '../../components/DetailsBox';
import { holdingShowTabs, defaultStartHoldingShow, holdingPipelineButtons } from '../../../../constants/funds';
import PEShowModuleWrapper from '../../components/PEShowModuleWrapper';
import SqrButtonCase from '../../components/SqrButtonCase';
import CardTitle from '../../../../components/styleguide/CardTitle';
import ExportButton from '../../../../components/styleguide/ExportButton';
import ManageHoldingModule from './modules/ManageHoldingModule';
import HoldingFormPublicationModule from "../../holding/modules/HoldingFormPublicationModule";

import { getHolding, queryHoldings, putFund, editFundHolding } from '../../../../repositories/funds_repository';
import { Holding } from '../../models/Holding';
import { PEErrorService } from '../../errorService';
import IFCPrincipleShow from '../../sections/reporting_framework/IFC_Principles/IFCPrincipleShow';
import IFCPrincipleCreate from '../../sections/reporting_framework/IFC_Principles/IFCPrincipleCreate';
import FormPublicationModule from '../form_publication/FormPublicationModule';

import HoldingActivityModule from "./modules/HoldingActivityModule";

import CustomFormsDisplay from './components/CustomFormsDisplay';

const HoldingShowPage = ({ fundId, holdingId, current_user }) => {
    const topOfHoldingTableRef = useRef(null)
    const [currTab, setCurrTab] = useState(defaultStartHoldingShow.currTab)
    const [activePipelineButton, setactivePipelineButton] = useState(defaultStartHoldingShow.pipelinebutton);
    const [holding, setHolding] = useState(null);
    const [showIFCShow, setIFCShow] = useState(true);
    const [showTabs, setShowTabs] = useState(holdingShowTabs);
    const [canUpdate, setCanUpdate] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        fundId &&
        holdingId &&
        getHolding({fundId, holdingId})
        .then((response) => {
            const holding = new Holding(response.data);
            setHolding(holding);
            setCanUpdate(response.data.current_app_user_can_update);

            if (holding.hasIFCForm()) {
                let newShowTabs = showTabs.slice();
                newShowTabs.splice(2,0, "IFC Reporting");

                setShowTabs(newShowTabs);
            }

        })
        .catch((error) => {
            console.log(error);
        });

    }, [holdingId]);

    const updateHolding = (holding) => {
        setHolding(holding);
    }


    const changeTab = (tab) => {
        setCurrTab(tab);
    };

    const onExport = () => {
        console.log("TODO: EXPORT")
    }

    const changeHoldingsPipeline = (button) => {
        setactivePipelineButton(button);
    }

    const openNoteModal = () => {
        console.log("TODO:ALLOW OPEN NOTE MODAL")
    }

    const currModuleTopTab = () => {
        switch(currTab){
            // case "Reporting":
            //     return <ExportButton onClick={onExport} fileType="PDF" className="export-btn"/>
            case "Monitoring & Engagement":
                return <div></div>
            case "IFC Reporting":
                return <div></div>
            case "Reports":
                return <SqrButtonCase
                    buttons={holdingPipelineButtons}
                    onChange={changeHoldingsPipeline}
                    dataLabel="pipeline-buttons-holding-reports"
                    activeButton={activePipelineButton}
                />;
            case "Activity":
                return <div/>
            case "Manage":
                return <div></div>
        }
    }

    const tabName = (tab) => {
        switch(tab){
            // case "Reporting":
            //     return "Key Metrics";
            case "Manage":
                return "Manage Holding";
            default:
                return tab;
        }
    }

    const currModule = () => {
        switch(currTab){
            // case "Reporting":
            //     return <div>reporting</div>
            case "Monitoring & Engagement":
                return <CustomFormsDisplay config={{ canUpdate: canUpdate }} scrollToTop={scrollToTop} dataLabel={`holding-show-monitoring-engagement`} fundID={fundId} holdingID={holding.id} current_user={current_user}/>
            case "IFC Reporting":
                if(showIFCShow){
                    return  <IFCPrincipleShow target={{ fundId, ...holding }} config={{canUpdate: holding.current_app_user_can_update}} changeFrameworkToCreate={() => setIFCShow(false)} dataLabel="holding-show" targetType="holding"/>
                } else {
                    return <IFCPrincipleCreate target={{ fundId, ...holding }} changeFrameworkToShow={() => setIFCShow(true)}
                    dataLabel="holding-show" targetType="holding"/>
                }
            case "Reports":
                return <HoldingFormPublicationModule holding={holding}/>
            case "Activity":
                return <HoldingActivityModule holding={holding} />
            case "Manage":
                return <ManageHoldingModule fundId={fundId} holding={holding} updateHolding={updateHolding}/>
        }
    };

    const scrollToTop = () => {
        topOfHoldingTableRef.current.scrollIntoView(true);
    }

    if (!holding) {
        return <div>Loading...</div>
    } else {
        return (
            <Container ref={topOfHoldingTableRef}
                       data-cy="holding-show-main-container">
                <HoldingHeader
                    holding={holding}
                />
                <SectionWrapper
                    data-cy="holding-show-section-wrapper:description-section"
                    margin="10px 0px 32px 0px"
                    justify="space-between"
                >
                    <TextSection width="48%" data-cy="holding-show-section-wrapper:description-section::text-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tortor libero, pretium nec ullamcorper et, pharetra sed nisi. Vivamus imperdiet congue purus, eget consequat libero vehicula in. Aliquam vehicula ligula quis tincidunt aliquam. Vivamus eget massa faucibus, finibus ex in, egestas leo. Nulla neque nunc, semper non vehicula eget, feugiat id nisl. Sed aliquet risus vitae lacus pretium tempus. Morbi facilisis, sem id aliquam fringilla, arcu quam volutpat nisi, eget scelerisque mauris nunc in dolor. Sed ut erat quis urna pellentesque pharetra ac vel velit.
                    </TextSection>
                    <DetailsBox
                        dataLabel="holdings-show-section-wrapper:description-section::fund-details"
                        look={{tableWidth:"50%"}}
                        details={holding.createDetailsHashMap()}
                    />
                </SectionWrapper>

                {
                    holding.id !== "" && <TabHeader

                        dataLabel="holding-show:tab-header"
                        tabs={showTabs}
                        onChange={changeTab}
                        activeTab={currTab}
                    >
                    </TabHeader>
                }

                <PEShowModuleWrapper data-cy="holding-pe-show-tab-module">
                    <SectionWrapper data-cy="holding-show-section-wrapper::header-module" margin="10px 0px 15px 0px" justify="space-between" align="center">
                        <CardTitle data-cy="holding-show-tab-header-card-title" title={tabName(currTab)}></CardTitle>
                        {currModuleTopTab()}
                    </SectionWrapper>
                    {currModule()}
                </PEShowModuleWrapper>
            </Container>
        )
    }
}

export default HoldingShowPage;
