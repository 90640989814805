/* eslint-disable no-unused-vars */
// LIBRARIES
import React, { useEffect, useState } from 'react'
// BOOSTRAP COMPONENTS
import { Modal } from 'react-bootstrap'
import Select from 'react-select-3'

import Button from '../../../../../components/styleguide/Button'
// STYLEGUIDE COMPONENTS
import InputLabel from '../../../../../components/styleguide/InputLabel'
import SectionWrapper from '../../../../../components/styleguide/SectionWrapper'
import TextInput from '../../../../../components/styleguide/TextInput'
import { SDGDescriptions } from '../../../../../constants'
// CONSTANTS
import {
  countryCodes,
  defaultCountryCode,
  defaultIndustry,
  defaultPipelineState,
  defaultVertical,
  industries,
  pipelineStateOptions,
  verticals,
} from '../../../../../constants/funds'
//REPOSITORY CALLS
import {
  createFundHolding,
  editFundHolding,
  getFundHoldingFormTemplates,
} from '../../../../../repositories/funds_repository'
import { PEErrorService } from '../../../errorService'
// MODELS
import { Holding } from '../../../models/Holding'
// STYLING
import { AddPipelineDialog } from '../../../styles'
import GoalsHoldingTable from './GoalsHoldingTable'

const HoldingCreateModal = ({
  fundId,
  show,
  onHide,
  onSubmit,
  holding,
  config,
}) => {
  const [name, setName] = useState('')
  const [primaryMarket, setPrimaryMarket] = useState(defaultCountryCode.value)
  const [industry, setIndustry] = useState(defaultIndustry.value)
  const [vertical, setVertical] = useState(defaultVertical.value)
  const [pipelineState, setPipelineState] = useState(defaultPipelineState.value)
  const [multiplier, setMultiplier] = useState(null)
  const [errors, setErrors] = useState([])
  const [goals, setGoals] = useState([])
  const [holdingId, setHoldingId] = useState(null)
  const [investmentAmount, setInvestmentAmount] = useState(0)

  useEffect(() => {
    getFundHoldingFormTemplates(fundId).then(response => {
      const reportingFramework = response.data
      const SDGs = reportingFramework.filter(rf => rf.key === 'un_sdg')
      const goals = SDGs[0].items.map(sdg => {
        const number = +sdg.key.split('_')[1]
        return {
          number,
          description: SDGDescriptions[number].header,
          weight: 0,
        }
      })
      setGoals(goals)
    })
  }, [])

  useEffect(() => {
    if (holding) {
      setName(holding.name)
      setPrimaryMarket(holding.primary_market)
      setIndustry(holding.industry)
      setVertical(holding.vertical)
      setPipelineState(holding.pipeline_state)
      setHoldingId(holding.id)
      setInvestmentAmount(parseInt(holding.value).toFixed(2))
    }
  }, [])

  const updateGoals = (event, number) => {
    const newValue = +event.target.value
    const newGoals = [...goals].map(goal => {
      if (goal.number === number) {
        goal.weight = newValue
      }
      return goal
    })
    setGoals(newGoals)
  }

  const goalTotalsValid = () => {
    const goalTotal = goals.reduce((acc, el) => {
      return acc + el.weight
    }, 0)
    if (goalTotal > 100) {
      return false
    } else {
      return true
    }
  }

  const handleSubmit = () => {
    setErrors([])
    if (goalTotalsValid() || config.type === 'edit') {
      const newPrimaryMarket = primaryMarket.value
        ? primaryMarket.value
        : primaryMarket
      const holdingParams = {
        name: name,
        value: investmentAmount,
        primary_market: newPrimaryMarket,
        industry: industry,
        vertical: vertical,
        pipeline_state: pipelineState,
        weight: multiplier,
      }

      let holding = new Holding(holdingParams)

      let initialFormSubmission = {}

      if (goals && goals.length > 0) {
        const item_values = goals.map(goal => {
          return {
            key: SDGDescriptions[goal.number].yb_identifier,
            value: (goal.weight / 100).toFixed(2),
          }
        })

        initialFormSubmission = {
          forms: [
            {
              key: 'un_sdg',
              item_values,
            },
          ],
        }
      }

      if (config.type === 'create') {
        createFundHolding({ fundId, holding, initialFormSubmission })
          .then(response => {
            const newHolding = new Holding(response.data)
            newHolding.setPipelineState(response.data.state)
            onSubmit(newHolding)
          })
          .catch(error => {
            if (Object.keys(error.response.data).includes('name')) {
              error.response.data['holding_name'] = error.response.data.name
            }
            error.response &&
              PEErrorService.setErrors(error.response.data, setErrors)
          })
      } else if (config.type === 'edit') {
        editFundHolding({ fundId, holding, holdingId })
          .then(response => {
            const replacementHolding = new Holding(response.data)
            replacementHolding.setPipelineState(response.data.state)
            onSubmit(replacementHolding, 'edit')
          })
          .catch(error => {
            error.response &&
              PEErrorService.setErrors(error.response.data, setErrors)
          })
      }
    } else {
      setErrors({
        goals:
          'SDG Alignment Must Be Less Than 100% Total And No SDG Weight Can Be 0%',
      })
    }
  }

  const getCountryCodes = code => {
    return countryCodes.find(el => el.value === code)
  }

  const getPipeline = state => {
    return pipelineStateOptions.find(el => el.value === state)
  }

  const getVertical = vertical => {
    return verticals.find(el => el.value === vertical)
  }

  const getIndustry = () => {
    return industries.find(el => el.value === vertical)
  }

  return (
    <AddPipelineDialog show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Company</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SectionWrapper margin='0px 5px -10px 10px' width='auto'>
          <TextInput
            error={errors.holding_name}
            label='Holding Name *'
            width='100%'
            id='holding-name'
            value={name}
            onChange={(id, value, e) => {
              setName(value)
            }}
          />
        </SectionWrapper>
        <SectionWrapper align='center' margin='5px 0px 5px 10px' width='100%'>
          <SectionWrapper
            align='center'
            margin='14.25px 0px 0px 0px'
            width='50%'
          >
            <InputLabel label='Holding Geography' />
            <Select
              styles={{
                container: provided => ({
                  ...provided,
                  width: '100%',
                  marginBottom: '16.5px',
                }),
                input: provided => ({
                  ...provided,
                  paddingTop: '8px',
                  paddingBottom: '4.75px',
                }),
              }}
              onChange={input => {
                setPrimaryMarket(input.value)
              }}
              options={countryCodes}
              value={getCountryCodes(primaryMarket)}
              defaultValue={defaultCountryCode}
            />
          </SectionWrapper>
          {
            <SectionWrapper
              align='center'
              margin='14.25px 0px 0px 20px'
              width='44%'
            >
              {config.type && config.type === 'create' && (
                <>
                  <InputLabel label='Pipeline' />
                  <Select
                    styles={{
                      container: provided => ({
                        ...provided,
                        width: '100%',
                        marginBottom: '16.5px',
                      }),
                      input: provided => ({
                        ...provided,
                        paddingTop: '8px',
                        paddingBottom: '4.75px',
                      }),
                    }}
                    onChange={input => {
                      setPipelineState(input.value)
                    }}
                    options={pipelineStateOptions}
                    value={getPipeline(pipelineState)}
                    defaultValue={defaultPipelineState}
                  />
                </>
              )}
            </SectionWrapper>
          }
          <SectionWrapper
            align='center'
            margin='14.25px 0px 0px 0px'
            width='50%'
          >
            <InputLabel label='Holding Vertical' />
            <Select
              styles={{
                container: (provided, state) => ({
                  ...provided,
                  width: '100%',
                  marginBottom: '16.5px',
                }),
                input: (provided, state) => ({
                  ...provided,
                  paddingTop: '8px',
                  paddingBottom: '4.75px',
                }),
              }}
              onChange={input => {
                setVertical(input.value)
              }}
              options={verticals}
              value={getVertical(vertical)}
              defaultValue={defaultVertical}
            />
          </SectionWrapper>
          <SectionWrapper
            align='center'
            margin='14.25px 0px 0px 20px'
            width='44%'
          >
            <InputLabel label='Holding Industry' />
            <Select
              styles={{
                container: (provided, state) => ({
                  ...provided,
                  width: '100%',
                  marginBottom: '16.5px',
                }),
                input: (provided, state) => ({
                  ...provided,
                  paddingTop: '8px',
                  paddingBottom: '4.75px',
                }),
              }}
              onChange={input => {
                setIndustry(input.value)
              }}
              options={industries}
              value={getIndustry(industry)}
              defaultValue={defaultIndustry}
            />
          </SectionWrapper>
        </SectionWrapper>
        <SectionWrapper margin='0px 20px 0px 10px' width='100%'>
          <TextInput
            append='&times;'
            error={errors.weight}
            type='number'
            label='Multiplier *'
            width='50%'
            marginRight='30px'
            id='holding-multiplier'
            value={multiplier}
            onChange={(id, value, e) => {
              setMultiplier(value)
            }}
          />
          <TextInput
            prepend='$'
            append='.00'
            error={errors.value}
            type='number'
            label='Investment Amount'
            width='43%'
            id='investment_amount'
            value={investmentAmount}
            onChange={(id, value, e) => {
              setInvestmentAmount(value)
            }}
          />
        </SectionWrapper>
        {goals && config.type === 'create' && goals.length > 0 && (
          <SectionWrapper width='90%' margin='0px 20px 25px 20px'>
            {errors && errors.goals && (
              <div className='alert alert-danger'>{errors.goals}</div>
            )}
            <GoalsHoldingTable
              dataLabel='holdings-table-create'
              onUpdateGoals={updateGoals}
              goals={goals}
            />
          </SectionWrapper>
        )}
        <SectionWrapper justify='space-between' margin='10px 47px 10px 12px'>
          <Button width='20%' label='Cancel' onClick={onHide} />
          <Button width='30%' label='Save Prospect' onClick={handleSubmit} />
        </SectionWrapper>
      </Modal.Body>
    </AddPipelineDialog>
  )
}

export default HoldingCreateModal
