import propTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

export const UserAvatar = ({ user, width, initials, backgroundColor }) => {
  const widthPx = `${width}px`
  const StyledImg = styled.img`
    width: ${widthPx};
    height: ${widthPx};
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.2s;
    &.img-visible {
      opacity: 1;
    }
    &.img-hidden {
      opacity: 0;
    }
    &.img-error {
      display: none;
    }
  `

  const PlaceholderDiv = styled.div`
    --width-px: ${widthPx};

    width: ${widthPx};
    height: ${widthPx};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${backgroundColor};
    overflow: hidden;
    span {
      font-size: calc(var(--width-px) / 2.6);
      @supports (font-size: clamp(0.5rem, calc(var(--width-px) / 2.5), 4rem)) {
        font-size: clamp(0.5rem, calc(var(--width-px) / 2.5), 4rem);
      }
      line-height: 1.1;
      position: relative;
      color: white;
    }
  `

  const getInitials = fullName => {
    const allNames = fullName.trim().split(' ')
    const initials = allNames.reduce((acc, curr, index) => {
      if (index === 0 || index === allNames.length - 1) {
        acc = `${acc}${curr.charAt(0).toUpperCase()}`
      }
      return acc
    }, '')
    return initials
  }

  const [displayInitials, setDisplayInitials] = useState(initials)
  useEffect(() => {
    user?.full_name?.length
      ? setDisplayInitials(getInitials(user.full_name))
      : setDisplayInitials(initials)
  }, [initials])

  const [imgLoaded, setImgLoaded] = useState(false)
  const [imgError, setImgError] = useState(false)

  return user.avatar_url != null && user.avatar_url.length ? (
    <>
      <StyledImg
        src={user.avatar_url}
        loading='lazy'
        width={width}
        height={width}
        onLoad={() => setImgLoaded(true)}
        onError={() => setImgError(true)}
        className={`img-${imgLoaded ? 'visible' : 'hidden'} img-${
          imgError ? 'error' : ''
        }`}
        data-cy='user_avatar'
      />
      {imgError && (
        <PlaceholderDiv>
          <span>{displayInitials}</span>
        </PlaceholderDiv>
      )}
    </>
  ) : (
    <PlaceholderDiv data-cy='user_avatar'>
      <span>{displayInitials}</span>
    </PlaceholderDiv>
  )
}

UserAvatar.propTypes = {
  user: propTypes.object.isRequired,
  width: propTypes.oneOfType([propTypes.number, propTypes.string]),
  initials: propTypes.string,
  backgroundColor: propTypes.string,
}
UserAvatar.defaultProps = {
  width: 48,
  backgroundColor: '#8aabff',
}
