//LIBRARIES
import React, { useEffect, useState } from 'react'
import Select from 'react-select-3'

import InputLabel from '../../../../../components/styleguide/InputLabel'
import SectionWrapper from '../../../../../components/styleguide/SectionWrapper'
import TextSection from '../../../../../components/styleguide/TextSection'
//REPOSITORY
import {
  getFundForm,
  getHoldingForm,
  getThematicSnapshots,
} from '../../../../../repositories/funds_repository'
import IFCPrincipleItem from './IFCPrincipleItem'
//COMPONENTS
import ThematicSnapshotLineChart from './ThematicSnapshotLineChart'

const IFCPrincipleShow = ({
  target,
  config,
  changeFrameworkToCreate,
  dataLabel,
  targetType,
}) => {
  const [currentSubmission, setCurrentSubmission] = useState(0)
  const [allSubmissions, setAllSubmissions] = useState([])
  const [noSubmissions, setNoSubmissions] = useState(false)
  const [alignments, setAlignments] = useState([])

  useEffect(() => {
    if (target && target.id) {
      const formKey = 'ifc_principle'
      if (targetType === 'fund') {
        const fundID = target.id
        target &&
          target.id &&
          getFundForm({ fundID, formKey })
            .then(response => {
              setProperties(response)
            })
            .catch(error => {
              console.log(error)
            })
        getThematicSnapshots({ fundID, formKey })
          .then(response => {
            createAlignments(response.data, targetType)
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        const fundID = target.fundId
        const holdingID = target.id
        getHoldingForm({ fundID, holdingID, formKey })
          .then(response => {
            setProperties(response)
          })
          .catch(error => {
            console.log(error)
          })
      }
    }
  }, [target.id])

  const createAlignments = alignments => {
    const fundAlignments = alignments.map(alignment => {
      return { x: alignment.as_of, y: +alignment.fund_alignment }
    })
    const holdingAlignments = alignments.map(alignment => {
      return { x: alignment.as_of, y: +alignment.holding_alignment }
    })
    alignments = { fundAlignments, holdingAlignments }
    setAlignments(alignments)
  }

  const setProperties = response => {
    if (response.data.submissions.length > 0) {
      setAllSubmissions(response.data.submissions)
    } else {
      setNoSubmissions(true)
    }
  }

  const getAsOfs = () => {
    return allSubmissions.map(sub => {
      return {
        label: `
                ${new Date(sub.as_of).toLocaleString('default', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                })}
                `,
        value: sub.as_of,
      }
    })
  }

  const changeSubmission = date => {
    const newSubmissionIndex = allSubmissions.indexOf(
      allSubmissions.find(sub => sub.as_of === date.value)
    )
    setCurrentSubmission(newSubmissionIndex)
  }

  const getTotalScore = () => {
    return currSubmission.item_values.reduce((acc, el) => {
      return acc + +el.value
    }, 0)
  }

  const currSubmission = allSubmissions[currentSubmission]

  return (
    <>
      {!noSubmissions && currSubmission && (
        <>
          {targetType === 'fund' && (
            <ThematicSnapshotLineChart
              alignments={alignments}
              dataLabel={dataLabel}
              header={'Fund Score Over Time'}
            />
          )}
          <SectionWrapper
            data-cy={`${dataLabel}-top-ifc-principle-show-wrapper`}
            margin='0px 0px 0px 0px'
            justify='space-betwen'
          >
            <TextSection
              data-cy={`${dataLabel}-top-ifc-principle-show-as-of`}
              style={{ alignSelf: 'center' }}
              width='50%'
            >
              Current Report (as of{' '}
              {new Date(currSubmission.as_of).toLocaleString('default', {
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
              })}
              )
            </TextSection>
            <SectionWrapper
              data-cy={`${dataLabel}-top-ifc-principle-show-as-of:select-wrapper`}
              margin='0px 0px 0px 0px'
              width='32%'
            >
              <InputLabel
                data-cy={`${dataLabel}-top-ifc-principle-show-as-of:label`}
                label='Choose an Existing Report'
              />
              <Select
                data-cy={`${dataLabel}-top-ifc-principle-show-as-of:select`}
                styles={{
                  container: provided => ({
                    ...provided,
                    width: '70%',
                    marginBottom: '16.5px',
                  }),
                  input: provided => ({
                    ...provided,
                    paddingTop: '8px',
                    paddingBottom: '4.75px',
                  }),
                }}
                onChange={selected => {
                  changeSubmission(selected)
                }}
                options={
                  allSubmissions && allSubmissions.length > 0 && getAsOfs()
                }
                defaultValue={{
                  label: `
                                ${new Date(currSubmission.as_of).toLocaleString(
                                  'default',
                                  {
                                    month: 'long',
                                    day: 'numeric',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                  }
                                )}
                                `,
                  value: currSubmission.as_of,
                }}
              />
            </SectionWrapper>
            {config.canUpdate && (
              <div
                onClick={changeFrameworkToCreate}
                style={{
                  color: 'blue',
                  'text-decoration': 'underline',
                  cursor: 'pointer',
                  alignSelf: 'center',
                }}
                data-cy={`${dataLabel}-ifc-principle-show:new-report-link`}
              >
                Submit a New Report
              </div>
            )}
          </SectionWrapper>
        </>
      )}
      {!noSubmissions && currSubmission && currSubmission.item_values && (
        <SectionWrapper
          margin='0px 0px 0px 20px'
          justify='space-between'
          data-cy={`${dataLabel}-top-section-wrapper`}
        >
          <TextSection width='33%' data-cy={`${dataLabel}-IFC-key`}>
            <h5>Question</h5>
          </TextSection>
          <TextSection
            width='33%'
            align='center'
            data-cy={`${dataLabel}-current-score-label`}
          >
            <h5>Current Score</h5>
          </TextSection>
          <TextSection
            width='33%'
            data-cy={`${dataLabel}-current-evaluation-label`}
          >
            <h5>Notes</h5>
          </TextSection>
        </SectionWrapper>
      )}

      {currSubmission &&
        currSubmission.item_values &&
        currSubmission.item_values.map((sub, i) => {
          return (
            <IFCPrincipleItem
              item={sub}
              dataLabel={`${dataLabel}-ifc-principle-show:ifc-principle-item-${i}`}
              key={`${dataLabel}-ifc-principle-show:ifc-principle-item-${i}`}
            />
          )
        })}
      {currSubmission && currSubmission.item_values && (
        <SectionWrapper
          justify='center'
          data-cy={`${dataLabel}-ifc-principle-show:total-score-wrapper`}
        >
          <TextSection
            width='auto'
            data-cy={`${dataLabel}-ifc-principle-show:total-score-label`}
          >
            Total Score
          </TextSection>
          <SectionWrapper
            width='10%'
            margin='2px 20px 0px 20px'
            data-cy={`${dataLabel}-ifc-principle-show:total-score-value`}
          >
            {getTotalScore()}
          </SectionWrapper>
        </SectionWrapper>
      )}
      {noSubmissions && (
        <SectionWrapper justify='center' margin='30px 0px 30px 0px'>
          <TextSection align='center'>
            You have no IFC Principle reports.
          </TextSection>
          {config.canUpdate && (
            <div
              onClick={changeFrameworkToCreate}
              style={{
                color: 'blue',
                'text-decoration': 'underline',
                cursor: 'pointer',
                alignSelf: 'center',
              }}
              data-cy={`${dataLabel}-ifc-principle-show:new-report-link`}
            >
              Submit a New Report
            </div>
          )}
        </SectionWrapper>
      )}
    </>
  )
}

export default IFCPrincipleShow
