export const COMPANY_TYPES = [
  { id: 1, value: 'public', label: 'Public' },
  { id: 2, value: 'private', label: 'Private' },
]

const M = 1000000
export const REVENUE_BUCKETS_OPTIONS = [
  { id: 1, label: '$0 - $10M', value: { lowerBound: 0, upperBound: 10 * M } },
  {
    id: 2,
    label: '$10M - $50M',
    value: { lowerBound: 10 * M, upperBound: 50 * M },
  },
  {
    id: 3,
    label: '$50M - $150M',
    value: { lowerBound: 50 * M, upperBound: 150 * M },
  },
  {
    id: 4,
    label: '$150M - $500M',
    value: { lowerBound: 150 * M, upperBound: 500 * M },
  },
  {
    id: 5,
    label: '$500M - $1B',
    value: { lowerBound: 500 * M, upperBound: 1000 * M },
  },
  {
    id: 5,
    label: '$1B - $5B',
    value: { lowerBound: 1000 * M, upperBound: 5000 * M },
  },
  { id: 6, label: '> $5B', value: { lowerBound: 5000 * M } },
]

export const QuickSearchesList = [
  {
    label: 'Mutual Funds / ETFs',
  },
  {
    label: 'Top 5 Capital Markets',
  },
  {
    label: 'High ESG Score Mega Caps',
  },
  {
    label: 'Bonds',
  },
]

export const QuickSearchesValues = {
  mutualFunds: {
    filter: {
      instrumentType: [
        {
          id: 46,
          label: 'Mutual Fund',
          value: 'Mutual Fund',
        },
        {
          id: 47,
          label: 'ETF',
          value: 'ETF',
        },
        {
          id: 102,
          label: 'Open End Fund',
          value: 'Open End Fund',
        },
        {
          id: 105,
          label: 'Closed End Fund',
          value: 'Closed End Fund',
        },
      ],
    },
  },
  topFiveCapitalMarkets: {
    filter: {
      countryType: [
        {
          id: 'JPN',
          label: 'Japan',
          value: 'JPN',
        },
        {
          id: 'CAN',
          label: 'Canada',
          value: 'CAN',
        },
        {
          id: 'USA',
          label: 'United States of America',
          value: 'USA',
        },
        {
          id: 'CHN',
          label: 'China',
          value: 'CHN',
        },
        {
          id: 'GBR',
          label: 'United Kingdom of Great Britain and Northern Ireland',
          value: 'GBR',
        },
      ],
    },
  },
  HighEsgScore: {
    filter: {
      marketCap: [
        {
          id: 1,
          label: 'Mega Cap (More than $200B)',
          value: 'Mega Cap',
        },
      ],
      totalEsg: [65, 100],
    },
  },
  Bonds: {
    filter: {
      instrumentType: [
        {
          id: 84,
          label: 'Convertible Bond',
          value: 'Convertible Bond',
        },
        {
          id: 44,
          label: 'Corporate Bond',
          value: 'Corporate Bond',
        },
        {
          id: 92,
          label: 'Municipal Bond',
          value: 'Municipal Bond',
        },
        {
          id: 45,
          label: 'Sovereign Bond',
          value: 'Sovereign Bond',
        },
        {
          id: 100,
          label: 'Mortgage',
          value: 'Mortgage',
        },
      ],
    },
  },
}

export const HEAD_SELECT_COUNTRIES = [
  { id: 'AUS', label: 'Australia', value: 'AUS' },
  { id: 'CAN', label: 'Canada', value: 'CAN' },
  { id: 'DEU', label: 'Germany', value: 'DEU' },
  { id: 'HKG', label: 'Hong Kong', value: 'HKG' },
  { id: 'KOR', label: 'Korea (Republic of)', value: 'KOR' },
  { id: 'JPN', label: 'Japan', value: 'JPN' },
  { id: 'CHN', label: 'China', value: 'CHN' },
  { id: 'FRA', label: 'France', value: 'FRA' },
  {
    id: 'GBR',
    label: 'United Kingdom of Great Britain and Northern Ireland',
    value: 'GBR',
  },
  { id: 'USA', label: 'United States of America', value: 'USA' },
]
